import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useMessageActions, useMessages } from '_messages'
import { DashboardMessagesConversationField } from 'components/dashboard/messages/dashboard-messages-conversation-field'
import { DashboardMessagesConversationCompose } from 'components/dashboard/messages/dashboard-messages-conversation-compose'
import { css } from '@emotion/core'
import { FormattedMessage } from 'react-intl'
import { fetchMessages } from '_messages/messages-service'

export const Messages = ({ applicationId, channelType }) => {
  const chatRoom = {
    applicationId,
    channelType,
  }
  const resolveContextKey = (msg) =>
    JSON.stringify({
      applicationId: msg.applicationId,
      channelType: msg.channelType,
    })
  const contextKey = resolveContextKey(chatRoom)
  useMessages(contextKey, resolveContextKey)
  const msgActions = useMessageActions(contextKey)

  const [chatVisible, setChatvisible] = useState(false)

  useEffect(() => {
    if (!applicationId) return
    msgActions.loadMessages(chatRoom)
    fetchMessages(chatRoom).then((messages) => {
      if (messages.length) {
        setChatvisible(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const Header = () => (
    <HeaderDiv open={chatVisible}>
      <div>
        <h2 id="header">
          <FormattedMessage id={'chat'} defaultMessage={'chat'} />
        </h2>
      </div>
      <div>
        <button
          className="riot-btn secondary round"
          active={chatVisible ? 'true' : undefined}
          onClick={() => setChatvisible(!chatVisible)}
        >
          <i class="fa fa-comments-o" aria-hidden="true"></i>
        </button>
      </div>
    </HeaderDiv>
  )

  if (!chatVisible) {
    return <Header />
  }

  return (
    <div
      css={css`
        height: fit-content;
        box-shadow: 0 3px 8px 0 var(--shade);
        border-radius: 10px;
        height: 497px;
        margin-bottom: 3em;
        --conversationFieldHeight: 342px;
        --inputHeight: 39px;
        --composeContainerPadding: 12px 17px;
      `}
    >
      {/* <ConversationWindow chatRoom={chatRoom} /> */}
      <Header />
      {chatVisible && (
        <>
          <DashboardMessagesConversationField chatRoom={chatRoom} />
          <DashboardMessagesConversationCompose chatRoom={chatRoom} />
        </>
      )}
    </div>
  )
}

const HeaderDiv = styled.div`
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  ${(props) =>
    !props.open
      ? `
      box-shadow: 0 3px 8px 0 var(--shade);
         border-radius: 10px;
         margin-bottom: 3em;
  `
      : `border-bottom: 1px solid grey;
      `}

  #header {
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
  }
`
