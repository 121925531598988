import gql from 'graphql-tag'

export const UPDATE_EVENTS_CATEGORIES = gql`
  mutation updateEventsCategories($eventsCategories: [EventsCategoryInput]) {
    updateEventsCategories(eventsCategories: $eventsCategories) {
      eventsCategories {
        id
        name
        subcategories {
          id
          name
          parentId
        }
      }
    }
  }
`
