import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'

export const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  box-shadow: 0 3px 8px 0 var(--shade);
  padding: 1em 2em;
  border-radius: 10px;
  button.ctaButtonHollow {
    ${ctaButtonHollow}
    width: 100%;
    margin: 10px 0;
  }
  .grow {
    flex-grow: 1;
  }
  #header {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
`
