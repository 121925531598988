import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ContentSection from './content-section'
import { ButtonWrapper } from './styles/contentSectionExpandable'

const ContentSectionExpandable = ({ header, items, ContentComponent }) => {
  const intl = useIntl()
  const [viewMore, setViewMore] = useState(false)

  return (
    <ContentSection header={header} hasItems={items && items.length > 0}>
      {items?.slice(0, !viewMore ? 2 : undefined).map((item) => (
        <ContentComponent item={item} />
      ))}
      {items?.length > 2 && (
        <ButtonWrapper>
          <button onClick={() => setViewMore(!viewMore)}>
            {intl.formatMessage({
              id: `person.details.view.${viewMore ? 'less' : 'more'}`,
            })}
          </button>
        </ButtonWrapper>
      )}
    </ContentSection>
  )
}

export default ContentSectionExpandable
