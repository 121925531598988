import gql from 'graphql-tag'

export const GET_USERS = gql`
  query {
    getAllUsers {
      data {
        firstName
        middleName
        lastName
        sub
        bioShort
        coverPhoto
        location
      }
    }
  }
`
