import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useAppLabel } from '_application'
import { Default as CompanyTile } from '_companies/components/tiles'
import { CONNECT_MUTATION } from '_companies/connected/company-details-connected'
import { useUser } from '_security/session-hooks'
import styled from '@emotion/styled'
import DiscoverSection from '../components/discover-section'
import { Grid, BaseTile } from '_tiles'
import { Loader } from '_components/loading'
import { getPageSizeForScreenSize } from '_tiles/helpers'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 4,
  xl: 4,
  lg: 6,
  md: 6,
  sm: 12,
}

const GET_DATA = gql`
  query GetDiscoverCompanies($paging: PagingInput) {
    getAllCompaniesPublic(paging: $paging)
    @connection(key: "GetDiscoverCompanies") {
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 0px 0px 100px;
  background-color: var(--background);
`

const DiscoverCompaniesConnected = ({ filter }) => {
  const appLabel = useAppLabel()
  const { data, error } = useQuery(GET_DATA, {
    variables: {
      paging: {
        offset: 0,
        limit: getPageSizeForScreenSize({
          type: 'company',
          pageType: 'discoverPage',
        }),
      },
    },
  })

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)
  const [connectId, setConnectId] = useState('')
  const user = useUser()
  const description = appLabel('companiesDescription', 'empty')

  const connectButton = (company) => {
    return {
      disabled: connecting && connectId === company.id,
      onConnect: () => {
        setConnectId(company.id)
        connect({
          variables: {
            id: company.id,
            creatorId: company?.creatorId || company?.creator?.sub
          },
          refetchQueries: [
            {
              query: GET_DATA,
              variables: {
                paging: {
                  offset: 0,
                  limit: getPageSizeForScreenSize({
                    type: 'company',
                    pageType: 'discoverPage',
                  }),
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
    }
  }

  return (
    <Container className="sectionContainer page-wrapper stretchColorToScreensize">
      <DiscoverSection
        title={appLabel('companiesPlural', 'companies.titleplural')}
        link="/companies"
        description={description}
      >
        <Loader loading={!data} error={error}>
          {() => (
            <>
              <Grid config={gridConfig} testid="companies">
                {data?.getAllCompaniesPublic?.data?.map((company) => {
                  return (
                    <BaseTile
                      key={company.id}
                      url={`/discover/companies/${company.id}`}
                      config={defaultConfig}
                      testid="discover-company"
                    >
                      <CompanyTile
                        key={company.id}
                        company={company}
                        connectButton={connectButton(company)}
                        isOwner={user?.sub === company?.creator?.sub}
                        showConnections={false}
                        tileHeight="398px"
                      />
                    </BaseTile>
                  )
                })}
              </Grid>
            </>
          )}
        </Loader>
      </DiscoverSection>
    </Container>
  )
}

export default DiscoverCompaniesConnected
