import styled from '@emotion/styled'

export const Content = styled.div`
  margin-bottom: 20px;
`

export const VisibilityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -35px;
  right: 35px;
  height: 66px;
  width: 66px;
  border-radius: 100%;
  z-index: 3;
  background-color: var(--shade);
  box-shadow: 0px 0px 6px #dfdfdf;
`

export const VisibilityIcon = styled.img`
  height: 35px;
  width: 35px;
  object-fit: contain;
  filter: ${({ isDarkTheme }) => isDarkTheme && 'invert(1)'};
`

export const ActivityTitle = styled.h1`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  margin-bottom: 20px;
  font-size: 36px;
`

export const DurationContainer = styled.div`
  /* margin-right: 35px; */
  padding-bottom: 15px;
`

export const DurationContainerNoPadding = styled.div``

export const DurationButton = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 500;
`

export const RecommendButton = styled.div`
  padding-right: 25px;
  padding: 0px;
  display: inline-block;
`

export const ConnectButton = styled.div`
  padding: 5px 0;
  display: inline-block;
`

export const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
`

export const EmailButton = styled.a`
  display: inline-block;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  color: var(--foreground);
  i {
    margin: 0 10px 0 15px;
    font-size: 16px;
  }
  &:hover {
    text-decoration: underline;
    color: inherit;
    opacity: 0.8;
  }
`
