import gql from 'graphql-tag'

export const ADD_ATTRIBUTE = gql`
  mutation AddAttribute(
    $name: String
    $description: String
    $isSearchable: Boolean
    $isPrivate: Boolean
    $type: String
    $badges: [BadgeInput]
  ) {
    addAttribute(
      name: $name
      description: $description
      type: $type
      isSearchable: $isSearchable
      isPrivate: $isPrivate
      badges: $badges
    ) {
      name
    }
  }
`

export const UPDATE_ATTRIBUTE = gql`
  mutation UpdateAttribute(
    $key: String
    $name: String
    $description: String
    $isSearchable: Boolean
    $isPrivate: Boolean
    $type: String
    $usedBy: [String]
    $badges: [BadgeInput]
  ) {
    updateAttribute(
      key: $key
      name: $name
      description: $description
      type: $type
      isSearchable: $isSearchable
      isPrivate: $isPrivate
      usedBy: $usedBy
      badges: $badges
    ) {
      name
    }
  }
`

export const DELETE_ATTRIBUTE = gql`
  mutation DeleteAttribute($key: String) {
    deleteAttribute(key: $key) {
      name
    }
  }
`

export const GET_ATTRIBUTES = gql`
  query {
    getAttributes {
      key
      name
      description
      type
      isSearchable
      isPrivate
      usedBy
      dateCreated
      dateUpdated
      badges {
        key
        icon
        name
      }
    }
  }
`
