import { gql } from '@apollo/client'

// TODO:
export const GET_EVENTS_PUBLIC = gql`
  query GetAllEvents(
    $paging: PagingInput
    $filter: EventFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllEventsPublic(filter: $filter, paging: $paging, ordering: $ordering) {
      hasMore
      ordering {
        field
        order
      }
      count
      offset
      data {
        id
        title
        bannerImage
        excerpt
        description
        location {
          lat
          long
          address
          type
          link
        }
        start
        end
        doorsOpen
        categories {
          childrenKeys
          key
        }
      }
    }
  }
`

export const GET_PUBLIC_EVENT = gql`
  query GetPublicEvent($id: ID!) {
    getPublicEvent(id: $id) {
      id
      title
      bannerImage
      description
      excerpt
      categories {
        key
        childrenKeys
      }
      venueLocation {
        type
        address
        link
        lat
        long
      }
      keywords {
        key
        childrenKeys
      }
      badges {
        key
        childrenKeys
      }
      start
      end
      doorsOpen
      companies {
        id
        uid
        name
        description
        owner {
          id
        }
        coverPhoto
        location
        connectionStatus
        start
        end
        _createdAt
      }
      activities {
        id
        uid
        name
        description
        coverPhoto
        location
        connectionStatus
        start
        end
        _createdAt
      }
      status
      step
      creatorId
      publishAt
      _createdAt
      attributes {
        texts {
          key
          value
        }
        urls {
          key
          value
        }
        dates {
          key
          value
        }
        phones {
          key
          value
        }
        emails {
          key
          value
        }
        images {
          key
          value
        }
        booleans {
          key
          value
        }
        badges {
          key
          value
        }
        locations {
          key
          lat
          long
          city
          country
          label
        }
        files {
          key
          value {
            name
            url
            extension
            dateCreated
            dateUpdated
            type
          }
        }
      }
      topics {
        key
        childrenKeys
      }
    }
  }
`

export const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      _createdAt
      publishAt
      creatorId
      bannerImage
      description
      excerpt
      venueLocation {
        type
        address
        lat
        link
        long
      }
      categories {
        key
        childrenKeys
      }
      speakers
      keywords {
        key
        childrenKeys
      }
      badges {
        key
        childrenKeys
      }
      start
      end
      doorsOpen
      companies
      activities
      status
      step
      attributes {
        key
        type
        name
        values
      }
      topics {
        key
        childrenKeys
      }
    }
  }
`

export const GET_EVENT_DETAILS = gql`
  query GetEventDetails($id: ID!) {
    getEventDetails(id: $id) {
      id
      title
      bannerImage
      description
      isGoing
      isInterested
      going
      interested
      excerpt
      categories {
        id
        name
        subcategories {
          id
          name
          parentId
        }
      }
      venueLocation {
        type
        address
        link
        lat
        long
      }
      speakers {
        id
        sub
        avatar
        name
      }
      keywords {
        id
        name
        children {
          id
          name
        }
      }
      badges {
        id
        name
        badges {
          key
          name
          icon
        }
      }
      start
      end
      doorsOpen
      companiesCount
      activitiesCount
      status
      step
      creator {
        id
        sub
        avatar
        name
        location
      }
      publishAt
      _createdAt
      attributes {
        texts {
          key
          value
        }
        urls {
          key
          value
        }
        dates {
          key
          value
        }
        phones {
          key
          value
        }
        emails {
          key
          value
        }
        images {
          key
          value
        }
        booleans {
          key
          value
        }
        badges {
          key
          value
        }

        files {
          key
          value {
            name
            url
            extension
            dateCreated
            dateUpdated
            type
          }
        }

        locations {
          key
          lat
          long
          city
          country
          label
        }
      }
      topics {
        key
        treeData {
          refId
        }
      }
    }
  }
`

export const GET_EVENT_CONNECTIONS = gql`
  query Data($id: ID!) {
    getEEventConnectedSpeakersPaged(id: $id) {
      count
    }
    getEEventConnectedCompaniesPaged(id: $id) {
      count
    }
    getEEventConnectedActivitiesPaged(id: $id) {
      count
    }
  }
`
