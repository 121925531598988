import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node,
  className: PropTypes.string,
}
export const H1 = themable('H1', Props)
export const H2 = themable('H2', Props)
export const H3 = themable('H3', Props)
export const H4 = themable('H4', Props)
export const H5 = themable('H5', Props)

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
}
