import gql from 'graphql-tag'

export const UPDATE_ENTITIES_ACCESS = gql`
  mutation updateEntitiesAccess($entitiesAccess: EntityAccessInput) {
    updateEntitiesAccess(entitiesAccess: $entitiesAccess) {
      entitiesAccess {
        name
        indexRequiresAuth
        detailsRequiresAuth
      }
    }
  }
`
