import styled from '@emotion/styled'

export const TypeSwitch = styled.div`
  button {
    margin: 0;
    padding: 6px 12px;
    font-size: 11px;
    opacity: 0.6;
    margin-right: 5px;
    color: var(--foreground);
    border: 1px solid var(--foreground);
    &.active {
      border: 1px solid var(--flavor);
      background: var(--flavor);
      color: var(--flavor-contrast);
      opacity: 1;
    }
    &:hover {
      border: 1px solid var(--flavor);
      background: var(--flavor);
      color: var(--flavor-contrast);
      opacity: 1;
    }
  }
`
