import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import { CONNECT_MUTATION } from '_activities/connected/activity-details-connected'
import { useUser } from '_security/session-hooks'
import { Default as ActivityTile } from '_activities/components/tiles'
import { Grid, BaseTile } from '_tiles'
import { getPageSizeForScreenSize } from '_tiles/helpers'
import DiscoverSection from '../components/discover-section'
import { Loader } from '_components/loading'
import styled from '@emotion/styled'

import { useColorBucket } from '_colorBus/hooks'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 1,
  xl: 3,
  lg: 6,
  md: 12,
  sm: 12,
}

const GET_DATA = gql`
  query GetDiscoverAllActivities($paging: PagingInput) {
    getAllActivitiesPublic(paging: $paging)
    @connection(key: "GetDiscoverAllActivities") {
      data {
        id
        name
        coverPhoto
        description
        start
        end
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
const Container = styled.div`
  position: relative;
  padding: 0px 0px 100px;
  background-color: var(--background);
`

const DiscoverActivitiesConnected = ({ filter }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const user = useUser()

  const { data, error } = useQuery(GET_DATA, {
    variables: {
      paging: {
        offset: 0,
        limit: getPageSizeForScreenSize({
          type: 'activity',
          pageType: 'discoverPage',
        }),
      },
    },
  })

  const title = intl.formatMessage(
    { id: 'discover.latestcategory' },
    {
      category: appLabel('activitiesPlural', 'activities.titleplural'),
    }
  )

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)
  const [connectId, setConnectId] = useState('')

  const DESCRIPTION = appLabel('activitiesDescription', 'empty')

  const connectButton = (activity) => {
    return {
      keydisabled: connecting && connectId === activity.id,
      onConnect: () => {
        setConnectId(activity.id)
        connect({
          variables: {
            id: activity.id,
            creatorId: activity?.creator?.sub,
          },
          refetchQueries: [
            {
              query: GET_DATA,
              variables: {
                paging: {
                  offset: 0,
                  limit: getPageSizeForScreenSize({
                    type: 'activity',
                    pageType: 'discoverPage',
                  }),
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
    }
  }

  const [colorBucket] = useColorBucket('blog')

  if (data?.getAllActivitiesPublic?.data?.length === 0) {
    return null
  }

  return (
    <Container ref={colorBucket} className="sectionContainer page-wrapper stretchColorToScreensize">
      <DiscoverSection
        title={title}
        link="/activities"
        description={DESCRIPTION}
      >
        <Loader loading={!data} error={error}>
          {() => (
            <>
              <Grid config={gridConfig} testid={'activities'}>
                {data?.getAllActivitiesPublic?.data?.map((activity) => {
                  return (
                    <BaseTile
                      key={activity.id}
                      url={`/discover/activities/${activity.id}`}
                      config={defaultConfig}
                      testid="discover-activity"
                    >
                      <ActivityTile
                        activity={activity}
                        connectButton={connectButton(activity)}
                        isOwner={user?.sub === activity?.creator?.sub}
                      />
                    </BaseTile>
                  )
                })}
              </Grid>
            </>
          )}
        </Loader>
      </DiscoverSection>
    </Container>
    // <div>
    //   {data?.getAllActivitiesPublic?.data?.length > 0 ? (
    //     <NamedTileSlider
    //       loading={!data}
    //       error={error}
    //       title={title}
    //       description={DESCRIPTION}
    //       link="/activities/list"
    //       testid="activities-slider"
    //       url={`/discover/activities/`}
    //     >
    //       <Grid config={gridConfig}>
    //         {data?.getAllActivitiesPublic?.data?.map((activity) => (
    //           <BaseTile
    //             key={activity.key}
    //             url={`/discover/activities/${activity?.id}`}
    //             config={defaultConfig}
    //           >
    //             <ActivityTile
    //               activity={activity}
    //               connectButton={connectButton(activity)}
    //               isOwner={user?.sub === activity?.creator?.sub}
    //             />
    //           </BaseTile>
    //         ))}
    //       </Grid>
    //     </NamedTileSlider>
    //   ) : null}
    // </div>
  )
}

export default DiscoverActivitiesConnected

