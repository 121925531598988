import { getFlatDataFromTree } from 'react-sortable-tree'

export function convertFlatDataToTreeData() {}

export function convertTreeDataToFlatData(treeData) {
  return getFlatDataFromTree({
    treeData,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  }).map(({ node, path }) => {
    const d = {
      id: parseInt(node.id, 10),
      name: node.title,
      refId: node.refId,
      parent: path.length > 1 ? parseInt(path[path.length - 2], 10) : null,
    }
    if (d.parent === null) delete d.parent
    return d
  })
}
