import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

export const NotificationsContent = styled.div`
  padding: 26px 45px 50px 45px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  ${mobile} {
    padding: 26px 13px 50px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }
`

export const NoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title,
  .subtitle,
  .message,
  .second-message {
    text-align: center;
  }

  .title,
  .subtitle,
  .message {
    padding: 25px 25px 0;
  }
  .title {
    font-weight: 500;
    margin-top: 30px;
  }
  .subtitle {
    margin-top: 20px;
  }
  .message {
    width: 65%;
    margin: 0 auto;
  }
  .second-message {
    padding-top: 15px;
  }
`

export const SuggestionTilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 306px);
  grid-template-rows: repeat(auto, 1fr);
  grid-gap: 25px;
  justify-content: center;
  margin-top: 35px;
`
