import actions from './actions'

const initialState = {
  labelOverrides: {},
}
export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_APP_CONFIG:
      return action.payload
    default:
      return state
  }
}
