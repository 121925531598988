import React from 'react'
import { usePluginsPublic } from '_application/plugins'
import { useUser } from '_security/session-hooks'

export const Mailchimp = () => {
  const { plugin } = usePluginsPublic('mailchimp')
  const isEnabled = plugin?.enabled
  const user = useUser()
  const isLogged = user?.profile?.firstName

  if (isEnabled && isLogged) {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: plugin?.data?.script }} />
      </div>
    )
  } else {
    return <></>
  }
}

export default Mailchimp
