import styled from '@emotion/styled'

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .title-input {
    width: 80vw;
    max-width: 800px;
    height: 39px;
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid var(--foreground);
    background: var(--background);
    color: var(--foreground);
  }

  .character-limit-indicator {
    opacity: 0.7;
    text-align: right;
  }
`
