import React from 'react'

const PersonalDetails = ({ name, biography, location }) => {
  return (
    <>
      <span className="user-name" data-testid="onboarding-preview-userName">
        {name}
      </span>
      <span className="user-location" data-testid="onboarding-preview-location">
        {location && <i className="fas fa-map-marker-alt" />}
        {location}
      </span>
      <div className="about-me">
        <p>{biography}</p>
      </div>
    </>
  )
}

export default PersonalDetails
