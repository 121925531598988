import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { Map } from '_components/drilldown'
import CustomAttributes from '_custom-attributes/components/list'
import CompanyMiniCard from './tiles/company-mini-card'
import PublicDetailsCard from '_public-details-card'
import { Taxonomy, Keywords } from '_keywords-and-taxonomies'
import { Section, SingleAttribute } from '_custom-attributes'
import { Link, useHistory } from 'react-router-dom'
import { useAppLabel, conditionalTrimmer } from '_application'
import count from 'helpers/counter'
import styled from '@emotion/styled'

import Connections from './connections'
import { ctaButtonHollow } from '_utils/css-buttons'

import SanitiseHTML from 'helpers/sanitize-html'
import Modal from '../../_events/components/event-creator/components/modal'
import { gql, useMutation } from '@apollo/client'
import EditInfo from 'components/indy/shared/edit-info'

const DELETE_COMPANY = gql`
  mutation deleteCompany($id: String) {
    deleteCompany(id: $id) {
      id
    }
  }
`

const GoBackButton = styled.button`
  ${ctaButtonHollow}
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
  span.text:first-letter {
    text-transform: uppercase;
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  transform: translateY(-100px);
  background-color: transparent;
`

const TileWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`

const Title = styled.h1`
  font-size: 36px;
`

// const EmailButton = styled.a`
//   display: inline-block;
//   font-size: 14px;
//   font-weight: 700;
//   color: var(--foreground);
//   margin: 15px 0 20px;
//   padding: 5px 0;
//   text-decoration: underline;
//   i {
//     margin: 0 10px;
//   }
//   &:hover {
//     color: inherit;
//     opacity: 0.8;
//     text-decoration: underline;
//   }
// `

const CompanyDetails = ({
  company,
  children,
  attributes,
  onClose,
  connectButton,
  c2aButton,
  isOwner,
  p2pButton,
  recommendButton,
}) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const history = useHistory()
  const location = company?.location?.name
    ? { label: company?.location?.name }
    : null
  const defaultTaxonomiesKeys = ['industry-areas']
  const defaultTaxonomies = company?.taxonomy?.filter((v) =>
    defaultTaxonomiesKeys.some((d) => d === v.key)
  )
  const defaultKeywordsKeys = ['maturiry', 'employees']
  const defaultKeywords = company?.keywords?.filter((v) =>
    defaultKeywordsKeys.some((d) => d === v.key)
  )
  const foundedDate = company?.founded
  let defaultTaxonomiesLength = count(defaultTaxonomies)
  let defaultKeywordsLength = count(defaultKeywords)

  const tabs = {
    about: { type: 'about', translationKey: 'user.profile.about' },
    activity: {
      type: 'connection',
      translationKey: 'activities.titleplural',
      count: company?.connectedActivitiesCount,
    },
    people: {
      type: 'connection',
      translationKey: 'people.titleplural',
      count: company?.connectedUsersCount,
    },
    opportunity: {
      type: 'connection',
      translationKey: 'opportunities.titleplural',
      count: company?.connectedOpportunitiesCount,
    },
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    variables: {
      id: company?.id,
    },
  })

  // const emailButton = () => {
  //   return (
  //     <>
  //       <EmailButton
  //         data-for="shareWithEmail"
  //         href={`mailto:?subject=${company.name}&body=${intl.formatMessage({
  //           id: 'company.details.sharewithemail.emailbody',
  //         })} ${window.location.href}`}
  //       >
  //         <i class="fas fa-arrow-up" />
  //         <span>
  //           {intl.formatMessage({
  //             id: 'companies.details.sharewithemail.button',
  //           })}
  //         </span>
  //       </EmailButton>
  //     </>
  //   )
  // }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])

  const isAboutView = tabs[currentTab]?.type === 'about'

  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${company?.name} company?`}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.continue',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.cancel',
        })}
        onProceed={() => {
          deleteCompany()
            .then(() => {
              history.push({
                pathname: '/companies/list',
                state: { refresh: true },
              })
            })
            .catch(console.error)
        }}
      />
      <PublicDetailsCard>
        <PublicDetailsCard.Header
          onClose={onClose}
          returnText={'Back to all companies'}
          coverUrl={
            company?.bannerImage?.asset?.url ||
            company?.bannerImage ||
            company?.coverPhoto
          }
          type={appLabel('companies', 'detailcard.company')?.toLowerCase()}
          entityId={company.id}
          title={company.name}
          creator={company?.creator?.id}
          recommendButton={recommendButton}
          onDelete={() => setIsDeleteModalOpen(true)}
        />

        <PublicDetailsCard.Container>
          {isAboutView && (
            <PublicDetailsCard.Info position="left">
              <InfoWrapper>
                <TileWrapper>
                  <CompanyMiniCard
                    company={company}
                    connectButton={connectButton}
                    c2aButton={c2aButton}
                    isOwner={isOwner}
                  />
                </TileWrapper>
                {location && (
                  <>
                    <Section type="location">
                      <SingleAttribute
                        attribute={{
                          type: 'location',
                          description: `${intl.formatMessage({
                            id: 'singleattribute.officelocation.label',
                          })}`,
                          name: `${intl.formatMessage({
                            id: 'singleattribute.officelocation.label',
                          })}`,
                          value: location,
                        }}
                      />
                    </Section>
                  </>
                )}
                {foundedDate && (
                  <Section type="date">
                    <SingleAttribute
                      attribute={{
                        type: 'date',
                        description: `${intl.formatMessage({
                          id: 'singleattribute.founded.label',
                        })}`,
                        name: `${intl.formatMessage({
                          id: 'singleattribute.founded.label',
                        })}`,
                        value: foundedDate,
                      }}
                    />
                  </Section>
                )}
                {company?.homepage && (
                  <Section type="url">
                    <SingleAttribute
                      attribute={{
                        type: 'url',
                        description: conditionalTrimmer(
                          company.homepage,
                          45,
                          '...'
                        ),
                        name: `${intl.formatMessage({
                          id: 'singleattribute.homepage.label',
                        })}`,
                        value: company?.homepage,
                      }}
                    />
                  </Section>
                )}
                {defaultKeywords && defaultKeywordsLength > 0 && (
                  <>
                    <Keywords
                      noMainTitle
                      values={defaultKeywords}
                      type={'companies'}
                    />
                  </>
                )}
                {defaultTaxonomies && defaultTaxonomiesLength > 0 && (
                  <>
                    <Taxonomy
                      noMainTitle
                      values={defaultTaxonomies}
                      type={'companies'}
                    />
                  </>
                )}

                {company?.keywords && (
                  <Keywords
                    noMainTitle
                    excludeKeys={defaultKeywordsKeys}
                    values={company?.keywords}
                    type={'companies'}
                  />
                )}
                {company?.taxonomy && (
                  <Taxonomy
                    noMainTitle
                    excludeKeys={defaultTaxonomiesKeys}
                    values={company?.taxonomy}
                    type={'companies'}
                  />
                )}
                <CustomAttributes
                  values={company?.customAttributes}
                  attributes={attributes}
                  side={'left'}
                />
              </InfoWrapper>
            </PublicDetailsCard.Info>
          )}

          <PublicDetailsCard.Content
            position={isAboutView ? 'right' : 'center'}
          >
            <PublicDetailsCard.ContentTabs
              tabs={tabs}
              onTabSelect={setCurrentTab}
              useFollowersLabel={true}
            />
            {company?.editor && (
              <EditInfo
                editDetails={{
                  authorName: company?.editor?.firstName,
                  authorSurname: company?.editor?.lastName,
                  date: company?._updatedAt,
                }}
              />
            )}
            {isAboutView && (
              <>
                <Title>{company.name}</Title>
                <SanitiseHTML html={company?.body} />
                <Map location={company.location} />
              </>
            )}
            {!isAboutView && (
              <Connections currentTab={currentTab} entityId={company?.id} />
            )}
          </PublicDetailsCard.Content>
          <PublicDetailsCard.GoBackContent>
            <Link to="/companies/list/">
              <GoBackButton data-testid="detailCard-btn-back">
                <p>
                  <i className="fas fa-chevron-left" />
                  <span className="text">
                    {intl
                      .formatMessage(
                        { id: 'detailcard.button.back' },
                        {
                          category: appLabel(
                            'companiesPlural',
                            'detailcard.button.companies'
                          ),
                        }
                      )
                      .toLowerCase()}
                  </span>
                </p>
              </GoBackButton>
            </Link>
          </PublicDetailsCard.GoBackContent>
        </PublicDetailsCard.Container>
        <ReactTooltip id="info-tooltip" effect="solid" />
      </PublicDetailsCard>
    </>
  )
}

export default CompanyDetails

