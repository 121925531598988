import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Notification,
  Title,
  Wrapper,
  AvatarWrapper,
  Description,
  BottomWrapper,
  AcceptButton,
  CancelButton,
  TimeAgo,
  TopWrapper,
  ShowMoreWrapper,
  ShowMore,
  ExpandedNotification,
  DeleteButton,
  TitleWrapper,
} from './styles/default'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Default = ({
  item,
  deleteAction,
  ExpandComponent,
  expandAction,
  ContentComponent,
  acceptAction,
  declineAction,
  image,
  hideSender,
}) => {
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState()
  const [isValid, setIsValid] = useState(true)
  const { firstName, lastName } = item?.user || {}

  const userName =
    firstName || (lastName && (firstName || lastName !== ''))
      ? `${firstName}${lastName ? ` ${lastName}` : ''}`
      : `${intl.formatMessage({
          id: 'notificationscenter.notification.username.noname',
        })}`

  const resolveSubType = (subtype) => {
    if (subtype === 'a2p') return 'p2a'
    return subtype
  }

  return (
    <Notification>
      <TopWrapper data-testid={`notification-${item?.user?.sub}`}>
        <TitleWrapper>
          <Title>
            {intl.formatMessage({
              id: 'notificationscenter.notification.title',
            })}
          </Title>
          <TimeAgo>
            {moment(item?.notification?.sentDate).fromNow(true)}
          </TimeAgo>
        </TitleWrapper>
        {deleteAction && (
          <DeleteButton
            data-tip={`${intl.formatMessage({
              id: 'notificationscenter.notification.tooltip.deletebutton.tip',
            })}`}
            data-for="delete-button"
            onClick={() => deleteAction(item?.notification?._id)}
          >
            <i class="fas fa-times-circle" />
          </DeleteButton>
        )}
        <ReactTooltip id="delete-button" effect="solid" />
      </TopWrapper>
      <Wrapper>
        <AvatarWrapper>
          <img src={image || item?.user?.avatar} alt="" />
        </AvatarWrapper>
        <Description>
          {hideSender ? null : (
            <Link to={`/people/list/${item?.user?.sub}`}>{`${userName} `}</Link>
          )}

          {ContentComponent && <ContentComponent setIsValid={setIsValid} />}
          {(expandAction || ExpandComponent) && isValid && (
            <ShowMoreWrapper>
              <ShowMore
                onClick={() =>
                  ExpandComponent ? setIsExpanded(!isExpanded) : expandAction()
                }
              >
                {intl.formatMessage({
                  id: `notificationscenter.notification.button.${
                    isExpanded ? 'showless' : 'showmore'
                  }`,
                })}
              </ShowMore>
            </ShowMoreWrapper>
          )}
        </Description>
      </Wrapper>
      <BottomWrapper isCentered={ExpandComponent && isExpanded}>
        {acceptAction && declineAction && (
          <>
            <AcceptButton
              onClick={() =>
                acceptAction(
                  item?.notification?.payload?.find(
                    (p) => p?.key === 'connection'
                  )?.value,
                  item?.notification?._id,
                  resolveSubType(item?.notification?.subtype?.split('-')?.[0])
                )
              }
            >
              {intl.formatMessage({
                id: 'notificationscenter.notification.button.accept',
              })}
            </AcceptButton>
            <CancelButton
              onClick={() =>
                declineAction(
                  item?.notification?.payload.find(
                    (p) => p?.key === 'connection'
                  )?.value,
                  item?.notification?._id,
                  resolveSubType(item?.notification?.subtype?.split('-')?.[0])
                )
              }
            >
              {intl.formatMessage({
                id: 'notificationscenter.notification.button.decline',
              })}
            </CancelButton>
          </>
        )}
        {ExpandComponent && isExpanded && (
          <ExpandedNotification>
            <div className="content">
              <ExpandComponent />
            </div>
          </ExpandedNotification>
        )}
      </BottomWrapper>
    </Notification>
  )
}

export default Default
