import styled from '@emotion/styled'
import { mobile, tablet } from '_utils/responsive-queries'

const NavStyled = styled.div`
  display: flex;
  /* flex-direction: column-reverse;
  width: 100%; */
  /* ${mobile} {
    margin-bottom: 25px;
  } */
  .progress-bar-container {
    width: 100%;
    height: 10px;
    margin: 40px auto;
    .progress-bar {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      .bar {
        height: 100%;
        border-radius: 20px;
        transition: width 0.5s ease-in;
      }
    }

    ${tablet} {
      margin: 64px auto;
      height: 6px;
    }

    ${mobile} {
      margin: 60px auto 40px;
    }
    /* .progress-message {
      display: inline;
      position: absolute;
      bottom: 25%;
      height: 35px;
      color: var(--flavor);
      font-family: ${({ theme }) => theme.fonts.googleFont};
      .percentage {
        display: inline-block;
        position: relative;
        width: 50px;
        height: 100%;
        border-radius: 5px;
        background-color: var(--flavor);
        color: var(--background);
        line-height: 35px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        &::after {
          content: '';
          position: absolute;
          transform: translate(-50%, -50%) rotate(45deg);
          bottom: -25%;
          left: 50%;
          height: 10px;
          width: 10px;
          background-color: var(--flavor);
        }
      }
      .message {
        display: block;
        line-height: 35px;
        font-size: 16px;
        font-weight: 500;
        padding: 0 10px;
        color: var(--foreground);

        ${mobile} {
          display: none;
        }
      }
    } */
  }
  /* p.sub-title {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 45px;
    color: var(--foreground);
  } */
`

export default NavStyled
