import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useAppLabel } from '_application'
import { Grid, BaseTile } from '_tiles'
import { tablet } from '_utils/responsive-queries'

import {
  CONNECT_MUTATION,
  DISCONNECT_MUTATION,
} from '_people/connected/person-details-connected'
import styled from '@emotion/styled'

import DiscoverSection from '../components/discover-section'
import { Loader } from '_components/loading'

import { Default as PeopleTile } from '_people/components/tiles'

import { getPageSizeForScreenSize } from '_tiles/helpers'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const GET_DATA = gql`
  query GetDiscoverUsers($paging: PagingInput) {
    getAllUsersPublic(paging: $paging) @connection(key: "GetDiscoverUsers") {
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

const Container = styled.div`
  --margin: 50px;
  position: relative;
  padding: 0px 0px 100px;
  background-color: var(--background);

  ${tablet} {
    --margin: 25px;
  }
`

const DiscoverPeopleConnected = () => {
  const appLabel = useAppLabel()
  const { data, error } = useQuery(GET_DATA, {
    variables: {
      paging: {
        offset: 0,
        limit: getPageSizeForScreenSize({
          type: 'people',
          pageType: 'discoverPage',
        }),
      },
    },
  })

  const description = appLabel('peopleDescription', 'empty')

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)
  const [connectId, setConnectId] = useState('')
  const [disconnect] = useMutation(DISCONNECT_MUTATION)

  const refetchQueries = {
    query: GET_DATA,
    variables: {
      paging: {
        offset: 0,
        limit: getPageSizeForScreenSize({
          type: 'people',
          pageType: 'discoverPage',
        }),
      },
    },
  }

  const connectButton = (person) => {
    return {
      disabled: connecting && connectId === person.sub,
      onConnect: () => {
        setConnectId(person.sub)
        connect({
          variables: { sub: person.sub },
          refetchQueries: [refetchQueries],
          awaitRefetchQueries: true,
        })
      },
    }
  }

  const disconnectButton = (person) => {
    return {
      onDisconnect: () => {
        disconnect({
          variables: { personId: person.sub },
          refetchQueries: [refetchQueries],
          awaitRefetchQueries: true,
        })
      },
    }
  }

  return (
    <Container className="sectionContainer page-wrapper stretchColorToScreensize">
      <DiscoverSection
        title={appLabel('peoplePlural', 'people.titleplural')}
        link="/people"
        description={description}
      >
        <Loader loading={!data} error={error}>
          {() => (
            <>
              <Grid config={gridConfig} testid="people">
                {data?.getAllUsersPublic?.data.map((person) => {
                  return (
                    <BaseTile
                      key={person.sub}
                      url={`/people/list/${person.id}`}
                      config={defaultConfig}
                      refetchQueries={refetchQueries}
                      testid="discover-person"
                    >
                      <PeopleTile
                        person={person}
                        connectButton={connectButton(person)}
                        disconnectButton={disconnectButton(person)}
                      />
                    </BaseTile>
                  )
                })}
              </Grid>
            </>
          )}
        </Loader>
      </DiscoverSection>
    </Container>
  )
}

export default DiscoverPeopleConnected
