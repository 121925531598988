import React, { useState, useRef, useEffect } from 'react'
import {
  SortableTreeStyled,
  ItemStyled,
  CheckboxStyled,
  ItemWrapper,
} from '../styles/sortable-tree'

const SortableTree = ({
  treeData,
  selectedValues,
  onSelect,
  key,
  isReadOnly,
}) => {
  /* TODO: workaround to get opened nodes, should be moved to useState in recursive component */
  const [openList, setOpenList] = useState(
    isReadOnly ? treeData.map((item) => openAllNodes(item, [])).flat(50) : []
  )
  const [isOpen, setIsOpen] = useState()
  const [id, setId] = useState('')

  const content = useRef(null)

  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    setContentHeight(content?.current?.clientHeight)
  }, [])

  if (id !== key && !isReadOnly) {
    setId(key)
    setOpenList([parseId(treeData?.[0]?.refId)])
  }

  return (
    <SortableTreeStyled
      className={`selected-taxonomy-list ${isReadOnly ? 'read-only' : ''}`}
      data-testid="tree-styled"
    >
      <div ref={content} className={`content ${isOpen ? 'open' : ''}`}>
        {treeData.map((item) =>
          Item({
            item,
            selectedValues: selectedValues?.map((item) => item?.split('_')?.join(' ')),
            onSelect,
            openList,
            setOpenList,
            isReadOnly,
          })
        )}
      </div>
      {isReadOnly && contentHeight >= 400 && (
        <div onClick={() => setIsOpen(!isOpen)} className="load-more">
          <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`} />
        </div>
      )}
    </SortableTreeStyled>
  )
}

const Item = ({
  item,
  selectedValues,
  onSelect,
  openList,
  setOpenList,
  isReadOnly,
}) => {
  const key = parseId(item?.refId)
  const isOpen = openList?.some((o) => o === key)
  const isSingleNode = !item?.children

  // to get root node, null check is required
  const isRootNode = item?.parent === null
  const isSelected =
    isSingleNode && selectedValues?.some((s) => s.toLowerCase() === parseId(item?.refId))
  const hasSelectedValues = hasChildrenSelectedValues({ item, selectedValues })

  const handleClick = (item) => {
    if (isSingleNode && !isReadOnly) onSelect({ item, isSelected })
    else if (!isReadOnly) {
      setOpenList(
        isOpen ? openList?.filter((o) => o !== key) : [...openList, key]
      )
    }
  }

  if ( 
    !hasSelectedValues &&
    isReadOnly &&
    !selectedValues?.some((sv) => sv === key)
  ) {
    return null
  }

  return (
    <ItemStyled
      key={key}
      className={`
        ${isSingleNode ? 'single' : 'multi'} 
        ${isReadOnly ? 'read-only' : ''}
        ${isRootNode ? 'root' : ''}
      `}
    >
      {!isRootNode &&
        (isReadOnly && hasSelectedValues ? null : (
          <div
            className={`info ${hasSelectedValues ? 'has-selected-values' : ''}`}
            onClick={() => handleClick(item)}
          >
            {isSingleNode && !isReadOnly && (
              <Checkbox isSelected={isSelected} />
            )}
            {!isSingleNode && !isReadOnly && (
              <i
                className={`chevron fas fa-chevron-${isOpen ? 'down' : 'right'
                  }`}
                data-testid={`tree-chevron-${item.title}-${isOpen ? 'down' : 'right'
                  }`}
              />
            )}
            {isReadOnly && isSingleNode && <div className="tree-line" />}

            <span
              className={`title ${isSingleNode ? 'single' : ''}`}
              data-testid={`tree-item-${item.title}${isSelected ? '-selected' : ''
                }`}
            >
              {item.title}
            </span>
          </div>
        ))}
      {!isSingleNode && (
        <ItemWrapper
          isReadOnly={isReadOnly}
          className={`children ${isOpen ? 'active' : ''} ${isRootNode ? 'root' : ''
            }`}
        >
          {item.children.map((child) =>
            Item({
              item: child,
              onSelect,
              selectedValues,
              openList,
              setOpenList,
              isReadOnly,
            })
          )}
        </ItemWrapper>
      )}
    </ItemStyled>
  )
}

const Checkbox = ({ isSelected }) => {
  return (
    <CheckboxStyled className={`${isSelected ? 'selected' : ''}`}>
      {isSelected && <i className="fas fa-check" />}
    </CheckboxStyled>
  )
}

const hasChildrenSelectedValues = ({ item, selectedValues }) => {
  const hasSelectedValues = item?.children?.some((c) =>
    selectedValues?.some((s) => s.toLowerCase() === parseId(c?.refId))
  )
  if (hasSelectedValues) return true
  else
    return item?.children?.some((c) =>
      hasChildrenSelectedValues({ item: c, selectedValues })
    )
}

const openAllNodes = (item, list) => {
  let l = [...list, parseId(item?.refId)]
  return item?.children
    ? item?.children.map((child) => openAllNodes(child, l))
    : l
}

const parseId = (id) => id?.toLowerCase()
export default SortableTree
