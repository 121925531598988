import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useLocation, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { GET_KEYWORDS } from '../graph-queries/keywords'
import { GET_TAXONOMY } from '../graph-queries/taxonomy'
import { defaultRoute } from '../routes'
import { useIntl } from 'react-intl'
import { usePluginsPublic } from '_application'
import { useStripeCustomer } from '_payment'

import { tablet, pablet } from '_utils/responsive-queries'

const Name = styled.div`
  font-weight: 700;
  color: var(--foreground);
  font-size: 28px;
  padding: 20px;
  cursor: pointer;

  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;

  p {
    margin: 0px;
    font-size: 24px;
  }

  i {
    display: none;
    justify-self: end;
    padding: 0px 10px;
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
  }

  ${tablet} {
    i {
      display: block;
    }
  }

  &.toggleOn i {
    transform: rotate(0deg);
  }
`

const NavWrapper = styled.div`
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  position: sticky;
  top: 48px;
  overflow: hidden;
  background: var(--background);

  ${pablet} {
    border-radius: 0px;
    box-shadow: none;
  }
`

const NavItems = styled.ul`
  padding: 0px;
  max-height: 100vh;
  transition: 0.4s ease-in-out;

  ${tablet} {
    &.toggleOff {
      max-height: 0px;
      padding: 0px;
      margin: 0px;
    }
  }

  li {
    list-style: none;
    position: relative;
    font-size: 18px;
    &.active {
      a {
        font-weight: 600;
        background-color: var(--shade-faint);
        color: var(--foreground) !important;
        font-family: ${(props) => props.theme.fonts.googleFont} !important;
        border-left: 4px solid var(--flavor);
      }
      .arrow-indicator {
        color: var(--foreground) !important;
        display: initial !important;
        position: absolute;
        right: 0px;
        top: 23%;
        font-size: 18px;
        -webkit-animation: slide-in-left 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }

    a {
      padding: 13px 20px;
      position: relative;
      display: block;
      color: var(--foreground);
      font-size: 16px;
      font-family: ${(props) => props.theme.fonts.googleFont} !important;
      transition: 0.2s ease-in-out;
    }
    .arrow-indicator {
      display: none;
    }
    &.active {
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
`

export const navItems = [
  {
    name: 'About',
    type: 'about',
    path: `${defaultRoute}/about`,
  },
  {
    name: 'My connections',
    type: 'myConnections',
    path: `${defaultRoute}/graph`,
  },
  { name: 'Keywords', type: 'keywords', path: `${defaultRoute}/keywords` },
  { name: 'Topics', type: 'topics', path: `${defaultRoute}/topics` },
  {
    name: 'Subscriptions',
    type: 'subscriptions',
    path: `${defaultRoute}/subscriptions`,
    isPayment: true,
  },
  {
    name: 'Payment details',
    type: 'paymentDetails',
    path: `${defaultRoute}/payment-details`,
    isPayment: true,
  },
  {
    name: 'Purchase history',
    type: 'purchaseHistory',
    path: `${defaultRoute}/purchase-history`,
    isPayment: true,
  },
  {
    name: 'Account',
    type: 'account',
    path: `${defaultRoute}/account`,
  },
  {
    name: 'Delete profile',
    type: 'deleteProfile',
    path: `${defaultRoute}/delete-profile`,
  },
]

const Navigation = () => {
  const location = useLocation()
  const { user } = useSelector((state) => state)
  const intl = useIntl()
  const { customer: { paymentHistory } = {} } = useStripeCustomer()
  // const { paymentHistory } = customer || {}

  const { data: keywordData } = useQuery(GET_KEYWORDS, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['people'] },
  })

  const { data: taxonomyData } = useQuery(GET_TAXONOMY, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['people'] },
  })

  const { plugin } = usePluginsPublic('stripe')
  const isPaymentEnabled = plugin?.data?.account?.id

  const navItemsFiltered = () => {
    if (isPaymentEnabled)
      return paymentHistory?.length > 0
        ? navItems
        : navItems?.filter((n) => n.type !== 'purchaseHistory')

    return navItems?.filter((n) => !n.isPayment)
  }

  const [navToggle, setNavToggle] = useState(false)

  const welcomeUser = () => {
    const userDoesExist =
      user?.profile?.firstName && user?.profile?.firstName !== ''
    const welcomeText = intl.formatMessage({
      id: 'user.profile.navigation.welcometext',
    })
    const fallbackText = intl.formatMessage({
      id: 'user.profile.navigation.welcometext.noname',
    })

    return userDoesExist
      ? welcomeText + ', ' + user?.profile?.firstName
      : fallbackText
  }

  return (
    <NavWrapper>
      <Name
        data-testid="profile-firstName"
        className={navToggle ? 'toggleOn' : 'toggleOff'}
        onClick={() => setNavToggle(!navToggle)}
      >
        <p>{welcomeUser()}</p>
        <i class="fas fa-chevron-up" aria-hidden="true"></i>
      </Name>
      <NavItems className={navToggle ? 'toggleOn' : 'toggleOff'}>
        {navItemsFiltered().map((n, i, a) => {
          if (n.type === 'topics' && taxonomyData?.getTaxonomy?.length === 0)
            return <></>
          if (n.type === 'keywords' && keywordData?.getKeywords?.length === 0)
            return <></>

          return (
            <li
              key={n.path}
              className={`${location?.pathname === n.path ? 'active' : ''} ${
                i === a.length - 1 ? 'last' : ''
              }`}
            >
              <Link to={n.path} data-testid={`profile-navlink-${n.name}`}>
                {intl.formatMessage({
                  id: `user.profile.${n.type.toLowerCase()}`,
                })}
              </Link>
            </li>
          )
        })}
      </NavItems>
    </NavWrapper>
  )
}

export default Navigation
