import React from 'react'
import EventDetailView from '_events/connected/events-details-connected'
import ConfirmPublish from './components/publish-confirm'
import { useParams } from 'react-router-dom'

const Preview = ({ event, updateEvent, setEvent }) => {
  const { eventId } = useParams()
  const isPreview = true

  return (
    <div>
      <ConfirmPublish
        updateEvent={updateEvent}
        setEvent={setEvent}
        event={event}
      />
      <EventDetailView event={event} id={eventId} isPreview={isPreview} />
    </div>
  )
}

export default Preview
