import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import moment from 'moment'
import { useTheme } from 'emotion-theming'
import { useIntl } from 'react-intl'
import { setCurrentConversation } from '_messages/messages-actions'
import { useDispatch } from 'react-redux'
import { readConversationMessage } from '_messages/messages-actions'
import { Link } from 'react-router-dom'

import {
  MessageContainer,
  AvatarWrapper,
  ContentWrapper,
  Name,
  Message,
  Created,
  NameWrapper,
  Dots,
  DotsWrapper,
  DotsExpand,
  MainContainer,
} from '../styles/single-message'

const GET_NOTIFICATIONS = gql`
  query {
    getCurrentUser {
      sub
      notifications {
        notification {
          _id
          sub
          channel
          body
          type
          sentDate
          subtype
          read
          deleted
          payload
        }
        user {
          sub
          firstName
          lastName
          avatar
        }
      }
    }
  }
`

const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      avatar
    }
  }
`

const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: String!) {
    readNotification(id: $id)
  }
`

const convertDateString = (string, intl) => {
  if (string.includes('a few seconds'))
    return string.replace(
      'a few seconds',
      `${intl.formatMessage({ id: 'date.timefromnow.now' })}`
    )
  if (string.includes('a minute')) return string.replace('a minute', '1 min')
  if (string.includes('minutes')) return string.replace('minutes', 'min')
  if (string.includes('hours')) return string.replace('hours', 'h')
  if (string.includes('a hour')) return string.replace('a hour', '1 h')
  if (string.includes('days')) return string.replace('days', 'd')
  if (string.includes('a day')) return string.replace('a day', '1 d')
  if (string.includes('months')) return string.replace('months', 'm')
  if (string.includes('a month')) return string.replace('a month', '1 m')
  if (string.includes('years')) return string.replace('years', 'y')
  if (string.includes('a year')) return string.replace('a year', '1 y')
  return string
}

const checkNameValue = (firstName, lastName) => {
  if ((!firstName || firstName === '') && (!lastName || lastName === ''))
    return ' a Stranger'
  return ` ${firstName ? firstName : ''} ${lastName ? lastName : ''}`
}

const SingleMessage = ({
  userId,
  selectedItemId,
  setSelectedItemId,
  lastMessage,
  read,
  isMobileView,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const [dotsExpanded, setDotsExpanded] = useState(false)
  const { content, created, id: messageId, _id: notificationId } = lastMessage
  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: {
      id: userId,
    },
  })

  const dispatch = useDispatch()

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  })

  const toggleSelect = (e) => {
    if (selectedItemId === e.currentTarget.id) return setSelectedItemId(null)
    return setSelectedItemId(e.currentTarget.id)
  }

  const handleClose = () => {
    dotsExpanded && setDotsExpanded(false)
  }

  const ref = useClickOutside(handleClose)

  if (loading) return <></>
  const { avatar = theme.images.defaultAvatar, firstName, lastName } =
    data?.getUser || {}

  return (
    <MainContainer onMouseLeave={() => setDotsExpanded(false)}>
      <Link
        to={() => {
          return '/user/messages'
        }}
        onClick={() => {
          dispatch(setCurrentConversation(userId))
        }}
        data-testid={`nav-message-${userId}`}
      >
        <MessageContainer
          isMobileView={isMobileView}
          id={messageId}
          className={dotsExpanded ? 'selected' : ''}
          onClick={(e) => toggleSelect(e)}
        >
          <AvatarWrapper>
            <img src={`${avatar}`} alt="user avatar" />
          </AvatarWrapper>
          <ContentWrapper isMobileView={isMobileView}>
            <NameWrapper>
              <Name>{checkNameValue(firstName, lastName)}</Name>
              {/* <Created>{moment(created).fromNow(true)}</Created> */}
              <Created>
                {convertDateString(moment(created).fromNow(true), intl)}
              </Created>
            </NameWrapper>
            <Message data-testid="nav-message-content">{content}</Message>
          </ContentWrapper>
        </MessageContainer>
      </Link>

      <DotsWrapper onClick={() => setDotsExpanded(!dotsExpanded)}>
        <Dots>
          <i class="fas fa-ellipsis-v" />
        </Dots>
        {dotsExpanded && (
          <DotsExpand
            onClick={() => {
              readNotification({
                variables: { id: notificationId },
              })
              // notificationId and messageId are interchangeable in this context.
              dispatch(readConversationMessage(notificationId))
            }}
            ref={ref}
          >
            <i class="far fa-check-circle" />
            {intl.formatMessage({ id: 'nav.notifications.markasread' })}
          </DotsExpand>
        )}
      </DotsWrapper>
    </MainContainer>
  )
}

export default SingleMessage
