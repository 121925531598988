import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import AddModal from './add-new-general-modal'

const Index = ({ onAdd, type }) => {
  const [modal, setModal] = useState()
  const intl = useIntl()

  const handleAdd = (props) => {
    onAdd(props)
    setModal(false)
  }

  const handleClose = () => {
    setModal(false)
  }

  return (
    <div>
      <div className="add-button">
        <Button onClick={() => setModal(true)} type="admin">
          {intl.formatMessage({ id: 'global.button.addnew' })}
        </Button>
      </div>
      {modal && (
        <AddModal
          modalType={type}
          onAdd={(p) => handleAdd(p)}
          onClose={() => handleClose()}
        />
      )}
    </div>
  )
}

export default Index
