import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import { useLocation } from 'react-router-dom'
import { StatsStyled } from './styles/statistics'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'
import { useAppConfig } from '_application'

import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const Statistics = ({ users }) => {
  const GET_KEY_DATA = gql`
    query {
      getKeyStatistics {
        activities
        opportunities
        companies
      }
    }
  `

  const intl = useIntl()
  const theme = useTheme()
  const config = useAppConfig()

  const { data /* , error  */ } = useQuery(GET_KEY_DATA)

  const stats = [
    {
      key: 'activitiesPlural',
      name: 'Activities',
      icon: theme.icons.activitiesIcon,
      value: data?.getKeyStatistics?.activities,
    },
    {
      key: 'opportunitiesPlural',
      name: 'Opportunities',
      icon: theme.icons.opportunitiesIcon,
      value: data?.getKeyStatistics?.opportunities,
    },
    {
      key: 'companiesPlural',
      name: 'Companies',
      icon: theme.icons.companiesIcon,
      value: data?.getKeyStatistics?.companies,
    },
    {
      key: 'peoplePlural',
      name: 'People',
      icon: theme.icons.peopleIcon,
      value: users?.length,
    },
    // {
    //   name: 'activities',
    //   icon: `${({ theme }) => theme.icons.activitiesIcon}`,
    //   value: activities?.length,
    // },
    // {
    //   name: 'opportunities',
    //   icon: `${({ theme }) => theme.icons.opportunitiesIcon}`,
    //   value: opportunities?.length,
    // },
    // {
    //   name: 'companies',
    //   icon: `${({ theme }) => theme.icons.companiesIcon}`,
    //   value: companies?.length,
    // },
    // {
    //   name: 'people',
    //   icon: `${({ theme }) => theme.icons.peopleIcon}`,
    //   value: users?.length,
    // },
  ]

  const renderMessageText = (key) => {
    const overrideLabel = config?.labelOverrides?.[key]?.value
    if (overrideLabel) return overrideLabel
    return intl.formatMessage({ id: `header.navigation.${key.toLowerCase()}` })
  }

  return (
    <StatsStyled>
      <Row>
        {stats.map((s) => {
          return (
            <Col md={3}>
              <div className="stats-box">
                <div className="values">
                  <span className="number">{s.value}</span>
                  <span className="name">{renderMessageText(s.key)}</span>
                </div>
                <div className="icon-container">
                  <s.icon
                    style={{ fill: 'white' }}
                    className={`${s.name}-icon`}
                  />
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </StatsStyled>
  )
}

export default Statistics
