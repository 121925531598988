export const couponToAmountText = ({ coupon }) => {
  return coupon?.amount_off
    ? `${(coupon?.amount_off / 100).toFixed(2)} ${coupon?.currency}`
    : `${coupon?.percent_off}%`
}

export const couponToAmount = ({ coupon }) => {
  return coupon?.amount_off
    ? (coupon?.amount_off / 100).toFixed(2)
    : coupon?.percent_off
}

export const couponToDurationText = ({ coupon, intl }) => {
  let duration = ''
  if (coupon?.duration === 'forever')
    duration = intl.formatMessage({
      id: 'user.profile.payments.form.coupon.duration.forever',
    })
  if (coupon?.duration === 'once')
    duration = `${intl.formatMessage({
      id: 'user.profile.payments.form.coupon.duration.once',
    })}`
  if (coupon?.duration_in_months) {
    const months = coupon?.duration_in_months
    duration = `${months} ${intl.formatMessage({
      id: `user.profile.payments.form.coupon.duration.${
        months > 1 ? 'months' : 'month'
      }`,
    })}`
  }
  return duration
}

export const calculateTotal = ({ coupon, price, renderMessageText }) => {
  const { recurring, unit_amount, currency } = price
  const interval = recurring?.interval

  let displayPrice

  if (coupon) {
    const { amount_off, percent_off } = coupon
    const discount = amount_off ? amount_off : (unit_amount * percent_off) / 100
    const totalPrice = ((unit_amount - discount) / 100).toFixed(2)
    displayPrice = `${currency.toUpperCase()} ${
      totalPrice > 0 ? totalPrice : 0
    }`
  } else {
    displayPrice = `${currency.toUpperCase()} ${(unit_amount / 100).toFixed(2)}`
  }

  if (interval) {
    const intervalText = renderMessageText(
      `summary.subscription.interval.${interval}`
    )
    return displayPrice + ` / ${intervalText}`
  }

  return displayPrice
}
