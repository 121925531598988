import React from 'react'
const File = ({ attr }) => {
  const { name, value, url } = attr

  return (
    <a
      className="file-link"
      href={url || value}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="file-info-wrapper">
        <span className="file-name">{name}</span>
      </div>
    </a>
  )
}

export default File
