import React, { useMemo, useState } from 'react'
import { getTreeFromFlatData } from 'react-sortable-tree'
import SelectorStyled from './selector-styled'
import SortableTree from './sortable-tree'

const Index = ({
  taxonomy,
  height,
  selectedValues,
  onSelectCallback,
  isReadOnly,
}) => {
  const [treeData, setTreeData] = useState()

  useMemo(() => {
    const convert = getTreeFromFlatData({
      flatData: taxonomy.treeData.map((node) => ({
        id: node.key,
        title: node.name,
        refId: node.refId,
        parent: node.parent,
      })),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    })
    setTreeData(convert)
  }, [taxonomy])

  const handleSelectTaxonomy = ({ item, isSelected }) => {
    let l = selectedValues
    if (isSelected) l = l.filter((li) => li !== item?.refId)
    else l.push(item?.refId)
    handleUpdateSelectList(l)
    onSelectCallback(l)
  }

  const handleUpdateSelectList = (list) => {
    let t = treeData
    // hack to force re-render of taxonomy
    t = treeData.concat({ title: 'z' })
    t = t.slice(0, t.length - 1)
    setTreeData(t)
  }

  if (!taxonomy || !treeData) return null
  return (
    <SelectorStyled className="taxonomy-tree-view-container">
      {/* <SelectedValues values={selectedValues} />*/}
      <SortableTree
        onSelect={({ item, isSelected }) =>
          handleSelectTaxonomy({ item, isSelected })
        }
        treeData={treeData}
        selectedValues={selectedValues}
        key={taxonomy?.key || ''}
        isReadOnly={isReadOnly}
      />
    </SelectorStyled>
  )
}

export default Index
