import styled from '@emotion/styled'

export const ProductPreviewStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--shade);
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin-bottom: 1rem;
`
export const ProductPricing = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: 1rem;
  column-gap: 0.25rem;
  .price {
    font-weight: 600;
  }
`
export const ProductName = styled.h3`
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 0.1rem;
`
export const ProductDescription = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const ImageWrapper = styled.div`
  border: 2px solid var(--background);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  background: var(--shade);
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 15px;
  text-align: center;
  overflow: hidden;
  span {
    font-size: 20px;
    line-height: 42px;
    color: var(--foreground);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const MainWrapper = styled.div`
  display: flex; ;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .details {
    justify-content: space-between;
    display: flex;
  }

  .interval {
    text-align: right;
    display: block;
  }
`

export const Section = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  &.bold {
    font-weight: 600;
  }
`

export const BottomPart = styled.div``
