import styled from '@emotion/styled'

export const SortableTreeStyled = styled.div`
  user-select: none;
  color: var(--foreground);
  &.read-only {
    user-select: inherit;
    .content {
      overflow: hidden;
      max-height: 400px;
      &.open {
        max-height: none;
      }
    }
  }
  .load-more {
    margin-top: 12px;
    &:hover {
      opacity: 0.65;
    }
    i {
      font-size: 18px;
      display: block;
      margin: 0 auto;
      padding: 4px 10px;
      cursor: pointer;
      text-align: center;
    }
  }
`

export const ItemStyled = styled.div`
  font-size: 15px;
  margin: 12px 0;
  &.single {
    font-size: 14px;
    margin: 8px 0;
  }
  .info {
    cursor: pointer;
    &.has-selected-values {
      .title {
        color: var(--flavor);
      }
      .chevron {
        color: var(--flavor);
      }
    }
    .tree-line {
      width: 5px;
      height: 5px;
      background-color: var(--flavor);
      display: inline-block;
      vertical-align: top;
      margin-top: 8px;
      border-radius: 50%;
    }
    .title {
      margin-left: 6px;
      display: inline-block;
      max-width: 95%;
      color: var(--foreground);
    }
    .chevron {
      color: var(--foreground);
    }
  }
  .children {
    display: none;
    margin-left: 10px;
    &.active {
      display: inherit;
    }
    &.root {
      margin-left: 0;
    }
  }
  &.read-only {
    .info {
      cursor: auto;
      &.has-selected-values {
        .title {
          font-weight: 600;
        }
      }
      .title {
        color: var(--foreground);
      }
    }
  }
`

export const ItemWrapper = styled.div`
  &.children.active {
    ${({ isReadOnly }) => isReadOnly && { margin: 0 }};
  }
`

export const CheckboxStyled = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--flavor);
  vertical-align: top;
  display: inline-block;
  margin-top: 4px;
  border-radius: 3px;
  &.selected {
    border: 1px solid transparent;
    background: var(--flavor);
    i {
      color: var(--background);
      width: 100%;
      height: 100%;
      font-size: 10px;
      font-weight: 900;
      display: table;
      margin: 2px;
    }
  }
`
