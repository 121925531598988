import React from 'react'
import { css } from '@emotion/core'
import OpportunityApplicationsListItem from './application-list-item'
import GridLoader from 'react-spinners/GridLoader'
import { LoaderStyled } from './styled/application-list'

function OpportunityApplicantsList({
  items,
  onItemCheckChange,
  selectedItems,
  loading,
  variables,
}) {
  if (loading || !items) {
    return renderLoading()
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        row-gap: 1.5em;
      `}
      data-testid="applications-list"
    >
      {items.map((item) => (
        <OpportunityApplicationsListItem
          key={item.id}
          onItemCheckChange={onItemCheckChange}
          application={item}
          isChecked={selectedItems[item.id]}
          variables={variables}
        ></OpportunityApplicationsListItem>
      ))}
    </div>
  )
}

export default OpportunityApplicantsList

const renderLoading = () => {
  return (
    <LoaderStyled data-testid="oldLoader">
      <GridLoader size={15} loading />
    </LoaderStyled>
  )
}
