import styled from '@emotion/styled'
import { desktop } from '_utils/responsive-queries'

export const CouponStyled = styled.div`
  span.title {
    font-size: 16px;
  }
  .coupon-header {
    cursor: pointer;
    i {
      margin-left: 10px;
    }
  }
`

export const CouponWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  display: flex;
  margin-top: 10px;
  ${desktop} {
    flex-wrap: nowrap;
  }

  flex-wrap: wrap;

  .error-message {
    display: block;
    color: #e25950;
    font-size: 12px;
    margin-top: 0.3rem;
  }
  button {
    flex-grow: 1;
  }
  input {
    color: var(--foreground);
    background: transparent;
    border: 1px solid var(--foreground-shade);
    padding: 5px 10px;
    border-radius: 4px;
    flex-grow: 1;
    ::placeholder {
      color: var(--foreground-shade);
    }
    &.error {
      border: 1px solid #e25950;
    }
  }
`
export const CurrentCoupon = styled.div`
  .coupon-name {
    font-weight: bold;
  }
  .coupon-off {
    margin-left: 10px;
  }
  i {
    margin-left: 8px;
    cursor: pointer;
  }
`
