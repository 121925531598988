import React from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'
import { Flex, FlexItem } from '@patternfly/react-core'
import { useQuery, gql } from '@apollo/client'
import moment from 'moment'
import {
  PageWrapper,
  CategoryWrapper,
  CategoryAvatar,
  Description,
  // CountersWrapper,
  PageSection,
  Counter,
  LatestWrapper,
  Indicator,
  TopicAvatar,
  MainSectionContainer,
  SectionHeader,
  TopWrapper,
  CategoryLink,
} from '../shared/styles'
import { CountersWrapper} from './styles/tag'
import { useMediaQuery } from 'react-responsive'
import { tabletPX } from '_utils/responsive-queries'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'
import { Loader } from '_components/loading'

const GET_TAG = gql`
  query GET_TAG($tagName: String!) {
    getTag(tag: $tagName) {
      tag
      score
      topicsList {
        tid
        title
        votes
        postcount
        viewcount
        teaser {
          content
          timestampiso
          user {
            username
            picture
          }
        }
        user {
          username
          picture
        }
      }
    }
  }
`

const Tag = () => {
  const { tagName } = useParams()

  const { data } = useQuery(GET_TAG, { variables: { tagName } })

  const intl = useIntl()
  const theme = useTheme()

  const isDesktopView = useMediaQuery({ query: `(min-width: ${tabletPX}px)` })

  const nameLimit = 30
  const descLimit = 45

  const setCharacterLimit = (string, limit) => {
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0
      ? ''
      : setCharacterLimit(e.childNodes[0].nodeValue, descLimit)
  }

  if (!data) return <Loader loading={!data} />

  return (
    <PageWrapper>
      <SectionHeader>
        <Flex>
          <FlexItem>
            <h3>{data?.getTag?.tag}</h3>
          </FlexItem>
        </Flex>
      </SectionHeader>
      {data?.getTag?.topicsList.map(function (object, i) {
        return (
          <MainSectionContainer hasGutter key={i}>
            <CategoryWrapper isTopElement={true} span={6}>
              <TopWrapper isTopElement={true}>
                <CategoryLink to={`/forum/topic/${object.tid}`}>
                  <CategoryAvatar isTopElement={true}>
                    <img
                      src={
                        object?.user?.picture || theme?.images?.defaultAvatar
                      }
                      alt="avatar"
                    />
                  </CategoryAvatar>
                  <Description>
                    <p
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: unescape(object.title),
                      }}
                      data-testid={`tag-topic-${object?.title}`}
                    ></p>

                    <span className="author">
                      <span>
                        {' '}
                        {replaceUsernameNumbers(object.user?.username)}
                      </span>
                      {/* {unescape(data?.getCategory.category.name)} */}
                      {/* <span className="bullet">&#8226;</span> Author name */}
                      <span className="bullet-wrapper">
                        <i className="fas fa-circle bullet" />
                      </span>
                      <span>
                        {moment(object.timestampiso).format(
                          'DD.MM.YYYY HH:mm '
                        )}
                      </span>
                    </span>
                  </Description>
                </CategoryLink>
                {/* <CountersWrapper span={2}>
                  <PageSection>
                    <Counter>{object.votes}</Counter>
                    <span>
                      {intl.formatMessage({
                        id: 'forum.stats.votes',
                      })}
                    </span>
                  </PageSection>
                  <PageSection>
                    <Counter>{object.postcount}</Counter>
                    <span>
                      {intl.formatMessage({
                        id: 'forum.stats.posts',
                      })}
                    </span>
                  </PageSection>
                  <PageSection>
                    <Counter>{object.viewcount}</Counter>
                    <span>
                      {intl.formatMessage({
                        id: 'forum.stats.views',
                      })}
                    </span>
                  </PageSection>
                </CountersWrapper> */}
              </TopWrapper>
            </CategoryWrapper>
            <CountersWrapper span={2}>
              <PageSection>
                <Counter>{object.votes}</Counter>
                <span>
                  {intl.formatMessage({
                    id: 'forum.stats.votes',
                  })}
                </span>
              </PageSection>
              <PageSection>
                <Counter>{object.postcount}</Counter>
                <span>
                  {intl.formatMessage({
                    id: 'forum.stats.posts',
                  })}
                </span>
              </PageSection>
              <PageSection>
                <Counter>{object.viewcount}</Counter>
                <span>
                  {intl.formatMessage({
                    id: 'forum.stats.views',
                  })}
                </span>
              </PageSection>
            </CountersWrapper>
            {isDesktopView && (
              <LatestWrapper span={4}>
                <Indicator href={`/forum/topic/${object.tid}`}>
                  {object.teaser ? (
                    <>
                      <div className="author-wrapper">
                        <TopicAvatar>
                          <img
                            src={
                              object.teaser.user.picture ||
                              theme?.images?.defaultAvatar
                            }
                            alt=""
                          />
                        </TopicAvatar>
                        <div className="author-info">
                          <div className="author-name">
                            <p>
                              {setCharacterLimit(
                                replaceUsernameNumbers(
                                  object?.teaser?.user?.username
                                ),
                                nameLimit
                              )}
                            </p>
                            <div className="date-wrapper">
                              <span className="bullet-wrapper">
                                <i className="fas fa-circle bullet" />
                              </span>
                              <span>
                                {moment(object.teaser.timestampiso).format(
                                  'DD.MM.YYYY HH:mm '
                                )}
                              </span>
                            </div>
                          </div>

                          <p
                            className="topic-name"
                            dangerouslySetInnerHTML={{
                              __html: unescape(object.teaser.content),
                            }}
                          ></p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <span className="no-posts">
                      {intl.formatMessage({ id: 'forum.info.nonewposts' })}
                    </span>
                  )}
                </Indicator>
              </LatestWrapper>
            )}
          </MainSectionContainer>
        )
      })}
    </PageWrapper>
  )
}

export default Tag
