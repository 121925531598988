import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import InputShared from '../generic/input-custom'

const Email = () => {
  const intl = useIntl()

  const renderMessageDescription = (value) => {
    return intl.formatMessage({
      id: `admin.settings.overridelabels.${value.toLowerCase()}`,
    })
  }

  return (
    <>
      <MainSection>
        <span className="title">
          {renderMessageDescription('opportunitiesemailtitle')}
        </span>
        <Row>
          <Col sm={12} md={6}>
            <InputShared
              type="opportunitiesEmail"
              limit={100}
              title={renderMessageDescription('opportunitiesemaildescription')}
            />
          </Col>
        </Row>
      </MainSection>
    </>
  )
}

export default Email
