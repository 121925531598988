import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useUser } from '_security/session-hooks'
import store from 'store'
import axios from 'axios'
import { useQuery, gql } from '@apollo/client'
import InitialLoading from '_components/initial-loading'

export const GET_CONFIG = gql`
  query GetAppConfig {
    getAppConfig {
      settings
      metaTitle
      metaKeywords
      backgroundVideoUrl
      theme
      termsAgreementSectionContent
      tenantId
      menu {
        key
        title
        url
        visibleLoggedIn
        visibleLoggedOut
		  icon
        index
      }
      configurations {
        kind
        properties
      }
      defaultLocale
      features
      isSubscriptionAuthEnabled
      isApplicationSubmissionAuthEnabled
      isOpportunitySubmissionAuthEnabled
      isBlogLoggedInAccessEnabled
      isBlogSubscriptionAccessEnabled
      blogCategories {
        id
        name
        subcategories {
          id
          name
          parentId
        }
      }
      eventsCategories {
        id
        name
        subcategories {
          id
          name
          parentId
        }
      }
      forum {
        id
        name
        moderators {
          id
          username
        }
      }
      themeOverrides {
        name
        values {
          key
          value
        }
      }
      backgroundImages {
        discoveryPage {
          value
          type
        }
        login {
          value
          type
        }
      }
      entitiesAccess {
        name
        indexRequiresAuth
        detailsRequiresAuth
      }
      accessPage {
        backgroundImage {
          value
          type
        }
        title
        description
        requestAccessEmail
        enabled
      }
      metaData {
        siteTitle
        siteTagline
        siteKeywords
      }
      email {
        value
        key
      }
      labelOverrides {
        activities {
          key
          value
        }
        activitiesPlural {
          key
          value
        }
        activitiesDescription {
          key
          value
        }
        opportunities {
          key
          value
        }
        loginDescription {
          key
          value
        }
        opportunitiesPlural {
          key
          value
        }
        opportunitiesDescription {
          key
          value
        }
        companies {
          key
          value
        }
        companiesPlural {
          key
          value
        }
        companiesDescription {
          key
          value
        }
        people {
          key
          value
        }
        peoplePlural {
          key
          value
        }
        peopleDescription {
          key
          value
        }
        blog {
          key
          value
        }
        blogPlural {
          key
          value
        }
        blogDescription {
          key
          value
        }
        forum {
          key
          value
        }
        forumPlural {
          key
          value
        }
        administration {
          key
          value
        }
        administrationPlural {
          key
          value
        }
        search {
          key
          value
        }
        searchPlural {
          key
          value
        }
        opportunitiesEmail {
          key
          value
        }
        discoverTitle {
          key
          value
        }
        discoverWelcome {
          key
          value
        }
        onboardingFinalStepText {
          key
          value
        }
        events {
          key
          value
        }
        eventsPlural {
          key
          value
        }
        eventsDescription {
          key
          value
        }
        speakers {
          key
          value
        }
        speakersPlural {
          key
          value
        }
      
      }
      logos {
        lightLogo {
          key
          value
          ratio
        }
        darkLogo {
          key
          value
          ratio
        }
      }
      leftFooterContent
      footerContent
      footerNavMenus {
        menus {
          title
          links {
            title
            link
            type
          }
        }
      }
    }
  }
`
const appConfigContext = React.createContext()

export const useAppConfig = () => useContext(appConfigContext)

export const useAppConfigRefresh = () => {
  const { data, loading, error, refetch } = useQuery(GET_CONFIG)
  return { refetch, data, loading, error }
}

export const AppConfigLoader = ({ children }) => {
  const user = useUser()
  const [code, setCode] = useState(null)
  const { data, loading, error } = useQuery(GET_CONFIG)

  // eslint-disable-next-line
  const memoUser = useMemo(() => user?.profile?.role, [user?.profile?.role])
  useEffect(() => {
    const validate = async () => {
      const { data: validCode } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/access/code-verify`,
        {
          headers: { AccessCode: localStorage.getItem('accessCode') },
        }
      )
      setCode(validCode)
    }
    validate()
  }, [])
  if (data && !loading && !error) {
    const defaultConfig = {
      defaultLocale: 'en_GB',
      eventsCategories: [],
      features: [],
    }
    const config = { ...defaultConfig, ...data.getAppConfig, validCode: code }

    return (
      <appConfigContext.Provider value={config}>
        {children?.(config)}
      </appConfigContext.Provider>
    )
  } else {
    return <InitialLoading />
  }
}
