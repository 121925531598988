import React from 'react'
import SectionHeader from './components/section-header'
import EventCreator from './event-creator'

const Index = () => {
  return (
    <>
      <SectionHeader />
      <EventCreator />
    </>
  )
}

export default Index
