import React from 'react'
import moment from 'moment'
import { find } from 'lodash'
import {
  CAWrapper,
  CardStyled,
  Title,
  Badge,
  Content,
  ImagesWrapper,
} from './styles/new-custom-attributes'
import { useIntl } from 'react-intl'
import { useCustomAttributes } from '_custom-attributes/hooks'

const attributesTypes = [
  'badges',
  'booleans',
  'dates',
  'emails',
  'files',
  'images',
  'locations',
  'phones',
  'texts',
  'urls',
]

const ConditionalCardWrapper = ({ isRendered, tooltipText, children }) =>
  isRendered ? (
    <CardStyled tooltipText={tooltipText}>{children}</CardStyled>
  ) : (
    <>{children}</>
  )

const resolveAttrValue = (type, attr, intl, customAttributes) => {
  const isAttributeImage = type === 'images'
  const { value, city } = attr

  const getAttrValue = (attr) => find(customAttributes, { key: attr.key })
  const attrValue = getAttrValue(attr)

  // TODO: change tooltip in CA, remove default image from badge,
  // remove creator from event state and add info from user profile directly
  let cardTitle = attrValue?.name
  let cardIcon = <></>
  let cardValue = value
if(type !== 'locations' || city === '' || !city)
  if (!value || (Array.isArray(value) && value.length < 1) || value === '')
    return <></>

  switch (type) {
    case 'locations':
      cardIcon = <i className="fas fa-map-marker-alt" />
      cardValue = <span>{city}</span>
      break
    case 'booleans':
      cardIcon = <i className="fas fa-flag" />
      break
    case 'dates':
      cardIcon = <i className="fas fa-calendar-day" />
      cardValue = <span>{moment(value).format('DD MMM YYYY')}</span>
      break
    case 'images':
      cardValue = (
        <ImagesWrapper isOnlyOne={value.length === 1}>
          {value.map((url) => (
            <div className="image-wrapper">
              <img src={url} alt="" />
            </div>
          ))}
        </ImagesWrapper>
      )
      break
    case 'urls':
      cardValue = (
        <a
          href={value}
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          {value}
        </a>
      )
      break
    case 'files':
      cardValue = (
        <>
          {attr?.value.map((file) => (
            <a
              className="file-link-wrapper"
              rel="noopener noreferrer"
              target="_blank"
              href={file?.url}
            >
              <i class="fas fa-file-pdf"></i>
              <p>
                {file?.name.length > 12
                  ? `${file?.name.substring(0, 12)}...${file?.extension}`
                  : `${file?.name}.${file?.extension}`}
              </p>
              {/* {intl.formatMessage({
            id: 'details.customattribute.typefile.button.download',
          })} */}
            </a>
          ))}
        </>
      )
      break
    case 'badges':
      cardValue =
        !value || value.length === 0 ? null : (
          <>
            {attrValue?.badges.map((badge) => {
              const { icon, name, key } = badge
              const isBadgeSelected = attr.value.includes(badge.key)
              return isBadgeSelected ? (
                <Badge key={key}>
                  <img src={icon} alt="badge" />
                  <span className="badge-name">{name}</span>
                </Badge>
              ) : (
                <></>
              )
            })}
          </>
        )
      break
    default:
      break
  }

  return (
    <>
      {cardValue && (
        <ConditionalCardWrapper
          tooltipText={attrValue?.description || ''}
          isRendered={!isAttributeImage}
        >
          <div className={`content-wrapper ${type}`}>
            <Title className={`title-${type || ''}`}>
              {cardTitle}
              {isAttributeImage && ':'}
            </Title>
            <Content type={type} className={type}>
              {cardIcon}
              {cardValue}
            </Content>
          </div>
        </ConditionalCardWrapper>
      )}
    </>
  )
}

const CustomAttributes = ({ attributes }) => {
  const intl = useIntl()

  const { attributes: customAttributes } = useCustomAttributes('events')

  return (
    <>
      <CAWrapper>
        {attributesTypes.map((type) => {
          if (!attributes[type] || attributes[type].length === 0) return <></>

          return attributes[type].map((attr) => (
            <> {resolveAttrValue(type, attr, intl, customAttributes)}</>
          ))
        })}
      </CAWrapper>
    </>
  )
}

export default CustomAttributes
