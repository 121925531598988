import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useMenuItems } from 'menu-builder/menuItems'
import { FormattedMessage } from 'react-intl'
import { gql, useMutation } from '@apollo/client'
import { StyledDiv, ModalDiv } from './styles'
import IconPicker from "_components/iconpicker"

export const MenuBuilder = () => {
  const menuItems = useMenuItems(true)
  const [updateMenuItem] = useMutation(gql`
    mutation UpdateMenuItem($menuItem: MenuConfigItemInput) {
      updateMenuItem(menuItem: $menuItem) {
        key
      }
    }
  `)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const closeModal = () => {
    setModalIsOpen(false)
    setLinkFormValues({})
  }

  const [deleteMenuItem] = useMutation(gql`
    mutation DeleteMenuItem($key: String) {
      deleteMenuItem(key: $key) {
        key
      }
    }
  `)

  const [linkFormValues, setLinkFormValues] = useState({})

  const editButtonHandler = ({ title, url, key, type }) => {
    setLinkFormValues({ title, url, key, type })
    setModalIsOpen(true)
  }

  function linksToHideInputsOn() {
    return linkFormValues.type === 'customPage' || typeof linkFormValues.type  === 'undefined'
  }


  const saveLink = () => {

    let menuItem = {
      key: linkFormValues.key || linkFormValues.url + new Date().getTime(),
      icon: linkFormValues.icon,
    }

    if (!linksToHideInputsOn()) {
      menuItem.title = linkFormValues.title
      menuItem.url = linkFormValues.url
    }

    updateMenuItem({
      variables: {
        menuItem: menuItem
      },
      refetchQueries: ['GetAppConfig'],
    })
    closeModal()
    setLinkFormValues({})
  }

  const [disabled, setDisabled] = useState(false)

  const valueChange = (newValue) => {
    const obj = {...linkFormValues, ...newValue}
    setLinkFormValues(obj)
    disableSaveLink(obj)
  }

  const disableSaveLink = (obj) => {
    const inputsEmpty = obj.title && obj.url ? false : true
    const disabled = !linksToHideInputsOn() && inputsEmpty
    setDisabled(disabled)
  }

  useEffect(() => {
    disableSaveLink(linkFormValues)
  })

  return (
    <StyledDiv>
      <h4>
        <FormattedMessage id="settings.mainMenu" defaultMessage="Main Menu" />
      </h4>
      <div className={'labels'}>
        <span>
          <FormattedMessage id={'public'} defaultMessage={'public'} />
        </span>
        <span>
          <FormattedMessage id={'member'} defaultMessage={'member'} />
        </span>
        <span>
          <FormattedMessage id={'edit'} defaultMessage={'edit'} />
        </span>
      </div>
      <div>
        {menuItems.map((m, index) => (
          <MenuItem
            menuItem={m}
            updateMenuItem={updateMenuItem}
            index={index}
            allItems={menuItems}
            editButtonHandler={editButtonHandler}
          />
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        style={{
          content: {
            background: 'var(--background',
            width: 'min(100%,450px)',
            padding: '40px 32px',
            height: '600px',
            top: '50%',
            left: '50%',
            right: 'auto',
            borderRadius: '10px',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <ModalDiv>
          <div>
            <h4>
              {linkFormValues.key ? (
                <FormattedMessage id="editLink" defaultMessage="edit link" />
              ) : (
                <FormattedMessage id="newLink" defaultMessage="new link" />
              )}
            </h4>
            <div className="inputFields">
              <div
                hidden={linksToHideInputsOn()}
                className={'inputFieldWrapper'}
              >
                <span>
                  <FormattedMessage id="title" defaultMessage="title" />
                </span>
                <input
                  value={linkFormValues.title}
                  onChange={e => valueChange({title: e.target.value})}
                />
              </div>
              <div
                hidden={linksToHideInputsOn()}
                className={'inputFieldWrapper'}
              >
                <span>URL</span>
                <input
                  value={linkFormValues.url}
                  onChange={e => valueChange({url: e.target.value})}
                />
              </div>
              <IconPicker onIconPick={e => valueChange({icon: e})}/>
            </div>
          </div>
          <div className={'buttons'}>
            <button className={'riot-btn secondary'} onClick={closeModal}>
              <FormattedMessage id="cancel" defaultMessage="cancel" />
            </button>
            <button
              hidden={!linkFormValues.key}
              className={'riot-btn secondary'}
              onClick={() => {
                deleteMenuItem({
                  variables: { key: linkFormValues.key },
                  refetchQueries: ['GetAppConfig'],
                })
                closeModal()
              }}
            >
              <FormattedMessage id="delete" defaultMessage="delete" />
            </button>
            <div />
            <button
              onClick={() => saveLink()}
              className={'riot-btn secondary'}
              disabled={disabled}
            >
              <FormattedMessage id="save" defaultMessage="save" />
            </button>
          </div>
        </ModalDiv>
      </Modal>
      <button className="addLinkButton" onClick={() => editButtonHandler({type: "addLink"})}>
        <i className="fa fa-plus"></i>
        <span>
          <FormattedMessage id="settings.addLink" defaultMessage="add link" />
        </span>
      </button>
    </StyledDiv>
  )
}

const MenuItem = ({
  menuItem,
  updateMenuItem,
  allItems,
  index,
  editButtonHandler,
}) => {
  const { key, label, icon: Icon, visibleLoggedIn, visibleLoggedOut } = menuItem
  const update = changes =>
    updateMenuItem({
      variables: {
        menuItem: { key, ...changes },
      },
      refetchQueries: ['GetAppConfig'],
    })

  const [updateMenuItems] = useMutation(gql`
    mutation UpdateMenuItems($menuItems: [MenuConfigItemInput]) {
      updateMenuItems(menuItems: $menuItems) {
        key
      }
    }
  `)

  const moveItem = toIndex => {
    if (toIndex < 0) return
    const arr = allItems.slice()
    arr.splice(index, 1)
    arr.splice(toIndex, 0, menuItem)
    updateMenuItems({
      variables: {
        menuItems: arr.map(({ key }, index) => ({ key, index })),
      },
      refetchQueries: ['GetAppConfig'],
    })
  }

  const isLink = menuItem.type === 'menuBuilderItem'

  return (
    <div className={'menuItem'}>
      <div className={'arrows'}>
        <button onClick={() => moveItem(index - 1)}>
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </button>
        <button onClick={() => moveItem(index + 1)}>
          <i className="fa fa-arrow-down" aria-hidden="true"></i>
        </button>
      </div>

      <Icon className={'icon'} />
      <div className="description">
        <span className="label">{label}</span>
        <span css={{ opacity: 0.7 }}>
          {isLink ? (
            <FormattedMessage id="link" defaultMessage="link" />
          ) : (
            <FormattedMessage id="page" defaultMessage="page" />
          )}
        </span>
      </div>
      <input
        onClick={() => update({ visibleLoggedOut: !visibleLoggedOut })}
        checked={visibleLoggedOut}
        type="checkbox"
      />
      <input
        onClick={() => update({ visibleLoggedIn: !visibleLoggedIn })}
        checked={visibleLoggedIn}
        type="checkbox"
      />
      <button onClick={() => editButtonHandler(menuItem)}>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </button>
    </div>
  )
}
