import styled from '@emotion/styled'
import { Badge } from '@patternfly/react-core'

export const ActivityIndicator = styled(Badge)`
  background-color: var(--flavor);
  padding: 5px 15px;
  position: absolute;
  top: 5px;
  left: 5px;
  span,
  i {
    font-size: 16px;
  }
  i {
    margin-right: 10px;
  }
`
