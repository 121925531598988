import { css } from '@emotion/core'

export const pagebuilderStyles = () => css`
.fr-toolbar .fr-command.fr-btn svg path, 
.fr-popup .fr-command.fr-btn svg path, 
.fr-modal .fr-command.fr-btn svg path {
  fill: var(--foreground);
}

.fr-toolbar.fr-inline {
  background-color: var(--background);
}

.fp-panel {
  padding: 20px 20px 0;
  position: absolute;
  width: calc(75% - 40px);
  height: calc(100%);
  right: -75%;
  box-sizing: border-box;
  border-left: solid 1px #ccc;
  display: flex;
  flex-direction: column
}

.fp-panel.fp-visible {
  right: 0;
  margin-right: 0
}

.fp-panel .fp-panel-head {
  margin: 0 10px 20px
}

.fp-panel .fp-panel-head h2 {
  margin-top: 0
}

.fp-panel .fp-panel-head .fp-panel-filter a {
  margin: 0;
  padding: 10px;
  min-width: 0;
  width: auto;
  color: #444;
  display: inline-block;
  cursor: pointer;
  transition: background-color .2s linear 0s, color .2s linear 0s;
  user-select: none
}

.fp-panel .fp-panel-head .fp-panel-filter a.fp-active {
  color: #fff;
  background-color: #2196f3
}

.fp-panel .fp-panel-head .fp-panel-filter a.fp-active:hover {
  color: #fff;
  background-color: #0d8aee
}

.fp-panel .fp-panel-head .fp-panel-filter a:hover {
  color: #444;
  background-color: #e1e1e1
}

.fp-panel .fp-panel-head .fp-panel-filter a.fp-hidden {
  display: none
}

.fp-panel .fp-panel-body {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow: scroll
}

.fp-panel .fp-panel-body .fp-panel-panel .fp-panel-blocks-wrapper {
  display: none;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  width: 100%
}

.fp-panel .fp-panel-body .fp-panel-panel .fp-panel-blocks-wrapper.fp-visible {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.fp-panel .fp-panel-body .fp-panel-panel .fp-panel-blocks-wrapper img {
  width: 100%;
  border: solid 1px #efefef;
  margin: 5px 0;
  cursor: pointer;
  transition: box-shadow .1s linear, border .1s linear
}

.fp-panel .fp-panel-body .fp-panel-panel .fp-panel-blocks-wrapper img:hover {
  border: solid 1px #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
}

img.fr-rounded,
.fr-img-caption.fr-rounded img {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box
}

body.fp-panel-visible {
  overflow: hidden
}

.fp-share-popup-wrap {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  top: calc(0px);
  left: calc(0px);
}

.fp-share-popup-wrap.fp-hidden {
  display: none
}

.fp-share-popup-wrap .fp-share-popup {
  width: calc(350px);
  height: calc(150px);
  background: #fff;
  border-radius: 3px;
  margin-left: calc(50vw - 125px);
  margin-top: calc(50vh - 75px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
}

.fp-share-popup-wrap .fp-share-popup .fp-popup-close-toolbar {
  padding: calc(3px) calc(5px);
  line-height: calc(30px);
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)
}

.fp-share-popup-wrap .fp-share-popup .fp-popup-close-toolbar span {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #373737;
  font-weight: 700
}

.fp-share-popup-wrap .fp-share-popup .fp-popup-text-row {
  display: flex;
  padding: calc(0px) calc(10px);
  line-height: calc(150px)
}

.fp-share-popup-wrap .fp-share-popup .fp-popup-text-row span {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center
}

.fp-share-popup-wrap .fp-share-popup svg {
  cursor: pointer
}

.fp-btn-group {
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  gap: 10px;
  padding: 1em 1em 1em 0px;
  background-color: var(--background);
}

.page-builder-scope.fp-full-view .fp-btn-group+.fp-btn-group {
  transform: translateX(0px);
  max-width: 100%;
  opacity: 1;
  z-index: 1;
}

.fp-btn-group+.fp-btn-group {
  z-index: -1;
  opacity: 0;
  margin-left: 10px;
  transform: translateX(-124px);
  max-width: 0%;
  transition: 0.4s;
}

.fp-btn {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  --size: 38px;
  height: var(--size); width: var(--size);
  border: solid 0px #00000000;
  border-radius: 0em;
  background-color: transparent;
  
  transition: 0.4s;
}

.fp-btn:hover,
.fp-btn-group .fp-btn.fp-active {
  border-radius: 1.5em;
  border: solid 2px var(--flavor);
}

.fp-btn:focus {
  outline: 0
}

.fp-btn:hover svg,
.fp-btn-group .fp-btn.fp-active svg {
  fill: var(--flavor);
}

.fp-btn svg {
  width: 20px;
  height: 20px;
  margin: 9px auto;
  fill: var(--foreground);
  -webkit-transition: fill .2s linear;
  -moz-transition: fill .2s linear;
  -ms-transition: fill .2s linear;
  -o-transition: fill .2s linear;
  transition: fill .2s linear
}

.fp-btn.fp-hidden {
  display: none
}

.fp-btn.fp-active svg {
  fill: var(--flavor);
}

.fp-btn.fp-disabled svg {
  fill: var(--shade);
}

.fp-btn.fr-btn-type-html {
  padding: 0 15px
}

.fp-btn.fp-remove-btn:hover svg {
  fill: #f44336
}

.fp-download-btn {
  background: #2196f3;
  color: #fff;
  display: flex;
  padding: 0 25px
}

.fp-download-btn svg {
  fill: #fff
}

.fp-download-btn span {
  margin-left: 5px
}

.fp-download-btn:hover {
  background: #1d84d6
}

.fp-download-btn:disabled {
  opacity: .5;
  cursor: auto
}

.fp-download-btn:disabled:hover {
  background-color: #2196f3
}

.fp-btn-zone {
  flex: 1
}

body.fp-dragging div.fp-block-toolbar button {
  border-radius: 1.5em;
  border: solid 2px var(--flavor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  --size: 38px;
  height: var(--size);
  width: var(--size);
  background-color: transparent;
  transition: 0.4s;
}


body.fp-dragging div.fp-block-toolbar {
  display: none !important
}

div.fp-block-toolbar {
  position: fixed;
  display: flex;
  gap: 10px;

  clip-path: circle(0% at 0px 0px);
  pointer-events: none;
  
  opacity: .85;
  transition: .4s ease-in-out;
}

div.fp-block-toolbar:hover {
  opacity: 1
}

div.fp-block-toolbar.fp-visible {
  clip-path: circle(200% at 0px 0px);
  pointer-events: auto;
}

div.fp-page-toolbar {
  position: relative;
  display: flex;
}

div.fp-page-toolbar #fp-rp-zone {
  display: flex;
  justify-content: flex-end
}

div.fp-page-toolbar #fp-lp-zone {
  display: flex;
  justify-content: flex-start
}

div.fp-page-toolbar #fp-cp-zone {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.fp-pageTop-toolbar {
  position: relative;
  display: none;
}

div.fp-pageTop-toolbar #fp-tp-zone {
  display: flex;
  justify-content: flex-start
}

div.fp-pageTop-toolbar #fp-tp-zone button {
  height: 90px
}

div.fp-pageTop-toolbar #fp-tp-zone button svg {
  width: 70px;
  height: 70px
}

.fp-element {
  height: 100%;
  width: 100%;
  transition: transform .25s linear;
  transform-origin: 0 0;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px !important;
}

.fp-element.fp-view-small {
  padding: 10px
}

.fp-element>iframe {
  width: 100%;
  height: 100%;
  border: none
}

body.page-builder-scope > .fr-toolbar {
  position: fixed !important;
  top: 10px !important;
  bottom: auto !important;
  left: 190px !important;
  width: calc(100% - 200px);
  border-radius: 10px;
}

body.page-builder-scope .fr-toolbar.fr-sticky-off {
  position: relative !important;
  top: 0px !important;
  left: 0px !important;
  width: 100%
}

body.page-builder-scope.fp-add-view section:nth-of-type(1) {
  margin-top: 0px;
}

body.page-builder-scope.fp-full-view section:nth-of-type(1) {
  margin-top: 100px;
}

body.page-builder-scope.fp-add-view .fr-toolbar {
  display: none !important; 
}

body.fp-add-view {
  background: #dedede
}

body.fp-add-view .fp-element {
  transform-origin: 25px 25px;
  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%);
}

.page-wrapper {
  transition: .4s;
}

body.fp-add-view .open {
  --page: clamp(1250px, 90vw, 2700px) !important;
}

body.fp-full-view .fp-element {
  padding: 0px !important;
}

body.fp-add-view .fp-container.fp-no-block .fp-element {
  display: none
}

body.fp-add-view .fp-container.fp-no-block .fp-drop-zone {
  display: flex
}

body.fp-full-view .fp-element>iframe {
  height: 100% !important
}

body .fp-license-error {
  text-align: center
}

.fp-container {
  position: relative;
  box-sizing: border-box;
}

.fp-container .fp-drop-zone {
  display: none;
  width: 25%;
  height: 150px;
  margin: 20px;
  float: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.page-builder-scope h1,
.page-builder-scope h2 {
  margin-bottom: 20px;
}

.page-builder-scope h1.text-center {
  text-align: left;
}

.page-builder-scope p {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.fp-container .fp-drop-zone p+p {
  margin-top: 5px;
  opacity: .75
}

.fp-container .fp-drop-zone #fp-drop-visual {
  position: absolute;
  top: 100%;
  margin-top: -15px;
  overflow: visible;
  width: 50%;
  right: -35px
}

.fp-container .fp-drop-zone #fp-drop-visual svg {
  width: 100%;
  fill: #919191
}

.fp-container .fp-drop-zone.fp-hover {
  background: #fff;
  border: solid 3px #2196f3
}

/*custom*/
.svg-inline--fa.fa-w-8 {
  width: 0.5em;
}

.fdb-block {
  position: relative;
  margin-bottom: 10px;
  padding: 40px  0px;
  background: transparent;
  outline: 0px solid var(--shade);
  transition: .2s;
}

[draggable="true"] {
  cursor: pointer;
}

[draggable="true"] h1, 
[draggable="true"] h2, 
[draggable="true"] h3, 
[draggable="true"] h4, 
[draggable="true"] h5, 
[draggable="true"] h6, 
[draggable="true"] p {
  cursor: text;
}

.fdb-block:hover {
  outline: 2px solid var(--shade);
  background: var(--shade-faint);
}

.page-builder-wrapper .fdb-block:hover {
  outline: none;
  background: transparent;
}

.page-builder-wrapper [draggable="true"] {
  cursor: inherit;
}

.page-builder-wrapper h1,
.page-builder-wrapper h2,
.page-builder-wrapper h3,
.page-builder-wrapper h4,
.page-builder-wrapper h5,
.page-builder-wrapper h6,
.page-builder-wrapper p {
  cursor: inherit;
}

.fdb-block .container {
  position: relative;
  z-index: 2;
  padding: 0px;
  margin: 0px;
  margin-top: 0px !important;
  background-repeat: no-repeat;
}

.bg-dark {
 background: var(--shade) !important;
}

.page-builder-scope .shadow {
  padding: 2em;
  background: var(--background);
  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%) !important;
}

.bg-dark {
 background: var(--shade) !important;
}

.page-builder-scope .shadow {
  padding: 2em;
  background: var(--background);
  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%) !important;
}

.fdb-block::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0px; left: 0px;
  background-color: var(--flavor);
  opacity: 0.4;
  width: 100%; height: 100%;
  clip-path: circle(0% at 0px 0px);
  border: solid 3px #dedede00;
  transition: .8s;
  border-radius: 1.5em;
}

.open .fdb-block:hover {
  outline: solid 3px var(--flavor);
  transition: .4s;
}

.fdb-block.fp-fragging {
  background-color: var(--shade);
}

.fdb-block img {
  width: 400px;
}

img.fdb-icon {
  width: 50px;
}

[data-block-type="widgets"] .container img {
  width: 100%;
}

.page-builder-scope a.btn:hover,
.page-builder-scope a.btn.btn-secondary:hover,
.page-builder-scope a.btn:focus,
.page-builder-scope a.btn.btn-secondary:focus {
  --fg: var(--flavor-contrast);
  background: var(--flavor);
  color: var(--flavor-contrast);
  fill: var(--flavor-contrast);
  background-image: none;
}

.page-builder-scope .btn {
  --fg: var(--flavor-contrast);
  background: var(--flavor);
  color: var(--flavor-contrast);
  fill: var(--flavor-contrast);
}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: 0 0 0 .0 !important;
}

.btn {
  border-radius: 30px;
  border: 2px solid var(--flavor);
  cursor: pointer;

  padding: 6px 25px;
  height: 40px;
  max-height: 42px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  --fg: var(--foreground);
  color: var(--fg);
  background: transparent !important;
  transition: 0.2s ease-in-out;
}

.btn:hover:not(.disabled) {
  background: var(--flavor) !important;
  color: var(--flavor-contrast) !important;
  --fg: var(--flavor-contrast);
}

.btn:active:not(.disabled) {
  opacity: 0.6;
  transition: 0.1s ease-in-out;
}


.page-builder-scope .row {
  justify-content: space-between;
}

html {
  font-family: sans-serif;
}

.fp-page-toolbar {
  background-color: var(--background) !important;
  margin-bottom: 25px;
}

.fp-page-toolbar + .fp-page-toolbar {
  display: none;
}

.fp-element {
  background-color: var(--background);
  height: auto !important;
  transition: .4s ease-in-out;
}

.fp-element.fp-view-small {
  background-color: var(--shade);
}

.fp-element.fp-view-small #froala-pages {
  background-color: var(--background);
  padding: 20px;
}

.fp-element>iframe {
  max-width: 1250px;
}

.fp-element>iframe,
.fp-element>iframe html,
.fp-element>iframe html body {
  height: 100% !important;
}

body.fp-add-view .fp-container.fp-no-block .fp-drop-zone {
  display: flex;
}


body.fp-add-view .fp-element {
  padding: 0px;
  transform: scale(1);
  overflow: hidden;
}

body.fp-add-view .fp-drop-zone,
body.fp-add-view .fp-element {
  margin: 0px;
  width: 200%;
  transition: .4s;
}

.fp-full-view .fp-drop-zone,
.fp-full-view .fp-element {
  width: 100%;
  width: 100%;
}

.fp-full-view .fp-panel {
  width: 0%;
  padding: 0px;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%);
}

.fp-full-view .fp-panel>* {
  opacity: 0;
}

.fp-panel>* {
  opacity: 1;
  transition: .4s;
}

.fp-panel .fp-panel-body {
  overflow: hidden;
}

.container {
  max-width: 100%;
}

iframe {
  transition: 0.4s;
}

.fp-no-block.fp-no-sections {
  display: none;
  width: 100%;
  height: 100%;
  background: var(--shade);
  border-radius: 15px;
  padding: 2em;
  margin: auto;
  cursor: pointer;
  transition: .4s;
}

.fp-no-block.fp-no-sections h1 {
  font-size: 4rem;
  font-weight: 900;
  max-width: 17ch;
  margin-bottom: 15px;
}

.fp-no-block.fp-no-sections:hover {
  color: var(--background) !important;
  background: var(--flavor);
}

.fp-no-block.fp-no-sections.fp-visible {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.page-builder-scope.fp-full-view .fp-container {
  gap: 0px;
}

.text-center p {
  margin: auto;
}

.fp-container {
  --border-seperator: solid 15px var(--flavor);
  border-bottom: var(--border-seperator);
  padding: 0px 0px 100px;

  animation-duration: 0.8s;
  animation-name: identifier;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 30px;

  transition: .4s;
}

@keyframes identifier {
  from {
    border-bottom: solid 0px var(--flavor);
    padding: 0px;
  }

  to {
    border-bottom: var(--border-seperator);
    padding: 10px;
  }
}

.fp-panel {
  position: relative;
  order: -5;
  background-color: var(--background);
  font-family: inherit;
  border: none;
  height: auto;
  width: 100%;
  right: 0px;
  padding: 2em;
  border-radius: 1em;
  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%);
  border-radius: 10px;
  overflow: hidden;
  transition: .4s;
}

.fp-panel .fp-panel-head .fp-panel-filter a,
.fp-container .fp-drop-zone,
.fp-panel .fp-panel-head .fp-panel-filter a+a {
  transition: .4s;
  background-color: var(--background);
  color: var(--foreground);
  padding: 10px;
  border-radius: 0px;
  border: solid 0px transparent;
  border-bottom: solid 0px var(--flavor);

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  position: relative;

  text-transform: lowercase;
}

.fp-panel .fp-panel-head .fp-panel-filter a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0%;
  height: 1.6px;
  background-color: var(--flavor);
  transition: 0.4s;
}


.fp-panel .fp-panel-head .fp-panel-filter a:hover:after,
.fp-panel .fp-panel-head .fp-panel-filter a.fp-active:after {
  width: 100%;
}

.fp-container .fp-drop-zone {
  display: none;
  background: transparent;
  width: 100%;
  height: auto;
  margin: 0px;
  border-radius: 25px;
  justify-content: flex-start;
  align-items: flex-start;
}

.fp-container .fp-drop-zone h2 {
  margin-bottom: 15px;
  font-size: 4rem;
  max-width: 17ch;
}

.fp-drop-zone #fp-drop-visual {
  display: none;
}

.fp-container .fp-drop-zone.fp-hover {
  background-color: var(--flavor);
  border: solid 0px var(--flavor);
  padding: 2em;
}

.fp-panel .fp-panel-head .fp-panel-filter a:hover {
background-color: VAR(--background);
}

.fp-panel .fp-panel-head .fp-panel-filter a:active {
  color: var(--flavor);
}

.fp-panel .fp-panel-head .fp-panel-filter a.fp-active {
  background: transparent;
  color: var(--foreground);
}

.fp-panel .fp-panel-head .fp-panel-filter a.fp-active:hover {
  background: transparent;
  color: var(--foreground);
}

.fp-panel-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

body.page-builder-scope {
  margin: 0;
  font-family: Lato, Helvetica, Arial;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: var(--foreground);
  background-color: var(--background);
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

.btn:not(:disabled):not(.disabled),
summary {
  cursor: pointer;
  padding: 6px 25px;
  border: 2px solid var(--flavor);
  color: var(--flavor-contrast);
  fill: var(--flavor-contrast);
  border-radius: 25px;
  margin: 0 14px 10px 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  border-color: var(--flavor);
  background: var(--flavor);
  font-weight: 700;
}


.btn {
  width: max-content;
}

a.btn:not(:disabled):not(.disabled) {
  color: var(--foreground);
}

.btn:not(:disabled):not(.disabled):hover {
  background: transparent;
  color: var(--foreground);
}


b,
strong {
  font-weight: 900 !important;
}

small {
  font-size: 80%;
}

a {
  color: #329ef7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.fr-view P {
  max-width: 100%;
}


.justify-content-center div {
  text-align: center;
}

.blockquote,
hr {
  margin-bottom: 1rem;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin-top: 0rem;
  color: var(--foreground);
}

.lead {
  font-size: inherit !important;
  font-weight: inherit !important;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  font-weight: 300;
  font-size: inherit;
}

p {
  color: var(--foreground);
}

`
