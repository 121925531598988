/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQuery, gql } from '@apollo/client'
import { useAppConfig } from '_application'
import { Link } from 'react-router-dom'
import IndyButton from '../../_components/indyButton'
import { notification } from 'antd'
import { useUser } from '_security/session-hooks'

const GET_REMAINING_OPPORTUNITIES_COUNT = gql`
  query GetRemainingOpportunityCount {
    getRemainingOpportunityCount
  }
`

export const AddNewOpportunityButton = () => {
  const intl = useIntl()
  const { isOpportunitySubmissionAuthEnabled } = useAppConfig()
  const user = useUser()
  const isAdmin = user?.profile?.role === "admin"

  const {
    data: remainingOpportunities,
    refetch,
    loading: remainingOpportunitiesCountLoading,
  } = useQuery(GET_REMAINING_OPPORTUNITIES_COUNT)
  const remainingOpportunitiesCount = !remainingOpportunitiesCountLoading
    ? remainingOpportunities?.getRemainingOpportunityCount || 0
    : null

  useEffect(() => {
    refetch()
  }, [])
 
  const isButtonDisabled = 
    (remainingOpportunitiesCount <= 0 && isOpportunitySubmissionAuthEnabled) && !isAdmin

  const disabledCallback = (e) => {
    e.preventDefault()
    const notify = {
      message: intl.formatMessage({
        id: 'opportunitysubmission.notenoughcredits.message',
      }),
      description: intl.formatMessage({
        id: 'opportunitysubmission.notenoughcredits.description',
      }),
      type: 'warining',
    }
    notification.warning(notify)
  }

  return (
    <Link onClick={(e) => isButtonDisabled ? disabledCallback(e) : {}} id={'new-opportunity'} to={'/opportunities/list/new'}>
      <IndyButton>
        <i className={`fas fa-plus`} alt="add new opportunity" />
        {intl.formatMessage({ id: 'companies.createnew' })}
      </IndyButton>
    </Link>
  )
}

export default AddNewOpportunityButton
