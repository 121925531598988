import React from 'react'
import { MainWrapper } from './styles/mainContent'
import ApplicantInfo from '../shared/applicantInfo'
import { ListItemStyled } from '../styles'
import { StyledLink } from '../shared/styled/applicantInfo'
import { Messages } from './messaging'
import { ApplicationContent } from '../shared/application-content'

function MainContent({ application }) {
  const {
    creator: { id },
  } = application

  return (
    <MainWrapper>
      <ListItemStyled isDetailView>
        <ApplicantInfo application={application} isDetailView />
        <StyledLink
          to={{
            pathname: `/people/list/${id}`,
          }}
        >
          <button className="view-profile-btn">View profile</button>
        </StyledLink>
      </ListItemStyled>

      <div className="content-wrapper">
        <Messages
          channelType={'application-applicant-messaging'}
          applicationId={application?._id}
        />
        <ApplicationContent application={application} />
      </div>
    </MainWrapper>
  )
}

export default MainContent
