import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { MainStyled, Content } from './styles/index'

import translations from './translations'
import BreakLine from '../break-line'
import { useUser } from '_security/session-hooks'

import { validate } from './validation'

const ReceiveApplicationBy = ({ formData, changeFormProperty, isRequired = true }) => {

  const [manageBy, setManageBy] = useState(formData?.receiveApplicationBy?.type || "app")
  const [input, setInput] = useState(formData?.receiveApplicationBy?.value)
  const [error, setError] = useState()

  const { profile } = useUser()
  const intl = useIntl()

  const translate = (id) => intl.formatMessage({ id })

  const handleSelect = (value) => {
    setManageBy(value)
    setInput('')
  }

  const handleSelectFormData = () => {
    const error = validate({ input, manageBy })
    if (!error)
      changeFormProperty({
        receiveApplicationBy: {
          type: manageBy, value: input, error: false
        }
      })
    else {
      setError(error)
      changeFormProperty({
        receiveApplicationBy: {
          error: true
        }
      })
    }
  }

  useEffect(() => {
    handleSelectFormData()
    setError(null)
  }, [manageBy])

  const renderInput = () => {
    const labels = manageBy === "ats" ?
      {
        info: translations.input.ats.info,
        placeholder: translations.input.ats.placeholder
      }
      :
      {
        info: translations.input.app.info,
        placeholder: `${profile?.email}`
      }

    return (
      <div className="section">
        <span className='info'>
          {`${translate(labels.info)}:`}
        </span>
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onFocus={() => setError(null)}
          onBlur={handleSelectFormData}
          placeholder={translate(labels.placeholder)}
        />
        {error && <span className="error">
          {translate(error)}
        </span>}
      </div>
    )
  }

  return (
    <MainStyled>
      <span className="title">
        {translate(translations.title)}
        {isRequired && <span className="required-star">*</span>}
      </span>
      <Content>
        <div className="section">
          <span className="info">
            {`${translate(translations.selector.title)}:`}
          </span>
          <select onChange={(e) => handleSelect(e?.target?.value)}>
            <option
              selected={!manageBy}
              hidden
              value="default">
              {translate(translations.selector.options.default)}
            </option>
            <option
              selected={manageBy === "app"}
              value="app">
              {translate(translations.selector.options.thisPlatform)}
            </option>
            <option
              selected={manageBy === "ats"}
              value="ats">
              {translate(translations.selector.options.externalWebsite)}
            </option>
          </select>
        </div>
        {manageBy && renderInput()}
      </Content>
      <BreakLine />
    </MainStyled>
  )
}

export default ReceiveApplicationBy