import styled from '@emotion/styled'

const ButtonStyled = styled.div`
  input[type='file'] {
    cursor: pointer;
    font-size: 0;
  }

  /* .layer {
    border-radius: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    cursor: pointer !important;
  } */
  .admin {
    min-width: 100px;
    min-height: 38px;
    border: 2px solid var(--flavor);

    &:hover {
      background-color: transparent;
      color: var(--foreground);
    }
  }
  button {
    border: none;
    border-radius: 6px;
    background-color: var(--flavor);
    color: var(--flavor-contrast);
    font-size: 12px;
    font-weight: 600;
    border-radius: 25px;
    transition: 0.3s ease-in-out;
    position: relative;
    /* border: 2px solid var(--flavor); */
    &:after {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0px 19px 29px -13px rgba(0, 0, 0, 0.2);
    }
    &:hover {
      background-color: transparent;
      /* border: 2px solid var(--flavor);
       */
      color: var(--foreground);
    }
  }

  .upload-regular {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 700;
    font-size: 14px;
    background-color: var(--background);
    color: var(--foreground);
    padding: 8px 18px;

    i {
      font-size: 14px;
      color: var(--foreground);
      margin: 0;
      padding: 0;
      margin-right: 6px;
    }

    .anticon-camera {
      margin-right: 5px;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }

    .button-name {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      color: var(--foreground);
    }

    &:hover {
      background-color: var(--shade);
      cursor: pointer;
    }

    .no-image {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      z-index: 3;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .upload-circular {
    position: absolute;
    right: 0;
    bottom: 0;

    background-color: var(--background);
    color: var(--foreground);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 100%;
    height: 35px;
    width: 35px;
    padding: 0px;
    font-size: 16px;
    cursor: pointer;

    i {
      font-size: 14px;
      transition: 0.2s ease-in-out;
      margin: 0;
      padding: 0;
    }

    /* .anticon-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      cursor: pointer !important;
    } */

    .no-image {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      z-index: 3;
      opacity: 0;
      background: var(--shade);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input {
      transition: 0.8s;
      border-radius: 100%;
      transform: scale(1);
    }

    &:hover {
      background: var(--shade);
      transform: scale(0.9);
      /* box-shadow: 0 0 0pt 2pt var(--foreground) !important; */
      i {
        transform: scale(1.1);
      }
      input {
        transform: scale(1.4);
        transition: 0.1s;
      }
    }
    &:active {
      transform: scale(0.7);
      transition: 0.1s ease-in-out;
      input {
        transform: scale(8);
        transition: 0.1s;
      }
    }
  }
`

export default ButtonStyled
