import styled from '@emotion/styled'
import border from '_utils/css-border'
import { css } from '@emotion/react'
import { tablet } from '_utils/responsive-queries'

export const MainContainer = styled.div`
  position: relative;
  i.notification-icon {
    color: ${({ isScrollTop, location }) =>
      isScrollTop && (location === '/' || location === '/discover')
        ? 'var(--imgColor)'
        : 'var(--foreground)'};
    ${tablet} {
      color: var(--foreground);
    }
  }
`

export const ListWrapper = styled.div``

export const NotificationsWrapper = styled.div`
  display: flex;
  gap: var(--space-xs);
  margin-right: var(--space-xs);;
  transform: translateY(2px);

  button.notification-button {
    border: none;
    background-color: transparent;
  }
  i {
    position: relative;
    font-size: 25px;

    span.counter {
      font-size: 10px;
      font-weight: 400;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -5px;
      right: -12px;
      color: var(--flavor-contrast);
      background-color: var(--flavor);
      font-family: ${({ theme }) => theme.fonts.googleFont};
      padding: 0px 2px 2px;
    }
  }
`

export const ExpandedWindow = styled.div`
  ${border()}

  overflow-y: auto;
  max-height: 300px;
  width: 374px;
  position: absolute;
  top: 49px;
  right: 32px;
  padding: 20px 0 30px;
  border-radius: 10px;
  border-top-right-radius: 0;
  background-color: var(--background);
  overflow-x: hidden;
  ${tablet} {
    width: 250px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }

  ${({ isMobileView, type }) =>
    isMobileView &&
    css`
      transform: ${type === 'messages'
        ? 'translateX(-40%)'
        : 'translateX(-55%)'};
      left: 0;
    `}
`

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  padding: 0 18px;
  text-transform: capitalize;
`

export const ItemsList = styled.ul`
  list-style: none;
  padding: 0;
`

export const ShowMore = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--foreground);
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
`
export const NotificationIndicator = styled.div`
  ${({ openMenu }) =>
    openMenu &&
    css`
      display: none;
    `}

  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 100;
  background: rgb(199, 24, 25);
  border-radius: 50%;
  margin: 10px;
  height: 8px;
  width: 8px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s 2;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`
