import { useCallback, useEffect, useRef } from 'react'

export const useClickOutside = (onClose) => {
  const ref = useRef(null)
  const escapeListener = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )
  const clickListener = useCallback(
    (e) => {
      if (!ref.current?.contains(e.target)) {
        onClose()
      }
    },
    [onClose]
  )
  useEffect(() => {
    document.addEventListener('click', clickListener, { capture: true})
    document.addEventListener('keyup', escapeListener, { capture: true})
    return () => {
      document.removeEventListener('click', clickListener, { capture: true})
      document.removeEventListener('keyup', escapeListener, { capture: true})
    }
  }, [clickListener, escapeListener])
  return ref
}
