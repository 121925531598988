import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'
// import Tile from '_uikit/tile'

import profileThumbnail from 'resources/intercom.jpg'

const Container = styled.div`
  margin-bottom: 25px;
`

const TopArea = styled.div`
  padding-left: 50px;
`

const BackgroundContainer = styled.div`
  background-color: #e8eaeb;
  padding: 30px;
  border-radius: 6px;
`

const RecommendationRow = styled(Row)``

const TileCol = styled(Col)``

const DetailsCol = styled(Col)``

const BottomContainer = styled.div`
  margin-top: 5px;
`

const ThumbnailContainer = styled.div`
  position: relative;
  display: inline-block;
`

const SmallThumbnail = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
  display: inline-block;
`

const SmallName = styled.span``

const Text = styled.span`
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  font-size: 16px;
`

const OnlineIndicator = styled.div`
  width: 15px;
  height: 15px;
  background-color: #4dcea6;
  border-radius: 10px;
  border: 3px solid var(--background);
  position: absolute;
  right: 2.5px;
  bottom: 0px;
`

const Title = styled.h3`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 16px;
  font-weight: 600;
`

const InsightContainer = styled.div`
  margin-top: 10px;
`

const TopInsight = styled.span`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
`

const BottomInsight = styled.span`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
`

const ActionButton = styled.button`
  display: block;
  position: relative;
  margin-top: 10px;
  position: relative;
  border: 2px solid var(--flavor);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    top: 5px;
  }
`

const Highlight = styled.span`
  font-weight: 600;
  text-decoration: underline;
`

const Index = () => {
  return (
    <Container>
      <TopArea>
        <BackgroundContainer>
          <RecommendationRow>
            <TileCol></TileCol>
            <DetailsCol className="my-auto">
              <Title>Stein thought you should know about this activity.</Title>
              <InsightContainer>
                <TopInsight>
                  Irrum Lipsum was posted by <Highlight>Admin</Highlight> eight
                  days ago.
                </TopInsight>
                <BottomInsight>
                  It was recommended to you by your connection{' '}
                  <Highlight>Stein Myrseth</Highlight> just 1 hour ago.
                </BottomInsight>
                <ActionButton>Check it out!</ActionButton>
              </InsightContainer>
            </DetailsCol>
          </RecommendationRow>
        </BackgroundContainer>
      </TopArea>
      <BottomContainer>
        <SmallName>
          <ThumbnailContainer>
            <SmallThumbnail src={profileThumbnail} />
            {true ? <OnlineIndicator /> : <></>}
          </ThumbnailContainer>
          <Text>Andreas Skaalerud</Text>
        </SmallName>
      </BottomContainer>
    </Container>
  )
}

export default Index
