import React from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { EditInfoWrapper } from './styles'

const EditInfo = ({ editDetails: { authorName, authorSurname, date } }) => {
  const intl = useIntl()
  return (
    <EditInfoWrapper>
      <p className="label">
        {intl.formatMessage({
          id: 'edit.info.title',
        })}
      </p>
      <p className="author">
        {authorName} {authorSurname}
        <span className="date"> | {moment.unix(date / 1000).format('ll')}</span>
      </p>
    </EditInfoWrapper>
  )
}

export default EditInfo

