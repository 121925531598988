import React from 'react'
import PropTypes from 'prop-types'
import LoginRequiredModal from './login-required-modal'
import { useUserAccess } from './helpers'

const Props = {
  children: PropTypes.func.isRequired,
  showLoginPopup: PropTypes.bool,
}

export const RequiresLogin = ({ children, showLoginPopup }) => {
  const hasUserAccess = useUserAccess()
  if (hasUserAccess) {
    return typeof children === 'function' ? children() : children
  } else if (showLoginPopup) {
    return <LoginRequiredModal />
  } else {
    return null
  }
}

RequiresLogin.propTypes = Props

export default RequiresLogin
