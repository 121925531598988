import styled from '@emotion/styled'

const AddNewItemStyled = styled.li`
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  list-style: none;
  .circle-container {
    height: 30px;
    width: 30px;
    background-color: var(--foreground);
    border-radius: 50%;
    i {
      color: var(--background);
      font-size: 18px;
      line-height: 30px;
    }
  }
`

export default AddNewItemStyled
