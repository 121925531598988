import * as React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import styled from '@emotion/styled'
import FilterDropdown from '_components/filter-dropdown'
//import ToggleButton from 'components/indy/shared/ui-kit/buttons/toggle-button-with-name'
import Toggle from '_components/toggle'
import { history } from 'index'
import { useLocation } from 'react-router-dom'
import { useFilters } from '_utils/filters'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1570px;
  margin: 0 auto;
  @media (max-width: 991px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Props = {
  basePath: PropTypes.string.isRequired,
  filter: PropTypes.object,
  filterOptions: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
}

const FilterHeader = ({
  basePath,
  filter,
  filterOptions,
  onFilterChange,
  isFilterOff,
}) => {
  const { setFilter } = useFilters()

  const handleRedirect = (isMapView, basePath) => {
    const path = `${basePath}/${isMapView ? 'list' : 'map'}`
    history.push(path)
    setFilter(filter)
  }

  const isMapView = useLocation().pathname?.includes('/map')

  return (
    <Row col={12}>
      <Col>
        <Container>
          {!isFilterOff ? (
            <FilterDropdown
              filter={filter}
              options={filterOptions}
              onSelect={onFilterChange}
            />
          ) : (
            <div></div>
          )}

          <Toggle
            checked={isMapView}
            onClick={() => handleRedirect(isMapView, basePath)}
          />
        </Container>
      </Col>
    </Row>
  )
}
FilterHeader.propTypes = Props

export default FilterHeader
