import React from 'react'
import { FooterStyles } from './styled/footer'
import { useColorBucket } from '_colorBus/hooks'

const Footer = ({ children }) => {
  const [colorBucket] = useColorBucket('footer')

  return (
    <FooterStyles ref={colorBucket}>
      {children}
    </FooterStyles>
  )
}

export default Footer
