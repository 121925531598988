/*@flow*/
import React from 'react'
import { css } from '@emotion/core'
import { FormattedMessage } from 'react-intl'

const style = {
  toggleContainer: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    .mapText {
      margin: 0px .5em;
    }
  `,
  toggle: theme => css`
    --height: 22px;
    position: relative;
    cursor: pointer;
    
    width: calc(var(--height) * 2);
    height: var(--height);

    background: var(--shade);
    border-radius: calc(var(--height) / 2);

    display: flex;
    align-items: center;

    &:after {
      --gap: 2.5px;
      --heightReduced: calc(var(--height) - (var(--gap) * 2)); 
      content: '\f3c5';
      position: absolute;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: var(--background);
      color: var(--foreground);
      border-radius: 100%; 

      font-size: calc(var(--height) / 2.5);
      height: var(--heightReduced);
      width: var(--heightReduced);
      max-height: var(--heightReduced);
      max-width: var(--heightReduced);

      margin: var(--gap);
      padding: var(--gap);
      left: 0%;
      top: 0px;

      transition: .4s ease-in-out;
    }

    &:hover:after {
      color: var(--foreground);
    }

    &.on:after {
      left: 49%;
      color: var(--flavor-contrast);
      background: var(--flavor);
    }
  `,
}

const Toggle = ({ checked, onClick, testid = "map"}) => (
  <div css={style.toggleContainer}>
    <span 
      className="mapText" 
      data-testid={`btn-toggle-${testid ?? 'default'}-${
        checked ? 'checked' : 'unchecked'
        }`}
    >
      <FormattedMessage id="search.mapview"/>
    </span>
    <div css={style.toggle} className={checked ? 'fas on' : 'fas'} onClick={onClick} />
  </div>
)

export default Toggle
