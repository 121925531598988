import React from 'react'
import { Bullseye } from '@patternfly/react-core'
import Footer from '_components/footer'
import { FooterContainer } from './styles/footer'

const ForumFooter = () => {
  return (
    <FooterContainer>
      <Bullseye>
        <Footer />
      </Bullseye>
    </FooterContainer>
  )
}

export default ForumFooter
