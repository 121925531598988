import React from 'react'

import Logos from './logos'
import Backgrounds from './backgrounds'
import MetaData from './meta-data'
import Language from './language'
import { usePageTitle } from '_application'

const Dashboard = () => {
  usePageTitle('Admin settings')

  return (
    <div>
      <MetaData />
      <Logos />
      <Backgrounds />
      <Language />
    </div>
  )
}

export default Dashboard
