import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import General from './general'
import OverrideLabels from './override-labels'
import AccessPage from './access-page'

const defaultManageDataRoute = '/settings'
const defaultRoute = `/manage${defaultManageDataRoute}`

const Routes = () => {
  return (
    <Switch>
      <Route path={`${defaultRoute}/general`} render={() => <General />} />
      <Route
        path={`${defaultRoute}/override-labels`}
        render={() => <OverrideLabels />}
      />
      <Route
        path={`${defaultRoute}/access-page`}
        render={() => <AccessPage />}
      />
      <Redirect from={defaultRoute} to={`${defaultRoute}/general`} />
    </Switch>
  )
}

export default Routes
