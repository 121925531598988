import styled from '@emotion/styled'
import css from '@emotion/css'

export const SectionHeaderWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderItem = styled.button`
  padding: 6px 16px;
  border-radius: 25px;
  background: transparent;
  margin: 0 20px;
  outline: none;
  border: 2px solid var(--flavor);
  font-weight: 500;
  font-size: 14px;
  cursor: default !important;

  color: var(--foreground);

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--background);
      background: var(--flavor);
    `}

  span {
    margin-right: 15px;
  }
`
