import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Combobox from "_components/combobox"

import {
  CategoryWrapper,
  MainContentWrapper,
  NumberOfSelections,
  CategoriesWrapper,
  CategoryName,
  CategoryOptions,
  SingleOption,
  SingleCategoryWrapper,
} from './styles/category-selector'

const SingleCategory = ({
  parent,
  selectedCategories,
  onSave,
  isFirstItem,
}) => {
  const { name, subcategories, id: parentId } = parent

  const hasSubcategories = subcategories

  // const isDropdownOpen = selectedCategories.some(
  //   (selectedCat) => selectedCat.key === parentId
  // )
  const [isExpand, setIsExpand] = useState(true)

  const selectedCategory = selectedCategories.find(
    (selectedCat) => selectedCat.key === parent.id
  )

  const handleClickCheckbox = (id) => {
    if (!hasSubcategories || subcategories.length === 0) {
      return onSave(
        selectedCategory
          ? selectedCategories.filter((el) => el.key !== parentId)
          : [...selectedCategories, { key: parentId, childrenKeys: [] }]
      )
    }

    if (selectedCategory) {
      const filteredCategories = selectedCategories.filter(
        (cat) => cat.key !== selectedCategory.key
      )

      const filteredSubcategories = selectedCategory.childrenKeys.includes(id)
        ? selectedCategory.childrenKeys.filter((subCat) => subCat !== id)
        : [...selectedCategory?.childrenKeys, id]

      return onSave(
        subcategories.length > 0 && filteredSubcategories.length === 0
          ? filteredCategories
          : [
              ...filteredCategories,
              {
                ...selectedCategory,
                childrenKeys: filteredSubcategories,
              },
            ]
      )
    }

    return onSave([
      ...selectedCategories,
      {
        key: parent.id,
        childrenKeys: selectedCategories.subcategories
          ? [...selectedCategories.subcategories, id]
          : [id],
      },
    ])
  }

  return (
    <SingleCategoryWrapper>
      <CategoryName
        isExpandable={hasSubcategories && subcategories.length > 0}
        isExpand={isExpand}
        onClick={() => setIsExpand(!isExpand)}
      >
        <i className="fa fa-angle-down" />
        <span className="name"> {name}</span>

        {(!hasSubcategories || subcategories?.length === 0) && (
          <input
            checked={selectedCategory || false}
            type="checkbox"
            id={`${parentId}`}
            name={`${name}`}
            onClick={() => handleClickCheckbox(parentId, false)}
          />
        )}
      </CategoryName>

      {hasSubcategories && isExpand && (
        <CategoryOptions>
          {subcategories.map((subcat, i) => {
            return (
              <SingleOption for={`${subcat.id}`} key={i}>
                <input
                  checked={
                    selectedCategory?.childrenKeys?.includes(subcat.id) || false
                  }
                  type="checkbox"
                  id={`${subcat.id}`}
                  name={`${subcat.name}`}
                  onClick={() => handleClickCheckbox(subcat.id)}
                />
                {subcat.name}
              </SingleOption>
            )
          })}
        </CategoryOptions>
      )}
    </SingleCategoryWrapper>
  )
}

const Category = ({ allCategories, selectedCategories, onSave }) => {
  const intl = useIntl()

  const countSelected = () => {
    let numberOfSelected = 0

    selectedCategories.forEach((element) => {
      const { childrenKeys } = element

      const hasSubcategoriesToSelect =
        allCategories.find((cat) => cat.id === element.key)?.subcategories
          ?.length > 0

      numberOfSelected = hasSubcategoriesToSelect
        ? numberOfSelected + childrenKeys.length
        : numberOfSelected + 1
    })
    return numberOfSelected
  }

  const [categories, setCategories] = useState([])

  useEffect(() => {
    handleData(allCategories)
  }, [allCategories]);

  const handleData = (data) => {
    let compiledList = []
    let selected = handleSelected(selectedCategories)

    data.forEach((item) => {
      const isSelected = (id) => selected.includes(id);

      item.subcategories?.length
      ? item.subcategories.forEach((sub) => compiledList.push({title: sub.name, parent: item.name, checked: isSelected(sub.id), id: null}))
      : compiledList.push({title: item.name, parent: null, checked: isSelected(item.id), id: item.id})
    })
    setCategories(compiledList)
  }

  const handleSelected = (data) => {
    let compiledList = []
    data.forEach((item) => {
      item.childrenKeys?.length
      ? item.childrenKeys.forEach((child) => compiledList.push(child))
      : compiledList.push(item.key)
    })
    return compiledList
  }

  const categoriesChange = (data) => {
    const selectedCategories = []

    data.forEach((item) => {
      const assoiatedObject = allCategories.find(i => item.parent ? i.name === item.parent : i.id === item.id)
      const checkIfAlreadyAdded = selectedCategories.find(e => e === assoiatedObject) ? true : false;
      !checkIfAlreadyAdded && selectedCategories.push(assoiatedObject)
    })

    const filteredForSub = selectedCategories.map((e) => {
      const newObj = {...e}
      if(e.subcategories) {
        const filtered = e.subcategories.filter((i) => data.find((o) => o.title === i.name))
        newObj.subcategories = filtered
      }
      return newObj
    })

    const formatedChange = filteredForSub?.map((e) => {
      const sub = e.subcategories
      const subCategories = sub ? sub.map((i) => i.id) : []
      return { key: e.id, name: e.name, childrenKeys: subCategories}
    })

    onSave(formatedChange)
  }

  return (
    <CategoryWrapper>
      <div className="label-wrapper">
        <label htmlFor="category-content">
          {intl.formatMessage({
            id: 'events.createevent.general.categories.title',
          })}
        </label>
        {/* todo: intl */}
        <p className='desc'>Assign this blog to a Category</p>
      </div>
      <MainContentWrapper>
        <CategoriesWrapper>
          <Combobox data={categories} onChange={categoriesChange}/>
          {/*true && allCategories.map((category, i) => {
            return (
              <SingleCategory
                key={category.id}
                parent={category}
                selectedCategories={selectedCategories}
                onSave={onSave}
                numberOfSelected={countSelected()}
                isFirstItem={firstCategoryWithSubcategories?.id === category.id}
              />
            )
          })*/}
        </CategoriesWrapper>
        {false && <NumberOfSelections>
          {intl.formatMessage(
            { id: 'event.create.categories.number' },
            { number: countSelected() }
          )}
        </NumberOfSelections>}
      </MainContentWrapper>
    </CategoryWrapper>
  )
}

export default Category
