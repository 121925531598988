import React from 'react'
import { connect } from 'react-redux'

import TaxonomySelector from 'components/indy/shared/taxonomy/index'
import KeywordGenericStyled from './styles/keyword-generic'

const mapStateToProps = ({ user }) => ({ user })

const TaxonomyGeneric = ({ step, stepList, user, dispatch }) => {
  const taxonomy = stepList[step.currentStep]

  const handleTaxonomy = (values) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: {
          ...user.profile,
          taxonomies: [
            ...user.profile.taxonomies.filter((k) => k.key !== taxonomy?.key),
            {
              key: taxonomy?.key,
              values,
            },
          ],
        },
      },
    })
  }

  const selectedValues =
    user.profile?.taxonomies.filter((k) => k.key === taxonomy.key)[0]?.values ||
    []

  return (
    <KeywordGenericStyled>
      <div className="step-content-container background">
        <div className="tiles gender">
          <span className="title-main"></span>
          {}
          <TaxonomySelector
            selectedValues={selectedValues}
            taxonomy={taxonomy}
            onSelectCallback={(l) => handleTaxonomy(l)}
          />
        </div>
      </div>
    </KeywordGenericStyled>
  )
}

export default connect(mapStateToProps)(TaxonomyGeneric)
