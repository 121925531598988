import styled from '@emotion/styled'
import css from '@emotion/css'

export const CoverPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .label-wrapper {
    display: flex;
    margin-bottom: 10px;
  }
  
  .label {
    margin: 0 10px 0 0;
    font-weight: 600;
    font-size: 18px;
    color: var(--foreground);
  }

  .info {
    font-weight: 400;
    font-size: 14px;
  }

  .required-star {
    color: red;
  }

  .cover-photo-upload-btn {
    border: 2px solid var(--flavor);
    border-radius: 25px;
    padding: 5px 20px;
    background-color: var(--flavor);
    color: var(--background);
    font-size: 14px;
    font-weight: 700;
  }
`

export const StyledUploadDiv = styled.div`
  border: none;
  padding: 50px 100px;
  width: 300px;
  height: 115px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  position: relative;

  .change-img-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 5px 20px;
    border-radius: 25px;
    border: 2px solid var(--background);
    color: var(--background);
    background: transparent;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  :hover {
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000;
      opacity: 0.5;
      border-radius: 10px;
      transition: all 0.5s ease-in-out;
    }
    .change-img-btn {
      z-index: 100;
    }
  }

  ${({ imgUrl }) =>
    imgUrl &&
    css`
      background-image: url(${imgUrl});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;

      i {
        display: none;
      }
    `}

  i {
    font-size: 35px;
    color: #6a6e73;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--foreground-shade);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  i {
    font-size: 8px;
    color: var(--background);
  }
`
