import React, { Children, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Slider from 'react-slick'
import RightArrow from '_components/right-arrow'
import LeftArrow from '_components/left-arrow'

const styles = {
  leftArrow: css`
    outline: none;
    left: -15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
  `,
  rightArrow: css`
    outline: none;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
  `,
}

const DEFAULT_CONFIG = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplaySpeed: 5000,
  nextArrow: <RightArrow css={styles.rightArrow} />,
  prevArrow: <LeftArrow css={styles.leftArrow} />,
}

const TileSlider = forwardRef(({ children, className, ...rest }, ref) => (
  <Slider
    {...DEFAULT_CONFIG}
    className={className}
    infinite={Children.count(children) > 3}
    {...rest}
    ref={ref}
  >
    {children}
  </Slider>
))
TileSlider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  config: PropTypes.object,
}

export default TileSlider
