function blocksToText(blocks) {
  return blocks.map((block) =>
    block.children.map((child) => child.text).join('')
  )
}

export const processDescription = (desc) => {
  if (desc !== null && typeof desc === 'object') {
    return blocksToText(desc)[0]
  }
  return desc
}
