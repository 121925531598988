import Tile from './tile'
import BlogTile from './blog-tile'
import AvatarTile from './avatar-tile'
import ToggleButton from './styled/toggle-button'
import LogoTile from './logo-tile'
import Footer from './footer'
import SDKContainer from './sdk-container'
import RequestAccess from './request-access'
import LoginTitle from './login-title'
import HeaderNav from './header-navigation'
import * as TileSections from './tile-sections'
import * as Headers from './styled/headers'
import ResourcesDropdown from './resources-dropdown'
import UserDropdown from './user-dropdown'
import LoginRegister from './login-register'
import BaseTile from './base-tile'
import * as Tiles from './tiles'

export default {
  Tile,
  BlogTile,
  AvatarTile,
  LogoTile,
  SDKContainer,
  RequestAccess,
  LoginTitle,
  ToggleButton,
  ...Headers,
  ...TileSections,
  Footer,
  HeaderNav,
  ResourcesDropdown,
  UserDropdown,
  LoginRegister,
  BaseTile,
  ...Tiles,
}
