import React from 'react'
import { connect } from 'react-redux'
import ProductsList from './products-list'
import { useIntl } from 'react-intl'

import { Section, MainWrapper } from '../styles'
const mapStateToProps = ({ user }) => ({ user })

const Products = ({ user, dispatch }) => {
  const intl = useIntl()
  return (
    <MainWrapper>
      <Section>
        <span className="section-title">
          {intl.formatMessage({ id: 'user.profile.subscriptions' })}
        </span>
        <div className="content">
          <ProductsList />
        </div>
      </Section>
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(Products)
