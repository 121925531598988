import React from 'react'
import ButtonStyled from './single-button-styled'

const Button = ({
  onClick,
  size,
  button,
  children,
  type,
  testid,
}) => {
  return (
    <ButtonStyled
      className={`ui-kit ui-kit-button button-single size-${size || 'm'} type-${
        type || ''
      }`}
    >
      <button
        type="button"
        className={`${type}`}
        onClick={onClick}
        data-testid={`btn-${testid ?? 'default'}`}
      >
        {/* <div className="layer" /> */}
        {children}
      </button>
    </ButtonStyled>
  )
}

export default Button
