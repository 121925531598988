import React from 'react'
import * as Sentry from '@sentry/react'
import FallbackUI from './components/fallback-ui'

const ErrorHandler = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackUI}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorHandler
