const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const checkInputValue = (type, value) => {
  let isValueCorrect
  let warningMessageKey = ''
  let isValueValidated = true
  switch (type) {
    case 'email':
      isValueCorrect = value?.toLowerCase().match(emailRegex)
      warningMessageKey = 'ca.input.validation.warningmessage'
      break
    case 'url':
      isValueCorrect = value?.toLowerCase().match(urlRegex)
      warningMessageKey = 'ca.input.validation.warningmessage'
      break

    default:
      isValueCorrect = true
      break
  }
  return { isValueCorrect, warningMessageKey, isValueValidated }
}
