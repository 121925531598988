import gql from 'graphql-tag'

export const UPDATE_BACKGROUND = gql`
  mutation updateBackgroundImage(
    $backgroundImage: BackgroundImageInput
    $type: String
  ) {
    updateBackgroundImage(backgroundImage: $backgroundImage, type: $type) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`
