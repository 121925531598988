import PropTypes from 'prop-types'
import themable from './_themable'

const TextProps = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.string,
  className: PropTypes.string,
}

export const TileText = themable('TileText', TextProps)
export const TileTextShort = themable('TileTextShort', TextProps)
export const TileSectionSeparator = themable('TileSectionSeparator', {})
export const TileConnections = themable('TileConnections', {
  connectedActivities: PropTypes.array,
  connectedCompanies: PropTypes.array,
  connectedOpportunities: PropTypes.array,
  connectedUsers: PropTypes.array,
})

export default {
  TileText,
  TileTextShort,
  TileSectionSeparator,
}
