import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Row, Col } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import Input from 'components//indy/shared/ui-kit/inputs/text'
import ToggleButton from 'components//indy/shared/ui-kit/buttons/toggle-button-with-name'

import BadgeCreator from './badge-creator'
import KeywordCreator from '../../../keywords/creator'
import TaxonomyCreator from '../../../taxonomy/creator'

import { StyledModal } from './styles/modal-styled'

const names = {
  keyword: 'Keyword category',
  taxonomy: 'Taxonomy',
  attribute: 'Custom attribute',
}

const types = [
  { name: 'Text input', value: 'text' },
  { name: 'Boolean', value: 'boolean' },
  { name: 'Date picker', value: 'date' },
  { name: 'Phone number', value: 'phone' },
  { name: 'Email', value: 'email' },
  { name: 'Website link', value: 'url' },
  { name: 'Image', value: 'image' },
  { name: 'File', value: 'file' },
  { name: 'Location', value: 'location' },
  { name: 'Badge type', value: 'badge' },
]

const Index = ({ onClose, onAdd, modalType, modalProps }) => {
  const [props, setProps] = useState(modalProps || { type: 'text' })
  const [errors, setErrors] = useState({})
  const isEditMode = modalProps
  const intl = useIntl()

  const handleClose = () => {
    onClose()
  }

  const renderKey = (type) =>
    intl.formatMessage({ id: `select.option.label.${type}` })

  const handleAdd = () => {
    const { name, description } = props
    const err = {}
    if (!name || name.split(' ').join('').length <= 0)
      err.name = { message: 'Field cannot be empty' }
    if (!description || description.split(' ').join('').length <= 0)
      err.description = { message: 'Field cannot be empty' }
    if (props.badges)
      props.badges = props.badges.map((n) => {
        return { key: n.key, icon: n.icon, name: n.name }
      })
    if (Object.keys(err).length === 0) onAdd(props)
    else setErrors(err)
  }

  const handleCleanErrors = (type) => {
    if (errors[type]) setErrors({ ...errors, [type]: null })
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `global.modal.label.${value.toLowerCase()}`,
    })
  }

  return (
    <StyledModal
      dialogClassName=""
      show
      onHide={onClose}
      size={modalType === 'taxonomy' ? 'xl' : null}
    >
      <div>
        <Modal.Body className="add-new-container">
          <h3 className="modal-title">{`${renderMessageText(
            isEditMode ? 'title.edit' : 'title.addnew'
          )} ${renderMessageText(
            'modaltype.' + names[modalType].replace(/\s/g, '').toLowerCase()
          )}`}</h3>
          {modalType === 'attribute' && (
            <div className="type-selector">
              <span className="section-title">
                {renderMessageText('attributeType')}
              </span>
              <select
                value={props?.type}
                onChange={(e) => setProps({ ...props, type: e.target.value })}
              >
                {types.map((t) => {
                  return <option value={t.value}>{renderKey(t.value)}</option>
                })}
              </select>
            </div>
          )}
          <div className="inputs">
            <Input
              input={{
                name: 'Name',
                placeholder: renderMessageText(
                  `placeholder.name.${names[modalType]
                    .replace(/\s/g, '')
                    .toLowerCase()}`
                ),
                value: props?.name,
              }}
              onBlurCallback={(v) => setProps({ ...props, name: v })}
              onChangeCallback={() => handleCleanErrors('name')}
              error={errors.name}
            />
            <Input
              input={{
                name: 'Description',
                placeholder: renderMessageText(
                  `placeholder.description.${names[modalType]
                    .replace(/\s/g, '')
                    .toLowerCase()}`
                ),
                value: props?.description,
              }}
              onBlurCallback={(v) => setProps({ ...props, description: v })}
              onChangeCallback={() => handleCleanErrors('description')}
              error={errors.description}
            />
          </div>
          <div className="data">
            <span className="section-title">{renderMessageText('data')}</span>
            <div className="content">
              {props?.type === 'badge' && (
                <BadgeCreator
                  defaultBadges={props?.badges}
                  onChange={(badges) => setProps({ ...props, badges })}
                />
              )}
              {modalType === 'keyword' && (
                <KeywordCreator onChange={(p) => setProps(p)} props={props} />
              )}
              {modalType === 'taxonomy' && (
                <TaxonomyCreator onChange={(p) => setProps(p)} props={props} />
              )}
            </div>
          </div>
          <div className="options">
            <Row>
              <Col xs={12} md={6} />
              <Col xs={12} md={6}>
                <div className="toggle-options">
                  <span className="section-title">
                    {renderMessageText('additionalOptions')}
                  </span>
                  <ToggleButton
                    name={`${intl.formatMessage({
                      id: 'togglebutton.label.searchable',
                    })}`}
                    checked={props?.isSearchable}
                    onChange={(v) => setProps({ ...props, isSearchable: v })}
                    intl
                  />
                  {modalType === 'attribute' ? (
                    <ToggleButton
                      name={`${intl.formatMessage({
                        id: 'togglebutton.label.private',
                      })}`}
                      checked={props?.isPrivate}
                      onChange={(v) => setProps({ ...props, isPrivate: v })}
                      intl
                    />
                  ) : (
                    <ToggleButton
                      name={`${intl.formatMessage({
                        id: 'togglebutton.label.matchable',
                      })}`}
                      checked={props?.isMatchable}
                      onChange={(v) => setProps({ ...props, isMatchable: v })}
                      intl
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} type="admin">
            {renderMessageText('close')}
          </Button>
          <Button onClick={() => handleAdd()} type="admin">
            {renderMessageText(isEditMode ? 'save' : 'create')}
          </Button>
        </Modal.Footer>
      </div>
    </StyledModal>
  )
}

export default Index
