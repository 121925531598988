import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const Props = {
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const ClickAway = (props) => {
  const { children, className, onClickOutside } = props
  const containerRef = useRef()
  const [onWrapper, setCursorOn] = useState(false)

  const bodyClicked = () => {
    if (!onWrapper && onClickOutside) onClickOutside()
  }

  useEffect(() => {
    if (containerRef.current) {
      document.body.addEventListener('click', bodyClicked, { capture: true})
      return () => {
        document.body.removeEventListener('click', bodyClicked, { capture: true})
      }
    }
  })

  return (
    <div
      ref={containerRef}
      className={className}
      onMouseEnter={() => setCursorOn(true)}
      onMouseLeave={() => setCursorOn(false)}
      {...props} 
    >
      {children}
    </div>
  )
}
ClickAway.propTypes = Props

export default ClickAway
