import React, { useState } from 'react'
import { BaseTile } from '_tiles'
import { Default as EventTile } from '../tiles'
import { GridEvents } from './styles'
import {
  desktopXLPX,
  pabletPX,
  tabletPX,
  mobilePX,
} from '_utils/responsive-queries'
import { useGetEntityAccess } from '_security/entity-access'
import { useUserAccess } from '_security/helpers'

const resolveGirdColumnsNumber = (screenSize) => {
  const mobile = screenSize <= mobilePX
  const tablet = screenSize >= pabletPX && screenSize < tabletPX
  const desktop = screenSize > tabletPX && screenSize < desktopXLPX
  const desktopXL = screenSize >= desktopXLPX

  switch (true) {
    case mobile:
      return 1
    case tablet:
      return 2
    case desktop:
      return 3
    case desktopXL:
      return 4
    default:
      return 1
  }
}

const resolveGridColumSpans = (screenSize, index, itemsNumber) => {
  const mobile = screenSize <= mobilePX
  const tablet = screenSize >= pabletPX && screenSize < tabletPX
  const desktop = screenSize > tabletPX && screenSize < desktopXLPX
  const desktopXL = screenSize >= desktopXLPX

  const smallTile = 1
  const mediumTile = 2
  const largeTile = 3

  switch (true) {
    case mobile:
      return smallTile

    case tablet:
      return index === 0 ? mediumTile : smallTile

    case desktop: {
      const tilesIncludedInSpecialGrid = 6
      const numberOfColumns = 3
      const defaultSpanValue = 1

      if (index < numberOfColumns) return numberOfColumns - index
      const isLastItem = index === itemsNumber - 1
      if (
        index >= numberOfColumns &&
        isLastItem &&
        index <= tilesIncludedInSpecialGrid
      )
        return defaultSpanValue + (tilesIncludedInSpecialGrid - itemsNumber)
      return defaultSpanValue
    }

    case desktopXL: {
      const isFirstItem = index === 0
      const isLastItem = index === itemsNumber - 1

      if (index < 3) return isFirstItem ? largeTile : index
      if (itemsNumber === 4 || itemsNumber === 5)
        return index === 3 && isLastItem ? mediumTile : smallTile
      if (itemsNumber === 6)
        return index > 2 && !isLastItem ? 5 - index : largeTile
      if (itemsNumber >= 7) return index < 5 ? mediumTile : smallTile
      return smallTile
    }
    default:
      return smallTile
  }
}

const EventsGrid = ({ events }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const setViewport = () => setWindowWidth(window.innerWidth)

  window.addEventListener('resize', setViewport)

  const entityAccess = useGetEntityAccess('EVENTS', 'detailsRequiresAuth')

  const eventDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  const isAdditionalAuth = useUserAccess()

  return (
    <GridEvents gridConfig={resolveGirdColumnsNumber(windowWidth)}>
      {events.map((event, i) => {
        const numberOfElements = events.length
        return (
          <BaseTile
            url={`/events/list/${event.id}`}
            isAdditionalAuthEnabled={eventDetailsRequiresAuth}
            isAdditionalAuth={isAdditionalAuth}
            config={{
              style: {
                gridColumn: `span ${resolveGridColumSpans(
                  windowWidth,
                  i,
                  numberOfElements
                )}`,
              },
            }}
            testid={`event-${event?.id}`}
          >
            <EventTile
              event={event}
              className={`event-tile grid-span-column-${resolveGridColumSpans(
                windowWidth,
                i,
                numberOfElements
              )}`}
            />
          </BaseTile>
        )
      })}
    </GridEvents>
  )
}

export default EventsGrid
