import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import moment from 'moment'
import { useMessageActions } from '_messages'
import { useUserStatus } from '_meet'

const AvatarWrapper = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-left: 10px;
`

const DeleteMessageButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  color: var(--foreground);
  i {
    font-size: 15px;
    color: inherit;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: var(--flavor-contrast);
    `}
`

const OnlineIndicator = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ isOnline, theme }) => (isOnline ? '#4dcea6' : 'grey')};
  border-radius: 10px;
  border: 2px solid var(--background);
  position: absolute;
  right: 0;
  bottom: 0;
`

const SingleConversationContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 0.15s;
  z-index: 1;
  border-left: 3px solid transparent;
  transition: 0.1s;
  &.active {
    border-left: 3px solid var(--flavor);
    padding-left: 5px;
  }
  &:hover {
    ${DeleteMessageButton} {
      display: block;
    }
  }
`

const DetailsRow = styled.div`
  position: relative;
  display: flex;
  z-index: 2;
  padding: 15px 0;
`

const DetailsCol = styled(Col)`
  padding: 0px 15px;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
`

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
`

const Name = styled.h5`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: inherit;
`

const Date = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
`

const Excerpt = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  padding-top: 2px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  &.unread {
    opacity: 1;
  }
`

const UpperDetailsRow = styled(Row)`
  padding: 0px !important;
  margin: 0px !important;
`

const UpperDetailsNameCol = styled(Col)`
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden;
`
const UpperDetailsDateCol = styled(Col)`
  margin: 0px !important;
  padding: 0px !important;
  max-width: 95px;
  text-align: right;
`

const Fader = styled.div`
  transition: 0.9s ease-out;
  position: absolute;
  left: 0px;
  width: ${({ isActive }) => (isActive ? '75%' : '0')};
  height: 100%;
  background-image: linear-gradient(
    to right,
    var(--flavor-shade),
    rgba(239, 239, 239, 0) 100%
  );
  z-index: 1;
`

export const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      avatar
    }
  }
`

const convertDateString = (string, intl) => {
  if (string.includes('a few seconds'))
    return string.replace(
      'a few seconds',
      `${intl.formatMessage({ id: 'date.timefromnow.now' })}`
    )
  if (string.includes('a minute')) return string.replace('a minute', '1 min')
  if (string.includes('minutes')) return string.replace('minutes', 'min')
  if (string.includes('hours')) return string.replace('hours', 'h')
  if (string.includes('a hour')) return string.replace('a hour', '1 h')
  if (string.includes('days')) return string.replace('days', 'd')
  if (string.includes('a day')) return string.replace('a day', '1 d')
  if (string.includes('months')) return string.replace('months', 'm')
  if (string.includes('a month')) return string.replace('a month', '1 m')
  if (string.includes('years')) return string.replace('years', 'y')
  if (string.includes('a year')) return string.replace('a year', '1 y')
  return string
}

const Index = ({ conversation }) => {
  const intl = useIntl()
  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: {
      id: conversation.conversationUser,
    },
  })
  const { user } = useSelector((state) => state)
  const activeConversation = useSelector(
    (state) => state.message && state.messages?.currentConversation
  )
  const messageActions = useMessageActions()
  const onlineStatus = useUserStatus(conversation.conversationUser)
  const checkIfNew = (message) => {
    return !message.read && user.sub !== message.creator
  }
  if (loading || !data?.getUser) {
    return <></>
  }

  return (
    <SingleConversationContainer
      className={
        activeConversation === conversation.conversationUser ? 'active' : ''
      }
    >
      <Fader isActive={activeConversation === conversation.conversationUser} />
      <DetailsRow
        onClick={() => {
          messageActions.openConversation(data?.getUser?.sub)
        }}
      >
        <DeleteMessageButton
          isActive={activeConversation === conversation.conversationUser}
          data-for="info-tooltip"
          data-tip={intl.formatMessage({
            id: 'messages.message.deleteconversationbutton.tooltip',
          })}
          onClick={() =>
            messageActions.deleteConversation(conversation.conversationUser)
          }
        >
          <i class="far fa-times-circle" />
        </DeleteMessageButton>
        <AvatarWrapper>
          <Thumbnail
            className={`conversation-thumbnail ${
              checkIfNew(conversation.lastMessage) ? ' unread' : ''
            }`}
            alt=""
            src={data?.getUser?.avatar}
          />
          <OnlineIndicator isOnline={onlineStatus === 'ONLINE'} />
        </AvatarWrapper>
        <DetailsCol>
          <UpperDetailsRow className="my-auto">
            <UpperDetailsNameCol className="text-left my-auto">
              <Name
                className={checkIfNew(conversation.lastMessage) ? 'unread' : ''}
              >
                {data.getUser.firstName} {data.getUser.lastName}
              </Name>
            </UpperDetailsNameCol>
            <UpperDetailsDateCol className="text-right my-auto">
              <Date>
                {convertDateString(
                  moment(conversation.lastMessage.created).fromNow(true),
                  intl
                )}
              </Date>
            </UpperDetailsDateCol>
          </UpperDetailsRow>
          <Excerpt
            className={checkIfNew(conversation.lastMessage) ? ' unread' : ''}
          >
            {conversation.lastMessage
              ? `${String(conversation.lastMessage.content)}`
              : ''}
          </Excerpt>
        </DetailsCol>
      </DetailsRow>
    </SingleConversationContainer>
  )
}

export default Index
