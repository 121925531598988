import styled from '@emotion/styled'

import { GridItem } from '@patternfly/react-core'
import { Link } from 'react-router-dom'
import border from '_utils/css-border'
import { mobile, pablet, tablet } from '_utils/responsive-queries'
import { ctaButtonHollow } from '_utils/css-buttons'

export const MainSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .bullet {
    margin: 0 6px;
    font-size: 5px;
    transform: translateY(-50%);
  }
  ${pablet} {
    flex-direction: column;
    padding-top: 10px;
    margin-bottom: 20px;
  }
`

export const PageWrapper = styled.div`
  max-width: 1570px;
  margin: 0 auto;
`

export const SectionHeader = styled.div`
  width: 100%;
  margin: 30px 0;
`

export const TopicAvatar = styled.div`
  ${border()}
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 8px;
  flex-shrink: 0;
  img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    object-fit: cover;
    object-position: center;
  }
`

export const Indicator = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  max-width: 100%;
  word-break: break-word;
  border-left: ${({ sectionColor }) =>
    `7px  solid ${sectionColor || 'var(--flavor)'}`};
  padding-left: 16px;

  .author-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
      display: none;
    }
  }
  p {
    margin: 0;
    &.no-posts {
      margin: 0;
    }
  }
  span.no-posts {
    margin: 0;
    font-size: 14px;
  }
  .content-preview {
    margin-top: 5px;

    & > * {
      display: none;
    }

    *:first-child {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      display: none !important;
    }
  }

  ${pablet} {
    margin-left: 5px;
    flex-grow: 1;
  }
`

export const Counter = styled.div`
  font-size: ${({ isTopElement }) => (isTopElement ? '22px' : '16px')};
  font-weight: 500;
  text-align: center;
  /* ${tablet} {
    font-size: 14px;
  }
  ${pablet} {
    font-size: 22px;
  } */
`

export const PageSection = styled.div`
  /* align-self: center;
  .item-name {
    font-size: 16px;
    font-weight: 400;
  }

  ${tablet} {
    display: flex;
    ${Counter}, .item-name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
    }
  }

  ${pablet} {
    display: flex;
     flex-direction: column; 
    ${Counter}, .item-name {
      font-size: 20px;
      font-weight: 400;
      margin: 0 5px;
    }
    span {
      align-self: center;
    }
  }  */
`

export const Description = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;


  .title {
    font-size: 18px;
    font-weight: 500;
    flex-grow: 1;
  }
  .description {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  .author {
    display: flex;
    align-items: center;
    font-size: 12px;

    ${tablet} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px 0;
      gap: 5px;
      .bullet {
        display: none;
      }
    }
  }
`

// export const Description = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   padding: 0 20px;
//   flex-grow: 1;

//   .title {
//     font-size: 18px;
//     font-weight: 600;
//     margin-bottom: 5px;
//   }
//   .description {
//     font-size: 18px;
//     font-weight: 400;
//     margin: 0;
//   }
//   .author {
//     display: flex;
//     align-items: center;
//     font-size: 12px;
//   }

//   ${tablet} {
//     padding-right: 0;
//     .title {
//       font-size: 16px;
//     }
//     .description {
//       font-size: 14px;
//     }
//   }

//   ${pablet} {
//     padding-right: 0;
//     .title {
//       font-size: 18px;
//     }
//     .description {
//       font-size: 16px;
//     }
//   }
//   /* ${mobile} {
//     .author {
//       flex-direction: column;
//       align-items: flex-start;
//       .bullet {
//         display: none;
//       }
//     }
//   } */
// `
export const CategoryLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  /* align-items: ${({ isTopElement }) => !isTopElement && 'center'}; */
  padding-right: 20px;
`

export const CategoryWrapper = styled(GridItem)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: ${({ isTopElement }) => isTopElement && '58px'};
  min-height: ${({ isTopElement }) => isTopElement && '75px'};
  width: ${({ isTopElement }) => isTopElement && '50%'};
  /* padding-right: 20px; */

  ${tablet} {
    width: ${({ isTopElement }) => isTopElement && '60%'};
    margin-bottom: ${({ isTopElement }) => isTopElement && '32px'};
    /* padding-bottom: 24px; */
  }

  ${pablet} {
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

export const CategoryIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ categoryColor }) => categoryColor};
  i {
    font-size: 10px;
  }
`

export const SecondLineInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 25px;
  font-size: 12px;
  .fa-tag {
    margin-right: 5px;
  }
  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
    .bullet {
      display: none;
    }
  }
`

export const FirstLineInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
`

export const AdditionalInfoWrapper = styled.div``

export const CountersWrapper = styled(GridItem)`
  display: flex;
  flex-basis: 25%;
  min-width: 25%;
  justify-content: space-around;
  align-items: flex-start;
  height: 75px;
  width: auto;
  padding: 0 5px;
  gap: 24px;
${tablet} {

}
  ${pablet} {
    flex-grow: 1;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
  }
  ${mobile} {
    padding: 0;
    margin-left: 60px;
    gap: 24px;
    justify-content: flex-start;
  }
`

export const LatestWrapper = styled(GridItem)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  width: 28%;
  position: relative;
  padding-left: 20px;

  .author-wrapper {
    display: flex;
  }
  .author-name,
  .date {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 0px;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    i {
      font-size: 5px;
      margin: 0 5px;
    }
  }

  .topic-name {
    font-size: 12px;
    & > * {
      display: none;
    }

    *:first-child {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      display: none !important;
    }
  }

  .date-wrapper {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 12px;
    &.no-posts {
      font-size: 14px;
      padding: 28px 0;
    }
  }
  ${tablet} {
    width: 45%;
  }
  ${pablet} {
    width: 100%;
  }
  ${mobile} {
    .date-wrapper {
      .bullet,
      .fa-circle {
        display: none;
      }
    }
    .author-name {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
export const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

export const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

export const CategoryAvatar = styled.div`
  ${border()}
  border-radius: 50%;
  width: ${({ isTopElement }) => (isTopElement ? '75px' : '35px')};
  height: ${({ isTopElement }) => (isTopElement ? '75px' : '35px')};
  overflow: hidden;
  flex-shrink: 0;
  font-size: ${({ isTopElement }) => (isTopElement ? '23px' : '16px')};
  text-align: center;
  color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-right: 16px;
  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  ${mobile} {
    width: ${({ isTopElement }) => (isTopElement ? '45px' : '35px')};
    height: ${({ isTopElement }) => (isTopElement ? '45px' : '35px')};
  }
`

export const SubcategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${CategoryAvatar} {
    align-self: flex-start;
  }
  padding-left: 38px;
  padding-top: ${({ isTopElement }) => isTopElement && '36px'};

  ${tablet} {
    padding-left: 40px;
    /* margin-right: ${({ isTopElement }) => isTopElement && '25%'}; */
    padding-top: ${({ isTopElement }) => isTopElement && '12px'};
  }
  ${pablet} {
    padding-left: 50px;
    padding-left: ${({ isTopElement }) => isTopElement && '40px'};
    /* padding-top: ${({ isTopElement }) => isTopElement && '12px'}; */
  }

  ${pablet} {
    padding-left: ${({ isTopElement }) => isTopElement && '50px'};
  }

  ${mobile} {
    margin-right: 0;
    padding-top: ${({ isTopElement }) => isTopElement && '24px'};
  }
`

export const Subcategory = styled.div`
  display: flex;
  align-items: center;
  margin-left: 90px;
  font-weight: 600;
  font-size: 16px;
  gap: 18px;
  margin-top: 12px;
  &:last-of-type {
    margin-bottom: 50px;
    ${pablet} {
      margin-bottom: 4px;
    }
  }
  &:first-of-type {
    margin-top: 0;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: ${({ iconBg }) => iconBg || 'var(--flavor)'};

    i {
      color: ${({ iconColor }) => iconColor || ''};
    }
  }
  ${mobile} {
    margin-left: 60px;
  }
`

export const TopWrapper = styled.div`
  display: flex;
  gap: 16px;
  ${mobile} {
    flex-direction: column;
  }
`
