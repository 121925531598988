import { css } from '@emotion/core'

export const stretch = css`
  & > *,
  & {
    transition: 0.2s ease-in-out;
  }

  &:hover {
    transform: scale(1.2);
    & > * {
      transform: scale(0.8);
    }
  }

  &:active {
    transform: scale(0.8);
    & > * {
      transform: scale(1);
    }
  }
`

export const psuedo = () => css`
  content: '';
  position: absolute;
  z-index: 0;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
`

export const onionBloom = (props) => css`
  --waxOn: ${props.theme.colors.buttonColor};
  --waxOff: ${props.theme.colors.whiteColor};

  overflow: hidden;
  position: relative;
  z-index: 0;

  background: var(--flavor);
  color: var(--background);

  & > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    ${psuedo()}
    z-index: 1;
    clip-path: circle(150% at 0% 0%);
    background: var(--waxOn);
    transition: 0.8s ease-in-out;
  }

  &::before {
    ${psuedo()}
    z-index: 1;
    clip-path: circle(150% at 0% 0%);
    background: var(--waxOff);

    transition: 0.4s ease-in-out, background 0.01s;
  }

  &:hover::after {
    clip-path: circle(0% at 0% 0%);
    transition: 0.4s ease-in-out;
  }

  &:hover::before {
    --antiAliasOffset: 0px;
    --antiAliasSize: 100%;
    clip-path: circle(0% at 0% 0%);
    transition: 0.8s ease-in-out;
  }

  &:active,
  &.active {
    --waxOn: ${props.theme.colors.whiteColor};
    --waxOff: ${props.theme.colors.buttonColor};
    background: var(--background);
    color: var(--flavor);
  }
`

export const onionBloomAliasFix = () => css`
  &::before {
    --antiAliasOffset: 2px;
    --antiAliasSize: 90%;
    top: var(--antiAliasOffset);
    left: var(--antiAliasOffset);
    width: var(--antiAliasSize);
    height: var(--antiAliasSize);
    border-radius: 100%;
  }
  &:hover::before {
    --antiAliasOffset: 0px;
    --antiAliasSize: 100%;
  }
`

export const clipBloom = (props) => css`
  &,
  & > * {
    position: relative;
    z-index: 1;
  }

  &,
  &:hover {
    background-color: transparent;
  }

  &::after {
    ${psuedo()}
    z-index: 0;
    clip-path: circle(0% at 0% 0%);
    background: var(--flavor);
    transition: 0.4s ease-in-out;
  }

  &:hover::after {
    clip-path: circle(150% at 0% 0%);
  }

  &:active,
  &.active {
    color: var(--foreground);
    &::after {
      animation-name: example;
      animation-duration: 4s;
      clip-path: circle(12% at 50% 0%);
      @media (max-width: 991px) {
        clip-path: circle(10% at 50% 0%);
      }
    }
  }

  @keyframes clipCenter {
    from {
      clip-path: circle(0% at 50% 0%);
    }
    to {
      clip-path: circle(12% at 50% 0%);
    }
  }
`

export const bloom = (props) => css`
  &:hover,
  &:active {
    color: var(--background);
  }

  & > * {
    position: relative;
    z-index: 5;
    transition: 0.4s ease-in-out;
  }

  &::after {
    ${psuedo()}
    background: var(--flavor);
    border-radius: 50%;
    transform: scale(0);
    transition: 0.8s;
  }

  &:hover > * {
    transform: scale(1.1);
  }

  &:hover::after {
    transform: scale(4);
    transition: 0.4s ease-in-out;
  }

  &:active::after {
    transform: scale(80);
    transition: 0.4s ease-in-out;
  }
`
