import React from 'react'
import { Route } from 'react-router'
import people from '_people'

const UserProfileModal = ({ path = 'user/:userId' }) => {
  return (
    <Route
      path={`/opportunities/:opportunityId/applications/${path}`}
      render={({ match, history }) => (
        <people.Details
          sub={match?.params?.userId}
          onClose={() => history.goBack()}
        />
      )}
    />
  )
}

export default UserProfileModal
