import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'components//indy/shared/ui-kit/buttons/single-button'
import { useIntl } from 'react-intl'
import { StyledModal } from './styles/styled-modal'

const Delete = ({ onDelete, onClose, currentData, type }) => {
  const intl = useIntl()
  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `global.button.${value.toLowerCase()}`,
    })
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Body className="add-new-container">
        <span>
          {renderMessageText('delete.confirm')}{' '}
          <strong>{currentData.name}</strong>{' '}
          {type === 'taxonomy' ? 'topic' : type}?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button type="admin" onClick={onClose}>
          {renderMessageText('cancel')}
        </Button>
        <Button type="admin" onClick={onDelete}>
          {renderMessageText('delete')}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export default Delete
