import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const animations = {
  appear: keyframes`
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
  `,
}

const Appear = styled.div`
  animation: ${animations.appear} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`

export default Appear
