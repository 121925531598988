import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USERS } from './graph-queries'

import FiltersAndSearch from './filters-and-search'
import Table from './table'
import LoadMore from './load-more'

import { IndexStyled } from './styles/index'

const ManageUsers = () => {
  const [search, setSearch] = useState('')
  const [roleFilter, setRoleFilter] = useState('allUsers')
  const [lockedFilter, setLockedFilter] = useState('allUsers')
  const [sort, setSort] = useState({ field: '_id', order: 'DESC' })
  const [pagingLimit, setPagingLimit] = useState(50)

  const { data, loading, refetch, fetchMore } = useQuery(GET_USERS, {
    variables: {
      paging: { offset: 0, limit: pagingLimit },
      searchFilter: search,
      ordering: [sort],
      roleFilter,
      lockedFilter,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: users, hasMore } = data?.getAllUsersAdmin || {}

  return (
    <IndexStyled>
      <FiltersAndSearch
        setSearch={setSearch}
        sort={sort}
        setFilter={setRoleFilter}
        refetch={refetch}
        filters={{ search, roleFilter, lockedFilter }}
        setLockFilter={setLockedFilter}
        setPagingLimit={setPagingLimit}
        setRoleFilter={setRoleFilter}
      />
      {users && (
        <Table
          sort={sort}
          setSort={setSort}
          refetch={refetch}
          users={users}
          filters={{ search, roleFilter, lockedFilter }}
          setPagingLimit={setPagingLimit}
        />
      )}
      <LoadMore
        hasMore={hasMore}
        loader={fetchMore}
        offset={users?.length}
        loading={loading}
      />
    </IndexStyled>
  )
}

export default ManageUsers
