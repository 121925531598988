import styled from '@emotion/styled'

export const StyledAddNewBtn = styled.button`
  border-radius: 30px;
  border: 2px solid var(--flavor);
  cursor: pointer;
  padding: 6px 25px;
  height: 40px;
  max-height: 42px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--flavor);
  color: var(--flavor-contrast);
  transition: 0.2s ease-in-out;

  :hover {
    background: transparent;
    color: var(--foreground);
    p {color: var(--foreground);}
  }

  p {
    color: var(--flavor-contrast);
    margin: 0;
  }

  i {
    font-weight: 700;
    margin-right: 5px;
  }
`
