import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'

import { GET_ATTRIBUTES } from '../graph-queries/attributes'

import { MainWrapper } from '../styles'
import { useIntl } from 'react-intl'
import SectionRenderer from '../shared/section-renderer'

const mapStateToProps = ({ user }) => ({ user })

const CustomAttributes = ({ user, dispatch }) => {
  const [errors, setErrors] = useState({})
  const [isOpen, setIsOpen] = useState(true)
  const intl = useIntl()

  const { data } = useQuery(GET_ATTRIBUTES, {
    variables: { usedBy: ['people'] },
    fetchPolicy: 'cache-and-network',
  })

  const isAttributeObject = (attribute) => {
    return (
      attribute.type === 'location' ||
      attribute.type === 'badge' ||
      attribute.type === 'file'
    )
  }

  const saveValue = (attribute, value) => {
    const c = user.profile.customAttributes.filter(
      (ca) => ca.key !== attribute.key
    )
    const isObject = isAttributeObject(attribute)
    let stringObject = ''
    if (isObject) stringObject = JSON.stringify(value)
    else if (attribute.type === 'boolean') stringObject = value.toString()
    else stringObject = value
    c.push({ key: attribute.key, value: stringObject, type: attribute.type })
    const p = {
      ...user.profile,
      customAttributes: c,
    }
    saveProfile(p)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const handleError = (attr, message) => {
    setErrors({ ...errors, [attr.key]: message })
  }

  const sectionTitle = intl.formatMessage({
    id: 'user.profile.customatributes',
  })

  if (!data || !data.getAttributes || !data.getAttributes.length) return null

  const section = data.getAttributes.map((attr) => {return {...attr, md: 12}})

  return (
    <MainWrapper>
      <SectionRenderer
        sectionTitle={sectionTitle}
        section={section || []}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        user={user}
        errors={errors}
        additionalData={{
          isCustomAttr: true,
          saveHandler: (attr, v) => saveValue(attr, v),
          errorHandler: (attr, message) => handleError(attr, message),
        }}
      />
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(CustomAttributes)
