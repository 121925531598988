import React from 'react'
import BaseNotification from './default'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

export const ContentStudioNotification = ({ item, deleteAction }) => {
  const { user, notification } = item
  const intl = useIntl()

  const image = notification?.payload[0]?.image
  const contentId = notification?.payload[0]?.value
  const contentName = notification?.subtype?.replace('-ownership-change', '')
  const contentLinkPath =
    contentName === 'activity'
      ? '/activities/list/'
      : contentName === 'blog'
      ? '/blog/'
      : contentName === 'company'
      ? '/companies/list/'
      : '/opportunities/list/'

  const ContentComponent = () => (
    <span>
      {user?.name} {' '}

        {intl.formatMessage({ id: 'ownership-change.notification.description' })} {' '}
        
      <Link to={`${contentLinkPath}${contentId}`}>{contentName}</Link>.
    </span>
  )

  return (
    <BaseNotification
      image={image}
      item={item}
      ContentComponent={ContentComponent}
      singleButtonAction={() => null}
      hideSender={true}
      deleteAction={deleteAction}
    />
  )
}

