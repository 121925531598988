import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { ExcerptWrapper } from './styles/excerpt'

const Excerpt = ({ excerpt, onSave }) => {
  const intl = useIntl()
  const [value, setValue] = useState(excerpt || '')
  const [charLeftCounter, setCharLeftCounter] = useState(
    100 - excerpt.length || 100
  )
  const handleCharCounter = (e) => {
    const charLeft = 100 - e.target.value.length
    setCharLeftCounter(charLeft)
  }

  return (
    <ExcerptWrapper>
      <div className="label-wrapper">
        <label htmlFor="excerpt-content">
          {intl.formatMessage({
            id: 'events.createevent.general.shortdescription.title',
          })}
          <span className="required-star">*</span>
        </label>
        <p className="excerpt-content-desc">
          {intl.formatMessage({
            id: 'events.createevent.general.shortdescription.description',
          })}
        </p>
      </div>
      <div className="excerpt-content-wrapper">
        <textarea
          className="excerpt-content"
          id="excerpt-content"
          placeholder={intl.formatMessage({
            id: 'events.createevent.general.shortdescription.placeholder',
          })}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            handleCharCounter(e)
          }}
          maxLength="100"
          onBlur={() => onSave(value)}
        />
        <p className="char-counter">
          {intl.formatMessage({
            id: 'events.createevent.general.shortdescription.limitlabel',
          })}
          : {charLeftCounter}
        </p>
      </div>
    </ExcerptWrapper>
  )
}

export default Excerpt
