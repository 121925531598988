import styled from '@emotion/styled'

const SideBarStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  row-gap: 15px;

  .sidebarBox {
    border: solid 1px;
    padding: 7px 16px;
  }

  #dropdownWrapper {
    display: flex;
    .spacer {
      flex-grow: 1;
    }
  }
`

export default SideBarStyled
