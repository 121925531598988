import styled from '@emotion/styled'
import border from '_utils/css-border'
import { ctaButtonHollow } from '_utils/css-buttons'
import { mobile } from '_utils/responsive-queries'

export const Location = styled.div`
  align-self: flex-start;
  font-size: 14px;
  word-break: break-word;
  i {
    margin-right: 10px;
  }
`
export const InfoWrapper = styled.div``
export const Name = styled.h1`
  font-size: 22px;
  font-weight: 700;
  padding: 10px 0;
  color: inherit;
  word-break: break-word;
`
export const Date = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: inherit;
`
export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 25px 0 50px;
  color: inherit;
  word-break: break-word;
`
export const DetailsButton = styled.button`
  ${ctaButtonHollow}
  color: inherit !important;
`
export const EventTileWrapper = styled.div`
  ${border()}
  height: 480px;
  border-radius: 25px;
  background: ${({ imageUrl }) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imageUrl});`};

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 25px 50px;
  overflow: hidden;

  color: white;

  &.grid-span-column-1,
  &.grid-span-column-0 {
    button {
      margin: 0 auto;
    }
  }
  &.grid-span-column-2 {
    ${Name} {
      font-size: 28px;
    }
  }
  &.grid-span-column-3 {
    ${Name} {
      font-size: 32px;
    }
  }

  ${mobile} {
    height: 407px;
  }
`
