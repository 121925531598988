import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'

const EmailButtonWrapper = styled.a`
  position: relative;
  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: space-between;

    i {
      margin: 5px 10px 0 0;
      color: var(--foreground);
    }
  }
`

const ShareWithEmail = ({ title, message, icon }) => {
  const intl = useIntl()
  const titleContant = title ? title : ''
  const messageContent = message
    ? message
    : `${intl.formatMessage({
        id: 'profile.details.sharewithemail.emailbody',
      })} ${window.location.href}`

  return (
    <>
      <EmailButtonWrapper
        href={`mailto:?subject=${encodeURIComponent(titleContant)}&body=${encodeURIComponent(messageContent)}`}
      >
        <button>
          {icon || <i class="far fa-envelope" />}
          <span>Share with email</span>
        </button>
      </EmailButtonWrapper>
    </>
  )
}

export default ShareWithEmail
