import styled from '@emotion/styled'

export const AboutMeWrapper = styled.div`
  width: 100%;
`

export const Header = styled.h3`
  font-size: 30px;
  font-weight: 700;
`

export const BioLongWrapper = styled.div`
  ul,
  ol {
    list-style-position: inside;
  }
`
