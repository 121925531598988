import { v4 as uuid } from 'uuid'

export const mapBlocksToEntity = (data) => {
  try {
    const blocks = JSON.parse(data)?.blocks
    const mappedBlocks = blocks.map((b) => {
      let model
      if (isCustomBlock(b)) {
        const idPattern = 'customblock-'
        const idIndex = b?.search(idPattern)
        const name =
          b
            ?.substring(idIndex, b.length)
            ?.split('"')?.[0]
            ?.replace(idPattern, '') || 'default'

        model = {
          id: uuid(),
          name,
          type: 'custom',
        }
      } else {
        model = {
          id: uuid(),
          type: 'froala',
          content: b,
        }
      }
      return model
    })

    return mappedBlocks
  } catch (e) { 
    console.log('Error while parsing building block data')
    return null 
  }
}

const isCustomBlock = (block) => {
  return !!block.match(/id="customblock-/)
}
