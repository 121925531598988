import styled from '@emotion/styled'

export const ResourcesNavigation = styled.div`
  .admin-dropdown {
    display: flex;
    align-items: center;
    .admin-icon {
      fill: var(--background);
      margin: 0 auto;
      display: block;
      height: 25px;
      margin-bottom: 10px;
    }

    .admin-label {
      display: block;
    }

    .dropdown-menu {
      position: absolute;
      top: ${({ position }) => (position ? '110%' : '110%')};
      left: ${({ position }) => (position ? '-32%' : '-35%')};
      border-radius: 10px;
      background-color: var(--background);
      font-weight: 600;
      &.show {
        min-width: 10rem;
      }
    }
    a.dropdown-link {
      position: relative;
      border-left: 4px solid transparent;
      color: var(--foreground);
      display: block;
      text-align: left;
      font-weight: 600;
      padding: 10px 0;
      padding-left: 20px;
      &:hover {
        background-color: var(--flavor) !important;
        border-left: 4px solid var(--flavor);
        color: var(--foreground);
      }
    }
  }
`
