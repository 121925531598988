import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import InlineMap from '_components/map-inline'

const Props = {
  location: PropTypes.shape({
    lat: PropTypes.string.isRequired,
    long: PropTypes.string.isRequired,
  }),
}

const Map = styled(InlineMap)`
  display: block;
  margin-top: 25px;
  margin-bottom: 125px;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  border: 3px solid white;
  box-shadow: 0px 0px 8px #dcdcdc;
`

const DrilldownMap = ({ location }) => {
  if (parseFloat(location?.lat) && parseFloat(location?.long)) {
    return <Map location={location} />
  }
  return null
}
DrilldownMap.propTypes = Props

export default DrilldownMap
