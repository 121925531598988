import React from 'react'
import {
  AvatarsStyled,
  Multiple,
  Single,
  Status,
  Name,
  OnlineIndicator,
  Avatar,
  MultiImage,
} from './styles/avatars-mini-card'
import { useTheme } from 'emotion-theming'
import scaleSanityImage from 'helpers/scaleSanityImage'
import { getDisplayName } from '_people/people-models'

const AvatarsMiniCard = ({ creator, connections }) => {
  const theme = useTheme()
  const isMultiple = connections?.length > 1
  const limit = 4
  const additionalCount = connections?.length - limit
  const singleAvatar = connections?.length === 1
  ? connections[0].coverPhoto
  : creator?.avatar
  return (
    <AvatarsStyled isMultiple={isMultiple}>
      {isMultiple && (
        <Multiple>
          {connections.slice(0, limit).map((c, i) => {
            const img = c?.coverPhoto
              ? scaleSanityImage({ link: c?.coverPhoto, height: 99 })
              : theme.images.defaultCover
            return <MultiImage index={i} src={img} alt={img} />
          })}
          {connections?.length > limit && (
            <span className="count">{`+${additionalCount}`}</span>
          )}
        </Multiple>
      )}
      {!isMultiple &&  (
        <Single>
          <Avatar>
            <img
              src={
                singleAvatar || "https://storage.googleapis.com/buildtoriot-cdn/not-logged-avatar.jpg"
              }
              alt={"User Avatar"}
            />
            {connections?.length === 0 && <OnlineIndicator onlineStatus={''} />}
          </Avatar>
          <Name>
            {connections?.length > 0
              ? connections[0].name
              : getDisplayName(creator)}
          </Name>
          {connections.length === 0 && <Status>{creator?.bioShort}</Status>}
        </Single>
      )}
    </AvatarsStyled>
  )
}

export default AvatarsMiniCard
