import styled from "@emotion/styled"
import { mobile } from '_utils/responsive-queries'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space);

  outline: var(--border);
  border-radius: var(--radius);
  padding: var(--space-m);
  padding-left: var(--space-m);
  box-shadow: var(--shadow);

  &.closed {
    gap: 0px;
  }

  button {
    align-self: flex-end;
  }

  button.settings {
    position: absolute;
  }
  `
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  aspect-ratio: 1 / 1;

  border-radius: var(--radius);
  border-radius: 100%;
  background: var(--foreground);
  color: var(--background);
  i { font-size: 1.7em; }
`

export const CardHeader = styled.div`
  display: flex;
  gap: var(--space-m);
  transition: .2s;

  .text {
    display: flex;
    gap: var(--space-xs);
    flex-direction: column;
  }

  .text p {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    justify-content: center;
  }

  & > * { line-height: 1; }
  &.closed { transition: .2s .2s; }
  p { 
    margin: 0px; 
    color: var(--shade); 
  }
`

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  grid-template-areas: 
    "url date"
    ". .";

  max-height: 600px;
  overflow: hidden;
  transition: .2s;

  ${mobile} {
    grid-template-areas: 
      "url url"
      ". .";
  }

  &.open {
    border-top: var(--border);
    padding-top: var(--space-s);
    margin-top: var(--space);
  }

  &.open .description {
    margin-top: var(--space);
    margin-bottom: var(--space);
  }

  &.closed { 
    max-height: 0px; 
    transition: .2s; 
  }

  &.closed .description, 
  &.closed p {
    opacity: 0; 
    transition: .2s;
  }

  p {color: var(--shade); margin: 0px; }
  .description, p {
    transition: .2s .1s;
  }

  .description p { color: var(--foreground); max-width: 56ch; }
  .description { grid-column: span 2; }
  & > a p,
  & > p { 
    overflow: hidden;
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dates {
    max-width: 100%;
  }

  ${mobile} {
    p.dates {grid-area: url;}
  }
`