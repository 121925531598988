import styled from '@emotion/styled'
import { css } from '@emotion/core'
import border from '_utils/css-border'
import { circleButton, ctaButton } from '_utils/css-buttons'
import { mobile, pablet, tablet } from '_utils/responsive-queries'
import { themeIsDark } from '_colorBus/primitives/scheme'

export const Avatar = styled.img`
  ${border()}
  border: 5px solid white;
  border-radius: 100%;
  display: block;
  height: 175px;
  width: 175px;
  object-fit: cover;
  flex-shrink: 0;
  ${({ isOnCoverPhoto }) =>
    isOnCoverPhoto &&
    css`
      position: absolute;
      width: 40px;
      height: 40px;
      right: 2%;
      top: 60%;
    `}
  ${mobile} {
    height: 112px;
    width: 112px;
  }
`

export const OnlineIndicator = styled.div`
  ${border()}
  position: absolute;
  top: 130px;
  right: 13px;
  width: 30px;
  height: 30px;
  border: 5px solid white;
  border-radius: 100%;

  background-color: ${({ onlineStatus }) =>
    onlineStatus === 'ONLINE' ? '#4dcea6' : '#4a4a4a'};
  z-index: 1;
  ${mobile} {
    width: 28px;
    height: 28px;
    top: 80px;
    right: 0;
  }
`

export const PersonInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .background-image {
    display: flex;
  }

  .grow {
    flex-grow: 1;
  }

  span {
    color: var(--foreground);
  }


  }
`

export const EditPersonButton = styled.button`
  ${circleButton()};
  border-color: var(--flavor);
  width: 42px;
  padding: 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 25px 0 50px 20px;

  .action-button {
    width: 40px;
    height: 40px;
    margin: 0;
    flex-shrink: 0;
  }
  .connect-button {
    ${ctaButton()};
    margin: 0 !important;

    svg {
      width: 18px;
    }
  }
  .recommend-button-wrapper {
    button {
      i {
        color: var(--foreground);
      }
    }
  }

  ${pablet} {
    justify-content: center;
    padding: 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  ${pablet} {
    margin-top: 105px;
    flex-direction: column-reverse;
    flex-grow: 1;
  }
  ${mobile} {
    margin-top: 80px;
  }
`

export const NameWrapper = styled.div`
  padding: 25px 0 50px;
  font-size: 16px;

  .person-fullname {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 8px;
    color: var(--foreground);
    ${mobile} {
      font-size: 22px;
    }
  }
  .person-location {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;
    width: 40%;
    margin: 7px 20px 0 0;
    color: var(--foreground);

    i {
      color: var(--foreground);
    }
    ${mobile} {
      font-size: 12px;
    }
  }
  .status-wrapper {
    ${mobile} {
      font-size: 14px;
    }
  }
  ${pablet} {
    justify-content: center;
    text-align: center;
  }
`

export const BackgroundImage = styled.img`
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  ${mobile} {
    height: 170px;
  }
`

export const UserInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  max-width: 825px;
  .avatar-wrapper {
    flex-shrink: 0;
    margin: 0 25px 0 0;
    position: relative;
    transform: translateY(-50px);
    .circle-background {
      background-color: white;
      border-radius: 50%;
      position: relative;
    }
  }
  ${tablet} {
    margin: 0 50px;
  }
  ${pablet} {
    flex-wrap: wrap;
    flex-grow: 1;

    .avatar-wrapper {
      position: absolute;
      top: -90px;
      left: 50%;
      transform: translateX(-50%);
      justify-content: center;
      margin: 0;
    }
    .circle-background {
      display: inline-block;
    }
    .personal-info-wrapper {
      flex-grow: 1;
    }
  }
  ${mobile} {
    .avatar-wrapper {
      height: 112px;
      width: 112px;
      top: -55px;
    }
  }
`

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  i {
    margin-right: 4px;
  }

  ${pablet} {
    justify-content: center;
  }
`

export const CircularButton = styled.button`
  border: solid 2px var(--flavor);
  background-color: transparent;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;

  &[disabled] {
    opacity: 0.6;
    background-color: var(--shade);
    border: none;
    cursor: default;
  }

  &:hover :not([disabled]) {
    background-color: var(--flavor);
    color: var(--background);
  }
`

export const DotsButton = styled.button`
  ${circleButton()};
  border-color: var(--flavor);
  transform: rotate(${({ isDropdownOpen }) => isDropdownOpen && '90deg'});
  &:hover {
    transform: rotate(${({ isDropdownOpen }) => isDropdownOpen && '90deg'});
  }
`

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 5px;
  ${pablet} {
    position: static;
  }
`
export const Dropdown = styled.div`
  ${border()}
  z-index: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: ${({ isInputShown }) =>
    isInputShown ? '20px' : '10px 0px 10px 10px'};
  padding: ${({ isInputShown }) => (isInputShown ? '0' : '15px')};
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: 15px;
  background-color: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};

  .inner-suggestions-container {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  ${pablet} {
    border-radius: 20px;
    right: 20vw;
    left: 20vw;
    top: 165px;
  }
  ${mobile} {
    top: 135px;
    right: 10vw;
    left: 10vw;
  }
`
export const InputWrapper = styled.div``

export const DisconnectUser = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
`
