import styled from '@emotion/styled'

export const MessageStyled = styled.div`
  text-align: center;
  padding: 0.5rem;
  i {
    text-align: center;
    font-size: 22px;
    border: 1px solid var(--foreground);
    margin: 0 auto;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 6px;
  }
  .title {
    font-size: 20px;
    margin-top: 10px;
    display: block;
  }
  .description {
    display: block;
    font-size: 14px;
  }
  button {
    padding: 4px 25px;
    border: none;
    opacity: 0.8;
    margin-top: 20px;
    background: var(--flavor);
    color: var(--flavor-contrast);
    &:hover {
      opacity: 1;
    }
  }
`
