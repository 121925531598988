import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

export const Container = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  max-width: 1570px;
  margin: 50px auto 0;
  overflow: hidden;

  /* ${mobile} {
    padding: 50px 0;
  } */
`
