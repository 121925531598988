import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useAppLabel } from '_application'
import { TextSmall, Capitalize } from '_components/text'
import COMPANY_ICON from '_components/icons/company.svg'
import OPPORTUNITY_ICON from '_components/icons/opportunity.svg'
import ACTIVITY_ICON from '_components/icons/activity.svg'
import POST_ICON from '_components/icons/post.svg'
import PAGE_ICON from '_components/icons/page.svg'
import people from '_people/people-models'
import { Link } from 'react-router-dom';

const Item = styled.div`
  align-items: center;
  color: var(--foreground);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 7px 30px;
  transition: 0.3s;
  &:hover {
    color: var(--flavor-shade);
  }
`

const Name = styled.b`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

const AvatarContainer = styled.div`
  background-color: #144552;
  border-radius: 100%;
  border: none;
  display: block;
  flex-shrink: 0;
  height: 55px;
  margin-right: 30px;
  overflow: hidden;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const AvatarIcon = styled.img`
  height: 28px;
`

const Location = styled.span`
  color: ${({ theme }) => theme.colors.brand};
  text-decoration: underline;
`

const getDisplayLocation = (location) => {
  if (!location) {
    return ''
  }
  if (location.city && location.country) {
    if (
      location.city !== 'null' &&
      location.country !== 'null' &&
      location.city !== 'undefined' &&
      location.country !== 'undefined'
    ) {
      return `${location.city}, ${location.country}`
    }
  }
  return ''
}


const AbstractItem = ({
  avatar,
  icon,
  title,
  subtitle,
  type,
  location,
  href,
  testid,
}) => {
  return (
    <Link to={href}>
      <Item data-testid={testid}>
        <AvatarContainer>
          {avatar ? <AvatarImage src={avatar} /> : <AvatarIcon src={icon} />}
        </AvatarContainer>
        <div>
          <div>
            <Name>{title}</Name>
            {subtitle ? ` - ${subtitle}` : null}
          </div>
          <div>
            <TextSmall>
              <Capitalize>{type}</Capitalize>
              {location && ' - '}
              <Location>{location}</Location>
            </TextSmall>
          </div>
        </div>
      </Item>
    </Link>
  )
}
AbstractItem.propTypes = {
  avatar: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  location: PropTypes.string,
}

const UserItem = ({ data: user }) => {
  const appLabel = useAppLabel()
  // Creator is added for all items returned from search and contains either the user, or the creator of the content
  return (
    <AbstractItem
      avatar={user?.creator?.avatar}
      title={people.getDisplayName(user?.creator)}
      subtitle={user?.creator?.companyRole}
      location={getDisplayLocation(user?.creator?.location)}
      href={`/people/list/${user._id}`}
      type={appLabel('people', 'search.type.person')}
      testid={`search-people-${people.getDisplayName(user?.creator)}`}
    />
  )
}

const CompanyItem = ({ data: company }) => {
  const appLabel = useAppLabel()
  return (
    <AbstractItem
      icon={COMPANY_ICON}
      title={company.name || company.title}
      location={getDisplayLocation(company.location)}
      href={`/companies/list/${company._id}`}
      type={appLabel('companies', 'search.type.company')}
      testid={`search-company-${company.name || company.title}`}
    />
  )
}

const OpportunityItem = ({ data: opportunity }) => {
  const appLabel = useAppLabel()

  return (
    <AbstractItem
      icon={OPPORTUNITY_ICON}
      title={opportunity.name || opportunity.title}
      location={getDisplayLocation(opportunity.location)}
      href={`/opportunities/list/${opportunity._id}`}
      type={appLabel('opportunities', 'search.type.opportunity')}
      testid={`search-opportunity-${opportunity.name || opportunity.title}`}
    />
  )
}

const ActivityItem = ({ data: activity }) => {
  const appLabel = useAppLabel()
  return (
    <AbstractItem
      icon={ACTIVITY_ICON}
      title={activity.name || activity.title}
      location={getDisplayLocation(activity.location)}
      href={`/activities/list/${activity._id}`}
      type={appLabel('activities', 'search.type.activity')}
      testid={`search-activity-${activity.name || activity.title}`}
    />
  )
}

const PostItem = ({ data: post }) => {
  const appLabel = useAppLabel()
  return (
    <AbstractItem
      icon={POST_ICON}
      title={post.title}
      location={getDisplayLocation(post.location)}
      href={`/blog/${post._id}`}
      type={appLabel('posts', 'search.type.post')}
      testid={`search-blog-${post.title}`}
    />
  )
}

const PageItem = ({ data: page }) => {
  const appLabel = useAppLabel()
  return (
    <AbstractItem
      icon={PAGE_ICON}
      title={page.title}
      location={getDisplayLocation(page.location)}
      href={`/public/static/${page._id}`}
      type={appLabel('pages', 'search.type.page')}
      testid={`search-page-${page.title}`}
    />
  )
}

const Props = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['USER', 'COMPANY']).isRequired,
  }),
}
export const SearchDropdownItem = ({ data }) => {
  switch (data.type) {
    case 'USER':
      return <UserItem data={data} />
    case 'COMPANY':
      return <CompanyItem data={data} />
    case 'OPPORTUNITY':
      return <OpportunityItem data={data} />
    case 'ACTIVITY':
      return <ActivityItem data={data} />
    case 'POST':
      return <PostItem data={data} />
    case 'PAGE':
      return <PageItem data={data} />
    default:
      return null
  }
}
SearchDropdownItem.propTypes = Props

export default SearchDropdownItem
