import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Loader } from '_components/loading'
import { useAppConfig, useAppLabel } from '_application'
import DiscoverSection from '../components/discover-section'
import EventsGrid from '_events/components/events-grid/events-grid'
import { usePageBuilder } from '_pagebuilder/hooks'

export const GET_EVENTS_PUBLIC = gql`
  query GetAllEvents(
    $paging: PagingInput
    $filter: EventFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllEventsPublic(filter: $filter, paging: $paging, ordering: $ordering) {
      hasMore
      ordering {
        field
        order
      }
      count
      offset
      data {
        id
        title
        bannerImage
        excerpt
        description
        location {
          lat
          long
          address
          type
          link
        }
        start
        end
        doorsOpen
        categories {
          childrenKeys
          key
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 0px 0px 100px;
`

const DiscoverEventsConnected = ({ refId, category, refetchBlocks }) => {
  const appLabel = useAppLabel()
  const config = useAppConfig()
  const [selectedCategory, setSelectedCategory] = useState(
    category ? category : null
  )

  const pagebuilder = usePageBuilder('discovery-page', false)

  const { data, error } = useQuery(GET_EVENTS_PUBLIC, {
    variables: {
      paging: {
        offset: 0,
        limit: 3,
      },
      filter: {
        byCategory:
          selectedCategory === 'All' || !selectedCategory
            ? undefined
            : selectedCategory,
      },
    },
  })

  const [setCustomBlockCategory] = useMutation(gql`
    mutation UpdateContentBlock(
      $entityId: String
      $contentBlockId: String
      $category: String
    ) {
      updateContentBlock(
        entityId: $entityId
        contentBlockId: $contentBlockId
        category: $category
      ) {
        _id
      }
    }
  `)

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setCustomBlockCategory({
        variables: {
          entityId: pagebuilder?.data?._id,
          contentBlockId: refId,
          category: selectedCategory,
        },
      })
        .then(() => refetchBlocks())
        .catch((err) => console.log('error', err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  const events = data?.getAllEventsPublic?.data

  const title = appLabel('eventsPlural', 'events.titleplural')
  const description = appLabel('eventsDescription', 'empty')

  return (
    <div>
      <Container className="sectionContainer page-wrapper stretchColorToScreensize">
        <DiscoverSection
          title={title}
          link="/events/list"
          description={description}
          type="events"
          eventsCategories={config?.eventsCategories}
          selectedCategory={selectedCategory}
          onSelect={cat => setSelectedCategory(cat)}
        >
          {events?.length > 0 ? (
            <Loader loading={!data} error={error}>
              {() => <EventsGrid events={events} />}
            </Loader>
          ) : (
            'No events of this category were found'
          )}
        </DiscoverSection>
      </Container>
    </div>
  )
}

export default DiscoverEventsConnected

