import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

const Attributes = ({ children }) => {
  return <Container>{children}</Container>
}

export default Attributes
