import React from 'react'
import styled from '@emotion/styled'

const Line = styled.div`
  height: 1px;
  background: var(--foreground);
  opacity: 0.5;
  margin: 50px 0;
  width: 80vw;
  max-width: 800px;
`

const BreakLine = () => {
  return <Line></Line>
}

export default BreakLine

