export const preloadVideo = (video, callback) => {
  video.addEventListener('canplaythrough', callback)
}

export const preloadImage = (image, callback) => {
  image.addEventListener('load', callback, false)
}

export const preloadMedia = (media, callback) => {
  media.tagName === 'IMG'
    ? preloadImage(media, callback)
    : preloadVideo(media, callback)
}

export default preloadMedia
