import React, { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-date-picker'
import { TimePicker } from '@patternfly/react-core'
import { EventDatesWrapper } from './styles/event-dates'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { roundTime } from '../../helpers/roundTime'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const EventDates = ({ start, end, doorsOpen, onSave, defaultLocale }) => {
  const [isEndDateValid, setIsEndDateValid] = useState(true)
  const [isStartDateValid, setIsStartDateValid] = useState(true)
  const intl = useIntl()

  useEffect(() => {
    setIsEndDateValid(!moment(end).isBefore(start))
    setIsStartDateValid(!moment(end).isBefore(start))
  }, [start, end])

  const isValidDoorsOpen = roundTime(doorsOpen) <= roundTime(start)

  const prevValues = usePrevious({ start })

  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (
        moment(prevValues?.start).format('YYYY-MM-DD') !==
        moment(start).format('YYYY-MM-DD')
      ) {
        onSave(
          'end',
          new Date(
            `${moment(start).format('YYYY-MM-DD')} ${roundTime(end)}`
          ).toISOString()
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, prevValues?.start])

  return (
    <EventDatesWrapper>
      <div className="label-wrapper">
        <h4 className="title">
          {intl.formatMessage({
            id: 'events.createevent.general.dates.title',
          })}
        </h4>
        <p>
          {intl.formatMessage({
            id: 'events.createevent.general.dates.description',
          })}
        </p>
      </div>

      <div className="start-date-wrapper">
        <h5 className="section-title">
          {intl.formatMessage({
            id: 'events.createevent.general.dates.label.startdate',
          })}
              <span className="required-star">*</span>
        </h5>
        <div className="pickers-wrapper">
          <div className="picker-wrapper">
            <label>
              {intl.formatMessage({
                id: 'events.createevent.general.dates.label.selectstart',
              })}
            </label>
            <DatePicker
              calendarClassName="calendar-wrapper"
              value={new Date(start)}
              showLeadingZeros
              clearIcon={false}
              locale={defaultLocale.replace('_', '-')}
              onChange={(value) => {
                const formattedValue = moment(value).format('YYYY-MM-DD')

                onSave(
                  'start',
                  new Date(
                    `${formattedValue} ${roundTime(start)}`
                  ).toISOString()
                )
                setIsStartDateValid(!moment(end).isBefore(start))
              }}
            />
          </div>
          <div className="timepicker-wrapper">
            <label>
              {intl.formatMessage({
                id: 'events.createevent.general.dates.label.dooropen',
              })}
            </label>
            <TimePicker
              is24Hour
              time={roundTime(doorsOpen)}
              onChange={(value) => {
                onSave(
                  'doorsOpen',
                  new Date(
                    `${moment(start).format('YYYY-MM-DD')} ${value}`
                  ).toISOString()
                )
              }}
            />

            <span className="validation-error">
              {!isValidDoorsOpen &&
                intl.formatMessage({
                  id: 'events.createevent.general.dates.label.dooropen.warning',
                })}
            </span>
          </div>
          <div className="timepicker-wrapper">
            <label>
              {intl.formatMessage({
                id: 'events.createevent.general.dates.label.starttime',
              })}
            </label>
            <TimePicker
              is24Hour
              time={roundTime(start)}
              onChange={(value) => {
                onSave(
                  'start',
                  new Date(
                    `${moment(start).format('YYYY-MM-DD')} ${value}`
                  ).toISOString()
                )
                setIsStartDateValid(!moment(end).isBefore(start))
              }}
            />
          </div>
        </div>
      </div>

      <div className="end-date-wrapper">
        <h5 className="section-title">
          {intl.formatMessage({
            id: 'events.createevent.general.dates.label.enddate',
          })}
              <span className="required-star">*</span>
        </h5>
        <div className="pickers-wrapper">
          <div className="picker-wrapper">
            <label>
              {intl.formatMessage({
                id: 'events.createevent.general.dates.label.selectend',
              })}
            </label>
            <DatePicker
              calendarClassName="calendar-wrapper"
              value={new Date(end)}
              showLeadingZeros
              clearIcon={false}
              locale={defaultLocale.replace('_', '-')}
              onChange={(value) => {
                const formattedValue = moment(value).format('YYYY-MM-DD')

                onSave(
                  'end',
                  new Date(`${formattedValue} ${roundTime(end)}`).toISOString()
                )
                setIsEndDateValid(!moment(end).isBefore(start))
              }}
            />
          </div>
          <div className="timepicker-wrapper">
            <label>
              {intl.formatMessage({
                id: 'events.createevent.general.dates.label.endtime',
              })}
            </label>
            <TimePicker
              is24Hour
              time={roundTime(end)}
              onChange={(value) => {
                onSave(
                  'end',
                  new Date(
                    `${moment(end).format('YYYY-MM-DD')} ${value}`
                  ).toISOString()
                )
                setIsEndDateValid(!moment(end).isBefore(start))
              }}
            />
          </div>
        </div>
      </div>
      <span className="validation-error-bottom">
        {!isEndDateValid &&
          !isStartDateValid &&
          intl.formatMessage({
            id: 'events.createevent.general.dates.label.dates.warning',
          })}
      </span>
    </EventDatesWrapper>
  )
}

export default EventDates
