import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

export const ToggleButton = themable('ToggleButton', Props)
export default ToggleButton
