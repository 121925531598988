import React from 'react'
// import styled from '@emotion/styled'

import BackgroundImage from './background-image'
import CommonAttributes from './common-attributes'
import CustomAttributes from './custom-attributes'

const PersonalDetails = () => {
  return (
    <div>
      <BackgroundImage />
      <CommonAttributes />
      <CustomAttributes />
    </div>
  )
}

export default PersonalDetails
