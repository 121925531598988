import React from 'react'
import { connect } from 'react-redux'

import Selector from 'components/indy/shared/ui-kit/selectors/multi-select-with-icon'

import KeywordGenericStyled from './styles/keyword-generic'

const mapStateToProps = ({ user }) => ({ user })

const KeywordGeneric = ({ step, stepList, user, dispatch }) => {
  const keyword = stepList[step.currentStep]

  const handleKeyword = (values) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: {
          ...user.profile,
          keywords: [
            ...user.profile.keywords.filter((k) => k.key !== keyword?.key),
            {
              key: keyword?.key,
              values: values.map((v) => {
                return { value: v, type: 'internal' }
              }),
            },
          ],
        },
      },
    })
  }

  return (
    <KeywordGenericStyled>
      <div className="step-content-container background">
        <div className="tiles gender">
          <span className="title-main"></span>
          <Selector
            items={keyword?.keywordList.map((k) => {
              return { key: k?.id, name: k?.value }
            })}
            selectedValues={
              user.profile?.keywords
                .filter((k) => k.key === keyword.key)[0]
                ?.values?.map((v) => v.value) || []
            }
            onSelect={(v) => handleKeyword(v)}
          />
        </div>
      </div>
    </KeywordGenericStyled>
  )
}

export default connect(mapStateToProps)(KeywordGeneric)
