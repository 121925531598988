import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { useIntl, FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { sendNotification } from '_components/buttons-connections/message-helper'
import BasicInput from '_components/buttons-connections/styles/basic-input-styles'
import { INVITE_APPLICATION_MANAGER } from '../opportunity-applications-management/gql'

const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      acceptedUserConnections {
        status
        owner
        connectedUser {
          name
          sub
          _id
          avatar
        }
      }
    }
  }
`

const SingleResult = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }
`
const ResultWrapper = styled.div`
  padding: 10px 15px 15px;
`
const InputStyled = styled(BasicInput)`
  ${border()}
  height: 42px;
  width: 100%;
  padding: 0 20px;
`
const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  &.inner-suggestions-container {
    background-color: var(--background);
  }

  .input-container {
    width: 100%;
  }
`
const RecommendBtn = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  width: fit-content !important;
  align-items: center;
  i {
    margin-right: 10px;
  }
`

export const InviteApplicationManagerButton = ({ opportunity }) => {
  const [isInputShown, setIsInputShown] = useState(false)
  const intl = useIntl()
  // const [showInput, setShowInput] = useState(false)
  const [query, setQuery] = useState('')
  const { user } = useSelector(state => state)
  const [people, setPeople] = useState([])

  const { data } = useQuery(GET_USERS_CONNECTIONS, {
    variables: {
      ids: [user.sub],
    },
  })

  const { acceptedUserConnections: connectedUsers } = data
    ?.getUserConnectionsPublic[0]
    ? data?.getUserConnectionsPublic[0]
    : ''

  const [inviteApplicationManager, { loading }] = useMutation(
    INVITE_APPLICATION_MANAGER
  )

  const addButton = intl.formatMessage({
    id: 'default.add.button',
  })
  const searchPlaceholder = intl.formatMessage({
    id: 'search.recommend.placeholder',
  })

  useEffect(() => {
    setPeople(
      connectedUsers?.filter(
        u =>
          query?.length > 0 &&
          `${u?.connectedUser[0]?.name}`
            ?.toLowerCase()
            ?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [connectedUsers, query, setPeople])

  const onClose = () => {
    if (isInputShown) setIsInputShown(false)
  }

  const onRecommend = person => {
    const { name, _id: userId } = person?.connectedUser[0]

    inviteApplicationManager({
      variables: {
        opportunityId: opportunity.id,
        userId,
      },
      refetchQueries: ['GetOpportunity'],
    })

    // const recommendType = resolveRecommendType(queryKey)

    // recommend({
    //   variables: {
    //     documentToRecommend: contentToRecomment,
    //     recipient: sub,
    //     recommendType: recommendType,
    //     isPrivate,
    //   },
    // })
    setIsInputShown(false)
    sendNotification({
      type: 'invitation',
      intl,
      name,
      defaultHeader: 'Invitation sent',
      defaultContent: 'An invitation has been sent.',
    })
  }

  const ref = useClickOutside(onClose)

  if (isInputShown) {
    return (
      <ItemWrapper className="inner-suggestions-container">
        <div className="input-container" ref={ref}>
          <InputStyled
            className="input-field"
            placeholder={searchPlaceholder}
            type="text"
            onKeyUp={e => setQuery(e.target.value)}
            onBlur={() => people?.length === 0 && setIsInputShown(false)}
            autoFocus
          />
          {people?.length > 0 && (
            <ResultWrapper>
              {people?.map(p => {
                return (
                  <SingleResult>
                    <span className="name">{p?.connectedUser[0]?.name}</span>
                    <button
                      className="add-button"
                      type="button"
                      disabled={loading}
                      onClick={() => onRecommend(p)}
                    >
                      {addButton}
                    </button>
                  </SingleResult>
                )
              })}
            </ResultWrapper>
          )}
        </div>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper className="recommend-button-wrapper">
      <RecommendBtn onClick={() => setIsInputShown(true)}>
        {/* <i class="fas fa-plus" /> */}
        <FormattedMessage id="global.button.addnew" />
      </RecommendBtn>
    </ItemWrapper>
  )
}
