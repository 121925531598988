import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from "@emotion/styled"

import { IndyButton } from '_components/indyButton'
import Cookies from 'js-cookie'

const CookieWrapper = styled.div`
  display: flex;
  justify-content: center;

  position: fixed;
  bottom: 16px;
  width: 100vw;
  z-index: 2147483004;
`

const CookieModal = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-m);

  width: 60em;
  max-width: 90vw;

  background: var(--shade);
  border: var(--border);
  border-radius: var(--radius);
  padding: var(--space-m) var(--space-m);

  @media (max-width: 830px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    p {margin: 0px;}
  }
`

export const CookieAccept = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(Cookies.get('ircookie'))

  const clickAcceptCookies = () => {
    setAcceptedCookies(true)
    Cookies.set('ircookie', true)
  }

  return (
    <>
      {!acceptedCookies && (
        <CookieWrapper>
          <CookieModal>
            <div className="content">
              <p><b>Your Privacy</b></p>
              <p>This website uses cookies to personalize and optimize
                our user experience. Some cookies are necessary for your
                safety and to help our website work properly and
                can&apos;t be turned off. By clicking OK you agree to
                this, as outlined in our Cookie Policy that you can read{' '}
                <Link to="/public/cookie-policy">here</Link>.</p>
            </div>
            <IndyButton
              onClick={() => clickAcceptCookies()}
              type="button"
            >
              OK
            </IndyButton>
          </CookieModal>
        </CookieWrapper>
      )}
    </>
  )
}
