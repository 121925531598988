import React, { lazy } from 'react'
import { connect } from 'react-redux'

import SocialMediaStyled from './styles/social-media'

const mapStateToProps = ({ user }) => ({ user })

const components = {
  phone: lazy(() => import(`components/indy/shared/ui-kit/inputs/phone`)),
  text: lazy(() => import(`components/indy/shared/ui-kit/inputs/text`)),
}

const inputs = [
  { key: 'phone', name: 'Phone number', type: 'phone' },
  { key: 'linkedIn', name: 'LinkedIn', type: 'text' },
  { key: 'twitter', name: 'Twitter', type: 'text' },
  { key: 'instagram', name: 'Instagram', type: 'text' },
]

const SocialMedia = ({ user, dispatch }) => {
  const handleForm = (val, type) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: val },
      },
    })
  }

  return (
    <SocialMediaStyled>
      <div className="step-content-container background">
        {inputs.map((input) => {
          const Comp = components[input.type]
          return (
            <Comp
              input={{
                ...input,
                value: user.profile[input.key],
                placeholder: `https://www.${input.key.toLowerCase()}.com/`,
              }}
              onBlurCallback={(val) => handleForm(val, input.key)}
              size="m"
              label={input.name}
            />
          )
        })}
      </div>
    </SocialMediaStyled>
  )
}

export default connect(mapStateToProps)(SocialMedia)
