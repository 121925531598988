/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { useUser } from '_security/session-hooks'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import ApplyForm from './apply-form'
import { notification } from 'antd'
import GridLoader from 'react-spinners/GridLoader'
import { Loader } from './styles'
import moment from 'moment'
import { useQuery, gql } from '@apollo/client'
import styled from '@emotion/styled'

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: var(--background);
  }
`

const ApplicationModal = ({ children, theme, loading }) => {
  const styles = {
    modal: css`
      padding: 0;
      .modal-100w {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        position: relative;
        .modal-body {
          margin: 0 auto;
          display: table;
          max-width: 95%;
          min-width: 90%;
          overflow: hidden;
          padding-bottom: 25px;
          margin-top: 20px;
        }
      }
    `,
  }
  return (
    <StyledModal
      css={styles.modal}
      dialogClassName="modal-100w"
      size="lg"
      onHide={() => {}}
      show
    >
      {loading && (
        <Loader>
          <div className="loader-content">
            <GridLoader size={15} color={theme.colors.buttonColor} loading />
          </div>
        </Loader>
      )}
      <Modal.Body bsClass="custom-modal">{children}</Modal.Body>
    </StyledModal>
  )
}

const GET_REMAINING_APPLICATION_COUNT = gql`
  query GetRemainingApplicationCount {
    getRemainingApplicationCount
  }
`

const Index = ({ opportunity, onClose }) => {
  const intl = useIntl()
  const user = useUser()
  const theme = useTheme()

  const [loading, setLoading] = useState()

  const { data, refetch, loading: remainingApplicationCountLoading } = useQuery(
    GET_REMAINING_APPLICATION_COUNT
  )
  const remainingApplicationCount = !remainingApplicationCountLoading
    ? data?.getRemainingApplicationCount || 0
    : null

  useEffect(() => {
    refetch()
  }, [])

  const getLabel = (label) => {
    return intl.formatMessage({
      id: `applicationsubmission.${label}`,
    })
  }

  const handleSendApplication = async ({
    coverLetter,
    attachments,
    isGdprConsent,
  }) => {
    setLoading(true)
    if (!coverLetter || coverLetter?.replace(/(<([^>]+)>)/gi, '').length <= 0) {
      const notify = {
        message: getLabel('notify.title'),
        description: getLabel('notify.warning'),
        type: 'warning',
      }
      notification[notify.type](notify)
    } else {
      let formatedCoverLetter = coverLetter
      try {
        formatedCoverLetter = coverLetter?.replace(/(<p><\/p>)+/g, '<br />')
      } catch (e) {}

      const host = `${window.location.protocol}//${window.location.hostname}`

      const applicationInfo = {
        coverLetter: formatedCoverLetter,
        fullName: user?.profile?.fullName,
        coverLetterLabel: getLabel('coverletter'),
        aboutLabel: getLabel('about'),
        birthdateLabel: getLabel('birthdate'),
        phoneLabel: getLabel('phone'),
        emailLabel: getLabel('email'),
        applicationTitle: opportunity?.name,
        ...theme.colors,
        opportunityLink: `${host}/opportunities/list/${opportunity?.id}`,
        userProfileLink: `${host}/people/list/${user.profile.id}`,
        isGdprConsent,
        gdprLabel: getLabel('gdpr.label'),
        ownerEmail: opportunity?.creator?.email,
        ownerName: `${opportunity?.creator?.firstName} ${opportunity?.creator?.lastName}`,
        opportunityId: opportunity?.id,
        attachments: JSON.stringify(attachments),
      }

      if (user?.profile?.birthDate)
        applicationInfo.birthDate = moment(user?.profile?.birthDate).format(
          'DD.MM.YYYY'
        )

      const formData = new FormData()

      Object.keys(applicationInfo).map((k) =>
        formData.append(k, applicationInfo[k])
      )

      let request = {
        path: `${process.env.REACT_APP_API_ENDPOINT}/opportunities/send-application`,
        payload: formData,
      }

      const { data } = await axios.post(request.path, request.payload, {
        headers: { authorization: localStorage.getItem('RIOT/token') },
      })
      let notify = {}

      refetch()

      if (data?.error) {
        notify = {
          message: getLabel('notify.title'),
          description: data.error,
          type: 'warning',
        }
        notification[notify.type](notify)
      } else {
        notify = {
          message: getLabel('notify.title'),
          description: getLabel('notify.success'),
          type: 'success',
        }
        notification[notify.type](notify)
        onClose()
      }
    }
    setLoading(false)
  }

  return (
    <ApplicationModal theme={theme} loading={loading}>
      <ApplyForm
        opportunity={opportunity}
        onClose={onClose}
        onSend={(data) => handleSendApplication(data)}
        remainingApplicationCount={remainingApplicationCount}
      />
    </ApplicationModal>
  )
}

export default Index
