import React, { Children } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from './drilldown-section'
import { tablet } from '_utils/responsive'

const Tile = styled.div`
  margin: 0 0px 20px 10px;
`

const SlideContainer = styled.div`
  margin-top: 25px;
`

const Container = styled.div`
  margin: 0 -10px;
  display: flex;
  flex-direction: column;

  .slick-list {
    padding: 10px 15px;
    .slick-track {
      display: flex;
      .slick-slide {
        margin-right: 30px;
      }
    }
  }

  ${tablet} {
    grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  }
`

const Props = {
  children: PropTypes.node,
}

export const DrilldownTilesSection = ({ title, children }) => (
  <div>
    <Section title={title}>
      <SlideContainer>
        <Container>
          {Children.map(children, (child) => (
            <Tile>{child}</Tile>
          ))}
        </Container>
      </SlideContainer>
    </Section>
  </div>
)
DrilldownTilesSection.propTypes = Props

export default DrilldownTilesSection
