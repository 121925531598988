import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import NProgress from 'nprogress'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

import { usePageTitle } from '_application'
import { useSetThemeSettings } from '_application'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

let previousPath = ''
const Layout = ({ children, location: { pathname, search } }) => {
  usePageTitle('Index')
  useSetThemeSettings()
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    // window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)
  // Layout Rendering
  const getLayout = () => {
    if (
      /^\/login(?=\/|$)/i.test(pathname) ||
      /^\/registration(?=\/|$)/i.test(pathname)
    ) {
      return 'auth'
    }
    return 'main'
  }
  const Container = Layouts[getLayout()]
  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    // in other case render previously set layout
    return <Container pathname={pathname}>{children}</Container>
  }
  return <Fragment>{BootstrappedLayout()}</Fragment>
}
export default withRouter(Layout)
