import styled from '@emotion/styled'

export const TransactionListStyled = styled.div`
  margin-top: 25px;
  .payment {
    margin-bottom: 20px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    .section {
      margin-top: 4px;
      .key {
        display: block;
        font-weight: bold;
      }
      .value {
        margin-top: 2px;
        display: block;
        &.capital-case {
          text-transform: capitalize;
        }
        &.upper-case {
          text-transform: uppercase;
        }
        &.date {
          font-size: 16px;
        }
      }
    }
  }
`

export const PaymentHeader = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 97%;
    background-color: rgba(0, 0, 0, 0.08);
  }

  cursor: pointer;
  .info {
    padding: 10px 25px 10px 15px;
    position: relative;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);

    i {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
    }
  }
`

export const PaymentContent = styled.div`
  padding: 10px 15px;
`
