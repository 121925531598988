import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import FilterHeader from '_components/dashboard/dashboard-filter-header'
import { SORT_OPTIONS_PEOPLE } from '_utils/sort'

const Props = {
  filter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
}

const PeopleHeader = ({ filter, onFilterChange }) => {
  const intl = useIntl()
  const options = useMemo(
    () => [
      {
        key: 'connected',
        label: intl.formatMessage({ id: 'people.filter.connectedtome' }),
      },
      ...SORT_OPTIONS_PEOPLE(intl),
    ],
    [intl]
  )
  return (
    <FilterHeader
      basePath="/people"
      filter={filter}
      filterOptions={options}
      onFilterChange={onFilterChange}
    />
  )
}
PeopleHeader.propTypes = Props

export default PeopleHeader
