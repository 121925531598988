import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'
import { CancelSubscriptionModalStyled } from './styles/cancel-subscription-modal'

const CancelSubscriptionModal = ({ onClose, onSuccess, product }) => {
  const intl = useIntl()
  return (
    <Modal size="md" show onHide={onClose}>
      <CancelSubscriptionModalStyled>
        <Modal.Body>
          <span>
            {intl.formatMessage({
              id: 'user.profile.products.subscriptions.cancel.message',
            })}{' '}
            {product?.name}?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="return-button"
            onClick={onClose}
            data-testid="subs-modal-btn-cancel"
          >
            {intl.formatMessage({
              id: 'user.profile.products.subscriptions.cancel.button.back',
            })}
          </button>
          <button
            className="cancel-button"
            onClick={onSuccess}
            data-testid="subs-modal-btn-confirm"
          >
            {intl.formatMessage({
              id: 'user.profile.products.subscriptions.cancel.button.confirm',
            })}
          </button>
        </Modal.Footer>
      </CancelSubscriptionModalStyled>
    </Modal>
  )
}

export default CancelSubscriptionModal
