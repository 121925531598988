import styled from '@emotion/styled'

export const EditorStyled = styled.div`
  margin-left: 10px;
`

export const Section = styled.div`
  margin-bottom: 30px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  .text {
    position: relative;
    margin: 0px;

    transform: translateY(-6px);

    .title {
      font-size: 16px;
      font-weight: bold;
      display: block;
    }

    .subtitle {
      position: absolute;
      top: 17px;
      left: 0px;
      color: var(--shade);
      font-size: 12px;
    }
  }
`

export const SectionWrapper = styled.div`
  margin-top: 8px;
  border-radius: 25px;
  border: 2px solid var(--shade);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const SubSection = styled.div`
  padding: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  .title {
    font-size: 14px;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
  }
  .controls {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    i {
      cursor: pointer;
      padding: 5px;
    }
  }

  .content-container {
    display: inline-block;
    vertical-align: top;
    &.fonts {
      margin-left: 12px;
    }

    &.colors {
      --size: 22px;

      margin-right: 12px;
      width: var(--size);
      height: var(--size);
      border: 1px solid var(--shade);
      border-radius: 100%;
      justify-content: center;
      align-items: center;
    }

    &.interactive {
      poition: relative;

      & > * {
        z-index: 2;
      }

      & > i {
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        background: var(--shade);

        border-radius: 100%;

        width: var(--size);
        height: var(--size);

        transition: 0.2s ease-in-out;
      }

      &:hover:after {
        transform: scale(2);
      }
    }

    .font-selector {
      background: var(--shade);
      border: 1px solid var(--shade);
      border-radius: 6px;
      padding: 0px 6px;
      -webkit-appearance: value;
      -moz-appearance: value;
      appearance: menulist;
    }
  }
`

export const Controls = styled.div`
  .set-active-theme {
    display: block;
    margin-bottom: 20px;
  }
  button {
    display: inline-block;
    margin-right: 20px;
    border: none;
    border-radius: 14px;
    background: var(--shade);
    padding: 6px 22px;
    &:hover {
      opacity: 0.6;
    }
  }
`

export const FlavorArrayWrapper = styled.div`
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
`

export const AddFlavor = styled(SubSection)`
  grid-template-columns: auto auto 1fr;
  background: var(--flavor);
  color: var(--foreground);
  display: inline-block;
  padding: 4px 16px;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 14px;
  .content-container.interactive:hover {
    color: var(--foreground);
    &:after {
      background: var(--foreground);
    }
  }
`
