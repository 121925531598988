import React, { useState } from 'react'
import { useClickOutside } from '../../../components/indy/shared/ui-kit/helpers/click-outside'
import {
  SelectWrapper,
  MultiSelectStyled,
  BadgesWrapper,
} from '../styles/ca-badges'
import BreakLine from './breakline'
import { useIntl } from 'react-intl'

const CustomAttributeBadges = ({
  attribute,
  selectedValues,
  attributeConfig: { onSave, onDelete },
}) => {
  const intl = useIntl()
  const [dropdown, setDropdown] = useState()

  const ref = useClickOutside(() => setDropdown(false))
try {
  const handleOnSelect = (item) => {
    if (selectedValues?.value?.some((v) => v === item.key))
      onSave(
        attribute.key,
        selectedValues?.value?.filter((v) => v !== item.key)
      )
    else onSave(attribute.key, [...(selectedValues?.value || []), item.key])
  }

  return (
    <>
      <SelectWrapper>
        <h4 className="title">{attribute.name}</h4>
        <p className="description">{attribute.description}</p>
        <MultiSelectStyled className={`ui-kit ui-kit-badge-select`}>
          <div className="full-selector" ref={ref}>
            <button
              onClick={() => setDropdown(!dropdown)}
              data-testid="btn-dropdown"
            >
              {`${intl.formatMessage({ id: 'input.multiselect.label' })}`}{' '}
              <i className={`fas fa-chevron-${dropdown ? 'up' : 'down'}`} />
            </button>
            <ul className={`select-dropdown ${dropdown ? 'active' : ''}`}>
              {attribute?.badges.map((item) => {

                return (
                  <li
                    className={
                      selectedValues?.value?.some((v) => v === item.key)
                        ? 'active'
                        : ''
                    }
                    onClick={() => handleOnSelect(item)}
                    key={item.key || item.name}
                    data-testid={`keywords-dropdown-item-${item.name}${
                      selectedValues?.value?.some((v) => v === item.key)
                        ? '-active'
                        : ''
                    }`}
                  >
                    {item.icon && <img alt={item.name} src={item.icon} />}
                    <span>{item.name}</span>
                    <i
                      class={`far fa${
                        selectedValues?.value?.some((v) => v === item.key)
                          ? '-check'
                          : ''
                      }-square`}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        </MultiSelectStyled>
      </SelectWrapper>

      <BadgesWrapper>
        {attribute?.badges.map(
          (badge) =>
            selectedValues?.value?.includes(badge.key) && (
              <div className="badge">
                <button 
                  className="delete-btn"
                  onClick={() => onDelete(attribute.key, badge.key)}
                >
                  <i class="fas fa-times"></i>
                </button>
                <img className="badge-img" src={badge.icon} alt={badge.name} />
                <p className="badge-name">
                  {' '}
                  {badge.name.length > 25
                    ? `${badge.name.substring(0, 25)}...`
                    : badge.name}
                </p>
              </div>
            )
        )}
      </BadgesWrapper>
      <BreakLine />
    </>
  )
} catch (e) {
	console.error(e)
	return null
}
}
export default CustomAttributeBadges
