import { forEach } from 'lodash'
import Multimap from 'multimap'

const ALL_KEY = '__$ALL$__'

export const eventBus = () => {
  const listeners = new Multimap()

  return {
    on: (event, listener) => {
      listeners.set(event, listener)
      return () => listeners.delete(event, listener)
    },
    clearEvent: (event) => {
      listeners.delete(event)
    },
    clear: () => {
      listeners.clear()
    },
    onAll: (listener) => {
      listeners.set(ALL_KEY, listener)
      return () => listeners.delete(ALL_KEY, listener)
    },
    emit: (event, payload) => {
      forEach(listeners.get(event), (listener) => listener(payload))
      forEach(listeners.get(ALL_KEY), (listener) => listener(event, payload))
    },
  }
}

export default eventBus
