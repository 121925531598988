import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
//import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon'
import {
  SelectDirection,
  Dropdown,
  DropdownItem,
  Button,
} from '@patternfly/react-core'
import { DELETE_APPLICATION } from './gql'
import Delete from 'components/indy/admin/manage-data/shared/modals/delete'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

const SidebarDropdown = ({ application }) => {
  const history = useHistory()
  const intl = useIntl()

  const [deleteApplicationQuery] = useMutation(DELETE_APPLICATION)

  const deleteApplication = async () => {
    await deleteApplicationQuery({
      variables: {
        applicationId: application._id,
      },
    })
    history.push(`/opportunities/${application?.parentId}/applications`)
  }

  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(
    false
  )

  const dropdownItems = [
    <DropdownItem onClick={() => setDisplayDeleteConfirmation(true)}>
      Delete
    </DropdownItem>,
  ]
  const [isOpen, setOpen] = useState(false)

  const onSelect = () => {
    setOpen(false)
  }

  const titleId = 'title-id-1'
  return (
    <>
      <Dropdown
        aria-label="Select Input"
        onToggle={setOpen}
        onSelect={onSelect}
        isOpen={isOpen}
        aria-labelledby={titleId}
        dropdownItems={dropdownItems}
        toggle={
          <Button
            className={'riot-btn secondary round'}
            onClick={() => setOpen(!isOpen)}
            variant="plain"
            data-testid="btn-application-threeDots"
          >
            <i class="fas fa-ellipsis-h"></i>
          </Button>
        }
        direction={SelectDirection.down}
        isPlain
      ></Dropdown>
      {displayDeleteConfirmation && (
        <Delete
          onClose={() => setDisplayDeleteConfirmation(false)}
          onDelete={deleteApplication}
          currentData={{
            name: intl.formatMessage(
              {
                id: 'application.management.application.belonging.to.person',
                defaultMessage: "{name}'s application",
              },
              {
                name: application.creator.firstName,
              }
            ),
          }}
          type={null}
        />
      )}
    </>
  )
}

export default SidebarDropdown
