import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'

const style = theme => css`
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 10px;
  background: white;
  color: var(--background);
  box-shadow: 0px 0px 12px rgb(95, 95, 95);
  text-transform: uppercase;
`
const CategoryTag = ({ children, className }) => (
  <button type="button" css={style} className={className}>
    {children}
  </button>
)
CategoryTag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CategoryTag
