import styled from '@emotion/styled'
import BaseStyled from './default'

const AboutStyled = styled(BaseStyled)`
  .about-me {
    span {
      font-size: 15px;
      font-weight: 500;
      color: var(--foreground);
    }
    textarea {
      width: 100%;
      margin: 20px auto;

      display: table;
      border: 1px solid var(--shade);
      background-color: var(--background);
      padding: 4px 10px;
      resize: none;
      border-radius: 8px;
    }
  }
`

export default AboutStyled
