import { ctaButton, ctaButtonHollow } from '_utils/css-buttons'
import styled from '@emotion/styled'
import css from '@emotion/css'


export const IndyHollowButton = styled.button`
  --stroke-color: var(--imgColor);
  ${ctaButtonHollow}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--flavor);

  i {
    margin-right: 5px;
    transition: .4s;
  }
`

export const IndyButton = styled.button`
  --stroke-color: var(--imgColor);
  ${ctaButton}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--flavor);

  i {
    margin-right: 5px;
    transition: .4s;
  }

  ${({ isDisabled }) =>
      isDisabled &&
      css`
        background: var(--shade);
        border-color: var(--foreground-shade);
        cursor: not-allowed;
      `}
`


export const TextButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--background);
  border: none;
  outline: solid 0px var(--shade);
  padding: 0px;
  transition: .1s;

  &:hover p { 
    text-decoration: underline; 
  }

  p { 
    text-decoration: underline; 
    text-underline-offset: 10px;
    margin-bottom: 5px;
    transition: .1s;
  }

  span {
    color: var(--shade);
  }

  i { line-height: 0.8; }
  &:disabled p, &:disabled i { color: var(--shade); }
  .iconBox {  height: 25px; }
`

export default IndyButton