import React, { useMemo, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getPageSizeForScreenSize } from '_tiles/helpers'

import Row from 'react-bootstrap/Row'
import Opportunities from './opportunities'
import People from './people'
import Activities from './activities'
import Companies from './companies'
import Events from './events'

import {
  ResultGridStyled,
  LoadMoreContainer,
  SeparatorCol,
  ButtonCol,
  LoadMoreButton,
  NoResults,
} from './styles'

const Index = ({ markers, visibleIds, pageSize: ps, type }) => {
  const pageSize = ps || getPageSizeForScreenSize({ type, pageType: 'grid' })

  const [offset, setOffset] = useState(0)

  const items = useMemo(() => {
    setOffset(0)
    return (
      markers?.filter((m) => visibleIds.some((id) => id === m?.dataId)) || []
    )
  }, [visibleIds, markers])

  const hasMore = offset + pageSize < items?.length

  const visibleItems = useMemo(() => {
    return items?.slice(0, hasMore ? offset + pageSize : items?.length)
  }, [items, offset, pageSize, hasMore])

  const resolveGrid = () => {
    let Component
    if (type === 'people') {
      Component = People
    }
    if (type === 'activities') {
      Component = Activities
    }
    if (type === 'opportunities') {
      Component = Opportunities
    }
    if (type === 'companies') {
      Component = Companies
    }
    if (type === 'events') {
      Component = Events
    }
    return <Component items={visibleItems} />
  }

  const handleLoadMore = () => {
    setOffset(offset + pageSize)
  }

  if (visibleItems?.length <= 0) {
    return (
      <NoResults>
        <FormattedMessage id="search.noresults" />
      </NoResults>
    )
  }

  return (
    <ResultGridStyled>
      {resolveGrid()}
      {hasMore && <LoadMore onClick={handleLoadMore} />}
    </ResultGridStyled>
  )
}

const LoadMore = ({ onClick }) => {
  const intl = useIntl()

  return (
    <LoadMoreContainer>
      <Row>
        <SeparatorCol className="my-auto">
          <hr />
        </SeparatorCol>
        <ButtonCol>
          <LoadMoreButton onClick={onClick}>
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i class="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </ButtonCol>
        <SeparatorCol className="my-auto">
          <hr />
        </SeparatorCol>
      </Row>
    </LoadMoreContainer>
  )
}

export default Index
