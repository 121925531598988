import gql from 'graphql-tag'

export const GET_USERS = gql`
  query GetAllUsersList(
    $paging: PagingInput
    $ordering: [OrderingInput]
    $searchFilter: String
    $lockedFilter: String
    $roleFilter: String
  ) {
    getAllUsersAdmin(
      paging: $paging
      ordering: $ordering
      searchFilter: $searchFilter
      lockedFilter: $lockedFilter
      roleFilter: $roleFilter
    ) @connection(key: "GetAllUsersList", filter: ["filter", "ordering"]) {
      offset
      hasMore
      data {
        _id
        firstName
        middleName
        lastName
        sub
        bioShort
        coverPhoto
        dateRegistered
        isLocked
        role
        location
        lastLogin
        primaryEmail
        email
      }
    }
  }
`

export const CHANGE_ROLE = gql`
  mutation ChangeRole($sub: String, $role: String) {
    changeRole(sub: $sub, role: $role) {
      firstName
    }
  }
`

export const LOCK_USER = gql`
  mutation LockUser($sub: String, $locked: Boolean) {
    lockUser(sub: $sub, locked: $locked) {
      firstName
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: String) {
    deleteUser(id: $id)
  }
`
export const GET_PROFILE_DATA = gql`
  query GetProfileData($sub: String) {
    getProfileData(sub: $sub) {
      activities
      opportunities
      companies
      subscriptions
    }
  }
`
