import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { composeWithDevTools } from 'redux-devtools-extension'

import { createBrowserHistory } from 'history'

import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import { IKUIInit } from '@indykiteone/jarvis-sdk-web'

import { Intercom, Mailchimp, Hubspot } from '_plugins'

import { CookiesProvider } from 'react-cookie'
import dotenv from 'dotenv'
import { routerMiddleware } from 'connected-react-router'
import qs from 'query-string'

import { ApolloProvider } from '@apollo/client'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import { call, put } from 'redux-saga/effects'

import Localization from './localization'
import GoogleAnalytics from './google-analytics'
import Router from './router'
import * as serviceWorker from './serviceWorker'

import Splash from './splash/Splash'
import { client as apolloClient } from './apollo-graph'

import { refreshToken } from '_application/refresh-token'

import { ThemeLoader } from '_themes'
import { AppConfigLoader } from '_application'
import { InitializeMeet, MonitorUserOnlineStatus } from '_meet'
import ErrorHandlerComponent from '_error-handler'
import ThreeDSecureConfirm from 'components/indy/user/profile/payment/components/three-d-secure-confirm'
import { sentryInit } from '_utils/sentry-init'
import InitialLoading from '_components/initial-loading'

// import { nnNOLocale } from '@indykiteone/jarvis-sdk-web/lib/services/core/locale/nn-NO'
// import { nbNOLocale } from '@indykiteone/jarvis-sdk-web/lib/services/core/locale/nb-NO'
// import { enUSLocale } from '@indykiteone/jarvis-sdk-web/lib/services/core/locale/en-US'
// import { enGBLocale } from '@indykiteone/jarvis-sdk-web/lib/services/core/locale/en-GB'
// import { csCZLocale } from '@indykiteone/jarvis-sdk-web/lib/services/core/locale/cs-CZ'

import 'normalize.css'
import 'antd/lib/style/index.less'

import './components/kit/vendors/antd/themes/default.less'
import './components/kit/vendors/antd/themes/dark.less'
import './global.scss'

dotenv.config()
let reduxStore, history, refreshInterval

if (window.location.pathname === '/confirm-three-d-secure') {
  ReactDOM.render(<ThreeDSecureConfirm />, document.getElementById('root'))
} else {
  // middlewared
  history = createBrowserHistory()
  const sagaMiddleware = createSagaMiddleware()
  const routeMiddleware = routerMiddleware(history)
  const middlewares = [sagaMiddleware, routeMiddleware]
  // if (process.env.NODE_ENV === 'development') {
  //   middlewares.push(logger)
  // }
  const middleware = applyMiddleware(...middlewares)
  reduxStore = createStore(
    reducers(history),
    process.env.NODE_ENV === 'production'
      ? compose(middleware)
      : composeWithDevTools(middleware)
  )
  sagaMiddleware.run(sagas)
  
  //TODO: just for testing
  const theme = qs.parse(window.location.search)?.theme

  const IndyKiteUI = (appId, tenantId, config) => {
    return IKUIInit({
      baseUri: process.env.REACT_APP_INDY_BASE_URI,
      applicationId: appId,
      tenantId: tenantId,
      localeConfig: null,
    })
  }

  const isProduction =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
  const emptyComponent = ({ children }) => <>{children}</>
  const ErrorHandler = isProduction ? ErrorHandlerComponent : emptyComponent

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <CookiesProvider>
        <Provider store={reduxStore}>
          <AppConfigLoader>
            {(config) => {
              sentryInit(reduxStore)
              const isAccessRequired =
                config?.validCode !== true && config?.accessPage?.enabled
              let content

              if (isAccessRequired) {
                content = (
                  <ThemeLoader theme={theme || config.theme}>
                    {() => (
                      <ErrorHandler>
                        <Splash config={config} />
                      </ErrorHandler>
                    )}
                  </ThemeLoader>
                )
              } else {
                //Init IndyKite SDK
                const appId =
                  process.env.REACT_APP_INDY_CLIENT_ID ||
                  config?.configurations?.find(
                    (value) => value.kind === 'indykite'
                  )?.properties['appId']
                const tenantId =
                  process.env.REACT_APP_INDY_TENANT_ID ||
                  config?.configurations?.find(
                    (value) => value.kind === 'indykite'
                  )?.properties['tenantId']
                IndyKiteUI(appId, tenantId, config)
                //Get user
                if(localStorage.getItem('RIOT/token') && !reduxStore.getState().user.loaded){
                  reduxStore.dispatch({
                    type: 'user/LOAD_CURRENT_ACCOUNT',
                  })
                }

                if(!refreshInterval){
                  refreshInterval = setInterval(() => {
                    refreshToken({
                      dispatch: reduxStore.dispatch,
                      user: reduxStore.getState().user,
                    })
                  }, 30 * 1000)
                }
                if(reduxStore.getState().user.loading) {
                  return (
                    <Localization>
                      <GoogleAnalytics history={history}>
                        <InitialLoading />  
                      </GoogleAnalytics>
                    </Localization>
                  )
                }
                content = (
                  <>
                    <Intercom>
                      <ThemeLoader theme={theme || config.theme}>
                        {() => (
                          <ErrorHandler>
                            <Router history={history} />
                          </ErrorHandler>
                        )}
                      </ThemeLoader>
                    </Intercom>
                    <>
                      <InitializeMeet />
                      <MonitorUserOnlineStatus />
                      <Mailchimp />
                      <Hubspot />
                    </>
                  </>
                )
              }
              return (
                <Localization>
                  <GoogleAnalytics history={history}>{content}</GoogleAnalytics>
                </Localization>
              )
            }}
          </AppConfigLoader>
        </Provider>
      </CookiesProvider>
    </ApolloProvider>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { reduxStore as store, history }
