import React from 'react'
import SearchInput from '_search/components/search-input'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'

import {
  Results,
  SingleResult,
  Avatar,
  Name
} from '_events/components/event-creator/components/styles/section-with-search'

import { SearchInputWrapper } from './styles/index'

const Search = ({
  loading,
  placeholder,
  searchState: { query, setQuery },
  handleSelect,
  results
}) => {

  const closeOnEsc = (e) => {
    if (e.key === 'Escape') {
      setQuery('')
    }
  }

  const clearQuery = () => setQuery('')

  const ref = useClickOutside(clearQuery)

  const isInputActive = query && query !== ''

  return (
    <SearchInputWrapper ref={ref} isInputActive={isInputActive}>
      <SearchInput
        query={query}
        placeholder={placeholder}
        onChange={e => setQuery(e.target.value)}
        loading={loading}
        onKeyUp={closeOnEsc}
        className={`connections-search-input `}
      />
      {query && results?.length > 0 && (
        <Results>  
          {results.filter(item => item?.creator).map((item) => {
            return (
              <SingleResult onClick={() => handleSelect(item?.creator)}>
                <Avatar src={item?.creator?.avatar} />
                <Name>{item?.creator?.firstName} {item?.creator?.lastName}</Name>
              </SingleResult>
            )
          })}
        </Results>
      )}
    </SearchInputWrapper>

  )
}

export default Search