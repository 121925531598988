import React from 'react'
import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Avatar, OnlineIndicator } from '../styles'
import { ApplicantInfoWrapper, StyledLink } from './styled/applicantInfo'
import { useUserStatus } from '_meet'

const intlKey = 'application.management.'

function ApplicantInfo({ application, isDetailView }) {
  const intl = useIntl()

  const { creator, state } = application
  const { id } = creator
  const { pathname } = useLocation()

  const appliedAt = format(new Date(application.dateCreated), 'dd.MM.yy')

  const onlineStatus = useUserStatus(creator?.id)

  return (
    <StyledLink
      to={
        isDetailView
          ? {
              pathname: `/people/list/${id}`,
            }
          : `${pathname}/detail/${application._id}`
      }
    >
      <ApplicantInfoWrapper>
        <div className="avatar-wrapper">
          <Avatar src={creator.avatar} />
          <OnlineIndicator onlineStatus={onlineStatus} />
        </div>
        <div
          className={
            state && !isDetailView
              ? 'creator-content-wrapper has-state'
              : 'creator-content-wrapper'
          }
        >
          <h4 className="creator-fullname">
            {creator?.firstName}
            {creator?.middleName ? ` ${creator?.middleName} ` : ' '}
            {creator?.lastName}
          </h4>
          <div>
            {creator?.location?.label &&
              creator?.location.label !== 'undefined' && (
                <span className="creator-location">
                  <i class="fas fa-map-marker-alt"></i>{' '}
                  {creator?.location?.label}
                </span>
              )}
            {appliedAt && (
              <span>
                {intl.formatMessage({ id: `${intlKey}applied` })} {appliedAt}
              </span>
            )}
            {state && !isDetailView ? (
              <p className="application-state">
                {intl.formatMessage({ id: `${intlKey}state` })}
                {': '}
                {application.state}
              </p>
            ) : null}
          </div>
        </div>
      </ApplicantInfoWrapper>
    </StyledLink>
  )
}

export default ApplicantInfo
