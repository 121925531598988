import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import { TextareaAutosize } from '@material-ui/core'

import AboutStyled from './styles/about-me'

const mapStateToProps = ({ user }) => ({ user })

const AboutMe = ({ user, dispatch }) => {
  const [bio, setBio] = useState('')
  const handleTextarea = (value, save) => {
    setBio(value)
    if (save) {
      dispatch({
        type: 'user/SET_USER_PROFILE_VALUES',
        payload: {
          ...user,
          profile: { ...user.profile, bioShort: bio },
        },
      })
    } else {
      dispatch({
        type: 'user/SET_USER_PROFILE_VALUES_NOT_SAVE',
        payload: {
          ...user,
          profile: { ...user.profile, bioShort: value },
        },
      })
    }
  }
  const intl = useIntl()

  return (
    <AboutStyled>
      <div className="step-content-container background">
        <div className="about-me">
          <span>
            {intl.formatMessage({
              id: 'user.onboarding.step.aboutme.biography',
            })}
          </span>
          <TextareaAutosize
            maxLength={40}
            defaultValue={user.profile.bioShort}
            onChange={(e) => handleTextarea(e.target.value, false)}
            onBlur={(e) => handleTextarea(e.target.value, true)}
            aria-label="About me"
            rowsMin={3}
            placeholder="Write something about yourself"
          />
        </div>
      </div>
    </AboutStyled>
  )
}

export default connect(mapStateToProps)(AboutMe)
