import React from 'react'

import SubNavigation from '../../shared/components/sub-navigation'
import Routes from './routes'

const defaultUrl = '/manage/site-structure/events'

const navItems = [
  {
    url: `${defaultUrl}/categories`,
    titleKey: 'admin.sitestructure.events.categories',
  },
  {
    url: `${defaultUrl}/access`,
    titleKey: 'admin.sitestructure.events.access',
    defaultMessage: 'Access',
  },
  {
    url: `${defaultUrl}/labels`,
    titleKey: 'admin.sitestructure.events.labels',
  },
]

const Dashboard = () => {
  return (
    <>
      <SubNavigation navItems={navItems} />
      <Routes />
    </>
  )
}

export default Dashboard
