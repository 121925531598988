import React from 'react'
import BaseNotification from './default'
import { useIntl } from 'react-intl'

const People = ({ item, acceptAction, declineAction }) => {
  const intl = useIntl()

  const ContentComponent = () => (
    <span>
      {`${intl.formatMessage({
        id: 'notificationscenter.notification.message.connect',
      })}`}
    </span>
  )

  /*  REF FOR FUTURE NOTIFICATIONS */
  /* <Link to={`/people/list/${item?.notification?.payload?.find(p => p.key === "sub")?.value}`}>
     <ShowMoreWrapper>
       <ShowMore>
         {intl.formatMessage({
           id: 'notificationscenter.notification.button.showmore',
         })}
       </ShowMore> 
     </ShowMoreWrapper>
   </Link>*/

  return (
    <BaseNotification
      item={item}
      acceptAction={acceptAction}
      declineAction={declineAction}
      ContentComponent={ContentComponent}
    />
  )
}

export default People
