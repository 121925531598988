import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { truncate } from 'lodash'
import Tag from '_components/tag'
import CategoryTag from '_components/category-tag'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Modal from 'react-bootstrap/Modal'

import EmailReferalButton from '../email-referal-button'
import RecommendButton from '../buttons-connections/recommend-button'
import InviteButton from '../buttons-connections/invite-button'

const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 450px;
  position: absolute;
  top: 0;
  left: 0;
`

const Avatar = styled.img`
  display: block;
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid white;
  border-radius: 50%;
  margin-top: -25px;
  margin-right: 15px;
  object-fit: cover;
  color: var(--foreground);
`

const HeaderContainer = styled.div`
  padding: 0;
  position: relative;
`

const HeaderSections = styled.div`
  display: flex;
  padding: 10px 15px;
`

const Title = styled.h2`
  display: block;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  font-weight: 500;
  color: var(--foreground);
`

const SubTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  color: var(--shade);
`

const HeaderCategories = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  max-width: 70%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`
const HeaderCategory = styled(Tag)`
  margin-left: 7px;
  margin-bottom: 4px;
`

const styles = {
  modal: css`
    .modal-95vw {
      max-width: 95vw;
      .modal-content {
        border-radius: 30px;
      }
    }
    .modal-1200px {
      max-width: 1200px;
      .modal-content {
        border-radius: 30px;
      }
    }
    .modal-content {
      padding-bottom: 25px;
      background: var(--background);
    }
  `,
  body: css`
    padding: 0px !important;
  `,
  headerTag: css`
    position: absolute;
    left: 15px;
    top: 15px;
  `,
}

const Header = ({
  cover,
  avatar,
  tag,
  categories,
  title,
  subject,
  subtitle,
  children,
  start,
  end,
  sub,
  id,
  buttons,
}) => {
  const theme = useTheme()

  return (
    <HeaderContainer>
      <Cover alt="" src={cover || theme.images.defaultCover} />
      {tag && <CategoryTag css={styles.headerTag}>{tag}</CategoryTag>}
      <HeaderCategories>
        {categories?.map((c) => (
          <HeaderCategory>{c}</HeaderCategory>
        ))}
      </HeaderCategories>
      {false && (
        <HeaderSections>
          <Avatar src={avatar || theme.images.defaultAvatar} />
          <div className="flex-grow-1">
            <Title>{title}</Title>
            <SubTitle>
              {truncate(subtitle, { length: 100, omission: '..' })}
            </SubTitle>
          </div>
          {sub ? (
            <RecommendButton contentToRecomment={sub}></RecommendButton>
          ) : (
            <></>
          )}
          {id ? <InviteButton id={id}></InviteButton> : <></>}
          <EmailReferalButton title={subject}></EmailReferalButton>
          <div>{children}</div>
        </HeaderSections>
      )}
    </HeaderContainer>
  )
}
Header.propTypes = {
  avatar: PropTypes.string,
  children: PropTypes.node,
  cover: PropTypes.string,
  subtitle: PropTypes.string,
  tag: PropTypes.node,
  categories: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

const Props = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  header: PropTypes.any,
}
const DrilldownModal = ({ children, header, onClose }) => (
  <Modal
    dialogClassName="modal-1200px"
    size="lg"
    css={styles.modal}
    onHide={onClose}
    show
  >
    <Modal.Body css={styles.body}>{children}</Modal.Body>
  </Modal>
)
DrilldownModal.propTypes = Props

export default DrilldownModal
