import styled from '@emotion/styled'
import border from '_utils/css-border'

export const MainSection = styled.div`
  margin-bottom: 30px;
  position: relative;
  .title {
    font-size: 20px;
    font-weight: bold;
    display: block;
  }
`

export const TextAreaStyled = styled.textarea`
  border: solid 1px var(--foreground);
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 6px;
  font-size: 14px;
  opacity: 0.8;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.googleFont} !important;
  color: var(--foreground) !important;
  resize: none;
`

export const SubSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  .title,
  .ratio,
  .image-upload-container {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    flex-basis: 100%;
  }
  span.ratio {
    flex-basis: 10%;
  }
  select {
    flex-basis: 15%;
    text-align: center;
    border-radius: 10px;
    border: none;
    padding: 0 10px;
    text-align: center;
    text-align-last: center;
    font-weight: 600;
    background: var(--background);
    &:focus {
      outline: none;
    }
  }
  .description,
  .access-key {
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: var(--flavor);
  }
  button {
    margin-bottom: 20px;
    background-color: var(--background);
    border: none;
    border-radius: 5px;
    padding: 11px 15px;
    font-size: 14px;
  }
  .uploaded-image {
    width: 64px;
    height: 64px;
  }
  img {
    ${border(1, 10, 4, 2)}
    border-radius: 3px;
    width: 64px;
    height: 64px;
  }

  img.logo-4-3 {
    width: calc(64px * 1.3);
  }
  img.logo-16-9 {
    width: calc(64px * 1.8);
  }
  img.background-img {
    height: 150px;
    width: 220px;
  }
`
