import React from 'react'
import styled from '@emotion/styled'

const Line = styled.div`
  height: 1px;
  background: var(--foreground);
  opacity: 0.5;
  width: 100%;
  margin: 50px 0 25px;
`

const BreakLine = () => {
  return <Line></Line>
}

export default BreakLine
