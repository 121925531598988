import styled from '@emotion/styled'

export const ApplyFormStyled = styled.div`
  background-color: var(--background);
  .main-title {
    display: block;
    font-weight: bold;
  }
  .main-description {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const Section = styled.div`
  margin-top: 20px;
  .title {
    font-weight: bold;
    font-size: 18px;
  }
  .description {
    display: block;
    font-size: 14px;
    margin: 0;
  }
  .content {
    margin-top: 10px;
  }
`

export const Footer = styled.div`
  margin-top: 30px;
  text-align: right;
  display: block;
  button {
    font-size: 14px;
    padding: 10px 28px;
    margin-left: 20px;
    border: 1px solid var(--flavor);
    color: var(--flavor);
    background: transparent;
    border-radius: 20px;
    &.submit {
      color: var(--background);
      background: var(--flavor);
    }
    &.disabled {
      background: var(--shade);
      color: var(--foreground);
      border: 1px solid transparent;
    }
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  user-select: none;
  .loader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const GdprContainer = styled.div`
  display: flex;
  padding: 20px 10px;
  font-size: 12px;
  text-align: left;
  max-width: 50%;

  input {
    margin-right: 15px;
  }
`
