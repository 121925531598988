import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import { useIntl } from 'react-intl'
import throttle from 'lodash/throttle'
import './index.scss'

const mapStateToProps = ({ settings, user, appConfig }) => ({
  settings,
  user,
  appConfig,
})

const Index = ({ settings, isStatic, user, pathname, appConfig }) => {
  // const intl = useIntl()
  const navigationItems = [
    { name: 'Discover', link: '/discover' },
    {
      name: appConfig.labelOverrides.activities
        ? appConfig.labelOverrides.activities.name
        : 'Activities',
      link: '/activities',
    },
    {
      name: appConfig.labelOverrides.opportunities
        ? appConfig.labelOverrides.opportunities.name
        : 'Opportunities',
      link: '/public/dashboard/discover/opportunity',
    },
    { name: 'People', link: '/people' },
    { name: 'Companies', link: '/companies' },
  ]
  const [isScrolled, setIsScrolled] = useState()
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }
  const handleScrollBind = throttle(handleScroll, 200)
  useEffect(() => {
    handleScrollBind()
    document.addEventListener('scroll', handleScrollBind, { capture: true})
    return () => {
      document.removeEventListener('scroll', handleScrollBind, { capture: true})
    }
  }, [handleScrollBind])

  // const renderMessageText = (value) => {
  //   return intl.formatMessage({ id: `header.navigation.${value} ` })
  // }

  return (
    <div
      className={`header-container ${isStatic ? 'no-scroll' : ''} ${
        isScrolled || pathname !== '/' ? 'scrolled' : ''
      }`}
    >
      <Container>
        <div className="content">
          <Row>
            <Col className="logo" sm={3} md={1}>
              <Link to="/">
                <img alt="Logo" src={settings.logo} />
              </Link>
            </Col>
            <Col className="navigation" sm={12} md={6}>
              <ul>
                {navigationItems.map((n) => {
                  return (
                    <li>
                      <Link to={`/${n.link}`}>{n.name}</Link>
                    </li>
                  )
                })}
              </ul>
            </Col>
            <div className="login">
              {!user.authorized ? (
                <button type="button">
                  <Link to="/login">Get started</Link>
                </button>
              ) : (
                <button type="button">
                  <Link to="/logout">Logout</Link>
                </button>
              )}
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default connect(mapStateToProps)(Index)
