import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { filters } from './index'

import { UPDATE_REPORT } from './graph-queries'
import { useIntl } from 'react-intl'

import Dropdown from 'react-bootstrap/Dropdown'
import { Row, Col } from 'react-bootstrap'

import ModalStyled from './styles/manage-modal'

const Index = ({ report, onClose }) => {
  const [note, setNote] = useState(report.internalNote || '')
  const [status, setStatus] = useState(report.status)
  const intl = useIntl()

  const [updateReport] = useMutation(UPDATE_REPORT)

  const handleOnClose = () => {
    onClose()
  }

  const handleStatusChange = (s) => {
    updateReport({ variables: { status: s, _id: report._id } })
    setStatus(s)
  }

  const handleSaveNote = () => {
    updateReport({ variables: { internalNote: note, _id: report._id } })
  }

  const { firstName, lastName, avatar } = report.user[0]

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `global.modal.report.label.${value.toLowerCase()}`,
    })
  }

  return (
    <Modal
      dialogClassName="manage-reports-inspect-modal-container"
      show
      onHide={handleOnClose}
      closeButton
    >
      <ModalStyled>
        <Modal.Body className="manage-reports-inspect-modal-body">
          <div className="inspect-report-upper-container">
            <h2 className="inspect-report-title">
              {renderMessageText('reportDetails')}
            </h2>
            <Row className="report-user-info-row">
              <Col className="thumbnail-col my-auto">
                <img alt="" className="user-thumbnail" src={avatar} />
              </Col>
              <Col className="name-col my-auto">
                <p className="user-name">{`${firstName} ${lastName}`}</p>
              </Col>
              <Col className="date-col text-right my-auto">
                <p className="publish-date">
                  {moment(report.dateCreated).format('DD/MM/YYYY HH:mm')}
                </p>
              </Col>
            </Row>
            <div className="message-content-container">
              <label className="report-title">
                {renderMessageText('message')}
              </label>
              <p className="inspect-report-description">{report.message}</p>
            </div>
          </div>
          <hr />
          <div className="inspect-report-bottom-container">
            <h2 className="response-title">{renderMessageText('note')}</h2>
            <div className="message-compose-container">
              <textarea
                onBlur={() => handleSaveNote()}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Internal note"
                rows={4}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="manage-reports-inspect-modal-buttons-container">
            <span className="info">{renderMessageText('information')}</span>
            <div className="button-container w-100">
              <Dropdown>
                <Dropdown.Toggle className="save-button">
                  {intl.formatMessage({ id: `admin.manage.reports.${status}` })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {filters.map((s) => {
                    return (
                      <Dropdown.Item
                        onClick={() => handleStatusChange(s.value)}
                      >
                        {renderMessageText(s.value)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <button
                onClick={() => onClose()}
                className="cancel-button"
                type="button"
              >
                {renderMessageText('close')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </ModalStyled>
    </Modal>
  )
}

export default Index
