import React from 'react'

import SignupForm from './components/signup-form'

import './splash.scss'

function App({ config }) {
  return (
    <div className="splash-screen-container">
      <SignupForm config={config} />
    </div>
  )
}

export default App
