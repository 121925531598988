import React from 'react'
// import styled from '@emotion/styled'
import { Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'

import ScrollToTop from '_components/scroll-to-top'
import { useFilters } from '_utils/filters'

import ActivitiesHeader from './components/activities-header'
import AddNew from './components/activity-new-button'
import ActivitiesMapConnected from './connected/activities-map-connected'

import ActivitiesGridConnected from './connected/activities-grid-connected'
import ActivityDetailsConnected from './connected/activity-details-connected'
import { usePageTitle, useAppLabel } from '_application'

import { useUser } from '_security/session-hooks'
import { Container } from '../_styled-components/shared/section-container'
import ActivityForm from '_content-studio/forms/activity'

const Activities = () => {
  const appLabel = useAppLabel()
  const title = appLabel('activitiesPlural', 'activities.titleplural')
  const description = appLabel('activitiesDescription', 'empty')
  usePageTitle(title)
  const user = useUser()

  const { filter, setFilter } = useFilters()
  return (
    <Switch>
      <Route exact path={['/activities/:section/new', '/activities/:section/:id/edit']} component={ActivityForm} />

      <MainLayout>
        <ScrollToTop />
        <Container className={"page-wrapper"}>
          <PageTitle
            description={description}
            actions={user.authorized && <AddNew />}
          >
            {title}
          </PageTitle>

          <ActivitiesHeader filter={filter} onFilterChange={setFilter} />

          <Switch>
            <Route
              path="/activities/:section/:id"
              render={({ match, history, location }) => (

                <ActivityDetailsConnected
                  id={match.params.id}
                  onClose={() =>
                    history.push(
                      `/activities/${match.params.section}${location.search}`
                    )
                  }
                />
              )}
            />
          </Switch>

          <Switch>
            <Route
              path="/activities/map"
              render={() => <ActivitiesMapConnected filter={filter} />}
            />
            <Route
              path="/activities/list"
              render={() => <ActivitiesGridConnected filter={filter} />}
            />
            <Redirect exact from="/activities" to="/activities/list" />
          </Switch>

        </Container>
      </MainLayout>
    </Switch>
  )
}

export default Activities
