import { gql } from '@apollo/client'

export const CONNECTED_COMPANIES = gql`
  query GetCompanyConnectedCompaniesPaged($id: ID, $paging: PagingInput) {
    getCompanyConnectedCompaniesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECTED_ACTIVITIES = gql`
  query GetCompanyConnectedActivitiesPaged($id: ID, $paging: PagingInput) {
    getCompanyConnectedActivitiesPaged(id: $id, paging: $paging) {
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECTED_USERS = gql`
  query GetCompanyConnectedUsersPaged($id: ID, $paging: PagingInput) {
    getCompanyConnectedUsersPaged(id: $id, paging: $paging) {
      offset
      hasMore
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

export const CONNECTED_OPPORTUNITIES = gql`
  query GetCompanyConnectedOpportunitiesPaged($id: ID, $paging: PagingInput) {
    getCompanyConnectedOpportunitiesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        _createdAt
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
        connectedCompanies {
          id
          coverPhoto
        }
      }
    }
  }
`
