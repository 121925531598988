import React, { useEffect, useState } from 'react'
import { useRouteMatch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const Verify = () => {
  const match = useRouteMatch()
  const [redirect, setRedirect] = useState(false)
  useEffect(() => {
    const { refId } = match.params
    if (refId) {
      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/user/verifyemail/${refId}`)
        .then(() => {
          //redirect user to login page
          setRedirect(true)
        })
        .catch((err) => console.error(err))
    } else {
      console.warn('No ref ID')
    }
  }, [match])
  if (redirect) {
    return <Redirect to="/" />
  }
  return (
    <div>
      <Helmet title="Login" />
      <h2>Redirecting...</h2>
    </div>
  )
}

export default Verify
