import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../shared/styles/sections'
import { useIntl } from 'react-intl'

import LogoShared from './logo-shared'

const Dashboard = () => {
  const intl = useIntl()
  const logoMessage = intl.formatMessage({ id: 'admin.settings.general.logos' })

  return (
    <MainSection>
      <span className="title">{logoMessage}</span>
      <Row>
        <Col sm={12} md={6}>
          <LogoShared type="lightLogo" title="Light logo" />
        </Col>
        <Col sm={12} md={6}>
          <LogoShared type="darkLogo" title="Dark logo" />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
