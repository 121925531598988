import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import { Access } from '../../shared/styles/access'
import { useEntityAccess } from '../../shared/entity-access'
import { useIntl } from 'react-intl'
import { useAppConfig } from '_application'

const Dashboard = () => {
  const intl = useIntl()
  const config = useAppConfig()
  const { isBlogLoggedInAccessEnabled } = config
  const [setEntityAccess, useGetEntityAccess] = useEntityAccess()

  const renderMessageText = (value, defaultMessage) =>
    intl.formatMessage({
      id: `admin.sitestructure.blog.access.${value}`,
      defaultMessage,
    })

  const { doUpdateEntityAccess } = setEntityAccess()

  const handleAction = (detailsRequiresAuth) => {
    doUpdateEntityAccess({ name: 'BLOG', detailsRequiresAuth })
  }

  const entityAccess = useGetEntityAccess('BLOG', 'detailsRequiresAuth')

  const blogDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : isBlogLoggedInAccessEnabled

  return (
    <MainSection>
      <Row>
        <Col sm={12} md={12}>
          <span className="title">{renderMessageText('title')}</span>
          <span className="description">
            {renderMessageText('description')}
          </span>

          <Access>
            <button
              className={blogDetailsRequiresAuth ? 'active' : ''}
              onClick={() => handleAction(true)}
            >
              {renderMessageText('buttons.signed')}
            </button>
            <button
              className={!blogDetailsRequiresAuth ? 'active' : ''}
              onClick={() => handleAction(false)}
            >
              {renderMessageText('buttons.everyone')}
            </button>
          </Access>
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
