import React, { useEffect, useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { mobilePX, tabletPX } from '_utils/responsive-queries'
import SingleMessage from './single-message'
import SingleNotification from './single-notification'
import {
  MainContainer,
  NotificationsWrapper,
  ExpandedWindow,
  Title,
  ItemsList,
  ShowMore,
  ListWrapper,
} from '../styles/nav-notifications'

export const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      avatar
    }
  }
`

const CHANGE_STATUS = gql`
  mutation ChangeStatus(
    $id: String!
    $status: String
    $collection: String
    $notificationId: String
  ) {
    changeConnectionStatus(
      id: $id
      status: $status
      collection: $collection
      notificationId: $notificationId
    )
  }
`
const GET_NOTIFICATIONS = gql`
  query {
    getCurrentUser {
      sub
      notifications {
        notification {
          _id
          sub
          channel
          body
          type
          sentDate
          subtype
          read
          deleted
          payload
        }
        user {
          sub
          firstName
          lastName
          avatar
        }
      }
    }
  }
`

const notificationsTypes = [
  {
    type: 'messages',
    id: 'messages-dropdown',
    icon: 'far fa-comment',
    path: 'messages',
  },
  {
    type: 'notifications',
    id: 'notifications-dropdown',
    icon: 'far fa-bell',
    path: 'notifications-center',
  },
]

const DirectoryLink = ({ children, list, path }) => {
  if (list.length > 0) return <div>{children}</div>
  return <Link to={`/user/${path}`}>{children}</Link>
}

const NavNotifications = ({
  setOpenItemId,
  openItemId,
  messagesList,
  notificationsList,
  isScrollTop,
  refetchNotifications,
}) => {
  const notificationsLimit = 5

  const newestNotifications =
    notificationsList.length > notificationsLimit
      ? notificationsList.reverse().slice(0, notificationsLimit)
      : notificationsList.reverse()

  const newestMessages =
    messagesList.length > notificationsList
      ? messagesList.slice(0, notificationsLimit)
      : messagesList

  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const location = useLocation()

  const [changeConnectionStatus] = useMutation(CHANGE_STATUS, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
refetchNotifications()
  }, [notificationsList, refetchNotifications])

  const renderItems = (type) => {
    if (type === 'messages')
      return newestMessages.map((msg) => (
        <SingleMessage
          key={msg.lastMessage.key}
          userId={msg.conversationUser}
          lastMessage={msg.lastMessage}
          selectedItemId={selectedMessageId}
          setSelectedItemId={setSelectedMessageId}
          isMobileView={isMobileView}
        />
      ))
    if (type === 'notifications')
      return newestNotifications.map((notification) => (
        <SingleNotification
          key={notification.notification._id}
          notification={notification}
          deleteAction={() => {
            changeConnectionStatus({
              variables: {
                id: notification?.notification?.payload.find(
                  (p) => p?.key === 'connection'
                )?.value,
                status: 'cancelled',
                collection: `${
                  notification?.notification?.subtype?.split('-')?.[0]
                }`,
                notificationId: notification.notification._id,
              },
            })
          }}
          acceptAction={() => {
            return changeConnectionStatus({
              variables: {
                id: notification?.notification?.payload.find(
                  (p) => p?.key === 'connection'
                )?.value,
                status: 'accepted',
                collection: `${
                  notification?.notification?.subtype?.split('-')?.[0]
                }`,
                notificationId: notification.notification._id,
              },
            })
          }}
        />
      ))
  }

  let history = useHistory()
  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })
  const isTabletView = useMediaQuery({ query: `(max-width: ${tabletPX}px)` })

  const dropdownOrDirect = (e, id, openItemId, path) => {
    isTabletView
      ? history.push(`/user/${path}`)
      : setOpenItemId(e, id, openItemId)
  }

  const renderButton = (typeObject) => {
    const { id, type, icon, path } = typeObject
    const itemsList = type === 'messages' ? messagesList : notificationsList

    return (
      <MainContainer
        key={id}
        isScrollTop={isScrollTop}
        location={location.pathname}
      >
        <DirectoryLink list={itemsList} path={path}>
          <button
            className="notification-button"
            id={id}
            onClick={(e) => dropdownOrDirect(e, id, openItemId, path)}
          >
            <i className={`${icon} notification-icon`}>
              {itemsList.length > 0 ? (
                <span className="counter">
                  {itemsList.length > 9 ? '9+' : itemsList.length}
                </span>
              ) : null}
            </i>
          </button>
        </DirectoryLink>
        {openItemId === id && (
          <>
            <ExpandedWindow isMobileView={isMobileView} type={type}>
              <ListWrapper>
                <Title>{type}</Title>
                <ItemsList data-testid={`nav-${type}-list`}>
                  {renderItems(type)}
                </ItemsList>
              </ListWrapper>
              <Link to={`/user/${path}`}>
                <ShowMore
                  data-testid={`nav-${type}-dropdown-seeAll`}
                >{`See all ${type}`}</ShowMore>
              </Link>
            </ExpandedWindow>
          </>
        )}
      </MainContainer>
    )
  }

  return (
    <>
      <NotificationsWrapper>
        {notificationsTypes.map((type) => renderButton(type))}
      </NotificationsWrapper>
    </>
  )
}

export default NavNotifications
