import React from 'react'
import CustomAttributeInput from './ca-input'
import CustomAttributePhoneNumberInput from './ca-phone-number-input'
import CustomAttributeBoolean from './ca-boolean'
import CustomAttributesMediaUploader from './media-uploader'
import CustomAttributeBadges from './ca-badges'
import CustomAttributesLocation from './ca-location'
import CustomAttributesDatePicker from './ca-date'

const SingleAttribute = ({ attribute, attributesConfig, attributeValue }) => {
  const _renderAttribute = () => {
    const { type } = attribute

    switch (type) {
      case 'text':
      case 'email':
      case 'url':
        return (
          <CustomAttributeInput
            attributeConfig={attributesConfig[type]}
            attribute={attribute}
            attributeValue={attributeValue}
          />
        )
      case 'phone':
        return (
          <CustomAttributePhoneNumberInput
            attributeConfig={attributesConfig[type]}
            attribute={attribute}
          />
        )

      case 'boolean':
        return (
          <CustomAttributeBoolean
            attributeConfig={attributesConfig[type]}
            attribute={attribute}
            attributeValue={attributeValue}
          />
        )

      case 'badge':
        let parsedValue = []
        try {
          parsedValue = {...attributeValue, value: JSON.parse(attributeValue?.value)}
        }catch(err) {
          parsedValue = attributeValue        
        }

        return (
          <CustomAttributeBadges
            attribute={attribute}
            selectedValues={parsedValue}
            attributeConfig={attributesConfig[type]}
          />
        )

      case 'image':
      case 'file':
        return (
          <CustomAttributesMediaUploader
            attribute={attribute}
            attributeConfig={attributesConfig[type]}
            attributeValue={
              typeof attributeValue?.value === 'string'
                ? { ...attributeValue, value: attributeValue?.value }
                : attributeValue
            }
          />
        )
      case 'location':
        return (
          <CustomAttributesLocation
            attribute={attribute}
            attributeValue={attributeValue}
            attributeConfig={attributesConfig[type]}
          />
        )
        case 'date':
          return (
            <CustomAttributesDatePicker
              attribute={attribute}
              attributeValue={attributeValue}
              attributeConfig={attributesConfig[type]}
            />
          )
      default:
        return <></>
    }
  }

  return <>{_renderAttribute()}</>
}

export default SingleAttribute

