import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { GET_EVENT, GET_ACTIVITY, GET_COMPANY } from '../shared/helpers'
import { useQuery } from '@apollo/client'
import { useAppLabel } from '_application'

const Event = ({
  item,
  acceptAction,
  declineAction,
  isOwnerToPersonConnection,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()

  const itemPayload = item?.notification?.payload

  const connectionType = itemPayload?.some((i) => i.key === 'company')
    ? 'companies'
    : itemPayload?.some((i) => i.key === 'activity')
    ? 'activities'
    : 'people'

  const { data: eventData, loading: eventLoading } = useQuery(GET_EVENT, {
    variables: {
      id:
        item?.notification?.payload?.find((p) => p?.key === 'event')?.value ||
        '',
    },
  })

  // TODO:lukasz to refactor (query skip does not work)

  const { data: activityData, loading: activityLoading } = useQuery(
    GET_ACTIVITY,
    {
      variables: {
        id: item?.notification?.payload?.[0]?.value,
      },
    }
  )

  const { data: companyData, loading: companyLoading } = useQuery(GET_COMPANY, {
    variables: {
      id: item?.notification?.payload?.[0]?.value,
    },
  })

  const { getActivity } = activityData || {}
  const { getCompany } = companyData || {}

  const eventLink = eventData?.getEventDetails?.id

  if (
    !eventData ||
    !activityData ||
    !companyData ||
    eventLoading ||
    companyLoading ||
    activityLoading
  )
    return null

  const ContentComponent = () => (
    <span>
      {`${intl.formatMessage(
        {
          id: `notificationscenter.notification.message.connect.event.${connectionType}`,
        },
        {
          event: `${
            isOwnerToPersonConnection
              ? appLabel('events', 'events.title').toLowerCase()
              : ''
          }`,
        },
        {
          entity:
            connectionType !== 'people'
              ? `${
                  isOwnerToPersonConnection
                    ? appLabel(
                        `${connectionType}`,
                        `${connectionType}.title`
                      ).toLowerCase()
                    : ''
                }`
              : '',
        },
        {
          entityLink:
            connectionType !== 'people' ? (
              <Link
                to={`/${connectionType}/list/${
                  connectionType === 'comapnies'
                    ? getCompany?.id
                    : getActivity?.id
                }`}
              >
                {connectionType === 'comapnies'
                  ? getCompany?.name
                  : getActivity?.name}
              </Link>
            ) : (
              ''
            ),
        }
      )} `}
      <Link to={`/events/list/${eventLink}`}>
        {eventData?.getEventDetails?.title}
      </Link>
    </span>
  )

  return (
    <BaseNotification
      item={item}
      acceptAction={acceptAction}
      declineAction={declineAction}
      ContentComponent={ContentComponent}
    />
  )
}

export default Event
