import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Route } from 'react-router-dom'
import ScrollToTop from '_components/scroll-to-top'
import MainLayout from '_layouts/main'
import LoginRequired from '_security/login-required'
import companies from '_companies'
import people from '_people'
import activities from '_activities'
import opportunities from '_opportunities'
import blog from '_blog'
import DiscoverBanner from './connected/discover-banner-connected'
import DiscoverCompanies from './connected/discover-companies-connected'
import DiscoverOpportunities from './connected/discover-opportunities-connected'
import DiscoverPeople from './connected/discover-people-connected'
import DiscoverActivities from './connected/discover-activities-connected'
import DiscoverNews from './connected/discover-news-connected'
import DiscoverEvents from './connected/discover-events-connected'
import { usePageTitle } from '_application'
import { useSetThemeSettings } from '_application'
import styled from '@emotion/styled'
import { PageBuilderEditor } from '_pagebuilder/editor/page-builder-editor'
import { PageBuilderRenderer } from '_pagebuilder/renderer/page-builder-renderer'
import { PUBLISH_PAGE_BUILDER_DRAFT } from '_pagebuilder/hooks'
import { useUser } from '_security/session-hooks'
import { FormattedMessage } from 'react-intl'
import useIsFeatureOn from '_application/is-feature-on'

import { IndyHollowButton, IndyButton } from '_components/indyButton'

const PagebuilderStateControls = styled.div`
  position: absolute; z-index: 1;
  width: 100vw;
  transform: translateY(calc(-39px - 15px));

  button:not(.filled) {
    border: solid 2px var(--imgColor);
    color: var(--imgColor);
    &:hover {
      border: solid 2px var(--flavor);
    }
  }

  .page-wrapper {
    display: flex;
    column-gap: 10px;
    width: 100%;
  }

  #publish-draft-button {
    margin-left: 4em;
  }

  .pagebuilder-wrapper {
    margin: 0px 100px;
  }
`

const PagebuilderWrapper = styled.div`
`

const Discover = () => {
  const [showEditor, setShowEditor] = useState(false)
  const [isPagebuilderDraft, setIsPagebuilderDraft] = useState(false)
  const { profile } = useUser() || {}
  const editorRef = useRef()

  const isPageBuilderEnabled = useIsFeatureOn('pagebuilder')
  const isEventsEnabled = useIsFeatureOn('events')
  const pageBuilderContextKey = 'discovery-page'

  const [publishDraftMutation] = useMutation(PUBLISH_PAGE_BUILDER_DRAFT)

  const publishDraft = () => {
    publishDraftMutation({
      variables: { contextId: pageBuilderContextKey },
      refetchQueries: ['GetPageBuilderContext'],
    })
    setShowEditor(false)
    setIsPagebuilderDraft(false)
  }

  return (
    <>
      <DiscoverBanner />
      <PagebuilderStateControls className={`${showEditor ? "open" : "closed"}`}>
        <div class="page-wrapper">
          {isPageBuilderEnabled && profile?.role === 'admin' && (
            <>
              <IndyHollowButton onClick={() => setShowEditor(!showEditor)}>
                <FormattedMessage id={!showEditor ? 'edit' : 'cancel'} />
              </IndyHollowButton>
              {showEditor && (
                <IndyButton
                  className="filled"
                  onClick={async () => { 
                    const saveResult = await editorRef.current.save()
                    if (saveResult)
                      setShowEditor(false)
                  }}
                >
                  <FormattedMessage id={'global.button.save'} />
                  {isPagebuilderDraft && ' - draft'}
                </IndyButton>
              )}
              {!showEditor && (
                <IndyHollowButton
                  className={isPagebuilderDraft ? 'filled' : ''}
                  onClick={() => {
                    setIsPagebuilderDraft(!isPagebuilderDraft)
                  }}
                >
                  Draft mode: {isPagebuilderDraft ? 'on' : 'off'}
                </IndyHollowButton>
              )}

              {isPagebuilderDraft && !showEditor && (
                <IndyHollowButton id="publish-draft-button" onClick={publishDraft}>
                  <FormattedMessage
                    id={'publish_draft'}
                    defaultMessage={'Publish draft'}
                  />
                </IndyHollowButton>
              )}
            </>
          )}
        </div>
      </PagebuilderStateControls>
      {isPageBuilderEnabled && (
        <PagebuilderWrapper className={`${showEditor ? "open" : "closed"}`}>
          {showEditor && (
            <PageBuilderEditor
              ref={editorRef}
              draft={isPagebuilderDraft}
              contextId={pageBuilderContextKey}
            />
          )}
          {!showEditor && (
            <PageBuilderRenderer
              draft={isPagebuilderDraft}
              contextId={pageBuilderContextKey}
            />
          )}
        </PagebuilderWrapper>
      )}
      {!isPageBuilderEnabled && (
        <>
          <DiscoverOpportunities />
          {isEventsEnabled && <DiscoverEvents />}
          <DiscoverNews />
          <DiscoverPeople />
          <DiscoverActivities />
          <DiscoverCompanies />
        </>
      )}
    </>
  )
}

const Companies = () => {
  usePageTitle('Discover')
  useSetThemeSettings({ hasNoBackground: true })

  return (
    <MainLayout>
      <ScrollToTop />
      <Route path="/(discover)?" component={Discover} />
      {
        <Route
          path="/discover/news/:id"
          render={({ match, history, location }) => (
            <blog.Details
              id={match.params.id}
              onClose={() => history.push(`/discover`)}
            />
          )}
        />
      }
      <LoginRequired>
        {() => (
          <>
            <Route
              path="/discover/companies/:id"
              render={({ match, history, location }) => (
                <companies.Details
                  id={match.params.id}
                  onClose={() => history.push(`/discover`)}
                />
              )}
            />
            <Route
              path="/discover/people/:id"
              render={({ match, history, location }) => (
                <people.Details
                  sub={match.params.id}
                  onClose={() => history.push(`/discover`)}
                />
              )}
            />
            <Route
              path="/discover/activities/:id"
              render={({ match, history, location }) => (
                <activities.Details
                  id={match.params.id}
                  onClose={() => history.push(`/discover`)}
                />
              )}
            />
          </>
        )}
      </LoginRequired>
      <Route
        path="/discover/opportunities/:id"
        render={({ match, history, location }) => (
          <opportunities.Details
            id={match.params.id}
            onClose={() => history.push(`/discover`)}
          />
        )}
      />
    </MainLayout>
  )
}

export default Companies

