import styled from '@emotion/styled'

export const MainWrapper = styled.div`
  min-height: 90vh;

  .content-wrapper {
    padding: 50px 0;
  }

  .cover-letter-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 18px;
    color: var(--foreground);
  }

  .files-heading {
    font-size: 18px;
    font-weight: 600;
    margin: 45px 0 18px;
    color: var(--foreground);
  }

  .cover-letter-content {
    ul {
      list-style: initial;
    }

    ol {
      list-style: initial;
    }
  }
`
