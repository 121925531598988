import React from 'react'
import PropTypes from 'prop-types'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'
import { Grid, BaseTile } from '_tiles'
import { Default as EventTile } from '../tiles'
import { filterToQueryString } from '_utils/filters'

const gridConfig = {
  hasGutter: true,
}
const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const EventsGridMap = ({ events, filter }) => {
  const queryString = filterToQueryString(filter)

  return (
    <DashboardGridTemp>
      <Grid config={gridConfig}>
        {events.map((event) => {
          return (
            <BaseTile
              url={`/events/list/${event.id}`}
              queryParams={queryString}
              config={defaultConfig}
            >
              <EventTile event={event} className={'grid-span-column-1'} />
            </BaseTile>
          )
        })}
      </Grid>
    </DashboardGridTemp>
  )
}

EventsGridMap.propTypes = {
  companies: PropTypes.array.isRequired,
}

export default EventsGridMap
