import React from 'react'

const SanitiseHTML = ({ html = "" }) => {
  const createDOMPurify = require('dompurify')

  const DOMPurify = createDOMPurify(window)

  const clean = DOMPurify.sanitize(html, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allowfullscreen', 'frameborder', 'target'] })
  return <div dangerouslySetInnerHTML={{ __html: clean }} />
}

export default SanitiseHTML