import React from 'react'

import { SearchStyled } from '../shared/styles/search'
import { FiltersStyled } from '../shared/styles/filters'
import { ExportStyled } from './styles/index'

import { useIntl } from 'react-intl'

import ExportUsers from '_user-export/components'

const FiltersAndSearch = ({
  setSearch,
  setRoleFilter,
  setLockFilter,
  filters,
  setPagingLimit,
}) => {
  const intl = useIntl()

  const handleSearch = (value) => {
    setSearch(value)
    setPagingLimit(50)
  }

  const handleRoleFilter = (value) => {
    setRoleFilter(value)
    setPagingLimit(50)
  }

  const handleLockFilter = (value) => {
    setLockFilter(value)
    setPagingLimit(50)
  }

  const filterList = [
    { name: 'All users', value: 'allUsers' },
    { name: 'Users', value: 'user' },
    { name: 'Content editors', value: 'contentEditor' },
    { name: 'Admins', value: 'admin' },
  ]

  const lockFilterList = [
    { name: 'All users', value: 'allUsers' },
    { name: 'Locked', value: 'locked' },
    { name: 'Active', value: 'active' },
  ]

  const renderMessage = (value) => {
    return intl.formatMessage({
      id: `admin.table.users.${value.toLowerCase()}`,
    })
  }

  const placeholderText = intl.formatMessage({
    id: 'admin.table.users.search.placeholder',
  })

  return (
    <div className="filter-and-search-wrapper">
      <FiltersStyled>
        <span className="title">{renderMessage('roleFilter')}</span>
        <select
          value={filters?.filter}
          onChange={(e) => handleRoleFilter(e.target.value)}
        >
          {filterList.map((f) => {
            return <option value={f.value}>{renderMessage(f.value)}</option>
          })}
        </select>
      </FiltersStyled>
      <FiltersStyled>
        <span className="title">{renderMessage('lockedFilter')}</span>
        <select
          value={filters?.lockFilter}
          onChange={(e) => handleLockFilter(e.target.value)}
        >
          {lockFilterList.map((f) => {
            return <option value={f.value}>{renderMessage(f.value)}</option>
          })}
        </select>
      </FiltersStyled>
      <SearchStyled>
        <span className="title">{renderMessage('searchFilter')}</span>
        <input
          placeholder={placeholderText}
          value={filters?.searchFilter}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </SearchStyled>
      <ExportStyled> 
        <span className="title">{renderMessage('exportOption')}</span>
        <ExportUsers context={"admin"} /> 
      </ExportStyled>
    </div>
  )
}

export default FiltersAndSearch
