import React from 'react'
import mock from '_forum/data/mock'
import { useIntl } from 'react-intl'
import SectionHeader from '_forum/components/section-header'
import LatestPosts from '_forum/components/latest-posts'
import PopularTopics from '_forum/components/popular-topics'
import PopularTags from '_forum/components/popular-tags'
import PopularCategories from '_forum/components/popular-categories'
import TopContibutors from '_forum/components/top-contributors'
import { useQuery, gql } from '@apollo/client'
import {
  HomeViewWrapper,
  ContentWrapper,
  CategoriesSection,
  ContributorsSection,
  SidebarWrapper,
} from './styles/home'

const GET_CATEGORIES = gql`
  {
    getCategories {
      categoriesList {
        name
        description
        cid
        slug
        postCount
        topicCount
        icon
        bgcolor
        color
      }
    }
    getPopularTopics {
      popularTopics {
        topicsList {
          tid
          title
          votes
          postcount
          viewcount
          user {
            picture
          }
        }
      }
    }
    getRecentTopics {
      recentTopics {
        topicsList {
          tid
          title
          timestampiso
          teaser {
            user {
              username
              picture
            }
            content
            timestampiso
          }
        }
      }
    }
    getTags {
      tags {
        tagsList {
          tag
          value
          score
        }
      }
    }
  }
`

const Home = () => {
  const intl = useIntl()
  const { data } = useQuery(GET_CATEGORIES)

  return (
    <HomeViewWrapper>
      <ContentWrapper>
        <CategoriesSection>
          <SectionHeader
            title={intl.formatMessage({
              id: 'forum.homeview.title.categories',
            })}
            buttonAlign="alignRight"
            buttonText={intl.formatMessage({
              id: 'forum.header.discoverall.buttontext',
            })}
            buttonLink="/forum/categories/"
            buttonVariant="secondary"
          />
          <PopularCategories categories={data?.getCategories} />
        </CategoriesSection>
        <ContributorsSection>
          <SectionHeader
            title={intl.formatMessage({
              id: 'forum.homeview.title.topcontributors',
            })}
            buttonAlign="alignRight"
            buttonText={intl.formatMessage({
              id: 'forum.header.discoverall.buttontext',
            })}
            buttonLink="/forum/users/"
            buttonVariant="secondary"
          />
          <TopContibutors users={mock.users} />
        </ContributorsSection>
      </ContentWrapper>
      <SidebarWrapper>
        <div>
          <SectionHeader
            title={intl.formatMessage({
              id: 'forum.homeview.title.lateststopics',
            })}
          />
          <LatestPosts posts={data?.getRecentTopics} />
          <SectionHeader
            title={intl.formatMessage({
              id: 'forum.homeview.title.populartopics',
            })}
            buttonAlign="alignRight"
            buttonText={intl.formatMessage({
              id: 'forum.header.discoverall.buttontext',
            })}
            buttonLink="/forum/popular/"
            buttonVariant="secondary"
          />
          <PopularTopics topics={data?.getPopularTopics} />
          <SectionHeader
            title={intl.formatMessage({
              id: 'forum.homeview.title.toptags',
            })}
            buttonAlign="alignRight"
            buttonText={intl.formatMessage({
              id: 'forum.header.discoverall.buttontext',
            })}
            buttonLink="/forum/tags/"
            buttonVariant="secondary"
          />
          <PopularTags tags={data?.getTags} />
        </div>
      </SidebarWrapper>
    </HomeViewWrapper>
  )
}

export default Home
