import React from 'react'
import PropTypes from 'prop-types'
import { Tile as ActivityTile } from '_activities'

const Props = {
  activity: PropTypes.shape({}).isRequired,
}

const ConnectedActivityTile = ({ activity, url }) => (
  <ActivityTile url={url} activity={activity} tileHeight="547px" />
)
ConnectedActivityTile.propTypes = Props

export default ConnectedActivityTile
