import React from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownSeparator,
} from '@patternfly/react-core'
import CaretDownIcon from '@patternfly/react-icons/dist/esm/icons/caret-down-icon'

class NavBarDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.onToggle = (isOpen) => {
      this.setState({
        isOpen,
      })
    }
    this.onSelect = (event) => {
      this.setState({
        isOpen: !this.state.isOpen,
      })
      this.onFocus()
    }
    this.onFocus = () => {
      const element = document.getElementById('toggle-id')
      element.focus()
    }
  }

  render() {
    const { isOpen } = this.state
    const dropdownItems = [
      <DropdownItem key="link">Link</DropdownItem>,
      <DropdownItem key="action" component="button">
        Action
      </DropdownItem>,
      <DropdownItem key="disabled link" isDisabled href="www.google.com">
        Disabled link
      </DropdownItem>,
      <DropdownItem
        key="disabled action"
        isAriaDisabled
        component="button"
        tooltip="Tooltip for disabled item"
        tooltipProps={{ position: 'top' }}
      >
        Disabled action
      </DropdownItem>,
      <DropdownSeparator key="separator" />,
      <DropdownItem key="separated link">Separated link</DropdownItem>,
      <DropdownItem key="separated action" component="button">
        Separated action
      </DropdownItem>,
    ]
    return (
      <Dropdown
        onSelect={this.onSelect}
        toggle={
          <DropdownToggle
            id="toggle-id"
            onToggle={this.onToggle}
            toggleIndicator={CaretDownIcon}
          >
            Dropdown
          </DropdownToggle>
        }
        isOpen={isOpen}
        dropdownItems={dropdownItems}
      />
    )
  }
}
export default NavBarDropdown
