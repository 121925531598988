import React, { useState } from 'react'

import { useAppLabel } from '_application'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'

import {
  DesktopMenuWrapper,
  Item,
  MobileMenuWrapper,
  ActiveOption,
  OptionsWrapper,
} from './styles/menu'

const menu = [
  { name: 'Forum', path: '/forum/', title: 'Forum' },
  { name: 'Categories', path: '/forum/categories/', title: 'Categories' },
  { name: 'Popular', path: '/forum/popular/', title: 'Popular Topics' },
  { name: 'Tags', path: '/forum/tags/', title: 'Tags' },
  { name: 'Users', path: '/forum/users/', title: 'Users' },
]

const ForumMenu = () => {
  const appLabel = useAppLabel()
  // const location = useLocation()
  // const { pathname } = location

  const [activeItem, setActiveItem] = useState({
    title: 'Forum',
    path: '/forum/',
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const breakToMobilePx = 640

  const isMobileView = useMediaQuery({
    query: `(max-width: ${breakToMobilePx}px)`,
  })

  const handleOptionSelect = (item) => {
    isMobileView && setIsDropdownOpen(false)
    return setActiveItem({ title: item.title, path: item.path })
  }

  return (
    <>
      {/* {isMobileView ? ( */}
      <MobileMenuWrapper isMobileView={isMobileView}>
        <ActiveOption
          isDropdownOpen={isDropdownOpen}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span> {activeItem.title}</span>
          <i className="fas fa-angle-down" />
        </ActiveOption>
        <OptionsWrapper isDropdownOpen={isDropdownOpen}>
          {menu.map(function (object, i) {
            return (
              <Link
                id={object.name}
                to={object.path}
                className="menu-item-link"
                onClick={() => handleOptionSelect(object)}
              >
                <Item
                  key={i}
                  itemPath={object.path}
                  isActive={activeItem.path === object.path}
                  className={`menu-item`}
                >
                  {object.title}
                </Item>
              </Link>
            )
          })}
        </OptionsWrapper>
      </MobileMenuWrapper>
      {/* ) : ( */}
      <DesktopMenuWrapper isMobileView={isMobileView}>
        {menu.map(function (object, i) {
          return (
            <Link to={object.path} className="menu-item-link">
              <Item
                isMobileView={isMobileView}
                key={i}
                itemPath={object.path}
                isActive={activeItem.path === object.path}
                className={`menu-item`}
                onClick={() => handleOptionSelect(object)}
                data-testid={`forum-navlink-${object?.title}`}
              >
                {appLabel(
                  object.title.toLowerCase(),
                  `forum.menu.items.itemname.${object.title
                    .toLowerCase()
                    .split(' ')
                    .join('')}`
                )}
              </Item>
            </Link>
          )
        })}
      </DesktopMenuWrapper>
      {/* )} */}
    </>
  )
}

export default ForumMenu
