import styled from '@emotion/styled'

export const ToggleButton = styled.button`
  border: solid 2px var(--flavor);
  border-radius: 30px;
  background-color: transparent;
  transition: 0.5s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--flavor);
  font-weight: 500;
  font-size: 16px;
  &:hover {
    background-color: var(--flavor);
    border-color: var(--flavor);
    color: var(--background);
  }
`

export default ToggleButton
