import actions from './actions'
import * as jwt from 'services/jwt'

const initialState = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    location: null,
    birthDate: '',
    gender: '',
    avatar: '',
    coverPhoto: '',
    bioShort: '',
    bioLong: '',
    instagram: '',
    twitter: '',
    linkedIn: '',
    phone: '',
    role: '',
    competences: [],
    experience: [],
    languagesSpoken: [],
    languagesWritten: [],
    industryAreas: [],
    termsAndConditionsConsentDate: '',
    education: [],
    customAttributes: [],
    keywords: [],
    taxonomies: [],
  },
  authorized: false, // false is default value
  loading: false,
  registrationErrors: {},
  currentChatRoom: null,
  sub: '',
  loaded: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_REGISTRATION_ERRORS:
      return { ...state, registrationErrors: action.payload }
    case actions.SET_CURRENT_CHAT_ROOM: {
      return { ...state, ...action.payload }
    }
    case actions.SET_USER_PROFILE_VALUES_NOT_SAVE:
      return { ...state, profile: jwt.mapUserAccount(action.payload.profile) }
    default:
      return state
  }
}
