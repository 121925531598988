import React from 'react'

import Generic from './generic'

const Dashboard = () => {
  return (
    <div>
      <Generic />
    </div>
  )
}

export default Dashboard
