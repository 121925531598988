import styled from '@emotion/styled'

import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'

export const MiniCardStyled = styled.div`
  ${border()}
  max-width: 306px;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
`

export const ConnectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  button {
    display: block;
  }
`

export const Location = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 25px 0;
  text-align: center;
  font-size: 12px;
  svg {
    margin-right: 6px;
    path {
      stroke: var(--foreground);
    }
  }
`

export const Avatar = styled.div`
  padding: 25px 71px;
  img {
    ${border()}
    ${imageSkeleton}
    width: 164px;
    height: 164px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const Name = styled.div`
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 0 25px;
`

export const Status = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 0 25px;
`
