import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import { useTheme } from 'emotion-theming'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'

import { pablet, mobilePX } from '_utils/responsive-queries'

import { circleButton } from '_utils/css-buttons'
import generateMargins from '_utils/css-margins'

import ModularIcon from '_components/icons/modular-icon.js'
import { useMediaQuery } from 'react-responsive'

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  position: absolute;
  background: var(--shade);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`

const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  height: ${({isCoverUrl})=> isCoverUrl ? '450px' : '100px' };

  .cover {
    width: 100%;
    height: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.64),
      rgba(84, 84, 84, 0)
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  ${pablet} {
    height: 260px;
  }
`

const ReturnButton = styled.div`
  --stroke-color: var(--imgColor);

  position: absolute;
  z-index: 2;

  top: 20px;
  right: 20px;

  button {
    ${circleButton}
    border: 2px solid var(--stroke-color);
    padding: 0px;
  }
`

const EditButton = styled.button``
const DeleteButton = styled.button``

const EmailButton = styled.a``
const ShowApplicationsButton = styled(Link)(EditButton)

const ButtonsContainer = styled.div`
  --stroke-color: var(--imgColor);
  position: absolute;
  z-index: 2;
  display: flex;

  padding: ${generateMargins().normal};

  top: 0;
  left: 0;
  right: 70%;

  & > * {
    margin-right: ${generateMargins().small};
  }
  .emailTooltip,
  .recommendTooltip {
    border-radius: 10px;
  }
  button {
    --stroke-color: var(--imgColor);
    ${circleButton}
    color: var(--stroke-color) !important;
    border: 2px solid var(--stroke-color) !important;
    &:hover {
      opacity: 0.8;
    }
  }
`

const Header = ({
  coverUrl = '',
  returnText,
  onClose,
  editButton,
  type,
  entityId,
  entity,
  title,
  creator,
  recommendButton,
  email,
  entityType,
  onDelete
}) => {
  const theme = useTheme()
  const user = useUser()
  const intl = useIntl()

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })

  const canEdit =
    entityType !== 'people' &&
    (user?.profile?.role === 'admin' || user?.sub === creator)

  const isCoverUrl =  coverUrl !== ''

  const coverPhoto = coverUrl || theme.images.defaultCover
  const currentUser = user?.profile?.id === creator

  const emailButton = () => {
    return (
      <>
        <EmailButton
          data-for="shareWithEmail"
          href={`mailto:?subject=${encodeURIComponent(title)}&body=${intl.formatMessage({
            id: 'profile.details.sharewithemail.emailbody',
          })} ${window.location.href}`}
          data-tip={intl.formatMessage({
            id: 'people.details.sharewithemail.tooltip',
          })}
        >
          <button>
            <i class="far fa-envelope" />
          </button>
        </EmailButton>
        <ReactTooltip
          effect="solid"
          id="shareWithEmail"
          place="bottom"
          className="emailTooltip"
        />
      </>
    )
  }

  const showApplicationsButton = () => {
    if (entityType !== 'opportunity' || !entity?.isManager || isMobileView) {
      return <></>
    }

    return (
      <>
        <ShowApplicationsButton
          data-for="showApplications"
          to={`/opportunities/${entityId}/applications`}
          data-tip={intl.formatMessage({
            id: 'application.management.applications',
          })}
          data-testid="btn-entity-detail-applications"
        >
          <button>
            <i class="fas fa-list" />
          </button>
        </ShowApplicationsButton>
        <ReactTooltip effect="solid" id="showApplications" place="bottom" />
      </>
    )
  }

  const EditFormButton = () => {

    const location = useLocation()

    return (
      <Link to={`${location.pathname}/edit`}>
        <EditButton
          data-tip={intl.formatMessage({
            id: 'people.details.editbutton.tooltip',
          })}
          data-for="shareWithEmail"
        >
          <i className={`fas fa-pen`} />
        </EditButton>
      </Link>
    )
  }

  const DeleteEntityButton = () => {
    return (
        <DeleteButton
          data-tip={intl.formatMessage({
            id: 'opportunity.details.editbutton.tooltip',
            defaultMessage: 'Delete'
          })}
          data-for="delete"
          onClick={onDelete}
        >
          <i className="fas fa-trash"></i>
        </DeleteButton>
    )
  }

  return (
    <>
      <ButtonsContainer>
        {/* <RecommendButton
          data-tip={intl.formatMessage({
            id: 'people.details.recommenduser.tooltip',
          })}
        > */}
        {recommendButton}
        {!currentUser && emailButton()}
        {canEdit ? (
          <EditFormButton/>
        ) : null}
        {currentUser && entityType === 'people' && (
          <Link to="/user/profile">
            <EditButton
              data-tip={intl.formatMessage({
                id: 'people.details.editbutton.tooltip',
              })}
              data-for="shareWithEmail"
            >
              <i className={`fas fa-pen`} />
            </EditButton>
          </Link>
        )}
        {canEdit ? (
          DeleteEntityButton()
        ) : null}
        {showApplicationsButton()}
      </ButtonsContainer>
      <ReturnButton onClick={onClose}>
        <button data-testid="btn-detailCard-close">
          <ModularIcon exClass={'cross'} />
        </button>
      </ReturnButton>
      <Cover isCoverUrl={isCoverUrl}>
       {isCoverUrl && <Image src={coverPhoto} alt='cover-img'/>} 
        <div className="cover" />
      </Cover>
    </>
  )
}

export default Header