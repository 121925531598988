import styled from '@emotion/styled'

export const TableStyled = styled.div`
  border-collapse: collapse;
  font-family: ${({ theme }) => theme.colors.googleFont};
  color: var(--foreground);
  padding: 25px;
  overflow: auto;
  max-height: 60vh;
  button {
    border: none;
    background-color: transparent;
  }
  tr.first-row {
    border-bottom: 1px solid var(--foreground);
  }
  td,
  th {
    padding: 10px;
  }
  td.register-date {
    min-width: 250px;
  }

  td.limited {
    word-break: break-word;
  }

  i.fas {
    color: var(--flavor);
  }
  span.status {
    background-color: var(--foreground);
    padding: 3px 10px;
    border-radius: 10px;
  }
  td.text-center {
    color: var(--shade);
  }
`
