import React from 'react'
import PropTypes from 'prop-types'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'
import { Grid, BaseTile } from '_tiles'
import { Default as OpportunityTile } from './tiles'
import { filterToQueryString } from '_utils/filters'
import { useGetEntityAccess } from '_security/entity-access'
import { useUserAccess } from '_security/helpers'

const gridConfig = {
  hasGutter: true,
}
const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const OpportunityGrid = ({ opportunities, filter }) => {
  const queryString = filterToQueryString(filter)
  const isAdditionalAuth = useUserAccess()

  const entityAccess = useGetEntityAccess(
    'OPPORTUNITIES',
    'detailsRequiresAuth'
  )

  const opportunityDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  return (
    <DashboardGridTemp>
      <Grid config={gridConfig} testid="opportunities">
        {opportunities.map((opportunity, i) => {
          return (
            <BaseTile
              key={i}
              url={`/opportunities/list/${opportunity.id}`}
              queryParams={queryString}
              config={defaultConfig}
              isAdditionalAuthEnabled={opportunityDetailsRequiresAuth}
              isAdditionalAuth={isAdditionalAuth}
              testid="opportunity"
            >
              <OpportunityTile
                opportunity={opportunity}
                connectButton={''}
                isOwner={''}
              />
            </BaseTile>
          )
        })}
      </Grid>
    </DashboardGridTemp>
  )
}

OpportunityGrid.propTypes = {
  companies: PropTypes.array.isRequired,
}

export default OpportunityGrid
