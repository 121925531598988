import React from 'react'
import DatePicker from '_content-studio/components/date-picker'
import ToggleButton from '_content-studio/components/toggle-button'
import { useAppConfig, useAppLabel } from '_application'
import CustomAttributes from '../../../components/custom-attributes'
import Keywords from '../../../components/keywords'
import Taxonomies from '_content-studio/components/taxonomies'
import { useParams } from 'react-router-dom'
import EntityConnection from '_content-studio/components/entity-connections'
import { useIntl } from 'react-intl'

import ReceiveApplicationBy from '_content-studio/components/receive-applications-by/index' 
 
const AdditionalInformation = ({
  formData,
  changeFormProperty,
  uploadConfig,
  entity
}) => {
  const { defaultLocale } = useAppConfig()
  const intl = useIntl()
  const appLabel = useAppLabel()

  const { connectedCompanies, connectedActivity } = entity || {}

  const { id } = useParams()

  const canRenderConnections = id || formData?.id

  return (
    <>
      {canRenderConnections && (
        <>
          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to activity',
              },
              { entity: appLabel('activities', 'activities.title') }
            )}
            entityType={'activity'}
            entityName={appLabel('activities', 'activities.title')}
            connectionType="o2a"
            id={formData?.id}
            connections={connectedActivity}
          />

          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to company',
              },
              { entity: appLabel('companies', 'companies.title') }
            )}
            entityType={'company'}
            entityName={appLabel('companies', 'companies.title')}
            connectionType="o2c"
            id={formData?.id}
            connections={connectedCompanies}
          />
        </>
      )}

      <DatePicker
        value={formData.expire}
        isRequired={true}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.applyby.title',
            defaultMessage: 'Apply by',
          }),
          description: intl.formatMessage({
            id: 'contentstudio.additional.date.applyby.description',
            defaultMessage:
              'Last chance to apply. It will also be unpublished on this date.',
          }),
          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ expire: value })}
      />

      <DatePicker
        value={formData.start}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.start.title',
            defaultMessage: 'Start (optional)',
          }),
          description: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.start.description',
              defaultMessage: `When the ${appLabel(
                'opportunities',
                'opportunities.title'
              )} starts`,
            },
            { entity: appLabel('opportunities', 'opportunities.title') }
          ),
          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ start: value })}
      />

      <DatePicker
        value={formData.end}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.end.title',
            defaultMessage: 'End (optional)',
          }),
          description: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.end.description',
              defaultMessage: `When the ${appLabel(
                'opportunities',
                'opportunities.title'
              )} ends`,
            },
            { entity: appLabel('opportunities', 'opportunities.title') }
          ),
          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ end: value })}
      />

      <ReceiveApplicationBy
        formData={formData}
        changeFormProperty={changeFormProperty}
      />

      <CustomAttributes
        entityType="opportunities"
        changeFormProperty={changeFormProperty}
        uploadConfig={uploadConfig}
        formData={formData}
      />

      <Keywords
        entityType="opportunities"
        keywords={formData.keywords}
        changeFormProperty={changeFormProperty}
      />

      <Taxonomies
        entityType="opportunities"
        taxonomies={formData?.taxonomy}
        changeFormProperty={changeFormProperty}
      />

      <ToggleButton
        value={formData.isDraft}
        onSave={(value) => changeFormProperty({ isDraft: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.isdraft.toggle.title',
            defaultMessage: 'Is draft?',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.isdraft.toggle.description',
              defaultMessage: `If this ${appLabel(
                'opportunities',
                'opportunities.title'
              )} is draft`,
            },
            { entity: appLabel('opportunities', 'opportunities.title') }
          ),
        }}
      />

      <ToggleButton
        value={formData.private}
        onSave={(value) => changeFormProperty({ private: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.private.toggle.title',
            defaultMessage: 'Private',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.private.toggle.description',
              defaultMessage: `If this ${appLabel(
                'opportunities',
                'opportunities.title'
              )} is private`,
            },
            { entity: appLabel('opportunities', 'opportunities.title') }
          ),
        }}
      />
    </>
  )
}

export default AdditionalInformation

