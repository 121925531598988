import React, { useEffect, useState } from 'react'
import PublicDetailsCard from '_public-details-card'
import { Loader } from '_components/loading'
import { useIntl } from 'react-intl'
import AboutEvent from '../components/event-details/about-event'
import Speakers from '../components/event-details/speakers'
import Taxonomy from '../components/event-details/taxonomy'
import Keywords from '../components/event-details/keywords'
import CustomAttributes from '../components/event-details/new-custom-attributes'
import { useUser } from '_security/session-hooks'
// import SocialMedia from '../components/event-details/social-media'
import {
  GET_EVENT_DETAILS,
  GET_EVENT_CONNECTIONS,
  GET_PUBLIC_EVENT,
} from './graph'
import {
  TopBackButtonWrapper,
  BackgroundImage,
  ContentWrapper,
  TabsWrapper,
  BottomBackButtonWrapper,
  GoBackButton,
  EventWrapper,
  ConnectionsWrapper,
  BackgroundWrapper,
  EventDetailsWrapper,
  BlurBackgroundImageWrapper,
  LoaderWrapper,
} from '_events/components/event-details/styles/event-detail-view'

import EventInformations from '../components/event-details/event-info'
import EventInformationPublic from '../components/event-details/event-info-public'
import Connections from '../components/event-details/connections'

import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import { useAppLabel } from '_application'
import { useQuery } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import EventCategories from '_events/components/event-details/categories'
import ScrollToTop from '_components/scroll-to-top'
// import EditInfo from 'components/indy/shared/edit-info'

const ConditionalWrapper = ({ hasWrapper, children, url }) => {
  return hasWrapper ? <Link to={`${url}`}>{children}</Link> : <>{children}</>
}

// const resolveConnectionType = (type) => {
//   switch (type) {
//     case 'activity':
//       return 'activities'
//     case 'company':
//       return 'companies'
//     default:
//       return null
//   }
// }

const EventDetailView = ({ id, isPreview, event }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const user = useUser()
  const isLoggedIn = user?.profile?.role

  const query = isLoggedIn ? GET_EVENT_DETAILS : GET_PUBLIC_EVENT

  const { data, refetch, loading, error } = useQuery(query, {
    variables: { id },
  })

  const eventQueryResult = isLoggedIn
    ? data?.getEventDetails
    : data?.getPublicEvent

  const eventData = isPreview
    ? { ...eventQueryResult, ...event }
    : {
        ...eventQueryResult,
        topics: eventQueryResult?.topics?.map((topic) => {
          return {
            key: topic?.key,
            childrenKeys: topic?.treeData?.map((item) => item.refId),
          }
        }),
        ...event,
      }

  const { data: eventConnections } = useQuery(GET_EVENT_CONNECTIONS, {
    variables: { id },
  })

  const previewInlineStyles = isPreview && { pointerEvents: 'none' }

  useEffect(() => {
    refetch()
  }, [refetch, data])

  const tabs = {
    about: { type: 'about', translationKey: 'user.profile.about' },
    activity: {
      type: 'connection',
      translationKey: 'activities.titleplural',
      count: eventConnections?.getEEventConnectedActivitiesPaged?.count || '',
    },
    company: {
      type: 'connection',
      translationKey: 'companies.titleplural',
      count: eventConnections?.getEEventConnectedCompaniesPaged?.count || '',
    },
    speakers: {
      type: 'connection',
      translationKey: 'connections.tabs.speakers',
      count: eventConnections?.getEEventConnectedSpeakersPaged?.count || '',
    },
    going: {
      type: 'connection',
      translationKey: 'connections.tabs.going',
      count: data?.getEventDetails?.going || '',
    },
    interested: {
      type: 'connection',
      translationKey: 'connections.tabs.interested',
      count: data?.getEventDetails?.interested || '',
    },
  }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])

  const isEventNotFound = !loading && (eventData == null || !eventData?.title)

  const renderContent = () => {
    const type = tabs[currentTab]?.type

    if (type === 'about')
      return (
        <EventWrapper>
          {/* Events do not have editor property */}
          {/* <EditInfo
            editDetails={{
              authorName: '',
              authorSurname: '',
              date: new Date(),
            }}
          /> */}
          <AboutEvent event={eventData} />
          <Speakers event={eventData} isPreview={isPreview} />
          {isLoggedIn && (
            <EventCategories event={eventData} isPreview={isPreview} />
          )}
          <Keywords event={eventData} isPreview={isPreview} />
          <CustomAttributes attributes={eventData.attributes} />
          <Taxonomy event={eventData} />
          {/* TODO: Social Media - not for 1.0 version */}
          {/* <SocialMedia event={mockEvent} /> */}
        </EventWrapper>
      )
    else
      return (
        <ConnectionsWrapper>
          <Connections currentTab={currentTab} entityId={id} page={'event'} />
          {/* {!isPreview && (
            <PublicDetailsCard.ConnectionsPaged
              currentTab={currentTab}
              connections={mockTabsContent}
            />
          )} */}
        </ConnectionsWrapper>
      )
  }

  const onBackButtonClick = () => {
    if (location.key) {
      history.goBack()
    } else {
      history.push('/events/list')
    }
  }

  if (!data)
    return (
      <LoaderWrapper>
        <ScrollToTop />
        <Loader loading={loading} error={error}></Loader>
      </LoaderWrapper>
    )

  if (isEventNotFound) {
    return <Redirect to="/events/list" />
  }

  return (
    <>
      {!isPreview && (
        <BlurBackgroundImageWrapper>
          <img src={eventData?.bannerImage} alt="" />
        </BlurBackgroundImageWrapper>
      )}
      <EventDetailsWrapper isPreview={isPreview}>
        <BackgroundWrapper>
          <TopBackButtonWrapper>
            <button
              style={previewInlineStyles}
              disabled={isPreview}
              onClick={() => onBackButtonClick()}
            >
              <i className="fas fa-arrow-left" />
            </button>
          </TopBackButtonWrapper>
          <BackgroundImage
            src={eventData?.bannerImage || theme?.images?.defaultCover}
            alt="event background"
          />
        </BackgroundWrapper>
        <ContentWrapper>
          {isLoggedIn && (
            <EventInformations
              isPreview={isPreview}
              event={eventData}
              refetchEventData={refetch}
            />
          )}

          {!isLoggedIn && (
            <EventInformationPublic
              isPreview={isPreview}
              event={eventData}
              refetchEventData={refetch}
            />
          )}

          <TabsWrapper>
            <PublicDetailsCard.ContentTabs
              tabs={tabs}
              onTabSelect={setCurrentTab}
            />
          </TabsWrapper>
          {renderContent()}
        </ContentWrapper>
        <BottomBackButtonWrapper>
          <ConditionalWrapper hasWrapper={false} url={'/events/list'}>
            <GoBackButton
              style={previewInlineStyles}
              onClick={() => onBackButtonClick()}
              disabled={isPreview}
              data-testid="detailCard-btn-back"
            >
              <p>
                <i className="fas fa-chevron-left" />
                <span className="text">
                  {intl
                    .formatMessage(
                      { id: 'detailcard.button.back' },
                      {
                        category: appLabel(
                          'eventsPlural',
                          'events.titleplural'
                        ),
                      }
                    )
                    .toLowerCase()}
                </span>
              </p>
            </GoBackButton>
          </ConditionalWrapper>
        </BottomBackButtonWrapper>
      </EventDetailsWrapper>
    </>
  )
}

export default EventDetailView

