const actions = {
  SET_CURRENT_CALL: 'meet/SET_CURRENT_CALL',
  SET_USER_STATUS: 'meet/SET_USER_STATUS',
  SET_USER_STATUSES: 'meet/SET_USER_STATUSES',
  SET_USER_STATUS_PREFETCH: 'meet/SET_USER_STATUS_PREFETCH',
}

export const setCurrentCall = (meetingDetails) => ({
  type: actions.SET_CURRENT_CALL,
  payload: meetingDetails,
})

export const setUserStatus = (userId, status) => ({
  type: actions.SET_USER_STATUS,
  payload: { userId, status },
})

export const setUserStatuses = (statuses) => ({
  type: actions.SET_USER_STATUSES,
  payload: statuses,
})

export const setUserStatusPrefetch = (prefetching) => ({
  type: actions.SET_USER_STATUS_PREFETCH,
  payload: prefetching,
})

export default actions
