import React from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { Loader } from '_components/loading'
import SectionHeader from '../components/section-header'
import { useQuery, gql } from '@apollo/client'
import { useTheme } from 'emotion-theming'

import {
  PageWrapper,
  CategoryWrapper,
  CategoryAvatar,
  Counter,
  LatestWrapper,
  Indicator,
  TopicAvatar,
  CategoryLink,
  MainSectionContainer,
  SubcategoriesWrapper,
  TopWrapper,
} from '../shared/styles'

import {
  PageSection,
  CountersWrapper,
  Description,
} from '../shared/styles/categories'
import { useMediaQuery } from 'react-responsive'
import { pabletPX, tabletPX } from '_utils/responsive-queries'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'

const GET_CATEGORIES = gql`
  {
    getCategories {
      categoriesList {
        name
        description
        cid
        slug
        postCount
        topicCount
        icon
        color
        bgcolor
        childrenList {
          name
          description
          cid
          slug
          postCount
          topicCount
          icon
          color
          bgcolor
        }
        postsList {
          content
          timestampiso
          tid
          pid
          uid
          user {
            username
            picture
          }
        }
        teaser {
          pid
          uid
          timestamp
          tid
          content
          timestampiso
          user {
            username
            picture
          }
          index
          url
          topic {
            slug
            title
          }
        }
      }
    }
  }
`

const setCharacterLimit = (string, limit) => {
  return string.length > limit ? string.substring(0, limit) + '...' : string
}

const unescape = (html) => {
  const returnStr = html?.replace(/<[^>]*>?/gm, '')
  let e = document.createElement('div')
  e.innerHTML = returnStr
  return e.childNodes.length === 0
    ? ''
    : setCharacterLimit(e.childNodes[0].nodeValue)
}

const renderLatestPreview = (object, theme, intl) => {
  const nameLimit = 30

  const setCharacterLimit = (string, limit) => {
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  return (
    <LatestWrapper span={4}>
      {object.postsList[0] ? (
        <Indicator
          sectionColor={object.bgcolor}
          href={`/forum/topic/${object.teaser.topic.slug}`}
        >
          <div className="author-wrapper">
            <TopicAvatar>
              <img
                src={
                  object.postsList[0].user.picture ||
                  theme?.images?.defaultAvatar
                }
                alt=""
              />
            </TopicAvatar>
            <div className="author-info">
              <div className="author-name">
                <p>
                  {setCharacterLimit(
                    replaceUsernameNumbers(object.postsList[0].user.username),
                    nameLimit
                  )}
                </p>
                <div className="date-wrapper">
                  <span className="bullet-wrapper">
                    <i className="fas fa-circle bullet" />
                  </span>
                  <span>
                    {moment(object.postsList[0].timestampiso).fromNow(true)}
                  </span>
                </div>
              </div>
              <p className="topic-name">
                {unescape(object.teaser.topic.title)}
              </p>
            </div>
          </div>
          <p
            className="content-preview"
            dangerouslySetInnerHTML={{
              __html: unescape(object.postsList[0].content),
            }}
          ></p>
        </Indicator>
      ) : (
        <span className="no-posts">
          {intl.formatMessage({ id: 'forum.info.nonewposts' })}
        </span>
      )}
    </LatestWrapper>
  )
}

const renderCategory = (object, isMobileView, intl, isTopElement) => {
  return (
    <CategoryWrapper isTopElement={isTopElement} span={6}>
      <TopWrapper isTopElement={isTopElement}>
        <CategoryLink
          isTopElement={isTopElement}
          to={`/forum/category/${object.cid}`}
          data-testid={`category-${object?.name}`}
        >
          {!isMobileView && (
            <CategoryAvatar
              isTopElement={isTopElement}
              style={{ backgroundColor: object.bgcolor }}
            >
              <i
                className={`fa ${object.icon}`}
                style={{ color: object.color }}
              />
            </CategoryAvatar>
          )}
          {isMobileView && (
            <CategoryAvatar style={{ backgroundColor: object.bgcolor }}>
              <i
                className={`fa ${object.icon}`}
                style={{ color: object.color }}
              />
            </CategoryAvatar>
          )}
          <Description isTopElement={isTopElement}>
            <h6
              className="title"
              dangerouslySetInnerHTML={{
                __html: unescape(object.name),
              }}
            ></h6>
            {isTopElement && (
              <p className="description">{unescape(object.description)}</p>
            )}
          </Description>
        </CategoryLink>
        <CountersWrapper isTopElement={isTopElement} span={2}>
          <PageSection>
            <Counter isTopElement={isTopElement}>{object.topicCount}</Counter>
            <span className="item-name">
              {intl.formatMessage({
                id: 'forum.stats.topics',
              })}
            </span>
          </PageSection>
          <PageSection>
            <Counter isTopElement={isTopElement}>{object.postCount}</Counter>
            <span className="item-name">
              {intl.formatMessage({
                id: 'forum.stats.posts',
              })}
            </span>
          </PageSection>
        </CountersWrapper>
      </TopWrapper>
      {object.childrenList && object.childrenList.length > 0 && (
        <SubcategoriesWrapper isTopElement={isTopElement}>
          {object.childrenList.map((item) =>
            renderCategory(item, isMobileView, intl)
          )}
        </SubcategoriesWrapper>
      )}
    </CategoryWrapper>
  )
}

const Categories = () => {
  const intl = useIntl()
  const theme = useTheme()
  const { data } = useQuery(GET_CATEGORIES)

  const isMobileView = useMediaQuery({ query: `(max-width: ${pabletPX}px)` })
  const isDesktopView = useMediaQuery({ query: `(min-width: ${tabletPX}px)` })

  if (!data) return <Loader loading={!data} testid="categories" />

  return (
    <PageWrapper>
      <SectionHeader />
      {data?.getCategories?.categoriesList.map(function (object, i) {
        return (
          <MainSectionContainer hasGutter key={i}>
            {renderCategory(object, isMobileView, intl, true)}
            {isDesktopView && <>{renderLatestPreview(object, theme, intl)}</>}
          </MainSectionContainer>
        )
      })}
    </PageWrapper>
  )
}

export default Categories
