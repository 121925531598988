import React from 'react'
import { AnchorTag, Location } from './styles/event-info'

export const renderLocation = (locationObject, intl, appLabel, isPreview) => {
  const { type, address, link } = locationObject

  switch (type) {
    case 'HYBRID':
      return (
        <>
          {address !== '' && (
            <Location>
              <i className="fas fa-map-marker-alt" />
              {address}
            </Location>
          )}
          {link !== '' && (
            <AnchorTag isPreview={isPreview} target="_blank" href={link}>
              <i className="fas fa-globe" />
              {intl.formatMessage(
                {
                  id: 'events.detailview.label.location.online',
                },
                {
                  event: appLabel('events', 'events.title').toLowerCase(),
                }
              )}
            </AnchorTag>
          )}
        </>
      )
    case 'ADDRESS':
      return (
        <>
          <Location>
            <i className="fas fa-map-marker-alt" />
            {address}
          </Location>
        </>
      )
    case 'ONLINE':
      return (
        <>
          <AnchorTag isPreview={isPreview} target="_blank" href={link}>
            <i className="fas fa-globe" />
            {intl.formatMessage(
              {
                id: 'events.detailview.label.location.online',
              },
              {
                event: appLabel('events', 'events.title').toLowerCase(),
              }
            )}
          </AnchorTag>
        </>
      )
    default:
      return (
        <>
          {intl.formatMessage({
            id: 'events.detailview.label.location.unknown',
          })}
        </>
      )
  }
}
