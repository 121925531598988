import styled from '@emotion/styled'

export const ThemeSelectorStyled = styled.div`
  margin-top: 14px;
  .theme-container {
    display: inline-block;
    vertical-align: top;
    margin: 4px 8px;
    .edit {
      display: block;
      text-align: center;
      font-size: 16px;
      margin: 8px 0;
    }
    .theme {
      border: 1px solid var(--foreground);
      border-radius: 15px;
      padding: 18px;
      text-align: center;
      background: var(--background);
      cursor: pointer;
      &.selected {
        border-color: var(--flavor);
      }
      .name {
        font-size: 16px;
      }
      .selected-icon {
        border: 1px solid var(--foreground);
        border-radius: 50%;
        display: table;
        margin: 0 auto;
        margin-top: 10px;
        width: 25px;
        height: 25px;
        background: var(--background);
        &.selected {
          i {
            opacity: 1;
          }
        }
        i {
          opacity: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          color: var(--flavor);
          vertical-align: top;
          margin-top: 5px;
        }
      }
    }
  }
`
