import styled from '@emotion/styled'

export const StyledDiv = styled.div`
  box-shadow: 0 3px 8px var(--shade);
  border-radius: 0 10px 10px 0;
  padding: 32px;

  .listItem {
    height: 86px;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    justify-content: space-between;
    padding: 0 1em;
    border: solid 1px var(--shade);
    border-radius: 5px;
    margin-bottom: -1px;
    align-items: center;
    cursor: pointer;

    &.selected {
      border-width: 3px;
    }

    input,
    button > i {
      width: 16px;
      height: 16px;
    }

    .description {
      display: grid;
      gap: 0.5em;
      justify-self: start;
      text-transform: capitalize;

      .label {
        font-weight: 600;
      }
    }

    button {
      background: transparent;
      border: none;
      font-size: 16px;
    }

    .arrows {
      display: grid;
      gap: 0.5em;
    }

    span {
      width: 100%;
    }

    .icon {
      height: 22px;
      width: 22px;
    }
  }

  .labels {
    text-transform: capitalize;
    margin-bottom: 0.9em;
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    grid-template-columns: repeat(3, 15%);
    justify-items: center;
  }

  .title-input {
    width: 100%;
    height: 39px;
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid var(--foreground);
    background: var(--background);
    color: var(--foreground);
  }

  .inputsHorizontalLayout {
    display: flex;
    justify-content: space-between;
  }

  .inputFields {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;
    flex-grow: 1;
  }
`

export const ModalDiv = styled.div`
  span,
  h4 {
    color: black;
  }

  & *:not(input):first-letter {
    text-transform: capitalize;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h4 {
    text-align: center;
    text-transform: capitalize;
  }

  .inputFields {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;
  }

  .inputFieldWrapper {
    display: grid;
    align-content: center;
    gap: 6px;
    height: 80px;
    input {
      height: 39px;
      border-radius: 5px;
      border-width: 1px;
      padding: 10px 16px;
    }
  }

  .buttons {
    display: flex;
    gap: 0.5em;
    font-size: 16px;
    div {
      flex-grow: 1;
    }
  }
`
