import { useAppConfig } from '_application'

export const useGetEntityAccess = (name, property) => {
  const config = useAppConfig()
  const { entitiesAccess } = config

  const entityProperties = entitiesAccess?.find(
    (entity) => entity.name === name
  )

  if (entityProperties !== undefined) {
    if (entityProperties.hasOwnProperty(property))
      return entityProperties[property]
  }

  return undefined
}
