import gql from 'graphql-tag'

export const GET_REPORTS = gql`
  query GetReports($status: String) {
    getReports(status: $status) {
      _id
      user {
        sub
        firstName
        lastName
        avatar
      }
      message
      internalNote
      dateCreated
      dateStatus
      status
    }
  }
`

export const UPDATE_REPORT = gql`
  mutation UpdateReport($internalNote: String, $status: String, $_id: String) {
    updateReport(internalNote: $internalNote, status: $status, _id: $_id) {
      _id
    }
  }
`
