/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
  getNodeAtPath,
  getTreeFromFlatData,
} from 'react-sortable-tree'
import { convertTreeDataToFlatData } from 'services/taxonomy'
import EditModal from './edit'
import DeleteModal from './delete'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { v4 as uuid } from 'uuid'

import { CreatorStyled } from './styles'

const defTax = [{ key: 0, name: 'Root', parent: null }]

const Creator = ({ props, onChange }) => {
  const [canShowModals, setCanShowModals] = useState({
    canShowAddModal: false,
    canShowEditModal: false,
    canShowDeleteModal: false,
  })
  const [currentNode, setCurrentNode] = useState()

  const [treeData, setTreeData] = useState(defTax)
  const [nextNodeId, setNextNodeId] = useState(0)

  useEffect(() => {
    const taxFlatData = props?.treeData?.length > 0 ? props.treeData : defTax
    setNextNodeId(taxFlatData.length + 1)
    const convert = getTreeFromFlatData({
      flatData: taxFlatData.map((node, i) => ({
        id: node.key,
        title: node.name,
        parent: node.parent,
        refId: node.refId,
      })),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    })
    setTreeData(convert)
    handleTreeData(convert)
  }, [])

  const handleTreeData = (data) => {
    setTreeData(data)
    const flatTree = convertTreeDataToFlatData(data)
    setNextNodeId(flatTree.length + 1)
    onChange({ ...props, treeData: flatTree })
  }

  const handleAddNodeUnderParent = ({ path }) => {
    const data = addNodeUnderParent({
      treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      newNode: { title: `Nested node`, id: nextNodeId, refId: uuid() },
      getNodeKey: ({ treeIndex }) => treeIndex,
    })
    getNodeAtPath({
      treeData: data.treeData,
      path: [data.treeIndex],
      getNodeKey: ({ treeIndex }) => {
        return treeIndex
      },
    })
    handleTreeData(data.treeData)
  }

  const handleRemoveNode = () => {
    handleTreeData(
      removeNodeAtPath({
        treeData,
        path: currentNode.path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      })
    )
    setCurrentNode(null)
  }

  const handleEditNode = (node) => {
    const newTree = changeNodeAtPath({
      treeData,
      path: node.path,
      newNode: node.node,
      getNodeKey: ({ treeIndex: number }) => {
        return number
      },
    })
    handleTreeData(newTree)
    setCurrentNode(null)
  }
  return (
    <CreatorStyled className="taxonomy-tree-view-container">
      <div className="taxonomy-panel" />
      <div className="full-tree-view">
        <SortableTree
          canDrag={false}
          isVirtualized={false}
          onChange={(data) => handleTreeData(data)}
          className="single-"
          treeData={treeData}
          generateNodeProps={(rowInfo) => ({
            buttons: [
              <button
                type="button"
                className="delete-button"
                id={rowInfo.node.title}
                onClick={() => {
                  setCurrentNode(rowInfo)
                  setCanShowModals({
                    ...canShowModals,
                    canShowDeleteModal: true,
                  })
                }}
              >
                <i className="far fa-trash-alt" />
              </button>,
              <button
                type="button"
                className="edit-button"
                onClick={() => {
                  setCurrentNode(rowInfo)
                  setCanShowModals({ ...canShowModals, canShowEditModal: true })
                }}
              >
                <i className="fas fa-pen" />
              </button>,
              true && (
                <button
                  type="button"
                  className="add-button"
                  onClick={() =>
                    handleAddNodeUnderParent({
                      node: rowInfo.node,
                      path: rowInfo.path,
                    })
                  }
                >
                  <i className="fas fa-plus" />
                </button>
              ),
            ],
          })}
        />
      </div>
      {currentNode && canShowModals.canShowEditModal ? (
        <EditModal
          canShowModals={canShowModals}
          setCanShowModals={setCanShowModals}
          currentNode={currentNode}
          onChangeCallback={(node) => handleEditNode(node)}
        />
      ) : null}
      {currentNode && canShowModals.canShowDeleteModal ? (
        <DeleteModal
          canShowModals={canShowModals}
          setCanShowModals={setCanShowModals}
          removeNodeCallback={() => handleRemoveNode()}
        />
      ) : null}
    </CreatorStyled>
  )
}

export default Creator
