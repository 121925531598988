import styled from '@emotion/styled'

export const EventCategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`
export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
`
export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`
export const SingleCategory = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
`
