import styled from '@emotion/styled'
import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import {
  Select,
  SelectOption,
  SelectVariant,
  SearchInput,
} from '@patternfly/react-core'
import SubsectionHeader from '../shared/subsection-header'
import { GET_CATEGORIES, GET_FORUM_USERS } from './graph'
// TODO: check the error
// import { MainContainer } from './styles'
import {
  SearchResults,
  SingleResultWrapper,
  SearchInputWrapper,
  AddButton,
  Name,
  Avatar,
  UsersList,
  SingleUserWrapper,
  RemoveButton,
} from './styles'

const MainContainer = styled.div``

const userData = [
  { username: 'Bruce Willis' },
  { username: 'Jason Statham' },
  { username: 'Clint Eastwood' },
]

const removeUserBtn = () => {
  return (
    <RemoveButton>
      <i className="fas fa-trash" />
    </RemoveButton>
  )
}

const renderUsers = (users, removeButton) => {
  return users.map((u) => (
    <SingleUserWrapper>
      <Avatar></Avatar>
      <span className="name">{u.username}</span>
      {removeButton()}
    </SingleUserWrapper>
  ))
}

const AdminsMods = () => {
  const { data } = useQuery(GET_CATEGORIES)
  const { data: users } = useQuery(GET_FORUM_USERS)
  const { categoriesList } = data?.getCategories || {}

  const { usersList } = users?.getForumUsers || {}

  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [forumUsers, setForumUsers] = useState([])

  const onChange = (value, event) => {
    setQuery(value)
  }

  useEffect(() => {
    setForumUsers(
      usersList?.filter(
        (u) =>
          query?.length > 0 &&
          `${u?.username}`?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [usersList, setForumUsers, query])

  return (
    <MainContainer>
      <SubsectionHeader
        title="Administrators"
        description="Here you can add administrators for your forum that can create categories etc..."
      >
        <SearchInputWrapper>
          <SearchInput
            value={query}
            onChange={onChange}
            type="text"
            onClear={(evt) => onChange('', evt)}
          />
          {forumUsers && forumUsers.length > 0 && (
            <SearchResults>
              {forumUsers.map((u) => {
                return (
                  <SingleResultWrapper>
                    <Avatar></Avatar>
                    <Name>{u.username}</Name>
                    <AddButton>Add</AddButton>
                  </SingleResultWrapper>
                )
              })}
            </SearchResults>
          )}
        </SearchInputWrapper>
        <UsersList>{renderUsers(userData, removeUserBtn)}</UsersList>
      </SubsectionHeader>
      <SubsectionHeader
        title="Global moderators"
        description="Here you can add global moderators for your forum."
      >
        <input type="text" />
      </SubsectionHeader>
      <SubsectionHeader
        title="Category moderators"
        description="Here you can add global moderators for each category in your forum."
      >
        <Select
          variant={SelectVariant.single}
          placeholderText="Select an option"
          aria-label="Select Input with validation"
          onToggle={() => setIsOpen(!isOpen)}
          onSelect={(e, selections) => {}}
          // onSelect={onSelect}
          selections={''}
          isOpen={isOpen}
          validated={'validated'}
          aria-describedby="validated-helper"
          aria-invalid={'validated' === 'error' ? true : false}
        >
          {categoriesList &&
            categoriesList.map((category) => (
              <SelectOption value={category.name}>{category.name}</SelectOption>
            ))}
        </Select>

        <input type="text" />
      </SubsectionHeader>
    </MainContainer>
  )
}

export default AdminsMods
