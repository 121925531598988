import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import SectionTitle from '_components/section-title'

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const Props = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

const DrilldownSection = ({ title, className, children }) => (
  <Container className={className}>
    {title && <SectionTitle>{title}</SectionTitle>}
    {children}
  </Container>
)
DrilldownSection.propTypes = Props

export default DrilldownSection
