import styled from '@emotion/styled'

export const IndexStyled = styled.div``

export const ExportStyled = styled.div`
  width: 20%;
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
`
