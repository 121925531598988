import React, { Suspense } from 'react'
import { Route, Switch, Router, Redirect } from 'react-router-dom'
// import { ConnectedRouter } from 'connected-react-router'

import people from '_people'
import companies from '_companies'
import activities from '_activities'
import opportunities from '_opportunities'
import blog from '_blog'
import forum from '_forum'
import discover from '_discover'
import events from '_events'
import search from '_search'
import contentStudio from '_content-studio'
import { MeetGlobalCall } from '_meet'
import { Notifications } from '_notifications'
import Layout from './layouts'
import UserProfile from 'components/indy/user/profile'
import AdminPanel from 'components/indy/admin'
import Onboarding from 'components/indy/user/onboarding'
import SystemPage from 'pages/system'
import Messages from 'pages/dashboard-messages'
import NotificationsCenter from '_notification-center/index'
import Verify from 'pages/auth/verify'

import Login from 'pages/auth/login'
import Forgot from 'pages/auth/forgot'
import NewPassword from 'pages/auth/new-password'
import Registration from 'pages/auth/registration'
import CallbackPage from 'pages/callback'
import NotificationsPage from 'pages/dashboard-notifications'
import CookieConsent from 'pages/cookie-consent'
import EmbedPage from 'pages/static-embed'
import { CustomPages } from 'custom-pages'

import { VerifyUser } from '_application'

import { useUser } from '_security/session-hooks'

const RouterComponent = ({ history }) => {
  const isLogged = useUser()?.profile?.role

  return (
    <Router history={history}>
      <Notifications />
      <MeetGlobalCall />
      <Layout>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/user/onboarding" component={Onboarding} />
            <Redirect exact from="/terms" to="/pages/terms" />
            <VerifyUser>
              <Route path="/" component={discover.Route} exact />
              <Route path="/discover" component={discover.Route} />
              <Route path="/people" component={people.Route} />
              <Route path="/companies" component={companies.Route} />
              <Route path="/activities" component={activities.Route} />
              <Route path="/opportunities" component={opportunities.Route} />
              <Route path="/blog" component={blog.Route} />
              <Route path="/events" component={events.Route} />
              <Route path="/content-studio" component={contentStudio.Route} />
              <Route path="/forum" component={forum.Route} />
              <Route path="/search" component={search.Route} />
              <Route path="/user/profile" component={UserProfile} />
              <Route path="/manage" component={AdminPanel} />
              <Route path="/pages/:path" component={CustomPages} />

              <Route path="/public/system/:id" component={SystemPage} />
              <Route path="/public/static/:id" component={SystemPage} />
              <Route path="/verify-email/:refId" component={Verify} />
              <Route path="/user/notifications" component={NotificationsPage} />
              <Route path="/user/messages" component={Messages} />
              <Route
                path="/user/notifications-center"
                component={NotificationsCenter}
              />
              <Route path="/public/cookie-policy" component={CookieConsent} />

              <Route path="/callback" component={CallbackPage} />
              <Route path="/public/static-embed/:id" component={EmbedPage} />
              {!isLogged && [
                <Route path="/forgot/password" component={Forgot} />,
                <Route
                  path="/set/new/password/:referenceId"
                  component={NewPassword}
                />,
                <Route path="/login" component={Login} />,
                <Route path="/registration" component={Registration} />,
              ]}
              <Route
                path="/errorhandlertest"
                component={() => {
                  return <>{null.substring(0, 99)}</>
                }}
              />
            </VerifyUser>
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  )
}

export default RouterComponent

