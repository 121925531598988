import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import ToolTip from '_events/components/event-creator/components/tooltip'
import {
  DiscoverAll,
  Container,
  TopWrapper,
  Title,
  Description,
  SelectWrapper,
  EventsCategorySelect,
} from './styles/discover-section'
import { useUser } from '_security/session-hooks'

const DiscoverAllJSX = ({ link, type }) => {
  return (
    <Link to={link}>
      <DiscoverAll data-testid={`${link}-btn-discoverAll`} type={type}>
        <p>
          <FormattedMessage id="discover.discoverall" />
        </p>
      </DiscoverAll>
    </Link>
  )
}

const DiscoverSection = ({
  children,
  title,
  link,
  description,
  type,
  eventsCategories,
  selectedCategory,
  onSelect,
}) => {
  const [dropdown, setDropdown] = useState(false)
  const ref = useClickOutside(() => setDropdown(false))

  const { profile } = useUser() || {}

  const _renderCurrentEventsCategory = () => {
    let currentCategory = 'Choose category'
    if (selectedCategory) {
      selectedCategory === 'All'
        ? (currentCategory = selectedCategory)
        : (currentCategory = eventsCategories?.find(
            (item) => item?.id === selectedCategory
          )?.name)
    }

    return currentCategory
  }

  const _renderEventsCategorySelect = () => (
    <SelectWrapper>
      <ToolTip>
        {/*todo intl */}
        Choose what category of events to show. Only you as admin can see this
        option.
      </ToolTip>
      <EventsCategorySelect>
        <div className="full-selector" ref={ref}>
          <button
            onClick={() => setDropdown(!dropdown)}
            className="btn-dropdown"
          >
            {_renderCurrentEventsCategory()}
            <i className={`fas fa-chevron-${dropdown ? 'up' : 'down'}`} />
          </button>
          <ul className={`select-dropdown ${dropdown ? 'active' : ''}`}>
            <li onClick={() => onSelect('All')}>
              <span>All</span>
            </li>
            {eventsCategories?.map((item, i) => {
              return (
                <li key={i} onClick={() => onSelect(item?.id)}>
                  <span>{item?.name}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </EventsCategorySelect>
    </SelectWrapper>
  )

  return (
    <Container>
      <TopWrapper type={type}>
        <Title>{title && title}</Title>
        <DiscoverAllJSX link={link} type={type} />
        {description && (
          <Description>
            <p>{description}</p>
          </Description>
        )}
        {type === 'events' &&
          profile?.role === 'admin' &&
          _renderEventsCategorySelect()}
      </TopWrapper>
      <div className="children">{children}</div>
      <DiscoverAllJSX link={link} />
    </Container>
  )
}
DiscoverSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default DiscoverSection

