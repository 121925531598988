import { useMemo, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'

const parseFilter = (filterString) => {
  if (filterString) {
    const decoded = decodeURI(filterString)
    const split = decoded.split('^')
    const filter = split.reduce((acc, e) => {
      const [key, value] = e.split('=')
      acc[key] = value === 'true' ? true : value
      return acc
    }, {})
    return filter
  } else {
    return undefined
  }
}

const serializeFilter = (filter) => {
  if (filter) {
    return Object.keys(filter)
      .reduce((acc, key) => {
        acc.push(`${key}=${filter[key]}`)
        return acc
      }, [])
      .join('^')
  } else {
    return undefined
  }
}

export const filterToQueryString = (filter) => {
  const updated = serializeFilter(filter)
  const queryString = qs.stringify({ /* ...query, */ filter: updated })
  return '?' + queryString
}

export const useFilters = () => {
  const history = useHistory()
  const { search } = useLocation()

  const query = useMemo(() => qs.parse(search), [search])
  const current = useMemo(() => parseFilter(query.filter), [query])

  const setFilter = useCallback(
    (filter) => {
      const updated = serializeFilter(filter)
      history.push({ search: qs.stringify({ ...query, filter: updated }) })
    },
    [query, history]
  )

  const clearFilter = useCallback(() => setFilter(null), [setFilter])
  const addFilter = useCallback(
    (filter) => {
      setFilter({ ...current, filter })
    },
    [current, setFilter]
  )
  return {
    addFilter,
    setFilter,
    clearFilter,
    filter: current,
  }
}

export default {
  useFilters,
}
