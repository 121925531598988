import React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {
  ImageWrapper,
  UserTile,
  UserName,
  Location,
  Status,
} from '../styles/index'

import Button from '../../../shared/ui-kit/buttons/single-button'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import { MbToB } from 'services/file'
import { useUser } from '_security/session-hooks'
import { useAppConfig } from '_application'
import { useIntl } from 'react-intl'

const RevertButton = styled.button`
  position: absolute;
  right: -13px;
  top: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 17px 1px;
`

const defaultImage =
  'https://storage.googleapis.com/buildtoriot-cdn/not-logged-avatar.jpg'

const mapStateToProps = ({ user }) => ({ user })

const UserCard = ({ user, dispatch }) => {
  const intl = useIntl()
  const { firstName, lastName } = user.profile
  const handleImageUpload = (imgUrl, type) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: imgUrl },
      },
    })
  }

  const { _id } = useUser()
  const { tenantId } = useAppConfig()

  const uploadConfig = {
    folderName: 'user-profile',
    tagsConfig: {
      userId: _id,
      tenantId: tenantId,
      context: 'user-profile-avatars',
    },
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    mediaContainerType: 'profileUserAvatar',
  }

  const { showWidget } = useMediaUploader(uploadConfig, (img) =>
    handleImageUpload(img?.url, 'avatar')
  )

  return (
    <UserTile>
      <ImageWrapper>
        <div className="image-wrapper">
          <img
            src={user.profile.avatar}
            alt=""
            data-testid="about-img-avatar"
          />
        </div>
        <Button
          type="upload-circular"
          onClick={() => showWidget()}
          testid="about-avatar"
        >
          <i className="fas fa-camera" />
        </Button>
        {user.profile.avatar !== defaultImage && (
          <RevertButton
            data-for="revertbutton"
            data-tip={intl.formatMessage({
              id: 'user.profile.about.avatar.buttontooltip.revert',
            })}
            onClick={() => handleImageUpload(defaultImage, 'avatar')}
            data-testid="btn-about-avatar-revert"
          >
            <i class="fas fa-times"></i>
          </RevertButton>
        )}
      </ImageWrapper>
      <UserName data-testid="about-fullName">
        <h3>{`${firstName} ${lastName}`}</h3>
      </UserName>
      <Status>
        <p>{user.profile.bioShort}</p>
      </Status>
      <Location>
        {user?.profile?.location?.country && user?.profile?.location?.city && (
          <i class="fas fa-map-marker-alt" />
        )}
        <span className="city"> {user?.profile?.location?.city}</span>
        <span className="country"> {user?.profile?.location?.country}</span>
      </Location>
      <ReactTooltip id="revertbutton" effect="solid" />
    </UserTile>
  )
}

export default connect(mapStateToProps)(UserCard)
