import React from 'react'

import styled from '@emotion/styled'

const Content = styled.div`
  padding: 0 45px;
  button {
    background-color: transparent;
    border: none;
    font-family: ${({ theme }) => theme.fonts.googleFont};

    width: 100%;
    text-align: left;
    font-size: 14px;
    padding: 20px 0;
    font-weight: 600;

    i {
      float: right;
      margin-top: 5px;
    }
  }
`

const ShowMore = ({ show, setShow, text }) => {
  return (
    <Content>
      <button onClick={() => setShow(!show)}>
        {show ? 'Hide' : text}
        <i className={`fas fa-chevron-${show ? 'up' : 'down'}`} />
      </button>
    </Content>
  )
}

export default ShowMore
