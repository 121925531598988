import React from 'react'

import SubNavigation from '../../shared/components/sub-navigation'
import Routes from './routes'

const defaultUrl = '/manage/site-structure/forum'

const navItems = [
  // {
  //   url: `${defaultUrl}/dashboard`,
  //   titleKey: 'admin.sitestructure.forum.dashboard.title',
  // },
  {
    url: `${defaultUrl}/categories`,
    titleKey: 'admin.sitestructure.forum.categories.title',
  },
  // {
  //   url: `${defaultUrl}/admins-mods`,
  //   titleKey: 'admin.sitestructure.forum.adminsmods',
  // },
  {
    url: `${defaultUrl}/tags`,
    titleKey: 'admin.sitestructure.forum.tags.title',
  },
  // { url: `${defaultUrl}/labels`, titleKey: 'admin.sitestructure.forum.labels.title' },
]

const Dashboard = () => {
  return (
    <>
      <SubNavigation navItems={navItems} />
      <Routes />
    </>
  )
}

export default Dashboard
