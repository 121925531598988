import styled from '@emotion/styled'

export const SubNavigationStyled = styled.ul`
  padding: 0;
  margin-bottom: 20px;
`

export const NavItem = styled.li`
  display: inline-block;
  padding: 4px 10px;
  border-bottom: 2px solid transparent;
  box-shadow: 0 1px 0px 0px #b7b7b7;
  font-size: 16px;
  &.active {
    box-shadow: 0 0px 0px 0px #b7b7b7;
    border-bottom: 3px solid var(--flavor);
  }
  a {
    color: var(--foreground);
  }
`
