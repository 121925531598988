import gql from 'graphql-tag'

export const GET_PLUGINS = gql`
  query GetPlugins {
    getPlugins {
      key
      enabled
      data
    }
  }
`

export const UPDATE_PLUGIN = gql`
  mutation UpdatePlugin($key: String, $enabled: Boolean, $data: JSONObject) {
    updatePlugin(key: $key, enabled: $enabled, data: $data) {
      key
    }
  }
`
