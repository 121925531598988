import React from 'react'
import DatePicker from '_content-studio/components/date-picker'
import ToggleButton from '_content-studio/components/toggle-button'
import { useAppConfig, useAppLabel } from '_application'
import CustomAttributes from '../../../components/custom-attributes'
import Keywords from '../../../components/keywords'
import Taxonomies from '_content-studio/components/taxonomies'
import { useParams } from 'react-router-dom'
import EntityConnection from '_content-studio/components/entity-connections'
import { useIntl } from 'react-intl'

const AdditionalInformation = ({
  formData,
  changeFormProperty,
  uploadConfig,
  entity
}) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const { defaultLocale } = useAppConfig()

  const { connectedOpportunities, connectedActivities } = entity || {}

  const { id } = useParams()

  const canRenderConnections = id || formData?.id

  return (
    <>
      {canRenderConnections && (
        <>
          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to activity',
              },
              { entity: appLabel('activities', 'activities.title') }
            )}
            entityType={'activity'}
            entityName={appLabel('activities', 'activities.title')}
            connectionType="c2a"
            id={formData?.id}
            connections={connectedActivities}
          />

          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to opportunity',
              },
              { entity: appLabel('opportunities', 'opportunities.title') }
            )}
            entityType={'opportunity'}
            entityName={appLabel('opportunities', 'opportunities.title')}
            connectionType="c2o"
            id={formData?.id}
            connections={connectedOpportunities}
          />
        </>
      )}

      <DatePicker
        value={formData.founded}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.founded.title',
            defaultMessage: 'Founded',
          }),
          description: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.founded.description',
              defaultMessage: `What date was the ${appLabel(
                'companies',
                'companies.title'
              )} founded?`,
            },
            { entity: appLabel('companies', 'companies.title') }
          ),

          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ founded: value })}
      />

      <CustomAttributes
        entityType="opportunities"
        changeFormProperty={changeFormProperty}
        uploadConfig={uploadConfig}
        formData={formData}
      />

      

      <Keywords
        entityType="companies"
        keywords={formData.keywords}
        changeFormProperty={changeFormProperty}
      />

      <Taxonomies
        entityType="companies"
        taxonomies={formData?.taxonomy}
        changeFormProperty={changeFormProperty}
      />

<ToggleButton
        value={formData.isDraft}
        onSave={(value) => changeFormProperty({ isDraft: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.isdraft.toggle.title',
            defaultMessage: 'Is draft?',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.isdraft.toggle.description',
              defaultMessage: `If this ${appLabel(
                'companies',
                'companies.title'
              )} is draft`,
            },
            { entity: appLabel('companies', 'companies.title') }
          ),
        }}
      />

      <ToggleButton
        value={formData.private}
        onSave={(value) => changeFormProperty({ private: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.private.toggle.title',
            defaultMessage: 'Private',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.private.toggle.description',
              defaultMessage: `If this ${appLabel(
                'companies',
                'companies.title'
              )} is private`,
            },
            { entity: appLabel('companies', 'companies.title') }
          ),
        }}
      />
    </>
  )
}

export default AdditionalInformation

