import styled from '@emotion/styled'

const BasicButton = styled.button`
  display: block;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : 'var(--flavor)'};
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  border-radius: 25px;
  border: none;
  display: block;
  position: relative;
  margin-top: 20px !important;

  i.fas {
    font-size: 16px;
    margin-right: 5px;
  }
  /* &:hover {
    opacity: 0.7;
  } */
`

export default BasicButton
