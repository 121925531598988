import styled from '@emotion/styled'

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SearchInputWrapper = styled.div`
  position: relative;
  margin-right: 25px;
  width: 350px;
  z-index: ${({ isInputActive }) => (isInputActive ? '3' : '2')};
  .connections-search-input {
    z-index: 2;
    padding: 10px;
    border-radius: 6px;
  }
`

