import React, { useState } from 'react'

import SubsectionHeader from '../shared/subsection-header'
import { GET_TAGS, DELETE_TAG, CREATE_TAG } from './graph-queries'
import { useQuery, useMutation } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import { Button, Form, FormGroup, TextInput } from '@patternfly/react-core'

import { MainContainer, SingleTag } from './styles'
import { useIntl } from 'react-intl'

const Tags = () => {
  const [tagValue, setTagValue] = useState('')
  const intl = useIntl()

  const { data } = useQuery(GET_TAGS, { variables: { all: true } })

  const [deleteTag] = useMutation(DELETE_TAG, {
    refetchQueries: [{ query: GET_TAGS, variables: { all: true } }],
  })

  const [createTag] = useMutation(CREATE_TAG, {
    refetchQueries: [{ query: GET_TAGS, variables: { all: true } }],
  })

  const handleTagDelete = async (value) => {
    console.log('fire tag', value)
    if (value) {
      await deleteTag({ variables: { value: value } })
    }
  }

  const onChange = (value, event) => {
    setTagValue(value)
  }

  const handleSave = async (e) => {
    e.preventDefault()

    console.log('fire value', tagValue)
    if (tagValue) {
      await createTag({
        variables: {
          value: tagValue,
        },
      })
      setTagValue('')
    }
  }
  return (
    <MainContainer>
      <SubsectionHeader
        title={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.tags.title',
          defaultMessage: 'Tags',
        })}`}
        description={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.tags.description',
          defaultMessage: 'You can add new tag or remove existing one.',
        })}`}
      />
      <>
        <Form id="modal-with-form-form" onSubmit={handleSave}>
          <FormGroup
            label={intl.formatMessage({
              id: 'admin.sitestructure.forum.tags.form.label',
              defaultMessage: 'Add new tag',
            })}
            fieldId="modal-with-form-form-email"
          >
            <Row>
              <Col sm={12} md={4}>
                <TextInput
                  value={tagValue}
                  onChange={onChange}
                  type="text"
                  onClear={(evt) => onChange('', evt)}
                />
              </Col>
              <Col sm={12} md={4}>
                <Button
                  key="create"
                  variant="primary"
                  type="submit"
                  form="modal-with-form-form"
                >
                  {intl.formatMessage({
                    id: 'admin.sitestructure.forum.tags.button.addtag',
                    defaultMessage: 'Add',
                  })}
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </>
      <ul>
        {data?.getTags.tags.tagsList.map(function (object) {
          return (
            <SingleTag>
              <i className="fas fa-tag" />
              {object.value} {object.score}
              <button
                className="remove-tag"
                onClick={() => handleTagDelete(object.value)}
              >
                <i className="fas fa-times" />
              </button>{' '}
            </SingleTag>
          )
        })}
      </ul>
    </MainContainer>
  )
}

export default Tags
