import React from 'react'
import { Tabs, Tab, TabTitleText } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { useAppConfig } from '_application'

const StyledTab = styled(Tabs)`
  .pf-c-tabs__link {
    color: var(--foreground);
  }
`

const CategoryTab = ({ categories }) => {
  const history = useHistory()
  const { blogCategories } = useAppConfig()

  if (!categories || categories?.length <= 0) {
    return <></>
  }

  const handleTabClick = (event, tabIndex) => {
    history.push('/blog/?category=' + tabIndex)
  }

  const subs = [].concat(
    ...blogCategories?.reduce(function (result, cat) {
      if (cat.subcategories) {
        result.push(cat.subcategories)
      }
      return result
    }, [])
  )
  const ids = []?.concat(...subs?.map(({ id }) => id))
  const filteredCats = categories?.filter((cat) => ids?.includes(cat?.id))

  const getCatName = (id) => subs.find((cat) => cat?.id === id)?.name

  return (
    <StyledTab activeKey={0} onSelect={handleTabClick}>
      {filteredCats &&
        filteredCats?.map((category) => {
          return (
            <Tab
              key={category?.id}
              eventKey={category?.id}
              title={<TabTitleText> {getCatName(category?.id)}</TabTitleText>}
            ></Tab>
          )
        })}
    </StyledTab>
  )
}

export default CategoryTab
