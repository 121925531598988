import { gql } from '@apollo/client'

export const GET_OPPORTUNITY = gql`
  query getOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      name
      coverPhoto
      description
      body
      location
      start
      end
      expire
      private
      isDraft
      connectedCompanies {
        id
        name
        coverPhoto
        bannerImage
        description
        location
      }
      connectedActivity{
        id
        name
        coverPhoto
        start
        end
        description
        location
      }
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        fullName
        lastName
        avatar
      } 
      receiveApplicationBy{
        type
        value
      }
      customAttributes {
        key
        value
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomy {
        key
        values {
          type
          value
        }
      }
    }
  }
`

export const SAVE_OPPORTUNITY = gql`
  mutation SaveOpportunity($input: OpportunityInput) {
    saveOpportunity(input: $input) {
      id
    }
  }
`
