import { mixToShade, pickContrast } from './primitives/color'
import { adjust } from "./adjust"

export function ColorObj(colors, scheme) {
  //Generic color object with all its auto generated color variations
  const faintness = 1.1
  const { color, antithesis } = colors
  this.color = color
  this.shade = mixToShade(color, antithesis)
  this.shade2 = mixToShade(color, antithesis, faintness)
  this.contrast = pickContrast(color, scheme).toString()
}

//generate colors
export function generateColor(color, antithesis, scheme) {
  if(!color) return
  return new ColorObj({color, antithesis}, scheme)
}

function background(scheme) {
  if(!scheme.background) return
  const { background, foreground } = scheme
  return generateColor(background, foreground, scheme)
}

function foreground(scheme) {
  if(!scheme.foreground) return
  const { background, foreground } = scheme
  return generateColor(foreground, background, scheme)
}

function flavors(scheme) {
  if(!scheme.flavor) return
  return scheme.flavor?.map((fl) => generateColor(fl, scheme.background, scheme))
}

export const generate = (scheme = adjust()) => {
  //Gets the adjusted colors from the wrapper and generates more colors
  //assosiated with the root colors. Like, shadeded variations and flavor contrast colors.
  return {
    background: background(scheme),
    foreground: foreground(scheme),
    flavor: flavors(scheme)
  }
}