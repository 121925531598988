import React from 'react'

import { MainWrapper } from '../styles'

import Account from './account'

const AccountManagment = () => {
  return (
    <MainWrapper>
      <Account />
    </MainWrapper>
  )
}

export default AccountManagment
