import React, { useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { usePageTitle } from '_application'
import { useSetThemeSettings } from '_application'

import Navigation from './navigation-content'
import MainContent from './main-content'

import { mobilePX } from '_utils/responsive-queries'
import {
  MainContainer,
  LeftColumn,
  RightColumn,
} from './styles/notifications-center'

const mapStateToProps = ({ user, message, dispatch, messages }) => ({
  user,
  message,
  dispatch,
  messages,
})

const GET_SORTED_NOTIFICATIONS = gql`
  query GetSortedNotifications {
    getCurrentUser {
      sub
      sortedNotifications
    }
  }
`

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: String!) {
    deleteNotification(id: $id)
  }
`
// const READ_NOTIFICATION = gql`
//   mutation ReadNotification($id: String!) {
//     readNotification(id: $id)
//   }
// `

const CHANGE_STATUS = gql`
  mutation ChangeStatus(
    $id: String!
    $status: String
    $collection: String
    $notificationId: String
  ) {
    changeConnectionStatus(
      id: $id
      status: $status
      collection: $collection
      notificationId: $notificationId
    )
  }
`

const NotificationCenter = ({ user }) => {
  const [sorting, setSorting] = useState(null)

  const { data, loading } = useQuery(GET_SORTED_NOTIFICATIONS)
  console.log('sorted1', data)
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: [{ query: GET_SORTED_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  })
  // const [readNotification] = useMutation(READ_NOTIFICATION, {
  //   refetchQueries: [{ query: GET_SORTED_NOTIFICATIONS }],
  //   awaitRefetchQueries: true,
  // })

  const [changeConnectionStatus] = useMutation(CHANGE_STATUS, {
    refetchQueries: [{ query: GET_SORTED_NOTIFICATIONS }],
    awaitRefetchQueries: true,
  })

  usePageTitle('Notifications')
  useSetThemeSettings({ hasNoBackground: true })

  const handleSorting = (e, value) => {
    if (e.currentTarget.id === 'all') return setSorting(null)
    return setSorting(value)
  }

  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })

  if (loading) return <></>

  return (
    <MainContainer>
      {!isMobileView && (
        <LeftColumn>
          <Navigation
            user={user}
            handleSorting={handleSorting}
            sorting={sorting}
            data={data?.getCurrentUser?.sortedNotifications || []}
          />
        </LeftColumn>
      )}
      <RightColumn>
        <MainContent
          data={data?.getCurrentUser?.sortedNotifications || []}
          sorting={sorting}
          acceptAction={(payload, id, type) => {
            changeConnectionStatus({
              variables: {
                id: payload,
                status: 'accepted',
                collection: type,
                notificationId: id,
              },
            })
          }}
          declineAction={(payload, id, type) => {
            changeConnectionStatus({
              variables: {
                id: payload,
                status: 'cancelled',
                collection: type,
                notificationId: id,
              },
            })
          }}
          deleteAction={(id) => {
            deleteNotification({
              variables: { id },
            })
          }}
        />
      </RightColumn>
    </MainContainer>
  )
}

export default connect(mapStateToProps)(NotificationCenter)
