import { useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'

const SEARCH_QUERY = gql`
  query SearchQuery($searchInput: SearchInput) {
    search(searchInput: $searchInput) {
      count
      data {
        _id
        type
        title
        coverPhoto 
        sanityId
        creator {
          _id
          avatar
          name
          firstName
          lastName
          location {
            city
            country
          }
        }
        score
      }
    }
  } 
`

export const useSearch = (query, { limit, type }) => {
  const [lazyQueryExecFunction, { data, loading, error }] = useLazyQuery(SEARCH_QUERY)
  const getSearchResults = useCallback(debounce(lazyQueryExecFunction, 500, { maxWait: 2000, trailing: true }), [])

  getSearchResults({ variables: { searchInput: { query, type, paging: { limit: limit } } } })

  return [getSearchResults, { data, loading, error }]
}