import styled from '@emotion/styled'
import BaseStyled from '../../styles/default'

const KeywordGenericStyled = styled(BaseStyled)`
  .tiles {
    .tile {
      width: 50%;
    }
  }
  .tile-content {
    padding: 18px;
  }
`
export default KeywordGenericStyled
