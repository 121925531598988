import styled from '@emotion/styled'
import BaseStyled from './default'

const SocialMediaStyled = styled(BaseStyled)`
  .PhoneInputCountry {
    margin-bottom: 20px;
  }
`

export default SocialMediaStyled
