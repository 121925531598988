import gql from 'graphql-tag'

export const UPDATE_LABEL = gql`
  mutation UpdateLabelOverride($label: LabelOverrideInput, $type: String) {
    updateLabelOverride(label: $label, type: $type) {
      labelOverrides {
        activities {
          key
        }
      }
    }
  }
`
