import { gql } from '@apollo/client'

export const CONNECT_COMPANY_TO_ACTIVITY = gql`
  mutation ConnectCompanyToActivity(
    $companyId: String!
    $activityId: String!
    $activityOwner: String!
  ) {
    connectCompanyToActivity(
      companyId: $companyId
      activityId: $activityId
      activityOwner: $activityOwner
    ) {
      status
    }
  }
`

export const DISCONNECT_COMPANY_TO_ACTIVITY = gql`
  mutation DisconnectCompanyToActivity(
    $companyId: String!
    $activityId: String!
  ) {
    disconnectCompanyToActivity(
      companyId: $companyId
      activityId: $activityId
    ) {
      status
    }
  }
`

export const CONNECT_COMPANY_TO_OPPORTUNITY = gql`
  mutation ConnectCompanyToOpportunity(
    $companyId: String!
    $opportunityId: String! 
    $opportunityOwner: String!
  ) {
    connectCompanyToOpportunity(
      companyId: $companyId
      opportunityId: $opportunityId
      opportunityOwner: $opportunityOwner
    ) {
      status
    }
  }
`

export const DISCONNECT_COMPANY_TO_OPPORTUNITY = gql`
  mutation DisconnectCompanyToOpportunity(
    $companyId: String!
    $opportunityId: String!
  ) {
    disconnectCompanyToOpportunity(
      companyId: $companyId
      opportunityId: $opportunityId
    ) {
      status
    }
  }
`