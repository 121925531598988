import border from '_utils/css-border'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { Select } from '@patternfly/react-core'

export const ApplicationStyled = styled.div`
  --side-padding: 50px;

  @media (max-width: 700px) {
    --side-padding: 15px;
  }

  padding: 30px var(--side-padding) 50px;
  max-width: 1200px;
  margin: 0 auto;
`

export const MainWrapper = styled.div`
  ${border()}
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  color: var(--foreground);
  background: var(--background);
  padding: 0 20px;
  min-height: 50vh;
  font-size: 12px;
  .section-title {
    position: relative;
    font-size: 22px;
    font-family: ${(props) => props.theme.fonts.profileSectionTitle};
  }
  &.general-info {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
`

export const ListItemStyled = styled.div`
  height: 120px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 8px var(--shade);
  border-radius: 10px;
  background-color: var(--background);

  ${({ isDetailView }) =>
    isDetailView &&
    css`
      margin-top: 0;
      justify-content: space-between;

      .view-profile-btn {
        border-radius: 25px;
        background-color: var(--background);
        border: 2px solid var(--flavor);
        outline: none;
        margin-right: 30px;
        padding: 5px 18px;
        transition: all 0.4s ease-in-out;
        font-weight: 600;

        :hover {
          background-color: var(--flavor);
          color: var(--background);
        }
      }
    `}

  .item-details {
    display: flex;
    flex-direction: column;
  }

  .item-name {
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 5px;
    color: var(--foreground);
  }

  .item-desc {
    font-size: 16px;
    font-weight: 400;
    color: var(--foreground);
  }

  .item-expire-days {
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    color: var(--foreground);
  }

  .item-applicants-number {
    font-size: 14px;
    font-weight: 600;
    color: var(--foreground);
  }
`

export const CoverPhotoWrapper = styled.div`
  margin: 0 25px 0 10px;
  width: 94px;
  height: 94px;
  position: relative;

  .online-indicator-wrapper {
    display: flex;
    justify-content: center;
    height: 20px;
    position: absolute;
    background-color: var(--background);
    box-shadow: 0px 3px 8px var(--shade);
    border-radius: 2px;
    top: 10%;
    left: -27%;

    span {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: var(--foreground);
      margin-left: 12px;
      margin-right: 7px;
    }
  }
`

export const CoverPhoto = styled.img`
  border-radius: 100%;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const Avatar = styled.img`
  border-radius: 100%;
  display: block;
  height: 75px;
  width: 75px;
  object-fit: cover;
  box-shadow: 0 0 8px 0 var(--shade);

  ${({ isOnCoverPhoto }) =>
    isOnCoverPhoto &&
    css`
      position: absolute;
      width: 40px;
      height: 40px;
      right: 2%;
      top: 60%;
    `}
`

export const OnlineIndicator = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border: 3.2px solid white;
  border-radius: 100%;
  bottom: 0;
  right: 2.5px;
  background-color: ${({ onlineStatus, isOnCoverPhoto, opportunityState }) => {
    if (opportunityState) {
      return opportunityState === 'active' ? '#3CC918' : '#4a4a4a'
    }

    if (isOnCoverPhoto) {
      return onlineStatus === 'ONLINE' ? '#3CC918' : '#4a4a4a'
    } else {
      return onlineStatus === 'ONLINE' ? '#4dcea6' : '#4a4a4a'
    }
  }};
  z-index: 1;

  ${({ isOnCoverPhoto }) =>
    isOnCoverPhoto &&
    css`
      border: none;
      width: 12px;
      height: 12px;
      left: 4px;
      top: 4.5px;
    `}
`

export const StyledSelect = styled(Select)`
  width: fit-content;

  .pf-c-select__toggle-wrapper {
    justify-content: flex-end;
  }

  .pf-c-select__toggle {
    background-color: transparent;
    color: var(--foreground);
  }
  .pf-c-select__toggle::before {
    border: none;
  }
  .pf-c-select__menu {
    ${({ listSelect }) => (listSelect ? '' : 'right: 20%;')}
  }

  .pf-c-select__toggle-arrow {
    margin-left: 3px;
  }

  ${({ isDetailView }) =>
    isDetailView &&
    css`
      .pf-c-select__toggle::before {
        border: none;
        box-shadow: 0px 3px 8px var(--shade);
        border-radius: 2px;
      }

      .pf-c-select__menu {
        right: auto;
      }

      .pf-c-select__toggle-wrapper {
        justify-content: flex-start;
      }
    `}
`
