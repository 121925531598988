const searchableFields = ['contactPerson', 'message']

export const filterSearchResults = ({ reportsList, filter, search }) => {
  const filtered = reportsList.filter((u) => {
    const s = search.trimStart().trimEnd()
    if (s === '' && filter === '') return true
    if (filter !== '' && u.status !== filter) return false
    let include = false
    searchableFields.map((sf) => {
      if (
        Object.keys(u).some((k) =>
          k === sf
            ? u[k]
              ? u[k].toLowerCase().indexOf(search.toLowerCase()) !== -1
              : false
            : false
        )
      ) {
        include = true
      }
      return sf
    })
    if (!include && s === '' && u.status === filter) return true
    return include
  })
  return filtered
}

export const sortResults = ({ sort, reportsList }) => {
  const sorted = [...reportsList].sort((a, b) =>
    a[sort.key] > b[sort.key]
      ? sort.order === 0
        ? -1
        : 1
      : sort.order === 0
      ? 1
      : -1
  )
  return sorted
}
