import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../shared/styles/sections'
import { useIntl } from 'react-intl'

import InputShared from './input-shared'
import TextareaShared from './textarea-shared'

const Dashboard = () => {
  const intl = useIntl()

  const renderMessageText = (value) =>
    intl.formatMessage({
      id: `admin.settings.general.metadata.${value}`,
    })

  return (
    <MainSection>
      <span className="title">{renderMessageText('title')}</span>
      <Row>
        <Col sm={12} md={6}>
          <InputShared
            type="siteTitle"
            title={`${renderMessageText('sitetitle')}`}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputShared
            type="siteTagline"
            title={`${renderMessageText('sitetagline')}`}
          />
        </Col>
        <Col sm={12} md={6}>
          <TextareaShared
            type="siteKeywords"
            title={`${renderMessageText('sitekeywords')}`}
          />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
