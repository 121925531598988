import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'

import { nextStep, previousStep } from './services/step'
import { GET_KEYWORDS, GET_TAXONOMY } from './graph-queries'
import defaultStepList from './step-list'

import ConsentModal from './consent-modal'

import { Button } from './steps/shared/button'
import Navigation from './navigation'
import ProfilePreview from './profile-preview/index'
import ComponentResolver from './component-resolver'
import { usePageTitle } from '_application'
import { useCustomAttributes } from '_custom-attributes/hooks'

import MainLayout from '_layouts/main'

import OnboardingStyled from './styles'

function useUrlQuery() {
  return new URLSearchParams(useLocation().search)
}

const hasUserName = (person) => {
  const { firstName, lastName } = person || null
  // if (/\s/g.test(firstName) || /\s/g.test(lastName)) return false
  return (firstName && firstName !== '' && lastName && lastName !== '') || false
}

const Index = () => {
  usePageTitle('Onboarding')

  const intl = useIntl()
  const { user } = useSelector((state) => state)
  const query = useUrlQuery()
  const history = useHistory()

  const [isNameError, setIsNameError] = useState(false)

  const { data: keywordData, keywordsLoading } = useQuery(GET_KEYWORDS, {
    variables: { usedBy: 'onboarding' },
    fetchPolicy: 'cache-and-network',
  })

  const { data: taxonomyData, taxonomyLoading } = useQuery(GET_TAXONOMY, {
    variables: { usedBy: 'onboarding' },
    fetchPolicy: 'cache-and-network',
  })

  const { attributes, attrLoading } = useCustomAttributes('onboarding')

  const mapData = ({ dataType, list }) => {
    return (
      list
        ?.map((k) => {
          return {
            ...k,
            title: k.name,
            subTitle: k.description,
            dataType,
          }
        })
        .filter((k) => k) || []
    )
  }

  const stepList = useMemo(() => {
    if (!keywordsLoading || !attrLoading || !taxonomyLoading) {
      return [
        ...defaultStepList,
        ...mapData({ dataType: 'custom-attribute', list: attributes }),
        ...mapData({
          dataType: 'custom-keyword',
          list: keywordData?.getKeywords,
        }),
        ...mapData({
          dataType: 'custom-taxonomy',
          list: taxonomyData?.getTaxonomy,
        }),
        // finalStep,
      ]
    }
    return []
  }, [
    attributes,
    attrLoading,
    keywordData,
    keywordsLoading,
    taxonomyData,
    taxonomyLoading,
  ])

  useEffect(() => {
    setStep({
      currentStep: 0,
      progressInPercentage: parseInt(((0 + 1) / stepList.length) * 100, 10),
    })
  }, [stepList])

  const [step, setStep] = useState({
    currentStep: 0,
    progressInPercentage: parseInt(((0 + 1) / stepList.length) * 100, 10),
  })

  const handleStepChange = (isNext, type) => {
    setIsNameError(false)
    if (step.isLastStep && type === 'discoverPage') {
      history.push(`${'/'}`)
    }
    let newStep = {}
    if (isNext) newStep = nextStep(step, stepList)
    else newStep = previousStep(step, stepList)
    setStep(newStep)
  }

  const progressCheck = ({ currentStep, isLastStep }) => {
    const renderButtonLabel = (type) => {
      return intl.formatMessage({
        id: `user.onboarding.step.${type.toLowerCase()}button`,
      })
    }

    return (
      <>
        {currentStep > 0 && (
          <Button
            handleClick={() => handleStepChange()}
            option={renderButtonLabel('previous')}
            testid="previous"
          />
        )}
        <Button
          currentStep={currentStep}
          isFirstStep={currentStep === 0}
          handleClick={() =>
            hasUserName(user.profile)
              ? handleStepChange(true, 'discoverPage')
              : setIsNameError(true)
          }
          option={
            !isLastStep ? (
              renderButtonLabel('next')
            ) : (
              <>{renderButtonLabel('finish')}</>
            )
          }
          testid={!isLastStep ? 'next' : 'finish'}
        />
      </>
    )
  }

  const displayTitle = (step) => {
    if (!stepList[step].dataType)
      return intl.formatMessage({
        id: `user.onboarding.step.${stepList[step].key.toLowerCase()}.title`,
      })
    return stepList[step].title
  }

  const displaySubtitle = (step) =>
    stepList[step]?.subTitle ||
    intl.formatMessage({
      id: `onboarding.subtitle.${stepList[step].key.toLowerCase()}`,
    })

  useEffect(() => {
    if (query.get('step')) {
      setStep({
        currentStep: 0,
        progressInPercentage: parseInt(
          ((step.currentStep + 1) / stepList.length) * 100,
          10
        ),
      })
      if (step.currentStep === 0) {
        history.replace({
          search: '',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile, step.currentStep, stepList])

  return (
    <MainLayout>
      <OnboardingStyled>
        <div className="main-container">
          <div className="base-container">
            <Navigation step={step} stepList={stepList} user={user} />

            <div className="content-container">
              <div className="progress-card">
                <h3
                  className="step-title"
                  data-testid={
                    step.currentStep
                      ? displayTitle(step.currentStep)
                      : 'no-step-title'
                  }
                >
                  {displayTitle(step.currentStep)}
                </h3>

                <p className="step-subtitle">
                  {displaySubtitle(step.currentStep)}
                </p>
                <div className="step-content">
                  <ComponentResolver
                    stepList={stepList}
                    step={step}
                    setStep={setStep}
                  />

                  <p className="error-message">
                    {isNameError
                      ? intl.formatMessage({
                          id: 'user.onboarding.nameerror.message',
                        })
                      : ''}
                  </p>
                </div>

                <div className="buttons-container">{progressCheck(step)}</div>
              </div>
              <div className="profile-preview">
                <ProfilePreview />
              </div>
            </div>
            {false ? <ConsentModal /> : null}
          </div>
        </div>
      </OnboardingStyled>
    </MainLayout>
  )
}

export default Index
