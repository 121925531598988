import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from '../../../../shared/ui-kit/buttons/single-button'
import Modal from 'react-bootstrap/Modal'
import Selector from 'components/indy/shared/ui-kit/selectors/multi-select-with-icon'

import ManageKeywordsStyled from './styles/manage-keywords.js'

const ModalIndex = ({ onClose, onSave, keywordList, keywordValues }) => {
  const [values, setValues] = useState(keywordValues || [])
  const intl = useIntl()

  const handleSelect = (list) => {
    setValues(
      list.map((l) => {
        return { type: 'internal', value: l }
      })
    )
  }

  const handleSave = () => {
    onSave(values)
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `user.profile.manage.keywords.modal.${value.toLowerCase()}`,
    })
  }

  const allKeywords = keywordList.keywordList.map((keyword) => ({
    key: keyword?.id,
    name: keyword?.value,
  }))

  return (
    <Modal show onHide={onClose}>
      <ManageKeywordsStyled>
        <Modal.Body>
          <div className="modal-content">
            <h3
              className="modal-title"
              data-testid="keywords-modal-title"
            >{`${renderMessageText('title')} ${keywordList.name}`}</h3>
            <p className="modal-description">{keywordList.description}</p>
            <Selector
              items={allKeywords}
              selectedValues={values.map((v) => v.value)}
              onSelect={(v) => handleSelect(v)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="admin"
            onClick={onClose}
            testid="keywords-modal-close"
          >
            {renderMessageText('close')}
          </Button>
          <Button
            type="admin"
            onClick={() => handleSave()}
            testid="keywords-modal-save"
          >
            {renderMessageText('save')}
          </Button>
        </Modal.Footer>
      </ManageKeywordsStyled>
    </Modal>
  )
}

export default ModalIndex
