import styled from '@emotion/styled'
import Card from '../card'
import { ctaButtonHollow } from '_utils/css-buttons'
import { mobile, pablet, tablet } from '_utils/responsive-queries'

export const CardStyled = styled(Card)`
  align-items: center;
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  ${pablet} {
    width: 100%;
  }
  ${mobile} {
    position: relative;
    .tooltipIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
  img {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    max-height: 500px;
    border-radius: 20px;
    object-fit: contain;
  }
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  /* max-width: 25%; */
  word-break: break-word;
  flex-shrink: 0;
  &.location,
  &.title-date {
    max-width: 45%;
  }
  &.file {
    align-self: center;
  }
  &.title-file {
    align-self: center;
  }
  &.title-badges {
    max-width: 100%;
  }

  ${pablet} {
    padding: 0 40px 0 15px;
    &.file {
      align-self: flex-start;
    }
  }
  ${tablet} {
    max-width: none;
  }
`
export const CAWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;
  font-size: 16px;
  color: var(--foreground);
  &.location {
    /* padding-left: 20px; */
  }
  ${pablet} {
    justify-content: ${({ isBadges }) => isBadges && 'center'};
    gap: 20px;
    padding: 20px 15px;
  }

  ${mobile} {
    justify-content: center;
    .grow {
      display: none;
    }
    .file-name {
      width: 100%;
      text-align: center;
    }
  }

  &.location {
    column-gap: 5px;
    i {
      margin-right: 10px;
    }
  }
  .file-link {
    ${ctaButtonHollow()}
  }
`
export const FileWrapper = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  .label {
    font-size: 18px;
  }
  .file-name {
    word-break: break-word;
    padding: 0 20px;
  }
`

export const ImgTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
`
export const DownloadLink = styled.a`
  ${ctaButtonHollow()}
  margin-left: auto;
  width: 100%;
`
