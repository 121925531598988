import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

export const Notification = styled.div`
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 25px;
  background-color: var(--shade);

  ${mobile} {
    width: 92vw;
  }
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  font-size: 16px;
  color: var(--flavor);
  font-weight: 500;
  padding: 15px 25px;
`

export const TimeAgo = styled.div`
  margin: auto 0;
  display: list-item;
  color: var(--foreground);
`

export const Name = styled.span`
  font-weight: 600;
  text-decoration: underline;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px 12px;

  ${mobile} {
    align-items: flex-start;
  }
`

export const BottomWrapper = styled.div`
  background-color: var(--flavor-shade);
  display: flex;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'end')};
  flex-direction: row-reverse;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const Description = styled.div`
  word-break: break-word;
  font-size: 16px;
  flex-grow: 1;
  color: var(--foreground);
  a {
    color: var(--foreground);
    text-decoration: underline;
    font-weight: 500;
  }
`

export const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const ShowMore = styled.button`
  border: none;
  background-color: transparent;
  color: var(--foreground);
  text-decoration: underline;
  font-size: 16px;
`

export const CancelButton = styled.button`
  padding: 6px 24px;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 18px 15px 0;
  background-color: var(--background);
  color: var(--foreground);
  transition: 0.3s;
  &:hover {
    color: var(--flavor);
  }
`

export const AcceptButton = styled.button`
  background-color: var(--flavor);
  border: none;
  padding: 6px 24px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 18px 15px 0;
  color: var(--flavor-contrast);
  transition: 0.4s;
  &:hover {
    background-color: var(--background);
    color: var(--flavor);
  }
`

export const AvatarWrapper = styled.div`
  border-radius: 100%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
  flex-shrink: 0;
  img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const ExpandedNotification = styled.div`
  padding: 50px;

  .content {
    min-width: 270px;
    max-width: 350px;
    margin: 0 auto;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
`

export const DeleteButton = styled.button`
  padding: 15px;
  color: var(--foreground);
  transition: 0.3s;
  border: none;
  background-color: transparent;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 20px;
  }
`
