import React from 'react'
import { EntityEditButtonStyled, Button } from './styles/entity-edit-button'
import { Link } from 'react-router-dom'

const EntityEditButton = ({ url }) => {
  return (
    <EntityEditButtonStyled>
      <Link to={url}>
        <Button type="button"
          className={`interact-button sanity-button ctaButton pen`}>
          <i className="fa fa-pen" />
        </Button> 
      </Link>
    </EntityEditButtonStyled>
  )
}

export default EntityEditButton
