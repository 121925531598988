import React from 'react'
import { useIntl } from 'react-intl'
import { useQuery, gql } from '@apollo/client'
import SectionHeader from '../components/section-header'
import moment from 'moment'
import { Loader } from '_components/loading'
import { useTheme } from 'emotion-theming'
import { Flex, FlexItem } from '@patternfly/react-core'

import {
  PageWrapper,
  CategoryWrapper,
  Description,
  Counter,
  LatestWrapper,
  Indicator,
  TopicAvatar,
  MainSectionContainer,
  CategoryIconWrapper,
  AdditionalInfoWrapper,
  FirstLineInfo,
  TopWrapper,
  CategoryLink,
} from '../shared/styles'

import {
  CategoryAvatar,
  SecondLineInfo,
  CountersWrapper,
  PageSection,
} from '../shared/styles/topic'

import { useMediaQuery } from 'react-responsive'
import { tabletPX } from '_utils/responsive-queries'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'

const GET_POPULAR_TOPICS = gql`
  {
    getPopularTopics {
      popularTopics {
        topicsList {
          tid
          title
          votes
          postcount
          viewcount
          timestamp
          timestampiso
          teaser {
            content
            timestampiso
            user {
              username
              picture
            }
          }
          user {
            picture
            username
          }
          category {
            name
            bgcolor
            icon
          }
          tagsList {
            title
            value
            tag
          }
        }
      }
    }
  }
`

const Popular = () => {
  const intl = useIntl()
  const theme = useTheme()
  const { data } = useQuery(GET_POPULAR_TOPICS)

  const nameLimit = 30
  const descLimit = 45

  const isDesktopView = useMediaQuery({ query: `(min-width: ${tabletPX}px)` })

  const setCharacterLimit = (string, limit) => {
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  const displayTags = (tagList) => {
    if (!tagList || tagList.length < 1) return <></>
    return (
      <>
        <span className="bullet-wrapper">
          <i className="fas fa-circle bullet" />
        </span>
        <i className="fas fa-tag" />
        {tagList[0].value}
        {tagList.length > 1 && ` + ${tagList.length - 1}`}
      </>
    )
  }

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0
      ? ''
      : setCharacterLimit(e.childNodes[0].nodeValue, descLimit)
  }

  if (!data) return <Loader loading={!data} testid="popularTopics" />

  return (
    <PageWrapper>
      <SectionHeader>
        <Flex>
          <FlexItem align={{ default: 'alignRight' }}>
            {/* <FilterButton>
              {intl.formatMessage({ id: 'forum.header.sortbutton.bytime' })}
            </FilterButton>
            <FilterButton>
              {intl.formatMessage({ id: 'forum.header.sortbutton.bytopics' })}
            </FilterButton>
            <FilterButton>
              {intl.formatMessage({
                id: 'forum.header.sortbutton.bycategories',
              })}
            </FilterButton> */}
          </FlexItem>
        </Flex>
      </SectionHeader>
      {data?.getPopularTopics?.popularTopics?.topicsList
        .slice(0, 5)
        .map(function (object, i) {
          return (
            <MainSectionContainer hasGutter key={i}>
              <CategoryWrapper span={6}>
                <TopWrapper isTopElement={true}>
                  <CategoryLink to={`/forum/topic/${object.tid}`}>
                    <CategoryAvatar>
                      <img
                        src={
                          object?.user?.picture || theme?.images?.defaultAvatar
                        }
                        alt=""
                      />
                    </CategoryAvatar>
                    <Description>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: unescape(object.title),
                        }}
                        data-testid={`popularTopic-${object?.title}`}
                      ></p>
                      <AdditionalInfoWrapper>
                        <FirstLineInfo>
                          <CategoryIconWrapper
                            categoryColor={object.category.bgcolor}
                          >
                            <i className={`fa ${object.category.icon}`} />
                          </CategoryIconWrapper>
                          <span> {unescape(object.category.name)}</span>
                        </FirstLineInfo>
                        <SecondLineInfo>
                          <span>
                            {replaceUsernameNumbers(object.user?.username)}
                          </span>
                          <span className="bullet-wrapper">
                            <i className="fas fa-circle bullet" />
                          </span>
                          {moment(object.timestampiso).fromNow(true)}
                          <>{displayTags(object.tagsList)}</>
                        </SecondLineInfo>
                      </AdditionalInfoWrapper>
                    </Description>
                  </CategoryLink>
                  <CountersWrapper span={2}>
                    <PageSection>
                      <Counter>{object.votes}</Counter>
                      <span className="item-name">
                        {intl.formatMessage({
                          id: 'forum.stats.votes',
                        })}
                      </span>
                    </PageSection>
                    <PageSection>
                      <Counter>{object.postcount}</Counter>
                      <span className="item-name">
                        {intl.formatMessage({
                          id: 'forum.stats.posts',
                        })}
                      </span>
                    </PageSection>
                    <PageSection>
                      <Counter>{object.viewcount}</Counter>
                      <span className="item-name">
                        {intl.formatMessage({
                          id: 'forum.stats.views',
                        })}
                      </span>
                    </PageSection>
                  </CountersWrapper>
                </TopWrapper>
              </CategoryWrapper>

              {isDesktopView && (
                <LatestWrapper span={4}>
                  <Indicator
                    sectionColor={object.category.bgcolor}
                    href={`/forum/topic/${object.tid}`}
                    isTopicView
                  >
                    {object.teaser ? (
                      <>
                        <div className="author-wrapper">
                          <TopicAvatar>
                            <img
                              src={
                                object.teaser.user.picture ||
                                theme?.images?.defaultAvatar
                              }
                              alt=""
                            />
                          </TopicAvatar>
                          <div className="author-info">
                            <div className="author-name">
                              <p>
                                {setCharacterLimit(
                                  replaceUsernameNumbers(
                                    object?.teaser?.user?.username
                                  ),
                                  nameLimit
                                )}
                              </p>
                              <div className="date-wrapper">
                                <span className="bullet-wrapper">
                                  <i className="fas fa-circle bullet" />
                                </span>
                                <span>
                                  {moment(object.teaser.timestampiso).fromNow(
                                    true
                                  )}
                                </span>
                              </div>
                            </div>

                            <p
                              className="topic-name"
                              dangerouslySetInnerHTML={{
                                __html: unescape(object.teaser.content),
                              }}
                            ></p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <span className="no-posts">
                        {intl.formatMessage({ id: 'forum.info.nonewposts' })}
                      </span>
                    )}
                  </Indicator>
                </LatestWrapper>
              )}
            </MainSectionContainer>
          )
        })}
    </PageWrapper>
  )
}

export default Popular
