import styled from '@emotion/styled'

export const NavigationStyled = styled.div`
  padding: 25px;
  background: var(--background);
  box-shadow: 0 3px 8px 0px rgb(0 0 0 / 20%);
  border-radius: 12px;

  .admin-welcome-text {
    font-size: 28px;
    font-weight: 600;
  }
`

export const NavItems = styled.div`
  margin-top: 18px;
  .nav-item.active {
    a,
    i {
      font-weight: 700;
      color: var(--flavor);
    }
  }
  a {
    position: relative;
    display: block;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 16px;
    font-weight: 400;
    color: var(--foreground);
    transition: 0.3s;
    &:hover {
      opacity: 0.9;
      color: var(--flavor);
    }
  }
  .nav-item {
    list-style: none;
    position: relative;
  }
  .nav-item-content {
    margin-left: 10px;
    & > div.nav-item-main {
      a {
        color: var(--foreground);
        &:hover {
          color: var(--flavor);
        }
      }
    }
    .nav-children {
      .nav-item {
        &.active {
          .nav-item-content {
            a,
            i {
              font-weight: 700;
              color: var(--flavor);
            }
          }
        }
        a {
          border: none;
          font-size: 14px;
          padding: 10px 15px;
          color: var(--foreground);
          &:hover {
            opacity: 0.9;
            color: var(--flavor);
          }
        }
      }
    }
    .arrow-indicator {
      display: none;
      cursor: pointer;
      position: absolute;
      right: -5px;
      top: 23%;
      font-size: 18px;
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    .nav-item-main {
      .dropdown-icon {
        opacity: 0.4;
        cursor: pointer;
        position: absolute;
        top: 26px;
        right: 6px;
        font-size: 16px;
        transform: translateY(-50%);
      }
      &:hover {
        .dropdown-icon {
          opacity: 1;
          color: var(--flavor);
        }
      }
    }
    &.active {
      opacity: 1;
    }
    &.active {
      a {
        font-weight: 600;
        font-size: 14px;
      }
      .dropdown-icon {
        opacity: 1;
      }
    }
  }
  i {
    color: var(--foreground);
  }
  i.fas.fa-angle-right {
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
`
