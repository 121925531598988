export const isWebRTCSupported = () => {
  try {
    const supportsGetUserMedia = !!(
      window.navigator.mozGetUserMedia ||
      window.navigator.webkitGetUserMedia ||
      window.navigator.msGetUserMedia ||
      window.navigator.getUserMedia
    )
    const supportsRTCPeerConnection = !!(
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection
    )
    return supportsGetUserMedia && supportsRTCPeerConnection
  } catch (e) {
    return false
  }
}
