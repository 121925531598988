import React from 'react'
// import styled from '@emotion/styled'
import { Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'
import ScrollToTop from '_components/scroll-to-top'
import { useFilters } from '_utils/filters'
import LoginRequired from '_security/login-required'
import PeopleHeader from './components/people-header'
import PeopleMapConnected from './connected/people-map-connected'
import PeopleGridConnected from './connected/people-grid-connected'
import PersonDetailsConnected from './connected/person-details-connected'
import { useAppLabel, usePageTitle } from '_application'
import { Container } from '../_styled-components/shared/section-container'
import styled from '@emotion/styled'
import { tablet } from '_utils/responsive-queries'

const People = () => {
  const { filter, setFilter } = useFilters()
  const appLabel = useAppLabel()
  const title = appLabel('peoplePlural', 'people.titleplural')
  const description = appLabel('peopleDescription', 'empty')
  usePageTitle(title)

  const DetailPageContainer = styled(Container)`
    padding: 100px 0 0 0;
    ${tablet} {
      padding: 100px 0 0 0;
    }
  `

  return (
    <MainLayout>
      <Switch>
        <Route path="/people/:section/:userSub/:tab?">
          <DetailPageContainer className={"page-wrapper"}>
            <LoginRequired showLoginPopup={true}>
              {() => <PersonDetailsConnected />}
            </LoginRequired>
          </DetailPageContainer>
        </Route>
        <Route path="*">
          <Container className={"page-wrapper"}>
            <ScrollToTop />
            <PageTitle description={description}>{title}</PageTitle>
            <PeopleHeader filter={filter} onFilterChange={setFilter} />
            <Switch>
              <Route
                path="/people/map"
                render={() => <PeopleMapConnected filter={filter} />}
              />
              <Route
                path="/people/list"
                render={() => <PeopleGridConnected filter={filter} />}
              />
              <Redirect from="/people" to="/people/list" />
            </Switch>
          </Container>
        </Route>
      </Switch>
    </MainLayout>
  )
}

export default People
