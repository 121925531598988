import { gql } from '@apollo/client'

export const GET_EVENT = gql`
  query Query($getEventId: ID!) {
    getEvent(id: $getEventId) {
      id
      title
      bannerImage
      description
      excerpt
      end
      start
      doorsOpen
      categories {
        key
        childrenKeys
      }
      creatorId
      step
      venueLocation {
        type
        address
        link
        lat
        long
      }
      speakers {
        id
        sub
        avatar
        name
        connectionStatus
      }
      keywords {
        key
        childrenKeys
      }
      badges {
        key
        childrenKeys
      }
      companies {
        id
        uid
        name
        coverPhoto
        connectionStatus
        owner {
          id
        }
      }
      activities {
        id
        uid
        name
        coverPhoto
        connectionStatus
        owner {
          id
        }
      }
      status
      publishAt
      _createdAt
      attributes {
        texts {
          key
          value
        }
        urls {
          key
          value
        }
        dates {
          key
          value
        }
        phones {
          key
          value
        }
        emails {
          key
          value
        }
        images {
          key
          value
        }
        booleans {
          key
          value
        }
        badges {
          key
          value
        }
        locations {
          key
          city
        }
        files {
          key
          value {
            name
            url
            extension
            dateCreated
            dateUpdated
            type
          }
        }
      }
      topics {
        key
        childrenKeys
      }
    }
  }
`
export const CREATE_EVENT = gql`
  mutation createEvent {
    createEvent
  }
`

export const UPDATE_EVENT = gql`
  mutation updateEvent($event: UpdateEvent) {
    updateEvent(event: $event) {
      id
      title
      description
      excerpt
      step
      status
      doorsOpen
      end
      start
      speakers {
        id
      }
      companies {
        id
      }
      activities {
        id
      }
      bannerImage
      publishAt
      venueLocation {
        type
        address
        link
        lat
        long
      }
      categories {
        key
        childrenKeys
      }
      creatorId
      keywords {
        key
        childrenKeys
      }
      attributes {
        texts {
          key
          value
        }
        urls {
          key
          value
        }
        emails {
          key
          value
        }
        images {
          key
          value
        }
        booleans {
          key
          value
        }
        badges {
          key
          value
        }
        files {
          key
          value {
            name
            url
            extension
            dateCreated
            dateUpdated
            type
          }
        }
        locations {
          key
          lat
          long
          city
          country
          label
        }
        dates {
          key
          value
        }
      }
    }
  }
`

export const GET_KEYWORDS = gql`
  query GetKeywords($key: String, $usedBy: [String]) {
    getKeywords(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      keywordList {
        id
        value
      }
    }
  }
`

export const GET_ALL_KEYWORDS = gql`
  query {
    getKeywords {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      keywordList
    }
  }
`

export const GET_TAXONOMY = gql`
  query GetTaxonomy($key: String, $usedBy: [String]) {
    getTaxonomy(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      treeData {
        key: id
        name
        parent
        refId
      }
    }
  }
`

export const CONNECT_EVENT_TO_PERSON = gql`
  mutation Mutation($eventId: String!, $id: String!) {
    connectEventToPerson(eventId: $eventId, personId: $id) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const CONNECT_EVENT_TO_ACTIVITY = gql`
  mutation Mutation($id: String!, $eventId: String!, $activityOwner: String!) {
    connectEventToActivity(
      activityId: $id
      eventId: $eventId
      activityOwner: $activityOwner
    ) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const CONNECT_EVENT_TO_COMPANY = gql`
  mutation Mutation($id: String!, $eventId: String!, $companyOwner: String!) {
    connectEventToCompany(
      companyId: $id
      eventId: $eventId
      companyOwner: $companyOwner
    ) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DISCONNECT_EVENT_TO_COMPANY = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToCompany(companyId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DISCONNECT_EVENT_TO_ACTIVITY = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToActivity(activityId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DISCONNECT_EVENT_TO_PERSON = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToPerson(personId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DELETE_EVENT = gql`
  mutation deleteEvent($deleteEventId: ID!) {
    deleteEvent(id: $deleteEventId)
  }
`
