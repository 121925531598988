import React from 'react'
import { useTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'
import { mobile } from '_utils/responsive-queries'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'

export const DefaultTileStyled = styled.div`
  position: relative;
  display: flex;
  cursor: default;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 34px 0;
  height: 100%;
  justify-content: space-between;
  background-color: var(--background);
  ${border()}
`

export const Header = styled.div``

export const EntityInfo = styled.div``

export const LikeWrapper = styled.div``

export const Controls = styled.div``

export const Content = styled.div``

export const Description = styled.div`
  margin-top: 10px;
  font-size: 16px;
  padding: 0 25px;
  color: var(--foreground);
  word-break: break-word;
`

export const TopContentWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const Title = styled.div``

export const Name = styled.h2`
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
  color: var(--foreground);
  word-break: break-word;
`

export const DiscoverName = styled.h3`
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
  color: var(--foreground);
  word-break: break-word;
`

export const Location = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--foreground);
  margin: 10px 0 15px;
  padding: 0 25px;
  font-size: 12px;
  overflow: hidden;
  svg {
    margin-right: 6px;
    path {
      stroke: var(--foreground);
    }
  }
`

export const Avatar = styled.div`
  position: relative;
  width: 164px;
  height: 164px;
  margin: 0 auto 15px;
  img {
    ${border()}
    ${imageSkeleton}

    height: 164px;
    width: 164px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }
  /* ${mobile} {
    width: 75px;
    height: 75px;
    img {
      width: 75px;
      height: 75px;
    }
  } */
`

export const Date = styled.div``

export const ConnectButtonContainer = styled.div`
  padding: 0 25px;
  display: flex;
  button.video-button {
    ${ctaButtonHollow}
    margin-left: 10px;
    max-width: 42px;
    width: 42px;
    padding: 0px;
  }
`

export const OnlineIndicator = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ isOnline }) => (isOnline ? '#4dcea6' : '#cecece')};
  border-radius: 100%;
  border: 4px solid var(--background);
  position: absolute;
  right: 25px;
  bottom: 0px;
  /* ${mobile} {
    right: 0;
  } */
`

export const DropdownButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  transition: 0.4s ease-in-out 0.1s;
  transform: ${({ isMenuOpen }) => isMenuOpen && 'rotate(90deg)'};
  font-size: 20px;
  &:hover {
    i {
      color: var(--flavor);
    }
  }
`

export const DotsButtonContainer = styled.div``

export const Menu = styled.div`
  position: absolute;
  right: 25px;
  top: 45px;
  z-index: 3;
`

export const DisconnectUserButton = styled.button`
  border: none;
  color: black;
  padding: 5px 15px;
  ${border(0.3, 8, 2, 5)}
  border-radius: 5px;
  transition: 0.8s;
  &:hover {
    background-color: var(--flavor);
    color: var(--flavor-contrast);
  }
`

export const UserTile = ({ children, person }) => {
  const { picture, username, status } = person || {}

  const theme = useTheme()
  const isOnline = status === 'online'

  return (
    <DefaultTileStyled>
      <TopContentWrapper>
        <Avatar>
          <img src={picture || theme?.images?.defaultAvatar} alt="" />
          <OnlineIndicator isOnline={isOnline} />
          {children}
        </Avatar>
        <Name>{replaceUsernameNumbers(username)}</Name>
      </TopContentWrapper>
    </DefaultTileStyled>
  )
}
