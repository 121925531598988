import React from 'react'
import DOMPurify from 'dompurify'

import { AboutEventWrapper, DescriptionWrapper } from './styles/about-event'


const AboutEvent = ({ event }) => {
  const renderBioLong = () => {
    const { description } = event || {}

    if (/<\/?[a-z][\s\S]*>/i.test(description)) {
      return (
        <DescriptionWrapper
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description, {
              ALLOWED_TAGS: [
                'iframe',
                'table',
                'tbody',
                'tr',
                'td',
                'th',
                'thead',
                'br',
                'p',
                'h1',
                'h2',
                'h3',
                'h4',
                'strong',
                'u',
                'em',
                'ol',
                'ul',
                'li',
                'a',
                'blockquote',
                'img',
                'span',
              ],
              ALLOWED_ATTR: ['style', 'src', 'class', 'href', 'target', 'allowfullscreen']
            }),
          }}
        ></DescriptionWrapper>
      )
    }
    return description
  }

  return <AboutEventWrapper>{renderBioLong()}</AboutEventWrapper>
}

export default AboutEvent
