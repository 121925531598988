import React from 'react'
import PublicDetailsCard from '_public-details-card'

import {
  CONNECTED_COMPANIES,
  CONNECTED_USERS,
  CONNECTED_OPPORTUNITIES,
  CONNECTED_ACTIVITIES,
} from './graph-queries'

const Index = ({ entityId, currentTab }) => {
  const connections = {
    company: {
      gqlQuery: CONNECTED_COMPANIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getCompanyConnectedCompaniesPaged',
      translationKey: 'companies.titleplural',
    },
    people: {
      gqlQuery: CONNECTED_USERS,
      gqlParams: { id: entityId },
      gqlQueryName: 'getCompanyConnectedUsersPaged',
      translationKey: 'people.titleplural',
    },
    activity: {
      gqlQuery: CONNECTED_ACTIVITIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getCompanyConnectedActivitiesPaged',
      translationKey: 'activity.titleplural',
    },
    opportunity: {
      gqlQuery: CONNECTED_OPPORTUNITIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getCompanyConnectedOpportunitiesPaged',
      translationKey: 'opportunities.titleplural',
    },
  }

  return (
    <PublicDetailsCard.ConnectionsPaged
      currentTab={currentTab}
      connections={connections}
      useFollowersLabel={true}
    />
  )
}

export default Index
