import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Opportunities from './opportunities'
import Blog from './blog'
import Forum from './forum'
import Events from './events'
import { MenuBuilder } from './menu'
import useIsFeatureOn from '_application/is-feature-on'
import { Pages } from './pages'
import { Footer } from './footer'

const defaultManageDataRoute = '/site-structure'
const defaultRoute = `/manage${defaultManageDataRoute}`

const Routes = () => {
  return (
    <Switch>
      <Route
        path={`${defaultRoute}/opportunities`}
        render={() => <Opportunities />}
      />
      <Route path={`${defaultRoute}/blog`} render={() => <Blog />} />
      {useIsFeatureOn('forum') && (
        <Route path={`${defaultRoute}/forum`} render={() => <Forum />} />
      )}
      {useIsFeatureOn('events') && (
        <Route path={`${defaultRoute}/events`} render={() => <Events />} />
      )}
      <Route path={`${defaultRoute}/menu`} component={MenuBuilder} />
      <Route path={`${defaultRoute}/pages`} component={Pages} />
      <Route path={`${defaultRoute}/footer`} component={Footer} />
      <Redirect from={defaultRoute} to={`${defaultRoute}/opportunities`} />
    </Switch>
  )
}

export default Routes
