import React from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'
import {
  Content,
  VisibilityContainer,
  VisibilityIcon,
  DurationContainer,
  DurationContainerNoPadding,
  DurationButton,
  ActivityTitle,
  ConnectButton,
  AdditionalInfo,
} from '../styles/content-header'
import lockIcon from 'resources/lock-icon.png'
import { useAppConfig } from '_application'

const ContentHeader = ({
  children,
  title,
  isPrivate,
  recommendButton,
  startDate,
  endDate,
  applyByDate,
  isStartDateCreated,
  connectButton,
  isOwner,
  type,
}) => {
  const intl = useIntl()
  const appConfig = useAppConfig()

  const renderDates = () => {
    let date = ''

    if (applyByDate) {
      date =
        intl.formatMessage({ id: 'opportunity.tile.applyby' }) +
        ' ' +
        moment(applyByDate).format('DD.MM.YYYY')
      return (
        <DurationContainer>
          <DurationButton>{date ? date : null}</DurationButton>
        </DurationContainer>
      )
    }

    if (startDate && endDate) {
      date = `${moment(startDate).format('DD.MM.YYYY')} - ${moment(
        endDate
      ).format('DD.MM.YYYY')}`
    } else if (startDate) {
      date = `${intl.formatMessage({
        id: 'sanity.event.start.title',
      })} ${moment(startDate).format('DD.MM.YYYY')} `
    } else if (endDate) {
      date = `${intl.formatMessage({ id: 'sanity.event.end.title' })} ${moment(
        endDate
      ).format('DD.MM.YYYY')}`
    }

    if (date && isOwner) {
      return (
        <DurationContainerNoPadding>
          <DurationButton>{date ? date : null}</DurationButton>
        </DurationContainerNoPadding>
      )
    } else if (date) {
      return (
        <DurationContainer>
          <DurationButton>{date ? date : null}</DurationButton>
        </DurationContainer>
      )
    } else {
      return <></>
    }
  }

  return (
    <Content>
      {isPrivate && (
        <VisibilityContainer>
          <VisibilityIcon
            // That is a temporary usage. Until we get a correct icons
            isDarkTheme={appConfig?.theme === 'secondary'}
            src={lockIcon}
          />
        </VisibilityContainer>
      )}
      <ActivityTitle>{title}</ActivityTitle>
      {(startDate || endDate || applyByDate) && (
        <AdditionalInfo>{renderDates()}</AdditionalInfo>
      )}
      {!isOwner && connectButton && (
        <ConnectButton>{!isOwner && connectButton}</ConnectButton>
      )}
    </Content>
  )
}

export default ContentHeader
