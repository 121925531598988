import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Row, Col } from 'react-bootstrap'
import { renderField } from '../shared/field-factory'

import { Section, Field } from './styles/index'
import { useIntl } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const CommonAttributes = ({ user, dispatch }) => {
  const [errors, setErrors] = useState({})
  const intl = useIntl()

  const handleSaveGeneric = (key, value) => {
    const { profile } = user
    profile[key] = value
    saveProfile(profile)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const sections = [
    {
      name: intl.formatMessage({ id: 'user.profile.generalinformation' }),
      fields: [
        {
          key: 'bioShort',
          name: intl.formatMessage({
            id: 'user.profile.generalinformation.status',
          }),
          type: 'text',
          onError: (attr, message) =>
            setErrors({ ...errors, bioShort: message }),
          onSave: (attr, v) => handleSaveGeneric('bioShort', v),
          md: 12,
          maxLength: 40,
        },
        {
          key: 'bioLong',
          name: intl.formatMessage({
            id: 'user.profile.generalinformation.longbio',
          }),
          type: 'wysiwyg',
          onError: (attr, message) =>
            setErrors({ ...errors, bioLong: message }),
          onSave: (attr, v) => handleSaveGeneric('bioLong', v),
          md: 12,
        },
      ],
    },
    {
      name: intl.formatMessage({
        id: 'user.profile.socialmedia',
      }),
      fields: [
        {
          key: 'linkedIn',
          name: intl.formatMessage({
            id: 'user.profile.socialmedia.linkedin',
          }),
          type: 'text',
          placeholder: 'https://www.linkedin.com/',
          onSave: (attr, v) => handleSaveGeneric('linkedIn', v),
        },
        {
          key: 'twitter',
          name: intl.formatMessage({
            id: 'user.profile.socialmedia.twitter',
          }),
          type: 'text',
          placeholder: 'https://www.twitter.com/',
          onSave: (attr, v) => handleSaveGeneric('twitter', v),
        },
        {
          key: 'instagram',
          name: intl.formatMessage({
            id: 'user.profile.socialmedia.instagram',
          }),
          type: 'text',
          placeholder: 'https://www.instagram.com/',
          onSave: (attr, v) => handleSaveGeneric('instagram', v),
        },
      ],
    },
  ]

  return (
    <div>
      {sections.map((section) => {
        return (
          <Section key={section.name}>
            <Row>
              <Col sm={12}>
                <span className="section-title field-title">
                  {section.name}
                </span>
                <div className="section-content">
                  <Row>
                    {section.fields.map((field) => {
                      return (
                        <Col key={field.name} sm={12} md={field.md || 6}>
                          <Field>
                            <span
                              className="field-title"
                              data-testid={`about-${field.key}`}
                            >{`${field.name} ${
                              field.required ? '*' : ''
                            }`}</span>
                            {renderField({
                              attribute: field,
                              user,
                              isCustomAttr: false,
                              onSave: field.onSave,
                              onError: field.onError,
                              error: errors[field.key],
                              maxLength: field.maxLength,
                              disabled: field.disabled,
                            })}
                          </Field>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Col>
            </Row>
          </Section>
        )
      })}
    </div>
  )
}

export default connect(mapStateToProps)(CommonAttributes)
