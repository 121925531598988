import styled from '@emotion/styled'

export const Section = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0;
  color: var(--foreground);
  font-size: 12px;
  .section-title {
    position: relative;
    font-size: 22px !important;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.profileSectionTitle};
  }
`

export const Field = styled.div`
  .field-title {
    display: block;
    font-size: 14px;
    color: var(--foreground);
    font-weight: 500;
    margin: 20px 0 10px;
  }
  .file-details {
    color: var(--foreground);
  }

  input[type='email']:disabled {
    background: #e7e5e5;
  }
`
