import styled from '@emotion/styled'

export const DotsWrapper = styled.div`
  position: absolute;
  z-index: 9;
  display: none;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--background);
  box-shadow: ${({ isRecommend }) =>
    !isRecommend && `0 0 8px 0 rgba(0, 0, 0, 0.16)`};
`

export const DotsExpanded = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 80px;
  width: 125px;
  cursor: default;
  position: absolute;
  border: 1px solid var(--foreground);
  background-color: var(--background);
  top: 10px;
  right: 50px;
  border-radius: 5px;
  border-top-right-radius: 0;
  button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    span {
      font-size: 16px;
    }
  }
`

export const Dots = styled.button`
  border: none;
  background-color: transparent;
  position: relative;
  display: block;
  i {
    font-size: 15px;
    color: transparent;
  }
`

export const AcceptButton = styled.button`
  text-align: left;
  height: 50%;
  transition: 0.3;
  i {
    color: var(--foreground);
    font-size: 15px;
    margin-right: 10px;
    margin-left: 5px;
  }
  &:hover {
    background-color: var(--flavor-shade);
    i {
      color: var(--flavor);
    }
  }
`

export const DeclineButton = styled.button`
  text-align: left;
  height: 50%;
  transition: 0.3;
  i {
    color: var(--foreground);
    font-size: 15px;
    margin-right: 10px;
    margin-left: 5px;
  }
  &:hover {
    background-color: var(--flavor-shade);
    i {
      color: var(--flavor);
    }
  }
`
export const Info = styled.p`
  flex-basis: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  margin: auto 0;
  font-weight: 500;
  padding-right: 10px;
`

export const NotificationsWrapper = styled.li`
  position: relative;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 10px 15px 18px;
  color: var(--foreground);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  border-left: 3px solid transparent;
  &.selected {
    border-left: 3px solid var(--flavor);
    background-color: var(--flavor-shade);
  }
  &:hover {
    color: var(--foreground);
    border-left: 3px solid var(--flavor);
    background-color: var(--flavor-shade);
    ${Dots} {
      display: block;
      i {
        color: inherit;
        display: block;
      }
    }
    ${DotsWrapper} {
      display: ${({ isRecommend }) => !isRecommend && 'flex'};
    }
    ${Info} {
      color: var(--flavor-contrast);
    }
  }
  &.force-show {
    color: var(--flavor);
    border-left: 3px solid var(--flavor);
    background-color: var(--flavor-shade);
    ${Dots} {
      display: block;
      i {
        display: block;
        color: inherit;
      }
    }
    ${DotsWrapper} {
      display: flex;
    }
  }
  a {
    color: inherit;
    width: 100%;
  }
`
