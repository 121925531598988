import React from 'react'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import { MediaUploadStyled } from './styles/media-upload'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'
import { useAppConfig } from '_application'
import { MbToB } from 'services/file'

const MediaUpload = ({ attachments, setAttachments, maxFilesCount }) => {
  const intl = useIntl()
  const { _id } = useUser()
  const { tenantId } = useAppConfig()

  const uploadConfig = {
    folderName: 'opportunities',
    tagsConfig: {
      userId: _id,
      tenantId: tenantId,
      context: 'application-management',
    },
    maxImageFileSize: MbToB(5),
    maxImageWidth: 2000,
  }

  const isMaxFilesCountExceeded = attachments?.length >= maxFilesCount

  const handleUpload = (media) => {
    if (!isMaxFilesCountExceeded)
      setAttachments([
        ...attachments,
        { url: media?.url, name: media?.original_filename },
      ])
  }

  const { showWidget } = useMediaUploader(uploadConfig, handleUpload)

  return (
    <MediaUploadStyled>
      {!isMaxFilesCountExceeded && (
        <button onClick={showWidget}>
          {intl.formatMessage({ id: 'application.management.upload' })}
        </button>
      )}
    </MediaUploadStyled>
  )
}

export default MediaUpload
