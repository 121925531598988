import React from 'react'
import DatePicker from '_content-studio/components/date-picker'
import ToggleButton from '_content-studio/components/toggle-button'
import { useAppConfig, useAppLabel } from '_application'
import CustomAttributes from '../../../components/custom-attributes'
import EntityConnection from '_content-studio/components/entity-connections'
import { useParams } from 'react-router-dom'
import Keywords from '../../../components/keywords'
import Taxonomies from '_content-studio/components/taxonomies'
import { useIntl } from 'react-intl'

const AdditionalInformation = ({ formData, changeFormProperty, uploadConfig, entity }) => {
    const intl = useIntl()
    const appLabel = useAppLabel()
  const { defaultLocale } = useAppConfig()

  const { connectedCompanies, connectedOpportunities } = entity || {}

  const { id } = useParams()

  const canRenderConnections = id || formData?.id

  return (
    <>
      {canRenderConnections && (
        <>
          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to company',
              },
              { entity: appLabel('companies', 'companies.title') }
            )}
            entityType={'company'}
            entityName={appLabel('companies', 'companies.title')}
            connectionType="a2c" 
            id={formData?.id}
            connections={connectedCompanies}
          />

          <EntityConnection
            title={intl.formatMessage(
              {
                id: 'contentstudio.additional.connection.title',
                defaultMessage: 'Connect to opportunity',
              },
              { entity: appLabel('opportunities', 'opportunities.title') }
            )}
            entityType={'opportunity'}
            entityName={appLabel('opportunities', 'opportunities.title')}
            connectionType="a2o"
            id={formData?.id}
            connections={connectedOpportunities}
          />
        </>
      )}

      <DatePicker
        value={formData.start}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.start.title',
            defaultMessage: 'Start',
          }),
          description: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.start.description',
              defaultMessage: `When the ${appLabel(
                'activities',
                'activities.title'
              )} starts`,
            },
            { entity: appLabel('activities', 'activities.title') }
          ),
          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ start: value })}
      />

      <DatePicker
        value={formData.end}
        config={{
          label: intl.formatMessage({
            id: 'contentstudio.additional.date.end.title',
            defaultMessage: 'End',
          }),
          description: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.end.description',
              defaultMessage: `When the ${appLabel(
                'activities',
                'activities.title'
              )} ends`,
            },
            { entity: appLabel('activities', 'activities.title') }
          ),
          defaultLocale,
        }}
        onSave={(value) => changeFormProperty({ end: value })}
      />

      <CustomAttributes
        entityType="activities"
        changeFormProperty={changeFormProperty}
        uploadConfig={uploadConfig}
        formData={formData}
      />
      <h1>{appLabel('activities', 'activities.title')}</h1>
      <ToggleButton
        value={formData.isDraft}
        onSave={(value) => changeFormProperty({ isDraft: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.isdraft.toggle.title',
            defaultMessage: 'Is draft?',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.isdraft.toggle.description',
              defaultMessage: `If this ${appLabel(
                'activities',
                'activities.title'
              )} is draft`,
            },
            { entity: appLabel('activities', 'activities.title') }
          ),
        }}
      />

      <ToggleButton
        value={formData.private}
        onSave={(value) => changeFormProperty({ private: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.private.toggle.title',
            defaultMessage: 'Private',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.private.toggle.description',
              defaultMessage: `If this ${appLabel(
                'activities',
                'activities.title'
              )} is private`,
            },
            { entity: appLabel('activities', 'activities.title') }
          ),
        }}
      />

      <Keywords
        entityType="activities"
        keywords={formData.keywords}
        changeFormProperty={changeFormProperty}
      />
      <Taxonomies
        entityType="activities"
        taxonomies={formData.taxonomy}
        changeFormProperty={changeFormProperty}
      />
    </>
  )
}

export default AdditionalInformation
