import React from "react"
import Title from '_content-studio/components/title'
import ShortDescription from '_content-studio/components/short-desc'
import { WysiwygWrapper } from '_content-studio/components/styles/wysiwyg-wrapper'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import BannerImage from '_content-studio/components/banner-image'
import Location from '_content-studio/components/location'
import { useUser } from '_security/session-hooks'

import Url from '../../../components/url'
import { useIntl } from "react-intl"
import { useAppLabel } from "_application"
import BreakLine from "_content-studio/components/break-line"

import AuthorSelector from '_content-studio/components/owner-selector'

const GeneralInformation = ({ formData, changeFormProperty, uploadConfig, entity }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const { sub } = useUser()

const resolvedEntityName =  appLabel('companies', 'companies.title')

  return (
    <>
      <Title
        onSave={(value) => changeFormProperty({ name: value })}
        value={formData.name}
      />
      <ShortDescription
        onSave={(value) => changeFormProperty({ description: value })}
        value={formData.description}
      />

      <WysiwygWrapper>
        <label htmlFor="postContent" className="desc-label">
          {intl.formatMessage({
            id: 'contentstudio.entity.description',
            defaultMessage: 'Description',
          })}
          <span className="required-star">*</span>
        </label>
        <span>
          {intl.formatMessage(
            {
              id: 'contentstudio.entity.description.info',
              defaultMessage: `What is the ${resolvedEntityName.toLowerCase()} about?`,
            },
            {
              entity: resolvedEntityName.toLowerCase(),
            }
          )}
        </span>

        {renderField({
          attribute: {
            type: 'wysiwyg',
            name: 'activityContent',
            heightMin: 515,
            heightMax: 650,
            editorType: 'activityContent',
            editorValue: formData?.body,
            imgUploadFolder: 'activity',
            imgTagsConfig: {
              context: 'activity-description-content',
              eventId: 'activityId',
              userId: sub,
            },
          },
          onSave: (value) => {
            changeFormProperty({ body: value })
          },
        })}
      </WysiwygWrapper>
      <BreakLine/>

      <AuthorSelector 
        entity={entity}
        onSave={(newCreatorId) => changeFormProperty({ newCreatorId })}
      />

      <Url
        name="Homepage"
        value={formData?.homepage}
        onSave={(value) => {
          changeFormProperty({ homepage: value })
        }}
      />

      <BannerImage
        uploadConfig={uploadConfig}
        bannerImg={formData.coverPhoto}
        name={intl.formatMessage({
          id: 'contentstudio.entity.logo.title',
          defaultMessage: "Logo"
        })}
        description={intl.formatMessage(
          {
            id: 'contentstudio.entity.logo.description',
            defaultMessage: `The ${appLabel(
              'companies',
              'companies.title'
            ).toLowerCase()} logo.`,
          },
          {
            entity: appLabel('companies', 'companies.title').toLowerCase(),
          }
        )}
        onSave={(value) => changeFormProperty({ coverPhoto: value })}
      />

      <BannerImage
        uploadConfig={uploadConfig}
        bannerImg={formData.bannerImage}
        onSave={(value) => changeFormProperty({ bannerImage: value })}
      />

      <Location
        location={formData.location}
        onSave={(value) => changeFormProperty({ location: value })}
        desc={intl.formatMessage(
          {
            id: 'contentstudio.entity.location.info',
            defaultMessage: 'What is the location?',
          },
          {
            entity: appLabel('companies', 'companies.title').toLowerCase(),
          }
        )}
      />
    </>
  )
}

export default GeneralInformation