import React from 'react'
import Button from './export-button'
import Modal from './export-modal'

import { useState } from 'react'

const Index = ({context}) => {
  const [modal, setModal] = useState()

  return (
    <>
      <Button onClick={() => setModal(true)} />
      {modal && <Modal context={context} onClose={() => setModal(false)} />}
    </>
  )
}

export default Index
