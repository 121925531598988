import styled from '@emotion/styled'

import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'

export const AvatarsStyled = styled.div`
  display: table;
  position: relative;
  margin-top: ${({ isMultiple }) => isMultiple && '50px'};
  .count {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: -15px;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    padding: 3px;
    color: var(--background);
    background: var(--foreground);
    min-width: 32px;
    min-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Multiple = styled.div`
  img {
    @media (max-device-width: 320px) {
      width: 75px;
      height: 75px;
    }
    @media (min-device-width: 321px) and (max-device-width: 420px) {
      width: 85px;
      height: 85px;
    }
  }
`

export const Single = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--foreground);
  span {
    padding: 0 25px;
    color: var(--foreground);
    font-size: 22px;
    font-weight: 600;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 auto;
  }
  img {
    height: 164px;
    width: 164px;
    border-radius: 100%;
    border: none;
    object-fit: cover;
    object-position: center;
  }
`

export const Status = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  color: var(--foreground);
  padding: 0 25px;
  padding-bottom: 20px;
`

export const Name = styled.div`
  word-break: break-word;
  overflow: hidden;

  padding: 0 25px;
  color: var(--foreground);
  font-size: 22px;
  font-weight: 600;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
`

export const OnlineIndicator = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ onlineStatus }) =>
    onlineStatus === 'ONLINE' ? '#4dcea6' : '#cecece'};
  border-radius: 100%;
  border: 4px solid var(--background);
  position: absolute;
  right: 25px;
  bottom: 0px;
`

export const Avatar = styled.div`
  ${border()}
  ${imageSkeleton}
  margin: 25px 70px 20px;
  height: 164px;
  width: 164px;
  position: relative;
  border-radius: 100%;
`

export const MultiImage = styled.img`
  position: relative;
  z-index: ${({ index }) => `${3 - index}`};
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 8px 0px;
  display: inline-block;
  margin-left: -30px;
  border-radius: 100%;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  height: 99px;
  object-fit: cover;
  width: 99px;
  &:first-child {
    margin-left: 0;
  }
`
