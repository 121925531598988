/* eslint-disable import/prefer-default-export */

import * as types from './types'

export const setActivePage = (payload) => {
  return {
    type: types.SET_ACTIVE_PAGE,
    page: payload,
  }
}
