import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import border from '_utils/css-border'
import { pablet } from '_utils/responsive-queries'

export const Avatar = styled.div`
  ${border()}
  border-radius: 50%;
  margin-right: 15px;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`
export const ListBlock = styled.div`
  display: flex;
  margin-bottom: 25px;
  .bullet {
    margin: 0 6px;
    font-size: 5px;
    transform: translateY(-50%);
  }
`

export const ContentWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 100px);
  .header {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    max-width: 100%;
    row-gap: 5px;
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
    }
  }
  .content {
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    max-width: 100%;
    max-height: 45px;
    img {
      display: none;
    }
  }
  p {
    margin: 0;
  }
  span {
    align-self: center;
  }

  ${pablet} {
    .bullet {
      display: none;
    }
    .header {
      flex-direction: column;
      gap: 5px;
      span {
        align-self: flex-start;
      }
    }
  }
`
