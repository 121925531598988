import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useUser } from '_security/session-hooks'
import { useAppLabel } from '_application'
import { useCustomAttributes } from '_custom-attributes/hooks'
import { connect, useSelector } from 'react-redux'

import { Loader } from '_components/loading'
import { Modal } from '_components/drilldown'
import { getDisplayName } from '_people/people-models'
import { useGetEntityAccess } from '_security/entity-access'
import LoginRequiredModal from '_security/login-required-modal'

import OpportunityDetails from '../components/opportunity-details'
import RecommendButton from '_components/buttons-connections/recommend-button'

import styled from '@emotion/styled'
import ErrorModal from '_components/modals/error-modal'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

const Button = styled.a`
  width: 100%;
  margin: 0 10px 0 0;
  display: block;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  font-size: 15px;
  background: ${({ theme }) => theme.colors.secondaryColor};
  color: ${({ theme }) => theme.colors.secondaryColor} !important;
  i {
    font-size: 12px;
    margin-right: 5px;
  }
`

const GET_OPPORTUNITY_PUBLIC = gql`
  query getOpportunityPublic($id: ID!) {
    getOpportunityPublic(id: $id) {
      id
      name
      coverPhoto
      description
      body
      location
      start
      end
      expire
      isDraft
      connectedCompanies {
        id
        name
        coverPhoto
        bannerImage
        description
        location
      }
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        fullName
        lastName
        avatar
        location
      }
      creatorId
      customAttributes {
        key
        value
      }
    }
  }
`

export const GET_OPPORTUNITY = gql`
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      name
      coverPhoto
      isOwner
      isManager
      description
      body
      location
      start
      end
      expire
      isDraft
      myApplication {
        _id
      }
      applicationManagers {
        firstName
        lastName
        middleName
        userId
        state
        avatar
      }
      receiveApplicationBy{
        type
        value
      }
      private
      audience {
        label
      }
      competencies {
        label
      }
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        lastName
        avatar
        email
        location
      }
      customAttributes {
        key
        value
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomy {
        key
        values {
          type
          value
        }
      }
      connectedActivity {
        id
        name
        coverPhoto
        description
        location
        start
        end
      }
      connectedCompanies {
        id
        name
        coverPhoto
        bannerImage
        description
        location
      }
      createdByMe
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        lastName
        avatar
      }
      editor {
        id
        sub 
        firstName
        lastName
        avatar
      }
      _updatedAt
    }
  }
`

const CONNECT_MUTATION = gql`
  mutation ConnectOpportunity($id: String!, $creatorId: String!) {
    connectToOpportunity(id: $id, creatorId: $creatorId) {
      status
    }
  }
`

const Props = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const mapStateToProps = ({ appConfig }) => ({ appConfig })

const OpportunityDetailsConnected = ({ id, onClose, appConfig }) => {
  const user = useUser()
  const intl = useIntl()
  const isLoggedIn = user?.profile?.role
  const entityAccess = useGetEntityAccess(
    'OPPORTUNITIES',
    'detailsRequiresAuth'
  )

  const opportunityDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  const query = isLoggedIn ? GET_OPPORTUNITY : GET_OPPORTUNITY_PUBLIC

  const { data, error, loading } = useQuery(query, {
    variables: { id },
  })

  const opportunity = isLoggedIn
    ? data?.getOpportunity
    : data?.getOpportunityPublic

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION, {
    variables: { id, creatorId: opportunity?.creator?.sub },
    refetchQueries: [{ query: query, variables: { id } }],
    awaitRefetchQueries: true,
  })

  const email = appConfig?.email?.value

  const { attributes } = useCustomAttributes('opportunities')
  const currentUser = useSelector((state) => state.user)

  const isAdmin = currentUser?.profile?.role === 'admin'
  const isOwner = currentUser?.sub === opportunity?.creator?.sub
  const isOpportunityNotFound =
    !loading &&
    (opportunity == null || (!isOwner && !isAdmin && opportunity?.isDraft))

  if (opportunityDetailsRequiresAuth && !isLoggedIn) {
    return <LoginRequiredModal onClose={onClose} />
  }

  return (
    <>
      {isOpportunityNotFound ? (
        <ErrorModal
          show={true}
          title={intl.formatMessage({
            id: 'modal.error.contentnotfound.title',
            defaultMessage: 'Ups! This information is private',
          })}
          description={intl.formatMessage({
            id: 'modal.error.contentnotfound.description',
            defaultMessage:
              "We're sorry, this page might have been removed or can only be accessed by platform administrators.",
          })}
          handleClose={onClose}
          button={
            <Link to="/">
              <button className="proceed-button">
                {intl.formatMessage({
                  id: 'modal.error.contentnotfound.button',
                  defaultMessage: 'homepage',
                })}
              </button>
            </Link>
          }
        ></ErrorModal>
      ) : (
        <Modal
          onClose={onClose}
          header={
            opportunity && (
              <Header
                opportunity={opportunity}
                onConnect={connect}
                connecting={connecting}
              />
            )
          }
        >
          <Loader loading={loading} error={error}>
            {() => (
              <OpportunityDetails
                onClose={onClose}
                opportunity={opportunity}
                attributes={attributes}
                email={email}
                recommendButton={
                  <RecommendButton
                    contentToRecomment={opportunity.id}
                    queryKey={'opportunityToRecommend'}
                    isPrivate={opportunity?.private}
                  />
                }
              ></OpportunityDetails>
            )}
          </Loader>
        </Modal>
      )}
    </>
  )
}
OpportunityDetailsConnected.propTypes = Props

const Header = ({ opportunity, onConnect, connecting }) => {
  const appLabel = useAppLabel()
  return ( 
    <Modal.Header
      cover={opportunity.coverPhoto}
      avatar={opportunity.creator?.avatar}
      title={getDisplayName(opportunity.creator)}
      subject={opportunity.name}
      subtitle={opportunity.creator?.bioShort}
      tag={appLabel('opportunities', 'opportunities.title')}
    >
      <Button href={`mailto:welcome@toolspawn.com?subject=${encodeURIComponent(opportunity.name)}`}>
        <i className="fas fa-pen" /> Apply now
      </Button>
    </Modal.Header>
  )
}

export default connect(mapStateToProps)(OpportunityDetailsConnected)
