import React from 'react'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { useIntl } from 'react-intl'

const intlKey = 'application.management.'

export const StyledButton = styled.button`
  display: flex;
  outline: none;
  border: 2px solid var(--flavor);
  margin-right: 10px;
  column-gap: 5px;
  padding: 5px 15px;
  border-radius: 25px;
  background-color: transparent;

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--flavor);
      color: var(--flavor-contrast);
    `}
`

const ApplicantsListSelector = ({ selectedListState, lists }) => {
  const [selected, setSelected] = selectedListState
  const intl = useIntl()

  return lists.map((list) => (
    <StyledButton
      onClick={() => setSelected(list.value)}
      key={list.value}
      selected={selected === list.value}
      data-testid={`btn-applications-list-${list.value}`}
    >
      <span>
        {' '}
        {intl.formatMessage({
          id: `${intlKey}category.${list.value?.split(' ')?.join('')}`,
        })}
      </span>
      <span>{list.count}</span>
    </StyledButton>
  ))
}

export default ApplicantsListSelector
