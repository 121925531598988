import styled from '@emotion/styled'
import css from '@emotion/css'

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 620px;
  margin-top: 10px;

  .image-wrapper {
    position: relative;
    width: 286px;
    height: 180px;
    margin: 12px;

    ${({ isOnlyOne }) =>
      isOnlyOne &&
      css`
        width: 600px;
        height: 335px;
      `}

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.16));
    }

    button {
      position: absolute;
      right: -3%;
      top: -3%;
      width: 23px;
      border-radius: 50%;
      height: 23px;
      background: var(--foreground);
      border: none;
      z-index: 2;

      ${({ isOnlyOne }) =>
        isOnlyOne &&
        css`
          right: -2%;
          top: -2%;
        `}

      i {
        font-size: 12px;
        color: var(--background);
      }
    }
  }
`
