/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from '@emotion/styled'
import moment from 'moment'
import companyIcon from 'resources/companies-icon-tool.svg'
import linkIcon from 'resources/link-icon.svg'
import activityIcon from 'resources/activities-icon-tool.svg'
import recommendIcon from 'resources/recommend-icon.svg'

import DashboardNotificationTypesList from '../dashboard-notification-types-list'
import DashboardActiveSelectionWindow from '../dashboard-notifications-active-window'

const NotificationsContentSection = styled.section`
  position: relative;
  margin-top: 0px;
  background-color: var(--background);
  height: 100vh;
`

const InnerNotificationsContent = styled.div`
  padding: 0px;
`

const FullReviewRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  padding: 0px;
`

const NotificationsTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: var(--foreground);
  font-size: 32px;
  font-weight: bold;
`

const NotificationsCount = styled.span`
  position: relative;
  font-size: 16px;
  background-color: #3fad8b;
  color: var(--background);
  padding-left: 12.5px;
  padding-right: 12.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  border-radius: 20px;
  top: -17.5px;
`

const LeftContentCol = styled(Col)`
  max-width: 500px;
  background-color: #e8eaeb;
  padding: 0px;
  padding: 50px;
  height: 100vh;
  overflow-y: scroll;
  border-right: 1px solid #c2c8ca;
`

const SelectionWindowCol = styled(Col)`
  padding: 0px;
`

const Index = () => {
  const [activeView, setActiveView] = useState('')

  const types = [
    {
      id: 'M7FIQqeYDE',
      name: 'Connection Requests',
      icon: linkIcon,
      latest: moment(Date.now()).unix(),
    },
    {
      id: '2icOLlAoLq',
      name: 'Recommendations',
      icon: recommendIcon,
      latest: moment(Date.now()).unix(),
    },
    {
      id: 'OqudUoAkL4',
      name: 'Company connection request',
      icon: companyIcon,
      latest: moment(Date.now()).unix(),
    },
    {
      id: 'WDjhdZyuN7',
      name: 'Activity connection request',
      icon: activityIcon,
      latest: moment(Date.now()).unix(),
    },
  ]

  return (
    <NotificationsContentSection>
      <InnerNotificationsContent>
        <FullReviewRow>
          <LeftContentCol>
            <NotificationsTitle>
              Notifications <NotificationsCount>9+</NotificationsCount>
            </NotificationsTitle>
            <DashboardNotificationTypesList
              types={types}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          </LeftContentCol>
          <SelectionWindowCol>
            <DashboardActiveSelectionWindow activeView={activeView} />
          </SelectionWindowCol>
        </FullReviewRow>
      </InnerNotificationsContent>
    </NotificationsContentSection>
  )
}

export default Index
