import React from 'react'
import NotificationWindowType from '../dashboard-notifications-window-type'

const Index = ({ activeView }) => {
  const listOne = [
    {
      id: 0,
      sender: 'Stein Myrseth',
      thumbnail: null,
      type: 'connection_request',
      response: null,
    },
    {
      id: 0,
      sender: 'Tom Jones',
      thumbnail: null,
      type: 'connection_request',
      response: true,
    },
  ]

  const listTwo = [
    {
      id: 0,
      sender: 'Stein Myrseth',
      thumbnail: null,
      type: 'recommendation',
      response: null,
    },
    {
      id: 0,
      sender: 'Tom Jones',
      thumbnail: null,
      type: 'recommendation',
      response: true,
    },
  ]

  const renderNotifications = () => {
    if (activeView) {
      switch (activeView) {
        case 'M7FIQqeYDE':
          return listOne
        case '2icOLlAoLq':
          return listTwo
        case 'OqudUoAkL4':
          return listOne
        case 'WDjhdZyuN7':
          return listOne
        default:
          break
      }
    } else {
      return null
    }
  }

  return (
    <div>
      <NotificationWindowType notifications={renderNotifications()} />
    </div>
  )
}

export default Index
