import { gql } from '@apollo/client'

export const GET_COMPANY = gql`
query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    coverPhoto
    bannerImage
    description
    body
    location
    connectionStatus
    homepage
    founded
    connectedUsersCount
    connectedActivitiesCount
    connectedOpportunitiesCount
    createdByMe
    publishedAt
    private
    isDraft
    creator {
      id
      sub
      bioShort
      firstName
      middleName
      lastName
      avatar
    }
    connectedActivities{
      id
      name
      coverPhoto
      start
      end
      description
      location
    }
    connectedOpportunities {
      id
      name
      coverPhoto
      start
      end
      description
      location
    }
    customAttributes {
      key
      value
    }
    keywords {
      key
      values {
        type
        value
      }
    }
    taxonomy {
      key
      values {
        type
        value
      }
    }
  }
}
`

export const SAVE_COMPANY = gql`
  mutation saveCompany($input: CompanyInput) {
    saveCompany(input: $input) {
      id
    }
  }
`
