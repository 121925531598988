import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'

import { StylesBody, StylesFooter } from './styles/edit'

const Index = ({
  canShowModals,
  setCanShowModals,
  currentNode,
  onChangeCallback,
}) => {
  const intl = useIntl()
  const [input, setInput] = useState(currentNode ? currentNode.node.title : '')

  const handleClose = () => {
    setCanShowModals({ ...canShowModals, canShowEditModal: false })
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `admin.taxonomy.modal.editnode.${value.toLowerCase()}`,
    })
  }

  return (
    <Modal
      dialogClassName="taxonomy-edit-modal-container"
      show={canShowModals.canShowEditModal}
      onHide={handleClose}
    >
      <StylesBody>
        <Modal.Body className="taxonomy-edit-modal-body">
          <div className="taxonomy-edit-info-container">
            <h2 className="taxonomy-title">{renderMessageText('title')}</h2>
            <p className="taxonomy-description">
              {renderMessageText('description')}
            </p>
          </div>
          <div className="taxonomy-edit-field-container">
            <div className="taxonomy-edit-container">
              <label className="input-label" htmlFor="apiKeyInput">
                {renderMessageText('subTitle')}
              </label>
              <input
                type="email"
                className="input-field"
                id="apiKeyInput"
                aria-describedby="apiKey"
                placeholder="Write a value"
                onChange={(e) => setInput(e.target.value)}
                value={input}
              />
            </div>
          </div>
        </Modal.Body>
      </StylesBody>
      <StylesFooter>
        <Modal.Footer>
          <div className="edit-taxonomy-modal-buttons-container">
            <button
              onClick={() =>
                setCanShowModals({ ...canShowModals, canShowEditModal: false })
              }
              className="cancel-button"
              type="button"
            >
              {renderMessageText('cancelButton')}
            </button>
            <button
              onClick={() =>
                onChangeCallback({
                  ...currentNode,
                  node: { ...currentNode.node, title: input },
                })
              }
              className="save-button"
              type="button"
            >
              {renderMessageText('saveButton')}
            </button>
          </div>
        </Modal.Footer>
      </StylesFooter>
    </Modal>
  )
}

export default Index
