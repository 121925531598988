/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMessageActions } from '_messages'
import { useIntl } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useMediaQuery } from 'react-responsive'
import { mobilePX, pabletPX } from '_utils/responsive-queries'
import styled from '@emotion/styled'

const ComposeContainer = styled.div`
  border-top: 1px solid grey;
  padding: var(--composeContainerPadding, 20px 40px 30px);
  width: 100%;

  padding: ${({ isMobileView, isTabletView }) =>
    (isMobileView || isTabletView) && '20px'};
`

const InnerComposeContainer = styled.div`
  position: relative;
  padding-bottom: 0px;
`

const ComposeRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const InputCol = styled(Col)`
  padding: 0;
`

const MessageInputContainer = styled.div`
  height: var(--inputHeight, auto);
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  position: relative;
  padding: 5px;
`

const MessageInput = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
  outline: none;
  resize: none;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  background-color: var(--background);
  padding-right: 50px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--foreground);
    opacity: 0.7;
  }
`

const SendButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  width: auto !important;
  i {
    color: ${({ isActive }) => (isActive ? 'var(--flavor)' : 'var(--shade)')};
  }
`

export const DashboardMessagesConversationCompose = ({ chatRoom }) => {
  const intl = useIntl()
  const { messages } = useSelector((state) => state)
  const [message, setMessage] = useState('')
  const messageActions = useMessageActions()

  const messageLength = message.split(' ').join('').length

  const handleSendMessage = () => {
    if (messageLength <= 0) return null
    messageActions.sendMessage(
      chatRoom || messages.currentConversation,
      message
    )
    setMessage('')
  }

  const isTabletView = useMediaQuery({ query: `(max-width: ${pabletPX}px)` })
  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })

  return (
    <ComposeContainer isTabletView={isTabletView} isMobileView={isMobileView}>
      <InnerComposeContainer>
        <ComposeRow>
          <InputCol>
            <MessageInputContainer>
              <MessageInput
                rows={3}
                placeholder={intl.formatMessage({
                  id: 'user.messages.typemessageinput.placeholder',
                })}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleSendMessage()
                  }
                }}
              ></MessageInput>
            </MessageInputContainer>
          </InputCol>
          <SendButton
            isActive={messageLength > 0}
            type="button"
            onClick={() => handleSendMessage()}
            disabled={messageLength <= 0}
          >
            <i class="fas fa-play" />
          </SendButton>
        </ComposeRow>
      </InnerComposeContainer>
    </ComposeContainer>
  )
}

export default DashboardMessagesConversationCompose
