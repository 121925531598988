import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import ItemList, {
  AddNewItem,
  DetailedInfo,
  NoContent,
} from '../shared/items-list'
import AddEditModal from './add-edit-modal'
import RemoveButton from './remove-button'

const mapStateToProps = ({ user }) => ({ user })

const Education = ({ user }) => {
  const [currentExperience, setCurrentExperience] = useState()
  const [addNewExperience, setAddNewExperience] = useState()
  const [deleteExperience, setDeleteExperience] = useState()
  const intl = useIntl()

  const { experience } = user?.profile

  const onClose = () => {
    setAddNewExperience(false)
    setCurrentExperience(false)
    setDeleteExperience(false)
  }

  const handleAddNewExperience = () => {
    setAddNewExperience(true)
  }

  return (
    <ItemList>
      {experience && experience.length > 0 ? (
        experience.map((exp) => {
          const isCurrentPosition = !exp.endDate || exp.endDate === 'null'
          const startDate = moment(exp.startDate).format('DD MMM YYYY')
          const endDate = isCurrentPosition
            ? intl.formatMessage({ id: 'user.profile.experience.date.current' })
            : moment(exp.endDate).format('DD MMM YYYY')
          return (
            <DetailedInfo>
              <Row>
                <Col sm={12} md={6}>
                  <span
                    className="text-bold"
                    data-testid={`experience-companyName-${exp.companyName}`}
                  >
                    {exp.companyName}
                  </span>
                  <span
                    className="text-standard"
                    data-testid={`experience-title-${exp.companyName}`}
                  >
                    {exp.title}
                  </span>
                  <span
                    className="text-url"
                    data-testid={`experience-websiteUrl-${exp.companyName}`}
                  >
                    {exp.websiteUrl}
                  </span>
                </Col>
                <Col sm={12} md={6}>
                  <span
                    className="text-bold"
                    data-testid={`experience-location-${exp.companyName}`}
                  >
                    {exp?.location}
                  </span>
                  <span
                    className="text-standard"
                    data-testid={`experience-date-${exp.companyName}`}
                  >
                    {`${startDate}-${endDate}`}
                  </span>
                </Col>
                <button
                  onClick={() => setDeleteExperience(exp)}
                  className="delete"
                  data-testid={`btn-delete-${exp.companyName}`}
                >
                  <i className="fas fa-trash" />
                </button>
                <button
                  onClick={() => setCurrentExperience(exp)}
                  className="edit"
                  data-testid={`btn-edit-${exp.companyName}`}
                >
                  <i className="fas fa-pen" />
                </button>
              </Row>
            </DetailedInfo>
          )
        })
      ) : (
        <NoContent>
          <i className="empty-icon fas fa-briefcase"></i>
          <span className="empty-label">
            {intl.formatMessage({
              id: 'user.profile.education.noexperience.message',
            })}
          </span>
        </NoContent>
      )}
      {deleteExperience && (
        <RemoveButton onClose={onClose} deleteExperience={deleteExperience} />
      )}
      {(currentExperience || addNewExperience) && (
        <AddEditModal onClose={onClose} currentExperience={currentExperience} />
      )}
      <AddNewItem onClick={() => handleAddNewExperience()} />
    </ItemList>
  )
}

export default connect(mapStateToProps)(Education)
