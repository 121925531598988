import React, { useState } from 'react'
import { useAppConfig, GET_CONFIG } from '_application'
import { UPDATE_BACKGROUND } from './graph-queries'
import { useMutation } from '@apollo/client'

import ImageUpload from 'components/indy/shared/image-upload'
import VideoUpload from 'components/indy/shared/video-upload'
import { SubSection } from '../../shared/styles/sections'
import { useIntl } from 'react-intl'
import { TypeSwitch } from '../../shared/styles/type-switch'

const renderTypeSwitch = ({ type, onClick, intl }) => {
  return (
    <TypeSwitch>
      <button
        onClick={() => onClick('image')}
        className={`${type === 'image' ? 'active' : ''}`}
      >
        {intl.formatMessage({
          id: 'admin.settings.general.backgrounds.type.image',
        })}
      </button>
      <button
        onClick={() => onClick('video')}
        className={`${type === 'video' ? 'active' : ''}`}
      >
        {intl.formatMessage({
          id: 'admin.settings.general.backgrounds.type.video',
        })}
      </button>
    </TypeSwitch>
  )
}

const BackgroundShared = ({ type, title, description }) => {
  const backgroundImages = useAppConfig()?.backgroundImages
  const background = backgroundImages ? backgroundImages[type] : null
  const [backgroundType, setBackgroundType] = useState(
    background?.type || 'image'
  )

  const intl = useIntl()

  const [updateLogo] = useMutation(UPDATE_BACKGROUND)

  const handleImageUpload = (imgUrl, imgType) => {
    updateLogo({
      variables: {
        type,
        backgroundImage: { value: imgUrl, type: imgType || 'image' },
      },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const checkValue = (value) => {
    return value && value !== 'undefined' ? value : null
  }

  return (
    <SubSection>
      <span className="title">
        {intl.formatMessage({
          id: `admin.settings.general.backgrounds.${type.toLowerCase()}`,
        })}
      </span>
      <span className="description">{checkValue(description)}</span>
      {renderTypeSwitch({
        type: backgroundType,
        onClick: (type) => setBackgroundType(type),
        intl,
      })}
      {backgroundType === 'image' ? (
        <ImageUpload
          className="background-img profile-dashboard-thumbnail"
          value={background?.type === 'video' ? null : background?.value}
          type={type}
          cdnType={type}
          onUpload={(imgUrl) => handleImageUpload(imgUrl)}
          onRevert={() => handleImageUpload('')}
          imgSize="l"
        />
      ) : (
        <VideoUpload
          className="background-img profile-dashboard-thumbnail"
          value={background?.type !== 'video' ? null : background?.value}
          type={type}
          cdnType={type}
          onUpload={(imgUrl) => handleImageUpload(imgUrl, 'video')}
          onRevert={() => handleImageUpload('')}
          imgSize="l"
        />
      )}
    </SubSection>
  )
}

export default BackgroundShared
