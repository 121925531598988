import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import BreakLine from './break-line'
import { ShortDescWrapper } from './styles/short-desc'

const ShortDescription = ({ value, onSave }) => {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(value || '')
  const [charLeftCounter, setCharLeftCounter] = useState(
    100 - value?.length || 100
  )
  const handleCharCounter = (e) => {
    const charLeft = 100 - e.target.value.length
    setCharLeftCounter(charLeft)
  }

  return (
    <>
      <ShortDescWrapper>
        <div className="label-wrapper">
          <label htmlFor="desc-content">
            {intl.formatMessage({
              id: 'contentstudio.entity.shortdescription',
              defaultMessage: 'Short description',
            })}
            <span className="required-star">*</span>
          </label>
          <p className="description-content-info">
            {intl.formatMessage({
              id: 'contentstudio.entity.shortdescription.info',
              defaultMessage:
                'This text ends up on the mini cards, summary pages, on Google, and when people share your post on social media. We recommend 100 characters.',
            })}
          </p>
        </div>
        <div className="description-content-wrapper">
          <textarea
            name="description-textarea"
            className="description-content"
            id="desc-content"
            placeholder={intl.formatMessage({
              id: 'events.createevent.general.shortdescription.placeholder',
            })}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
              handleCharCounter(e)
            }}
            maxLength="100"
            onBlur={() => onSave(inputValue)}
          />
          <p className="char-counter">
            {intl.formatMessage({
              id: 'events.createevent.general.shortdescription.limitlabel',
            })}
            : {charLeftCounter}
          </p>
        </div>
      </ShortDescWrapper>
      <BreakLine />
    </>
  )
}

export default ShortDescription
