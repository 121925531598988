import styled from '@emotion/styled'
import { FlexItem, MenuToggle, GridItem } from '@patternfly/react-core'

export const PageWrapper = styled.div`
  max-width: 1570px;
  margin: 0 auto;
`
export const SectionHeader = styled.div`
  width: 100%;
  margin: 30px 0;
`
export const TitleWrapper = styled(FlexItem)``

export const FilterWrapper = styled(FlexItem)`
  display: flex;
`
export const FilterButton = styled(MenuToggle)`
  font-size: 14px;
  color: var(--foreground);
  opacity: 0.6;
  &:before,
  &:after {
    display: none;
  }
`
export const SingleTag = styled(GridItem)`
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  i {
    color: var(--flavor);
    margin-right: 10px;
  }
  span {
    margin-left: 10px;
    opacity: 0.6;
  }
`
