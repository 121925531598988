import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const VerifyUser = ({ children }) => {
  const { user } = useSelector((state) => state)
  const location = useLocation()

  if (user && user.profile && user.authorized) {
    const dateRegistered = new Date(user.profile.dateRegistered._d)
    const now = new Date()
    const ageOfAccountInSeconds = (now.getTime() - dateRegistered.getTime()) / 1000
    if (
      user.profile.firstName?.length < 2 ||
      user.profile.lastName?.length < 2 ||
      (ageOfAccountInSeconds <= 5 && ageOfAccountInSeconds >= 0)
    ) {
      if (location.pathname !== '/user/onboarding') {
        return <Redirect to={'/user/onboarding?step=3'} />
      }
    }
  }

  return children
}
