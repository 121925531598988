import { useMutation } from '@apollo/client'
import {
  CONNECT_ACTIVITY_TO_COMPANY,
  DISCONNECT_ACTIVITY_TO_COMPANY,
  CONNECT_ACTIVITY_TO_OPPORTUNITY,
  DISCONNECT_ACTIVITY_TO_OPPORTUNITY
} from './gql/activity'

import {
  CONNECT_OPPORTUNITY_TO_ACTIVITY,
  DISCONNECT_OPPORTUNITY_TO_ACTIVITY,
  CONNECT_OPPORTUNITY_TO_COMPANY,
  DISCONNECT_OPPORTUNITY_TO_COMPANY
} from './gql/opportunity'

import {
  CONNECT_COMPANY_TO_ACTIVITY,
  DISCONNECT_COMPANY_TO_ACTIVITY,
  CONNECT_COMPANY_TO_OPPORTUNITY,
  DISCONNECT_COMPANY_TO_OPPORTUNITY
} from './gql/company'

import {
  CONNECT_EVENT_TO_PERSON,
  DISCONNECT_EVENT_TO_PERSON,
  CONNECT_EVENT_TO_ACTIVITY,
  DISCONNECT_EVENT_TO_ACTIVITY,
  CONNECT_EVENT_TO_COMPANY,
  DISCONNECT_EVENT_TO_COMPANY
} from './gql/events'

import { mapConnectVariables, mapDisconnectVariables } from './connection-variables'

const useConnectionBuilder = (type, id) => {
  const query = entityQueries[type]

  const [connect] = useMutation(query.connect)
  const [disconnect] = useMutation(query.disconnect)

  const getConnectVariables = (item) => mapConnectVariables(item, type, id)
  const getDisconnectVariables = (item) => mapDisconnectVariables(item, type, id)

  return { connect, disconnect, getConnectVariables, getDisconnectVariables }
}

const entityQueries = {
  a2c: { connect: CONNECT_ACTIVITY_TO_COMPANY, disconnect: DISCONNECT_ACTIVITY_TO_COMPANY },
  a2o: { connect: CONNECT_ACTIVITY_TO_OPPORTUNITY, disconnect: DISCONNECT_ACTIVITY_TO_OPPORTUNITY },
  o2a: { connect: CONNECT_OPPORTUNITY_TO_ACTIVITY, disconnect: DISCONNECT_OPPORTUNITY_TO_ACTIVITY },
  o2c: { connect: CONNECT_OPPORTUNITY_TO_COMPANY, disconnect: DISCONNECT_OPPORTUNITY_TO_COMPANY },
  c2a: { connect: CONNECT_COMPANY_TO_ACTIVITY, disconnect: DISCONNECT_COMPANY_TO_ACTIVITY },
  c2o: { connect: CONNECT_COMPANY_TO_OPPORTUNITY, disconnect: DISCONNECT_COMPANY_TO_OPPORTUNITY },
  e2p: { connect: CONNECT_EVENT_TO_PERSON, disconnect: DISCONNECT_EVENT_TO_PERSON },
  e2a: { connect: CONNECT_EVENT_TO_ACTIVITY, disconnect: DISCONNECT_EVENT_TO_ACTIVITY },
  e2c: { connect: CONNECT_EVENT_TO_COMPANY, disconnect: DISCONNECT_EVENT_TO_COMPANY }
}

export default useConnectionBuilder