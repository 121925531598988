import React from 'react'
import PropTypes from 'prop-types'
import SanityModal from 'shared/SanityModal'
import Tile from '_uikit/tile'
import * as moment from 'moment'
import { TileConnections } from '_uikit/tile-sections'
import { getDisplayName } from '_people/people-models'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'

import ConnectButton from '_components/buttons-connections/connect-button'
import LoginRequiredLink from '_security/login-required-link'
import { useUserAccess } from '_security/helpers'
import { conditionalTrimmer, trimmer } from '_application'

const Props = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    creator: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    coverPhoto: PropTypes.string,
    connectedUsers: PropTypes.array,
    connectedCompanies: PropTypes.array,
    connectedOpportunities: PropTypes.array,
  }).isRequired,
  className: PropTypes.string,
}

const Description = styled.p`
  height: 125px;
  font-family: ${({ theme }) => theme.fonts.googleFont} !important;
  color: var(--foreground) !important;
  margin: 0;
  font-size: 16px;
`
const Duration = styled.div`
  margin-bottom: 15px;
  position: relative;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 12px;
  font-weight: 600;
`

const Edit = styled.div`
  button {
    color: var(--foreground);
    border: 2px solid var(--flavor);
    background-color: transparent;
    font-weight: 600;
    height: 50px;
    width: 180px;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 25px;
    i {
      font-size: 14px;
      margin-right: 6px;
    }
    &:hover {
      background: transparent;
      opacity: 0.6;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  padding-top: 20px;
`

const Activity = ({
  activity,
  className,
  connectButton,
  isDetailsCard,
  recommendActivityToUserButton,
  a2cButton,
  isOwner,
  a2pButton,
  url,
  tileHeight,
  showConnections,
}) => {
  const maxLength = 23
  let title = activity.name
  const intl = useIntl()
  const user = useUser()
  const hasUserAccess = useUserAccess()

  if (title?.length >= maxLength) {
    title = trimmer(activity.name, maxLength, '...')
  }
  const renderDescription = (description) => {
    if (description) {
      conditionalTrimmer(description, 200, '..')
    } else {
      return intl.formatMessage({ id: 'description.notavailable' })
    }
  }
  const renderStartDisplay = (activity) => {
    return activity.start ? moment(activity.start).format('DD.MM.YYYY') : ''
  }
  const renderEndDisplay = (activity) => {
    if (activity.end) {
      let endTime = moment(activity.end).format('DD.MM.YYYY')
      let time = endTime
      if (activity.start) time = ' - ' + endTime
      return time
    }
  }

  const activityUrl = url

  const renderConnectButton = (owner) => {
    const isLogged = user?.profile?.role
    const defComponent = ({ children }) => <>{children}</>
    const Component = isLogged ? defComponent : LoginRequiredLink
    return owner ? (
      <Edit>
        <SanityModal
          buttonName="Edit"
          icon="fa-pen"
          url={`desk/activity;activity;${activity.id}`}
        />
      </Edit>
    ) : (
      <Component key={activity.id} to={activityUrl} preserveQuery>
        {connectButton && (
          <ConnectButton
            status={isLogged ? activity.connectionStatus : null}
            onConnect={
              isLogged && hasUserAccess ? connectButton?.onConnect : () => {}
            }
            disabled={connectButton?.disabled}
            type="activities"
          />
        )}
      </Component>
    )
  }

  let Link = ({ children }) => <>{children}</>
  if (url) {
    Link = ({ children }) => (
      <LoginRequiredLink to={activityUrl} preserveQuery>
        {children}
      </LoginRequiredLink>
    )
  }

  return (
    <Tile
      cover={activity.coverPhoto}
      avatar={activity.creator?.avatar}
      avatarName={getDisplayName(activity.creator)}
      title={title}
      start={activity.start}
      end={activity.end}
      location={activity.location?.name}
      createdAt={activity._createdAt}
      className={className}
      isDetailsCard={isDetailsCard}
      type={'activity'}
      url={activityUrl}
      tileHeight={tileHeight}
      connections={{
        companies: activity.connectedCompanies || [],
        users: activity.connectedUsers || [],
        opportunities: activity.connectedOpportunities || [],
      }}
    >
      <Link>
        {!isDetailsCard && (
          <>
            <Duration>
              {renderStartDisplay(activity)}
              {renderEndDisplay(activity)}
            </Duration>
            <Description>
              {renderDescription(activity?.description)}
            </Description>
          </>
        )}
      </Link>
      <ButtonContainer>
        {renderConnectButton(isOwner)}
        {recommendActivityToUserButton}
        {isOwner ? a2cButton : <></>}
        {isOwner ? a2pButton : <></>}
      </ButtonContainer>
      <Link>
        {showConnections && (
          <TileConnections
            connectedUsers={activity.connectedUsers}
            connectedCompanies={activity.connectedCompanies}
            connectedOpportunities={activity.connectedOpportunities}
            // connectedUsersCount={activity.connectedUsers?.length}
            // connectedCompaniesCount={activity.connectedCompanies?.length}
            type={'activity'}
          />
        )}
      </Link>
    </Tile>
  )
}
Activity.propTypes = Props
export default Activity
