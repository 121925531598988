import styled from '@emotion/styled'
export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: right;
  column-gap: 20px;
  margin-bottom: 30px;

  .grow {
    flex-grow: 1;
  }

  #listSelectors {
    display: flex;
    overflow: auto;
    column-gap: 6px;
  }

  #selectedItems {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin: 0 31px;
  }
`
export default StyledDiv
