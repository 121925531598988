import styled from '@emotion/styled'

export const ColorPickerStyled = styled.div`
  position: relative;
  .color-preview {
    border: 1px solid #eee;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
  }
  .color-picker {
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 50;
    button {
      width: 100%;
      border: none;
      padding: 4px;
      font-size: 12px;
      background: var(--flavor);
      color: var(--flavor-contrast);
    }
  }
`
