import styled from '@emotion/styled'

export const LoginTitleStyle = styled.div`
  &.login-title-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .login-title {
    text-align: center;
    color: var(--background);
    font: ${({ theme }) => theme.fonts.googleFont};
    font-weight: 600;

    span {
      display: block;
    }
  }
`
