import styled from '@emotion/styled'

export const EventDatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  .label-wrapper {

    .title {
      font-size: 15px;
      font-weight: 700;
      color: var(--foreground);
    }

    p {
      margin-bottom: 28px;
      font-size: 14px;
      font-weight: 400;
      color: var(--foreground);
    }
  }

  .start-date-wrapper {
    width: 500px;
  }

  .end-date-wrapper {
    width: 315px;
  }

  .section-title {
    margin: 0 0 12px 0;
    font-size: 15px;
    font-weight: 700;
    color: var(--foreground);
  }

  .pickers-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .picker-wrapper,
  .timepicker-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    label {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 400;
      color: var(--foreground);
    }

    .react-date-picker__inputGroup__input {
      color: var(--foreground) !important;
    }

    .react-calendar__tile--active {
      background-color: var(--flavor) !important;
    }

    .react-date-picker {
      height: 35px !important;
      box-shadow: 0px 3px 8px rgb(0 0 0 / 16%) !important;
    }
    .react-date-picker__wrapper {
      border: none !important;
      padding: 0 5px;
    }
  }

  .timepicker-wrapper {
    width: 140px;


    .pf-c-menu, .pf-m-scrollable {
      position: absolute;
      width: 150px;
    }
  }

  .validation-error {
    min-height: 64px;
    word-break: break-word;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .validation-error-bottom {
    min-height: 64px;
    word-break: break-word;
    padding-top: 15px;
    color: red;
  }

  .calendar-wrapper {
    background-color: var(--background);
    color: var(--foreground);
  }
  .react-date-picker__button__icon {
    stroke: var(--foreground);
    color: var(--foreground);
  }
`
