import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Loading } from '_components/loading'
import Error from '_components/error'
import cls from 'classnames'
import { usePexipCall } from '../meet-pexip'

const ActionButtons = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
`
const ActionButton = styled.button(
  ({ active }) => `
  color: ${active ? 'red' : 'white'};
  border: 1px solid ${active ? 'red' : 'white'};;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  z-index: 1;
  font-size: 30px;
  margin: 0 5px;
`
)
const LoadingStyled = styled(Loading)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`
const FullScreenButton = styled.i`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  opacity: 0.5;
  color: white;
  font-size: 32px;
  cursor: pointer;
`

const videoStyles = {
  main: css`
    width: 100%;
    height: 100%;
    z-index: 0;
  `,
  self: css`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25%;
    height: 25%;
    min-width: 150px;
    min-height: 100px;
    z-index: 1;

    @media (orientation: portrait) {
      min-width: 100px;
      min-height: 150px;
    }
  `,
  presentation: css`
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 25%;
    height: 25%;
    min-width: 150px;
    min-height: 100px;
    z-index: 1;

    @media (orientation: portrait) {
      min-width: 100px;
      min-height: 150px;
    }
  `,
}

const styles = {
  container: css`
    position: relative;
  `,
  containerFullScreen: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
}

const Props = {
  conferenceId: PropTypes.string.isRequired,
  onDisconnect: PropTypes.func,
}
export const MeetVideo = ({ conferenceId, onDisconnect }) => {
  const intl = useIntl()
  const [focus, setFocus] = useState('main')
  const [fullScreen, setFullScreen] = useState()
  const {
    loading,
    error,
    videoRef,
    selfRef,
    presentationRef,
    muted,
    setMuted,
    presenting,
    hasPresentation,
    setPresenting,
    disconnect,
  } = usePexipCall(conferenceId)

  useEffect(() => {
    if (hasPresentation && !presenting) {
      setFocus('presentation')
    } else {
      setFocus('main')
    }
  }, [hasPresentation, presenting])

  return (
    <div css={fullScreen ? styles.containerFullScreen : styles.container}>
      {loading ? <LoadingStyled /> : null}
      {error ? <Error>{error}</Error> : null}
      <video
        ref={videoRef}
        autoPlay="autoplay"
        css={focus === 'main' ? videoStyles.main : videoStyles[focus]}
        onClick={() => setFocus('main')}
      />
      <video
        ref={selfRef}
        autoPlay="autoplay"
        muted={true}
        css={focus === 'self' ? videoStyles.main : videoStyles.self}
        onClick={() => setFocus('self')}
      />
      {hasPresentation && (
        <video
          ref={presentationRef}
          autoPlay="autoplay"
          muted={true}
          css={
            focus === 'presentation'
              ? videoStyles.main
              : videoStyles.presentation
          }
          onClick={() => setFocus('presentation')}
        />
      )}
      <FullScreenButton
        onClick={() => setFullScreen(!fullScreen)}
        className={cls(
          'fas',
          fullScreen ? 'fa-compress' : 'fa-expand-arrows-alt'
        )}
      />

      {loading ? null : (
        <ActionButtons>
          <ActionButton
            className={cls(
              'fas',
              muted ? 'fa-microphone-slash' : 'fa-microphone'
            )}
            onClick={() => setMuted(!muted)}
            active={muted}
            data-tip={intl.formatMessage({
              id: muted ? 'meet.unmute' : 'meet.mute',
            })}
          />
          <ActionButton
            className="fas fa-desktop"
            onClick={() => setPresenting(!presenting)}
            active={presenting}
            data-tip={intl.formatMessage({ id: 'meet.sharescreen' })}
          />
          <ActionButton
            className="fas fa-times"
            onClick={() => {
              disconnect()
              onDisconnect && onDisconnect()
            }}
            data-tip={intl.formatMessage({ id: 'meet.disconnect' })}
          />
        </ActionButtons>
      )}
      <ReactTooltip effect="solid" />
    </div>
  )
}
MeetVideo.propTypes = Props

export default MeetVideo
