import styled from '@emotion/styled'

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
 align-items: flex-start;
 width: 80vw;
 max-width: 800px;

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .location-label{
      font-size: 14px;
      font-weight: 700;
  }
  .location-description{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .location-content-wrapper {
    width: 100%;
  }
`

export const LocationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .location-selector,
  .ui-kit-input {
    width: 410px;
    margin: 0;
  }
`



