import { find } from 'lodash'
import { Loader } from '_components/loading'
import { tablet, pablet } from '_utils/responsive-queries'
import React from 'react'
import { customBlocks } from '_pagebuilder/blocks/custom-blocks'
import { useContentBlocks } from '_pagebuilder/hooks'
import { css } from '@emotion/core'

export const pageBuilderScope = css`
  span.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
  }

  ${pablet} {
    margin-bottom: 50px;
  }
`

export const wrapper = css`
 .fdb-block:not(.stretch),
 .page-wrapper {
    ${tablet} {
      margin-left: 50px;
      margin-right: 50px;
    }

    ${pablet} {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .fdb-block img.fdb-icon,
  .fdb-block img.fdb-icon-round {
    width: auto;
    width: 3.75rem;
  }
`

export const PageBuilderRenderer = ({ contextId, draft }) => {
  const { data, loading, draftContentBlocks, refetch } = useContentBlocks(
    contextId,
    draft
  )
  const displayData = draft ? draftContentBlocks || data : data
  return (
    <Loader
      loading={loading}
      css={css`
        margin-top: 100px;
      `}
    >
      {() =>
        (displayData?.length && (
          <div className="page-builder-wrapper" css={wrapper}>
            {displayData?.map((block, i) =>
              block?.type === 'custom' ? (
                ResolveCustomBlock(block, refetch)
              ) : (
                <div
                  key={i}
                  css={pageBuilderScope}
                  className="page-builder-scope"
                  dangerouslySetInnerHTML={{ __html: block?.content }}
                />
              )
            )}
          </div>
        )) ||
        null
      }
    </Loader>
  )
}

function ResolveCustomBlock(block, refetch) {
  const Component = find(customBlocks, {
    id: block?.name || block?.id,
  })?.component

  return (
    Component && (
      <Component
        blockId={block?.name || block?.id}
        refId={block?.id}
        category={block?.category}
        refetchBlocks={refetch}
      />
    )
  )
}
