import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import Col from 'react-bootstrap/Col'

export const ResultGridStyled = styled.div`
  margin-top: 20px;
`

export const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 0px;
  margin-top: 100px;
`

export const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

export const SeparatorCol = styled(Col)`
  hr {
    border: 1px solid var(--shade);
  }
`

export const ButtonCol = styled(Col)`
  max-width: 300px;

  max-width: 300px;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    min-width: 190px;
  }
`

export const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  padding: 40px 20px;
`
