import { useAppConfig } from './app-config'

const useIsFeatureOn = (featureName) => {
  const { features } = useAppConfig() || {}

  const isFeatureOn = features?.includes(featureName)

  return isFeatureOn
}

export default useIsFeatureOn
