import React from 'react'

import AddNewItem from './add-new-item'
import NoContent from './no-content'
import DetailedInfo from './item-detailed-info'
import BasicInfo from './item-basic-info'

import Content from './styles'

export { AddNewItem, DetailedInfo, NoContent, BasicInfo }

const ItemsContainer = ({ children }) => {
  return <Content>{children}</Content>
}

export default ItemsContainer
