import styled from '@emotion/styled'

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;
  margin-bottom: 25px;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: var(--foreground);
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--foreground);
    margin-bottom: 10px;
  }
`

export const MultiSelectStyled = styled.div`
  display: inline-block;
  width: 410px;

  .full-selector {
    position: relative;
    width: 100%;
    border: solid 1px var(--foreground);
    border-radius: 6px;
    color: var(--foreground);
    button {
      background: transparent;
      padding: 10px;
      border: none;
      width: 100%;
      text-align: left !important;
      font-size: 14px;
      font-family: ${(props) => props.theme.fonts.googleFont} !important;
      color: var(--foreground) !important;
      i {
        margin-left: 8px;
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    ul {
      position: absolute;
      width: 100%;
      top: 45px;
      left: 0;
      opacity: 0;
      transition: opacity 0.24s;
      z-index: 10000 !important;
      background-color: var(--background);
      box-shadow: 0px 0px 10px #e5e5e5;
      padding: 15px;
      border-radius: 6px;
      max-height: 200px;
      overflow-y: auto;
      background: var(--background);
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        list-style: none;
        padding: 10px 16px;
        font-family: ${(props) => props.theme.fonts.googleFont} !important;
        color: var(--foreground);
        cursor: pointer;
        &:last-of-type {
          border: none;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &.active {
          color: var(--foreground);
          background: rgba(0, 0, 0, 0.05);
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
  }
  i.far {
    float: right;
  }
`

export const BadgesWrapper = styled.div`
  width: 80vw;
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .badge {
    margin: 0 25px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .badge-img {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      border: 3px solid black;
      object-fit: contain;
      margin-bottom: 10px;
      color: var(--foreground);
    }

    .badge-name {
      font-size: 12px;
      font-weight: 700;
      color: var(--foreground);
    }

    .delete-btn {
      position: absolute;
      right: -3%;
      top: -3%;
      width: 23px;
      border-radius: 50%;
      height: 23px;
      background: var(--foreground);
      color: var(--background);
      border: none;
      z-index: 2;
    }
  }
`

