import styled from '@emotion/styled'
import { GridItem } from '@patternfly/react-core'
import border from '_utils/css-border'
import { mobile, pablet, tablet } from '_utils/responsive-queries'

export const CategoryAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: flex-start;

  ${border()}
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-right: 16px;

  overflow: hidden;
  font-size: 23px;
  text-align: center;
  color: var(--background);

  img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
    object-position: center;
  }

  ${pablet} {
    width: 45px;
    height: 45px;
    img {
      width: 45px;
      height: 45px;
    }
  }
`

export const SecondLineInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 25px;
  font-size: 12px;
  .fa-tag {
    margin-right: 5px;
  }
  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0;
    gap: 5px;
    .bullet {
      display: none;
    }
  }
`

export const CountersWrapper = styled(GridItem)`
  display: flex;
  /* flex-grow: 1; */
  flex-basis: 25%;
  min-width: 25%;
  justify-content: space-around;
  align-items: flex-start;
  height: 75px;
  width: auto;
  padding: 0 5px;
  gap: 24px;

  ${pablet} {
    flex-grow: 1;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
    /* padding: 25px 0 10px; */
  }
  ${mobile} {
    padding: 0;
    margin-left: 60px;
    gap: 24px;
    justify-content: flex-start;
  }
`

export const Counter = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`

export const PageSection = styled.div`
  /* align-self: center; */
  /* .item-name {
    font-size: 16px;
    font-weight: 400;
  }

  ${tablet} {
    display: flex;
    ${Counter}, .item-name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
    }
  } */

  /* ${pablet} {
    display: flex;
    flex-direction: column;
    ${Counter}, .item-name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
    }
    span {
      align-self: center;
    }
  } */
`
