import styled from '@emotion/styled'

export const DashboardGridTemp = styled.div`
  max-width: 1470px;
  justify-content: center;
  margin: 50px auto 100px;
`

/*TODO legacy wrapper */
const DashboardGrid = styled.div`
  max-width: 1570px;
  display: grid;
  grid-gap: 70px;
  grid-template-columns: repeat(auto-fit, 306px);
  justify-content: center;
  margin: 50px auto 100px;
  @media (max-width: 667px) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
`

export default DashboardGrid
