import Route from './opportunities-routes'
import Tile from './components/opportunity-tile'
import Details from './connected/opportunity-details-connected'

export { Route, Details, Tile }
export default {
  Route,
  Details,
  Tile,
}
