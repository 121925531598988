import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

export const MyActivitiesStyled = styled.div`
  margin-bottom: 20px;
  width: 100%;
  h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    ${mobile} {
      font-size: 18px;
    }
  }
`
