import moment from 'moment'

export const roundTime = (date) => {
  const rounding = 30 * 60 * 1000
  const startDate = moment(date)
  const roundDate = moment(Math.ceil(+startDate / rounding) * rounding)
  const time = roundDate.format('HH:mm')

  return time
}
