import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import { pablet } from '_utils/responsive-queries'

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 50px 0 25px;
  .header-wrapper {
    flex-grow: 1;
  }
  h3 {
    font-size: 18px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
`
export const DiscoverAll = styled.button`
  ${ctaButtonHollow}
  width: 100%;
  ${pablet} {
    display: block;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  i {
    margin-right: 10px;
  }
`
