import React from 'react'

import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'

export const MessageRenderer = ({ type, notification }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()

  const renderMessage = (type) => {
    switch (type) {
      case 'application-applicant-messaging':
        return GetAmMsgText(notification)

      case 'opportunity-management-invitation':
        return intl.formatMessage({
          id: 'nav.notifications.opportunityManagementInvitation',
          defaultMessage: 'Application manager invitation from',
        })

      case 'a2p-request':
        return intl.formatMessage({
          id: 'nav.notifications.connectionrequest',
        })
      case 'p2p-request':
        return intl.formatMessage({
          id: 'nav.notifications.connectionrequest',
        })
      case 'p2a-request':
        return intl.formatMessage(
          { id: 'nav.notifications.activityrequest' },
          {
            category: appLabel('activitiesPlural', 'activities.title'),
          }
        )
      case 'o2a-request':
        return intl.formatMessage(
          { id: 'nav.notifications.activityrequest' },
          {
            category: appLabel('activitiesPlural', 'activities.title'),
          }
        )
      case 'c2a-request':
        return intl.formatMessage(
          { id: 'nav.notifications.activityrequest' },
          {
            category: appLabel('activitiesPlural', 'activities.title'),
          }
        )
      case 'p2c-request':
        return intl.formatMessage(
          { id: 'nav.notifications.companyrequest' },
          {
            category: appLabel('companiesPlural', 'companies.title'),
          }
        )
      case 'forum':
        return intl.formatMessage({ id: 'nav.notifications.forum' })
      case 'activity-ownership-change':
        return `${appLabel(
          'activitiesPlural',
          'activities.title'
        )} ${intl.formatMessage({
          id: 'ownership-change.notification.dropdown.description',
        })}`
      case 'opportunity-ownership-change':
        return `${appLabel(
          'opportunitiesPlural',
          'opportunities.title'
        )} ${intl.formatMessage({
          id: 'ownership-change.notification.dropdown.description',
        })}`
      case 'blog-ownership-change':
        return `${appLabel(
          'blogPlural',
          'blog.title'
        )} ${intl.formatMessage({
          id: 'ownership-change.notification.dropdown.description',
        })}`
      case 'company-ownership-change':
        return `${appLabel(
          'companiesPlural',
          'companies.title'
        )} ${intl.formatMessage({
          id: 'ownership-change.notification.dropdown.description',
        })}`
      case 'o2p-request':
        return intl.formatMessage(
          { id: 'nav.notifications.opportunities' },
          {
            category: appLabel('opportunitiesPlural', 'opportunities.title'),
          }
        )
      case 'p2o-request':
        return intl.formatMessage(
          { id: 'nav.notifications.companyrequest' },
          {
            category: appLabel('companiesPlural', 'companies.title'),
          }
        )
      case 'o2c-request':
        return intl.formatMessage(
          { id: 'nav.notifications.companyrequest' },
          {
            category: appLabel('companiesPlural', 'companies.title'),
          }
        )
      case 'a2c-request':
        return intl.formatMessage(
          { id: 'nav.notifications.companyrequest' },
          {
            category: appLabel('companiesPlural', 'companies.title'),
          }
        )
      case 'a2o-request':
        return intl.formatMessage(
          { id: 'nav.notifications.opportunityrequest' },
          {
            category: appLabel('opportunitiesPlural', 'opportunities.title'),
          }
        )
      case 'c2o-request':
        return intl.formatMessage(
          { id: 'nav.notifications.opportunityrequest' },
          {
            category: appLabel('opportunitiesPlural', 'opportunities.title'),
          }
        )
      case 'e2c-request':
        return intl.formatMessage(
          { id: 'nav.notifications.eventrequest' },
          {
            category: appLabel('events', 'events.title'),
          }
        )
      case 'e2a-request':
        return intl.formatMessage(
          { id: 'nav.notifications.eventrequest' },
          {
            category: appLabel('events', 'events.title'),
          }
        )
      case 'e2p-request':
        return intl.formatMessage(
          { id: 'nav.notifications.eventrequest' },
          {
            category: appLabel('events', 'events.title'),
          }
        )
      case 'p2p-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      case 'p2e-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      case 'p2a-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      case 'p2c-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      case 'p2o-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      case 'p2b-recommend':
        return intl.formatMessage({
          id: 'nav.notifications.recommendation',
        })
      default:
        return null
    }
  }

  return <>{renderMessage(type)}</>
}

const GetAmMsgText = ({ payload }) => {
  const intl = useIntl()

  const opportunity = payload[0]?.opportunity

  return intl.formatMessage(
    {
      id: 'notificationscenter.notification.message.newApplicationMessage',
      defaultMessage:
        'You have a new message regarding an application for {opportunityName}',
    },
    { opportunityName: opportunity?.name }
  )
}

