import React from 'react'
import DashboardNotificationsPage from 'components/dashboard/notifications/dashboard-notifications'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'

const Index = () => {
  return (
    <NavWrapper>
      <DashboardNotificationsPage />
    </NavWrapper>
  )
}

export default Index
