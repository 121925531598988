import React from 'react'
import { Button } from '_themes/default/components/styled/location-tag'

const LocationTag = ({ children, className }) => (
  <Button type="button" className={className}>
    <i className="fas fa-map-marker-alt" /> {children}
  </Button>
)

export default LocationTag
