export const SANITY_ORDERINGS = ['titleASC', 'titleDESC', 'dateASC', 'dateDESC']
export const ORDERINGS = ['nameASC', 'nameDESC', 'userDateASC', 'userDateDESC']

export const SORT_OPTIONS = (intl) => [
  {
    key: 'titleASC',
    label: intl.formatMessage({ id: 'sorting.sanity.ascending' }),
  },
  {
    key: 'titleDESC',
    label: intl.formatMessage({ id: 'sorting.sanity.descending' }),
  },
  {
    key: 'dateASC',
    label: intl.formatMessage({ id: 'sorting.sanity.newest' }),
  },
  {
    key: 'dateDESC',
    label: intl.formatMessage({ id: 'sorting.sanity.oldest' }),
  }
]

export const SORT_OPTIONS_PEOPLE = (intl) => [
  {
    key: 'nameASC',
    label: intl.formatMessage({ id: 'sorting.sanity.ascending' }),
  },
  {
    key: 'nameDESC',
    label: intl.formatMessage({ id: 'sorting.sanity.descending' }),
  },
  {
    key: 'userDateASC',
    label: intl.formatMessage({ id: 'sorting.sanity.newest' }),
  },
  {
    key: 'userDateDESC',
    label: intl.formatMessage({ id: 'sorting.sanity.oldest' }),
  }
]

const SANITY_SORTING = {
  titleASC: [
    {
      field: 'title',
      order: 'ASC',
    },
    {
      field: '_updatedAt',
      order: 'ASC',
    },
  ],
  titleDESC: [
    {
      field: 'title',
      order: 'DESC',
    },
    {
      field: '_updatedAt',
      order: 'DESC',
    },
  ],
  dateASC: [
    {
      field: '_updatedAt',
      order: 'ASC',
    },
  ],
  dateDESC: [
    {
      field: '_updatedAt',
      order: 'DESC',
    },
  ],
}

const SORTING = {
  nameASC: [
    {
      field: 'name',
      order: 'ASC',
    },
  ],
  nameDESC: [
    {
      field: 'name',
      order: 'DESC',
    },
  ],
  userDateASC: [
    {
      field: '_id',
      order: 'DESC',
    },
  ],
  userDateDESC: [
    {
      field: '_id',
      order: 'ASC',
    },
  ],
}

export const parseSort = (sort) => {
  switch (sort) {
    case 'nameASC':
      return SORTING.nameASC
    case 'nameDESC':
      return SORTING.nameDESC
    case 'userDateASC':
      return SORTING.userDateASC
    case 'userDateDESC':
      return SORTING.userDateDESC
    default:
      return SORTING.nameASC
  }
}

export const parseSanitySort = (sort) => {
  switch (sort) {
    case 'titleASC':
      return SANITY_SORTING.titleASC
    case 'titleDESC':
      return SANITY_SORTING.titleDESC
    case 'dateASC':
      return SANITY_SORTING.dateASC
    case 'dateDESC':
      return SANITY_SORTING.dateDESC
    default:
      return SANITY_SORTING.titleASC
  }
}

export default {
  parseSort,
  parseSanitySort,
  SORT_OPTIONS,
  ORDERINGS,
}
