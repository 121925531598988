import styled from '@emotion/styled'
import border from '_utils/css-border'

export const MainContainer = styled.div``

export const SingleResults = styled.div``
export const SingleResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`
export const SearchResults = styled.div`
  padding: 10px;
  width: 100%;
  height: auto;
  ${border()}
  border-radius: 0;
`

export const SearchInputWrapper = styled.div``
export const AddButton = styled.button`
  border: 1px solid red;
  background-color: transparent;
`
export const Name = styled.span``
export const Avatar = styled.span`
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: grey;
`
export const UsersList = styled.div`
  width: 100%;
`
export const SingleUserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  .name {
    margin-left: 15px;
  }
`
export const RemoveButton = styled.button`
  border: none;
  background-color: transparent;
`
