import React, { Component } from 'react'

const withExpand = (WrappedComponent) => {
  return class WithExpand extends Component {
    state = {
      expand: false,
    }

    toggleExpand = () => {
      this.setState((prevState) => ({
        expand: !prevState.expand,
      }))
    }

    render() {
      const { expand } = this.state

      return (
        <WrappedComponent
          expand={expand}
          toggleExpand={this.toggleExpand}
          {...this.props}
        />
      )
    }
  }
}

export default withExpand
