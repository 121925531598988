export const toMarkers = (companies) =>
  companies &&
  companies.reduce((acc, company) => {
    const { location } = company
    const lat = location?.lat
    const lng = location?.long

    if (lat && lng) {
      acc.push({
        lat,
        lng,
        title: company.name,
        data: company,
        dataId: company?.id,
      })
    }

    return acc
  }, [])

export default {
  toMarkers,
}
