import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import InputShared from '../../../settings/override-labels/generic/input-shared'
import LabelsSection from '../../shared/labels-section'

const EventsLabels = () => {
  const intl = useIntl()

  const renderMessageDescription = (value, defaultMessage = '') => {
    return intl.formatMessage({
      id: `admin.sitestructure.events.labels.${value.toLowerCase()}`,
      defaultMessage,
    })
  }

  return (
    <>
      <LabelsSection
        title={renderMessageDescription('events.title', 'Events')}
        description={renderMessageDescription('events.description')}
      >
        <Row>
          <Col sm={12} md={4}>
            <InputShared
              type="events"
              title={renderMessageDescription('single')}
            />
          </Col>
          <Col sm={12} md={4}>
            <InputShared
              type="eventsPlural"
              title={renderMessageDescription('plural')}
            />
          </Col>
          <Col sm={12} md={8}>
            <InputShared
              type="eventsDescription"
              title={renderMessageDescription('descriptioninput')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </LabelsSection>
      <LabelsSection
        title={renderMessageDescription('speakers.title', 'Speakers')}
        description={renderMessageDescription('speakers.description')}
      >
        <Col sm={12} md={8}>
          <InputShared
            type="speakers"
            title={renderMessageDescription('single')}
          />
        </Col>
        <Col sm={12} md={8}>
          <InputShared
            type="speakersPlural"
            title={renderMessageDescription('plural')}
          />
        </Col>
      </LabelsSection>
    </>
  )
}

export default EventsLabels
