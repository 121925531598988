import styled from '@emotion/styled'

export const SectionTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
`

export const SectionDescription = styled.p`
  padding: 10px 0 0;
`
