import styled from '@emotion/styled'
import BaseStyled from './default'

const LocationStyled = styled(BaseStyled)`
  .step-content-container {
    .location-selector {
      padding-top: 5px;
    }
  }
  .location-optional {
    .content {
      .title {
        font-weight: 600;
        margin-top: 10px;
        .geosuggest__input {
          font-size: 12px;
        }
      }
    }
  }
`

export default LocationStyled
