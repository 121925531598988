import React, { useState } from 'react'
import { connect } from 'react-redux'
import { renderField } from '../profile/shared/field-factory'

import Welcome from './steps/welcome'
import Location from './steps/location'
import PersonalDetails from './steps/personal-details'
import AboutMe from './steps/about-me'
import SocialMedia from './steps/social-media'
import ProfileImages from './steps/profile-images'
// import FinalStep from './steps/final-step'
import KeywordGeneric from './steps/shared/keyword-generic'
import TaxonomyGeneric from './steps/shared/taxonomy-generic'

const mapStateToProps = ({ user }) => ({ user })

const components = {
  welcome: Welcome,
  location: Location,
  personalDetails: PersonalDetails,
  profileImages: ProfileImages,
  aboutMe: AboutMe,
  socialMedia: SocialMedia,
  // finalStep: FinalStep,
}

const ComponentResolver = ({ step, setStep, stepList, user, dispatch }) => {
  const [errors, setErrors] = useState({})
  const attr = stepList[step.currentStep]

  const isAttributeObject = (attribute) => {
    return (
      attribute.type === 'location' ||
      attribute.type === 'badge' ||
      attribute.type === 'file'
    )
  }

  const saveValue = (attribute, value) => {
    const c = user.profile.customAttributes.filter(
      (ca) => ca.key !== attribute.key
    )
    const isObject = isAttributeObject(attribute)
    let stringObject = ''
    if (isObject) stringObject = JSON.stringify(value)
    else if (attribute.type === 'boolean') stringObject = value.toString()
    else stringObject = value
    c.push({ key: attribute.key, value: stringObject, type: attribute.type })
    const p = {
      ...user.profile,
      customAttributes: c,
    }
    saveProfile(p)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const handleError = (attr, message) => {
    setErrors({ ...errors, [attr.key]: message })
  }

  const renderStep = () => {
    let Component

    if (attr?.dataType === 'custom-keyword') {
      Component = KeywordGeneric
    } else if (attr?.dataType === 'custom-taxonomy') {
      Component = TaxonomyGeneric
    } else {
      Component = components[attr?.key]
    }
    return <Component step={step} stepList={stepList} setStep={setStep} />
  }

  return (
    <>
      {!attr?.dataType ||
      attr?.dataType === 'custom-keyword' ||
      attr?.dataType === 'custom-taxonomy'
        ? renderStep()
        : renderField({
            attribute: attr,
            user,
            isCustomAttr: true,
            onSave: (attr, v) => saveValue(attr, v),
            onError: (attr, message) => handleError(attr, message),
            error: errors[attr.key],
          })}
    </>
  )
}

export default connect(mapStateToProps)(ComponentResolver)
