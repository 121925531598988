export const DEFAULT_PAGE_SIZE = 20

export const mergePage = (query) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev
  const data = [...prev[query].data, ...fetchMoreResult[query].data]
  return {
    ...prev,
    [query]: {
      ...prev[query],
      ...fetchMoreResult[query],
      data,
    },
  }
}

export const mergeForumPage = (query) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev
  const usersList = [
    ...prev[query].usersList,
    ...fetchMoreResult[query].usersList,
  ]
  return {
    ...prev,
    [query]: {
      ...prev[query],
      ...fetchMoreResult[query],
      usersList,
    },
  }
}

export const mergePostsPage = (query) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev
  const topicsList = [
    ...prev[query].topicsList,
    ...fetchMoreResult[query].topicsList,
  ]
  return {
    ...prev,
    [query]: {
      ...prev[query],
      ...fetchMoreResult[query],
      topicsList,
    },
  }
}

export const mergeTopicPage = (query) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev
  const postsList = [
    ...prev[query].postsList,
    ...fetchMoreResult[query].postsList,
  ]
  return {
    ...prev,
    [query]: {
      ...prev[query],
      ...fetchMoreResult[query],
      postsList,
    },
  }
}

const FIRST_PAGE = {
  paging: {
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  },
}
export const firstPage = () => FIRST_PAGE
export const nextPage = (offset = 0, limit = DEFAULT_PAGE_SIZE) => ({
  paging: {
    offset,
    limit,
  },
})

export default {
  DEFAULT_PAGE_SIZE,
  firstPage,
  nextPage,
  mergePage,
}
