import React from 'react'
import Grid from './grid'
import BackButton from '../shared/backButton'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import UserProfileModal from '../shared/user-profile-modal'
import { GET_APPLICATION } from './gql'
import { GET_OPPORTUNITY } from '_opportunities/connected/opportunity-details-connected'
import { useIntl } from 'react-intl'

const intlKey = 'application.management.'

function OpportunityApplicationDetail() {
  const intl = useIntl()

  const { applicationId } = useParams()

  const { data, loading } = useQuery(GET_APPLICATION, {
    variables: { applicationId },
  })

  const { data: opportunityData } = useQuery(GET_OPPORTUNITY, {
    variables: { id: data?.getApplication?.parentId },
    skip: !data?.getApplication?.parentId,
  })

  const opportunity = opportunityData?.getOpportunity

  const application = data?.getApplication

  if (!data || loading) {
    return <div>Loading...</div>
  }

  if (!opportunity?.isManager) return <></>

  return (
    <>
      <BackButton path={`/opportunities/${application?.parentId}/applications`}>
        {intl.formatMessage({ id: `${intlKey}backtoapplicants` })}
      </BackButton>
      {application && <Grid application={application} />}
      <UserProfileModal path={'detail/:applicationId/user/:userId'} />
    </>
  )
}

export default OpportunityApplicationDetail
