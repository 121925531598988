import React, { useState } from 'react'
import ColorPicker from 'components/indy/shared/color-picker'
import {
  ModalVariant,
  Button,
  Form,
  FormGroup,
  TextInput,
  Switch,
  Select,
  SelectOption,
  SelectVariant,
} from '@patternfly/react-core'
import {
  ModalStyled,
  BottomSectionWrapper,
  BottomSectionHeader,
  SinglePoperty,
  PropertyName,
} from './styles/add-modal'
import { useIntl } from 'react-intl'

const AddModal = ({
  isOpen,
  onClose,
  formProperties,
  setFormProperties,
  onSubmit,
  data,
}) => {
  const { cid, name, description, moderated, parentcid, icon, bgcolor, color } =
    formProperties
  const intl = useIntl()

  const [openSelectId, setOpenSelectId] = useState(null)

  const onSelect = (e, selections, propertyName) => {
    setFormProperties({ ...formProperties, [propertyName]: selections })
    setOpenSelectId(null)
  }

  const handleSelect = (id) => {
    if (!openSelectId) return setOpenSelectId(id)
    return id === openSelectId ? setOpenSelectId(null) : setOpenSelectId(id)
  }

  const modalTitle = `admin.sitestructure.forum.categories.modal.title.${
    cid ? 'update' : 'create'
  }`
  const modalSubmit = `admin.sitestructure.forum.categories.modal.button.${
    cid ? 'update' : 'create'
  }`
  const isDisabled = name.trim().length === 0 || description.trim().length === 0

  const subcategoryParent = data?.getCategories?.categoriesList?.find(
    (item) => item.cid === parentcid
  )

  return (
    <>
      <ModalStyled
        variant={ModalVariant.large}
        title={intl.formatMessage({
          id: modalTitle,
          defaultMessage: `${cid ? 'Update' : 'Create'} category`,
        })}
        isOpen={isOpen}
        onClose={() => onClose()}
        actions={[
          <Button
            key="create"
            variant="primary"
            type="submit"
            form="modal-with-form-form"
            isDisabled={isDisabled}
          >
            {intl.formatMessage({
              id: modalSubmit,
              defaultMessage: `${cid ? 'Update' : 'Create'}`,
            })}
          </Button>,
          <Button key="cancel" variant="link" onClick={() => onClose()}>
            {intl.formatMessage({
              id: 'admin.sitestructure.forum.categories.modal.button.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>,
        ]}
      >
        <Form id="modal-with-form-form" onSubmit={onSubmit}>
          <FormGroup
            label={`${intl.formatMessage({
              id: 'admin.sitestructure.forum.categories.modal.form.name',
              defaultMessage: 'Category name',
            })}`}
            isRequired
            fieldId="modal-with-form-form-name"
          >
            <TextInput
              isRequired
              type="text"
              id="modal-with-form-form-name"
              name="modal-with-form-form-name"
              value={name}
              onChange={(value) =>
                setFormProperties({ ...formProperties, name: value })
              }
            />
          </FormGroup>
          <FormGroup
            label={`${intl.formatMessage({
              id: 'admin.sitestructure.forum.categories.modal.form.description',
              defaultMessage: 'Category description',
            })}`}
            isRequired
            fieldId="modal-with-form-form-email"
          >
            <TextInput
              isRequired
              type="text"
              id="modal-with-form-form-email"
              name="modal-with-form-form-email"
              value={description}
              onChange={(value) =>
                setFormProperties({ ...formProperties, description: value })
              }
            />
          </FormGroup>
          {cid !== '' && (
            <FormGroup label="Moderate">
              <Switch
                id="simple-switch"
                label={`${intl.formatMessage({
                  id: 'admin.sitestructure.forum.categories.modal.form.toggleon',
                  defaultMessage: 'Only moderators can create topics',
                })}`}
                labelOff={`${intl.formatMessage({
                  id: 'admin.sitestructure.forum.categories.modal.form.toggleoff',
                  defaultMessage: 'Everyone can create topics',
                })}`}
                isChecked={moderated}
                onChange={(value) =>
                  setFormProperties({ ...formProperties, moderated: value })
                }
              />
            </FormGroup>
          )}
          <FormGroup>
            <SinglePoperty>
              <PropertyName>
                {intl.formatMessage({
                  id: 'admin.sitestructure.forum.categories.modal.form.parentcategory',
                  defaultMessage: 'Parent category (optional)',
                })}
              </PropertyName>
              <Select
                variant={SelectVariant.single}
                placeholderText={intl.formatMessage({
                  id: 'admin.sitestructure.forum.categories.modal.form.selectplaceholder',
                  defaultMessage: 'No parent category',
                })}
                aria-label="Select Input with validation"
                onToggle={() => handleSelect('parentcid')}
                onSelect={(e, selections) =>
                  onSelect(e, selections, 'parentcid')
                }
                selections={subcategoryParent?.name}
                isOpen={openSelectId === 'parentcid'}
                validated={'validated'}
                aria-describedby="validated-helper"
                aria-invalid={'validated' === 'error' ? true : false}
              >
                <SelectOption value="">
                  {intl.formatMessage({
                    id: 'admin.sitestructure.forum.categories.modal.form.selectoption',
                    defaultMessage: 'No parent category',
                  })}
                </SelectOption>
                {data?.getCategories.categoriesList.map((object) => {
                  return (
                    <>
                      <SelectOption key={object.cid} value={object.cid}>
                        {object.name}
                      </SelectOption>
                    </>
                  )
                })}
              </Select>
            </SinglePoperty>
          </FormGroup>
          <FormGroup>
            <BottomSectionWrapper>
              <BottomSectionHeader>
                {' '}
                {intl.formatMessage({
                  id: 'admin.sitestructure.forum.categories.modal.form.visuals',
                  defaultMessage: 'Visuals',
                })}
              </BottomSectionHeader>
              <SinglePoperty>
                <FormGroup
                  label={intl.formatMessage({
                    id: 'admin.sitestructure.forum.categories.modal.form.categoryicon',
                    defaultMessage: 'Category icon',
                  })}
                  fieldId="modal-with-form-form-icon"
                >
                  <TextInput
                    isRequired
                    type="text"
                    id="modal-with-form-form-icon"
                    name="modal-with-form-form-icon"
                    value={icon}
                    onChange={(value) =>
                      setFormProperties({ ...formProperties, icon: value })
                    }
                  />
                </FormGroup>
              </SinglePoperty>
              <SinglePoperty>
                <PropertyName>
                  {' '}
                  {intl.formatMessage({
                    id: 'admin.sitestructure.forum.categories.modal.form.backgroundcolor',
                    defaultMessage: 'Background color',
                  })}
                </PropertyName>
                <ColorPicker
                  color={bgcolor}
                  onChange={(color) =>
                    setFormProperties({ ...formProperties, bgcolor: color })
                  }
                />
              </SinglePoperty>
              <SinglePoperty>
                <PropertyName>
                  {intl.formatMessage({
                    id: 'admin.sitestructure.forum.categories.modal.form.textcolor',
                    defaultMessage: 'Text color',
                  })}
                </PropertyName>
                <ColorPicker
                  color={color}
                  onChange={(color) =>
                    setFormProperties({ ...formProperties, color: color })
                  }
                />
              </SinglePoperty>
            </BottomSectionWrapper>
          </FormGroup>
        </Form>
      </ModalStyled>
    </>
  )
}

export default AddModal
