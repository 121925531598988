import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_EVENTS_PUBLIC } from './graph'

import EventsGrid from '_events/components/events-grid/events-grid'
import CategoryTab from '../components/events-grid/category-tab-grid'
import { useQuery as useRouteQuery } from '_utils/router'
import { useAppConfig } from '_application'

import { firstPage, mergePage } from '_utils/graph-pagination'

import { Wrapper } from './styles'
import Loading from '_components/loading'
import Error from '_components/error'
import { getPageSizeForScreenSize } from '_tiles/helpers'
import { FetchMore } from '_components/fetch-more'

const updateQuery = mergePage('getAllEventsPublic')

const EventsGridConnected = ({ filter }) => {
  const appConfig = useAppConfig()
  const [category, setCategory] = useRouteQuery('category')
  const { eventsCategories } = appConfig

  const { data, error, loading, fetchMore, refetch } = useQuery(
    GET_EVENTS_PUBLIC,
    {
      variables: {
        ...firstPage(),
        paging: {
          offset: 0,
          limit: getPageSizeForScreenSize({ pageType: 'grid' }),
        },
        filter: {
          ...filter,
          byCategory: category === 'all' ? undefined : category,
        },
      },
    }
  )

  useEffect(() => {
    refetch()
      .then()
      .catch((err) => console.log('error', err))
  }, [refetch])

  if (!data || loading) return <Loading />
  if (error) return <Error />

  const { data: events, hasMore } = data?.getAllEventsPublic

  return (
    <Wrapper>
      <CategoryTab
        categories={eventsCategories}
        selected={category}
        onSelect={setCategory}
        discoverAllPath={'/events/list'}
      />
      <EventsGrid events={events} />
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={fetchMore}
          filter={filter}
          offset={events.length}
          updateQuery={updateQuery}
        />
      )}
    </Wrapper>
  )
}

export default EventsGridConnected
