import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  margin: 35px auto 10px;
`

const GoBackContent = ({ children }) => {
  return <Container>{children}</Container>
}

export default GoBackContent
