import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import border from '_utils/css-border'

export const Avatar = styled.div`
  ${border()}
  border-radius: 50%;
  flex-shrink: 0;
  width: 75px;
  height: 75px;
  margin-right: 15px;
  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const ListBlock = styled.div`
  display: flex;
  margin-bottom: 25px;
  p {
    margin: 0;
  }
`

export const ContentWrapper = styled(Link)`
  display: flex;
  align-items: center;
`
