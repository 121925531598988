import React from 'react'

import { Col } from 'react-bootstrap'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useAppConfig } from '_application'
import { useIntl } from 'react-intl'

import { TileSlider } from '_components/tile'
import { TilesSection } from '_components/drilldown'
import PersonTile from '_components/drilldown/connected-person-tile'
import CompanyTile from '_components/drilldown/connected-company-tile'
import ActivityTile from '_components/drilldown/connected-activity-tile'
import OpportunityTile from '_activities/components/tiles'

import { LeftArrow, RightArrow } from '_discover/components/discover-arrows'

const styles = {
  leftArrow: css`
    position: absolute;
    left: 440px;
    top: -65px;
    z-index: 1;
  `,
  rightArrow: css`
    position: absolute;
    left: 500px;
    top: -65px;
    z-index: 1;
  `,
}

const sliderConfig = {
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToShow: 2,
  slidesToScroll: 2,
  nextArrow: <RightArrow css={styles.rightArrow} />,
  prevArrow: <LeftArrow css={styles.leftArrow} />,
  responsive: [
    {
      breakpoint: 1701,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1401,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Layout = styled.div`
  background: var(--background);
  padding: 0px;
`

const getTile = ({
  type,
  entity,
  p2pButton,
  count,
  connections,
  showConnections = false,
}) => {
  if (type === 'people')
    return (
      <PersonTile
        key={entity.sub}
        p2pButton={p2pButton}
        person={entity}
        connections={connections}
        showConnections={showConnections}
        url={`/people/list/${entity.sub}`}
      />
    )
  if (type === 'company')
    return (
      <CompanyTile
        key={entity.sub}
        company={entity}
        showConnections={showConnections}
        url={`/companies/list/${entity.id}`}
      />
    )
  if (type === 'activity') {
    return (
      <ActivityTile
        url={`/activities/list/${entity.id}`}
        key={entity.sub}
        activity={entity}
      />
    )
  }
  if (type === 'opportunity')
    return (
      <OpportunityTile
        key={entity.sub}
        opportunity={entity}
        tileHeight="530px"
        showConnections={true}
        url={`/opportunities/list/${entity.id}`}
      />
    )
}

const Slider = styled(TileSlider)``

const Slide = styled.div`
  padding: 0px 0px 0px 0;
`

const typeToLabel = (type) => {
  switch (type) {
    case 'people':
      return 'peoplePlural'
    case 'company':
      return 'companiesPlural'
    case 'activity':
      return 'activitiesPlural'
    case 'opportunity':
      return 'opportunitiesPlural'
    default:
      return type
  }
}

const SingleCol = styled(Col)`
  margin-top: 25px;
`

const Section = ({
  type,
  entities,
  isDisplayLeft,
  p2pButton,
  count,
  showConnections,
}) => {
  const config = useAppConfig()
  const intl = useIntl()
  const labelOverride = config?.labelOverrides?.[typeToLabel(type)]?.value
  const title = `
    ${
      labelOverride
        ? intl.formatMessage({ id: `tile.details.connected.label` })
        : ''
    }
    ${
      labelOverride?.toLowerCase() ||
      intl.formatMessage({ id: `tile.details.connected.${type}` })
    }`

  if (entities && entities?.length > 0 && type) {
    return (
      <SingleCol lg={12}>
        <TilesSection title={title}>
          <Slider {...sliderConfig}>
            {entities.map((c) => {
              return (
                <Slide>
                  {getTile({
                    entity: c,
                    type,
                    p2pButton,
                    count,
                    connections: c.connections,
                    showConnections,
                  })}
                </Slide>
              )
            })}
          </Slider>
        </TilesSection>
      </SingleCol>
    )
  }
  return <></>
}

const Connections = ({ children }) => {
  return <Layout>{children}</Layout>
}

Connections.Section = Section

export default Connections
