import React from 'react'
import { connect } from 'react-redux'

import RemoveModal from '../../shared/modals/remove'

const mapStateToProps = ({ user }) => ({ user })

const Modal = ({ user, deleteItem, onClose, dispatch, type }) => {
  const handleDelete = (props) => {
    const updates = user.profile[type].filter((c) => !c.key.includes(props.key))
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: updates },
      },
    })
    onClose()
  }

  return (
    <RemoveModal
      title={type}
      defaultProps={deleteItem}
      user={user}
      onDelete={(props) => handleDelete(props)}
      onClose={() => onClose()}
    />
  )
}

export default connect(mapStateToProps)(Modal)
