import { useState, useEffect } from 'react'

const useScrollTop = (scrollTreshold = 75) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  const isScrollTop = scrollPosition < scrollTreshold

  return { isScrollTop, scrollPosition }
}

export default useScrollTop
