const applicationLists = {
  open: {
    value: 'open',
    icon: '',
    color: '#606060',
  },
  shortlisted: {
    value: 'shortlisted',
    color: '#006CC',
  },
  rejected: {
    value: 'rejected',
    icon: '',
    color: '#D80000',
  },
  hired: {
    value: 'hired',
    icon: '',
    color: '#3CC918',
  },
}

export default applicationLists
