import React from 'react'
import PropTypes from 'prop-types'
import Tile from '_uikit/tile'
import styled from '@emotion/styled'
import * as moment from 'moment'
import { getDisplayName } from '_people/people-models'
import { useIntl } from 'react-intl'

import LoginRequiredLink from '_security/login-required-link'
import { conditionalTrimmer } from '_application'

const Props = {
  opportunity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    creator: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    coverPhoto: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
}

const DurationContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
  text-align: left;
`
const DurationButton = styled.span`
  height: 21px;
  display: block;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 12px;
  font-weight: 600;
  margin-top: 2px;
`

const Description = styled.p`
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

const Opportunity = ({
  opportunity,
  className,
  date,
  url,
  isDetailsCard,
  tileHeight,
}) => {
  const intl = useIntl()
  const descriptionLimit = 130

  const renderDate = () => {
    return `${intl.formatMessage({ id: 'opportunity.tile.applyby' })} ${moment(
      opportunity.end
    ).format('DD.MM.YYYY')}`
  }

  const renderDescription = (description) => {
    if (description) {
      conditionalTrimmer(description, descriptionLimit, '..')
    } else {
      return 'No description available.'
    }
  }

  const opportunityUrl = url

  let Link = ({ children, className }) => (
    <div className={className}>{children}</div>
  )
  if (url) {
    Link = ({ children }) => (
      <LoginRequiredLink
        to={opportunityUrl}
        preserveQuery
        className={className}
      >
        {children}
      </LoginRequiredLink>
    )
  }

  return (
    <Tile
      cover={opportunity.coverPhoto}
      avatar={opportunity.creator?.avatar}
      avatarName={getDisplayName(opportunity.creator)}
      title={opportunity.name}
      start={opportunity.start}
      end={opportunity.end}
      location={opportunity.location?.name}
      className={className}
      isDetailsCard={isDetailsCard}
      type={'opportunity'}
      url={opportunityUrl}
      tileHeight={tileHeight}
      connections={{
        companies: opportunity.connectedCompanies || [],
        users: opportunity.connectedUsers || [],
        opportunities: opportunity.connectedOpportunities || [],
        activities: opportunity.connectedActivities || [],
      }}
    >
      <Link className={'url-link'}>
        {!isDetailsCard && (
          <>
            <DurationContainer>
              <DurationButton>
                {opportunity?.end && opportunity.end !== '' ? renderDate() : ''}
              </DurationButton>
            </DurationContainer>
            <Description>
              {renderDescription(opportunity?.description)}
            </Description>
          </>
        )}
      </Link>
    </Tile>
  )
}
Opportunity.propTypes = Props
export default Opportunity
