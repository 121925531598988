import React from 'react'
import Toggle from 'react-toggle'
import { InputWrapper } from '../styles'
import { ToggleButtonWithNameStyled } from '../styles/ca-boolean'
import 'react-toggle/style.css'
import BreakLine from './breakline'

const CustomAttributeBoolean = ({
  attribute,
  attributeValue,
  attributeConfig: { onSave },
}) => {
  return (
    <>
      <InputWrapper>
        <h4 className="title">{attribute.name}</h4>
        <p className="description">{attribute.description}</p>
        <ToggleButtonWithNameStyled>
          <Toggle
            icons={{ checked: null, unchecked: null }}
            checked={attributeValue?.value === 'true'}
            onChange={(e) => onSave(attribute.key, e.target.checked.toString())}
          />

          <span className="name">{attribute.name}</span>
        </ToggleButtonWithNameStyled>
      </InputWrapper>
      <BreakLine />
    </>
  )
}

export default CustomAttributeBoolean
