import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { mergeTopicPage } from '_utils/graph-pagination'
import { useIntl } from 'react-intl'
import { notification } from 'antd'
import { Link } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import { useUser } from '_security/session-hooks'
import { Loader } from '_components/loading'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, gql, useMutation } from '@apollo/client'
import moment from 'moment'
import SectionHeader from '_forum/components/section-header'
import DeleteModal from '_forum/components/delete-prompt'
import { Form, FormGroup, FlexItem } from '@patternfly/react-core'
import InfoModal from '_forum/shared/info-modal'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import Input from 'components/indy/shared/ui-kit/inputs/text'
import {
  ReplyWrapper,
  UserName,
  PageWrapper,
  PostInfo,
  AuthorAvatar,
  SingleTag,
  PostWrapper,
  PostDetails,
  RatesWrapper,
  PostContent,
  PostStatistics,
  ButtonsWrapper,
  Comment,
  CommentAvatar,
  ContentContainer,
  Description,
  ActionsWrapper,
  RateButton,
  ReplyButton,
  EditButton,
  GoBackButton,
  OnlineIndicator,
  CommentsWrapper,
  CommentButton,
  CancelReplyButton,
  CategoryLink,
  ReplyFormWrapper,
} from './styles/post'
import {
  CustomizedModal,
  CancelButton,
  AddButton,
  TagsInputWrapper,
  SearchResults,
  SingleResultWrapper,
  CustomizedInput,
  TagsMainWrapper,
  AllTagsWrapper,
} from './styles/category'
import ReactTooltip from 'react-tooltip'

import { LoadMoreContainer, LoadMoreButton } from '../shared/styles'
import { pabletPX } from '_utils/responsive-queries'
import { useMediaQuery } from 'react-responsive'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'
import FullscreenLoaderWrapper from '_components/loading/fullscreen-loader-wrapper'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'

const GET_TOPIC = gql`
  query GET_TOPIC($id: ID!, $page: Float) {
    getTopic(id: $id, page: $page) {
      currentpage
      pagecount
      topic {
        title
        votes
        viewcount
        postcount
        tagsList {
          value
          valueescaped
        }
        category {
          cid
          name
          icon
          bgcolor
          color
        }
      }
      postsList {
        pid
        topid
        deleted
        content
        votes
        timestampiso
        upvoted
        downvoted
        selfpost
        replies {
          hasMore
          count
        }
        user {
          uid
          username
          userslug
          status
          picture
        }
      }
    }
  }
`

const GET_TAGS = gql`
  query GET_TAGS($all: Boolean) {
    getTags(all: $all) {
      tags {
        tagsList {
          tag
          value
          score
        }
      }
    }
  }
`

const GET_CATEGORIES = gql`
  {
    getCategories {
      categoriesList {
        name
        description
        cid
        slug
        postCount
        topicCount
        icon
        color
        bgcolor
        postsList {
          content
          timestampiso
          tid
          user {
            username
          }
        }
      }
    }
  }
`

const GET_CATEGORY = gql`
  query GET_CATEGORY($id: ID!, $page: Float) {
    getCategory(id: $id, page: $page) {
      currentpage
      pagecount
      category {
        name
      }
      topicsList {
        title
        titleraw
        tid
        slug
        postcount
        viewcount
        votes
        deleted
        lastposttimeiso
        timestampiso
        user {
          picture
          username
        }
        teaser {
          tid
          content
          timestampiso
          user {
            username
            picture
          }
        }
      }
    }
  }
`

const ADD_POST = gql`
  mutation createPost($tid: ID!, $content: String!, $pid: ID) {
    createPost(tid: $tid, content: $content, pid: $pid) {
      status {
        status
        message
      }
      post {
        tid
        pid
        content
        votes
        timestampiso
      }
    }
  }
`

const EDIT_POST = gql`
  mutation editPost(
    $pid: ID!
    $content: String!
    $title: String
    $tagsList: String
  ) {
    editPost(pid: $pid, content: $content, title: $title, tagsList: $tagsList) {
      status {
        status
        message
      }
      post {
        tid
        pid
        content
        votes
        timestampiso
      }
    }
  }
`

const DELETE_POST = gql`
  mutation deletePost($pid: ID!) {
    deletePost(pid: $pid) {
      status {
        status
        message
      }
      response
    }
  }
`

const DELETE_TOPIC = gql`
  mutation deleteTopic($tid: ID!) {
    deleteTopic(tid: $tid) {
      status {
        status
        message
      }
      response
    }
  }
`

const VOTE_POST = gql`
  mutation voteOnPost($pid: ID!, $delta: Float) {
    voteOnPost(pid: $pid, delta: $delta) {
      status {
        status
        message
      }
      response
    }
  }
`

const UNVOTE_POST = gql`
  mutation unvoteOnPost($pid: ID!) {
    unvoteOnPost(pid: $pid) {
      status {
        status
        message
      }
      response
    }
  }
`

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const Topic = ({ loadingObject, setLoadingObject }) => {
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()
  const user = useUser()
  const userRole = user?.profile?.role
  const userId = user?.profile?.id
  const forumId = parseInt(user?.profile.forumId)

  const isMobileView = useMediaQuery({ query: `(max-width: ${pabletPX}px)` })

  const setCharacterLimit = (string) => {
    const limit = 35
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  const { id } = useParams()
  const { data, fetchMore } = useQuery(GET_TOPIC, {
    variables: { id: id, page: 1 },
  })

  const tagsConfig = {
    userId,
    categoryId: data?.getTopic.topic.category.cid,
    topicId: id,
  }
  const { data: tagsList } = useQuery(GET_TAGS, { variables: { all: true } })
  const [createPost] = useMutation(ADD_POST, {
    refetchQueries: [{ query: GET_TOPIC, variables: { id: id, page: 1 } }],
  })
  const [editPost] = useMutation(EDIT_POST, {
    refetchQueries: [{ query: GET_TOPIC, variables: { id: id, page: 1 } }],
  })
  const [deletePost] = useMutation(DELETE_POST, {
    refetchQueries: [
      { query: GET_TOPIC, variables: { id: id, page: 1 } },
      { query: GET_CATEGORIES },
      {
        query: GET_CATEGORY,
        variables: {
          id: data?.getTopic?.topic?.category?.cid,
        },
      },
    ],
  })

  const [deleteTopic] = useMutation(DELETE_TOPIC, {
    refetchQueries: [
      { query: GET_TAGS },
      { query: GET_CATEGORIES },
      {
        query: GET_CATEGORY,
        variables: {
          id: data?.getTopic?.topic?.category?.cid,
        },
      },
    ],
  })
  const [voteOnPost] = useMutation(VOTE_POST, {
    refetchQueries: [{ query: GET_TOPIC, variables: { id: id, page: 1 } }],
  })
  const [unvoteOnPost] = useMutation(UNVOTE_POST, {
    refetchQueries: [{ query: GET_TOPIC, variables: { id: id, page: 1 } }],
  })

  const updateQuery = mergeTopicPage('getTopic')

  const [contentValue, setContentValue] = useState('')
  const [editPid, setEditPid] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [topicTitleModal, setTopicTitleModal] = useState('')
  const [topicContentModal, setTopicContentModal] = useState('')
  const [query, setQuery] = useState('')
  const [isTagsOpen, setIsTagsOpen] = useState(false)
  const [tagsArray, setTagsArray] = useState([])
  const [tags, setTags] = useState([])
  const [tagsIncluded, setTagsIncluded] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setEditPid(0)
    setTopicTitleModal('')
    setTopicContentModal('')
    setTagsArray([])
    setIsModalOpen(false)
    setTagsIncluded(false)
    setQuery('')
    setIsTagsOpen(false)
  }

  const handleTopicChange = (html) => {
    setTopicTitleModal(html)
  }
  const handleContentBlur = (html) => {
    setTopicContentModal(html)
  }

  const handleTagsClose = (e) => {
    e.preventDefault()
    setQuery('')
    setIsTagsOpen(!isTagsOpen)
  }

  const removeTagFromList = (e, tag) => {
    e.preventDefault()
    const index = tagsArray.indexOf(tag)
    if (index > -1) {
      tagsArray.splice(index, 1)
    }
    setTagsArray([...tagsArray])
  }

  const onChange = (value, event) => {
    setQuery(value)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const addedTag = tagsArray.find((item) => item === query)
      if (query.length > 2 && !addedTag) {
        addTagToList(query)
        setQuery('')
      }
      console.log('tag should contain at least 3 letters')
    }
  }
  const addTagToList = (tag) => {
    setTagsArray([...tagsArray, tag])
    setQuery('')
  }

  const enableEditMode = (
    postId,
    postContent,
    postTitle,
    postTags,
    postTagsIncluded,
    isTopicModalOpen
  ) => {
    console.log('enableEditMode isTopicModalOpen::', isTopicModalOpen)
    postId && setEditPid(postId)
    postTitle && setTopicTitleModal(unescapePost(postTitle))
    postContent && setTopicContentModal(unescapePost(postContent))
    postTags && setTagsArray(postTags.map((a) => a.value))
    postTagsIncluded && setTagsIncluded(true)
    setIsModalOpen(!isModalOpen)
    setIsTopicModalOpen(!!isTopicModalOpen)
  }

  useEffect(() => {
    setTags(
      tagsList?.getTags.tags.tagsList.filter(
        (t) =>
          query?.length > 0 &&
          `${t?.value}`?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [tagsList, setTags, query])

  const handleBlur = (html) => {
    setContentValue(html)
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (contentValue) {
      setLoadingObject({ ...loadingObject, isLoading: true })
      const variables = { tid: id, content: contentValue, pid: '' }
      await createPost({ variables: variables })
        .then(() => {
          setLoadingObject({ ...loadingObject, isLoading: false })
          notification.success({
            message: intl.formatMessage({
              id: 'toast.topic.commentadd.success.message',
            }),
            description: intl.formatMessage({
              id: 'toast.topic.commentadd.success.description',
            }),
          })
          setContentValue('')
        })
        .catch((e) => {
          setLoadingObject({ ...loadingObject, isLoading: false })
          if (e?.message.includes('error:content-too-short')) {
            notification.error({
              message: intl.formatMessage({
                id: 'toast.topic.commentadd.error.message',
              }),
              description: intl.formatMessage({
                id: 'toast.topic.commentadd.error.description',
              }),
            })
          }
        })
    }
  }

  const handleEditSave = async (e) => {
    e.preventDefault()
    if (editPid) {
      await editPost({
        variables: {
          pid: editPid,
          content: topicContentModal,
          title: topicTitleModal,
          tagsList: tagsArray.toString(),
        },
      })
        .then(() => {
          setLoadingObject({ isLoading: false, hasBackground: false })
          setEditPid(0)
          setTopicTitleModal('')
          setTopicContentModal('')
          setTagsArray([])
          setIsModalOpen(false)
          setTagsIncluded(false)
          notification.success({
            message: intl.formatMessage({
              id: 'toast.topic.commentedited.success.message',
            }),
            description: intl.formatMessage({
              id: 'toast.topic.commentedited.success.description',
            }),
          })
        })
        .catch((e) => {
          setLoadingObject({ isLoading: false, hasBackground: false })
          const errorMessage = e?.message.includes('error:title-too-short')
            ? 'toast.topic.topicadd.error.description.title'
            : e?.message.includes('error:content-too-short')
            ? 'toast.topic.topicadd.error.description.message'
            : e?.message.includes('error:too-many-tags')
            ? 'toast.topic.topicadd.error.description.tags'
            : ''
          if (errorMessage) {
            notification.error({
              message: intl.formatMessage({
                id: 'toast.topic.commentedited.error.message',
              }),
              description: intl.formatMessage({
                id: errorMessage,
              }),
            })
          }
        })
    }
  }

  const handlePostDelete = (pid) => {
    if (pid) {
      setLoadingObject({ ...loadingObject, isLoading: true })
      deletePost({ variables: { pid: pid } })
        .then((res) => setLoadingObject({ ...loadingObject, isLoading: false }))
        .catch((err) =>
          setLoadingObject({ ...loadingObject, isLoading: false })
        )
    }
  }

  const handleVote = async (pid, delta) => {
    if (pid) {
      await voteOnPost({ variables: { pid: pid, delta: delta } })
    }
  }

  const handleUnVote = async (pid) => {
    if (pid) {
      await unvoteOnPost({ variables: { pid: pid } })
    }
  }

  const handleTopicDelete = () => {
    setIsDeleteModalOpen(false)
    history.push({
      pathname: '/forum/category/' + data?.getTopic.topic.category.cid,
      state: 'refetch',
    })
    setLoadingObject({ ...loadingObject, isLoading: true })
    deleteTopic({ variables: { tid: id } })
      .then((res) => setLoadingObject({ ...loadingObject, isLoading: false }))
      .catch((err) => setLoadingObject({ ...loadingObject, isLoading: false }))
  }

  const unescapePost = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const createTree = (list) => {
    if (list) {
      let map = {},
        node,
        roots = [],
        i

      for (i = 0; i < list.length; i += 1) {
        map[list[i].pid] = i
        list[i].children = []
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.topid && list[map[node.topid]]) {
          list[map[node.topid]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    }
  }

  const commentTree = createTree(
    _.cloneDeep(data?.getTopic?.postsList.slice(1))
  )

  const DisabledVotes = (votes) => {
    return (
      <>
        <RateButton
          style={{ color: '#cbcbcb' }}
          onClick={() =>
            votes.ownPost ? {} : setIsInfoModalOpen(!isInfoModalOpen)
          }
        >
          <i className="fas fa-arrow-circle-up" />
        </RateButton>
        <span className="count">{votes.votes}</span>
        <RateButton
          style={{ color: '#cbcbcb' }}
          onClick={() =>
            votes.ownPost ? {} : setIsInfoModalOpen(!isInfoModalOpen)
          }
        >
          <i className="fas fa-arrow-circle-down" />
        </RateButton>
      </>
    )
  }

  const Voting = (object) => {
    return (
      <>
        {userRole && object?.object?.selfpost !== true ? (
          <>
            {object?.object?.upvoted || object?.object?.downvoted ? (
              <Voted
                data={{
                  pid: object?.object?.pid,
                  upvoted: object?.object?.upvoted,
                  votes: object?.object?.votes,
                }}
              />
            ) : (
              <>
                <RateButton onClick={() => handleVote(object?.object?.pid, 1)}>
                  <i className="fas fa-arrow-circle-up" />
                </RateButton>
                <span className="count">{object?.object?.votes}</span>
                <RateButton onClick={() => handleVote(object?.object?.pid, -1)}>
                  <i className="fas fa-arrow-circle-down" />
                </RateButton>
              </>
            )}
          </>
        ) : (
          <DisabledVotes
            votes={object?.object?.votes}
            ownPost={object?.object?.selfpost}
          />
        )}
      </>
    )
  }

  const Voted = (data) => {
    return (
      <>
        {data.data.upvoted === true ? (
          <>
            <RateButton className="voted upvoted">
              <i
                className="fas fa-arrow-circle-up"
                onClick={() => handleUnVote(data.data.pid)}
              />
            </RateButton>
            <span className="count">{data.data.votes}</span>
            <RateButton onClick={() => handleVote(data?.data?.pid, -1)}>
              <i className="fas fa-arrow-circle-down" />
            </RateButton>
          </>
        ) : (
          <>
            <RateButton onClick={() => handleVote(data?.data?.pid, 1)}>
              <i className="fas fa-arrow-circle-up" />
            </RateButton>
            <span className="count">{data.data.votes}</span>
            <RateButton className="voted downvoted">
              <i
                className="fas fa-arrow-circle-down"
                onClick={() => handleUnVote(data.data.pid)}
              />
            </RateButton>
          </>
        )}
      </>
    )
  }

  const CommentObject = (data) => {
    const [reply, setReply] = useState({ pid: '', userslug: '' })
    const [replyContentValue, setReplyContentValue] = useState('')

    const handleReplyBlur = (html) => {
      setReplyContentValue(html)
    }

    const handleReplySave = async (e) => {
      e.preventDefault()

      if (replyContentValue) {
        const variables = {
          tid: id,
          content: replyContentValue,
          pid: reply.pid,
        }
        setLoadingObject({ ...loadingObject, isLoading: true })
        await createPost({ variables: variables })
          .then(() => {
            setLoadingObject({ ...loadingObject, isLoading: false })
            notification.success({
              message: intl.formatMessage({
                id: 'toast.topic.commentadd.success.message',
              }),
              description: intl.formatMessage({
                id: 'toast.topic.commentadd.success.description',
              }),
            })
            setReplyContentValue('')
            setReply({ pid: '', userslug: '' })
          })
          .catch((e) => {
            setLoadingObject({ ...loadingObject, isLoading: false })
            if (e?.message.includes('error:content-too-short')) {
              notification.error({
                message: intl.formatMessage({
                  id: 'toast.topic.commentadd.error.message',
                }),
                description: intl.formatMessage({
                  id: 'toast.topic.commentadd.error.description',
                }),
              })
            }
          })
      }
    }

    const handleReply = async (userslug, pid) => {
      userslug = replaceUsernameNumbers(userslug)

      setReply({
        pid: pid,
        userslug: userslug,
      })
      setReplyContentValue(`@${userslug}&nbsp;`)
    }

    const handleCancelReply = async () => {
      setReply({
        pid: '',
        userslug: '',
      })
      setReplyContentValue('')
    }

    const nestingLimit = isMobileView ? 1 : 4

    let depth = data.depth || 0
    const nestedComments = (data.object.children || []).map((comment) => {
      return <CommentObject depth={depth + 1} object={comment} type="child" />
    })

    return (
      <Comment
        id={`post-${data.object.pid}`}
        className={`${data.depth > nestingLimit ? 'no-margin' : ''}`}
      >
        <CommentAvatar>
          <OnlineIndicator onlineStatus={data.object.user.status} />
          <img
            src={data.object.user.picture || theme?.images?.defaultAvatar}
            alt="forum user avatar"
          />
        </CommentAvatar>
        <ContentContainer>
          <div className="header">
            <span className="name">
              {setCharacterLimit(
                replaceUsernameNumbers(data.object.user.username)
              )}{' '}
            </span>
            <span className="bullet-wrapper">
              <i className="fas fa-circle bullet" />
            </span>
            <span className="date">
              {moment(data.object.timestampiso).format('DD.MM.YYYY HH:mm ')}
            </span>
          </div>

          <Description
            dangerouslySetInnerHTML={{
              __html: unescapePost(data.object.content),
            }}
          ></Description>
          <ActionsWrapper>
            <Voting object={data.object} canVote={userRole} />
            <ReplyButton
              onClick={() =>
                userRole
                  ? handleReply(data.object.user.userslug, data.object.pid)
                  : setIsInfoModalOpen(!isInfoModalOpen)
              }
            >
              <i className="fas fa-comments" />
              {intl.formatMessage({
                id: 'forum.comments.button.reply',
              })}
            </ReplyButton>
            {!isNaN(forumId) && forumId === data.object.user.uid && (
              <EditButton
                onClick={() =>
                  enableEditMode(data.object.pid, data.object.content)
                }
              >
                <i className="fas fa-pencil-alt" />
                {intl.formatMessage({
                  id: 'forum.comments.button.edit',
                })}
              </EditButton>
            )}
            {(!isNaN(forumId) && forumId === data.object.user.uid) ||
            (userRole && userRole === 'admin' && data.object.deleted !== 1) ? (
              <EditButton onClick={() => handlePostDelete(data.object.pid)}>
                <i className="fas fa-trash" />
                {intl.formatMessage({
                  id: 'forum.topic.button.delete',
                })}
              </EditButton>
            ) : (
              <></>
            )}
            {userRole && userRole === 'admin' && data.object.deleted === 1 ? (
              <span>
                [
                {intl.formatMessage({
                  id: 'forum.topic.deleted',
                })}
                ]
              </span>
            ) : (
              <></>
            )}
          </ActionsWrapper>
          {reply.pid === data.object.pid && reply.userslug && (
            <ReplyFormWrapper>
              <Form className="comment-form" onSubmit={handleReplySave}>
                <ReplyWrapper>
                  <span>
                    {intl.formatMessage({
                      id: 'forum.commentform.replyuser.message',
                    })}
                    {'  '}
                    <UserName className="user-name">{reply.userslug}</UserName>
                  </span>
                </ReplyWrapper>
                <FormGroup fieldId="comment-field">
                  {renderField({
                    attribute: {
                      type: 'wysiwyg',
                      name: 'forum-topic',
                      heightMin: 100,
                      editorValue: replyContentValue,
                      onEditorValueChange: (value) => replyContentValue(value),
                      editorType: 'forum-topic',
                      imgUploadFolder: 'forum',
                      imgTagsConfig: {
                        ...tagsConfig,
                        context: 'forum-topic-comment',
                      },
                    },
                    onSave: (html) => handleReplyBlur(html),
                  })}
                </FormGroup>
                <ButtonsWrapper>
                  <CancelReplyButton onClick={handleCancelReply}>
                    {intl.formatMessage({
                      id: 'forum.commentform.replyuser.cancelreply',
                    })}
                  </CancelReplyButton>
                  <CommentButton
                    disabled={!userRole}
                    variant="primary"
                    type={userRole && 'submit'}
                    onClick={() =>
                      !userRole ? setIsInfoModalOpen(!isInfoModalOpen) : {}
                    }
                  >
                    {intl.formatMessage({ id: 'forum.actionbutton.comment' })}
                  </CommentButton>
                </ButtonsWrapper>
              </Form>
            </ReplyFormWrapper>
          )}
        </ContentContainer>
        {nestedComments}
      </Comment>
    )
  }
  const RecursiveCall = (data) => {
    return (
      <CommentsWrapper isLoading={!data.data}>
        {data.data ? (
          commentTree.map(function (object) {
            return (
              <>
                {object.deleted !== 1 ||
                (object.deleted === 1 && userRole === 'admin') ? (
                  <CommentObject object={object} />
                ) : (
                  <></>
                )}
              </>
            )
          })
        ) : (
          <Loader loading={!data.data} />
        )}
      </CommentsWrapper>
    )
  }
  const ref = useClickOutside(() => isTagsOpen && setIsTagsOpen(false))

  const topicModalSendBtnStyle =
    topicTitleModal.trim() === '' && isTopicModalOpen
      ? { pointerEvents: 'none', opacity: 0.5 }
      : {}
  return (
    <PageWrapper>
      <SectionHeader
        button={
          <GoBackButton onClick={() => history.goBack()}>
            <i className="fas fa-chevron-left" />
            {intl.formatMessage({
              id: 'forum.sectionheader.button.back',
            })}
          </GoBackButton>
        }
      />
      {loadingObject.isLoading && (
        <FullscreenLoaderWrapper
          hasDarkBackground={loadingObject.hasBackground}
          className="loaderwrapper"
        >
          <Loader loading={loadingObject.isLoading} />
        </FullscreenLoaderWrapper>
      )}
      {data ? (
        <>
          <PostInfo>
            <div className="author-details">
              <CategoryLink
                to={`/forum/category/${data?.getTopic.topic.category.cid}`}
              >
                <AuthorAvatar
                  style={{
                    backgroundColor: data?.getTopic.topic.category.bgcolor,
                  }}
                >
                  <i
                    className={`fa ${data?.getTopic.topic.category.icon}`}
                    style={{ color: data?.getTopic.topic.category.color }}
                  />
                </AuthorAvatar>
                <FlexItem className="category-name">
                  {unescapePost(data?.getTopic.topic.category.name)}
                </FlexItem>
              </CategoryLink>
              <span className="bullet-wrapper">
                <i className="fas fa-circle bullet" />
              </span>
              <ConditionalWrapper
                condition={isMobileView}
                wrapper={(children) => (
                  <div className="conditional-author-wrapper">{children}</div>
                )}
              >
                <AuthorAvatar>
                  <img
                    src={
                      data?.getTopic.postsList[0].user.picture ||
                      theme?.images?.defaultAvatar
                    }
                    alt=""
                  />
                </AuthorAvatar>
                <FlexItem className="user-name">
                  {replaceUsernameNumbers(
                    data?.getTopic.postsList[0].user.username
                  )}
                </FlexItem>
                <span className="bullet-wrapper">
                  <i className="fas fa-circle bullet" />
                </span>
                <FlexItem className="date">
                  {moment(data?.getTopic.postsList[0].timestampiso).format(
                    'DD.MM.YYYY HH:mm '
                  )}
                </FlexItem>
              </ConditionalWrapper>
            </div>
            {data?.getTopic.topic.tagsList &&
              data?.getTopic.topic.tagsList.map(function (object, i) {
                return (
                  <SingleTag key={i}>
                    <Link to={`/forum/tag/${object.valueescaped}`}>
                      <i className="fas fa-tag" />
                      {unescapePost(object.value)}
                    </Link>
                  </SingleTag>
                )
              })}
          </PostInfo>
          <PostWrapper>
            <PostDetails>
              {!isMobileView && (
                <RatesWrapper>
                  <Voting object={data?.getTopic?.postsList[0]} />
                </RatesWrapper>
              )}
              <PostContent>
                <ConditionalWrapper
                  condition={isMobileView}
                  wrapper={(children) => (
                    <div className="conditional-topic-wrapper">{children}</div>
                  )}
                >
                  {isMobileView && (
                    <RatesWrapper>
                      <Voting object={data?.getTopic?.postsList[0]} />
                    </RatesWrapper>
                  )}
                  <div className="topic">
                    <h2
                      className="post-title"
                      dangerouslySetInnerHTML={{
                        __html: data?.getTopic.topic.title,
                      }}
                    ></h2>
                    <p
                      className="post-content"
                      dangerouslySetInnerHTML={{
                        __html: data?.getTopic.postsList[0].content
                          ? unescapePost(data?.getTopic.postsList[0].content)
                          : '',
                      }}
                    ></p>
                  </div>
                </ConditionalWrapper>
                <PostStatistics>
                  <span>
                    <i className="far fa-comments" />
                    {data?.getTopic.topic.postcount
                      ? data?.getTopic.topic.postcount - 1
                      : 0}{' '}
                    {intl.formatMessage({ id: 'forum.topic.label.comments' })}
                  </span>
                  <span>
                    <i className="fas fa-eye" />
                    {data?.getTopic.topic.viewcount}{' '}
                    {intl.formatMessage({ id: 'forum.topic.label.views' })}
                  </span>
                  <span>
                    {!isNaN(forumId) &&
                      forumId === data?.getTopic.postsList[0].user.uid && (
                        <EditButton
                          onClick={() =>
                            enableEditMode(
                              data?.getTopic.postsList[0].pid,
                              data?.getTopic.postsList[0].content,
                              data?.getTopic.topic.title,
                              data?.getTopic.topic.tagsList,
                              true,
                              true
                            )
                          }
                        >
                          <i className="fas fa-pencil-alt" />
                          {intl.formatMessage({
                            id: 'forum.comments.button.edit',
                          })}
                        </EditButton>
                      )}
                  </span>
                  <span>
                    {((!isNaN(forumId) &&
                      forumId === data?.getTopic.postsList[0].user.uid) ||
                      (userRole && userRole === 'admin')) && (
                      <EditButton
                        onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                        disabled={!data && true}
                      >
                        <i className="fas fa-trash" />
                        {intl.formatMessage({
                          id: 'forum.topic.button.delete',
                        })}
                      </EditButton>
                    )}
                  </span>
                </PostStatistics>

                <Form className="comment-form" onSubmit={handleSave}>
                  <FormGroup fieldId="comment-field">
                    {renderField({
                      attribute: {
                        type: 'wysiwyg',
                        name: 'forum-topic',
                        heightMin: 100,
                        editorValue: contentValue,
                        onEditorValueChange: (value) => setContentValue(value),
                        editorType: 'forum-topic',
                        imgUploadFolder: 'forum',
                        imgTagsConfig: {
                          ...tagsConfig,
                          context: 'forum-topic-comment',
                        },
                      },
                      onSave: (html) => handleBlur(html),
                    })}
                  </FormGroup>
                  <ButtonsWrapper>
                    <CommentButton
                      data-tip="Make sure you include the text in your comment. Comments without text will not be posted"
                      disabled={!userRole}
                      variant="primary"
                      type={userRole && 'submit'}
                      onClick={() =>
                        !userRole ? setIsInfoModalOpen(!isInfoModalOpen) : {}
                      }
                    >
                      {intl.formatMessage({ id: 'forum.actionbutton.comment' })}
                    </CommentButton>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                  </ButtonsWrapper>
                </Form>
              </PostContent>
            </PostDetails>
          </PostWrapper>
        </>
      ) : (
        <Loader loading={!data} />
      )}
      <RecursiveCall data={data} />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={setIsDeleteModalOpen}
        topicDeleteAction={handleTopicDelete}
        textContent={intl.formatMessage({
          id: 'forum.modal.delete.textcontent',
        })}
        title={intl.formatMessage({ id: 'forum.modal.delete.title' })}
      />
      {data?.getTopic.pagecount > data?.getTopic.currentpage && (
        <LoadMoreContainer>
          <Row>
            <Col className="separator-col my-auto">
              <hr />
            </Col>
            <Col className="button-col">
              <LoadMoreButton
                onClick={() =>
                  fetchMore({
                    variables: {
                      id: id,
                      page: data?.getTopic.currentpage + 1,
                    },
                    updateQuery,
                  })
                }
              >
                {intl.formatMessage({ id: 'global.loadmore.button' })}
                <i class="fas fa-chevron-down"></i>
              </LoadMoreButton>
            </Col>
            <Col className="separator-col my-auto">
              <hr />
            </Col>
          </Row>
        </LoadMoreContainer>
      )}
      <InfoModal
        title={intl.formatMessage({ id: 'forum.infomodal.title' })}
        textContent={intl.formatMessage({ id: 'forum.infomodal.textcontent' })}
        isOpen={isInfoModalOpen}
        onClose={setIsInfoModalOpen}
      ></InfoModal>
      {isModalOpen && (
        <CustomizedModal
          position="top"
          title={intl.formatMessage({ id: 'forum.updatetopic.modal.title' })}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <div>
            <Form className="comment-form" onSubmit={handleEditSave}>
              <FormGroup fieldId="comment-field">
                {isTopicModalOpen && (
                  <Input
                    input={{
                      name: 'Title',
                      placeholder: intl.formatMessage({
                        id: 'forum.newtopic.modal.topictitle.placeholder',
                      }),
                      value: topicTitleModal,
                    }}
                    onChangeCallback={(html) => handleTopicChange(html)}
                  />
                )}
                {renderField({
                  attribute: {
                    type: 'wysiwyg',
                    name: 'forum-category',
                    heightMin: 150,
                    editorType: 'forum-category',
                    editorValue: topicContentModal,
                    imgUploadFolder: 'forum',
                    imgTagsConfig: {
                      ...tagsConfig,
                    },
                  },
                  onSave: (html) => handleContentBlur(html),
                })}
                {tagsIncluded && (
                  <AllTagsWrapper>
                    <button
                      className="add-tags-button"
                      onClick={handleTagsClose}
                    >
                      <i className="fas fa-tag" />
                      {intl.formatMessage({
                        id: 'forum.newtopic.button.label.addtags',
                      })}
                    </button>
                    {tagsArray.map(function (object) {
                      return (
                        <SingleTag>
                          <i className="fas fa-tag" />
                          {object}{' '}
                          <button
                            className="remove-tag"
                            onClick={(e) => removeTagFromList(e, object)}
                          >
                            <i className="fas fa-times" />
                          </button>{' '}
                        </SingleTag>
                      )
                    })}
                  </AllTagsWrapper>
                )}
                {isTagsOpen && tagsIncluded && (
                  <TagsMainWrapper ref={ref} className="tags-addtags-wrapper">
                    <TagsInputWrapper>
                      <span className="tags-helptext">
                        {intl.formatMessage({
                          id: 'forum.newtopic.tags.label.description',
                        })}
                      </span>
                      <CustomizedInput
                        value={query}
                        onChange={onChange}
                        type="text"
                        onClear={(evt) => onChange('', evt)}
                        onKeyDown={handleKeyDown}
                      />
                      {tags && tags.length > 0 && (
                        <SearchResults>
                          {tags.map((tag) => {
                            return (
                              <SingleResultWrapper>
                                <span onClick={() => addTagToList(tag.value)}>
                                  {tag.value}
                                </span>
                              </SingleResultWrapper>
                            )
                          })}
                        </SearchResults>
                      )}
                    </TagsInputWrapper>
                  </TagsMainWrapper>
                )}
              </FormGroup>
              <ButtonsWrapper>
                <CancelButton variant="link" onClick={() => handleCloseModal()}>
                  {intl.formatMessage({
                    id: 'forum.newtopic.modal.button.cancel',
                  })}
                </CancelButton>
                <AddButton
                  style={topicModalSendBtnStyle}
                  variant="primary"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'forum.actionbutton.update',
                  })}
                </AddButton>
              </ButtonsWrapper>
            </Form>
          </div>
        </CustomizedModal>
      )}
    </PageWrapper>
  )
}

export default Topic
