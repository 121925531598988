import React from 'react'
import { LoaderStyled } from './styles'
import GridLoader from 'react-spinners/GridLoader'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

const style = css`
  margin: 0 auto;
  margin-top: 20px;
`

const Loader = ({ title, description }) => {
  const defaultTitle = 'Your payment is in progress'
  const defaultDescription = 'Please wait'
  const theme = useTheme()

  return (
    <LoaderStyled>
      <span className="title">{title || defaultTitle}</span>
      <span className="description">{description || defaultDescription}</span>
      <div className="loader">
        <GridLoader
          css={style}
          size={15}
          color={theme.colors.buttonColor}
          loading
        />
      </div>
    </LoaderStyled>
  )
}

export default Loader
