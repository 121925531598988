import React, { useState } from 'react'
import { CouponStyled, CurrentCoupon, CouponWrapper } from '../styles/coupon'
import axios from 'axios'
import {
  couponToAmountText,
  couponToDurationText,
} from '../../shared/payment-helpers'
import { Button } from '../styles/checkout'
import { useIntl } from 'react-intl'

const Coupon = ({ onCallback, renderMessageText }) => {
  const [couponCode, setCouponCode] = useState('')
  const [currentCoupon, setCurrentCoupon] = useState()
  const [isCouponInvalid, setIsCouponInvalid] = useState()
  const [isLoading, setIsLoading] = useState()

  const intl = useIntl()

  const handleVerifyCoupon = async (e) => {
    e.preventDefault()
    if (!couponCode) {
      setIsCouponInvalid(true)
    } else {
      const path = `${process.env.REACT_APP_API_ENDPOINT}/payment/get-coupon/${couponCode}`
      setIsLoading(true)
      const { data } = await axios.get(path, {
        headers: { authorization: localStorage.getItem('RIOT/token') },
      })
      setCouponCode('')
      if (data?.coupon?.id) {
        setCurrentCoupon(data?.coupon)
        onCallback(data)
      } else {
        setIsCouponInvalid(true)
      }
      setIsLoading(false)
    }
  }

  const handleRemoveCoupon = () => {
    onCallback(null)
    setCurrentCoupon(null)
  }

  const handleCouponChange = (value) => {
    setCouponCode(value)
    setIsCouponInvalid(false)
  }

  return (
    <CouponStyled>
      <span className="title">
        {renderMessageText(
          !currentCoupon ? 'coupon.title.add' : 'coupon.title.activecoupons'
        )}
      </span>

      <div className="content">
        <CouponWrapper className="add-coupon">
          <input
            aria-label={renderMessageText('coupon.input.placeholder')}
            className={`${isCouponInvalid ? 'error' : ''}`}
            placeholder={renderMessageText('coupon.input.placeholder')}
            value={couponCode}
            onChange={(e) => handleCouponChange(e.target.value)}
          />
          <Button onClick={(e) => handleVerifyCoupon(e)}>
            {isLoading ? '...' : renderMessageText('coupon.button.add')}
          </Button>
        </CouponWrapper>
        {isCouponInvalid && (
          <span className="error-message">
            {renderMessageText('coupon.error')}
          </span>
        )}
      </div>

      {currentCoupon && (
        <CurrentCoupon>
          <span className="coupon-name">{currentCoupon?.name}</span>
          <span className="coupon-off">{`-${couponToAmountText({
            coupon: currentCoupon,
          })}
            (${couponToDurationText({
              coupon: currentCoupon,
              intl,
            })})`}</span>
          <i onClick={() => handleRemoveCoupon()} className="fas fa-times" />
        </CurrentCoupon>
      )}
    </CouponStyled>
  )
}

export default Coupon
