import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

//import './style.scss'

const SingleTypeContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  transition: 0.15s;
  z-index: 1;

  &.active {
    border-left: 3px solid #3fad8b;
    padding-left: 5px;
  }
`

const DetailsRow = styled(Row)`
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  z-index: 2;
`

const IconCol = styled(Col)`
  max-width: 70px;
`

const DetailsCol = styled(Col)`
  padding-bottom: 0px !important;
  padding-top: 0px !important;
`

const DateCol = styled(Col)`
  max-width: 70px;
`

const IconWrapper = styled.div`
  padding: 15px;
  border: 3px solid var(--background);
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 100%;
  position: relative;
`

const Icon = styled.img`
  position: absolute;
  height: 15px;
  width: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`

const Name = styled.h5`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 600;
  display: block;
`

const Date = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
`

const Index = ({ type, activeView, setActiveView }) => {
  return (
    <SingleTypeContainer
      className={`${activeView === type.id ? 'active' : ''}`}
      onClick={() => setActiveView(type.id)}
    >
      <DetailsRow>
        <IconCol className="icon-col text-left">
          <IconWrapper>
            <Icon className="notification-icon" alt="" src={type.icon} />
          </IconWrapper>
        </IconCol>
        <DetailsCol className="my-auto">
          <Name>{type.name}</Name>
        </DetailsCol>
        <DateCol className="text-right my-auto">
          <Date>Now</Date>
        </DateCol>
      </DetailsRow>
    </SingleTypeContainer>
  )
}

export default Index
