import styled from '@emotion/styled'
import { Card, CardBody, CardFooter, CardTitle } from '@patternfly/react-core'
import border from '_utils/css-border'
import { mobile } from '_utils/responsive-queries'

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  word-break: break-word;
`

export const Description = styled(CardBody)`
  font-weight: 500;
  font-size: 18px;
  ${mobile} {
    font-weight: 600;
  }
`

export const CategoryIconWrapper = styled.div`
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--flavor);
  background-size: cover;
  border-radius: 50%;
  margin-right: 15px;
  width: 75px;
  height: 75px;
  flex-shrink: 0;

  i {
    font-size: 23px;
    color: var(--flavor-contrast);
  }
`

export const PostTile = styled(Card)`
  background-color: var(--background);
  flex-grow: 1;
  height: 100%;
  ${border()}
`

export const PostTileTitle = styled(CardTitle)`
  display: flex;
`

export const Footer = styled(CardFooter)`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  .posts-counter,
  .topics-counter {
    padding: 0 20px;
    min-width: 100px;
  }
  .number,
  .item {
    display: block;
    text-align: center;
  }
  .item {
    font-size: 14px;
    font-weight: 400;
  }
  .number {
    font-size: 22px;
    font-weight: 500;
  }
`
