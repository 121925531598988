import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node
}

export const LoginTitle = themable('LoginTitle', Props)
export default LoginTitle

