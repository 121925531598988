import styled from '@emotion/styled'
import { css } from '@emotion/core'

import ClickAway from '_components/click-away'
import { mobile, tablet, desktop } from '_utils/responsive-queries'
import { themeIsDark } from '_colorBus/primitives/scheme'

const mobileMenuBanner = () => css`
  .banner {
    display: none;
    height: 0px;
    background: var(--shade);
    object-fit: cover;
    object-position: center;
    width: 100%;
    opacity: 0.5;
    ${tablet} {
      height: 12em;
      display: block;
    }
  }
`

const userCard = () => css`
  .userCard {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'left right';
    align-items: center;
    grid-gap: var(--space);

    border-radius: var(--radius);
    padding: var(--space) 0px;
    position: relative;
    z-index: 10;
    transition: 0.4s ease-in-out;
    
    ${tablet} {
      grid-template-columns: auto 2fr 1fr;
      border-bottom-left-radius: 0px;
      padding-left: var(--space-m);
      position: relative;
    }
  }
`

const userCardText = () => css`
  .text-name {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.3em;
    grid-area: left;
    transition: padding 0.4s ease-in-out;

    p {
      user-select: none;
      color: var(--foreground);
      opacity: 1;
      transform: translate(0em);
      transition: 0.4s ease-in-out;
      &:nth-of-type(1) {
        margin-bottom: 0px;
      }
      &:nth-of-type(2) {
        font-weight: 600;
        margin-bottom: 0px;
      }
    }
  }

  ${tablet} {
    .text-name {
      grid-area: right;
    }
  }

  ${desktop} {
    .text-name.scrolled {
      padding-right: 0em;
      p:nth-of-type(1) {
        margin-top: -1.3rem;
        transform: translateY(-3em);
        opacity: 0.2;
      }
    }
  }
`

const userCardCounter = (props) => css`
  .counter {
    position: absolute;
    top: -5px;
    right: -10px;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--cirlceSize);
    height: var(--cirlceSize);
    padding-top: 0.2em;
    border-radius: 50%;

    font-weight: 700;
    font-size: 12px;

    color: var(--flavor-contrast);
    background-color: var(--flavor);

    transition: 0.4s ease-in-out;

    ${tablet} {
      --cirlceSize: 21px;
      top: 6px;
      right: 68%;
    }
  }
`

const iconDropdown = ({ isScrollTop }) => css`
  .icon-dropdown {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    --size: 15px;
    width: var(--size);
    aspect-ratio: 1/1;

    position: absolute;
    --divide: ${isScrollTop ? '5' : '3'};
    left: calc(0px - var(--size) / var(--divide));
    bottom: calc(0px - var(--size) / var(--divide));
    transform: rotate(180deg);

    border-radius: 50%;
    background-color: var(--flavor);
    text-align: center;
    font-size: 10px;

    transition: 0.2s ease-in-out;

    &.open {
      transform: rotate(0deg);
    }

    i {
      color: var(--flavor-contrast);
      line-height: 14px;
      position: absolute;
      top: 0px;
      font-size: 10px;
    }

    ${tablet} {
      display: none;
      position: relative;
      width: 40px;
      left: auto;
      bottom: auto;
      background-color: transparent;
      i {
        color: var(--foreground);
        top: 11px;
        font-size: 2em;
      }
    }
  }
`

export const UserWrapper = styled.div`
  transition: .4s;
  &.loggedOut {top: var(--space);}
  ${desktop} {
    position: absolute;
    top: 0px; right: var(--margin);
    z-index: 10;
    &.scrolled.loggedOut {
      top: var(--space);
    }
  }
`

export const UserDropdownStyles = styled.div`
  width: auto;
  max-width: 100%;

  opacity: 1;
  transition: 0.4s ease-in-out;

  ${tablet} {
    width: 100%;
    position: unset;
    opacity: 1;
  }

  ${mobileMenuBanner}
  ${userCard}
  ${userCardText}
  ${userCardCounter}
  ${iconDropdown}
`

const isImgColor = ({frontpage}) => css`
  --isImgColor: ${frontpage ? "var(--imgColor);" : "var(--foreground);"};
`

export const AvatarWrapper = styled.div`
  position: relative;
  grid-area: right;
  top: 0px;

  width: 39px;
  aspect-ratio: 1 / 1;

  border: none;
  border: var(--borderSize) var(--foreground);
  border-radius: 50%;
  transition: 0.4s ease-in-out;

  ${desktop} {
    cursor: pointer;
    ${isImgColor}
    &:not(.scrolled) { border: var(--borderSize) var(--isImgColor); }
    &.scrolled {
      width: 30px;
      background: var(--isImgColor);
    }
  }

  ${tablet} {
    width: 60px;
    border: var(--borderSize) var(--foreground);
    background: var(--foreground);
    position: static;
    grid-area: left;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    user-select: none;
    object-fit: cover;
  }

  ${iconDropdown}
`

export const NotificationCircle = styled.span`
  position: absolute;
  right: 19px;
  top: 11px;
  width: var(--cirlceSize);
  height: var(--cirlceSize);
  border-radius: 50%;
  background-color: var(--flavor);
  color: var(--flavor-contrast);
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  ${tablet} {
    right: 45px;
  }
`

const dropDownMenuItems = () => css`
  a,
  .sanity-button {
    position: relative;
    color: var(--foreground) !important;
    display: block;
    text-align: left;
    font-weight: 700;
    font-size: 14px;

    padding: 
      var(--space-s) 
      var(--space-s) 
      var(--space-s) 
      var(--space);

    opacity: 1;
    transition: 0.2s;

    ${tablet} {
      padding-left: 10%;
    }

    ${mobile} {
      //TODO: turn admin back on
      &.dropdown-link.admin {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: var(--flavor);
      transition: opacity 0.2s ease-in-out;
    }

    &:after {
      content: '';
      height: 100%;
      background: var(--background);
      width: 0px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: 0.2s ease-in-out;
    }
    &:hover {
      opacity: 1;
      &::before {
        opacity: 0.2;
      }
      &::after {
        width: 3%;
        background: var(--flavor);
      }
      &.dropdown-link:after {
        height: 100%;
        bottom: 0px;
      }
    }
  }
  .sanity-button {
    width: 100%;
    transition: 0.5s !important;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 0px;
  }
`

const menuSubmenu = () => css`
  &.submenu-links {
    min-width: 14em;
  }
  ${tablet} {
    &.submenu-links > * { font-weight: 400; }
  }
`

export const NavLinksWrapper = styled.div`
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  background: var(--background);

  overflow-y: hidden;
  margin: 0 -50px;

  position: absolute;
  top: 100%; left: 50%;
  transform: translateX(-25%);
  z-index: 6;

  min-width: 12em;

  ${tablet} {
    background-color: var(--shade-faint);
    position: relative;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: translateX(0);
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
`

const openMenu = () => css`
  max-height: 170vh;
  padding: 
    var(--space) 0em 
    var(--space);
`

export const NavigationLinks = styled(ClickAway)`
  background: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};
  padding: 0em;

  border-top: solid 0px var(--shade);
  border-radius: var(--radius);
  max-height: 0px;
  max-width: 100%;

  overflow: hidden;
  transition: 0.4s;

  &.open {
    ${openMenu}
  }

  .active {
    font-weight: 900;
  }

  ${tablet} {
    ${openMenu}
    position: unset;
    background-color: transparent;
    box-shadow: var(--shadow);
    border-radius: 0em;
  }

  ${dropDownMenuItems}
  ${menuSubmenu}
`

export const SignInContainer = styled.div`
  display: flex;
  margin-top: 0rem;
  a > * {
    padding: 5px 10px;
    font-weight: 600;
    background-color: transparent;
  }
  ${tablet} {
    background: var(--shade);
    height: 5em;
    padding: 5em 0em;
  }
`

export const SignUpButton = styled.button`
  color: ${({ isScrollTop, location }) =>
    isScrollTop && (location === '/' || location === '/discover')
      ? 'var(--imgColor)'
      : 'var(--foreground)'};
  margin-left: 20px;
  border: none;
  ${tablet} {
    color: var(--foreground) !important;
  }
`

export const SignInButton = styled.button`
  color: ${({ isScrollTop, location }) =>
    isScrollTop && (location === '/' || location === '/discover')
      ? 'var(--imgColor)'
      : 'var(--foreground)'};
  border: 2px solid
    ${({ isScrollTop, location }) =>
      isScrollTop && (location === '/' || location === '/discover')
        ? 'var(--imgColor)'
        : 'var(--foreground)'};
  border-radius: 30px;
  transition: 0.5s;
  ${tablet} {
    border: 2px solid var(--foreground) !important;
    color: var(--foreground) !important;
  }
`

export const NotificationsWrapper = styled.div`
  button {
    margin-right: 20px;
    border: none;
    background-color: transparent;
  }

  i {
    position: relative;
    font-size: 25px;
    span.counter {
      width: 16px;
      height: 16px;
      position: absolute;
      top: -5px;
      right: -12px;
      color: var(--flavor-contrast);
    }
  }
`
