import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node,
  scrollPosition: PropTypes.number,
  themeSettings: PropTypes.object,
  isScrollTop: PropTypes.bool,
}

export const HeaderNav = themable('HeaderNav', Props)
export default HeaderNav
