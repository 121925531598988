import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Badge } from '@patternfly/react-core'
import { ctaButtonHollow } from '_utils/css-buttons'
import { mobile } from '_utils/responsive-queries'

export const SectionHeader = styled.div`
  width: 100%;
  margin: 30px 0;
`
export const PageWrapper = styled.div`
  max-width: 1570px;
  margin: 0 auto;
`

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 15px;
`

export const ByPostsButton = styled.button`
  ${ctaButtonHollow()}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--flavor) !important;
      border-color: var(--flavor) !important;
      color: var(--flavor-contrast) !important;
    `}
    ${mobile} {
    padding: 5px 15px;
  }
`
export const ByReputationButton = styled.button`
  ${ctaButtonHollow()}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--flavor) !important;
      border-color: var(--flavor) !important;
      color: var(--flavor-contrast) !important;
    `}
        ${mobile} {
    padding: 5px 15px;
  }
`

export const ActivityIndicator = styled(Badge)`
  background-color: var(--flavor);
  padding: 5px 15px;
  position: absolute;
  top: 5px;
  left: 5px;
  span,
  i {
    font-size: 16px;
  }
  i {
    margin-right: 10px;
  }
`

export const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

export const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`
