import React from 'react'
import { useTheme } from 'emotion-theming'
import { take } from 'lodash'
import LocationTag from './location-tag'
import LoginRequiredLink from '_security/login-required-link'

import scaleSanityImage from 'helpers/scaleSanityImage'
import { conditionalTrimmer } from '_application'
import {
  Container,
  ShadowLayer,
  Tags,
  Tag,
  Cover,
  CompanyDetailsHeader,
  CompanyAvatar,
  CompanyName,
  Header,
  Avatar,
  AvatarRow,
  AvatarName,
  ContentContainer,
  TileContent,
  Title,
  Location,
  CircleBackground,
} from '_themes/default/components/styled/tile'

const Tile = ({
  cover,
  avatar,
  avatarName,
  sub,
  title,
  subtitle,
  companyTitle,
  location,
  tags,
  type,
  className,
  children,
  borderOff,
  url,
  tileHeight,
  isDetailsCard,
  connections,
}) => {
  const theme = useTheme()

  const checkValue = (value) => {
    return value && value !== 'undefined' ? value : null
  }

  const tagsLimit = 2
  const tagsToDisplay = checkValue(tags) && tags.slice(0, tagsLimit)

  const Link = url ? LoginRequiredLink : ({ children }) => <>{children}</>

  const displayCompanyName = (name) => {
    const limit = 37
    if (!name) return 'No title'
    conditionalTrimmer(name, limit, '..')
  }

  const renderAvatars = (list, number, type) => {
    const srcPath = type === 'avatar' ? 'avatar' : 'coverPhoto'
    return (
      <>
        {!list || list.length === 0 ? (
          <>
            <Avatar alt="" src={avatar || theme.images.defaultAvatar} />
            <AvatarName>{companyTitle ? companyTitle : avatarName}</AvatarName>
          </>
        ) : (
          <>
            {take(list, number).map((item, i) => (
              <Avatar
                key={item.id}
                index={i}
                src={
                  // downScalesImage if it is from sanity
                  item[srcPath]
                    ? scaleSanityImage({ link: item[srcPath], height: 70 })
                    : theme.images.defaultAvatar
                }
              />
            ))}
            {list.length > number && (
              <CircleBackground>
                <p>+{list.length - number}</p>
              </CircleBackground>
            )}
          </>
        )}
      </>
    )
  }

  const renderHeaderContent = () =>
    type === 'company' ? (
      <CompanyDetailsHeader>
        <CompanyAvatar alt="" src={avatar || theme.images.defaultAvatar} />
        <CompanyName>
          {displayCompanyName(companyTitle ? companyTitle : avatarName)}
        </CompanyName>
      </CompanyDetailsHeader>
    ) : (
      <>
        <Cover alt="" src={cover || theme.images.defaultCover} />
        <Header>
          {avatar || avatarName || companyTitle ? (
            <AvatarRow>
              {renderAvatars(connections?.companies, 4, type)}
            </AvatarRow>
          ) : null}
        </Header>
      </>
    )

  let LinkComp = ({ children }) => <>{children}</>
  if (url) {
    LinkComp = ({ children }) => <Link to={url}>{children}</Link>
  }

  return (
    <Container
      className={className}
      borderOff={borderOff}
      tileHeight={tileHeight}
    >
      {isDetailsCard ? (
        <>
          <Link to={url}>
            <CompanyDetailsHeader>
              <CompanyAvatar
                alt=""
                src={avatar || theme.images.defaultAvatar}
              />
              <CompanyName>
                {companyTitle ? companyTitle : avatarName}
              </CompanyName>
              <Location>{location}</Location>
            </CompanyDetailsHeader>
          </Link>
        </>
      ) : (
        <LinkComp>
          <Link to={url}>
            {location && type !== 'company' && (
              <ShadowLayer>
                <Tags>
                  <LocationTag>{location}</LocationTag>
                </Tags>
              </ShadowLayer>
            )}

            <Tags>
              {tagsToDisplay?.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
              {checkValue(tags) && tags.length > tagsLimit && (
                <Tag>{`+${tags.length - tagsLimit}`}</Tag>
              )}
            </Tags>

            {renderHeaderContent()}
          </Link>
        </LinkComp>
      )}
      <ContentContainer
        className={`${type}${isDetailsCard ? '-detail' : ''}-card-content`}
      >
        {!isDetailsCard && type !== 'company' && (
          <Link to={url}>
            <Title>{title && conditionalTrimmer(title, 42, '..')}</Title>
          </Link>
        )}

        <TileContent>{children}</TileContent>
      </ContentContainer>
    </Container>
  )
}
export default Tile
