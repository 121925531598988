import styled from '@emotion/styled'

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;

  .label-wrapper {
    display: flex;
    flex-direction: column;
  }

  .location-content-wrapper {
    width: 100%;
  }
`

export const LocationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .location-selector,
  .ui-kit-input {
    width: 410px;
    margin: 0;
  }
`

export const EventMapWrapper = styled.div`
  .google-maps-container {
    height: 275px;
  }
`

