import React from 'react'
import PhoneInput from 'react-phone-number-input'
import { InputWrapper } from '../styles'
import { PhoneNumberStyled } from '../styles/ca-phone-number-input'
import 'react-phone-number-input/style.css'
import BreakLine from './breakline'

const CustomAttributeInput = ({ attribute, attributeConfig: { onSave } }) => {
  return (
    <>
      <InputWrapper>
        <h4 className="title">{attribute.name}</h4>
        <p className="description">{attribute.description}</p>
        <PhoneNumberStyled>
          <PhoneInput
            international
            defaultCountry="NO"
            className="phone-number-styled"
            onChange={(phone) => onSave(attribute.key, phone)}
          />
          <span className="arrow-icon"></span>
        </PhoneNumberStyled>
      </InputWrapper>
      <BreakLine />
    </>
  )
}

export default CustomAttributeInput
