import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import { Access } from '../../shared/styles/access'
import { useEntityAccess } from '../../shared/entity-access'
import { useIntl } from 'react-intl'

const Dashboard = () => {
  const intl = useIntl()
  const [setEntityAccess, useGetEntityAccess] = useEntityAccess()

  const renderMessageText = (value, defaultMessage) =>
    intl.formatMessage({
      id: `admin.sitestructure.events.access.${value}`,
      defaultMessage,
    })

  const { doUpdateEntityAccess } = setEntityAccess()

  const handleAction = (detailsRequiresAuth) => {
    doUpdateEntityAccess({ name: 'EVENTS', detailsRequiresAuth })
  }

  const entityAccess = useGetEntityAccess('EVENTS', 'detailsRequiresAuth')

  const eventDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  return (
    <MainSection>
      <Row>
        <Col sm={12} md={12}>
          <span className="title">{renderMessageText('title', 'Access')}</span>
          <span className="description">
            {renderMessageText(
              'description',
              'Who would you like to be able to access your events?'
            )}
          </span>

          <Access>
            <button
              className={eventDetailsRequiresAuth ? 'active' : ''}
              onClick={() => handleAction(true)}
            >
              {renderMessageText('buttons.signed', 'Signed-in users')}
            </button>
            <button
              className={!eventDetailsRequiresAuth ? 'active' : ''}
              onClick={() => handleAction(false)}
            >
              {renderMessageText('buttons.everyone', 'Everyone')}
            </button>
          </Access>
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
