import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import { useIntl } from 'react-intl'
import { TableStyled } from '../shared/styles/table'
import UserManageModal from './manage-modal'
import { conditionalTrimmer } from '_application'

const Table = ({ users, sort, setSort, setPagingLimit, refetch }) => {
  const intl = useIntl()

  const [currentUser, setCurrentUser] = useState()

  const [showModal, setShowModal] = useState()

  const handleSelectUser = (u) => {
    setCurrentUser(u)
    setShowModal(true)
  }
  const onClose = (shouldRefetch) => {
    setCurrentUser(null)
    setPagingLimit(users.length)
    if (shouldRefetch) {
      refetch()
    }
  }
  const renderMessage = (type, value) => {
    return intl.formatMessage({
      id: `admin.table.users.${type.toLowerCase()}.${value.toLowerCase()}`,
    })
  }

  const convertDateString = (string, intl) => {
    if (string.includes('a few seconds'))
      return string.replace(
        'a few seconds',
        `${intl.formatMessage({ id: 'date.timefromnow.now' })}`
      )
    if (string.includes('a minute')) return string.replace('a minute', '1 min')
    if (string.includes('minutes')) return string.replace('minutes', 'min')
    if (string.includes('hours')) return string.replace('hours', 'h')
    if (string.includes('a hour')) return string.replace('a hour', '1 h')
    if (string.includes('days')) return string.replace('days', 'd')
    if (string.includes('a day')) return string.replace('a day', '1 d')
    if (string.includes('months')) return string.replace('months', 'm')
    if (string.includes('a month')) return string.replace('a month', '1 m')
    if (string.includes('years')) return string.replace('years', 'y')
    if (string.includes('a year')) return string.replace('a year', '1 y')
    return string
  }

  const convertUserRole = (role) => {
    switch (role) {
      case 'user':
        return renderMessage('role', 'user')
      case 'contentEditor':
        return renderMessage('role', 'contentEditor')
      case 'admin':
        return renderMessage('role', 'admin')
      default:
        return renderMessage('role', 'n/a')
    }
  }

  const handleSort = (field) => {
    const s = {
      field,
      order:
        field === sort.field ? (sort.order === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
    }
    setSort(s)
    setPagingLimit(users.length)
  }

  const fields = [
    { name: 'No.', noSort: true, value: 'number' },
    { name: 'First name', value: 'firstName' },
    { name: 'Last name', value: 'lastName' },
    { name: 'Email', value: 'email' },
    { name: 'Location', noSort: true, value: 'location' },
    { name: 'Role', value: 'role' },
    { name: 'Register date', value: '_id' },
    { name: 'Last login', value: 'lastLogin' },
    { name: 'Manage', noSort: true, value: 'manage' },
  ]

  return (
    <TableStyled>
      <table className="user-overview-table">
        <tr className="first-row">
          {fields.map((f) => {
            return (
              <th>
                {renderMessage('title', f.value)}
                {!f.noSort && (
                  <button type="button">
                    <i
                      tabIndex={0}
                      role="button"
                      onClick={() => handleSort(f.value)}
                      className={`${
                        sort.key === f.value ? 'active' : ''
                      } fas fa-sort`}
                    />
                  </button>
                )}
              </th>
            )
          })}
        </tr>
        {users.map((singleUser, i) => {
          const isCurrentUser = false //user.sub === singleUser.sub
          const firstName =
            singleUser.firstName &&
            singleUser.firstName.trimStart().trimEnd() !== ''
              ? singleUser.firstName
              : 'N/A'
          const lastName =
            singleUser.lastName &&
            singleUser.lastName.trimStart().trimEnd() !== ''
              ? singleUser.lastName
              : 'N/A'

          const { email, lastLogin, primaryEmail } = singleUser

          return (
            <tr
              className={`${isCurrentUser ? 'highlighted' : ''} ${
                singleUser.isLocked ? 'locked' : ''
              }`}
            >
              <td>{i + 1}</td>
              <td>{conditionalTrimmer(firstName, 11, '...')}</td>
              <td>{conditionalTrimmer(lastName, 11, '...')}</td>
              <td>{conditionalTrimmer(email || primaryEmail, 40, '...')}</td>
              <td>
                {singleUser.location &&
                singleUser.location.lat &&
                singleUser.location.lat !== 'undefined'
                  ? `${singleUser.location.city}, ${singleUser.location.country}`
                  : 'N/A'}
              </td>
              <td>{convertUserRole(singleUser.role)}</td>
              <td className="register-date">
                {moment
                  .unix(singleUser.dateRegistered)
                  .format('DD MMM YYYY HH:mm')}
                (
                {convertDateString(
                  moment.unix(singleUser.dateRegistered).fromNow(true),
                  intl
                )}
                )
              </td>
              <td className="register-date">
                {lastLogin
                  ? moment(lastLogin).format('DD MMM YYYY HH:mm')
                  : 'N/A'}
              </td>
              <td className="text-center">
                {isCurrentUser ? null : (
                  <button
                    onClick={() => handleSelectUser(singleUser)}
                    type="button"
                    data-tip={`${intl.formatMessage({
                      id: 'admin.manageusers.editbutton.tooltip.text',
                    })}`}
                  >
                    <i className="fas fa-user-cog" />
                  </button>
                )}
              </td>
            </tr>
          )
        })}
        <ReactTooltip effect="solid" />
      </table>
      {showModal && currentUser ? (
        <UserManageModal user={currentUser} onClose={onClose} />
      ) : null}
    </TableStyled>
  )
}

export default Table
