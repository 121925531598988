import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

export const Tabs = styled.div`
  margin: 0 0 20px 0;
  ${mobile} {
    margin-bottom: 30px;
  }
  ${({ isMobileView }) =>
    isMobileView &&
    css`
      display: flex;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 20px;
    `}

  .tab {
    position: relative;
    display: inline-block;
    padding: 4px 15px;
    border: 2px solid var(--foreground);
    color: var(--foreground);
    fill: var(--foreground);
    border-radius: 25px;
    margin: 0 14px 10px 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:focus {
      color: var(--flavor-contrast);
      background-color: var(--flavor-shade);
      border: unset;
      outline: solid 2px;
      outline-offset: 5px;
      outline-color: var(--flavor-shade);
    }
    &.active {
      border-color: var(--flavor);
      background: var(--flavor);
      color: var(--flavor-contrast);
      fill: var(--flavor-contrast);
    }
    &:active {
      //letter-spacing: 3px;
      opacity: 0.2 !important;
      transition: 0s ease-in-out;
    }
    &:hover {
      border-color: var(--flavor);
      background: var(--flavor);
      color: var(--flavor-contrast);
      fill: var(--flavor-contrast);
    }
    ${({ isMobileView }) =>
      isMobileView &&
      css`
        color: var(--flavor);
        border-color: var(--flavor);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        padding: 8px;
        /* margin: 0 5px 15px 5px; */
        margin: 0;
        i {
          color: var(--foreground);
        }
        &.active {
          i {
            color: var(--flavor-contrast);
          }
        }
      `}
    .fa-star {
      font-size: 8px;
    }
  }
  .count {
    position: absolute;
    top: -14px;
    left: 60%;
    background-color: var(--flavor);
    color: var(--flavor-contrast);
    padding: 0 8px;
    border: 2px solid var(--flavor);
    border-radius: 15px;
    font-size: 12px;
    font-weight: 600;

    &.active {
      border-color: var(--flavor-contrast);
    }
  }
`
/* fix */
