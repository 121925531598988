import { gql } from '@apollo/client'

export const GET_BLOGPOST = gql`
  query getBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      coverPhoto
      description
      _createdAt
      content
      isRestricted
      enabledToc
      likesCount
      isLiked
      isFeatured
      publishedAt
      isDraft
      categories {
        id
        title
      }
      categoriesNew {
        id
        name
        groupBy
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
        bioShort
        location
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

export const SAVE_BLOGPOST = gql`
  mutation saveBlogPost($input: BlogPostInput) {
    saveBlogPost(input: $input) {
      id
    }
  }
`

export const GET_CATEGORIES = gql`
  query {
    getBlogCategories {
      id
      name
      subcategories {
        id
        name
        parentId
      }
    }
  }
`
