import styled from '@emotion/styled'

export const ShortDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  .required-star {
    color: red;
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;

    .description-content-info {
      font-weight: 400;
      font-size: 14px;
      color: var(--foreground);
    }
  }

  .description-content-wrapper {
    width: 80vw;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    .description-content {
      width: 80vw;
    max-width: 800px;
      height: 136px;
      border: 1px solid var(--foreground);
      resize: none;
      outline: none;
      background: var(--background);
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
      padding: 12px 16px;
    }

    .char-counter {
      align-self: flex-end;
      color: var(--foreground);
      opacity: 0.7;
      margin-top: 8px;
    }
  }
`
