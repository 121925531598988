import css from '@emotion/css'
import styled from '@emotion/styled'

import { rootScheme } from '_colorBus'
import { getHSLA } from '_colorBus/primitives/color'

export const SectionStyled = styled.div`
  --shallow-shade: ${({ theme }) =>
    getHSLA(rootScheme(theme.colors).foreground.color, 0.2).string};

  display: flex;
  margin-bottom: 25px;
  padding: 0 45px;

  .icon {
    display: inline-block;
    height: 100%;
    vertical-align: top;

    & > * {
      width: 20px;
      margin-right: 20px;
      path {
        stroke: var(--foreground);
      }
    }
  }
  .content {
    display: inline-block;
    width: 100%;
    div:last-child {
      margin-bottom: 0;
    }
  }

  ${({ type, side }) =>
    type === 'badge' &&
    css`
      position: relative;
      padding-top: 55px;

      background-color: var(--shallow-shade);
      &:before {
        content: '';
        position: absolute;

        top: 0;
        left: 45px;
        transform: translateY(-50%) rotate(45deg);
        width: 25px;
        height: 25px;
        background-color: var(--shade);
        z-index: 5;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -100px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: var(--shallow-shade);
        border-bottom-right-radius: ${side === 'left' ? '30px' : '0'};
        border-bottom-left-radius: ${side === 'right' ? '30px' : '0'};
        /* border-bottom-left-radius: 30px; */
      }
    `}
`

export const SeparatorStyled = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.25;
  margin: 20px 0;
  background: var(--flavor);
`
