import styled from '@emotion/styled'

const NoContentStyled = styled.li`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
  .empty-icon {
    padding: 10px;
    color: var(--foreground);
    font-size: 35px;
  }
  .empty-label {
    position: relative;
    color: var(--foreground);
    font-size: 16px;
  }
`

export default NoContentStyled
