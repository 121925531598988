import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  AddCreditCardModalStyled,
  StripeElements,
} from './styles/add-credit-card-modal'
import { usePluginsPublic } from '_application'
import { useUser } from '_security/session-hooks'
import { useIntl } from 'react-intl'

let card
let stripe

const AddCreditCardModal = ({ onClose, onSuccess }) => {
  const intl = useIntl()

  const { plugin } = usePluginsPublic('stripe')
  const user = useUser()

  const key = plugin?.data?.isLiveMode
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST

  useEffect(() => {
    stripe = window.Stripe(key, { stripeAccount: plugin?.data?.account?.id })
    card = stripe
      .elements()
      .create('card', { style: { base: { fontSize: '16px' } } })
    card.mount('#card-element')

    return () => {
      card && card.unmount()
    }
  }, [plugin, key])

  const handleAddCard = (e) => {
    e.preventDefault()
    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: user?.profile?.email || 'unknown@indyriot.com',
        },
      })
      .then((result) => {
        if (result.error) {
          console.log('err', result.error)
        } else {
          onSuccess(result.paymentMethod)
        }
      })
  }

  return (
    <Modal size="md" show onHide={onClose}>
      <AddCreditCardModalStyled>
        <Modal.Body>
          <h3>
            {intl.formatMessage({
              id: 'user.profile.paymentdetails.modal.title.addnewcard',
            })}
          </h3>
          <form id="add-card-form">
            <StripeElements>
              <div id="card-element" />
              <div id="card-element-errors" role="alert" />
            </StripeElements>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer">
            <button
              className="return-button"
              data-testid="payments-modal-btn-cancel"
              onClick={onClose}
            >
              {intl.formatMessage({
                id: 'user.profile.paymentdetails.modal.button.back',
              })}
            </button>
            <button
              className="confirm-button"
              onClick={handleAddCard}
              data-testid="payments-modal-btn-confirm"
            >
              {intl.formatMessage({
                id: 'user.profile.paymentdetails.modal.button.addnewcard',
              })}
            </button>
          </div>
        </Modal.Footer>
      </AddCreditCardModalStyled>
    </Modal>
  )
}

export default AddCreditCardModal
