import styled from '@emotion/styled'

const ModalStyled = styled.div`
  .consent-modal {
    button {
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      background: linear-gradient(to right, #3e7671, #306a87);
      color: var(--background);
      font-size: 14px;
    }
  }
`
export default ModalStyled
