export default {
  categories: [
    {
      id: 1,
      title:
        'Category title can go over three lines. Maybe we should hyphenate?...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
    {
      id: 2,
      title:
        'Category title can go over three lines. Maybe we should hyphenate?...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
    {
      id: 3,
      title: 'Category name',
      description: 'Long category description',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
    {
      id: 4,
      title: 'Category name',
      description: 'Long category description',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
    {
      id: 5,
      title: 'Category name',
      description: 'Long category description',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
    {
      id: 6,
      title: 'Category name',
      description: 'Long category description',
      link: '#',
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
      commentTopic: 'Topic name',
    },
  ],
  users: [
    // {
    //   avatar: "https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516",
    //   bioShort: "One small step...",
    //   connectionStatus: "accepted",
    //   coverPhoto: "https://storage.googleapis.com/buildtoriot-cdn/cover--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618305301",
    //   firstName: "Stein",
    //   id: "35c82491-9d33-4f93-8f1f-4b507ea0d218",
    //   lastJobPosition: null,
    //   lastName: "Myrseth",
    //   location: { lat: 59.80923170000001, long: 10.2555148, country: 'Norway', city: '', label: 'Tranby, Lier Municipality, Norway' },
    //   middleName: "Username & Password",
    //   sub: "35c82491-9d33-4f93-8f1f-4b507ea0d218"
    // },
    {
      id: 1,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 2,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 3,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 4,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 5,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 6,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 7,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 8,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
    {
      id: 9,
      name: 'User name',
      bio: 'Long user bio',
      link: '#',
      location: 'Location, Area',
      postsCount: 30,
      active: true,
    },
  ],
  posts: [
    {
      id: 1,
      img:
        'https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516',
      link: '#',
      active: true,
      author: 'User Name',
      time: 'Just now',
      topic: 'Topic name',
      text:
        'This is a reply on a topic, it can be as long as it wants, but we only show two lines here for obvious reasons. Can’t go a whole post ...',
    },
    {
      id: 2,
      img:
        'https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516',
      link: '#',
      active: true,
      author: 'User Name',
      time: 'Just now',
      topic: 'Topic name',
      text:
        'This is a reply on a topic, it can be as long as it wants, but we only show two lines here for obvious reasons. Can’t go a whole post ...',
    },
    {
      id: 3,
      img:
        'https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516',
      link: '#',
      active: true,
      author: 'User Name',
      time: 'Just now',
      topic: 'Topic name',
      text:
        'This is a reply on a topic, it can be as long as it wants, but we only show two lines here for obvious reasons. Can’t go a whole post ...',
    },
    {
      id: 4,
      img:
        'https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516',
      link: '#',
      active: true,
      author: 'User Name',
      time: 'Just now',
      topic: 'Topic name',
      text:
        'This is a reply on a topic, it can be as long as it wants, but we only show two lines here for obvious reasons. Can’t go a whole post ...',
    },
    {
      id: 5,
      img:
        'https://storage.googleapis.com/buildtoriot-cdn/avatar--2cde686d-a1b7-41d8-b35c-64c10e37ecbd--dev777?1624618335516',
      active: true,
      author: 'User Name',
      time: 'Just now',
      topic: 'Topic name',
      text:
        'This is a reply on a topic, it can be as long as it wants, but we only show two lines here for obvious reasons. Can’t go a whole post ...',
    },
  ],
  topics: [
    {
      id: 1,
      title:
        'Welcome to a new topic on the forum! The title can be as long as it wants! We don’t show the whole title here but three lines should be enough I hope before the do...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      category: 'Category name',
      author: 'Author Name',
      date: 'Just now',
      votesCount: 134,
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
    },
    {
      id: 2,
      title:
        'Welcome to a new topic on the forum! The title can be as long as it wants! We don’t show the whole title here but three lines should be enough I hope before the do...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      category: 'Category name',
      author: 'Author Name',
      date: 'Just now',
      votesCount: 134,
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
    },
    {
      id: 3,
      title:
        'Welcome to a new topic on the forum! The title can be as long as it wants! We don’t show the whole title here but three lines should be enough I hope before the do...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      category: 'Category name',
      author: 'Author Name',
      date: 'Just now',
      votesCount: 134,
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
    },
    {
      id: 4,
      title:
        'Welcome to a new topic on the forum! The title can be as long as it wants! We don’t show the whole title here but three lines should be enough I hope before the do...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      category: 'Category name',
      author: 'Author Name',
      date: 'Just now',
      votesCount: 134,
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
    },
    {
      id: 5,
      title:
        'Welcome to a new topic on the forum! The title can be as long as it wants! We don’t show the whole title here but three lines should be enough I hope before the do...',
      description:
        'Welcome to a new category on the forum! This is the description of the category and can be 4 lines long before it go...',
      link: '#',
      category: 'Category name',
      author: 'Author Name',
      date: 'Just now',
      votesCount: 134,
      topicsCount: 2,
      postsCount: 11,
      img: '#',
      commentAuthor: 'Author Name',
      commentDate: 'Just now',
      commentText:
        'Welcome to a new category on the forum! This is the description of the category',
    },
  ],
  tags: [
    { id: 1, tag: 'Tag name', postsCount: 36, link: '#' },
    { id: 2, tag: 'Tag name', postsCount: 0, link: '#' },
    { id: 3, tag: 'Tag name', postsCount: 11, link: '#' },
    { id: 4, tag: 'Tag name', postsCount: 347, link: '#' },
    { id: 5, tag: 'Tag name', postsCount: 1003, link: '#' },
    { id: 6, tag: 'Tag name', postsCount: 4, link: '#' },
    { id: 7, tag: 'Tag name', postsCount: 251, link: '#' },
    { id: 8, tag: 'Tag name', postsCount: 5460, link: '#' },
    { id: 9, tag: 'Tag name', postsCount: 2500, link: '#' },
    { id: 10, tag: 'Tag name', postsCount: 5, link: '#' },
    { id: 11, tag: 'Tag name', postsCount: 57, link: '#' },
    { id: 12, tag: 'Tag name', postsCount: 450, link: '#' },
    { id: 13, tag: 'Tag name', postsCount: 100, link: '#' },
    { id: 14, tag: 'Tag name', postsCount: 987, link: '#' },
    { id: 15, tag: 'Tag name', postsCount: 213, link: '#' },
    { id: 16, tag: 'Tag name', postsCount: 32, link: '#' },
    { id: 17, tag: 'Tag name', postsCount: 309, link: '#' },
    { id: 18, tag: 'Tag name', postsCount: 9, link: '#' },
    { id: 19, tag: 'Tag name', postsCount: 0, link: '#' },
    { id: 20, tag: 'Tag name', postsCount: 11002, link: '#' },
  ],
  post: {
    id: 1,
    category: 'Category name',
    tags: [
      { id: 1, tag: 'Tag name', link: '#' },
      { id: 2, tag: 'Tag name', link: '#' },
      { id: 3, tag: 'Tag name', link: '#' },
    ],
    author: 'Author Name',
    date: 'Just now',
    votes: '10k',
    viewsCount: 100,
    commentsCount: 200,
    title: 'Post Title Here',
    content:
      'This is the description of the topic. The text can go as wide as 700px before it breaks onto the second line. It can also be as long as it wants, no need to have any character limit ',
  },
  comment: {
    id: 1,
    author: 'Author Name',
    date: 'Just Now',
    content:
      'This is a post in a thread. The text can go as wide as 700px before it breaks onto the second line. It can also be as long as it wants, no need to have any character limit',
  },
}
