import styled from '@emotion/styled'
import { ctaButtonHollow, ctaButton } from '_utils/css-buttons'

export const StaticInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
`
export const Header = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`
export const Description = styled.div`
  text-align: center;
  font-size: 16px;
`
export const Actions = styled.div`
  width: 100%;
`
export const InputsWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
`

export const CheckboxWrapper = styled.div`
  margin-bottom: 15px;
  input {
    margin-right: 10px;
  }

  .additonal-info {
    margin: 10px 0;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 30px 35px;
`

export const LeftWrapper = styled.div``
export const RightWrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const RegularButton = styled.button`
  ${ctaButtonHollow()}
`
export const PublishButton = styled.button`
  ${ctaButton()}
`

export const PickersWrapper = styled.div`
  display: flex;
  .date-picker {
    margin-right: 40px;

    .react-date-picker__inputGroup__input {
      color: var(--foreground) !important;
    }

    .react-calendar__tile--active {
      background-color: var(--flavor) !important;
    }

    .react-date-picker {
      height: 35px !important;
      box-shadow: 0px 3px 8px rgb(0 0 0 / 16%) !important;
    }
    .react-date-picker__wrapper {
      border: none !important;
    }
  }
`
