import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
// import { useIntl } from 'react-intl'
import { GET_COMPANY, GET_OPPORTUNITY, GET_ACTIVITY } from '../shared/helpers'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const resolveEntityType = (type) => {
  switch (type) {
    case 'activity':
      return 'activities'
    case 'opportunity':
      return 'opportunities'
    case 'company':
      return 'companies'
    default:
      return 'entity'
  }
}

const Activity = ({ item, acceptAction, declineAction }) => {
  // const intl = useIntl()
  const mapQuery = {
    company: GET_COMPANY,
    opportunity: GET_OPPORTUNITY,
    activity: GET_ACTIVITY,
  }

  const requester = {
    type: item?.notification?.payload[0].key,
    id: item?.notification?.payload[0].value,
  }
  const receiver = {
    type: item?.notification?.payload[1].key,
    id: item?.notification?.payload[1].value,
  }

  const { data: dataRequester, loading: loadingRequester } = useQuery(
    mapQuery[requester.type],
    {
      variables: {
        id: requester.id,
      },
    }
  )
  const { data: dataReceiver, loading: loadingReceiver } = useQuery(
    mapQuery[receiver.type],
    {
      variables: {
        id: receiver.id,
      },
    }
  )

  const ContentComponent = () => {
    const intl = useIntl()
    const appLabel = useAppLabel()
    return (
      <span>
        {intl.formatMessage(
          { id: 'notifications.e2econnection.sender' },
          {
            entity: appLabel(
              resolveEntityType(requester.type),
              `${resolveEntityType(requester.type)}.title`
            ).toLowerCase(),
          }
        )}{' '}
        <Link to={`/${resolveEntityType(requester.type)}/list/${requester.id}`}>
          {dataRequester
            ? dataRequester[`get${capitalizeFirstLetter(requester.type)}`]?.name
            : ''}
        </Link>{' '}
        {intl.formatMessage(
          { id: 'notifications.e2econnection.receiver' },
          {
            entity: appLabel(
              resolveEntityType(receiver.type),
              `${resolveEntityType(receiver.type)}.title`
            ).toLowerCase(),
          }
        )}{' '}
        <Link to={`/${resolveEntityType(receiver.type)}/list/${receiver.id}`}>
          {dataReceiver
            ? dataReceiver[`get${capitalizeFirstLetter(receiver.type)}`]?.name
            : ''}
        </Link>
        {'.'}
      </span>
    )
  }

  if (loadingRequester || loadingReceiver || !dataReceiver || !dataRequester)
    return null

  return (
    <BaseNotification
      item={item}
      acceptAction={acceptAction}
      declineAction={declineAction}
      ContentComponent={ContentComponent}
    />
  )
}

export default Activity
