const actions = {
  READ_CONVERSATION_LAST_MESSAGE: 'messages/READ_CONVERSATION_LAST_MESSAGE',
  SET_CURRENT_CONVERSATION: 'messages/SET_CURRENT_CONVERSATION',
  SET_CURRENT_CONVERSATION_MESSAGES:
    'messages/SET_CURRENT_CONVERSATION_MESSAGES',
  SET_CONVERSATIONS: 'messages/SET_CONVERSATIONS',
}

export const setCurrentConversation = (conversationDetails) => ({
  type: actions.SET_CURRENT_CONVERSATION,
  payload: conversationDetails,
})

export const setCurrentConversationMessages = (messages, contextKey) => ({
  type: actions.SET_CURRENT_CONVERSATION_MESSAGES,
  payload: { messages, contextKey },
})

export const setConversations = (conversations) => ({
  type: actions.SET_CONVERSATIONS,
  payload: conversations,
})

export const readConversationMessage = (messageId) => ({
  type: actions.READ_CONVERSATION_LAST_MESSAGE,
  payload: { messageId },
})

export default actions
