import React from 'react'
import Selector from 'components/indy/shared/ui-kit/selectors/multi-select-with-icon'
import {
  KeywordContainer,
  KeywordDescription,
  KeywordTitle,
  SelectorWrapper,
} from './styles/keywords'
import { useKeywords } from '../../_keywords-and-taxonomies'
import BreakLine from './break-line'

const Keywords = ({ keywords = [], entityType, changeFormProperty }) => {
  const { keywords: gqlKeywords } = useKeywords(entityType)

  try {
    const handleKeywordsUpdate = (key, ids) => {
      let keyExists
      const mapped = keywords.map((k) => {
        if (k?.key === key) {
          keyExists = true
          return { key, values: ids.map((id) => ({ value: id })) }
        }
        return k
      })
      if (!keyExists) {
        mapped.push({ key, values: ids.map((id) => ({ value: id })) })
      }
      changeFormProperty({ keywords: mapped })
    }
    if (!gqlKeywords) return <></>
    return (
      <>
        {gqlKeywords.map((gqlKeyword) => {
          const selectedItem = keywords.find((k) => k.key === gqlKeyword.key)
          return (
            <>
              <KeywordContainer>
                <KeywordTitle>{gqlKeyword.name}</KeywordTitle>
                <KeywordDescription>
                  {gqlKeyword.description}
                </KeywordDescription>
                <SelectorWrapper>
                  <Selector
                    items={
                      gqlKeyword.keywordList?.map((listItem) => ({
                        name: listItem.value,
                        key: listItem.id,
                      })) || []
                    }
                    selectedValues={selectedItem?.values?.map((v) => v.value)}
                    onSelect={(values) =>
                      handleKeywordsUpdate(gqlKeyword.key, values)
                    }
                  />
                </SelectorWrapper>
              </KeywordContainer>
              <BreakLine />
            </>
          )
        })}
      </>
    )
  } catch (e) {
    console.error(e)
    return null
  }
}

export default Keywords

