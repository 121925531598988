import { gql } from '@apollo/client'

export const GET_APPLICATION = gql`
  query GetApplication($applicationId: String, $opportunityId: String) {
    getApplication(
      applicationId: $applicationId
      opportunityId: $opportunityId
    ) {
      _id
      state
      category
      dateCreated
      coverLetter
      applicantChatChannelId
      isApplicationManager
      parentId
      attachments {
        url
        name
      }
      creator {
        id
        sub
        firstName
        middleName
        lastName
        avatar
        location
      }
    }
  }
`

export const DELETE_APPLICATION = gql`
  mutation DeleteApplication($applicationId: String) {
    deleteApplication(applicationId: $applicationId) {
      _id
    }
  }
`
