import { useMutation, gql } from '@apollo/client'

const EXPORT_USERS = gql`
  mutation ExportUsers(
    $fields: [JSONObject]
    $sorting: String
    $context: String
  ) {
    exportUsers(fields: $fields, sorting: $sorting, context: $context)
  }
`

export const useUserExport = () => {
  const [exportUsers, { loading, data }] = useMutation(EXPORT_USERS)

  return { exportUsers, loading, data }
}
