import React, { useState } from 'react'
import { values } from 'lodash'
import { useMutation } from '@apollo/client'
//import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon'
import {
  SelectOption,
  SelectVariant,
  SelectDirection,
} from '@patternfly/react-core'
import { SAVE_APPLICATION } from '../opportunity-applications-management/gql'
import { GET_APPLICATION } from '../opportunity-application-detail/gql'
import applicationStates from './applicationStates'
import { useIntl } from 'react-intl'
import { StyledSelect } from '../styles'

const intlKey = 'application.management.'

const ApplicationStateSelect = ({ application, className }) => {
  const intl = useIntl()

  const options = values(applicationStates).map((state) => (
    <SelectOption key={state.text} value={state.text}>
      {intl.formatMessage({
        id: `${intlKey}state.${state.value?.split(' ').join('')}`,
      })}
    </SelectOption>
  ))

  const [isOpen, setOpen] = useState(false)

  const [saveApplication] = useMutation(SAVE_APPLICATION)

  const onSelect = (e, selection) => {
    setOpen(false)
    saveApplication({
      variables: {
        application: { _id: application._id, state: selection },
      },
      update: cache =>
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getApplicationsPaged' }),

      refetchQueries: [
        {
          query: GET_APPLICATION,
          variables: {
            applicationId: application?._id,
            opportunityId: application?.parentId,
          },
        },
      ],
    })
  }

  const titleId = 'title-id-1'
  return (
    <div>
      <span id={titleId} hidden>
        Title
      </span>
      <StyledSelect
        className={className}
        isDetailView
        //toggleIcon={<CubeIcon />}
        variant={SelectVariant.single}
        aria-label="Select Input"
        onToggle={setOpen}
        onSelect={onSelect}
        selections={application.state}
        isOpen={isOpen}
        aria-labelledby={titleId}
        direction={SelectDirection.down}
      >
        {options}
      </StyledSelect>
    </div>
  )
}

export default ApplicationStateSelect
