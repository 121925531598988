import styled from '@emotion/styled'

const Style = styled.div`
  padding: 15px 25px;
  .modal-title {
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    color: var(--foreground);
  }
  p {
    color: var(--foreground);
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    span {
      padding-right: 5px;
      font-size: 16px;
      color: var(--foreground);
      font-weight: 600;
    }
  }
  .modal-title,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal-footer {
    padding: 15px;
    margin-top: 20px;
  }
`

export default Style
