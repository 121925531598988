import React, { useState, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Layout from '../../components/layout'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '_components/loading'
import { useUser } from '_security/session-hooks'
import { useAppConfig, useAppLabel } from '_application'
import { MbToB } from 'services/file'
import { GET_COMPANY, SAVE_COMPANY } from './gql'
import { getFormDataFields } from './helpers'
import ScrollToTop from '_components/scroll-to-top'

import GeneralInformation from './components/general-information'
import AdditionalInformation from './components/additional-information'
import { useIntl } from 'react-intl'

export const CompanyForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    coverPhoto: null,
    description: '',
    body: '',
    bannerImage: null,
    publishedAt: null,
    founded: null,
    homepage: null,
    location: {
      city: '',
      country: '',
      label: '',
      lat: '',
      long: '',
    },
  })

  const { id } = useParams()
  const { sub } = useUser()
  const { pathname } = useLocation()

  const appLabel = useAppLabel()
  const intl = useIntl()

  const { tenantId } = useAppConfig()

  const tagsConfig = {
    userId: sub,
    tenantId: tenantId,
    context: 'content-studio-company-banner-image',
    mediaId: 'companyID',
  }

  const uploadConfig = {
    folderName: 'content-studio',
    tagsConfig,
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const [saveCompany] = useMutation(SAVE_COMPANY)

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: {
      id,
    },
    skip: !id,
  })

  const entity = data?.getCompany

  const onSubmit = () => {
    saveCompany({
      variables: {
        input: formData,
      },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAllCompaniesPublic' })
        cache.evict({ id: `Company:{"id":"${id}"}` })
      },
    })
  }

  const changeFormProperty = (change) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...change,
      }
    })
  }

  useMemo(async () => {
    if (!id) {
      const entity = await saveCompany()
      setFormData({ ...formData, id: entity?.data?.saveCompany?.id })
    }
    if (data) setFormData(getFormDataFields(data.getCompany))
  }, [data])

  if (id && loading) return <Loading /> // todo spinner comp
  if (error) return `Error! ${error}`

  const resolvedTitle =
    pathname?.split('/')?.pop()?.toLowerCase() === 'edit' ? 'edit' : 'new'

  const resolvedEntityName = appLabel('companies', 'companies.title')

  const title = intl.formatMessage(
    {
      id: 'contentstudiot.pagetitle',
      defaultMessage: `${
        resolvedTitle.charAt(0).toUpperCase() + resolvedTitle.slice(1)
      } ${resolvedEntityName.toLowerCase()}`,
    },
    {
      entity: resolvedEntityName.toLowerCase(),
    }
  )

  const isSubmitDisabled =
    formData?.name.length === 0 ||
    formData?.description.length === 0 ||
    formData?.body === '<p><br></p>' ||
    !formData?.coverPhoto ||
    !formData?.bannerImage

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={isSubmitDisabled}>
      <ScrollToTop />
      <h1>{title}</h1>
      <GeneralInformation
        formData={formData}
        changeFormProperty={changeFormProperty}
        uploadConfig={uploadConfig}
        entity={entity}
      />
      <AdditionalInformation
        formData={formData}
        changeFormProperty={changeFormProperty}
        uploadConfig={uploadConfig}
        entity={entity}
      />
    </Layout>
  )
}

export default CompanyForm

