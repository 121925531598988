import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { useIntl } from 'react-intl'
import SectionRenderer from '../shared/section-renderer'

const mapStateToProps = ({ user }) => ({ user })

const Account = ({ user, dispatch }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [errors, setErrors] = useState({})
  const intl = useIntl()

  const handleSaveGeneric = (key, value) => {
    const { profile } = user
    profile[key] = value
    saveProfile(profile)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const sectionTitle = intl.formatMessage({
    id: 'user.profile.accountmanagement',
  })

  const sections = [
    {
      key: 'firstName',
      name: intl.formatMessage({
        id: 'user.profile.generalinformation.firstname',
      }),
      type: 'text',
      onError: (attr, message) => setErrors({ ...errors, firstName: message }),
      onSave: (attr, v) => handleSaveGeneric('firstName', v),
      md: 4,
      required: true,
    },
    {
      key: 'middleName',
      name: intl.formatMessage({
        id: 'user.profile.generalinformation.middlename',
      }),
      type: 'text',
      onError: (attr, message) => setErrors({ ...errors, middleName: message }),
      onSave: (attr, v) => handleSaveGeneric('middleName', v),
      md: 4,
    },
    {
      key: 'lastName',
      name: intl.formatMessage({
        id: 'user.profile.generalinformation.lastname',
      }),
      type: 'text',
      onError: (attr, message) => setErrors({ ...errors, lastName: message }),
      onSave: (attr, v) => handleSaveGeneric('lastName', v),
      md: 4,
      required: true,
    },
    {
      key: 'email',
      name: intl.formatMessage({
        id: 'user.profile.personaldetails.email',
      }),
      type: 'emailAccount',
      onSave: (attr, v) => handleSaveGeneric('email', v),
      onError: (attr, message) => setErrors({ ...errors, email: message }),
      errors: errors['email'],
      required: false,
      md: 12,
    },
    {
      key: 'phone',
      name: intl.formatMessage({
        id: 'user.profile.personaldetails.phone',
      }),
      type: 'phone',
      onError: (attr, message) => setErrors({ ...errors, phone: message }),
      onSave: (attr, v) => handleSaveGeneric('phone', v),
    },

    {
      key: 'birthDate',
      name: intl.formatMessage({
        id: 'user.profile.personaldetails.birthdate',
      }),
      type: 'date',
      onSave: (attr, v) => handleSaveGeneric('birthDate', v),
      minDate: moment().subtract(80, 'years'),
      maxDate: moment().subtract(14, 'years'),
      required: true,
    },
    {
      key: 'location',
      name: intl.formatMessage({
        id: 'user.profile.personaldetails.location',
      }),
      type: 'location',
      onSave: (attr, v) => handleSaveGeneric('location', v),
    },
    {
      key: 'password',
      name: intl.formatMessage({
        id: 'user.profile.personaldetails.password',
      }),
      type: 'password',
      onSave: (attr, v) => handleSaveGeneric('password', v),
      onError: (attr, message) => setErrors({ ...errors, password: message }),
    },
  ]

  return (
    <>
      <SectionRenderer
        sectionTitle={sectionTitle}
        section={sections || []}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        user={user}
        errors={errors}
      />
    </>
  )
}

export default connect(mapStateToProps)(Account)
