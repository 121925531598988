import React from 'react'
// import { useIntl } from 'react-intl'
import { Flex, Grid } from '@patternfly/react-core'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import { SingleTag } from './styles/tags'
import {
  PageWrapper,
  SectionHeader,
  FilterWrapper,
  // FilterButton,
} from './styles/tags'
import { Loader } from '_components/loading'

const GET_TAGS = gql`
  {
    getTags {
      tags {
        tagsList {
          tag
          value
          score
        }
      }
    }
  }
`

const gridConfig = {
  xl2: 2,
  xl: 2,
  lg: 3,
  md: 3,
  sm: 4,
}

const Tags = () => {
  // const intl = useIntl()
  const { data } = useQuery(GET_TAGS)

  if (!data) return <Loader loading={!data} />
  return (
    <PageWrapper>
      <SectionHeader>
        <Flex>
          <FilterWrapper align={{ default: 'alignRight' }}>
            {/* <FilterButton>
              {intl.formatMessage({ id: 'forum.header.sortbutton.bytime' })}
            </FilterButton>
            <FilterButton>
              {intl.formatMessage({ id: 'forum.header.sortbutton.bytopics' })}
            </FilterButton>
            <FilterButton>
              {intl.formatMessage({
                id: 'forum.header.sortbutton.bycategories',
              })}
            </FilterButton> */}
          </FilterWrapper>
        </Flex>
      </SectionHeader>
      <Grid hasGutter {...gridConfig}>
        {/* <GridItem> */}
        {data?.getTags?.tags?.tagsList.map(function (object, i) {
          return (
            <>
              {object.score !== 0 && (
                <SingleTag>
                  <p>
                    <Link
                      to={`/forum/tag/${object.value}`}
                      data-testid={`tag-${object?.value}`}
                    >
                      <i className="fas fa-tag" />

                      {object.value}
                    </Link>
                    <span>{object.score}</span>
                  </p>
                </SingleTag>
              )}
            </>
          )
        })}
        {/* </GridItem> */}
      </Grid>
    </PageWrapper>
  )
}

export default Tags
