import React, { useState } from 'react'
import { useKeywords } from '../hooks'
import ReactTooltip from 'react-tooltip'

import { TagList } from '_components/drilldown'
import ShowMore from './show-more'
import { useIntl } from 'react-intl'

import styled from '@emotion/styled'

const Content = styled.div`
  h5 {
    margin-bottom: 2.5px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-weight: 600;
    font-size: 20px;
    color: var(--foreground);
  }
  .hide {
    display: none;
  }
`

const defaultShown = 2

const KeywordsList = ({ keywords, show }) => {
  return (
    <>
      {keywords.map((k, i) => {
        if (k && k?.values?.length > 0 && k?.values?.some((v) => v?.value))
          return (
            <div
              data-for="keywords-tooltip"
              className={!show && i > defaultShown - 1 ? 'hide' : ''}
              key={i}
              data-tip={k.description}
            >
              <TagList title={k.name} values={k.values.map((v) => v.value)} />
              <ReactTooltip effect="solid" id="keywords-tooltip" />
            </div>
          )
        return null
      })}
    </>
  )
}

const Keywords = ({
  type,
  values,
  excludeKeys,
  noMainTitle,
  RenderOverride,
}) => {
  const [show, setShow] = useState()
  const { keywords } = useKeywords(type)
  const intl = useIntl()

  const keywordsText = intl.formatMessage({
    id: 'tile.details.keywords',
  })
  const seeMoreText = intl.formatMessage({
    id: 'default.seemore.tag',
  })

  let keywordList = []

  if (keywords && values) {
    keywordList = values
      .map((k, i) => {
        if (excludeKeys && excludeKeys.some((ek) => ek === k.key)) return null
        const keyword = keywords.filter((kw) => kw.key === k.key)[0]

        if (k?.values && keyword && k?.values?.length > 0)
          return {
            name: keyword.name,
            values: k.values.map((v) => {
              return {
                ...v,
                value: keyword?.keywordList?.find((kk) => kk.id === v.value)
                  ?.value,
              }
            }),
            description: keyword.description,
          }
        return null
      })
      .filter((k) => k)
  }

  if (keywordList.length <= 0) return null
  if (RenderOverride) {
    return <RenderOverride keywordList={keywordList} /> //renderOverride({ keywordList, state: collapsedKeywordsState })
  }

  return (
    <Content>
      {!noMainTitle && <h5>{keywordsText}</h5>}
      <KeywordsList type={type} keywords={keywordList} show={show} />
      {keywordList?.length > defaultShown && (
        <ShowMore
          text={`${seeMoreText} +${keywordList.length - defaultShown}`}
          show={show}
          setShow={setShow}
        />
      )}
    </Content>
  )
}

export default Keywords
