import styled from '@emotion/styled'

export const Container = styled.div`
  width: 300px;
  height: 300px;
  padding: 10px;
  align-items: center;
  position: relative;
`
export const Logo = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`
