import css from '@emotion/css'
import React from 'react'
import ApplicationListSelect from '../shared/application-list-select'
import { ListItemStyled } from '../styles'
import ApplicantInfo from '../shared/applicantInfo'
import { useIntl } from 'react-intl'
import { StyledLink } from './styled/application-list-item'

const intlKey = 'application.management.'

function OpportunityApplicationsListItem({
  // onItemCheckChange,
  //isChecked,
  application,
  variables,
}) {
  const intl = useIntl()

  const { parentId, _id } = application

  return (
    <ListItemStyled>
      {/*
      <Checkbox
        id="listItemCheckbox"
        isChecked={isChecked}
        onChange={() => onItemCheckChange(application)}
        css={css`
           {
            margin-right: 5px;
          }
        `}
      ></Checkbox>
		*/}
      <div>
        <ApplicantInfo application={application} />
      </div>
      <div
        css={css`
          flex-grow: 1;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 15px;
        `}
      >
        <ApplicationListSelect
          application={application}
          variables={variables}
        ></ApplicationListSelect>
        <StyledLink
          to={`/opportunities/${parentId}/applications/detail/${_id}`}
          data-testid={`link-application-${_id}-viewApplication`}
        >
          {intl.formatMessage({ id: `${intlKey}viewapplication` })}
        </StyledLink>
      </div>
    </ListItemStyled>
  )
}

export default OpportunityApplicationsListItem
