import styled from '@emotion/styled'

export const FullBackgroundTileStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '217px')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.5) 34%,
      rgba(0, 0, 0, 0) 82%
    ),
    ${({ coverPhoto, theme }) =>
      `url(${coverPhoto || theme?.images?.defaultCover})`};

  border-radius: 25px;
  padding: 20px;
  &.large {
    min-height: 400px;
  }
  button {
    align-self: center;
  }
`

export const Location = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--imgColor);
  margin-bottom: 5px;
  svg {
    margin-right: 6px;
    path {
      stroke: var(--imgColor);
    }
  }
`

export const InfoWrapper = styled.div`
  flex-basis: 80%;
`

export const ConnectionsWrapper = styled.div`
  flex-basis: 20%;
`

export const Name = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--imgColor);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const TopContainer = styled.div`
  display: flex;

`
