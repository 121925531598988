import styled from '@emotion/styled'

export const RequestAccessStyled = styled.div`
  .access-page {
    position: relative;
    z-index: 3;
    max-width: 400px;
    display: block;
    margin: 0 auto;
    margin-top: 200px;
    .inner-sign-up-form {
      background-color: var(--background);
      border-radius: 20px;
      padding: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      .lock-icon {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        padding: 5px;
        margin: 0 auto;
        position: relative;
        z-index: 5;
        background-color: white;
        margin-top: -70px;
        box-shadow: 0px 0px 7.5px #cbcbcb;
        .icon {
          width: 45px;
          height: 45px;
          object-fit: contain;
          display: block;
          margin: 0 auto;
          margin-top: 5px;
        }
      }
      .content-container {
        max-width: 275px;
        margin: 0 auto;
        .form-title {
          color: var(--foreground);
          font-family: ${({ theme }) => theme.fonts.googleFont};
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          margin-top: 25px;
        }
        .form-description {
          color: var(--foreground);
          font-family: ${({ theme }) => theme.fonts.googleFont};
          font-size: 14px;
          max-width: 250px;
          text-align: center;
          margin: 0 auto;
          margin-top: 20px;
        }
        .submit-container {
          margin-top: 10px;
          .code-input {
            text-align: center;
            margin: 0 auto;
            display: block;
            margin-top: 25px;
            width: 100%;
            border: none;
            border-radius: 4px;
            height: 40px;
            font-family: ${({ theme }) => theme.fonts.googleFont};
            color: var(--foreground);
            font-size: 14px;
            outline: none;
            &::placerholder {
              color: var(--foreground);
            }
          }
          .enter-button {
            width: 100%;
            margin-top: 10px;
            display: block;
            position: relative;
            border: none;
            background-color: var(--flavor);
            border: 2px solid var(--flavor);
            font-family: ${({ theme }) => theme.fonts.googleFont};
            color: var(--flavor-contrast);
            border-radius: 8px;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14px;
            text-align: center;
            margin-top: 20px !important;
            font-weight: 500;
            transition: 0.5s;
            &:hover {
              color: var(--foreground);
              background-color: transparent;
            }
          }
        }
        .request-button {
          background-color: transparent;
          color: var(--foreground);
          font-family: ${({ theme }) => theme.fonts.googleFont};
          border: 2px solid var(--flavor);
          border-radius: 8px;
          width: 100%;
          margin-top: 10px;
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 14px;
          text-align: center;
          margin-top: 20px;
          font-weight: 500;
          transition: 0.5s;
          &:hover {
            background-color: var(--flavor);
            color: var(--flavor-contrast);
          }
        }
      }
    }
    .background {
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 100%;
      min-width: 100% !important;
      height: 100%;
      z-index: -1;
      background-size: cover;
      background-position: center;
      .overlay {
        z-index: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: var(--foreground);
        top: 0px;
        left: 0px;
        opacity: 0.17;
      }
    }
  }
`
