import React from 'react'
import { useTheme } from 'emotion-theming'

export const themable = (name, propTypes) => {
  const ThemableComponent = props => {
    const { components } = useTheme()
    const Component = components[name]
    return <Component {...props} />
  }
  ThemableComponent.propTypes = propTypes

  return ThemableComponent
}

export default themable


