import React, { useState } from 'react'
import { useAppConfig } from '_application'
import { UPDATE_META_DATA } from './graph-queries'
import { useMutation } from '@apollo/client'

import { SubSection, TextAreaStyled } from '../../shared/styles/sections'

// import { useIntl } from 'react-intl'

const TextareaShared = ({ type, title, description }) => {
  const config = useAppConfig()
  const meta = config?.metaData ? config?.metaData[type] : null

  const [textarea, setTextarea] = useState(meta)

  const [updateMetaData] = useMutation(UPDATE_META_DATA)

  const handleUpdateLabel = (v) => {
    updateMetaData({
      variables: { metaData: { [type]: v } },
    })
  }

  const checkValue = (value) => {
    return value && value !== 'undefined' ? value : null
  }

  return (
    <SubSection>
      <span className="title">{checkValue(title)}</span>
      <span className="description">{checkValue(description)}</span>
      <TextAreaStyled
        rows={3}
        onChange={(e) => setTextarea(e?.target?.value)}
        onBlur={(v) => handleUpdateLabel(v.target.value)}
        value={textarea}
        placeholder={`Provide ${checkValue(title)}`}
      />
    </SubSection>
  )
}

export default TextareaShared
