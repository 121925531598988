import React, { useState } from 'react'
import { useAppConfig } from '_application'
import { UPDATE_LABEL } from './graph-queries'
import { useMutation } from '@apollo/client'
import { GET_CONFIG } from '_application'

import Input from 'components/indy/shared/ui-kit/inputs/text'
import { SubSection } from '../../../shared/styles/sections'
import { InputCustomStyled } from './styles/input-custom'

const InputCustom = ({ type, title, description, limit, isMultiline }) => {
  const config = useAppConfig()

  const [label, setLabel] = useState(
    config?.labelOverrides?.[type]?.value || ''
  )

  const override = config?.labelOverrides?.[type]?.value

  const [isEdit, setIsEdit] = useState(!label)

  const [updateLabel] = useMutation(UPDATE_LABEL)

  const handleUpdateLabel = (v) => {
    updateLabel({
      variables: { type, label: { key: type, value: v } },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
    setLabel(v)
  }
  return (
    <SubSection>
      <span className="title">{title}</span>
      {description && <span className="description">{description}</span>}
      <InputCustomStyled>
        <div className="input">
          {(isEdit || !override) && (
            <Input
              input={{
                placeholder: `Override ${title}`,
                value: label,
                maxLength: limit,
                isMultiline,
              }}
              onChangeCallback={(v) => setLabel(v)}
              limit={limit}
            />
          )}
          {!isEdit && override && <span className="display">{override}</span>}
        </div>
        <div className="controls">
          {!isEdit && override && (
            <>
              <i
                onClick={() => {
                  handleUpdateLabel('')
                  setIsEdit(true)
                }}
                className="fas fa-trash-alt"
              />
              <i onClick={() => setIsEdit(true)} className="fas fa-pen" />
            </>
          )}
          {(isEdit || !override) && label?.length > 0 && (
            <>
              <i
                onClick={() => {
                  handleUpdateLabel(label)
                  setIsEdit(false)
                }}
                className="fas fa-check"
              />
              {override && (
                <i onClick={() => setIsEdit(false)} className="fas fa-times" />
              )}
            </>
          )}
        </div>
      </InputCustomStyled>
    </SubSection>
  )
}

export default InputCustom
