import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile } from '_utils/responsive-queries'


export const FooterStyles = styled.div`
  .inner-frontpage-footer-container {
    background-color: var(--background);
  }

  font-size: 16px;
  color: var(--foreground);
  background: var(--background);
  width: 100%;
  margin-top: auto;
  
  img {
    display: block;
    margin: 0 auto;
    width: 45px;
    max-width: 60px;
    max-height: 60px;
    object-fit: contain;
  }

  img.fr-rounded,
  .fr-img-caption.fr-rounded img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box
  }
  
  .logo-col {
    max-width: 225px;
    margin-right: 100px;

    .info-description {
      div {
        img {
          max-width: 120px;
          max-height: 80px;
          object-fit: cover !important;
        }
        a {
          margin-top: 15px;
          img {
            display: initial;
            width: 32px;
            object-fit: contain;
          }
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
      width: 45px;
      object-fit: contain;
    }
    a {
      display: inline-block;
      width: 32px;
      text-align: center;
      color: var(--foreground);
      img {
        display: inline;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
  
  .info-col {
    margin-right: 100px;
    h2 {
      font-weight: 600;
      text-transform: uppercase;
      color: var(--foreground);
      font-size: 16px;
    }
    p {
      margin-top: 5px;
      color: var(--foreground);
      font-size: 16px;
    }
  }
  .links-col {
    margin-right: 100px;
    max-width: 200px;
    .links-title {
      font-weight: 600;
      text-transform: uppercase;
      color: var(--foreground);
      font-size: 16px;
    }
    .links-container {
      margin-top: 10px;
      a {
        display: block;
        color: var(--foreground);
        font-size: 16px;
      }
    }
    &.last {
      margin-right: 0px;
    }
  }

  .frontpage-footer-section {
    background-color: var(--foreground);
    width: 100%;
    border-top: solid 1px var(--foreground);
    .inner-frontpage-footer-container {
      .footer-row {
        display: flex;
        justify-content: space-space-between;
        max-width: 1400px;
        padding: 40px;
        margin: 0 auto;
      }
    }
    @media only screen and (max-width: 1024px) {
      .footer-row {
        flex-direction: column;
        .about-col,
        .links-col,
        .info-col,
        .logo-col,
        .info-description {
          min-width: 100%;
          p {
            text-align: center;
          }

          h3.info-title,
          h3.links-title {
            text-align: center;
          }
          .links-container,
          .about-container {
            text-align: center;
          }
        }
      }
    }
  }
  .cookie-consent-section {
    position: fixed;
    bottom: 16px;
    width: 60em;
    max-width: 90vw;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2147483004;

    .inner-cookie-consent-container {
      ${border()}

      border-radius: 10px;
      width: 100%;
      background-color: var(--background);
      padding: 25px;
      transition: 0.5s;

      ${mobile} {
        .row {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .content-col {
        .content-container {
          .cookies-title {
            font-size: 16px;
            color: var(--foreground);
            font-weight: 600;
          }
          .cookies-description {
            margin-top: 10px;
            font-size: 14px;
            color: var(--foreground);
            font-weight: 400 !important;

            a {
              color: var(--flavor);
              text-decoration: underline;
            }
          }
        }
      }

      .button-col {
        max-width: 140px;

        .accept-button {
          padding: 10px 25px;
          font-weight: 400;
          font-size: 16px;
          background-color: var(--flavor);
          color: var(--flavor-contrast);
          border: 2px solid transparent;
          margin-right: 10px;
          width: 100%;
        }
      }
    }
  }
`
