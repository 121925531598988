import React, { useEffect, useCallback } from 'react'
import { Loader, Loading } from '_components/loading'
import styled from '@emotion/styled'
import { gql, useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import SearchResults from '../components/search-results'
import { Container } from '../../_styled-components/shared/section-container'

// My creations is displayed on the users profile

const LoadingIndicator = styled(Loading)`
  margin: 50px 0;
`

const GROUPED_SEARCH_QUERY = gql`
  query GroupedSearch($searchInput: SearchInputGrouped) {
    groupedSearch(searchInput: $searchInput)
    { data {
      _id,
      total
      data {
        _id
        body
        type
        title
        excerpt
        creator {
          _id
          avatar
          name
          firstName
          lastName
          location {
            city
            country
          }
        }
        coverPhoto
        score
      }
    } },
  }
`


const MyCreationsConnected = ({
  className,
  isMyCreations,
  creatorId,
  pageHeight,
}) => {
  const [getSearchResults, { data, loading }] = useLazyQuery(GROUPED_SEARCH_QUERY)
  const debouncer = useCallback(debounce(getSearchResults, 500), []);

  useEffect(() => {
    debouncer({ variables: { searchInput: { myCreations: isMyCreations, creatorId, paging: { limit: 50 } } } })
  }, [debouncer, creatorId, isMyCreations])

  return (
    <Container
      pageHeight={pageHeight}
      className={`page-wrapper ${isMyCreations ? 'my-creations' : ''}`}
    >
      <div className={className}>
        <Loader
          loading={loading}
          placeholder={<LoadingIndicator />}
        >
          {() => (data?.groupedSearch?.data?.length > 0 && (
            <SearchResults
              groups={data?.groupedSearch?.data}
            />
          )
          )}
        </Loader>
      </div>
    </Container>
  )
}

export default MyCreationsConnected
