import { useEffect } from 'react'

const ScrollToTop = ({ checkPathname }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null 
}

ScrollToTop.propTypes = {}

export default ScrollToTop
