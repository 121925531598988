import styled from '@emotion/styled'

export const EditInfoWrapper = styled.div`
  width: 100%;
  margin-top: 8px;

  .label {
    width: fit-content;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 2px 10px;
    color: #757575;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 6px;
  }
`
