import React from 'react'
import LoginTitleThemed from '_uikit/login-title'

const Index = ({ children }) => {
  return (
    <LoginTitleThemed className="login-title-wrapper">
      <h1 className="login-title">{children}</h1>
    </LoginTitleThemed>
  )
}

export default Index
