import { css } from '@emotion/core'

const sharedButton = (props) => css`
  color: var(--fg);
  transition: 0.2s ease-in-out;

  &:active:not([disabled]) {
    opacity: 0.6;
    transition: 0.1s ease-in-out;
  }

  svg path,
  p,
  i {
    fill: var(--fg) !important;
    color: var(--fg) !important;
    stroke: var(--fg) !important;
  }

  svg.connected-icon,
  svg.message-icon,
  svg.pending-icon {
    path {
      stroke: none !important;
    }
  }

  &[disabled] {
    opacity: 0.6;
    background: var(--shade) !important;
    border: none;
  }
`

export const ctaAnim = (props) => css`
  --fg: var(--flavor-contrast);
  background-color: var(--flavor);

  &:hover:not([disabled]),
  &.active {
    background: transparent !important;
    --fg: var(--foreground);
  }

  ${sharedButton(props)}
`

export const ctaAnimHollow = (props) => css`
  --fg: var(--foreground);
  &:not([disabled]) {
    background: transparent !important;
  }

  &:hover:not([disabled]),
  &.active {
    background: var(--flavor) !important;
    --fg: var(--flavor-contrast);
    color: var(--flavor-contrast);
  }

  ${sharedButton(props)}
`

export const taggAnim = css``
