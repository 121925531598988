import styled from '@emotion/styled'

export const SubsectionHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
export const SubsectionDescription = styled.p`
  margin-bottom: 30px;
  font-weight: 500;
`
export const SubsectionTitle = styled.h3`
  font-weight: 600;
  margin: 25px 0 15px;
`
