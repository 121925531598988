import Route from './people-routes'
import Tile from './components/person-tile'
import Details from './connected/person-details-connected'

export { Route, Tile, Details }
export default {
  Route,
  Tile,
  Details,
}
