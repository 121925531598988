import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

const GET_CUSTOM_ATTRS = gql`
  query {
    getAttributes {
      key
      name

      description
      dateCreated
      dateUpdated
      type
      usedBy
      badges {
        key
        name
        icon
      }
    }
  }
`

export const useCustomAttributes = (type) => {
  const { data, loading, error } = useQuery(GET_CUSTOM_ATTRS)

  const attributes = useMemo(
    () => data?.getAttributes.filter((att) => att?.usedBy.includes(type)),
    [data, type]
  )
  return { loading, error, attributes }
}
