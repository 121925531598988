import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import ClickAway from '_components/click-away'
import Appear from '_components/appear'
import SearchInput from '../components/search-input'
import SearchDropdown from '../components/search-dropdown'
import { useSearch } from '_search/search-hooks'

const DropdownContainer = styled(Appear)`
  position: absolute;
  margin-top: 10px;
  left: 0;
  right: 0;
`

const Container = styled(ClickAway)`
  position: relative;
  max-width: 600px;
  display: block;
`

const GlobalSearch = ({ children, onClose, className, searching }) => {
  const intl = useIntl()
  const [query, setQuery] = useState('')
  const [isQueryValid, setIsQueryValid] = useState(false)
  const [getSearchResults, { data, loading }] = useSearch(query, {limit: 5})

  const closeOnEsc = e => {
    if (e.key === 'Escape') {
      onClose()
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      window.open(`${window.location.origin}/search?q=${query}`, "_self")
    }
  }

  const handleChange = value => {
    const valueEntered = !!value
    const isValid = valueEntered && value.length > 2
    setQuery(value)
    setIsQueryValid(isValid)

    if (isQueryValid) {
      getSearchResults()
    }
  }

  return (
    <Container className={`search-box ${className}`} onClickOutside={onClose}>
      <SearchInput
        query={query}
        placeholder={intl.formatMessage({ id: 'search.placeholder' })}
        onChange={e => handleChange(e.target.value)}
        onKeyPress={e => handleKeyPress(e)}
        loading={loading}
        searching={searching}
        onKeyUp={closeOnEsc}
        autoFocus
        testid="nav"
      >{children}</SearchInput>
      {isQueryValid && data && (
        <DropdownContainer className={"dropdownContainer"}>
          <SearchDropdown loading={loading} results={data?.search?.data} count={data?.search?.count} afterSelect={onClose} query={query} />
        </DropdownContainer>
      )}

    </Container>
  )
}

export default GlobalSearch
