export default function count(values) {
  if (values) {
    let sum = 0
    // eslint-disable-next-line
    const val = values?.forEach((v) => {
      if (v?.value) {
        if (v?.value?.length) sum++
        else {
          try {
            if (JSON.parse(v?.value)?.length > 0) sum++
          } catch (e) {
            console.log('Error: ', e)
          }
        }
      } else if (v?.values) {
        if (v?.values?.length > 0) sum++
      }
    })

    return sum
  }
  return 0
}
