import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import { mapUserAccount } from 'services/jwt/index'
import InputText from 'components/indy/shared/ui-kit/inputs/text'
import BaseStyled from './styles/default'

const mapStateToProps = ({ user }) => ({ user })

const inputs = [
  { key: 'firstName', name: 'First name', required: true },
  { key: 'lastName', name: 'Last name *', required: true },
]

const PersonalDetails = ({ user, dispatch }) => {
  const intl = useIntl()
  const handleForm = (val, type) => {
    const profile = mapUserAccount({ ...user.profile, [type]: val })
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile,
      },
    })
  }

  const renderMessageText = (val) => {
    return intl.formatMessage({
      id: `user.onboarding.personaldetails.${val.toLowerCase()}.input`,
    })
  }

  return (
    <BaseStyled>
      <div className="step-content-container background">
        {inputs.map((input) => {
          return (
            <InputText
              input={{ ...input, value: user.profile[input.key] }}
              onBlurCallback={(val) => handleForm(val, input.key)}
              size="m"
              label={renderMessageText(input.key)}
            />
          )
        })}
      </div>
    </BaseStyled>
  )
}

export default connect(mapStateToProps)(PersonalDetails)
