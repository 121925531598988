import { useRef, useEffect, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { elementScheme, createScheme } from './index'
import { getHSLA } from './primitives/color'

export const useColorBucket = (id) => {
  //Finds the subscheme by ID and attacches its color config
  //to the reactive element assigned ref = colorBucket
  const theme = useTheme()
  const colorBucket = useRef(null)
  useEffect(() => {
    const { colors } = theme
    const element = colorBucket.current
    if(element) elementScheme(element, colors, id)
  }, [theme.colors, colorBucket, id])

  return [colorBucket]
}

export const useColorBucketForced = (id) => {
  //Finds the subscheme by ID and attaches its color config
  //to the reactive element assigned ref = colorBucket

  const theme = useTheme()
  const colorBucket = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  const getElement = (el) => {
    colorBucket.current = el
    setRefVisible(!!el)
  }

  useEffect(() => {
    if (refVisible) elementScheme(colorBucket.current, theme.colors, id)
  }, [refVisible, theme.colors, colorBucket, id])

  return [getElement]
}

export function useShadeColor(color = 'foreground', alpha = 0.6) {
  const theme = useTheme()
  const scheme = createScheme(theme.colors)
  const schemeColor = scheme[color].color
  const hsla = getHSLA(schemeColor, alpha)
  return hsla.string
}
