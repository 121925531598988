import React from 'react'
import styled from '@emotion/styled'
import SingleNotification from '../dashboard-single-notification'
import SingleRecommendation from '../dashboard-single-recommendation'

const TypeContainer = styled.div`
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
  padding-bottom: 25px;
  padding-top: 75px;
`

const Index = ({ notifications }) => {
  return (
    <TypeContainer>
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification) => {
          if (notification.type === 'connection_request') {
            return <SingleNotification notification={notification} />
          } else {
            return <SingleRecommendation notification={notification} />
          }
        })}
    </TypeContainer>
  )
}

export default Index
