import styled from '@emotion/styled'
import css from '@emotion/css'

export const ButtonsWrapper = styled.div`
  width: 800px;
  display: flex;
  justify-content: space-between;

  button {
    margin-bottom: 25px;
    padding: 9px 20px;
    border-radius: 25px;
    outline: none;
    border: 2px solid var(--flavor);
    font-size: 16px;
    cursor: pointer;
  }

  .reject-btn {
    background: transparent;
    color: var(--foreground);
    font-weight: 500;
  }
  .success-btn {
    background: var(--flavor);
    color: var(--background);
    font-weight: 700;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        background: var(--shade);
        border-color: var(--foreground-shade);
        cursor: not-allowed;
      `}
  }
`
