import React from 'react'
import AdditionalInfo from './additional-info'
import Card from './card'

// import PersonalDetails from './personal-details'

const socialMedia = {
  phone: { name: 'Phone', icon: 'fas fa-mobile-alt' },
  linkedIn: { name: 'LinkedIn', icon: 'fab fa-linkedin-in' },
  twitter: { name: 'Twitter', icon: 'fab fa-twitter' },
  instagram: { name: 'Instagram', icon: 'fab fa-instagram' },
}

const mapUserToSocialMedia = ({ profile }) => {
  const list = []
  Object.keys(profile).map((k) => {
    const obj = socialMedia[k] ? { ...socialMedia[k], value: k } : null
    if (obj) list.push(obj)
    return null
  })
  return list
}

const Main = ({ user }) => {
  const socialMedia = mapUserToSocialMedia(user)

  return (
    <>
      <div>
        <div
          style={{ backgroundImage: `url('${user.profile.coverPhoto}')` }}
          className="background-image"
          data-testid="onboarding-preview-img-background"
        >
          <Card user={user} />
        </div>
      </div>
      <AdditionalInfo
        data={socialMedia}
        name={'Social media'}
        type={'socialMedia'}
      />
    </>
  )
}

export default Main
