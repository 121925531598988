import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { H5 } from '_themes/default/components/styled/headers'
import CategoryTag from '_themes/default/components/category-tag'

export const Container = styled.div`
  position: relative;
  background-color: var(--background);
  border-radius: 25px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  &:hover {
    transform: translateY(-10px);
  }

  ${({ horizontalDisplay }) =>
    horizontalDisplay &&
    css`
      height: 503px;
    `}
`

export const TagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
`

export const Tag = styled(CategoryTag)`
  margin: 5px;
  color: ${({ theme }) => theme.colors.color3_3};
  background-color: ${({ theme }) => theme.colors.color4_2};
`

export const CoverWrapper = styled.div`
  position: relative;
  ${({ verticalDisplay }) =>
    verticalDisplay &&
    css`
      height: 600px;
    `}
`

export const Cover = styled.img`
  object-fit: cover;
  width: 100%;
  height: 245px;

  ${({ horizontalDisplay }) =>
    horizontalDisplay &&
    css`
      height: 100%;
    `}
`

export const Title = styled(H5)`
  padding: 20px 40px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
`

export const Description = styled.p`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  padding: 0 40px 10px;
`

export const ContentWrapper = styled.div`
  height: 160px;
  min-width: 100%;
  /*
  ${({ horizontalDisplay, theme }) =>
    horizontalDisplay &&
    css`
      position: absolute;
      z-index: 1;
      top: 35%;
      left: 0;
      width: 35%;
      height: 209px;
      background-color: var(--background);
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    `}
    */
`
