import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { Row, Col } from 'react-bootstrap'

import ItemList, {
  AddNewItem,
  DetailedInfo,
  NoContent,
} from '../shared/items-list'
import AddEditModal from './add-edit-modal'
import RemoveButton from './remove-button'

const mapStateToProps = ({ user }) => ({ user })

const Education = ({ user }) => {
  const [currentEducation, setCurrentEducation] = useState()
  const [addNewEducation, setAddNewEducation] = useState()
  const [deleteEducation, setDeleteEducation] = useState()
  const intl = useIntl()

  const { education } = user?.profile

  const onClose = () => {
    setAddNewEducation(false)
    setCurrentEducation(false)
    setDeleteEducation(false)
  }

  return (
    <ItemList>
      {education && education.length > 0 ? (
        education.map((edu) => {
          return (
            <DetailedInfo>
              <Row>
                <Col sm={12} md={6}>
                  <span
                    className="text-bold"
                    data-testid={`education-schoolName-${edu.schoolName}`}
                  >
                    {edu.schoolName}
                  </span>
                  <span
                    className="text-standard"
                    data-testid={`education-domain-${edu.schoolName}`}
                  >
                    {edu.domain}
                  </span>
                  <span
                    className="text-url"
                    data-testid={`education-websiteUrl-${edu.schoolName}`}
                  >
                    {edu.websiteUrl}
                  </span>
                </Col>
                <Col sm={12} md={6}>
                  <span
                    className="text-bold"
                    data-testid={`education-degree-${edu.schoolName}`}
                  >
                    {edu.degree}
                  </span>
                  <span
                    className="text-standard"
                    data-testid={`education-yearGraduated-${edu.schoolName}`}
                  >
                    {moment(edu.yearGraduated).format('DD MMM YYYY')}
                  </span>
                </Col>

                <button
                  onClick={() => setDeleteEducation(edu)}
                  className="delete"
                  data-testid={`btn-delete-${edu.schoolName}`}
                >
                  <i className="fas fa-trash" />
                </button>
                <button
                  onClick={() => setCurrentEducation(edu)}
                  className="edit"
                  data-testid={`btn-edit-${edu.schoolName}`}
                >
                  <i className="fas fa-pen" />
                </button>
              </Row>
            </DetailedInfo>
          )
        })
      ) : (
        <NoContent>
          <i className="empty-icon fas fa-graduation-cap"></i>
          <span className="empty-label">
            {intl.formatMessage({
              id: 'user.profile.education.noeducation.message',
            })}
          </span>
        </NoContent>
      )}
      {(currentEducation || addNewEducation) && (
        <AddEditModal onClose={onClose} currentEducation={currentEducation} />
      )}
      {deleteEducation && (
        <RemoveButton onClose={onClose} deleteEducation={deleteEducation} />
      )}
      <AddNewItem onClick={() => setAddNewEducation(true)} />
    </ItemList>
  )
}

export default connect(mapStateToProps)(Education)
