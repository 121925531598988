import React from 'react'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { DivStyled } from './styles/application-managers'
import { InviteApplicationManagerButton } from './addApplicationManager'
import styled from '@emotion/styled'
import { REMOVE_APPLICATION_MANAGER } from '../opportunity-applications-management/gql'

const ManagerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;

  img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    outline: solid 1px var(--flavor);
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .name {
    font-weight: 600;
  }

  button {
    width: fit-content;
    background-color: transparent;
    border: none;
  }
`

export const ApplicationManagers = ({ opportunity }) => {
  const [removeManagerMutation] = useMutation(REMOVE_APPLICATION_MANAGER)

  return (
    <DivStyled>
      <span id="header">
        <FormattedMessage
          id="application.management.applicationManagers"
          defaultMessage="Application Managers"
        />
      </span>
      {opportunity?.applicationManagers?.map(
        ({ firstName, lastName, state, userId, avatar, middleName }) => (
          <ManagerWrapper>
            <div>
              <img src={avatar} alt="" />
              <span className="name">
                {firstName}
                {middleName ? ` ${middleName} ` : ' '}
                {lastName}
              </span>
            </div>
            <div>
              <span>
                <FormattedMessage id={state} />
              </span>
              <button
                onClick={() =>
                  removeManagerMutation({
                    variables: {
                      entityId: opportunity.id,
                      userId,
                    },
                    refetchQueries: ['GetOpportunity'],
                  })
                }
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </ManagerWrapper>
        )
      )}
      <div className="grow" />
      <InviteApplicationManagerButton opportunity={opportunity} />
    </DivStyled>
  )
}
