import React from 'react'
import { Col } from 'react-bootstrap'
import styled from '@emotion/styled'

import { tablet } from '_utils/responsive-queries.js'

const Layout = styled.div`
  padding: 25px 0vw 50px 50px;
  height: 100%;
  background: var(--background);
  &.left,
  &.center {
    padding-left: 50px;
    padding-right: 50px;
    ${tablet} {
      padding: 25px 60px 10px 60px;
    }
  }
  &.right {
    padding-right: 50px;
    padding-left: 50px;
  }
  ${tablet} {
    padding: 60px 60px 10px 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--foreground);
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-weight: 700;
    padding: 0px;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.googleFont};
    color: var(--foreground);
    font-size: 16px;

    a {
    color: var(--link);
    text-decoration: underline;
  }
  }

  ul {
    list-style: inherit;
    padding-left: 20px;
  }

  iframe {
    height: 100%;
    aspect-ratio: 16 / 9;
    width: 100%;
  }
  iframe.oEmbedContent, .fr-video iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  pre {
    padding: 20px;
    color: #aaa;
    background-color: #222;
    white-space: pre;
    font: 16px/24px 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
  }

  .fr-class-highlighted {
    background-color: var(--flavor);
    color: var(--flavor-contrast);
  }

  .fr-class-transparency{
    opacity: 0.5;
  }

  .fr-class-code{
    border-color: var(--shade);
    border-radius: 2px;
    background-clip: padding-box;
    background: var(--shade-faint);
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
  }


`

const StyledCol = styled(Col)`
  padding: 0;
  position: relative;
`

const Content = ({ children, position, showApply }) => {
  const lg = position === 'center' ? 12 : 8
  const md = position === 'center' ? 12 : 6
  return (
    <StyledCol md={md} lg={lg} xl={lg}>
      <Layout className={position} showApply>
        {children}
      </Layout>
    </StyledCol>
  )
}

export default Content
