import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from '_components/button'
// import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
// import { useUser } from '_security/session-hooks'
import border from '_utils/css-border'
import { ctaButton, ctaButtonHollow } from '_utils/css-buttons'

const ModalStyled = styled(Modal)`
  .modal-dialog {
    top: 50% !important;
    transform: translate(0, -50%) !important;
  }
  .modal-content {
    padding: 5px 15px !important;
    background: var(--background);
    ${border()}
  }

  .info-title {
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 24px;
    color: var(--foreground);
    font-weight: 600;
  }

  .info-description {
    margin-top: 10px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 14px;
    color: var(--foreground);
    font-weight: 400 !important;
  }

  .close-button {
    margin-right: 10px;
    ${ctaButtonHollow}
  }

  .signin-button {
    margin-right: 10px;
    ${ctaButton}
  }

  .modal-footer {
    border-top: none;
    text-align: center;
    padding-bottom: 15px !important;
    justify-content: space-between !important;
  }
`

const ModalFooterStyled = styled(Modal.Footer)`
  justify-content: space-between !important;
`

const Index = ({
  title,
  description,
  canShowInfoModal,
  setCanShowInfoModal,
  cancelBtnContent,
  continueBtnContent,
  onProceed,
}) => {
  const handleClose = () => {
    setCanShowInfoModal(false)
  }

  // const renderMessageText = (value) => {
  //     return intl.formatMessage({
  //         id: `${modalProps.type.toLowerCase()}.required.modal.${value.toLowerCase()}`,
  //     })
  // }

  return (
    <ModalStyled
      dialogClassName="info-modal-container"
      show={canShowInfoModal}
      onHide={handleClose}
    >
      <Modal.Body className="info-modal-body">
        <div className="all-info-container">
          <h2 className="info-title">{title}</h2>
          <p className="info-description">{description}</p>
        </div>
      </Modal.Body>
      <ModalFooterStyled>
        <Button
          type="button"
          onClick={handleClose}
          className="close-button"
          disableMarginAuto
        >
          {cancelBtnContent}
        </Button>
        <Button
          type="button"
          onClick={onProceed}
          className="signin-button"
          disableMarginAuto
        >
          {continueBtnContent}
        </Button>
      </ModalFooterStyled>
    </ModalStyled>
  )
}

export default Index
