import styled from '@emotion/styled'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RowCenterAll = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ColCenterAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Relative = styled.div`
  position: relative;
`
