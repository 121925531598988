import Card from '../card'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile, pablet } from '_utils/responsive-queries'
import { css } from '@emotion/core'
import { themeIsDark } from '_colorBus/primitives/scheme'

export const CardStyled = styled(Card)`
  display: flex;
  align-items: center;
  margin: 20px 0;
  .grow {
    flex-grow: 1;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  ${pablet} {
    padding: 25px 15px;
  }
`

export const Avatar = styled.div`
  ${border()}
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin: 25px;
  flex-shrink: 0;
  background-color: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};

  ${pablet} {
    align-self: flex-start;
    margin: 0 25px 0 0;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 35%;
  .domain {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .school-name {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    opacity: 0.6;
  }
  .location {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.6;
    padding-bottom: 30px;
  }
  .description {
    margin-top: 10px;
  }

  ${pablet} {
    width: 50%;
    .domain {
      font-size: 18px;
      font-weight: 600;
      padding: 0;
    }

    .location {
      padding: 0;
      opacity: 0.6;
    }
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 75px;
  margin: 25px 0;
  ${pablet} {
    flex-direction: column;
    margin: 0;
  }
`

export const ExpandButtonWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  button {
    padding: 0 20px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    &:hover {
      opacity: 0.6;
    }
  }

  ${pablet} {
    button {
      padding: 0;
      i {
        color: var(--flavor-contrast);
      }
    }
  }

  ${({ isMobileView }) =>
    isMobileView &&
    css`
      flex-direction: row;
      justify-content: center;
      transform: translateX(-50px);
      margin-top: 25px;
      button {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: var(--flavor);
        i {
          color: var(--flavor-contrast);
        }
      }
    `}
`

export const Degree = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  .degree {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .data {
    font-size: 14px;
    opacity: 0.6;
  }

  ${pablet} {
    &.degree-wrapper {
      padding: 0;
    }

    .data {
      opacity: 0.6;
      padding: 0;
    }
    .description {
      margin-top: 10px;
    }
  }
  ${mobile} {
    .data,
    .degree {
      font-size: 14px;
    }
  }
`

export const EducationInfoWrapper = styled.div`
  display: flex;
  ${mobile} {
    flex-direction: column;
  }
`
