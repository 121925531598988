export default [
  {
    key: 'personalDetails',
    name: 'Personal Details',
    title: 'Fill your personal details',
    description: '',
  },

  {
    key: 'profileImages',
    name: 'Photo',
    title: 'Upload your photos',
    description: '',
  },
  {
    key: 'location',
    name: 'Location',
    title: 'Where are you located?',
    description: '',
  },
]
