import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useAppConfig } from '_application'
import { useIntl } from 'react-intl'
import { defaultDiscoverBackground } from '_resources'
import { Link } from 'react-router-dom'
import { useUser } from '_security/session-hooks'

import { preloadMedia } from '_utils/preload-media'

const Content = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  max-height: 120vh;
  overflow: hidden;
`

const HeaderTitle = styled.h1`
  color: var(--imgColor);
  font-weight: 600;
  font-size: 36px;
`

const HeaderSubtitle = styled.p`
  color: var(--imgColor);
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
`

const HeaderButton = styled.button`
  margin-top: 25px;
  border: 2px solid var(--imgColor);
  background: transparent;
  color: var(--imgColor);
  padding-left: 25px;
  font-size: 16px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  transition: 0.5s;
  &:hover {
    color: var(--foreground);
    background-color: var(--background);
    border: 2px solid var(--background);
  }
  @media (max-height: 600px) {
    margin: 0px;
  }
`

const TitleWrapper = styled.div`
  position: relative; z-index: 2;
  max-width: 80vw;
  @media (max-height: 600px) {
    padding: 0em 3em;
  }
`

const bannerMedia = css`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center center;
  background: var(--shade);
  position: absolute;
  top: 0px; left: 0px;
`

const Video = styled.video`
  ${bannerMedia}
`

const Img = styled.img`
  ${bannerMedia}
`

export const DiscoverBanner = () => {
  const user = useUser()
  const config = useAppConfig()
  const intl = useIntl()

  const history = useHistory()

  const discoverTitle = config?.labelOverrides?.discoverTitle?.value
  const discoverWelcome = config?.labelOverrides?.discoverWelcome?.value

  const defaultTitle = intl.formatMessage({ id: 'discover.title' })
  const defaultWelcome = intl.formatMessage({ id: 'discover.welcome' })

  const isBackgroundVideo =
    config?.backgroundImages?.discoveryPage?.type === 'video'
  const backgroundUrl =
    config?.backgroundImages?.discoveryPage?.value || defaultDiscoverBackground

  const [mediaLoaded, useMediaLoaded] = useState(false)
  const [popEnter, usePopEnter] = useState(false)

  const video = useRef(null)
  const image = useRef(null)

  const Unload = () => {
    useMediaLoaded(true)
  }

  const CheckPOP = () => {
    usePopEnter(true)
  }

  useEffect(() => {
    isBackgroundVideo
      ? preloadMedia(video.current, () => Unload())
      : preloadMedia(image.current, () => Unload())
  }, [isBackgroundVideo])

  useEffect(() => {
    history.action === 'POP' && CheckPOP(true)
  }, [history.action])

  const Container = styled.div`
    position: relative;
    width: 100%;

    --max: 150vh;

    max-height: var(--max);
    min-height: 40em;
    overflow: hidden;

    padding: 7em 0px;

    display: grid;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(84, 84, 84, 0)
      );
    }

    &.pop-animate {
      animation: slidein 1s 1.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: backwards;

      background-color: var(--background);

      @keyframes slidein {
        from {
          max-height: 100vh;
          height: 100vh;
        }
        to {
          max-height: var(--max);
          height: 40em;
        }
      }

      &::after {
        animation: shadowIn 1s 1.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: backwards;

        @keyframes shadowIn {
          from {
            transform: translateY(-103vh);
          }
          to {
            transform: translateY(0px);
          }
        }
      }

      .titleWrapper {
        animation: slide 1s 2.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: backwards;

        @keyframes slide {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }

      video,
      img {
        opacity: 0;
      }

      &.mediaLoaded {
        video,
        img {
          opacity: 1;
          animation: smoothOpacity 2s;
          animation-timing-function: ease-in-out;
          animation-fill-mode: backwards;

          @keyframes smoothOpacity {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        }
      }
    }
  `

  return (
    <Container
      className={`${popEnter && false && `pop-animate`} ${
        mediaLoaded && `mediaLoaded`
      }`}
    >
      {isBackgroundVideo ? (
        <Video
          ref={video}
          width="100%"
          height="100%"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={backgroundUrl} />
        </Video>
      ) : (
        <Img
          ref={image}
          src={backgroundUrl}
          alt="banner"
          width="500"
          height="600"
        />
      )}
      <Content className="content">
        <TitleWrapper className="titleWrapper">
          <HeaderTitle>{discoverTitle || defaultTitle}</HeaderTitle>
          {user.authorized ? (
            <HeaderSubtitle>
              <span>{`${discoverWelcome || defaultWelcome} ${
                user?.profile?.firstName
              }`}</span>
            </HeaderSubtitle>
          ) : (
            <div>
              <HeaderSubtitle>
                {intl.formatMessage({ id: 'discover.banner.loginneeded' })}
              </HeaderSubtitle>
              <Link to="/registration">
                <HeaderButton>
                  {intl.formatMessage({ id: 'discover.banner.signupnow' })}
                </HeaderButton>
              </Link>
            </div>
          )}
          <Link to={user.authorized ? '/discover' : '/login'}></Link>
        </TitleWrapper>
      </Content>
    </Container>
  )
}

export default DiscoverBanner
