import styled from '@emotion/styled'

export const SDKContainerStyle = styled.div`
  .login-title {
    color: var(--foreground);
    font-weight: 700;
    font-size: 24px;
    margin: 30px 20px;
  }
  label {
    font-family: ${({ theme }) => theme.fonts.googleFont} !important;
    color: var(--foreground);
  }
  button {
    margin-top: 25px;
    background-color: var(--flavor) !important;
    color: var(--flavor-contrast);
    border-radius: 6px;
    font-family: ${({ theme }) => theme.fonts.googleFont} !important;
    font-weight: 500;
  }
  hr {
    display: none;
  }
  p {
    border: 2px solid var(--flavor);
    border-radius: 6px;
    padding: 12px;
    margin-top: 20px;
    a {
      font-family: ${({ theme }) => theme.fonts.googleFont} !important;
      color: var(--flavor) !important;
      font-weight: 500;
    }
  }
  #IKUISDK-btn-to-forgot-password {
    display: block;
  }
  input {
    background-color: white;
    border: none;
    border-bottom: 2px solid #c4c4c4;
  }
`
