import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../components/indy/shared/logged/header'
import Footer from '_components/footer'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import styled from '@emotion/styled'

const StyledWrapper = styled.div`
  display: flex;
  margin-top: 110px;
  .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    position: relative;
    [aria-hidden^='true'].graph-tab-container {
      display: none;
    }
    .inner-container {
      width: calc(100% - 300px);
      margin: 80px 0 0 300px;
      position: relative;
      padding-bottom: 270.5px;
    }
    &.messages {
      .container {
        max-width: none;
        padding: 0px;
        margin: 0px;
      }
    }
  }
`

const LoggedContentWrapper = ({ children, requiredRoles }) => {
  const appState = useSelector((state) => state)
  const location = appState.router.location.pathname
  const { user } = appState

  if (requiredRoles) {
    if (!requiredRoles.includes(user?.profile?.role)) return <Redirect to="/" />
  }
  const urlMessages = window.location.pathname.includes('user/messages')

  return (
    <StyledWrapper className="logged-container" urlMessages={urlMessages}>
      <div
        className={`main-content ${
          location === '/user/messages' || '/user/notifications'
            ? 'messages'
            : ''
        }`}
      >
        <Header />
        <Container>{children}</Container>
        <Footer />
      </div>
    </StyledWrapper>
  )
}

export default LoggedContentWrapper
