import React from 'react'

import { Row, Col } from 'react-bootstrap'

import { renderField } from '../shared/field-factory'

import { Field, Section, ButtonsWrapper, ShowHideButton } from '../styles'

const SectionRenderer = ({
  sectionTitle,
  section,
  isOpen,
  setIsOpen,
  user,
  errors,
  additionalData,
}) => {
  const isAttributeObject = (attribute) => {
    return (
      attribute.type === 'location' ||
      attribute.type === 'badge' ||
      attribute.type === 'file'
    )
  }

  return (
    <Section key={sectionTitle}>
      <Row>
        <Col sm={12}>
          <ButtonsWrapper>
            <span className="section-title">{sectionTitle}</span>
            <ShowHideButton onClick={setIsOpen}>
              <i class={`fas fa-angle-${isOpen ? 'up' : 'down'}`} />
            </ShowHideButton>
          </ButtonsWrapper>

          {isOpen && (
            <div className="section-content">
              <Row>
                {section.map((item) => {
                  const isObject = isAttributeObject(item)
                  if (isObject && item.value)
                    item.value = JSON.parse(item.value)
                  return (
                    <Col key={item.name} sm={12} md={item.md || 6}>
                      <Field>
                        <span
                          className="field-title"
                          data-testid={`about-${item.key}`}
                        >{`${item.name} ${item.required ? '*' : ''}`}</span>
                        {renderField({
                          attribute: item,
                          user,
                          isCustomAttr: additionalData?.isCustomAttr || false,
                          onSave: additionalData?.saveHandler || item.onSave,
                          onError: additionalData?.errorHandler || item.onError,
                          error: errors[item.key],
                          maxLength: item.maxLength,
                          disabled: item.disabled,
                          editorType: item.editorType,
                        })}
                      </Field>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Section>
  )
}

export default SectionRenderer
