import React, { useEffect } from 'react'
import DashboardMessagesConversationDetails from '../dashboard-messages-conversation-details'
import DashboardMessagesConversationField from '../dashboard-messages-conversation-field'
import DashboardMessagesConversationCompose from '../dashboard-messages-conversation-compose'
import { useMessages, useMessageActions } from '_messages'
export const ConversationWindow = ({ currentChatRoom }) => {
  useMessages()
  const messageActions = useMessageActions()

  useEffect(() => {
    if (!currentChatRoom) return
    messageActions.setMessages([])
    messageActions.loadMessages(currentChatRoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChatRoom])

  return (
    <>
      <DashboardMessagesConversationDetails currentChatRoom={currentChatRoom} />
      <DashboardMessagesConversationField />
      <DashboardMessagesConversationCompose />
    </>
  )
}

export default ConversationWindow
