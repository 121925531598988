export const nextStep = (props, stepList) => changeStep(props, stepList, true)
export const previousStep = (props, stepList) => changeStep(props, stepList)

const changeStep = (props, stepList, isNext) => {
  const maxStep = stepList.length
  let step = props.currentStep
  step = isNext ? step + 1 : step - 1
  if (isNext && step > maxStep) {
    step = maxStep
  }
  if (!isNext && step < 0) {
    step = 0
  }

  return {
    currentStep: step,
    progressInPercentage: parseInt(((step + 1) / maxStep) * 100, 10),
    isLastStep: step + 1 === maxStep,
  }
}
