import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import { Map } from '_components/drilldown'
import CustomAttributes from '_custom-attributes/components/list'
import ActivityMiniCard from './tiles/activity-mini-card'
import PublicDetailsCard from '_public-details-card'
import { Link, useHistory } from 'react-router-dom'
import { Keywords, Taxonomy } from '_keywords-and-taxonomies'
import { Section, SingleAttribute } from '_custom-attributes'
import Connections from './connections'

import count from 'helpers/counter'
import styled from '@emotion/styled'
import SanitiseHTML from 'helpers/sanitize-html'

import { ctaButtonHollow } from '_utils/css-buttons'
import Modal from '../../_events/components/event-creator/components/modal'
import { gql, useMutation } from '@apollo/client'
import EditInfo from 'components/indy/shared/edit-info'

const DELETE_ACTIVITY = gql`
  mutation deleteActivity($id: String) {
    deleteActivity(id: $id) {
      id
    }
  }
`

const GoBackButton = styled.button`
  ${ctaButtonHollow}
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
  span.text:first-letter {
    text-transform: uppercase;
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  transform: translateY(-100px);
  .separator {
    height: 30px;
  }
`

const TileWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  max-width: 304px;
  margin: 0 auto;
  ${({ hasMultipleConnections }) =>
    hasMultipleConnections && { pointerEvents: 'none' }}
`

const ActivityDetails = ({
  activity,
  attributes,
  children,
  onClose,
  connectButton,
  recommendButton,
  a2cButton,
  isOwner,
  a2pButton,
  p2pButton,
  id,
}) => {
  const { creator, connectedCompanies } = activity
  const intl = useIntl()
  const appLabel = useAppLabel()
  const history = useHistory()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteActivity] = useMutation(DELETE_ACTIVITY, {
    variables: {
      id: activity?.id,
    },
  })

  const defaultTaxonomiesKeys = ['industry-areas']
  const defaultTaxonomies = activity?.taxonomy?.filter((v) =>
    defaultTaxonomiesKeys.some((d) => d === v.key)
  )

  let defaultTaxonomiesLength = count(defaultTaxonomies)
  let customAttributesLength = count(activity?.customAttributes)

  const tabs = {
    about: { type: 'about', translationKey: 'user.profile.about' },
    company: {
      type: 'connection',
      translationKey: 'companies.titleplural',
      count: activity?.connectedCompanies?.length,
    },
    opportunity: {
      type: 'connection',
      translationKey: 'opportunities.titleplural',
      count: activity?.connectedOpportunities?.length,
    },
    people: {
      type: 'connection',
      translationKey: 'people.titleplural',
      count: activity?.connectedUsersCount,
    },
  }

  const hasConnections = activity?.connectedCompanies.length > 0
  const hasMultipleConnections = activity?.connectedCompanies.length > 1

  const tileUrl = () => {
    if (!hasConnections) return `/people/list/${creator?.sub}`
    if (hasConnections && !hasMultipleConnections)
      return `/companies/list/${connectedCompanies[0]?.id}`
    return null
  }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])

  const isAboutView = tabs[currentTab]?.type === 'about'

  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${activity?.name} activity?`}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.continue',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.cancel',
        })}
        onProceed={() => {
          deleteActivity()
            .then(() => {
              history.push(`/activities/list`)
              history.go(0) // TODO this is temp solution to refetch list
            })
            .catch(console.error)
        }}
      />
      <PublicDetailsCard>
        <PublicDetailsCard.Header
          coverUrl={activity?.coverPhoto}
          onClose={onClose}
          returnText={'Back to all activities'}
          type={appLabel('activities', 'detailcard.activity')?.toLowerCase()}
          entityId={activity.id}
          title={activity.name}
          location={activity.location}
          creator={activity?.creator?.id}
          recommendButton={recommendButton}
          onDelete={() => setIsDeleteModalOpen(true)}
        />
        <PublicDetailsCard.Container>
          <PublicDetailsCard.Content position={isAboutView ? 'left' : 'center'}>
            <PublicDetailsCard.ContentTabs
              tabs={tabs}
              onTabSelect={setCurrentTab}
              useFollowersLabel={true}
            />
            {activity?.editor && (
              <EditInfo
                editDetails={{
                  authorName: activity?.editor?.firstName,
                  authorSurname: activity?.editor?.lastName,
                  date: activity?._updatedAt,
                }}
              />
            )}

            {isAboutView && (
              <>
                <PublicDetailsCard.ContentHeader
                  title={activity.name}
                  connectButton={connectButton}
                  startDate={activity?.start}
                  endDate={activity?.end}
                  isStartDateCreated={!activity?.start}
                  isPrivate={activity?.private}
                  isOwner={isOwner}
                />

                <SanitiseHTML html={activity?.body} />
                <Map location={activity.location} />
              </>
            )}
            {!isAboutView && (
              <Connections currentTab={currentTab} entityId={activity?.id} />
            )}
          </PublicDetailsCard.Content>
          {isAboutView && (
            <PublicDetailsCard.Info position={'right'}>
              <InfoWrapper position="right">
                <TileWrapper
                  to={tileUrl()}
                  hasMultipleConnections={hasMultipleConnections}
                >
                  <ActivityMiniCard
                    activity={activity}
                    connectButton={connectButton}
                    a2cButton={a2cButton}
                    isOwner={isOwner}
                  />
                </TileWrapper>
                {!hasMultipleConnections && <div className="separator" />}
                {activity.location && activity.location?.label && (
                  <>
                    <Section type="location">
                      <SingleAttribute
                        attribute={{
                          type: 'location',
                          description: 'Location',
                          name: 'Location',
                          value: activity.location,
                        }}
                      />
                    </Section>
                  </>
                )}
                {defaultTaxonomies && defaultTaxonomiesLength > 0 && (
                  <>
                    <Taxonomy
                      noMainTitle
                      values={defaultTaxonomies}
                      type={'activities'}
                    />
                  </>
                )}
                {activity?.keywords && (
                  <>
                    <Keywords
                      noMainTitle
                      values={activity?.keywords}
                      type={'activities'}
                    />
                  </>
                )}
                {activity?.taxonomy && (
                  <>
                    <Taxonomy
                      noMainTitle
                      excludeKeys={defaultTaxonomiesKeys}
                      values={activity?.taxonomy}
                      type={'activities'}
                    />
                  </>
                )}
                {CustomAttributes && customAttributesLength > 0 && (
                  <>
                    <CustomAttributes
                      values={activity?.customAttributes}
                      attributes={attributes}
                      side="right"
                    />
                  </>
                )}
              </InfoWrapper>
            </PublicDetailsCard.Info>
          )}
          <PublicDetailsCard.GoBackContent>
            <Link to="/activities/list/">
              <GoBackButton data-testid="detailCard-btn-back">
                <p>
                  <i className="fas fa-chevron-left" />
                  <span className="text">
                    {intl
                      .formatMessage(
                        { id: 'detailcard.button.back' },
                        {
                          category: appLabel(
                            'activitiesPlural',
                            'detailcard.button.activities'
                          ),
                        }
                      )
                      .toLowerCase()}
                  </span>
                </p>
              </GoBackButton>
            </Link>
          </PublicDetailsCard.GoBackContent>
        </PublicDetailsCard.Container>
        <ReactTooltip id="info-tooltip" effect="solid" />
      </PublicDetailsCard>
    </>
  )
}

export default ActivityDetails

