import actions from './actions'

const initialState = {
  creator: {
    type: '',
    role: '',
    gender: '',
    experience: 0,
    profileTypes: [],
    height: 175,
    size: 'XS',
    playingAge: [18, 26],
    eyeColor: {},
  },
}

export default function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_JOBS_CREATOR:
      return { ...state, creator: { ...state.creator, ...action.payload } }
    default:
      return state
  }
}
