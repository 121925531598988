import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import RecommendButton from '_components/buttons-connections/recommend-button-new'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { useAppConfig } from '_application'

const ShowRecommendButtonToggle = styled.button`
  width:100%;
  height: 58px;
  display: ${({ isDisplayNone }) => isDisplayNone ? 'none' : 'flex'};
  align-items: center;
`

const RecommendWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--background);
  position: relative;
  padding: 18px;
  height: 58px;

  .input-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    .inner-suggestions-container {
      ${border()}
      border-radius:25px;
    }
    &.align-to-left {
      min-width: 250px;
      left: auto;
      right: 0;
      transform: none;
    }
  }

  i {
    color: var(--shade);
    margin-right: 12px;
  }
`

const ShareButton = styled.button`
  position: relative;
  &:after {
    content: attr(data-after);
    display: ${({ isTipOn }) => (isTipOn ? 'block' : 'none')};
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    background: var(--foreground);
    color: var(--background);
    padding: 16px;
  }
  &:before {
    content: '';
    display: ${({ isTipOn }) => (isTipOn ? 'block' : 'none')};
    position: absolute;
    top: 4px;
    left: 50%;
    height: 20px;
    width: 20px;
    background: var(--foreground);
    transform: rotate(45deg) translateX(-50%);
  }
`

const handleShare = (e, { type, url, siteTitle, post }) => {
  e.preventDefault()
  const currentLocationHref = window?.location?.href
  const handledPostId = currentLocationHref.includes(post.id) ? '' : post.id

  const isSocialMedia = type === 'linkedin' || type === 'twitter'
  const mailtoContent = `mailto:?subject=${encodeURIComponent(siteTitle)}: ${encodeURIComponent(post.title)}&body=${encodeURIComponent(currentLocationHref)}/${encodeURIComponent(handledPostId)}`

  switch (true) {
    case type === 'link':
      return navigator.clipboard.writeText(
        `${currentLocationHref}/${handledPostId}`
      )
    case isSocialMedia:
      return window.open(`${url}${currentLocationHref}/${handledPostId}`)
    case type === 'email':
      return (window.location.href = mailtoContent)
    default:
      break
  }
}

export const SharePostButton = ({
  icon,
  type,
  name,
  url,
  post,
  isInputShown,
  setIsInputShown,
  tipData,
  tipFor,
  alignment

}) => {
  const intl = useIntl()
  const appConfig = useAppConfig()
  const [isTipOn, setIsTipOn] = useState(false)

  const siteTitle = appConfig?.metaData?.siteTitle || "indyRIOT"

  setTimeout(() => isTipOn && setIsTipOn(false), 1000)

  return type === 'users' ? (
    <div
      className="main-recommend-container"
      data-testid="detailCard-recommendContainer"
    >
      {isInputShown ? (
        <RecommendWrapper onClick={(e) => e.preventDefault()}>
          {icon}
          {name &&
            !isInputShown &&
            intl.formatMessage({
              id: `blog.tile.sharebutton.${type}`,
              defaultMessage: name,
            })}
          <ShowRecommendButtonToggle
            isDisplayNone={isInputShown}
            onClick={(e) => {
              e.preventDefault()
              setIsInputShown(!isInputShown)
            }}
          >
            {icon}
            {name &&
              intl.formatMessage({
                id: `blog.tile.sharebutton.${type}`,
                defaultMessage: name,
              })}
          </ShowRecommendButtonToggle>
          <div className={`input-wrapper align-to-${alignment}`}>
            <RecommendButton
              isInputShown={isInputShown}
              setIsInputShown={setIsInputShown}
              contentToRecomment={post.id}
              onClick={() => setIsInputShown(!isInputShown)}
              queryKey={'blogToRecommend'}
            />
          </div>
        </RecommendWrapper>
      ) : (
        <ShowRecommendButtonToggle
          isDisplayNone={isInputShown}
          onClick={(e) => {
            e.preventDefault()
            setIsInputShown(!isInputShown)
          }}
          data-tip={tipData}
          data-for={tipFor}
        >
          {icon}
          {name &&
            intl.formatMessage({
              id: `blog.tile.sharebutton.${type}`,
              defaultMessage: name,
            })}
        </ShowRecommendButtonToggle>
      )}
    </div>
  ) : (
    <ShareButton
      isTipOn={isTipOn}
      data-after={intl.formatMessage({
        id: 'blog.copylink.label',
        defaultMessage: 'Copied',
      })}
      onClick={(e) => {
        type === 'link' && setIsTipOn(true)
        handleShare(e, { type, url, siteTitle, post })
      }}
    >
      {icon}
      {name &&
        intl.formatMessage({
          id: `blog.tile.sharebutton.${type}`,
          defaultMessage: name,
        })}
    </ShareButton>
  )
}