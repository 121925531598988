import React from 'react'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import {
  LocationWrapper,
  LocationContentWrapper,
} from '../styles/ca-location'
import BreakLine from './breakline'

const CustomAttributesLocation = ({
  attribute,
  attributeValue,
  attributeConfig: { onSave },
}) => {
  const locationSave = (value) => {
    onSave(
      attribute.key,
      value?.label
    )
  }

  return (
    <>
      <LocationWrapper>
        <div className="label-wrapper">
          <label htmlFor="location-content" className='location-label'>
            {attribute?.name}
          </label>
        <p className="location-description">{attribute?.description}</p>
        </div>
        <div className="location-content-wrapper">
          <LocationContentWrapper>
            <>
              {renderField({
                attribute: {
                  type: 'location',
                  value: attributeValue?.value ? attributeValue?.value : attributeValue?.city,
                },
                onSave: (f, value) => locationSave(value),
              })}
            </>
          </LocationContentWrapper>
        </div>
      </LocationWrapper>
      <BreakLine />
    </>
  )
}

export const MemoizedLocation = React.memo(Location)

export default CustomAttributesLocation

