import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import LocationSelector from 'components/indy/shared/location-selector'

import LocationStyled from './styles/location'

const mapStateToProps = ({ user }) => ({ user })

const Location = ({ user, dispatch }) => {
  const intl = useIntl()
  const handleSelectLocation = (location) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, location },
      },
    })
  }

  return (
    <LocationStyled>
      <div className="step-content-container background">
        <div className="location-optional">
          <div className="content">
            <span className="title">
              {intl.formatMessage({
                id: 'user.onboarding.step.location.yourlocation',
              })}
            </span>
            <LocationSelector
              defaultLocation={user.profile.location}
              onSelect={(location) => handleSelectLocation(location)}
            />
          </div>
        </div>
      </div>
    </LocationStyled>
  )
}

export default connect(mapStateToProps)(Location)
