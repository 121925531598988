import React from 'react'
import styled from '@emotion/styled'

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: ${({ hasDarkBackground }) =>
      hasDarkBackground ? 'black' : 'transparent'};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
  }
`

export const FullscreenLoaderWrapper = ({
  children,
  hasDarkBackground = true,
}) => {
  return (
    <LoaderWrapper hasDarkBackground={hasDarkBackground}>
      {children}
    </LoaderWrapper>
  )
}

export default FullscreenLoaderWrapper
