import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from 'react-intl'
import {
  CardStyled,
  Avatar,
  Info,
  ExpandButtonWrapper,
  Content,
} from './styles/experience'
import moment from 'moment'
import ContentSectionExpandable from './content-section-expandable'
import { pabletPX } from '_utils/responsive-queries'

const ExperienceItem = ({ item }) => {
  const intl = useIntl()
  const [isExpand, setIsExpand] = useState(false)

  const isMobileView = useMediaQuery({
    query: `(max-width: ${pabletPX}px)`,
  })

  const hasDescription = item.description && item.description !== ''

  const formatDate = ({ startDate, endDate }) => {
    const parsedEndDate = endDate && moment(endDate)
    try {
      return (
        moment(startDate).format('YYYY') +
        '-' +
        (parsedEndDate?.isValid()
          ? parsedEndDate.format('YYYY')
          : intl.formatMessage({
              defaultMessage: 'present',
              id: 'person.details.present',
            }))
      )
    } catch (err) {
      console.log(err)
      return intl.formatMessage({
        defaultMessage: 'present',
        id: 'person.details.present',
      })
    }
  }

  return (
    <CardStyled>
      <Avatar>
        <i className="fas fa-suitcase fa-2x" />
      </Avatar>
      <Content>
        <Info>
          <span className="company-name">{item.companyName}</span>
          <span className="title">{item.title}</span>
          <span className="location">
            {formatDate(item)}{' '}
            {item.location && item.location !== '' && ` | ${item.location}`}
          </span>
          {isExpand && <span className="description">{item.description}</span>}
        </Info>
        {hasDescription && (
          <ExpandButtonWrapper
            isMobileView={isMobileView}
            onClick={() => setIsExpand(!isExpand)}
          >
            <button className="expand-button">
              {isMobileView ? (
                <i className={`fas fa-chevron-${isExpand ? 'up' : 'down'}`} />
              ) : (
                intl.formatMessage({
                  id: `person.details.experience.${
                    isExpand ? 'hidedescription' : 'viewdescription'
                  }`,
                })
              )}
              {!isMobileView && <span>{isExpand ? ' -' : ' +'}</span>}
            </button>
          </ExpandButtonWrapper>
        )}
      </Content>
    </CardStyled>
  )
}

const Experience = ({ person }) => {
  const intl = useIntl()
  return (
    <ContentSectionExpandable
      header={intl.formatMessage({
        id: 'person.details.experience',
        defaultMessage: 'Experience',
      })}
      items={person.experience}
      ContentComponent={ExperienceItem}
    />
  )
}

export default Experience
