import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Text } from './styles/text-styled'
import validate from 'services/validation/text'

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const InputText = ({
  onChangeCallback,
  onBlurCallback,
  onErrorCallback,
  input: inputDetails,
  size,
  noTitle,
  label,
  isSectionDescrtiption,
}) => {
  const [input, setInput] = useState(inputDetails)

  useEffect(() => {
    if (input.dataType === 'custom-attribute') setInput(inputDetails)
  }, [inputDetails, input.dataType])

  const handleInput = (e) => {
    setInput({ ...input, value: e.target.value })
    if (inputDetails.error) {
      onErrorCallback(null)
    }
    if (onChangeCallback) onChangeCallback(e.target.value)
  }

  const handleOnBlur = () => {
    const error = handleValidation()
    if (error) {
      return onErrorCallback(error)
    } else return onBlurCallback ? onBlurCallback(input.value) : null
  }

  const handleValidation = () => {
    return validate(input, input.required)
  }

  return (
    <Text
      isSectionDescrtiption={isSectionDescrtiption}
      className={`ui-kit ui-kit-input input-text size-${size || 'm'} ${
        inputDetails.error ? 'error' : ''
      }`}
    >
      {!noTitle && input.name && input.name !== '' && (
        <label>
          {label}
          {input.optional ? <span className="optional">(optional)</span> : null}
          {input.required ? <span className="asterix">*</span> : null}
        </label>
      )}
      {!input.isMultiline ? (
        <input
          onBlur={() => handleOnBlur()}
          type={input.type}
          value={input.value}
          onChange={(e) => handleInput(e)}
          placeholder={input.placeholder || ''}
          maxLength={input.maxLength}
          disabled={inputDetails.disabled || false}
          data-testid={`textInput-${input.key || input.testid || input.name}`}
        />
      ) : (
        <textarea
          onBlur={() => handleOnBlur()}
          type={input.type}
          value={input.value}
          onChange={(e) => handleInput(e)}
          placeholder={input.placeholder || ''}
          disabled={inputDetails.disabled || false}
          maxLength={input.maxLength}
          rows={input.rows || '3'}
          cols={input.cols || '80'}
        />
      )}

      {inputDetails.error ? (
        <label className="error-message">{inputDetails.error}</label>
      ) : null}
    </Text>
  )
}

export default connect(mapStateToProps)(InputText)
