import React from 'react'
import { ListBlock, Avatar, ContentWrapper } from './styles/popular-topics'
import { useTheme } from 'emotion-theming'
import { Loader } from '_components/loading'

const PopularTopics = ({ topics }) => {
  const theme = useTheme()

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }
  if (!topics) return <Loader loading={!topics} testid="popularTopics" />

  return (
    <>
      {topics?.popularTopics?.topicsList.slice(0, 5).map(function (topic, i) {
        return (
          <ListBlock key={i}>
            <Avatar>
              <img
                src={topic?.user?.picture || theme?.images?.defaultAvatar}
                alt=""
              />
            </Avatar>
            <ContentWrapper to={`/forum/topic/${topic.tid}`}>
              <p
                dangerouslySetInnerHTML={{
                  __html: unescape(topic.title),
                }}
                data-testid={`popularTopic-${topic?.title}`}
              ></p>
            </ContentWrapper>
          </ListBlock>
        )
      })}
    </>
  )
}

export default PopularTopics
