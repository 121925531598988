import React, { useState } from 'react'
import styled from '@emotion/styled'
import ClickAway from '_components/click-away'

const IconPickerContainer = styled(ClickAway)`
  .fa-chevron-down {
    transform: rotate(180deg);
    transition: .2s;
  }

  &.closed button .fa-chevron-down {
    transform: rotate(0deg);
  }

  &.open button, button:focus:not(:focus-visible) {
    outline: solid 2px var(--flavor);
  }

  &.closed .dropdown {
    max-height: 0px;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: 0px 0px 10px 0px rgb(33 33 33 / 0%);
  }

  .dropdown {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 6px;
    box-shadow: 0px 2px 5px 3px rgb(33 33 33 / 12%);

    gap: 6px;
    padding: 12px;
    width: max-content;
    margin-top: 10px;

    position: absolute;
    z-index: 2;
    background: var(--background);

    max-height: 700px;
    transition: .2s;

    .iconBox {
      cursor: pointer;
      border-radius: 6px;
      border: solid 1px var(--shade);
      transition: .2s;

      &:hover {
        background-color: var(--flavor);
        color: var(--flavor-contrast)
      }

      &:active {
        background-color: var(--foreground);
        color: var(--background)
      }
    }
  }
`

const IconBoxDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  aspect-ratio: 1 / 1;
`

const IconButtonStyled = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;
  border-radius: 6px;
  padding: 1px 6px;

  transition: .1s;

  outline: solid 1px var(--shade);

  &:hover {
    outline: solid 2px var(--flavor);
  }

  &:active {
    outline: solid 10px var(--flavor);
  }

  .divider {
    width: 1px;
    height: 20px;
    background-color: var(--shade);
  }
` 

const defaultIcons = [
  "fa fa-twitter",
  "fa fa-facebook",
  "fa fa-google",
  "fa fa-instagram",
  "fa fa-linkedin",
  "fa fa-youtube",
  "fa fa-twitch",
  "fa fa-pinterest",
  "fa fa-reddit",
  "fa fa-tumblr",
  "fa fa-vimeo",
  "fa fa-snapchat-ghost",
  "fa fa-slack",
]

export const IconBox = ({icon = "fa fa-picture-o", className, onClick = () => {}}) => {
  return (
    <IconBoxDiv className={`iconBox ${className}`} onClick={() => onClick()}>
      <i className={icon}></i>
    </IconBoxDiv>
  )
}

const IconButton = ({icon, onClick = () => {}, edit = false}) => {
  return (
    <IconButtonStyled onClick={() => onClick()}>
      <IconBox icon={icon}/>
      <div className="divider"/>
      <IconBox icon="fas fa-chevron-down"/>
    </IconButtonStyled>
  )
}

const IconDropdown = ({iconClick, icons}) => {
  return (
    <div className={`dropdown`}>
      {icons.map((i) => <IconBox 
        onClick={() => iconClick(i)} 
        key={i} icon={i}
      />)}
    </div>
  )
}

export const IconPicker = ({icon = "fa fa-picture-o", setIcon = () => {}, icons = defaultIcons, onIconPick = () => {}}) => {
  const [dropdown, setDropdown] = useState(false)
  
  const onIconClick = i => {
    setDropdown(false)
    setIcon(i)
    onIconPick(i)
  }

  return (
    <IconPickerContainer 
      className={`${dropdown ? "open" : "closed"}`}
      onClickOutside={() => setDropdown(false)}
    >
      <IconButton onClick={() => setDropdown(!dropdown)} icon={icon}/>
      <IconDropdown iconClick={(i) => onIconClick(i)} icons={icons}/>
    </IconPickerContainer>
  )
}

export default IconPicker