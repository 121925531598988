import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Keywords from './keywords'
import Taxonomy from './taxonomy'
import CustomAttributes from './custom-attributes'

const defaultManageDataRoute = '/data'
const defaultRoute = `/manage${defaultManageDataRoute}`

const Routes = () => {
  return (
    <Switch>
      <Route path={`${defaultRoute}/keywords`} render={() => <Keywords />} />
      <Route path={`${defaultRoute}/topics`} render={() => <Taxonomy />} />
      <Route
        path={`${defaultRoute}/custom-attributes`}
        render={() => <CustomAttributes />}
      />
      <Redirect from={defaultRoute} to={`${defaultRoute}/keywords`} />
    </Switch>
  )
}

export default Routes
