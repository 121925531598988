import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import Button from '_components/button'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { useUser } from '_security/session-hooks'
import border from '_utils/css-border'
import { ctaButton, ctaButtonHollow } from '_utils/css-buttons'

const ModalStyled = styled(Modal)`
  .modal-content {
    background: var(--background);
    ${border()}
  }

  .info-title {
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 24px;
    color: var(--foreground);
    font-weight: 600;
  }

  .info-description {
    margin-top: 10px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 14px;
    color: var(--foreground);
    font-weight: 400 !important;
  }

  .close-button {
    margin-right: 10px;
    ${ctaButtonHollow}
  }

  .signin-button {
    margin-right: 10px;
    ${ctaButton}
  }

  .modal-footer {
    border-top: none;
    text-align: center;
    padding-bottom: 15px !important;
  }
`

const getModalProps = ({ user }) => {
  const isLogged = user?.profile?.role
  if (isLogged) {
    return {
      type: 'subscription',
      link: '/user/profile/subscriptions',
    }
  } else {
    return {
      type: 'login',
      link: '/login',
    }
  }
}

const Index = ({
  title,
  description,
  canShowInfoModal,
  setCanShowInfoModal,
  proceedBtnContent,
  testid,
}) => {
  const intl = useIntl()
  const handleClose = () => {
    // setCanShowInfoModal(false)
    setCanShowInfoModal(false)
  }

  const user = useUser()

  const modalProps = getModalProps({ user })

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `${modalProps.type.toLowerCase()}.required.modal.${value.toLowerCase()}`,
    })
  }

  return (
    <ModalStyled
      dialogClassName="info-modal-container"
      show={canShowInfoModal}
      onHide={handleClose}
    >
      <Modal.Body className="info-modal-body">
        <div
          className="all-info-container"
          data-testid={`modal-${testid ?? 'default'}`}
        >
          <h2 className="info-title">{title || renderMessageText('title')}</h2>
          <p className="info-description">
            {description || renderMessageText('description')}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={handleClose}
          className="close-button"
          data-testid="btn-modal-close"
        >
          {renderMessageText('close')}
        </Button>
        <Link to={modalProps.link}>
          <Button
            type="button"
            className="signin-button"
            data-testid="btn-modal-proceed"
          >
            {proceedBtnContent || renderMessageText('proceed')}
          </Button>
        </Link>
      </Modal.Footer>
    </ModalStyled>
  )
}

export default Index
