import React, { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { useAppConfig } from '_application/app-config'
import axios from 'axios'
import fallbackTranslations from './fallbacks'
import localeAntd from 'antd/es/locale/en_US'

const defaultLocale = 'en_GB'

const Localization = ({ children }) => {
  const config = useAppConfig()
  const locale = config?.defaultLocale || defaultLocale
  const fallback = fallbackTranslations[locale]
  const [localeFile, setLocaleFile] = useState(
    fallback ? fallback : fallbackTranslations[defaultLocale]
  )

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/translation/get-translation/${locale}`)
      .then((r) => setLocaleFile(r?.data))
      .catch((e) => setLocaleFile(fallbackTranslations[defaultLocale]))
  }, [locale])

  return (
    <ConfigProvider locale={localeAntd}>
      <IntlProvider locale={localeAntd.locale} messages={localeFile}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default Localization
