import styled from '@emotion/styled'

export const SearchInputWrapper = styled.div`
  position: relative;
  z-index: ${({ isInputActive }) => (isInputActive ? '3' : '2')};
  width: 410px;
  margin-bottom: 10px;
  .connections-search-input {
    z-index: 2;
    padding:10px;
    border-radius: 6px;
  }
`