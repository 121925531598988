import React, { useState, useCallback, useMemo } from 'react'
import { UPDATE_THEME_OVERRIDES } from './graph-queries'
import { useMutation } from '@apollo/client'
import { GET_CONFIG, useAppConfig } from '_application'
import { loadTheme } from '_themes'

import {
  Section,
  SubSection,
  Controls,
  EditorStyled,
  AddFlavor,
} from './styles/editor'
import ColorPicker from 'components/indy/shared/color-picker'
import { useIntl } from 'react-intl'
import colorBus from '_colorBus'

const colorFields = [
  { key: 'background', name: 'Background' },
  { key: 'foreground', name: 'Text' },
  { key: 'cardColor', name: 'Card' },
  { key: 'backgroundColor', name: 'Background on blog' },
  //{ key: 'footerColor', name: 'Footer' },
]

const fontFields = [
  { key: 'Inter', name: 'Inter' },
  { key: 'Helvetica Neue', name: 'Helvetica Neue' },
  { key: 'Open Sans', name: 'Open Sans' },
  { key: 'Roboto', name: 'Roboto' },
  { key: 'Poppins', name: 'Poppins' },
  { key: 'Lora ', name: 'Lora ' },
  { key: 'Cormorant Garamond ', name: 'Cormorant Garamond ' },
]

const defaultFont = 'Inter'

const ColorSection = ({ color, onChange, name, onDelete }) => {
  return (
    <SubSection>
      <div className="content-container colors">
        <ColorPicker color={color} onChange={(color) => onChange(color)} />
      </div>
      <span className="title">{name}</span>
      {onDelete && (
        <div className="controls">
          <i onClick={onDelete} className="fas fa-trash" />
        </div>
      )}
    </SubSection>
  )
}

const Editor = ({ themeOverride, themeName, setThemeName }) => {
  const config = useAppConfig()
  const intl = useIntl()
  const [theme, setTheme] = useState()
  const [isReset, setIsReset] = useState()

  const loadThemeValues = useCallback(async ({ themeOverride, config }) => {
    const t = await loadTheme(themeOverride.name, config)
    setTheme(t.values)
    colorBus(t?.values?.colors)
    setThemeValues({ ...t.values.colors, ...t.values.fonts })
  }, [])

  useMemo(() => {
    setIsReset(false)
    loadThemeValues({ themeOverride, config })
  }, [themeOverride, config, loadThemeValues])

  const [updateThemeOverrides] = useMutation(UPDATE_THEME_OVERRIDES)
  const [themeValues, setThemeValues] = useState({})

  const handleValueChange = ({ value, key }) => {
    setIsReset(false)
    const newThemeValues = { ...themeValues, [key]: value }
    setThemeValues(newThemeValues)
    colorBus(newThemeValues)
  }

  const handleUpdateThemeOverrides = () => {
    let overrides = colorFields
      .map((c) => {
        if (themeValues[c.key] !== theme.colors[c.key] || isReset) {
          return { key: c.key, value: themeValues[c.key] }
        }
        return null
      })
      .filter((o) => o)
    if (flavors?.length > 0) {
      overrides = [
        ...overrides,
        { key: 'flavor', value: flavors?.join(',') || '' },
      ]
    }
    if (themeValues['googleFont'] !== theme.colors['googleFont']) {
      overrides = [
        ...overrides,
        { key: 'googleFont', value: themeValues['googleFont'] },
      ]
    }

    const override = { name: themeOverride.name, values: overrides }

    colorBus(override)
    updateThemeOverrides({
      variables: { themeOverride: override, themeName },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const handleOnReset = () => {
    setIsReset(true)
    loadThemeValues({
      themeOverride,
      config: { ...config, themeOverrides: null },
    })
    setThemeName(config.theme || 'default')
  }

  const handleOnSave = () => handleUpdateThemeOverrides()

  const handleSetActiveTheme = () => {
    setThemeName(themeOverride?.name)
  }

  const updateFlavorList = ({ color, index }) => {
    return flavors?.map((f, i) => (i === index ? color : f)) || []
  }

  const flavors = themeValues['flavor'] || ['fff']

  const isActiveTheme =
    themeName === themeOverride.name ||
    (themeOverride.name === 'default' && !config.theme)

  if (!theme) return null

  return (
    <EditorStyled>
      <Section>
        <span className="title">
          {intl.formatMessage({ id: 'admin.branding.themes.colors' })}
        </span>
        {colorFields?.map((f) => (
          <ColorSection
            color={themeValues[f.key]}
            onChange={(color) =>
              handleValueChange({ key: f.key, value: color })
            }
            name={intl.formatMessage({
              id: `admin.branding.themes.${f?.key?.toLowerCase()}`,
            })}
          />
        ))}
        {flavors?.map((f, i) => (
          <ColorSection
            color={f}
            onChange={(color) =>
              handleValueChange({
                key: 'flavor',
                value: updateFlavorList({ color, index: i }),
              })
            }
            name={`${intl.formatMessage({
              id: `admin.branding.themes.flavor`,
            })} ${i + 1}`}
            onDelete={
              i === 0
                ? null
                : () =>
                    handleValueChange({
                      key: 'flavor',
                      value: flavors?.filter((f, index) => i !== index),
                    })
            }
          />
        ))}
        {flavors?.length < 10 && false && (
          <AddFlavor
            onClick={() =>
              handleValueChange({ key: 'flavor', value: [...flavors, '#fff'] })
            }
          >
            {intl.formatMessage({ id: 'admin.branding.themes.addflavor' })}
          </AddFlavor>
        )}
      </Section>
      <Section>
        <span className="title">
          {intl.formatMessage({ id: 'admin.branding.themes.fonts' })}
        </span>
        <SubSection>
          <span className="title">
            {intl.formatMessage({ id: 'admin.branding.themes.googlefont' })}
          </span>
          <div className="content-container fonts">
            <select
              className="font-selector"
              value={themeValues['googleFont']}
              onChange={(e) =>
                handleValueChange({
                  key: 'googleFont',
                  value: e?.target?.value,
                })
              }
            >
              {fontFields.map((f) => {
                return (
                  <option value={f.key}>{`${f.name}${
                    f.key === defaultFont
                      ? ' (' +
                        intl.formatMessage({ id: 'global.label.default' }) +
                        ')'
                      : ''
                  }`}</option>
                )
              })}
            </select>
          </div>
        </SubSection>
      </Section>
      <Controls>
        {!isActiveTheme && (
          <button
            className="set-active-theme"
            onClick={() => handleSetActiveTheme()}
          >
            {intl.formatMessage({ id: 'admin.branding.themes.activatetheme' })}
          </button>
        )}
        <button onClick={() => handleOnReset()}>
          {intl.formatMessage({ id: 'admin.branding.themes.reset' })}
        </button>
        {
          <button onClick={() => handleOnSave()}>
            {intl.formatMessage({ id: 'admin.branding.themes.save' })}
          </button>
        }
      </Controls>
    </EditorStyled>
  )
}

export default Editor
