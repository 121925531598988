import React from 'react'
import Toggle from 'react-toggle'
import ToggleButtonWithNameStyled from './toggle-button-with-name-styled'
import 'react-toggle/style.css'

const ToggleButton = ({
  name,
  checked,
  onChange,
  className,
  testid,
  size,
  isMapToggle = false,
}) => {
  return (
    <ToggleButtonWithNameStyled
      className={`toggle-with-name-container ${
        size && size === 'lg' ? 'large' : ''
      } ${className}`}
    >
      <span
        className="name"
        data-testid={`btn-toggle-${testid ?? 'default'}-${
          checked ? 'checked' : 'unchecked'
        }`}
      >
        {name}
      </span>
      <Toggle
        icons={{ checked: null, unchecked: null }}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </ToggleButtonWithNameStyled>
  )
}

export default ToggleButton
