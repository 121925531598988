import DiscoverCompanies from '_discover/connected/discover-companies-connected'
import DiscoverOpportunities from '_discover/connected/discover-opportunities-connected'
import DiscoverPeople from '_discover/connected/discover-people-connected'
import DiscoverActivities from '_discover/connected/discover-activities-connected'
import DiscoverNews from '_discover/connected/discover-news-connected'
import DiscoverEvents from '_discover/connected/discover-events-connected'
export const customBlocks = [
  {
    id: 'DiscoverOpportunities',
    component: DiscoverOpportunities,
    thumbnail: 'customblocks/opportunities.png',
  },
  {
    id: 'DiscoverNews',
    component: DiscoverNews,
    thumbnail: 'customblocks/blogs.png',
  },
  {
    id: 'DiscoverPeople',
    component: DiscoverPeople,
    thumbnail: 'customblocks/people.png',
  },
  {
    id: 'DiscoverActivities',
    component: DiscoverActivities,
    thumbnail: 'customblocks/activities.png',
  },
  {
    id: 'DiscoverCompanies',
    component: DiscoverCompanies,
    thumbnail: 'customblocks/companies.png',
  },
  {
    id: 'DiscoverEvents',
    component: DiscoverEvents,
    thumbnail: 'customblocks/Events.png',
    feature: 'events',
  },
]
