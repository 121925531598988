import React from 'react'
import styled from '@emotion/styled'

import { ctaButton } from '_utils/css-buttons'
import { css } from '@emotion/core'

const StyledButton = styled.button`
  margin-left: ${({ isFirstStep }) => isFirstStep && 'auto'};
  padding: 8px 18px 8px 0;
  background-color: transparent;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  color: var(--foreground);
  border: none;

  i {
    font-size: 16px;
    color: var(--foreground);
    margin-right: 8px;
  }

  &.Next-button,
  &.finalStep-button {
    text-decoration: none;
    ${ctaButton};

    background-color: ${({ isNameError }) =>
      isNameError &&
      css`
        background-color: var(--shade) !important;
        border-color: var(--shade) !important;
        opacity: 0.8;
        color: var(--foreground);
      `}

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
export const Button = ({
  isButtonActive,
  handleClick,
  option,
  children,
  testid,
  isFirstStep,
}) => {
  return (
    <StyledButton
      option={option}
      isFirstStep={isFirstStep}
      onClick={handleClick}
      className={`${typeof option === 'string' ? option : 'finalStep'}-button`}
      data-testid={
        testid ? `onboarding-btn-${testid}` : 'onboarding-btn-styled'
      }
    >
      {option === 'Previous' && <i className="fas fa-arrow-left" />}
      {children ? children : option}
    </StyledButton>
  )
}
