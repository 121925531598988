import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import FilterHeader from '_components/dashboard/dashboard-filter-header'
import { SORT_OPTIONS } from '_utils/sort'
import { useUser } from '_security/session-hooks'

const Props = {
  filter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
}

const ActivitiesHeader = ({ filter, onFilterChange }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const isLoggedIn = useUser()?.profile?.role
  const options = useMemo(
    () => [
      {
        key: 'connected',
        label: intl.formatMessage(
          { id: 'activities.filter.connectedtome' },
          {
            category: appLabel(
              'activitiesPlural',
              'activities.titleplural'
            ),
          }
        ),
      },
      {
        key: 'createdByMe',
        label: intl.formatMessage({ id: 'activities.filter.createdbyme' }),
      },
      ...isLoggedIn ? [{
        key: 'draft',
        label: intl.formatMessage({ id: 'sorting.sanity.drafts', defaultMessage: 'My drafts' }),
      }] : [],
      ...SORT_OPTIONS(intl),
    ],
    [intl, appLabel, isLoggedIn]
  )

  return (
    <FilterHeader
      basePath="/activities"
      filter={filter}
      filterOptions={options}
      onFilterChange={onFilterChange}
    />
  )
}
ActivitiesHeader.propTypes = Props

export default ActivitiesHeader
