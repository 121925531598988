import React, { useEffect, useState } from 'react'
import { usePluginsPublic } from '_application/plugins'
import ReactGALegacy from 'react-ga'
import ReactGA from 'react-ga4'
import LocationApi from './location-api'

const GoogleAnalytics = ({ children, history }) => {
  const [isInit, setIsInit] = useState()
  const { plugin } = usePluginsPublic('google-analytics') || {}
  const { measurementId, trackingId } = plugin?.data || {}

  // TrackingId is for Google Analytics V3 and all legacy versions
  // MeasurmentId is for Google Analytics V4 data stream

  useEffect(() => {
    if ((measurementId || trackingId) && !isInit && plugin?.enabled) {
      setIsInit(true)

      if (trackingId) {
        ReactGALegacy.initialize(trackingId)
        addPageViewEvent({ url: window?.location?.pathname })
      }
      if (measurementId) {
        ReactGA.initialize(measurementId)
      }
    }
  }, [measurementId, isInit, plugin, trackingId])

  history.listen((location) => {
    if (plugin?.enabled && trackingId) {
      addPageViewEvent({ url: location?.pathname })
    }
  })

  const addPageViewEvent = ({ url }) => {
    ReactGALegacy.pageview(url)
  }

  return (
    <>
      <LocationApi />
      {children}
    </>
  )
}

export default GoogleAnalytics
