import styled from '@emotion/styled'
import css from '@emotion/css'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: var(--foreground);
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--foreground);
    margin-bottom: 10px;
  }

  .upload-btn {
    border: 2px solid var(--flavor);
    border-radius: 25px;
    padding: 5px 20px;
    background-color: var(--flavor);
    color: var(--background);
    font-size: 14px;
    font-weight: 700;
    max-width: 145px;

    ${({ hasLimit }) =>
      hasLimit &&
      css`
        background: var(--shade);
        border-color: var(--shade);
        cursor: not-allowed;
      `}
  }
`
