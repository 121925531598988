import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import DashboardMessagesSingleConversation from '../dashboard-messages-single-conversation'

import './style.scss'

const mapStateToProps = ({ user, message, dispatch, messages }) => ({
  user,
  message,
  dispatch,
  messages,
})

const MessageListContainer = styled.div`
  margin-top: 40px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }
`

const Index = ({
  setActiveChannel,
  setActiveIndex,
  dispatch,
  message,
  user,
  messages,
}) => {
  return (
    <MessageListContainer>
      {messages.conversations.map &&
        messages.conversations.map((c) => {
          return (
            <DashboardMessagesSingleConversation
              conversation={c}
              setActiveChannel={setActiveChannel}
              setActiveIndex={setActiveIndex}
            />
          )
        })}
    </MessageListContainer>
  )
}

export default connect(mapStateToProps)(Index)
