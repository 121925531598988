import React, { useState } from "react"
import styled from '@emotion/styled'

const IconActionButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px;
  border: none;
  background-color: transparent;

  .dot {
    width: 20px;
    height: 20px;
    background-image: radial-gradient(circle, var(--foreground) 1px, transparent 2px);
    background-size: 100% 33.33%;
    margin-left: 2px;
    margin-right: 2px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    aspect-ratio: 1 / 1;
    padding: 0px var(--space-s);
    border-radius: var(--radius);

    transition: .4s;
  }

  &.inactive .icon {
    transform: rotate(90deg);
  }

  &.active .icon {
    transform: rotate(0deg);
    background: var(--flavor);
    .dot {
      width: 5px;
      aspect-ratio: 1 / 1;
      background-image: radial-gradient(circle, var(--flavor-contrast) 1px, transparent 2px);
    }
  }

  &.inactive .dropdown {
    height: 0px;
    padding: 0px;
    box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%);
    outline: solid 0px var(--shade);
  }
  
  .dropdown {
    position: absolute;
    right: 0px;
    top: calc(100% + var(--space-s));
    z-index: 1;

    background-color: var(--background);
    outline: var(--border);
    box-shadow: var(--shadow);
    padding: var(--space-s) 0px;
    border-radius: var(--radius);
    min-width: 120px;
    overflow: hidden;
    transition: .2s;

    p {
      margin: 0px;
      text-align: left;
      padding: var(--space-s) var(--space);
      &:hover {
        background-color: var(--shade-faint);
      }
    }
  }

  & > p {
    font-size: 1.5rem;
  }
`

export const SettingsDropdown = ({
  children,
  testid,
  className = 'settings',
}) => {
  const [dropdown, setDropdown] = useState(false)
  return (
    <IconActionButton
      className={`${className} ${dropdown ? 'active' : 'inactive'}`}
      onClick={() => setDropdown(!dropdown)}
    >
      <div className="icon">
        <div
          className="dot"
          data-testid={`btn-eduExp-threeDots-${testid}`}
        ></div>
      </div>

      <div className="dropdown">{children}</div>
    </IconActionButton>
  )
}
