import styled from '@emotion/styled'

const ButtonRecommend = styled.button`
  position: relative;
  visibility: ${({ isInputDisplayed }) => isInputDisplayed && 'hidden'};
  &:hover {
    opacity: 0.8;
  }
`
export default ButtonRecommend
