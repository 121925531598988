import styled from '@emotion/styled'
import { pablet, tablet, mobile } from '_utils/responsive-queries'

const OnboardingStyled = styled.div`
  padding-top: 80px;
  padding-bottom: 50px;
  background-color: var(--shade);
  ${mobile} {
    padding-bottom: 0;
    padding-top: 65px;
    background-color: var(--background);
  }
  .step-content-container {
    i {
      padding: 0 10px;
    }
  }
  .main-container {
  }
  .base-container {
    max-width: 1038px;
    width: 90%;
    margin: 100px auto 0;
    padding: 0 48px 112px;
    background: var(--background);
    border-radius: 20px;

    .step-title {
      font-size: 22px;
      font-weight: 700;
      padding-bottom: 24px;
    }
    .step-subtitle {
      margin: 0;
      padding-bottom: 40px;
    }

    ${tablet} {
      padding: 0 41px 112px;
    }
    ${mobile} {
      width: 100%;
      padding: 0 16px;
      margin: 0 0 80px;
      background-color: transparent;
    }

    .content-container {
      display: flex;
      gap: 100px;

      ${tablet} {
        gap: 24px;
      }
    }
    .progress-card {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      max-width: 50%;
      ${pablet} {
        flex-basis: 100%;
        max-width: 100%;
      }
      ${mobile} {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    .profile-preview {
      flex-basis: 50%;
      max-width: 50%;

      ${pablet} {
        display: none;
      }

      ${mobile} {
        display: none;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;

    ${mobile} {
      margin-top: 40px;
    }
  }
  .error-message {
    min-height: 21px;
    color: #ff9494;
  }

  .Home-button {
    height: 50px;
  }
  .finalStep-button {
    height: 50px;
  }
`

export default OnboardingStyled
