import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { IconPicker, IconBox } from "_components/icon-picker.jsx"
import { InputStyled } from '_components/InputStyled.js'
import { TextButton } from '_components/indyButton'

const MediaEditorStyled = styled.div`
  button.textButton { padding: 1px 16px 1px 6px;}
`

const MediaLinkStyled = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: auto 1fr 0px;

  max-width: 600px;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    margin-left: 10px;
  }

  .delete {
    cursor: pointer;
    outline: solid 2px var(--shade);
    border-radius: 100%;
    height: 32px;
    transform: translate(-7px,-11px) scale(0.6);
    background: var(--background);
    box-shadow: 0 7px 8px 0px rgb(0 0 0 / 20%);
    transition: .2s;

    i {
      transform: rotate(40deg);
      line-height: 0.7;
      margin-top: 1.6px;
    }

    &:hover {
      outline: solid 3px var(--foreground);
    }
  }
`

//It defaults to the first link in the list if no icon is provided
//so if you want to add more icons, dont change the first one
const socialMediaIcons = [
  "fa fa-link",
  "fa fa-twitter",
  "fa fa-facebook",
  "fa fa-google",
  "fa fa-instagram",
  "fa fa-linkedin",
  "fa fa-youtube",
  "fa fa-twitch",
  "fa fa-pinterest",
  "fa fa-reddit",
  "fa fa-tumblr",
  "fa fa-vimeo",
  "fa fa-snapchat-ghost",
  "fa fa-slack",
]

const MediaLink = ({link, onChange = () => {}, deleteLink = () => {}}) => {
  const [icon, setIcon] = useState(link.icon ? link.icon : "fa fa-link")
  const [url, setUrl] = useState(link.url)

  useEffect(() => {
    const domainIcon = url ? findIcon(url, socialMediaIcons) : "fa fa-link"
    domainIcon && setIcon(domainIcon)
  }, [url])

  useEffect(() => {
    onChange({icon, url})
  }, [icon, url])

  const findIcon = (url, icons) => {
    const result = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]
    const nakedDomain = result.split('.')[0]
    return icons.find(icon => icon.includes(nakedDomain))
  }

  const intl = useIntl()
  const addMediaURLtext = intl.formatMessage({ id: 'admin.sitestructure.footer.media.mediaurl' })

  return (
    <MediaLinkStyled className="MediaLink">
      <IconPicker 
        icon={icon} 
        setIcon={setIcon} 
        icons={socialMediaIcons}/>
      <InputStyled 
        type="url" 
        id="media" 
        name="media" 
        placeholder={addMediaURLtext} 
        value={url} 
        onChange={(e) => setUrl(e.target.value)}
      />
      <IconBox icon="fa fa-plus" className={"delete"} onClick={deleteLink}/>
    </MediaLinkStyled>
  )
}

const AddMediaLink = ({onClick = () => {}, footer}) => {
  const intl = useIntl()
  const addMediaText = intl.formatMessage({ id: 'admin.sitestructure.footer.media.addmedia' })
  return (
    <TextButton className="textButton" onClick={() => onClick()} disabled={footer.media.length > 3}>
      <IconBox icon="fa fa-plus"/>
      <p>{addMediaText} <span>({footer.media.length}/4)</span></p>
    </TextButton>
  )
}

export const MediaEditor = ({
  footer, 
  setFooter = () => {},
}) => {
  const intl = useIntl()

  const addLink = () => {
    setFooter(prev => ({...prev, media: [...prev.media, {icon: "fa fa-link", url: ""}]}))
  }

  const deleteLink = (index) => {
    setFooter(prev => ({...prev, media: prev.media.filter((_, i) => i !== index)}))
  }

  const changeLink = (newLink, index) => {
    setFooter((prev) => {
      let newMedia = [...prev.media]
      newMedia[index] = newLink
      return {...prev, media: newMedia}
    })
  }

  const mediaText = {
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.media.title'}),
    description: intl.formatMessage({ id: 'admin.sitestructure.footer.media.description' }),
    placeholder: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.text.placeholder' }),
  }

  return (
    <MediaEditorStyled className="MediaEditor">
      <p onClick={() => addLink()}><b>{mediaText.title}</b></p>
      <p>{mediaText.description}</p>

      {footer.media.map((link, i) => (<MediaLink 
        link={link} 
        deleteLink={() => deleteLink(i)}
        onChange={(link) => changeLink(link, i)}
      />))}
      <AddMediaLink onClick={addLink} footer={footer}/>
    </MediaEditorStyled>
  )
}

export default MediaEditor
