import React, { useState } from 'react'
import BreakLine from './break-line'
import { useIntl } from 'react-intl'
import { TitleWrapper } from './styles/title'

const Title = ({ value, onSave }) => {
  const [inputValue, setInputValue] = useState(value || '')
    const intl = useIntl()

const characterLimit = 90

  return (
    <>
      <TitleWrapper>
        <label htmlFor="eventTitle">
          {intl.formatMessage({
            id: 'contentstudio.entity.title',
            defaultMessage: 'Title',
          })}
          <span className="required-star">*</span>
        </label>
        <input
          type="text"
          name="title-input"
          className="title-input"
          maxLength={characterLimit}
          value={inputValue}
          placeholder={intl.formatMessage({
            id: 'contentstudio.entity.title.placeholder',
            defaultMessage: 'Provide title',
          })}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => onSave(inputValue)}
        />
        <span className="character-limit-indicator">{`${intl.formatMessage({
          id: 'contentstudio.entity.title.charlimit',
          defaultMessage: 'Character left:',
        })} ${characterLimit - inputValue.length}`}</span>
      </TitleWrapper>
      <BreakLine />
    </>
  )
}

export default Title

