import React from 'react'
import { connect } from 'react-redux'
import ContentSection from './content-section'
import { useQuery, gql } from '@apollo/client'

const mapStateToProps = ({ user }) => ({ user })

export const StaticPage = ({ id, user, iframeSrc }) => {
  const GET_PAGE = gql`
  query {
    getEmbedPage(id: "${id}") {
      title
      url
    }
  }
`
  const { data } = useQuery(GET_PAGE, { skip: iframeSrc })
  return (
    <div>
		<ContentSection data={data} iframeSrc={iframeSrc} />
    </div>
  )
}

export default connect(mapStateToProps)(StaticPage)
