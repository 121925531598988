import React from 'react'
import PropTypes from 'prop-types'
import { Tile as CompanyTile } from '_companies'

const Props = {
  company: PropTypes.shape({
    cover: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

const ConnectedCompanyTile = ({ url, company, showConnections }) => (
  <CompanyTile
    url={url}
    company={company}
    tileHeight="410px"
    showConnections={showConnections}
  />
)
ConnectedCompanyTile.propTypes = Props

export default ConnectedCompanyTile
