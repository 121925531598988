import styled from '@emotion/styled'

export const Button = styled.button`
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.thirdColor};
  border-radius: 15px;
  background-color: #7cb3c7;
  text-transform: uppercase;
`
