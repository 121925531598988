import React, { useState, useMemo } from 'react'
import { useAppLabel } from '_application'
import { useIntl } from 'react-intl'
import { useQuery, useMutation } from '@apollo/client'
import { MbToB } from 'services/file'
import { useAppConfig } from '_application'
import { useUser } from '_security/session-hooks'
import BannerImage from '../../components/banner-image'
import ShortDescription from '../../components/short-desc'
import Title from '../../components/title'
import Layout from '../../components/layout'
import ToggleButton from '../../components/toggle-button'
import CategorySelector from '../../components/category-selector'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import Loading from '_components/loading'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import { WysiwygWrapper } from '_content-studio/components/styles/wysiwyg-wrapper'
import { uuid4 } from '@sentry/utils'
import BreakLine from '_content-studio/components/break-line'
import { getFormDataFields } from './helpers'
import { GET_BLOGPOST, SAVE_BLOGPOST, GET_CATEGORIES } from './gql'

import AuthorSelector from '_content-studio/components/owner-selector'

export const BlogForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    coverPhoto: '',
    enabledToc: false,
    content: '',
    isRestricted: false,
    isFeatured: false,
    publishedAt: null,
    categoriesNew: [],
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [saveBlogPost] = useMutation(SAVE_BLOGPOST)
  const intl = useIntl()
  const appLabel = useAppLabel()

  const { id } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  
  const { loading, error, data } = useQuery(GET_BLOGPOST, {
    variables: {
      id,
    },
    skip: !id,
  })
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_CATEGORIES)
  const blogCategories = categoriesData?.getBlogCategories
  
  const entity = data?.getBlogPost

  const { sub, profile } = useUser()

  const isAdmin = profile?.role === 'admin'
  const isOwner = profile?.sub === entity?.creator?.sub

  const { tenantId } = useAppConfig()

  const tagsConfig = {
    userId: sub,
    tenantId: tenantId,
    context: 'content-studio-blog-post-banner-image',
    mediaId: 'postId',
  }

  const uploadConfig = {
    folderName: 'content-studio',
    tagsConfig,
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const onSubmit = () => {
    saveBlogPost({
      variables: {
        input: formData,
      },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPostsPublic' })
        cache.evict({ id: `Post:{"id":"${id}"}` })
      },
    })
  }

  const changeFormProperty = (change) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...change,
      }
    })
  }
  const resolveCategory = (categoryKey) => {
    const temp = []
    blogCategories.forEach((cat) => temp.push(cat))
    blogCategories.forEach((cat) =>
      cat.subcategories?.forEach((sub) => temp.push(sub))
    )

    return temp.find((t) => t.id === categoryKey)
  }
  const resolveCategoryByName = (categoryName) => {
    if (!blogCategories) return null
    const temp = []
    blogCategories.forEach((cat) => temp.push(cat))
    blogCategories.forEach((cat) =>
      cat.subcategories?.forEach((sub) => temp.push(sub))
    )

    return temp.find((t) => t.name === categoryName)
  }
  const onSaveCategories = (data) => {
    setSelectedCategories(data)
    const transformedCategories = data.map((cat) =>
      cat.childrenKeys.length > 0
        ? cat.childrenKeys?.map((sub) => {
            return {
              id: sub,
              parentId: cat.key,
              groupBy: resolveCategory(cat.key)?.name,
              name: resolveCategory(sub)?.name,
            }
          })
        : {
            id: cat.key,
            name: resolveCategory(cat.key)?.name,
            groupBy: resolveCategory(cat.key)?.name,
          }
    )
    setFormData({ ...formData, categoriesNew: transformedCategories.flat() })
  }

  const getTocFromPostContent = (value) => {
    var parser = new DOMParser()
    var doc = parser.parseFromString(value, 'text/html')

    const allHeaders = doc.content.querySelectorAll('h2, h3, h4')

    const formattedHeaders = [...allHeaders]?.map((el) => ({
      children: [
        {
          text: el.innerHTML,
        },
      ],
      _key: uuid4(),
      style: el.localName,
    }))
  }
  const convertCategoriesIntoSelection = (categories) => {
    const temp = []
    if (categories && categories.length > 0) {
      categories.forEach((cat) => {
        if (cat.groupBy) {
          temp.push({
            key: resolveCategoryByName(cat.groupBy)?.id,
            name: cat.groupBy,
            childrenKeys: [],
          })
        }
      })
      categories.forEach((cat) => {
        if (cat.groupBy !== cat.name) {
          const findParent = temp.find((t) => t.name === cat.groupBy)

          if (findParent) {
            findParent.childrenKeys.push(cat.id)
          } else {
            temp.push({
              key: cat.id,
              name: cat.name,
              childrenKeys: [],
            })
          }
        }
      })
    }
    return temp
  }
  useMemo(async () => {
    if (!id) {
      const entity = await saveBlogPost()
      setFormData({ ...formData, id: entity?.data?.saveBlogPost?.id })
    }
    if (data) setFormData(getFormDataFields(data.getBlogPost))
    if (data) {
      setSelectedCategories(
        convertCategoriesIntoSelection(data.getBlogPost.categoriesNew)
      )
      if(!isOwner && !isAdmin) {
        history.push(`/blog`)
      }
    }
  }, [data])

  if (id && loading) return <Loading /> // todo spinner comp
  if (error) return `Error! ${error}`

  const title = `${
    pathname?.split('/')?.pop()?.toLowerCase() === 'edit' ? 'Edit' : 'New'
  } blog post`

  const isSubmitDisabled =
    formData?.title.length === 0 ||
    formData?.description.length === 0 ||
    formData?.content === '<p><br></p>' ||
    formData?.content.length === 0

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={isSubmitDisabled}>
      <h1>{title}</h1>

      <Title
        onSave={(value) => changeFormProperty({ title: value })}
        value={formData.title}
      />
      <ShortDescription
        onSave={(value) => changeFormProperty({ description: value })}
        value={formData.description}
      />

      <WysiwygWrapper>
        <label htmlFor="postContent" className="desc-label">
          Description
          <span className="required-star">*</span>
        </label>
        <span>What is your post about?</span>

        {renderField({
          attribute: {
            type: 'wysiwyg',
            name: 'postContent',
            heightMin: 515,
            heightMax: 650,
            editorType: 'postContent',
            editorValue: formData.content,
            imgUploadFolder: 'blog',
            imgTagsConfig: {
              context: 'post-description-content',
              eventId: 'postId',
              userId: sub,
            },
          },
          onSave: (value) => {
            changeFormProperty({ content: value })
            // getTocFromPostContent(value)
          },
        })}
      </WysiwygWrapper>

      <BreakLine />

      <AuthorSelector
        entity={entity}
        onSave={(newCreatorId) => changeFormProperty({ newCreatorId })}
      />

      <CategorySelector
        allCategories={blogCategories || []}
        selectedCategories={selectedCategories}
        onSave={(data) => onSaveCategories(data)}
      />

      <BreakLine />

      <BannerImage
        uploadConfig={uploadConfig}
        bannerImg={formData?.coverPhoto}
        onSave={(value) => changeFormProperty({ coverPhoto: value })}
        type="blog"
        isRequired={false}
      />

      <ToggleButton
        value={formData.enabledToc}
        onSave={(value) => changeFormProperty({ enabledToc: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.toc.toggle.title',
            defaultMessage: 'Table of contents',
          }),
          desc: intl.formatMessage({
            id: 'contentstudio.additional.date.toc.toggle.description',
            defaultMessage: `Enable Table of Contents (H1, H2 and H3)`,
          }),
        }}
      />

      <ToggleButton
        value={formData.isRestricted}
        onSave={(value) => changeFormProperty({ isRestricted: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.ispremium.toggle.title',
            defaultMessage: 'Premium content',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.ispremium.toggle.description',
              defaultMessage: `Enable ${appLabel(
                'blog',
                'blog.title'
              )} as a premium content`,
            },
            { entity: appLabel('blog', 'blog.title') }
          ),
        }}
      />

      <ToggleButton
        value={formData.isFeatured}
        onSave={(value) => changeFormProperty({ isFeatured: value })}
        config={{
          title: intl.formatMessage({
            id: 'contentstudio.additional.date.isfeature.toggle.title',
            defaultMessage: 'Featured',
          }),
          desc: intl.formatMessage(
            {
              id: 'contentstudio.additional.date.isfeature.toggle.description',
              defaultMessage: `Enable ${appLabel(
                'blog',
                'blog.title'
              )} as a feature`,
            },
            { entity: appLabel('blog', 'blog.title') }
          ),
        }}
      />
      {isAdmin && (
        <ToggleButton
          value={formData.isDraft}
          onSave={(value) => changeFormProperty({ isDraft: value })}
          config={{
            title: intl.formatMessage({
              id: 'contentstudio.additional.date.isdraft.toggle.title',
              defaultMessage: 'Is draft?',
            }),
            desc: intl.formatMessage(
              {
                id: 'contentstudio.additional.date.isdraft.toggle.description',
                defaultMessage: `If this ${appLabel(
                  'blog',
                  'blog.title'
                )} is draft`,
              },
              { entity: appLabel('blog', 'blog.title') }
            ),
          }}
        />
      )}
    </Layout>
  )
}

export default BlogForm

