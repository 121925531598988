import styled from '@emotion/styled'

export const ParagraphSmall = styled.p`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: var(--foreground);
  blockquote {
    color: var(--foreground);
  }
`

export const TextSmall = styled.span`
  color: var(--foreground);
  font-size: 12px;
`

export const Capitalize = styled.span`
  text-transform: capitalize;
`

export const LowerCase = styled.span`
  text-transform: lowercase;
`

export default {
  ParagraphSmall,
  TextSmall,
  Capitalize,
  LowerCase,
}
