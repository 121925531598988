/* eslint-disable no-underscore-dangle */
import React from 'react'
import styled from '@emotion/styled'

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 0;
  position: relative;
`

const MainImage = ({ mainImage, width = 1200, className }) => {
  if (!mainImage || !mainImage.asset) {
    return <></>
  }

  return mainImage.preparedImageUrl ? (
    <Image
      className={className}
      src={mainImage.preparedImageUrl}
      alt={mainImage.alt || ''}
    />
  ) : (
    <></>
  )
}

export default MainImage
