import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { LevelItem } from '@patternfly/react-core'
import { SingleTag } from './styles/popular-tags'

export default function PopularTags(props) {
  const intl = useIntl()
  return (
    <>
      {props.tags?.tags?.tagsList.map(function (tag, i) {
        return (
          <SingleTag key={i} data-testid={`tag-${tag?.value}`}>
            <LevelItem className="tag-name-wrapper">
              <Link to={`/forum/tag/${tag.value}`}>
                <i className="fas fa-tag" />
                <span>{tag.value}</span>
              </Link>
            </LevelItem>
            <LevelItem className="number">{tag.score}</LevelItem>
            <LevelItem className="single-tag-link">
              <Link to={`/forum/tag/${tag.value}`}>
                {intl.formatMessage({
                  id: 'forum.homeview.toptags.button.viewtopic',
                })}
              </Link>
            </LevelItem>
          </SingleTag>
        )
      })}
    </>
  )
}
