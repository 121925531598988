import styled from '@emotion/styled'
import scroll from '_utils/css-scroll'

export const MainWrapper = styled.div`
  width: 90vw;
  max-width: 1100px;
  margin: 30px auto;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  background: var(--background);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 12%;

  .new-event-title{
    margin: 40px 0;
    font-size: 32px;
  }

  .connections{
    margin-top:40px;
  }

  .validation-error {
    color: red;
  }
  .required-star {
    color: red;
  }

  .wysiwyg-wrapper {
    width: 100%;
    height: 515px;
    margin-bottom: 220px;
    display: flex;
    flex-direction: column;
    .fr-wrapper {
      ${scroll()}
    }
    .desc-label {
      font-size: 15px;
      font-weight: 700;
      color: (--foreground);
    }

    span {
      font-size: 1px;
      font-weight: 400;
      margin-bottom: 12px ;
      color: (--foreground);
    }
  }
`
