import { useEffect, useState, useCallback } from 'react'
import adapterjs from 'adapterjs'
import { useUser } from '_security/session-hooks'

const PEXIP_SERVER = process.env.REACT_APP_PEXIP_SERVER

export const usePexipCall = (conferenceId) => {
  const user = useUser()
  const [client, setClient] = useState()
  const [muted, setMuted] = useState()
  const [presenting, setPresenting] = useState()
  const [video, setVideo] = useState()
  const [self, setSelf] = useState()
  const [presentation, setPresentation] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const userId = user?.id || user?.sub
  const userName = user?.name

  useEffect(() => {
    if (userId) {
      // eslint-disable-next-line no-undef
      const client = new PexRTC()

      client.call_tag = userId
      client.onError = (error) => {
        console.error('PEXIP', error)
        setError(error)
      }
      client.onConnect = function (stream) {
        if (stream) {
          setVideo(stream)
          setLoading(false)
        }
      }

      client.onSetup = function (stream) {
        if (stream) setSelf(stream)
        this.connect()
      }

      client.onPresentation = function (started) {
        if (started) client.getPresentation()
      }
      client.onPresentationConnected = function (stream) {
        if (stream) setPresentation(stream)
      }
      client.onScreenshareConnected = function (stream) {
        if (stream) {
          setPresentation(stream)
          setPresenting(true)
        }
      }
      client.onScreenshareStopped = function () {
        setPresenting(false)
      }
      client.onPresentationDisconnected = function () {
        setPresentation(null)
      }
      client.onScreenshareStopped = function () {
        setPresenting(false)
      }

      client.makeCall(PEXIP_SERVER, conferenceId, userName, null, 'video')

      setClient(client)

      return () => {
        client.onConnect = undefined
        client.onSetup = undefined
        client.onError = undefined
        client.disconnect()
      }
    }
  }, [conferenceId, userId, userName])

  useEffect(() => {
    const disconnectOnUnload = () => {
      if (client) client.disconnect()
    }
    window.addEventListener('unload', disconnectOnUnload)

    return () => window.removeEventListener('unload', disconnectOnUnload)
  }, [client])

  const videoRef = useCallback(
    (node) => {
      if (node && video) adapterjs.attachMediaStream(node, video)
    },
    [video]
  )
  const selfRef = useCallback(
    (node) => {
      if (node && self) adapterjs.attachMediaStream(node, self)
    },
    [self]
  )
  const presentationRef = useCallback(
    (node) => {
      if (node && presentation) adapterjs.attachMediaStream(node, presentation)
    },
    [presentation]
  )

  useEffect(() => {
    if (client) client.muteAudio(!!muted)
  }, [muted, client])

  useEffect(() => {
    if (client) client.present(presenting ? 'screen' : null)
  }, [presenting, client])

  const disconnect = useCallback(() => client.disconnect(), [client])

  const hasPresentation = presenting || presentation

  return {
    loading,
    error,
    videoRef,
    selfRef,
    presentationRef,
    muted,
    setMuted,
    presenting,
    setPresenting,
    hasPresentation,
    disconnect,
  }
}
