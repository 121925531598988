import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import BreakLine from './breakline'
import { checkInputValue } from './ca-input-validation'

import { InputWrapper, WarningWrapper } from '../styles'

const defaultValidationSetting = {
  isValueCorrect: true,
  warningMessageKey: '',
  isValueValidated: false,
}

const CustomAttributeInput = ({
  attribute,
  attributeValue,
  attributeConfig: { onSave, config },
}) => {
  const intl = useIntl()
  const isInputFilled = attributeValue.value !== ''
  const [validationObject, setValidationObject] = useState(
    defaultValidationSetting
  )

  const {
    isValueCorrect,
    warningMessageKey,
    isValueValidated,
  } = validationObject

  // TODO: validation on blur or on change?

  return (
    <>
      <InputWrapper isValueIncorrect={!isValueCorrect}>
        <h4 className="title">{attribute.name}</h4>
        <p className="description">{attribute.description}</p>
        <input
          maxLength={150}
          type="text"
          value={attributeValue?.value}
          onChange={(e) => {
            isValueValidated &&
              e.target.value === '' &&
              setValidationObject(defaultValidationSetting)

            onSave(attribute.key, e.target.value)
          }}
          placeholder={config.placeholder}
          onBlur={() =>
            isInputFilled &&
            setValidationObject(
              checkInputValue(attribute.type, attributeValue.value)
            )
          }
        />
        <WarningWrapper>
          {isInputFilled && !isValueCorrect && isValueValidated && (
            <p className="warning-message">
              {intl.formatMessage(
                { id: warningMessageKey },
                {
                  inputtype: intl.formatMessage({
                    id: `ca.attribute.type.${attribute.type}`,
                  }),
                }
              )}
            </p>
          )}
        </WarningWrapper>
      </InputWrapper>

      <BreakLine />
    </>
  )
}

export default CustomAttributeInput
