import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

import profileThumbnail from 'resources/intercom.jpg'
import { ReactComponent as PlusIcon } from 'resources/connect-icon.svg'
import { ReactComponent as MessageIcon } from 'resources/message-icon.svg'

const Container = styled.div`
  margin-bottom: 25px;
`

const TopArea = styled.div`
  padding-left: 50px;
`

const BackgroundContainer = styled.div`
  background-color: #e8eaeb;
  padding: 30px;
  border-radius: 6px;
`

const TopRow = styled(Row)``

const TitleCol = styled(Col)`
  width: 100%;
`

const Title = styled.h3`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 16px;
  font-weight: 600;
`

const DetailsCol = styled(Col)`
  max-width: 90px;
`

const Details = styled.span``

const Date = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
`

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: var(--foreground);
  opacity: 0.7;
`

const DescriptionContainer = styled.div`
  margin-top: 10px;
  position: relative;
`

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  font-weight: 400;
`

const ButtonRow = styled(Row)``

const ButtonCol = styled(Col)``

const ButtonContainer = styled.div`
  margin-top: 10px;
`

const ConnectButton = styled.button`
  position: relative;
  border: 2px solid var(--flavor);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    top: 5px;
  }
`

const MessageButton = styled.button`
  position: relative;
  border: 2px solid var(--flavor);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
`

const ViewProfileButton = styled.button`
  border: 2px solid var(--flavor);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 16px;
  margin-left: 15px;
`

const UndoButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
`

const BottomContainer = styled.div`
  margin-top: 5px;
`

const ThumbnailContainer = styled.div`
  position: relative;
  display: inline-block;
`

const SmallThumbnail = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 100%;
  display: inline-block;
`

const SmallName = styled.span``

const Text = styled.span`
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  font-size: 16px;
`

const OnlineIndicator = styled.div`
  width: 15px;
  height: 15px;
  background-color: #4dcea6;
  border-radius: 10px;
  border: 3px solid var(--background);
  position: absolute;
  right: 2.5px;
  bottom: 0px;
`

//CONNECTION TYPES
// profile_connection_request, recommendation, x_connection_request, y_connection_request

const Index = ({ notification }) => {
  const displayConnect = (response) => {
    if (response) {
      switch (response) {
        case true:
          return (
            <MessageButton>
              <MessageIcon /> Message
            </MessageButton>
          )
        default:
          return (
            <ConnectButton>
              <PlusIcon /> Connect
            </ConnectButton>
          )
      }
    } else {
      return (
        <ConnectButton>
          <PlusIcon /> Connect
        </ConnectButton>
      )
    }
  }

  if (notification) {
    return (
      <Container>
        <TopArea>
          <BackgroundContainer>
            <TopRow>
              <TitleCol>
                <Title>Connection request from: Andreas Skaalerud</Title>
              </TitleCol>
              <DetailsCol className="text-right">
                <Details>
                  <Date>1h</Date>
                  <DeleteButton>
                    <i className="fas fa-trash" />
                  </DeleteButton>
                </Details>
              </DetailsCol>
            </TopRow>
            <DescriptionContainer>
              <Description>
                Has sent you a connection request, so what would you like to do
                about that? Here are some options.
              </Description>
            </DescriptionContainer>
            <ButtonRow>
              <ButtonCol className="text-left my-auto">
                <ButtonContainer>
                  {displayConnect(notification.response)}
                  <ViewProfileButton>View profile</ViewProfileButton>
                </ButtonContainer>
              </ButtonCol>
              <ButtonCol className="text-right my-auto">
                <ButtonContainer>
                  <UndoButton>Undo</UndoButton>
                </ButtonContainer>
              </ButtonCol>
            </ButtonRow>
          </BackgroundContainer>
        </TopArea>
        <BottomContainer>
          <SmallName>
            <ThumbnailContainer>
              <SmallThumbnail src={profileThumbnail} />
              {true ? <OnlineIndicator /> : <></>}
            </ThumbnailContainer>
            <Text>Andreas Skaalerud</Text>
          </SmallName>
        </BottomContainer>
      </Container>
    )
  }
}

export default Index
