import React from 'react'
import { Modal, ModalVariant } from '@patternfly/react-core'
import styled from '@emotion/styled'
import { ctaButton } from '_utils/css-buttons'
import { useIntl } from 'react-intl'

const ModalStyled = styled(Modal)`
  background-color: var(--background);
  color: var(--foreground);
  border-radius: 25px;
  .pf-c-modal-box__footer {
    display: flex;
    flex-direction: row-reverse;
  }
`
const GoToLogin = styled.a`
  ${ctaButton}
`
const CancelButton = styled.button`
  padding: 0 20px;
  color: var(--foreground);
  border: none;
  background-color: transparent;
  text-decoration: underline;
  &:hover {
    opacity: 0.6;
  }
`

const InfoModal = ({ isOpen, title, onClose, textContent, children }) => {
  const intl = useIntl()

  return (
    <ModalStyled
      isOpen={isOpen}
      variant={ModalVariant.small}
      title={title}
      onClose={() => onClose(false)}
      onAccept={() => {}}
      actions={[
        <CancelButton onClick={() => onClose(false)}>
          {intl.formatMessage({ id: 'forum.modal.button.cancel' })}
        </CancelButton>,
        <GoToLogin href="/login">
          {intl.formatMessage({ id: 'forum.modal.button.gotologin' })}
        </GoToLogin>,
      ]}
    >
      {textContent}
    </ModalStyled>
  )
}

export default InfoModal
