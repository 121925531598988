import React from 'react'
import { ResourcesNavigation } from './styled/resources-dropdown'

const ResourcesDropdown = ({ children, isScrollTop, themeSettings }) => {
  return (
    <ResourcesNavigation
      isScrollTop={isScrollTop}
      themeSettings={themeSettings}
    >
      {children}
    </ResourcesNavigation>
  )
}

export default ResourcesDropdown
