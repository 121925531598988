import React from 'react'
import { useIntl } from 'react-intl'
import {
  DefaultTileStyled,
  Description,
  ConnectButtonContainer,
  Location,
  Avatar,
  TopContentWrapper,
  Name,
  DiscoverName,
  OnlineIndicator,
} from './styled/default'
import { useAppConfig } from '_application'
import ConnectButton from '_components/buttons-connections/connect-button'
import DotsButton from './dots-button'
import { useUserAccess } from '_security/helpers'
import { useTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useUser } from '_security/session-hooks'
import { useMeetingCall, useUserStatus } from '_meet'
import { PlanFeature } from '_application'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon-black.svg'
import { ctaButtonHollow } from '_utils/css-buttons'
import { useIsDiscoverPage } from '_application/is-discover-page'

const EditButton = styled.div`
  ${ctaButtonHollow}

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  color: var(--foreground);
  background: var(--background);

  i {
    color: var(--foreground);
  }
`

const DefaultTile = ({
  children,
  person,
  connectButton,
  isOwner,
  disconnectButton,
}) => {
  const intl = useIntl()

  const { avatar, firstName, lastName, bioShort, location, sub, id } =
    person?.creator || person || {}

  const { features } = useAppConfig()
  const hasUserAccess = useUserAccess()
  const theme = useTheme()
  const currentUser = useUser()
  const onlineStatus = useUserStatus(id)
  const { startDirectCall } = useMeetingCall()
  const isDiscoverPage = useIsDiscoverPage()

  const isCurrentUser = currentUser?.sub === sub
  const isLogged = currentUser?.profile?.role
  const isUserIdEquelId = currentUser?.profile?.id === id

  const checkValue = (value) => {
    return value && value !== 'null' && value !== 'undefined' ? value : ''
  }

  const city = location ? checkValue(location.city) : null
  const country = location ? checkValue(location.country) : null

  const renderLocation = () =>
    city ? `${city}${country && country !== '' ? ', ' : ''}${country}` : country

  const isMeetActive = features?.includes('meet')

  const _renderName = () => (
    <>
      {isDiscoverPage ? (
        <DiscoverName>{`${checkValue(firstName)} ${checkValue(
          lastName
        )}`}</DiscoverName>
      ) : (
        <Name>{`${checkValue(firstName)} ${checkValue(lastName)}`}</Name>
      )}
    </>
  )

  const isConnected = person.connectionStatus === 'accepted'

  return (
    <DefaultTileStyled>
      <TopContentWrapper>
        {isConnected && !isCurrentUser ? (
          <DotsButton
            connectionStatus={person.connectionStatus}
            personId={person.sub}
            onDisconnect={disconnectButton?.onDisconnect}
          />
        ) : null}
        <Avatar>
          <img src={avatar || theme?.images?.defaultAvatar} alt="" />
          <OnlineIndicator onlineStatus={onlineStatus} />
          {children}
        </Avatar>
        {_renderName()}
        <Description>{bioShort && bioShort}</Description>
        {city || country ? (
          <Location>
            <LocationIcon />
            {renderLocation()}
          </Location>
        ) : null}
      </TopContentWrapper>
      <ConnectButtonContainer disabled={onlineStatus !== 'ONLINE'}>
        {connectButton && !isCurrentUser ? (
          <ConnectButton
            sub={sub}
            status={person?.connectionStatus}
            onConnect={hasUserAccess ? connectButton?.onConnect : () => {}}
            disabled={connectButton?.disabled}
            type="people"
            isMeetActive={isMeetActive}
          />
        ) : isLogged && isUserIdEquelId ? (
          <Link to="/user/profile">
            <EditButton>
              <div>
                <i className="fas fa-pen" />
                <p>{`${intl.formatMessage({
                  id: 'tile.user.button.editprofile',
                })}`}</p>
              </div>
            </EditButton>
          </Link>
        ) : (
          <></>
        )}
        {person?.connectionStatus === 'accepted' &&
          isMeetActive &&
          !isUserIdEquelId && (
            <PlanFeature feature="meet">
              <button
                className={
                  'video-button ' +
                  (onlineStatus !== 'ONLINE' ? 'disabled' : '')
                }
                onClick={() =>
                  onlineStatus === 'ONLINE' && startDirectCall(person.id)
                }
                disabled={onlineStatus !== 'ONLINE'}
              >
                <div>
                  <i className="fas fa-video" />
                </div>
              </button>
            </PlanFeature>
          )}
      </ConnectButtonContainer>
    </DefaultTileStyled>
  )
}

export default DefaultTile
