import { all, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* SET_STATE() {
  yield
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_STATE, SET_STATE)])
}
