import React from 'react'

import SubNavigation from '../../shared/components/sub-navigation'
import Routes from './routes'

const defaultUrl = '/manage/site-structure/opportunities'

const navItems = [
  { url: `${defaultUrl}/email`, titleKey: 'admin.sitestructure.email' },
  {
    url: `${defaultUrl}/access`,
    titleKey: 'admin.sitestructure.opportunities.access',
    defaultMessage: 'Access',
  },
  {
    url: `${defaultUrl}/subscriptions`,
    titleKey: 'admin.sitestructure.subscriptions',
  },
  { url: `${defaultUrl}/labels`, titleKey: 'admin.sitestructure.labels' },
]

const Dashboard = () => {
  return (
    <>
      <SubNavigation navItems={navItems} />
      <Routes />
    </>
  )
}

export default Dashboard
