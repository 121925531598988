import styled from '@emotion/styled'
import border from '_utils/css-border'
import { ctaButtonHollow } from '_utils/css-buttons'

export const MainContainer = styled.div``
export const CategoriesHeader = styled.div``
export const CategoriesDescription = styled.div``
export const CategoriesTitle = styled.div``
export const AddCategoryButton = styled.button`
  ${ctaButtonHollow}
`
export const ContentWrapper = styled.div`
  width: 70%;
`
export const SingleCategory = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: 100%;
`
export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const SubsectionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;

  ${MainWrapper} {
    padding-left: 40px;
  }
`
export const ActionsWrapper = styled.div`
  width: 20%;
`
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  .icon-wrapper {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: grey;
    i {
      color: white;
    }
  }
  .name {
    font-weight: 500;
    padding: 0 10px;
  }
`
export const DotsButton = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
`
export const ExpandButton = styled.button`
  border: none;
  background-color: transparent;
`
export const EditWindow = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 8px 26px;
  ${border()}
  border-radius: 0;
  background-color: var(--background);
`

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 8px 24px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`
