import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import Map from '_map-with-result-grid'
import { useHistoryPreservingSearch } from '_utils/router'
import { toMarkers } from '_events/events-models'
// import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'

const GET_EVENTS_PUBLIC = gql`
  query GetAllEventsPublic(
    $filter: EventFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllEventsPublic(filter: $filter, ordering: $ordering) {
      data {
        id
        title
        bannerImage
        excerpt
        description
        location {
          lat
          long
          address
          type
          link
        }
        categories {
          key
          childrenKeys
        }
      }
    }
  }
`

const EventsMapConnected = ({ filter }) => {
  const history = useHistoryPreservingSearch()
  // TODO: filter is not ready yet
  // let ordering = undefined
  // if (filter) {
  //   const sort = SANITY_ORDERINGS.find(
  //     (sort) => sort === Object.keys(filter)[0]
  //   )
  //   if (sort) {
  //     ordering = parseSanitySort(sort)
  //     filter = undefined
  //   }
  // }

  const { data, loading, error } = useQuery(GET_EVENTS_PUBLIC, {
    // variables: {
    //   filter,
    // },
    notifyOnNetworkStatusChange: true,
  })

  const events = data?.getAllEventsPublic?.data

  const markers = useMemo(() => toMarkers(events || []), [events])
  const showEvent = useCallback(
    (event) => history.push(`/events/list/${event.id}`),
    [history]
  )

  if (!events || loading) return <Loading />
  if (error) return <Error />

  return <Map markers={markers} onSelect={showEvent} type="events" />
}
EventsMapConnected.propTypes = {
  filter: PropTypes.object,
}

export default EventsMapConnected
