import React from 'react'
import PropTypes from 'prop-types'
import InfoModal from 'components/indy/shared/info-modal'

const Props = {
  onClose: PropTypes.func,
}
const LoginRequiredModal = ({ onClose }) => (
  <InfoModal
    // title="No access"
    // description="You need to be authenticated in order to view this content. If you already have an account, you can sign in using the button below."
    setCanShowInfoModal={onClose || goToHome}
    canShowInfoModal
    testid="loginRequired"
  />
)
LoginRequiredModal.propTypes = Props

const goToHome = () => {
  window.location = '/'
}

export default LoginRequiredModal
