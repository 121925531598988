import React from 'react'
import PropTypes from 'prop-types'
import SanityModal from 'shared/SanityModal'
import { Tile } from '_uikit/tile'
import { TileConnections } from '_uikit/tile-sections'
import styled from '@emotion/styled'
import ConnectButton from '_components/buttons-connections/connect-button'
import LoginRequiredLink from '_security/login-required-link'
import { useUser } from '_security/session-hooks'
import { useUserAccess } from '_security/helpers'

import { mobile } from '_utils/responsive-queries'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`
const Location = styled.div`
  p {
    margin: 0;
    display: inline-block;
  }
  i {
    padding-right: 5px;
  }
`

const ButtonsContainer = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  padding-top: 20px;
`

const LocationLabel = styled.div`
  display: flex;
  align-items: center;
  /* margin: 15px 5px; */
  font-size: 12px;
  font-weight: 400;
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  margin-top: 10px;
  p {
    min-width: 100px;
    max-width: 180px;
    color: var(--foreground) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Edit = styled.div`
  button {
    color: var(--foreground);
    border: 2px solid var(--flavor);
    background-color: transparent;
    font-weight: 600;
    height: 50px;
    width: 180px;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 25px;
    i {
      font-size: 14px;
      margin-right: 6px;
    }
    &:hover {
      background: transparent;
      opacity: 0.6;
    }
  }

  ${mobile} {
    display: none;
  }
`

const Props = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    creator: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    coverPhoto: PropTypes.string,
    bannerImage: PropTypes.string,
    connectedUsers: PropTypes.array,
    connectedCompanies: PropTypes.array,
  }).isRequired,
  className: PropTypes.string,
}
const Company = ({
  company,
  className,
  connectButton,
  c2aButton,
  isOwner,
  isPopup,
  isDetailsCard,
  url,
  showConnections = true,
  tileHeight,
}) => {
  const user = useUser()
  const companyUrl = url
  const hasUserAccess = useUserAccess()

  const renderConnectButton = () => {
    const isLogged = user?.profile?.role

    const defComponent = ({ children }) => <>{children}</>
    const Component = isLogged ? defComponent : LoginRequiredLink
    return (
      <Component key={company.id} to={companyUrl} preserveQuery>
        {connectButton && (
          <ConnectButton
            status={isLogged ? company.connectionStatus : null}
            onConnect={
              isLogged && hasUserAccess ? connectButton?.onConnect : () => {}
            }
            disabled={connectButton?.disabled}
            type="companies"
          />
        )}
      </Component>
    )
  }

  let Link = ({ children }) => <>{children}</>
  if (url) {
    Link = ({ children }) => (
      <LoginRequiredLink to={companyUrl} preserveQuery>
        {children}
      </LoginRequiredLink>
    )
  }

  return (
    <Tile
      cover={company.bannerImage}
      avatar={company.coverPhoto}
      companyTitle={company.name}
      description={company.description}
      location={company.location?.name}
      isDetailsCard={isDetailsCard}
      className={className}
      type={'company'}
      url={isDetailsCard ? '' : companyUrl}
      showConnections={showConnections}
      tileHeight={tileHeight}
    >
      {isDetailsCard ? (
        <ContentContainer>
          {/* <Location>
            {company.location === 'undefined' || !company.location ? (
              <LocationLabel>
                <i className="fas fa-map-marker-alt" />
                <p>Somewhere in the universe</p>
              </LocationLabel>
            ) : (
              <LocationLabel>
                <i className="fas fa-map-marker-alt" />
                <p>
                  {`${company.location?.city}, `}
                  {company.location?.country}
                </p>
              </LocationLabel>
            )}
          </Location> */}
          <ButtonsContainer>
            {!isOwner ? (
              connectButton
            ) : (
              <Edit>
                <SanityModal
                  buttonName="Edit"
                  icon="fa-pen"
                  url={`desk/company;company;${company.id}`}
                />
              </Edit>
            )}
            {isOwner && c2aButton}
          </ButtonsContainer>
          {showConnections && (
            <TileConnections
              connectedUsers={company.connectedUsers}
              connectedActivities={company.connectedActivities}
            />
          )}
        </ContentContainer>
      ) : (
        <>
          <Link>
            <ContentContainer>
              <Location>
                {company.location === 'undefined' || !company.location ? (
                  <LocationLabel>
                    <i className="fas fa-map-marker-alt" />
                    <p style={{ fontSize: '12px' }}>
                      Somewhere in the universe
                    </p>
                  </LocationLabel>
                ) : (
                  <LocationLabel>
                    <i className="fas fa-map-marker-alt" />
                    <p>
                      {`${company.location?.city}, `}
                      {company.location?.country}
                    </p>
                  </LocationLabel>
                )}
              </Location>
            </ContentContainer>
          </Link>
          <ButtonsContainer>
            {!isOwner && renderConnectButton()}
            {isOwner && (
              <Edit>
                <SanityModal
                  buttonName="Edit"
                  icon="fa-pen"
                  url={`desk/company;company;${company.id}`}
                />
              </Edit>
            )}
          </ButtonsContainer>
          <Link>
            {showConnections && (
              <TileConnections
                connectedUsers={company.connectedUsers}
                connectedActivities={company.connectedActivities}
                // connectedUsersCount={company.connectedUsers?.length}
                // connectedActivitiesCount={company.connectedActivities?.length}
                type={'company'}
              />
            )}
          </Link>
        </>
      )}
    </Tile>
  )
}
Company.propTypes = Props
export default Company
