import React from 'react'
import { Route, Switch, useParams, useHistory } from 'react-router'
import OpportunityApplicationsManagement from './opportunity-applications-management'
import OpportunityApplicationDetail from './opportunity-application-detail'
import { ApplicationApplicantView } from './application-detail-applicant-view'
import { useQuery, gql } from '@apollo/client'
import { useMediaQuery } from 'react-responsive'
import { mobilePX } from '_utils/responsive-queries'
import { RequiresLogin } from '_security/login-required'
import { notification } from 'antd'

export default function Routes() {
  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })
  const history = useHistory()

  return (
    <div>
      <RequiresLogin showLoginPopup={true}>
        <Switch>
          <Route
            path={`/opportunities/applications/:applicationId`}
            component={ResolveApplication}
          />
          <Route
            path={`/opportunities/:opportunityId/applications/detail/:applicationId`}
            component={OpportunityApplicationDetail}
          />
          <Route
            path={`/opportunities/:opportunityId/applications`}
            component={
              isMobileView
                ? history.goBack()
                : OpportunityApplicationsManagement
            }
          />
        </Switch>
      </RequiresLogin>
    </div>
  )
}

// send managers to manager-view, and applicants to applicant-view
const ResolveApplication = () => {
  const history = useHistory()
  const { applicationId } = useParams()
  const { data: applicationData, loading } = useQuery(
    gql(`query GetApplicationApplicantView($applicationId: String) {
      getApplicationApplicantView(applicationId: $applicationId) {
        isApplicationManager
      }
    }`),
    { variables: { applicationId } }
  )

  if (loading) return null

  if (!applicationData?.getApplicationApplicantView) {
    history.replace('/opportunities')
    notification.error({
      message: 'Unavailable',
      description: 'This application is not available',
    })
    return null
  }

  return applicationData.getApplicationApplicantView.isApplicationManager ? (
    <OpportunityApplicationDetail />
  ) : (
    <ApplicationApplicantView />
  )
}
