import React, { useMemo, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'
import { useQuery, gql, useMutation } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import styled from '@emotion/styled'
import { usePrefetchUserStatus } from '_meet'
import { nextPage, mergePage } from '_utils/graph-pagination'
import PeopleGrid from '../components/people-grid'
import { useIntl } from 'react-intl'
import { parseSort, ORDERINGS } from '_utils/sort'
import { getPageSizeForScreenSize } from '_tiles/helpers'
import {
  CONNECT_MUTATION,
  DISCONNECT_MUTATION,
} from './person-details-connected'
import { ctaButtonHollow } from '_utils/css-buttons'
import { sendNotification } from '_components/buttons-connections/message-helper'

const GET_USERS = gql`
  query GetAllUsersList(
    $filter: UserFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllUsersPublic(filter: $filter, paging: $paging, ordering: $ordering)
    @connection(key: "GetAllUsersList", filter: ["filter", "ordering"]) {
      offset
      hasMore
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  padding: 0 0px;
  margin-top: 100px;
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

const SeparatorCol = styled(Col)`
  hr {
    border: 1px solid var(--shade);
  }
`

const ButtonCol = styled(Col)`
  max-width: 300px;

  max-width: 300px;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    min-width: 190px;
  }
`

const updateQuery = mergePage('getAllUsersPublic')

const PeopleGridConnected = ({ filter }) => {
  const intl = useIntl()
  let ordering = undefined
  const detailedFilter = filter
  if (filter) {
    const sort = ORDERINGS.find((sort) => sort === Object.keys(filter)[0])
    if (sort) {
      ordering = parseSort(sort)
      filter = undefined
    }
  }
  const queryVariables = {
    filter,
    ordering,
    paging: {
      limit: getPageSizeForScreenSize({ pageType: 'grid' }),
      offset: 0,
    },
  }
  const { data, error, loading, fetchMore } = useQuery(GET_USERS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
  })
  const { data: people, hasMore } = data?.getAllUsersPublic || {}
  const ids = useMemo(() => people?.map((p) => p.id), [people])

  const [connectId, setConnectId] = useState()
  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)
  const [disconnect] = useMutation(DISCONNECT_MUTATION)

  const refetchQueries = {
    query: GET_USERS,
    variables: { ...queryVariables, ...nextPage(0, people?.length) },
  }

  usePrefetchUserStatus(ids)

  if (!people) return <Loading />
  if (error) return <Error />

  return (
    <div>
      {data && (
        <PeopleGrid
          filter={detailedFilter}
          refetchQueries={refetchQueries}
          people={people.map((p) => {
            return {
              person: p,
              connectButton: {
                disabled: connecting && connectId === p.sub,
                onConnect: () => {
                  setConnectId(p.sub)
                  connect({
                    variables: { sub: p.sub },
                    refetchQueries: [refetchQueries],
                    awaitRefetchQueries: true,
                  }).then(res => {
                    const { status } = res?.data?.connectToUser
                    sendNotification({
                      type: 'connection',
                      intl,
                      defaultHeader: 'Connection',
                      defaultContent: `You have ${
                        status === 'cancelled'
                          ? 'cancelled your connection'
                          : 'sent a connection request'
                      }`,
                    })
                  }).catch(err => console.log('error',err))
                },
              },
              disconnectButton: {
                onDisconnect: () => {
                  disconnect({
                    variables: { personId: p.sub },
                    refetchQueries: [refetchQueries],
                    awaitRefetchQueries: true,
                  })
                },
              },
            }
          })}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={fetchMore}
          filter={filter}
          offset={people.length}
        />
      )}
    </div>
  )
}
PeopleGridConnected.propTypes = {
  filter: PropTypes.object,
}

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <SeparatorCol className="my-auto">
          <hr />
        </SeparatorCol>
        <ButtonCol>
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(
                  offset,
                  getPageSizeForScreenSize({ pageType: 'grid' })
                ),
                filter,
                updateQuery,
              })
            }
          >
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i className="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </ButtonCol>
        <SeparatorCol className="my-auto">
          <hr />
        </SeparatorCol>
      </Row>
    </LoadMoreContainer>
  ) : null
}
export default PeopleGridConnected
