import styled from '@emotion/styled'
import { pablet, mobile } from '_utils/responsive-queries'

const BaseStyled = styled.div`
  color: var(--foreground);

  ${pablet} {
    width: 100%;
  }

  .step-content-container {
    .images-upload {
      margin-bottom: 75px;
    }
    .tile {
      border-color: transparent;
      background-color: transparent;
      box-sizing: border-box;
      padding: 4px;
      transform: 0.3s;
      &:hover {
        .tile-content {
          color: var(--background);
          background-color: var(--foreground);
          opacity: 0.7;
          border-color: transparent;
        }
      }
      &.active {
        .tile-content {
          border-color: transparent;
          color: var(--background);
          background-color: var(--foreground);
        }
      }
      .tile-content {
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--foreground);
      }
    }
    h3 {
      ${pablet} {
        font-size: 20px;
        padding-bottom: 20px;
        i {
          display: block;
          font-size: 18px;
          margin: 20px auto;
        }
      }
      ${mobile} {
        padding-bottom: 20px !important;
        i {
          display: inline;
        }
      }
    }
  }
  label {
    font-weight: 500;
    color: var(--foreground);
  }
`

export default BaseStyled
