import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'

import { GET_KEYWORDS } from '../graph-queries/keywords'
import { useIntl } from 'react-intl'
import { MainWrapper, Section } from '../styles'

import { Row, Col } from 'react-bootstrap'
import ItemList, { BasicInfo, NoContent } from '../shared/items-list'
import ManageKeywords from '../shared/modals/manage-keywords'

const mapStateToProps = ({ user }) => ({ user })

const Keywords = ({ user, dispatch }) => {
  const [currentKeyword, setCurrentKeyword] = useState()
  const intl = useIntl()

  const { data: keywordData } = useQuery(GET_KEYWORDS, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['people'] },
  })

  const onSave = (values) => {
    const newKeywordList = user.profile.keywords?.filter(
      (k) => k.key === currentKeyword.key
    )[0]
      ? user.profile.keywords.map((k) => {
          return {
            key: k.key,
            values: k.key === currentKeyword.key ? values : k.values,
          }
        })
      : [...user.profile.keywords, { key: currentKeyword.key, values }]
    saveProfile({
      ...user.profile,
      keywords: newKeywordList,
    })
    setCurrentKeyword(null)
  }

  const onClose = () => {
    setCurrentKeyword(false)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  return (
    <ItemList>
      <MainWrapper>
        <Section>
          <span className="section-title">
            {intl.formatMessage({
              id: 'user.profile.keywords',
            })}
          </span>
          <div style={{ marginTop: '25px' }}>
            {keywordData && keywordData.getKeywords.length > 0 ? (
              keywordData.getKeywords.map((key) => {
                const valuesList =
                  user.profile?.keywords?.filter((k) => k.key === key.key)[0]
                    ?.values || []
                return (
                  <BasicInfo>
                    <Row>
                      <Col sm={12} md={8}>
                        <Row>
                          <Col sm={8}>
                            <span className="name">{key.name}</span>
                            <span className="description">
                              {key.description}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <button
                        onClick={() => setCurrentKeyword(key)}
                        className="edit"
                        data-testid={`keywords-btn-edit-${key.name}`}
                      >
                        <i className="fas fa-pen" />
                      </button>
                    </Row>
                    <ul
                      className="values-list"
                      data-testid={`keywords-${key.name}-list`}
                    >
                      {valuesList.map((v) => {
                        const val = key?.keywordList.find(
                          (k) => k.id === v.value
                        )?.value

                        return (
                          <>
                            {val && (
                              <li
                                data-testid={`keywords-${key.name}-listed-${val}`}
                              >
                                {val}
                              </li>
                            )}
                          </>
                        )
                      })}
                    </ul>
                  </BasicInfo>
                )
              })
            ) : (
              <NoContent>
                {intl.formatMessage({ id: 'global.information.nocontent' })}
              </NoContent>
            )}
            {currentKeyword && (
              <ManageKeywords
                keywordList={currentKeyword}
                onSave={onSave}
                onClose={onClose}
                keywordValues={
                  user.profile?.keywords?.filter(
                    (k) => k.key === currentKeyword.key
                  )[0]?.values || []
                }
              />
            )}
          </div>
        </Section>
      </MainWrapper>
    </ItemList>
  )
}

export default connect(mapStateToProps)(Keywords)
