import styled from '@emotion/styled'

export const DatePickerWrapper = styled.div`
  width: 80vw;
  max-width: 800px;
  .picker-wrapper {
    display: flex;
    flex-direction: column;


    .label-wrapper {

        display: flex;
    flex-direction: column;

        .section-title{
            font-size: 14px;
            font-weight: 700;
        }

        .section-desc{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
        }
    }

    .react-date-picker__inputGroup__input {
      color: var(--foreground) !important;
    }

    .react-calendar__tile--active {
      background-color: var(--flavor) !important;
    }

    .react-date-picker {
      height: 35px;
      max-width: 140px;
      box-shadow: 0px 3px 8px rgb(0 0 0 / 16%);
    }
    .react-date-picker__wrapper {
      border: none !important;
      padding: 0 5px;
    }
  }

  .calendar-wrapper {
    background-color: var(--background);
    color: var(--foreground);
    max-width: 400px;
  }
  .react-date-picker__button__icon {
    stroke: var(--foreground);
    color: var(--foreground);
  }
`

