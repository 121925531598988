import React from 'react'
import PropTypes from 'prop-types'
import Tile from '_uikit/tile'
import { TileTextShort } from '_uikit/tile-sections'
import { getDisplayName } from '_people/people-models'

const Props = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    creator: PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
}

const Page = ({ page, className }) => {
  return (
    <Tile
      cover={page.coverPhoto}
      avatar={page.creator?.avatar}
      avatarName={getDisplayName(page.creator)}
      title={page.title}
      className={className}
    >
      <TileTextShort>{page.description}</TileTextShort>
    </Tile>
  )
}
Page.propTypes = Props

export default Page
