import React from 'react'
import {
  MiniCardStyled,
  ConnectButtonContainer,
  Location,
} from './styled/opportunity-mini-card'
import { AvatarsMiniCard } from '_tiles'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useUserAccess } from '_security/helpers'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon-black.svg'
import { useColorBucket } from '_colorBus/hooks'

const OpportunityMiniCard = ({
  children,
  opportunity,
  connectButton,
  isOwner,
}) => {
  const { creator, location } = opportunity || {}
  const hasUserAccess = useUserAccess()

  const userLocation = `${
    creator?.location?.city ? creator.location.city + ', ' : ''
  }${creator?.location?.country && creator.location.country}`

  const hasMultipleConnections = opportunity.connectedCompanies?.length > 1
  const hasNoConnections = opportunity.connectedCompanies?.length < 1

  const [colorBucket] = useColorBucket('tile')

  return (
    <MiniCardStyled
      ref={colorBucket}
      hasMultipleConnections={hasMultipleConnections}
      data-testid="detailCard-miniCard"
    >
      <AvatarsMiniCard
        creator={creator}
        connections={opportunity?.connectedCompanies}
      />

      {hasNoConnections && location && (
        <Location>
          <LocationIcon />
          {userLocation}
        </Location>
      )}

      <ConnectButtonContainer>
        {connectButton && !hasMultipleConnections && false && (
          <ConnectButton
            status={hasUserAccess ? opportunity.connectionStatus : null}
            onConnect={hasUserAccess ? connectButton?.onConnect : () => {}}
            disabled={connectButton?.disabled}
            type="opportunities"
          />
        )}
      </ConnectButtonContainer>
    </MiniCardStyled>
  )
}

export default OpportunityMiniCard
