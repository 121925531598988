import styled from '@emotion/styled'

const ModalStyled = styled.div`
  background: var(--background);
  .modal-content {
    background: var(--background);
  }

  h2.admin-user-title {
    font-weight: 600;
    font-size: 24px;
    margin: 25px;
    word-break: break-word;
    first-row span {
      display: block;
      font-size: 20px;
      color: var(--flavor);
    }
  }
  font-family: ${({ theme }) => theme.fonts.googleFont};
  .admin-user-settings-container {
    margin: 30px 40px;
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .content {
      margin: 10px 0;
      select {
        border: none;
        padding: 10px 20px;
        background-color: var(--shade);
        border-radius: 15px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  button {
    border: 1px solid var(--foreground);
    padding: 8px 22px;
    border-radius: 6px;
    background-color: var(--background);
    color: var(--foreground);
    font-size: 12px;
    transition: 0.5s;
    &:hover {
      color: var(--background);
      background-color: var(--flavor);
      border: 1px solid var(--flavor);
    }
  }
`
export default ModalStyled
