import React from 'react'
import Header from './components/header'
import Info from './components/info'
import Connections from './components/connections'
import ConnectionsPaged from './components/connections-paged'
import Container from './components/container'
import Content from './components/content'
import ContentHeader from './components/content-header'
import InfoAttributes from './components/info-attributes'
import GoBackContent from './components/go-back-content'
import ContentTabs from './components/content-tabs'

import styled from '@emotion/styled'

const Layout = styled.div`
  background: var(--background);
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  //todo remove sometime
  padding-bottom: 10em;

  .fade-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
`

const Index = ({ children }) => {
  return <Layout>{children}</Layout>
}

Index.Header = Header
Index.Info = Info
Index.Content = Content
Index.Connections = Connections
Index.ConnectionsPaged = ConnectionsPaged
Index.Container = Container
Index.ContentHeader = ContentHeader
Index.InfoAttributes = InfoAttributes
Index.GoBackContent = GoBackContent
Index.ContentTabs = ContentTabs

export default Index
