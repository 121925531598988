import styled from '@emotion/styled'

export const Selector = styled.select`
  margin-top: 10px;
  color: #253238;
  border: 1px solid #253238;
  border-radius: 5px;
  padding: 4px 10px;
  -webkit-appearance: menulist;
`
