import styled from '@emotion/styled'
import {
  StylesBody as DeleteBody,
  StylesFooter as DeleteFooter,
} from './delete'

export const StylesBody = styled(DeleteBody)`
  label {
    display: block;
    font-weight: 600;
  }
  input {
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid var(--shade);
  }
`

export const StylesFooter = styled(DeleteFooter)``
