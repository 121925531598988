import React from 'react'
import { useAppConfig } from '_application'
import { ThemeSelectorStyled } from './styles/theme-selector'
import { useIntl } from 'react-intl'
const themes = [
  { key: 'default', name: 'light' },
  { key: 'secondary', name: 'dark' },
]

const Index = ({ onChange, theme: currentTheme, themeName }) => {
  const config = useAppConfig()
  const intl = useIntl()
  const handleSelectTheme = async ({ theme }) => {
    const override = config?.themeOverrides?.filter(
      (obj) => obj.name === theme.key
    )[0]
    onChange(override || { name: theme.key })
  }

  return (
    <ThemeSelectorStyled>
      {themes.map((theme) => {
        const isActiveTheme = themeName === theme.key
        const isEditingTheme = theme.key === currentTheme?.name

        return (
          <div className="theme-container">
            <div
              onClick={() => handleSelectTheme({ theme })}
              className={`${isActiveTheme ? 'selected' : ''} theme`}
            >
              <span className="name">{`${intl.formatMessage({
                id: `admin.managetheme.selecttheme.name.${theme.name}`,
              })}`}</span>
              <div
                className={`${isActiveTheme ? 'selected' : ''} selected-icon`}
              >
                <i className={`fas fa-check`} />
              </div>
            </div>
            {isEditingTheme && <i className="edit fas fa-chevron-down" />}
          </div>
        )
      })}
    </ThemeSelectorStyled>
  )
}

export default Index
