import React from 'react'
import Routes from './routes'
import { ApplicationStyled } from './styles'

const Index = () => {
  return (
      <ApplicationStyled>
        <Routes />
      </ApplicationStyled>
  )
}

export default Index
