import styled from '@emotion/styled'

export const Dots = styled.div`
  padding: 0 10px;

  margin: auto 0;
  i {
    color: var(--foreground);
    font-size: 15px;
  }
`

export const DotsWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--background);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
`

export const MainContainer = styled.div`
  display: flex;
  border-left: 3px solid transparent;
  position: relative;
  a {
    flex-basis: 100%;
  }

  &:hover {
    border-left: 3px solid var(--flavor);
    background-color: var(--flavor-shade);
    ${DotsWrapper} {
      opacity: 1;
    }
  }
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 15px;
  padding: 0 10px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Message = styled.div`
  font-size: 15px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const MessageContainer = styled.li`
  font-size: 15px;
  font-weight: 500;
  padding: 15px 10px 18px 18px;
  color: var(--foreground);
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    ${Message}, ${Name} {
      color: var(--flavor-contrast);
    }
    /* ${Name} {
      color: var(--flavor-contrast);
    } */
  }

  width: ${({ isMobileView }) => isMobileView && '190px'};
`

export const AvatarWrapper = styled.div`
  border-radius: 100%;
  overflow: hidden;
  min-width: 40px;
  margin: auto 0;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
  }
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
  width: ${({ isMobileView }) => isMobileView && '190px'};
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
`

export const Created = styled.div`
  font-size: 10px;
  margin: 0 10px;
`

export const DotsExpand = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 10px 20px;
  white-space: nowrap;
  font-weight: 500;
  right: 25px;
  top: 10px;
  border-radius: 5px;
  border-top-right-radius: 0;
  color: var(--foreground);
  background-color: var(--background);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid var(--shade);
  i {
    margin-right: 6px;
    font-size: 18px;
  }
  &:hover {
    font-weight: 500;
  }
`
