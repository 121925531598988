import styled from '@emotion/styled'

const SelectorStyled = styled.div`
  text-align: left;
  padding-bottom: 10px;
  .selected-item {
    position: relative;
    display: inline-block;
    text-align: left;
    background: #3e7671;
    color: #fff;
    padding-left: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 24px;
    border-radius: 6px;
    font-size: 12px;
    margin-right: 4px;
  }
  .full-tree-view {
    overflow: auto;
    margin-top: 25px;
  }
  .rst__row {
    position: relative;
    border: none !important;
    .tree-toggle-select {
      background: white;
      color: var(--foreground);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: auto;
      .tree-title {
        text-align: left;
        color: $text-black;
      }
      &.selectable {
        cursor: pointer;
      }
      &.selected {
        background: var(--foreground);
        border: none !important;
        outline: none !important;
        color: white;
        .tree-title {
          color: $text-white;
        }
      }
      &.children-selected {
        color: var(--foreground);
        background: var(--foreground);
      }
    }
  }
`

export default SelectorStyled
