import { gql } from '@apollo/client'

export const GET_ACTIVITY = gql`
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      coverPhoto
      description
      body
      location
      connectionStatus
      location
      start
      end
      private
      publishedAt
      isDraft
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomy {
        key
        values {
          type
          value
        }
      }
      connectedUsersCount
      connectedCompanies {
        id
        name
        coverPhoto
        description
        location
        bannerImage
        connectionStatus
      }
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        fullName
        lastName
        avatar
      }
      connectedOpportunities {
        id
        name
        coverPhoto
        start
        end
        description
        location
        connectionStatus
      }
      customAttributes {
        key
        value
      }
    }
  }
`

export const SAVE_ACTIVITY = gql`
  mutation SaveActivity($input: ActivityInput) {
    saveActivity(input: $input) {
      id
    }
  }
`

