import React from 'react'
import { useIntl } from 'react-intl'
import { useColorBucket } from '_colorBus/hooks'
import { Loader } from '_components/loading'
import { Grid, BaseTile } from '_tiles'
import {
  PostTile,
  CategoryIconWrapper,
  HeaderWrapper,
  Description,
  Footer,
  PostTileTitle,
} from './styles/popular-categories'
const gridConfig = {
  hasGutter: true,
}
const defaultConfig = {
  '2xl': 4,
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
}

const PopularCategories = (props) => {
  const intl = useIntl()
  const [colorBucket] = useColorBucket('tile')
  if (!props.categories) return <Loader loading={!props.categories} />
  return (
    <Grid config={gridConfig} testid="popularCategories">
      {props.categories?.categoriesList.map(function (category, i) {
        return (
          <BaseTile
            config={defaultConfig}
            url={`/forum/category/${category.cid}`}
            isAdditionalAuthEnabled={true}
            isAdditionalAuth={true}
          >
            <PostTile
              key={i}
              isPlain
              ref={colorBucket}
              data-testid={`category-${category.name}`}
            >
              <PostTileTitle>
                <CategoryIconWrapper
                  style={{ backgroundColor: category.bgcolor }}
                >
                  <i
                    className={`fa ${category.icon}`}
                    style={{ color: category.color }}
                  />
                </CategoryIconWrapper>
                <HeaderWrapper>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: category.name,
                    }}
                  ></span>
                </HeaderWrapper>
              </PostTileTitle>
              <Description
                dangerouslySetInnerHTML={{
                  __html: category.description,
                }}
              ></Description>
              <Footer>
                <div className="topics-counter">
                  <span className="number">{category.topicCount}</span>
                  <span className="item">
                    {' '}
                    {intl.formatMessage({
                      id: 'forum.homeview.categories.label.topics',
                    })}
                  </span>
                </div>
                <div className="posts-counter">
                  <span className="number">{category.postCount}</span>
                  <span className="item">
                    {intl.formatMessage({
                      id: 'forum.homeview.categories.label.posts',
                    })}
                  </span>
                </div>
              </Footer>
            </PostTile>
          </BaseTile>
        )
      })}
    </Grid>
  )
}

export default PopularCategories
