import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import ConnectButton from '_components/buttons-connections/connect-button'
import LoginRequiredLink from '_security/login-required-link'

import { useUser } from '_security/session-hooks'
import { CONNECT_MUTATION } from '../connected/person-details-connected'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useUserAccess } from '_security/helpers'

const EditButton = styled.button`
  color: var(--foreground);
  border: 2px solid var(--flavor);
  i.fas {
    font-size: 14px;
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      font-weight: 500;
      border-radius: 100%;
      border: none;
      background-color: white;
      width: 33px;
      height: 33px;
      &.normal,
      &.pending,
      &.accepted,
      &.button-small {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 15px;
        right: 15px;
      }
      svg {
        height: 15px;
        width: 15px;
      }
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 50px;
      width: 180px;
      font-size: 18px;
      font-weight: 600;
      background-color: transparent;
      margin: 0px auto;
      padding: 10px 20px;
      border-radius: 25px;
      border: 2px solid var(--flavor);
      &.normal {
        color: var(--foreground);
        text-decoration: none;
      }
      &.pending {
        border: 2px solid #c7c7c7;

        color: var(--foreground);
      }
      &.accepted {
        border: 2px solid #c7c7c7;

        color: var(--foreground);
      }
      svg {
        height: 18px;
        width: 18px;
        margin-right: 5px;
      }
      i {
        margin-right: 5px;
      }
    `}
`

export const PersonConnectButton = ({
  person,
  connectButton,
  url,
  connections,
  size,
}) => {
  const history = useHistory()
  const user = useUser()
  const isLogged = user?.profile?.role
  const hasUserAccess = useUserAccess()

  const [connect, { loading: connecting, data }] = useMutation(CONNECT_MUTATION)
  const [connectionStatus, setConnectionStatus] = useState(null)

  useEffect(() => {
    setConnectionStatus(data?.connectToUser?.status)
  }, [data])

  if (!person) return null

  const defComponent = ({ children }) => <>{children}</>
  const Component = isLogged ? defComponent : LoginRequiredLink

  const onConnect = async () => {
    if (isLogged && hasUserAccess) {
      connect({
        variables: { sub: person.sub },
      })
    }
  }

  return (
    <Component
      key={person.sub}
      to={url || `/people/list/${person.sub}`}
      preserveQuery
    >
      {connectButton && user.sub !== person.sub ? (
        <ConnectButton
          sub={person.sub}
          status={
            connectionStatus
              ? connectionStatus
              : connectButton.status
              ? connectButton.status
              : isLogged
              ? connections?.connectionStatus
              : null
          }
          onConnect={onConnect}
          disabled={connecting}
          type="people"
          size={size}
        />
      ) : user.sub === person.sub ? (
        <EditButton
          onClick={() => history.push('/user/profile/details')}
          className={`button-${size}`}
          size={size}
        >
          <i className="fas fa-pen" />
          {size === 'large' && 'Edit'}
        </EditButton>
      ) : (
        <></>
      )}
    </Component>
  )
}

export default PersonConnectButton
