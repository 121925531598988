import React from 'react'
import { isString } from 'lodash'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import styled from '@emotion/styled'

import './PortableTextStyles.css'

const Container = styled.div`
  font-size: 16px;
  ul {
    list-style: inherit;
    padding-left: 20px;
  }
  ol {
    list-style: auto;
    padding-left: 20px;
  }
  blockquote {
    color: inherit;
  }
`

const isProduction = process.env.REACT_APP_NODE_ENV === 'production'

const PortableText = ({ blocks, className }) => {
  if (!blocks) {
    return null
  }

  if (isString(blocks)) {
    return blocks
  }

  return (
    <Container
      style={{
        paddingBottom: '10px',
      }}
    >
      <BasePortableText
        blocks={blocks}
        className={className}
        serializers={serializers}
        {...{
          projectId: 'u0vrrae4',
          dataset: isProduction ? 'staging' : 'develop',
        }}
      />
    </Container>
  )
}

export default PortableText
