import React, { useEffect, useState, useCallback } from 'react'
import { withRouter, useLocation, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from 'react-intl'
import moment from 'moment'
import SanitiseHTML from 'helpers/sanitize-html'
import { useUserStatus } from '_meet'
import { TextSection } from '_components/drilldown'
import PublicDetailsCard from '_public-details-card'
import SanityBlock from '_components/sanity-block'
import { useAppLabel } from '_application'
import CategoryTab from './category-tab-detailed'
import { mobilePX, pabletPX } from '_utils/responsive-queries'
import {
  TopSectionContainer,
  TocWrapper,
  TocListWrapper,
  TocItemWrapper,
  TocItem,
  Avatar,
  AvatarContainer,
  OnlineIndicator,
  BlogStats,
  BottomWrapper,
} from './styles/blog-post-details'
import { SharePostButton } from '../components/share-post-button'
import ReactTooltip from 'react-tooltip'
import FullscreenLoaderWrapper from '_components/loading/fullscreen-loader-wrapper'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import LoginRequiredModal from '../../_security/login-required-modal'
import { useUser } from '../../_security/session-hooks'
import Modal from '../../_events/components/event-creator/components/modal'
import EditInfo from 'components/indy/shared/edit-info'



const shareButtons = [
  {
    type: 'users',
    name: 'Recommend',
    icon: <i className="far fa-paper-plane" />,
  },
  {
    type: 'email',
    name: 'Email',
    icon: <i className="far fa-envelope" />,
  },
  {
    type: 'linkedin',
    name: 'LinkedIn',
    icon: <i className="fab fa-linkedin-in" />,
    shareLink: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  },
  {
    type: 'twitter',
    name: 'Twitter',
    icon: <i className="fab fa-twitter" />,
    shareLink: 'https://twitter.com/intent/tweet?url=',
  },
  { type: 'link', name: 'Link', icon: <i className="fas fa-link" /> },
]

const LIKE_BLOG_POST = gql`
  mutation LikeBlogPost($likeBlogPost: LikeBlogPost) {
    likeBlogPost(likeBlogPost: $likeBlogPost) {
      interactiontype
      user
      entity
    }
  }
`

const DISLIKE_BLOG_POST = gql`
  mutation DislikeBlogPost($likeBlogPost: LikeBlogPost) {
    dislikeBlogPost(likeBlogPost: $likeBlogPost) {
      interactiontype
      user
      entity
    }
  }
`

const DELETE_BLOG_POST = gql`
  mutation deleteBlogPost($id: String) {
    deleteBlogPost(id: $id) {
      status
      message
    }
  }
`

const renderStats = ({ data, handleLike, refetchData, refetchGridData }) => {
  const { likesCount, isLiked } = data
  const disable = true
  /* TODO: Disabling like button for now */
  if (disable) {
    return <></>
  }

  return (
    <BlogStats className="blog-stats">
      <span className="likes">
        <button className="like-button" onClick={() => handleLike()}>
          <i className={`fa${isLiked ? 's' : 'r'} fa-thumbs-up`} />
        </button>
        {likesCount}
      </span>
    </BlogStats>
  )
}

const BlogPostDetails = ({
  post,
  refetchData,
  onClose,
  recommendButton,
  enabledToc,
  history,
  refetchGridData,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()
  const categories = post?.categoriesNew
  const location = useLocation()
  const user = useUser()
  const isLoggedIn = user?.profile?.role
  const [isInputShown, setIsInputShown] = useState(false)
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false)
  const [isLikeClicked, setIsLikeClicked] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [deleteBlogPost] = useMutation(DELETE_BLOG_POST, {
    variables: {
      id: post?.id,
    },
  })

  const [likeBlogPost] = useMutation(LIKE_BLOG_POST, {
    variables: { likeBlogPost: { entity: post.id } },
  })

  const [dislikeBlogPost] = useMutation(DISLIKE_BLOG_POST, {
    variables: { likeBlogPost: { entity: post.id } },
  })

  const handleLike = () => {
    if (user?.authorized) {
      return (post.isLiked ? dislikeBlogPost() : likeBlogPost())
        .then((res) => {
          refetchData()
          refetchGridData()
        })
        .catch((err) => console.log('error', err))
    } else {
      setIsLikeClicked(true)
    }
  }

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })

  const onlineStatus = useUserStatus(post?.creator?.id)

  const [toc, setToc] = useState([])
  const [tocOptions, setTocOptions] = useState([])

  const scrollToElement = useCallback(
    (el) => {
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        //history.replace(`${history.location.pathname.replace(/[^/]*$/, index)}`)
      }
    },
    [history]
  )

  const getCurrentUrlHeader = () => {
    const currentHeaderId = location.pathname.split('/').pop()
    //currentHeaderId && scrollToElement(currentHeaderId, currentHeaderId)
  }

  const visibleHeadings = []
  let passedHeadings = []

  const HeaderIntersectionHandler = (headings) => {
    const h = headings[0]
    const item = toc.find((i) => i.target === h.target)
    const itemIndex = visibleHeadings.findIndex((i) => {
      return i.index === item.index
    })

    h.isIntersecting ? onItersect() : offIntersect()

    function onItersect() {
      visibleHeadings.push(item)
      checkPassedHeaders()
    }

    function offIntersect() {
      visibleHeadings.splice(itemIndex, 1)
    }

    function checkPassedHeaders() {
      passedHeadings = []
      toc.forEach((i) => i.index <= item.index && passedHeadings.push(i))
    }

    const lastHeader = passedHeadings[passedHeadings.length - 1]

    const newTocWithOptions = toc.map((item) => {
      return {
        ...item,
        active: lastHeader?.index === item.index,
        intersecting: visibleHeadings.includes(item),
      }
    })

    setTocOptions(newTocWithOptions)
  }

  const headingIntersect = new IntersectionObserver(HeaderIntersectionHandler, {
    rootMargin: '0px 0px -50% -50%',
  })

  useEffect(() => {
    toc.forEach((heading) => headingIntersect.observe(heading.target))
    return () => headingIntersect.disconnect()
  }, [toc])

  useEffect(() => {
    // the code inside this useEffect prevents
    // blogs from being displayed in the search-page,
    // and is therefore disabled for the search page.
    if (location.pathname !== '/search') {
      getCurrentUrlHeader()
      const userContent = document.querySelectorAll('.content-wrapper')
      const headersQuery = Array.from(
        userContent[0].querySelectorAll('h1, h2, h3')
      )
      const headers = headersQuery.map((h, index) => {
        return { index, target: h }
      })
      setToc(headers)
    }
  }, [scrollToElement, location.pathname])

  const _renderToC = () => {
    const includesH1 = toc.map((h) => h.tagName).includes('h1')

    return (
      <TocWrapper>
        <h5 className="tocMainHeader">
          {intl.formatMessage({ id: 'blog.details.tableofcontents.header' })}
        </h5>
        <TocListWrapper>
          {toc.map((h, index) => (
            <>
              {h.target.innerText.replace(/\s/g, '').length !== 0 && (
                <TocItemWrapper
                  onClick={() => scrollToElement(h.target)}
                  key={index}
                  className={`${tocOptions[index]?.active && 'active'}`}
                >
                  <TocItem includesH1={includesH1}>
                    <SanitiseHTML html={h.target.outerHTML} />
                  </TocItem>
                </TocItemWrapper>
              )}
            </>
          ))}
        </TocListWrapper>
      </TocWrapper>
    )
  }

  const ConditionalLinkWrapperr = ({ isLoggedIn, wrapper, children }) =>
    isLoggedIn ? wrapper(children) : children

  const _renderAvatarContainer = () => (
    <>
      <AvatarContainer>
        <div className="avatarImgWrapper">
          <ConditionalLinkWrapperr
            isLoggedIn={isLoggedIn}
            wrapper={(children) => (
              <Link to={`/people/list/${post?.creator?.id}`}>{children}</Link>
            )}
          >
            <Avatar src={post?.creator?.avatar} />
          </ConditionalLinkWrapperr>
          {onlineStatus && <OnlineIndicator onlineStatus={onlineStatus} />}
        </div>
        <p className="creatorName">
          {post?.creator?.firstName} {post?.creator?.lastName}
          <span className="publishDate">
            {' '}
            | {moment(post?._createdAt).format('ll')}
          </span>
        </p>
        {post?.editor &&
          <EditInfo
            editDetails={{
              authorName: post?.editor?.firstName,
              authorSurname: post?.editor?.lastName,
              date: post?._updatedAt,
            }}
          />}
        {renderStats({ data: post, handleLike, refetchData, refetchGridData })}
      </AvatarContainer>
    </>
  )

  const isTocVisible = useMediaQuery({
    query: `(max-width: ${pabletPX}px)`,
  })

  const showToc = !isTocVisible && enabledToc && toc.length > 0

  let postBody = post?.content

  const SanityFormatCheck = (body) => {
    return Array.isArray(body)
  }

  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${post?.title} post?`}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.continue',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.cancel',
        })}
        onProceed={() => {
          deleteBlogPost()
            .then(() => {
              history.push(`/blog`)
              history.go(0) // TODO this is temp solution to refetch list
            })
            .catch(console.error)
        }}
      />
      <PublicDetailsCard>
        {!user?.authorized && isLikeClicked && (
          <LoginRequiredModal onClose={() => setIsLikeClicked(false)} />
        )}
        <PublicDetailsCard.Header
          coverUrl={post?.coverPhoto}
          onClose={onClose}
          type={appLabel('blog', 'news.title')?.toLowerCase()}
          entityId={post?.id}
          returnText={'Back to all blogs'}
          title={post?.title}
          creator={post?.creator?.id}
          recommendButton={recommendButton}
          entityType={'blog'}
          onDelete={() => setIsDeleteModalOpen(true)}
        />
        <PublicDetailsCard.Container type="blog" showToc={showToc}>
          <PublicDetailsCard.Content position={showToc ? 'left' : 'center'}>
            {categories?.length > 1 && <CategoryTab categories={categories} />}
            <TextSection title={post?.title} className="content-wrapper">
              {_renderAvatarContainer()}
              {SanityFormatCheck(postBody) ? (
                <SanityBlock>{postBody}</SanityBlock>
              ) : (
                <SanitiseHTML html={postBody} />
              )}
            </TextSection>
            <BottomWrapper>
              {renderStats({
                data: post,
                handleLike,
                refetchData,
                refetchGridData,
              })}
              {!isMobileView ? (
                shareButtons.map((item, i) => {
                  const { type, icon, shareLink } = item
                  return (
                    <SharePostButton
                      key={i}
                      icon={icon} 
                      type={type}
                      url={shareLink}
                      post={post}
                      setIsInputShown={setIsInputShown}
                      isInputShown={isInputShown}
                      tipFor={'info-tooltip'}
                      tipData={intl.formatMessage({
                        id: 'blog.tile.sharebutton.users',
                        defaultMessage: 'Send to',
                      })}
                      alignment="left"
                    />
                  )
                })
              ) : (
                <button
                  data-tip={intl.formatMessage({
                    id: 'blog.share.button.tooltip',
                    defaultMessage: 'Share',
                  })}
                  data-for="info-tooltip"
                  className="sharebutton"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsShareMenuOpen(!isShareMenuOpen)
                  }}
                >
                  <i className="fas fa-share" />
                </button>
              )}
              {isMobileView && isShareMenuOpen && (
                <FullscreenLoaderWrapper>
                  <div className="buttons-wrapper">
                    {shareButtons.map((item, i) => {
                      const { name, type, icon, shareLink } = item

                      return (
                        <>
                          <SharePostButton
                            key={i}
                            icon={icon}
                            type={type}
                            name={name}
                            url={shareLink}
                            post={post}
                            setIsInputShown={setIsInputShown}
                            isInputShown={isInputShown}
                          />
                        </>
                      )
                    })}
                    <button
                      className="mobile-cancel-button"
                      onClick={(e) => {
                        e.preventDefault()
                        setIsShareMenuOpen(false)
                        setIsInputShown(false)
                      }}
                    >
                      {intl.formatMessage({
                        id: 'blog.share.button.cancel',
                        defaultMessage: 'Cancel',
                      })}
                    </button>
                  </div>
                </FullscreenLoaderWrapper>
              )}
            </BottomWrapper>
            <ReactTooltip id="info-tooltip" effect="solid" />
          </PublicDetailsCard.Content>
          {showToc ? (
            <PublicDetailsCard.Info position="right" tocView={enabledToc}>
              <TopSectionContainer>{_renderToC()}</TopSectionContainer>
            </PublicDetailsCard.Info>
          ) : null}
        </PublicDetailsCard.Container>
      </PublicDetailsCard>
    </>
  )
}

export default withRouter(BlogPostDetails)

