import React from 'react'
import DatePicker from 'react-date-picker'
import BreakLine from './break-line'
import { DatePickerWrapper } from './styles/date-picker'

const DatePickerComponent = ({
  onSave,
  value,
  isRequired,
  config: { defaultLocale, description, label },
}) => {
  return (
    <>
      <DatePickerWrapper>
        <div className="picker-wrapper">
          <label className="section-title">
            {label}
            {isRequired && <span className="required-star">*</span>}
          </label>
          <p>{description}</p>
          <DatePicker
            calendarClassName="calendar-wrapper"
            value={value ? new Date(value) : new Date()}
            showLeadingZeros
            clearIcon={false}
            locale={defaultLocale?.replace('_', '-')}
            onChange={(value) => {
              onSave(new Date(value).toISOString())
            }}
          />
        </div>
      </DatePickerWrapper>
      <BreakLine />
    </>
  )
}

export default DatePickerComponent

