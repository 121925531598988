import React from 'react'
import { useUser } from '_security/session-hooks'
import { Route, Switch } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'
import ScrollToTop from '_components/scroll-to-top'
import { useFilters } from '_utils/filters'
import { AddNewBlog, ViewDrafts, ViewPublished } from './components/blog-new-button'
import { useLocation } from "react-router-dom";
import BlogConnected from '_blog/connected/blog-grid-connected'
import BlogDetails from '_blog/connected/blog-post-connected'
import { useSetThemeSettings } from '_application'
import { usePageTitle, useAppLabel } from '_application'
import { Container } from '../_styled-components/shared/section-container'
import { useColorBucketForced } from '_colorBus/hooks'
import BlogForm from '_content-studio/forms/blog'
import { useQuery as useRouteQuery } from '_utils/router'
import { useQuery, gql } from '@apollo/client'
import { firstPage } from '_utils/graph-pagination'
// import BlogHeader from './components/blog-header' // left for future use if needed
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'

import styled from '@emotion/styled'

const GET_DATA = gql`
  query GetPostsPublic($paging: PagingInput, $filter: PostsFilterInput, $ordering: [OrderingInput]) {
    getPostsPublic(paging: $paging, filter: $filter, ordering: $ordering)
      @connection(key: "GetPostsPublic", filter: ["filter", "ordering"]) {
      hasMore
      count
      data {
        id
        title
        coverPhoto
        likesCount
        description
        categories {
          id
          title
        }
        categoriesNew {
          id
          name
          groupBy
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const BlogContainer = styled(Container)`
  background-color: var(--background);
`

const Blogs = () => {
  const appLabel = useAppLabel()
  const {pathname } = useLocation();
  const [category, setCategory] = useRouteQuery('category')
  const isDrafts = pathname?.includes('drafts')
  const title = isDrafts ? "Drafts" : appLabel('blogPlural', 'news.titleplural')
  const description = appLabel('blogDescription', 'empty')
  const user = useUser()
  const isAdmin = user?.profile?.role === 'admin'

  const { filter } = useFilters()

  usePageTitle(title)
  useSetThemeSettings({ hasAlternativeBgc: false, hasAlternativeColor: false })
  let ordering = undefined
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
    }
  }

  const {
    data: postsData,
    error: postsError,
    fetchMore,
    refetch
  } = useQuery(GET_DATA, {
    variables: {
      ...firstPage(),
      paging: { offset: 0, limit: 9 },
      filter: { category: category === 'all' ? undefined : category },
      ordering
    },
  })

  const {
    data: draftPostsData,
    error: draftPostsError,
    fetchMore: fetchMoreDrafts,
  } = useQuery(GET_DATA, {
    variables: {
      ...firstPage(),
      paging: { offset: 0, limit: 9 },
      filter: { category: category === 'all' ? undefined : category, isDraft: true },
    },
  })

  const [getElement] = useColorBucketForced('blog')

  return (
    <Switch>
      <Route path={['/blog/new', '/blog/:id/edit']} component={BlogForm} />
      <MainLayout>
        <ScrollToTop />
        <BlogContainer ref={getElement} className={'page-wrapper'}>

          <PageTitle
            description={description}
            blogTitle={true}
            actions={
              <>
                {!isDrafts && <ViewDrafts />}
                {isDrafts && <ViewPublished />}
                <AddNewBlog />
              </>
            }
          >
            {title}
          </PageTitle>
          {/* <BlogHeader filter={filter} onFilterChange={setFilter} /> */}
          <Switch>
            <Route
              path="/blog/:id"
              exact
              render={({ match, history }) => (
                <BlogDetails
                  id={match.params.id}
                  onClose={() => history.goBack()}
                  refetchGridData={refetch}
                />)} />
          </Switch>

          <Switch>
            <Route
              path="/blog/view/drafts"
              render={() => (
                isAdmin && <BlogConnected
                  postsData={draftPostsData}
                  postsError={draftPostsError}
                  category={category}
                  setCategory={setCategory}
                  fetchMore={fetchMoreDrafts}
                />
              )}
            />

            <Route
              path="/blog"
              render={() => (
                <BlogConnected
                  postsData={postsData}
                  postsError={postsError}
                  category={category}
                  setCategory={setCategory}
                  fetchMore={fetchMore}
                />
              )}
            />
          </Switch>

        </BlogContainer >
      </MainLayout >
    </Switch>

  )
}

export default Blogs
