import styled from '@emotion/styled'
import border from '_utils/css-border'

export const MainWrapper = styled.div``
export const Avatars = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`
export const SpeakersWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  min-height: 85px;
  box-shadow: 0px 3px 8px rgb(0 0 0 / 16%);
  padding: 30px 20px;
  background-color: var(--background);
`
export const SingleSpeaker = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .disabled-link {
    pointer-events: none;
  }
`
export const Avatar = styled.img`
  ${border()}
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
  border: 2px solid var(--foreground);
`
export const Name = styled.div`
  font-size: 16px;
`
export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  padding-right: 20px;
`
