import translations from "./translations"

export const validate = ({ input, manageBy }) => {
  if (manageBy === "ats") {
    return !isValidHttpUrl(input) ? translations.input.ats.error : null
  } else if (input?.length > 0) {
    return !isEmailValid(input) ? translations.input.app.error : null
  } 
  return null
}

const isEmailValid = (email) => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))

const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}