import styled from '@emotion/styled'
import border from '_utils/css-border'

export const DefaultTileStyled = styled.div`
  height: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  ${border()}
`

export const Header = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center;
  height: 200px;
  border-radius: 25px 25px 0 0;
  position: relative;
  z-index: 0;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 0 0;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.28)
    );
    z-index: -1;
  }
  .location {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    font-size: 12px;
    color: var(--imgColor);
    width: 100%;
    max-width: 80%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    svg {
      margin-right: 5px;
      width: 12px;
      path {
        stroke: var(--imgColor);
      }
    }
  }
`

export const EntityInfo = styled.div``

export const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  color: var(--foreground);
  flex: 1;
`

export const Description = styled.div`
  color: var(--foreground);
  text-align: left;
  margin-top: 15px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`

export const Title = styled.h2`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const DiscoverTitle = styled.h3`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const Date = styled.div`
  font-size: 12px;
  margin-top: 2px;
  font-weight: 500;
`

export const ConnectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  button {
    margin-top: 30px;
  }
`
