import { useEffect, useRef } from 'react'
import io from 'socket.io-client'
import store from 'store'
import { useUser } from '_security/session-hooks'
import { useEventBus } from '_event-bus'
import { isWebRTCSupported } from '_utils/isWebRTCSupported'

const PEXIP_SERVER = process.env.REACT_APP_PEXIP_SERVER
const MEET_SOCKET = process.env.REACT_APP_MEET_SOCKET

const loadPexRTC = () => {
  if (isWebRTCSupported()) {
    const jsScript = document.createElement('script')
    jsScript.src = `https://${PEXIP_SERVER}/static/webrtc/js/pexrtc.js`
    document.head.appendChild(jsScript)
  }
}

export const useMeetSocket = () => {
  const user = useUser()
  const socket = useRef()
  const eventBus = useEventBus()

  const userId = user?.profile?.id

  useEffect(() => {
    const token = store.get('RIOT/token')
    if (token && userId) {
      socket.current = io(MEET_SOCKET, {
        path: '/meet-socket',
        resource: '/meet-socket',
        auth: { token },
      })
      socket.current.on('connection', () =>
        console.log('Meet socket connected')
      )
      socket.current.on('disconnect', () =>
        console.log('Meet socket disconnected')
      )
      socket.current.onAny(eventBus.emit)
    } else {
      console.warn('User not authorized to socket connection')
    }

    return () => {
      socket.current && socket.current.disconnect()
    }
  })

  useEffect(() => {
    const disconnectOnUnload = () =>
      socket.current && socket.current.disconnect()

    window.addEventListener('unload', disconnectOnUnload)
    return () => window.removeEventListener('unload', disconnectOnUnload)
  }, [userId])
}

export const InitializeMeet = () => {
  const user = useUser()
  const authorized = user?.authorized
  useEffect(() => {
    if (authorized) {
      loadPexRTC()
    }
  }, [authorized])
  useEffect(() => {
    //Hack to make ringtone work
    const AudioContext = window.AudioContext || window.webkitAudioContext
    if (AudioContext) new AudioContext()
  }, [])

  useMeetSocket()
  return null
}

