/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import styled from '@emotion/styled'
import { nextPage, mergePage } from '_utils/graph-pagination'
import OpportunitiesGrid from '../components/opportunities-grid'
import { useIntl } from 'react-intl'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'
import { getPageSizeForScreenSize } from '_tiles/helpers'

import { ctaButtonHollow } from '_utils/css-buttons'

const GET_OPPORTUNITIES = gql`
  query GetAllOpportunitiesList(
    $filter: OpportunityFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllOpportunitiesPublic(
      filter: $filter
      paging: $paging
      ordering: $ordering
    )
    @connection(
      key: "GetAllOpportunitiesList"
      filter: ["filter", "ordering"]
    ) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        applicationCount
        expire
        location
        _createdAt
        isDraft
        connectedCompanies {
          id
          coverPhoto
          name
          location
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

const updateQuery = mergePage('getAllOpportunitiesPublic')

const OpportunitiesGridConnected = ({ filter }) => {
  let ordering = undefined
  const detailedFilter = filter
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error, fetchMore } = useQuery(GET_OPPORTUNITIES, {
    variables: {
      filter,
      ordering,
      paging: {
        limit: getPageSizeForScreenSize({ pageType: 'grid' }),
        offset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
    // quick way to update view after accepting entity-manager-invitation.
    // we can remove this if we update the cache directly after accepting.
    fetchPolicy: 'cache-and-network',
  })

  if (!data) return <Loading />
  if (error) return <Error />

  const { data: opportunities, hasMore } = data?.getAllOpportunitiesPublic

  return (
    <div>
      {data && (
        <OpportunitiesGrid
          filter={detailedFilter}
          opportunities={opportunities}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={fetchMore}
          filter={filter}
          offset={opportunities.length}
        />
      )}
    </div>
  )
}
OpportunitiesGridConnected.propTypes = {
  filter: PropTypes.object,
}

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(
                  offset,
                  getPageSizeForScreenSize({ pageType: 'grid' })
                ),
                filter,
                updateQuery,
              })
            }
          >
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i class="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}
export default OpportunitiesGridConnected
