import React from 'react'
import { useIntl } from 'react-intl'
import SubsectionHeader from '../shared/subsection-header'

import { MainContainer } from './styles'

const Labels = () => {
  const intl = useIntl()

  return (
    <MainContainer>
      <SubsectionHeader
        title={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.labels.title',
          defaultMessage: 'Labels',
        })}`}
        description={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.labels.description',
          defaultMessage:
            'Here you can override the labels that is shown in the menu and on the section page.',
        })}`}
      />
    </MainContainer>
  )
}

export default Labels
