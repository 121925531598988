import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'
import { Grid, BaseTile } from '_tiles'
import { Default as ActivityTile } from './tiles'
import { filterToQueryString } from '_utils/filters'

const gridConfig = {
  hasGutter: true,
}
const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const ActivityGrid = ({ activities, filter }) => {
  const queryString = filterToQueryString(filter)
  return (
    <DashboardGridTemp>
      <Grid config={gridConfig} testid="activities">
        {map(activities, ({ activity, connectButton, isOwner }) => (
          <BaseTile
            url={`/activities/list/${activity.id}`}
            queryParams={queryString}
            config={defaultConfig}
            testid="activity"
          >
            <ActivityTile
              activity={activity}
              connectButton={connectButton}
              isOwner={isOwner}
            />
          </BaseTile>
        ))}
      </Grid>
    </DashboardGridTemp>
  )
}

ActivityGrid.propTypes = {
  companies: PropTypes.array.isRequired,
}

export default ActivityGrid
