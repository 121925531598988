import { IKUIUserAPI } from '@indykiteone/jarvis-sdk-web'

export const refreshToken = ({ dispatch, user }) => {
  if (!user?.authorized || user.loading || !IKUIUserAPI.isInitialized()) {
    return
  }
  try {
    console.log('refreshToken: Refreshing access token')
    IKUIUserAPI.getValidAccessToken()
      .then((data) => {
        if (data) {
          localStorage.setItem('RIOT/token', data)
        }
      })
      .catch((err) => {
        console.warn('refreshToken: Logging out', err)
        console.warn('asdf refreshToken: Logging out', err)
        if (user?.authorized) {
          dispatch({
            type: 'user/LOGOUT',
            payload: {},
          })
        }
      })
  } catch (e) {
    console.warn('refreshToken: ', e)
  }
}
