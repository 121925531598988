import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useQuery, useMutation, gql } from '@apollo/client'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCustomAttributes } from '_custom-attributes/hooks'

import ConnectButton from '_components/buttons-connections/connect-button'
import { Loader } from '_components/loading'
import { Modal } from '_components/drilldown'
import { getDisplayName } from '_people/people-models'
import { Link, Redirect } from 'react-router-dom'
import { useUserAccess } from '_security/helpers'

import CompanyDetails from '../components/company-details'
import RecommendButton from '_components/buttons-connections/recommend-button'
import { sendNotification } from '_components/buttons-connections/message-helper'
import ErrorModal from '_components/modals/error-modal'

const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      coverPhoto
      bannerImage
      description
      body
      location
      connectionStatus
      homepage
      founded
      connectedUsersCount
      connectedActivitiesCount
      connectedOpportunitiesCount
      createdByMe
      isDraft
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        lastName
        avatar
      }
      editor {
        id
        sub
        firstName
        lastName 
        avatar
      }
      _updatedAt
      creatorId
      customAttributes {
        key
        value
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomy {
        key
        values {
          type
          value
        }
      }
    }
  }
`

export const CONNECT_MUTATION = gql`
  mutation ConnectCompany($id: String!, $creatorId: String!) {
    connectToCompany(id: $id, creatorId: $creatorId) {
      status
    }
  }
`

const Props = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const CompanyDetailsConnected = ({ id, onClose }) => {
  const intl = useIntl()
  const { data, error, loading } = useQuery(GET_COMPANY, { variables: { id } })

  const currentUser = useSelector((state) => state.user)

  const company = data?.getCompany

  const isOwner = currentUser?.sub === company?.creator?.sub
  const isAdmin = currentUser?.profile?.role === 'admin'

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION, {
    variables: { id, creatorId: company?.creatorId || company?.creator?.sub },
    refetchQueries: [{ query: GET_COMPANY, variables: { id } }],
    awaitRefetchQueries: true,
  })

  const { attributes } = useCustomAttributes('companies')
  const isCompanyNotFound =
    !loading && (company == null || (!isOwner && !isAdmin && company?.isDraft))

  const hasUserAccess = useUserAccess()

  if (!hasUserAccess) {
    return <Redirect to="/companies/list" />
  }

  return (
    <>
      {isCompanyNotFound ? (
        <ErrorModal
          show={true}
          title={intl.formatMessage({
            id: 'modal.error.contentnotfound.title',
            defaultMessage: 'Ups! This information is private',
          })}
          description={intl.formatMessage({
            id: 'modal.error.contentnotfound.description',
            defaultMessage:
              "We're sorry, this page might have been removed or can only be accessed by platform administrators.",
          })}
          handleClose={onClose}
          button={
            <Link to="/">
              <button className="proceed-button">
                {intl.formatMessage({
                  id: 'modal.error.contentnotfound.button',
                  defaultMessage: 'homepage',
                })}
              </button>
            </Link>
          }
        ></ErrorModal>
      ) : (
        <Modal
          onClose={onClose}
          header={
            company && (
              <Header
                company={company}
                onConnect={connect}
                connecting={connecting}
              />
            )
          }
        >
          <Loader loading={loading} error={error}>
            {() => (
              <CompanyDetails
                onClose={onClose}
                company={company}
                attributes={attributes}
                connectButton={
                  <ConnectButton
                    status={company?.connectionStatus}
                    onConnect={() =>
                      connect()
                        .then((res) => {
                          const { status } = res?.data?.connectToCompany
                          sendNotification({
                            type: 'connection',
                            intl,
                            defaultHeader: 'Connection',
                            defaultContent: `You have ${
                              status === 'cancelled'
                                ? 'cancelled your connection'
                                : 'sent a connection request'
                            }`,
                          })
                        })
                        .catch((err) => console.log('error', err))
                    }
                    disabled={connecting}
                    type="companies"
                  />
                }
                //c2aButton={<C2AButton companyId={company.id}></C2AButton>}
                p2pButton={{
                  refetchQueries: [{ query: GET_COMPANY, variables: { id } }],
                }}
                isOwner={isOwner}
                recommendButton={
                  <RecommendButton
                    contentToRecomment={company.id}
                    queryKey={'companyToRecommend'}
                  />
                }
              ></CompanyDetails>
            )}
          </Loader>
        </Modal>
      )}
    </>
  )
}
CompanyDetailsConnected.propTypes = Props

const Header = ({ company, onConnect, connecting }) => (
  <Modal.Header
    cover={company.bannerImage}
    avatar={company.coverPhoto}
    title={getDisplayName(company.creator)}
    subject={company.name}
    subtitle={company.creator?.bioShort}
    tag={<FormattedMessage id="companies.title" />}
  >
    <ConnectButton
      status={company?.connectionStatus}
      onConnect={onConnect}
      disabled={connecting}
    />
  </Modal.Header>
)

export default CompanyDetailsConnected
