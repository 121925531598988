import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import riotSpinner from './riotSpinner.json'

import { Player } from '@lottiefiles/react-lottie-player'

const Container = styled.div`
  height: 200px;
  overflow: hidden;
  .lottiePlayer {
    max-width: 790px;
    svg {
      transform: translate3d(0px, -30%, 0px) !important;
    }
    path {
      fill: var(--flavor);
    }
  }
`

export const Loading = ({ className, testid }) => {
  return (
    <Container
      className={className ?? ''}
      data-testid={`${testid ?? 'default'}Loader`}
    >
      <Player
        autoplay={true}
        loop={true}
        controls={false}
        src={riotSpinner}
        className="lottiePlayer"
      ></Player>
    </Container>
  )
}
Loading.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
}

export default Loading
