import React, { useState } from 'react'
//import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon'
import {
  SelectOption,
  SelectVariant,
  SelectDirection,
} from '@patternfly/react-core'
import { useIntl } from 'react-intl'
import { StyledSelect } from '../styles'

const intlKey = 'application.management.'

const ApplicationSortSelect = ({ sortState }) => {
  const [sort, setSort] = sortState
  const intl = useIntl()

  const options = ['received', 'last updated', 'list', 'state'].map(
    (option) => (
      <SelectOption key={option} value={option}>
        {intl.formatMessage({
          id: `${intlKey}sort.${option?.split(' ')?.join('')}`,
        })}
      </SelectOption>
    )
  )

  const [isOpen, setOpen] = useState(false)

  const onSelect = (e, selection) => {
    setSort(selection)
    setOpen(false)
  }

  const titleId = 'title-id-1'
  return (
    <div>
      <span id={titleId} hidden>
        Title
      </span>
      <StyledSelect
        //toggleIcon={<CubeIcon />}
        variant={SelectVariant.single}
        aria-label="Select Input"
        onToggle={setOpen}
        onSelect={onSelect}
        selections={sort}
        placeholderText="filters"
        isOpen={isOpen}
        aria-labelledby={titleId}
        direction={SelectDirection.down}
      >
        {options}
      </StyledSelect>
    </div>
  )
}

export default ApplicationSortSelect
