import React from 'react'
import { useIntl } from 'react-intl'
import SectionWithSearch from './components/section-with-search'
import { MainWrapper } from './styles'
import { useAppLabel } from '_application'
import { useHistory, useParams } from 'react-router-dom'

import { useCustomAttributes } from '_custom-attributes/hooks'
import CustomAttributes from '_custom-attributes/editor'
import EventKeywords from './components/keywords'
import ActionButtons from './components/action-buttons'
import EventTaxonomy from './components/taxonomy'
import {
  CONNECT_EVENT_TO_ACTIVITY,
  CONNECT_EVENT_TO_COMPANY,
  CONNECT_EVENT_TO_PERSON,
  DISCONNECT_EVENT_TO_COMPANY,
  DISCONNECT_EVENT_TO_ACTIVITY,
  DISCONNECT_EVENT_TO_PERSON,
} from './gql'
import BreakLine from '_custom-attributes/editor/components/breakline'

import EntityConnection from '_content-studio/components/entity-connections'

const AdditonalInformation = ({
  event,
  setEvent,
  eventAttributesConfig,
  updateEvent,
  locationObject,
  refetchEventData,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()
  const history = useHistory()
  const { eventId } = useParams()

  const { attributes } = useCustomAttributes('events')

  const canRenderConnections = true

  const sectionsTypes = [
    {
      name: 'speakers',
      type: 'USER',
      onConnect: CONNECT_EVENT_TO_PERSON,
      onDisconnect: DISCONNECT_EVENT_TO_PERSON,
      connectionKey: 'Person',
    },
    {
      name: 'activities',
      type: 'ACTIVITY',
      onConnect: CONNECT_EVENT_TO_ACTIVITY,
      onDisconnect: DISCONNECT_EVENT_TO_ACTIVITY,
      connectionKey: 'Activity',
    },
    {
      name: 'companies',
      type: 'COMPANY',
      onConnect: CONNECT_EVENT_TO_COMPANY,
      onDisconnect: DISCONNECT_EVENT_TO_COMPANY,
      connectionKey: 'Company',
    },
  ]

  const renderKey = (type, key) => {
    const isPlural = key === 'description'

    return intl.formatMessage(
      {
        id: `events.createevent.additionalinfo.${type}.${key}`,
      },
      {
        [type]: appLabel(
          `${isPlural ? type + 'Plural' : type}`,
          `${type}.title${isPlural ? 'plural' : ''}`
        ).toLowerCase(),
      }
    )
  }

  const actionButtonsConfig = {
    onCancel: () =>
      history.push(`/events/manage/general-information/${eventId}`),
    onSuccess: () => {
      if (event?.status !== 'PUBLISHED') {
        updateEvent()
        setEvent({
          ...event,
          status: 'PUBLISHED',
        })
      } 
      history.push(`/events/manage/preview-information/${event?.id}`)
    },
    rejectBtnContent: intl.formatMessage({ id: 'events.create.button.back' }),
    successBtnContent: intl.formatMessage({
      id: 'events.create.button.success',
    }),
  }

  return (
    <MainWrapper>
      {canRenderConnections && (
        <div className="connections">
          <EntityConnection
            title={renderKey('speakers', 'description')}
            entityType={'user'}
            entityName={intl.formatMessage({ id: 'speakers.title' })}
            connectionType="e2p"
            id={eventId}
            connections={event?.speakers}
          />
          <EntityConnection
            title={renderKey('activities', 'description')}
            entityType={'activity'}
            entityName={appLabel('activities', 'activities.title')}
            connectionType="e2a"
            id={eventId}
            connections={event?.activities}
          />
          <EntityConnection
            title={renderKey('companies', 'description')}
            entityType={'company'}
            entityName={appLabel('companies', 'companies.title')}
            connectionType="e2c"
            id={eventId} 
            connections={event?.companies}
          />
        </div> 
      )}
      <CustomAttributes
        attributes={attributes}
        attributesValues={event?.attributes}
        attributesConfig={eventAttributesConfig}
      />
      <EventKeywords event={event} setEvent={setEvent} />
      <EventTaxonomy
        event={event}
        onSave={(key, values) => {
          const isSelected = event.topics.some((topic) => topic.key === key)

          const updatedTopicsList = isSelected
            ? event.topics.filter((topic) => topic.key !== key)
            : event.topics

          setEvent({
            ...event,
            topics: [...updatedTopicsList, { key, childrenKeys: values }],
          })
        }}
      />

      <ActionButtons config={actionButtonsConfig} />
    </MainWrapper>
  )
}

export default AdditonalInformation
