import store from 'store'
import { isEmpty } from 'lodash'
import { validateResponse, toJson } from '_utils/fetch'

const MEET_ENDPOINT = process.env.REACT_APP_MEET_ENDPOINT

export const bookDirectCall = (userId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/instant', {
    method: 'POST',
    body: JSON.stringify({ with: userId }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(validateResponse)
    .then(toJson)
}

export const fetchCall = (callId, signal) => {
  const accessToken = store.get('RIOT/token')

  return fetch(`${MEET_ENDPOINT}/call/${callId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then(validateResponse)
    .then(toJson)
}

export const acceptCall = (callId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(`${MEET_ENDPOINT}/call/${callId}/accept`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then(validateResponse)
    .then(toJson)
}

export const rejectCall = (callId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(`${MEET_ENDPOINT}/call/${callId}/reject`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then(validateResponse)
    .then(toJson)
}

export const endCall = (callId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(`${MEET_ENDPOINT}/call/${callId}/close`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then(validateResponse)
    .then(toJson)
}

export const fetchUserStatus = (userId) => {
  const accessToken = store.get('RIOT/token')

  if(!accessToken){
    return Promise.reject({
      status: 401,
      message: 'Unauthorized'
    })
  }

  return fetch(`${MEET_ENDPOINT}/status/user/${userId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then(validateResponse)
    .then(toJson)


}

export const fetchUserStatuses = (userIds) => {
  const accessToken = store.get('RIOT/token')

  if(!accessToken){
    return Promise.reject({
      status: 401,
      message: 'Unauthorized'
    })
  }

  if (isEmpty(userIds)) {
    return Promise.resolve({})
  }

  return fetch(`${MEET_ENDPOINT}/status/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ ids: userIds }),
  })
    .then(validateResponse)
    .then(toJson)
    .then((json) => {
      const output = {}
      Object.keys((key) => (output[key] = json[key]?.status))
      return output
    })
}
