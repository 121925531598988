import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Redirect } from 'react-router-dom'
import { useUser } from '_security/session-hooks'

const Props = {
  isAuthRequired: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export const RequiresLogin = ({ isAuthRequired, children }) => {
  const location = useLocation()
  const user = useUser()
  const isLoggedIn = user?.profile?.role

  if (isAuthRequired && !isLoggedIn) {
    return <Redirect from={location} to="/login" push />
  }

  return children
}

RequiresLogin.propTypes = Props

export default RequiresLogin
