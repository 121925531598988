import React, { useCallback } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import GoogleMaps from 'components/indy/shared/google-maps'
import { Container } from './styles'

const Props = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      data: PropTypes.any,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
}

const Map = React.memo(({ markers, onSelect, onBoundsChange }) => {
  const handleMarkerClick = useCallback((m) => onSelect(m.data), [onSelect])

  const handleGetVisibleMarkers = debounce(
    ({ ids }) => onBoundsChange({ ids }),
    500,
    { maxWait: 2000, trailing: true }
  )

  return (
    <Container>
      <GoogleMaps
        markers={markers}
        onMarkerClick={handleMarkerClick}
        getVisibleMarkers={handleGetVisibleMarkers}
        onBoundsChange={() => {}}
      />
    </Container>
  )
})

Map.propTypes = Props

export default Map
