import styled from '@emotion/styled'

export const PhoneNumberStyled = styled.div`
  .phone-number-styled {
    border: solid 1px var(--foreground);
    height: 40px;
    width: 410px;
    padding: 10px 16px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.googleFont} !important;
    color: var(--foreground);

    .PhoneInputCountry {
      &::after {
        color: var(--foreground);
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f078';
        font-size: 12px;
        margin-left: 10px;
        margin-top: 2px;
      }
    }
  }
  .PhoneInputInput {
    background-color: transparent;
    border: none !important;
    font-family: ${(props) => props.theme.fonts.googleFont} !important;
    color: var(--foreground);
    font-size: 14px;
  }
  .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    box-shadow: none !important;
    img {
    }
    .PhoneInputCountryIcon {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .PhoneInputCountrySelectArrow {
    font-size: 28px;
    margin-top: -3px;
    font-weight: 600;
    opacity: 0;
    outline: none;
    display: none;
  }

  .PhoneInputCountrySelect {
    background: var(--background);
    color: var(--foreground);
  }
`
