import React from 'react'

import Modal from './add-new-general-modal'

const Edit = ({ onUpdate, onClose, currentData, type }) => {
  return (
    <Modal
      modalType={type}
      modalProps={currentData}
      onAdd={(p) => onUpdate(p)}
      onClose={() => onClose()}
    />
  )
}

export default Edit
