import styled from '@emotion/styled'

export const Section = styled.div`
  margin: 10px 0 10px 0;
`

export const Image = styled.img`
  max-width: 50%;
`

export const Container = styled.div``
