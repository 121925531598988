import React from 'react'
import PropTypes from 'prop-types'
// import Tag from '_components/tag'
import SubSectionTitle from '_components/subsection-title'

import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile } from '_utils/responsive-queries'

const Props = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
}

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 20px;
`

// const Badge = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 0 30px 0 0;

//   .circle-background {
//     width: 66px;
//     height: 66px;
//     border-radius: 100%;
//     background-color: var(--background);
//     border: 2px solid white;
//     padding: 10px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
//     margin: 0 auto;
//   }
//   span {
//     font-size: 12px;
//     margin: 10px 0 25px;
//     text-align: center;
//     font-weight: 600;
//   }
//   img.icon {
//     width: 100%;

//   }
// `

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 20px 0; */
  width: 100px;
  align-self: start;

  .circle-background {
    ${border()}
    overflow: hidden;
    border-radius: 100%;
    width: 66px;
    height: 66px;
  }
  img {
    display: inline-block;
    border-radius: 100%;
    border: 5px solid white;
    width: 66px;
    height: 66px;
    object-fit: cover;
    object-position: center;
  }
  span.badge-name {
    color: inherit;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    margin: 10px auto 0;
    /* min-height: 36px; */

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;

    ${mobile} {
      margin-top: 10px;
    }
  }
`
export const BadgeComponent = (value) => {
  return (
    <Badge>
      <div className="circle-background">
        <img className="icon" src={value.icon} alt={value.name} />
      </div>
      <span className="badge-name">{value.name}</span>
    </Badge>
  )
}

const TagList = ({ title, values }) => {
  return (
    <div>
      {title && <SubSectionTitle>{title}</SubSectionTitle>}
      <TagListWrapper>{values.map(BadgeComponent)}</TagListWrapper>
    </div>
  )
}
TagList.propTypes = Props

export default TagList
