import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const animations = {
  blink: keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }`,
}

const Blink = styled.div`
  opacity: 1;
  animation: ${animations.blink} 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
    infinite;
`

export default Blink
