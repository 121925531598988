import React, { useState, useMemo } from 'react'
import { getTreeFromFlatData } from 'react-sortable-tree'
import SortableTree from 'components/indy/shared/taxonomy/components/sortable-tree'
import { TaxonomiesWrapper, SingleTaxonomyWrapper } from './styles/taxonomies'
import { useQuery, gql } from '@apollo/client'
import BreakLine from './break-line'

const GET_TAXONOMY = gql`
  query GetTaxonomy($key: String, $usedBy: [String]) {
    getTaxonomy(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      treeData {
        key: id
        name
        parent
        refId
      }
    }
  }
`

const SingleTaxonomy = ({ singleTax, onSelect, selectedValues }) => {
  const [treeData, setTreeData] = useState()

  useMemo(() => {
    const convert = getTreeFromFlatData({
      flatData: singleTax.treeData.map((node) => ({
        id: node.key,
        title: node.name,
        refId: node.refId,
        parent: node.parent,
      })),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    })
    setTreeData(convert)
  }, [singleTax])
  try {
    const handleSelectTaxonomy = ({ item, isSelected }) => {
      let list = selectedValues || []

      if (isSelected) list = list.filter((li) => li !== `${item.refId}`)
      else list.push(`${item.refId}`)
      handleUpdateSelectList(list)
      onSelect(singleTax.key, list)
    }

    const handleUpdateSelectList = (list) => {
      let t = treeData
      t = treeData.concat({ title: 'z' })
      t = t.slice(0, t.length - 1)
      setTreeData(t)
    }

    return (
      <SingleTaxonomyWrapper>
        <h1 className="taxonomy-title">{singleTax.name}</h1>
        <p className="taxonomy-description">{singleTax.description}</p>
        <div className="taxonomy-tree-wrapper">
          <SortableTree
            selectedValues={selectedValues}
            key={singleTax.key}
            treeData={treeData}
            onSelect={({ item, isSelected }) => {
              handleSelectTaxonomy({ item, isSelected })
            }}
          />
        </div>
      </SingleTaxonomyWrapper>
    )
  } catch (e) {
    console.error(e)
    return null
  }
}

const Taxonomies = ({ entityType, taxonomies = [], changeFormProperty }) => {
  const { data: taxonomyData } = useQuery(GET_TAXONOMY, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: [entityType] },
  })

  const onSelect = (key, values) => {
    const isSelected = taxonomies?.some((taxonomy) => taxonomy.key === key)

    const updatedTaxonomiesList = isSelected
      ? taxonomies.filter((taxonomy) => taxonomy.key !== key)
      : taxonomies

    changeFormProperty({
      taxonomy: [
        ...updatedTaxonomiesList,
        { key, values: values.map((item) => ({ value: item })) },
      ],
    })
  }

  if (!taxonomyData) return <></>

  return (
    <TaxonomiesWrapper>
      {taxonomyData &&
        taxonomyData.getTaxonomy.map((item) => {
          const taxonomiesSelected = taxonomies.find(
            (taxonomy) => taxonomy.key === item.key
          )
          return (
            <>
              <SingleTaxonomy
                singleTax={item}
                onSelect={onSelect}
                selectedValues={
                  taxonomiesSelected
                    ? taxonomiesSelected?.values?.map((item) => item.value)
                    : []
                }
              />
              <BreakLine />
            </>
          )
        })}
    </TaxonomiesWrapper>
  )
}

export default Taxonomies

