/* eslint-disable prefer-destructuring */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import store from 'store'
import actions from './actions'

export function* GET_CHANNELS() {
  const response = yield call(() =>
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/message/channels`,
      method: 'get',
      headers: { authorization: store.get('RIOT/token') },
    }).then((r) => r)
  )
  yield put({
    type: actions.SET_CHANNELS,
    payload: response.data,
  })
}
export function* GET_CURRENT_CHANNEL({ payload }) {
  yield put({
    type: actions.SET_CURRENT_CHANNEL,
    payload,
  })
}
export function* GET_CURRENT_CHANNEL_MESSAGES({ payload }) {
  const response = yield call(() =>
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/message/forchannel/${payload}`,
      method: 'get',
      headers: { authorization: store.get('RIOT/token') },
    }).then((r) => r)
  )
  yield put({
    type: actions.SET_CURRENT_CHANNEL_MESSAGES,
    payload: response.data,
  })
}
export function* GET_APPEND_ARTICLE_CONNECTIONS({ payload, ispeople }) {
  const response = yield call(() =>
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/connect/connections/article/${payload}/${ispeople}`,
      method: 'get',
      headers: { authorization: store.get('RIOT/token') },
    }).then((r) => r)
  )
  yield put({
    type: actions.SET_APPEND_ARTICLE_CONNECTIONS,
    payload: response.data,
    articleId: payload,
  })
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CHANNELS, GET_CHANNELS),
    takeEvery(actions.GET_CURRENT_CHANNEL, GET_CURRENT_CHANNEL),
    takeEvery(
      actions.GET_CURRENT_CHANNEL_MESSAGES,
      GET_CURRENT_CHANNEL_MESSAGES
    ),
    takeEvery(
      actions.GET_APPEND_ARTICLE_CONNECTIONS,
      GET_APPEND_ARTICLE_CONNECTIONS
    ),
  ])
}
