export const getFormDataFields = (data) => {
  return {
    id: data?.id,
    title: data?.title,
    coverPhoto: data?.coverPhoto,
    description: data?.description,
    content: data?.content,
    isRestricted: data?.isRestricted,
    isFeatured: data?.isFeatured,
    enabledToc: data?.enabledToc,
    publishedAt: data?.publishedAt,
    isDraft: data?.isDraft
  }
}
