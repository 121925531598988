import React from 'react'
import {
  SubsectionHeaderStyled,
  SubsectionDescription,
  SubsectionTitle,
} from './styles/subsection-header'

const SubsectionHeader = ({ title = '', description = '', children }) => {
  return (
    <SubsectionHeaderStyled>
      <div>
        <SubsectionTitle>{title}</SubsectionTitle>
        <SubsectionDescription>{description}</SubsectionDescription>
      </div>

      {children}
    </SubsectionHeaderStyled>
  )
}

export default SubsectionHeader
