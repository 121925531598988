import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useAppLabel } from '_application'
import { useIntl } from 'react-intl'

import PersonInfo from './person-info'
import AboutMe from './about-me'
import Education from './education'
import Experience from './experience'
import Taxonomy from './taxonomy'
import Keywords from './keywords'
import CustomAttributes from './custom-attributes'
import Connections from './connections'
import PublicDetailsCard from '_public-details-card'
import MyActivities from './my-activities'
import MyCreations from './my-creations'
import SocialMedia from './social-media'

import { BackgroundImage } from './styles/person-info'

import {
  UserWrapper,
  ContentWrapper,
  TopBackButtonWrapper,
  BottomBackButtonWrapper,
  ConnectionsWrapper,
  TabsWrapper,
  GoBackButton,
} from './styles/person-detailed-view'
import ScrollToTop from '_components/scroll-to-top'

const PersonDetailedView = ({
  person,
  disconnectButton,
  connectButton,
  connections,
  connectionsLoading,
  p2pButton,
  recommendButton,
}) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const location = useLocation()
  const history = useHistory()

  const tabs = {
    about: { type: 'about', translationKey: 'user.profile.about' },
    mycreations: {
      type: 'myCreations',
      translationKey: 'person.details.mycreations',
    },
    activity: {
      type: 'connection',
      translationKey: 'activities.titleplural',
      count: connections?.connectedActivitiesCount,
    },
    company: {
      type: 'connection',
      translationKey: 'companies.titleplural',
      count: connections?.connectedCompaniesCount,
    },
    people: {
      type: 'connection',
      translationKey: 'people.titleplural',
      count: connections?.connectedUsersCount,
    },
  }

  const firstLetterToUppercase = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])

  const renderContent = () => {
    const type = tabs[currentTab]?.type

    if (type === 'about')
      return (
        <UserWrapper>
          <ScrollToTop />
          <AboutMe person={person} />
          <Experience person={person} />
          <Education person={person} />
          <MyActivities person={person} />
          <Keywords person={person} />
          <CustomAttributes person={person} />
          <Taxonomy person={person} />
          <SocialMedia person={person}></SocialMedia>
        </UserWrapper>
      )
    else if (type === 'myCreations')
      return (
        <ConnectionsWrapper>
          <MyCreations creatorId={person?.id} pageHeight={'auto'} />
        </ConnectionsWrapper>
      )
    else
      return (
        <ConnectionsWrapper>
          <Connections
            currentTab={currentTab}
            entityId={person?.sub}
            page={'peopleDetail'}
          />
        </ConnectionsWrapper>
      )
  }

  if (!person) return null

  const onBackButtonClick = () => {
    if (location.key) {
      history.goBack()
    } else {
      history.push('/people/list')
    }
  }
  return (
    <>
      <div>
        <TopBackButtonWrapper>
          <button onClick={onBackButtonClick}>
            <i className="fas fa-arrow-left" />
          </button>
        </TopBackButtonWrapper>
        <BackgroundImage src={person?.coverPhoto} alt="" />
      </div>
      <ContentWrapper>
        <PersonInfo
          person={person}
          disconnectButton={disconnectButton}
          connectButton={connectButton}
          connections={connections}
          connectionsLoading={connectionsLoading}
          p2pButton={p2pButton}
          recommendButton={recommendButton}
        />
        <TabsWrapper>
          <PublicDetailsCard.ContentTabs
            tabs={tabs}
            onTabSelect={setCurrentTab}
          />
        </TabsWrapper>
        {renderContent()}
      </ContentWrapper>
      <BottomBackButtonWrapper>
        <GoBackButton
          onClick={() => history.push('/people/list')}
          data-testid="detailCard-btn-back"
        >
          <i className="fas fa-arrow-left" />
          {firstLetterToUppercase(
            intl
              .formatMessage(
                { id: 'detailcard.button.back' },
                {
                  category: appLabel(
                    'peoplePlural',
                    'detailcard.button.people'
                  ),
                }
              )
              .toLowerCase()
          )}
        </GoBackButton>
      </BottomBackButtonWrapper>
    </>
  )
}

export default PersonDetailedView
