import React from 'react'
import { Button } from '@patternfly/react-core'
import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon'
import Dropdown from './dropdown'
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  .chip {
    border: solid 1px;
    border-radius: 20px;
    padding: 5px 10px;
  }

  .applicants {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    border: solid 1px;
    height: 100px;
    overflow: auto;
    padding: 5px;
  }

  .message {
    border: solid 1px;
    padding: 5px;
  }
`

function SendMessageModal({ show, setShow, applicants }) {
  const handleClose = () => setShow(false)

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <StyledDiv>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="applicants">
              {applicants.map((applicant) => (
                <div key={applicant.id} className="chip">
                  {applicant.name}
                  <TimesIcon />
                </div>
              ))}
            </div>
            <div>Message</div>
            <div>
              Short intro about action you are about to make! Edit or have it as
              it is to send out bulk emails.
            </div>
            <Dropdown />
            <div className="message">
              Hello, We enjoyed your application and portfolio. Venenatis
              placerat aliquet mattis turpis velit leo ullamcorper quis lectus.
              Sed suspendisse lectus nulla facilisis non lectus integer et,
              congue. You will be the chosen one Team
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </StyledDiv>
      </Modal>
    </>
  )
}

export default SendMessageModal
