import styled from '@emotion/styled'
import css from '@emotion/css'

export const CoverPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;

  .label-wrapper {
    display: flex;

    label {
      margin-right: 5px;
    }
  }

  .info {
    font-weight: 400;
    font-size: 14px;
  }

  .required-star {
    color: red;
  }

  .cover-photo-upload-btn {
    border: 2px solid var(--flavor);
    border-radius: 25px;
    padding: 5px 20px;
    background-color: var(--flavor);
    color: var(--background);
    font-size: 14px;
    font-weight: 700;
    border: none;
    max-width: 145px;
  }
`

export const StyledUploadDiv = styled.div`
  border: none;
  padding: 50px 100px;
  width: 300px;
  height: 115px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  position: relative;

  .remove-img-wrapper {
    position: absolute;
    --pos: -5px;
    top: var(--pos);
    right: var(--pos);
    transform: rotate(45deg);
    width: min-content;
    cursor: pointer;
    z-index: 12;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 17px 1px;
    background: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: black;
      font-size: 14px;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;

    .change-img-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5px 20px;
      border-radius: 25px;
      border: 2px solid var(--background);
      color: var(--background);
      background: transparent;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    :hover {
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease-in-out;

      .change-img-btn {
        z-index: 100;
      }
    }
  }

  ${({ imgUrl }) =>
    imgUrl &&
    css`
      background-image: url(${imgUrl});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;

      i {
        /* display: none; */
      }
    `}

  i {
    font-size: 35px;
    color: #6a6e73;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--foreground-shade);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  i {
    font-size: 8px;
    color: var(--background);
  }
`

