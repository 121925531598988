import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { SectionHeaderWrapper, HeaderItem } from './styles/section-header'

const SectionHeader = () => {
  const intl = useIntl()
  const location = useLocation()
  const currentLocation = location?.pathname.substring(
    15,
    location?.pathname.length - 25
  )
  return (
    <SectionHeaderWrapper>
      <HeaderItem isActive={currentLocation === 'general-information'}>
        <span>1</span>
        {intl.formatMessage({ id: 'events.create.navbuttons.step.1st' })}
      </HeaderItem>
      <HeaderItem isActive={currentLocation === 'additonal-information'}>
        <span>2</span>
        {intl.formatMessage({ id: 'events.create.navbuttons.step.2nd' })}
      </HeaderItem>
      <HeaderItem isActive={currentLocation === 'preview-information'}>
        <span>3</span>
        {intl.formatMessage({ id: 'events.create.navbuttons.step.3th' })}
      </HeaderItem>
    </SectionHeaderWrapper>
  )
}

export default SectionHeader
