import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ApplicationCountButtonStyled } from './tiles/styled/default'

export const ApplicationCountButton = ({ opportunity }) => {
  return (
    typeof opportunity.applicationCount === 'number' && (
      <Link to={`/opportunities/${opportunity.id}/applications`}>
        <ApplicationCountButtonStyled>
          <FormattedMessage
            id={'opportunity.applicants'}
            defaultMessage={`{count} ${
              opportunity.applicationCount === 1 ? 'applicant' : 'applicants'
            }`}
            values={{ count: opportunity.applicationCount }}
          />
        </ApplicationCountButtonStyled>
      </Link>
    )
  )
}
