import React from 'react'
import { useSelector } from 'react-redux'
import { UserDropdownStyles } from './styled/user-dropdown'

const UserDropdown = ({ children, isScrollTop, themeSettings, className }) => {
  const location = useSelector((state) => state.router?.location?.pathname)
  return (
    <UserDropdownStyles
      isScrollTop={isScrollTop}
      location={location}
      themeSettings={themeSettings}
      className={className}
    >
      {children}
    </UserDropdownStyles>
  )
}

export default UserDropdown
