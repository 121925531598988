import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EventTitle from './components/event-title'
import Excerpt from './components/excerpt'
import Category from './components/category'
import Location from './components/location'
import BannerImage from './components/banner-image'
import EventDates from './components/event-dates'
import { MainWrapper } from './styles'
import { useUser } from '_security/session-hooks'
import { useAppConfig } from '_application'
import { MbToB } from 'services/file'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import ActionButtons from './components/action-buttons'
import BreakLine from './components/break-line'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Modal from './components/modal'
import { roundTime } from '../helpers/roundTime'
import moment from 'moment'

const GeneralInformation = ({
  event,
  setEvent,
  updateEvent,
  locationObject,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const intl = useIntl()
  const { sub } = useUser()
  const { tenantId, eventsCategories, defaultLocale } = useAppConfig()

  const location = useLocation()
  const eventId = location.pathname.slice(location.pathname.length - 24)
  const history = useHistory()

  const tagsConfig = {
    userId: sub,
    tenantId: tenantId,
    context: 'event-banner-image',
    mediaId: event?.id,
  }

  const uploadConfig = {
    folderName: 'events',
    tagsConfig,
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }
  const isDisabled =
    event?.excerpt.length === 0 ||
    event?.title.length === 0 ||
    event?.description === '<p><br></p>' ||
    !event?.bannerImage ||
    roundTime(event.doorsOpen) > roundTime(event.start) ||
    moment(event.end).isBefore(event.start)

  const actionButtonsConfig = {
    isDisabled,
    onSuccess: () => {
      if (event?.status !== 'PUBLISHED') {
        updateEvent()
      }
      history.push(`/events/manage/additonal-information/${event?.id}`)
    },
    onCancel: () => setIsDeleteModalOpen(true),
    rejectBtnContent: intl.formatMessage({ id: 'events.create.button.reject' }),
    successBtnContent: intl.formatMessage({
      id: 'events.create.button.success',
    }),
  }

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'events.modal.cancel.title' })}
        description={intl.formatMessage({
          id: 'events.modal.cancel.description',
        })}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.cancel.button.cancel',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.cancel.button.continue',
        })}
        onProceed={() => history.push(`/events/list`)}
      />

      <MainWrapper>
        <h1 className='new-event-title'>
        {intl.formatMessage({
              id: 'events.createevent.general.eventsection.title',
            })}
        </h1>
        <EventTitle
          eventTitle={event?.title}
          onSave={(value) => setEvent({ ...event, title: value })}
        />
        <Excerpt
          excerpt={event?.excerpt}
          onSave={(value) => setEvent({ ...event, excerpt: value })}
        />
        <BreakLine />
        {eventsCategories && eventsCategories.length > 0 && (
          <>
            <Category
              eventSelectedCategories={event?.categories}
              allEventsCategories={eventsCategories}
              onSave={(value) => {
                return setEvent({
                  ...event,
                  categories: value,
                })
              }}
            />
            <BreakLine />
          </>
        )}
        <Location
          venueLocation={event?.venueLocation}
          onSave={(value) => setEvent({ ...event, venueLocation: value })}
          locationObject={locationObject}
        />
        <BreakLine />
        <EventDates
          start={event?.start}
          end={event?.end}
          doorsOpen={event?.doorsOpen}
          defaultLocale={defaultLocale}
          onSave={(key, value) =>
            setEvent({
              ...event,
              [key]: value,
            })
          }
        />
        <BreakLine />
        <div className="wysiwyg-wrapper">
          <label htmlFor="eventDescription" className="desc-label">
            {intl.formatMessage({
              id: 'events.createevent.general.eventdescription.title',
            })}
            <span className="required-star">*</span>
          </label>
          <span>
            {intl.formatMessage({
              id: 'events.createevent.general.eventdescription.description',
            })}
          </span>

          {renderField({
            attribute: {
              type: 'wysiwyg',
              name: 'eventsCreator',
              heightMin: 515,
              heightMax: 650,
              editorType: 'eventsCreator',
              editorValue: event?.description,
              imgUploadFolder: 'events',
              imgTagsConfig: {
                context: 'event-description-content',
                eventId: eventId,
                userId: sub,
              },
            },
            onSave: (value) =>
              setEvent((prevState) => ({ ...prevState, description: value })),
          })}
        </div>
        <BreakLine />

        <BannerImage
          bannerImg={event?.bannerImage}
          uploadConfig={uploadConfig}
          onSave={(value) => setEvent({ ...event, bannerImage: value })}
        />
        <BreakLine />

        <ActionButtons config={actionButtonsConfig} />
      </MainWrapper>
    </>
  )
}

export default GeneralInformation
