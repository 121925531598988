import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  background-color: var(--background);
  border: solid 1px ${({ theme }) => theme.colors.interactiveSecondary};
  border-radius: 22px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightColor};
  }
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primaryColor};
  }
  &:active {
    opacity: 0.5;
  }
  svg {
    width: 1.5em;
    stroke: ${({ theme }) => theme.colors.primaryColor};
    stroke-width: 3px;
  }
`

const Input = styled.input`
  color: ${({ theme }) => theme.colors.muted};
  border: none;
  background: none;
  font-weight: 600;
  width: 100%;
  padding-left: 10px;
  padding-right: 55px;
  opacity: 1;
  transition: 0.2s;
  color: var(--foreground);
`

const SearchInput = ({
  children,
  query,
  loading,
  searching,
  className,
  testid,
  ...props
}) => (
  <Container className={className}>
    <svg id="search" viewBox="0 0 25.561 26.561">
      <g id="glass" fill="none">
        <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
        <circle cx="9.5" cy="9.5" r="8" fill="none" />
      </g>
      <line id="handle" x2="9" y2="9" transform="translate(15.5 16.5)" />
    </svg>
    {searching && (
      <Input
        data-testid={`${testid ? `${testid}-` : ''}searchInput`}
        type="text"
        {...props}
        value={query || ''}
      />
    )}
    {children}
  </Container>
)

SearchInput.propTypes = {
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  searching: PropTypes.bool,
  className: PropTypes.string,
}

SearchInput.defaultProps = {
  searching: true,
}

export default SearchInput
