import styled from '@emotion/styled'
import RawRightArrow from '_components/right-arrow'
import RawLeftArrow from '_components/left-arrow'

const arrowsCSS = () => {
  return `
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    z-index: 20;
    border: 1px solid var(--buttonColor);
    background-color: var(--buttonColor);
    border-radius: 100%;
    height: 37px;
    width: 37px;
    margin-right: 10px;
    transition: transform .2s ease-in-out;
    i {
      font-size: 14px;
      margin: 0;
      padding: 0;

      transition: transform .2s ease-in-out;
    }
    &:hover {
      opacity: .5;
      i {
        transform: scale(1.1);
      }
    }
    &:active {
      transform: scale(0.9);
    }
  `
}

export const LeftArrow = styled(RawLeftArrow)`
  --buttonColor: var(--flavor);
  ${arrowsCSS()}
  i {
    transform: translate(-1px);
  }
`

export const RightArrow = styled(RawRightArrow)`
  --buttonColor: var(--flavor);
  ${arrowsCSS()}
  i {
    transform: translate(1px);
  }
`
