import React from 'react'
import { ImagesWrapper } from '../styles/ca-images'

const CustomAttributesImages = ({ attributes, onDelete, currentAttribute }) => {
  if(!Array.isArray(attributes)) {
    try {
      attributes = JSON.parse(attributes)
    } catch {
      attributes = []
    }
  }
  return (
      <ImagesWrapper isOnlyOne={attributes.length === 1}>
        {attributes.map((url, index) => (
          <div className="image-wrapper">
            <button onClick={() => onDelete(currentAttribute.key, index)}>
              <i class="fas fa-times"></i>
            </button>
            <img src={url} alt="custom-att-img" />
          </div>
        ))}
      </ImagesWrapper>
  )
}

export default CustomAttributesImages
