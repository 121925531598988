import styled from '@emotion/styled'

export const Button = styled.button`
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 8px;
  background: transparent;
  color: white;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  text-transform: uppercase;
  text-align: left;
`
