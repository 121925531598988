/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { IKUIOidc } from '@indykiteone/jarvis-sdk-web'
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import Loading from '_components/loading'
// import { notification } from 'antd'

const cookies = new Cookies()

const CallbackPage = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state)
  useEffect(() => {
    IKUIOidc.oidcCallback()
      .then(async (data) => {
        if(data && data.token){
          const verify = await axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/user/verify`,
            method: 'get',
            headers: { authorization: data.token },
          })

          if (!user.authorized && data.token && verify) {
            const expires = new Date()
            expires.setTime(expires.getTime() + 48 * 60 * 60 * 1000)

            cookies.set('accessToken', data.token)
            localStorage.setItem('RIOT/token', data.token)

            dispatch({
              type: 'user/LOGIN',
              payload: { token: data.token },
            })
          }
        }else {
          if(data.redirect_to){
            return window.location.href = data.redirect_to
          }
        }
        return <Redirect to={'/login?status=1'} />
      })
      .catch(console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IKUIOidc])

  return (
    <div>
      <Helmet title="Loading..." />
      <Loading />
    </div>
  )
}

export default CallbackPage
