import styled from '@emotion/styled'

export const Name = styled.span`
  padding-right: 5px;
  color: var(--foreground);
  transition: 0.5s;
`

export const CreatorStyled = styled.div`
  .keyword-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .keyword {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 3px 5px 3px 0;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primaryColor};
    border: 1px solid var(--foreground);
    transition: 0.5s;
    &:hover {
      background-color: var(--foreground);
      color: var(--background);
      border-color: var(--foreground);
      ${Name} {
        color: var(--background);
      }
    }

    span.value {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    i {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 6px;
    border: 1px solid var(--shade);
    font-size: 14px;
    color: var(--foreground);
  }
  span.input-label {
    display: block;
    text-align: right;
    margin-bottom: 15px;
    opacity: 0.6;
  }
`
