export const scaleSanityImage = ({ link, height, width, quality }) => {
  try {
    const url = new URL(link)
    if (url?.hostname !== 'cdn.sanity.io') {
      return link
    }
    const searchParams = new URLSearchParams(url.search)

    if (height) {
      searchParams.set('h', height)
      url.search = searchParams.toString()
    }
    if (width) {
      searchParams.set('w', width)
      if (!height) {
        if (searchParams.has('h')) {
          searchParams.delete('h')
        }
        url.search = searchParams.toString()
      }
      url.search = searchParams.toString()
    }
    if (quality) {
      searchParams.set('q', quality)
      url.search = searchParams.toString()
    }

    return url.toString()
  } catch (e) {
    console.log('errr', e)
    return link
  }
}

export default scaleSanityImage
