import React from 'react'
import Loading from '_components/loading'
import { Row, Col } from 'react-bootstrap'
import { LoadMoreContainer, LoadMoreButton } from './styles/load-more'
import { nextPage, mergePage } from '_utils/graph-pagination'
import { useIntl } from 'react-intl'

const updateQuery = mergePage('getAllUsersAdmin')

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(offset),
                filter,
                updateQuery,
              })
            }
          >
            <p>
              {intl.formatMessage({ id: 'global.loadmore.button' })}
              <i class="fas fa-chevron-down"></i>
            </p>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}

const LoadMore = ({ hasMore, loader, filter, offset, loading }) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={loader}
          filter={filter}
          offset={offset}
        />
      )}
    </>
  )
}

export default LoadMore
