/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { connect, useSelector } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { GlobalSearch } from '_search'
import { useAppConfig } from '_application'
import { useEventBus } from '_event-bus'
import { notification } from 'antd'
import { useMessagesConversations } from '_messages'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'
import { useThemeSettings } from '_application'
import NavigationUserDropdown from './navigation-user-dropdown'
import {
  defaultLightLogo,
  defaultDarkLogo,
  defaultDiscoverBackground,
} from '_resources'
import HeaderNav from '_uikit/header-navigation'
import { NotificationIndicator } from './styles/nav-notifications'

import useScrollTop from '_utils/useScrollTop'

import { themeIsDark } from '_colorBus/primitives/scheme'
import { useMenuItems } from 'menu-builder/menuItems'

const GET_NOTIFICATIONS = gql`
  query {
    getCurrentUser {
      sub
      notifications {
        notification {
          _id
          read
          subtype
          payload
        }
        user {
          sub
          firstName
          lastName
        }
      }
    }
  }
`

const mapStateToProps = ({ user, settings, appConfig }) => ({
  user,
  settings,
  appConfig,
})


const FoldableSearch = ({ children, className = "", onChange = () => {} }) => {
  const [overExit, setOverExit] = useState(false)
  const [searching, setSearching] = useState(false)

  const change = (bool) => {
    setSearching(bool)
    onChange(bool)
  }

  return (
    <div
      className={`menu-item search-link ${className}`}
      onClick={() => !overExit && change(true)}
    >
      <GlobalSearch
        searching={searching}
        onClose={() => change(false)}
      >
        <button 
          className="exit" 
          onClick={() => change(false)} 
          onMouseLeave={() => setOverExit(false)}
          onMouseOver={() => setOverExit(true)}
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </GlobalSearch>
      <span className="search-label">{children}</span>
    </div>
  )
}

const Header = ({ user, appConfig }) => {
  const menuItems = useMenuItems()
  const theme = useTheme()
  const themeSettings = useThemeSettings()
  const history = useHistory()
  const location = history.location.pathname
  const eventBus = useEventBus()
  const intl = useIntl()
  const conversationsHook = useMessagesConversations()
  const authorized = user?.authorized

  const [searching, setSearching] = useState(false)
  const [openMenu, setOpen] = useState(false)
  const { messages } = useSelector((state) => state)
  let { isScrollTop } = useScrollTop(75)
  const [dynamicMenu, setDynamicMenu] = useState(true)

  useEffect(() => {
    if (authorized) {
      conversationsHook.getConversations()
    }
  }, [authorized])

  if ( 
    appConfig.settings &&
    appConfig?.settings['menu.alwaysHideIcons'] === true
  ) { 
    if (dynamicMenu)
      setDynamicMenu(false)
  }

  const config = useAppConfig()

  const convertRatio = type => {
    const logos = config?.logos || {}
    const ratio = logos[type]?.ratio
    switch (ratio) {
      case '1:1':
        return '1-1'
      case '4:3':
        return '4-3'
      case '16:9':
        return '16-9'
      case '24:8':
        return '24-8'
      default:
        return '1-1'
    }
  }

  const renderMessageText = (key) => {
    const overrideLabel = config?.labelOverrides?.[key]?.value
    if (overrideLabel) return overrideLabel
    return intl.formatMessage({ id: `header.navigation.${key.toLowerCase()}` })
  }

  const displayBackgroundImage = (scroll, path) => {
    return path === '/' && !scroll
      ? `url('${config?.backgroundImages?.discoveryPage?.value ||
      defaultDiscoverBackground
      }')`
      : null
  }

  const logoOption = () => {
    const isDark = themeIsDark(theme?.colors)
    const lightThemeExceptions =
      dynamicMenu && isScrollTop && (location === '/' || location === '/discover')
    const lightTheme = lightThemeExceptions ? 'lightLogo' : 'darkLogo'
    return isDark ? 'lightLogo' : lightTheme
  }

  const renderHeaderLogo = () => {
    const currentLogo = logoOption()
    function imgSrc() {
      return (
        config?.logos?.[currentLogo]?.value ||
        (currentLogo === 'lightLogo' ? defaultLightLogo : defaultDarkLogo)
      )
    }
    return (
      <img
        data-testid="navlink-logo"
        className={`logo-${convertRatio(`${currentLogo}`)} company-logo`}
        src={imgSrc()}
        alt="logo"
      />
    )
  }

  const linkActive = link => {
    const shortenedLocation = location.substring(0, link.url.length)
    const linkUrl = link.url
    const urlCheck = shortenedLocation.includes(linkUrl) ? 'active' : null
    return urlCheck
  }
  

  const ShowNotificationIndicator = () => {
    const { data } = useQuery(GET_NOTIFICATIONS, {
      skip: !user?.authorized,
    })

    const messagesList =
      messages?.conversations?.filter(
        (conv) => !conv.lastMessage.read && user.sub !== conv.lastMessage.creator
      ) || []

    const notificationsList =
      data?.getCurrentUser?.notifications?.filter(
        (n) => n.user?.sub !== user?.sub && n.notification?.read === false
      ) || []


    useEffect(() => {
      const eventUnsub = eventBus.on('NEW_MESSAGE', () => {
        if (location !== '/user/messages') {
          conversationsHook.getConversations()
          notification.info({
            message: intl.formatMessage({ id: 'toast.newmessage.title' }),
            description: intl.formatMessage({ id: 'toast.newmessage.text' }),
          })
        }
      })
      return eventUnsub
    }, [])

    if (notificationsList.length > 0 || messagesList.length > 0) {
      return <NotificationIndicator openMenu={openMenu} />
    }

    return null
  }

  return (
    <HeaderNav
      themeSettings={themeSettings}
      isScrollTop={dynamicMenu ? isScrollTop : false}
      style={{
        backgroundImage: displayBackgroundImage(dynamicMenu ? isScrollTop : false, location),
      }}
    >
      <div
        id="header-nav-container"
        className={`header
          ${isScrollTop ? '' : 'scrolled'}
          ${dynamicMenu ? "dynamicMenu" : ""}
          ${openMenu ? 'openMobile' : ''}`}
      >
        <div className="logo">
          <Link to="/">{renderHeaderLogo()}</Link>
        </div>

        <NavigationUserDropdown
          isScrollTop={dynamicMenu ? isScrollTop : false}
          location={location}
          themeSettings={themeSettings}
        />

        <nav className={`${searching ? 'searching' : ''} icons-nav`}>
          <FoldableSearch className="mobile" onChange={(bool) => setSearching(bool)}>
            {renderMessageText('search')}
          </FoldableSearch>

          {menuItems.map(item => {
            const content = (
              <>
                <span className="icon">
                  <item.icon className={`link-icon`} />
                </span>
                <span className="label item-name">{item.label}</span>
              </>
            )
            return item.type === 'menuBuilderItem' ? (
              <a
                className={'menu-item'}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  item.url.match(/^https?:\/\//)
                    ? item.url
                    : 'https://' + item.url
                }
              >
                {content}
              </a>
            ) : (
              <Link
                key={item.key}
                to={item.url}
                onClick={() => setOpen(false)}
                data-testid={`navlink-${item.key}`}
                className={`menu-item ${linkActive(item)} 
                ${item.name?.toLowerCase()}`}
              >
                {content}
              </Link>
            )
          })}
          <FoldableSearch onChange={(bool) => setSearching(bool)}>
            {renderMessageText('search')}
          </FoldableSearch>
        </nav>

        <div
          className={`mobile-menu-burger ${user.authorized ? 'loggedin' : 'loggedout'
            }`}
          onClick={() => setOpen(!openMenu)}
        >
          <div className="lines">
            <div className="line"></div>
            {user?.authorized && (
              <ShowNotificationIndicator />
            )}
          </div>
        </div>

        <div
          className={`mobile-menu-vail`}
          onClick={() => setOpen(false)}
        ></div>
      </div>
    </HeaderNav>
  )
}

export default withRouter(connect(mapStateToProps)(Header))
