/* eslint-disable no-underscore-dangle */
import React from 'react'
import styled from '@emotion/styled'

const Logo = styled.img`
  max-height: 50px;
  max-width: 50px;
  object-fit: cover;
  z-index: 0;
  position: relative;
  display: inline;
`

const MainImage = ({ mainImage, className }) => {
  if (mainImage.preparedImageUrl) {
    return (
      <a href={mainImage?.logoLink?.href}>
        <Logo
          className={className}
          src={mainImage.preparedImageUrl}
          alt={mainImage.alt || ''}
        />
      </a>
    )
  }
}

export default MainImage
