import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  display: flex;
  outline: none;
  margin-right: 10px;
  column-gap: 5px;
  padding: 5px 15px;
  border-radius: 25px;
  background-color: transparent;

  background-color: var(--flavor);
  color: var(--flavor-contrast);

  :hover {
    color: var(--flavor-contrast);
  }
`
