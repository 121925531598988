import { useTheme } from 'emotion-theming'
import { useQuery, gql } from '@apollo/client'
import { useAppConfig } from '_application'
import { useIntl } from 'react-intl'
import { find } from 'lodash'
import { useUser } from '_security/session-hooks'
import React from 'react'

export const useMenuItems = (isEditMode) => {
  const theme = useTheme()
  const config = useAppConfig()
  const intl = useIntl()
  const user = useUser()
  const isLoggedIn = user?.authorized

  const isForumEnabled = config?.features?.includes('forum-dev')
  const isEventsEnabled = config?.features?.includes('events')

  const renderMessageText = (key) => {
    const overrideLabel = config?.labelOverrides?.[key]?.value
    if (overrideLabel) return overrideLabel
    return intl.formatMessage({ id: `header.navigation.${key.toLowerCase()}` })
  }

  const customPages = useQuery(gql`
    query CustomPages {
      customPages {
        path
        iframeSrc
        title
      }
    }
  `)?.data?.customPages

  let menuItems = [
    {
      key: 'activitiesPlural',
      name: 'Activities',
      icon: theme.icons.activitiesIcon,
      url: '/activities',
    },
    {
      key: 'opportunitiesPlural',
      name: 'Opportunities',
      icon: theme.icons.opportunitiesIcon,
      url: '/opportunities',
    },
    {
      key: 'companiesPlural',
      name: 'Companies',
      icon: theme.icons.companiesIcon,
      url: '/companies',
    },
    {
      key: 'peoplePlural',
      name: 'People',
      icon: theme.icons.peopleIcon,
      url: '/people',
    },
    {
      key: 'blogPlural',
      name: 'Blog',
      icon: theme.icons.blogIcon,
      url: '/blog',
    },
    {
      key: 'forum',
      name: 'Forum',
      icon: theme.icons.forumIcon,
      url: '/forum/',
      disabled: !isForumEnabled,
    },
    {
      key: 'eventsPlural',
      name: 'Events',
      icon: theme.icons.eventsIcon,
      url: '/events/list',
      disabled: !isEventsEnabled,
    },
  ]

  config.menu &&
    menuItems.push(
      ...config.menu
        .filter(menuItem => menuItem.url)
        .map(menuItem => ({
          label: menuItem.title,
          ...menuItem,
          icon: () =>
            menuItem.icon ? (
              <i className={menuItem.icon} aria-hidden="true"></i>
            ) : (
              <i className="fa fa-link" aria-hidden="true"></i>
            ),
          type: 'menuBuilderItem',
        }))
    )

  if (customPages) {
    menuItems.push(
      ...customPages.map(customPage => ({
        key: customPage.path,
        type: 'customPage',
        name: customPage.path,
        label: customPage.title || customPage.path,
        url: '/pages/' + customPage.path,
        icon: () => {
          return customPage.icon
            ? <i className={customPage.icon} aria-hidden="true"></i>
            : <i className="fa fa-file-o" aria-hidden="true"></i>
        },
      }))
    )
  }

  menuItems.forEach(menuItem => {
    const menuBuilderItem =
      menuItem.type === 'menuBuilderItem'
        ? menuItem
        : find(config?.menu, { key: menuItem.key })

    menuItem.visibleLoggedOut =
      typeof menuBuilderItem?.visibleLoggedOut === 'boolean'
        ? menuBuilderItem?.visibleLoggedOut
        : true

    menuItem.visibleLoggedIn =
      typeof menuBuilderItem?.visibleLoggedIn === 'boolean'
        ? menuBuilderItem?.visibleLoggedIn
        : true

    menuItem.index = menuBuilderItem?.index

    if (menuItem.type !== 'menuBuilderItem' && menuBuilderItem?.icon) {
      menuItem.icon = () => <i className={menuBuilderItem.icon} aria-hidden="true"></i>
    }

    if (menuItem.type !== 'menuBuilderItem' && menuItem.type !== 'customPage') {
      menuItem.label = renderMessageText(menuItem.key)
    }
  })
  menuItems = menuItems.filter((m) => !m.disabled)
  menuItems.sort((a, b) => {
    if (!a.index && !b.index) return 0
    if (a.index !== null && b.index === null) return -1
    if (a.index === null && b.index !== null) return 1
    if (a.index === b.index) return 0
    return a.index < b.index ? -1 : a.index > b.index ? 1 : 0
  })

  if (!isEditMode) {
    menuItems = menuItems.filter((m) =>
      isLoggedIn ? m.visibleLoggedIn : m.visibleLoggedOut
    )
  }

  return menuItems
}
