import React from 'react'
import SingleAttribute from './components/single-attribute'

const CustomAttributes = ({
  attributes,
  attributesValues,
  attributesConfig,
}) => {
  return (
    <>
      {attributes?.map((attribute) => {
        const defaultValue = attribute?.type === "badge" ? [] : ""
        let attributeValue = defaultValue

        if(attributesValues.hasOwnProperty(`${attribute.type}s`) && attributesValues[`${attribute.type}s`].length ){
          attributeValue = attributesValues[`${attribute.type}s`]?.find(
            (attr) => attr.key === attribute.key
          )
        } else if (attributesValues.length) {
          attributeValue = attributesValues.find(attr => attr.key === attribute.key) || defaultValue
        }

        return <>
          <SingleAttribute
            attribute={attribute}
            attributesConfig={attributesConfig}
            attributeValue={attributeValue}
          />
        </>
      })}
    </>
  )
}

export default CustomAttributes
