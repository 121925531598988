import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@patternfly/react-core'
import { Grid, BaseTile } from '_tiles'
// import { Default as PeopleTile } from '_people/components/tiles'

import { UserTile } from '../components/user-tile'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import {
  PageWrapper,
  SectionHeader,
  FiltersWrapper,
  ByPostsButton,
  ByReputationButton,
  ActivityIndicator,
} from './styles/users'

const GET_USERS = gql`
  query GET_FORUM_USERS($query: String!, $page: Float!) {
    getForumUsersSection(query: $query, page: $page) {
      currentpage
      pagecount
      usercount
      usersList {
        uid
        status
        displayname
        username
        picture
        postcount
        reputation
      }
    }
  }
`

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const Users = () => {
  const intl = useIntl()
  const [sortingBy, setSortingBy] = useState('sort-posts')
  const currentSorting = sortingBy === 'sort-posts' ? 'postcount' : 'reputation'

  const { data } = useQuery(GET_USERS, {
    variables: { query: `${sortingBy}`, page: 1 },
  })

  const handleSorting = (sort) => setSortingBy(sort)

  // const number = 13

  return (
    <PageWrapper>
      <SectionHeader>
        <Flex>
          <FiltersWrapper>
            <ByPostsButton
              isActive={sortingBy === 'sort-posts'}
              onClick={() => handleSorting('sort-posts')}
              data-testid="byPostsBtn"
            >
              {intl.formatMessage({ id: 'forum.users.sortby.posts' })}
            </ByPostsButton>
            <ByReputationButton
              isActive={sortingBy === 'sort-reputation'}
              onClick={() => handleSorting('sort-reputation')}
              data-testid="byReputationBtn"
            >
              {intl.formatMessage({ id: 'forum.users.sortby.reputation' })}
            </ByReputationButton>
          </FiltersWrapper>
        </Flex>
      </SectionHeader>
      {!data ? (
        <Loader loading={!data} />
      ) : (
        <Grid config={gridConfig} testid="users">
          {data?.getForumUsersSection?.usersList?.map(function (user, i) {
            if (
              !user[currentSorting] ||
              user[currentSorting] < 1 ||
              user.uid === 1
            )
              return <></>
            return (
              <BaseTile config={defaultConfig}>
                <UserTile
                  isPlain
                  person={user}
                  connectButton={{
                    status: user.connectionStatus,
                    onConnect: () => {},
                    disabled: '',
                  }}
                  // connections={connections}
                  // connectionsLoading={connectionsLoading}
                >
                  <ActivityIndicator>
                    {sortingBy === 'sort-posts' ? (
                      <i className="fas fa-pen" />
                    ) : (
                      <i className="fas fa-arrow-circle-up" />
                    )}

                    <span>{user[currentSorting]}</span>
                  </ActivityIndicator>
                </UserTile>
              </BaseTile>
            )
          })}
        </Grid>
      )}
    </PageWrapper>
  )
}

export default Users
