import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_TAXONOMY,
  UPDATE_TAXONOMY,
  DELETE_TAXONOMY,
  ADD_TAXONOMY,
} from './graph-queries'

import GenericDataManager from '../shared'

const Keywords = () => {
  const { data: taxonomyData, refetch } = useQuery(GET_TAXONOMY, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateTaxonomy] = useMutation(UPDATE_TAXONOMY)
  const [deleteTaxonomy] = useMutation(DELETE_TAXONOMY)
  const [addTaxonomy] = useMutation(ADD_TAXONOMY)

  const handleToggleUsedBy = (value, keyword) => {
    const key = JSON.parse(JSON.stringify(keyword))
    key.usedBy = keyword.usedBy.includes(value)
      ? keyword.usedBy.filter((k) => k !== value)
      : [...keyword.usedBy, value]
    updateTaxonomy({
      variables: { key: key.key, usedBy: key.usedBy },
      refetchQueries: [{ query: GET_TAXONOMY }],
    })
  }

  const handleDelete = (key) => {
    deleteTaxonomy({
      variables: { key: key.key },
      refetchQueries: [{ query: GET_TAXONOMY }],
    })
  }

  const handleUpdate = (key) => {
    updateTaxonomy({
      variables: {
        key: key.key,
        treeData: key.treeData,
        name: key.name,
        description: key.description,
        isSearchable: key.isSearchable,
        isMatchable: key.isMatchable,
      },
      refetchQueries: [{ query: GET_TAXONOMY }],
    })
  }

  const handleAdd = (key) => {
    addTaxonomy({
      variables: {
        treeData: key.treeData,
        name: key.name,
        description: key.description,
        isSearchable: key.isSearchable,
        isMatchable: key.isMatchable,
      },
      refetchQueries: [{ query: GET_TAXONOMY }],
    })
  }

  const notEditableKeys = []

  return (
    <GenericDataManager
      data={taxonomyData?.getTaxonomy}
      refetch={refetch}
      onToggleUsedBy={handleToggleUsedBy}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onAdd={handleAdd}
      notEditableKeys={notEditableKeys}
      type="taxonomy"
    />
  )
}

export default Keywords
