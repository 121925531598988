import React, { useState } from 'react'
import Geosuggest from 'react-geosuggest'
import LocationSelectorStyled from './location-selector-styled'

const LocationSelector = ({ defaultLocation, onSelect }) => {
  const [show, setShow] = useState(false)
  const [location, setLocation] = useState(
    defaultLocation && defaultLocation.label
      ? defaultLocation.label
      : defaultLocation || ''
  )

  const handleSuggestSelect = (suggest) => {
    if (suggest) {
      const l = {
        city: suggest.gmaps.address_components.filter((ac) =>
          ac.types.includes('locality')
        )[0],
        country: suggest.gmaps.address_components.filter((ac) =>
          ac.types.includes('country')
        )[0],
      }
      const newLocation = {
        lat: suggest.location.lat,
        long: suggest.location.lng,
        city: l.city?.long_name || '',
        country: l.country?.long_name || '',
        label: suggest?.label || '',
      }
      onSelect(newLocation)
      setShow(false)
    }
  }

  const handleSuggestEnter = (e) => {
    setLocation(e)
    if (e.length > 2) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  return (
    <LocationSelectorStyled className="location-selector">
      <Geosuggest
        placeholder="Type location"
        onSuggestSelect={(s) => handleSuggestSelect(s)}
        onChange={(e) => handleSuggestEnter(e)}
        onClick={() => setShow(true)}
        onBlur={() => setShow(false)}
        initialValue={location}
        radius="20"
        id="geoSuggest"
        className={`location-search-input ${
          show && location.length > 0 ? '' : 'hidden'
        }`}
        location={window.google ? new window.google.maps.LatLng(59, 10) : null}
        queryDelay={0}
        data-testid="input-geosuggest"
      />
    </LocationSelectorStyled>
  )
}

export default LocationSelector
