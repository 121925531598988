import React, { useState } from 'react'
import { useIsDiscoverPage } from '_application/is-discover-page'
import {
  DefaultTileStyled,
  DefaultStyled,
  Content,
  Tags,
  TopInfo,
  FooterInfo,
} from './styled/default'
import { SharePostButton } from '../share-post-button'
import { useAppConfig } from '_application'
import ClickAway from '_components/click-away'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { mobilePX } from '_utils/responsive-queries'
import { useMediaQuery } from 'react-responsive'
import { ConditionalWrapper } from '../conditional-wrapper'
import FullscreenLoaderWrapper from '_components/loading/fullscreen-loader-wrapper'
// import { useMutation, gql } from '@apollo/client'

// const LIKE_BLOG_POST = gql`
//   mutation LikeBlogPost($likeBlogPost: LikeBlogPost) {
//     likeBlogPost(likeBlogPost: $likeBlogPost) {
//       interactiontype
//       user
//       entity
//     }
//   }
// `

const shareButtons = [
  {
    type: 'users',
    name: 'Recommend',
    icon: <i className="far fa-paper-plane" />,
  },
  {
    type: 'email',
    name: 'Email',
    icon: <i className="far fa-envelope" />,
  },
  {
    type: 'linkedin',
    name: 'LinkedIn',
    icon: <i className="fab fa-linkedin-in" />,
    shareLink: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  },
  {
    type: 'twitter',
    name: 'Twitter',
    icon: <i className="fab fa-twitter" />,
    shareLink: 'https://twitter.com/intent/tweet?url=',
  },
  { type: 'link', name: 'Link', icon: <i className="fas fa-link" /> },
]

// const Default = ({ post, className }) => {
//   const intl = useIntl()
//   const { blogCategories } = useAppConfig()
//   const { coverPhoto, title, description } = post

//   const [isShareMenuOpen, setIsShareMenuOpen] = useState(false)
//   const [isInputShown, setIsInputShown] = useState(false)

//   const isMobileView = useMediaQuery({
//     query: `(max-width: ${mobilePX}px)`,
//   })

// const DISLIKE_BLOG_POST = gql`
//   mutation DislikeBlogPost($likeBlogPost: LikeBlogPost) {
//     dislikeBlogPost(likeBlogPost: $likeBlogPost) {
//       interactiontype
//       user
//       entity
//     }
//   }
// `

const Default = ({
  post,
  className,
  refetchData
}) => {
  const intl = useIntl()
  const { blogCategories } = useAppConfig()
  const { coverPhoto, title, description } = post

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })

  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false)
  const [isInputShown, setIsInputShown] = useState(false)

  // like and dislike function is off on the grid level, but might be on in the future

  // const [likeBlogPost] = useMutation(LIKE_BLOG_POST, {
  //   variables: { likeBlogPost: { entity: post.id } },
  // })

  // const [dislikeBlogPost] = useMutation(DISLIKE_BLOG_POST, {
  //   variables: { likeBlogPost: { entity: post.id } },
  // })

  // const handleLike = () => (post.isLiked ? dislikeBlogPost() : likeBlogPost())

  const subs = blogCategories
    ? [].concat(
      ...blogCategories?.reduce(function (result, cat) {
        if (cat.subcategories) {
          result.push(cat.subcategories)
        }
        return result
      }, [])
    )
    : []
  const ids = []?.concat(...subs?.map(({ id }) => id))
  const filteredCats = post?.categoriesNew?.filter((cat) =>
    ids?.includes(cat?.id)
  )

  const tagsLimit = 2
  const displayedTags = filteredCats?.slice(0, tagsLimit)

  const isDiscoverPage = useIsDiscoverPage()

  const getCatName = (id) => subs.find((cat) => cat?.id === id)?.name

  const _renderTitle = () => (
    <>
      {isDiscoverPage ? (
        <h3 className="title">{title || "Title not specified"}</h3>
      ) : (
        <h2 className="title">{title || "Title not specified"}</h2>
      )}
    </>
  )

  return (
    <DefaultStyled coverPhoto={coverPhoto?.replace('upload/', 'upload/c_fill,w_800,h_400/')}>
      <Content>
        <DefaultTileStyled className={className}>
          {post.categoriesNew || post.categoriesNew === 'undefined' ? (
            <Tags>
              {displayedTags?.map((t) => (
                <div className="tag" key={t.id}>
                  {getCatName(t.id)}
                </div>
              ))}
              {filteredCats.length > tagsLimit && (
                <div className="tag">
                  {`+${filteredCats.length - tagsLimit}`}
                </div>
              )}
            </Tags>
          ) : null}
          <div className="info">
            <TopInfo className="weirdfix">
              {_renderTitle()}
              <p className="description">{description}</p>
            </TopInfo>
            <FooterInfo>
              <div className="blog-stats"> 
                {/*TODO: like button disabled*/ false && <span className="likes">
                  <button
                    className="like-button"
                  // onClick={(e) => {
                  //   e.preventDefault()
                  //   handleLike()
                  //     .then(() => refetchData())
                  //     .catch((err) => console.log('error', err))
                  // }}
                  >
                    <i
                      className={`fa${post.isLiked ? 's' : 'r'} fa-thumbs-up`}
                    />
                  </button>
                  {post.likesCount}
                </span>}
                
                {/* <span className="comments">
                  <i class="far fa-comment" />
                  14
                </span> */}
                <ClickAway
                  onClickOutside={() => {
                    setIsShareMenuOpen(false)
                    setIsInputShown(false)
                  }}
                  className="share-menu"
                >
                  {isShareMenuOpen && (
                    <ConditionalWrapper
                      hasWrapper={isMobileView}
                      Wrapper={FullscreenLoaderWrapper}
                    >
                      <div className="buttons-wrapper">
                        {shareButtons.map((item, i) => {
                          const { name, type, icon, shareLink } = item

                          return (
                            <>
                              <SharePostButton
                                key={i}
                                icon={icon}
                                type={type}
                                name={name}
                                url={shareLink}
                                post={post}
                                setIsInputShown={setIsInputShown}
                                isInputShown={isInputShown}
                              />
                            </>
                          )
                        })}
                        {isMobileView && (
                          <button
                            className="mobile-cancel-button"
                            onClick={(e) => {
                              e.preventDefault()
                              setIsShareMenuOpen(false)
                              setIsInputShown(false)
                            }}
                          >
                            {intl.formatMessage({
                              id: 'blog.share.button.cancel',
                              defaultMessage: 'Cancel',
                            })}
                          </button>
                        )}
                      </div>
                    </ConditionalWrapper>
                  )}
                  <button
                    data-tip={intl.formatMessage({
                      id: 'blog.share.button.tooltip',
                      defaultMessage: 'Share',
                    })}
                    data-for="info-tooltip"
                    className="sharebutton"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsShareMenuOpen(!isShareMenuOpen)
                    }}
                  >
                    <i className="fas fa-share" />
                  </button>
                </ClickAway>
              </div>
              <ReactTooltip id="info-tooltip" effect="solid" />
            </FooterInfo>
          </div>
        </DefaultTileStyled>
      </Content>
    </DefaultStyled>
  )
}


export default Default
