import React from 'react'
import styled from '@emotion/styled'
import { mobile } from '_utils/responsive-queries'

const Header = styled.h2`
  font-size: 22px;
  font-weight: 700;
  ${mobile} {
    font-size: 18px;
  }
`

const ItemWrapper = styled.div`
  width: 100%;
`

const ContentSection = ({ header, children, hasItems }) => {
  return (
    <>
      {hasItems && (
        <ItemWrapper>
          <Header>{header}</Header>
          <div>{children}</div>
        </ItemWrapper>
      )}
    </>
  )
}

export default ContentSection
