import React from 'react'
import Toggle from 'react-toggle'
import { Wrapper, ToggleButtonWithNameStyled  } from './styles/toggle-button'
import 'react-toggle/style.css'
import BreakLine from './break-line'

const ToggleButton = ({
  value,
  onSave,
  config: {title, desc }
}) => {
  return (
    <>
      <Wrapper>
        <label>{title}</label>
        <ToggleButtonWithNameStyled>
        <span className="name">{desc}</span>
          <Toggle
            icons={{ checked: null, unchecked: null }}
            checked={value && value}
            onChange={(e) => onSave(e.target.checked)}
          />
        </ToggleButtonWithNameStyled>
      </Wrapper>
      <BreakLine/>
    </>
  )
}

export default ToggleButton
