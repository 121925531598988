import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Modal } from 'react-bootstrap'

import ModalStyled from './styles/consent-modal'

const mapStateToProps = ({ user }) => ({ user })

const ConsentModal = ({ user, dispatch }) => {
  const handleConsent = () => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: {
          ...user.profile,
          termsAndConditionsConsentDate: new Date().toISOString(),
        },
      },
    })
  }

  return (
    <ModalStyled>
      <Modal
        className="consent-modal"
        onHide={() => {}}
        show={!user.profile.termsAndConditionsConsentDate}
      >
        <Modal.Body>
          <span>
            Accept{' '}
            <Link target="_blank" to="/">
              terms and conditions
            </Link>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={() => handleConsent()}>
            Accept consent
          </button>
        </Modal.Footer>
      </Modal>
    </ModalStyled>
  )
}

export default connect(mapStateToProps)(ConsentModal)
