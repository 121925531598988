import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from '../../../../shared/ui-kit/buttons/single-button'
import Modal from 'react-bootstrap/Modal'
import TaxonomySelector from 'components/indy/shared/taxonomy'
import ManageTaxonomyStyled from './styles/manage-taxonomy.js'

const ModalIndex = ({ onClose, onSave, taxonomyList, taxonomyValues }) => {
  const [values, setValues] = useState(taxonomyValues || [])
  const intl = useIntl()

  const handleSelect = (selectedList) => {
    setValues(selectedList)
  }

  const handleSave = () => {
    onSave(values)
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `user.profile.manage.taxonomy.modal.${value.toLowerCase()}`,
    })
  }

  return (
    <Modal show onHide={onClose}>
      <ManageTaxonomyStyled>
        <Modal.Body>
          <h3 className="modal-title">{`${renderMessageText('title')} ${
            taxonomyList.name
          }`}</h3>
          <p className="modal-description">{taxonomyList.description}</p>
          <div className="modal-content">
            <TaxonomySelector
              selectedValues={values}
              taxonomy={taxonomyList}
              onSelectCallback={(l) => handleSelect(l)}
              height={650}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="admin" onClick={onClose}>
            {renderMessageText('close')}
          </Button>
          <Button type="admin" onClick={() => handleSave()}>
            {renderMessageText('save')}
          </Button>
        </Modal.Footer>
      </ManageTaxonomyStyled>
    </Modal>
  )
}

export default ModalIndex
