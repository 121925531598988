import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'

const Avatar = styled.div`
  position: absolute;
  right: ${({ index }) => `${index * 20 - 5 * index}px`};
  width: 23px;
  height: 23px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid var(--imgColor);
  transform: translateZ(${({ index }) => index * -1 + 'px'});
  img {
    ${border()}
    ${imageSkeleton}
    width: 23px;
    height: 23px;
    object-fit: cover;
    object-position: center;
  }
`
const ConnectionsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  transform-style: preserve-3d;
`
const Container = styled.div``

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: var(--imgColor);
  transform: translateZ(
    ${({ connectionsDisplayLimit }) => connectionsDisplayLimit + 'px'}
  );
  position: absolute;
  right: -7px;
  top: 12px;
  transform: translateY(-50%);
  font-size: 6px;
  font-weight: 500;
`

const connectionsDisplayLimit = 3

const Connections = ({ data }) => {
  const {
    connectedCompanies,
    connectedOpportunities,
    connectedPeople,
    connectedActivities,
  } = data

  const theme = useTheme()

  const connectionsList = [
    connectedActivities,
    connectedCompanies,
    connectedOpportunities,
    connectedPeople,
  ]

  const renderConnections = (connections, list) => {
    if (!connections) return null

    return (
      <ConnectionsWrapper>
        {connections.map((c, i) => {
          return (
            <>
              <Avatar key={i} index={i}>
                <img src={c.coverPhoto || theme?.images?.defaultCover} alt="" />
              </Avatar>
            </>
          )
        })}
        {list.length > connectionsDisplayLimit && (
          <Count connectionsDisplayLimit={connectionsDisplayLimit}>
            {`+${list.length - connectionsDisplayLimit}`}
          </Count>
        )}
      </ConnectionsWrapper>
    )
  }

  return (
    <Container>
      {connectionsList.map((list) => {
        const limitedList =
          list?.length > 3 ? list.slice(0, connectionsDisplayLimit) : list

        return renderConnections(limitedList, list)
      })}
    </Container>
  )
}

export default Connections
