import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile } from '_utils/responsive-queries'

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 110px);
  padding: 35px 50px 25px 25px;
  background: var(--shade);

  ${mobile} {
    height: 100%;
    padding: 35px 0 25px;
  }
`
export const LeftColumn = styled.div`
  ${border()}
  align-self: flex-start;
  flex-basis: 35%;
  max-width: 380px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 15px;
  padding: 25px 0 80px;
  background-color: var(--background);
`

export const RightColumn = styled.div`
  ${border()}
  flex-basis: 65%;
  max-width: 800px;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--background);

  ${mobile} {
    box-shadow: none;
    border-radius: 0;
  }
`
