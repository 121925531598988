import React from 'react'
import { Container } from '_components/custom-attributes'
import styled from '@emotion/styled'
import { Section, SingleAttribute } from '_custom-attributes'

const FileUploadWrapper = styled.div`
  margin-top: 30px;
  .file-info-wrapper {
    display: flex;
    align-items: center;
    i {
      font-size: 36px;
      padding: 5px 10px;
      color: var(--flavor);
    }
    .file-name {
      color: var(--foreground);
    }
  }
  div:last-child {
    margin-bottom: 0;
  }
`

export const sortAttributes = (attributes, values) => {
  const types = [
    ...new Set(
      attributes
        .filter((item) => item.type !== 'badge')
        .map((item) => item.type)
    ),
    'badge',
  ]
  let sortedAttributes = []

  types.map((t) => {
    const attr = {
      key: t,
      values: attributes.filter(
        (a) => a.type === t && values?.some((v) => v.key === a.key && v.value)
      ),
    }
    if (attr.values?.length > 0) sortedAttributes.push(attr)
    return t
  })

  return sortedAttributes
}

const CustomAttributes = ({ values, attributes, side }) => {
  // Values are the values within the attribute
  if (attributes) {
    let sortedAttributes = sortAttributes(attributes, values)

    return (
      <Container>
        <FileUploadWrapper>
          {sortedAttributes.map((attrList) => {

    const attributeResolved = values?.find(item => attrList.values.find((val) => val.key === item.key))

    const parsedAttributeValue = () => {
      try {
        return JSON.parse(attributeResolved.value)
      } catch {console.log('error')}
        return attributeResolved.value
      } 

    const hasAttributeValues = parsedAttributeValue()?.length > 0 || attributeResolved.value
            if (!hasAttributeValues) return <></>
            return (
              <Section type={attrList.key} side={side}>
                {attrList.values.map((attr, i) => {
            
                  let attrValue = values?.filter((c) => c.key === attr.key)[0]
                    ?.value
                  if(attr.type === 'boolean'){
                    return (
                      <SingleAttribute
                        attribute={{
                          name: attr.name,
                          description: attr.description,
                          type: attr.type,
                          url: attrValue,
                          badges: attr.badges,
                          value: attrValue
                        }}
                      />
                    )
                  }
                  try {
                    attrValue = JSON.parse(attrValue)
                    return <SingleAttribute
                              attribute={{
                                name: attr.name,
                                description: attr.description || '',
                                type: attr.type,
                                url: attr.url,
                                badges: attr.badges,
                                value: attrValue
                              }}
                            />
                  }catch(err) {
                  }
                    return (
                      <SingleAttribute
                        attribute={{
                          name: attr.name,
                          description: attr.description,
                          type: attr.type,
                          url: attrValue,
                          badges: attr.badges,
                          value: attrValue
                        }}
                      />
                    )
                  
                })}
              </Section>
            )
          })}
        </FileUploadWrapper>
      </Container>
    )
  } else {
    return null
  }
}
export default CustomAttributes
