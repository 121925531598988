import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Loader } from '_components/loading'
import RecommendButton from '_components/buttons-connections/recommend-button'
import { Redirect, useParams } from 'react-router-dom'
import { useUserAccess } from '_security/helpers'
import { useLocation } from 'react-router-dom'
import PersonDetailedView from '_people/components/person-detailed-view'
import { Route } from 'react-router-dom'
import ActivityDetailsConnected from '_activities/connected/activity-details-connected'
import { sendNotification } from '_components/buttons-connections/message-helper'

export const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      email
      birthDate
      gender
      avatar
      coverPhoto
      bioShort
      bioLong
      instagram
      linkedIn
      twitter
      languagesSpoken
      connectionStatus
      languagesWritten
      phone
      role
      location
      lastJobPosition {
        companyName
        title
      }
      competences {
        value
        type
      }
      experience {
        companyName
        title
        startDate
        endDate
        location
        description
        websiteUrl
        showOnMinature
      }
      education {
        schoolName
        degree
        domain
        yearGraduated
        yearStarted
        websiteUrl
      }
      customAttributes {
        key
        value
        type
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomies {
        key
        values
      }
    }
  }
`
const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      connectionStatus
      connectedCompaniesCount
      createdCompaniesCount
      createdActivitiesCount
      connectedUsersCount
      connectedActivitiesCount
    }
  }
`

export const CONNECT_MUTATION = gql`
  mutation ConnectUser($sub: String!) {
    connectToUser(sub: $sub) {
      status
    }
  }
`

export const DISCONNECT_MUTATION = gql`
  mutation DisconnectPersonToPerson($personId: String!) {
    disconnectPersonToPerson(personId: $personId) {
      status
    }
  }
`

const Props = {
  sub: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const PersonDetailsConnected = () => {
  const intl = useIntl()
  const { userSub } = useParams()

  const { data, error, loading } = useQuery(GET_USER_INFO, {
    variables: {
      id: userSub,
    },
  })

  const sub = data?.getUser?.sub || userSub

  const [disconnect] = useMutation(DISCONNECT_MUTATION)

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION, {
    variables: { sub },
    refetchQueries: [
      {
        query: GET_USERS_CONNECTIONS,
        variables: { ids: [sub], limit: 12 },
      },
    ],
    awaitRefetchQueries: true,
    update(cache, { data }) {
      // We use an update function here to write the
      // new value of the GET_USERS_CONNECTIONS query.
      const newStatus = data?.connectToUser.status

      const existingConnections = cache.readQuery({
        query: GET_USERS_CONNECTIONS,
        variables: { ids: [sub], limit: 12 },
      })

      if (existingConnections && newStatus) {
        let newConnections = JSON.parse(JSON.stringify(existingConnections)) // copy without reference

        cache.writeQuery({
          query: GET_USERS_CONNECTIONS,
          data: {
            getUserConnectionsPublic: [
              ...newConnections.getUserConnectionsPublic.map((c) => {
                c.connectionStatus = newStatus
                return c
              }),
            ],
          },
        })
      }
    },
  })

  const {
    data: connectionsData,
    loading: connectionsLoading,
    refetch,
  } = useQuery(GET_USERS_CONNECTIONS, {
    variables: { ids: [sub], limit: 12 },
    notifyOnNetworkStatusChange: true,
  })
  const { getUserConnectionsPublic: usersConnections } = connectionsData || []
  const userConnections =
    usersConnections && usersConnections.length > 0 ? usersConnections[0] : null
  const isUserNotFound = !loading && !data?.getUser?.sub
  const hasUserAccess = useUserAccess()

  useEffect(() => {
    refetch()
  }, [refetch])

  const currentLocation = useLocation()
  const { state: refetchQueries } = currentLocation

  const [connectRefetchQueries] = useMutation(CONNECT_MUTATION)

  if (!hasUserAccess) {
    return <Redirect to="/people/list" />
  }

  return isUserNotFound ? (
    <h2>{intl.formatMessage({ id: 'person.details.user.notfound' })}</h2>
  ) : (
    <Loader loading={loading && connectionsLoading} error={error}>
      {() =>
        userConnections && (
          <>
            <PersonDetailedView
              person={data?.getUser}
              disconnectButton={{
                onDisconnect: () => {
                  disconnect({
                    variables: { personId: sub },
                    refetchQueries: [
                      {
                        query: GET_USERS_CONNECTIONS,
                        variables: { ids: [sub], limit: 12 },
                      },
                    ],
                    awaitRefetchQueries: true,
                  })
                },
                onDisconnectRefetchQueries: () =>
                  refetchQueries &&
                  disconnect({
                    variables: { personId: sub },
                    refetchQueries: [refetchQueries],
                    awaitRefetchQueries: true,
                  }),
              }}
              connectButton={{
                status: userConnections?.connectionStatus,
                onConnect: () => {
                  connect().then(res => {
                    const { status } = res?.data?.connectToUser
                    sendNotification({
                      type: 'connection',
                      intl,
                      defaultHeader: 'Connection',
                      defaultContent: `You have ${
                        status === 'cancelled'
                          ? 'cancelled your connection'
                          : 'sent a connection request'
                      }`,
                    })
                  }).catch(err => console.log('error',err))},
                onConnectRefetchQueries: () =>
                  refetchQueries &&
                  connectRefetchQueries({
                    variables: { sub },
                    refetchQueries: [refetchQueries],
                    awaitRefetchQueries: true,
                  }),
                disabled: connecting,
              }}
              connections={userConnections}
              connectionsLoading={connectionsLoading}
              p2pButton={{
                refetchQueries: [
                  {
                    query: GET_USERS_CONNECTIONS,
                    variables: { ids: [sub], limit: 12 },
                  },
                ],
              }}
              recommendButton={<RecommendButton contentToRecomment={sub} />}
            />
            <Route
              path="/people/list/:personId/activities/:id"
              render={({ match, history, location }) => (
                <ActivityDetailsConnected
                  id={match.params.id}
                  onClose={() =>
                    history.push(`/people/list/${match.params?.personId}`)
                  }
                />
              )}
            />
          </>
        )
      }
    </Loader>
  )
}
PersonDetailsConnected.propTypes = Props

export default PersonDetailsConnected
