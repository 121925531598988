import React, { useState, useEffect } from 'react'
import { renderField } from '../../shared/field-factory'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import Button from '../../../../shared/ui-kit/buttons/single-button'
import Modal from 'react-bootstrap/Modal'
import ModalStyled from './styles/add-edit'

import validate from 'services/validation/text'
// import date from 'components/indy/shared/ui-kit/inputs/date'

const ModalIndex = ({
  onClose,
  onSave,
  user,
  defaultProps,
  fields,
  type,
  description,
}) => {
  const [modalProps, setModalProps] = useState(defaultProps || {})
  const [errors, setErrors] = useState({})
  const intl = useIntl()

  useEffect(() => {
    const obj = {}
    if (!defaultProps) {
      fields.forEach((f) => {
        if (f.key === 'yearStarted') return (obj[f.key] = new Date())
        f.key === 'endDate'
          ? (obj[f.key] = null)
          : f.key === 'startDate'
          ? (obj[f.key] = new Date())
          : (obj[f.key] = '')
      })
      setModalProps(obj)
    }
  }, [fields, defaultProps])

  const isEditMode = defaultProps

  const handleSave = () => {
    const error = {}
    Object.keys(modalProps).forEach((k, index) => {
      const f = fields.filter((f) => f.key === k)
      const obj = {
        value: modalProps[k],
        type: f[0]?.type,
        name: f[0]?.name,
        required: f[0]?.required ? f[0]?.required : null,
      }

      error[k] = validate(obj)
    })
    setErrors({
      ...error,
    })
    if (
      !Object.keys(errors).some((k) => errors[k]) &&
      !Object.keys(error).some((k) => error[k])
    ) {
      onSave(modalProps)
    }
  }

  const handleSetModalProps = (item, value) => {
    let val = value
    if (item.type === 'date') {
      val = handleDatePicker({ item, value: value, key: item.key })
    }
    setModalProps({ ...modalProps, [item.key]: val })
  }

  const handleDatePicker = ({ item, value }) => {
    if (!value) return value

    const { endDate, startDate, yearStarted, yearGraduated } = modalProps

    const targetDate = moment(
      type === 'experience'
        ? item.key === 'startDate'
          ? endDate
          : startDate
        : item.key === 'yearStarted'
        ? yearGraduated
        : yearStarted
    ).toDate()

    return item.key === 'startDate' || item.key === 'yearStarted'
      ? value > targetDate
        ? targetDate
        : value
      : value < targetDate
      ? targetDate
      : value
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `global.modal.label.${value.toLowerCase()}`,
    })
  }

  const renderDataTip = (key) => {
    return (
      intl.formatMessage({ id: 'global.modal.label.tooltip.question' }) +
      ' ' +
      intl
        .formatMessage({ id: `global.modal.label.${key.toLowerCase()}` })
        .toLowerCase()
    )
  }

  return (
    <Modal show onHide={onClose}>
      <ModalStyled>
        <Modal.Body>
          <h3 className="modal-title">
            {`${renderMessageText(
              !defaultProps ? 'add' : 'edit'
            )} ${renderMessageText(type)}`}
          </h3>
          <div className="modal-content">
            <span>{description}</span>
            {fields.map((f, i) => {
              return (
                <div key={i} className="field">
                  <span
                    data-tip={renderDataTip(f.key)}
                    className="field-title"
                    data-testid={`modal-${f.key}`}
                  >
                    {`${renderMessageText(f.key)} ${f.required ? '*' : ''}`}
                  </span>
                  {renderField({
                    attribute: f,
                    user,
                    isCustomAttr: false,
                    customValue: modalProps[f.key],
                    isCustomValue: true,
                    error: errors[f.key],
                    onSave: (f, value) => handleSetModalProps(f, value),
                    onError: (field, message) =>
                      setErrors({ ...errors, [f.key]: message }),
                  })}
                </div>
              )
            })}
            <ReactTooltip effect="solid" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="admin" onClick={onClose} testid="modal-close">
            {renderMessageText('close')}
          </Button>
          <Button
            onClick={() => handleSave()}
            testid={`modal-${isEditMode ? 'save' : 'add'}`}
            type="admin"
          >
            {renderMessageText(isEditMode ? 'save' : 'add')}
          </Button>
        </Modal.Footer>
      </ModalStyled>
    </Modal>
  )
}

export default ModalIndex
