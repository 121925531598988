import React, { useState } from 'react'
import styled from '@emotion/styled'
import ClickAway from '_components/click-away'

const IconPickerContainer = styled(ClickAway)`
  --padding: 25px;
  --radius: 25px;

  display: flex;
  flex-direction: column;
  max-width: 500px;

  margin: calc(var(--padding) / 2) 0px var(--padding);

  .icon-picker {
    cursor: pointer;
    position: relative;
    display: flex;
    padding: calc(var(--padding) / 2) 0px;
    gap: calc(var(--padding) / 2);
    border-bottom: solid 3px var(--shade);
    transition: .2s;

    label {
      cursor: pointer;
      margin-top: 2px;
    }

    input {
      background: transparent;
      border: none;
      &::placeholder {
        opacity: .5;
      }
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 0%;
      bottom: 0;
      left: 0;
      background: var(--flavor);
      border-top-left-radius: calc(var(--radius) / 2);
      border-top-right-radius: calc(var(--radius) / 2);
      opacity: 0;
      transition: .2s;
    }
  }

  .icon-picker:hover,
  &.open .icon-picker {
    padding: calc(var(--padding) / 2);
    border-bottom: solid 3px var(--flavor);
  }

  .icon-picker:hover::after,
  &.open .icon-picker::after {
    height: 100%;
    opacity: 0.2;
  }

  .dropdown {
    --icon-size: 50px;
    position: relative;
    max-height: 20vh;
    padding-bottom: calc(var(--padding) / 2);
    transition: .2s;

    .overflow {
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
      max-height: 100%;
    }
  }

  .icon {
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-size, 25px);
    aspect-ratio: 1 / 1;
    transition: .2s;

    i { z-index: 1 }

    &:active {
      transform: rotate(45deg);
    }

    &:hover::after {
      clip-path: circle(100% at center);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      background: var(--flavor);
      clip-path: circle(0% at center);
      border-radius: var(--radius);
      transition: .2s;
    }
  }

  &.open .dropdown {
    border-radius: var(--raius);
    box-shadow: 0px 5px 15px -1px rgb(0 0 0 / 20%);
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }

  &.closed .dropdown {
    max-height: 0px;
  }
` 

const IconPicker = ({children, value = "fa fa-folder-open", onIconPick = () => {}}) => {
  const icons = [
    "fa fa-bolt",
    "fa fa-book",
    "fa fa-bookmark",
    "fa fa-briefcase",
    "fa fa-building",
    "fa fa-bullhorn",
    "fa fa-bullseye",
    "fa fa-calendar",
    "fa fa-camera",
    "fa fa-users",
    "fa fa-cogs",
    "fa fa-comments",
    "fa fa-compass",
    "fa fa-credit-card",
    "fa fa-cubes",
    "fa fa-cutlery",
    "fa fa-desktop",
    "fa fa-diamond",
    "fa fa-window-restore",
    "fa fa-envelope",
  ]

  const [icon, setIcon] = useState(value)
  const [dropdown, setDropdown] = useState(false)

  const onIconClick = i => {
    setDropdown(false)
    setIcon(i)
    onIconPick(i)
  }

  return (
    <IconPickerContainer 
      className={`${dropdown ? "open" : "closed"}`}
      onClickOutside={() => setDropdown(false)}
    >
      <div className="icon-picker" onClick={() => setDropdown(!dropdown)}>
        <div className="icon">
          <i className={icon}></i>
        </div>
        {children 
          ? <div onClick={() => setDropdown(false)}>{children}</div>
          : <label>Menu Icon</label>}
      </div>
      <div className={`dropdown`}>
        <div className="overflow">
          {icons.map((i, index) => (
            <div className="icon" onClick={() => onIconClick(i)} key={index}>
              <i className={i}></i>
            </div>
          ))}
        </div>
      </div>
    </IconPickerContainer>
  )
}

export default IconPicker