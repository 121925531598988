import React from 'react'
import './style.scss'

const Index = () => {
  return (
    <div className="cookie-policy-section">
      <div className="inner-cookie-policy-container">
        <div className="content-group">
          <h1>Cookie Policy</h1>
          <p>
            This website uses cookie technology to help personalise content and
            provide a better experience. Some cookies are necessary for your
            safety and to help our website work properly and can&apos;t be
            switched off. This policy explains how and why we use these
            technologies and the choices you have.
          </p>
          <p>
            A cookie is a small data file that is transferred to your device
            (e.g. your phone or your computer). For example, a cookie could
            allow us to recognize your browser, while another could store your
            preferences. There are two types of cookies used on this platform:
            (1) “session cookies” and (2) “persistent cookies.” Session cookies
            normally expire when you close your browser, while persistent
            cookies remain on your device after you close your browser, and can
            be used again the next time you access this platform.
          </p>
          <p>
            We also use other technologies with similar functionality to
            cookies, such as web beacons, pixels, mobile identifiers, and
            tracking URLs, to obtain Log Data (as described in the Privacy
            Policy). For example, our email messages may contain web beacons and
            tracking URLs to determine whether you have opened a certain message
            or accessed a certain link.
          </p>
        </div>
        <div className="content-group">
          <h3>Why We Use These Technologies</h3>
          <p>We use these technologies for a number of purposes, such as:</p>
          <ul>
            <li>
              To enable you to use and access the platform in a safe manner.
            </li>
            <li>
              To enable, facilitate and streamline the functioning of and your
              access to the platform.
            </li>
            <li>
              To better understand how you navigate through and interact with
              the platform and to help improve the platform.
            </li>
            <li>
              To monitor and analyze the performance, operation, and
              effectiveness of the platform.
            </li>
            <li>
              To enforce legal agreements that govern use of the platform.
            </li>
            <li>
              For fraud detection and prevention, trust and safety, and
              investigations.
            </li>
            <li>
              For purposes of our own customer support, analytics, research,
              product development, and regulatory compliance.
            </li>
          </ul>
        </div>
        <div className="content-group">
          <h3>Cookies</h3>
          <p>
            When you visit our websites we place cookies on your computer.
            Cookies are small text files that websites send to your computer or
            other Internet-connected device to uniquely identify your browser or
            to store information or settings in your browser. Cookies allow us
            to recognize you when you return. They also help us provide a
            customized experience and can enable us to detect certain kinds of
            fraud.
          </p>
          <p>
            In many cases you can manage cookie preferences and opt-out of
            having cookies and other data collection technologies used by
            adjusting the settings on your browser. All browsers are different
            so visit the “help” section of your browser to learn about cookie
            preferences and other privacy settings that may be available. Please
            note that if you choose to remove or reject cookies or clear local
            storage this could affect the features, availability, and
            functionality of our websites.
          </p>
        </div>
        <div className="content-group">
          <h3>Pixel Tags and Web Beacons</h3>
          <p>
            Pixel tags and web beacons are tiny graphic images and small blocks
            of code placed on website pages, ads, or in our emails that allow us
            to determine whether you performed a specific action. When you
            access these pages, or when you open an email, the pixel tags and
            web beacons let us know you have accessed the web page or opened the
            email. These tools help us measure response to our communications
            and improve our web pages and promotions.
          </p>
        </div>
        <div className="content-group">
          <h3>Server Logs and Other Technologies</h3>
          <p>
            We collect many different types of information from server logs and
            other technologies. For example, we collect information about the
            device you use to access our website, your operating system type,
            browser type, domain, and other system settings, as well as the
            language your system uses and the country and time zone where your
            device is located.
          </p>
          <p>
            Our server logs also record the IP address of the device you use to
            connect to the Internet. An IP address is a unique identifier that
            devices require to identify and communicate with each other on the
            Internet. We may also collect information about the website you were
            visiting before you came to our website and the website you visit
            after you leave our site. These tools help us improve user
            experience and deliver our services.
          </p>
        </div>
        <div className="content-group">
          <h3>Device Information</h3>
          <p>
            We may use device-related information to authenticate users. For
            example, we may use your IP address, browser information, or other
            data provided by your browser or device to identify the device being
            used to access our platform. We may also use these device-related
            techniques for associating you with different devices that you may
            use to access our content including for fraud-protection purposes
            and to better target advertising.
          </p>
        </div>
        <div className="content-group">
          <h3>Third Parties</h3>
          <p>
            We may also allow certain business partners to place these
            technologies on the platform. These partners use these technologies
            to (1) help us analyze how you use the platform, such as by noting
            the third party services from which you arrived, (2) help us detect
            or prevent fraud or conduct risk assessments, or (3) collect
            information about your activities on the platform, and other sites.
            For example, to help us better understand how people use the
            platform, we work with a number of analytics partners, including
            Google Analytics. To prevent Google Analytics from using your
            information for analytics, you may install the Google Analytics
            Opt-Out Browser by clicking here.
          </p>
        </div>
        <div className="content-group">
          <h3>Your Choices - Cookie Preferences</h3>
          <p>
            Most browsers automatically accept cookies, but you can modify your
            browser setting to decline cookies by visiting the Help portion of
            your browser’s toolbar. Your mobile device may allow you to control
            cookies through its settings function. Refer to your device
            manufacturer’s instructions for more information. If you choose to
            decline cookies, some parts of this platform may not work as
            intended or may not work at all.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Index
