import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useAppConfig } from '_application'
import { IKUICore } from '@indykiteone/jarvis-sdk-web'
import Header from 'components/indy/shared/logged/header'
import { defaultLoginBackground } from '_resources'
import LoginTitle from '_components/login-title'
import LoginRegister from '_uikit/login-register'
import { LoginContainer } from '_themes/default/components/styled/login-register'
import { useSetThemeSettings } from '_application'
import { useThemeSettings } from '_application'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`

const mapStateToProps = ({ settings, appConfig }) => ({ settings, appConfig })

const Index = () => {
  const config = useAppConfig()
  const intl = useIntl()
  useSetThemeSettings({ hasAlternativeBgc: true })
  const themeSettings = useThemeSettings()

  const description = intl.formatMessage({
    id: 'forgotten.password.resetpassword.description',
  })

  const backgroundImage = config?.backgroundImages?.login
  const isBackgroundVideo = backgroundImage?.type === 'video'
  const backgroundUrl = backgroundImage?.value || defaultLoginBackground

  useEffect(() => {
    IKUICore.renderForgotPasswordForm({
      renderElementSelector: '#forgotten-password-container',
    })
  }, [])

  return (
    <>
      <Header />
      <LoginRegister themeSettings={themeSettings}>
        <LoginContainer>
          <Row className="login-container">
            <Col className="login-col">
              <h2 className="login-title">
                {intl.formatMessage({
                  id: 'forgotten.password.resetpassword.title',
                })}
              </h2>

              <div id="forgotten-password-container" />
            </Col>
            <Col className="image-col">
              {isBackgroundVideo ? (
                <Video
                  className="background"
                  width="100%"
                  height="100%"
                  src={backgroundUrl}
                  playsinline
                  autoPlay
                  muted
                  loop
                />
              ) : (
                <img className="thumbnail" src={backgroundUrl} alt="" />
              )}
              <LoginTitle>{description}</LoginTitle>
            </Col>
          </Row>
        </LoginContainer>
      </LoginRegister>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(Index))
