import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const sentryInit = (reduxStore) => {
  const { id, email } = reduxStore.getState().user?.profile

  const isEnabled =
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    enabled: isEnabled,
    tracesSampleRate: 0.25,

    initialScope: (scope) => {
      scope.setTags({ tenant_hostname: window.location.hostname })
      email && id && scope.setUser({ id, email })
      return scope
    },
    // beforeSend(event) {
    //   const { mechanism } = event.exception.values[0]

    //   if (!mechanism?.handled) {
    //     Sentry.showReportDialog({ eventId: event.event_id })
    //   }

    //   return event
    // },
  })
}
