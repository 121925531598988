import React from 'react'
import { useQuery, gql } from '@apollo/client'
// import { Link } from 'react-router-dom'
import { Grid, BaseTile } from '_tiles'
// import { Default as PeopleTile } from '_people/components/tiles'
import { ActivityIndicator } from './styles/top-contributors'
import { UserTile } from '../components/user-tile'
import { Loader } from '_components/loading'
import { mobilePX } from '_utils/responsive-queries'
import { useMediaQuery } from 'react-responsive'

const GET_SORTED_USERS = gql`
  query GET_SORTED_USERS($query: String!, $page: Float) {
    getForumUsersSection(query: $query, page: $page) {
      usersList {
        uid
        username
        status
        displayname
        username
        picture
        postcount
      }
    }
  }
`

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 3,
  xl: 4,
  lg: 6,
  md: 6,
  sm: 6,
}

const TopContibutors = (props) => {
  const { data } = useQuery(GET_SORTED_USERS, {
    variables: { query: 'sort-posts', page: 1 },
  })

  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })

  const usersToDisplay = isMobileView ? 10 : 30

  const topContibutorsUsers =
    data?.getForumUsersSection?.usersList.slice(0, usersToDisplay) || []

  const filteredContributors = topContibutorsUsers.filter(
    (user) => user.postcount > 0
  )

  if (!data) return <Loader loading={!data} />
  return (
    <Grid config={gridConfig} testid="topContributors">
      {filteredContributors.map(function (user, i) {
        if (user.uid === 1) return <></>
        return (
          <BaseTile
            config={defaultConfig}
            isAdditionalAuthEnabled={true}
            isAdditionalAuth={true}
          >
            <UserTile
              isPlain
              person={user}
              connectButton={{
                status: user.connectionStatus,
                onConnect: () => {},
                disabled: '',
              }}

              // connections={connections}
              // connectionsLoading={connectionsLoading}
            >
              <ActivityIndicator>
                <i className="fas fa-pencil-alt" />
                <span>{user.postcount >= 0 ? user.postcount : 0}</span>
              </ActivityIndicator>
            </UserTile>
          </BaseTile>
        )
      })}
    </Grid>
  )
}

export default TopContibutors
