import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  location: PropTypes.string,
  onlineStatus: PropTypes.oneOf(['ONLINE', 'OFFLINE']),
  children: PropTypes.node,
  className: PropTypes.string,
  withoutConnections: PropTypes.bool
}

export const AvatarTile = themable('AvatarTile', Props)
export default AvatarTile
