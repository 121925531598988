import styled from '@emotion/styled'

export const StyledList = styled.div`
  margin: 15px 0 50px;
  color: var(--foreground);
  font-size: 14px;

  table {
    width: 100%;
  }
  .item-description,
  .item-name,
  .item-type {
    max-width: 150px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px;
  }
  tr {
    &.item {
      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }
    th {
      border: 1px solid var(--shade);
      border-left: none;
      border-right: none;
      border-top: none;
      font-weight: 500;
      padding-bottom: 10px;
    }
    td {
      text-align: left;
      font-weight: 400;
      &.no-edit {
        opacity: 0.3;
      }
      &.attribute-type {
        text-transform: capitalize;
      }
    }
  }
  .fas {
    color: var(--foreground);
    margin: 5px;
    padding: 5px;
    outline: none;
    &.fa-times {
      color: var(--foreground);
    }
    &.fa-check {
      color: ${(props) => props.theme.colors.primaryColor};
    }
    &.fa-trash-alt:hover {
      color: ${(props) => props.theme.colors.alertColor};
    }

    &.fa-pencil-alt:hover {
      color: ${(props) => props.theme.colors.primaryColor};
    }
  }
  .text-used-by {
    text-transform: capitalize;
    font-size: 11px;
  }
  .text-center {
    text-align: center;
  }
  .text-capital {
    text-transform: capitalize;
  }
`
