import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import InputShared from '../../../settings/override-labels/generic/input-shared'

const Email = () => {
  const intl = useIntl()

  const renderMessageDescription = (value) => {
    return intl.formatMessage({
      id: `admin.sitestructure.opportunities.labels.${value.toLowerCase()}`,
    })
  }

  return (
    <>
      <MainSection>
        <span className="title">{renderMessageDescription('title')}</span>
        <span className="description">
          {renderMessageDescription('description')}
        </span>
        <Row>
          <Col sm={12} md={4}>
            <InputShared
              type="blog"
              title={renderMessageDescription('single')}
            />
          </Col>
          <Col sm={12} md={4}>
            <InputShared
              type="blogPlural"
              title={renderMessageDescription('plural')}
            />
          </Col>
          <Col sm={12} md={8}>
            <InputShared
              type="blogDescription"
              title={renderMessageDescription('descriptioninput')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
    </>
  )
}

export default Email
