import React from 'react'
import { useIntl } from 'react-intl'
import { useAppConfig, GET_CONFIG } from '_application'
import { UPDATE_LANGUAGE } from './graph-queries'
import { useMutation } from '@apollo/client'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../shared/styles/sections'
import { Selector } from '../../shared/styles/selector'
import Loader from '../../shared/components/loader'

const languages = ['en_GB', 'en_US', 'nb_NO', 'nn_NO']

const Language = () => {
  const intl = useIntl()
  const config = useAppConfig()

  const [updateLanguage, { loading: languageLoading }] = useMutation(
    UPDATE_LANGUAGE
  )

  const renderMessageText = (value) =>
    intl.formatMessage({ id: `admin.settings.general.languages.${value}` })

  const handleLanguageSelect = (e) => {
    updateLanguage({
      variables: { language: e?.target?.value },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const defaultLocale = config?.defaultLocale || 'en_GB'

  return (
    <MainSection>
      <span className="title">{renderMessageText('title')}</span>
      <Row>
        <Col sm={12} md={6}>
          <Selector
            value={defaultLocale}
            onChange={(e) => handleLanguageSelect(e)}
          >
            {languages.map((l) => (
              <option key={`language-code-${l}`} value={l}>
                {intl.formatMessage({ id: `global.languages.${l}` })}
              </option>
            ))}
          </Selector>
        </Col>
      </Row>
      {languageLoading && <Loader />}
    </MainSection>
  )
}

export default Language
