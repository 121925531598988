import React from 'react'
import { connect } from 'react-redux'
import {
  Recomendation,
  People,
  Activity,
  Company,
  Opportunity,
  EntityConnection,
  Event,
} from './notifications'

import { NotificationsContent } from './styles/main-content'
import NoContent from './no-content'
import ApplicationManagerInvitation from './notifications/opportunity-application-manager-invitation'
import { OpportunityApplicationMessage } from './notifications/opportunity-application-message'
import { ForumMessage } from './notifications/forum'
import { ContentStudioNotification } from './notifications/content-studio'

const mapStateToProps = ({ user }) => ({ user })

const MainContent = ({
  sorting,
  data,
  acceptAction,
  declineAction,
  deleteAction,
  user,
}) => {
  const notifications = sorting
    ? data[sorting]
    : [].concat.apply(
      [],
      Object.keys(data).map((k) => data[k])
    )

  return (
    <NotificationsContent>
      {(!notifications || notifications?.length <= 0) && <NoContent />}
      {notifications?.map((item) => {
        const type = item.notification.subtype

        if (type.includes('recommend')) {
          return <Recomendation deleteAction={deleteAction} item={item} />
        } else if (type === 'p2p-request') {
          return (
            <People
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
            />
          )
        } else if (type === 'p2a-request' || type === 'a2p-request') {
          return (
            <Activity
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
              isOwnerToPersonConnection={type === 'a2p-request'}
            />
          )
        } else if (type === 'p2c-request') {
          return (
            <Company
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
            />
          )
        } else if (type === 'o2p-request') {
          return (
            <Opportunity
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
              isOwnerToPersonConnection={type === 'o2p-request'}
            />
          )
        } else if (type === 'e2p-request') {
          return (
            <Event
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
              isOwnerToPersonConnection={type === 'e2p-request'}
            />
          )
        } else if (type === 'e2c-request') {
          return (
            <Event
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
            // isOwnerToPersonConnection={type === 'e2p-request'}
            />
          )
        } else if (type === 'e2a-request') {
          return (
            <Event
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
            // isOwnerToPersonConnection={type === 'e2p-request'}
            />
          )
        } else if (type === 'opportunity-management-invitation') {
          return (
            <ApplicationManagerInvitation
              acceptAction={acceptAction}
              deleteAction={deleteAction}
              item={item}
              isOwnerToPersonConnection={false}
            />
          )
        } else if (type === 'application-applicant-messaging') {
          return (
            <OpportunityApplicationMessage
              deleteAction={deleteAction}
              item={item}
            />
          )
        } else if (
          type === 'a2c-request' ||
          type === 'o2c-request' ||
          type === 'o2a-request' ||
          type === 'a2o-request'
        ) {
          return (
            <EntityConnection
              acceptAction={acceptAction}
              declineAction={declineAction}
              item={item}
            />
          )
        } else if (type === 'forum') {
          return <ForumMessage
            item={item}
            deleteAction={deleteAction}
          />
        } else if (type.includes('ownership-change')) {
          return <ContentStudioNotification
            item={item}
            deleteAction={deleteAction}
          />
        }else {
          return null
        }
      })}
    </NotificationsContent>
  )
}

export default connect(mapStateToProps)(MainContent)
