import React from 'react'
import {
  CoverPhotoWrapper,
  CoverPhoto,
  Avatar,
  ListItemStyled,
  OnlineIndicator,
} from '../styles'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { css } from '@emotion/core'

const intlKey = 'application.management.'

export const OpportunityInfo = ({ opportunity, applicationCount }) => {
  const intl = useIntl()

  const totalCountLabel = `${intl.formatMessage({
    id: `${intlKey}applicants`,
  })}: ${applicationCount}`

  const displayDaysLeft = () => {
    const expire = opportunity?.expire
    if (expire) {
      const isFutureDate = moment(opportunity?.expire) > moment()
      const daysLeft = moment(opportunity?.expire).diff(moment(), 'days')
      let expireLabel = intl.formatMessage({ id: `${intlKey}expired` })
      if (isFutureDate && daysLeft > 0) {
        expireLabel = daysLeft
      }
      return (
        <span className="item-expire-days">
          {`${intl.formatMessage({
            id: `${intlKey}daysleft`,
          })}: ${expireLabel}`}
        </span>
      )
    }
    return <></>
  }

  const isExpired =
    opportunity?.expire && moment(opportunity?.expire) < moment()

  return (
    <ListItemStyled
      css={css`
        margin-bottom: 40px;
      `}
    >
      <CoverPhotoWrapper>
        {opportunity && (
          <div className="online-indicator-wrapper">
            <OnlineIndicator
              opportunityState={isExpired ? 'expired ' : 'active'}
              isOnCoverPhoto
            />
            <span>{isExpired ? 'Expired' : 'Live'} </span>
          </div>
        )}
        <CoverPhoto src={opportunity?.coverPhoto} alt={opportunity?.name} />
        <Avatar
          isOnCoverPhoto
          src={opportunity?.creator?.avatar}
          alt={opportunity?.creator?.firstName}
        />
      </CoverPhotoWrapper>
      <div className="item-details">
        <h3 className="item-name">{opportunity?.name}</h3>
        <p className="item-desc">{opportunity?.description}</p>
        <div>
          {displayDaysLeft()}
          {applicationCount >= 0 && (
            <span className="item-applicants-number">{totalCountLabel}</span>
          )}
        </div>
      </div>
    </ListItemStyled>
  )
}
