import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import { useIntl } from 'react-intl'
import Toggle from 'components/indy/shared/ui-kit/buttons/toggle-button-with-name-alt'
import { useAppConfig, GET_CONFIG } from '_application'
import { UPDATE_BLOG_SUBSCRIPTION_ACCESS } from './graph-queries'
import { useMutation } from '@apollo/client'

const Dashboard = () => {
  const intl = useIntl()
  const config = useAppConfig()

  const [updateBlogSubscriptionAccess] = useMutation(
    UPDATE_BLOG_SUBSCRIPTION_ACCESS
  )

  const renderMessageText = (value) =>
    intl.formatMessage({
      id: `admin.sitestructure.blog.subscription.${value}`,
    })

  const handleAction = (checked) => {
    updateBlogSubscriptionAccess({
      variables: { isBlogSubscriptionAccessEnabled: checked },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const { isBlogSubscriptionAccessEnabled } = config

  return (
    <MainSection>
      <Row>
        <Col sm={12} md={12}>
          <Toggle
            title={renderMessageText('title')}
            description={renderMessageText('description')}
            statusText={renderMessageText('status')}
            checked={isBlogSubscriptionAccessEnabled}
            onChange={(checked) => handleAction(checked)}
          />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
