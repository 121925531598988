import styled from '@emotion/styled'
import border from '_utils/css-border'
import { tablet, pablet, mobile } from '_utils/responsive-queries'

export const Content = styled.div`
  background-color: var(--background) !important;
  min-height: 100vh;
  padding: 180px 50px 100px;

  ${tablet} {
    padding: 180px 0px 100px;
    .container {
      max-width: 100vw;
    }
  }

  .tab-title {
    font-size: 34px;
    color: var(--foreground);
    font-weight: 600;
  }

  &.profile-container {
    max-width: 100vw;
    width: 1200px;
    margin: 0 auto;
    padding: 180px var(--space);

    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--space-m);

    ${pablet} {
      padding: 180px 0px;
      grid-template-columns: 1fr;
      gap: 0px;
    }

    .route-navigation {
      min-width: 300px;
      width: 100%;
      ${mobile} {
        min-width: 0px;
      }
    }
  }
`

export const MainWrapper = styled.div`
  overflow: visible;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  margin-bottom: var(--space);
  color: var(--foreground);
  background: var(--background);

  position: relative;
  width: 100%;

  ${pablet} {
    overflow: initial;
    box-shadow: none;
    section { 
      padding: var(--space);
      padding-bottom: var(--space-m);
    }
  }

  .section-title {
    position: relative;
    font-size: 22px;
    font-family: ${(props) => props.theme.fonts.profileSectionTitle};
  }

  &.general-info {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
`

export const Section = styled.section`
  padding: var(--space-l);
  padding-bottom: var(--space-xl);
  background: var(--background);

  span.field-title {
    font-weight: 700;
  }
`

export const Field = styled.div`
  .field-title {
    display: block;
    font-size: 14px;
    color: var(--foreground);
    font-weight: 500;
    margin: 20px 0 10px;
  }
  .file-details {
    color: var(--foreground);
  }

  input[type='email']:disabled {
    background: #e7e5e5;
  }
`

export const BackgroundImageStyled = styled.div`
  position: relative;
  height: 300px;

  img {
    position: relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: calc(4 * var(--radius));
    border-top-right-radius: calc(4 * var(--radius));

    ${pablet} {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  button.upload-regular {
    top: 30px;
    right: 50px;
  }
`

export const TitleStyle = styled.div`
  .section-title {
    font-size: 24px;
    color: var(--foreground);
  }
`

export const ShowHideButton = styled.button`
  background: var(--shade);
  border: none;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  i {
    font-size: 25px;
    color: var(--foreground);
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const UserTile = styled.div`
  ${border()}
  position: relative;
  margin-left: 50px;
  margin-top: -165px;
  background-color: var(--background);
  width: 260px;
  padding: 30px 20px;
`

export const ImageWrapper = styled.div`
  position: relative;
  display: table;
  margin: 0 auto;
  .image-wrapper {
    ${border()}
    width: 164px;
    height: 164px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`

export const UserName = styled.div`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  word-break: break-word;
`

export const Status = styled.div`
  text-align: center;
  font-size: 16px;
`

export const Location = styled.div`
  margin-top: 9px;
  text-align: center;
  font-size: 12px;
`
