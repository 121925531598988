import React from 'react'
import GeneralTaxonomy from './components'

const Index = ({
  taxonomy,
  selectedValues,
  onSelectCallback,
  height,
  isReadOnly,
}) => {
  return (
    <GeneralTaxonomy
      taxonomy={taxonomy}
      selectedValues={selectedValues}
      onSelectCallback={onSelectCallback}
      height={height}
      isReadOnly={isReadOnly}
    />
  )
}

export default Index
