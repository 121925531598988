import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USERS } from './graph-queries'

import Statistics from './statistics'
import Map from './map'

const Dashboard = () => {
  const { data: users, error } = useQuery(GET_USERS)
  const usersList = users && !error ? users.getAllUsers.data : []

  return (
    <div>
      <Statistics users={usersList} />
      <Map users={usersList} />
    </div>
  )
}

export default Dashboard
