import * as types from './types'

const initialState = {
  activePage: null,
}

export default function userReducer(state = initialState, payload) {
  switch (payload.type) {
    case types.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload.page,
      }
    default:
      return state
  }
}
