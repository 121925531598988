import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node
}

export const SDKContainer = themable('SDKContainer', Props)
export default SDKContainer

