import React from 'react'
import ContentSection from './content-section'
import { useQuery, gql } from '@apollo/client'

const StaticPage = ({ id }) => {
  const GET_PAGE = gql`
    query {
      getSystemPage(type: "${id}") {
        title
        description
        subtitle
        body
        type
      }
      getPage(id: "${id}") {
        title
        description
        subtitle
        body
      }
    }
  `
  const { data } = useQuery(GET_PAGE)

  const page = data?.getSystemPage || data?.getPage || []

  return <ContentSection page={page} />
}

export default StaticPage
