import {
  ActivityDefaultTile,
  ActivityFullBackgroundTile,
} from './activity-tiles'
import { OpportunityDefaultTile } from './opportunity-tiles'
import { CompanyDefaultTile } from './company-tiles'
import { PeopleDefaultTile } from './people-tiles'
import { BlogDefaultTile } from './blog-tiles'
import { EventDefaultTile } from './event-tiles'

export {
  ActivityDefaultTile,
  ActivityFullBackgroundTile,
  OpportunityDefaultTile,
  CompanyDefaultTile,
  PeopleDefaultTile,
  BlogDefaultTile,
  EventDefaultTile,
}

export default {
  ActivityDefaultTile,
  ActivityFullBackgroundTile,
  OpportunityDefaultTile,
  CompanyDefaultTile,
  PeopleDefaultTile,
  BlogDefaultTile,
  EventDefaultTile,
}
