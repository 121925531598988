import React /* , { useState, useEffect } */ from 'react'

import { isEmpty } from 'lodash'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import { useIntl } from 'react-intl'
import { nextPage, mergePage } from '_utils/graph-pagination'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BlogGrid from '../components/blog-grid'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'

import { useAppConfig } from '_application'
import CategoryTab from '../components/category-tab-grid'
import styledComponents from './styled'

const GET_CATEGORIES = gql`
  query {
    getPostCategories {
      id
      title
    }
  }
`

const {
  Wrapper,
  /* ButtonsContainer,
  TagsContainer,
  Category,
  LoadButton,
  DiscoverButtonWrapper,
  DiscoverAllButton */
} = styledComponents

const updateQuery = mergePage('getPostsPublic')
/*
const Categories = ({ categories, selected, onSelect }) => {
  const intl = useIntl()
  const [showAllTags, setShowAllTags] = useState(false)
  const [activeTag, setActiveTag] = useState(null)
  let location = useLocation()

  const tagsToDisplay = showAllTags ? categories : categories?.slice(0, 5)

  const showTitle = (title) => {
    if (title) {
      return `${title.substring(0, 20)}..`
    }
  }

  const onSelectTagg = (taggId) => {
    setActiveTag(taggId)
    onSelect(taggId)
  }

  const onSelectAll = () => {
    setActiveTag(null)
    onSelect(null)
  }

  useEffect(() => {
    let taggID = location.search.trim().split('=').pop()
    setActiveTag(taggID)
  }, [location.search])

  useEffect(() => {
    console.log('ff: ', activeTag)
  }, [activeTag])

  return (
    <ButtonsContainer>
      <TagsContainer>
        <DiscoverButtonWrapper>
          <DiscoverAllButton
            className={activeTag ? 'active' : ''}
            active={!selected}
            onClick={() => onSelectAll()}
            data-testid="btn-discoverAll"
          >
            <p>
              <FormattedMessage id="discover.discoverall" />
            </p>
          </DiscoverAllButton>
        </DiscoverButtonWrapper>
        {tagsToDisplay.map((category) => (
          <Category
            className={
              (activeTag === category.id ? 'active' : '') + ' category'
            }
            active={category.id === selected}
            onClick={() => onSelectTagg(category.id)}
          >
            <p>
              {category.title && category.title.length > 20
                ? showTitle(category.title)
                : category.title}
            </p>
          </Category>
        ))}
        {categories.length > 5 && (
          <LoadButton onClick={() => setShowAllTags(!showAllTags)}>
            <p>
              {showAllTags
                ? intl.formatMessage({ id: 'global.showless.button' })
                : intl.formatMessage({ id: 'global.loadmore.button' })}
              <i className={`fas fa-chevron-${showAllTags ? 'up' : 'down'}`} />
            </p>
          </LoadButton>
        )}
      </TagsContainer>
    </ButtonsContainer>
  )
} */

const BlogConnected = ({
  setCategory,
  category,
  postsData,
  postsError,
  fetchMore,
}) => {
  const { blogCategories } = useAppConfig()

  const { data: categoriesData, error: categoriesError } =
    useQuery(GET_CATEGORIES)

  const { data, hasMore } = postsData?.getPostsPublic || {}

  return (
    <Wrapper>
      <CategoryTab
        categories={blogCategories}
        selected={category}
        onSelect={setCategory}
        discoverAllPath={'/blog'}
      />
      <Loader
        loading={!postsData || !categoriesData}
        error={postsError || categoriesError}
      >
        {() =>
          isEmpty(data) ? null : (
            <DashboardGridTemp>
              <BlogGrid
                posts={data}
              />
            </DashboardGridTemp>
          )
        }
      </Loader>
      <FetchMore
        hasMore={hasMore}
        loader={fetchMore}
        filter={category}
        offset={data?.length}
      />
    </Wrapper>
  )
}

const { LoadMoreContainer, LoadMoreButton } = styledComponents

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(offset),
                filter,
                updateQuery,
              })
            }
          >
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i className="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}

export default BlogConnected
