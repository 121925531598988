import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import Button from '../../../shared/ui-kit/buttons/single-button'
import { BackgroundImageStyled } from '../styles'
import { useMediaUploader } from '../../../shared/media-uploader'
import { MbToB } from 'services/file'
import { useUser } from '_security/session-hooks'
import { useAppConfig } from '_application'

const mapStateToProps = ({ user }) => ({ user })

const BackgroundImage = ({ user, dispatch, children }) => {
  const { sub } = useUser()
  const { tenantId } = useAppConfig()
  const intl = useIntl()
  const handleImageUpload = (imgUrl, type) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: imgUrl },
      },
    })
  }

  const uploadConfig = {
    folderName: 'user-profile',
    tagsConfig: {
      userId: sub,
      tenantId: tenantId,
      context: 'user-profile-covers',
    },
    maxImageFileSize: MbToB(2),
    maxImageWidth: 2000,
    mediaContainerType: 'profileCoverImg',
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const { showWidget } = useMediaUploader(uploadConfig, (img) =>
    handleImageUpload(img?.url, 'coverPhoto')
  )

  return (
    <BackgroundImageStyled>
      <div>
        <img
          src={user.profile.coverPhoto}
          alt="Profile coverphoto"
          data-testid="about-img-background"
        />
        <Button
          type="upload-regular"
          onClick={() => showWidget()}
          testid="about-background"
        >
          <i className="fas fa-camera" />
          <span>
            {intl.formatMessage({ id: 'global.background.changecover' })}
          </span>
        </Button>
      </div>
      {children}
    </BackgroundImageStyled>
  )
}

export default connect(mapStateToProps)(BackgroundImage)
