import React from 'react'
import { useAppConfig } from '_application'
import { UPDATE_META_DATA } from './graph-queries'
import { useMutation } from '@apollo/client'

import Input from 'components/indy/shared/ui-kit/inputs/text'
import { SubSection } from '../../shared/styles/sections'

// import { useIntl } from 'react-intl'

const LogoShared = ({ type, title, description }) => {
  const config = useAppConfig()
  const meta = config?.metaData ? config?.metaData[type] : null
  // const intl = useIntl()

  const [updateMetaData] = useMutation(UPDATE_META_DATA)

  const handleUpdateLabel = (v) => {
    updateMetaData({
      variables: { metaData: { [type]: v } },
    })
  }

  const checkValue = (value) => {
    return value && value !== 'undefined' ? value : null
  }

  return (
    <SubSection>
      <span className="title">{checkValue(title)}</span>
      <span className="description">{checkValue(description)}</span>
      <Input
        input={{ placeholder: `Provide ${checkValue(title)}`, value: meta }}
        onBlurCallback={(v) => handleUpdateLabel(v)}
      />
    </SubSection>
  )
}

export default LogoShared
