import React from 'react'
import { connect } from 'react-redux'

import RemoveModal from '../shared/modals/remove'

const mapStateToProps = ({ user }) => ({ user })

const Modal = ({ user, deleteEducation, onClose, dispatch }) => {
  const handleDelete = (props) => {
    const updates = user.profile.education.filter(
      (c) => !c.key.includes(props.key)
    )
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, education: updates },
      },
    })
    onClose()
  }

  return (
    <RemoveModal
      title={'Education'}
      defaultProps={deleteEducation}
      user={user}
      onDelete={(props) => handleDelete(props)}
      onClose={() => onClose()}
    />
  )
}

export default connect(mapStateToProps)(Modal)
