import actions from './messages-actions'

const initialState = {
  currentConversation: null,
  currentConversationMessages: [],
  conversations: [],
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actions.SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: payload,
      }
    case actions.SET_CURRENT_CONVERSATION_MESSAGES:
      const { messages, contextKey } = payload
      return contextKey
        ? {
            ...state,
            [contextKey + '-messages']: messages,
          }
        : {
            ...state,
            currentConversationMessages: messages,
          }
    case actions.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: payload,
      }
    case actions.READ_CONVERSATION_LAST_MESSAGE: {
      const { messageId } = payload
      const conversations = state.conversations
      const conversationIndex = conversations.findIndex(
        (c) => c.lastMessage?._id === messageId
      )
      if (conversationIndex < 0) return state

      const targetConversation = conversations[conversationIndex]
      const newConversations = conversations.slice()
      newConversations.splice(conversationIndex, 1, {
        ...targetConversation,
        lastMessage: { ...targetConversation.lastMessage, read: true },
      })

      // updating denormalized store-data is super messy
      return {
        ...state,
        conversations: newConversations,
      }
    }

    default:
      return state
  }
}
