import React from 'react'
import PropTypes from 'prop-types'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'
import { Grid, BaseTile } from '_tiles'
import { Default as PeopleTile } from './tiles'
import { filterToQueryString } from '_utils/filters'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 3,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const PeopleGrid = ({ people, filter, refetchQueries }) => {
  const queryString = filterToQueryString(filter)
  return (
    <DashboardGridTemp>
      <Grid config={gridConfig} testid="people">
        {people.map((people) => {
          const {
            person,
            connectButton,
            connections,
            connectionsLoading,
            disconnectButton,
          } = people
          return (
            <BaseTile
              key={person.id}
              url={`/people/list/${person.id}`}
              queryParams={queryString}
              refetchQueries={refetchQueries}
              config={defaultConfig}
              testid="person"
            >
              <PeopleTile
                person={person}
                connectButton={connectButton}
                disconnectButton={disconnectButton}
                connections={connections}
                connectionsLoading={connectionsLoading}
              />
            </BaseTile>
          )
        })}
      </Grid>
    </DashboardGridTemp>
  )
}

PeopleGrid.propTypes = {
  companies: PropTypes.array.isRequired,
}

export default PeopleGrid
