import React, { useState } from 'react'

import { IndexStyled } from './styles/index'
import PluginList from './plugins'
import ManageModal from './manage-modal'

const ManagePlugins = () => {
  const [currentPlugin, setCurrentPlugin] = useState()

  return (
    <IndexStyled>
      <PluginList setCurrentPlugin={setCurrentPlugin} />
      {currentPlugin && (
        <ManageModal
          onClose={() => setCurrentPlugin(null)}
          currentPlugin={currentPlugin}
        />
      )}
    </IndexStyled>
  )
}

export default ManagePlugins
