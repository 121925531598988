import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'

export const StyledModal = styled(Modal)`
  /* * {
    color: var(--foreground) !important;
  } */
  h3.modal-title {
    margin-bottom: 30px;
    color: var(--foreground);
  }

  div.data {
    span.section-title {
      display: block;
      margin-bottom: 15px;
    }
  }
  div.toggle-options {
    float: right;
    margin-top: 30px;
    div {
      display: flex;
      justify-content: space-between;
    }
  }
  div.modal-content {
    padding: 10px 25px;
    background-color: var(--background);
    input {
      color: var(--foreground);
      opacity: 0.8;
      border: 1px solid var(--foreground);
      &::placeholder {
        color: var(--shade);
      }
    }
  }
  input {
    background-color: transparent;
    color: var(--shade);
    border: 1px solid var(--foreground);
    &::placeholder {
      color: var(--shade);
    }
  }
  .toggle-options {
    div {
      span {
        color: var(--foreground);
      }
    }
  }
  .rst__rowContents {
    background-color: transparent;
    .rst__toolbarButton {
      background-color: transparent;
    }
  }
  .type-selector {
    margin-bottom: 15px;
    select {
      margin: 0 10px;
      padding: 5px 15px;
      border-radius: 4px;
      background-color: transparent;
      option {
        background-color: var(--background);
        color: var(--foreground);
      }
    }
  }
`
