import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useMutation, gql } from '@apollo/client'

import ReactTooltip from 'react-tooltip'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { sendNotification } from './message-helper'

import ButtonRecommend from './styles/recommend-button'
import {
  SingleResult,
  ResultWrapper,
  InputStyled,
  ItemWrapper,
} from './styles/basic-input-dropdown-styles'
import { useIntl } from 'react-intl'

const resolveRecommendType = (queryKey) => {
  switch (queryKey) {
    case 'activityToRecommend':
      return 'p2a-recommend'
    case 'opportunityToRecommend':
      return 'p2o-recommend'
    case 'blogToRecommend':
      return 'p2b-recommend'
    case 'companyToRecommend':
      return 'p2c-recommend'
    case 'userToRecommend':
      return 'p2p-recommend'
    default:
      return 'p2d-recommend'
  }
}

const RECOMMEND_DOCUMENT_MUTATION = gql`
  mutation RecommendDocumentToUser(
    $documentToRecommend: String!
    $recipient: String!
    $recommendType: String!
    $isPrivate: Boolean
  ) {
    recommendDocumentToUser(
      documentToRecommend: $documentToRecommend
      recipient: $recipient
      recommendType: $recommendType
      isPrivate: $isPrivate
    ) {
      sub
    }
  }
`

const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      acceptedUserConnections {
        status
        owner
        connectedUser {
          name
          sub
          avatar
        }
      }
    }
  }
`

const RecommendButton = ({
  contentToRecomment,
  queryKey = 'userToRecommend',
  isPrivate,
}) => {
  const intl = useIntl()
  const [showInput, setShowInput] = useState(false)
  const [query, setQuery] = useState('')
  const { user } = useSelector((state) => state)
  const [people, setPeople] = useState([])

  const { data } = useQuery(GET_USERS_CONNECTIONS, {
    variables: {
      ids: [user.sub],
    },
  })

  const { acceptedUserConnections: connectedUsers } = data
    ?.getUserConnectionsPublic[0]
    ? data?.getUserConnectionsPublic[0]
    : ''

  const [recommend, { loading }] = useMutation(RECOMMEND_DOCUMENT_MUTATION)

  const addButton = intl.formatMessage({
    id: 'default.add.button',
  })
  const searchPlaceholder = intl.formatMessage({
    id: 'search.recommend.placeholder',
  })

  useEffect(() => {
    setPeople(
      connectedUsers?.filter(
        (u) =>
          query?.length > 0 &&
          `${u?.connectedUser[0]?.name}`
            ?.toLowerCase()
            ?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [connectedUsers, query, setPeople])

  const onClose = () => {
    if (showInput) setShowInput(false)
  }

  const onRecommend = (person) => {
    const { sub, name } = person?.connectedUser[0]
    const recommendType = resolveRecommendType(queryKey)

    recommend({
      variables: {
        documentToRecommend: contentToRecomment,
        recipient: sub,
        recommendType: recommendType,
        isPrivate,
      },
    })
    setShowInput(false)
    sendNotification({
      category: 'activity',
      type: 'recommendation',
      intl,
      name,
    })
  }

  const ref = useClickOutside(onClose)

  if (showInput) {
    return (
      <ItemWrapper className="inner-suggestions-container">
        <div className="input-container" ref={ref}>
          <InputStyled
            className="input-field"
            placeholder={searchPlaceholder}
            type="text"
            onKeyUp={(e) => setQuery(e.target.value)}
            onBlur={() => people?.length === 0 && setShowInput(false)}
            autoFocus
          />
          {people?.length > 0 && (
            <ResultWrapper>
              {people?.map((p) => {
                return (
                  <SingleResult>
                    <span className="name">{p?.connectedUser[0]?.name}</span>
                    <ButtonRecommend
                      className="add-button"
                      type="button"
                      disabled={loading}
                      onClick={() => onRecommend(p)}
                    >
                      {addButton}
                    </ButtonRecommend>
                  </SingleResult>
                )
              })}
            </ResultWrapper>
          )}
        </div>
      </ItemWrapper>
    )
  }

  if (user.authorized) {
    return (
      <ItemWrapper className="recommend-button-wrapper">
        <ButtonRecommend
          data-for="recommenduser"
          data-tip={intl.formatMessage({
            id: 'people.details.recommenduser.tooltip',
          })}
          onClick={() => setShowInput(true)}
        >
          <span className="circle-background">
            <i class="far fa-star" />
          </span>
        </ButtonRecommend>
        <ReactTooltip
          effect="solid"
          id="recommenduser"
          place="bottom"
          className="recommendTooltip"
        />
      </ItemWrapper>
    )
  }

  return null
}

export default RecommendButton
