import { gql } from '@apollo/client'

export const CONNECTED_ACTIVITIES = gql`
  query GetEEventConnectedActivitiesPaged($id: ID!, $paging: PagingInput) {
    getEEventConnectedActivitiesPaged(id: $id, paging: $paging) {
      hasMore
      count
      data {
        id
        uid
        name
        description
        creator {
          id
          sub
          name
          avatar
          firstName
          middleName
          lastName
        }
        coverPhoto
        location
        connectedUsers {
          id
          sub
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedOpportunities {
          id
          coverPhoto
        }

        connectionStatus
        start
        end
        _createdAt
      }
    }
  }
`

export const CONNECTED_SPEAKERS = gql`
  query GetEEventConnectedSpeakersPaged($id: ID!, $paging: PagingInput) {
    getEEventConnectedSpeakersPaged(id: $id, paging: $paging) {
      hasMore
      count
      data {
        id
        sub
        name
        firstName
        middleName
        lastName
        fullName
        avatar
        coverPhoto
        bioShort
        location
        connectionStatus
      }
    }
  }
`

export const CONNECTED_COMPANIES = gql`
  query GetEEventConnectedCompaniesPaged($id: ID!, $paging: PagingInput) {
    getEEventConnectedCompaniesPaged(id: $id, paging: $paging) {
      hasMore
      count
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        connectionStatus
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const GOING_USERS = gql`
  query GetGoingUsers($eventId: ID!, $paging: PagingInput) {
    getGoingUsers(eventId: $eventId, paging: $paging) {
      hasMore
      count
      data {
        id
        sub
        name
        firstName
        lastName
        avatar
        location
        connectionStatus
        bioShort
      }
    }
  }
`

export const INTERESTED_USERS = gql`
  query GetInterestedUsers($eventId: ID!, $paging: PagingInput) {
    getInterestedUsers(eventId: $eventId, paging: $paging) {
      hasMore
      count
      data {
        id
        sub
        name
        firstName
        lastName
        bioShort
        avatar
        connectionStatus
        location
      }
    }
  }
`
