import React from 'react'
import { Modal, ModalVariant } from '@patternfly/react-core'
import styled from '@emotion/styled'
import { ctaButton } from '_utils/css-buttons'

const ModalStyled = styled(Modal)`
  background-color: var(--background);
  color: var(--foreground);
  border-radius: 25px;
  .pf-c-modal-box__footer {
    display: flex;
    flex-direction: row-reverse;
  }
`
const ConfirmButton = styled.button`
  ${ctaButton}
`
const CancelButton = styled.button`
  padding: 0 20px;
  color: var(--foreground);
  border: none;
  background-color: transparent;
  text-decoration: underline;
  &:hover {
    opacity: 0.6;
  }
`

const ModalIndex = ({
  isOpen,
  onClose,
  onConfirm,
  cancelButtonLabel,
  confirmButtonLabel,
  title,
  content,
}) => {
  return (
    <ModalStyled
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      variant={ModalVariant.small}
      actions={[
        <ConfirmButton
          onClick={onConfirm}
          data-testid={`btn-modal-${confirmButtonLabel}`}
        >
          {confirmButtonLabel}
        </ConfirmButton>,
        <CancelButton
          type="neutral"
          onClick={onClose}
          data-testid={`btn-modal-${cancelButtonLabel}`}
        >
          {cancelButtonLabel}
        </CancelButton>,
      ]}
    >
      {content}
    </ModalStyled>
  )
}

export default ModalIndex
