import React from 'react'
import { Tab, TabTitleText } from '@patternfly/react-core'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { StyledTabs } from './styles/tabs'

const getSelected = (categories, childId) => {
  if (categories && childId) {
    for (const category of categories) {
      if (category.id === childId) {
        return childId
      }
      if (category?.subcategories) {
        for (const subcategory of category?.subcategories) {
          const subCategoryExistAndMatchChildId =
            subcategory && subcategory?.id && subcategory.id === childId
          if (subCategoryExistAndMatchChildId) {
            return subcategory.parentId
          }
        }
      }
    }
  }
  return 0
}

const CategoryTab = ({ categories, selected, onSelect, discoverAllPath }) => {
  const handleTabClick = (event, tabIndex) => {
    onSelect(tabIndex)
  }

  if (!categories || categories?.length <= 0) {
    return <></>
  }

  return (
    <StyledTabs
      activeKey={getSelected(categories, selected)}
      onSelect={handleTabClick}
      className="pf-m-no-border-bottom"
    >
      <Tab
        eventKey={0}
        title={
          <TabTitleText>
            <Link
              data-testid="btn-discoverAll"
              className="tag-link"
              to={discoverAllPath}
            >
              <FormattedMessage id="discover.discoverall" />
            </Link>
          </TabTitleText>
        }
      ></Tab>

      {categories &&
        categories.map((category) => {
          return (
            <Tab
              data-testid={`btn-category-${category?.name}`}
              eventKey={category?.id}
              title={<TabTitleText>{category?.name}</TabTitleText>}
            >
              {category?.subcategories && category?.subcategories?.length > 0 && (
                <StyledTabs
                  subcategories
                  activeKey={selected}
                  onSelect={handleTabClick}
                  className="pf-m-no-border-bottom"
                >
                  {category?.subcategories?.map((subcategory) => {
                    return (
                      <Tab
                        eventKey={subcategory?.id}
                        title={<TabTitleText>{subcategory?.name}</TabTitleText>}
                      ></Tab>
                    )
                  })}
                </StyledTabs>
              )}
            </Tab>
          )
        })}
    </StyledTabs>
  )
}

export default CategoryTab
