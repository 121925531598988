import styled from '@emotion/styled'

export const AddButton = styled.button`
  padding: 6px 10px;
  border: none;
  margin-top: 14px;
  background: ${(props) => props.theme.colors.primaryColor};
  color: ${(props) => props.theme.colors.whiteColor};
`

export const Items = styled.div`
  margin-left: 16px;
`

export const Item = styled.div`
  margin: 12px 0;
  display: block;
  .content {
    width: 70%;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    input {
      width: 100%;
      padding: 2px 10px;
      border: 1px solid #ccc;
    }
  }
  .controls {
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
    i {
      padding: 4px;
      font-size: 13px;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .children {
    margin-left: 22px;
  }
`
