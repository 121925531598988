import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { mobile } from '_utils/responsive-queries'
const DetailsContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
  ${mobile} {
    display: none;
  }
`
const Title = styled.span`
  display: block;
  width: 100%;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: 700;
`

const Icon = styled.i`
  padding: 0 5px;
`

const renderItem = ({ value, type }) => {
  switch (type) {
    case 'socialMedia':
      return <Icon alt={value.name} className={value.icon} />
    default:
      return null
  }
}
const AdditionalInfo = ({ data, name, type }) => {
  const intl = useIntl()
  const title = intl.formatMessage({
    id: `user.onboarding.profilepreview.${name
      .replace(/\s/g, '.')
      .toLowerCase()}`,
  })

  return (
    <DetailsContainer>
      <Title>{data.length > 0 && title}</Title>
      {data.map((d) => renderItem({ value: d, type }))}
    </DetailsContainer>
  )
}

export default AdditionalInfo
