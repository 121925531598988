import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import { mobile } from '_utils/responsive-queries'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    ${ctaButtonHollow()}
  }
  ${mobile} {
    margin-top: 20px;
  }
`
