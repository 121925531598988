import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ReportModal from 'components/indy/shared/report-modal'
import FooterThemed from '_uikit/footer'

import { useLocation } from 'react-router-dom'
import SanitiseHTML from "helpers/sanitize-html.js"

const getAppConfig = gql`
  query GetAppConfig {
    getAppConfig {
      leftFooterContent
      footerContent
    }
  }
`

const GET_PAGES = gql`
  query {
    getSettings {
      companyName
      companyLogo
      footerContent
      leftFooterContent
      footerTitle
      footerNavMenus {
        menus {
          title
          links {
            link
            title
            type
          }
        }
      }
      socialMedia {
        mediaName
        mediaLogo
        link
      }
    }
  }
`

export const LegacyFooter = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const intl = useIntl()
  let location = useLocation()

  const hideFooter =
    location.pathname === '/user/messages' ||
    location.pathname === '/user/notifications-center'


  const { data } = useQuery(GET_PAGES)
  const { data: appConfig } = useQuery(getAppConfig)

  const navMenus = data?.getSettings?.footerNavMenus?.menus?.filter(
    (menu) => menu?.links?.length > 0
  )

  return (
    <>
      {hideFooter ? (
        <></>
      ) : (
        <FooterThemed>
          <section className="frontpage-footer-section">
            <div className="inner-frontpage-footer-container">
              <Row className="footer-row">
                <Col lg className="logo-col">
                  <div className="info-description text-center">
                    <SanitiseHTML html={appConfig?.getAppConfig?.leftFooterContent}/>
                  </div>
                </Col>
                <Col lg className="info-col">
                  <div className="info-description">
                    <SanitiseHTML html={appConfig?.getAppConfig?.footerContent}/>
                  </div>
                </Col>

                {navMenus?.map((menu, i, arr) => {
                  return (
                    <Col
                      key={i}
                      lg
                      className={`${
                        i === arr.length - 1 ? 'links-col last' : 'links-col'
                      }`}
                    >
                      <p className="links-title">{menu.title}</p>
                      <div className="links-container">
                        {menu?.links?.map((link, i) => {
                          return (
                            <Fragment key={i}>
                              {link.type === 'externalLink' ? (
                                <a
                                  href={link.link}
                                  key={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {link.title}
                                </a>
                              ) : (
                                <Link to={'/' + link.link} key={link.link}>
                                  {link.title}
                                </Link>
                              )}
                            </Fragment>
                          )
                        })}
                        {i + 1 === navMenus.length ? (
                          <p
                            role="button"
                            tabIndex={0}
                            onClick={() => setModalVisible(!modalVisible)}
                            data-testid="btn-report"
                          >
                            {intl.formatMessage({ id: 'footer.link.report' })}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </section>
          
          {modalVisible && (
            <ReportModal
              canShowModal={modalVisible}
              setModalVisible={setModalVisible}
            />
          )}
        </FooterThemed>
      )}
    </>
  )
}
