/* eslint-disable no-unreachable */
import React from 'react'
import { truncate, take } from 'lodash'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import { useIntl } from 'react-intl'
import { useAppConfig } from '_application'
import emptyThumbnail from 'resources/no-connections.svg'

import {
  Title,
  Container,
  Tags,
  Tag,
  Text,
  ConnectedAvatarsContainer,
  ConnectedAvatar,
  ConnectionsContainer,
  Connections,
  ConnectedWrapper,
  ConnectionsCount,
  EmptyContainer,
  EmptyIcon,
  EmptyTitle,
} from '_themes/default/components/styled/tile-section'

import scaleSanityImage from 'helpers/scaleSanityImage'

const TileTitle = ({ children }) =>
  children ? (
    <Title>{truncate(children, { length: 22, omission: '..' })}</Title>
  ) : null

export const TileSectionSeparator = () => (
  <div
    className="fas"
    css={(theme) => css`
      position: relative;
      height: 1px;
      background-color: ${theme.colors.secondaryColor};
      margin: 0 8px;
      width: 100%;

      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.colors.secondaryColor};
        background-color: var(--background);
        content: '\f3c5';
        font-size: 14px;
        height: 22px;
        width: 22px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
  />
)

export const TileText = ({ title, children, tags, truncateAt, className }) => {
  return (
    <Container className={className}>
      <TileTitle>{title}</TileTitle>
      {tags ? (
        <Tags>
          {tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Tags>
      ) : null}
      <Text>
        {truncateAt
          ? truncate(children, { length: 400, omission: '..' })
          : children}
      </Text>
    </Container>
  )
}

export const TileTextShort = (props) => <TileText {...props} truncateAt={230} />

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const TileConnections = ({
  connectedUsers,
  connectedCompanies,
  connectedActivities,
  connectedUsersCount,
  connectedCompaniesCount,
  connectedActivitiesCount,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const config = useAppConfig()

  const renderMessageCount = (number, type) => {
    if (number > 0) {
      const count = number
      const plural = count > 1 ? 'Plural' : ''
      let labelOverride = config?.labelOverrides?.[`${type}${plural}`]?.value
      const entityType =
        labelOverride ||
        intl.formatMessage({
          id: `tile.connections.${type.toLowerCase()}${plural.toLowerCase()}`,
        })

      return `${count} ${capitalize(entityType)}`
    } else {
      return ''
    }
  }
  const renderAvatars = (list, number, type) => {
    const capitalType = type?.charAt(0).toUpperCase() + type?.slice(1)
    const srcPath = type === 'avatar' ? 'avatar' : 'coverPhoto'

    return (
      <div className="avatars-wrapper">
        <ConnectedAvatarsContainer>
          {take(list, number).map((item) => (
            <ConnectedAvatar key={item.id}>
              <img
                src={
                  // downScalesImage if it is from sanity
                  item[srcPath]
                    ? scaleSanityImage({ link: item[srcPath], height: 30 })
                    : theme.images[`default${capitalType}`]
                }
                alt={capitalType}
              />
            </ConnectedAvatar>
          ))}
        </ConnectedAvatarsContainer>
      </div>
    )
  }

  return (
    <ConnectionsContainer>
      {connectedUsers ? (
        <>
          {connectedUsers.length > 0 ? (
            <Connections>
              {connectedUsers.length > 0 &&
                renderAvatars(connectedUsers, 3, 'avatar')}
              <ConnectedWrapper center={connectedUsers.length > 0}>
                <ConnectionsCount>
                  {renderMessageCount(
                    connectedUsersCount
                      ? connectedUsersCount
                      : connectedUsers?.length,
                    'people'
                  )}
                </ConnectionsCount>
              </ConnectedWrapper>
            </Connections>
          ) : null}
        </>
      ) : (
        <></>
      )}
      {connectedCompanies && connectedCompanies?.length > 0 && (
        <Connections>
          {connectedCompanies?.length > 0 &&
            renderAvatars(connectedCompanies, 3, 'Logo')}
          <ConnectedWrapper center={connectedCompanies?.length > 0}>
            <ConnectionsCount>
              {renderMessageCount(
                connectedCompaniesCount
                  ? connectedCompaniesCount
                  : connectedCompanies?.length,
                'companies'
              )}
            </ConnectionsCount>
          </ConnectedWrapper>
        </Connections>
      )}
      {connectedActivities && connectedActivities?.length > 0 && (
        <Connections>
          {renderAvatars(connectedActivities, 3, 'Logo')}
          <ConnectedWrapper center={connectedActivities?.length > 0}>
            <ConnectionsCount>
              {renderMessageCount(
                connectedActivitiesCount
                  ? connectedActivitiesCount
                  : connectedActivities?.length,
                'activities'
              )}
            </ConnectionsCount>
          </ConnectedWrapper>
        </Connections>
      )}
      {connectedCompanies?.length === 0 && connectedUsers?.length === 0 ? (
        <EmptyContainer>
          <EmptyIcon src={emptyThumbnail} />
          <EmptyTitle>
            {intl.formatMessage({ id: 'tile.connections.noconnections' })}
          </EmptyTitle>
        </EmptyContainer>
      ) : null}
    </ConnectionsContainer>
  )
}
