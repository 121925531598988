import React from 'react'

import { Row } from 'react-bootstrap'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { tablet } from '_utils/responsive-queries'

const Layout = styled.div``

const LayoutRow = styled(Row)`
  width: 100%;
  margin: 0 auto;

  ${({ type, showToc }) =>
    type === 'blog' &&
    css`
      width: ${showToc ? '100%' : '80%'};
      ${tablet} {
        width: 100%;
      } ;
    `}
`

const Container = ({ children, type, showToc }) => {
  return (
    <Layout>
      <LayoutRow showToc={showToc} type={type}>
        {children}
      </LayoutRow>
    </Layout>
  )
}

export default Container
