import React, { useState } from 'react'
import { Loading } from '_components/loading'
import { FormattedMessage, useIntl } from 'react-intl';
import css from '@emotion/css'
// import { Link } from 'react-router-dom'
import { pick } from 'lodash'
import { gql, useMutation, useQuery } from '@apollo/client'
import { StaticPage } from 'components/indy/static-embed-page'
import { StyledDiv } from './styles'
import { PageBuilderEditor, useShowSaveMessage } from '_pagebuilder/editor/page-builder-editor'
import { PageBuilderRenderer } from '_pagebuilder/renderer/page-builder-renderer'

export const Pages = () => {
  const [formVal, setFormVal] = useState({})
  // const debounceContent = useDebounce(formVal.iframeSrc, 500)
  // const iFrame = useRef(null)

  const [editModeActive, setEditModeActive] = React.useState(false)
  const [selectedPage, setSelectedPage] = React.useState(null)
  const [mode, setMode] = React.useState('pageBuilder')

  const pageBuilderRef = React.useRef()

  const { showSaveMessage } = useShowSaveMessage()

  const intl = useIntl()

  const customPages = useQuery(gql`
    query CustomPages {
      customPages {
        path
        iframeSrc
        _id
        isPageBuilder
        title
      }
    }
  `)?.data?.customPages

  const [saveCustomPageMutation] = useMutation(gql`
    mutation SaveCustomPage($customPage: CustomPageInput) {
      saveCustomPage(customPage: $customPage) {
        path
        iframeSrc
        _id
        title
      }
    }
  `)

  const [deleteCustomPageMutation] = useMutation(gql`
    mutation DeleteCustomPage($_id: String) {
      deleteCustomPage(_id: $_id) {
        path
        iframeSrc
      }
    }
  `)

  const saveCustomPage = async () => {
    let isSuccess = false

    const res = await saveCustomPageMutation({
      variables: {
        customPage: { ...formVal, isPageBuilder: mode === 'pageBuilder' },
      },
      refetchQueries: ['CustomPages'],
    })

    if (mode === 'pageBuilder') {
      isSuccess = await pageBuilderRef.current.save(
        'customPage' + res.data.saveCustomPage._id
      )
    } else {
      isSuccess = true
      showSaveMessage(true, 'custom')
    }

    if (isSuccess) {
      setEditModeActive(false)
      setSelectedPage(null)
      setFormVal({})
    }
  }

  // const onFileChange = event => {
  //   const myFile = event.target.files[0]
  //   const reader = new FileReader()

  //   reader.addEventListener('load', function(e) {
  //     setFormVal({ ...formVal, iframeSrc: e.target.result })
  //   })
  //   reader.readAsBinaryString(myFile)
  // }

  // React.useEffect(() => {
  //   iFrame.current.srcdoc = debounceContent
  // }, [debounceContent])

  return (
    <div
      css={css`
        h1,
        h2 {
          margin-bottom: 1em;
        }
        .riot-btn {
          margin-bottom: 2em;
          margin-top: 2em;
        }
      `}
    >
      <h1>
        <FormattedMessage
          defaultMessage="Custom pages"
          id="admin.customPages.customPages"
        />
      </h1>
      <button
        hidden={editModeActive}
        onClick={() => {
          setFormVal({})
          setSelectedPage(null)
          setEditModeActive(true)
          setMode('pageBuilder')
        }}
        css={css`
          span:first-word {
            text-transform: capitalize;
          }
        `}
        className="riot-btn secondary"
      >
        <i className="fa fa-plus"></i>
        <FormattedMessage
          defaultMessage="add new page"
          id="admin.customPages.addNewPage"
        />
      </button>
      <button
        hidden={!editModeActive}
        onClick={() => {
          setFormVal({})
          setSelectedPage(null)
          setEditModeActive(false)
        }}
        css={css`
          span:first-word {
            text-transform: capitalize;
          }
        `}
        className="riot-btn secondary"
      >
        <FormattedMessage
          id="cancel"
          defaultMessage="cancel"
        ></FormattedMessage>
      </button>
      <StyledDiv>
        <h2 hidden={editModeActive}>
          <FormattedMessage
            defaultMessage="Pages"
            id="admin.customPages.pages"
          />
        </h2>
        <div hidden={editModeActive}>
          {!customPages?.map && <Loading />}
          {customPages?.map(page => (
            <div
              className={`listItem ${formVal.path === page.path ? 'selected' : ''
                }`}
              onClick={() => {
                setFormVal(pick(page, 'path', 'iframeSrc'))
                setSelectedPage(page)
              }}
            >
              <div
                css={css`
                  display: grid;
                  span:last-of-type {
                    font-weight: 200;
                  }
                `}
              >
                <span>{page.title || page.path}</span>
                <span>
                  {window.location.origin}/pages/{page.path}
                </span>
              </div>

              {/* <button onClick={() => setEditModeActive(true)}>edit</button> */}
              <div
                css={css`
                  display: flex;
                  gap: 1em;
                  * {
                    text-transform: lowercase;
                  }
                `}
              >
                <button
                  onClick={e => {
                    e.stopPropagation()
                    setFormVal(pick(page, 'path', 'iframeSrc', '_id', 'title'))
                    setSelectedPage(page)
                    setMode(page.isPageBuilder ? 'pageBuilder' : 'iframeSrc')
                    setEditModeActive(true)
                  }}
                >
                  <FormattedMessage defaultMessage="edit" id="edit" />
                </button>
                <button
                  onClick={e => {
                    e.stopPropagation()
                    deleteCustomPageMutation({
                      variables: { _id: page._id },
                      refetchQueries: ['CustomPages'],
                    })
                    setFormVal({})
                    setSelectedPage(null)
                  }}
                >
                  <FormattedMessage defaultMessage="delete" id="delete" />
                </button>
              </div>
            </div>
          ))}
        </div>
        {editModeActive && (
          <div>
            <h2>
              <FormattedMessage
                defaultMessage="New Custom Page"
                id="admin.customPages.createNewPage"
              />
            </h2>
            <div
              css={css`
                row-gap: .3em;
                display: grid;
              `}
            >
              <div className={"inputsHorizontalLayout"}>
                <div
                  className={"inputFields"}
                  css={css`margin-right: 32px`}
                >
                  <label>
                    <FormattedMessage
                      defaultMessage="Page Title"
                      id="admin.customPages.modal.title"
                    />
                  </label>
                  <input
                    className={"title-input"}
                    placeholder={intl.formatMessage({
                      id: 'admin.customPages.modal.title.placeholder',
                      defaultMessage: 'Type Title'
                    })}
                    value={formVal.title}
                    onChange={e =>
                      setFormVal({
                        ...formVal,
                        title: e.target.value,
                      })
                    }
                    type="text"
                  />
                </div>
                <div className={"inputFields"}>
                  <label>
                    <FormattedMessage
                      defaultMessage="Slug"
                      id="admin.customPages.modal.slug"
                    />
                  </label>
                  <div css={css`align-items: center; display: flex;`}>
                    <span
                      css={css`margin-right: 4px`}
                      title={window.location.origin + '/pages/' + formVal.path}
                    >
                      {window.location.origin}/pages/
                    </span>
                    <input
                      className={"title-input"}
                      placeholder={
                        intl.formatMessage({
                          id: 'admin.customPages.modal.slug.placeholder',
                          defaultMessage: 'Type Slug'
                        })
                      }
                      value={formVal.path}
                      onChange={e =>
                        setFormVal({
                          ...formVal,
                          // allow the following characters in path
                          path: e.target.value.replace(/[^a-zA-Z0-9-]/, ''),
                        })
                      }
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div
                hidden={selectedPage?._id}
                css={{ display: 'flex', gap: '.5em' }}
              >
                <button
                  onClick={() => setMode('pageBuilder')}
                  className="riot-btn secondary"
                  active={mode === 'pageBuilder' ? 'true' : undefined}
                >
                  pagebuilder
                </button>
                <button
                  onClick={() => setMode('iframeSrc')}
                  className="riot-btn secondary"
                  active={mode === 'iframeSrc' ? 'true' : undefined}
                >
                  iframe-src
                </button>
              </div>
              <div />
              <div
                hidden={mode !== 'iframeSrc'}
                css={{ display: 'grid', 'grid-template-columns': '.1fr 1fr' }}
              >
                <span>iframe-src</span>
                <input
                  css={{ maxWidth: '100%' }}
                  value={formVal.iframeSrc}
                  onChange={e =>
                    setFormVal({ ...formVal, iframeSrc: e.target.value })
                  }
                  type="text"
                />
              </div>

              {/* <span>html-file</span> */}
              {/* <input onChange={onFileChange} type="file" accept=".iframeSrc" /> */}
              {/* <span>paste code</span> */}
              {/* <textarea */}
              {/*   value={formVal.iframeSrc} */}
              {/*   onChange={e => setFormVal({ ...formVal, html: e.target.value })} */}
              {/*   cols="30" */}
              {/*   rows="10" */}
              {/* ></textarea> */}
            </div>
            <button onClick={saveCustomPage} className="riot-btn secondary">
              <FormattedMessage defaultMessage="save" id="save" />
            </button>
            {mode === 'pageBuilder' && (
              <PageBuilderEditor
                ref={pageBuilderRef}
                contextId={
                  selectedPage?._id && selectedPage?.isPageBuilder
                    ? 'customPage' + selectedPage._id
                    : undefined
                }
              />
            )}
          </div>
        )}
        {formVal.iframeSrc && (
          <div>
            {/* <Link to={`/pages/${formVal.path}`}> */}
            {/*   <button */}
            {/*     css={{ 'margin-top': '1em' }} */}
            {/*     className="riot-btn secondary" */}
            {/*   > */}
            {/*     visit */}
            {/*   </button> */}
            {/* </Link> */}
            <h2 css={{ margin: '1em 0 .5em 0', 'text-align': 'center' }}>
              preview
            </h2>
            <StaticPage iframeSrc={formVal.iframeSrc} />
          </div>
        )}
        {selectedPage?.isPageBuilder && (
          <PageBuilderRenderer contextId={'customPage' + selectedPage._id} />
        )}
      </StyledDiv>
    </div>
  )
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
