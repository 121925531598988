import React from 'react'
import { Badge } from '@patternfly/react-core'
import { Keywords as ExistingKeyword } from '_keywords-and-taxonomies'
import Card from '_people/components/card'
import styled from '@emotion/styled'
import { mobile, pablet, tablet } from '_utils/responsive-queries'
import { useQuery } from '@apollo/client'
import { GET_KEYWORDS } from '../event-creator/gql'

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  /* max-width: 25%; */
  word-break: break-word;
  flex-shrink: 0;
  &.location,
  &.title-date {
    max-width: 45%;
  }
  &.file {
    align-self: center;
  }
  &.title-file {
    align-self: center;
  }
  &.title-badges {
    max-width: 100%;
  }

  ${pablet} {
    padding: 0 40px 0 15px;
    &.file {
      align-self: flex-start;
    }
  }
  ${tablet} {
    max-width: none;
  }
`

const CardStyled = styled(Card)`
  min-width: calc(50% - 10px);
  .name {
    padding-right: 0;
    &.keyword {
      padding-top: 5px;
    }
  }

  .badges {
    align-self: center;
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  ${pablet} {
    width: 100%;
    position: relative;
    i.tooltipIcon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  ${mobile} {
    .badges {
      justify-content: center;
    }
  }
`

const RenderOverride = ({ keywordList }) => {
  return (
    <>
      {keywordList.map((k, i) => {
        if (k && k?.values?.length > 0)
          return (
            <CardStyled key={i} tooltipText={k.description}>
              <div className="content-wrapper">
                <Title className="name keyword" span={3}>
                  {k.name}
                </Title>
                <div className="badges" span={9}>
                  {k.values.map((value) => (
                    <Badge className="custome-attribute">{value.value}</Badge>
                  ))}
                </div>
              </div>
            </CardStyled>
          )
        return null
      })}
    </>
  )
}

// it works anyway

const Keywords = ({ event, isPreview }) => {
  const defaultKeywordsKeys = ['competences']

  const { data: keywordData } = useQuery(GET_KEYWORDS, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['events'] },
  })

  const childrenNameResolved = isPreview ? 'childrenKeys' : 'children'
  const idNameResolved = isPreview ? 'key' : 'id'

  const defaultKeywords = event?.keywords?.filter((v) =>
    defaultKeywordsKeys.some((d) => d === v.key)
  )

  if (!keywordData) return <></>

  const allKeywords = event?.keywords?.map((keyword) => {
    return {
      key: keyword[idNameResolved],
      values: keyword[childrenNameResolved].map((child) => {
        if (!isPreview) return { type: child.__typename, value: child.id }
        const currentKeyword = keywordData?.getKeywords.find(
          (item) => item.key === keyword.key
        )

        const currentChild = currentKeyword?.keywordList?.find(
          (item) => item.id === child
        )

        return currentChild
      }),
    }
  })

  return (
    <>
      {defaultKeywords && defaultKeywords.length > 0 && (
        <>
          <ExistingKeyword
            RenderOverride={RenderOverride}
            noMainTitle
            values={defaultKeywords}
            type={'events'}
          />
        </>
      )}

      {true && (
        <ExistingKeyword
          noMainTitle
          RenderOverride={RenderOverride}
          excludeKeys={defaultKeywordsKeys}
          values={allKeywords || []}
          type={'events'}
        />
      )}
    </>
  )
}

export default Keywords
