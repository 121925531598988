import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { setActivePage } from 'redux/menu/actions'
import StaticEmbedPage from '../../components/indy/static-embed-page'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'

export const StaticEmbed = ({ history, iframeSrc }) => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setActivePage('about'))
  }, [history, dispatch])

  return (
    <NavWrapper>
      <StaticEmbedPage id={match.params.id} iframeSrc={iframeSrc} />
    </NavWrapper>
  )
}

export default withRouter(StaticEmbed)
