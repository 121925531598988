import styled from '@emotion/styled'

export const ApplicationManagementStyled = styled.div``

export const NoItemsDivStyled = styled.div`
  height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
`
