import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { InputStyled, Textarea } from '_components/InputStyled.js'
import styled from "@emotion/styled"

const TextEditorStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  input, textarea { 
    margin-top: var(--space-s, 10px);
  }
`

export const TextEditor = ({value, onChange = () => {}}) => {
  const intl = useIntl()
  const [title, setTitle] = useState(value?.title)
  const [text, setText] = useState(value?.text)

  useEffect(() => {
    onChange({title, text})
  }, [title, text])

  useEffect(() => {
    setTitle(value?.title)
    setText(value?.text)
  }, [value])

  const titleContent = {
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.title.title'}),
    description: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.title.description' }),
    placeholder: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.title.placeholder' }),
  }

  const textContent = {
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.text.title' }),
    description: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.text.description' }),
    placeholder: intl.formatMessage({ id: 'admin.sitestructure.footer.textEditor.text.placeholder' }),
  }

  return (
    <TextEditorStyled className="TextEditor">
      <div>
        <p><b>{titleContent.title}</b></p>
        <p>{titleContent.description}</p>
        <InputStyled
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={titleContent.placeholder}
        />
      </div>

      <div>
        <p><b>{textContent.title}</b></p>
        <p>{textContent.description}</p>
        <Textarea
          value={text}
          onChange={(text) => setText(text)}
          placeholder={textContent.placeholder}
        />
      </div>
    </TextEditorStyled>
  )
}
