import styled from '@emotion/styled'
import border from '_utils/css-border'
import { ctaButtonHollow } from '_utils/css-buttons'

export const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  max-width: 800px;
  gap: 20px;
  min-width: 400px;

:first-child{
  margin-top: 20px;

}
`
export const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`
export const Description = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`
export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80vw;
  max-width: 410px;
`
export const Results = styled.div`
  ${border()}
  position: absolute;
  background-color: var(--background);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 45px 20px 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const SearchInputWrapper = styled.div`
  position: relative;
  z-index: ${({ isInputActive }) => (isInputActive ? '3' : '2')};
  .event-search-input {
    z-index: 2;
    width: 410px;
    height: 40px;
    border-radius: 5px;
  }
`
export const SingleResult = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--foreground);
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
`
export const Name = styled.div``
export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`
export const Info = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`
export const SelectedAvatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid var(--foreground);
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
`
export const SelectedName = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
  max-width: 120px;
`
export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
  width: calc(50% - 10px);
`
export const ConnectionButton = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 3px solid;
  border-color: ${({ isConnected, isCanceled }) =>
    isConnected ? '#3da854' : isCanceled ? '#FF0000' : 'var(--shade)'};
  border-radius: 25px;
  background-color: transparent;
  padding: 10px 25px;
  i {
    margin-right: 10px;
  }
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`
export const RemoveButton = styled.button`
  ${ctaButtonHollow()}
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0;
  flex-shrink: 0;
`
