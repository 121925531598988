import React, { useState } from 'react'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'

import { ColorPickerStyled } from './styled'
import { ChromePicker } from 'react-color'

const Index = ({ color, onChange }) => {
  const [showPicker, setShowPicker] = useState(false)
  const [warning, setWarnign] = useState(false)

  const ref = useClickOutside(() => offClick())

  const offClick = () => {
    showPicker && setShowPicker(false)
    warning && setWarnign(false)
  }

  const changeColor = (color) => {
    let newColor = color.hex
    onChange(newColor)
  }

  return (
    <ColorPickerStyled>
      <div
        style={{ background: color }}
        className="color-preview"
        onClick={() => setShowPicker(true)}
      />
      {showPicker && (
        <div className="color-picker" ref={ref}>
          <ChromePicker
            color={color}
            onChange={(color) => changeColor(color)}
          />
          <button onClick={() => setShowPicker(false)}>Done</button>
        </div>
      )}
    </ColorPickerStyled>
  )
}

export default Index
