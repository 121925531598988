import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as PendingIcon } from 'resources/pending-icon.svg'
import { ReactComponent as MessageIcon } from 'resources/message-icon.svg'
import { ReactComponent as ConnectedIcon } from 'resources/connected-icon.svg'
import { ReactComponent as ConnectIcon } from 'resources/connect-icon.svg'
import { useMessagesConversations } from '_messages'
import { useHistory } from 'react-router-dom'

import {
  ConnectButtonStyled,
  PendingButtonStyled,
} from './styles/connect-button'

const Props = {
  status: PropTypes.oneOf(['requested', 'accepted', 'cancelled']),
  disabled: PropTypes.bool,
  onConnect: PropTypes.func.isRequired,
}

const renderType = (status) => {
  switch (status) {
    case 'requested':
      return 'pending'
    case 'accepted':
      return 'accepted'
    default:
      return 'normal'
  }
}

const ConnectButton = ({
  status,
  sub,
  disabled,
  onConnect,
  onConnectRefetchQueries,
  type = '',
  size = 'large',
  isMeetActive,
}) => {
  const history = useHistory()
  const conversationsHook = useMessagesConversations()

  const [hover, setHover] = useState(false)

  const determineEvent = (status, type, sub, e) => {
    e.preventDefault()
    if (renderType(status) === 'accepted' && type === 'people') {
      conversationsHook.openConversation(sub)
      history.push('/user/messages')
    } else {
      onConnect()
      onConnectRefetchQueries && onConnectRefetchQueries()
    }
  }

  const clickConnect = (e) => {
    e.preventDefault()
    setHover(false)
    onConnect()
    onConnectRefetchQueries && onConnectRefetchQueries()
  }

  const renderIcon = (status, type, disabled) => {
    if (disabled) return null
    switch (renderType(status, isSmall)) {
      case 'pending':
        return <PendingIcon className="pending-icon" />
      case 'accepted':
        if (type === 'people') {
          return <MessageIcon className="message-icon" />
        } else {
          return <ConnectedIcon className="connected-icon" />
        }
      default:
        return isSmall ? (
          <i className="fas fa-user-plus" />
        ) : (
          <ConnectIcon className="connect-icon" />
        )
      // return <ConnectIcon className="connect-icon" />
    }
  }

  const textIs = (status === 'accepted' && isMeetActive) || size === 'small'
  const renderText = (message) => {
    return textIs ? <></> : message
  }

  let messageText
  switch (status) {
    case 'requested':
      messageText = (
        <FormattedMessage
          id={`${
            hover
              ? `connections.${type && type + '.'}cancel`
              : `connections.${type && type + '.'}pending`
          }`}
        />
      )

      break
    case 'accepted':
      if (type === 'people') {
        messageText = (
          <FormattedMessage
            id={`connections.${type && type + '.'}sendmessage`}
          />
        )
      } else {
        messageText = (
          <FormattedMessage
            id={`${
              hover
                ? `connections.${type && type + '.'}disconnect`
                : `connections.${type && type + '.'}connected`
            }`}
          />
        )
      }
      break
    default:
      messageText = (
        <FormattedMessage id={`connections.${type && type + '.'}connect`} />
      )
  }

  let message
  if (disabled) {
    message = <span>...</span>
  } else {
    message = <p>{messageText}</p>
  }

  const buttonContent = (status, type, disabled, message) => {
    return (
      <div>
        {renderIcon(status, type, disabled)}
        {renderText(message)}
      </div>
    )
  }

  const isSmall =
    status === 'requested' ? false : isMeetActive || size === 'small'

  return renderType(status) === 'pending' ? (
    <PendingButtonStyled
      isSmall={isSmall}
      size={size}
      hover={hover}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => clickConnect(e)}
      className={`
        ${status ? status : 'connect'}
        ${renderType(status, isSmall)}
      `}
      data-testid={`btn-detailCard-connect-${status ? status : 'connect'}`}
    >
      {buttonContent(status, type, disabled, message)}
    </PendingButtonStyled>
  ) : (
    <ConnectButtonStyled
      isSmall={isSmall}
      size={status === 'accepted' && isMeetActive ? 'small' : size}
      onClick={(e) => determineEvent(status, type, sub, e)}
      disabled={disabled}
      className={`
        ${status === 'accepted' && isMeetActive ? 'small' : size}
        ${status ? status : 'connect'}
        ${renderType(status, isSmall)}
      `}
      data-testid={`btn-detailCard-connect-${status ? status : 'connect'}`}
    >
      {buttonContent(status, type, disabled, message)}
    </ConnectButtonStyled>
  )
}

ConnectButton.propTypes = Props
export default ConnectButton
