import React from 'react'

import SubNavigation from '../../shared/components/sub-navigation'
import Routes from './routes'

const defaultUrl = '/manage/site-structure/blog'

const navItems = [
  {
    url: `${defaultUrl}/categories`,
    titleKey: 'admin.sitestructure.blog.categories',
  },
  {
    url: `${defaultUrl}/access`,
    titleKey: 'admin.sitestructure.blog.access',
  },
  {
    url: `${defaultUrl}/subscription`,
    titleKey: 'admin.sitestructure.blog.subscription',
  },
  { url: `${defaultUrl}/labels`, titleKey: 'admin.sitestructure.blog.labels' },
]

const Dashboard = () => {
  return (
    <>
      <SubNavigation navItems={navItems} />
      <Routes />
    </>
  )
}

export default Dashboard
