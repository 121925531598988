import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useCustomAttributes } from '_custom-attributes/hooks'
// import { useAppLabel } from '_application'
// import ConnectButton from '_components/connect-button'
import { Loader } from '_components/loading'
import { Modal } from '_components/drilldown'
// import SanityBlock from '_components/sanity-block'
import ConnectButton from '_components/buttons-connections/connect-button'
// import { getDisplayName } from '_people/people-models'
import RecommendButton from '_components/buttons-connections/recommend-button'
//import A2CButton from '_components/buttons-connections/a2c-button'
import A2PButton from '_components/buttons-connections/a2p-button'

import ActivityDetails from '../components/activity-details'
import { useUserAccess } from '_security/helpers'
import { sendNotification } from '_components/buttons-connections/message-helper'
import { useIntl } from 'react-intl'
import LoginRequiredModal from '_security/login-required-modal'
import ErrorModal from '_components/modals/error-modal'
import { Link } from 'react-router-dom'

const GET_ACTIVITY = gql`
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      coverPhoto
      description
      body
      creatorId
      location
      connectionStatus
      location
      start
      end
      private
      _createdAt
      _updatedAt
      isDraft
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomy {
        key
        values {
          type
          value
        }
      }
      connectedUsersCount
      connectedCompanies {
        id
        name
        coverPhoto
        description
        location
        bannerImage
      }
      connectedOpportunities {
        id
        name
        coverPhoto
        start
        end
        description
        location
      }
      createdByMe
      creator {
        id
        sub
        bioShort
        firstName
        middleName
        lastName
        avatar
        location
      }
      editor {
        id
        sub
        firstName
        lastName
        avatar
      }
      customAttributes {
        key
        value
      }
    }
  }
`

export const CONNECT_MUTATION = gql`
  mutation ConnectActivity($id: String!, $creatorId: String!) {
    connectToActivity(id: $id, creatorId: $creatorId) {
      status
    }
  }
`

const Props = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const ActivityDetailsConnected = ({ id, onClose }) => {
  const intl = useIntl()
  const { data, error, loading } = useQuery(GET_ACTIVITY, { variables: { id } })
  const currentUser = useSelector((state) => state.user)
  const activity = data?.getActivity

  const isOwner = currentUser?.sub === activity?.creator?.sub
  const isAdmin = currentUser?.profile?.role === 'admin'

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION, {
    variables: { id, creatorId: activity?.creator?.sub },
    refetchQueries: [{ query: GET_ACTIVITY, variables: { id } }],
    awaitRefetchQueries: true,
  })

  const { attributes } = useCustomAttributes('activities')
  const isActivityNotFound =
    !loading &&
    (activity == null || (!isOwner && !isAdmin && activity?.isDraft))

  const hasUserAccess = useUserAccess()

  if (!hasUserAccess) {
    return <LoginRequiredModal onClose={onClose} />
  }

  return (
    <>
      {isActivityNotFound ? (
        <ErrorModal
          show={true}
          title={intl.formatMessage({
            id: 'modal.error.contentnotfound.title',
            defaultMessage: 'Ups! This information is private',
          })}
          description={intl.formatMessage({
            id: 'modal.error.contentnotfound.description',
            defaultMessage:
              "We're sorry, this page might have been removed or can only be accessed by platform administrators.",
          })}
          handleClose={onClose}
          button={
            <Link to="/">
              <button className="proceed-button">
                {intl.formatMessage({
                  id: 'modal.error.contentnotfound.button',
                  defaultMessage: 'homepage',
                })}
              </button>
            </Link>
          }
        ></ErrorModal>
      ) : (
        <Modal onClose={onClose}>
          <Loader loading={loading} error={error}>
            {() => (
              <ActivityDetails
                onClose={onClose}
                activity={activity}
                attributes={attributes}
                connectButton={
                  <ConnectButton
                    status={data.getActivity?.connectionStatus}
                    onConnect={() =>
                      connect()
                        .then((res) => {
                          const { status } = res?.data?.connectToActivity
                          sendNotification({
                            type: 'connection',
                            intl,
                            defaultHeader: 'Connection',
                            defaultContent: `You have ${
                              status === 'cancelled'
                                ? 'cancelled your connection'
                                : 'sent a connection request'
                            }`,
                          })
                        })
                        .catch((err) => console.log('error', err))
                    }
                    disabled={connecting}
                    type="activities"
                  />
                }
                recommendButton={
                  <RecommendButton
                    contentToRecomment={id}
                    queryKey={'activityToRecommend'}
                    isPrivate={activity?.private}
                  />
                }
                //a2cButton={<A2CButton activityId={id}></A2CButton>}
                isOwner={isOwner}
                a2pButton={<A2PButton activityId={id}></A2PButton>}
                p2pButton={{
                  refetchQueries: [{ query: GET_ACTIVITY, variables: { id } }],
                }}
              ></ActivityDetails>
            )}
          </Loader>
        </Modal>
      )}
    </>
  )
}

ActivityDetailsConnected.propTypes = Props

// const Header = ({ activity, onConnect, connecting, isOwner }) => {
//   const appLabel = useAppLabel()
//   return (
//     <Modal.Header
//       cover={activity.coverPhoto}
//       avatar={activity.creator?.avatar}
//       title={getDisplayName(activity.creator)}
//       subject={activity.name}
//       subtitle={activity.creator?.bioShort}
//       tag={appLabel('activities', 'activities.title')}
//       id={isOwner ? activity.id : null}
//     >
//       <RecommendActivityToUser activityId={activity.id} />
//       <ConnectButton
//         status={activity?.connectionStatus}
//         onConnect={onConnect}
//         disabled={connecting}
//       />
//     </Modal.Header>
//   )
// }

export default ActivityDetailsConnected

