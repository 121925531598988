import React, { useState } from 'react'
import { useAppConfig, GET_CONFIG } from '_application'
import { UPDATE_ACCESS_PAGE, GET_ACCESS_KEY } from './graph-queries'
import { useMutation, useQuery } from '@apollo/client'

import { SubSection } from '../shared/styles/sections'
import Input from 'components/indy/shared/ui-kit/inputs/text'
import Toggle from 'components/indy/shared/ui-kit/buttons/toggle-button-with-name'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import BackgroundShared from './background-shared'

export const Overlay = styled.div`
  position: relative;
  &.disabled {
    opacity: 0.4;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
`

const Generic = () => {
  const [accessKeyEdit, setAccessKeyEdit] = useState()
  const intl = useIntl()

  const config = useAppConfig()
  const { title, description, requestAccessEmail, backgroundImage, enabled } =
    config?.accessPage || {}

  const [updateAccessPage] = useMutation(UPDATE_ACCESS_PAGE)
  const { data } = useQuery(GET_ACCESS_KEY)

  const accessKey = data?.getAccessKey

  const handleImageUpload = (imgUrl, type) => {
    updateAccessPage({
      variables: { accessPage: { backgroundImage: { value: imgUrl, type } } },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const handleUpdateInput = (value, type) => {
    updateAccessPage({
      variables: { accessPage: { [type]: value } },
      refetchQueries: [{ query: GET_ACCESS_KEY }],
      awaitRefetchQueries: true,
    })
    setAccessKeyEdit(false)
  }
  const handleToggle = (value) => {
    localStorage.setItem('accessCode', true)
    updateAccessPage({
      variables: { accessPage: { enabled: value } },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `admin.settings.accesspage.${value.toLowerCase()}`,
    })
  }

  return (
    <div>
      <Toggle
        name={renderMessageText('toggleaccesspage.label')}
        checked={enabled}
        onChange={(checked) => handleToggle(checked)}
      />
      <Overlay className={enabled ? '' : 'disabled'}>
        <SubSection>
          <span className="title">{renderMessageText('welcomeTitle')}</span>
          <span className="description"></span>
          <Input
            input={{
              placeholder: `${renderMessageText('placeholder.welcome.title')}`,
              value: title,
            }}
            onBlurCallback={(v) => handleUpdateInput(v, 'title')}
          />
        </SubSection>
        <SubSection>
          <span className="title">
            {renderMessageText('welcomeDescription')}
          </span>
          <span className="description"></span>
          <Input
            input={{
              placeholder: `${renderMessageText(
                'placeholder.welcome.description'
              )}`,
              value: description,
            }}
            onBlurCallback={(v) => handleUpdateInput(v, 'description')}
          />
        </SubSection>
        <SubSection>
          <span className="title">
            {renderMessageText('accessEmailRequest')}
          </span>
          <span className="description">
            {renderMessageText('requestInfo')}
            {/* Email address to which users can send their access request */}
          </span>
          <Input
            input={{
              placeholder: `${renderMessageText('placeholder.request.email')}`,
              value: requestAccessEmail,
            }}
            onBlurCallback={(v) => handleUpdateInput(v, 'requestAccessEmail')}
          />
        </SubSection>
        <SubSection>
          <span className="title">{renderMessageText('accessKey')}</span>
          <span className="description"></span>
          <span className="access-key">
            {renderMessageText('accessKey')} {accessKey || 'NOT SET'}
          </span>
          {accessKeyEdit ? (
            <div className="edit-key">
              <Input
                input={{ placeholder: `Provide access key`, value: accessKey }}
                required={true}
                onBlurCallback={(v) => handleUpdateInput(v, 'accessKey')}
              />
            </div>
          ) : (
            <div className="edit-key">
              <button onClick={() => setAccessKeyEdit(true)}>
                {renderMessageText('editAccess')}
                {/* Click here to edit access key */}
              </button>
            </div>
          )}
        </SubSection>
        <SubSection>
          <span className="title">{renderMessageText('backgroundImage')}</span>
          <span className="description"></span>
          <BackgroundShared
            value={backgroundImage}
            onUpload={(imgUrl, type) => handleImageUpload(imgUrl, type)}
            onRevert={() => handleImageUpload('')}
          />
        </SubSection>
        <div className="overlay" />
      </Overlay>
    </div>
  )
}

export default Generic
