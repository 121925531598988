import React from 'react'

import PersonalDetails from './personal-details'

const Card = ({ user }) => {
  return (
    <div className="profile-info-container">
      <div className="info-card">
        <div
          style={{ backgroundImage: `url('${user.profile.avatar}')` }}
          className="profile-image"
          data-testid="onboarding-preview-img-avatar"
        />
        <PersonalDetails
          name={user?.profile?.fullName}
          location={user?.profile?.location?.label}
        />
      </div>
      <div className="details-container"></div>
    </div>
  )
}

export default Card
