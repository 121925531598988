import React from 'react'
import { useIntl } from 'react-intl'
import {
  CoverPhotoWrapper,
  StyledUploadDiv,
  IconWrapper,
} from './styles/banner-image'
// import ToolTip from './tooltip'
import ReactTooltip from 'react-tooltip'
import { useMediaUploader } from 'components/indy/shared/media-uploader'

const BannerImage = ({ bannerImg, uploadConfig, onSave }) => {
  const intl = useIntl()
  const { showWidget } = useMediaUploader(uploadConfig, (media) =>
    onSave(media?.url)
  )
  return (
    <CoverPhotoWrapper>
      <div className="label-wrapper">
        <p className="label">
          {intl.formatMessage({
            id: 'events.createevent.general.banerimage.title',
          })}
        </p>
        <IconWrapper
          data-for="banner-image"
          data-tip={intl.formatMessage({
            id: 'events.createevent.general.banerimage.tooltip',
          })}
        >
          <i className="fas fa-info" />
        </IconWrapper>

        {/* <ToolTip>
          {intl.formatMessage({
            id: 'events.createevent.general.banerimage.tooltip',
          })}
        </ToolTip> */}
      </div>
      <p className="info">
        {intl.formatMessage({
          id: 'events.createevent.general.banerimage.description',
        })}
        <span className="required-star">*</span>
      </p>
      {bannerImg ? (
        <StyledUploadDiv id="upload_widget" imgUrl={bannerImg}>
          <div className="overlay" />
          <button className="change-img-btn" onClick={() => showWidget()}>
            {intl.formatMessage({
              id: 'events.createevent.general.banerimage.button.label.change',
            })}
          </button>
        </StyledUploadDiv>
      ) : (
        <button onClick={() => showWidget()} className="cover-photo-upload-btn">
          {intl.formatMessage({
            id: 'events.createevent.general.banerimage.button.label.upload',
          })}
        </button>
      )}
      <ReactTooltip id="banner-image" place="top" type="dark" effect="solid" />
    </CoverPhotoWrapper>
  )
}

export default BannerImage
