import React from 'react'
import {
  MiniCardStyled,
  ConnectButtonContainer,
  Location,
} from './styled/activity-mini-card'
import { AvatarsMiniCard } from '_tiles'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useUserAccess } from '_security/helpers'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon-black.svg'
import { useColorBucket } from '_colorBus/hooks'

const renderLocation = (location) => {
  if (!location) return null
  const { city, country } = location

  const hasCity = city && city !== 'null' && city !== 'undefined' && city !== ''
  const hasCountry =
    country && country !== 'null' && country !== 'undefined' && country !== ''

  return (
    <Location>
      {(hasCity || hasCountry) && <LocationIcon />}
      {`${hasCity ? city + ', ' : ''}${hasCountry ? country : ''}`}
    </Location>
  )
}

const ActivityMiniCard = ({ children, activity, connectButton, isOwner }) => {
  const { creator, connectedCompanies } = activity || {}
  const hasUserAccess = useUserAccess()

  const hasMultipleConnections = connectedCompanies.length > 1
  const hasOneConnection = connectedCompanies.length === 1

  const [colorBucket] = useColorBucket('tile')

  return (
    <MiniCardStyled
      ref={colorBucket}
      hasMultipleConnections={hasMultipleConnections}
      data-testid="detailCard-miniCard"
    >
      <AvatarsMiniCard
        creator={creator}
        connections={activity?.connectedCompanies}
      />
      {renderLocation(
        hasOneConnection
          ? connectedCompanies[0]?.location
          : hasMultipleConnections
          ? false
          : creator?.location
      )}
      <ConnectButtonContainer>
        {connectButton && !hasMultipleConnections && false && (
          <ConnectButton
            status={hasUserAccess ? activity?.connectionStatus : null}
            onConnect={hasUserAccess ? connectButton?.onConnect : () => {}}
            disabled={connectButton?.disabled}
            type="activities"
          />
        )}
      </ConnectButtonContainer>
    </MiniCardStyled>
  )
}

export default ActivityMiniCard
