import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { defaultLoginBackground } from '_resources'
import RequestAccess from '_components/request-access'
import { useIntl } from 'react-intl'
import lockIcon from 'resources/lock-icon.png'
import styled from '@emotion/styled'

export const AUTH_ACCESS_KEY = gql`
  mutation authAccessKey($accessKey: String) {
    authAccessKey(accessKey: $accessKey)
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`

const Index = ({ config }) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [authAccessKey, { data: authorization }] = useMutation(AUTH_ACCESS_KEY)
  const intl = useIntl()

  const handleVerification = () => {
    authAccessKey({ variables: { accessKey: verificationCode } })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      authAccessKey({ variables: { accessKey: verificationCode } })
    }
  }

  if (authorization?.authAccessKey) {
    localStorage.setItem('accessCode', verificationCode)
    window.location.reload()
  }

  const translation = (id) =>
    intl.formatMessage({ id: `accesspage.${id.toLowerCase()}` })

  const { backgroundImage, title, description, requestAccessEmail } =
    config?.accessPage || {}
  const isBackgroundVideo = backgroundImage?.type === 'video'
  const backgroundUrl = backgroundImage?.value || defaultLoginBackground

  return (
    <RequestAccess>
      <div className="access-page">
        <div className="inner-sign-up-form">
          <div className="lock-icon">
            <img className="icon" src={lockIcon} alt="lockIcon" />
          </div>
          <div className="content-container">
            <h1 className="form-title">{title || translation('title')}</h1>
            <label className="form-description" htmlFor="secret_code">
              {description || translation('description')}
            </label>
            <div className="submit-container">
              <input
                id="secret_code"
                onChange={(e) => setVerificationCode(e.target.value)}
                className="code-input"
                type="text"
                placeholder={translation('inputPlaceholder')}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <button
                onClick={handleVerification}
                className="enter-button"
                type="button"
              >
                {translation('enter')}
              </button>
            </div>
            <a href={`mailto:${requestAccessEmail}`}>
              <button className="request-button">
                {translation('button.requestcode')}
              </button>
            </a>
          </div>
        </div>
        {isBackgroundVideo ? (
          <Video
            className="background"
            width="100%"
            height="100%"
            playsinline
            src={backgroundUrl}
            autoPlay
            muted
            loop
          >
            <div className="overlay" />
          </Video>
        ) : (
          <div
            className="background"
            style={{ backgroundImage: `url(${backgroundUrl})` }}
          >
            <div className="overlay" />
          </div>
        )}
      </div>
    </RequestAccess>
  )
}

export default Index
