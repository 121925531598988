/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ThreeDSecureStyled } from '../styles/three-d-secure'

const ThreeDSecure = ({ threeDSecure, onThreeDSecureComplete }) => {
  const [showIframe, setShowIframe] = useState(true)

  const handleThreeDSecureComplete = () => {
    if (showIframe) {
      setShowIframe(false)
      onThreeDSecureComplete()
    }
  }

  const handleCallback = (ev) => {
    if (ev.data === '3DS-authentication-complete') {
      handleThreeDSecureComplete()
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleCallback, true)
    return window.removeEventListener('message', handleCallback)
  }, [])

  return (
    <ThreeDSecureStyled>
      {threeDSecure && showIframe && (
        <iframe
          id="3dsecure"
          title="3dsecure"
          src={threeDSecure.next_action.redirect_to_url.url}
          width={600}
          height={600}
        />
      )}
    </ThreeDSecureStyled>
  )
}

export default ThreeDSecure
