import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Props = {
  title: PropTypes.string,
}

const Button = styled.a`
  margin: 0 10px 0 0;
  display: block;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  font-size: 15px;
  background: var(--flavor);
  color: var(--background);
  i {
    margin-right: 10px;
  }
`

const InviteButton = ({ id }) => {
  return (
    <Button href="#">
      <i claclassNamess="fas fa-link fa-lg"></i>
      Invite
    </Button>
  )
}
InviteButton.propTypes = Props

export default InviteButton
