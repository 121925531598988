import axios from 'axios'
import store from 'store'
import moment from 'moment'
import { IKUIUserAPI } from '@indykiteone/jarvis-sdk-web'
import * as Sentry from '@sentry/react'

export async function login(accessToken) {
  if (accessToken) {
    const verify = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/user/verify`,
      method: 'get',
      headers: { authorization: accessToken },
    })
    if (verify) {
      store.set('accessToken', accessToken)
    }
    if (verify?.data?.redirect) {
      return { success: true, redirect: true }
    }
    return { success: true }
  }
  return { success: false }
}

export async function register(payload) {
  const REGISTER_USER = `
    mutation {
      registerUser(
        email: "${payload.email}",
        firstName: "${payload.firstName}",
        lastName: "${payload.lastName}"
      ){
        firstName,
        lastName,
        email
      }
    }
  `
  return axios({
    url: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
    method: 'post',
    data: { query: REGISTER_USER },
    headers: { authorization: payload.token },
  })
    .then((d) => {
      return d.data
    })
    .catch((err) => console.error(err))
}

const GET_CURRENT_USER = `
  query {
    getCurrentUser{
      id,
      sub,
      firstName,
      lastName,
      middleName,
      email,
      emails {
        id,
        value,
        meta {
          primary
          secondary
          assuranceLevel
          issuer
          verifier
        }
      },
      forumId,
      birthDate,
      gender,
      avatar,
      coverPhoto,
      bioShort,
      bioLong,
      instagram,
      twitter,
      linkedIn,
      phone,
      role,
      languagesSpoken,
      languagesWritten,
      industryAreas,
      termsAndConditionsConsentDate,
      dateRegistered,
      sub,
      location,
      activeSubscriptions,
      paymentInfo{
        stripeCustomerIdTest
        stripeCustomerIdLive
      },
      competences{
        value,
        type
      },
      experience{
        key,
        companyName,
        title,
        startDate,
        endDate,
        location,
        description,
        websiteUrl,
        showOnMinature
      },
      education{
        key,
        schoolName,
        degree,
        yearGraduated,
        yearStarted,
        domain,
        websiteUrl
      },
      custom {
        experience
      },
      customAttributes{
        key
        value
        type
      }
      keywords{
        key
        values{
          type
          value
        }
      }
      taxonomies{
        key
        values
      }
      sortedNotifications
      notifications{
        notification {
          _id
          sub
          channel
          body
          type
          sentDate
          subtype
          read
          deleted
          payload
        }
        user {
          sub
          firstName
          lastName
          avatar
        }
      }
    }
  }
`
const GET_USERS_CONNECTIONS = `
query GetUserConnectionsPublic($ids: [ID]!) {
    getUserConnectionsPublic(ids: $ids){
      forSub
      connectedUsers{
        sub
        avatar
        firstName
        lastName
      }
      connectedActivities{
        id
      }
      connectedCompanies{
        id
        coverPhoto
      }
      connectedUsersCount
      connectedActivitiesCount
      connectedCompaniesCount
      createdActivities{
        id
        coverPhoto
      }
      createdCompanies{
        id
        coverPhoto
      }
      connectionStatus
      availableActivities {
        id
        coverPhoto
      }
      availableCompanies {
        id
        coverPhoto
      }
    }
  }
`

export async function currentAccount() {
  let accessToken
  if(!IKUIUserAPI.isInitialized()) {
    return
  }
  await IKUIUserAPI.getValidAccessToken()
    .then((data) => {
      if (data) {
        accessToken = data
        localStorage.setItem('RIOT/token', data)
      }
    })
    .catch((err) => {
      console.warn('accessToken: Could not get valid access token', err)
      localStorage.removeItem('RIOT/token')
    })

  if (accessToken) {
    return axios({
      url: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
      method: 'post',
      data: { query: GET_CURRENT_USER },
      headers: { authorization: accessToken },
    })
      .then(async (r) => {
        if (r.data.data.getCurrentUser.email) {
          Sentry.setUser({ email: r.data.data.getCurrentUser.email })
        }

        if (
          (r.data.errors && r.data.errors.length > 0) ||
          r.data.data.getCurrentUser === null
        ) {
          IKUIUserAPI.logoutUser().then(() => {
            /* clear SDK storage */
            localStorage.removeItem('IDKUISDK/cv')
            localStorage.removeItem('IKUISDK/expirationTime')
            localStorage.removeItem('IKUISDK/notifications')
            localStorage.removeItem('IKUISDK/oidcOrgnlParams')
            localStorage.removeItem('IKUISDK/oidcSetupResponse')
            localStorage.removeItem('IKUISDK/refreshToken')
            localStorage.removeItem('RIOT/token')
            localStorage.removeItem('accessToken')
            Sentry.configureScope((scope) => scope.setUser(null))

            window.location.reload()
          })
        }
        const connections = await axios({
          url:
            process.env.REACT_APP_GRAPHQL_URI ||
            'http://localhost:4001/graphql',
          method: 'post',
          data: {
            query: GET_USERS_CONNECTIONS,
            variables: { ids: [r.data.data.getCurrentUser.sub] },
          },
          headers: { authorization: accessToken },
        })
        return mapUserAccount(
          {
            ...r.data.data.getCurrentUser,
            ...connections.data.data.getUserConnectionsPublic[0],
          } || {}
        )
      })
      .catch((err) => console.error(err))
  }

  return null
}

export const mapUserAccount = (user) => {
  if (!user) return user
  if (user.firstName === 'null' || user.firstName === 'undefined')
    user.firstName = ''
  if (user.middleName === 'null' || user.middleName === 'undefined')
    user.middleName = ''
  if (user.lastName === 'null' || user.lastName === 'undefined')
    user.lastName = ''
  user.fullName = `${
    user.middleName !== '' && user.middleName !== 'undefined'
      ? `${user.firstName} ${user.middleName}`
      : user.firstName
  } ${user.lastName}`
  if (user.location) {
    user.location.lat =
      user.location.lat === 'undefined' || user.location.lat === 'null'
        ? null
        : user.location.lat
    user.location.long =
      user.location.long === 'undefined' || user.location.long === 'null'
        ? null
        : user.location.long
    user.location.city =
      user.location.city === 'undefined' || user.location.city === 'null'
        ? null
        : user.location.city
    user.location.country =
      user.location.country === 'undefined' || user.location.country === 'null'
        ? null
        : user.location.country
  }
  user.dateRegistered = user.dateRegistered
    ? moment.unix(user.dateRegistered)
    : new Date()
  user.custom = user.custom ? user.custom : {}
  user.custom.profileTypes = user.custom.profileTypes
    ? user.custom.profileTypes
    : []
  user.customAttributes = user.customAttributes || []
  user.notifications = user.notifications || []

  return user
}

export async function logout() {
  IKUIUserAPI.logoutUser().then((result) => {
    /* clear RIOT storage */
    localStorage.removeItem('RIOT/token')
    localStorage.removeItem('accessToken')
    Sentry.configureScope((scope) => scope.setUser(null))

    window.location.href = '/'
  })
}
