import React from 'react'
import PropTypes from 'prop-types'
import { ParagraphSmall } from '_components/text'
import Section from './drilldown-section'

import styled from '@emotion/styled'

const StyledSection = styled(Section)`
  padding-bottom: 50px;

  *.intersecting {
    background-color: red;
    outline: solid 3px blue;
  }
`

const Props = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const DrilldownSection = ({ title, children, className }) => (
  <StyledSection title={title} className={className}>
    {children && <ParagraphSmall>{children}</ParagraphSmall>}
  </StyledSection>
)
DrilldownSection.propTypes = Props

export default DrilldownSection
