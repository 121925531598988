import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from '_security/session-hooks'

import { Row, Col, Container } from 'react-bootstrap'
import Navigation from './navigation'
import Routes from './routes'
import { usePageTitle, useAppLabel } from '_application'

import MainLayout from '_layouts/main'
import Content from './styles/index'

import { useColorBucket } from '_colorBus/hooks'

const Profile = () => {
  const user = useUser()
  const appLabel = useAppLabel()
  const title = appLabel('administration', 'admin.nav.administration')
  usePageTitle(title)

  const [colorBucket] = useColorBucket('admin')

  if (user?.profile?.role !== 'admin') return <Redirect to="/" />

  return (
    <MainLayout>
      <Content>
        <Container className="admin-container">
          <Row ref={colorBucket}>
            <Col sm={12} md={3}>
              <Navigation />
            </Col>
            <Col sm={12} md={9}>
              <div className="content">
                <Routes />
              </div>
            </Col>
          </Row>
        </Container>
      </Content>
    </MainLayout>
  )
}

export default Profile
