import React from 'react'
import Loader from 'components/indy/shared/loader'
import Message from './message'
import Checkout from './checkout'
import ThreeDSecure from './three-d-secure'
import Modal from 'react-bootstrap/Modal'
import { ModalStyled } from '../styles/checkout'

const CheckoutModal = ({ children, onClose }) => {
  return (
    <ModalStyled className="checkout-modal" size="lg" show onHide={onClose}>
      <Modal.Body>{children}</Modal.Body>
    </ModalStyled>
  )
}

const Index = ({
  onSuccessCallback,
  onErrorCallback,
  onClose,
  isPaymentInProgress,
  product,
  paymentMessage,
  threeDSecure,
  onThreeDSecureComplete,
}) => {
  const handleOnClose = () => {
    if (!isPaymentInProgress) {
      onClose()
    }
  }

  const resolveRender = () => {
    if (threeDSecure)
      return (
        <ThreeDSecure
          onThreeDSecureComplete={onThreeDSecureComplete}
          threeDSecure={threeDSecure}
        />
      )
    if (isPaymentInProgress) return <Loader />
    if (paymentMessage)
      return <Message message={paymentMessage} onClose={onClose} />

    return (
      <Checkout
        onSuccessCallback={onSuccessCallback}
        onClose={onClose}
        product={product}
      />
    )
  }

  return (
    <CheckoutModal onClose={handleOnClose}>{resolveRender()}</CheckoutModal>
  )
}

export default Index
