import React from 'react'

import PortableText from '../shared/PortableText'
import styled from '@emotion/styled'

const StyledContentSection = styled.section`
  border-radius: 25px;
  background-color: var(--background);
  blockquote {
    color: inherit;
  }
  .single-blog-desc {
    margin: 16px 0;
    font-size: 16px;
  }
`

const ContentSection = (data) => {
  return (
    <StyledContentSection className="single-blog-content-section">
      <div className="inner-single-blog-content-container">
        <div className="single-blog-content-container">
          <h1 className="single-blog-title">
            {data?.page && data?.page?.title}
          </h1>
          <h2>{data?.page && data?.page?.subtitle}</h2>
          <p className="single-blog-desc">
            {data?.page && data?.page?.description}
          </p>
          <PortableText
            className="single-blog-text"
            blocks={data?.page && data?.page?.body}
          />
        </div>
      </div>
    </StyledContentSection>
  )
}

export default ContentSection
