import styled from '@emotion/styled'
import { ActionGroup, Button, Flex } from '@patternfly/react-core'
import border from '_utils/css-border'
import { ctaButton } from '_utils/css-buttons'
import { mobile, pablet } from '_utils/responsive-queries'
import { Link } from 'react-router-dom'

export const ReplyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

export const UserName = styled.span`
  color: var(--flavor);
  font-weight: 600;
`

export const CancelReplyButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--foreground);
  font-weight: 600;
  transition: 0.4s;
  margin-left: 10px;
  &:hover {
    opacity: 0.6;
  }
`

export const CommentButton = styled(Button)`
  ${ctaButton}
`

export const CancelButton = styled(Button)`
  text-decoration: underline;
  color: var(--foreground) !important;
`

export const PageWrapper = styled.div`
  .bullet {
    margin: 0 6px;
    font-size: 5px;
    transform: translateY(-50%);
  }
`

export const Replies = styled.div`
  margin-left: 100px;
`
export const CommentAvatar = styled.div`
  position: relative;
  flex-shrink: 0;
  float: left;
  background-color: var(--background);
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-left: -38px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 9px;
    background-color: var(--background);
    z-index: 0;
  }
  img {
    ${border()}
    position: relative;
    z-index: 1;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${pablet} {
    width: 45px;
    height: 45px;
    margin-left: -22px;
    &:after {
      height: 15px;
      bottom: -15px;
    }
  }
  ${mobile} {
    width: 35px;
    height: 35px;
    margin-left: -18px;
    &:after {
      height: 15px;
      bottom: -15px;
    }
  }
`

export const CategoryLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  ${pablet} {
    div {
      font-size: 12px;
    }
    .category-name {
      opacity: 0.6;
      align-self: center;
    }
  }
`
export const SingleTag = styled.span`
  i {
    transition: 0.3s;
    margin-right: 10px;
  }

  a {
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
  }
  button.remove-tag {
    border: none;
    background: transparent;
    i {
      margin: 0 auto;
    }
    &:hover {
      i {
        transform: scale(1.2);
        opacity: 0.6;
      }
    }
  }
`
export const AuthorAvatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
  object-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  i {
    font-size: 12px;
  }
`

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: limegreen;
  margin-right: 5px;
  align-self: center;
`

export const TagsWrapper = styled(Flex)`
  display: flex;
`
export const PostInfo = styled(Flex)`
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  .category-name,
  .user-name,
  .date {
    opacity: 0.6;
  }
  .author-details {
    display: flex;
    align-items: center;
  }
  ${pablet} {
    .bullet {
      display: none;
    }
    .author-details {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-grow: 1;
    }
  }
  .conditional-author-wrapper {
    display: flex;
    flex-wrap: wrap;
    .date {
      width: 100%;
      margin-left: 25px;
    }
    .user-name,
    .date {
      font-size: 12px;
      opacity: 0.6;
    }
  }
`

export const PostWrapper = styled(Flex)`
  ${pablet} {
    width: 80%;
  }
  ${mobile} {
    width: 100%;
  }
`

export const PostDetails = styled(Flex)`
  align-items: flex-start;
  flex-wrap: nowrap;
  textarea {
    border-radius: 10px;
  }
`
export const RatesWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  flex-shrink: 0;
  margin: 0;

  span.count {
    margin: 0 auto;
  }
  .upvoted {
    i {
      color: #4dcea6;
    }
  }
  .downvoted {
    i {
      color: red;
    }
  }
  i {
    color: var(--foreground);
    margin: 0 5px;
  }

  ${pablet} {
    width: 30px;
  }
`

export const ReplyFormWrapper = styled(Flex)`
  form {
    width: 100%;
  }
  .textarea {
    border-radius: 10px;
  }
  .comment-form {
    .input-textarea,
    .toolbar {
      min-width: 300px;
    }
  }

  .pf-c-form {
    max-width: 666px;
    min-width: 666px;

    ${pablet} {
      max-width: 90vw;
      min-width: 90vw;
    }
  }
  .pf-c-form__group {
    max-width: 666px;
    min-width: 666px;

    ${pablet} {
      max-width: 90vw;
      min-width: 90vw;
    }
  }
  .pf-c-form__actions {
    display: flex;
    justify-content: flex-end;
  }
`
export const PostContent = styled(Flex)`
  margin: 0 0 60px 0;
  max-width: 700px;
  .post-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .post-content {
    font-size: 16px;
    a {
      text-decoration: underline;
      color: var(--flavor);
    }
    ${mobile} {
      font-size: 14px;
    }
  }
  form {
    width: 100%;
  }
  .textarea {
    border-radius: 10px;
  }
  .comment-form {
    .input-textarea,
    .toolbar {
      min-width: 300px;
    }
  }

  .pf-c-form {
    max-width: 666px;
    min-width: 666px;

    ${pablet} {
      max-width: 90vw;
      min-width: 90vw;
    }
  }
  .pf-c-form__group {
    max-width: 666px;
    min-width: 666px;

    ${pablet} {
      max-width: 90vw;
      min-width: 90vw;
    }
  }
  .pf-c-form__actions {
    display: flex;
    justify-content: flex-end;
  }

  .topic {
    ul {
      list-style: initial;
    }

    ul,
    ol {
      padding-left: 25px;
    }
  }

  .conditional-topic-wrapper {
    display: flex;
    gap: 10px;
    .post-title {
      font-size: 18px;
    }
  }

  .fr-class-code {
    color: black;
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: 'Courier New', Courier, monospace;
  }

  .fr-class-highlighted {
    background-color: var(--flavor);
    color: var(--flavor-contrast);
  }

  .fr-class-transparency {
    opacity: 0.5;
  }

  iframe {
    height: 300px !important;
    max-width: 650px;
  }
`
export const PostStatistics = styled.div`
  width: 100%;
  margin-bottom: 35px;
  font-size: 14px;
  i {
    margin: 0 10px;
  }
  ${pablet} {
    margin-left: 30px;
    margin-right: 0;
    * {
      font-size: 12px;
    }
  }
`
export const ButtonsWrapper = styled(ActionGroup)`
  margin: 0 !important;
  justify-content: end;
`
export const AuthorName = styled.span`
  font-size: 12px;
  margin-bottom: 15px;
`
export const Description = styled.p`
  font-size: 16px;

  ul,
  ol {
    padding-left: 25px;
  }

  img {
    display: block;
  }

  a {
    text-decoration: underline;
    color: var(--flavor);
  }

  ${pablet} {
    font-size: 14px;
    margin-bottom: 10px;
  }
`
export const ActionsWrapper = styled.div`
  .upvoted {
    i {
      color: #4dcea6;
    }
  }
  .downvoted {
    i {
      color: red;
    }
  }
  i {
    color: var(--foreground);
    margin: 0 5px;
  }
  ${pablet} {
    button {
      font-size: 12px;
    }
  }
`
export const ContentContainer = styled.div`
  margin-left: 55px;
  padding-bottom: 35px;
  margin-bottom: 5px;
  .header {
    font-size: 12px;
    margin-bottom: 15px;
    .name {
    }
    .date {
    }
  }
  ul,
  ol {
    padding-left: 20px;
  }
  ul {
    list-style: initial;
  }

  ${pablet} {
    margin-left: 35px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    .header {
      margin-bottom: 10px;
    }
  }
  ${mobile} {
    margin-left: 23px;
  }

  iframe {
    height: 300px !important;
    max-width: 650px;
  }
`

export const GoBackButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`
export const EditButton = styled.button`
  border: none;
  background-color: transparent;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
  ${pablet} {
    padding: 0;
  }
`
export const ReplyButton = styled.button`
  border: none;
  background-color: transparent;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`
export const RateButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0;
  transition: 0.3s;
  padding: 0;
  &:hover {
    opacity: 0.6;
  }
  &.disabled {
    cursor: default;
    opacity: 0.3;
  }
`

export const OnlineIndicator = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ onlineStatus }) =>
    onlineStatus === 'online' ? '#4dcea6' : '#cecece'};
  border-radius: 100%;
  border: 4px solid var(--background);
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  ${pablet} {
    width: 16px;
    height: 16px;
    border: 3px solid var(--background);
  }
`

export const Comment = styled.div`
  margin-left: 100px;
  display: block;
  border-left: 2px solid var(--foreground);
  &:last-child {
    border: none;
  }
  &.no-margin {
    margin-left: 0;
  }
  ${pablet} {
    margin-left: 38px;
    border-left: none;
  }

  .fr-class-code {
    color: black;
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: 'Courier New', Courier, monospace;
  }

  .fr-class-highlighted {
    background-color: var(--flavor);
    color: var(--flavor-contrast);
  }

  .fr-class-transparency {
    opacity: 0.5;
  }
`

export const CommentsWrapper = styled.div`
  position: relative;
  padding-top: 25px;
  margin-left: ${({ isLoading }) => (isLoading ? '0' : '38px')};
  &:before {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% + 38px);
    background-color: var(--foreground);
    position: absolute;
    top: 0;
    left: -38px;
    opacity: 0.3;
  }

  & > ${Comment} {
    margin-left: 0;
  }

  ${pablet} {
    margin-left: 15px;
    &:before {
      left: -15px;
      width: calc(100% + 15px);
    }
    & > ${Comment} {
      border-left: 2px solid var(--foreground);
    }
  }
`
