import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const Ball = styled.div`
  height: var(--height);
  aspect-ratio: 1 / 1;

  background: var(--background);
  border-radius: 50%;
  box-sizing: box-sizing;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0%;
  transition: .2s;

  i {
    transform: scale(0.6);
  }
`

const Toggle = styled.i`
  --height: 22px;
  --margin: 3px;
  position: relative;
  cursor: pointer;
  
  width: calc(var(--height) * 2);
  height: var(--height);

  background: var(--shade-faint);
  border-radius: 22px;
  box-sizing: content-box;
  padding: 5px;
  outline: var(--border);

  display: flex;
  align-items: center;
  transition: .2s;

  &.checked .ball {
    margin-left: calc(100% - var(--height));
    background: var(--flavor);
    color: var(--flavor-contrast);
  }
`

export const BaseToggle = ({ onClick = () => {}, checked = false, on = "fa fa-cubes", off = "fa fa-cubes"}) => {
  const [toggleChecked, setChecked] = useState(false)

  const onClickHandler = () => {
    setChecked(!toggleChecked)
    onClick(!toggleChecked)
  }

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  return (
    <Toggle onClick={() => onClickHandler()} className={toggleChecked ? "checked" : "unchecked"}>
      <Ball className="ball"><i className={toggleChecked ? on : off}/></Ball>
    </Toggle>
  )
}
