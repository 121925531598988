import React, { useState } from 'react'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import GoogleMaps from 'components/indy/shared/google-maps'
import {
  LocationWrapper,
  OptionsWrapper,
  OptionButton,
  LocationContentWrapper,
  EventMapWrapper,
} from './styles/location'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import omitDeep from 'omit-deep-lodash'

const options = [
  { key: 'ADDRESS', name: 'venue' },
  { key: 'ONLINE', name: 'online' },
  { key: 'HYBRID', name: 'hybrid' },
  { key: 'UNDEFINED', name: 'to be announced' },
]

const Location = ({ onSave, venueLocation, locationObject }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const [activeOption, setActiveOption] = useState(venueLocation.type)

  const ommitedLocation = omitDeep(venueLocation, '__typename')

  const resolveLocationContent = (option) => {
    switch (option) {
      case 'ADDRESS':
        return (
          <>
            {renderField({
              attribute: {
                type: 'location',
                value: venueLocation.address,
              },
              onSave: (f, value) => {
                return onSave({
                  ...ommitedLocation,
                  type: 'ADDRESS',
                  address: value.label,
                  long: `${value.long}`,
                  lat: `${value.lat}`,
                  link: '',
                })
              },
            })}

            <EventMapWrapper>
              <GoogleMaps
                markers={[
                  {
                    ...venueLocation,
                    lng: venueLocation.long,
                  },
                ]}
                onMarkerClick={() => {}}
                getVisibleMarkers={''}
                onBoundsChange={() => {}}
              />
            </EventMapWrapper>
          </>
        )
      case 'ONLINE':
        return renderField({
          attribute: {
            type: 'url',
            value: venueLocation.link,
          },
          onSave: (f, value) =>
            onSave({ ...ommitedLocation, link: value, type: 'ONLINE' }),
        })
      case 'HYBRID':
        return (
          <div>
            {renderField({
              attribute: {
                type: 'url',
                value: venueLocation.link,
              },
              onSave: (f, value) => onSave({ ...ommitedLocation, link: value }),
            })}
            {renderField({
              attribute: {
                type: 'location',
                value: venueLocation.address,
              },
              onSave: (f, value) =>
                onSave({
                  ...ommitedLocation,
                  long: `${value.long}`,
                  lat: `${value.lat}`,
                  address: value.label,
                  type: 'HYBRID',
                }),
            })}
            <EventMapWrapper>
              <GoogleMaps
                markers={[
                  {
                    ...venueLocation,
                    lng: venueLocation.long,
                  },
                ]}
                onMarkerClick={() => {}}
                getVisibleMarkers={''}
                onBoundsChange={() => {}}
              />
            </EventMapWrapper>
          </div>
        )
      case 'UNDEFINED':
        return <></>

      default:
        return <></>
    }
  }

  return (
    <LocationWrapper>
      <div className="label-wrapper">
        <label htmlFor="location-content">
          {intl.formatMessage({
            id: 'events.createevent.general.location.title',
          })}
        </label>
        <p className="location-content-desc">
          {intl.formatMessage(
            {
              id: 'events.createevent.general.location.description',
            },
            {
              event: appLabel('events', 'events.title'),
            }
          )}
        </p>
      </div>
      <div className="location-content-wrapper">
        <OptionsWrapper>
          {options.map((option) => (
            <OptionButton
              isButtonActive={activeOption === option.key}
              onClick={() => {
                onSave(
                  option.key !== locationObject.type
                    ? {
                        long:
                          option.key === 'ADDRESS' || option.key === 'HYBRID'
                            ? locationObject.long !== ''
                              ? locationObject.long
                              : ''
                            : '',
                        lat:
                          option.key === 'ADDRESS' || option.key === 'HYBRID'
                            ? locationObject.lat !== ''
                              ? locationObject.lat
                              : ''
                            : '',
                        address:
                          option.key === 'ADDRESS' || option.key === 'HYBRID'
                            ? locationObject.address !== ''
                              ? locationObject.address
                              : ''
                            : '',
                        link:
                          option.key === 'ONLINE' || option.key === 'HYBRID'
                            ? locationObject.link !== ''
                              ? locationObject.link
                              : ''
                            : '',
                        type: `${option.key}`,
                      }
                    : locationObject
                )
                setActiveOption(activeOption === option.key ? null : option.key)
              }}
            >
              {intl.formatMessage({
                id: `events.createevent.general.location.selectbutton.${option.key.toLowerCase()}`,
              })}
            </OptionButton>
          ))}
        </OptionsWrapper>
        <LocationContentWrapper>
          {resolveLocationContent(activeOption || venueLocation.type)}
        </LocationContentWrapper>
      </div>
    </LocationWrapper>
  )
}

export default Location
