import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { sortResults } from './helpers'

import ReportManageModal from './manage-modal'
import { TableStyled } from '../shared/styles/table'

import { useIntl } from 'react-intl'

const Table = ({ reportsList, setReportsList, refetch, sort, setSort }) => {
  const intl = useIntl()
  const [currentReport, setCurrentReport] = useState()
  const [showModal, setShowModal] = useState()

  const handleSelectReport = (u) => {
    setCurrentReport(u)
    setShowModal(true)
  }

  const onClose = () => {
    setCurrentReport(null)
    refetch()
  }

  const handleSort = (key) => {
    const s = { key, order: key === sort.key ? (sort.order > 0 ? 0 : 1) : 0 }
    setSort(s)
    setReportsList(sortResults({ sort: s, reportsList }))
  }

  const fields = [
    { name: 'Contact person', type: 'contactPerson' },
    { name: 'Message', type: 'message' },
    { name: 'Date updated', value: 'dateStatus', type: 'dateUpdated' },
    { name: 'Status', type: 'status' },
    { name: 'Manage', type: 'manage' },
  ]

  const renderMessage = (value) => {
    return intl.formatMessage({
      id: `admin.table.reports.${value.toLowerCase()}`,
    })
  }

  return (
    <TableStyled>
      <table className="user-overview-table">
        <tr className="first-row">
          {fields.map((f) => {
            return (
              <th>
                {renderMessage(f.type)}
                {f.value && (
                  <button type="button">
                    <i
                      tabIndex={0}
                      role="button"
                      onClick={() => handleSort(f.value)}
                      className={`${
                        sort.key === f.value ? 'active' : ''
                      } fas fa-sort`}
                    />
                  </button>
                )}
              </th>
            )
          })}
        </tr>
        {reportsList.map((singleReport) => {
          const user = singleReport?.user[0]
          const firstName =
            user?.firstName && user?.firstName.trimStart().trimEnd() !== ''
              ? user?.firstName
              : 'N/A'
          const lastName =
            user?.lastName && user?.lastName.trimStart().trimEnd() !== ''
              ? user?.lastName
              : 'N/A'
          return (
            <tr>
              <td>
                {firstName} {lastName}
              </td>
              <td>{singleReport.message}</td>
              <td className="register-date">
                {moment(singleReport.dateStatus).format('DD MMM YYYY HH:mm')}(
                {moment(singleReport.dateStatus).fromNow()})
              </td>
              <td className="text-center">
                <span className="status">
                  {renderMessage(singleReport.status)}
                </span>
              </td>
              <td className="text-center">
                <button
                  onClick={() => handleSelectReport(singleReport)}
                  type="button"
                  data-tip="Manage report"
                >
                  <i className="fas fa-user-cog" />
                </button>
              </td>
            </tr>
          )
        })}
        <ReactTooltip effect="solid" />
      </table>
      {showModal && currentReport ? (
        <ReportManageModal report={currentReport} onClose={onClose} />
      ) : null}
    </TableStyled>
  )
}

export default Table
