import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import ReportModal from 'components/indy/shared/report-modal'
import styled from "@emotion/styled"

import { tablet, pablet } from '_utils/responsive-queries'
import { IconBox } from "_components/icon-picker.jsx"
import { CookieAccept } from "./cookie-consent"
import { LegacyFooter } from './legacy-footer'

const GetFooterQuery = gql` 
  query GetFooter {
    getFooter {
      logo
      media {
        icon
        url
      }
      text {
        title
        text
      }
      menus {
        title
        visible
        links {
          title
          url
        }
      }
    }
  }
`

const FooterStyled = styled.div`  
  border-top: var(--border);
  background-color: var(--background);
  padding: var(--space-l) 0px;

  h4:first-letter, 
  p:first-letter { text-transform: capitalize; }
  h4 { margin-bottom: var(--space-s) }
  p { margin: 0px; }


`

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: var(--space-xl);
  row-gap: var(--space-l);

  ${tablet} {
    margin-left: 50px;
    margin-right: 50px;
  }

  ${pablet} {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0px var(--space-l);
  }
`

const MediaStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--space-s);

  .logo {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    width: min-content;
  }
`

const TextStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 55ch;
`

const MenusStyled = styled.div`
  display: flex;
  column-gap: var(--space-xl);
  row-gap: var(--space-l);
  justify-content: flex-end;
  grid-column: 3;

  @media (max-width: 980px) {
    justify-content: flex-start;
    grid-column: 2;
    grid-row: 2;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
    grid-column: auto;
    grid-row: auto;
  }
`

const SubmenuStyled = styled.div`
  display: flex;
  flex-direction: column;

  h4 { cursor: default; }
  p { cursor: pointer; }
`


const Index = () => {
  const { data, loading } = useQuery(GetFooterQuery)
  const [footer, setFooter] = useState(false)

  useEffect(() => {
    data?.getFooter && setFooter(data?.getFooter)
  }, [loading])

  let location = useLocation()

  const hideFooter =
    location.pathname === '/user/messages' ||
    location.pathname === '/user/notifications-center'

  return (
    <>
      {!hideFooter && <div>
        {footer 
          ? <NewFooter footer={footer}/>
          : <LegacyFooter/>}
        </div>}
      <CookieAccept/>
    </>
  )
}

const NewFooter = ({footer}) => {
  const mediaExists = footer.media && footer.media.length > 0
  const textExists = footer.text && footer.text.title && footer.text.text
  return (
    <FooterStyled className="frontpage-footer-section">
      <FooterContent className="page-wrapper">
        {mediaExists && <FooterMedia footer={footer}/>}
        {textExists && <FooterText footer={footer}/>}
        {footer.menus && <FooterMenus footer={footer}/>}
      </FooterContent>
    </FooterStyled>
  )
}

const FooterMedia = ({footer}) => {
  const { media, logo } = footer
  return (
    <MediaStyled>
      {logo && <FooterLogo logo={logo}/>}
      {media.map((m) => (
        <a href={m.url} aria-label='Social media link' key={m.url}>
          <IconBox icon={m.icon}/>
        </a>
      ))}
    </MediaStyled>
  )
}

const FooterLogo = ({ logo }) => {
  return (
    <div className="logo" data-testid="footer-logo">
      <Link to={'/'}>
        <img src={logo} width="50" />
      </Link>
    </div>
  )
}

const FooterText = ({footer}) => {
  return (
    <TextStyled>
      <h4 data-testid="footer-text">
        <b>{footer.text?.title}</b>
      </h4>
      <p>{footer.text?.text}</p>
    </TextStyled>
  )
}

const FooterMenus = ({footer}) => {
  const { menus } = footer
  const [modal, setModal] = useState(false)
  const isLast = (index) => index === menus.length - 1
  return (
    <MenusStyled>
      {menus.map((m, i) => (
        <SubmenuStyled>
          {m.visible && <Submenu menu={m}/>}
          {isLast(i) && <p onClick={() => setModal(true)} data-testid="btn-report" role="link">Report</p>}
        </SubmenuStyled>
      ))}
      {modal && (
        <ReportModal
          canShowModal={modal}
          setModalVisible={setModal}
        />
      )}
    </MenusStyled>
  )
}

const Submenu = ({menu}) => {
  const links = menu.links
  return (
    <div key={menu.title}>
      <h4><b>{menu.title}</b></h4>
      {links.map((l) => <IndyLink link={l}/>)}
    </div>
  )
}

const IndyLink = ({link}) => {
  const { url, title } = link
  const first = url.charAt(0)
  const isSlash = first === '/'
  return (
    <>
      {isSlash 
        ? <Link to={url}><p>{title}</p></Link>
        : <a href={url} aria-label={`${title} link`}><p>{title}</p></a>
      }
    </>
  )
}

export default Index
