import styled from '@emotion/styled'
import { mobile, pablet } from '_utils/responsive-queries'
import border from '_utils/css-border'

export const TopSectionContainer = styled.div`
  height: 100%;
  position: sticky;
  top: 0;
  width: 96%;
  margin: auto;
`

export const TileWrapper = styled.div`
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
`

export const TocWrapper = styled.div`
  padding: 20px 15px 5px;

  .tocMainHeader {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0 20px;
  }
`

export const TocListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  max-height: 100vh;
`

export const TocItemWrapper = styled.li`
  display: flex;
  padding-bottom: 10px;
  cursor: pointer;

  h1,
  h2,
  h3 {
    font-size: 12px;
  }

  &.active h1,
  &.active h2,
  &.active h3 {
    color: var(--flavor);
  }

  &.active > div:before {
    border-color: var(--flavor);
  }

  :last-child div::after {
    display: none;
  }
`

export const TocItem = styled.div`
  padding: 3px 20px;
  position: relative;
  display: block;

  .h1,
  h1 {
    font-size: 15px;
    font-weight: 700;
    margin-left: 4px;
  }

  .h2,
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-left: ${({ includesH1 }) => (includesH1 ? '18px' : '4px')};
  }

  .h3,
  h3 {
    font-size: 13.5px;
    font-weight: 400;
    margin-left: ${({ includesH1 }) => (includesH1 ? '36px' : '18px')};
  }

  ::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 7.5px;
    top: 17px;
    height: 100%;
    border-left: 3.5px var(--flavor-contrast) solid;
    width: 4px;
  }

  ::before {
    content: '';
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 5.5px;
    border-radius: 50%;
    background: transparent;
    border: 3.5px solid;
    border-color: var(--flavor-contrast);
    width: 14px;
    height: 14px;
    margin: 0 2px;
  }
`

export const Avatar = styled.img`
  border-radius: 100%;
  display: block;
  height: 35px;
  width: 35px;
  object-fit: cover;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 16%);
`

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;
  gap: 12px 5px;
  margin: 25px 0px 12px;

  ${pablet} {
    width: 75vw;
  }

  .avatarImgWrapper {
    position: relative;
  }

  .creatorName {
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
  }

  .publishDate {
    font-weight: 400;
  }
`

export const OnlineIndicator = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid white;
  border-radius: 100%;
  bottom: 0;
  right: 2.5px;
  background-color: ${({ onlineStatus }) =>
    onlineStatus === 'ONLINE' ? '#4dcea6' : '#4a4a4a'};
  z-index: 1;
`

export const BlogStats = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
  .likes,
  .comments {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 12px;
    font-weight: 700;
    i {
      color: var(--foreground);
      font-size: 16px;
    }
  }
  .like-button {
    border: none;
    background-color: transparent;
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-items: space-between;
  button {
    border: none;
    background-color: transparent;
  }
  .buttons-wrapper {
    ${border()}
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    padding: 4px 0;
    margin-bottom: 22px;
    border-radius: 10px 10px 0 10px;
    background-color: var(--background);
  }

  ${mobile} {
    .main-recommend-container {
      border-bottom: 1px solid var(--shade);
    }
    button {
      border-bottom: 1px solid var(--shade);
      background-color: transparent;
      padding: 18px;
      text-align: left;
      &:nth-last-of-type(1) {
        border: none;
      }
      i {
        margin-right: 12px;
      }
      &.sharebutton {
        padding: 0;
        i {
          margin: 0;
        }
      }
    }
    .buttons-wrapper {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
      border-radius: 0;
      background-color: transparent;
      margin: 0;
      button {
        background-color: var(--background);
        &.mobile-cancel-button {
          margin-top: 8px;
          text-align: center;
        }
      }
    }
  }
`

