import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  post: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
}

export const Tile = themable('BlogTile', Props)
export default Tile
