import gql from 'graphql-tag'

export const UPDATE_ACCESS_PAGE = gql`
  mutation updateAccessPage($accessPage: AccessPageInput) {
    updateAccessPage(accessPage: $accessPage) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`

export const GET_ACCESS_KEY = gql`
  query {
    getAccessKey
  }
`
