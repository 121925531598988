import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { TileWithIcon } from '_components/tile'
import EXPERIENCE_ICON from 'resources/experience-icon.png'

const Props = {
  experience: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
}
const ExperienceTile = ({ experience, className }) => (
  <TileWithIcon className={className} icon={EXPERIENCE_ICON}>
    <Body>
      <Title>{experience.companyName}</Title>
      <Domain>{experience.title}</Domain>
      <WorkPeriod>
        {experience.workedFrom} - {experience.workedTo}
      </WorkPeriod>
      <Location>{experience.location}</Location>
    </Body>
  </TileWithIcon>
)
ExperienceTile.propTypes = Props

const Body = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
`
const Title = styled.div`
  font-size: 16px;
  color: '#525252';
  font-weight: 500;
`
const Domain = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  color: #999;
`
const WorkPeriod = styled.div`
  font-size: 14px;
  color: #525252;
  font-weight: 500;
`
const Location = styled.div`
  margin-bottom: 1rem;
  font-size: 12px;
  color: #999;
`

export default ExperienceTile
