import { useState } from 'react'

export const desktopXLPX = 1920
export const tabletPX = 1250
export const pabletPX = 850
export const mobilePX = 600

//Min
export const desktopXL = `@media(min-width: ${desktopXLPX}px)`
export const desktop = `@media(min-width: ${tabletPX}px)`
export const mobileUp = `@media(min-width: ${mobilePX}px)`
//Max

export const tablet = `@media(max-width: ${tabletPX}px)`
export const pablet = `@media(max-width: ${pabletPX}px)`
export const mobile = `@media(max-width: ${mobilePX}px)`

export const useWindowResize = () => {
  const [windowQuery, setWindowQuery] = useState(window.innerWidth)
  function reportWindowSize() {
    setWindowQuery(window.innerWidth)
  }
  window.onresize = reportWindowSize
  return [windowQuery]
}
