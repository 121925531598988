import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile, tablet } from '_utils/responsive-queries'

import circleButton, { ctaButtonHollow } from '_utils/css-buttons'
import { themeIsDark } from '_colorBus/primitives/scheme'
import { css } from '@emotion/core'

export const EventDetailsWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 30px auto;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  background: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  ${({ isPreview }) =>
    !isPreview &&
    css`
      border-radius: 0 0 15px 15px;
      margin-top: 110px;
    `}
`

export const EventInfo = styled.div``
export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  iframe {
    width: 100%;
  }

  ${mobile} {
    padding: 0;
  }
`
export const ConnectionsWrapper = styled.div``

export const BackgroundWrapper = styled.div`
  width: 100%;
`

export const BackgroundImage = styled.img`
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center;

  ${mobile} {
    height: 250px;
  }
`

export const GoBackButton = styled.button`
  ${ctaButtonHollow()}
  margin: 65px 0;
  i {
    margin-right: 10px;
  }
`

export const TabsWrapper = styled.div`
  max-width: 825px;
`

export const UserWrapper = styled.div`
  ${border()}
  background-color: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};
  max-width: 825px;
  padding: 40px 40px 50px;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 20px;
  margin: 0 auto 20px;
  ${tablet} {
    padding: 30px 50px;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
  }
  ${mobile} {
    padding: 30px 15px;
  }
`

export const BottomBackButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
  button {
    ${ctaButtonHollow};
  }
`

export const ContentWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  ${tablet} {
    width: 90%;
  }
  ${mobile} {
    width: 100%;
    padding: 0 16px;
  }
`

export const TopBackButtonWrapper = styled.div`
  position: absolute;
  margin: 25px 0 0 25px;
  button {
    ${circleButton}
    background-color: rgba(0,0,0,.1);
    i {
      color: white;
    }
  }
`

export const BlurBackgroundImageWrapper = styled.div`
  position: absolute;
  top: 110px;
  height: 525px;
  width: 105%;
  overflow: hidden;
  filter: blur(6px);

  img {
    margin-top: -25px;
    height: auto;
    width: 110%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
`
