import styled from '@emotion/styled'

const ModalStyled = styled.div`
  padding: 10px 15px;
  background-color: var(--background);

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--foreground);
  }
  .modal-content {
    border: none;
    background: transparent;
    margin-top: 20px;
    .field {
      .field-title {
        display: block;
        font-size: 14px;
        color: var(--foreground);
        font-weight: 500;
        margin: 5px 0 10px;
      }
    }
  }
  .save-button {
    display: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }
`

export default ModalStyled
