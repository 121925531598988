import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { GET_EVENT, CREATE_EVENT, UPDATE_EVENT } from './gql'
import GeneralInformation from './general-information'
import AdditonalInformation from './additonal-information'
import Preview from './preview-publish'
import { useLocation } from 'react-router-dom'
import { useCustomAttributes } from '_custom-attributes/hooks'
import { MbToB } from 'services/file'
import { useAppConfig } from '_application'
import { useUser } from '_security/session-hooks'
import omitDeep from 'omit-deep-lodash'
import moment from 'moment'

const EventCreator = () => {
  const [event, setEvent] = useState({
    id: null,
    title: '',
    excerpt: '',
    description: '',
    bannerImage: null,
    venueLocation: {
      type: 'UNDEFINED',
      address: '',
      link: '',
      lat: '',
      long: '',
    },
    categories: [],
    keywords: [],
    topics: [],
    speakers: [],
    companies: [],
    activities: [],
    attributes: {},
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    doorsOpen: new Date().toISOString(),
    step: 1,
    status: '',
    publishAt: new Date().toISOString(),
  })

  const [createEvent] = useMutation(CREATE_EVENT)
  const location = useLocation()
  const eventId = location.pathname.slice(location.pathname.length - 24)
  const { tenantId, eventsCategories } = useAppConfig()
  const { sub } = useUser()
  const currentLocation = location?.pathname.substring(
    15,
    location?.pathname.length - 25
  )
  const { attributes } = useCustomAttributes('events')

  const { data, refetch } = useQuery(GET_EVENT, {
    variables: { getEventId: eventId },
  })

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    variables: {
      event: {
        ...event,
      },
    },
  })

  const handleUpdateEvent = () => {
    updateEvent()
      .then((res) => {
        console.log(res, 'After update')
        // history.push(
        //   `/events/manage/additonal-information/${res.data.updateEvent.id}`
        // )
      })
      .catch((err) => console.log(err))
  }

  const setCurrentStepAndStatus = () => {
    switch (currentLocation) {
      case 'general-information':
        return { step: 1, status: 'PENDING' }
      case 'additonal-information':
        return { step: 2, status: 'PENDING' }
      case 'preview-information':
        return { step: 3, status: 'PUBLISHED' }
      default:
        return { step: 1, status: 'PENDING' }
    }
  }
  // name to refactor
  const setEmptyCustomAttributes = () => {
    let tempCustomAttributes = {
      texts: [],
      urls: [],
      dates: [],
      phones: [],
      emails: [],
      images: [],
      booleans: [],
      badges: [],
      files: [],
      locations: [],
    }
    let omittedCustomAttributes = omitDeep(
      data?.getEvent.attributes,
      '__typename'
    )

    if (attributes && omittedCustomAttributes) {
      attributes.forEach(({ key, type }) => {
        switch (type) {
          case 'boolean':
            tempCustomAttributes = {
              ...tempCustomAttributes,
              booleans: [
                ...tempCustomAttributes.booleans,
                {
                  key,
                  value:
                    omittedCustomAttributes?.booleans.find(
                      (item) => item.key === key
                    )?.value || false,
                },
              ],
            }
            break
          case 'file':
            tempCustomAttributes = {
              ...tempCustomAttributes,
              files: [
                ...tempCustomAttributes.files,
                {
                  key,
                  value:
                    omittedCustomAttributes?.files.find(
                      (item) => item.key === key
                    )?.value || [],
                },
              ],
            }

            break

          case 'badge':
            tempCustomAttributes = {
              ...tempCustomAttributes,
              badges: [
                ...tempCustomAttributes.badges,
                {
                  key,
                  value:
                    omittedCustomAttributes?.badges.find(
                      (item) => item.key === key
                    )?.value || [],
                },
              ],
            }
            break

          case 'image':
            tempCustomAttributes = {
              ...tempCustomAttributes,
              images: [
                ...tempCustomAttributes.images,
                {
                  key,
                  value:
                    omittedCustomAttributes?.images.find(
                      (item) => item.key === key
                    )?.value || [],
                },
              ],
            }
            break

          case 'location':
            tempCustomAttributes = {
              ...tempCustomAttributes,
              locations: [
                ...tempCustomAttributes.locations,
                {
                  key,
                  lat: '',
                  long: '',
                  city:  omittedCustomAttributes?.locations.find(
                    (item) => item.key === key
                  )?.city || '',
                  country: '',
                  label: '',
                },
              ],
            }
            break
          default:
            tempCustomAttributes = {
              ...tempCustomAttributes,
              [`${type}s`]: [
                ...tempCustomAttributes[`${type}s`],
                {
                  key,
                  value:
                    omittedCustomAttributes[`${type}s`].find(
                      (item) => item.key === key
                    )?.value || '',
                },
              ],
            }
        }
      })
    }

    return tempCustomAttributes
  }
 
  useEffect(() => {
    if (location.pathname === '/events/manage') {
      createEvent()
        .then((res) => {
          setEvent({
            ...event,
            id: res.data.createEvent,
            // attributes: setEmptyCustomAttributes(),
            status: setCurrentStepAndStatus().status,
            step: setCurrentStepAndStatus().step,
            attributes: setEmptyCustomAttributes(),
          })
        })
        .catch((err) => console.log(err, 'error'))
    } else {
      // todo: get ca from existing event
      const ommitedCategories = omitDeep(
        data?.getEvent.categories,
        '__typename'
      )

      const availableCategories = ommitedCategories?.filter((cat) =>
        eventsCategories.some((ecat) => ecat.id === cat.key)
      )

      const eventSelectedCategories = availableCategories?.map((cat) => {
        const category = eventsCategories.find((eve) => eve.id === cat.key)

        return {
          ...cat,
          childrenKeys: cat.childrenKeys?.filter((sub) =>
            category.subcategories.some((item) => item.id === sub)
          ),
        }
      })

      const ommitedLocation = omitDeep(
        data?.getEvent.venueLocation,
        '__typename'
      )
      setEvent({
        ...event,
        id: data?.getEvent.id,
        title: data?.getEvent.title,
        bannerImage: data?.getEvent.bannerImage,
        excerpt: data?.getEvent.excerpt,
        start: data?.getEvent.start || new Date().toISOString(),
        end: data?.getEvent.end || new Date().toISOString(),
        doorsOpen: data?.getEvent.doorsOpen || new Date().toISOString(),
        description: data?.getEvent.description,
        attributes: setEmptyCustomAttributes(),
        keywords: data?.getEvent.keywords || [],
        topics: omitDeep(data?.getEvent.topics, '__typename') || [],
        speakers: omitDeep(data?.getEvent.speakers, '__typename') || [],
        companies: omitDeep(data?.getEvent.companies, '__typename') || [],
        activities: omitDeep(data?.getEvent.activities, '__typename') || [],
        // status: setCurrentStepAndStatus().status,
        status:
          currentLocation !== 'preview-information'
            ? data?.getEvent.status
            : 'PUBLISHED',
        // step: setCurrentStepAndStatus().step,
        categories: eventSelectedCategories,
        venueLocation: ommitedLocation,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, attributes])

  const uploadConfig = {
    folderName: 'events',
    tagsConfig: {
      userId: sub,
      tenantId: tenantId,
      context: 'custom-attributes-event-images',
      mediaId: event?.id,
    },
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const eventAttributesConfig = {
    text: {
      config: {
        placeholder: 'Write short text',
      },

      onSave: (key, value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            texts: event.attributes.texts.map((attr) => {
              return attr.key === key ? { ...attr, value } : attr
            }),
          },
        }),
    },

    email: {
      config: {
        placeholder: 'email@domain.com',
      },

      onSave: (key, value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            emails: event.attributes.emails.map((attr) => {
              return attr.key === key ? { ...attr, value } : attr
            }),
          },
        }),
    },

    url: {
      config: {
        placeholder: 'https://',
      },

      onSave: (key, value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            urls: event.attributes.urls.map((attr) => {
              return attr.key === key ? { ...attr, value } : attr
            }),
          },
        }),
    },

    boolean: {
      onSave: (key, value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            booleans: event.attributes.booleans.map((attr) => {
              return attr.key === key ? { ...attr, value } : attr
            }),
          },
        }),
    },

    badge: {
      onSave: (key, value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            badges: event.attributes.badges.map((attr) => {
              return attr.key === key ? { ...attr, value } : attr
            }),
          },
        }),

      onDelete: (key, badgeKey) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            badges: event.attributes.badges.map((attr) => {
              return attr.key === key
                ? {
                    ...attr,
                    value: attr.value.filter((el) => el !== badgeKey),
                  }
                : attr
            }),
          },
        }),
    },

    image: {
      config: {
        limit: 5,
        uploadConfig,
      },
      onSave: (value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            images: event.attributes.images.map((attr) => {
              return attr.key === value.key
                ? {
                    ...attr,
                    value: [...attr.value, value.media?.url],
                  }
                : attr
            }),
          },
        }),

      onDelete: (key, index) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            images: event.attributes.images.map((attr) => {
              return attr.key === key
                ? {
                    ...attr,
                    value: attr.value.filter((el, i) => i !== index),
                  }
                : attr
            }),
          },
        }),
    },

    file: {
      config: {
        limit: 5,
        uploadConfig: {
          ...uploadConfig,
          tagsConfig: {
            ...uploadConfig.tagsConfig,
            context: 'custom-attributes-event-files',
          },
          maxImageFileSize: MbToB(5),
          allowedFormats: ['pdf'],
        },
      },
      onSave: (value) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            files: event.attributes.files.map((attr) => {
              return attr.key === value.key
                ? {
                    ...attr,
                    value: [
                      ...attr.value,
                      {
                        url: value.media?.url,
                        name: value.media?.original_filename,
                        extension: value.media?.format,
                        dateCreated: moment(new Date()).format('YYYY-MM-DD'),
                        dateUpdated: moment(new Date()).format('YYYY-MM-DD'),
                        type: 'file',
                      },
                    ],
                  }
                : attr
            }),
          },
        }),

      onDelete: (key, index) =>
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            files: event.attributes.files.map((attr) => {
              return attr.key === key
                ? {
                    ...attr,
                    value: attr.value.filter((el, i) => i !== index),
                  }
                : attr
            }),
          },
        }),
    },
    location: {
      onSave: (key, value) => {
        setEvent({
          ...event,
          attributes: {
            ...event.attributes,
            locations: event.attributes.locations.map((attr) => {
              return attr.key === key ? { ...attr, city: value } : attr
            }),
          },
        })
      }
     
    },

    date: {
      onSave: (key, value) =>
      setEvent({
        ...event,
        attributes: {
          ...event.attributes,
          dates: event.attributes.dates.map((attr) => {
            return attr.key === key ? { ...attr, value } : attr
          }),
        },
      }),
    }
  }

  return (
    <>
      {event?.id && (
        <Switch>
          <Route
            path="/events/manage/general-information/:eventId"
            render={() => (
              <GeneralInformation
                event={event}
                setEvent={setEvent}
                updateEvent={handleUpdateEvent}
                locationObject={data?.getEvent.venueLocation}
              />
            )}
          />
          <Route
            path="/events/manage/additonal-information/:eventId"
            render={() => (
              <AdditonalInformation
                event={event}
                setEvent={setEvent}
                refetchEventData={refetch}
                eventAttributesConfig={eventAttributesConfig}
                updateEvent={handleUpdateEvent}
              />
            )}
          />

          <Route
            path="/events/manage/preview-information/:eventId"
            render={() => (
              <Preview
                event={event}
                updateEvent={handleUpdateEvent}
                setEvent={setEvent}
              />
            )}
          />

          <Redirect to={`/events/manage/general-information/${event?.id}`} />
        </Switch>
      )}
    </>
  )
}

export default EventCreator
