import styled from '@emotion/styled'
import {
  GridItem,
  Modal,
  Button,
  Switch,
  SearchInput,
} from '@patternfly/react-core'
import { ctaButton } from '_utils/css-buttons'
import border from '_utils/css-border'
import scroll from '_utils/css-scroll'
import { mobile, pablet, tablet } from '_utils/responsive-queries'

export const ToggleButton = styled(Switch)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  font-size: 14px;
  border: none !important;
  span.pf-c-switch__label {
    color: var(--foreground) !important;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`

export const CancelButton = styled(Button)`
  color: var(--foreground) !important;
  text-decoration: underline;
`
export const AddButton = styled(Button)`
  ${ctaButton}
`

export const CustomizedModal = styled(Modal)`
  width: 50%;
  border-radius: 25px;
  color: var(--foreground);
  background-color: var(--background);
  .pf-c-form__actions {
    justify-content: flex-end;
  }
  .pf-c-button.pf-m-plain {
    display: none;
  }
  .pf-c-modal-box__body {
    padding-bottom: 0;
    ${scroll()}
  }
  .pf-c-modal-box__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
  }
  .pf-c-form__actions {
    padding-bottom: 15px;
  }
  .add-tags-button {
    border: none;
    background-color: transparent;
    font-size: 12px;
    text-decoration: underline;
    font-weight: 500;
    padding: 0;
    i {
      margin-right: 10px;
    }
  }
  .fr-wrapper {
    height: 300px !important;
  }

  ${tablet} {
    width: 80%;
  }

  ${pablet} {
    width: 100%;
  }
`

export const AddTopicButton = styled.button`
  ${ctaButton}
`

export const PageWrapper = styled.div`
  .comment-form {
    .input-textarea,
    .toolbar,
    input {
      max-width: 40%;
      min-width: 300px;
    }
  }
`

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0 25px;
  min-height: 42px;
  .category-header-wrapper {
    max-width: 80%;
  }
  .category-description {
    margin-top: 5px;
    padding-right: 25px;
  }
  .category-title {
    padding-right: 25px;
    margin-bottom: 10px;
  }
  ${mobile} {
    flex-direction: column;
    .category-header-wrapper {
      max-width: 100%;
    }
    .category-description,
    .category-title {
      padding-right: 0;
    }
  }
`

export const CategoryAvatar = styled.div`
  background-color: var(--flavor);
  border-radius: 50%;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
`

export const TopicAvatar = styled.div`
  ${border()}
  background-color: var(--foreground);
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
  height: 35px;
  margin-right: 8px;
  flex-shrink: 0;
  img {
    border-radius: 50%;

    width: 35px;
    height: 35px;
  }
`

export const PageSection = styled.div`
  align-self: center;
  .item-name {
    font-size: 16px;
    font-weight: 400;
  }
`

export const Counter = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`
export const Description = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* padding: 0 20px; */
  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .description {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  .author {
    display: flex;
    align-items: center;
    font-size: 12px;

    ${tablet} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px 0;
      gap: 5px;
      .bullet {
        display: none;
      }
    }
  }
`
export const PopularWrapper = styled(GridItem)`
  display: flex;
  margin-bottom: 30px;
`
export const CountersWrapper = styled(GridItem)`
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: flex-start;
  height: 75px;
  width: auto;
  padding: 0 5px;
  gap: 24px;

  ${pablet} {
    flex-grow: 1;
    width: auto;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    padding: 10px 0;
    margin-left: 90px;
  }
  ${mobile} {
    margin-left: 60px;
  }
`
export const LatestWrapper = styled(GridItem)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  word-break: break-word;
  span {
    font-size: 12px;
  }

  .author-name {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }
`
export const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 7px solid var(--flavor);
  padding-left: 20px;
  min-height: 80px;
  .author-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  p {
    margin: 6px 0 0;
  }
  .content-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const SearchResults = styled.div`
  padding: 10px;
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  background-color: var(--background);
  border-radius: 0;
  ${scroll()}
`
export const SingleResults = styled.div``

export const SingleResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    font-weight: 600;
  }
`
export const SingleTag = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  i {
    transition: 0.3s;
    margin-right: 10px;
    font-size: 12px;
  }

  a {
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
  }
  button.remove-tag {
    border: none;
    background: transparent;
    i {
      margin: 0 auto;
    }
    &:hover {
      i {
        transform: scale(1.2);
        opacity: 0.6;
      }
    }
  }
`
export const CustomizedInput = styled(SearchInput)`
  position: relative;
  padding: 10px;
  margin: 0 !important;
  .pf-c-search-input__text::after {
    display: none;
  }
  &::after {
    content: '${({ labelText }) => labelText}';
    position: absolute;
    display: block;
    bottom: -16px;
    left: 10px;
    font-size: 14px;
    color: var(--foreground);
    opacity: 0.7;
  }
`

export const TagsMainWrapper = styled.div`
  position: absolute;
  top: 565px;
  background-color: var(--background);
  width: 40%;
  max-height: 258px;
  z-index: 3;

  ${pablet} {
    width: calc(100% - 48px);
    left: 24px;
  }
`

export const TagsInputWrapper = styled.div`
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.06);

  ${pablet} {
    width: 100%;
  }
  .tags-helptext {
    padding: 5px 10px;
  }
  .pf-c-search-input {
    margin-top: 20px;
  }
`

export const AllTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 30px;
  max-height: 220px;
  overflow-y: auto;
  ${scroll()};
  padding: 15px 0 10px;
  min-height: 48px;
`
