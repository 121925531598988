import React from 'react'
import { nextStep } from '../services/step'
import { useIntl } from 'react-intl'

import WelcomeStyled from './styles/welcome'

import { Button } from './shared/button'

const Welcome = ({ step, setStep, stepList }) => {
  const handleStepChange = () => {
    setStep(nextStep(step, stepList))
  }
  const intl = useIntl()
  const currentStep = stepList[step.currentStep].key

  return (
    <WelcomeStyled>
      <div className="step-content-container background">
        <h3>
          {intl.formatMessage({
            id: `user.onboarding.step.${currentStep}.title`,
          })}
          {step.currentStep === 0 && <i className="fas fa-star" />}
        </h3>
        <Button handleClick={handleStepChange} option="start">
          {intl.formatMessage({
            id: 'user.onboarding.step.welcome.startbutton',
          })}
        </Button>
      </div>
    </WelcomeStyled>
  )
}

export default Welcome
