import React from 'react'
import { useSelector } from 'react-redux'
import MeetCallModal from '../components/meet-call-modal'
import { useWaitForAnswer } from '../meet-hooks'
import { selectCurrentCall } from '../meet-reducers'
import { notification } from 'antd'
import { isWebRTCSupported } from '_utils/isWebRTCSupported'
import { useIntl } from 'react-intl'

export const MeetGlobalCall = () => {
  const currentCall = useSelector(selectCurrentCall)
  const cancelCall = useWaitForAnswer(currentCall)
  const intl = useIntl()

  const notifyWebRTCUnsupported = {
    message: intl.formatMessage({
      defaultMessage: 'Calls Not Supported',
      id: 'notification.webRTCUnsupported.message',
    }),
    description: intl.formatMessage({
      defaultMessage:
        'Try another browser or a mobile device to make and receive calls',
      id: 'notification.webRTCUnsupported.description',
    }),
    type: 'warning',
  }

  if (currentCall) {
    if (!isWebRTCSupported()) {
      cancelCall()
      notification.warning(notifyWebRTCUnsupported)
      return null
    }
    return (
      <MeetCallModal
        callState={currentCall.state}
        callData={currentCall}
        onDisconnect={cancelCall}
      />
    )
  } else {
    return null
  }
}

export default MeetGlobalCall
