import styled from '@emotion/styled'

export const SingleStyled = styled.div`
  display: ${({ isBoolean }) => isBoolean && 'flex'};
  margin-bottom: 10px;
  overflow: hidden;
  .name {
    display: block;
    font-weight: 600;
    font-size: 16px;
  }
  .modal-content {
    border-radius: 20px;
    border: 1px solid black;
  }
  i {
    color: var(--foreground);
    width: 20px;
    margin-right: 20px;
    font-size: 18px;
  }
  img.thumb-up {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  text-decoration: ${({ type }) => (type === 'url' ? 'underline' : 'none')};
`

export const TextSection = styled.span`
  margin-bottom: 4px;
  a {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
  }
`

export const ImageStyled = styled.div`
  .img-link {
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const CloseButton = styled.button`
  border-radius: 10px;
  border: none;
  padding: 5px 20px;
  color: var(--foreground);
  background: var(--shade);
  transition: 0.3s;
  &:hover {
    background-color: var(--flavor);
    color: var(--background);
  }
`
