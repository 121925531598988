import styled from '@emotion/styled'

export const AvatarsStyled = styled.div`
  position: relative;
  .count {
    bottom: 20px;
    position: absolute;
    right: -10px;
    border-radius: 25px;
    font-size: 12px;
    padding: 3px;
    color: var(--background);
    background: var(--foreground);
    min-width: 23px;
    min-height: 23px;
  }
  img {
    margin-left: -25px;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    height: 70px;
    object-fit: cover;
    width: 70px;
    margin-top: -44px;
    margin-left: -22px;
    flex-shrink: 0;
  }
`

export const Multiple = styled.div`
  position: relative;
  display: inline;
`

export const Single = styled.div`
  display: flex;

  span {
    color: var(--foreground);
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /* 1199 */
  img {
    margin-left: 0;
    @media (min-device-width: 1200px) and (max-device-width: 1209px) {
      width: 55px;
      height: 55px;
    }
    @media (min-device-width: 1210px) and (max-device-width: 1269px) {
      width: 60px;
      height: 60px;
    }
    @media (min-device-width: 1270px) and (max-device-width: 1289px) {
      width: 62px;
      height: 62px;
    }
    @media (min-device-width: 1290px) and (max-device-width: 1370px) {
      width: 65px;
      height: 65px;
    }
  }
`

export const MultiImage = styled.img`
  &.multiple {
    position: relative;
    z-index: ${({ index }) => `${3 - index}`};
    display: inline-block;
    margin-left: -25px;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    height: 70px;
    object-fit: cover;
    width: 70px;
    margin-left: -25px;

    @media (min-device-width: 1001px) and (max-device-width: 1029px) {
      width: 62px;
      height: 62px;
    }

    @media (min-device-width: 1200px) and (max-device-width: 1209px) {
      width: 55px;
      height: 55px;
    }
    @media (min-device-width: 1210px) and (max-device-width: 1269px) {
      width: 60px;
      height: 60px;
    }
    @media (min-device-width: 1270px) and (max-device-width: 1289px) {
      width: 62px;
      height: 62px;
    }
    @media (min-device-width: 1290px) and (max-device-width: 1370px) {
      width: 65px;
      height: 65px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`
