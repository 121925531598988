import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Props = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

const TileWithIcon = ({ icon, children, className }) => (
  <Container className={className}>
    <IconWrapper>
      <Icon src={icon} />
    </IconWrapper>

    <Wrapper>{children}</Wrapper>
  </Container>
)
TileWithIcon.propTypes = Props

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  border-radius: 25px;
  width: 100%;
  margin: 20px 0;
`

const Wrapper = styled.div`
  background-color: var(--background);
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--flavor);
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
`

export default TileWithIcon
