import { gql } from '@apollo/client'

export const GET_APPLICATIONS_FOR_OPPORTUNITY = gql`
  query GetApplicationsPaged(
    $categoryFilter: String
    $stateFilters: [String]
    $paging: PagingInput
    $sorting: String
    $opportunityId: String
  ) {
    getApplicationsPaged(
      categoryFilter: $categoryFilter
      stateFilters: $stateFilters
      paging: $paging
      sorting: $sorting
      opportunityId: $opportunityId
    ) {
      count
      offset
      hasMore
      totalCount
      lists {
        name
        applicantCount
      }
      data {
        _id
        state
        category
        dateCreated
        parentId
        creator {
          id
          sub
          firstName
          middleName
          lastName
          avatar
          location
        }
      }
    }
  }
`

export const SAVE_APPLICATION = gql`
  mutation SaveApplication($application: EntityInput) {
    saveApplication(application: $application) {
      state
    }
  }
`

export const INVITE_APPLICATION_MANAGER = gql`
  mutation InviteApplicationManager($opportunityId: String!, $userId: String!) {
    inviteApplicationManager(opportunityId: $opportunityId, userId: $userId) {
      _id
    }
  }
`

export const REMOVE_APPLICATION_MANAGER = gql`
  mutation RemoveManager($entityId: String!, $userId: String!) {
    removeManager(entityId: $entityId, userId: $userId) {
      userId
    }
  }
`
