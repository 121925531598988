import styled from '@emotion/styled'

export const InputCustomStyled = styled.div`
  .input {
    display: inline-block;
    .display {
      font-weight: 500;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    margin-right: 6px;
  }
  .controls {
    display: inline-block;
    vertical-align: top;
    i {
      cursor: pointer;
      padding: 4px;
      margin-left: 8px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`
