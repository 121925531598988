import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import {
  DropdownButton,
  DotsButtonContainer,
  Menu,
  DisconnectUserButton,
} from './styled/default'

const DotsButton = ({
  connectionStatus,
  onDisconnect,
  onDisconnectRefetchQueries,
}) => {
  const intl = useIntl()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isConnected = connectionStatus === 'accepted'

  const openMenu = (e) => {
    e.preventDefault()
    return setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    return setIsMenuOpen(false)
  }

  const disconnectUser = (e) => {
    e.preventDefault()
    if (onDisconnect) {
      const status = onDisconnect()
      onDisconnectRefetchQueries && onDisconnectRefetchQueries()
      closeMenu()
      return console.log('DISCONNECT', status)
    }
  }

  const ref = useClickOutside(closeMenu)

  return (
    <DotsButtonContainer ref={ref}>
      <DropdownButton onClick={(e) => openMenu(e)} isMenuOpen={isMenuOpen}>
        <i className={`fas fa-ellipsis-h`} />
      </DropdownButton>
      {isMenuOpen && (
        <Menu>
          {isConnected && (
            <DisconnectUserButton onClick={(e) => disconnectUser(e)}>
              {intl.formatMessage({ id: 'tile.dots.disconnectuser.button' })}
            </DisconnectUserButton>
          )}
        </Menu>
      )}
    </DotsButtonContainer>
  )
}

export default DotsButton
