import React from 'react'
import { useCustomAttributes } from '_custom-attributes/hooks'
import { MbToB } from 'services/file'
import moment from 'moment'
import CA from '_custom-attributes/editor'

const CustomAttributes = ({ entityType, formData, uploadConfig, changeFormProperty }) => {
  const { attributes } = useCustomAttributes(entityType)
try {

  const handleCustomAttributeUpdate = (key, value) => {
    const array = formData?.customAttributes || []

    const mapped = array.find(a => a?.key === key) ?
      array.map(a => a?.key === key ? { key, value } : a)
      :
      [...array, { key, value }]

    changeFormProperty({ customAttributes: mapped })
  }

  const attributesConfig = {
    text: {
      config: {
        placeholder: 'Write short text',
      },
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
    email: {
      config: {
        placeholder: 'email@domain.com',
      },
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
    url: {
      config: {
        placeholder: 'https://',
      },
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
    boolean: {
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
    badge: {
      onSave: (key, value) => handleCustomAttributeUpdate(key, JSON.stringify(value)),

      onDelete: (key, badgeKey) => {
        const badges = formData?.customAttributes.find(k => k.key === key)?.value
        if (badges) {
          handleCustomAttributeUpdate(key, JSON.stringify(JSON.parse(badges).filter(b => b !== badgeKey)))
        }
      }
    },
    image: {
      config: {
        limit: 5,
        uploadConfig,
      },
      onSave: ({ key, media }) => {
        const images = formData?.customAttributes?.find(k => k.key === key)?.value || `[]`
        if (images) {
          handleCustomAttributeUpdate(key, JSON.stringify([...JSON.parse(images), media?.url]))
        }
      },
      onDelete: (key, index) => {
        const images = formData?.customAttributes.find(k => k.key === key)?.value
        if (images) {
          handleCustomAttributeUpdate(key, JSON.stringify(JSON.parse(images).filter((b, i) => i !== index)))
        }
      }
    },
    file: {
      config: {
        limit: 5,
        uploadConfig: {
          ...uploadConfig,
          tagsConfig: {
            ...uploadConfig.tagsConfig,
            context: `custom-attributes-${entityType}-files`,
          },
          maxImageFileSize: MbToB(5),
          allowedFormats: ['pdf'],
        },
      },
      onSave: ({ key, media }) => {
        const files = formData?.customAttributes?.find(k => k.key === key)?.value || `[]`
        if (files) {
          const file = {
            url: media?.url,
            name: media?.original_filename,
            extension: media?.format,
            dateCreated: moment(new Date()).format('YYYY-MM-DD'),
            dateUpdated: moment(new Date()).format('YYYY-MM-DD'),
            type: 'file'
          }
          handleCustomAttributeUpdate(key, JSON.stringify([...JSON.parse(files), file]))
        }
      },
      onDelete: (key, index) => {
        const images = formData?.customAttributes.find(k => k.key === key)?.value
        if (images) {
          handleCustomAttributeUpdate(key, JSON.stringify(JSON.parse(images).filter((b, i) => i !== index)))
        }
      }
    },
    location: {
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
    date: {
      onSave: (key, value) => handleCustomAttributeUpdate(key, value)
    },
  }

  return (
    <>
      <CA
        attributes={attributes}
        attributesValues={formData?.customAttributes || []}
        attributesConfig={attributesConfig}
      />
    </>

  )
} catch (e) {
	console.error(e)
	return null
}
}

export default CustomAttributes
