import React, { useState } from 'react'
import { values, without } from 'lodash'
import {
  SelectOption,
  SelectVariant,
  SelectDirection,
} from '@patternfly/react-core'
import applicationStates from '../shared/applicationStates'
import { useIntl } from 'react-intl'
import { StyledSelect } from '../styles'

const intlKey = 'application.management.'

const ApplicationFilters = ({ stateFilterState }) => {
  const intl = useIntl()

  const options = values(applicationStates).map((application) => (
    <SelectOption key={application?.text} value={application?.value}>
      {intl.formatMessage({
        id: `${intlKey}state.${application?.value?.split(' ').join('')}`,
      })}
    </SelectOption>
  ))

  const [isOpen, setOpen] = useState(false)
  const [selected, setSelected] = stateFilterState

  const onSelect = (e, selection) => {
    if (selected.includes(selection)) {
      setSelected(without(selected, selection))
    } else {
      setSelected([selection, ...selected])
    }
  }

  const titleId = 'title-id-1'
  return (
    <div>
      <span id={titleId} hidden>
        Title
      </span>
      <StyledSelect
        //toggleIcon={<CubeIcon />}
        variant={SelectVariant.checkbox}
        aria-label="Select Input"
        onToggle={setOpen}
        onSelect={onSelect}
        selections={selected}
        placeholderText="filters"
        isOpen={isOpen}
        aria-labelledby={titleId}
        direction={SelectDirection.down}
      >
        {options}
      </StyledSelect>
    </div>
  )
}

export default ApplicationFilters
