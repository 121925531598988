import React from 'react'
import './content-section.scss'
import store from 'store'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  height: 110vh;
  flex-direction: column;
  /* position: absolute; */
  /* left: 0;
  top: 0; */
`

const ContentSection = ({ data, iframeSrc }) => {
  const token = store.get('RIOT/token')

  return (
    <div className="iframe-content-wrapper">
      <Container className="single-content-section">
        <div className="inner-single-content-container">
          <div className="single-content-container">
            {/* <h1 className="single-title">{data.data && data.data.title}</h1>
          <h2>{data.page && data?.data?.subtitle}</h2> */}
            <iframe
              src={
                iframeSrc ||
                (data?.data?.getEmbedPage?.url
                  ? data?.data?.getEmbedPage?.url + '?auth_token=' + token
                  : '')
              }
              frameBorder="0"
              title={data?.data?.getEmbedPage?.title || ''}
              className="embed-iframe"
              id="iframe"
            ></iframe>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContentSection
