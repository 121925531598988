const applicationStates = {
  received: {
    value: 'received',
    icon: '',
    text: 'received',
    color: '#606060',
  },
  inProgress: {
    value: 'in progress',
    icon: '',
    text: 'in progress',
    color: '#606060',
  },
  awaitingResponse: {
    value: 'awaiting response',
    icon: '',
    text: 'awaiting response',
    color: '#606060',
  },
  interviewConfirmed: {
    value: 'interview confirmed',
    icon: '',
    text: 'interview confirmed',
    color: '#606060',
  },
  closed: {
    value: 'closed',
    icon: '',
    text: 'closed',
    color: '#606060',
  },
}

export default applicationStates
