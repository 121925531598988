import React from 'react'
import { filterSearchResults, sortResults } from './helpers'
import { filters } from './index'

import { SearchStyled } from '../shared/styles/search'
import { FiltersStyled } from '../shared/styles/filters'

import { useIntl } from 'react-intl'

const FiltersAndSearch = ({
  reportsList,
  setReportsList,
  search,
  setSearch,
  filter,
  setFilter,
  sort,
  refetch,
}) => {
  const intl = useIntl()

  const handleList = (s, f) => {
    setReportsList(
      sortResults({
        sort,
        reportsList: filterSearchResults({ search: s, reportsList, filter: f }),
      })
    )
  }

  const handleSearch = (value) => {
    setSearch(value)
    handleList(value, filter)
  }

  const handleFilter = (value) => {
    setFilter(value)
    refetch()
  }

  const renderMessage = (value) => {
    return intl.formatMessage({
      id: `admin.table.reports.${value.toLowerCase()}`,
    })
  }

  return (
    <div className="filter-and-search-wrapper">
      <FiltersStyled>
        <select value={filter} onChange={(e) => handleFilter(e.target.value)}>
          {filters.map((f) => {
            return <option value={f.value}>{renderMessage(f.value)}</option>
          })}
        </select>
      </FiltersStyled>
      <SearchStyled>
        <input value={search} onChange={(e) => handleSearch(e.target.value)} />
      </SearchStyled>
    </div>
  )
}

export default FiltersAndSearch
