import React from 'react'
import OpportunitiesGrid from '_opportunities/components/opportunities-grid'

const OpportunitiesGridConnected = ({ items: opportunities }) => {
  return (
    <div>
      {opportunities && (
        <OpportunitiesGrid opportunities={opportunities.map((o) => o?.data)} />
      )}
    </div>
  )
}

export default OpportunitiesGridConnected
