import styled from '@emotion/styled'

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 400;
  padding: 0 22px;
  margin-bottom: 12px;
`

export const ItemName = styled.h5`
  font-size: 18px;
  font-weight: 400;
  flex-basis: 65%;
`

export const Item = styled.div`
  display: flex;
  padding: 14px 22px;
  cursor: pointer;
  transition: 0.3s;
  &.selected {
    transition: 0.3s;
    background-color: var(--shade);
    border-left: 3px solid var(--flavor);
  }
  &:hover {
    transition: 0.3s;
    background-color: var(--shade);
    border-left: 3px solid var(--flavor);
  }
`

export const InfoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1; */
`

export const NotificationCounter = styled.span`
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 35px;
  height: 19px;
  line-height: 19px;
  border-radius: 25px;
  background-color: var(--flavor);
  color: var(--flavor-contrast);
`

export const Date = styled.span`
  font-size: 10px;
  max-width: 80px;
  margin: 0 auto;
  padding-left: 5px;
`
