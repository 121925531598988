// eslint-disable-next-line no-unused-vars
import React from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../../components/indy/shared/header'

const PublicLayout = ({ children, pathname }) => {
  return (
    <div>
      <Header pathname={pathname} />
      {children}
    </div>
  )
}

export default withRouter(PublicLayout)
