import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQuery, gql, useMutation } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import styled from '@emotion/styled'
import { nextPage, mergePage } from '_utils/graph-pagination'
import ActivitiesGrid from '../components/activities-grid'
import { useUser } from '_security/session-hooks'
import { useIntl } from 'react-intl'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'
import { getPageSizeForScreenSize } from '_tiles/helpers'

import { CONNECT_MUTATION } from './activity-details-connected'

import { ctaButtonHollow } from '_utils/css-buttons'
import { sendNotification } from '_components/buttons-connections/message-helper'

export const GET_ACTIVITIES = gql`
  query GetAllActivitiesList(
    $filter: ActivityFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllActivitiesPublic(
      filter: $filter
      paging: $paging
      ordering: $ordering
    ) @connection(key: "GetAllActivitiesList", filter: ["filter", "ordering"]) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        isDraft
        connectedCompanies {
          id
          coverPhoto
          name
          location
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

const updateQuery = mergePage('getAllActivitiesPublic')

const ActivitiesGridConnected = ({ filter }) => {
  const intl = useIntl()
  let ordering = undefined
  const detailedFilter = filter
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error, fetchMore } = useQuery(GET_ACTIVITIES, {
    variables: {
      filter,
      ordering,
      paging: {
        limit: getPageSizeForScreenSize({ pageType: 'grid' }),
        offset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [connectId, setConnectId] = useState()
  const user = useUser()

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)

  if (!data) return <Loading />
  if (error) return <Error />
  const { data: activities, hasMore } = data?.getAllActivitiesPublic

  return (
    <div>
      {data && (
        <ActivitiesGrid
          filter={detailedFilter}
          activities={activities.map((a) => {
            const creatorId = a?.creator?.sub
            return {
              activity: a,
              isOwner: user?.sub === creatorId,
              connectButton: {
                disabled: connecting && connectId === a.id,
                onConnect: () => {
                  setConnectId(a.id)
                  connect({
                    variables: { id: a.id, creatorId },
                    refetchQueries: [
                      {
                        query: GET_ACTIVITIES,
                        variables: {
                          ...nextPage(0, activities.length),
                          filter,
                        },
                      },
                    ],
                    awaitRefetchQueries: true,
                  }).then((res) => {
                      const { status } = res?.data?.connectToActivity

                      sendNotification({
                        type: 'connection',
                        intl,
                        defaultHeader: 'Connection',
                        defaultContent: `You have ${
                          status === 'cancelled'
                            ? 'cancelled your connection'
                            : 'sent a connection request'
                        }`,
                      })
                    })
                    .catch((err) => console.log('error', err))
                },
              },
            }
          })}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={fetchMore}
          filter={filter}
          offset={activities.length}
        />
      )}
    </div>
  )
}

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(
                  offset,
                  getPageSizeForScreenSize({ pageType: 'grid' })
                ),
                filter,
                updateQuery,
              })
            }
          >
            <p>
              {intl.formatMessage({ id: 'global.loadmore.button' })}
              <i class="fas fa-chevron-down"></i>
            </p>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}

export default ActivitiesGridConnected
