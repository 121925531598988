import styled from '@emotion/styled'

export const MainContainer = styled.div`
  ul {
    overflow: auto;
  }
`

export const SingleTag = styled.li`
  margin: 20px 20px 0 0;
  display: block;
  float: left;
  border-bottom: solid 2px #a6a6a6;
  i {
    transition: 0.3s;
    margin-right: 10px;
  }

  a {
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
  }
  button.remove-tag {
    border: none;
    background: transparent;
    i {
      margin: 0 auto;
    }
    &:hover {
      i {
        transform: scale(1.2);
        opacity: 0.6;
      }
    }
  }
`
