import React, { useEffect, useState } from 'react';
import Multiselect from "react-widgets/Multiselect";
import styled from '@emotion/styled'
import "react-widgets/styles.css";

const ComboboxContainer = styled.div`
  --padding: 1;
  --svg-width: 1.2;
  --svg-margin: .2;
  margin-top: 12px;
  padding: calc(var(--padding) * 1em);
  padding-top: .2em;
  padding-bottom: .5em;
  position: relative;
  display: flex;
  align-items: center;

  input:checked + .slider {
    background-color: var(--flavor);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--flavor);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .rw-multiselect {
    width: 100%;
  }

  .frame {
    border: var(--shade) 1px solid;
    border-radius: 1em;
    background: var(--background);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3000;
    pointer-events: none;
    margin-left: -1em;
    margin-top: 0em;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--svg-width) * 1em);
    margin-right: calc(var(--svg-margin) * 1em);
    position: relative;
    z-index: 4000;
    & > * { width: 100%; }
  }

  #Ellipse_75, line {
    stroke: var(--shade);
  }

  .rw-widget-picker {
    border: var(--shade) 0px solid;
    position: relative;
    z-index: 4000;
    background: var(--background);
    color: var(--foreground);
  }

  .rw-widget-input {
    box-shadow: none;
    box-shadow: none !important;
    background: var(--background);
    color: var(--foreground);
  }

  .rw-state-focus {
    .rw-widget-picker {
      box-shadow: none;
      border: rgba(black, .0) 1px solid;
    }
  }

  .rw-multiselect-tag {
    background: var(--flavor);
    border-radius: 13px;
    padding-left: .7em;
    border: none;
    span {
      color: var(--flavor-contrast);
      font-size: .8em;
    }
  }

  .rw-multiselect-taglist {
    gap: .2em;
  }

  .rw-popup-container {
    position: absolute;
    top: 100%;
    transform: translateY(-2em);
    left: calc((var(--padding) + var(--svg-width) + var(--svg-margin)) * -1em + 1px);
    right: calc(var(--padding) * -1em);
    border: solid 1px var(--shade);
    background: var(--background);
    border-radius: 1em;
    padding-top: 2em;
  }

  .rw-popup {
    box-shadow: none;
    background: none;
    border: none;
  }

  .rw-list-option {
    color: var(--foreground);
    border: solid 0px transparent;
    border-radius: 1em;
    transition: .2s;
    &:hover  {
      color: var(--foreground) !important;
      border: solid 0px var(--foreground);
    }
    &.rw-state-focus {
      color: var(--background) !important;
      background: var(--flavor) !important;
      border: solid 1px var(--flavor);
    }
  }

  .rw-multiselect-tag-btn {
    padding: 0 1em 0 0.35em;
  }

  .rw-multiselect-tag-btn path {
    fill: var(--flavor-contrast);
  }
`

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25.561" height="26.561" viewBox="0 0 25.561 26.561">
    <g id="search" transform="translate(0 -5.25)">
      <g id="Group_1775" data-name="Group 1775" transform="translate(0 5.25)">
        <g id="Ellipse_75" data-name="Ellipse 75" fill="none" stroke="#707070" stroke-width="3">
          <circle cx="9.5" cy="9.5" r="9.5" stroke="none"/>
          <circle cx="9.5" cy="9.5" r="8" fill="none"/>
        </g>
        <line id="Line_15" data-name="Line 15" x2="9" y2="9" transform="translate(15.5 16.5)" fill="none" stroke="#707070" stroke-width="3"/>
      </g>
    </g>
  </svg>
)

const Combobox = ({children, data, placeholder = "Select categories", onChange = () => {}}) => {
  const [value, setValue] = useState([]);
  const [list, setList ] = useState([]);

  useEffect(() => {
    setList(data)
    setValue(data.filter(item => item.checked))
  }, [data])

  function changeValue(values, meta) {
    setValue(values)
    onChange(values)
  }

  return (
    <ComboboxContainer>
      <div className="frame"></div>

      <div className="icon-container">
        {children ? children : <SearchIcon/>}
      </div>

      <Multiselect
        placeholder={placeholder}
        showPlaceholderWithValues={true}
        data={list}
        value={value}
        textField="title"
        groupBy={item => item.parent ? item.parent : 'Categories'}
        onChange={(value, meta) => changeValue(value, meta)}
      />
    </ComboboxContainer>
  );
}

export default Combobox;
