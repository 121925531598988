import React from 'react'
import { GET_OPPORTUNITY } from './helpers'
import { useQuery } from '@apollo/client'
import { EntityManagerInvitation } from './entity-manager-invitation'

const ApplicationManagerInvitation = ({ item }) => {
  const opportunityId = item?.notification?.payload[0].entityId
  const { data } = useQuery(GET_OPPORTUNITY, {
    variables: {
      id: item?.notification?.payload[0].entityId,
    },
  })

  return (
    <EntityManagerInvitation
      item={item}
      translationKey={
        'notificationscenter.notification.message.applicationManagerInvitation'
      }
      defaultMessage={'has invited you to manage applications for '}
      linkUrl={`/opportunities/list/${opportunityId}`}
      entityName={data?.getOpportunity?.name}
    />
  )
}

export default ApplicationManagerInvitation
