/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { connect, useDispatch } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useMessageActions } from '_messages'
import { useIntl } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import { mobilePX, pabletPX } from '_utils/responsive-queries'
import DashboardMessagesSearch from '../dashboard-messages-search'
import DashboardMessagesList from '../dashboard-messages-list'
import DashboardMessagesActiveWindow from '../dashboard-messages-active-window'

import border from '_utils/css-border'
import RequiresLogin from '_security/login-required'

const GET_NOTIFICATIONS = gql`
  query {
    getCurrentUser {
      sub
      notifications {
        notification {
          _id
          read
        }
        user {
          sub
        }
      }
    }
  }
`

const mapStateToProps = ({ messages, user }) => ({ messages, user })

const MessagesContentSection = styled.section`
  position: relative;
  background-color: var(--background);
`

const InnerMessagesContent = styled.div`
  padding: 0px;
`

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 110px);
  padding: 35px 35px 25px 45px;
  background: var(--shade);

  ${({ isMobileView, isTabletView }) =>
    (isMobileView || isTabletView) &&
    css`
      padding: 20px 30px 20px 40px;
    `}
`

const MessagesTitle = styled.h1`
  padding: 0 20px;
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  padding-top: ${({ isMobileView }) => isMobileView && '15px'};
`

const NotificationsCount = styled.span`
  position: relative;
  font-size: 16px;
  background-color: var(--flavor);
  color: var(--background);
  font-size: 12px;
  border-radius: 15px;
  width: 19px;
  height: 19px;
  top: -15px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const LeftColumn = styled.div`
  ${border()}
  align-self: flex-start;
  flex-basis: 35%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 15px;
  padding: 25px 0 80px;
  background-color: var(--background);
  height: 100%;
  max-width: 380px;
  overflow-y: auto;
  ${({ isMobileView, isTabletView, isHidden }) =>
    (isMobileView || isTabletView) &&
    css`
      display: ${isHidden && 'none'};
      flex-basis: none;
      width: 100%;
      max-width: 100%;
      padding: 10px;
      flex-grow: 1;
      margin: 0;
    `}
`

const RightColumn = styled.div`
  ${border()}
  flex-basis: ${({ isMobileView, isTabletView }) =>
    isMobileView || isTabletView ? '100%' : '65%'};
  max-width: 800px;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ isMobileView, isLeftColumnHidden }) =>
    isMobileView &&
    css`
      flex-grow: ${isLeftColumnHidden && '1'};
      flex-basis: auto;
      width: auto;
      max-width: 100%;
      display: ${({ isLeftColumnHidden }) =>
        isLeftColumnHidden ? 'block' : 'none'};
    `}
`

const SearchButton = styled.button`
  height: 30px;
  width: 30px;
  outline: none;
  border: none;
  color: var(--foreground);
  transition: 0.5s;
  background-color: transparent;
  .start-chat-hover {
    position: absolute;
    display: none;
    top: -30px;
    left: -25px;
  }
  i {
    font-size: 18px;
  }

  &:hover {
    .start-chat-hover {
      display: flex;
      position: absolute;
      background-color: #333232;
      padding-top: 2.5px;
      padding-bottom: 2.5px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 10px;
      color: white;
      text-align: center;
      white-space: nowrap;
      font-family: ${({ theme }) => theme.fonts.googleFont};
      font-size: 12px;
    }
  }
`

const Index = ({ messages, user }) => {
  const intl = useIntl()
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [query, setQuery] = useState('')

  const [selection, setSelection] = useState({
    sub: null,
    firstName: null,
    lastName: null,
  })
  const [resetSearch, setResetSearch] = useState(false)
  const activeChannel = useSelector(
    (state) => state.messages && state.messages.currentConversation
  )
  const conversations = useSelector((state) => state.messages?.conversations)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeChannel && conversations?.length) {
      messageActions.openConversation(conversations[0].conversationUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChannel, conversations])

  const messageActions = useMessageActions()
  const { data, loading, error, stopPolling, startPolling } = useQuery(
    GET_NOTIFICATIONS,
    {
      pollInterval: 5000,
      skip: !user?.authorized,
    }
  )
  const connectedUsersSubs = user?.profile?.connectedUsers?.map((u) => u.sub)

  useEffect(() => {
    setNotificationsCount(
      messages?.conversations?.filter(
        (conv) =>
          !conv.lastMessage.read &&
          user.sub !== conv.lastMessage.creator &&
          connectedUsersSubs.includes(conv.lastMessage.creator)
      ).length
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, messages.currentConversation])

  const handleUserClick = (user) => {
    setQuery('')
    messageActions.openConversation(user.sub)
  }

  const isTabletView = useMediaQuery({ query: `(max-width: ${pabletPX}px)` })
  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })

  return (
    <RequiresLogin showLoginPopup={true}>
      <MessagesContentSection>
        <InnerMessagesContent>
          <MainContainer isMobileView={isMobileView || isTabletView}>
            <LeftColumn
              isMobileView={isMobileView || isTabletView}
              isHidden={messages.currentConversation}
            >
              <MessagesTitle isMobileView={isMobileView || isTabletView}>
                <span>
                  {intl.formatMessage({
                    id: 'user.messages.messageslist.title',
                  })}
                </span>
                {notificationsCount && notificationsCount > 0 ? (
                  <NotificationsCount>
                    {notificationsCount <= 9 ? notificationsCount : '9+'}
                  </NotificationsCount>
                ) : null}
              </MessagesTitle>
              <DashboardMessagesSearch
                handleUserClick={handleUserClick}
                selection={selection}
                setSelection={setSelection}
                resetSearch={resetSearch}
                setResetSearch={setResetSearch}
              />
              <DashboardMessagesList />
            </LeftColumn>
            <RightColumn
              isMobileView={isMobileView || isTabletView}
              isLeftColumnHidden={messages.currentConversation}
            >
              <DashboardMessagesActiveWindow
                currentChatRoom={messages.currentConversation}
                user={user}
                isMobileView={isMobileView || isTabletView}
              />
            </RightColumn>
          </MainContainer>
        </InnerMessagesContent>
      </MessagesContentSection>
    </RequiresLogin>
  )
}

export default connect(mapStateToProps)(Index)
