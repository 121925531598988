import gql from 'graphql-tag'

export const UPDATE_BLOG_CATEGORIES = gql`
  mutation updateBlogCategories($blogCategories: [BlogCategoryInput]) {
    updateBlogCategories(blogCategories: $blogCategories) {
      blogCategories {
        id
        name
        subcategories {
          id
          name
          parentId
        }
      }
    }
  }
`
