import { useCallback, useRef, useEffect } from 'react'
import * as service from './meet-service'
import { useDispatch } from 'react-redux'
import { setCurrentCall } from './meet-actions'

export const useMeeting = (meetingId) => {
  const dispatch = useDispatch()

  const accept = useCallback(() => {
    service
      .acceptCall(meetingId)
      .then((meeting) => dispatch(setCurrentCall(meeting)))
      .catch((e) => {
        console.error('Failed to accept meeting', e)
      })
  }, [meetingId, dispatch])

  const reject = useCallback(() => {
    service.rejectCall(meetingId).catch((e) => {
      console.error('Failed to reject meeting', e)
    })
  }, [meetingId])

  return { accept, reject }
}

export const useMeetingCall = () => {
  const dispatch = useDispatch()

  const startDirectCall = useCallback(
    (userId) => {
      service
        .bookDirectCall(userId)
        .then((meeting) => {
          dispatch(setCurrentCall(meeting))
        })
        .catch((e) => {
          console.error('Failed to start meeting call')
          dispatch(setCurrentCall(null))
        })
    },
    [dispatch]
  )

  return { startDirectCall }
}

export const useWaitForAnswer = (meeting) => {
  const cancelToken = useRef({ canceled: false })
  const dispatch = useDispatch()
  const meetingId = meeting?.id

  useEffect(() => {
    let timeoutId
    if (meeting?.state === 'NEW' && !cancelToken.current.canceled) {
      timeoutId = setTimeout(
        () =>
          service.fetchCall(meeting.id).then((meeting) => {
            timeoutId = null
            if (!cancelToken.canceled) {
              dispatch(setCurrentCall(meeting))
            }
          }),
        1000
      )
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [meeting, dispatch, cancelToken])

  useEffect(() => {
    cancelToken.current.canceled = false
  }, [meetingId])

  return useCallback(() => {
    if (meeting) {
      cancelToken.current.canceled = true
      service.endCall(meeting.id).then(() => dispatch(setCurrentCall(null)))
    }
  }, [meeting, dispatch, cancelToken])
}
