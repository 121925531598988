import React from 'react'
import LoginRequiredLink from '_security/login-required-link'
import { useIntl } from 'react-intl'
import { conditionalTrimmer } from '_application'
import {
  Avatar,
  Container,
  AvatarContainer,
  OnlineIndicator,
  Title,
  SubTitle,
  LocationLabel,
  CoverContainer,
} from '_themes/default/components/styled/avatar-tile'

const AvatarTile = ({
  avatar,
  title,
  subtitle,
  onlineStatus,
  location,
  className,
  children,
  sub,
  url,
  withoutConnections,
  cover,
  showCover,
  showLocation = false,
  tileHeight,
}) => {
  const intl = useIntl()
  let Link = ({ children }) => <div className="avatar-popup">{children}</div>
  if (url) {
    Link = ({ children }) => (
      <LoginRequiredLink
        className="avatar-popup"
        key={sub}
        to={url}
        preserveQuery
      >
        {children}
      </LoginRequiredLink>
    )
  }

  return (
    <Container
      showCover={showCover}
      className={className}
      withoutConnections={withoutConnections}
      tileHeight={tileHeight}
    >
      <CoverContainer>{showCover && <img src={cover} alt="" />}</CoverContainer>

      <Link>
        <AvatarContainer>
          <Avatar src={avatar} />
          {onlineStatus ? (
            <OnlineIndicator onlineStatus={onlineStatus} />
          ) : null}
        </AvatarContainer>
        <Title>{conditionalTrimmer(title, 30, '...')}</Title>
        <SubTitle>
          {subtitle && conditionalTrimmer(subtitle, 40, '...')}
        </SubTitle>
        {showLocation && (
          <LocationLabel>
            <i className="fas fa-map-marker-alt" />
            &nbsp;{' '}
            {location ||
              intl.formatMessage({ id: 'default.location.notavailable' })}
          </LocationLabel>
        )}
      </Link>
      {children}
    </Container>
  )
}

export default AvatarTile
