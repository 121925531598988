import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { BaseTile } from '_tiles'
import Loading from '_components/loading'
import Error from '_components/error'
import {
  GET_ACTIVITIES,
  GET_COMPANIES,
  GET_OPPORTUNITIES,
  GET_USERS,
  CONNECT_COMPANY_MUTATION,
  CONNECT_ACTIVITY_MUTATION,
  CONNECT_USER_MUTATION,
} from './shared/helpers'
import { Default as ActivityTile } from '_activities/components/tiles'
import { Default as CompanyTile } from '_companies/components/tiles'
import { Default as PersonTile } from '_people/components/tiles'
import { Default as OpportunityTile } from '_opportunities/components/tiles'
import { NoContentWrapper, SuggestionTilesWrapper } from './styles/main-content'
import { sendNotification } from '_components/buttons-connections/message-helper'

const suggestionsNumber = 2

const NoContent = () => {
  const intl = useIntl()
  // TODO: filtering by newest entity
  const orderingForPeople = [{ field: '_updatedAt', order: 'DESC' }]
  const ordering = [{ field: '_updatedAt', order: 'DESC' }]

  const { data: activitiesData, error: activitiesDataError } = useQuery(
    GET_ACTIVITIES,
    {
      variables: {
        ordering,
        paging: {
          offset: 0,
          limit: suggestionsNumber,
        },
      },
    }
  )

  const { data: companiesData, error: companiesDataError } = useQuery(
    GET_COMPANIES,
    {
      variables: {
        ordering,
        paging: {
          offset: 0,
          limit: suggestionsNumber,
        },
      },
    }
  )

  const { data: peopleData, error: peopleDataError } = useQuery(GET_USERS, {
    variables: {
      orderingForPeople,
      paging: {
        offset: 0,
        limit: suggestionsNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: opportunitiesData, error: opportunitiesDataError } = useQuery(
    GET_OPPORTUNITIES,
    {
      variables: {
        ordering,
        paging: {
          offset: 0,
          limit: suggestionsNumber,
        },
      },
    }
  )

  const [connectId, setConnectId] = useState()

  const [connectCompany, { loading: connectingCompany }] = useMutation(
    CONNECT_COMPANY_MUTATION
  )

  const [connectActivity, { loading: connectingActivity }] = useMutation(
    CONNECT_ACTIVITY_MUTATION
  )

  const [connectUser, { loading: connectingUser }] = useMutation(
    CONNECT_USER_MUTATION
  )

  const suggestionsData = [
    { type: 'activities', data: activitiesData, error: activitiesDataError },
    { type: 'companies', data: companiesData, error: companiesDataError },
    { type: 'people', data: peopleData, error: peopleDataError },
    {
      type: 'opportunities',
      data: opportunitiesData,
      error: opportunitiesDataError,
    },
  ]

  const renderSuggestions = () =>
    suggestionsData.map((d) => {
      if (!d.data) return <Loading />
      if (d.error) return <Error />
      switch (d.type) {
        case 'activities':
          return d.data?.getAllActivitiesPublic.data.map((act) => {
            return (
              <BaseTile url={`/activities/list/${act.id}`}>
                <ActivityTile
                  activity={act}
                  connectButton={{
                    disabled: connectingActivity && connectId === act.id,
                    onConnect: () => {
                      setConnectId(act.id)
                      connectActivity({
                        variables: { id: act.id, creatorId: act?.creator?.sub },
                        refetchQueries: [
                          {
                            query: GET_ACTIVITIES,
                            variables: {
                              ordering,
                              paging: {
                                offset: 0,
                                limit: suggestionsNumber,
                              },
                            },
                          },
                        ],
                        awaitRefetchQueries: true,
                      })
                        .then((res) => {
                          const { status } = res?.data?.connectToActivity

                          sendNotification({
                            type: 'connection',
                            intl,
                            defaultHeader: 'Connection',
                            defaultContent: `You have ${
                              status === 'cancelled'
                                ? 'cancelled your connection'
                                : 'sent a connection request'
                            }`,
                          })
                        })
                        .catch((err) => console.log('error', err))
                    },
                  }}
                />
              </BaseTile>
            )
          })
        case 'people':
          return d.data?.getAllUsersPublic.data.map((user) => (
            <BaseTile url={`/people/list/${user.id}`}>
              <PersonTile
                person={user}
                connectButton={{
                  disabled: connectingUser && connectId === user?.sub,
                  onConnect: () => {
                    setConnectId(user?.sub)
                    connectUser({
                      variables: { sub: user?.sub },
                      refetchQueries: [
                        {
                          query: GET_USERS,
                          variables: {
                            orderingForPeople,
                            paging: {
                              offset: 0,
                              limit: suggestionsNumber,
                            },
                          },
                          notifyOnNetworkStatusChange: true,
                        },
                      ],
                      awaitRefetchQueries: true,
                    })
                      .then((res) => {
                        const { status } = res?.data?.connectToUser

                        sendNotification({
                          type: 'connection',
                          intl,
                          defaultHeader: 'Connection',
                          defaultContent: `You have ${
                            status === 'cancelled'
                              ? 'cancelled your connection'
                              : 'sent a connection request'
                          }`,
                        })
                      })
                      .catch((err) => console.log('error', err))
                  },
                }}
              />
            </BaseTile>
          ))
        case 'companies':
          return d.data?.getAllCompaniesPublic.data.map((com) => (
            <BaseTile url={`/companies/list/${com.id}`}>
              <CompanyTile
                company={com}
                connectButton={{
                  disabled: connectingCompany && connectId === com.id,
                  onConnect: () => {
                    setConnectId(com.id)
                    connectCompany({
                      variables: { id: com.id, creatorId: com?.creator?.sub },
                      refetchQueries: [
                        {
                          query: GET_COMPANIES,
                          variables: {
                            ordering,
                            paging: {
                              offset: 0,
                              limit: suggestionsNumber,
                            },
                          },
                        },
                      ],
                      awaitRefetchQueries: true,
                    })
                      .then((res) => {
                        const { status } = res?.data?.connectToCompany

                        sendNotification({
                          type: 'connection',
                          intl,
                          defaultHeader: 'Connection',
                          defaultContent: `You have ${
                            status === 'cancelled'
                              ? 'cancelled your connection'
                              : 'sent a connection request'
                          }`,
                        })
                      })
                      .catch((err) => console.log('error', err))
                  },
                }}
              />
            </BaseTile>
          ))
        case 'opportunities':
          return d.data?.getAllOpportunitiesPublic.data.map((opp) => (
            <BaseTile url={`/opportunities/list/${opp.id}`}>
              <OpportunityTile opportunity={opp} />
            </BaseTile>
          ))
        default:
          return <></>
      }
    })

  return (
    <NoContentWrapper>
      <h3 className="title">
        {intl.formatMessage({
          id: 'notificationcenter.notifications.nocontent.title',
        })}
      </h3>
      <p className="message">
        {intl.formatMessage({
          id: 'notificationcenter.notifications.nocontent.message',
        })}
      </p>
      <h5 className="second-message">
        {intl.formatMessage({
          id: 'notificationcenter.notifications.nocontent.message.second',
        })}
      </h5>
      <SuggestionTilesWrapper> {renderSuggestions()}</SuggestionTilesWrapper>
    </NoContentWrapper>
  )
}

export default NoContent
