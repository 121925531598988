import styled from '@emotion/styled'

export const Access = styled.div`
  button {
    margin: 20px 10px 0 0;
    border: none;
    background: #f4f4f4;
    opacity: 0.65;
    padding: 12px 20px;
    border: 1px solid transparent;
    &:hover {
      opacity: 1;
    }
    &.active {
      border-color: var(--foreground);
      background: transparent;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`
