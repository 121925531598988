import React from 'react'
import { useHistory } from 'react-router-dom'
import { HeaderContainer as HeaderStyles } from './styled/header-navigation'

const HeaderNav = ({ children, isScrollTop, themeSettings }) => {
  const history = useHistory()
  const location = history.location.pathname

  return (
    <HeaderStyles
      isScrollTop={isScrollTop}
      location={location}
      themeSettings={themeSettings}
    >
      {children}
    </HeaderStyles>
  )
}

export default HeaderNav
