import styled from '@emotion/styled'
import BaseStyled from './default'
import { pablet } from '_utils/responsive-queries'
const WelcomeStyled = styled(BaseStyled)`
  .step-content-container {
    text-align: center;
    &.background {
    }
    h3 {
      padding-bottom: 60px;
      ${pablet} {
        padding-bottom: 0;
      }
    }
  }
  .step-content {
    min-width: 100%;
  }
`

export default WelcomeStyled
