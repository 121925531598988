import React from 'react'
import { useParams } from 'react-router-dom'
import { OpportunityInfo } from '../shared/opportunity-info'
import { ApplicationContent } from '../shared/application-content'
import { useQuery, gql } from '@apollo/client'
import BackButton from '../shared/backButton'
import { FormattedMessage } from 'react-intl'
import { Messages } from '../opportunity-application-detail/messaging'
import { MainWrapper } from '../opportunity-application-detail/styles/mainContent'
import Loading from '_components/loading'

export const ApplicationApplicantView = () => {
  const { applicationId } = useParams()

  const { data: applicationData } = useQuery(
    gql`
      query GetApplicationApplicantView($applicationId: String) {
        getApplicationApplicantView(applicationId: $applicationId) {
          opportunity {
            id
            name
            coverPhoto
          }
          coverLetter
          attachments {
            url
            name
          }
        }
      }
    `,
    { variables: { applicationId: applicationId } }
  )

  const application = applicationData?.getApplicationApplicantView
  const opportunity = application?.opportunity

  return (
    <MainWrapper>
      <BackButton path={`/opportunities/list/${opportunity?.id}`}>
        <FormattedMessage id={`application.management.backtocommunity`} />
      </BackButton>
      {opportunity ? (
        <>
          <OpportunityInfo opportunity={opportunity} />
          <Messages
            channelType={'application-applicant-messaging'}
            applicationId={applicationId}
          />
          <ApplicationContent application={application} />
        </>
      ) : (
        <Loading />
      )}
    </MainWrapper>
  )
}
