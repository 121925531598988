import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import border from '_utils/css-border'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { css } from '@emotion/core'

const ButtonWrapper = styled.div`
  position: relative;
`
const Button = styled.button`
  ${ctaButtonHollow()}
  border: 2px solid var(--flavor);
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  transition: 0.5s;
  transform: ${({ isOpen, isAnimated }) =>
    isOpen && isAnimated && 'rotate(90deg)'};
`
const Dropdown = styled.div`
  ${border()}
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background-color: var(--background);
  border-radius: 25px;
  border-top-right-radius: 0;
  padding: 20px;
  z-index: 3;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  /* TODO: temporary - remove when real buttons are implemented*/
  span {
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }
  }
  i {
    margin-right: 10px;
  }

  a {
    ${({ isPreview }) =>
      isPreview &&
      css`
        pointer-events: none;
        cursor: default;
      `}
  }
`

const ButtonWithDropdown = ({
  itemsToDropdown,
  isPreview,
  icon,
  isAnimated = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const isArray = Array.isArray(itemsToDropdown)

  const ref = useClickOutside(() => setIsOpen(false))
  return (
    <>
      {isArray && (
        <ButtonWrapper ref={ref}>
          <Button
            isAnimated={isAnimated}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          >
            {/* <i className="fas fa-ellipsis-h" /> */}
            {icon}
          </Button>
          {isOpen && (
            <Dropdown>
              {itemsToDropdown.map((item) => {
                const Component = () => item

                return <Component onClick={() => setIsOpen(false)} />
              })}
            </Dropdown>
          )}
        </ButtonWrapper>
      )}
    </>
  )
}

export default ButtonWithDropdown
