import { css } from '@emotion/core'
import styled from '@emotion/styled'
import circleButton, { ctaButtonHollow, cancelButton } from '_utils/css-buttons'

const pickAnim = (theme, hover) => {
  let prop = {}
  prop.theme = theme

  let result = hover ? ctaButtonHollow(prop) : cancelButton(prop)
  return result
}

export const PendingButtonStyled = styled.button`
  ${({ theme, hover }) => pickAnim(theme, hover)}
  color: var(--foreground);
  background: var(--shade);
  border: 2px solid transparent;
  ${({ isSmall }) =>
    isSmall &&
    css`
      ${circleButton()}
      border-color: var(--flavor);
      padding: 0;
    `}
  svg path {
    stroke: none !important;
    fill: var(--foreground);
  }
`

export const ConnectButtonStyled = styled.button`
  ${ctaButtonHollow}
  color: var(--foreground);
  background: var(--background);

  &.small {
    width: 42px;
    height: 42px;
    padding: 0px;
    margin: 0px;

    svg {
      width: 22px;
      height: 22px;
      path {
        stroke: none !important;
        fill: var(--foreground);
      }
    }
  }

  &.requested {
    background-color: var(--shade);
  }

  &.accepted:hover {
    border: 2px solid var(--flavor);
    background-color: transparent;
  }

  &.cancelled {
    border: 2px solid var(--flavor);
    &:hover {
      border: 2px solid var(--flavor);
    }
  }
`
export default ConnectButtonStyled
