import styled from '@emotion/styled'

export const LoaderStyled = styled.div`
  margin: 0 auto;
  display: table;
  text-align: center;
  .title {
    font-size: 20px;
  }
  .description {
    display: block;
    font-size: 13px;
  }
  .loader {
    margin: 0 auto;
    margin-top: 20px;
    display: table;
  }
`
