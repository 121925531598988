import React, { useEffect, Suspense } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import ProfileDetails from './personal-details'
import ProfileEducation from './education'
import ProfileExperience from './experience'
import { useStripeCustomer } from '_payment'
import ProfileKeywords from './keywords'
import ProfileTaxonomy from './taxonomy'
import ProfileGraph from './graph'
import ProfileProducts from './products'
import ProfilePaymentDetails from './payment-details'
import ProfilePaymentHistory from './payment-history'
import AccountManagment from './account'
import About from './about'
import DeleteMyProfile from './account/delete-my-profile'
import { usePluginsPublic } from '_application'

export const defaultRoute = '/user/profile'

const Routes = () => {
  const { plugin } = usePluginsPublic('stripe')
  const { customer: { paymentHistory } = {} } = useStripeCustomer()
  // const { paymentHistory } = customer || {}
  const isPaymentEnabled = plugin?.data?.account?.id

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route
          path={`${defaultRoute}/details`}
          render={() => <ProfileDetails />}
        />
        <Route
          path={`${defaultRoute}/education`}
          render={() => <ProfileEducation />}
        />
        <Route
          path={`${defaultRoute}/experience`}
          render={() => <ProfileExperience />}
        />
        <Route
          path={`${defaultRoute}/keywords`}
          render={() => <ProfileKeywords />}
        />
        <Route
          path={`${defaultRoute}/topics`}
          render={() => <ProfileTaxonomy />}
        />
        <Route path={`${defaultRoute}/graph`} render={() => <ProfileGraph />} />
        <Route
          path={`${defaultRoute}/subscriptions`}
          exact={true}
          render={isPaymentEnabled ? () => <ProfileProducts /> : () => null}
        />
        <Route
          path={`${defaultRoute}/payment-details`}
          render={
            isPaymentEnabled ? () => <ProfilePaymentDetails /> : () => null
          }
        />
        <Route
          path={`${defaultRoute}/purchase-history`}
          render={
            isPaymentEnabled && paymentHistory && paymentHistory.length > 0
              ? () => <ProfilePaymentHistory />
              : () => null
          }
        />
        <Route
          path={`${defaultRoute}/account`}
          render={() => <AccountManagment />}
        />
        <Route
          path={`${defaultRoute}/delete-profile`}
          render={() => <DeleteMyProfile />}
        />

        <Route path={`${defaultRoute}/about`} component={About} />

        <Redirect from={defaultRoute} to={`${defaultRoute}/about`} />
      </Switch>
    </Suspense>
  )
}

export default Routes
