import React from 'react'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { StaticEmbed } from 'pages/static-embed'
import { PageBuilderRenderer } from '_pagebuilder/renderer/page-builder-renderer'

export const CustomPages = () => {
  const { path } = useParams()

  const { data } = useQuery(
    gql`
      query CustomPage($path: String) {
        customPage(path: $path) {
          path
          iframeSrc
          isPageBuilder
          _id
          title
        }
      }
    `,
    { variables: { path } }
  )

  if (!data) {
    return null
  }

  if (data.customPage.isPageBuilder) {
    return (
      <NavWrapper>
        <div css={{ 'max-width': '1200px', 'margin-inline': 'auto' }}>
          <PageBuilderRenderer contextId={'customPage' + data.customPage._id} />
        </div>
      </NavWrapper>
    )
  }

  return <StaticEmbed iframeSrc={data?.customPage?.iframeSrc} />
}
