import { gql } from '@apollo/client'
// what data do we need here?
export const GET_CATEGORIES = gql`
  {
    getCategories {
      categoriesList {
        name
        description
        cid
        slug
        postCount
        topicCount
        icon
        color
        bgcolor
        postsList {
          content
          timestampiso
          tid
          user {
            username
          }
        }
      }
    }
  }
`

export const GET_FORUM_USERS = gql`
  {
    getForumUsers {
      context
      currentpage
      pagecount
      usercount
      usersList {
        username
        uid
      }
    }
  }
`
