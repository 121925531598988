import React, { useState } from 'react'
import moment from 'moment'
import { useIntl, FormattedMessage } from 'react-intl'
import { ApplyFormStyled, Section, Footer, GdprContainer } from './styles'
import {
  DurationButton,
  DurationContainer,
} from '_public-details-card/styles/content-header'

import { useAppConfig } from '_application'
import MediaUpload from './media-upload'

import AssetsViewer from 'components/indy/user/content-studio/shared/assets-viewer'
import { renderField } from 'components/indy/user/profile/shared/field-factory'

const ApplyForm = ({
  opportunity,
  onClose,
  onSend,
  remainingApplicationCount,
}) => {
  const [isGdprConsent, setIsGdprConsent] = useState(true)
  const [coverLetter, setCoverLetter] = useState()
  const [attachments, setAttachments] = useState([])
  const intl = useIntl()

  const config = useAppConfig()

  const {
    start: startDate,
    end: endDate,
    expire,
    name,
    description,
  } = opportunity

  const renderMessageText = (value) => {
    return intl.formatMessage({ id: `global.date.${value}` })
  }

  const renderDates = () => {
    let dates = ''
    if (startDate && endDate) {
      dates =
        moment(startDate).format('DD.MM.YYYY') +
        ' - ' +
        moment(endDate).format('DD.MM.YYYY')
    } else if (startDate) {
      dates =
        intl.formatMessage({ id: 'sanity.event.start.title' }) +
        ' ' +
        moment(startDate).format('DD.MM.YYYY')
    } else if (endDate) {
      dates =
        intl.formatMessage({ id: 'sanity.event.end.title' }) +
        ' ' +
        moment(endDate).format('DD.MM.YYYY')
    }
    if (dates) {
      return <DurationButton>{dates}</DurationButton>
    } else {
      return <></>
    }
  }

  const applyBy = () => {
    if (expire)
      return `${intl.formatMessage({
        id: 'opportunity.tile.applyby',
      })} ${moment(opportunity.expire).format('DD.MM.YYYY')}`
    return ' '
  }

  const handleRemoveAttachement = (attachment) => {
    setAttachments(attachments?.filter((a) => a?.url !== attachment?.url))
  }

  return (
    <ApplyFormStyled>
      <h3 className="main-title">{`${renderMessageText(
        'applyfor'
      )} ${name}`}</h3>
      <span className="main-description">{description}</span>

      {expire && (
        <DurationContainer className="date-range">
          <DurationButton>{applyBy()}</DurationButton>
        </DurationContainer>
      )}
      {(startDate || endDate) && (
        <DurationContainer className="date-range">
          {renderDates()}
        </DurationContainer>
      )}
      <Section>
        <span className="title">
          {intl.formatMessage({ id: 'applicationsubmission.coverletter' })}*
        </span>
        <div className="content">
          {renderField({
            attribute: {
              type: 'wysiwyg',
              name: 'coverLetter',
              editorType: 'coverLetter',
              editorValue: coverLetter,
            },
            onSave: (html) => setCoverLetter(html),
          })}
        </div>
      </Section>
      <Section>
        <span className="title">
          {intl.formatMessage({ id: 'applicationsubmission.attachments' })}
        </span>
        <span className="description">
          {intl.formatMessage({
            id: 'applicationsubmission.applyform.description',
          })}
        </span>
        <div className="content">
          <AssetsViewer
            removeCallback={handleRemoveAttachement}
            assets={attachments}
          />
          <MediaUpload
            attachments={attachments}
            setAttachments={setAttachments}
            maxFilesCount={5}
          />
        </div>
      </Section>
      <Footer>
        <GdprContainer>
          <input
            type="checkbox"
            id="gdpr"
            checked={isGdprConsent}
            onClick={() => setIsGdprConsent(!isGdprConsent)}
          />
          <label htmlFor="gdpr">
            {intl.formatMessage({ id: 'applicationsubmission.gdpr' })}
          </label>
        </GdprContainer>
        {remainingApplicationCount !== null &&
          config?.isApplicationSubmissionAuthEnabled && (
            <span className="application-count">
              <FormattedMessage
                id={'applicationsubmission.remaining.count'}
                values={{ count: remainingApplicationCount }}
              />
            </span>
          )}
        <button onClick={onClose} className="cancel">
          {intl.formatMessage({ id: 'applicationsubmission.cancel' })}
        </button>
        <button
          onClick={() => onSend({ coverLetter, attachments, isGdprConsent })}
          className={`submit`}
        >
          {intl.formatMessage({ id: 'applicationsubmission.submit' })}
        </button>
      </Footer>
    </ApplyFormStyled>
  )
}

export default ApplyForm
