import React from 'react'
import { useQuery as useRouteQuery } from '_utils/router'
import { BaseTile } from '_tiles'
import SpanBasedGrid from '_components/span-based-grid'
import { Default as BlogTile } from '../components/tiles'
import { useUserBlogAccess } from '_security/helpers'

const blogTilesSetupColumnsNumber = {
  mobile: [],
  tablet: [2],
  desktop: [3, 2, 1, 1, 1, 1, 1, 2],
}

const SingleTile = ({
  key,
  className,
  config,
  url = '',
  item,
  isAdditionalAuthEnabled,
  isAdditionalAuth,
  queryParams,
  refetchData,
}) => {
  return (
    <BaseTile
      key={key}
      config={config}
      url={`${url}/${item.id}`}
      isAdditionalAuth={isAdditionalAuth}
      isAdditionalAuthEnabled={isAdditionalAuthEnabled}
      queryParams={queryParams}
      testid="blog"
    >
      <BlogTile post={item} className={className} refetchData={refetchData} />
    </BaseTile>
  )
}

const BlogGrid = ({ posts, refetchData }) => {
  const hasUserBlogAccess = useUserBlogAccess()
  const [category] = useRouteQuery('category')

  return (
    <SpanBasedGrid
      gridItems={posts}
      SingleTile={SingleTile}
      url={'/blog'}
      isAdditionalAuthEnabled={true}
      isAdditionalAuth={hasUserBlogAccess}
      queryParams={category ? `?category=${category}` : ''}
      testid="blog"
      gridConfig={blogTilesSetupColumnsNumber}
    />
  )
}

export default BlogGrid

