import styled from '@emotion/styled'

export const StylesBody = styled.div`
  background-color: var(--background);
  padding: 10px 20px;
  border-left: 1px solid var(--foreground);
  border-right: 1px solid var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  h2 {
    margin-bottom: 15px;
    font-weight: 700;
  }
  label {
    display: block;
    font-weight: 600;
  }
  input {
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid var(--shade);
    background-color: transparent;
    color: var(--foreground);
    &::placeholder {
      color: var(--foreground);
    }
  }
`

export const StylesFooter = styled.div`
  background-color: var(--background);
  border-bottom: 1px solid var(--foreground);
  border-left: 1px solid var(--foreground);
  border-right: 1px solid var(--foreground);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  button {
    padding: 8px 22px;
    border: none;
    background-color: var(--foreground);
    color: var(--background);
    border-radius: 8px;
    margin-right: 5px;
  }
`
