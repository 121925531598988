import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export const OpportunityApplicationMessage = ({
  item,
  defaultMessage,
  deleteAction,
  linkUrl,
  entityName,
}) => {
  const { applicationId, opportunity } = item.notification.payload[0]

  const ContentComponent = () => (
    <span>
      <FormattedMessage
        id={'notificationscenter.notification.message.newApplicationMessage'}
        defaultMessage={'You have a new message regarding an application for'}
      />
      {' ' + opportunity?.name + '. '}
      <Link to={'/opportunities/applications/' + applicationId}>
        <FormattedMessage
          id={'notificationscenter.notification.message.viewHere'}
          defaultMessage={'View here.'}
        />
      </Link>
    </span>
  )

  return (
    <BaseNotification
      image={opportunity?.coverPhoto}
      item={item}
      deleteAction={deleteAction}
      ContentComponent={ContentComponent}
      singleButtonAction={() => null}
      hideSender={true}
    />
  )
}
