import React from 'react'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import CustomAttributesImages from './ca-images'
import CustomAttributesPdfs from './ca-pdfs'
import { Wrapper } from '../styles/media-uploader'
import BreakLine from './breakline'

const CustomAttributesMediaUploader = ({
  attribute,
  attributeValue,
  attributeConfig: { config, onSave, onDelete },
}) => {
  const { showWidget } = useMediaUploader(config.uploadConfig, (media) =>
    onSave({ key: attribute.key, media })
  )

  const _renderValues = () => {
    switch (attribute.type) {
      case 'image':
        return (
          <CustomAttributesImages
            attributes={attributeValue?.value}
            onDelete={onDelete}
            currentAttribute={attribute}
          />
        )
      case 'file':
        return (
          <CustomAttributesPdfs
            attributes={attributeValue?.value}
            onDelete={onDelete}
            currentAttribute={attribute}
          />
        )
      default:
        return <></>
    }
  }

  const hasLimit = attributeValue?.value?.length >= config.limit

  return (
    <>
      <Wrapper hasLimit={hasLimit}>
        <h4 className="title">{attribute.name}</h4>
        <p className="description">{attribute.description}</p>
        <button
          onClick={() => showWidget()}
          className="upload-btn"
          disabled={hasLimit}
        >
          Upload
        </button>
      </Wrapper>

      {attributeValue?.value?.length > 0 && _renderValues()}
      <BreakLine />
    </>
  )
}

export default CustomAttributesMediaUploader
