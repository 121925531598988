import styled from '@emotion/styled'
import css from '@emotion/css'
import { H2 } from '_uikit/headers'
import { ctaButton } from '_utils/css-buttons'
import { pablet } from '_utils/responsive-queries'

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  .children {
    padding-bottom: 50px;
  }
`

export const Title = styled(H2)`
  font-size: 36px;
  font-weight: 600;
  color: var(--foreground);
`

export const Description = styled.div`
  color: var(--foreground);
  width: 100%;
  margin-top: 10px;
  p {
    color: var(--foreground);
    width: 100%;
    max-width: 40em;
    font-size: 16px;
    font-weight: 600;
  }
`

export const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 50px 0;

  button {
    display: block;
    ${pablet} {
      display: none;
    }
  }

  ${({ type }) =>
    type === 'events' &&
    css`
      padding: 0;
      padding-top: 50px;

      ${pablet} {
        display: flex;
        flex-direction: column;
      }
    `}
`

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const EventsCategorySelect = styled.div`
  width: 200px;
  margin-left: 8px;

  .full-selector {
    position: relative;
    width: 100%;
    border: solid 1px var(--foreground);
    border-radius: 6px;
    color: var(--foreground);
    .btn-dropdown {
      background: transparent;
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      padding: 10px;
      border: none;
      width: 100%;
      text-align: left !important;
      font-size: 14px;
      font-family: ${(props) => props.theme.fonts.googleFont} !important;
      color: var(--foreground) !important;
      i {
        margin-left: 8px;
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    ul {
      position: absolute;
      width: 100%;
      top: 45px;
      left: 0;
      opacity: 0;
      transition: opacity 0.24s;
      z-index: 10000 !important;
      background-color: var(--background);
      box-shadow: 0px 0px 10px #e5e5e5;
      padding: 15px;
      border-radius: 6px;
      max-height: 200px;
      overflow-y: auto;
      background: var(--background);
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        list-style: none;
        padding: 5px 4px;
        font-family: ${(props) => props.theme.fonts.googleFont} !important;
        color: var(--foreground);
        cursor: pointer;
        &:last-of-type {
          border: none;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &.active {
          color: var(--foreground);
          background: rgba(0, 0, 0, 0.05);
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
  }
`

export const DiscoverAll = styled.button`
  ${ctaButton}
  /* width: 100%; */

${({ type }) =>
    type === 'events' &&
    css`
      margin-left: auto;
      margin-right: 0;
    `}



  display: none;

  p {
    font-weight: 700;
  }

  ${pablet} {
    display: block;
  }
`
