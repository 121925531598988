import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import { Modal } from '_components/drilldown'

import BlogPostDetails from '../components/blog-post-details'
import RecommendButton from '_components/buttons-connections/recommend-button'

import { useUserBlogAccess } from '_security/helpers'

import LoginRequiredModal from '_security/login-required-modal'

const GET_POST = gql`
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      coverPhoto
      description
      _createdAt
      content
      isRestricted
      enabledToc
      likesCount
      isLiked
      isDraft
      creatorId
      categories {
        id
        title
      }
      categoriesNew {
        id
        name
        groupBy
      }
      _updatedAt
      editor {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
        bioShort
        location
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

const Props = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const BlogPostConnected = ({ id, onClose, refetchGridData }) => {
  const { data, error, loading, refetch } = useQuery(GET_POST, { variables: { id } })

  const { isRestricted } = data?.getBlogPost || {}

  const hasUserBlogAccess = useUserBlogAccess(isRestricted)

  const post = data?.getBlogPost

  if (loading) {
    return <Loader loading={loading} error={error} />
  }

  if (!hasUserBlogAccess && !loading) {
    return <LoginRequiredModal onClose={onClose} />
  }

  return (
    <Modal onClose={onClose}>
      <Loader loading={loading} error={error}>
        {() => (
          <BlogPostDetails
            post={post}
            refetchGridData={refetchGridData}
            refetchData={refetch}
            onClose={onClose}
            recommendButton={
              <RecommendButton
                contentToRecomment={post?.id}
                queryKey={'blogToRecommend'}
              />
            }
            enabledToc={post?.enabledToc}
          />
        )}
      </Loader>
    </Modal>
  )
}
BlogPostConnected.propTypes = Props

export default BlogPostConnected
