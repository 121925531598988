import React from 'react'
// import styled from '@emotion/styled'
import { Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'
import ScrollToTop from '_components/scroll-to-top'
import { useFilters } from '_utils/filters'
import OpportunitiesHeader from './components/opportunities-header'
import AddNew from './components/opportunity-new-button'
import OpportunitiesMapConnected from './connected/opportunities-map-connected'
import OpportunitiesGridConnected from './connected/opportunities-grid-connected'
import OpportunityDetailsConnected from './connected/opportunity-details-connected'
import { useAppLabel, usePageTitle } from '_application'
import { Container } from '../_styled-components/shared/section-container'
import { useUser } from '_security/session-hooks'
import ApplicationManagementRouter from 'components/indy/user/content-studio'
import OpportunityForm from '_content-studio/forms/opportunity'

const Opportunities = () => {
  const { filter, setFilter } = useFilters()

  const appLabel = useAppLabel()
  const title = appLabel('opportunitiesPlural', 'opportunities.titleplural')
  const description = appLabel('opportunitiesDescription', 'empty')
  usePageTitle(title)

  const user = useUser()

  return (
    <Switch>
      <Route
        path={`/opportunities/:opportunityId/applications`}
        component={ApplicationManagementRouter}
      />
      <Route
        path={`/opportunities/applications`}
        component={ApplicationManagementRouter}
      />
      <Route exact path={['/opportunities/:section/new', '/opportunities/:section/:id/edit']} component={OpportunityForm} />
      <Route
        path="*"
        render={() => (
          <MainLayout>
            <ScrollToTop />
            <Container className={"page-wrapper"}>
              <PageTitle
                description={description}
                actions={user.authorized && <AddNew />}
              >
                {title}
              </PageTitle>
              <OpportunitiesHeader filter={filter} onFilterChange={setFilter} />
              <Switch>
                <Route
                  path="/opportunities/:section/:id"
                  render={({ match, history, location }) => (
                    <OpportunityDetailsConnected
                      id={match.params.id}
                      onClose={() =>
                        history.push(
                          `/opportunities/${match.params.section}${location.search}`
                        )
                      }
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/opportunities/map"
                  render={() => <OpportunitiesMapConnected filter={filter} />}
                />
                <Route
                  path="/opportunities/list"
                  render={() => <OpportunitiesGridConnected filter={filter} />}
                />

                <Redirect exact from="/opportunities" to="/opportunities/list" />
              </Switch>

            </Container>
          </MainLayout>
        )}
      />
    </Switch>
  )
}

export default Opportunities
