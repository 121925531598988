import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  children: PropTypes.node
}

export const Footer = themable('Footer', Props)
export default Footer

