import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'

import { GET_TAXONOMY } from '../graph-queries/taxonomy'

import { Row, Col } from 'react-bootstrap'
import ItemList, { BasicInfo, NoContent } from '../shared/items-list'

import ManageTaxonomy from '../shared/modals/manage-taxonomy'
import { MainWrapper, Section } from '../styles'
import { useIntl } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const Keywords = ({ user, dispatch }) => {
  const [currentTaxonomy, setCurrentTaxonomy] = useState()

  const { data: taxonomyData } = useQuery(GET_TAXONOMY, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['people'] },
  })

  const intl = useIntl()

  const onSave = (values) => {
    const newTaxonomyList = user.profile.taxonomies?.filter(
      (k) => k.key === currentTaxonomy.key
    )[0]
      ? user.profile.taxonomies.map((k) => {
          return {
            key: k.key,
            values: k.key === currentTaxonomy.key ? values : k.values,
          }
        })
      : [...user.profile.taxonomies, { key: currentTaxonomy.key, values }]
    saveProfile({
      ...user.profile,
      taxonomies: newTaxonomyList,
    })
    setCurrentTaxonomy(null)
  }

  const onClose = () => {
    setCurrentTaxonomy(false)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  return (
    <MainWrapper>
      <Section>
        <span className="section-title">
          {intl.formatMessage({ id: 'user.profile.topics' })}
        </span>
        <div style={{ marginTop: '25px' }}>
          <ItemList>
            {taxonomyData && taxonomyData?.getTaxonomy?.length > 0 ? (
              taxonomyData.getTaxonomy.map((key) => {
                const valuesList =
                  user.profile?.taxonomies?.filter((k) => k.key === key.key)[0]
                    ?.values || []
                return (
                  <BasicInfo>
                    <Row>
                      <Col sm={12} md={8}>
                        <Row>
                          <Col sm={8}>
                            <span className="name">{key.name}</span>
                            <span className="description">
                              {key.description}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <button
                        onClick={() => setCurrentTaxonomy(key)}
                        className="edit"
                      >
                        <i className="fas fa-pen" />
                      </button>
                    </Row>
                    <ul className="values-list">
                      {valuesList.map((v) => {
                        const val = key?.treeData?.find((k) => k.refId === v)
                          ?.name

                        return (
                          <>
                            {val && (
                              <li data-testid={`topics-${key.name}-${val}`}>
                                {val}
                              </li>
                            )}
                          </>
                        )
                      })}
                    </ul>
                  </BasicInfo>
                )
              })
            ) : (
              <NoContent>no content</NoContent>
            )}
            {currentTaxonomy && (
              <ManageTaxonomy
                taxonomyList={currentTaxonomy}
                onSave={onSave}
                onClose={onClose}
                taxonomyValues={
                  user.profile?.taxonomies?.filter(
                    (k) => k.key === currentTaxonomy.key
                  )[0]?.values || []
                }
              />
            )}
          </ItemList>
        </div>
      </Section>
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(Keywords)
