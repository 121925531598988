export default [
  {
    key: 'stripe',
    name: 'Stripe',
    type: 'payment',
    icon: 'fab fa-cc-stripe',
    firstTimeSetup: true,
    data: [
      {
        key: 'isLiveMode',
        name: 'Enable live mode',
        type: 'boolean',
      },
    ],
  },
  {
    key: 'intercom',
    name: 'Intercom',
    type: 'other',
    icon: 'fab fa-intercom',
    data: [
      {
        key: 'appId',
        name: 'Application ID',
        type: 'text',
      },
      {
        key: 'apiKey',
        name: 'API Key',
        type: 'text',
      },
    ],
  },
  {
    key: 'google-analytics',
    name: 'Google analytics',
    type: 'google',
    icon: 'fa fa-google',
    data: [
      {
        key: 'measurementId',
        name: 'Measurement Id for v4',
        type: 'text',
      },
      {
        key: 'trackingId',
        name: 'Tracking Id for v3 and all legacy versions',
        type: 'text',
      },
    ],
  },
  {
    key: 'mailchimp',
    name: 'Mailchimp',
    type: 'other',
    icon: 'fab fa-mailchimp',
    data: [
      {
        key: 'integrationCode',
        name: 'Integration code',
        type: 'textarea',
      },
    ],
  },
  {
    key: 'hubspot',
    name: 'Hubspot',
    type: 'other',
    icon: 'fab fa-hubspot',
    data: [
      {
        key: 'accountId',
        name: 'Account Id',
        type: 'text',
      },
    ],
  },
  {
    key: 'uxtweak',
    name: 'Uxtweak',
    type: 'other',
    icon: 'fas fa-puzzle-piece',
    data: [
      {
        key: 'script',
        name: 'UXtweak snippet',
        type: 'textarea',
      },
    ],
  },
]
