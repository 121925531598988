import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { MessageRenderer } from './message-renderer'
import {
  Dots,
  DotsExpanded,
  AcceptButton,
  DeclineButton,
  NotificationsWrapper,
  Info,
  DotsWrapper,
} from '../styles/single-notification'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { RESPOND_ENTITY_MANAGER_INVITATION } from '_entities/entityManagers/gql'

const checkNameValue = (user) => {
  const { firstName, lastName } = user || {}
  if ((!firstName || firstName === '') && (!lastName || lastName === ''))
    return ' a Stranger'
  return ` ${firstName ? firstName : ''} ${lastName ? lastName : ''}`
}

const SingleNotification = ({ notification, deleteAction, acceptAction }) => {
  const intl = useIntl()
  const [expandedId, setExpandedId] = useState(null)
  const [isDotsExpanded, setIsDotsExpanded] = useState(false)

  const subtype = notification.notification.subtype

  const toggleExpand = (e) => {
    const currentId = e.currentTarget.id
    setIsDotsExpanded(!isDotsExpanded)
    if (currentId === expandedId) return setExpandedId(null)
    return setExpandedId(currentId)
  }

  const [respondMutation] = useMutation(RESPOND_ENTITY_MANAGER_INVITATION)
  if (notification?.notification?.subtype?.match(/management-invitation$/)) {
    const respondCallback = (state) =>
      respondMutation({
        variables: { notificationId: notification.notification._id, state },
        refetchQueries: [
          'GetSortedNotifications',
          'GetAllOpportunitiesList',
          'GetDiscoverOpportunities',
        ],
      })
    acceptAction = () => respondCallback('accepted')
    deleteAction = () => respondCallback('declined')
  }

  const handleClose = () => {
    if (expandedId || isDotsExpanded) {
      setIsDotsExpanded(false)
      return setExpandedId(null)
    }
  }
  const ref = useClickOutside(handleClose)

  return (
    <NotificationsWrapper
      isRecommend={subtype.includes('recom')}
      className={`${
        expandedId === notification.notification._id && isDotsExpanded
          ? 'force-show'
          : ''
      }`}
    >
      <Link
        to={
          subtype === 'application-applicant-messaging'
            ? `/opportunities/applications/${notification.notification.payload[0].applicationId}`
            : `/user/notifications-center`
        }
        data-testid={`nav-notification-${notification?.user?.sub}-${subtype}`}
      >
        <Info>
          <MessageRenderer
            type={subtype}
            notification={notification.notification}
          />
          {subtype !== 'application-applicant-messaging' &&
            checkNameValue(notification?.user)}
        </Info>
      </Link>
      {!['application-applicant-messaging', 'forum'].includes(subtype) && !subtype.includes('ownership') &&(
        <DotsWrapper isRecommend={subtype.includes('recom')}>
          {!subtype.includes('recom') && (
            <Dots
              id={notification.notification._id}
              onClick={(e) => toggleExpand(e)}
            >
              <i className="fas fa-ellipsis-v" />
            </Dots>
          )}
          {expandedId === notification.notification._id && isDotsExpanded && (
            <DotsExpanded ref={ref}>
              <AcceptButton onClick={() => acceptAction()}>
                <i className="fas fa-thumbs-up" />
                <span>
                  {intl.formatMessage({
                    id: 'nav.notifications.button.accept',
                  })}
                </span>
              </AcceptButton>
              <DeclineButton onClick={() => deleteAction()}>
                <i className="fas fa-thumbs-down" />
                <span>
                  {intl.formatMessage({
                    id: 'nav.notifications.button.decline',
                  })}
                </span>
              </DeclineButton>
            </DotsExpanded>
          )}
        </DotsWrapper>
      )}
    </NotificationsWrapper>
  )
}

export default SingleNotification
