import React from 'react'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'
import moment from 'moment'

import AddEditModal from '../shared/modals/add-edit'

const fields = [
  { key: 'schoolName', name: 'School name', type: 'text', required: true },
  { key: 'degree', name: 'Degree', type: 'text', required: true },
  { key: 'domain', name: 'Field of study', type: 'text' },
  {
    key: 'yearGraduated',
    name: 'Year graduated',
    type: 'date',
    minDate: moment().subtract(70, 'years'),
    maxDate: moment().add(10, 'years'),
  },
  {
    key: 'websiteUrl',
    name: 'Website',
    type: 'url',
    placeholder: 'https://...',
  },
]

const mapStateToProps = ({ user }) => ({ user })

const Modal = ({ user, currentEducation, onClose, dispatch }) => {
  const handleSave = (props) => {
    let list
    if (currentEducation) {
      list = user.profile.education.map((e) =>
        e.key === props.key ? props : e
      )
    } else {
      list = [...user.profile.education, { ...props, key: uuid() }]
    }
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, education: list },
      },
    })
    onClose()
  }

  return (
    <AddEditModal
      title={'Education'}
      defaultProps={currentEducation}
      user={user}
      onSave={(props) => handleSave(props)}
      onClose={() => onClose()}
      fields={fields}
    />
  )
}

export default connect(mapStateToProps)(Modal)
