const generateMargins = (num = 25) => {
  const margin = {
    normal: num + "px",
    small: num / 2 + "px",
    medium: num * 2 + "px",
    large: num * 3 + "px",
    extraLarge: num * 4 + "px",
  }

  return margin
}

export default generateMargins
