import styled from '@emotion/styled'

const Content = styled.div`
  background-color: var(--background) !important;
  min-height: 100vh;
  padding: 150px 50px;
  .container {
  }
  .tab-title {
    padding-left: 30px;
    font-size: 20px;
    color: var(--foreground);
    font-weight: 600;
  }
  .content {
    padding: 25px 30px 25px 30px;
    background: var(--background);

    .filter-and-search-wrapper {
      display: flex;
      flex-wrap: nowrap;
      .user-overview-table {
        width: 100%;
      }
    }
  }
`

export default Content
