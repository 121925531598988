import { useQuery, gql, useMutation } from '@apollo/client'
import { mapBlocksToEntity } from './mapper'

export const GET_PAGE_BUILDER_CONTEXT = gql`
  query GetPageBuilderContext($contextId: String, $draft: Boolean!) {
    getPageBuilderContext(contextId: $contextId) {
      _id
      type
      pageBuilder {
        contextId
        editorState
        draftEditorState @include(if: $draft)
      }
    }
  }
`

export const GET_CONTENT_BLOCKS = gql`
  query GetPageBuilderContext($contextId: String, $draft: Boolean!) {
    getPageBuilderContext(contextId: $contextId) {
      pageBuilder {
        contextId
        contentBlocks {
          id
          type
          content
          category
          name
        }
        draftContentBlocks @include(if: $draft) {
          id
          type
          content
          name
          category
        }
      }
    }
  }
`

const SAVE_PAGE_BUILDER_CONTEXT = gql`
  mutation SavePageBuilderContext(
    $context: PageBuilderInput
    $draft: Boolean!
  ) {
    savePageBuilderContext(context: $context, draft: $draft) {
      _id
    }
  }
`

export const PUBLISH_PAGE_BUILDER_DRAFT = gql`
  mutation PublishDraft($contextId: String!) {
    publishDraft(contextId: $contextId) {
      _id
    }
  }
`

export const usePageBuilder = (contextId, draft) => {
  const { data, loading, refetch } = useQuery(GET_PAGE_BUILDER_CONTEXT, {
    variables: { contextId, draft: draft || false },
    fetchPolicy: draft ? 'network-only' : undefined,
    skip: !contextId,
  })
  const [save] = useMutation(SAVE_PAGE_BUILDER_CONTEXT)

  const pageBuilderData = data?.getPageBuilderContext

  return { save, data: pageBuilderData, loading, refetch }
}

export const useContentBlocks = (contextId, draft) => {
  const { data, loading, refetch } = useQuery(GET_CONTENT_BLOCKS, {
    variables: { contextId, draft: draft || false },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: draft ? 'network-only' : undefined,
  })

  const contentBlocks = data?.getPageBuilderContext?.pageBuilder?.contentBlocks

  const draftContentBlocks =
    data?.getPageBuilderContext?.pageBuilder?.draftContentBlocks

  return { data: contentBlocks, loading, refetch, draftContentBlocks }
}

export { mapBlocksToEntity }
