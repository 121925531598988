import React from 'react'
import { Avatars } from '_tiles'
import EntityEditButton from '_tiles/shared/entity-edit-button'
import moment from 'moment'
import { useIntl } from 'react-intl'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useUserAccess } from '_security/helpers'
import { useUser } from '_security/session-hooks'
import { useTheme } from 'emotion-theming'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon.svg'
import { useIsDiscoverPage } from '_application/is-discover-page'

import {
  DefaultTileStyled,
  Header,
  EntityInfo,
  Content,
  Title,
  DiscoverTitle,
  Description,
  Date,
  ConnectButtonContainer,
  Controls,
  LikeWrapper,
  ApplicationLabelsWrapper,
} from './styled/default'
import { ApplicationCountButton } from '../applicationCountButton'

const DefaultTile = ({ children, opportunity, connectButton, isOwner }) => {
  const { creator, coverPhoto, name, description, title, excerpt  } = opportunity || {}
  const intl = useIntl()
  const hasUserAccess = useUserAccess()
  const user = useUser()
  const isAdmin = user?.profile?.role === 'admin'
  const theme = useTheme()
  const location = opportunity?.location?.label
  const isDiscoverPage = useIsDiscoverPage()
  const isExpired = moment().isAfter(opportunity?.expire)

  const applyBy = () => {
    if (opportunity?.expire) {
      return isExpired
        ? intl.formatMessage({ id: 'opportunity.tile.applyby.expired' })
        : `${intl.formatMessage({
          id: 'opportunity.tile.applyby',
        })} ${moment(opportunity?.expire).format('DD.MM.YYYY')}`
    }

    return ' '
  }

  const _renderTitle = () => (
    <>
      {isDiscoverPage ? (
        <DiscoverTitle>{name || title}</DiscoverTitle>
      ) : (
        <Title>{name || title}</Title>
      )}
    </>
  )

  const opportunityId = opportunity?.id || opportunity?._id

  return (
    <DefaultTileStyled>
      <Header
        isExpired={isExpired}
        style={{
          backgroundImage: `url(${coverPhoto?.replace('upload/', 'upload/c_fill,w_400,h_400/') || theme?.images?.defaultCover})`,
        }}
      >
        {location && (
          <span className="location">
            <LocationIcon />
            {location}
          </span>
        )}
        {(isOwner || isAdmin ) && (
          <Controls>
            <EntityEditButton url={`/opportunities/list/${opportunityId}/edit`} />
          </Controls>
        )}
        <div className="overlay" />
        {false && (
          <LikeWrapper>
            <i class="far fa-heart" />
          </LikeWrapper>
        )}
      </Header>
      <Content>
      <EntityInfo>
          <Avatars
             creator={creator}
             connections={opportunity?.connectedCompanies}
           />
        </EntityInfo>
        {_renderTitle()}
        <ApplicationLabelsWrapper>
          {opportunity?.expire && (
            <Date isExpired={isExpired}>
              <span>{applyBy()}</span>
            </Date>
          )}

          <ApplicationCountButton opportunity={opportunity} />
        </ApplicationLabelsWrapper>
        <Description>{description || excerpt}</Description>
        <ConnectButtonContainer>
          {connectButton && (
            <ConnectButton
              status={hasUserAccess ? opportunity.connectionStatus : null}
              onConnect={hasUserAccess ? connectButton?.onConnect : () => { }}
              disabled={connectButton?.disabled}
              type="opportunities"
            />
          )}
        </ConnectButtonContainer>
      </Content>
    </DefaultTileStyled>
  )
}

export default DefaultTile
