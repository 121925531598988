import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { ctaButtonHollow } from '_utils/css-buttons'
import border from '_utils/css-border'
import styled from '@emotion/styled'

const ModalStyled = styled(Modal)`
  .modal-content {
    background: var(--background);
    border-radius: 10px;
    padding: 36px 16px 16px;
    width: 357px;
    margin: 240px auto 0;
    position: relative;
  }

  .all-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }

  .info-title {
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 18px;
    color: var(--foreground);
    font-weight: 700;
  }

  .info-description {
    margin-top: 10px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 14px;
    color: var(--foreground);
    font-weight: 400 !important;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    padding-top: 36px;
  }

  button {
    &.x-button {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background-color: transparent;
      transition: 0.3s;
      &:hover {
        opacity: 0.6;
      }
    }
    &.proceed-button,
    &.close-button {
      margin-left: 10px;
      ${ctaButtonHollow}
    }
  }
`

const InfoModal = ({
  title,
  description,
  proceedButton,
  cancelButton,
  onClose,
  onProceed,
  isOpen
}) => {
  return (
    <ModalStyled
      dialogClassName="info-modal-container"
      show={isOpen}
      onHide={onClose}
    >
      <button className="x-button" onClick={onClose}>
        <i className="fas fa-times" />
      </button>
      <div className="all-info-container">
        <h2 className="info-title">{title}</h2>
        <p className="info-description">{description}</p>
      </div>

      <div className="buttons-container">
        <button type="button" className="proceed-button" onClick={onProceed}>
          {proceedButton}
        </button>
        <button type="button" onClick={onClose} className="close-button">
          {cancelButton}
        </button>
      </div>
    </ModalStyled>
  )
}

export default InfoModal
