import React from 'react'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'

import AddEditModal from '../../shared/modals/add-edit'

const mapStateToProps = ({ user }) => ({ user })

const Modal = ({ user, currentItem, onClose, dispatch, fields, type }) => {
  const handleSave = (props) => {
    let list
    if (currentItem) {
      type === 'education'
        ? (list = user.profile[type].map((e) =>
            e.key === props.key ? props : e
          ))
        : (list = user.profile[type].map((e) =>
            e.key === props.key
              ? { ...props, location: props.location?.label || props.location }
              : e
          ))
    } else {
      type === 'education'
        ? (list = [...user.profile[type], { ...props, key: uuid() }])
        : (list = [
            ...user.profile[type],
            {
              ...props,
              location: props.location?.label || props.location,
              key: uuid(),
            },
          ])
    }

    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: list },
      },
    })

    onClose()
  }

  return (
    <AddEditModal
      type={type}
      defaultProps={currentItem}
      user={user}
      onSave={(props) => handleSave(props)}
      onClose={() => onClose()}
      fields={fields}
    />
  )
}

export default connect(mapStateToProps)(Modal)
