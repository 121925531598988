export const getPageSizeForScreenSize = (props) => {
  const { type, pageType } = props || {}

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )
  if (pageType === 'discoverPage') {
    if (type === 'company' || type === 'companies') {
      if (vw > 1200) {
        return 9
      } else if (vw >= 994 && vw <= 1200) {
        return 6
      } else if (vw >= 577 && vw <= 1200) {
        return 6
      } else {
        return 4
      }
    } else {
      if (vw > 1200) {
        return 8
      } else if (vw >= 994 && vw <= 1200) {
        return 6
      } else if (vw >= 577 && vw <= 993) {
        return 4
      } else {
        return 4
      }
    }
  }

  if (pageType === 'grid') {
    if (type === 'company' || type === 'companies') {
      if (vw >= 1200) {
        return 30
      } else if (vw >= 992 && vw <= 1199) {
        return 20
      } else if (vw >= 577 && vw <= 1200) {
        return 10
      } else {
        return 10
      }
    } else {
      if (vw >= 1200) {
        return 40
      } else if (vw >= 992 && vw <= 1199) {
        return 30
      } else if (vw >= 768 && vw <= 991) {
        return 20
      } else if (vw <= 767) {
        return 10
      } else {
        return 10
      }
    }
  }

  if (pageType === 'connections') {
    if (type === 'company' || type === 'companies') {
      if (vw > 1200) {
        return 4
      } else if (vw >= 994 && vw <= 1200) {
        return 4
      } else if (vw >= 577 && vw <= 1200) {
        return 4
      } else {
        return 4
      }
    } else {
      if (vw > 1200) {
        return 6
      } else if (vw >= 994 && vw <= 1200) {
        return 6
      } else if (vw >= 577 && vw <= 993) {
        return 6
      } else {
        return 6
      }
    }
  }
}
