import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons.js'

export const LoadMoreContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

export const LoadMoreButton = styled.button`
  ${ctaButtonHollow}
  font-weight: 600;
`
