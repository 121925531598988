import gql from 'graphql-tag'

export const GET_KEYWORDS = gql`
  query GetKeywords($key: String, $usedBy: [String]) {
    getKeywords(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      keywordList {
        id
        value
      }
    }
  }
`

export const GET_ALL_KEYWORDS = gql`
  query {
    getKeywords {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      keywordList
    }
  }
`
