import React from 'react'
import { ButtonsWrapper } from './styles/action-buttons'

const ActionButtons = ({
  config: {
    isDisabled,
    onSuccess,
    rejectBtnContent,
    onCancel,
    successBtnContent,
  },
}) => {
  return (
    <ButtonsWrapper isDisabled={isDisabled}>
      <button className="reject-btn" onClick={onCancel}>
        {rejectBtnContent}
      </button>
      <button className="success-btn" disabled={isDisabled} onClick={onSuccess}>
        {successBtnContent}
      </button>
    </ButtonsWrapper>
  )
}

export default ActionButtons
