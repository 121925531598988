import { useEffect, useState } from 'react'
import axios from 'axios'

export const useStripeCustomer = () => {
  const [customer, setCustomer] = useState()
  const [loading, setLoading] = useState(true)

  const fetchCustomer = async () => {
    setLoading(true)

    await Promise.all([
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/payment/get-customer`, {
        headers: { authorization: localStorage.getItem('RIOT/token') },
      }),
      axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/payment/get-payment-methods`,
        { headers: { authorization: localStorage.getItem('RIOT/token') } }
      ),
      axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/payment/get-payment-history`,
        { headers: { authorization: localStorage.getItem('RIOT/token') } }
      ),
    ])
      .then(results => {
        const [c, pm, ph] = results;
        setCustomer({
          ...c.data,
          paymentMethods: pm?.data?.data?.reverse() || [],
          paymentHistory: ph?.data?.data || [],
        })
      })
      .catch((err) => console.log('Failed to get Stripe customer', err))
      .finally(() => setLoading(false));

  }

  useEffect(() => {
    fetchCustomer()
  }, [])

  return { customer, loading, refetch: () => fetchCustomer() }
}
