import React from 'react'
import DatePicker from 'react-date-picker'
import BreakLine from './breakline'
import { DatePickerWrapper } from '../styles/ca-date'
import { useAppConfig } from '_application'

const CustomAttributesDatePicker = ({
  attribute,
  attributeValue,
  attributeConfig: { onSave },
}) => {
  const { defaultLocale } = useAppConfig()

  return (
    <>
      <DatePickerWrapper>
        <div className="picker-wrapper">
          <div className="label-wrapper">
            <label className="section-title">{attribute?.name}</label>
            <p className="section-desc">{attribute?.description}</p>
          </div>
          <DatePicker
            calendarClassName="calendar-wrapper"
            value={
              attributeValue?.value
                ? new Date(attributeValue?.value)
                : new Date()
            }
            showLeadingZeros
            clearIcon={false}
            locale={defaultLocale?.replace('_', '-')}
            onChange={(value) => {
              onSave(attribute.key, new Date(value).toISOString())
            }}
          />
        </div>
      </DatePickerWrapper>
      <BreakLine />
    </>
  )
}

export default CustomAttributesDatePicker

