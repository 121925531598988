import React from 'react'
import BaseNotification from './default'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom';

export const ForumMessage = ({ item, deleteAction }) => {
  const intl = useIntl()
  const type = item.notification.body
  let topic,
    author,
    image = '',
    topicId

  if (item.notification.payload.length) {
    topic = item.notification.payload[0]?.title
    author = item.notification.payload[0]?.author
    image = item.notification.payload[0]?.image
    topicId = item.notification.payload[0]?.topicId
  }
  const newTopicId =
    'notificationscenter.notification.forum.admin.newtopic.message'
  const newMessageId =
    'notificationscenter.notification.forum.admin.newmessage.message'
  const ContentComponent = () => (
    <span>
      {intl.formatMessage(
        { id: type === 'NEW_COMMENT_CREATED' ? newMessageId : newTopicId },
        { topic: (<Link to={`/forum/topic/${topicId}`}>{topic}</Link>), author }
      )}
    </span>
  )

  return (
    <BaseNotification
      image={image}
      item={item}
      ContentComponent={ContentComponent}
      singleButtonAction={() => null}
      hideSender={true}
      deleteAction={deleteAction}
    />
  )
}
