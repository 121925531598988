/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useAppConfig } from '_application'
import { Redirect } from 'react-router-dom'
import { useUser } from '_security/session-hooks'

const GET_REMAINING_OPPORTUNITIES_COUNT = gql`
  query GetRemainingOpportunityCount {
    getRemainingOpportunityCount
  }
`

const Secure = ({ children, isEdit }) => {

  const { isOpportunitySubmissionAuthEnabled } = useAppConfig()
  const user = useUser()
  const isAdmin = user?.profile?.role === "admin"

  const {
    data: remainingOpportunities,
    loading: remainingOpportunitiesCountLoading,
  } = useQuery(GET_REMAINING_OPPORTUNITIES_COUNT)
  const remainingOpportunitiesCount = !remainingOpportunitiesCountLoading
    ? remainingOpportunities?.getRemainingOpportunityCount || 0
    : null

  const auth = ((remainingOpportunitiesCount <= 0 && isOpportunitySubmissionAuthEnabled)
    && !isEdit)
    && !isAdmin

  if (!!auth)
    return <Redirect to={'/opportunities/list'} />

  return (
    <>{children}</>
  )
}

export default Secure