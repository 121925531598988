const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SET_REGISTRATION_ERRORS: 'user/SET_REGISTRATION_ERRORS',
  SET_USER_CUSTOM_PROFILE_VALUES: 'user/SET_USER_CUSTOM_PROFILE_VALUES',
  SET_USER_PROFILE_VALUES_NOT_SAVE: 'user/SET_USER_PROFILE_VALUES_NOT_SAVE',
  SET_USER_PROFILE_VALUES: 'user/SET_USER_PROFILE_VALUES',
  SET_CURRENT_CHAT_ROOM: 'user/SET_CURRENT_CHAT_ROOM',
}

export default actions
