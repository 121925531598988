import styled from '@emotion/styled'

export const Container = styled.div`
  #IKUISDK-notification-container {
    height: none !important;
    height: auto !important;
    color: red !important;
    padding: 10px;
    text-align: center;
    text-transform: none !important;
    span {
      padding: 10px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      font-family: ${({ theme }) => theme.fonts.googleFont};
      transition: 0.5s;
      text-transform: none !important;
    }
  }
  padding-top: 0px;
  .inner-indykite-login-container {
    label {
      font-size: 14px;
      font-family: ${({ theme }) => theme.fonts.googleFont};
      color: var(--foreground);
      text-transform: none !important;
    }
    input {
      margin-bottom: 20px !important;
    }
    h5 {
      font-size: 14px;
      font-family: ${({ theme }) => theme.fonts.googleFont};
      border: 2px solid ${({ theme }) => theme.colors.primaryColor};
      padding: 10px;
      color: var(--foreground);
      border-radius: 4px;
      font-weight: 400;
    }
    a {
      color: ${({ theme }) => theme.colors.primaryColor};
      text-decoration: underline;
    }
  }
  #IKUISDK-confirm-password,
  #IKUISDK-password,
  #IKUISDK-username {
    border: 1px solid var(--foreground);
  }
  .IKUISDK-primary-btn, #IKUISDK-btn-action-finish {
    display: inline !important;
    width: auto !important;
    margin: 40px auto 20px !important;
    border-radius: 25px !important;
    padding: 5px 20px !important;
    min-width: 40% !important;
    background: ${({ theme }) => theme.colors.foreground} !important;
    color: ${({ theme }) => theme.colors.background} !important;
    font-size: 16px !important;
    width: auto !important;
    text-decoration: none !important;
    a {
        color: ${({ theme }) => theme.colors.background} !important;
        text-decoration: none !important;
    }
  }
  .IKUISDK-input {
    height: 40px !important;
    color: black !important;
    font-size: 14px !important;
    margin-top: 5px !important;
    padding: 5px 10px !important;
    border-radius: 6px !important;
    background-color: white !important;
    border: 1px solid var(--foreground) !important;
    margin-bottom: 10px !important;
    background-color: white !important;
  }
  .IKUISDK-action-btn {
    text-decoration: underline;
    padding: 0px !important;
  }
  .IKUISDK-message {
    color: #000 !important;
    font-size: 14px !important;
  }
`