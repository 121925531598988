import styled from '@emotion/styled'

export const AssetsViewerStyled = styled.div`
  margin-top: 10px;
`

export const Assets = styled.div``

const removeButton = `
.remove-button{
  margin-left:10px;
  cursor:pointer;
  padding:2px;
  &:hover{
    opacity: .7;
  } 
}`

export const Image = styled.div`
  margin: 18px 0;
  img {
    width: 50px;
    height: 50px;
  }
  ${removeButton}
`

export const Pdf = styled.a`
  margin: 18px 0;
  display: block;
  i {
    font-size: 15px;
  }
  span {
    margin-left: 5px;
    font-size: 15px;
  }
  ${removeButton}
`

export const ImageGalleryStyled = styled.div`
  max-width: 50%;
  margin: 0 auto;
`
