import { gql } from '@apollo/client'

export const CONNECT_EVENT_TO_PERSON = gql`
  mutation Mutation($eventId: String!, $id: String!) {
    connectEventToPerson(eventId: $eventId, personId: $id) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DISCONNECT_EVENT_TO_PERSON = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToPerson(personId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const CONNECT_EVENT_TO_ACTIVITY = gql`
  mutation Mutation($id: String!, $eventId: String!, $activityOwner: String!) {
    connectEventToActivity(
      activityId: $id
      eventId: $eventId
      activityOwner: $activityOwner
    ) {
      sub
      connectedToSub
      status
      updatedAt
    }
  } 
` 
 
export const DISCONNECT_EVENT_TO_ACTIVITY = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToActivity(activityId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const CONNECT_EVENT_TO_COMPANY = gql`
  mutation Mutation($id: String!, $eventId: String!, $companyOwner: String!) {
    connectEventToCompany(
      companyId: $id
      eventId: $eventId
      companyOwner: $companyOwner
    ) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`

export const DISCONNECT_EVENT_TO_COMPANY = gql`
  mutation Mutation($id: String!, $eventId: String!) {
    disconnectEventToCompany(companyId: $id, eventId: $eventId) {
      sub
      connectedToSub
      status
      updatedAt
    }
  }
`