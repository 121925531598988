import styled from '@emotion/styled'

export const FiltersStyled = styled.div`
  width: 20%;
  margin-right: 20px;
  select {
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    width: 100%;
    background-color: var(--foreground);
    color: var(--background);
    &:focus {
      outline: none;
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
`
