import { all, takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'
import store from 'store'
import actions from './actions'

export function* SET_APP_CONFIG({ payload }) {
  console.log(payload)
  yield
}

const GET_APP_CONFIG = `
  query {
    getAppConfig {
	 settings
      metaTitle
      metaKeywords
      backgroundVideoUrl
      theme
      termsAgreementSectionContent
      tenantId
      configurations { kind properties }
      defaultLocale
      features
      backgroundImages{ 
        discoveryPage { value }
      }
      accessPage{
        backgroundImage{
          type
          value 
        }
        title
        description
        requestAccessEmail
        enabled
      } 
      metaData{
        siteTitle
        siteTagline
      }
      email { 
        value
        key
      } 
      labelOverrides {
        activities {
          key
          value
        }
        opportunities {
          key
          value
        }
        opportunitiesEmail {
          key
          value
        }
        discoverTitle {
          key
          value
        } 
        discoverWelcome {
          key
          value
        }
      }
      logos {
        lightLogo {
          key,
          value,
          ratio
        },
        darkLogo {
          key,
          value,
          ratio
        }
      }
    }
  }
  `
export function* LOAD_APP_CONFIG() {
  const d = yield call(() => {
    return axios({
      url: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
      method: 'post',
      data: { query: GET_APP_CONFIG },
    })
      .then(r => {
        return r.data
      })
      .catch(err => console.error(err))
  })
  if (!d.data.getAppConfig.labelOverrides)
    d.data.getAppConfig.labelOverrides = {
      activities: { value: 'Activities', key: 'activities' },
      opportunities: { value: 'Opportunities', key: 'opportunities' },
    }
  if (d.data.getAppConfig /* && d.data.getAppConfig.labelOverrides */) {
    // const { labelOverrides } = d.data.getAppConfig
    yield put({
      type: 'application/SET_APP_CONFIG',
      payload: d.data.getAppConfig,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_APP_CONFIG, SET_APP_CONFIG),
    LOAD_APP_CONFIG(),
  ])
}
