import { noop } from 'lodash'

export const validateResponse = (res) => {
  if (res?.ok) {
    return res
  } else {
    return { error: true } //new Error(`Request failed (${res.status}): ${res?.url}`)
  }
}

export const toJson = (res) => {
  if (res && Object.prototype.hasOwnProperty.call(res, 'json')) {
    return res.json()
  }
  if (res.json) {
    return res.json()
  }
  return res
}

export const abortController = () => {
  if (window.AbortController) {
    return new AbortController()
  } else {
    return {
      abort: noop,
      signal: {},
    }
  }
}

export default {
  validateResponse,
  toJson,
}
