import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import Map from '_map-with-result-grid'
import { useHistoryPreservingSearch } from '_utils/router'
import { toMarkers } from '../activities-models'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'

const GET_ACTIVITIES = gql`
  query GetAllActivitiesMap(
    $filter: ActivityFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllActivitiesPublic(filter: $filter, ordering: $ordering) {
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        connectedCompanies {
          id
          coverPhoto
          name
          location
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const ActivitiesMapConnected = ({ filter }) => {
  const history = useHistoryPreservingSearch()

  let ordering = undefined
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error } = useQuery(GET_ACTIVITIES, {
    variables: { filter, ordering },
    notifyOnNetworkStatusChange: true,
  })

  const activities = data?.getAllActivitiesPublic?.data

  const markers = useMemo(() => toMarkers(activities || []), [activities])
  const showActivity = useCallback(
    (activity) => history.push(`/activities/map/${activity.id}`),
    [history]
  )

  if (!activities && loading) return <Loading />
  if (error) return <Error />

  return <Map markers={markers} onSelect={showActivity} type="activities" />
}
ActivitiesMapConnected.propTypes = {
  filter: PropTypes.object,
}

export default ActivitiesMapConnected
