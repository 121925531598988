import styled from '@emotion/styled'

const DetailedInfoStyled = styled.li`
  position: relative;
  padding: 50px 50px 50px;
  border: 1px solid var(--shade) !important;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  .icon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    i {
      font-size: 10px;
      background: var(--foreground);
      color: var(--background);
      padding: 1em;
      border-radius: 100%;
    }
  }

  span {
    display: block;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.text-bold {
      color: var(--foreground);
      font-weight: 700;
      font-size: 16px;
    }
    &.text-standard {
      color: var(--foreground);
      font-weight: 600;
      font-size: 14px;
    }
    &.text-url {
      color: var(--foreground);
      font-weight: 700;
      font-size: 14px;
      padding-top: 20px;
    }
  }
  
  button {
    position: absolute;
    border-radius: 50%;
    border: none;
    height: 25px;
    width: 25px;
    font-size: 10px;
    background: var(--shade);
    color: var(--background);
    &.edit {
      top: 10px;
      right: 10px;
    }
    &.delete {
      top: 10px;
      right: 40px;
    }
  }
`

export default DetailedInfoStyled
