import React from 'react'
import { useIntl } from 'react-intl'
import {
  CoverPhotoWrapper,
  StyledUploadDiv,
  IconWrapper,
} from './styles/banner-image'
import ReactTooltip from 'react-tooltip'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import BreakLine from './break-line'

const BannerImage = ({
  bannerImg,
  uploadConfig,
  onSave,
  name,
  description,
  isRequired = true,
  type = '',
}) => {
  const intl = useIntl()
  const { showWidget } = useMediaUploader(uploadConfig, (media) =>
    onSave(media?.url)
  )

  const handleClick = (e) => {
    e.preventDefault()
    showWidget()
  }
  return (
    <>
      <CoverPhotoWrapper>
        <div className="label-wrapper">
          <label>
            {name ||
              intl.formatMessage({
                id: 'events.createevent.general.banerimage.title',
                defaultMessage: 'Banner image',
              })}
          </label>
          <IconWrapper
            data-for="banner-image"
            data-tip={`${intl.formatMessage({
              id: 'contentstudio.banerimage.title.tooltip',
              defaultMessage: 'Add a background image',
            })}`}
          >
            <i className="fas fa-info" />
          </IconWrapper>
        </div>
        <p className="info">
          {description ||
            intl.formatMessage({
              id: 'events.createevent.general.banerimage.description',
              defaultMessage: 'Upload banner image',
            })}
          {isRequired && <span className="required-star">*</span>}
        </p>
        {bannerImg ? (
          <StyledUploadDiv id="upload_widget" imgUrl={bannerImg}>
            {type === 'blog' && (
              <div className="remove-img-wrapper" onClick={() => onSave('')}>
                <i className="fas fa-plus" alt="remove img" />
              </div>
            )}
            <div className="overlay">
              <button className="change-img-btn" onClick={handleClick}>
                {intl.formatMessage({
                  id: 'events.createevent.general.banerimage.button.label.change',
                  defaultMessage: 'Change image',
                })}
              </button>
            </div>
          </StyledUploadDiv>
        ) : (
          <button onClick={handleClick} className="cover-photo-upload-btn">
            {intl.formatMessage({
              id: 'events.createevent.general.banerimage.button.label.upload',
              defaultMessage: 'Upload image',
            })}
          </button>
        )}
        <ReactTooltip
          id="banner-image"
          place="top"
          type="dark"
          effect="solid"
        />
      </CoverPhotoWrapper>
      <BreakLine />
    </>
  )
}

export default BannerImage

