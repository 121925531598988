import React from 'react'
import PropTypes from 'prop-types'
import { Loading } from '_components/loading'
import Error from '_components/error'

export const Loader = ({ loading, error, placeholder, children, testid, className }) => {
  if (loading) {
    return placeholder || <Loading className={className} testid={testid} />
  } else if (error) {
    return <Error />
  } else {
    return children()
  }
}
Loader.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  placeholder: PropTypes.node,
  children: PropTypes.func.isRequired,
}

export default Loader
