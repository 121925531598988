import React from 'react'
import { FormattedMessage } from 'react-intl'
import AssetViewer from './assets-viewer'

export const ApplicationContent = ({ application }) => (
  <>
    <h4 className="cover-letter-heading">
      <FormattedMessage id={'applicationsubmission.coverletter'} />
    </h4>
    <div
      className="cover-letter-content"
      dangerouslySetInnerHTML={{
        __html: application?.coverLetter,
      }}
    />
    <AssetViewer renderGallery={true} assets={application?.attachments} />
  </>
)
