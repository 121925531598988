import styled from '@emotion/styled'
import { pablet } from '_utils/responsive-queries'

export const ToolTipWrapper = styled.div`
  position: relative;
  margin: auto 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: var(--foreground-shade);
  cursor: pointer;
`

export const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 220px;
  height: 90px;
  max-width: 350px;
  cursor: default;
  position: absolute;
  border: 1px solid var(--foreground);
  background-color: var(--background);
  top: 18px;
  right: 8px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);

  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};

  font-size: 14px;
  padding: 10px;

  ${pablet} {
    left: 8px;
  }

  p {
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    span {
      font-size: 16px;
    }
  }
`

export const IconBtn = styled.button`
  border: none !important;
  background-color: transparent !important;
  margin: auto !important;
  height: 15px !important;
  width: 15px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  i {
    font-size: 8px;
    color: var(--background);
  }
`
