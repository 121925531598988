import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { useSearch } from '_search/search-hooks'

import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { sendNotification } from './message-helper'

import BasicButton from './styles/basic-button-styles'
import {
  ResultWrapper,
  InputStyled,
  ItemWrapper,
} from './styles/basic-input-dropdown-styles'

const CONNECT_MUTATION = gql`
  mutation ConnectActivityToPerson($personId: String!, $activityId: String!) {
    connectActivityToPerson(personId: $personId, activityId: $activityId) {
      status
    }
  }
`
const ActivityToCompany = ({ activityId }) => {
  const [showInput, setShowInput] = useState(false)
  const [query, setQuery] = useState('')
  const { data } = useSearch(300, query, { limit: 5, type: 'USER' })

  const [connect, { loading: connectLoading }] = useMutation(CONNECT_MUTATION)

  const onClose = () => {
    if (showInput) setShowInput(false)
  }

  const ref = useClickOutside(onClose)

  const onConnect = (e) => {
    connect({
      variables: { personId: e.id, activityId },
    })
    setShowInput(false)
    sendNotification({ category: 'activity', type: 'invitation' })
  }

  if (showInput) {
    return (
      <ItemWrapper className="inner-suggestions-container">
        <div className="input-container" ref={ref}>
          <InputStyled
            className="input-field"
            placeholder="Type person name..."
            type="text"
            onKeyUp={(e) => setQuery(e.target.value)}
            onBlur={() => data?.length === 0 && setShowInput(false)}
            autoFocus
          />

          {data?.length > 0 &&
            data.map((e) => {
              return (
                <ResultWrapper key={e.id}>
                  <span>
                    {e.firstName} {e.middleName || ''} {e.lastName}
                  </span>
                  <BasicButton
                    type="button"
                    disabled={connectLoading}
                    onClick={() => onConnect(e)}
                  >
                    Invite user
                  </BasicButton>
                </ResultWrapper>
              )
            })}
        </div>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper>
      <BasicButton onClick={() => setShowInput(true)}>
        <i className="fas fa-link fa-lg"></i>
        Invite user
      </BasicButton>
    </ItemWrapper>
  )
}

export default ActivityToCompany
