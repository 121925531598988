import styled from '@emotion/styled'
import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'
import { mobileUp, mobile } from '_utils/responsive-queries'

export const DefaultStyled = styled.div`
  ${border()}
  ${imageSkeleton}
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ coverPhoto, theme }) =>
    coverPhoto ? `url(${coverPhoto})` : 'var(--background)'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: var(--foreground);
  /* min-width: 360px; */
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%;
  min-height: 320px; */
  height: 363px;
  position: relative;
  .info {
    padding: 24px;
    background-color: var(--background);
    margin-top: auto;
    /* height: 223px; */
    /* margin-right: auto; */
    .title {
      font-size: 22px;
      text-align: left;
      font-weight: 600;
      overflow: hidden;
      white-space: unset;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .description {
      margin-top: 8px;
      font-size: 16px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const Tags = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  .tag {
    background-color: var(--background);
    border-radius: 25px;
    display: inline-block;
    padding: 4px 15px;
    text-transform: uppercase;
    margin-right: 6px;
  }
`

export const DefaultTileStyled = styled(Content)`
  height: 100%;
  .info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 223px;
    width: 100%;
    border-radius: 25px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .weirdfix {
      max-width: 404px;
    }

    .blog-stats {
      display: flex;
      align-items: center;
      gap: 22px;
      .likes,
      .comments {
        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 12px;
        font-weight: 700;
        i {
          color: var(--foreground);
          font-size: 16px;
        }
        button {
          border: none;
          background-color: transparent;
        }
      }
      .share {
        display: flex;
        flex-grow: 1;
        i {
          margin-left: auto;
        }
      }
    }
  }
  &.grid-span-column-2,
  &.grid-span-column-3 {
    .info {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-40%);

      width: 45%;
      min-height: 161px;
      height: auto;
      border-radius: 0;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
`

export const TopInfo = styled.div`
  width: 100%;
`
export const FooterInfo = styled.div`
  width: 100%;
  .blog-stats {
    position: relative;
  }
  .sharebutton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  .share-menu {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    width: 360px;

    .main-recommend-container {
      border-bottom: 1px solid var(--shade);
    }

    button {
      border: none;
      border-bottom: 1px solid var(--shade);
      background-color: transparent;
      padding: 18px;
      text-align: left;
      &:nth-last-of-type(1) {
        border: none;
      }
      i {
        margin-right: 12px;
      }
      &.sharebutton {
        padding: 0;
        i {
          margin: 0;
        }
      }
    }
  }

  .buttons-wrapper {
    ${border()}
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    padding: 4px 0;
    margin-bottom: 22px;
    border-radius: 10px 10px 0 10px;
    background-color: var(--background);
  }

  ${mobile} {
    .buttons-wrapper {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
      border-radius: 0;
      background-color: transparent;
      margin: 0;
      button {
        background-color: var(--background);
        &.mobile-cancel-button {
          margin-top: 8px;
          text-align: center;
        }
      }
      .like-button {
        border: none;
        background-color: transparent;
      }
    }
  }
`

export const FullWidthTileStyled = styled(Content)`
  height: 100%;

  ${mobile} {
    .info {
      width: 100%;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }

  ${mobileUp} {
    flex-direction: column;
    justify-content: center;
    max-width: 35%;
    min-height: 500px;
    .info {
      margin: 0;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
`

export const LargerTileStyled = styled(Content)`
  ${mobile} {
    .info {
      width: 100%;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }

  ${mobileUp} {
    .info {
      margin-right: auto;
      max-width: 45%;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 0;
    }
  }
`

export const VeryHighTileStyled = styled(Content)`
  ${mobile} {
    .info {
      width: 100%;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }

  ${mobileUp} {
    .info {
      max-width: 45%;
      border-radius: 25px;
      border-top-right-radius: 25px;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`
