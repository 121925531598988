import React from 'react'
import PublicDetailsCard from '_public-details-card'

import { CONNECTED_COMPANIES, CONNECTED_ACTIVITIES } from './graph-queries'

const Index = ({ entityId, currentTab }) => {
  const connections = {
    company: {
      gqlQuery: CONNECTED_COMPANIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getOpportunityConnectedCompaniesPaged',
      translationKey: 'companies.titleplural',
    },
    activity: {
      gqlQuery: CONNECTED_ACTIVITIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getOpportunityConnectedActivitiesPaged',
      translationKey: 'activities.titleplural',
    },
  }

  return (
    <PublicDetailsCard.ConnectionsPaged
      currentTab={currentTab}
      connections={connections}
    />
  )
}

export default Index
