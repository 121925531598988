import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { tablet, desktop, mobile } from '_utils/responsive-queries'
import { themeIsDark } from '_colorBus/primitives/scheme'

const cssVariables = css`
  --faintShadow: 0px 0px 29px -13px rgba(0, 0, 0, 0.2);
  --cirlceSize: 19px;
  --margin: var(--space-m);

  max-width: 100vw;

  .header.scrolled,
  .header:not(.dynamicMenu) {
    --margin: var(--space);
    font-size: 14px !important;
  }
`

const logoContainer = css`
  .logo {
    position: absolute;
    z-index: 4;
    left: var(--margin);
    top: var(--margin);
    pointer-events: auto;
    transition: 0.4s ease-in-out;

    ${tablet} {
      z-index: -1;
    }
  }
`

const logoImg = css`
  .logo img.company-logo {
    height: 3.7em;
    max-width: 9rem;
    object-fit: contain;
    transition: 0.4s ease-in-out;
    ${tablet} {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .header.scrolled .logo img.company-logo {
    ${tablet} {
      transform: translateX(-200%);
      opacity: 0;
    }
  }

  .header.scrolled .logo img.company-logo,
  .header:not(.dynamicMenu) .logo img.company-logo {
    height: 2em;
  }
`

const menu = ({ location, theme }) => css`
  nav {
    background: ${location === '/' || location === '/discover'
      ? 'transparent'
      : 'var(--background)'};

    position: relative;
    pointer-events: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;

    height: auto;
    padding: var(--space-m) 0px 0px;

    transition: 0.4s ease-in-out, min-height 0.1s;

    ${tablet} {
      overflow: hidden !important;
      padding: 0px 0px var(--space);

      flex-direction: column;
      align-items: flex-start;
      background: transparent;
      .icon,
      .search-box {
        opacity: 0;
        grid-column: 2;
        transform: translateX(2em);
      }

      .submenu {
        transition: 0.4s ease-in-out;
        &.open {
          background: var(--shade);
        }
        a {
          font-size: 16px;
        }
        .icon {
          opacity: 0 !important;
        }
      }
      .submenu .label {
        justify-self: start;
      }
    }
  }

  ${desktop} {
    .icons-nav {
      min-height: 110px;
    }
    nav.searching {
      min-height: 110px;
    }

    .header.scrolled nav,
    .header:not(.dynamicMenu) nav {
      padding: 0px 0px;
      min-height: 53px;

      box-shadow: ${themeIsDark(theme.colors) ?  "none" : "var(--shadow)"};
      background: ${themeIsDark(theme.colors) ? 'var(--shade-faint)' : 'var(--background)'};
    }

    .header.scrolled nav.searching
    .header:not(.dynamicMenu) nav.searching {
      min-height: 53px;
      max-height: 53px;
    }
  }
`

const menuItems = ({ isScrollTop, location }) => css`
  i.fas.fa-clone {
    font-size: 20px;
  }

  nav > .menu-item {
    display: flex;
    flex-direction: column;
    place-items: center;

    cursor: pointer;
    position: relative;

    max-height: 200vh;
    padding:
      var(--space-s)
      var(--space)
      var(--space-s);
    //padding: 0.5rem 1.3rem 0.5rem;
    font-weight: 500;

    will-change: padding;
    transition: 0.2s;

    i.fa {  font-size: 1.7em; }

    &.blog svg *,
    &.forum svg *,
    &.events svg * {
      fill: ${isScrollTop && (location === '/' || location === '/discover')
        ? 'var(--imgColor)'
        : 'var(--foreground)'};
    }

    &::after {
      content: '';
      background: var(--flavor);
      height: 2px;
      width: 0%;
      transition: 0.4s ease-in-out;
    }

    .search-label {
      font-weight: 700;
    }

    &.search-link button.exit {
      display: none;
      background-color: transparent;
      fill: var(--foreground);
      color: var(--foreground);
      border: none;
      i { font-size: 1em; }
    }

    ${desktop} {
      --color: ${isScrollTop && (location === '/' || location === '/discover')
        ? 'var(--imgColor)'
        : 'var(--foreground)'};
      color: var(--color);
      .label {color: var(--color);}
    
      &:hover::after {
        width: 100%;
      }
      &:active {
        opacity: 0.5;
      }
      &.active::after,
      &.open::after {
        width: 100%;
      }

      &.search-link.mobile {
        display: none;
      }

      .label.item-name,
      .search-label {
        font-size: 0;
        transition: 0.3s;
      }

      &:hover {
        span.label.item-name,
        .search-label {
          font-size: 14px;
        }

        &::after {
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    ${tablet} {
      justify-content: space-between;
      flex-direction: row;

      font-size: 18px;
      color: var(--foreground) !important;

      width: 100%;
      max-width: 100vw;
      max-height: 2.5em;
      padding: 
        var(--space-s) 10% 
        var(--space-s);
        
      &.active::after,
      &.open::after {
        height: 100%;
        opacity: 0.2;
        bottom: 0px;
      }

      .icon,
      .search-box {
        order: 2;
      }

      &.search-link:not(.mobile) {
        display: none;
      }

      .submenu-links {
        transition: 0.4s ease-in-out;
      }

      &.submenu {
        max-height: 100vh;
        flex-direction: column;
        align-items: flex-start;
        .icon {
          display: none;
        }
      }

      &.submenu:not(.open) .submenu-links {
        opacity: 0;
      }
    }
  }

  nav.searching {
    .menu-item.search-link {
      max-height: 300vh;
    }

    .menu-item:not(.search-link) {
      box-sizing: border-box;
      overflow: hidden;
      transform: translateY(-50px);
      opacity: 0;

      pointer-events: none;
      * {pointer-events: none;}

      ${desktop} {
        padding: 0px;
        max-width: 0px;
        max-height: 0px;
      }

      ${tablet} {
        transform: translateX(-50px);
      }
    }

    .menu-item.search-link button.exit {
      display: block;
    }
  }

  ${desktop} {
    .header.scrolled,
    .header:not(.dynamicMenu) {
      nav:not(.searching) > .menu-item {
        padding: 0.5rem 1rem 1rem;
        &:hover::after {
          margin-top: 0;
        }
      }
      .submenu-links,
      .header.scrolled .dropdown-links,
      .header:not(.dynamicMenu) .dropdown-links {
        border-top-left-radius: 0em;
        border-top-right-radius: 0em;
      }
    }

    .header:not(.scrolled) .submenu-links,
    .header.dynamicMenu .submenu-links {
      top: 88%;
    }
  }
`

const menuIcons = ({ isScrollTop, location }) => css`
  --iconSize: 1.7em;
  .header .icon {
    width: var(--iconSize);
    height: var(--iconSize);
    fill: ${isScrollTop && (location === '/' || location === '/discover')
      ? 'var(--imgColor)'
      : 'var(--foreground)'};
    margin-bottom: 0.5em;
    transform: translateY(0em);
    transition: 0.4s ease-in-out;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${tablet} {
    .header .icon {
      opacity: 0;
      transform: translateX(2em) !important;
      fill: var(--foreground);
      transition: 0.2s ease-in-out;
    }

    .header.openMobile {
      --iconSize: 0.7em;
      width: 30em;
      max-width: 100vw;
      overflow: inherit;
      overflow-y: auto;
      .icon svg {
        top: 0px;
        position: absolute;
      }

      .icon,
      .icon svg {
        margin: 0px;
        transform: translate(0em);
      }
    }
  }

  ${desktop} {
    .header.scrolled,
    .header:not(.dynamicMenu) {
      --iconSize: 0.7rem;
      .icon {
        opacity: 0;
        transform: translateY(-2em);
        margin: 0px;
      }
      .menu-item {
        .item-name.label,
        .search-label {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }
  }
`

const menuItemSearchSearchbox = css`
  .search-link .search-box {
    pointer-events: none;
    input {
      opacity: 0;
      width: 0px;
      padding: 0px;
      color: var(--foreground);
      &::placeholder {
        color: var(--foreground) !important;
        opacity: 0.5;
      }
    }
  }

  nav.searching .search-link .search-box {
    pointer-events: auto;
    & > div:nth-of-type(1) {
      padding: 0.5em 1em;
      width: 22em;
      height: auto;
      background-color: var(--background);
      transition: 0.4s ease-in-out;
    }

    input {
      opacity: 1;
      width: 100%;
      padding: 0px 0.5rem;
    }
  }

  ${desktop} {
    nav:not(.searching) .search-box {
      & > div {
        --addedDim: calc(var(--iconSize) + 9px);
        height: var(--addedDim);
        width: var(--addedDim);
        border: solid 0px;
        background-color: rgba(0, 0, 0, 0);
      }
      .dropdownContainer {
        opacity: 0;
      }
    }

    .header.scrolled nav:not(.searching) .search-box > div,
    .header:not(.dynamicMenu) nav:not(.searching) .search-box > div {
      opacity: 0;
      transform: translateY(-2em);
      height: 10.5px;
    }
  }

  ${tablet} {
    .search-box {
      opacity: 0;
      transform: translateX(2em);
      transition: 0.2s ease-in-out;
      & > * {
        background: rgba(0, 0, 0, 0);
        border: solid 0px;
      }
      input {
        font-size: 0.7em;
      }
    }

    nav.searching .search-link .search-box {
      opacity: 1;
      width: 100%;
      transform: translateX(0em);
      & > * {
        background-color: var(--background);
        width: 100% !important;
        border: solid 1px;
        &:nth-of-type(2) {
          border: solid 0px;
          position: relative;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
`

const menuItemSearch = ({ isScrollTop, location }) => css`
  .search-link {
    transition: 0.4s ease-in-out;
    svg {
      width: var(--iconSize);
      stroke: ${isScrollTop && (location === '/' || location === '/discover')
        ? 'var(--imgColor)'
        : 'var(--foreground)'};
      transition: 0.4s ease-in-out;
    }
    span {
      max-height: 5rem;
      overflow: hidden;
      transform: translateY(0rem);
      transition: 0.2s;
    }

    ${tablet} {
      padding-top: var(--space-m) !important;
      padding-bottom: var(--space-m) !important;
      border-bottom: var(--border);
      margin-bottom: var(--space-m);
    }
  }

  .header.scrolled nav.searching .search-link
  .header:not(.dynamicMenu) nav.searching .search-link {
    padding: 0.5rem 1rem 0.5rem;
    svg {
      stroke: var(--foreground);
    }
  }

  .header nav.searching .search-link {
    --iconSize: 1.4em;
    svg { stroke: var(--foreground); }
    span { max-height: 0rem; display: none; }

    &:hover::after {
      transition: opacity 0.1s;
      width: 0px;
    }
  }

  ${tablet} {
    nav.searching .search-link {
      flex-direction: column;
      display: flex;
      svg { stroke: var(--foreground); }
    }
  }
`

const mobileBurger = ({theme}) => css`
  .mobile-menu-burger {
    --size: 2em;
    --height: calc(var(--size) * 0.8);
    --rounded: 1rem;
    --middle: calc(50% - (var(--height) / 2));

    cursor: pointer;
    pointer-events: auto;

    display: none;
    justify-content: center;
    align-items: center;

    position: absolute;
    inset:
      var(--space-s)
      var(--space-s) 
      auto auto;
    z-index: 99999999;
    box-sizing: content-box;

    width: var(--size);
    height: var(--height);
    padding: 1.2em 1em;
    background: ${themeIsDark(theme.colors) ? 'var(--shade-faint)' : 'var(--background)'};
    border-radius: 100%;

    transition: transform .4s ease-in-out .4s, all .2s ease-in-out;

    ${tablet} {
      display: flex;
      border: var(--border);
      //box-shadow: var(--shadow);
    }

    &:hover {
      transform: scale(1.1);
      .lines {
        opacity: 0.5;
      }
    }
    &:active {
      transform: scale(0.9);
      .lines {
        opacity: 1;
        &::before {
          transform: translateY(calc(0px - var(--middle)));
        }
        &::after {
          transform: translateY(var(--middle));
        }
      }
    }
  }

  .openMobile .mobile-menu-burger {
    transform: rotate(45deg);
    &:hover {
      transform: scale(1.1) rotate(45deg);
    }
    &:active {
      transform: scale(0.9) rotate(45deg);
    }
    .line {
      transform: rotate(90deg);
    }
    .lines::before {
      transform: translateY(calc(0px - var(--middle)));
    }
    .lines::after {
      transform: translateY(var(--middle));
    }
  }

  .lines {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
  }

  .line {
    width: 100%;
    height: calc(var(--height) / 5);
    border-radius: var(--rounded);
    background: var(--foreground);
    transition: .4s ease-in-out;
  }

  .lines::before {
    content: "";
    width: 100%;
    height: calc(var(--height) / 5);
    border-radius: var(--rounded);
    background: var(--foreground);
    position: absolute;
    top: 0px;
    transition: .4s ease-in-out;
  }

  .lines::after {
    content: "";
    width: 100%;
    height: calc(var(--height) / 5);
    border-radius: var(--rounded);
    background: var(--foreground);
    position: absolute;
    bottom: 0px;
    transition: .4s ease-in-out;
  }
`

const mobileMenuVail = css`
  .mobile-menu-vail {
    pointer-events: none;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background: rgba(9, 9, 9, 0);
    opacity: 0;
    transition: 0.4s ease-in-out;
    ${tablet} {
      background: rgba(9, 9, 9, 0.5);
    }
  }
  .openMobile .mobile-menu-vail {
    pointer-events: auto;
    opacity: 1;
  }
`

const header = ({ location }) => css`
  position: fixed;
  z-index: 12;
  right: 0px;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0) !important;

  color: var(--foreground) !important;
  transition: width 0.4s ease-in-out;
  ${tablet} {
    background-image: none;
    pointer-events: none;
    * {
      pointer-events: auto;
    }
  }

  .header {
    width: 100vw;
    max-width: 100vw;
    overflow: visible;
    font-size: 14px !important;

    transition: 0.4s ease-in-out;
    ${tablet} {
      float: right;
      width: 0em;
      height: 100vh;
      background: var(--background);
      overflow-y: scroll;
    }
    ${mobile} {
      &.openMobile {
        width: 100vw;
      }
    }
  }

  .header.pop-animate {
    animation: smoothSlide 1s 1.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;

    @keyframes smoothSlide {
      from {
        transform: translateY(-20vh);
      }
      to {
        transform: translateY(0vh);
      }
    }
  }

  ${(location === '/' || location === '/discover') && headerShadow()}
`

const headerShadow = () => css`
  .header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(84, 84, 84, 0)
    );
    ${tablet} {
      background-image: none;
    }
  }
`

export const HeaderContainer = styled.div`
  ${cssVariables}
  ${header}
  ${logoContainer}
  ${logoImg}

  ${menu}
  ${menuItems}
  ${menuIcons}
  ${menuItemSearch}
  ${menuItemSearchSearchbox}

  ${mobileBurger}
  ${mobileMenuVail}
`
