import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQuery, gql, useMutation } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import { useUser } from '_security/session-hooks'
import { Loader } from '_components/loading'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import { mergePostsPage } from '_utils/graph-pagination'
import { Form, FormGroup } from '@patternfly/react-core'
import Input from 'components/indy/shared/ui-kit/inputs/text'
import { ButtonsWrapper } from './styles/post'
import { useMediaQuery } from 'react-responsive'
import { tabletPX } from '_utils/responsive-queries'
import { useAppConfig } from '_application'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import ReactTooltip from 'react-tooltip'
import {
  SectionHeader,
  AddTopicButton,
  CustomizedModal,
  CancelButton,
  AddButton,
  ActionsWrapper,
  TagsInputWrapper,
  SearchResults,
  SingleResultWrapper,
  SingleTag,
  CustomizedInput,
  TagsMainWrapper,
  Description,
  CountersWrapper,
  AllTagsWrapper,
} from './styles/category'

import {
  PageWrapper,
  CategoryAvatar,
  // CountersWrapper,
  PageSection,
  Counter,
  LatestWrapper,
  Indicator,
  TopicAvatar,
  MainSectionContainer,
  CategoryWrapper,
  LoadMoreContainer,
  LoadMoreButton,
  TopWrapper,
} from '../shared/styles'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'
import FullscreenLoaderWrapper from '_components/loading/fullscreen-loader-wrapper'
import { notification } from 'antd'

const GET_CATEGORY = gql`
  query GET_CATEGORY($id: ID!, $page: Float) {
    getCategory(id: $id, page: $page) {
      currentpage
      pagecount
      category {
        name
        description
      }
      topicsList {
        title
        titleraw
        tid
        slug
        postcount
        viewcount
        votes
        deleted
        lastposttimeiso
        timestampiso
        user {
          picture
          username
        }
        teaser {
          tid
          pid
          content
          timestampiso
          user {
            username
            picture
          }
        }
      }
    }
  }
`
const CREATE_TOPIC = gql`
  mutation createTopic(
    $cid: ID!
    $title: String!
    $content: String!
    $tagsList: String
  ) {
    createTopic(
      cid: $cid
      title: $title
      content: $content
      tagsList: $tagsList
    ) {
      title
      tid
      slug
      postcount
      viewcount
      votes
    }
  }
`

const GET_TAGS = gql`
  query GET_TAGS($all: Boolean) {
    getTags(all: $all) {
      tags {
        tagsList {
          tag
          value
          score
        }
      }
    }
  }
`

const Category = (props) => {
  const theme = useTheme()
  const intl = useIntl()
  const user = useUser()
  const userRole = user?.profile?.role
  const { id } = useParams()
  const { data, refetch, fetchMore } = useQuery(GET_CATEGORY, {
    variables: { id: id, page: 1 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const updateQuery = mergePostsPage('getCategory')
  const { data: tagsList } = useQuery(GET_TAGS, { variables: { all: true } })
  const [tags, setTags] = useState([])
  const [tagsArray, setTagsArray] = useState([])
  const [query, setQuery] = useState('')
  const [isTagsOpen, setIsTagsOpen] = useState(false)
  const appConfig = useAppConfig()

  const onChange = (value, event) => {
    setQuery(value)
  }
  useEffect(() => {
    setTags(
      tagsList?.getTags.tags.tagsList.filter(
        (t) =>
          query?.length > 0 &&
          `${t?.value}`?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [tagsList, setTags, query])

  const addTagToList = (tag) => {
    const alreadySelectedTag = tagsArray.find((item) => item === tag)

    if (alreadySelectedTag) return
    setTagsArray([...tagsArray, tag])
    setQuery('')
  }

  const handleTagsClose = (e) => {
    e.preventDefault()
    setQuery('')
    setIsTagsOpen(!isTagsOpen)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (query.length > 2) {
        addTagToList(query)
        setQuery('')
      }
      console.log('tag should contain at least 3 letters')
    }
  }
  const removeTagFromList = (e, tag) => {
    e.preventDefault()
    const index = tagsArray.indexOf(tag)
    if (index > -1) {
      tagsArray.splice(index, 1)
    }
    setTagsArray([...tagsArray])
  }
  const state = props.location.state || ''
  if (state === 'refetch') {
    refetch()
  }
  const [createTopic] = useMutation(CREATE_TOPIC, {
    refetchQueries: [{ query: GET_CATEGORY, variables: { id: id, page: 1 } }],
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [topicTitleModal, setTopicTitleModal] = useState('')
  const [topicContentModal, setTopicContentModal] = useState('')

  const handleTopicChange = (html) => {
    setTopicTitleModal(html)
  }
  const handleContentBlur = (html) => {
    setTopicContentModal(html)
  }

  const nameLimit = 30

  const handleSave = async (e) => {
    e.preventDefault()
    if (topicTitleModal) {
      props.setLoadingObject({ isLoading: true, hasBackground: true })
      await createTopic({
        variables: {
          cid: id,
          title: topicTitleModal,
          content: topicContentModal,
          tagsList: tagsArray.toString(),
        },
      })
        .then(() => {
          props.setLoadingObject({ isLoading: false, hasBackground: false })
          setTopicTitleModal('')
          setIsModalOpen(false)
        })
        .catch((e) => {
          props.setLoadingObject({ isLoading: false, hasBackground: false })
          const errorMessage = e?.message.includes('error:title-too-short')
            ? 'toast.topic.topicadd.error.description.title'
            : e?.message.includes('error:content-too-short')
            ? 'toast.topic.topicadd.error.description.message'
            : e?.message.includes('error:too-many-tags')
            ? 'toast.topic.topicadd.error.description.tags'
            : ''
          if (errorMessage) {
            notification.error({
              message: intl.formatMessage({
                id: 'toast.topic.topicadd.error.message',
              }),
              description: intl.formatMessage({
                id: errorMessage,
              }),
            })
          }
        })
    }
  }
  const handleCloseModal = () => setIsModalOpen(false)

  const isDesktopView = useMediaQuery({ query: `(min-width: ${tabletPX}px)` })

  const topicModalSendBtnStyle =
    topicTitleModal.trim() === '' ? { pointerEvents: 'none', opacity: 0.5 } : {}

  const setCharacterLimit = (string, limit) => {
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0
      ? ''
      : setCharacterLimit(e.childNodes[0].nodeValue)
  }

  const moderatedCategory = () => {
    return appConfig?.forum?.some(function (el) {
      return el.id === Number(id)
    })
  }

  const ref = useClickOutside(() => isTagsOpen && setIsTagsOpen(false))

  const tagsConfig = {
    // userId,
    categoryId: id,
    context: 'forum-create-topic',
  }
  if (!data) return <Loader loading={!data} />
  return (
    <PageWrapper>
      <SectionHeader>
        <div className="category-header-wrapper">
          <h3 className="category-title">
            {unescape(data?.getCategory?.category?.name)}
          </h3>
          <p className="category-description">
            {unescape(data?.getCategory?.category?.description)}
          </p>
        </div>
        {userRole && (!moderatedCategory() || userRole === 'admin') && (
          <ActionsWrapper>
            <AddTopicButton
              onClick={() => setIsModalOpen(!isModalOpen)}
              data-testid="btn-addTopic"
            >
              {intl.formatMessage({ id: 'forum.button.addtopic' })}
            </AddTopicButton>
          </ActionsWrapper>
        )}
      </SectionHeader>
      {props.loadingObject.isLoading && (
        <FullscreenLoaderWrapper className="loaderwrapper">
          <Loader loading={props.loadingObject.isLoading} />
        </FullscreenLoaderWrapper>
      )}
      {data?.getCategory?.topicsList
        .slice()
        .sort((a, b) => b.lastposttimeiso.localeCompare(a.lastposttimeiso))
        .map(function (object, i) {
          return (
            <>
              {object.deleted !== 1 ||
              (object.deleted !== 1 && userRole === 'admin') ? (
                <MainSectionContainer hasGutter key={i}>
                  <CategoryWrapper isTopElement={true} span={6}>
                    <TopWrapper>
                      <CategoryAvatar isTopElement={true}>
                        <img
                          src={
                            object.user.picture || theme?.images?.defaultAvatar
                          }
                          alt=""
                        />
                      </CategoryAvatar>
                      <Description>
                        <Link to={`/forum/topic/${object.tid}`}>
                          <p
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html: unescape(object.title),
                            }}
                            data-testid={`category-topic-${object?.title}`}
                          ></p>
                        </Link>
                        <span className="author">
                          <span>
                            {' '}
                            {replaceUsernameNumbers(object.user?.username)}
                          </span>
                          {/* {unescape(data?.getCategory.category.name)} */}
                          {/* <span className="bullet">&#8226;</span> Author name */}
                          <span className="bullet-wrapper">
                            <i className="fas fa-circle bullet" />
                          </span>
                          <span>
                            {' '}
                            {moment(object.timestampiso).format(
                              'DD.MM.YYYY HH:mm '
                            )}
                          </span>
                        </span>
                      </Description>
                    </TopWrapper>
                  </CategoryWrapper>
                  <CountersWrapper span={2}>
                    <PageSection>
                      <Counter>{object.votes}</Counter>
                      <span>
                        {intl.formatMessage({ id: 'forum.stats.votes' })}
                      </span>
                    </PageSection>
                    <PageSection>
                      <Counter>{object.postcount}</Counter>
                      <span>
                        {intl.formatMessage({ id: 'forum.stats.posts' })}
                      </span>
                    </PageSection>
                    <PageSection>
                      <Counter>{object.viewcount}</Counter>
                      <span>
                        {intl.formatMessage({ id: 'forum.stats.views' })}
                      </span>
                    </PageSection>
                  </CountersWrapper>
                  {isDesktopView && (
                    <LatestWrapper span={4}>
                      {object.teaser ? (
                        <Indicator
                          href={`/forum/topic/${object.tid}#post-${object.teaser.pid}`}
                          isTopicView
                        >
                          <div className="author-wrapper">
                            <TopicAvatar>
                              <img
                                src={
                                  object.teaser.user.picture ||
                                  theme?.images?.defaultAvatar
                                }
                                alt=""
                              />
                            </TopicAvatar>
                            <div className="author-info">
                              <div className="author-name">
                                <p>
                                  {setCharacterLimit(
                                    replaceUsernameNumbers(
                                      object?.teaser?.user?.username
                                    ),
                                    nameLimit
                                  )}
                                </p>
                                <div className="date-wrapper">
                                  <span className="bullet-wrapper">
                                    <i className="fas fa-circle bullet" />
                                  </span>
                                  <span>
                                    {moment(object.teaser.timestampiso).fromNow(
                                      true
                                    )}
                                  </span>
                                </div>
                              </div>

                              <p
                                className="topic-name"
                                dangerouslySetInnerHTML={{
                                  __html: unescape(object.teaser.content),
                                }}
                              ></p>
                            </div>
                          </div>
                        </Indicator>
                      ) : (
                        <span className="no-posts">
                          {intl.formatMessage({ id: 'forum.info.nonewposts' })}
                        </span>
                      )}
                    </LatestWrapper>
                  )}
                </MainSectionContainer>
              ) : (
                <></>
              )}
            </>
          )
        })}
      {data?.getCategory?.pagecount > data?.getCategory?.currentpage && (
        <LoadMoreContainer>
          <Row>
            <Col className="separator-col my-auto">
              <hr />
            </Col>
            <Col className="button-col">
              <LoadMoreButton
                onClick={() =>
                  fetchMore({
                    variables: {
                      id: id,
                      page: data?.getCategory?.currentpage + 1,
                    },
                    updateQuery,
                  })
                }
              >
                {intl.formatMessage({ id: 'global.loadmore.button' })}
                <i class="fas fa-chevron-down"></i>
              </LoadMoreButton>
            </Col>
            <Col className="separator-col my-auto">
              <hr />
            </Col>
          </Row>
        </LoadMoreContainer>
      )}
      {isModalOpen && (
        <CustomizedModal
          position="top"
          title={intl.formatMessage({ id: 'forum.newtopic.modal.title' })}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          actions={[
            <CancelButton variant="link" onClick={() => handleCloseModal()}>
              {intl.formatMessage({
                id: 'forum.newtopic.modal.button.cancel',
              })}
            </CancelButton>,
            <AddButton
              form="category-form"
              style={topicModalSendBtnStyle}
              variant="primary"
              type="submit"
              data-tip="Make sure you include the text in your comment. Comments without text will not be posted"
            >
              {intl.formatMessage({
                id: 'forum.newtopic.modal.button.add',
              })}
            </AddButton>,
          ]}
        >
          <div>
            <Form
              id="category-form"
              className="comment-form"
              onSubmit={handleSave}
            >
              <FormGroup fieldId="comment-field">
                <Input
                  input={{
                    name: 'Title',
                    placeholder: intl.formatMessage({
                      id: 'forum.newtopic.modal.topictitle.placeholder',
                    }),
                    value: topicTitleModal,
                  }}
                  onChangeCallback={(html) => handleTopicChange(html)}
                />
                {renderField({
                  attribute: {
                    type: 'wysiwyg',
                    name: 'forum-category',
                    heightMin: 150,
                    editorType: 'forum-category',
                    editorValue: topicContentModal,
                    imgUploadFolder: 'forum',
                    imgTagsConfig: {
                      ...tagsConfig,
                    },
                  },
                  onSave: (html) => handleContentBlur(html),
                })}
                <AllTagsWrapper>
                  <button className="add-tags-button" onClick={handleTagsClose}>
                    <i className="fas fa-tag" />
                    {intl.formatMessage({
                      id: 'forum.newtopic.button.label.addtags',
                    })}
                  </button>
                  {tagsArray.map(function (object) {
                    return (
                      <SingleTag>
                        <i className="fas fa-tag" />
                        {object}{' '}
                        <button
                          className="remove-tag"
                          onClick={(e) => removeTagFromList(e, object)}
                        >
                          <i className="fas fa-times" />
                        </button>{' '}
                      </SingleTag>
                    )
                  })}
                </AllTagsWrapper>
                {isTagsOpen && (
                  <TagsMainWrapper ref={ref} className="tags-addtags-wrapper">
                    <TagsInputWrapper>
                      {/* <span className="tags-description">
                        {intl.formatMessage({
                          id: 'forum.newtopic.tags.label.description',
                        })}
                      </span> */}
                      <CustomizedInput
                        value={query}
                        onChange={onChange}
                        type="text"
                        onClear={(evt) => onChange('', evt)}
                        onKeyDown={handleKeyDown}
                      />
                      {tags && tags.length > 0 && (
                        <SearchResults>
                          {tags.map((tag) => {
                            return (
                              <SingleResultWrapper>
                                <span onClick={() => addTagToList(tag.value)}>
                                  {tag.value}
                                </span>
                              </SingleResultWrapper>
                            )
                          })}
                        </SearchResults>
                      )}
                    </TagsInputWrapper>
                  </TagsMainWrapper>
                )}
              </FormGroup>
              <ButtonsWrapper>
                {/* <CancelButton variant="link" onClick={() => handleCloseModal()}>
                  {intl.formatMessage({
                    id: 'forum.newtopic.modal.button.cancel',
                  })}
                </CancelButton>
                <AddButton
                  style={topicModalSendBtnStyle}
                  variant="primary"
                  type="submit"
                  data-tip="Make sure you include the text in your comment. Comments without text will not be posted"
                >
                  {intl.formatMessage({
                    id: 'forum.newtopic.modal.button.add',
                  })}
                </AddButton> */}
                <ReactTooltip place="top" type="dark" effect="solid" />
              </ButtonsWrapper>
            </Form>
          </div>
        </CustomizedModal>
      )}
    </PageWrapper>
  )
}

export default Category
