import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MainLayout from '_layouts/main'
import SearchResults from './connected/search-results-connected'

export const Search = () => {
  return (
    <MainLayout>
      <Switch>
        <Route
          path="/search/:type?"
          component={() =>
            <SearchResults />
          }
        />
        <Route
          path="/search/mycreations/:userId/:type?"
          component={() =>
            <SearchResults isMyCreations={true} />
          }
        />
        <Route
          path="/search/"
          component={() =>
            <SearchResults />
          }
        />
      </Switch>
    </MainLayout>
  )
}

export default Search
