import React from 'react'
import { find } from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import LoginRequiredLink from '_security/login-required-link'
import Linkify from 'linkifyjs/react'
import { useMessageActions } from '_messages'
import moment from 'moment'
import { css } from '@emotion/core'
// import { useColorBucket } from '_colorBus/hooks'

const IncomingMessageContainer = styled.div`
  margin-top: 10px;
  &.top {
    margin-top: 0px;
  }
`

const OutgoingMessageContainer = styled.div`
  margin-top: 10px;
  &.top {
    margin-top: 0px;
  }
`

const InnerIncomingMessageContainer = styled.div``

const InnerOutgoingMessageContainer = styled.div``

const IncomingMessageRow = styled(Row)`
  margin: 0 auto;
`
const OutgoingMessageRow = styled(Row)`
  margin: 0 auto;
`

const IncomingThumbnailCol = styled(Col)`
  flex-grow: 0;
  flex-basis: 60px;
  padding: 0;
`

const OutgoingThumbnailCol = styled(Col)`
  flex-grow: 0;
  flex-basis: 60px;
  padding: 0;
`

const IncomingMessageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-bottom: -20px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
`

const OutgoingMessageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-bottom: -20px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
`

const DeleteMessageButton = styled.button`
  display: none;
  padding: 5px 0 0;
  margin-left: 10px;
  background-color: transparent;
  border: none;
  color: var(--foreground);
  i {
    font-size: 15px;
    color: inherit;
  }
`

const OutgoingTimestamp = styled.div`
  display: flex;
  opacity: 0;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  padding-right: 15px;
`

const IncomingTimestamp = styled.div`
  display: flex;
  opacity: 0;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  padding-left: 15px;
`

const IncomingContentCol = styled(Col)`
  width: auto;
  display: flex;
  max-width: 90%;
  padding: 0;
  &:hover {
    ${IncomingTimestamp} {
      opacity: 1;
    }
  }
`

const OutgoingContentCol = styled(Col)`
  width: auto;
  display: flex;
  justify-content: flex-end;
  max-width: 90%;
  &:hover {
    ${OutgoingTimestamp} {
      opacity: 1;
    }
    ${DeleteMessageButton} {
      display: flex;
    }
  }
`

const IncomingContentContainer = styled.div`
  background-color: var(--flavor-shade);
  border-radius: 4px;
  padding: 5px 10px;
  max-width: 80%;
  word-wrap: break-word;
  border-radius: 10px;
  border-bottom-left-radius: 0;
`

const OutgoingContentContainer = styled.div`
  display: flex;
  background-color: var(--shade);
  border-radius: 4px;
  padding: 5px 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  max-width: 80%;
  word-wrap: break-word;
`

const IncomingMessageContent = styled.p`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--flavor-contrast);
  font-weight: 500;
  font-size: 14px;
  opacity: 0.7;
  margin: 0;

  .linkified {
    word-break: break-word;
    a {
      text-decoration: underline;
    }
  }
`

const OutgoingMessageContent = styled.p`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 500;
  font-size: 14px;
  opacity: 0.7;
  margin: 0;

  .linkified {
    word-break: break-word;
    a {
      text-decoration: underline;
    }
  }
`

const Index = ({ message, user, nextId, currentId, prevId, users }) => {
  const { avatar } = user.profile
  const { creator, content, created } = message
  const messageActions = useMessageActions()
  const intl = useIntl()
  // const [colorBucket] = useColorBucket('messages')
  if (!message /*|| !conversationUser */) {
    console.log('wrong message', message)
    return <></>
  }

  const conversationUser = find(users, { sub: creator })

  let MessageElement
  if (creator !== user.sub) {
    const url = `/people/list/${creator}`
    const Link = url ? LoginRequiredLink : ({ children }) => <>{children}</>

    MessageElement = (
      <IncomingMessageContainer className={'single-message'}>
        <InnerIncomingMessageContainer>
          <IncomingMessageRow>
            <IncomingThumbnailCol className="align-self-end">
              {currentId !== nextId && (
                <Link to={url}>
                  <IncomingMessageThumbnail
                    alt=""
                    src={conversationUser?.avatar}
                  />
                </Link>
              )}
            </IncomingThumbnailCol>
            <IncomingContentCol>
              <IncomingContentContainer>
                <IncomingMessageContent>
                  <Linkify className="linkified">{content}</Linkify>
                </IncomingMessageContent>
              </IncomingContentContainer>
              <IncomingTimestamp>
                {moment(created).format('LT')}
              </IncomingTimestamp>
            </IncomingContentCol>
          </IncomingMessageRow>
        </InnerIncomingMessageContainer>
      </IncomingMessageContainer>
    )
  } else {
    const url = `/people/list/${user.sub}`
    const Link = url ? LoginRequiredLink : ({ children }) => <>{children}</>

    MessageElement = (
      <OutgoingMessageContainer className={'single-message'}>
        <InnerOutgoingMessageContainer>
          <OutgoingMessageRow>
            <OutgoingContentCol>
              <OutgoingTimestamp>
                {moment(created).format('LT')}
              </OutgoingTimestamp>
              <OutgoingContentContainer>
                <OutgoingMessageContent>
                  <Linkify className="linkified">{content}</Linkify>
                </OutgoingMessageContent>
                {!message.channelId && (
                  <DeleteMessageButton
                    data-for="info-tooltip"
                    data-tip={intl.formatMessage({
                      id: 'messages.message.deletemessagebutton.tooltip',
                    })}
                    onClick={() =>
                      messageActions.deleteMessage(
                        message._id,
                        conversationUser.sub
                      )
                    }
                  >
                    <i class="far fa-times-circle" />
                  </DeleteMessageButton>
                )}
              </OutgoingContentContainer>
              <ReactTooltip id="info-tooltip" effect="solid" />
            </OutgoingContentCol>
            <OutgoingThumbnailCol className="align-self-end">
              {currentId !== nextId && (
                <Link to={url}>
                  <OutgoingMessageThumbnail alt="" src={avatar} />
                </Link>
              )}
            </OutgoingThumbnailCol>
          </OutgoingMessageRow>
        </InnerOutgoingMessageContainer>
      </OutgoingMessageContainer>
    )
  }
  const MessageSenderName = () => {
    if (prevId === currentId || !message.channelId) return null

    return (
      <span
        css={css`
          display: block;
          padding: 0 71px 0 60px;
          text-align: ${creator === user.sub ? 'right' : 'left'};
        `}
      >
        {conversationUser?.firstName} {conversationUser?.lastName}
      </span>
    )
  }
  return (
    <>
      <MessageSenderName />
      {MessageElement}
    </>
  )
}

export default Index
