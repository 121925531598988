import React from 'react'

import { SectionStyled, SeparatorStyled } from '../styles/section'

const mapMarker = (type) => (
  <svg
    width="14.817"
    height="17.654"
    viewBox="0 0 14.817 17.654"
    data-type={type}
  >
    <path
      fill="none"
      stroke="#000000"
      stroke-miterlimit="10"
      stroke-width="1.5px"
      d="M856.179 902.558a6.658 6.658 0 0 0-6.659 6.658c0 4.994 6.659 9.35 6.659 9.35s6.658-4.356 6.658-9.35a6.658 6.658 0 0 0-6.658-6.658zm0 9.036a2.378 2.378 0 1 1 2.378-2.378 2.379 2.379 0 0 1-2.378 2.378z"
      transform="translate(0.75 0.75) translate(-849.52 -902.558)"
    ></path>
  </svg>
)

const typeToIcon = (type) => {
  switch (type) {
    case 'boolean':
      return null
    case 'email':
      return <i class="far fa-paper-plane" data-type={type}></i>
    case 'phone':
      return <i class="fas fa-phone-alt" data-type={type}></i>
    case 'location':
      return mapMarker(type)
    case 'date':
      return <i class="far fa-calendar-alt" data-type={type}></i>
    case 'file':
      return <i class="fas fa-download" data-type={type}></i>
    case 'url':
      return <i class="fas fa-globe" data-type={type}></i>
    case 'image':
      return <i class="far fa-image" data-type={type}></i>
    case 'badge':
      return <i class="fas fa-medal" data-type={type}></i>
    case 'social-media-twitter':
      return <i class="fab fa-twitter" data-type={type}></i>
    case 'social-media-linkedIn':
      return <i class="fab fa-linkedin" data-type={type}></i>
    case 'social-media-instagram':
      return <i class="fab fa-instagram" data-type={type}></i>
    default:
      return <i class="fas fa-i-cursor" data-type={type}></i>
  }
}

export const Separator = () => {
  return <SeparatorStyled />
}

const Section = ({ type, children, side }) => {
  return (
    <SectionStyled type={type} side={side}>
      {type !== 'badge' && type !== 'boolean' && (
        <div className="icon">{typeToIcon(type)}</div>
      )}
      <div className="content">{children}</div>
    </SectionStyled>
  )
}

export default Section
