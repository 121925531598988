import styled from '@emotion/styled'

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 36px;
  font-weight: bold;
`

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 32px;
  font-weight: bold;
`

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 28px;
  font-weight: bold;
`

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 32px;
  font-weight: bold;
`

export const H5 = styled.h5`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 20px;
  font-weight: bold;
`
