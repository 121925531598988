import { useAppConfig } from '_application'

export const usePageTitle = (title) =>
  MetaData({ metaData: { pageTitle: title } })

export const MetaData = ({ metaData }) => {
  const config = useAppConfig()
  const siteTitle = config?.metaData?.siteTitle || 'Default'
  const pageTitle = metaData?.pageTitle || 'Index'
  const title = `${pageTitle} | ${siteTitle}`
  window.document.title = title
}
