import React, { useState } from 'react'
import { useHistory } from "react-router-dom";

import styled from '@emotion/styled'
import { useColorBucketForced } from "_colorBus/hooks"
import IndyButton from '_components/indyButton'
import { useIntl } from 'react-intl';
import InfoModal from './info-modal';

const FormLayout = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background);
  border-radius: 10px;

  box-shadow: var(--shadow);
  padding: 100px 100px 200px;
  margin-top: 10em;
  margin-bottom: 56px;
  transition: .0s;

  .cross {
    position: absolute;
    --pos: 20px;
    top: var(--pos); right: var(--pos);
    transform: rotate(45deg);
    width: min-content;
    cursor: pointer;
  }

  .submit-btn-wrapper{
    width: 80vw;
    max-width: 800px;
    display: flex;
    justify-content: flex-end;
  }

  h1{
    margin-bottom: 25px;
  }
  .required-star {
    color: red;
  }

  p {
    max-width: 66ch;
  }
`

const Layout = ({ children, onSubmit, isSubmitDisabled }) => {
  let history = useHistory();
  const intl = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

const renderText = ({ key, defaultMessage }) =>
  intl.formatMessage({
    id: `contentstudio.form.infomodal.${key}`,
    defaultMessage: `${defaultMessage}`,
  })

  const goToPreviousPath = () => {
    history.goBack()
  }

  const handleSubmit = (e) => {
    onSubmit()
    goToPreviousPath()
  }

  const [getElement] = useColorBucketForced("admin")

  return (
    <FormLayout
      ref={getElement}
      className="page-wrapper"
      onSubmit={(e) => e.preventDefault()}
    >
      {isModalOpen && (
        <InfoModal
          title={renderText({
            key: 'title',
            defaultMessage: 'Save page before you leave',
          })}
          description={renderText({
            key: 'description',
            defaultMessage:
              'The information added to this page has not been saved. Are you sure you want to leave before saving changes?',
          })}
          proceedButton={renderText({
            key: 'button.leave',
            defaultMessage: 'Leave',
          })}
          cancelButton={renderText({
            key: 'button.close',
            defaultMessage: 'Cancel',
          })}
          onClose={() => setIsModalOpen(false)}
          onProceed={() => goToPreviousPath()}
          isOpen={isModalOpen}
        />
      )}
      <div className="cross" onClick={() => setIsModalOpen(true)}>
        <i className={`fas fa-plus`} alt="add new blog" />
      </div>
      {children}
      <div className="submit-btn-wrapper">
        <IndyButton onClick={() => handleSubmit()} isDisabled={isSubmitDisabled} disabled={isSubmitDisabled}>
          {intl.formatMessage({
            id: 'contentstudio.form.button.submit',
            defaultMessage: 'Submit',
          })}
        </IndyButton>
      </div>
    </FormLayout>
  )
}

export default Layout

