import React from 'react'
import { useAppConfig, GET_CONFIG } from '_application'
import { UPDATE_LOGO } from './graph-queries'
import { useMutation } from '@apollo/client'

import ImageUpload from 'components/indy/shared/image-upload/index'
import { SubSection } from '../../shared/styles/sections'

import { useIntl } from 'react-intl'

const ratios = ['1:1', '4:3', '16:9']

const LogoShared = ({ type, description }) => {
  const config = { ...useAppConfig() }
  const intl = useIntl()

  const [updateLogo] = useMutation(UPDATE_LOGO)
  if (!config?.logos || !config?.logos[type]) {
    config.logos = { [type]: {} }
  }
  const logo = config?.logos[type]

  const handleImageUpload = (imgUrl) => {
    handleUpdateLogo({ ...logo, value: imgUrl })
  }

  const handleRatio = (ratio) => {
    handleUpdateLogo({ ...logo, ratio })
  }

  const handleUpdateLogo = (logo) => {
    updateLogo({
      variables: {
        type,
        logo: { key: logo.key, value: logo.value, ratio: logo.ratio },
      },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const renderMessage = (value) => {
    return intl.formatMessage({
      id: `admin.settings.general.${value.toLowerCase()}`,
    })
  }

  return (
    <SubSection>
      <span className="title">{renderMessage(type)}</span>
      <span className="description">
        {description && renderMessage(description)}
      </span>
      <ImageUpload
        className={`logo-${
          logo.ratio === '1:1'
            ? '1-1'
            : logo.ratio === '4:3'
            ? '4-3'
            : logo.ratio === '16:9'
            ? '16-9'
            : ''
        } profile-dashboard-thumbnail`}
        value={logo.value}
        type={type}
        cdnType={type}
        onUpload={(imgUrl) => handleImageUpload(imgUrl)}
        onRevert={() => handleImageUpload('')}
      />
      <span className="ratio">{`${renderMessage('ratio')}:`}</span>
      <select onChange={(e) => handleRatio(e.target.value)} value={logo.ratio}>
        {ratios.map((r) => (
          <option value={r}>{r}</option>
        ))}
      </select>
    </SubSection>
  )
}

export default LogoShared
