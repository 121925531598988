/* eslint-disable */
import React, { useState } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { useIntl } from 'react-intl'
import { defaultRoute } from '../routes'

import { NavItems, NavigationStyled } from './styles/index'
import { useAppConfig } from '_application'
import useIsFeatureOn from '_application/is-feature-on'

export let navItems = (features) => [
  { name: 'Dashboard', type: 'dashboard', path: `${defaultRoute}/dashboard` },
  {
    name: 'Site structure',
    type: 'sitestructure',
    path: `${defaultRoute}/site-structure`,
    children: [
      {
        name: 'Opportunities',
        type: 'opportunities',
        path: `${defaultRoute}/site-structure/opportunities`,
      },
      {
        name: 'Blog',
        type: 'blog',
        path: `${defaultRoute}/site-structure/blog`,
      },
      {
        name: 'Events',
        type: 'events',
        path: `${defaultRoute}/site-structure/events`,
        featureId: 'events',
      },
      ...(features?.includes('menubuilder')
        ? [
            {
              name: 'Menu',
              type: 'menu',
              defaultMessage: 'Menu',
              path: `${defaultRoute}/site-structure/menu`,
            },
          ]
        : []),
      ...(features?.includes('custompages')
        ? [
            {
              name: 'Pages',
              type: 'pages',
              defaultMessage: 'Pages',
              path: `${defaultRoute}/site-structure/pages`,
            },
          ]
        : []),
      {
        name: 'Forum',
        type: 'forum',
        path: `${defaultRoute}/site-structure/forum`,
        featureId: 'forum',
      },
      {
        name: 'Footer',
        type: 'footer',
        path: `${defaultRoute}/site-structure/footer`,
        featureId: 'footer',
      },
    ],
  },
  {
    name: 'Manage',
    path: `${defaultRoute}/data`,
    type: 'manageData',
    children: [
      {
        name: 'Keywords',
        type: 'keywords',
        path: `${defaultRoute}/data/keywords`,
      },
      {
        name: 'Topics',
        type: 'topics',
        path: `${defaultRoute}/data/topics`,
      },
      {
        name: 'Custom attributes',
        type: 'customAttributes',
        path: `${defaultRoute}/data/custom-attributes`,
      },
      {
        name: 'Users',
        type: 'manageUsers',
        path: `${defaultRoute}/users`,
      },
      {
        name: 'Reports',
        type: 'manageReports',
        path: `${defaultRoute}/reports`,
      },
      {
        name: 'Plugins',
        type: 'managePlugins',
        path: `${defaultRoute}/plugins`,
      },
    ],
  },
  {
    name: 'Settings',
    type: 'settings',
    path: `${defaultRoute}/settings`,
    children: [
      {
        name: 'General',
        type: 'general',
        path: `${defaultRoute}/settings/general`,
      },
      {
        name: 'Access page',
        type: 'accessPage',
        path: `${defaultRoute}/settings/access-page`,
      },
      {
        name: 'Override labels',
        type: 'overrideLabels',
        path: `${defaultRoute}/settings/override-labels`,
      },
    ],
  },
  {
    name: 'Branding',
    type: 'branding',
    path: `${defaultRoute}/branding`,
    children: [
      {
        name: 'Themes',
        type: 'themes',
        path: `${defaultRoute}/branding/themes`,
      },
    ],
  },
]

const renderNavItem = ({
  n,
  i,
  a,
  location,
  dropdowns,
  setDropdowns,
  intl,
  features,
}) => {
  const isDropdownOpen = dropdowns[n.path]
  return (
    <div
      key={n.path}
      className={`nav-item ${n.type.toLowerCase()} ${
        location?.pathname === n.path || n.path === resolvePathname(location)
          ? 'active'
          : ''
      } ${i === a.length - 1 ? 'last' : ''}`}
      
    >
      {n.children ? (
        <div className={`nav-item-content`}>
          <div
            onClick={() =>
              setDropdowns({ ...dropdowns, [n.path]: !isDropdownOpen })
            }
            className="nav-item-main"
          >
            <a href={location?.path} data-testid={`admin-navlink-${n.name}`}>
              {intl.formatMessage({
                id: `admin.nav.${n.type.toLowerCase()}`,
                defaultMessage: n.defaultMessage,
              })}
            </a>
            <div className="dropdown-icon">
              <i
                className={`fas fa-chevron-${isDropdownOpen ? 'up' : 'down'}`}
              />
            </div>
          </div>
          {isDropdownOpen && (
            <div className="nav-children">
              {n.children.map((c, ci, ca) =>
                checkForFeatureRoute(() =>
                  renderNavItem({
                    n: c,
                    i: ci,
                    a: ca,
                    location,
                    dropdowns,
                    setDropdowns,
                    intl,
                  })
                )({ features, navItem: c })
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="nav-item-content">
          <Link to={n.path} data-testid={`admin-navlink-${n.name}`}>
            {intl.formatMessage({
              id: `admin.nav.${n.type.toLowerCase()}`,
              defaultMessage: n.defaultMessage,
            })}
          </Link>
          <span
            className={`${
              location?.pathname === n.path ||
              n.path === resolvePathname(location)
                ? 'active'
                : ''
            } arrow-indicator`}
          >
            {(location?.pathname === n.path ||
              n.path === resolvePathname(location)) && (
              <i className="fas fa-chevron-circle-right"></i>
            )}
          </span>
        </div>
      )}
    </div>
  )
}

const resolvePathname = (location) => {
  const splittedPathname = location?.pathname?.split('/')
  if (splittedPathname?.length < 5) return ''
  return (
    splittedPathname?.slice(0, splittedPathname?.length - 1)?.join('/') || ''
  )
}

const checkForFeatureRoute = (callback) => ({ features, navItem }) => {
  if (navItem?.featureId)
    return useIsFeatureOn(navItem?.featureId) || navItem.featureId === "footer" ? callback() : () => {}
  return callback()
}

const initNavigation = (location, features) => {
  const dropdowns = {}
  navItems(features).map((nav) => {
    const isOpen =
      nav.children?.some(
        (c) =>
          c.path === location?.pathname || c.path === resolvePathname(location)
      ) || location.pathname === nav.path
    if (isOpen) dropdowns[nav.path] = true
    return nav
  })

  return dropdowns
}

const Navigation = () => {
  const location = useLocation()
  const [dropdowns, setDropdowns] = useState(initNavigation(location))
  const intl = useIntl()
  const { features } = useAppConfig()

  return (
    <NavigationStyled>
      <span className="admin-welcome-text">
        {intl.formatMessage({ id: `admin.welcometext` })}
      </span>
      <NavItems>
        {navItems(features).map((n, i, a) =>
          checkForFeatureRoute(() =>
            renderNavItem({
              n,
              i,
              a,
              location,
              dropdowns,
              setDropdowns,
              intl,
              features,
            })
          )({ features, navItem: n })
        )}
      </NavItems>
    </NavigationStyled>
  )
}

export default Navigation
