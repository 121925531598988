import gql from 'graphql-tag'

export const GET_KEYWORDS = gql`
  query GetKeywords($key: String, $usedBy: [String]) {
    getKeywords(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      keywordList {
        id
        value
      }
    }
  }
`

export const GET_CUSTOM_ATTRS = gql`
  query {
    getAttributes {
      key
      name
      description
      dateCreated
      dateUpdated
      type
      usedBy
      badges {
        key
        name
        icon
      }
    }
  }
`

export const GET_TAXONOMY = gql`
  query GetTaxonomy($key: String, $usedBy: [String]) {
    getTaxonomy(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      treeData {
        key: id
        name
        refId
        parent
      }
    }
  }
`
