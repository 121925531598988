import React, { useEffect } from 'react'
import { IKUICore } from '@indykiteone/jarvis-sdk-web'
import { ToastContainer } from 'react-toastify'
import Cookies from 'universal-cookie'
import dotenv from 'dotenv'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useUser } from '_security/session-hooks'
import { notification } from 'antd'
import { Container } from '../shared/sdk'

import 'react-toastify/dist/ReactToastify.css'

dotenv.config()

const cookies = new Cookies()

const Login = () => {
  const intl = useIntl()
  const user = useUser()
  const dispatch = useDispatch()

  const htmlString = `<h5>By clicking Agree & Join you agree with our <a href="/pages/terms/" data-testid="navlink-terms">terms and conditions</a>.</h5>`
  const IKForm = () => {
    useEffect(() => {
      IKUICore.renderForm({
        actionLabels: {
          I18N_FORGOTTEN_PASSWORD: intl.formatMessage({
            id: 'login.forgot.button',
          }),
          I18N_REGISTER: intl.formatMessage({ id: 'login.register.button' }),
          KEY_FORGOTTEN: intl.formatMessage({
            id: 'login.forgot.button',
          }),
          KEY_REGISTER: intl.formatMessage({ id: 'login.register.button' }),
        },
        labels: {
          forgotPasswordButton: intl.formatMessage({
            id: 'forgotten.password.resetpassword.title',
          }),
          username: intl.formatMessage({ id: 'login.email.label' }),
          password: intl.formatMessage({ id: 'login.password.label' }),
          loginButton: intl.formatMessage({ id: 'login.signin.button' }),
          registerButton: intl.formatMessage({ id: 'login.register.button' }),
        },
        renderElementSelector: '#login-container',
        forgotPasswordPath: '/forgot/password',
        onSuccess: (data) => {
          if (!user.authorized && data.token) {
            const expires = new Date()
            expires.setTime(expires.getTime() + 48 * 60 * 60 * 1000)
            cookies.set('accessToken', data.token, { path: '/' })

            localStorage.setItem('RIOT/token', data.token)

            dispatch({
              type: 'user/LOGIN',
              payload: { token: data.token },
            })
            notification.success({
              message: `${intl.formatMessage({ id: 'toast.login.message' })}`,
              description: `${intl.formatMessage({
                id: 'toast.login.description',
              })}`,
            })
          } else {
            // history.push('/')
          }
        },
        onRenderComponent: (
          component,
          componentType,
          componentSubtype,
          ...rest
        ) => {
          // Use this function in order to change the Register button label
          if (componentType === "form" && componentSubtype === "submit") {
            const [clickHandler, label, context] = rest;
            if (context["~ui"].startsWith("passwordCreate")) {
              component.innerText = "Agree & Register";
            }
          }
          if(componentType === 'form' && componentSubtype === 'password-confirm') {
            const termsAndConditionsEl = document.createElement("div");
      
            // You can put any "Terms & Conditions" content you want here.
            termsAndConditionsEl.innerHTML = `<b style='color: black;'>${htmlString}</b>`;

            component.appendChild(termsAndConditionsEl);
          }
          if(componentSubtype === 'register') {
            component.innerHTML = `<a href="/registration">${intl.formatMessage({ id: 'login.register.button' })}</a>`;
          }
          return component;
        },
      })
    }, [])
  }
  IKForm()
  return (
    <div className="full-signin-container">
      <div className="signin-container">
        <Container id="login-container" />
      </div>
      <ToastContainer />
    </div>
  )
}

export default Login
