import { identity } from 'lodash'

export const getDisplayName = (person, hideMiddleName = false) => {
  if (person) {
    if (hideMiddleName)
      return [person.firstName, person.lastName].filter(identity).join(' ')
    return [person.firstName, person.middleName, person.lastName]
      .filter(identity)
      .join(' ')
  }

  return ''
}

export const toMarkers = (people) =>
  people &&
  people.reduce((acc, person) => {
    const { location } = person
    const lat = parseFloat(location?.lat)
    const lng = parseFloat(location?.long)

    if (lat && lng) {
      acc.push({
        lat,
        lng,
        title: getDisplayName(person),
        data: person,
        dataId: person?.sub,
      })
    }

    return acc
  }, [])

export default {
  getDisplayName,
  toMarkers,
}
