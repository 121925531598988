import gql from 'graphql-tag'

export const UPDATE_OPPORTUNITY_SUBMISSION_AUTH = gql`
  mutation updateOpportunitySubmissionAuth(
    $isOpportunitySubmissionAuthEnabled: Boolean
  ) {
    updateOpportunitySubmissionAuth(
      isOpportunitySubmissionAuthEnabled: $isOpportunitySubmissionAuthEnabled
    ) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`

export const UPDATE_APPLICATION_SUBMISSION_AUTH = gql`
  mutation updateApplicationSubmissionAuth(
    $isApplicationSubmissionAuthEnabled: Boolean
  ) {
    updateApplicationSubmissionAuth(
      isApplicationSubmissionAuthEnabled: $isApplicationSubmissionAuthEnabled
    ) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`
