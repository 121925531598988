import PropTypes from 'prop-types'
import themable from './_themable'

const Props = {
  cover: PropTypes.string,
  avatar: PropTypes.string,
  avatarName: PropTypes.string,
  location: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.string,
}

export const Tile = themable('Tile', Props)
export default Tile
