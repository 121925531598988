import axios from 'axios'
import store from 'store'

const uploadImageToCdn = (file, type, contentType) => {
  return new Promise((resolve) => {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('type', type)
    formData.append('contentType', contentType)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/image/upload`, formData, {
        headers: { authorization: store.get('RIOT/token') },
      })
      .then((r) => {
        resolve(r.data)
      })
  })
}

export default uploadImageToCdn
