import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import admin from './admin/sagas'
import application from './application/sagas'
import message from './message/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), admin(), application(), message()])
}
