import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client'
import { setContext } from "@apollo/client/link/context";

import { IKUIUserAPI } from '@indykiteone/jarvis-sdk-web'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
})

const withToken = setContext(() => IKUIUserAPI.isInitialized() && IKUIUserAPI.isAuthenticated() && IKUIUserAPI.getValidAccessToken().then(tkn => {
  const token = tkn
  return { token }
}).catch((err) => { 
  return {token: ''} 
}))

const authLink = new ApolloLink((operation, forward) => {
    const { token } = operation.getContext()

    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        Authorization: token || ''
      },
    }))
    return forward(operation)
})

const typePolicies = {
  User: { keyFields: ['sub'] },
  PublicUserInfo: { keyFields: ['sub'] },
  Company: { keyFields: ['id'] },
  CompanyPublic: { keyFields: ['id'] },
  Activity: { keyFields: ['id'] },
  ActivityPublic: { keyFields: ['id'] },
  Opportunity: { keyFields: ['id'] },
  OpportunityPublic: { keyFields: ['id'] },
  Post: { keyFields: ['id'] },
  PostPublic: { keyFields: ['id'] },
  PostCategory: { keyFields: ['id'] },
  PageBuilder: { keyFields: ['contextId'] },
}

const cache = new InMemoryCache({
  typePolicies,
})

const link = ApolloLink.from([withToken, authLink, httpLink])

export const client = new ApolloClient({
  link,
  cache,
})

export default {
  client,
}
