import React, { useState } from 'react'

import AddModal from './modals/add'
import EditModal from './modals/edit'
import DeleteModal from './modals/delete'
import Table from './table'

const GenericDataManager = ({
  data,
  refetch,
  onAdd,
  onDelete,
  onToggleUsedBy,
  type,
  onUpdate,
  notEditableKeys,
}) => {
  const [editModal, setEditModal] = useState()
  const [deleteModal, setDeleteModal] = useState()

  const [currentData, setCurrentData] = useState()

  const handleOnUpdate = (props) => {
    onClose()
    onUpdate(props)
  }

  const handleOnDelete = () => {
    onClose()
    onDelete({ key: currentData.key })
  }

  const onClose = () => {
    setEditModal(false)
    setDeleteModal(false)
    setCurrentData(null)
  }

  return (
    <div>
      <AddModal type={type} onAdd={onAdd} />
      {editModal && (
        <EditModal
          onClose={onClose}
          onUpdate={(p) => handleOnUpdate(p)}
          currentData={currentData}
          type={type}
        />
      )}
      {deleteModal && (
        <DeleteModal
          onClose={onClose}
          onDelete={(p) => handleOnDelete(p)}
          currentData={currentData}
          type={type}
        />
      )}
      <Table
        data={data}
        onUpdate={onUpdate}
        refetch={refetch}
        type={type}
        setCurrentData={setCurrentData}
        setEditModal={setEditModal}
        setDeleteModal={setDeleteModal}
        onToggleUsedBy={onToggleUsedBy}
        notEditableKeys={notEditableKeys}
      />
    </div>
  )
}

export default GenericDataManager
