import store from 'store'
import { toJson } from '_utils/fetch'

const MEET_ENDPOINT = process.env.REACT_APP_MEET_ENDPOINT

export const sendMessage = (userId, message) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/send', {
    method: 'POST',
    body: JSON.stringify({ to: userId, content: message }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}

export const fetchMessages = (to) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/fetch', {
    method: 'POST',
    body: JSON.stringify({ to }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}
export const fetchMessagesByMessage = (messageId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/fetchbymessage', {
    method: 'POST',
    body: JSON.stringify({ messageId }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}
export const getConversations = () => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/conversations', {
    method: 'GET',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}
export const deleteConversation = (conversationUser) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/conversation', {
    method: 'DELETE',
    body: JSON.stringify({ conversationUser }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}
export const deleteMessage = (messageId) => {
  const accessToken = store.get('RIOT/token')

  return fetch(MEET_ENDPOINT + '/messages/message', {
    method: 'DELETE',
    body: JSON.stringify({ messageId }),
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}
