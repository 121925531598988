// Basic styles for tile buttons and tile input suggestion dropdown:
import styled from '@emotion/styled'
import BasicInput from './basic-input-styles'
import { tablet } from '_utils/responsive-queries'

export const ResultWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 23px;
  left: 0;
  width: 100%;
  padding: 30px 10px 15px;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--background);
  span {
    color: ${({ theme }) => theme.colors.primaryColor};
    font-weight: 500;
  }
  .add-button {
    padding: 5px 5px 5px 10px;
    color: var(--background) !important;
    font-weight: 600;
    font-size: 15px;
    transition: 0.3s;
    border: none !important;
    &:hover {
      color: var(--flavor);
      transform: scale(1);
    }
  }
`

export const InputStyled = styled(BasicInput)`
  position: relative;
  width: 100%;
  z-index: 10;
`

export const ItemWrapper = styled.div`
  --stroke-color: var(--imgColor);
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: max-content;

  .input-field {
    width: 250px;
  }
  input {
    padding: 8px 20px;
  }
  .input-container {
    position: relative;
    input {
      background-color: var(--background);
      color: var(--foreground);
      &::placeholder {
        color: var(--foreground) !important;
      }
    }
    ${tablet} {
      width: 45vw;
    }
  }
  i.fa-search {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.primaryColor};
    z-index: 3;
    font-size: 18px;
  }
`

export const SingleResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--foreground);
  span.name {
    padding: 5px 0;
    max-width: 100%;
    overflow: hidden;
  }
  button.add-button {
    color: var(--foreground) !important;
    border: none;
  }
`
