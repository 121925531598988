import { ReactComponent as activitiesIcon } from '../../resources/activities-icon-tool.svg'
import { ReactComponent as opportunitiesIcon } from '../../resources/opportunities-icon-tool.svg'
import { ReactComponent as companiesIcon } from '../../resources/companies-icon-tool.svg'
import { ReactComponent as peopleIcon } from '../../resources/people-icon-tool.svg'
import { ReactComponent as blogIcon } from '../../resources/blog-icon.svg'
import { ReactComponent as resourcesIcon } from '../../resources/resources-icon-tool.svg'
import { ReactComponent as searchIcon } from 'resources/search-icon-tool.svg'
import { ReactComponent as administrationIcon } from 'resources/administration-icon.svg'
import { ReactComponent as forumIcon } from 'resources/forum-icon.svg'
import { ReactComponent as eventsIcon } from 'resources/events-icon.svg'

export default {
  activitiesIcon: activitiesIcon,
  opportunitiesIcon: opportunitiesIcon,
  companiesIcon: companiesIcon,
  peopleIcon: peopleIcon,
  blogIcon: blogIcon,
  resourcesIcon: resourcesIcon,
  searchIcon: searchIcon,
  administrationIcon: administrationIcon,
  forumIcon: forumIcon,
  eventsIcon: eventsIcon,
}
