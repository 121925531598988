import React from 'react'
import { Modal, ModalVariant } from '@patternfly/react-core'
import { useIntl } from 'react-intl'

import styled from '@emotion/styled'
import { ctaButton } from '_utils/css-buttons'

const ModalStyled = styled(Modal)`
  background-color: var(--background);
  color: var(--foreground);
  border-radius: 25px;
  .pf-c-modal-box__footer {
    display: flex;
    flex-direction: row-reverse;
  }
`
const DeleteButton = styled.button`
  ${ctaButton}
`
const CancelButton = styled.button`
  padding: 0 20px;
  color: var(--foreground);
  border: none;
  background-color: transparent;
  text-decoration: underline;
  &:hover {
    opacity: 0.6;
  }
`

const DeleteModal = ({
  isOpen,
  onClose,
  topicDeleteAction,
  title,
  textContent,
}) => {
  const intl = useIntl()
  return (
    <>
      <ModalStyled
        title={title}
        variant={ModalVariant.small}
        isOpen={isOpen}
        onClose={() => onClose(false)}
        actions={[
          <DeleteButton onClick={() => topicDeleteAction()}>
            {intl.formatMessage({ id: 'forum.modal.button.delete' })}
          </DeleteButton>,
          <CancelButton onClick={() => onClose(false)}>
            {intl.formatMessage({ id: 'forum.modal.button.cancel' })}
          </CancelButton>,
        ]}
      >
        {textContent}
      </ModalStyled>
    </>
  )
}

export default DeleteModal
