import React from 'react'
// import styled from '@emotion/styled'
import { Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'
import ScrollToTop from '_components/scroll-to-top'
import { useFilters } from '_utils/filters'
// import LoginRequired from '_security/login-required'
import CompaniesHeader from './components/companies-header'
import AddNew from './components/company-new-button'
import CompaniesMapConnected from './connected/companies-map-connected'
import CompaniesGridConnected from './connected/companies-grid-connected'
import CompanyDetailsConnected from './connected/company-details-connected'
import { useAppLabel, usePageTitle } from '_application'
import { Container } from '../_styled-components/shared/section-container'
import { useUser } from '_security/session-hooks'
import CompanyForm from '_content-studio/forms/company'

const Companies = (props) => {
  const { filter, setFilter } = useFilters()
  const appLabel = useAppLabel()
  const title = appLabel('companiesPlural', 'companies.titleplural')
  const description = appLabel('companiesDescription', 'empty')
  usePageTitle(title)
  const user = useUser()

  return (
    <Switch>
      <Route exact path={['/companies/:section/new', '/companies/:section/:id/edit']} component={CompanyForm} />
      <MainLayout>
        <ScrollToTop />
        <Container className={"page-wrapper"}>
          <PageTitle
            description={description}
            actions={user.authorized && <AddNew />}
          >
            {title}
          </PageTitle>
          <CompaniesHeader filter={filter} onFilterChange={setFilter} />
          <Switch>
            <Route
              path="/companies/:section/:id"
              render={({ match, history, location }) => (
                <CompanyDetailsConnected
                  id={match.params.id}
                  onClose={() =>
                    history.push(
                      `/companies/${match.params.section}${location.search}`
                    )
                  }
                />
              )}
            />
          </Switch>

          <Switch>
            <Route
              path="/companies/map"
              render={() => <CompaniesMapConnected filter={filter} />}
            />
            <Route
              path="/companies/list"
              render={() => <CompaniesGridConnected filter={filter} history={props.history} />}
            />

            <Redirect exact from="/companies" to="/companies/list" />

          </Switch>
        </Container>
      </MainLayout>
    </Switch>
  )
}

export default Companies
