import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAppLabel } from '_application'
import { useMutation, useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import { MainWrapper } from '../styles'
import InfoModal from '../shared/modals/info-modal'
import {
  Section,
  SectionHeader,
  DeleteButton,
  StaticContentWrapper,
  ItemsWrapper,
  SingleItem,
} from './styles'

const DELETE_MY_PROFILE = gql`
  mutation DeleteMyProfile {
    deleteMyProfile
  }
`
const GET_PROFILE_DATA = gql`
  query GetProfileData {
    getProfileData {
      activities
      opportunities
      companies
      subscriptions
    }
  }
`

const entities = ['activities', 'opportunities', 'companies', 'subscriptions']

const listElementsKeys = ['one', 'two', 'three', 'four', 'five']

const DeleteMyProfile = () => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const [deleteMyProfile] = useMutation(DELETE_MY_PROFILE)
  const { data } = useQuery(GET_PROFILE_DATA)
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const renderKey = (item) =>
    intl.formatMessage({ id: `user.profile.account.deleteprofile.${item}` })

  const renderEntities = (type) => {
    return (
      <SingleItem>
        {appLabel(`${type}Plural`, `${type}.titleplural`)}:
        {` (${data?.getProfileData[type]})`}
      </SingleItem>
    )
  }

  const safeForDelete = ({
    activities,
    opportunities,
    companies,
    subscriptions,
  }) => {
    return activities + opportunities + companies + subscriptions === 0
  }

  if (!data) return <Loader loading={!data} />

  return (
    <MainWrapper>
      <Section>
        <SectionHeader>{renderKey('sectionheader')}</SectionHeader>
        <StaticContentWrapper>
          <span className="info-header">{renderKey('infoheader')}</span>
        </StaticContentWrapper>
        {data.getProfileData && (
          <ItemsWrapper>
            <span className="items-header-info">
              {renderKey('itemsheaderinfo')}
            </span>
            <span className="items-header">{renderKey('itemsheader')}</span>
            {data && entities.map((ent) => renderEntities(ent))}
          </ItemsWrapper>
        )}
        <StaticContentWrapper>
          <span className="list-header">{renderKey('listheader')}</span>
          <ul className="list">
            {listElementsKeys.map((key) => (
              <li>{renderKey(`listitem.${key}`)}</li>
            ))}
          </ul>
        </StaticContentWrapper>
        <span className="info-summary">{renderKey('infosummary')}</span>
        <DeleteButton
          disabled={!safeForDelete(data.getProfileData)}
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {renderKey('button')}
        </DeleteButton>
        {isModalOpen && (
          <InfoModal
            isOpen={isModalOpen}
            title={renderKey('modal.title')}
            content={renderKey('modal.content')}
            confirmButtonLabel={renderKey('modal.button.confirm')}
            cancelButtonLabel={renderKey('modal.button.cancel')}
            onClose={() => setIsModalOpen(false)}
            onConfirm={async () => {
              await deleteMyProfile()
              //logout current user
              dispatch({
                type: 'user/LOGOUT',
                payload: {},
              })
            }}
          />
        )}
      </Section>
    </MainWrapper>
  )
}

export default DeleteMyProfile
