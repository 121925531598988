import styled from '@emotion/styled'
import { css } from '@emotion/core'

import border from '_utils/css-border'
import imageSkeleton from '_utils/css-skeletons'

const extraSmall = css`
  grid-template-columns: 1fr;
  text-align: center;
  button {
    align-self: center;
    justify-self: center;
  }
`

export const DefaultTileStyled = styled.div`
  height: 100%;
  max-width: 526px;
  margin: 0 auto;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 25px;

  background-color: var(--background);

  padding: 25px;
  box-sizing: border-box;

  ${({ containerQuery }) => containerQuery === 'XS' && extraSmall}
  ${border()}
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 0;
`

export const Description = styled.div`
  color: var(--foreground);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`

export const Title = styled.h2`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const DiscoverTitle = styled.h3`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const Date = styled.div``

export const Location = styled.p`
  display: flex;
  font-size: 11px;
  color: var(--foreground);
  svg {
    width: 13.3px;
    margin-right: 6px;
    path {
      stroke: var(--foreground);
    }
  }
  .location-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Avatar = styled.div`
  --size: 164px;
  width: var(--size);
  height: var(--size);

  img {
    ${border()}
    ${imageSkeleton}
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const ConnectButtonContainer = styled.div`
  margin-top: 30px;
  display: grid;
  width: 100%;
  align-self: flex-end;
  button {
    width: 150px;
  }
`

export const Icon = styled.span`
display: flex;
align-items: flex-start;
`
