export const mapConnectVariables = (item, type, id) => {
  if (type === "a2c") {
    return {
      companyId: item?.id,
      activityId: id,
      companyOwner: item?.creator?._id
    }
  }
  if (type === "a2o") {
    return {
      opportunityId: item?._id,
      activityId: id,
      opportunityOwner: item?.creator?._id
    }
  }
  if (type === "o2a") {
    return {
      activityId: item?.id,
      opportunityId: id,
      activityOwner: item?.creator?._id
    }
  }
  if (type === "o2c") {
    return {
      companyId: item?.id,
      opportunityId: id,
      companyOwner: item?.creator?._id
    }
  }
  if (type === "c2a") {
    return {
      companyId: id,
      activityId: item?.id,
      activityOwner: item?.creator?._id
    }
  }
  if (type === "c2o") {
    return {
      companyId: id,
      opportunityId: item?.id,
      opportunityOwner: item?.creator?._id
    }
  }
  if (type === "e2p") {
    return {
      eventId: id,
      id: item?._id,
    }
  }
  if (type === "e2a") {
    return {
      eventId: id,
      id: item?._id, 
      activityOwner: item?.creator?._id
    }
  }
  if (type === "e2c") {
    return {
      eventId: id,
      id: item?._id, 
      companyOwner: item?.creator?._id
    }
  }
}

export const mapDisconnectVariables = (item, type, id) => {
  if (type === "a2c") {
    return { 
      companyId: item?.id,
      activityId: id
    } 
  }
  if (type === "a2o") {
    return {
      opportunityId: item?.id,
      activityId: id
    }
  }
  if (type === "o2a") {
    return {
      activityId: item?.id,
      opportunityId: id
    }
  }
  if (type === "o2c") {
    return {
      companyId: item?.id,
      opportunityId: id
    }
  }
  if (type === "c2a") {
    return {
      companyId: item?.id,
      activityId: id
    }
  }
  if (type === "c2o") {
    return {
      companyId: id,
      opportunityId: item?.id
    }
  }
  if (type === "e2p") {
    return {
      eventId: id,
      id: item?.id,
    }
  }
  if (type === "e2a") {
    return {
      eventId: id,
      id: item?.id,
    }
  }
  if (type === "e2c") {
    return { 
      eventId: id,
      id: item?.id,
    }
  }
}