import styled from '@emotion/styled'
import scroll from '_utils/css-scroll'

export const WysiwygWrapper = styled.div`
    width: 80vw;
    max-width: 800px;
    height: 515px;
    margin-bottom: 220px;
    display: flex;
    flex-direction: column;
    .fr-wrapper {
      ${scroll()}
    }
    .desc-label {
      font-size: 14px;
      font-weight: 700;
      color: var(--foreground);
    }

    span {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      color: var(--foreground);
    }
`

