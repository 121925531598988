import React, { useState } from 'react'
import {
  GET_FORUM_CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  UPDATE_FORUM_MODERATED_CATEGORIES,
} from './graph-queries'
import { useQuery, useMutation } from '@apollo/client'
import { omit } from 'lodash'
import SubsectionHeader from '../shared/subsection-header'
import AddModal from '../shared/add-modal'
import { useAppConfig } from '_application'
import { useAppConfigRefresh } from '_application/app-config'
import {
  MainContainer,
  AddCategoryButton,
  ContentWrapper,
  SingleCategory,
  ActionsWrapper,
  DotsButton,
  ExpandButton,
  InfoWrapper,
  MainWrapper,
  SubsectionWrapper,
  EditWindow,
  ActionButton,
} from './styles'
import { useIntl } from 'react-intl'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'

const defaultFormProperties = {
  cid: '',
  name: '',
  description: '',
  parentcid: 0,
  icon: '',
  bgcolor: '',
  color: '',
  moderated: false,
}

const Categories = () => {
  const appConfig = useAppConfig()
  const appConfigRefresh = useAppConfigRefresh()
  const { refetch: refetchAppConfig } = appConfigRefresh
  const intl = useIntl()

  const cloneForumConfig = (items) => {
    return items.map((i) =>
      omit(
        {
          ...i,
        },
        ['__typename', 'moderators']
      )
    )
  }

  let forumConfig = cloneForumConfig(appConfig?.forum || [])

  const moderatedCategory = (categoryId) => {
    return appConfig?.forum?.some(function (el) {
      return el.id === Number(categoryId)
    })
  }
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: GET_FORUM_CATEGORIES }],
  })

  const [editCategory] = useMutation(EDIT_CATEGORY, {
    refetchQueries: [{ query: GET_FORUM_CATEGORIES }],
  })

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [{ query: GET_FORUM_CATEGORIES }],
  })

  const [updateForumModeratedCategories] = useMutation(
    UPDATE_FORUM_MODERATED_CATEGORIES,
    {
      refetchQueries: [{ query: GET_FORUM_CATEGORIES }],
    }
  )

  const [expandDots, setExpandDots] = useState('')
  const [expandSubcategoriesNames, setExpandSubcategoriesNames] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formProperties, setFormProperties] = useState(defaultFormProperties)

  const handleCategorySubmit = (e) => {
    e.preventDefault()

    if (formProperties.cid) {
      handleEditCategory()
    } else {
      handleCreateCategory()
    }
  }

  const addModeratedCategory = (category) => {
    forumConfig.push(category)
    updateForumModeratedCategories({
      variables: {
        forum: forumConfig,
      },
    })
  }

  const removeModeratedCategory = (cid) => {
    forumConfig = forumConfig.filter(function (obj) {
      return obj.id !== cid
    })
    updateForumModeratedCategories({
      variables: {
        forum: forumConfig,
      },
    })
  }

  const handleCreateCategory = () => {
    createCategory({
      variables: formProperties,
    })
    setIsModalOpen(false)
    setFormProperties(defaultFormProperties)
  }

  const handleEditCategory = () => {
    editCategory({
      variables: formProperties,
    })
      .then((res) => refetchAppConfig())
      .catch((err) => console.log('error', err))

    if (formProperties.moderated) {
      addModeratedCategory({
        id: formProperties.cid,
        name: formProperties.name,
      })
    } else {
      removeModeratedCategory(formProperties.cid)
    }
    setIsModalOpen(false)
    setFormProperties(defaultFormProperties)
  }

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const handleClickExpand = (itemName) => {
    if (expandSubcategoriesNames.includes(itemName)) {
      const newArray = expandSubcategoriesNames.filter((n) => n !== itemName)

      return setExpandSubcategoriesNames(newArray)
    }

    return setExpandSubcategoriesNames([...expandSubcategoriesNames, itemName])
  }

  const handleClickDots = (itemName) => {
    setExpandDots(`${itemName === expandDots ? '' : itemName}`)
  }

  const { data } = useQuery(GET_FORUM_CATEGORIES)

  const handleEdit = (cid, parentCid) => {
    const isSubcategory = parentCid
    const resolvedId = parentCid ? parentCid : cid

    let category = data?.getCategories.categoriesList.find((obj) => {
      return obj.cid === resolvedId
    })

    category = isSubcategory
      ? category.childrenList.find((item) => item.cid === cid)
      : category

    setFormProperties({
      cid: category.cid,
      name: category.name,
      description: category.description,
      parentcid: category.parentcid,
      icon: category.icon,
      bgcolor: category.bgcolor,
      color: category.color,
      moderated: moderatedCategory(cid),
    })
    setIsModalOpen(true)
  }

  const handleDelete = (cid) => {
    deleteCategory({ variables: { cid: cid } })
  }

  const handleOnClose = () => {
    setIsModalOpen(false)
    setFormProperties(defaultFormProperties)
  }

    const ref = useClickOutside(() => expandDots && setExpandDots(false))

  const renderCategory = (item) => {
    const { name, icon, childrenList, cid, parentcid } = item
    const hasSubcategory = childrenList && childrenList.length > 0

    return (
      <SingleCategory>
        <MainWrapper>
          <InfoWrapper>
            <span className="icon-wrapper">
              <i className={`fa ${icon}`} />
            </span>
            <span className="name">{unescape(name)} </span>
          </InfoWrapper>
          <ActionsWrapper>
            <DotsButton onClick={() => handleClickDots(name)}>
              <i className="fas fa-ellipsis-h" />
              {expandDots === name && (
                <EditWindow ref={ref}>
                  <ActionButton
                    key="cancel"
                    variant="link"
                    onClick={() => handleEdit(cid, parentcid)}
                  >
                    {intl.formatMessage({
                      id: 'admin.sitestructure.forum.categories.editwindow.button.edit',
                      defaultMessage: 'Edit',
                    })}
                  </ActionButton>
                  <ActionButton
                    key="cancel"
                    variant="link"
                    onClick={() => handleDelete(cid)}
                  >
                    {intl.formatMessage({
                      id: 'admin.sitestructure.forum.categories.editwindow.button.delete',
                      defaultMessage: 'Delete',
                    })}
                  </ActionButton>
                </EditWindow>
              )}
            </DotsButton>
            {childrenList && item.childrenList.length > 0 && (
              <ExpandButton onClick={() => handleClickExpand(name)}>
                <i className="fas fa-chevron-down" />
              </ExpandButton>
            )}
          </ActionsWrapper>
        </MainWrapper>
        <SubsectionWrapper>
          {hasSubcategory &&
            expandSubcategoriesNames.includes(name) &&
            childrenList.map((subcategory) => renderCategory(subcategory))}
        </SubsectionWrapper>
      </SingleCategory>
    )
  }

  return (
    <MainContainer>
      <SubsectionHeader
        title={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.categories.title',
          defaultMessage: 'Categories',
        })}`}
        description={`${intl.formatMessage({
          id: 'admin.sitestructure.forum.categories.description',
          defaultMessage: 'What categories do you want in your forum?',
        })}`}
      >
        <AddCategoryButton onClick={() => setIsModalOpen(true)}>
          {intl.formatMessage({
            id: 'admin.sitestructure.forum.categories.button.addcategory',
            defaultMessage: 'Add new category',
          })}
        </AddCategoryButton>
      </SubsectionHeader>
      <ContentWrapper>
        {data?.getCategories.categoriesList.map((object) => {
          return <>{renderCategory(object)}</>
        })}
      </ContentWrapper>
      <AddModal
        refetchAppConfig={refetchAppConfig}
        isOpen={isModalOpen}
        onClose={handleOnClose}
        formProperties={formProperties}
        setFormProperties={setFormProperties}
        data={data}
        onSubmit={handleCategorySubmit}
      ></AddModal>
    </MainContainer>
  )
}

export default Categories
