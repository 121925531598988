import React from 'react'
import DashboardMessagesPage from 'components/dashboard/messages/dashboard-messages'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'

const Index = () => {
  return (
    <NavWrapper>
      <DashboardMessagesPage />
    </NavWrapper>
  )
}

export default Index
