import React, { useState } from 'react'
import { values } from 'lodash'
//import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon'
import {
  SelectOption,
  SelectVariant,
  SelectDirection,
} from '@patternfly/react-core'
import applicationLists from './applicationLists'
import { SAVE_APPLICATION } from '../opportunity-applications-management/gql'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { StyledSelect } from '../styles'

const intlKey = 'application.management.'

const ApplicationListSelect = ({
  application,
  isDetailView,
  className,
}) => {
  const [saveApplication] = useMutation(SAVE_APPLICATION)
  const intl = useIntl()

  const options = values(applicationLists).map((list) => (
    <SelectOption
      key={list.value}
      value={list.value}
      data-testid={`btn-application-categoryOption-${list.value}`}
    >
      {intl.formatMessage({
        id: `${intlKey}category.${list?.value?.split(' ')?.join('')}`,
      })}
    </SelectOption>
  ))

  const [isOpen, setOpen] = useState(false)

  const onSelect = (e, selection) => {
    saveApplication({
      variables: {
        application: { _id: application?._id, category: selection },
      },
      update: cache =>
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getApplicationsPaged' }),
      refetchQueries: ['GetApplication'],
      awaitRefetchQueries: true,
    })

    setOpen(false)
  }

  const titleId = 'title-id-1'
  return (
    <div>
      <span
        id={titleId}
        hidden
        data-testid={`application-${application?._id}-${application?.category}`}
      >
        {intl.formatMessage({ id: `${intlKey}title` })}
      </span>

      <StyledSelect
        className={className}
        //toggleIcon={<CubeIcon />}
        isDetailView={isDetailView}
        variant={SelectVariant.single}
        aria-label="Select Input"
        onToggle={setOpen}
        onSelect={onSelect}
        selections={application?.category}
        isOpen={isOpen}
        aria-labelledby={titleId}
        direction={SelectDirection.down}
      >
        {options}
      </StyledSelect>
    </div>
  )
}

export default ApplicationListSelect
