import { ctaButtonHollow } from '_utils/css-buttons'
import styled from '@emotion/styled'

export const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

export const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`
