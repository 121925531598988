export const getDisplayLocation = (location, intl) => {
  if (!location) {
    return ''
  }
  if (location.name) {
    return location.name
  }
  if (location.city && location.country) {
    if (
      location.city !== 'null' &&
      location.country !== 'null' &&
      location.city !== 'undefined' &&
      location.country !== 'undefined'
    ) {
      return `${location.city}, ${location.country}`
    }
    const locationNotAvailable = intl.formatMessage({
      id: 'default.location.notavailable',
    })
    return locationNotAvailable
  }
  return location.city || location.country || ''
}

export default {
  getDisplayLocation,
}
