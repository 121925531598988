import { gql } from '@apollo/client'

export const CONNECTED_COMPANIES = gql`
  query GetOpportunityConnectedCompaniesPaged($id: ID, $paging: PagingInput) {
    getOpportunityConnectedCompaniesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECTED_ACTIVITIES = gql`
  query GetOpportunityConnectedActivitiesPaged($id: ID, $paging: PagingInput) {
    getOpportunityConnectedActivitiesPaged(id: $id, paging: $paging) {
      offset
      hasMore
      count
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
