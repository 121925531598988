import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
// import { useAppLabel } from '_application'
import FilterHeader from '_components/dashboard/dashboard-filter-header'
// import { SORT_OPTIONS } from '_utils/sort'

const Props = {
  filter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
}
// TODO: only working filter atm - createdByMe

const EventsHeader = ({ filter, onFilterChange, user }) => {
  const intl = useIntl()
  // const appLabel = useAppLabel()
  const isAdmin = user.profile?.role === 'admin'
  const options = useMemo(
    () => [
      // {
      //   key: 'connected',
      //   label: intl.formatMessage(
      //     { id: 'companies.filter.connectedtome' },
      //     {
      //       category: appLabel(
      //         'eventsPlural',
      //         'events.titleplural'
      //       ).toLowerCase(),
      //     }
      //   ),
      // },
      {
        key: 'createdByMe',
        label: intl.formatMessage({ id: 'companies.filter.createdbyme' }),
      },
      isAdmin && {
        key: 'showPast',
        label: intl.formatMessage({
          id: 'events.filter.past',
          defaultMessage: 'Past events'
        }),
      }
      // ...SORT_OPTIONS(intl),
    ],
    // [intl, appLabel]
    [intl]
  )
  return (
    <FilterHeader
      className="events-header-filter"
      basePath="/events"
      filter={filter}
      filterOptions={options}
      onFilterChange={onFilterChange}
    />
  )
}
EventsHeader.propTypes = Props

export default EventsHeader
