import styled from '@emotion/styled'

export const DashboardGridTemp = styled.div`
  max-width: 1470px;
  justify-content: center;
  margin: 50px auto 100px;
`

export const Wrapper = styled.div`
  max-width: 1570px;
  margin: 25px auto 0;
`
