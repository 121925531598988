import React from 'react'
import PropTypes from 'prop-types'
import Tile from '_uikit/blog-tile'
//import { TileTextShort } from '_uikit/tile-sections'
import styled from '@emotion/styled'
import { processDescription } from '_utils/processDescription'

const Props = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    coverPhoto: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
      })
    ),
  }).isRequired,
  className: PropTypes.string,
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Post = ({ post, className, size }) => {
  const checkValue = (value) => {
    return value && value !== 'undefined' ? value : null
  }

  const postChecked = checkValue(post.description)
  return (
    <Tile 
      className={className}
      post={post}
      size={size}
    >
      <Wrapper>
        <p>
          {postChecked < 130
            ? processDescription(postChecked)
            : `${processDescription(postChecked).substring(0, 130)}...`}
        </p>
      </Wrapper>
    </Tile>
  )
}
Post.propTypes = Props

export default Post
