import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'react-bootstrap'
import { useAppLabel } from '_application'

import { Default as OpportunityTile } from '_opportunities/components/tiles'
import { Default as PersonTile } from '_people/components/tiles'
import FullBackgroundTile from '_activities/components/tiles/full-background-tile'
import { Default as CompanyTile } from '_companies/components/tiles'
import { Grid, BaseTile } from '_tiles'

import {
  LoadMoreContainer,
  LoadMoreButton,
  Layout,
  Container,
  PageTitle,
} from '../styles/connections-paged'

import { mergePage, nextPage } from '_utils/graph-pagination'

import { CONNECT_MUTATION as COMPANY_CONNECT_MUTATION } from '_companies/connected/company-details-connected'
import {
  CONNECT_MUTATION as PEOPLE_CONNECT_MUTATION,
  DISCONNECT_MUTATION,
} from '_people/connected/person-details-connected'
import { CONNECT_MUTATION as ACTIVITY_CONNECT_MUTATION } from '_activities/connected/activity-details-connected'

import { useUser } from '_security/session-hooks'

import { Loader } from '_components/loading'

import { getPageSizeForScreenSize } from '_tiles/helpers'
import { sendNotification } from '_components/buttons-connections/message-helper'
import { useIntl } from 'react-intl'

const resolveLabel = (currentTab) => {
  switch (currentTab) {
    case 'activity':
      return 'activities'
    case 'company':
      return 'companies'
    case 'opportunity':
      return 'opportunities'
    default:
      return currentTab
  }
}

const getTile = ({
  type,
  entity,
  connectButton,
  currentUser,
  disconnectButton,
}) => {
  const isOwner = entity?.creator?.sub === currentUser?.sub

  if (type === 'people')
    return (
      <PersonTile
        person={entity}
        connectButton={connectButton}
        isOwner={isOwner}
        disconnectButton={disconnectButton}
      />
    )
  if (type === 'company')
    return (
      <CompanyTile
        company={entity}
        connectButton={connectButton}
        isOwner={isOwner}
      />
    )
  if (type === 'activity')
    return (
      <FullBackgroundTile
        url={`/activities/list/${entity.id}`}
        key={entity.sub}
        activity={entity}
        connectButton={connectButton}
        isOwner={isOwner}
      />
    )
  if (type === 'opportunity')
    return <OpportunityTile opportunity={entity} isOwner={isOwner} />
}

const FetchMore = ({ hasMore, onFetch }) => {
  return (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton onClick={onFetch}>
            <i class="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  )
}

const gridConfig = {
  hasGutters: true,
}

const getTileConfig = ({ type, page }) => {
  let config = { '2xl': 3, xl: 4, lg: 4, md: 6, sm: 12 }

  if (page === 'peopleDetail') {
    config = { '2xl': 2, xl: 3, lg: 4, md: 6, sm: 6 }
    if (type === 'company') config = { '2xl': 3, xl: 4, lg: 6, md: 12, sm: 12 }
  } else {
    if (type === 'company') config = { '2xl': 4, xl: 6, lg: 6, md: 12, sm: 12 }
    if (type === 'activity') config = { '2xl': 4, xl: 4, lg: 4, md: 6, sm: 12 }
    if (type === 'event') config = { '2xl': 4, xl: 4, lg: 4, md: 6, sm: 12 }
  }

  return config
}

const ConnectionsPaged = ({
  children,
  connections,
  currentTab,
  page,
  useFollowersLabel,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()
  const [isLoadingMore, setIsLoadingMore] = useState()

  const resolvedType =
    currentTab === 'going' ||
    currentTab === 'interested' ||
    currentTab === 'speakers'
      ? 'people'
      : currentTab

  const pageSize = getPageSizeForScreenSize({
    pageType: 'connections',
    type: currentTab,
  })
  const currentConnection = connections[currentTab] || {}

  const { data, loading, fetchMore } = useQuery(currentConnection?.gqlQuery, {
    variables: {
      ...currentConnection?.gqlParams,
      paging: { offset: 0, limit: pageSize },
    },
  })

  const { hasMore, data: entities } =
    data?.[currentConnection?.gqlQueryName] || {}

  const connectMutations = {
    company: COMPANY_CONNECT_MUTATION,
    people: PEOPLE_CONNECT_MUTATION,
    opportunity: PEOPLE_CONNECT_MUTATION,
    activity: ACTIVITY_CONNECT_MUTATION,
  }

  const [disconnect] = useMutation(DISCONNECT_MUTATION)

  const [connectId, setConnectId] = useState()
  const [connect, { loading: connecting }] = useMutation(
    connectMutations[resolvedType]
  )

  const getConnectButton = ({ entity }) => {
    if (resolvedType !== 'opportunity')
      return {
        connectButton: {
          disabled: connecting && connectId === entity.id,
          onConnect: () => {
            setConnectId(entity.id)
            connect({
              variables: resolveConnectButtonVariables({ entity }),
              refetchQueries: [
                {
                  query: currentConnection?.gqlQuery,
                  variables: {
                    ...currentConnection?.gqlParams,
                    ...nextPage(0, entities?.length),
                  },
                },
              ],
              awaitRefetchQueries: true,
            }).then((res) => {

                const { status } =
                  res?.data?.[
                    `connectTo${
                      resolvedType === 'people'
                        ? 'User'
                        : `${
                            resolvedType.charAt(0).toUpperCase() +
                            resolvedType.slice(1)
                          }`
                    }`
                  ]

                sendNotification({
                  type: 'connection',
                  intl,
                  defaultHeader: 'Connection',
                  defaultContent: `You have ${
                    status === 'cancelled'
                      ? 'cancelled your connection'
                      : 'sent a connection request'
                  }`,
                })
              })
              .catch((err) => console.log('error', err))
          },
        },
      }
  }

  const getDisconnectButton = ({ entity }) => {
    if (currentTab === 'people') {
      return {
        disconnectButton: {
          onDisconnect: () => {
            disconnect({
              variables: { personId: entity.sub },
              refetchQueries: [
                {
                  query: currentConnection?.gqlQuery,
                  variables: {
                    ...currentConnection?.gqlParams,
                    ...nextPage(0, entities?.length),
                  },
                },
              ],
              awaitRefetchQueries: true,
            })
          },
        },
      }
    }
  }

  const resolveConnectButtonVariables = ({ entity }) => {
    switch (resolvedType) {
      case 'company':
        return {
          id: entity.id,
          creatorId: entity?.creatorId || entity?.creator?.sub,
        }
      case 'activity': 
        return {
          id: entity.id,
          creatorId: entity?.creatorId || entity?.creator?.sub,
        }
      case 'people':
        return { sub: entity?.sub }
      default:
        return {}
    }
  }

  const resolveTileUrl = ({ entity }) => {
    switch (resolvedType) {
      case 'company':
        return `/companies/list/${entity.id}`
      case 'people':
        return `/people/list/${entity.sub}`
      case 'opportunity':
        return `/opportunities/list/${entity.id}`
      case 'activity':
        return `/activities/list/${entity.id}`
      default:
        return ''
    }
  }

  const currentUser = useUser()

  const tabTitle =
    useFollowersLabel && currentTab === 'people'
      ? intl.formatMessage({
          id: 'connections.followers',
          defaultMessage: 'Followers',
        })
      : appLabel(
          `${resolveLabel(currentTab)}Plural`,
          `connections.paged.${resolveLabel(currentTab)}`
        )

  return (
    <Container>
      <PageTitle>
        {/* {entities?.length || '0'}{' '} */}
        {tabTitle}
      </PageTitle>
      <Layout key={currentTab}>
        <Grid config={gridConfig}>
          {entities?.map((entity) => {
            return (
              <BaseTile
                url={resolveTileUrl({ entity })}
                config={getTileConfig({ type: currentTab, page })}
                key={`${currentTab}-${entity?.id}`}
              >
                {getTile({
                  entity,
                  type: resolvedType,
                  currentUser,
                  ...getConnectButton({ entity }),
                  ...getDisconnectButton({ entity }),
                })}
              </BaseTile>
            )
          })}
        </Grid>
        {hasMore && !loading && !isLoadingMore && (
          <FetchMore
            onFetch={async () => {
              setIsLoadingMore(true)
              await fetchMore({
                variables: {
                  ...currentConnection?.gqlParams,
                  paging: { offset: entities?.length, limit: pageSize },
                },
                updateQuery: mergePage(currentConnection?.gqlQueryName),
              })
              setIsLoadingMore(false)
            }}
          />
        )}
        {(loading || isLoadingMore) && (
          <Loader loading={loading || isLoadingMore} />
        )}
      </Layout>
    </Container>
  )
}

export default ConnectionsPaged
