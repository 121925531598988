import styled from '@emotion/styled'
import { pablet, tablet } from '_utils/responsive-queries'

export const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: ${({ pageHeight }) =>
    pageHeight === 'auto' ? pageHeight : '100vh'};
  background-color: var(--background);
  padding: 180px 0px 50px;

  --c: var(--background);
  box-shadow: 0 0 0 100vmax var(--c);
  clip-path: inset(0 -100vmax);

  &.my-creations {
    padding: 0;
  }

  ${tablet} {
    padding-left: 50px;
    padding-right: 50px;
  }
  ${pablet} {
    padding-left: 25px;
    padding-right: 25px;
  }
`
