import React, { useState } from 'react'
import { MbToB } from 'services/file'
import { useAppConfig } from '_application'
import { useUser } from '_security/session-hooks'
import BannerImage from './banner-image'
import DatePicker from './date-picker'
import ShortDescription from './short-desc'
import Title from './title'
import Layout from './layout'
import ToggleButton from './toggle-button'
import Location from './location'

// Component for testing shared components
const SharedComponents = () => {
  const [exampleBannerImg, setExampleBannerImg] = useState('')
  const [exampleBooleanState, setExampleBooleanState] = useState(false)

  const [exampleLocation, setExampleLocation] = useState({
    address: '',
    lat: '',
    long: '',
  })

  const { sub } = useUser()
  const { tenantId, defaultLocale } = useAppConfig()
  const tagsConfig = {
    userId: sub,
    tenantId: tenantId,
    context: 'content-studio-blog-post-banner-image',
    mediaId: 'postId',
  }

  const uploadConfig = {
    folderName: 'content-studio',
    tagsConfig,
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  return (
    <Layout onSubmit={(formData) => console.log('formData:', formData)}>
      <h1>Shared Components:</h1>

      <Title onSave={(value) => console.log(value)} />
      <ShortDescription onSave={(value) => console.log(value)} />
      <BannerImage
        uploadConfig={uploadConfig}
        bannerImg={exampleBannerImg}
        onSave={(value) => setExampleBannerImg(value)}
      />
      <DatePicker
        config={{
          label: 'Published at',
          description: 'This can be used to schedule post for publishing',
          defaultLocale,
        }}
        onSave={(value) => console.log(value)}
      />
      <ToggleButton
        value={exampleBooleanState}
        onSave={(value) => setExampleBooleanState(value)}
        config={{
          title: 'Table of Contents ',
          desc: 'Enable Table of Contents (H1, H2 and H3)',
        }}
      />

      <Location
        venueLocation={exampleLocation}
        onSave={(value) => setExampleLocation(value)}
        desc="Location of the company"
      />
    </Layout>
  )
}

export default SharedComponents

