import React, { useState, useMemo } from 'react'
import { getTreeFromFlatData } from 'react-sortable-tree'
import SortableTree from 'components/indy/shared/taxonomy/components/sortable-tree'
import { AllTaxonomiesWrapper, SingleTaxonomyWrapper } from './styles/taxonomy'
import { GET_TAXONOMY } from '../gql'
import { useQuery } from '@apollo/client'
import BreakLine from '_custom-attributes/editor/components/breakline'

const SingleTaxonomy = ({ singleTax, onSelect, selectedValues }) => {
  const [treeData, setTreeData] = useState()

  useMemo(() => {
    const convert = getTreeFromFlatData({
      flatData: singleTax.treeData.map((node) => ({
        id: node.key,
        title: node.name,
        refId: node.refId,
        parent: node.parent,
      })),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    })
    setTreeData(convert)
  }, [singleTax])

  const handleSelectTaxonomy = ({ item, isSelected }) => {
    let list = selectedValues

    if (isSelected) list = list.filter((li) => li !== `${item.refId}`)
    else list.push(`${item.refId}`)
    handleUpdateSelectList(list)
    onSelect(singleTax.key, list)
  }

  const handleUpdateSelectList = (list) => {
    let t = treeData
    // hack to force re-render of taxonomy
    t = treeData.concat({ title: 'z' })
    t = t.slice(0, t.length - 1)
    setTreeData(t)
  }

  return (
    <SingleTaxonomyWrapper>
      <h1 className="taxonomy-title">{singleTax.name}</h1>
      <p className="taxonomy-description">{singleTax.description}</p>
      <div className="taxonomy-tree-wrapper">
        <SortableTree
          selectedValues={selectedValues}
          key={singleTax.key}
          treeData={treeData}
          onSelect={({ item, isSelected }) => {
            handleSelectTaxonomy({ item, isSelected })
          }}
        />
      </div>
    </SingleTaxonomyWrapper>
  )
}

const EventTaxonomy = ({ event, onSave }) => {
  const { data: taxonomyData } = useQuery(GET_TAXONOMY, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['events'] },
  })
  if (!taxonomyData) return <></>

  return (
    <>
    
    <AllTaxonomiesWrapper>
      {taxonomyData &&
        taxonomyData.getTaxonomy.map((item) => {
          const topicSelected = event.topics.find(
            (topic) => topic.key === item.key
          )

          return (
            <SingleTaxonomy
              singleTax={item}
              onSelect={onSave}
              selectedValues={topicSelected ? topicSelected.childrenKeys : []}
            />
          )
        })}
    </AllTaxonomiesWrapper>
    <BreakLine/>
    </>

  )
}

export default EventTaxonomy
