import React, { lazy } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'
import Location from 'components/indy/shared/location-selector'
import Url from 'components/indy/shared/ui-kit/inputs/text'

const components = {
  text: lazy(() => import('components/indy/shared/ui-kit/inputs/text')),
  email: lazy(() => import('components/indy/shared/ui-kit/inputs/text')),
  emailAccount: lazy(() =>
    import('components/indy/shared/ui-kit/inputs/email-account')
  ),
  url: Url,
  boolean: lazy(() =>
    import('components/indy/shared/ui-kit/buttons/toggle-button-with-name')
  ),
  image: lazy(() => import('components/indy/shared/image-upload')),
  date: lazy(() => import('components/indy/shared/date-picker')),
  select: lazy(() => import('components/indy/shared/ui-kit/selectors/generic')),
  phone: lazy(() => import('components/indy/shared/phone-number-input')),
  location: Location,
  badge: lazy(() =>
    import('components/indy/shared/ui-kit/selectors/multi-select-with-icon')
  ),
  file: lazy(() => import('components/indy/shared/file-upload')),
  wysiwyg: lazy(() =>
    import('components/indy/shared/ui-kit/inputs/froala-wysiwyg')
  ),
  textarea: lazy(() =>
    import('components/indy/shared/ui-kit/inputs/froala-wysiwyg')
  ),
  password: lazy(() => import('components/indy/shared/ui-kit/inputs/password')),
}

const convertStringToValue = (stringValue) => {
  if (stringValue) {
    try {
      return JSON.parse(stringValue.replace(/'/g, '"'))
    } catch (e) {
      return null
    }
  }
  return null
}

const getPlaceholder = (attribute, intl) => {
  let type = attribute.type
  let alternative = attribute.name
  if (type === 'text' || type === 'textarea')
    alternative = `${intl().formatMessage({ id: 'input.default.placeholder' })}`
  else if (type === 'url') alternative = 'https://'
  return attribute.placeholder || alternative
}

export const renderField = ({
  attribute,
  user,
  isCustomAttr,
  onSave,
  customValue,
  isCustomValue,
  error,
  onError,
  editorType,
}) => {
  const isAttrString =
    attribute.type === 'text' ||
    attribute.type === 'textarea' ||
    attribute.type === 'url' ||
    attribute.type === 'email' ||
    attribute.type === 'image' ||
    attribute.type === 'date' ||
    attribute.type === 'phone' ||
    attribute.type === 'wysiwyg' ||
    attribute.type === 'password'

  const savedAttr = isCustomAttr
    ? user?.profile.customAttributes.filter((ca) => ca.key === attribute.key)[0]
    : user?.profile[attribute.key]

  let value = isCustomValue ? customValue : savedAttr

  if (isCustomAttr)
    value = isAttrString
      ? savedAttr?.value
      : convertStringToValue(savedAttr?.value)
  const Component = components[attribute.type]
  if (!Component) return null
  if (
    attribute.type === 'text' ||
    attribute.type === 'url' ||
    attribute.type === 'password' ||
    attribute.type === 'email'
  ) {
    return (
      <Component
        onBlurCallback={(v) => onSave(attribute, v)}
        onErrorCallback={(v) => (onError != null ? onError(attribute, v) : '')}
        input={{
          name: attribute.name,
          value: value || attribute.value,
          type: attribute.type,
          placeholder: getPlaceholder(attribute, useIntl),
          error,
          required: attribute.required || false,
          maxLength: attribute.maxLength,
          disabled: attribute.disabled || false,
          dataType: attribute.dataType || null,
          testid: attribute.key,
        }}
        noTitle
      />
    )
  }
  if (attribute.type === 'emailAccount') {
    return (
      <Component
        onBlurCallback={(v) => onSave(attribute, v)}
        onErrorCallback={(v) => (onError != null ? onError(attribute, v) : '')}
        input={{
          name: attribute.name,
          value,
          type: attribute.type,
          placeholder: getPlaceholder(attribute, useIntl),
          error,
          required: attribute.required || false,
          maxLength: attribute.maxLength,
          disabled: attribute.disabled || false,
          dataType: attribute.dataType || null,
          testid: attribute.key,
        }}
        noTitle
      />
    )
  }
  if (attribute.type === 'textarea') {
    return (
      <Component
        onBlurCallback={
          attribute?.hasOnSaveAttr
            ? (v) => onSave(attribute, v)
            : (v) => onSave(v)
        }
        onErrorCallback={(v) => (onError != null ? onError(attribute, v) : '')}
        input={{
          name: attribute.name,
          value,
          type: attribute.type,
          placeholder: getPlaceholder(attribute),
          error,
          required: attribute.required || false,
        }}
        noTitle
        editorType={editorType}
      />
    )
  }
  if (attribute.type === 'boolean') {
    return (
      <Component
        className="user-profile"
        checked={value}
        onChange={(v) => onSave(attribute, v)}
      />
    )
  }
  if (attribute.type === 'image') {
    return (
      <Component
        value={value}
        type={attribute.key}
        cdnType={attribute.key}
        onUpload={(imgUrl) => onSave(attribute, imgUrl)}
        onRevert={() => onSave(attribute, null)}
      />
    )
  }
  if (attribute.type === 'file') {
    return (
      <Component
        className=""
        value={value}
        cdnType={attribute.key}
        type={attribute.key}
        onUpload={(fileUrl) => onSave(attribute, fileUrl)}
      />
    )
  }
  if (attribute.type === 'date') {
    const isValidDate =
      value && value !== '' && value !== 'null' && value !== 'undefined'

    const noDate = attribute.optional ? null : moment()

    return (
      <Component
        defaultDate={isValidDate ? moment(value) : noDate}
        onDateSelect={(e) => onSave(attribute, e)}
        size="m"
        align="left"
        style={{ marginTop: '10px' }}
        minDate={attribute.minDate}
        maxDate={attribute.maxDate}
        optional={attribute.optional}
      />
    )
  }
  if (attribute.type === 'select') {
    return (
      <Component
        value={value}
        onSelect={(v) => onSave(attribute, v)}
        options={attribute.options}
      />
    )
  }
  if (attribute.type === 'phone') {
    return <Component value={value} onSave={(v) => onSave(attribute, v)} />
  }
  if (attribute.type === 'location') {
    return (
      <Component
        defaultLocation={value || attribute.value}
        onSelect={(v) => onSave(attribute, v)}
      />
    )
  }
  if (attribute.type === 'badge') {
    return (
      <Component
        items={attribute.badges}
        selectedValues={value && Array.isArray(value) ? value : []}
        onSelect={(v) => onSave(attribute, v)}
      />
    )
  }

  if (attribute.type === 'wysiwyg') {
    return (
      <Component
        onBlurCallback={
          attribute?.hasOnSaveAttr
            ? (v) => onSave(attribute, v)
            : (v) => onSave(v)
        }
        onErrorCallback={(v) => (onError != null ? onError(attribute, v) : '')}
        value={value}
        editorValue={attribute.editorValue}
        onEditorModelChange={attribute.onEditorModelChange}
        editorType={attribute.editorType}
        heightMin={attribute.heightMin}
        imgUploadFolder={attribute.imgUploadFolder}
        imgTagsConfig={attribute.imgTagsConfig}

        // input={{
        //   name: attribute.name,
        //   value,
        //   type: attribute.type,
        //   placeholder: attribute.placeholder || attribute.name,
        //   error,
        //   required: attribute.required || false,
        // }}
      />
    )
  }
  if (attribute.type === 'password') {
    return (
      <Component
        onSaveCallback={(v) => onSave(attribute, v)}
        onErrorCallback={(v) => (onError != null ? onError(attribute, v) : '')}
        value={value}
        error={error}
        input={{
          name: attribute.name,
          value,
          type: attribute.type,
          placeholder: attribute.placeholder || attribute.name,
          error,
          required: attribute.required || false,
        }}
      />
    )
  }
  return null
}

export default { renderField }
