import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import ShareButton from 'resources/share-button-modal.png'

const Props = {
  title: PropTypes.string,
}

const EmailButton = styled.a`
  position: absolute;
  top: 26px;
  left: 120px;
  z-index: 2;
  img {
    height: 15px;
    object-fit: contain;
  }
  .share-text {
    color: var(--background);
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-weight: 600;
    font-size: 12px;
  }
  &.left {
    left: 20px;
  }
`

const EmailReferalButton = ({ title, canEdit }) => {
  const intl = useIntl()
  return (
    <EmailButton
      className={`${!canEdit ? `left` : ``}`}
      href={`mailto:?subject=${encodeURIComponent(title)}
          &body=I found something that might interest you, please register to check it out: ${window.location.href}`}
    >
      <img alt="share" src={ShareButton} />{' '}
      <span className="share-text">
        {intl.formatMessage({ id: `global.modal.label.share` })}
      </span>
    </EmailButton>
  )
}
EmailReferalButton.propTypes = Props

export default EmailReferalButton
