import { notification } from 'antd'

export const sendNotification = ({
  type,
  category,
  intl,
  name = '',
  defaultHeader,
  defaultContent,
}) => {
  if (type === 'connection') type = 'connection request'
  const message = intl.formatMessage({
    id: `toast.${type}.message`,
    defaultMessage: defaultHeader,
  })

  const description = intl.formatMessage(
    { id: `toast.${type}.description`, defaultMessage: defaultContent },
    { name }
  )

  notification.success({
    message,
    description,
  })
}
