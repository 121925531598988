import React from 'react'
import CookieConsentPage from '../../components/public-pages/cookie-policy'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'

const Index = () => {
  return (
    <NavWrapper>
      <CookieConsentPage />
    </NavWrapper>
  )
}

export default Index
