import React from 'react'

import PropTypes from 'prop-types'
import Tag from '_components/tag'
import SubSectionTitle from '_components/subsection-title'
import styled from '@emotion/styled'

const Props = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
}

const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 45px;
`

const AllTagsContainer = styled.div`
  width: 100%;
`

const List = styled.div`
  width: 100%;
`

const TagList = ({ title, values }) => {
  return (
    <TagListContainer>
      <SubSectionTitle>{title}</SubSectionTitle>
      <AllTagsContainer>
        <List>
          {values.map((value) => (
            <Tag key={value}>{value}</Tag>
          ))}
        </List>
      </AllTagsContainer>
    </TagListContainer>
  )
}
TagList.propTypes = Props

export default TagList
