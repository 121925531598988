import styled from '@emotion/styled'
import { mobile, pablet } from '_utils/responsive-queries'

import googleIcon from 'resources/google-icon-color.png'
import facebookIcon from 'resources/facebook-icon-color.png'
import linkedinIcon from 'resources/linkedin-icon-color.png'
import microsoftIcon from 'resources/windows-icon-color.jpeg'

export const Container = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 25px;
  min-height: 100%;
  background: radial-gradient(circle, var(--background) 27%, var(--shade) 98%);

  ${mobile} {
    padding: 0px;
  }

  ${pablet} {
    padding: 0;
    border-radius: 0%;
  }
`

export const LoginContainer = styled.div`
  position: relative;
  max-width: 950px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  margin-top: 250px;
  margin-bottom: 100px;
  background-color: var(--background);
  width: 100% !important;
  border-radius: 15px;

  box-shadow: 2px 0px 24px #7272724a;

  ${mobile} {
    margin: 0 auto;
    margin-top: 120px;
  }

  ${pablet} {
    margin: 0 60px 0 0;
    padding: 0;
    border-radius: 0px;
    overflow: auto !important;
  }

  .indykite-login-section {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .indykite-login-container > div {
    position: relative !important;

    div {
      width: 100% !important;
    }
    form {
      width: 100%;
      text-align: center;
      label {
        text-align: left !important;
        position: initial !important;
        height: auto !important;
        font-size: 16px !important;
        display: block !important;
        text-transform: capitalize !important;
        width: 100% !important;
      }
    }
  }

  #IKUISDK-new-password-container,
  .forgotten-password-form {
    display: flex;
    flex-direction: column;
    p {
      border: none !important;
    }
  }

  #IKUISDK-content-container {
    margin: 0 !important;

    span {
      font-size: 14px !important;
    }
  }

  #IKUISDK-btn-login,
  #IKUISDK-btn-new-password,
  #IKUISDK-reset-password-email-btn {
    display: inline !important;
    width: auto !important;
    margin: 40px auto 20px !important;
    border-radius: 25px !important;
    padding: 5px 20px !important;
    min-width: 40% !important;
    background: var(--foreground) !important;
    color: var(--background) !important;
    font-size: 16px !important;
  }

  #IKUISDK-btn-to-login {
    border: none;
    margin: 0;
    color: var(--foreground);
    a {
      color: var(--foreground);
      font-weight: 400;
      font-size: 14px;
      text-decoration: underline !important;
    }
  }

  #IKUISDK-btn-register {
    display: block !important;
    width: auto !important;
    margin: 30px auto 20px !important;
    border-radius: 25px !important;
    padding: 5px 20px !important;
    min-width: 40% !important;
    background: var(--foreground) !important;
    color: var(--background) !important;
    font-size: 16px !important;
  }

  #IKUISDK-btn-to-registration {
    margin: 0 0 30px !important;
    border: none !important;
    padding: 0 !important;
    &::before {
      content: attr(data-before-content);
      display: inline;
      color: var(--foreground) !important;
    }
    a {
      color: var(--foreground) !important;
      font-weight: 400 !important;
      text-decoration: underline !important;
      font-size: 14px !important;
    }
  }

  #IKUISDK-btn-to-forgot-password {
    position: absolute !important;
    top: 135px;
    left: 20px;
    font-size: 10px !important;
    color: var(--foreground) !important;
    border: none !important;
    padding: 0px !important;
    a {
      padding: 10px !important;
      &:hover {
        color: var(--foreground) !important;
      }
    }
  }
  .login-container {
    padding: 0px !important;

    &.row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      ${mobile} {
        grid-template-columns: 1fr;
      }
    }

    .login-col {
      padding-right: 0 !important;
      background-color: var(--background);
      .login-title {
        color: var(--foreground);
        font-weight: 700;
        font-size: 24px;
        margin: 30px;
        margin-bottom: 0px;
        padding-right: 15px !important;
      }
      button {
        height: 40px !important;
        background-color: var(--foreground) !important;
        border-radius: ${({ theme }) => theme.styles.borderRadius};
        font-family: ${({ theme }) => theme.fonts.googleFont} !important;
        color: var(--background);
        font-weight: 500;
      }
      hr {
        display: none;
      }
      p {
        border: 2px solid var(--foreground);
        border-radius: ${({ theme }) => theme.styles.borderRadius};
        padding: 12px;
        margin-top: 20px;
        a {
          font-family: ${({ theme }) => theme.fonts.googleFont} !important;
          color: var(--foreground) !important;
          font-weight: 500;
        }
      }
      h5 {
        width: 85%;
        color: var(--foreground) !important;
        border: none;
        font-size: 12px;
        margin: 0 auto;
        a {
          color: var(--foreground) !important;
        }
      }
      input {
        border: ${({ theme }) => theme.styles.inputBorder};
        height: 40px !important;
        color: black !important;
        font-size: 14px !important;
        margin-top: 5px !important;
        padding: 5px 10px !important;
        border-radius: 6px !important;
        background-color: white !important;

        ::placeholder {
          color: transparent;
        }
      }
    }
    .image-col {
      position: relative;
      padding-left: 0px !important;
      margin-left: 0px !important;
      .thumbnail {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: cover;
      }
      .slogan {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 40%;
        text-align: center;
        color: var(--background);
        font: ${({ theme }) => theme.fonts.googleFont};
        font-weight: 600;
        span {
          display: block;
        }
      }
    }
  }
  .indykite-login-container {
    background-color: transparent !important;
  }

  #IKUISDK-confirm-password,
  #IKUISDK-password,
  #IKUISDK-username {
    border: 1px solid var(--foreground) !important;
    margin-bottom: 10px !important;
    background-color: white !important;
  }

  .forgotten-password-form {
    label {
      color: var(--foreground) !important;
      font-size: 16px;
      display: block;
      text-transform: capitalize;
      width: 100% !important;
    }
    input {
      border: 1px solid rgb(208, 223, 243) !important;
    }
  }

  .IKUISDK-notification-container {
    height: auto !important;
    padding: 0 !important;
    margin: 20px 30px;
    div {
      border-color: red !important;
      padding: 5px 10px;
      span#IKUISDK-notification-text {
        color: red !important;
        font-size: 14px !important;
      }
    }
  }
  .indykite-login-container {
    input#IKUISDK-new-password,
    input#IKUISDK-confirm-new-password {
      border: 1px solid rgb(208, 223, 243) !important;
    }
    label {
      color: var(--foreground) !important;
      text-align: left !important;
      position: initial !important;
      height: auto !important;
      font-size: 16px !important;
      display: block !important;
      text-transform: capitalize !important;
      width: 100% !important;
    }
  }
  .indykite-login-container div {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.googleFont} !important;
    p:not(#IKUISDK-btn-to-forgot-password) {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: none !important;
      color: var(--foreground) !important;
      width: 100%;
      font-weight: 700;
      &::before,
      &::after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 30px;
        background-color: var(--flavor-contrast) !important;
        flex-grow: 1;
      }
      &::before {
        margin-right: 20px;
      }
      &::after {
        margin-left: 20px;
      }
    }
    button {
      color: var(--flavor-contrast) !important;
      border-color: var(--foreground) !important;
    }

    .google-login-button,
    .facebook-login-button,
    .microsoft-login-button,
    .linkedin-login-button {
      font-size: 0 !important;
      width: auto !important;
      border-radius: 100% !important;
      background-color: transparent !important;
      margin: 0 15px;
      padding: 0 !important;
      height: 30px !important;
      width: 30px !important;
      border-radius: 50% !important;
      &::after {
        content: '';
        display: block;
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
    .google-login-button {
      width: auto !important;
      background: url(${googleIcon});
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover !important;
    }
    .facebook-login-button {
      width: auto !important;
      background: url(${facebookIcon});
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover !important;
    }
    .microsoft-login-button {
      width: auto !important;
      background: url(${microsoftIcon});
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover !important;
    }
    .linkedin-login-button {
      width: auto !important;
      background: url(${linkedinIcon});
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover !important;
    }
  }

  ${pablet} {
    .login-container {
      display: flex !important;
      flex-direction: column-reverse !important;
      min-height: 100vh;
      max-width: 100% !important;
      margin: 100px 0px 0px;
      .login-col {
        /* height: 100px !important; */
        .indykite-login-container {
          padding-right: 30px;
        }
        #indykite-login-container {
          padding-top: 15px !important;
        }
        .indykite-login-container {
          padding-right: 15px !important;
        }
        #IKUISDK-btn-to-registration {
          margin-bottom: 15px !important;
        }
        #IKUISDK-btn-login {
          /* margin-top: 10px !important; */
        }
        #IKUISDK-notification-container {
          margin: 15px 20px !important;
        }
        #IKUISDK-reset-password-email-btn {
        }
        .forgotten-password-form {
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }
      .image-col {
        display: none;
        /* flex-grow: 0 !important; */
        min-height: 150px;
        overflow: hidden;
        margin-top: 100px;
        height: 500px;
        padding: 0 !important;
        img {
          max-height: 100% !important;
          object-position: center !important;
          height: 500px;
        }

        .login-title {
          width: 100%;
          height: 100%;
          position: relative;
          display: none;
        }
      }
    }
  }
`

