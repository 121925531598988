import { css } from '@emotion/core'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { ctaButton, ctaButtonHollow } from '_utils/css-buttons'
import { mobile } from '_utils/responsive-queries'

export const EventInfoWrapper = styled.div`
  padding: 25px 0;
`
export const TopInfoWrapper = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  padding-bottom: 25px;
  border-bottom: 1px solid var(--foreground-shade);

  ${mobile} {
    grid-template-columns: 4fr 1fr;
  }
`
export const BottomInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px 0;
  border-bottom: 1px solid var(--foreground-shade);

  ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
`
export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 25px;
  max-width: 75%;

  ${mobile} {
    margin-bottom: 15px;
  }
`
export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  position: relative;
`

export const RecommendButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 49px;
  right: -5px;
  .inner-suggestions-container {
    ${border()}
  }
  .recommend-button-wrapper {
    button,
    i {
      opacity: 0;
    }
  }
  ${mobile} {
    top: 33px;
    right: 0px;
    min-width: 92vw;
  }
`

export const TopButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  & > button {
    ${ctaButtonHollow()}
    border: 2px solid var(--flavor);
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
  }

  ${mobile} {
    flex-direction: column;
  }
`
export const BottomButtons = styled.div`
  display: flex;
  justify-content: end;
  button {
    ${ctaButton()}
  }
`
export const AdditionalInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  gap: 10px 25px;
  * > {
    font-size: 16px;
  }
  i {
    margin-right: 10px;
  }

  ${mobile} {
    display: flex;
    flex-direction: column;
  }
`
export const Date = styled.div``
export const EventLocation = styled.div``
export const Time = styled.div``
export const Price = styled.div``
export const EventCreator = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 2px solid var(--foreground);
  object-fit: cover;
  object-position: center;
  align-self: flex-start;

  ${mobile} {
    width: 45px;
    height: 45px;
  }
`
export const CreatorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const CreatorName = styled.div`
  font-size: 18px;
  font-weight: 600;
`
export const CreatorLocation = styled.div`
  font-size: 16px;
  i {
    margin-right: 10px;
  }
`
export const EventStats = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

  ${mobile} {
    justify-content: center;
  }
`
export const StatusOfInterestButton = styled.button`
  padding: 5px 15px;
  border-radius: 25px;
  border: 2px solid;
  border-color: var(--shade);
  background-color: transparent;
  color: var(--foreground-shade);

  i {
    color: var(--foreground-shade);
    fill: var(--foreground-shade);
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.6;
  }

  &.active:hover {
    color: var(--background) !important;
  }

  &.active {
    background-color: var(--shade);
    border-color: var(--shade);
    color: var(--foreground) !important;
    font-weight: 600;
    i.fa-check-circle {
      fill: #3da854 !important;
      color: #3da854 !important;
    }
    i.fa-star {
      fill: #fbce5e !important;
      color: #fbce5e !important;
    }
  }
`

export const DotsDropdown = styled.div``
export const LocationWrapper = styled.div``
export const Location = styled.div``
export const AnchorTag = styled.a`
  text-decoration: underline;
  &:focus {
    color: inherit;
  }
  ${({ isPreview }) =>
    isPreview &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  ${mobile} {
    align-self: flex-start;
  }
`

export const RecommendEvent = styled.button`
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: ${({ isPreview }) => isPreview && 'default'};
`

export const EditEventButton = styled.button`
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  cursor: ${({ isPreview }) => isPreview && 'default'};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 30px;
`

export const AddToCalendarButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: flex-end;

  padding: 20px 0 0;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  color: var(--flavor);
  font-weight: 500;
  font-size: 16px;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`
