import React from 'react'
import { Badge } from '@patternfly/react-core'
import { Keywords as ExistingKeyword } from '_keywords-and-taxonomies'
import Card from './card'
import styled from '@emotion/styled'
import { mobile, pablet } from '_utils/responsive-queries'
import { Title } from './styles/custom-attributes'

const CardStyled = styled(Card)`
  min-width: calc(50% - 10px);
  .name {
    padding-right: 0;
    &.keyword {
      padding-top: 5px;
    }
  }

  .badges {
    align-self: center;
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  ${pablet} {
    width: 100%;
    position: relative;
    i.tooltipIcon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  ${mobile} {
    .badges {
      justify-content: center;
    }
  }
`

const RenderOverride = ({ keywordList }) => {
  return (
    <>
      {keywordList.map((k, i) => {
        if (k && k?.values?.length > 0)
          return (
            <CardStyled key={i} tooltipText={k.description}>
              <div className="content-wrapper">
                <Title className="name keyword" span={3}>
                  {k.name}
                </Title>
                <div className="badges" span={9}>
                  {k.values.map((value) => (
                    <Badge className="custome-attribute">{value.value}</Badge>
                  ))}
                </div>
              </div>
            </CardStyled>
          )
        return null
      })}
    </>
  )
}

const Keywords = ({ person }) => {
  const defaultKeywordsKeys = ['competences']
  const defaultKeywords = person?.keywords?.filter((v) =>
    defaultKeywordsKeys.some((d) => d === v.key)
  )

  return (
    <>
      {defaultKeywords && (
        <>
          <ExistingKeyword
            RenderOverride={RenderOverride}
            noMainTitle
            values={defaultKeywords}
            type={'people'}
          />
        </>
      )}

      {person?.keywords && (
        <ExistingKeyword
          noMainTitle
          RenderOverride={RenderOverride}
          excludeKeys={defaultKeywordsKeys}
          values={person?.keywords}
          type={'people'}
        />
      )}
    </>
  )
}

export default Keywords
