import { useMemo, useCallback } from 'react'
import { omit } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

export const useHistoryPreservingSearch = () => {
  const history = useHistory()
  const location = useLocation()

  return useMemo(() => {
    return {
      push: (path) => history.push({ pathname: path, search: location.search }),
      replace: (path) =>
        history.replace({ pathname: path, search: location.search }),
    }
  }, [history, location])
}

export const useQuery = (key) => {
  const history = useHistory()
  const { search } = useLocation()
  const query = useMemo(() => qs.parse(search), [search])
  const current = query[key]

  const setQuery = useCallback(
    (value) => {
      const updated = value ? { ...query, [key]: value } : omit(query, key)
      history.push({ search: qs.stringify(updated) })
    },
    [query, history, key]
  )

  return [current, setQuery]
}

export default {
  useHistoryPreservingSearch,
}
