export const toMarkers = (opportunities) =>
  opportunities &&
  opportunities.reduce((acc, opportunity) => {
    const { location } = opportunity
    const lat = location?.lat
    const lng = location?.long

    if (lat && lng) {
      acc.push({
        lat,
        lng,
        title: opportunity.name,
        data: opportunity,
        dataId: opportunity?.id,
      })
    }

    return acc
  }, [])

export default {
  toMarkers,
}
