import gql from 'graphql-tag'

export const GET_TAGS = gql`
  query getTags($all: Boolean) {
    getTags(all: $all) {
      tags {
        tagsList {
          tag
          value
          score
        }
      }
    }
  }
`

export const CREATE_TAG = gql`
  mutation createTag($value: String!) {
    createTag(value: $value) {
      ok
      error
    }
  }
`

export const DELETE_TAG = gql`
  mutation deleteTag($value: String!) {
    deleteTag(value: $value) {
      status {
        status
        message
      }
      response
    }
  }
`
