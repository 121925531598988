import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import { RESPOND_ENTITY_MANAGER_INVITATION } from '_entities/entityManagers/gql'

export const EntityManagerInvitation = ({
  item,
  translationKey,
  defaultMessage,
  linkUrl,
  entityName,
}) => {
  const [respondMutation] = useMutation(RESPOND_ENTITY_MANAGER_INVITATION)

  const notificationId = item.notification._id

  const respondCallback = (state) =>
    respondMutation({
      variables: { notificationId, state },
      refetchQueries: ['GetSortedNotifications'],
    })

  const ContentComponent = () => (
    <span>
      <FormattedMessage
        id={
          translationKey ||
          'notificationscenter.notification.message.manageInvitationGeneric'
        }
        defaultMessage={defaultMessage || 'has invited you to manage'}
      />{' '}
      <Link to={linkUrl}>{entityName}</Link>
    </span>
  )

  // if (!data || loading) return null

  return (
    <BaseNotification
      item={item}
      acceptAction={() => respondCallback('accepted')}
      // decline-actions should delete the notification
      declineAction={() => respondCallback('declined')}
      ContentComponent={ContentComponent}
    />
  )
}
