import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Icon = styled.i`
  color: #dbdbdb;
  font-size: 16px;
  margin-left: -2px;
  margin-top: 1px;
`

const Container = styled.div`
  font-size: 28px;
`

const Props = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const LeftArrow = ({ onClick, className }) => {
  return (
    <Container
      className={className}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <Icon className="fas fa-chevron-left" />
    </Container>
  )
}

LeftArrow.propTypes = Props

export default LeftArrow
