import React from 'react'
import PublicDetailsCard from '_public-details-card'

import {
  CONNECTED_COMPANIES,
  CONNECTED_SPEAKERS,
  CONNECTED_ACTIVITIES,
  GOING_USERS,
  INTERESTED_USERS,
} from './graph-queries'

const Index = ({ entityId, currentTab, page }) => {
  const connections = {
    company: {
      gqlQuery: CONNECTED_COMPANIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getEEventConnectedCompaniesPaged',
      translationKey: 'companies.titleplural',
    },
    speakers: {
      gqlQuery: CONNECTED_SPEAKERS,
      gqlParams: { id: entityId },
      gqlQueryName: 'getEEventConnectedSpeakersPaged',
      translationKey: 'people.titleplural',
    },
    activity: {
      gqlQuery: CONNECTED_ACTIVITIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getEEventConnectedActivitiesPaged',
      translationKey: 'activities.titleplural',
    },
    going: {
      gqlQuery: GOING_USERS,
      gqlParams: { eventId: entityId },
      gqlQueryName: 'getGoingUsers',
      translationKey: 'connections.users.going',
    },
    interested: {
      gqlQuery: INTERESTED_USERS,
      gqlParams: { eventId: entityId },
      gqlQueryName: 'getInterestedUsers',
      translationKey: 'connections.users.interested',
    },
  }

  return (
    <PublicDetailsCard.ConnectionsPaged
      currentTab={currentTab}
      connections={connections}
      page={page}
    />
  )
}

export default Index
