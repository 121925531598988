import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import React, { useState } from 'react'
import { ToolTipWrapper, IconBtn, ToolTipContent } from './styles/tooltip'

const ToolTip = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useClickOutside(() => setIsExpanded(false))

  return (
    <ToolTipWrapper ref={ref}>
      <IconBtn
        onClick={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <i className="fas fa-info" />
      </IconBtn>
      <ToolTipContent show={isExpanded}>{children}</ToolTipContent>
    </ToolTipWrapper>
  )
}

export default ToolTip
