import styled from '@emotion/styled'

export const AboutEventWrapper = styled.div``
export const DescriptionWrapper = styled.div`
  iframe {
    height: 350px;
  }

  table td {
    border: 1px solid #ddd;
  }

  th {
    background: #ececec;
    border: 1px solid #ddd;
  }

  .fr-highlighted {
    border: 1px double red;
  }
  .fr-class-highlighted {
    background-color: var(--flavor);
    color: var(--flavor-contrast);

  }

  .fr-class-transparency{
    opacity: 0.5;
  }

  .fr-class-code{
    border-color: var(--shade);
    border-radius: 2px;
    background-clip: padding-box;
    background: var(--shade-faint);
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
  }

  a {
    color: var(--link);
    text-decoration: underline;
  }

  .fr-thick {
    border-width: 2px;
  }
  .fr-rounded {
    border-radius: 10px;
  }
  .fr-bordered {
    border: solid 5px var(--shade);
  }
  .fr-shadow {
    box-shadow: 10px 10px 5px 0px var(--shade);
  }
  .fr-fir {
    float: right;
  }
  ol, ul {
    list-style-position: inside;
  }
`
