import React from 'react'
import { nextPage } from '_utils/graph-pagination'
import { useIntl } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { LoadMoreButton, LoadMoreContainer } from './styled/fetch-more'

const FetchMore = ({
  hasMore,
  offset,
  loader,
  updateQuery,
  pageSize,
  variables,
}) => {
  const intl = useIntl()

  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: { ...variables, ...nextPage(offset, pageSize) },
                updateQuery,
              })
            }
          >
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i class="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}

export default FetchMore
