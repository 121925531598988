import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Props = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['round', 'square']),
}

const Button = styled.button`
  display: block;
  margin: ${({ disableMarginAuto }) => !disableMarginAuto && '0 auto'};
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-weight: 600;
  border-radius: ${(props) => (props.variant === 'square' ? '4px' : '30px')};
  color: var(--flavor-contrast);
  background-color: var(--flavor);
  border: 2px solid var(--flavor);
  transition: 0.5s;
  &:disabled {
    background: ${({ theme }) => theme.colors.inactive};
  }

  &:hover {
    background-color: transparent;
    color: var(--flavor);
  }
`
Button.propTypes = Props

const Success = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primaryColor};
`

const Danger = styled(Button)`
  background-color: ${({ theme }) => theme.colors.alertColor};
`

Button.Success = Success
Button.Danger = Danger

export default Button
