import { gql } from '@apollo/client'

export const CONNECT_OPPORTUNITY_TO_ACTIVITY = gql`
  mutation ConnectOpportunityToActivity(
    $activityId: String!
    $opportunityId: String!
    $activityOwner: String!
  ) {
    connectOpportunityToActivity(
      activityId: $activityId
      opportunityId: $opportunityId
      activityOwner: $activityOwner
    ) {
      status
    } 
  }
`

export const DISCONNECT_OPPORTUNITY_TO_ACTIVITY = gql`
  mutation DisconnectOpportunityToActivity(
    $activityId: String!
    $opportunityId: String!
  ) {
    disconnectOpportunityToActivity(
      activityId: $activityId
      opportunityId: $opportunityId
    ) {
      status
    }
  }
`

export const CONNECT_OPPORTUNITY_TO_COMPANY = gql`
  mutation ConnectOpportunityToCompany(
    $companyId: String!
    $opportunityId: String!
    $companyOwner: String!
  ) {
    connectOpportunityToCompany(
      companyId: $companyId
      opportunityId: $opportunityId
      companyOwner: $companyOwner
    ) {
      status
    }
  }
`

export const DISCONNECT_OPPORTUNITY_TO_COMPANY = gql`
  mutation DisconnectOpportunityToCompany(
    $companyId: String!
    $opportunityId: String!
  ) {
    disconnectOpportunityToCompany(
      companyId: $companyId
      opportunityId: $opportunityId
    ) {
      status
    }
  }
`