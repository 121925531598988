import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'

export const AddCreditCardModalStyled = styled.div`
  background-color: var(--background);
  h3 {
    font-size: 22px;
  }
  form {
    margin-top: 20px;
  }
  .footer {
    display: flex;
    gap: 10px;
    span {
      text-align: center;
      display: block;
      font-size: 16px;
    }
    button {
      &.return-button {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        &:hover {
          opacity: 0.6;
        }
      }
      &.confirm-button {
        ${ctaButtonHollow()};
      }
    }
  }
`

export const StripeElements = styled.div`
  input {
    border: 1px solid #b7b7b7;
  }
`
