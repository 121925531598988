import styled from '@emotion/styled'

export const TaxonomyTitle = styled.span`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  i {
    transition: 0.3s;
    transform: ${({ isExpand }) => isExpand && 'rotate(-180deg)'};
    margin-right: 15px;
  }
`

export const OptionsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px 30px;
  padding: 25px 0 0 30px;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  input {
    margin-right: 10px;
  }
  input[type='checkbox'] {
    accent-color: var(--flavor);
  }
`

export const SingleTaxonomyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  align-items: flex-start;
  gap: 5px;
  .taxonomy-tree-wrapper {
    align-self: flex-start;
  }
  .taxonomy-title {
    font-size: 15px;
    font-weight: 700;
  }
  .taxonomy-description {
    font-size: 14px;
  }
`

export const AllTaxonomiesWrapper = styled.div`
  width: 800px;
`
