import React from 'react'
import AddNewItemStyled from './styles/add-new-item'

const AddNewItem = ({ onClick, testid }) => {
  return (
    <AddNewItemStyled onClick={onClick}>
      <div
        className="circle-container"
        data-testid={`btn-${testid ?? 'default'}-addNewItem`}
      >
        <i className="fas fa-plus" />
      </div>
    </AddNewItemStyled>
  )
}

export default AddNewItem
