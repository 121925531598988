import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  color: var(--foreground);

  :hover {
    color: var(--foreground);
  }
`

export const ApplicantInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  .avatar-wrapper {
    margin: 0 25px 0 20px;
    position: relative;
  }

  .creator-content-wrapper {
  }

  .has-state {
    padding-top: 20px;
  }

  .creator-fullname {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 5px;
    color: var(--foreground);
  }

  .creator-location {
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    color: var(--foreground);

    i {
      margin-right: 4px;
      color: var(--foreground);
    }
  }

  .application-state {
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
    color: var(--foreground);
    text-transform: capitalize;
  }

  span {
    color: var(--foreground);
  }
`
