import React from 'react'
import GoogleMaps from 'components/indy/shared/google-maps'

import { MapStyled } from './styles/map'

const Map = ({ users }) => {
  const convertDataToMarkers = () => {
    const list = []
    users.map((u) => {
      if (
        u.location &&
        u.location?.lat !== 'null' &&
        u.location?.lat !== 'undefined'
      ) {
        list.push({
          lat: u.location.lat,
          lng: u.location.long,
          title: `${u.firstName} ${u.lastName}`,
        })
      }
      return null
    })
    return list
  }

  return (
    <MapStyled>
      <GoogleMaps markers={convertDataToMarkers()} />
    </MapStyled>
  )
}

export default Map
