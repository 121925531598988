import styled from '@emotion/styled'

export const EventTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;

  label {
    font-size: 15px;
    font-weight: 700;
    color: var(--foreground);
  }

  .title-input {
    width: 100%;
    height: 39px;
    padding: 10px 16px;
    border-radius: 5px;
    border: 1px solid var(--foreground);
    background: var(--background);
    color: var(--foreground);
  }
`
