export const toMarkers = (activities) =>
  activities &&
  activities.reduce((acc, activity) => {
    const { location } = activity
    const lat = location?.lat
    const lng = location?.long

    if (lat && lng) {
      acc.push({
        lat,
        lng,
        title: activity.name,
        data: activity,
        dataId: activity?.id,
      })
    }

    return acc
  }, [])

export default {
  toMarkers,
}
