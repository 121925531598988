import styled from '@emotion/styled'
import border from '_utils/css-border'
import { H5 } from '_themes/default/components/styled/headers'
import CategoryTag from '_themes/default/components/category-tag'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  border-radius: 25px;
  overflow: hidden;
  transition: 0.5s;
  height: ${({ tileHeight }) => tileHeight};
  ${border(0.3, 8, 2)}
  padding-bottom: 30px;
`

export const Cover = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

export const Header = styled.div`
  padding-left: 25px;
  padding-right: 40px;
`
export const Avatar = styled.img`
  position: relative;
  border-radius: 100%;
  border: 2px solid var(--background);
  background-color: var(--background);
  display: block;
  height: 70px;
  object-fit: cover;
  width: 70px;
  margin-top: -33px;
  margin-left: ${({ index }) => `-${index > 0 ? 23 : 0}px`};
  z-index: ${({ index }) => 4 - index};
`
export const AvatarName = styled.p`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-weight: 600;
  font-size: 13px;
  margin: 13px 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-left: 40px;
  padding-right: 40px;

  &.company-detail {
    padding-top: 0px;
  }
  &.company {
    padding-top: 0px;
  }
`
export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`
export const Tag = styled(CategoryTag)`
  margin-right: 7px;
  margin-bottom: 4px;
  color: var(--background);
`
export const Tags = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  overflow: hidden;
  i {
    color: var(--background);
  }
`
export const Title = styled(H5)`
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  color: var(--foreground);
`
export const ShadowLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75)
  );
`

export const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  p {
    color: var(--foreground) !important;
    font-family: ${({ theme }) => theme.fonts.googleFont};
  }
  a,
  .url-link {
    width: 100%;
  }
`

export const CompanyDetailsHeader = styled.div`
  padding: 25px 25px 0;
  position: relative;
  width: 100%;
  display: block;
`

export const CompanyAvatar = styled.img`
  border-radius: 100%;
  position: relative;
  border: none;
  display: block;
  height: 164px;
  width: 164px;
  object-fit: cover;
  margin: 0 auto 20px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 16%);
`

export const CompanyName = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  display: block;
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
`

export const Location = styled.div`
  text-align: center;
  color: var(--foreground);
`

export const CircleBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--foreground);
  border-radius: 100%;
  height: 23px;
  width: 23px;
  z-index: 1;
  margin-top: -12px;
  margin-left: -8px;
  p {
    margin: 0;
    color: var(--background);
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 10px;
    font-weight: 600;
  }
`
