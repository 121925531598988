import React, { useState, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Layout from '../../components/layout'
import { useLocation, useParams } from 'react-router-dom'
import Loading from '_components/loading'
import { useUser } from '_security/session-hooks'
import { useAppConfig, useAppLabel } from '_application'
import { MbToB } from 'services/file'
import { GET_OPPORTUNITY, SAVE_OPPORTUNITY } from './gql'
import { getFormDataFields } from './helpers';
import ScrollToTop from '../../../_components/scroll-to-top';
import Secure from './security'

import GeneralInformation from './components/general-information'
import AdditionalInformation from './components/additional-information'
import { useIntl } from 'react-intl'

export const OpportunityForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    coverPhoto: null,
    description: '',
    body: '',
    private: false,
    location: {
      city: '',
      country: '',
      label: '',
      lat: '',
      long: '',
    }
  })
  const { id } = useParams()
  const { sub } = useUser()

  const { tenantId } = useAppConfig()
  const { pathname } = useLocation()

  const intl = useIntl()
  const appLabel = useAppLabel()

  const [saveOpportunity] = useMutation(SAVE_OPPORTUNITY)

  const { loading, error, data } = useQuery(GET_OPPORTUNITY, {
    variables: {
      id,
    },
    skip: !id,
  })

  const entity = data?.getOpportunity

  const onSubmit = () => {
    let fd = { ...formData }

    if (fd?.receiveApplicationBy) {
      delete fd?.receiveApplicationBy.error
    }
 
    saveOpportunity({
      variables: { 
        input: fd,
      },
      update: cache => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAllOpportunitiesPublic' })
        cache.evict({ id: `Opportunity:{"id":"${id}"}` })
      }
    })
  }

  const changeFormProperty = (change) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...change,
      }
    })
  }

  useMemo(async () => {
    if (!id) {
      const entity = await saveOpportunity()
      setFormData({ ...formData, id: entity?.data?.saveOpportunity?.id })
    }
    if (data) setFormData(getFormDataFields(data.getOpportunity))
  }, [data])

  if (id && loading) return <Loading /> // todo spinner comp
  if (error) return `Error! ${error}`

  const tagsConfig = {
    userId: sub,
    tenantId: tenantId,
    context: 'content-studio-opportunity-banner-image',
    mediaId: 'opportunityID',
  }

  const uploadConfig = {
    folderName: 'content-studio',
    tagsConfig,
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }
  /* TODO: find better way to detect edit mode (more secure) */
  const isEdit = pathname?.split('/')?.pop()?.toLowerCase() === "edit"
  const resolvedTitle = isEdit === 'edit' ? 'edit' : 'new'

  const resolvedEntityName = appLabel('opportunities', 'opportunities.title')

  const title = intl.formatMessage(
    {
      id: 'contentstudiot.pagetitle',
      defaultMessage: `${resolvedTitle.charAt(0).toUpperCase() + resolvedTitle.slice(1)
        } ${resolvedEntityName.toLowerCase()}`,
    },
    {
      entity: resolvedEntityName.toLowerCase(),
    }
  )


  const isSubmitDisabled =
    formData?.name.length === 0 ||
    formData?.description.length === 0 ||
    formData?.body === '<p><br></p>' ||
    !formData?.coverPhoto ||
    formData?.receiveApplicationBy?.error




  return (
    <Secure isEdit={isEdit}>
      <Layout onSubmit={onSubmit} isSubmitDisabled={isSubmitDisabled}>
        <ScrollToTop />
        <h1>{title}</h1>
        <GeneralInformation
          formData={formData}
          changeFormProperty={changeFormProperty}
          uploadConfig={uploadConfig}
          entity={entity}
        />
        <AdditionalInformation
          formData={formData}
          changeFormProperty={changeFormProperty}
          uploadConfig={uploadConfig}
          entity={entity}
        />
      </Layout>
    </Secure>
  )
}

export default OpportunityForm

