import styled from '@emotion/styled'

export const MainSection = styled.div`
  margin-bottom: 30px;
  .title {
    font-size: 20px;
    font-weight: bold;
    display: block;
  }
`

export const SubSection = styled.div``
