import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import Button from '../../../shared/ui-kit/buttons/single-button'
import BackgroundImageStyled from './styles/background-image'
import ImageUpload from '../../../shared/image-upload/index'

const mapStateToProps = ({ user }) => ({ user })

const BackgroundImage = ({ user, dispatch }) => {
  const intl = useIntl()
  const handleImageUpload = (imgUrl, type) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: imgUrl },
      },
    })
  }

  return (
    <BackgroundImageStyled>
      <img
        src={user.profile.coverPhoto}
        alt=""
        data-testid="details-img-background"
      />
      <Button type="upload-regular">
        <ImageUpload
          className="profile-dashboard-thumbnail"
          value={user.profile.coverPhoto}
          type="no-image"
          cdnType="cover"
          onUpload={(imgUrl) => handleImageUpload(imgUrl, 'coverPhoto')}
          testid="details-background"
        />
        <i className="fas fa-camera" />
        <span>
          {intl.formatMessage({ id: 'global.background.changecover' })}
        </span>
      </Button>
    </BackgroundImageStyled>
  )
}

export default connect(mapStateToProps)(BackgroundImage)
