import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'
import { IndyHollowButton, IndyButton} from '_components/indyButton'
import { ModalStyled, Section } from './styles/export-modal'
import { useUserExport } from '_user-export/hooks'

import Loader from 'components/indy/shared/loader'

const fieldList = [
  { id: 'firstName', title: 'First name', sortBy: true },
  { id: 'lastName', title: 'Last name', sortBy: true },
  { id: 'email', title: 'Email', sortBy: true },
  { id: 'location', title: 'Location' },
  { id: 'role', title: 'Role', sortBy: true },
  { id: 'dateRegistered', title: 'Date registered', sortBy: true },
  { id: 'lastLogin', title: 'Last login', sortBy: true },
  { id: 'birthDate', title: 'Birth date', sortBy: true },
  { id: 'bioShort', title: 'Status', sortBy: true },
  { id: 'bioLong', title: 'Bio', sortBy: true },
]

const ExportModal = ({ onClose, context }) => {
  const intl = useIntl()
  const { exportUsers, loading, data } = useUserExport()

  const [fields, setFields] = useState(fieldList.slice(0, 4))
  const [sorting, setSorting] = useState('dateRegistered.desc')
  const [autoDownload, setAutoDownload] = useState()

  const renderMessage = (value) => {
    return intl.formatMessage({
      id: `usersexport.modal.${value.toLowerCase()}`,
    })
  }

  const handleExportUsers = () => {
    exportUsers({ variables: { context, fields, sorting } })
  }

  const renderMain = () => {
    return (
      <>
        <Modal.Body>
          <h3>{renderMessage('title')}</h3>
          <Section>
            <span className="title">
              {renderMessage('section.fields.title')}
            </span>
            {fieldList.map((f) => {
              return (
                <label
                  for={`admin-modal-field-checkbox-${f?.id}`}
                  className="checkbox"
                >
                  <input
                    id={`admin-modal-field-checkbox-${f?.id}`}
                    type={'checkbox'}
                    defaultChecked={fields.find((ff) => ff.id === f.id)}
                    onChange={() =>
                      setFields(
                        fields.find((ff) => ff.id === f.id)
                          ? fields.filter((ff) => ff.id !== f.id)
                          : [...fields, f]
                      )
                    }
                  />
                  <span>{renderMessage(`fields.${f?.id?.toLowerCase()}`)}</span>
                </label>
              )
            })}
          </Section>
          <Section>
            <span className="title">{renderMessage('section.sort.title')}</span>
            <div className="select">
              <select
                onChange={(e) => setSorting(e?.target?.value)}
                value={sorting}
              >
                {fieldList
                  .filter((f) => f?.sortBy)
                  .map((f) => {
                    return (
                      <>
                        <option value={`${f?.id}.desc`}>
                          {renderMessage(`fields.${f?.id?.toLowerCase()}`) +
                            ' (DESC)'}
                        </option>
                        <option value={`${f?.id}.asc`}>
                          {renderMessage(`fields.${f?.id?.toLowerCase()}`) +
                            ' (ASC)'}
                        </option>
                      </>
                    )
                  })}
              </select>
            </div>
          </Section>
        </Modal.Body>
        <Modal.Footer>
          <IndyHollowButton className="cancel" onClick={onClose}>
            {renderMessage('footer.button.cancel')}
          </IndyHollowButton>
          <IndyButton className="save" onClick={() => handleExportUsers()}>
            {renderMessage('footer.button.confirm')}
          </IndyButton>
        </Modal.Footer>
      </>
    )
  }

  const renderLoading = () => {
    return (
      <Modal.Body>
        <Loader
          title={renderMessage('loader.title')}
          description={renderMessage('loader.description')}
        />
      </Modal.Body>
    )
  }

  const renderResult = () => {
    const url = data?.exportUsers
    if (!autoDownload) {
      /* TODO: seems a bit hacky, maybe there is better solution */
      setAutoDownload(true)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', url)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }

    return (
      <>
        <Modal.Body>
          <div className="result">
            <span className="title">{renderMessage('result.title')}</span>
            <span>{renderMessage('result.description')}</span>
            <a
              href={url || '#'}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              {renderMessage('result.link')}
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IndyHollowButton className="cancel" onClick={onClose}>
            {renderMessage('footer.button.close')}
          </IndyHollowButton>
        </Modal.Footer>
      </>
    )
  }

  return (
    <Modal show onHide={onClose}>
      <ModalStyled>
        {loading && renderLoading()}
        {!loading && !data && renderMain()}
        {data && renderResult()}
      </ModalStyled>
    </Modal>
  )
}

export default ExportModal
