import React from 'react'
import moment from 'moment'

import { useAppLabel } from '_application'
import { useIntl } from 'react-intl'
import { useAppConfig } from '_application'

import { StyledList } from './styles/table'

let usedBy = [
  'activities',
  'opportunities',
  'companies',
  'people',
  'onboarding',
]

const Table = ({
  data,
  refetch,
  onDelete,
  onToggleUsedBy,
  type,
  setDeleteModal,
  setEditModal,
  setCurrentData,
  notEditableKeys,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()
  const itemName = (name) => {
    const itemName = intl.formatMessage({
      id: `admin.table.${name.toLowerCase()}`,
    })
    return itemName
  }

  const { features } = useAppConfig()

  const handleToggleModal = (d, isDelete) => {
    if (isDelete) setDeleteModal(true)
    else setEditModal(true)
    setCurrentData(d)
  }

  if (features?.includes('events')) {
    usedBy = usedBy?.includes('events') ? usedBy : [...usedBy, 'events']
  }

  if (!data) return null
  return (
    <StyledList>
      <table className="general-keywords-overview-table">
        <tr>
          <th>{itemName('name')}</th>
          <th>{itemName('description')}</th>
          {type === 'attribute' && <th>{itemName('type')}</th>}
          {usedBy.map((u) => {
            return (
              <th className="text-used-by">
                {appLabel(u, `admin.table.${u}`)}
              </th>
            )
          })}
          <th>{itemName('lastUpdated')} </th>
          <th className="text-center">{itemName('manage')}</th>
        </tr>
        {data.map((d) => {
          const noEdit = notEditableKeys?.some((nek) => nek === d.key)
          return (
            <tr className="item">
              <td className="item-name">{d.name}</td>
              <td className="item-description">{d.description}</td>
              {type === 'attribute' && <td className="item-type">{d.type}</td>}
              {usedBy.map((u) => {
                return (
                  <td className={`${noEdit ? 'no-edit' : ''} text-center`}>
                    <i
                      role="button"
                      tabIndex={0}
                      onClick={() => (noEdit ? () => {} : onToggleUsedBy(u, d))}
                      className={`fas fa-${
                        d.usedBy?.includes(u) ? 'check' : 'times'
                      }`}
                    />
                  </td>
                )
              })}
              <td className="text-used-by">
                {moment(d.dateUpdated).fromNow()}
              </td>
              <td className="text-center">
                {!noEdit && (
                  <i
                    tabIndex={0}
                    role="button"
                    onClick={() => handleToggleModal(d, true)}
                    className="fas fa-trash-alt"
                  />
                )}
                <i
                  tabIndex={0}
                  role="button"
                  onClick={() => handleToggleModal(d)}
                  className="fas fa-pencil-alt"
                />
              </td>
            </tr>
          )
        })}
      </table>
    </StyledList>
  )
}

export default Table
