import React, { useState } from 'react'
import { PlanFeature } from '_application'
import {
  PersonInfoWrapper,
  Avatar,
  OnlineIndicator,
  UserInfoWrapper,
  ContentWrapper,
  ButtonsWrapper,
  AdditionalInfoWrapper,
  DotsButton,
  DropdownWrapper,
  Dropdown,
  DisconnectUser,
  NameWrapper,
  EditPersonButton,
  CircularButton,
} from './styles/person-info'
import { useMediaQuery } from 'react-responsive'
import { useUserStatus, useMeetingCall } from '_meet'
import { useUserAccess } from '_security/helpers'
import { getDisplayName } from '_people/people-models'
import ConnectButton from '_components/buttons-connections/connect-button'
import RecommendButton from '_components/buttons-connections/recommend-button-new'
import { useIntl } from 'react-intl'
// import { useAppConfig } from '_application'
import ShareWithEmail from '_components/buttons-connections/share-with-email-new'
import { useUser } from '_security/session-hooks'
import { Link } from 'react-router-dom'
import { tabletPX } from '_utils/responsive-queries'

const PersonInfo = ({
  person,
  disconnectButton,
  connectButton,
  connections,
  connectionsLoading,
  p2pButton,
  recommendButton,
}) => {
  const intl = useIntl()
  const onlineStatus = useUserStatus(person?.id)
  const hasUserAccess = useUserAccess()
  const { startDirectCall } = useMeetingCall()
  // const { features } = useAppConfig()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isInputShown, setIsInputShown] = useState(false)

  const {
    firstName,
    // middleName,
    // coverPhoto,
    lastName,
    bioShort,
    location,
    avatar,
    // id,
    sub,
  } = person || {}

  const isTabletView = useMediaQuery({
    query: `(max-width: ${tabletPX}px)`,
  })

  const { onDisconnect, onDisconnectRefetchQueries } = disconnectButton
  const currentUser = useUser()
  const isCurrentUser = currentUser?.sub === sub

  const isConnected = connections?.connectionStatus === 'accepted'

  const disconnectUser = (e) => {
    e.preventDefault()
    if (onDisconnect) {
      const status = onDisconnect()
      onDisconnectRefetchQueries && onDisconnectRefetchQueries()
      // closeMenu()
      return console.log('DISCONNECT', status)
    }
  }

  return (
    <PersonInfoWrapper>
      <UserInfoWrapper className="personal-info-wrapper">
        <div className="avatar-wrapper">
          <div className="circle-background">
            <Avatar src={avatar} />
            <OnlineIndicator onlineStatus={onlineStatus} />
          </div>
        </div>
        <ContentWrapper>
          <NameWrapper>
            <h4 className="person-fullname">{getDisplayName(person)}</h4>
            <div className="status-wrapper">{bioShort}</div>
            <AdditionalInfoWrapper>
              {location?.label !== 'undefined' && (
                <span className="person-location">
                  <i className="fas fa-map-marker-alt" />
                  {location?.label}
                </span>
              )}
            </AdditionalInfoWrapper>
          </NameWrapper>
          <ButtonsWrapper>
            {isCurrentUser && (
              <Link to="/user/profile/about">
                <EditPersonButton>
                  <i className="fas fa-edit" />
                </EditPersonButton>
              </Link>
            )}
            {!isCurrentUser && (
              <>
                <ConnectButton
                  className="connect-button"
                  sub={sub}
                  status={hasUserAccess ? connections.connectionStatus : null}
                  onConnect={
                    hasUserAccess ? connectButton?.onConnect : () => {}
                  }
                  onConnectRefetchQueries={
                    hasUserAccess
                      ? connectButton?.onConnectRefetchQueries
                      : () => {}
                  }
                  disabled={connectButton?.disabled}
                  type="people"
                  size={`${isConnected || isTabletView ? 'small' : 'large'}`}
                />
                {isConnected && (
                  <PlanFeature feature="meet">
                    <CircularButton
                      className={
                        'video-button ' +
                        (onlineStatus !== 'ONLINE' ? 'disabled' : '')
                      }
                      onClick={() =>
                        onlineStatus === 'ONLINE' && startDirectCall(person.id)
                      }
                      disabled={onlineStatus !== 'ONLINE'}
                    >
                      <i className="fas fa-video" />
                    </CircularButton>
                  </PlanFeature>
                )}
              </>
            )}

            <DropdownWrapper>
              <DotsButton
                isDropdownOpen={isDropdownOpen}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <i className="fas fa-ellipsis-h" />
              </DotsButton>

              {isDropdownOpen && (
                <Dropdown isInputShown={isInputShown}>
                  <RecommendButton
                    isInputShown={isInputShown}
                    setIsInputShown={setIsInputShown}
                    contentToRecomment={sub}
                    onClick={() => setIsInputShown(!isInputShown)}
                  />
                  {!isInputShown && (
                    <>
                      <ShareWithEmail title={`${firstName} ${lastName}`} />
                      {!isCurrentUser && isConnected && (
                        <DisconnectUser onClick={(e) => disconnectUser(e)}>
                          <i className="fas fa-unlink" />
                          <span>
                            {intl.formatMessage({
                              id: `person.details.user.button.label.disconnect`,
                            })}
                          </span>
                        </DisconnectUser>
                      )}
                    </>
                  )}
                </Dropdown>
              )}
            </DropdownWrapper>
          </ButtonsWrapper>
        </ContentWrapper>
      </UserInfoWrapper>
    </PersonInfoWrapper>
  )
}

export default PersonInfo
