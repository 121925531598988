import styled from '@emotion/styled'

export const CreatorStyled = styled.div`
  &.taxonomy-tree-view-container {
    font-family: ${({ theme }) => theme.fonts.googleFont};
    color: var(--foreground);
  }
  .full-tree-view {
    margin-top: 0;
  }
  button {
    border: none;
    background-color: transparent;
    i {
      transition: 0.3s;
    }
    i:hover {
      color: var(--flavor);
      font-size: 16px;
    }
  }
`
