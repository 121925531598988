import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { IKUICore } from '@indykiteone/jarvis-sdk-web'
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'
import dotenv from 'dotenv'
import { useIntl } from 'react-intl'
import { Container } from '../shared/sdk'

dotenv.config()

const cookies = new Cookies()

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
})

const checkLength = (string) => string?.length > 7
const checkForUppercase = (string) => string?.match(/[A-Z]/)
const checkForLowercase = (string) => string?.match(/[a-z]/)
const checkForNumber = (string) => string?.match(/\d+/g)
const checkForWhitespaces = (string) => !string?.match(/\s/)
// const checkForSymbols = (string) => {
//   const symbols = new RegExp(/[^A-Z a-z0-9]/)
//   return symbols.test(string)
// }
const RenderContainer = React.memo(() => {
  return <Container id="register-container" />
})

const Registration = ({ dispatch, user }) => {
  const intl = useIntl()
  const [redirect, setRedirect] = useState()
  const [passwordProperties, setPasswordProperties] = useState({
    password: '',
    correctLenght: false,
    containsUppercase: false,
    containsLowercase: false,
    containsNumber: false,
    containsSymbols: false,
    hasOnlySigns: false,
  })

  const messages = {
    correctLenght: `${intl.formatMessage({
      id: 'changepassword.message.length',
    })}`,
    containsUppercase: `${intl.formatMessage({
      id: 'changepassword.message.uppercase',
    })}`,
    containsLowercase: `${intl.formatMessage({
      id: 'changepassword.message.lowercase',
    })}`,
    containsNumber: `${intl.formatMessage({
      id: 'changepassword.message.number',
    })}`,
    // containsSymbols: `${intl.formatMessage({
    //   id: 'changepassword.message.symbol',
    // })}`,
    hasOnlySigns: `${intl.formatMessage({
      id: 'changepassword.message.signs',
    })}`,
  }

  const validatePassword = (password) => {
    const err = document.getElementById('password-error')
    if (err) {
      err.remove()
    }

    const checkPasswordProperties = {
      password: password,
      correctLenght: checkLength(password),
      containsUppercase: checkForUppercase(password),
      containsLowercase: checkForLowercase(password),
      containsNumber: checkForNumber(password),
      //containsSymbols: checkForSymbols(password),
      hasOnlySigns: checkForWhitespaces(password),
    }

    setPasswordProperties(checkPasswordProperties)
    if (Object.values(checkPasswordProperties).some((item) => item === false)) {
      let p = document.createElement('div')
      p.id = 'password-error'
      let output = ''

      Object.keys(passwordProperties).forEach((k) => {
        if (k && !checkPasswordProperties[k] && messages[k]) {
          output = `<span style="color: red; align-self: flex-start; text-align: left;">${intl.formatMessage(
            { id: 'register.validation.password.fail' }
          )}</span>`
        }
      })

      p.innerHTML = output

      document.querySelector('#IKUISDK-username').parentElement.appendChild(p)

      return false
    }
    return true
  }
  const htmlString = `<h5>By clicking Agree & Join you agree with our <a href="/pages/terms/" data-testid="navlink-terms">terms and conditions</a>.</h5>`
  const alreadyAMemberHtml = `
  <p id="IKUISDK-btn-to-login" style="font-family: Rubik, sans-serif; font-size: 12px; text-align: center; cursor: pointer; margin-bottom: 16px;">
    <a style="color: rgb(250, 250, 250); text-decoration: none;" href="/login">
      ${intl.formatMessage({
        id: 'register.alreadyregistered.button',
      })}
    </a>
  </p>`
  const IKForm = () => {
    useEffect(() => {
      IKUICore.renderForm({
        actionLabels: {
          I18N_FORGOTTEN_PASSWORD: intl.formatMessage({
            id: 'login.forgot.button',
          }),
          I18N_REGISTER: intl.formatMessage({ id: 'login.register.button' }),
        },
        labels: {
          forgotPasswordButton: intl.formatMessage({
            id: 'login.forgot.button',
          }),
          username: intl.formatMessage({ id: 'login.email.label' }),
          password: intl.formatMessage({ id: 'login.password.label' }),
          loginButton: intl.formatMessage({ id: 'login.signin.button' }),
          registerButton: intl.formatMessage({ id: 'login.register.button' }),
        },
        renderElementSelector: '#register-container',
        forgotPasswordPath: '/forgot/password',
        arguments: {
          flow: 'register',
        },
        onSuccess: (data) => {
          if (!user.authorized && data.token) {
            const expires = new Date()
            expires.setTime(expires.getTime() + 48 * 60 * 60 * 1000)
            cookies.set('accessToken', data.token, { path: '/' })
  
            localStorage.setItem('RIOT/token', data.token)
  
            dispatch({
              type: 'user/REGISTER',
              payload: {
                token: data.token,
                intl,
                firstName: '',
                lastName: '',
                email: '',
              },
            })
            setRedirect(true)
          } else {
            // history.push('/')
          }
        },
        onRenderComponent: (
          component,
          componentType,
          componentSubtype,
          ...rest
        ) => {
          // Use this function in order to change the Register button label
          if (componentType === "form" && componentSubtype === "submit") {
            const [clickHandler, label, context] = rest;
            if (context["~ui"].startsWith("passwordCreate")) {
              component.innerText = intl.formatMessage({ id: 'login.register.button' });
            }
          }
          if(componentType === 'form' && componentSubtype === 'password-confirm') {
            const termsAndConditionsEl = document.createElement("div");
            const alreadyAMember = document.createElement("div");
      
            // You can put any "Terms & Conditions" content you want here.
            termsAndConditionsEl.innerHTML = `<b style='color: black;'>${htmlString}</b>`;
            alreadyAMember.innerHTML = alreadyAMemberHtml

            component.appendChild(termsAndConditionsEl)
            component.appendChild(alreadyAMember)
          }
          return component;
        },
      })
    }, [])
  }
  IKForm()

  if (redirect) {
    return <Redirect to="/user/onboarding" />
  }

  return (
    <div>
      <div>
        <RenderContainer />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Registration)
