import React from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'
import Button from '../../../shared/ui-kit/buttons/single-button'
import ProfileImgStyled from './styles/profile-images'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import { MbToB } from 'services/file'
import { useAppConfig } from '_application'
import { useUser } from '_security/session-hooks'

const mapStateToProps = ({ user, appConfig }) => ({ user, appConfig })

const ProfileImages = ({ user, dispatch }) => {
  const theme = useTheme()
  const intl = useIntl()

  const { tenantId } = useAppConfig()
  const { sub } = useUser()

  const handleImageUpload = (imgUrl, type) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, [type]: imgUrl },
      },
    })
  }

  const uploadConfigCoverPhoto = {
    folderName: 'user-on-boarding',
    tagsConfig: {
      userId: sub,
      tenantId: tenantId,
      context: 'user-profile-covers',
    },
    maxImageFileSize: MbToB(2),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const uploadConfigUserAvatar = {
    folderName: 'user-on-boarding',
    tagsConfig: {
      userId: sub,
      tenantId: tenantId,
      context: 'user-profile-avatars',
    },
    maxImageFileSize: MbToB(2),
    maxImageWidth: 2000,
    mediaContainerType: 'profileUserAvatar',
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const coverImg = useMediaUploader(uploadConfigCoverPhoto, (img) =>
    handleImageUpload(img?.url, 'coverPhoto')
  )

  const avatarImg = useMediaUploader(uploadConfigUserAvatar, (img) =>
    handleImageUpload(img?.url, 'avatar')
  )

  return (
    <ProfileImgStyled>
      <div className="step-content-container background">
        <div className="images-upload">
          <div className="image cover-photo">
            <img
              alt=""
              src={user?.profile?.coverPhoto || theme.images.defaultCover}
              data-testid="onboarding-img-background"
            />
            <Button
              onClick={() => coverImg?.showWidget()}
              type={`upload-${'regular'}`}
              testid="onboarding-background"
            >
              <i className="fas fa-camera" />
              <span>
                {intl.formatMessage({ id: 'default.button.changecover' })}
              </span>
            </Button>
            <div className="image avatar">
              <img
                alt=""
                src={user?.profile?.avatar || theme.images.defaultAvatar}
                data-testid="onboarding-img-avatar"
              />
              <Button
                type="upload-circular"
                onClick={() => avatarImg?.showWidget()}
                testid="onboarding-avatar"
              >
                <i className="fas fa-camera" />
              </Button>
              <span className="title">
                {intl.formatMessage({
                  id: 'default.button.changeprofileimage',
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ProfileImgStyled>
  )
}

export default connect(mapStateToProps)(ProfileImages)

