import React from 'react'
import { LoginTitleStyle } from '_themes/default/components/styled/login-title'

const LoginTitle = ({ className, children }) => {
  return (
    <LoginTitleStyle className={`${className}`}>{children}</LoginTitleStyle>
  )
}

export default LoginTitle
