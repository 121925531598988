import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

const GET_KEYWORDS = gql`
  query {
    getKeywords {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      keywordList {
        id
        value
      }
    }
  }
`

export const GET_TAXONOMY = gql`
  query {
    getTaxonomy {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      treeData {
        key: id
        name
        refId 
        parent
        refId
      }
    }
  } 
`

export const useKeywords = (type) => {
  const { data, loading, error } = useQuery(GET_KEYWORDS)

  const keywords = useMemo(
    () => data?.getKeywords.filter((att) => att?.usedBy.includes(type)),
    [data, type]
  ) 

  return { loading, error, keywords }
}

export const useTaxonomy = (type) => {
  const { data, loading, error } = useQuery(GET_TAXONOMY)
  const taxonomies = useMemo(
    () => data?.getTaxonomy.filter((att) => att?.usedBy.includes(type)),
    [data, type]
  )

  return { loading, error, taxonomies }
}
