import { useEffect } from 'react'
import { difference, isEmpty } from 'lodash'
import { useEventBus } from '_event-bus'
import { useDispatch, useSelector, useStore } from 'react-redux'
import * as service from './meet-service'
import { setUserStatus, setUserStatuses, setUserStatusPrefetch } from './meet-actions'
import {
  getUserOnlineStatus,
  getUserOnlineStatuses,
  isPrefetchingUserStatus,
} from './meet-reducers'

export const MonitorUserOnlineStatus = () => {
  const eventBus = useEventBus()
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubMethods = []
    unsubMethods.push(
      eventBus.on('USER_ONLINE', ({ subject }) => {
        dispatch(setUserStatus(subject, 'ONLINE'))
      }),
    )

    unsubMethods.push(
      eventBus.on('USER_OFFLINE', ({ subject }) => {
        dispatch(setUserStatus(subject, 'OFFLINE'))
      }),
    )
    return () => unsubMethods.forEach(unsub => unsub())
  }, [eventBus, dispatch])

  return null
}

export const useUserStatus = userid => {
  const dispatch = useDispatch()
  const status = useSelector(getUserOnlineStatus(userid))
  const { user } = useSelector((state) => state)

  const isPrefetching = useSelector(state => {
    if(user?.authorized) {
        return isPrefetchingUserStatus
    }
    return null;
 })

  const hasStatus = !!status
  useEffect(() => {
    if (!isPrefetching && !hasStatus && user?.authorized) {
      service
      .fetchUserStatus(userid)
      .then(res => {
        dispatch(setUserStatus(userid, res.status))
      })
      .catch(e => {
        console.log("User not authorized to fetch user's status", e)
      })
    }
  }, [dispatch, hasStatus, userid, isPrefetching, user])

  return status
}

export const usePrefetchUserStatus = userIds => {
  const dispatch = useDispatch()
  const store = useStore()

  dispatch(setUserStatusPrefetch(true))

  useEffect(() => {
    dispatch(setUserStatusPrefetch(true))
    const fetchedStatuses = Object.keys(getUserOnlineStatuses(userIds)(store.getState()))
    const missingStatuses = difference(userIds, fetchedStatuses)

    if (isEmpty(missingStatuses)) {
      dispatch(setUserStatusPrefetch(false))
      return
    } else {
      service
        .fetchUserStatuses(missingStatuses)
        .then(res => {
          dispatch(setUserStatuses(res))
        })
        .catch(e => {
          console.log("User not authorized to fetch user's status", e)
        })
        .finally(() => dispatch(setUserStatusPrefetch(false)))
    }
  }, [store, dispatch, userIds])
}
