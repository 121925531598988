import React from 'react'
import EventsGridMap from '_events/components/events-grid/events-grid-mapview'

const EventsGridConnected = ({ items: events }) => {
  return (
    <div>
      {events && (
        <EventsGridMap
          events={events.map((e) => {
            return e?.data
          })}
        />
      )}
    </div>
  )
}
export default EventsGridConnected
