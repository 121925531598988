import React from 'react'
import { Col } from 'react-bootstrap'
import styled from '@emotion/styled'
import css from '@emotion/css'
import { tablet } from '_utils/responsive-queries'

const Layout = styled.div`
  background-color: var(--shade);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  ${({ tocView }) =>
    tocView &&
    css`
      position: sticky;
      top: 0;
    `}

  &.left {
    border-bottom-right-radius: 30px;
    @media only screen and (max-width: 1500px) {
      border-bottom-right-radius: 30px;
    }
    ${tablet} {
      border-bottom-right-radius: 30px;
    }
  }
  &.right {
    border-bottom-left-radius: 30px;
    ${tablet} {
      border-bottom-left-radius: 30px;
    }
    ${tablet} {
      border-bottom-left-radius: 30px;
    }
  }
`

const StyledCol = styled(Col)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    margin-top: 10em;
  }
`

const Info = ({ children, position, tocView }) => {
  return (
    <StyledCol md={6} lg={4} xl={4}>
      <Layout className={position} position={position} tocView={tocView}>
        {children}
      </Layout>
    </StyledCol>
  )
}

export default Info
