import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SectionRenderer from '../shared/section-renderer'

import { MainWrapper } from '../styles'
import { useIntl } from 'react-intl'

const SocialMedia = () => {
  const [errors] = useState({})
  const [isOpen, setIsOpen] = useState(true)
  const dispatch = useDispatch()
  const intl = useIntl()
  const { user } = useSelector((state) => state)

  const handleSaveGeneric = (key, value) => {
    const { profile } = user
    profile[key] = value
    saveProfile(profile)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const sectionTitle = intl.formatMessage({
    id: 'user.profile.socialmedia',
  })

  const section = [
    {
      key: 'linkedIn',
      name: intl.formatMessage({
        id: 'user.profile.socialmedia.linkedin',
      }),
      type: 'text',
      placeholder: 'https://www.linkedin.com/',
      onSave: (attr, v) => handleSaveGeneric('linkedIn', v),
    },
    {
      key: 'twitter',
      name: intl.formatMessage({
        id: 'user.profile.socialmedia.twitter',
      }),
      type: 'text',
      placeholder: 'https://www.twitter.com/',
      onSave: (attr, v) => handleSaveGeneric('twitter', v),
    },
    {
      key: 'instagram',
      name: intl.formatMessage({
        id: 'user.profile.socialmedia.instagram',
      }),
      type: 'text',
      placeholder: 'https://www.instagram.com/',
      onSave: (attr, v) => handleSaveGeneric('instagram', v),
    },
  ]

  return (
    <MainWrapper>
      <SectionRenderer
        sectionTitle={sectionTitle}
        section={section || []}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        user={user}
        errors={errors}
      />
    </MainWrapper>
  )
}

export default SocialMedia
