/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { connect, useSelector } from 'react-redux'
import DashboardMessagesConversationSingleMessage from '../dashboard-messages-conversation-single-message'

const GET_USERS = gql`
  query GetUsers($subs: [ID!]!) {
    getUsers(subs: $subs) {
      sub
      firstName
      lastName
      middleName
      avatar
    }
  }
`

const mapStateToProps = ({ user, messages }) => ({ user, messages })

const Index = ({ user, messages: stateMessages, chatRoom }) => {
  let messagesEndRef = useRef(null)

  const chatRoomMessages =
    useSelector((state) => {
      const contextKey = chatRoom && JSON.stringify(chatRoom) + '-messages'
      return chatRoom && state.messages[contextKey]
    }) || []

  useSelector(console.log)

  const messageItems = chatRoom
    ? chatRoomMessages
    : stateMessages?.currentConversationMessages

  const userSubs = Array.from(
    messageItems && new Set(messageItems.map((m) => m.creator))
  )

  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      subs: Array.from(userSubs),
    },
    skip: !messageItems?.length,
  })

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'end',
      inline: 'start',
    })
  }

  useEffect(scrollToBottom, [messageItems, loading])
  if (loading) {
    return <div ref={messagesEndRef} />
  }

  return (
    <div className="messages-conversation-timeline">
      {messageItems.map((m, i) => {
        const currentId = m.creator
        const nextId = messageItems[i + 1]?.creator
        const prevId = messageItems[i - 1]?.creator
        return (
          <DashboardMessagesConversationSingleMessage
            message={m}
            user={user}
            users={data?.getUsers}
            currentId={currentId}
            nextId={nextId}
            prevId={prevId}
          />
        )
      })}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default connect(mapStateToProps)(Index)
