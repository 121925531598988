import { gql } from '@apollo/client'

export const CONNECTED_COMPANIES = gql`
  query GetActivityConnectedCompaniesPaged($id: ID, $paging: PagingInput) {
    getActivityConnectedCompaniesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        # connectedUsers {
        #   sub
        #   id
        #   avatar
        # }
        # connectedCompanies {
        #   id
        #   coverPhoto
        # }
        # connectedActivities {
        #   id
        #   coverPhoto
        # }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECTED_USERS = gql`
  query GetActivityConnectedUsersPaged($id: ID, $paging: PagingInput) {
    getActivityConnectedUsersPaged(id: $id, paging: $paging) {
      offset
      hasMore
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

export const CONNECTED_OPPORTUNITIES = gql`
  query GetActivityConnectedOpportunitiesPaged($id: ID, $paging: PagingInput) {
    getActivityConnectedOpportunitiesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        _createdAt
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
