import styled from '@emotion/styled'

const BasicInput = styled.input`
  margin-right: 15px;
  &.input-field {
    width: 100%;
    display: block;
    position: relative;
    color: var(--foreground);
    border: 1px solid var(--shade);
    background-color: var(--background);
    border-radius: 25px;
    font-weight: 500;
    &::placeholder {
      font-weight: 400;
      color: var(--shade) !important;
    }
  }
  i {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

export default BasicInput
