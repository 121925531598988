import React from 'react'
import CompaniesGrid from '_companies/components/companies-grid'

const CompaniesGridConnected = ({ items: companies }) => {
  return (
    <div>
      {companies && (
        <CompaniesGrid
          companies={companies.map((c) => {
            return {
              company: c?.data,
            }
          })}
        />
      )}
    </div>
  )
}
export default CompaniesGridConnected
