import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { merge } from 'lodash'
import InitialLoading from '_components/initial-loading'
import defaultTheme from './default'
import { useAppConfig } from '_application'
import { tablet, mobile } from '_utils/responsive-queries'
import { colorBus } from '_colorBus'
import { pagebuilderStyles } from "_pagebuilder/style"

import { ctaButton } from '_utils/css-buttons'

import Helvetica100 from "../resources/fonts/helvetica/100.woff"
import Helvetica100w from "../resources/fonts/helvetica/100.woff2"

import Helvetica200 from "../resources/fonts/helvetica/200.woff"
import Helvetica200w from "../resources/fonts/helvetica/200.woff2"

import Helvetica300 from "../resources/fonts/helvetica/300.woff"
import Helvetica300w from "../resources/fonts/helvetica/300.woff2"

import Helvetica400 from "../resources/fonts/helvetica/400.woff"
import Helvetica400w from "../resources/fonts/helvetica/400.woff2"

import Helvetica500 from "../resources/fonts/helvetica/500.woff"
import Helvetica500w from "../resources/fonts/helvetica/500.woff2"

import Helvetica600 from "../resources/fonts/helvetica/600.woff"
import Helvetica600w from "../resources/fonts/helvetica/600.woff2"

import Helvetica700 from "../resources/fonts/helvetica/700.woff"
import Helvetica700w from "../resources/fonts/helvetica/700.woff2"

import Helvetica800 from "../resources/fonts/helvetica/800.woff"
import Helvetica800w from "../resources/fonts/helvetica/800.woff2"

const global = (values) => css`
  body {
    --margin: 50px;
    ${tablet} {
      --margin: 25px;
    }
  }

  body {
    overflow-x: hidden;
    background-color: var(--background);
    &.disable-transitions * {
      transition: none !important;
      animation: none !important;
    }
  }

  html {
    --page: clamp(1250px, 60vw, 1750px);
    //overflow: hidden;
  }

  .page-wrapper, .fdb-block:not(.stretch) {
    max-width: var(--page);
    margin: 0 auto;

    &.stretchColorToScreensize {
      --c: var(--background);
      box-shadow: 0 0 0 100vmax var(--c);
      clip-path: inset(0 -100vmax);
    }
  }

  button, .btn {
    ${ctaButton}
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: var(--background) !important;
  }

  .modal-backdrop {z-index: 1055}
  .sitestructure .footer {
    ${mobile} {
      display: none;
    }
  }
  
  :root {
    --space: 12px;
    --space-xs: calc(var(--space) / 4);
    --space-s: calc(var(--space) / 2);
    --space-m: calc(var(--space) * 2);
    --space-l: calc(var(--space) * 4);
    --space-xl: calc(var(--space) * 6);
  }

  body {
    --radius: 6px;
    --borderSize: solid 1px;
    --border: var(--borderSize) var(--shade);
    --shadowSize: 0 3px 8px 0px;
    --shadow: var(--shadowSize) rgb(0 0 0 / 20%);
    color: var(--foreground);
  }

  .border {
    border-radius: var(--radius);
    outline: var(--border) !important;
  }

  .modal-content {
    background-color: var(--background);
  }

  iframe[name="adapterjs-alert"] {display: none;}
`

//This css object is strictly for non nested CSS.
//This is so that we can define some global CSS that
//can be shared with iframes without issues #blameFroala
const typeStructure = (values) => css`
  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica100w}) format("woff2"),
      url(${Helvetica100}) format("woff");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica200w}) format("woff2"),
      url(${Helvetica200}) format("woff");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica300w}) format("woff2"),
      url(${Helvetica300}) format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica400w}) format("woff2"),
      url(${Helvetica400}) format("woff");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica500w}) format("woff2"),
      url(${Helvetica500}) format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica600w}) format("woff2"),
      url(${Helvetica600}) format("woff");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica700w}) format("woff2"),
      url(${Helvetica700}) format("woff");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: 
    url(${Helvetica800w}) format("woff2"),
      url(${Helvetica800}) format("woff");
    font-weight: 800;
    font-style: normal;
  }

  .lead {
    font-weight: 600 !important; //overwrites froala class
    margin-top: 10px;
  }

  div > span {
    font-size: 1rem;
    font-weight: 400;
  }

  html {
    font-size: clamp(14px,1.2vw,14px) !important;
    color: var(--foreground);
    --font: ${values.fonts.googleFont}, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-family: var(--font) !important;
    background-color: var(--background);
  }

  body {
    font-family: var(--font) !important;
  }

  p,
  button,
  input,
  textarea,
  select {
    font-size: 1rem;
  }

  label, .label {
    font-weight: 700;
    font-size: 14px;
    color: var(--foreground);
  }

  i {font-size: 16px}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--foreground);
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  h1 {
    font-weight: 600;
    font-size: 36px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h4 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.3rem;
  }

  h6 {
    font-size: 1.1rem;
  }
`

const getThemeOverrides = ({ config, themeName }) => {
  const themeOverride = config?.themeOverrides?.filter(
    (t) => t.name === themeName
  )[0]
  if (themeOverride) {
    const obj = { colors: {}, fonts: {} }
    themeOverride.values.map((v) => {
      if (v?.value && v?.value !== '') {
        let value = v.value
        if (v.key === 'flavor') {
          value = value?.split(',') || value
        }
        obj['colors'][v.key] = value
        obj['fonts'][v.key] = value
      }
      return null
    })
    return obj
  }
}

const mergeConfig = ({ theme = 'default', config, loaded }) => {
  const overrides = getThemeOverrides({ themeName: theme, config })
  return merge({}, defaultTheme, loaded?.default, overrides)
}

const fetchValues = (theme, config) => {
  if (theme && theme !== 'default') {
    return import(`$themes$/${theme}`)
      .then((loaded) => mergeConfig({ theme, config, loaded }))
      .catch((e) => {
        console.error('Failed to load theme', theme)
        return defaultTheme
      })
  } else {
    return Promise.resolve(mergeConfig({ config }))
  }
}

export const loadTheme = async (theme, config) => {
  let values = await fetchValues(theme, config)
  const globalCSS = global(values)
  const typeCSS = typeStructure(values)
  values.typeCSS = typeCSS

  colorBus(values?.colors || null)
  return { values, globalCSS, typeCSS }
}

const useThemeHook = (theme = 'default') => {
  const config = useAppConfig()
  const [themeData, setThemeData] = useState()

  useEffect(() => {
    loadTheme(theme, config).then((data) => setThemeData(data))
  }, [theme, config]) 
 
  return themeData
}

export const ThemeLoader = ({ children, theme }) => {
  const themeData = useThemeHook(theme)
  if (themeData) {
    return (
      <ThemeProvider theme={themeData.values}>
        <Global styles={themeData.globalCSS} />
        <Global styles={themeData.typeCSS} />
        <Global styles={pagebuilderStyles()} />
        {children()}
      </ThemeProvider>
    )
  } else {
    return <InitialLoading />
  }
}

ThemeLoader.propTypes = {
  children: PropTypes.func.isRequired,
  theme: PropTypes.string,
}
