import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SectionRenderer from '../shared/section-renderer'
import UserCard from './user-card'

import { MainWrapper } from '../styles'
import { useIntl } from 'react-intl'

import BackgroundImage from './background-image'

const CommonAttributes = () => {
  const [errors, setErrors] = useState({})
  const [isOpen, setIsOpen] = useState(true)

  const dispatch = useDispatch()
  const { user } = useSelector((state) => state)

  const intl = useIntl()

  const handleSaveGeneric = (key, value) => {
    const profile = {
      ...user?.profile,
      [key]: value,
    }
    saveProfile(profile)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const sectionTitle = intl.formatMessage({
    id: 'user.profile.generalinformation',
  })

  const section = [
    {
      key: 'bioShort',
      name: intl.formatMessage({
        id: 'user.profile.generalinformation.status',
      }),
      type: 'text',
      onError: (attr, message) => setErrors({ ...errors, bioShort: message }),
      onSave: (attr, v) => handleSaveGeneric('bioShort', v),
      md: 12,
      maxLength: 40,
    },
    {
      key: 'bioLong',
      name: intl.formatMessage({
        id: 'user.profile.generalinformation.longbio',
      }),
      type: 'wysiwyg',
      editorType: 'bioLong',
      onError: (attr, message) => setErrors({ ...errors, bioLong: message }),
      // to refactor
      onSave: () => {},
      onEditorModelChange: (v) => handleSaveGeneric('bioLong', v),
      md: 12,
    },
  ]

  return (
    <MainWrapper isGeneralInfo={true} className="general-info">
      <BackgroundImage />
      <UserCard />
      <SectionRenderer
        sectionTitle={sectionTitle}
        section={section || []}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        user={user}
        errors={errors}
      />
    </MainWrapper>
  )
}

export default CommonAttributes
