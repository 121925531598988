import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import application from './application/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import jobs from './jobs/reducers'
import admin from './admin/reducers'
import message from './message/reducers'
import meet from '_meet'
import messages from '_messages'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    appConfig: application,
    user,
    menu,
    settings,
    jobs,
    admin,
    message,
    meet: meet.reducer,
    messages: messages.reducer,
  })
