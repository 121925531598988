import * as React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { mobile } from '_utils/responsive-queries'

const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 50px;
  max-width: 1570px;
  @media (max-width: 991px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Description = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-right: 36%;
  font-size: 16px;
  font-weight: 600;
  color: var(--foreground);
  @media (max-width: 1024px) {
    padding-right: 10%;
  }
  @media (max-width: 540px) {
    padding-right: 0;
  }
`

const Header = styled.h1`
  font-size: 36px;
  font-weight: 600;
  color: var(--foreground);

  font-family: ${({ theme }) => theme.fonts.googleFont};
`
const Actions = styled.div`
  display: flex;
  gap: 1rem;
  ${mobile} {
    display: none;
  }
`

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Props = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
}
export const PageTitle = ({ children, actions, blogTitle, description }) => {
  return (
    <HeaderRow>
      <TopContainer>
        <Header isBlogTitle={blogTitle} data-testid="pageTitle">
          {children}
        </Header>
        <Actions>{actions}</Actions>
      </TopContainer>

      <Description isBlogTitle={blogTitle}>{description}</Description>
    </HeaderRow>
  )
}
PageTitle.propTypes = Props

export default PageTitle
