import styled from '@emotion/styled'

export const StatsStyled = styled.div`
  margin-bottom: 50px;
  .stats-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 3px solid var(--flavor);
    padding: 10px;

    .values {
      display: inline-block;
      margin: 10px 0;
      span {
        display: block;
        line-height: normal;
        font-weight: 500;
        &.name {
          margin-top: 4px;
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: var(--flavor);
      svg,
      svg path {
        fill: var(--flavor-contrast);
      }
      img.opportunities-icon {
        margin: 6px 0 0 5px;
      }
    }
  }
`
