import styled from '@emotion/styled'

export const Text = styled.div`
  width: 100%;
  input,
  select,
  textarea {
    border: solid 1px var(--foreground);
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 6px;
    font-size: 14px;
    opacity: 0.8;
    background-color: transparent;
    font-family: ${(props) => props.theme.fonts.googleFont} !important;
    color: var(--foreground) !important;
    resize: none;
    &::placeholder {
      font-weight: 300;
      opacity: 1;
      color: var(--foreground) !important;
    }
  }
  .asterix {
    display: inline-block !important;
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  .optional {
    margin-left: 5px;
    font-weight: 400 !important;
    font-style: italic !important;
  }
  select {
    border: none;
  }
`

export default Text
