import gql from 'graphql-tag'

export const UPDATE_LOGO = gql`
  mutation updateLogoOverrides($logo: LogoOverride, $type: String) {
    updateLogoOverrides(logo: $logo, type: $type) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`
