import styled from '@emotion/styled'

export const KeywordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding: 25px 0 50px;
  width: 800px;
`

export const SelectorWrapper = styled.div`
  width: 410px;
`

export const KeywordTitle = styled.h1`
  font-weight: 700;
  font-size: 15px;
`

export const KeywordDescription = styled.p`
  font-size: 14px;
`
