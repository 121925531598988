import React from 'react'
import { useAppConfig } from '_application'
import { UPDATE_LABEL } from './graph-queries'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import Input from 'components/indy/shared/ui-kit/inputs/text'
import { SubSection } from '../../shared/styles/sections'

const LogoShared = ({ type, title, description, limit, isMultiline }) => {
  const config = useAppConfig()
  const intl = useIntl()
  const override = config?.labelOverrides?.[type]?.value || ''

  const [updateLabel] = useMutation(UPDATE_LABEL)

  const handleUpdateLabel = (v) => {
    updateLabel({
      variables: { type, label: { key: type, value: v } },
    })
  }
  return (
    <SubSection>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <Input
        input={{
          placeholder: `${intl.formatMessage({
            id: 'labeloverride.input.placeholder',
          })} ${title}`,
          value: override,
          maxLength: limit,
          isMultiline,
        }}
        onBlurCallback={(v) => handleUpdateLabel(v)}
        limit={limit}
      />
    </SubSection>
  )
}

export default LogoShared
