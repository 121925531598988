import gql from 'graphql-tag'

export const GET_TAXONOMY = gql`
  query GetTaxonomy($key: String, $usedBy: [String]) {
    getTaxonomy(key: $key, usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      isSearchable
      isMatchable
      treeData {
        key: id
        refId
        name
        parent
      }
    }
  }
`

export const ADD_TAXONOMY = gql`
  mutation AddTaxonomy(
    $name: String
    $description: String
    $isSearchable: Boolean
    $isMatchable: Boolean
    $treeData: [TreeDataInput]
  ) {
    addTaxonomy(
      name: $name
      description: $description
      isSearchable: $isSearchable
      isMatchable: $isMatchable
      treeData: $treeData
    ) {
      key
    }
  }
`

export const UPDATE_TAXONOMY = gql`
  mutation UpdateTaxonomy(
    $key: String
    $usedBy: [String]
    $name: String
    $description: String
    $isSearchable: Boolean
    $isMatchable: Boolean
    $treeData: [TreeDataInput]
  ) {
    updateTaxonomy(
      key: $key
      usedBy: $usedBy
      name: $name
      description: $description
      isSearchable: $isSearchable
      isMatchable: $isMatchable
      treeData: $treeData
    ) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      treeData {
        id
        name
        parent
        refId
      }
    }
  }
`

export const DELETE_TAXONOMY = gql`
  mutation DeleteTaxonomy($key: String) {
    deleteTaxonomy(key: $key) {
      name
    }
  }
`
