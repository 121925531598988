import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Categories from './categories'
import Access from './access'
import Subscription from './subscription'
import Labels from './labels'

const defaultManageDataRoute = '/site-structure'
const defaultRoute = `/manage${defaultManageDataRoute}/blog`

const Routes = () => {
  return (
    <Switch>
      <Route
        path={`${defaultRoute}/categories`}
        render={() => <Categories />}
      />
      <Route path={`${defaultRoute}/access`} render={() => <Access />} />
      <Route
        path={`${defaultRoute}/subscription`}
        render={() => <Subscription />}
      />
      <Route path={`${defaultRoute}/labels`} render={() => <Labels />} />
      <Redirect from={defaultRoute} to={`${defaultRoute}/categories`} />
    </Switch>
  )
}

export default Routes
