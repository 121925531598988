import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { DashboardGridTemp } from '_components/dashboard/dashboard-grid'
import { Grid, BaseTile } from '_tiles'
import { Default as CompanyTile } from './tiles'
import { filterToQueryString } from '_utils/filters'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 4,
  xl: 4,
  lg: 6,
  md: 6,
  sm: 12,
}

const CompanyGrid = ({ companies, filter }) => {
  const queryString = filterToQueryString(filter)
  return (
    <DashboardGridTemp>
      <Grid config={gridConfig} testid="companies">
        {map(companies, ({ company, connectButton, isOwner, i }) => (
          <BaseTile
            key={i}
            config={defaultConfig}
            url={`/companies/list/${company.id}`}
            queryParams={queryString}
            testid="company"
          >
            <CompanyTile
              company={company}
              connectButton={connectButton}
              isOwner={isOwner}
            />
          </BaseTile>
        ))}
      </Grid>
    </DashboardGridTemp>
  )
}

CompanyGrid.propTypes = {
  companies: PropTypes.array.isRequired,
}

export default CompanyGrid
