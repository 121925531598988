import React from 'react'
import styled from '@emotion/styled'
import DashboardNotificationsType from '../dashboard-notifications-single-type'

const NotificationTypesListContainer = styled.div`
  margin-top: 40px;
`

const Index = ({ types, activeView, setActiveView }) => {
  return (
    <NotificationTypesListContainer data-testid="notification-types-list">
      {types.map((type) => {
        return (
          <DashboardNotificationsType
            type={type}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        )
      })}
    </NotificationTypesListContainer>
  )
}

export default Index
