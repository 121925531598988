export const defaultScheme = {
  background: 'pink',
  foreground: 'blue',
  flavor: ['lightgreen'],
  
  generated: null,
  readability: 2,

  subSchemes: {},
}

//converts legacy indyriot color scheme to new colorbus scheme
export const convertIndyColors = (colors) => {
  const { background, foreground, flavor } = colors
  return {
    ...defaultScheme,

    background: background ? background : defaultScheme.background,
    foreground: foreground ? foreground : defaultScheme.foreground,
    flavor: flavor && flavor.length ? flavor : defaultScheme.flavor,

    subSchemes: {
      navigation: {
        background: colors.navigationColor,
      },
      footer: {
        background: colors.background,
      },
      blog: {
        background: colors.backgroundColor,
      },
      tile: {
        background: colors.cardColor,
      },
      admin: {
        background: 'white',
        foreground: 'black',
      },
      messages: {
        flavor: 'blue',
      },
    },
  }
}
