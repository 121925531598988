import styled from '@emotion/styled'

export const MediaUploadStyled = styled.div`
  button {
    font-size: 14px;
    padding: 10px 10px;
    border: 1px solid var(--flavor);
    color: var(--flavor);
    background: transparent;
    border-radius: 20px;
    &.submit {
      color: var(--background);
      background: var(--flavor);
    }
    &.disabled {
      background: var(--shade);
      color: var(--foreground);
      border: 1px solid transparent;
    }
  }
`
