import styled from '@emotion/styled'
import { Modal } from '@patternfly/react-core'
import scroll from '_utils/css-scroll'

export const ExpandAdvancedButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  i {
    margin-left: 10px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
  }
  &:hover {
    span {
      opacity: 0.6;
    }
  }
`

export const ModalStyled = styled(Modal)`
  background-color: var(--background);
  color: var(--foreground);
  padding: 0 30px;
  .pf-c-switch__label {
    color: var(--foreground) !important;
  }
  .pf-c-modal-box__footer {
    display: flex;
    flex-direction: row-reverse;
  }
  .pf-c-select__menu {
    max-height: 250px;
    overflow-y: auto;
  }
  .pf-c-modal-box__body {
    padding-bottom: 40px;
  }
  ul.pf-c-select__menu {
    ${scroll()}
    button {
      overflow: hidden;
      white-space: break-spaces;
    }
  }
`

export const BottomSectionWrapper = styled.div``
export const BottomSectionHeader = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`
export const SinglePoperty = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
  .change-icon-button {
    margin-top: 5px;
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
    cursor: default;
  }
  .pf-c-select {
    width: auto;
    margin-bottom: 10px;
    flex-grow: 1;
  }
`
export const PropertyName = styled.span`
  font-weight: 500;
  padding-right: 10px;
`
