import { pick } from 'lodash'
import actions from './meet-actions'

const initialState = {
  currentCall: null,
  userStatus: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actions.SET_CURRENT_CALL:
      return {
        ...state,
        currentCall: payload,
      }
    case actions.SET_USER_STATUS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          [payload.userId]: payload.status,
        },
      }
    case actions.SET_USER_STATUSES:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          ...payload,
        },
      }
    case actions.SET_USER_STATUS_PREFETCH:
      return {
        ...state,
        userStatusPrefetch: payload,
      }
    default:
      return state
  }
}

export const selectCurrentCall = (state) => state.meet.currentCall
export const getUserOnlineStatus = (userId) => (state) =>
  state.meet.userStatus[userId]?.status
    ? state.meet.userStatus[userId].status
    : state.meet.userStatus[userId]
export const getUserOnlineStatuses = (userIds) => (state) =>
  pick(state.meet.userStatus, userIds)
export const isPrefetchingUserStatus = (state) => state.meet.userStatusPrefetch
