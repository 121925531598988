import React, { useEffect, useState } from 'react'
import { useSearch } from '_search/search-hooks'
import Search from './search'

import {
  MainSectionWrapper,
  SectionHeader,
  Description,
} from '_events/components/event-creator/components/styles/section-with-search'

import ConnectedList from './connected-list'

import useConnectionBuilder from './connection-builder'
import { useIntl } from 'react-intl'
import BreakLine from '../break-line'

const EntityConnection = ({
  title,
  description,
  id,
  entityType,
  entityName,
  connectionType,
  connections
}) => {
  const [query, setQuery] = useState('')
  const [connectionList, setConnectionList] = useState()
  const [getSearchResults, { loading, data }] = useSearch(query, {
    limit: 5,
    type: entityType
  })

  const intl = useIntl()

  const results = data?.search?.data

  const connectionBuilder = useConnectionBuilder(connectionType, id)

  const { connect, disconnect, getConnectVariables, getDisconnectVariables } =
    connectionBuilder

  const handleSelect = (item) => {
    const searchItem = { ...item, id: item?.sanityId || item._id }
    const isAlreadyConnected = connectionList.some(
      (c) => c.id === searchItem?.id
    )

    if (!isAlreadyConnected) {
      connect({
        variables: getConnectVariables(searchItem),
      }).then(r => {
        const status = r?.data?.[Object.keys(r?.data)[0]]?.status
        if (status === "accepted" || status === "requested") {
          setConnectionList([...connectionList, { ...searchItem, connectionStatus: status }])
          setQuery()
        }
      }).catch(e => console.log("Cannot connect"))
    }
  }

  const handleRemove = (item) => {
    const isAlreadyDisconnected = connectionList.every((c) => c.id !== item?.id)

    if (!isAlreadyDisconnected) {
      disconnect({
        variables: getDisconnectVariables(item),
      })
        .then((r) => {
          const status = r?.data?.[Object.keys(r?.data)[0]]?.status
          if (status === 'cancelled') {
            setConnectionList(connectionList.filter((c) => c?.id !== item?.id))
          }
        })
        .catch((e) => console.log('Cannot disconnect'))
    }
  }

  useEffect(() => setConnectionList(connections || []), [])

  return (
    <>
      <MainSectionWrapper>
        <SectionHeader>{title}</SectionHeader>
        <Description>{description}</Description>
        <Search
          searchState={{ query, setQuery }}
          loading={loading}
          placeholder={`${intl.formatMessage({
            id: 'contentstudio.additional.entityconnections.placeholder',
            defaultMessage: 'Search for',
          })} ${entityName || entityType}`}
          handleSelect={handleSelect}
          results={results}
          entityType={entityType}
        />
        <ConnectedList
          connectionList={connectionList}
          handleRemove={handleRemove}
          entityType={entityType}
          connectionType={connectionType}
        />
      </MainSectionWrapper>

      <BreakLine />
    </>
  )
}

export default EntityConnection

