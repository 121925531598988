import React, { useState } from 'react'

import { MainSection } from '../shared/styles/sections'
import { Row, Col } from 'react-bootstrap'

import Editor from './editor'
import ThemeSelector from './theme-selector'
import { useAppConfig } from '_application'

import { useIntl } from 'react-intl'

const Index = () => {
  const intl = useIntl()
  const config = useAppConfig()

  const [themeOverride, setThemeOverride] = useState(
    config?.themeOverrides?.filter((obj) => obj.name === config.theme)[0] || {
      name: config.theme || 'default',
    }
  )
  const [themeName, setThemeName] = useState(config.theme || 'default')

  return (
    <MainSection>
      <span className="title">
        {intl.formatMessage({ id: 'admin.branding.themes.themestitle' })}
      </span>
      <Row>
        <Col sm={12} md={6}>
          <ThemeSelector
            themeName={themeName}
            theme={themeOverride}
            onChange={(override) => setThemeOverride(override)}
          />
          {themeOverride && (
            <Editor
              setThemeName={setThemeName}
              themeName={themeName}
              themeOverride={themeOverride}
              setThemeOverride={setThemeOverride}
            />
          )}
        </Col>
      </Row>
    </MainSection>
  )
}

export default Index
