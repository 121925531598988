import React, { useEffect, useState, useCallback } from 'react'
import { useQuery } from '_utils/router'
import { useIntl, FormattedMessage } from 'react-intl'
import { Loader, Loading } from '_components/loading'
import styled from '@emotion/styled'
import { RowCenterAll } from '_components/containers'
import { H2 } from '_uikit/headers'
import { gql, useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import SearchInput from '../components/search-input'
import SearchResults from '../components/search-results'
import { Container } from '../../_styled-components/shared/section-container'

const SearchInputStyled = styled(SearchInput)`
  position: relative;
  padding: 0.5em 1em;

  max-width: 770px;
  margin: 0 auto;

  input {
    color: var(--foreground);
    &::placeholder {
      color: var(--foreground) !important;
      opacity: 0.5;
    }
  }
`
const Title = styled(H2)`
  margin-bottom: 10px;
`
const LoadingIndicator = styled(Loading)`
  margin: 50px 0;
`

const GROUPED_SEARCH_QUERY = gql`
  query GroupedSearch($searchInput: SearchInputGrouped) {
    groupedSearch(searchInput: $searchInput)
    { data {
      _id,
      total
      data {
        _id
        body
        type
        title
        excerpt
        creator {
          _id
          avatar
          name
          firstName
          lastName
          location {
            city
            country
          }
        }
        coverPhoto
        score
      }
    } },
  }
`

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 600;
  padding: 40px 20px;
`


const SearchResultsConnected = ({
  className,
  pageHeight,
}) => {
  const intl = useIntl()
  const [query, setQuery] = useQuery('q')
  const [isQueryValid, setIsQueryValid] = useState(false)
  const [getSearchResults, { data, loading }] = useLazyQuery(GROUPED_SEARCH_QUERY)
  const debouncer = useCallback(debounce(getSearchResults, 500), []);

  const handleChange = value => {
    const valueEntered = !!value
    const isValid = valueEntered && value.length > 2
    setQuery(value)
    setIsQueryValid(isValid)

    if (isQueryValid) {
      debouncer({ variables: { searchInput: { query: value, paging: { limit: 50 } } } })
    }
  }

  useEffect(() => {
    debouncer({ variables: { searchInput: { query: query, paging: { limit: 50 } } } })
  }, [debouncer, query])

  return (
    <Container
      pageHeight={pageHeight}
      className={`page-wrapper`}
    >
      <div className={className}>
        <RowCenterAll>
          <Title>
            <FormattedMessage id="search.title" />
          </Title>
        </RowCenterAll>
        <SearchInputStyled
          query={query}
          placeholder={intl.formatMessage({ id: 'search.placeholder' })}
          onChange={e => handleChange(e.target.value)}
          loading={loading}
          autoFocus
          testid="search"
        />
        <Loader
          loading={loading}
          placeholder={<LoadingIndicator />}
        >
          {() => (data?.groupedSearch?.data?.length > 0 && (
            <SearchResults
              groups={data?.groupedSearch?.data}
            />
          )
          )}
        </Loader>
        {data?.groupedSearch?.data?.length === 0 &&
        <NoResults>
          <FormattedMessage id="search.noresults" />
        </NoResults>
        }
      </div>
    </Container>
  )
}

export default SearchResultsConnected
