import React from 'react'
import Toggle from 'react-toggle'
import { useIntl } from 'react-intl'
import ToggleButtonWithNameStyled from './toggle-button-with-name-styled'
import 'react-toggle/style.css'

const ToggleButtonAlt = ({
  title,
  description,
  statusText,
  checked,
  onChange,
  className,
  testid,
  size,
  isMapToggle = false,
}) => {
  const intl = useIntl()

  const statusLabel = intl.formatMessage({
    id: `global.${checked ? 'active' : 'off'}`,
  })

  return (
    <ToggleButtonWithNameStyled
      className={`toggle-with-name-container ${
        size && size === 'lg' ? 'large' : ''
      } ${className}`}
    >
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <span className="status">
        {`${statusText} `}
        <strong>{statusLabel}</strong>
      </span>
      <Toggle
        icons={{ checked: null, unchecked: null }}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="toggle-alt"
      />
    </ToggleButtonWithNameStyled>
  )
}

export default ToggleButtonAlt
