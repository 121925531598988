import gql from 'graphql-tag'

export const UPDATE_THEME_OVERRIDES = gql`
  mutation UpdateThemeOverrides(
    $themeOverride: ThemeOverrideInput
    $themeName: String
  ) {
    updateThemeOverrides(themeOverride: $themeOverride, themeName: $themeName) {
      themeOverrides {
        name
        values {
          key
          value
        }
      }
    }
  }
`

export const SET_THEME = gql`
  mutation SetTheme($theme: String) {
    setTheme(theme: $theme) {
      themeOverrides {
        name
        values {
          key
          value
        }
      }
    }
  }
`
