import React from 'react'
import { connect } from 'react-redux'

import Main from './main'

import ProfileStyled from '../styles/profile-preview'

const mapStateToProps = ({ user }) => ({ user })

const ProfilePreview = ({ user }) => {
  if (!user?.profile) return null
  return (
    <ProfileStyled>
      <Main user={user} />
    </ProfileStyled>
  )
}

export default connect(mapStateToProps)(ProfilePreview)
