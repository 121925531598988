import styled from '@emotion/styled'

export const ImageUploadStyled = styled.div`
  .wrapper {
    position: relative;
    &:hover {
      label,
      input,
      img,
      video,
      .far.fa-image,
      .fas.fa-video {
        opacity: 0.7;
      }
      i.far.fa-image,
      i.fas.fa-video {
        opacity: ${({ isImgUploaded }) => isImgUploaded && '0'};
      }
    }
  }

  i.far.fa-image,
  i.fas.fa-video {
    opacity: ${({ isImgUploaded }) => isImgUploaded && '0'};
  }

  .size-l {
    width: 220px;
    height: 150px;
    img,
    label {
      width: 220px;
      height: 150px;
    }
    i {
      font-size: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--foreground);
    }
  }

  .ant-notification {
    z-index: 9999 !important;
  }

  &.image-upload-container {
    input {
      display: none;
    }
    .image-placeholder {
      display: inline-block;
      color: rgba(0, 0, 0, 0.5);
      border: 1px solid var(--foreground);
      padding: 12px;
      position: relative;
      border-radius: 6px;
      cursor: pointer;
      &.avatar {
        width: 120px;
        height: 120px;
      }
      &.cover {
        width: 160px;
        height: 90px;
      }

      &.uploaded-image {
        border: none;
      }
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
      i {
        font-size: 40px;
        margin: 0 auto;
        color: var(--foreground);
        &.fa-times {
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: var(--foreground);
        }
      }
      .span {
        text-align: center;
        display: block;
        line-height: 18px;
        margin-top: 10px;
      }
      label {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }
`

export const Revert = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 3;

  width: 20px;
  height: 20px;
  background-color: var(--background);
  border-radius: 100%;
  outline: var(--border);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 17px 1px;

  transition: .2s;

  i.fas.fa-times {
    color: var(--foreground);
    font-size: 10px;
    transition: 0.4s;
  }

  &:hover {
    outline: solid 2px var(--foreground);
  }
`
