import styled from '@emotion/styled'

import { mobile } from '_utils/responsive-queries'
import { ctaButton, circleButton } from '_utils/css-buttons'

export const EntityEditButtonStyled = styled.div`
  ${mobile} {
    display: none;
  }

  button {
    color: var(--foreground);
    background: var(--background);
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    i {
      font-size: 14px;
      margin: 0;
    }
    &:hover {
      opacity: 0.9;
    }
  }
`

export const Button = styled.button`
  --stroke-color: var(--imgColor);
  p {
    margin: 0px;
  }

  &.ctaButton:not(.pen) {
    ${ctaButton}
    font-weight: 700;
  }

  display: block;
  margin: 0 auto;

  padding: 6px 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--flavor);

  font-weight: 700;

  i {
    margin-right: 5px;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.inactive};
  }

  &.pen {
    ${circleButton};
    color: var(--stroke-color) !important;
    border: 2px solid var(--stroke-color) !important;
    background-color: transparent !important;
    padding: 0 !important;
    i {
      margin: 0 !important;
    }
  }
`