import React from 'react'
import { Link } from 'react-router-dom'
import { useAppLabel } from '_application'
import {
  MainWrapper,
  SpeakersWrapper,
  SingleSpeaker,
  Avatar,
  Name,
  Title,
  Avatars,
} from './styles/speakers'

const dataTypes = [
  'speakers',
  'hosts',
  // { type: 'speakers', key: 'speakers' },
  // { type: 'hosts', key: 'hosts' },
]

const Speakers = ({ event, isPreview }) => {
  const appLabel = useAppLabel()

  const dataByType = (type) => (event ? event[type] : [])

  return (
    <>
      {dataTypes.map((obj) => {
        return dataByType(obj)?.length > 0 ? (
          <MainWrapper>
            <SpeakersWrapper>
              <Title>
                {appLabel(
                  `${obj}Plural`,
                  `events.createevent.preview.speakers.${obj}`
                )}
                :
              </Title>
              <Avatars>
                {event?.[obj].map((person) => (
                  <SingleSpeaker>
                    <Link
                      className={`${isPreview ? 'disabled-link' : ''}`}
                      to={`/people/list/${person.sub}`}
                    >
                      <Avatar src={person.avatar || ''} />
                    </Link>

                    <Name>{person.name}</Name>
                  </SingleSpeaker>
                ))}
              </Avatars>
            </SpeakersWrapper>
          </MainWrapper>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default Speakers
