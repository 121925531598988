import styled from '@emotion/styled'
import { tablet, pablet, mobile } from '_utils/responsive-queries'
import ToggleButton from '_uikit/toggle-button'
import { ctaButton, taggButton, ctaButtonHollow } from '_utils/css-buttons'
import Button from '_components/button'

// Categories
const Wrapper = styled.div`
  max-width: 1570px;
  margin: 0 auto;
`

const ButtonsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  display: flex;
  ${pablet} {
    padding: 0px;
    display: block;
  }
`

const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 100%;
  ${pablet} {
    margin-top: 20px;
  }

  ${mobile} {
    display: none;
  }
`

const Category = styled(ToggleButton)`
  font-size: 14px;

  margin-bottom: 16px;
  margin-right: 15px;

  ${taggButton}
`

const LoadButton = styled(Category)`
  i {
    padding-left: 15px;
  }
`

const DiscoverButtonWrapper = styled.div`
  flex-basis: 30%;
  margin-right: 15px;
  ${pablet} {
    flex-basis: unset !important;
  }
`

const DiscoverAllButton = styled(Button)`
  ${ctaButton}

  float: left;
  width: 100%;
  max-width: 250px;

  ${tablet} {
    flex-shrink: 0;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 30px;
  }

  @media (max-width: 991px) {
    float: none;
    display: block;
    width: 100%;
  }
`

// Fetch more
const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

export default {
  Wrapper,
  ButtonsContainer,
  TagsContainer,
  Category,
  LoadButton,
  DiscoverButtonWrapper,
  DiscoverAllButton,
  LoadMoreContainer,
  LoadMoreButton,
}
