import styled from '@emotion/styled'

export const SearchGrid = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: start;
`

export default SearchGrid
