/* eslint-disable prefer-destructuring */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import axios from 'axios'
import { notification } from 'antd'
// import store from 'store'
import { history } from 'index'
import * as jwt from 'services/jwt'
import store from 'store'
import JwtDecode from 'jwt-decode'
import { IKUIUserAPI } from '@indykiteone/jarvis-sdk-web'
import actions from './actions'
import notLoggedAvatar from '../../resources/not-logged-avatar.jpg'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* SET_USER_CUSTOM_PROFILE_VALUES({ payload }) {
  let values = ''
  if (payload.profile) {
    Object.keys(payload.profile.custom).map((k) => {
      values += `${k}: ${payload.profile.custom[k]},`
      return false
    })
    const UPDATE_USER = `
     query {
       updateUserCustomProfile(email: "maloki@gmail.com", custom: {${values.substring(
         0,
         values.length - 1
       )}}){
         firstName,
         lastName,
         email
       }
     }
   `
    axios({
      url: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
      method: 'post',
      data: { query: UPDATE_USER },
      headers: { authorization: store.get('RIOT/token') },
    })
    yield put({
      type: 'user/SET_STATE',
      payload,
    })
  }
}

export function* SET_USER_PROFILE_VALUES({ payload }) {

  const { profile } = payload
  const location = {
    ...profile.location,
    lat: `${profile.location?.lat}`,
    long: `${profile.location?.long}`,
  }

  const experienceArray = Object.values(profile.experience)
  const experience = experienceArray.map((experience) => {
    return {
      ...experience,
      showOnMinature: experience.showOnMinature || false,
    }
  })
  const competences = Object.values(profile.competences)
  const education = Object.values(profile.education)
  const customAttributes = Object.values(profile.customAttributes)

  const UPDATE_USER = `
  mutation ($firstName: String, $lastName: String, $middleName: String, $email: String, $location: LocationInput, $birthDate: String, $gender: String, $avatar: String, $coverPhoto: String, $bioShort: String, $bioLong: String, $instagram: String, $linkedIn: String, $twitter: String, $phone: String, $languagesSpoken: [String], $languagesWritten: [String], $competences: [CompetenceInput], $experience: [ExperienceInput], $education: [EducationInput], $industryAreas: [String], $termsAndConditionsConsentDate: String, $customAttributes: [CustomAttributeInput], $keywords: [UserKeywordInput], $taxonomies: [UserTaxonomyInput]) {
    updateUserProfile(
      firstName: $firstName,
      lastName: $lastName,
      middleName: $middleName,
      email: $email,
      location: $location,
      birthDate: $birthDate,
      gender: $gender,
      avatar: $avatar,
      coverPhoto: $coverPhoto,
      bioShort: $bioShort,
      bioLong: $bioLong,
      phone: $phone,
      instagram: $instagram,
      linkedIn: $linkedIn,
      twitter: $twitter,
      languagesSpoken: $languagesSpoken,
      languagesWritten: $languagesWritten,
      competences: $competences,
      experience: $experience,
      education: $education,
      industryAreas: $industryAreas,
      termsAndConditionsConsentDate: $termsAndConditionsConsentDate,
      customAttributes: $customAttributes,
      keywords: $keywords,
      taxonomies: $taxonomies
    ) {
      firstName,
      lastName,
      email
    }
  }
  `

  axios({
    url: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
    method: 'post',
    data: { query: UPDATE_USER, variables : { ...payload?.profile, location, experience, competences, education, customAttributes} },
    headers: { authorization: `${store.get('RIOT/token')}` },
  })
  yield put({
    type: 'user/SET_STATE',
    payload: { ...payload, profile: jwt.mapUserAccount(payload.profile) },
  })
}

export function* LOGIN({ payload }) {
  const { token } = payload
  //TODO MAY BE NEEDED
  /* yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })*/
  const { authProvider: autProviderName } = yield select(
    (state) => state.settings
  )
  const result = yield call(mapAuthProviders[autProviderName].login, token)
  if (result.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push(result.redirect ? '/user/onboarding' : '/')
  }
  if (!result || !result.success) {
    //TODO MAY BE NEEDED
    /* yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })*/
  }
}

export function* REGISTER({ payload }) {
  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, payload)
  if (success) {
    yield call(mapAuthProviders[authProvider].login, payload.token)
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield history.push('/user/onboarding')
    notification.success({
      message: payload.intl.formatMessage({ id: 'toast.registration.message' }),
      description: payload.intl.formatMessage({
        id: 'toast.registration.description',
      }),
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      loaded: true
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const {
      id,
      email,
      emails,
      firstName,
      lastName,
      middleName,
      avatar,
      company,
      fullName,
      forumId,
      location,
      birthDate,
      gender,
      bioShort,
      bioLong,
      coverPhoto,
      twitter,
      instagram,
      linkedIn,
      role,
      languagesSpoken,
      languagesWritten,
      phone,
      competences,
      experience,
      education,
      industryAreas,
      termsAndConditionsConsentDate,
      dateRegistered,
      customAttributes,
      keywords,
      taxonomies,
      notifications,
      connectedUsers,
      createdCompanies,
      createdActivities,
      connectedActivities,
      connectedCompanies,
      availableActivities,
      availableCompanies,
      activeSubscriptions,
      paymentInfo,
    } = response
    const token = store.get('RIOT/token')
    let sub = ''
    try {
      sub = JwtDecode(token).sub
    } catch (e) {
      console.log(e)
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profile: {
          id: id || 'temp_id',
          firstName: firstName || '',
          lastName: lastName || '',
          middleName:
            middleName === 'undefined' || middleName === 'null'
              ? ''
              : middleName || '',
          fullName: fullName || '',
          forumId: forumId,
          email: email || '',
          emails: emails || [],
          avatar: avatar || notLoggedAvatar,
          company: company || '',
          coverPhoto: coverPhoto || null,
          location: location || null,
          birthDate: birthDate || '',
          gender: gender || '',
          bioShort: bioShort || '',
          bioLong: bioLong || '',
          phone: phone || '',
          twitter: twitter || '',
          instagram: instagram || '',
          linkedIn: linkedIn || '',
          role: role || 'user',
          languagesSpoken: languagesSpoken || [],
          languagesWritten: languagesWritten || [],
          competences: competences || [],
          experience: experience || [],
          education: education || [],
          industryAreas: industryAreas || [],
          custom: response.custom,
          termsAndConditionsConsentDate: termsAndConditionsConsentDate || null,
          dateRegistered: dateRegistered || null,
          customAttributes: customAttributes || [],
          keywords: keywords || [],
          taxonomies: taxonomies || [],
          notifications: notifications || [],
          createdActivities,
          createdCompanies,
          connectedUsers,
          connectedActivities,
          connectedCompanies,
          availableActivities,
          availableCompanies,
          paymentInfo: paymentInfo,
          activeSubscriptions: activeSubscriptions || [],
        },
        authorized: true,
        sub,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      loaded: true
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select((state) => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      authorized: false,
      loading: false,
    },
  })
  IKUIUserAPI.logoutUser()
    .then((result) => {
      /* clear RIOT storage */
      localStorage.removeItem('RIOT/token')
      localStorage.removeItem('accessToken')

      window.location.href = '/'
    })
    .catch((err) => console.error(err))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(
      actions.SET_USER_CUSTOM_PROFILE_VALUES,
      SET_USER_CUSTOM_PROFILE_VALUES
    ),
    takeEvery(actions.SET_USER_PROFILE_VALUES, SET_USER_PROFILE_VALUES),
  ])
}
