import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useEventBus } from '_event-bus'
import * as components from './notification-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  top: 75px;
  z-index: 99999999;
`

const NOTIFICATION_COMPONENTS = {
  NEW_INSTANT_CALL: components.InstantCall,
}

const NOTIFICATION_HANDLERS = {
  NEW_INSTANT_CALL: (events, event, payload) => [{ event, payload }, ...events],
  MEETING_CLOSED: (events, event, payload) =>
    events.filter(
      (e) => e.event !== 'NEW_INSTANT_CALL' || e.payload?.id !== payload?.id
    ),
  MESSAGE_RECEIVED: (events, event, payload) => [{ event, payload }, ...events],
}

export const Notifications = () => {
  const eventBus = useEventBus()
  const [events, setEvents] = useState([])

  useEffect(
    () =>
      eventBus.onAll((event, payload) => {
        const handler = NOTIFICATION_HANDLERS[event]
        if (handler) {
          setEvents(handler(events, event, payload))
        }
      }),
    [eventBus, events]
  )

  const removeNotification = (event) => {
    setEvents(events.filter((e) => e !== event))
  }

  return (
    <Container>
      {events.map((event) => {
        const Component = NOTIFICATION_COMPONENTS[event.event]
        if (Component) {
          return (
            <Component
              key={event.event + event.payload?.id}
              clear={() => removeNotification(event)}
              event={event.event}
              payload={event.payload}
            />
          )
        } else {
          return null
        }
      })}
    </Container>
  )
}

export default Notifications
