import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import arrayMove from 'array-move'

import { Row, Col } from 'react-bootstrap'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import styled from '@emotion/styled'
import css from '@emotion/css'
import { useMediaUploader } from 'components/indy/shared/media-uploader'
import { MbToB } from 'services/file'

const Creator = styled.div`
  overflow-x: hidden;
  .fas {
    &.fa-plus:hover {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
    &.fa-times:hover {
      color: ${({ theme }) => theme.colors.alertColor};
    }
    &.fa-bars {
      padding-left: 10px;
    }
  }
`

const Header = styled.div`
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  button {
    background: transparent;
    border: none;
    i {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

const Content = styled.div`
  margin-top: 10px;
  .sortableHelper {
    z-index: 10000 !important;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    .remove-badge {
      background: transparent;
      border: none;
    }

    .badge-item-row {
      padding: 4px 0;
    }

    .badge-name-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .badge-name-input {
      color: var(--foreground) !important;
    }

    .badge-char-limit-warning {
      font-size: 12px;
    }
  }
`

export const UploadBtn = styled.button`
  width: 34px;
  height: 34px;
  border: none;
  background: transparent;
  padding: 0;
  :hover {
    opacity: 0.5;
  }
  ${({ imgUrl }) =>
    imgUrl &&
    css`
      background-image: url(${imgUrl});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;

      i {
        display: none;
      }
    `}

  .image-placeholder {
    padding: 3px;
    i {
      font-size: 20px;
    }
  }
`

const BadgeCreator = ({ onChange, defaultBadges }) => {
  const [badges, setBadges] = useState(defaultBadges || [])
  const [selectedBadge, setSelectedBadge] = useState(null)
  const [editInput, setEditInput] = useState({})

  const uploadConfig = {
    folderName: 'badges',
    tagsConfig: {
      context: 'admin-badge',
    },
    maxImageFileSize: MbToB(1),
    maxImageWidth: 2000,
    allowedFormats: ['jpg', 'png', 'svg', 'webp'],
  }

  const handleAddBadge = () => {
    const newBadge = {
      key: uuid().split('-').join(''),
      name: 'New badge',
    }
    const newBadges = [...badges, newBadge]
    setBadges(newBadges)
    handleOnChange(newBadges)
  }

  const handleUpdateBadge = (badge) => {
    const newBadges = badges.map((b) => (b.key === badge.key ? badge : b))
    setBadges(newBadges)
    handleOnChange(newBadges)
  }

  const handleRemoveBadge = (badgeKey) => {
    const newBadges = badges.filter((b) => b.key !== badgeKey)
    setBadges(newBadges)
    handleOnChange(newBadges)
  }

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    const newBadges = arrayMove(badges, oldIndex, newIndex)
    setBadges(newBadges)
    handleOnChange(newBadges)
  }

  const handleInputOnBlur = (badge) => {
    if (editInput?.value?.trimStart().trimEnd() !== '')
      handleUpdateBadge({ ...badge, name: editInput.value })
    setEditInput({})
  }

  const handleOnChange = (newList) => {
    onChange(newList)
  }

  const { showWidget } = useMediaUploader(uploadConfig, (media) => {
    selectedBadge && handleUpdateBadge({ ...selectedBadge, icon: media?.url })
  })

  useEffect(() => {
    selectedBadge && showWidget()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBadge])

  const BadgeList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((item, index) => {
          return (
            <BadgeItem key={`item-${item.key}`} index={index} badge={item} />
          )
        })}
      </ul>
    )
  })

  const BadgeItem = SortableElement(({ badge }) => {
    return (
      <li key={badge.key}>
        <Row className="badge-item-row">
          <Col sm={2}>
            <i className="fas fa-bars" />
          </Col>
          <Col sm={2}>
            <UploadBtn
              type="button"
              onClick={() => setSelectedBadge(badge)}
              imgUrl={badge.icon}
            >
              <i class="fas fa-upload"></i>
            </UploadBtn>
          </Col>
          <Col sm={6} className="badge-name-col">
            {editInput.key === badge.key ? (
              <>
                <input
                  value={editInput.value}
                  onBlur={() => handleInputOnBlur(badge)}
                  onChange={(e) =>
                    editInput.value.length <= 30 &&
                    setEditInput({ ...editInput, value: e.target.value })
                  }
                  autoFocus={true}
                  maxLength="30"
                  className="badge-name-input"
                />
                {editInput.value.length >= 30 && (
                  <span className="badge-char-limit-warning">
                    Character limit is 30
                  </span>
                )}
              </>
            ) : (
              <span
                onClick={() =>
                  setEditInput({ key: badge.key, value: badge.name })
                }
              >
                {badge.name}
              </span>
            )}
          </Col>
          <Col sm={2}>
            <button
              className="remove-badge"
              onClick={() => handleRemoveBadge(badge.key)}
            >
              <i className="fas fa-times" />
            </button>
          </Col>
        </Row>
      </li>
    )
  })

  return (
    <Creator>
      <label className="section-title">Badges</label>
      <Header>
        <Row>
          <Col sm={2}>
            <span>Order</span>
          </Col>
          <Col sm={2}>
            <span>Icon</span>
          </Col>
          <Col sm={6}>
            <span>Name</span>
          </Col>
          <Col sm={2}>
            <button onClick={() => handleAddBadge()}>
              <i className="fas fa-plus" />
            </button>
          </Col>
        </Row>
      </Header>
      <Content>
        <BadgeList
          pressDelay={200}
          helperClass="sortableHelper"
          items={badges}
          onSortEnd={handleOnSortEnd}
        />
      </Content>
    </Creator>
  )
}

export default BadgeCreator
