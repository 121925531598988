import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_REPORTS } from './graph-queries'
import { filterSearchResults, sortResults } from './helpers'

import FiltersAndSearch from './filters-and-search'
import Table from './table'

import { IndexStyled } from './styles/index'

export const filters = [
  { name: 'Open', value: 'open' },
  { name: 'Active', value: 'active' },
  { name: 'Closed', value: 'closed' },
]

const ManageUsers = () => {
  const [reportsList, setReportsList] = useState([])

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('open')
  const [sort, setSort] = useState({ key: 'dateStatus', order: 0 })

  const { data: reports, refetch } = useQuery(
    GET_REPORTS,
    { variables: { status: filter } },
    { fetchPolicy: 'cache-and-network' }
  )

  useEffect(() => {
    if (reports?.getReports) {
      const filtered = filterSearchResults({
        reportsList: reports.getReports,
        search,
        filter,
      })
      const sorted = sortResults({ reportsList: filtered, sort })
      setReportsList(sorted)
    }
  }, [reports, filter, search, sort])

  return (
    <IndexStyled>
      <FiltersAndSearch
        search={search}
        setSearch={setSearch}
        filter={filter}
        sort={sort}
        refetch={refetch}
        setFilter={setFilter}
        reportsList={reports?.getReports}
        setReportsList={setReportsList}
      />
      <Table
        sort={sort}
        setSort={setSort}
        refetch={refetch}
        reportsList={reportsList}
        setReportsList={setReportsList}
      />
    </IndexStyled>
  )
}

export default ManageUsers
