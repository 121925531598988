import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { pick } from "lodash"

import { FooterWrapper } from './styles'
import IndyButton from '_components/indyButton'
import { FooterEditor } from './styles'
import { MediaEditor } from "./mediaEditor.jsx"
import { TextEditor } from "./textEditor.jsx"
import { LogoEditor } from './logoEditor'
import { MenuEditor } from './menuEditor'

const SetFooterMutation = gql` 
  mutation SetFooter($input: FooterInput) {
    setFooter(input: $input) {
      logo
      media {
        icon
        url
      }
      text {
        title
        text
      }
      menus {
        title
        visible
        links {
          title
          url
        }
      }
    }
  }
`

const GetFooterQuery = gql` 
  query GetFooter {
    getFooter {
      logo
      media {
        icon
        url
      }
      text {
        title
        text
      }
      menus {
        title
        visible
        links {
          title
          url
        }
      }
    }
  }
`

const orderData = (footerData) => {
  //strips away the __typename property 
  //because it messes up the mutation
  const cleanRoot = pick(footerData, ['logo', 'media', 'text', 'menus'])
  const cleanText = pick(cleanRoot.text, ['title', 'text'])
  const cleanMedia = cleanRoot.media.map((item) => pick(item, ['icon', 'url']))
  const cleanMenus = cleanRoot.menus.map((item) => {
    const cleanLinks = item.links.map((link) => pick(link, ['title', 'url']))
    return {
      ...pick(item, ['title', 'visible']),
      links: cleanLinks,
    }
  })

  return {
    logo: cleanRoot.logo,
    text: cleanText,
    media: cleanMedia,
    menus: cleanMenus,
  }
}

export const Footer = () => {
  const intl = useIntl()
  const [ SetFooter, { loading: loadingMutation } ] = useMutation(SetFooterMutation)
  const { data, loading } = useQuery(GetFooterQuery)

  const [footer, setFooter] = useState({
    logo: null,
    media: [],
    text: null,
    menus: [
      {title: 'Primary', visible: true, links: []},
      {title: 'Secondary', visible: true, links: []}
    ]
  })

  useEffect(() => {
    //initialises the footer state with the data from the query
    data?.getFooter && setFooter(orderData(data?.getFooter))
  }, [loading])

  useEffect(() => {
    loadingMutation && window.location.reload()
  }, [loadingMutation])

  const onSave = () => {
    SetFooter({variables: { input: footer }})
  }

  const saveText = intl.formatMessage({ id: 'user.profile.manage.keywords.modal.save' })
  const editorText = {
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.title'}),
    description: intl.formatMessage({ id: 'admin.sitestructure.footer.description' })
  }

  return (
    <FooterWrapper>
      <h1>{editorText.title}</h1>
      <p>{editorText.description}</p>

      <FooterEditor>
        <LogoEditor
          value={footer.logo} 
          onChange={(url) => setFooter({...footer, logo: url})
        }/>
        <MediaEditor
          footer={footer}
          setFooter={setFooter}
        />
        <TextEditor
          value={footer.text}
          onChange={(text) => setFooter({...footer, text})}
        />
        <MenuEditor
          footer={footer}
          setFooter={setFooter}
        />
        <div className="button-wrapper">
          {loadingMutation 
            ? <IndyButton>...loading</IndyButton>
            : <IndyButton onClick={() => onSave()}>{saveText}</IndyButton>
          }
        </div>
      </FooterEditor>
    </FooterWrapper>
  )
}

export default Footer
