import React from 'react'
import { Logo, Container } from '_themes/default/components/styled/logo-title'

const LogoTile = ({ className, children }) => {
  return (
    <Container className={className}>
      <Logo src={children} />
    </Container>
  )
}

export default LogoTile
