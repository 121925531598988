import styled from '@emotion/styled'

export const ButtonStyled = styled.button`
  margin: 0;
  padding: 9px 15px;
  font-size: 11px;
  opacity: 1;
  margin-right: 5px;
  color: var(--background);
  border: none;
  background: var(--flavor);
  border-radius: 15px;
  &.active {
    background: var(--flavor);
    color: var(--flavor-contrast);
    opacity: 0.7;
  }
  &:hover {
    opacity: 0.7;
  }
`
