import React, { useState } from 'react'

import Map from './map'
import ResultGrid from './result-grid'

const Index = ({ onSelect, markers, type }) => {
  const [visibleIds, setVisibleIds] = useState([])

  const onBoundsChange = ({ ids }) => {
    setVisibleIds(ids)
  }

  return (
    <>
      <Map
        onSelect={onSelect}
        markers={markers}
        onBoundsChange={onBoundsChange}
      />
      <ResultGrid markers={markers} visibleIds={visibleIds} type={type} />
    </>
  )
}

export default Index
