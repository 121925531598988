import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { AddButton, Items, Item } from './styles/tree-view'
import { v4 as uuid } from 'uuid'
import { Row, Col } from 'react-bootstrap'

const TreeViewCreator = ({ items: defaultItems, onSaveCallback }) => {
  const intl = useIntl()

  const renderLabel = (value) => {
    return intl.formatMessage({
      id: `admin.settings.sitestructure.blog.categories.${value.toLowerCase()}`,
    })
  }

  const [items, setItems] = useState(defaultItems || [])
  const [editId, setEditId] = useState()
  const [expandedIds, setExpandedIds] = useState([])

  const addCategory = (parentId) => {
    const id = uuid()
    const newItem = { id, name: '', parentId }

    if (parentId) {
      setItems(
        items.map((item) => {
          if (item?.id === parentId) {
            return {
              ...item,
              subcategories: [...(item?.subcategories || []), newItem],
            }
          }
          return item
        })
      )
    } else {
      setItems([...items, newItem])
    }
    setEditId(id)
  }

  const updateItem = ({ id, value, parentId, isToDelete }) => {
    let isToDel = isToDelete || !value?.split(' ').join('')

    if (parentId) {
      const updated = items.map((item) => {
        if (item?.id === parentId) {
          return {
            ...item,
            subcategories: isToDel
              ? item?.subcategories.filter((i) => i?.id !== id)
              : item.subcategories?.map((sub) => {
                  if (sub?.id === id) {
                    return { ...sub, name: value }
                  }
                  return sub
                }),
          }
        }
        return item
      })
      setItems(updated)
      return updated
    } else {
      const updated = isToDel
        ? items?.filter((i) => id !== i?.id)
        : items.map((item) => {
            if (item?.id === id) {
              return { ...item, name: value }
            }
            return item
          })
      setItems(updated)
      return updated
    }
  }

  const SingleItem = ({ item }) => {
    const isEditMode = item?.id === editId
    const [value, setValue] = useState(item?.name)
    const [isExpanded, setIsExpanded] = useState(
      expandedIds.some((id) => item?.id === id)
    )

    const addExpandedId = () => {
      setExpandedIds([...expandedIds, item?.id])
      setIsExpanded(true)
    }

    const removeExpandedId = () => {
      setExpandedIds(
        expandedIds.filter((expandedId) => expandedId !== item?.id)
      )
      setIsExpanded(false)
    }

    const handleUpdateItem = ({ isToDelete } = {}) => {
      const updatedItems = updateItem({
        id: item?.id,
        value,
        parentId: item?.parentId,
        isToDelete,
      })
      if (onSaveCallback) onSaveCallback({ items: updatedItems })
      removeExpandedId(item?.id)
      setEditId()
    }

    return (
      <Item>
        <div className="content">
          {!isEditMode && <span className="name">{item?.name}</span>}
          {isEditMode && (
            <input value={value} onChange={(e) => setValue(e?.target?.value)} />
          )}
        </div>
        <div className="controls">
          {isEditMode && (
            <i className="fas fa-check" onClick={() => handleUpdateItem()} />
          )}
          {!isEditMode && (
            <>
              <i
                className="fas fa-trash"
                onClick={() => handleUpdateItem({ isToDelete: true })}
              />
              <i className="fas fa-pen" onClick={() => setEditId(item?.id)} />
              {!item?.parentId && (
                <i
                  className="fas fa-plus"
                  onClick={() => {
                    addExpandedId()
                    addCategory(item?.id)
                  }}
                />
              )}
            </>
          )}
          {item?.subcategories?.length > 0 && (
            <i
              onClick={() =>
                isExpanded ? removeExpandedId() : addExpandedId()
              }
              className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`}
            />
          )}
        </div>
        {isExpanded && (
          <div className="children">
            {item?.subcategories?.map((i) => (
              <SingleItem item={i} />
            ))}
          </div>
        )}
      </Item>
    )
  }

  return (
    <>
      <Row>
        <Col md={6} sm={12}>
          <Items>
            {items?.map((item) => (
              <SingleItem item={item} />
            ))}
          </Items>
          <AddButton onClick={() => addCategory()}>
            {renderLabel('addnewcategory')}
          </AddButton>
        </Col>
      </Row>
    </>
  )
}

export default TreeViewCreator
