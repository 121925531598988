import { css } from '@emotion/core'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { ctaButton } from '_utils/css-buttons'

export const DefaultTileStyled = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 30px;
  flex-direction: column;
  background-color: var(--background);
  ${border()}
`

export const Header = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 25px 25px 0 0;
  position: relative;
  z-index: 0;
  filter: ${({ isExpired }) => isExpired && 'grayscale(1)'};

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 0 0;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.28)
    );
    z-index: -1;
  }
  .location {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    font-size: 12px;
    color: var(--imgColor);
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    svg {
      margin-right: 5px;
      stroke: var(--imgColor);
      width: 12px;
    }
  }
`

export const EntityInfo = styled.div``

export const LikeWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -18px;
  right: 15px;
  background-color: var(--background);
  i {
    font-size: 20px;
  }
`

export const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  color: var(--foreground);
  flex: 1;
`

export const Description = styled.div`
  color: var(--foreground);
  margin-top: 15px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`

export const Title = styled.h2`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const DiscoverTitle = styled.h3`
  color: var(--foreground);
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ApplicationLabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 9px;
  align-items: center;
`

export const Date = styled.div`
  font-size: 12px;
  font-weight: 600;
  ${({ isExpired }) =>
    isExpired &&
    css`
      span {
        background-color: var(--foreground);
        color: var(--background);
        border-radius: 20px;
        padding: 3px 10px;
      }
    `}
`

export const ApplicationCountButtonStyled = styled.button`
  ${ctaButton}
  font-size: 12px;
  font-weight: 600;
  background-color: var(--flavor);
  color: var(--fg);
  border-radius: 20px;
  padding: 0 10px;
  height: 22px;
  white-space: nowrap;
`
export const ConnectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  button {
    margin-top: 30px;
  }
`
