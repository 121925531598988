/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react'
import uploadImageToCdn from '../../../../services/images'
import { ImageUploadStyled, Revert } from './image-upload-styled'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import { MbToB } from 'services/file'

const defaultMaxFileSize = MbToB(2)

const Index = ({
  type,
  onUpload,
  value,
  className,
  cdnType,
  testid,
  maxFileSize,
  imgSize,
  noRevertToDefault,
  onRevert,
}) => {
  const intl = useIntl()
  const [uploader, setUploader] = useState({ imgUrl: value })
  let imageUploadRef = null

  useEffect(() => {
    setUploader({ imgUrl: value })
  }, [value])

  const renderName = (name) => {
    const defaultName = intl.formatMessage({
      id: 'toast.upload.fail.defaultname',
    })
    if (!name) return defaultName

    return `${
      name.charAt(0).toUpperCase() + name.slice(1)
    } ${defaultName.toLowerCase()}`
  }

  const handleUploadImage = () => {
    if (imageUploadRef.files[0]) {
      const fileName = imageUploadRef?.files[0]?.name || ''
      if (imageUploadRef.files[0].size > (maxFileSize || defaultMaxFileSize)) {
        notification.warning({
          message: intl.formatMessage({ id: 'toast.upload.fail.message' }),
          description: `
          ${renderName(fileName)}
          ${intl.formatMessage(
            { id: 'toast.upload.fail.description' },
            {
              size: maxFileSize || defaultMaxFileSize,
            }
          )}MB`,
        })
      } else {
        uploadImageToCdn(imageUploadRef.files[0], cdnType).then((d) => {
          setUploader({ ...uploader, imgUrl: d.urlNoCache })
          onUpload(d.urlNoCache)
          notification.success({
            message: intl.formatMessage({ id: 'toast.upload.success.message' }),
            description: `${renderName(fileName)} has been uploaded`,
          })
        })
      }
    }
  }

  const handleOnRevert = () => {
    setUploader({ imgUrl: null })
    onRevert()
  }

  const convertFileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      cb(reader.result)
    }
  }
  if (type === 'no-image') {
    return (
      <input
        className={type}
        accept="image/*"
        id={`upload-image-${type}`}
        ref={(ref) => {
          imageUploadRef = ref
        }}
        type="file"
        onChange={() => handleUploadImage()}
        data-testid={`imgInput-${testid ?? 'default'}`}
      />
    )
  }
  return (
    <ImageUploadStyled
      isImgUploaded={uploader.imgUrl}
      className="image-upload-container"
    >
      <div
        className={`wrapper ${type} size-${imgSize} image-placeholder ${
          uploader.imgUrl ? 'uploaded-image' : ''
        }`}
      >
        {!uploader.imgValue ? <i className="far fa-image" /> : null}
        <label htmlFor={`upload-image-${type}`} />
        <input
          accept="image/png, image/jpeg"
          id={`upload-image-${type}`}
          ref={(ref) => {
            imageUploadRef = ref
          }}
          type="file"
          onChange={() => handleUploadImage()}
          data-testid={`imgInput-${testid ?? 'default'}`}
        />
        {uploader.imgUrl ? (
          <img alt="" className={className} src={uploader.imgUrl} />
        ) : null}
        {!noRevertToDefault && onRevert && uploader.imgUrl && (
          <Revert onClick={handleOnRevert}>
            <i class="fas fa-times" />
          </Revert>
        )}
      </div>
    </ImageUploadStyled>
  )
}

export default Index
