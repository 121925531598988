import React, { useState } from 'react'
import { useAppConfig } from '_application'
import { useIntl } from 'react-intl'
import ImageUpload from 'components/indy/shared/image-upload'
import VideoUpload from 'components/indy/shared/video-upload'
import { TypeSwitch } from '../shared/styles/type-switch'

const renderTypeSwitch = ({ type, onClick, intl }) => {
  return (
    <TypeSwitch>
      <button
        onClick={() => onClick('image')}
        className={`${type === 'image' ? 'active' : ''}`}
      >
        {intl.formatMessage({
          id: 'admin.settings.accesspage.backgrounds.type.image',
        })}
      </button>
      <button
        onClick={() => onClick('video')}
        className={`${type === 'video' ? 'active' : ''}`}
      >
        {intl.formatMessage({
          id: 'admin.settings.accesspage.backgrounds.type.video',
        })}
      </button>
    </TypeSwitch>
  )
}

const BackgroundShared = ({ onRevert, onUpload }) => {
  const intl = useIntl()
  const background = useAppConfig()?.accessPage?.backgroundImage || null
  const [backgroundType, setBackgroundType] = useState(
    background?.type || 'image'
  )

  return (
    <>
      {renderTypeSwitch({
        type: backgroundType,
        onClick: (type) => setBackgroundType(type),
        intl,
      })}
      {backgroundType === 'image' ? (
        <ImageUpload
          className="background-img profile-dashboard-thumbnail"
          value={background?.type === 'video' ? null : background?.value}
          type="access-page-background"
          cdnType="access-page-background"
          onUpload={(imgUrl) => onUpload(imgUrl)}
          onRevert={() => onRevert('')}
          imgSize="l"
        />
      ) : (
        <VideoUpload
          className="background-img profile-dashboard-thumbnail"
          value={background?.type !== 'video' ? null : background?.value}
          type={'access-page-background-video'}
          cdnType={'access-page-background-video'}
          onUpload={(imgUrl) => onUpload(imgUrl, 'video')}
          onRevert={() => onRevert('')}
          imgSize="l"
        />
      )}
    </>
  )
}

export default BackgroundShared
