import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../shared/styles/sections'
import InputShared from './input-shared'

const Dashboard = () => {
  const intl = useIntl()

  const renderMessageDescription = (value) => {
    return intl.formatMessage({
      id: `admin.settings.overridelabels.${value.toLowerCase()}`,
    })
  }

  return (
    <>
      <MainSection>
        <span className="title">{renderMessageDescription('onboarding')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="onboardingFinalStepText"
              title={renderMessageDescription('onboardingFinalStepText')}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('discover')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="discoverTitle"
              title={renderMessageDescription('discoverTitle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="discoverWelcome"
              title={renderMessageDescription('discoverWelcomeText')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('login')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="loginDescription"
              title={renderMessageDescription('loginDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('activities')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="activities"
              title={renderMessageDescription('activitiesSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="activitiesPlural"
              title={renderMessageDescription('activitiesPlural')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="activitiesDescription"
              title={renderMessageDescription('activitiesDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">
          {renderMessageDescription('opportunities')}
        </span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="opportunities"
              title={renderMessageDescription('opportunitiesSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="opportunitiesPlural"
              title={renderMessageDescription('opportunitiesPlural')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="opportunitiesDescription"
              title={renderMessageDescription('opportunitiesDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="opportunitiesEmail"
              title={renderMessageDescription('opportunitiesEmail')}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('companies')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="companies"
              title={renderMessageDescription('companiesSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="companiesPlural"
              title={renderMessageDescription('companiesPlural')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="companiesDescription"
              title={renderMessageDescription('companiesDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('people')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="people"
              title={renderMessageDescription('peopleSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="peoplePlural"
              title={renderMessageDescription('peoplePlural')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="peopleDescription"
              title={renderMessageDescription('peopleDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('blog')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="blog"
              title={renderMessageDescription('blogSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="blogPlural"
              title={renderMessageDescription('blogPlural')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="blogDescription"
              title={renderMessageDescription('blogDescription')}
              limit={200}
              isMultiline={true}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('forum')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="forum"
              title={renderMessageDescription('forumSingle')}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputShared
              type="forumPlural"
              title={renderMessageDescription('forumPlural')}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">
          {renderMessageDescription('administration')}
        </span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="administration"
              title={renderMessageDescription('administration')}
            />
          </Col>
        </Row>
      </MainSection>
      <MainSection>
        <span className="title">{renderMessageDescription('search')}</span>
        <Row>
          <Col sm={12} md={12}>
            <InputShared
              type="search"
              title={renderMessageDescription('search')}
            />
          </Col>
        </Row>
      </MainSection>
    </>
  )
}

export default Dashboard
