import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from "@emotion/styled"
import Modal from 'react-modal'

import { BaseToggle } from "_components/base-toggle"
import { InputStyled } from '_components/InputStyled.js'
import { IndyHollowButton, IndyButton } from '_components/indyButton'
import { IconBox } from "_components/icon-picker.jsx"
import { SettingsDropdown } from "_components/settingsDropdown"

const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;

  label {
    margin-bottom: var(--space-s, 10px);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);

    h3 {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
`

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  button {
    border: none;
    background: none;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 80px 60px;
    column-gap: var(--space-m);
    padding-right: var(--space-m);
  }

  .labels {
    margin-bottom: var(--space-s, 10px);
    .meta {
      grid-column: span 3;
      margin-bottom: var(--space-s, 5px);
    }
  }

  .labels:nth-of-type(2) {
    margin-top: var(--space-m);
  }
`

const MenuItemStyled = styled.div`
  justify-content: center;
  align-items: center;

  border-radius: var(--radius);
  border: var(--border) !important;
  padding: var(--space-m);
  opacity: 1;

  margin-bottom: -1px;
  transition: .4s, transform .2s, opacity .2s;

  &.moved:not(.mover) {
    position: relative; z-index: 1;
    border: solid 1px var(--foreground) !important;
  }

  input {
    outline: solid 0px var(--shade) !important;
    font-weight: 900;
    &:not(:focus) {
      padding: 0px;
    }
  }

  &.item {
    grid-column: 3;
    background-color: var(--shade-faint);
  }

  .firstColumn {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items: center;
    grid-column: span 2;
  }

  .center {
    display: flex;
    justify-content: center;
  }
`

const ArrangeItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-row: span 2;

  width: 60px;
  height: 100%;
  padding: 0px var(--space-m) 0px 0px;

  .icon {
    cursor: pointer;
    border-radius: var(--radius);
    outline: solid 0px var(--shade);
    transition: .1s;
  }

  .icon:hover {
    outline: var(--border);
  }
`

const MenuHead = ({
  menuIndex,
  footer,
  setFooter = () => {},
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addItem = (newLink) => {
    setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]
      let newItems = [...newMenu.links]

      newItems = [...newItems, newLink]

      newMenu.links = newItems
      newMenus[menuIndex] = newMenu
      return {...prev, menus: newMenus}
    })
  }

  const [title, setTitle] = useState(footer.menus[menuIndex].title)
  
  useEffect(() => {
    changeTitle(title)
  }, [title])

  const changeTitle = (newTitle) => {
    true && setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]

      newMenu.title = newTitle

      newMenus[menuIndex] = newMenu
      return {...prev, menus: newMenus}
    })
  }

  const changeVisible = () => {
    setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]

      newMenu.visible = !newMenu.visible

      newMenus[menuIndex] = newMenu
      return {...prev, menus: newMenus}
    })

  }

  const intl = useIntl()
  const headerPlaceholder = intl.formatMessage({ id: 'admin.sitestructure.footer.menu.headerPlaceholder' })

  return (
    <MenuItemStyled className="head grid">
      <InputStyled 
        placeholder={headerPlaceholder} 
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      
      <BaseToggle 
        on="fas fa-check"
        off="fas fa-times"
        checked={footer.menus[menuIndex].visible}
        onClick={() => changeVisible()}
      />

      <button 
        className="addItem" 
        onClick={() => setModalIsOpen(true)}
      >
        <i className="fa fa-plus"></i>
      </button>

      <ModalComponent
        item={{title: "", url: ""}}
        modalIsOpen={modalIsOpen} 
        setModalIsOpen={setModalIsOpen}
        onSave={(e) => addItem(e)}
      />
    </MenuItemStyled>
  )
}

const useMoveState = () => {
  const [change, setChange] = useState(null)
  const [moved, setMoved] = useState(null)

  const registerMove = () => {
    setChange(true)
    setTimeout(() => {
      setChange(false)
      setMoved(null)
    }, 300)
  }

  return {
    change,
    moved,
    setMoved,
    registerMove,
  }
}

const MenuItem = ({
  menuIndex,
  itemIndex,
  item,
  footer,
  setFooter = () => {},
}) => {
  const [modalIsOpen , setModalIsOpen] = useState(false)

  const { 
    change, 
    moved, 
    setMoved, 
    registerMove 
  } = useMoveState()

  useEffect(() => {
    registerMove()
  }, [item])

  const changeItem = (newItem) => {
    setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]
      let newItems = [...newMenu.links]

      newItems = newItems.map((oldItem, index) => {
        return index === itemIndex 
          ? newItem 
          : oldItem
      })

      newMenu.links = newItems
      newMenus[menuIndex] = newMenu
      return {...prev, menus: newMenus}
    })
  }
  
  const moveItem = (toIndex) => {
    setMoved(itemIndex + 1)
    setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]
      let newItems = [...newMenu.links]

      const movedItem = newItems.splice(itemIndex, 1)
      newItems.splice(itemIndex + toIndex, 0, movedItem[0])

      newMenu.links = newItems
      newMenus[menuIndex] = newMenu
      
      return {...prev, menus: newMenus}
    })
  }

  const removeItem = () => {
    setFooter((prev) => {
      let newMenus = [...prev.menus]
      let newMenu = newMenus[menuIndex]
      let newItems = [...newMenu.links]

      newItems.splice(itemIndex, 1)

      newMenu.links = newItems
      newMenus[menuIndex] = newMenu
      return {...prev, menus: newMenus}
    })
  }

  const intl = useIntl()
  const itemDropdown = {
    edit: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.item.edit' }),
    delete: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.item.remove' }),
  }

  return (
    <MenuItemStyled className={`${moved - 1 === itemIndex && "mover"} item grid ${change ? "moved" : "parked"}`}>
      <div class="firstColumn">
        <ArrangeItem>
          {itemIndex !== 0 && <IconBox icon="fas fa-angle-up" onClick={() => moveItem(-1)}/>}
          {footer.menus[menuIndex].links.length !== itemIndex + 1 && <IconBox icon="fas fa-angle-down" onClick={() => moveItem(1)}/>}
        </ArrangeItem>

        <p className="title"><b>{item.title}</b></p>
        <p className="url">{item.url}</p>
      </div>

      <div class="center">
        <SettingsDropdown>
          <p onClick={() => setModalIsOpen(true)}>{itemDropdown.edit}</p>
          <p onClick={() => removeItem()}>{itemDropdown.delete}</p>
        </SettingsDropdown>
      </div>

      <ModalComponent
        item={item}
        modalIsOpen={modalIsOpen} 
        setModalIsOpen={setModalIsOpen}
        onSave={(newItem) => changeItem(newItem)}
      />
    </MenuItemStyled>
  )
}

export const MenuEditor = ({footer, setFooter = () => {} }) => {
  const intl = useIntl()
  const menuText = {
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.title' }),
    description: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.description' }),
  }

  const columnText = {
    name: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.column.name' }),
    visibility: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.column.visibility' }),
    action: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.column.action' }),
  }

  return (
    <FooterMenu>
      <div>
        <div className="labels grid">
          <div class="meta">
            <p><b>{menuText.title}</b></p>
            <p>{menuText.description}</p> 
          </div>
          <p>{columnText.name} 1</p>
          <p>{columnText.visibility}</p>
          <p>{columnText.action}</p>
        </div>

        <MenuSection
          index={0}
          footer={footer}
          setFooter={setFooter}
        />
      </div>
      <div>
        <div className="labels grid">
          <p>{columnText.name} 2</p>
          <p>{columnText.visibility}</p>
          <p>{columnText.action}</p>
        </div>

        <MenuSection
          index={1}
          footer={footer}
          setFooter={setFooter}
        />
      </div>
    </FooterMenu>
  )
}

const MenuSection = ({footer, setFooter = () => {}, index}) => {
  const menu = footer?.menus[index]

  return (
    <>
      {menu && <MenuHead
        menuIndex={index}
        footer={footer}
        setFooter={setFooter}
      />}
      {menu && menu.links.map((item, i) => (
        <MenuItem
          menuIndex={index}
          itemIndex={i}
          item={item}
          footer={footer}
          setFooter={setFooter}
        />
      ))}
    </>
  )
}

const ModalComponent = ({item, modalIsOpen, setModalIsOpen, onSave = () => {}}) => {
  const [content, setContent] = useState({
    title: item?.title || '',
    url: item?.url || '',
  })

  const saveContent = () => {
    onSave(content)
    setModalIsOpen(false)
  }

  const changeContent = (e) => {
    setContent({...content, ...e})
  }

  useEffect(() => {
    setContent(item)
  }, [modalIsOpen])

  const intl = useIntl()
  const modalText = {
    modalTitle: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.modal.modalTitle' }),
    title: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.modal.title' }),
    titlePlaceholder: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.modal.titlePlaceholder' }),
    url: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.modal.url' }),
    urlPlaceholder: intl.formatMessage({ id: 'admin.sitestructure.footer.menu.modal.urlPlaceholder' }),
  }

  const buttonText = {
    save: intl.formatMessage({ id: 'user.profile.manage.keywords.modal.save' }),
    cancel: intl.formatMessage({ id: 'blog.share.button.cancel' }),
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          background: 'var(--background)',
          width: 'min(100%,450px)',
          padding: '40px 32px',
          height: '600px',
          top: '50%',
          left: '50%',
          right: 'auto',
          borderRadius: '10px',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
      }}
    >
      <ModalStyled>
        <div className="content">
          <h3>{modalText.modalTitle}</h3>
          <div>
            <label>{modalText.title}</label>
            <InputStyled 
              placeholder={modalText.titlePlaceholder}
              value={content.title} 
              onChange={(e) => {
                changeContent({title: e.target.value})
              }}
            />
          </div>
          <div>
            <label>{modalText.url}</label>
            <InputStyled
              type="url"
              placeholder={modalText.urlPlaceholder}
              value={content.url}
              onChange={(e) => {
                changeContent({url: e.target.value})
              }}
            />
          </div>
        </div>
        
        <div className="buttons">
          <IndyHollowButton onClick={() => setModalIsOpen(false)}>
            <p>{buttonText.cancel}</p>
          </IndyHollowButton>
          <IndyButton
            disabled={!content.title || !content.url}
            onClick={() => saveContent()}
          >
            <p>{buttonText.save}</p>
          </IndyButton>
        </div>
      </ModalStyled>
    </Modal>
  )
}

export default MenuEditor
