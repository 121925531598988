import { omit } from 'lodash'

export const getFormDataFields = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    coverPhoto: data?.coverPhoto,
    description: data?.description,
    body: data?.body,
    location: {
      ...data?.location || {},
      lat: data?.location?.lat?.toString(),
      long: data?.location?.long?.toString()
    },
    start: data?.start,
    end: data?.end,
    private: data?.private,
    isDraft: data?.isDraft,
    expire: data?.expire,
    customAttributes: data?.customAttributes?.map(ca => omit(ca, '__typename')),
    keywords: data?.keywords?.map(k => ({
      values: k?.values?.map(v => omit(v, '__typename')),
      key: k?.key
    })),
    taxonomy: data?.taxonomy?.map(k => ({
      values: k?.values?.map(v => omit(v, '__typename')),
      key: k?.key
    })),
    receiveApplicationBy: data?.receiveApplicationBy
  }
}
