import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import BreakLine from './break-line'
import { ShortDescWrapper } from './styles/short-desc'
import { TitleWrapper } from './styles/title'

const ShortDescription = ({ value, onSave, name, description, isRequired }) => {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(value || '')
  return (
    <>
      <ShortDescWrapper>
        <div className="label-wrapper">
          <label htmlFor="desc-content">
            {intl.formatMessage({
              id: 'contentstudio.entity.url',
              defaultMessage: `${name}`,
            })}
            {isRequired && <span className="required-star">*</span>}
          </label>
          <p className="description-content-info">
            {description && intl.formatMessage({
              id: 'contentstudio.entity.url.description',
              defaultMessage: `${description}`,
            })}
          </p>
        </div>
        <div className="description-content-wrapper">
          <TitleWrapper>
            <input
              type="text"
              name="title-input"
              className="title-input"
              maxLength={50}
              value={inputValue}
              placeholder={'https://'}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={() => onSave(inputValue)}
            />
          </TitleWrapper>
        </div>
      </ShortDescWrapper>
      <BreakLine />
    </>
  )
}

export default ShortDescription
