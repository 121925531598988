import React from 'react'
import { connect } from 'react-redux'
import TransactionList from './transaction-list'
import { useIntl } from 'react-intl'

import { Section, MainWrapper } from '../styles'

const mapStateToProps = ({ user }) => ({ user })

const PaymentHistory = ({ user, dispatch }) => {
  return (
    <MainWrapper>
      <Section>
        <span className="section-title">
          {useIntl().formatMessage({
            id: 'user.profile.paymenthistory.transactions',
          })}
        </span>
        <div className="content">
          <TransactionList />
        </div>
      </Section>
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(PaymentHistory)
