import styled from '@emotion/styled'
import BaseStyled from './default'
import { mobile, pablet } from '_utils/responsive-queries'

const ProfileImgStyled = styled(BaseStyled)`
  .images-upload {
    position: relative;
    height: 100%;
    width: 100%;
    .title {
      margin: 10px 0;
      display: block;
      color: ${(props) => props.theme.colors.active};
    }
    .image {
      position: relative;
      display: table;
      &.cover-photo {
        position: relative;
        width: 100%;

        ${mobile} {
          width: calc(100% + 32px);
          transform: translateX(-16px);
        }

        img {
          width: 100%;
          height: 200px;
          border: none;
          object-fit: cover;
          object-position: center;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;

          ${pablet} {
            /* height: 150px; */
          }
          ${mobile} {
            border-radius: 0;
          }
        }
        .change-cover-photo-btn {
          .upload-regular {
            border: 2px solid var(--flavor);
          }
        }
      }
      &.avatar {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);

        ${mobile} {
          top: 40%;
        }

        img {
          position: relative;
          width: 114px;
          height: 114px;
          border-radius: 50%;
          box-shadow: 0 0 8px 0 rgb(0 0 0 / 16%);
        }
        .title {
          position: absolute;
          top: 100px;
          left: 0;
          display: none;

          width: 100%;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
`

export default ProfileImgStyled
