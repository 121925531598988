import gql from 'graphql-tag'

export const UPDATE_BLOG_SUBSCRIPTION_ACCESS = gql`
  mutation updateBlogSubscriptionAccess(
    $isBlogSubscriptionAccessEnabled: Boolean
  ) {
    updateBlogSubscriptionAccess(
      isBlogSubscriptionAccessEnabled: $isBlogSubscriptionAccessEnabled
    ) {
      logos {
        lightLogo {
          key
        }
      }
    }
  }
`
