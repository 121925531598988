import { css } from '@emotion/core'

export const border = (
  alpha = 0.2,
  spread = 17,
  top = 3,
  size = 1,
  radius = 25
) => css`
  box-shadow: 0 ${top + 'px'} ${spread + 'px'} ${size + 'px'}
    rgba(0, 0, 0, ${alpha});
  border-radius: ${radius + 'px'};
`

export default border
