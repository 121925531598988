/* eslint-disable camelcase */
import Modal from 'react-bootstrap/Modal'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useAppConfig } from '_application'
import { ctaButton, circleButton } from '_utils/css-buttons'
import styled from '@emotion/styled'
import store from 'store'
import './style.scss'

const mapStateToProps = ({ dispatch, user, application }) => ({
  dispatch,
  user,
  application,
})

const SanityButton = styled.button`
  --stroke-color: var(--imgColor);
  p {
    margin: 0px;
  }

  &.ctaButton:not(.pen) {
    ${ctaButton}
    font-weight: 700;
  }

  display: block;
  margin: 0 auto;

  padding: 6px 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--flavor);

  font-weight: 700;

  i {
    margin-right: 5px;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.inactive};
  }

  &.pen {
    ${circleButton};
    color: var(--stroke-color) !important;
    border: 2px solid var(--stroke-color) !important;
    background-color: transparent !important;
    padding: 0 !important;
    i {
      margin: 0 !important;
    }
  }
`

const SanityModal = ({
  url,
  buttonName,
  user,
  icon,
  style,
  className,
  buttonClass,
  buttonAnimation = true,
  isDisabled,
  disabledCallback,
  onCloseCallback,
}) => {
  const [showModal, setShowModal] = useState(false)
  const { tenantId } = useAppConfig()

  const handleClose = (e) => {
    if (onCloseCallback) onCloseCallback()
    e.preventDefault()
    setShowModal(false)
  }

  const token = store.get('RIOT/token')

  const handleSanityModalOpen = (e) => {
    e.preventDefault()
    if (isDisabled) {
      disabledCallback()
    } else {
      setShowModal(true)
    }
  }

  if (user.authorized) {
    return (
      <div className={`sanity-modal ${className}`} style={style}>
        <Modal
          dialogClassName="sanity-modal-container"
          show={showModal}
          onHide={() => {}}
        >
          <div className="tab">
            <div className="close-button" onClick={(e) => handleClose(e)}>
              <i class="fas fa-times"></i>
            </div>
          </div>
          <Modal.Body className="single-modal-body">
            <iframe
              title="studio"
              className="sanity-window"
              src={`${process.env.REACT_APP_STUDIO_ENDPOINT}/${
                url || ''
              }?authtoken=${token}&tenantId=${tenantId}`}
              style={{ width: '100%', height: '100%' }}
            />
          </Modal.Body>
        </Modal>
        <SanityButton
          type="button"
          className={`interact-button sanity-button ${buttonClass} ${
            buttonAnimation && 'ctaButton'
          } ${icon === 'fa-pen' && 'pen'}`}
          title={`${process.env.REACT_APP_STUDIO_ENDPOINT}/${
            url || ''
          }?authtoken=${token}&tenantId=${tenantId}`}
          onClick={(e) => handleSanityModalOpen(e)}
          data-testid="btn-addNew"
        >
          <p>
            {icon ? <i className={`fas ${icon}`} alt={tenantId} /> : <></>}
            {buttonName || ''}
          </p>
        </SanityButton>
      </div>
    )
  }
  return (
    <div className={`sanity-modal ${className}`} style={style}>
      <Modal
        dialogClassName="sanity-modal-container"
        show={showModal}
        onHide={() => {}}
      >
        <Modal.Body className="single-modal-body">
          <h1>Access denied</h1>
        </Modal.Body>
      </Modal>
      <SanityButton
        type="button"
        className={`interact-button sanity-button ${buttonClass}`}
        onClick={(e) => handleSanityModalOpen(e)}
        data-testid="btn-addNew"
      >
        <p>{buttonName || 'Show'}</p>
      </SanityButton>
    </div>
  )
}

export default connect(mapStateToProps)(SanityModal)
