import { useQuery, gql } from '@apollo/client'

const AUTH = gql`
  query {
    canRegularUsersAddBlogPost
  }
`

export const useCanAddBlogPost = () => {
  const { data } = useQuery(AUTH)

  return data?.canRegularUsersAddBlogPost
}