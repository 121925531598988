import React from 'react'
import ReactPlayer from 'react-player'
import InstagramEmbed from 'react-instagram-embed'
import MainImage from './main-image'
import FooterLogo from './footer-logo'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import BlockContent from '@sanity/block-content-to-react'

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>
  }
  return <></>
}

const Code = ({ node }) => {
  if (!node.language && node.code) {
    return (
      <SyntaxHighlighter style={coldarkDark} showLineNumbers={true}>
        {node.code}
      </SyntaxHighlighter>
    )
  }
  if (!node.code) return <></>
  return (
    <SyntaxHighlighter
      language={node.language}
      style={coldarkDark}
      showLineNumbers={true}
    >
      {node.code}
    </SyntaxHighlighter>
  )
}

const Instagram = ({ node }) => {
  if (!node.url) return <></>
  return (
    <InstagramEmbed
      clientAccessToken="123|456"
      url={node.url}
      maxWidth={375}
      hideCaption={false}
      containerTagName="div"
      injectScript
      protocol=""
      onLoading={() => {}}
      onSuccess={() => {}}
      onAfterRender={() => {}}
      onFailure={() => {}}
    />
  )
}

const Latex = ({ node }) => {
  if (!node.latex) return <></>
  return <p>{node.latex}</p>
}

const reduceHeader = (header) => {
  switch (header) {
    case 'h1':
      return 'h2'
    case 'h2':
      return 'h3'
    case 'h3':
      return 'h4'
    default:
      return header
  }
}

const BlockRenderer = (props) => {
  const { style = 'normal', _key } = props.node
  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    const className =
      level !== '4' ? `heading-${level} toc-header` : `heading-${level}`
    return React.createElement(
      reduceHeader(style),
      { className, id: _key },
      props.children
    )
  }

  if (style === 'blockquote') {
    return (
      <blockquote
        style={{
          fontFamily: 'Inter',
          fontWeight: '300',
          fontSize: '18px',
          lineHeight: '45px',
        }}
      >
        {props.children}
      </blockquote>
    )
  }

  if (style === 'ingress') {
    return (
      <p
        style={{
          fontFamily: 'Inter',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '28px',
        }}
      >
        {props.children}
      </p>
    )
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const highlight = (props) => {
  return <span style={{ backgroundColor: 'yellow' }}>{props.children}</span>
}

const link = (props) => {
  const linkStyles = { textDecoration: 'underline' }
  if (props?.mark?.openInNewTab) {
    return (
      <a
        href={props?.mark?.href}
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyles}
      >
        {props?.children}
      </a>
    )
  }
  return (
    <a href={props?.mark?.href} style={linkStyles}>
      {props?.children}
    </a>
  )
}

const serializers = {
  marks: {
    link: link,
    highlight: highlight,
  },
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    logo: ({ node }) => <FooterLogo mainImage={node} />,
    videoEmbed: ({ node }) => (
      <ReactPlayer
        className="mt-12 mb-12"
        style={{ maxWidth: '100%' }}
        url={node.url}
        controls
      />
    ),
    instagram: Instagram,
    math: Latex,
    code: Code,
    block: BlockRenderer,
  },
}

export default serializers
