export const toMarkers = (events) =>
  events &&
  events.reduce((acc, event) => {
    const { location } = event

    const lat = location?.lat
    const lng = location?.long

    if (lat && lng) {
      acc.push({
        lat,
        lng,
        title: event.title,
        data: event,
        dataId: event?.id,
      })
    }

    return acc
  }, [])

export default {
  toMarkers,
}
