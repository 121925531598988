import React from 'react'
import { FallbackUIStyled } from '../styles/fallback-ui'

const ErrorHandler = () => {
  return (
    <FallbackUIStyled>
      <div className="container">
        <h1 className="title">Sorry, something went wrong.</h1>
        <span className="disclamer">
          Don't worry, you can find plenty of other things on our homepage!
        </span>
        <div className="controls">
          <button
            onClick={() => {
              window.location.pathname = '/'
            }}
          >
            Back to homepage
          </button>
        </div>
      </div>
    </FallbackUIStyled>
  )
}

export default ErrorHandler
