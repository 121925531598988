import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { IconBox } from "_components/icon-picker.jsx"
import { TextButton } from '_components/indyButton'
import { SettingsDropdown } from "_components/settingsDropdown"
import { StyledWrapper, IconWrapper, CardHeader, CardBody } from "./styles"

const isEmpty = (value) => {
  return (value == null || value.length === 0);
}

export const InfoCard = ({children, start, end, url, testid, icon = "fas fa-graduation-cap"}) => {
  const intl = useIntl()
  const [details, setDetails] = useState(false)

  const current = intl.formatMessage({
    id: 'user.profile.education.date.current',
    defaultMessage: 'Current',
  })

  const show = intl.formatMessage({
    id: 'person.details.view.more',
    defaultMessage: 'Show desciption',
  })

  const hide = intl.formatMessage({
    id: 'person.details.view.less',
    defaultMessage: 'Hide description',
  })

  const startDate = () => {
    return !isEmpty(start) ? `${moment(start).format('DD MMM YYYY')} - ` : ''
  }

  const endDate = () => {
    return !isEmpty(end)
    ? moment(end).format('DD MMM YYYY')
    : current
  }

  const spreadChildren = (child) => {
    if(!child) return
    const children = child.props.children
    const isArray = Array.isArray(children)
    return isArray 
      ? child.props.children.map((child) => child)
      : child
  }

  const makeURLExternal = (url) => {
    return url && url.includes('http') ? url : `https://${url}`
  }

  return (
    <StyledWrapper className={`${details ? "open" : "closed"}`}>
      <SettingsDropdown className="settings" testid={testid}>
        {spreadChildren(children[0])}
      </SettingsDropdown>

      <CardHeader className={`${details ? "open" : "closed"}`}>
        <div className="icon">
          <IconWrapper>
            <i className={icon}></i>
          </IconWrapper>
        </div>

        <div className="text">
          {spreadChildren(children[1])}
        </div>

      </CardHeader>

      <CardBody className={`${details ? "open" : "closed"}`}>
        <a href={makeURLExternal(url)}>
          <p data-testid={`eduExp-websiteUrl-${testid}`}>{url}</p>
        </a>
        <p className="dates" data-testid={`eduExp-date-${testid}`}>
          {startDate()}
          {endDate()}
        </p>

        <div className="description">
          {spreadChildren(children[2])}
        </div>
      </CardBody>

      <TextButton 
        className="textButton" 
        onClick={() => setDetails(!details)}
      >
        <p>{details ? hide : show}</p>
        {details
          ? <IconBox icon="fa fa-minus"/>
          : <IconBox icon="fa fa-plus"/>
        }
      </TextButton>
    </StyledWrapper>
  )
}