import styled from '@emotion/styled'
import { Level } from '@patternfly/react-core'
import { pablet } from '_utils/responsive-queries'

export const SingleTag = styled(Level)`
  margin-bottom: 30px;
  font-size: 16px;
  .single-tag-link {
    transition: 0.6s;
    a {
      color: var(--flavor);
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .tag-name-wrapper {
    width: 60%;

    a {
      display: flex;
      align-items: center;
    }
    span {
      width: 65%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .fa-tag {
    font-size: 22px;
    margin-right: 25px;
  }
  .number {
    width: 10%;
    font-size: 16px;
    font-weight: 500;
  }
  &:first-child {
    margin-top: 25px;
  }
  ${pablet} {
    .single-tag-link {
      display: none;
    }
  }
`
