import styled from '@emotion/styled'
import ClickAway from '_components/click-away'

export const FooterEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  box-shadow: 0 3px 8px 0px rgb(0 0 0 / 20%);
  padding: 50px;
  border-radius: 12px;
`

export const MenuItem = styled(ClickAway)`
  --space: 20px;
  
  background: var(--background);
  border-left: 1px solid var(--shade);
  border-top: 1px solid var(--shade);

  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%);
  box-shadow: 8px 6px 19px -5px rgb(0 0 0 / 20%);
  border-radius: 12px;

  user-select: none;
  overflow: hidden;

  p { margin: 0 }

  display: grid;
  grid-template-columns: auto 1fr auto;

  .arrange {
    display: grid;
    flex-direction: column;
    justify-content: space-around;
    transition: .4s;

    border-right: 1px dotted var(--shade);

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      width: 40px;
      transition: .2s;
      &:hover {
        background-color: var(--flavor);
        color: var(--flavor-contrast);
      }
    }
  }

  input {
    padding-left: 10px;
    margin: 0px;
    color: var(--foreground);
    height: 2em;
    border: 0px solid var(--shade);
    border-left: 1px solid var(--shade);
    border-bottom: 1px solid var(--shade);
    transition: .4s;
  }

  input:disabled {
    padding: 0px;
    height: 1.2em;
    border: 0px solid var(--shade);;
    background-color: transparent;
  }

  input::placeholder {
    color: var(--shade);
  }

  .leftCap {
    display: flex;
    flex-direction: column;
    padding: var(--space);
    & > *:nth-of-type(1) { font-weight: bold }
    & > *:nth-last-child(1) { color: var(--shade) }
  }

  .rightCap {
    border-left: 1px dotted var(--shade);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space);
    padding-left: calc(1.5 * var(--space));

    button {
      padding: 0px;
      max-width: 0%;
      height: 100%;
      border: none;
      background: var(--flavor);
      overflow: hidden;
      transition: .4s;
      
      i {
        color: var(--flavor-contrast);
        transition: .4s;
      }

      &:hover i {
        transition: .2s;
        transform: rotate(40deg) scale(1.2);
      }

      &:active i {
        transition: .4s;
        transform: rotate(40deg) scale(3);
      }
    }
  }

  .addItemInputs {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &.addItemWrapper {
    //margin-top: 2em;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .addItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;
    //background: var(--flavor);
    border: none;
  }

  &.editing button {
    max-width: 45%;
    padding: var(--space);
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  gap: var(--padding, 25px);
  flex-direction: column;
  max-width: 1200px;

  & > p {max-width: 66ch;}
  p { margin-bottom: 0px;}

  .button-wrapper{
    display: flex;
    justify-content: flex-end;
    button {width: min-content}
  }

  .biglabel {
    font-size: 1.5em;
    opacity: 0.4;
    font-weight: 900;
  }
`

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 10px;
  & > div { transition: .4s; }
  &.editing > div:not(.editing) {
    opacity: 0.2;
  }

`

export const FooterColumn = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 3px 17px 1px rgb(0 0 0 / 20%);
  transition: .2s, box-shadow .8s .5s;

  margin-top: 600px;

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    pointer-events: none;
    top: 0px; left: 0px;
    width: 100%; height: 100%;
    background: var(--background);
    opacity: 0.3;
    border-radius:
      0px 12px
      0px 0px;
  }

  .label-panel {
    position: relative;
    display: flex;
    justify-content: space-between;
  
    cursor: pointer;
    padding: 5px;
    transition: .4s;

    p, label, i {
      cursor: pointer;
    }

    label, i {
      position: relative;
      z-index: 1;
    }

    .right {
      display: flex;
      gap: 12px;
      i {
        transform: rotate(180deg);
        transition: .2s;
        height: 15px;
      }
    }

    .right .actions {
      position: relative;
      overflow: hidden;
      width: 4em;
      transition: .2s;
      p {
        text-align: right;
        position: absolute;
        margin: 0px;
        transition: .4s;
      }
    }

    &::after, &::before {
      content: '';
      position: absolute;
      z-index: 0;

      opacity: 1;
      transition: .2s;
    }

    &::after {
      bottom: 0; left: 0;
      width: 100%; height: 0%;
      background-color: var(--shade);
    }

    &:hover::after {
      background-color: var(--flavor);
    }

    &::before {
      clip-path: circle(0% at 100% center);
      bottom: 0; right: 0;
      width: 8.5em; height: 100%;
      background-color: var(--flavor);
      transition: .4s .2s;
      border-radius: 
        var(--radius, 25px)
        12px 0px 0px;
    }

    &:hover::before {
      clip-path: circle(200% at 100% center);
      transition: .4s;
    }
  }

  .label-panel .actions {
    max-width: 0px;
  }

  .label-panel:hover .actions {
    max-width: 5em;
  }

  .dropdown {
    overflow: hidden;
    max-height: 700px;
    transition: .2s;
  }

  &.open {
    border-radius: var(--radius, 25px);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .label-panel {
      padding: var(--padding, 25px);
      &::after { height: 100%; opacity: 0.3;}
      &::before { width: 10em;}
    }

    .actions p:nth-of-type(1) {
      opacity: 1;
      transform: translate(0px);
      transition: .4s;
    }

    .actions p:nth-of-type(2)  {
      opacity: 0;
      transform: translate(25px);
      transition: .2s;
    }
  }

  &.closed {
    border-bottom: solid 3px var(--shade);
    box-shadow: 0 3px 17px 1px rgb(0 0 0 / 0%);
    transition: .2s, box-shadow .1s .0s;
    .label-panel {padding: 5px 12px 5px 0px;}
    .label-panel i {transform: rotate(0deg);}
    .dropdown{max-height: 0px;}

    .actions p:nth-of-type(1) {
      opacity: 0;
      transform: translate(25px);
      transition: .2s;
    }

    .actions p:nth-of-type(2) {
      opacity: 1;
      transform: translate(0px);
      transition: .4s;
    }

    &:hover {
      border-bottom: solid 3px var(--flavor);
    }
  }

  .fr-toolbar.fr-top {
    border-radius: 0px;
  }
`