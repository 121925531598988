import React from 'react'
import { renderField } from 'components/indy/user/profile/shared/field-factory'
import GoogleMaps from 'components/indy/shared/google-maps'
import {
  LocationWrapper,
  LocationContentWrapper,
  EventMapWrapper,
} from './styles/location'
import { useIntl } from 'react-intl'
import omitDeep from 'omit-deep-lodash'
import BreakLine from './break-line'


const Map = ({location}) => {
  const marker = {
    ...location,
    lng: location?.long,
  }

  return (
    <EventMapWrapper>
      <GoogleMaps
        markers={[marker]}
        onMarkerClick={() => { }}
        getVisibleMarkers={''}
        onBoundsChange={() => { }}
      />
    </EventMapWrapper>
  )
}

const MemoizedMap = React.memo(Map);

const Location = ({ onSave, location, desc }) => {
  const intl = useIntl()

  const ommitedLocation = omitDeep(location, '__typename')

  const locationSave = (value) => {
    const loc = value?.label?.split(',')
    return onSave({
      ...ommitedLocation,
      city: loc?.[0],
      country: loc?.[1],
      label: value.label,
      long: `${value.long}`,
      lat: `${value.lat}`,
    })
  }

  return (
    <>
      <LocationWrapper>
        <div className="label-wrapper">
          <label htmlFor="location-content">
            {intl.formatMessage({
              id: 'events.createevent.general.location.title',
            })}
          </label>
          <p className="location-content-desc">
            {desc}
          </p>
        </div>
        <div className="location-content-wrapper">
          <LocationContentWrapper>
            <>
              {renderField({ 
                attribute: {
                  type: 'location',
                  value: location?.label,
                },
                onSave: (f, value) => locationSave(value),
              })}

              <MemoizedMap location={location}/>
            </>
          </LocationContentWrapper>
        </div>
      </LocationWrapper>
      <BreakLine />
    </>
  )
}

export const MemoizedLocation = React.memo(Location);

export default Location

