import styled from '@emotion/styled'
import css from '@emotion/css'
import Card from '../card'
import { mobile, pablet, tablet } from '_utils/responsive-queries'

export const CardStyled = styled(Card)`
  align-items: center;
  align-self: normal;
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  ${pablet} {
    width: 100%;
  }
  ${mobile} {
    position: relative;
    .tooltipIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  /* max-width: 25%; */
  word-break: break-word;
  flex-shrink: 0;
  &.location,
  &.title-date {
    max-width: 45%;
  }
  &.file {
    align-self: center;
  }
  &.title-file {
    align-self: center;
  }
  &.title-badges {
    max-width: 100%;
  }

  ${pablet} {
    padding: 0 40px 0 15px;
    &.file {
      align-self: flex-start;
    }
  }
  ${tablet} {
    max-width: none;
  }
`
export const CAWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;
  font-size: 16px;
  color: var(--foreground);

  .images {
    width: 100%;
  }

  .file-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: var(--foreground);
    :hover {
      text-decoration: underline;
    }

    p {
      margin: 0 0 0 9px;
      color: var(--foreground);
    }
  }

  ${pablet} {
    justify-content: ${({ isBadges }) => isBadges && 'center'};
    gap: 20px;
    padding: 20px 15px;
  }
  ${mobile} {
    justify-content: center;
    padding: 0;
  }
`

// export const ImageAttr = styled.img`
//   margin-top: 15px;
//   border-radius: 10px;
//   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
// `

export const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  width: 100px;
  gap: 10px;
  img {
    width: 66px;
    height: 66px;
    border-radius: 100%;
    border: 2px solid var(--foreground);
    flex-shrink: 0;
    object-fit: cover;
    object-position: center;
  }
  .badge-name {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
//TODO: could use CAWrapper instead for Content component?
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 18px;
  align-items: center;
  justify-content: ${({ type }) =>
    type === 'images' ? 'center' : 'flex-start'};
`

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 620px;
  margin: 10px 0 40px;

  .image-wrapper {
    position: relative;
    width: 286px;
    height: 180px;
    margin: 12px;

    ${({ isOnlyOne }) =>
      isOnlyOne &&
      css`
        width: 100%;
        max-width: 600px;
        height: 350px;
      `}

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.16));
    }
  }

  ${mobile} {
    margin: 0;
  }
`
