import styled from '@emotion/styled'

const ToggleButtonWithNameStyled = styled.div`
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px var(--flavor);
  }

  margin-top: 15px;
  .name {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-size: 14px;
    font-weight: 500;
    color: var(--foreground);
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 16px;
    display: block;
    margin-top: 4px;
  }

  .status {
    font-size: 16px;
    display: block;
    margin-top: 10px;
    strong {
    }
  }

  strong {
    text-transform: uppercase;
  }

  .toggle-alt {
    margin-top: 6px;
    clear: both;
  }

  .react-toggle.react-toggle--checked {
    .react-toggle-track {
      background-color: var(--flavor);
    }
    .react-toggle-thumb {
      left: unset;
      right: 1px !important;
      border: none;
    }
    .react-toggle-track-check {
      margin-left: 0px;
    }
    &:hover {
      .react-toggle-track {
        background-color: var(--flavor) important;
      }
    }
  }

  .react-toggle {
    .react-toggle-track {
      background-color: var(--shade);
    }
    .react-toggle-thumb {
      border: none;
    }
    &:hover {
      .react-toggle-track {
        background-color: var(--shade) !important;
      }
    }
  }

  &.user-profile {
    .name {
      display: block;
      position: relative;
    }
  }

  &.large {
    .react-toggle.react-toggle--checked {
      .react-toggle-track {
        width: 80px;
        height: 40px;
        background-color: var(--flavor);
        .react-toggle-track-check {
          min-width: 85px;
        }
      }
      .react-toggle-thumb {
        width: 32px;
        height: 32px;
        top: 4px;
        left: unset;
        right: 4px !important;
        border: none;
      }
      .react-toggle-track-check {
        margin-left: 10px;
      }
      &:hover {
        .react-toggle-track {
          background-color: var(--flavor) !important;
        }
      }
    }

    .react-toggle {
      .react-toggle-track {
        width: 80px;
        height: 40px;
        background-color: var(--shade);
      }
      .react-toggle-thumb {
        width: 32px;
        height: 32px;
        top: 4px;
        left: 4px;
        border: none;
      }

      .react-toggle-track-x {
        margin-right: 10px;
        min-width: 85px;
        svg {
          right: 2.5px;
          position: absolute;
        }
      }
      &:hover {
        .react-toggle-track {
          background-color: var(--shade) !important;
        }
      }
    }

    &.user-profile {
      .name {
        display: block;
      }
    }
  }
`

export default ToggleButtonWithNameStyled
