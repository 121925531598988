import React, { useState } from 'react'
import { Grid, GridItem } from '@patternfly/react-core'
import OpportunityApplicantsList from './application-list'
import NavBar from './nav-bar'
import { omit, values } from 'lodash'
import BackButton from '../shared/backButton'
import { useQuery } from '@apollo/client'
import { GET_APPLICATIONS_FOR_OPPORTUNITY } from './gql'
import FetchMore from './fetch-more'
import { mergePage } from '_utils/graph-pagination'
import { nextPage } from '_utils/graph-pagination'
import { GET_OPPORTUNITY } from '_opportunities/connected/opportunity-details-connected'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ApplicationManagementStyled, NoItemsDivStyled } from './styled/index'
import UserProfileModal from '../shared/user-profile-modal'
import { ApplicationManagers } from '../opportunity-application-detail/application-managers'
import { OpportunityInfo } from '../shared/opportunity-info'

const intlKey = 'application.management.'

const OpportunityApplicationsManagement = () => {
  const intl = useIntl()
  const { opportunityId } = useParams()

  const { data: opportunityData } = useQuery(GET_OPPORTUNITY, {
    variables: { id: opportunityId },
  })

  const opportunity = opportunityData?.getOpportunity

  const [selectedItems, setSelectedItems] = useState({})

  const [selectedList, setSelectedList] = useState('all')
  const [sort, setSort] = useState('received')
  const [stateFilters, setStateFilters] = useState([])

  const pageSize = 10

  let params = {
    categoryFilter: selectedList === 'all' ? undefined : selectedList,
    stateFilters: stateFilters,
    sorting: sort,
    paging: { offset: 0, limit: pageSize },
    opportunityId: opportunity?.id,
  }

  const { data, loading, fetchMore, refetch } = useQuery(
    GET_APPLICATIONS_FOR_OPPORTUNITY,
    {
      variables: params,
    }
  )

  const { hasMore, data: items, totalCount, lists } =
    data?.getApplicationsPaged || {}

  const updateParams = () => {
    params = { ...params, ...nextPage(items?.length, pageSize) }
  }

  updateParams()

  const updateQuery = mergePage('getApplicationsPaged')

  const onItemCheckChange = function (item) {
    if (selectedItems[item.id]) {
      setSelectedItems(omit(selectedItems, item.id))
    } else {
      setSelectedItems({ ...selectedItems, [item.id]: item })
    }
  }

  const toggleAll = function () {
    // if (size(selectedItems) === allItems.length) {
    //   setSelectedItems({})
    // } else {
    //   setSelectedItems(
    //     allItems.reduce((acc, curr) => {
    //       acc[curr.id] = curr
    //       return acc
    //     }, {})
    //   )
    // }
  }

  if (!opportunity?.isManager) return <></>

  return (
    <ApplicationManagementStyled>
      <BackButton path={`/opportunities/list/${opportunity?.id}`}>
        {intl?.formatMessage({ id: `${intlKey}backtocommunity` })}
      </BackButton>
      <OpportunityInfo
        opportunity={opportunity}
        applicationCount={totalCount}
      />
      <NavBar
        selectedItems={values(selectedItems)}
        lists={lists}
        toggleAll={toggleAll}
        selectedListState={[selectedList, setSelectedList]}
        sortState={[sort, setSort]}
        stateFilterState={[stateFilters, setStateFilters]}
      />

      {(() => {
        return (
          <Grid hasGutter>
            <GridItem sm={12} md={opportunity.isOwner ? 8 : 12}>
              {!loading && items?.length === 0 && (
                <NoItemsDivStyled>
                  <span>
                    {intl?.formatMessage({ id: `${intlKey}noresults` })}
                  </span>
                </NoItemsDivStyled>
              )}

              {items?.length > 0 && (
                <>
                  <OpportunityApplicantsList
                    loading={loading}
                    items={items}
                    onItemCheckChange={onItemCheckChange}
                    selectedItems={selectedItems}
                    refetch={refetch}
                    variables={params}
                  />
                  <FetchMore
                    hasMore={hasMore}
                    loader={fetchMore}
                    offset={items?.length}
                    updateQuery={updateQuery}
                    pageSize={pageSize}
                    variables={params}
                  />
                </>
              )}
            </GridItem>
            {opportunity.isOwner && !loading && (
              <GridItem sm={12} md={4}>
                <ApplicationManagers opportunity={opportunity} />
              </GridItem>
            )}
          </Grid>
        )
      })()}
      <UserProfileModal />
    </ApplicationManagementStyled>
  )
}

export default OpportunityApplicationsManagement
