import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_ATTRIBUTES,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  ADD_ATTRIBUTE,
} from './graph-queries'

import GenericDataManager from '../shared'

const CustomAttributes = () => {
  const { data: attributeData, refetch } = useQuery(GET_ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE)
  const [deleteAttribute] = useMutation(DELETE_ATTRIBUTE)
  const [addAttribute] = useMutation(ADD_ATTRIBUTE)

  const handleToggleUsedBy = (value, keyword) => {
    const key = JSON.parse(JSON.stringify(keyword))
    key.usedBy = keyword.usedBy?.includes(value)
      ? keyword.usedBy.filter((k) => k !== value)
      : [...(keyword.usedBy || []), value]
    updateAttribute({
      variables: { key: key.key, usedBy: key.usedBy },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_ATTRIBUTES }],
    })
  }

  const handleDelete = (key) => {
    deleteAttribute({
      variables: { key: key.key },
      refetchQueries: [{ query: GET_ATTRIBUTES }],
    })
  }

  const handleUpdate = (key) => {
    updateAttribute({
      variables: {
        key: key.key,
        badges: key.badges,
        type: key.type,
        name: key.name,
        description: key.description,
        isSearchable: key.isSearchable,
        isPrivate: key.isPrivate,
      },
      refetchQueries: [{ query: GET_ATTRIBUTES }],
    })
  }

  const handleAdd = (key) => {
    addAttribute({
      variables: {
        name: key.name,
        badges: key.badges,
        type: key.type,
        description: key.description,
        isSearchable: key.isSearchable,
        isPrivate: key.isPrivate,
      },
      refetchQueries: [{ query: GET_ATTRIBUTES }],
    })
  }

  return (
    <GenericDataManager
      data={attributeData?.getAttributes}
      refetch={refetch}
      onToggleUsedBy={handleToggleUsedBy}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onAdd={handleAdd}
      type="attribute"
    />
  )
}

export default CustomAttributes
