import React from 'react'
import Loading from '_components/loading'
import PeopleGrid from '_people/components/people-grid'

const People = ({ items: people, loading }) => {
  if (!people) return <Loading />

  return (
    <div>
      {people && (
        <PeopleGrid
          people={people.map((p) => {
            return {
              person: p?.data,
            }
          })}
        />
      )}
    </div>
  )
}

export default People
