import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_KEYWORDS,
  UPDATE_KEYWORD,
  DELETE_KEYWORD,
  ADD_KEYWORD,
} from './graph-queries'
import { omit } from 'lodash'

import GenericDataManager from '../shared'

const Keywords = () => {
  const { data: keywordData, refetch } = useQuery(GET_KEYWORDS, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateKeyword] = useMutation(UPDATE_KEYWORD)
  const [deleteKeyword] = useMutation(DELETE_KEYWORD)
  const [addKeyword] = useMutation(ADD_KEYWORD)

  const handleToggleUsedBy = (value, keyword) => {
    const key = JSON.parse(JSON.stringify(keyword))
    key.usedBy = keyword.usedBy.includes(value)
      ? keyword.usedBy.filter((k) => k !== value)
      : [...keyword.usedBy, value]
    updateKeyword({
      variables: {
        key: key.key,
        keywordList: key.keywordList?.map((k) => omit(k, '__typename')),
        usedBy: key.usedBy,
      },
      refetchQueries: [{ query: GET_KEYWORDS }],
    })
  }

  const handleDelete = (key) => {
    deleteKeyword({
      variables: { key: key.key },
      refetchQueries: [{ query: GET_KEYWORDS }],
    })
  }

  const handleUpdate = (key) => {
    updateKeyword({
      variables: {
        key: key.key,
        keywordList: key.keywordList?.map((k) => omit(k, '__typename')),
        name: key.name,
        description: key.description,
        isSearchable: key.isSearchable,
        isMatchable: key.isMatchable,
      },
      refetchQueries: [{ query: GET_KEYWORDS }],
    })
  }

  const handleAdd = (key) => {
    addKeyword({
      variables: {
        keywordList: key.keywordList?.map((k) => omit(k, '__typename')),
        name: key.name,
        description: key.description,
        isSearchable: key.isSearchable,
        isMatchable: key.isMatchable,
      },
      refetchQueries: [{ query: GET_KEYWORDS }],
    })
  }

  const notEditableKeys = []

  return (
    <GenericDataManager
      data={keywordData?.getKeywords}
      refetch={refetch}
      onToggleUsedBy={handleToggleUsedBy}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onAdd={handleAdd}
      notEditableKeys={notEditableKeys}
      type="keyword"
    />
  )
}

export default Keywords
