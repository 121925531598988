import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { TileWithIcon } from '_components/tile'
import EDUCATION_ICON from 'resources/education-icon.png'

const Props = {
  education: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
}
const EducationTile = ({ education, className }) => (
  <TileWithIcon className={className} icon={EDUCATION_ICON}>
    <Body>
      <Title>{education.schoolName}</Title>
      <Domain>{education.domain}</Domain>
      <Degree>{education.degree}</Degree>
      <Graduated>{education.yearGraduated}</Graduated>
    </Body>
  </TileWithIcon>
)
EducationTile.propTypes = Props

const Body = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
`
const Title = styled.div`
  font-size: 16px;
  color: '#525252';
  font-weight: 500;
`
const Domain = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  color: #999;
`
const Degree = styled.div`
  font-size: 14px;
  color: #525252;
  font-weight: 500;
`
const Graduated = styled.div`
  margin-bottom: 1rem;
  font-size: 12px;
  color: #999;
`

export default EducationTile
