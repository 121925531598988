import { css } from '@emotion/core'
import { onionBloom, clipBloom, stretch } from './css-animations'
import { ctaAnim, ctaAnimHollow } from './css-default-animations'

const animCTA = (props) => {
  const special = false
  return special ? onionBloom(props) : ctaAnim(props)
}

const animHollowCTA = (props) => {
  const special = false
  return special ? onionBloom(props) : ctaAnimHollow(props)
}

const taggBTN = (props) => {
  const special = false
  return special ? clipBloom(props) : ctaAnimHollow(props)
}

export const circleButton = (props) => css`
  position: relative;

  border-radius: 50%;
  background: transparent;
  color: var(--foreground);
  border: 2px solid var(--background);

  font-size: 12px;
  width: 42px;
  height: 42px;
  min-width: 42px;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0px;
  }

  i {
    font-size: 1.2em;
    transition: 0.2s ease-in-out;
    color: var(--stroke-color);
  }

  ${stretch}
`

export const cancelButton = (props) => css`
  position: relative;
  overflow: hidden;
  z-index: 1;

  & * {
    position: relative;
    z-index: 1;
  }

  ${clear}
  ${basic(props)}
`

const clear = css`
  & > * {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500;
  }

  *:nth-child(2) {
    margin-left: 10px;
  }
`

const basic = (props) => css`
  border-radius: 30px;
  border: 2px solid var(--flavor);
  cursor: pointer;

  padding: 6px 25px;
  height: 40px;
  max-height: 42px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    background-color: var(--shade);
    border-color: var(--shade);
  }
`

export const ctaButton = (props) => css`
  ${clear}
  ${basic(props)}
  ${animCTA(props)}
`

export const ctaButtonHollow = (props) => css`
  ${clear}
  ${basic(props)}
  ${animHollowCTA(props)}
`

export const taggButton = (props) => css`
  border-radius: 30px;
  border: 2px solid var(--flavor);
  color: var(--foreground);

  & > * {
    margin: 0px;
  }

  ${taggBTN(props)}
`

export default circleButton
