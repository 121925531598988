import React from 'react'
import { Grid as PfGrid, GridItem } from '@patternfly/react-core'
import Sidebar from './sidebar.js'
import MainContent from './mainContent.js'

const Grid = ({ application }) => {
  return (
    <PfGrid>
      <GridItem span={8}>
        <MainContent application={application} />
      </GridItem>
      <GridItem span={4}>
        <Sidebar application={application}></Sidebar>
      </GridItem>
    </PfGrid>
  )
}

export default Grid
