import React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useAppConfig, useAppLabel } from '_application'
import { useIntl } from 'react-intl'
import Header from 'components/indy/shared/logged/header'
import { defaultLoginBackground } from '_resources'
import SDKContainer from '_components/sdk-container'
import LoginTitle from '_components/login-title'
import LoginRegister from '_uikit/login-register'
import { LoginContainer } from '_themes/default/components/styled/login-register'
import { useSetThemeSettings } from '_application'
import { useThemeSettings } from '_application'
import styled from '@emotion/styled'
import { mobilePX, useWindowResize } from '_utils/responsive-queries'

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const mapStateToProps = ({ settings, appConfig }) => ({ settings, appConfig })

const Index = ({ children }) => {
  const intl = useIntl()
  const location = useLocation()
  const config = useAppConfig()
  const appLabel = useAppLabel()
  useSetThemeSettings({ hasAlternativeBgc: true })
  const themeSettings = useThemeSettings()

  const [windowQuery] = useWindowResize()

  const description = appLabel('loginDescription', 'login.description')

  const backgroundImage = config?.backgroundImages?.login
  const isBackgroundVideo = backgroundImage?.type === 'video'
  const backgroundUrl = backgroundImage?.value || defaultLoginBackground

  return (
    <>
      <Header />

      <LoginRegister themeSettings={themeSettings}>
        <LoginContainer>
          <Row className="login-container">
            <Col className="login-col">
              <h2 className="login-title">
                {location.pathname === '/login'
                  ? `${intl.formatMessage({ id: 'login.page.title' })}`
                  : `${intl.formatMessage({ id: 'register.page.title' })}`}
              </h2>
              <SDKContainer>{children}</SDKContainer>
            </Col>
            <Col className="image-col">
              {isBackgroundVideo && windowQuery > mobilePX ? (
                <Video
                  className="background"
                  width="100%"
                  height="100%"
                  src={backgroundUrl}
                  playsinline
                  autoPlay
                  muted
                  loop
                />
              ) : (
                <img className="thumbnail" src={backgroundUrl} alt="" />
              )}
              <LoginTitle>{description}</LoginTitle>
            </Col>
          </Row>
        </LoginContainer>
      </LoginRegister>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(Index))
