import React from 'react'

import {
  ItemsWrapper,
  RemoveButton,
  SelectedItem,
  Info,
  SelectedAvatar,
  SelectedName,
  Actions,
  ConnectionButton as ConnectionStatus
} from '_events/components/event-creator/components/styles/section-with-search'

import images from '_themes/default/images'

import { useIntl } from 'react-intl'

const Status = ({
  connectionStatus,
}) => {
  const intl = useIntl()

  const isConnected = connectionStatus === 'accepted'
  const isCanceled = connectionStatus === 'cancelled'

  const renderIcon = () => {
    switch (connectionStatus) {
      case 'accepted':
        return <i class="fas fa-check-circle" />
      case 'cancelled':
        return <i class="fas fa-times-circle" />
      case 'requested':
        return <i className="fa fa-clock" />
      default:
        break
    }
  }

  return (
    <ConnectionStatus
      isConnected={isConnected}
      isCanceled={isCanceled}
    >
      {renderIcon(connectionStatus)} 
      <span className="text">
        {intl.formatMessage({
          id: `events.detailview.connectionrequest.button.label.${connectionStatus}`,
        })}
      </span>
    </ConnectionStatus>
  )
}

const ConnectedList = ({ connectionList, handleRemove, entityType, connectionType }) => {
  return (
    <ItemsWrapper>
      {connectionList?.map((item) => {

        const info = { 
          title: item?.title || item?.name,
          coverPhoto: item?.coverPhoto || images?.defaultCover
        }

        if (entityType === "user") {
          info.title = item?.name || `${item?.creator?.firstName} ${item?.creator?.lastName}`
          info.coverPhoto = item?.avatar || item?.creator?.avatar || images?.defaultAvatar
        }

        const isEvent = connectionType === 'e2p' 
        || connectionType === 'e2c' 
        || connectionType === 'e2a'
        
        return (
          <SelectedItem>
            <Info> 
              <SelectedAvatar src={info.coverPhoto} />
              <SelectedName>{info.title}</SelectedName>
            </Info>
            <Actions>
              {isEvent && <Status connectionStatus={item?.connectionStatus} />}
              {item.connectionStatus !== 'cancelled' && (
                <RemoveButton onClick={() => handleRemove(item)}>
                  {item.eventConnection || <i className="fas fa-times" />}
                </RemoveButton>
              )}
            </Actions>
          </SelectedItem>
        )
      })}
    </ItemsWrapper>
  )
}

export default ConnectedList