import React from 'react'
import { useIntl } from 'react-intl'
import { find } from 'lodash'
import moment from 'moment'
import { sortAttributes } from '_custom-attributes/components/list'
import { useCustomAttributes } from '_custom-attributes/hooks'
import { BoolCard, BoolWrapper } from './card'
import { BadgeComponent } from '_components/drilldown/drilldown-tag-with-icon-list'
import {
  ImageWrapper,
  ImgTitle,
  CAWrapper,
  CardStyled,
  Title,
  // FileWrapper,
  // DownloadLink,
} from './styles/custom-attributes'

const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const ConditionalWrapper = ({ hasWrapper, children }) => {
  return hasWrapper ? <BoolWrapper>{children}</BoolWrapper> : <>{children}</>
}

const CustomAttributes = ({ person }) => {
  const intl = useIntl()

  const { attributes: customAttributes } = useCustomAttributes('people')
  const personAttributes = person.customAttributes

  const getAttrValue = (attr) => find(personAttributes, { key: attr.key })
  if (!customAttributes) return null

  const sortedAttributes = sortAttributes(customAttributes, personAttributes)

  return (
    <>
      {sortedAttributes.map((attributeWrapper) => {
        const wrapperValues = attributeWrapper.values

        if (attributeWrapper.key === 'boolean') {
          const boolValues = wrapperValues.map(
            (value) => personAttributes.find((pa) => pa.key === value.key).value
          )

          return (
            <ConditionalWrapper hasWrapper={boolValues.includes('true')}>
              {wrapperValues.map((value) => {
                const boolValue = personAttributes.find(
                  (pa) => pa.key === value.key
                ).value

                const isBooleanActive = isJson(boolValue)
                  ? JSON.parse(boolValue)
                  : boolValue

                return isBooleanActive ? (
                  <BoolCard tooltipText={value.description}>
                    <span className="boolwrapper">
                      <i className="fas fa-flag" />
                      <span className="boolname">{value.name}</span>
                    </span>
                  </BoolCard>
                ) : (
                  <></>
                )
              })}
            </ConditionalWrapper>
          )
        }

        return wrapperValues.map((value) => {
          const activeAttributeValues = () => {
            const values = personAttributes.find((pa) => pa.key === value.key)
              .value

            return isJson(values) ? JSON.parse(values) : values
          }

          const pickActiveBadges = () => {
            const activeBadgesIds = activeAttributeValues()

            return activeBadgesIds && activeBadgesIds.length > 0
              ? value.badges.filter((badge) =>
                  activeBadgesIds.includes(badge.key)
                )
              : []
          }

          const hasValues = () => {
            const userCAValues = activeAttributeValues()
            return (
              userCAValues &&
              (userCAValues.length > 0 || Object.keys(userCAValues).length > 0)
            )
          }

          const resolvedValue = getAttrValue(value)
          let cardTitle = value.name
          let tooltipText = value.description
          let cardValue
          let cardIcon
          let type

          switch (attributeWrapper.key) {
            case 'file':
              const file = JSON.parse(resolvedValue.value).file
              cardValue = (
                <>
                  <span className="file-name">{file.name}</span>
                  <div className="grow" />
                  <a
                    className="file-link" 
                    href={file?.url || "#"}
                    rel="noopener noreferrer"
                    target="_blank" 
                  >
                    {intl.formatMessage({
                      id: 'person.details.user.customeattr.file.downloadbutton',
                    })}
                  </a>
                </>
              )
              type = 'file'
              break
            case 'badge': {
              cardValue =
                pickActiveBadges()?.length > 0
                  ? pickActiveBadges().map(BadgeComponent)
                  : null
              type = 'badges'
              break
            }
            case 'url':
              cardValue = (
                <a
                  href={resolvedValue.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {resolvedValue.value}
                </a>
              )
              break
            case 'email':
              cardValue = (
                <a
                  href={'mailto:' + resolvedValue.value}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {resolvedValue.value}
                </a>
              )
              break
            case 'date':
              cardValue = moment(getAttrValue(value).value).format(
                'DD MMM YYYY'
              )
              cardIcon = 'fas fa-calendar-day'
              type = 'date'
              break
            case 'location':
              cardValue = (
                <span>
                  <i className="fas fa-map-marker-alt" />
                  <span>{JSON.parse(getAttrValue(value).value).label}</span>
                </span>
              )
              type = 'location'
              break

            case 'image':
              const resolvedImg = getAttrValue(value)
              return (
                <ImageWrapper>
                  <ImgTitle>{value.name}:</ImgTitle>
                  <img src={resolvedImg.value} alt="" />
                </ImageWrapper>
              )
            default:
              cardValue = getAttrValue(value).value
          }

          return hasValues() ? (
            <CardStyled tooltipText={tooltipText}>
              <div className="content-wrapper">
                <Title className={`title-${type || ''}`}>{cardTitle}</Title>
                <CAWrapper
                  className={type || ''}
                  isBadges={attributeWrapper.key === 'badge'}
                >
                  {cardIcon && <i className={cardIcon} />}
                  {cardValue}
                </CAWrapper>
              </div>
            </CardStyled>
          ) : (
            <></>
          )
        })
      })}
    </>
  )
}

// TODO: Maybe relevant for later
/*const File = ({ file }) => {
  const intl = useIntl()
  return (
    <Card>
      <FileWrapper>
        <span className="label">
          {intl.formatMessage({
            id: 'person.details.user.customeattr.file',
          })}
          :
        </span>
        <span className="file-name">{file.name}</span>
        <DownloadLink
          className="file-link"
          href={file?.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {intl.formatMessage({
            id: 'person.details.user.customeattr.file.downloadbutton',
          })}
        </DownloadLink>
      </FileWrapper>
    </Card>
  )
}*/

export default CustomAttributes
