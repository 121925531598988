import styled from '@emotion/styled'
import { desktop } from '_utils/responsive-queries'
import Modal from 'react-bootstrap/Modal'

export const ModalStyled = styled(Modal)`
  .modal-content,
  .modal-body {
    border: none;
    background: var(--background);
    overflow: hidden;
  }
  *,
  input,
  select,
  label {
    font-family: ${({ theme }) => theme.fonts.googleFont + ', sans-serif'};
  }
  i {
    font-family: 'Font Awesome 5 Free';
  }
`

export const Section = styled.div``

export const CheckoutStyled = styled.form`
  display: flex;
  flex-direction: column;
  ${desktop} {
    flex-direction: row;
    column-gap: 1rem;
    row-gap: 0rem;
  }
  row-gap: 1rem;
`

export const StripeElements = styled.div`
  input {
    border: 1px solid var(--foreground-shade);
  }
`

export const Column = styled.div`
  ${desktop} {
    width: 50%;
    :first-of-type {
      padding-right: 1.5rem;
      border-right: 1px solid var(--shade);
    }
  }

  padding: 0.5rem;
`

export const ModalTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
`

export const ModalSubtitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 400;
`

export const Content = styled.div`
  margin-bottom: 25px;
`

export const CardInput = styled.div`
  margin-bottom: 10px;
  border: 1px solid var(--foreground-shade);
  padding: 5px 10px;
  border-radius: 4px;
`

export const CardDetails = styled.div``

export const SaveInfoText = styled.div`
  font-size: 12px;
  margin-left: 5px;
  color: var(--foreground-shade);
`

export const SaveCardOption = styled.label`
  display: flex;
  align-items: center;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
`

export const Info = styled.div``

export const ButtonsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`

export const Button = styled.button`
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: 5px 20px;
  border-radius: 25px;
  text-align: center;
  border: 2px solid;
  border-color: var(--flavor);
  background-color: transparent;
  cursor: pointer;
  :hover {
    background-color: var(--flavor);
    color: var(--background);
  }
  &.active,
  &.subscribe {
    background-color: var(--flavor);
    border-color: var(--flavor);
    color: var(--background);
    :hover {
      color: var(--background);
    }
  }
  &.subscribe {
    font-weight: 700;
  }
`
export const Label = styled.label`
  margin-bottom: 10px;
  flex-grow: 1;
  select:invalid {
    color: var(--foreground-shade);
  }
  input,
  select {
    color: var(--foreground);
    ::placeholder {
      color: var(--foreground-shade);
    }
    background: transparent;
    width: 100%;
    appearance: none;
    box-shadow: none;
    border: 1px solid var(--foreground-shade);
    padding: 5px 10px;
    border-radius: 4px;
  }
  .help {
    color: var(--foreground-shade);
    font-size: 12px;
  }
`

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CityAndPostcodeWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

export const IndividualOrCompanyWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`
