import styled from '@emotion/styled'

const Item = styled.li`
  position: relative;
  padding: 50px 70px;
  border: 1px solid var(--shade) !important;
  &:last-of-type {
    display: block !important;
  }
  span {
    display: block;
  }
  .name {
    color: var(--foreground);
    font-weight: 700;
    font-size: 16px;
  }
  .description {
    color: var(--foreground);
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 15px;
  }
  .values-list {
    padding: 0;
    li {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;

      margin: 1px 3px;
      padding: 5px 10px;
      border: none;
      border-radius: 8px;

      color: var(--background);
      background: var(--foreground);
    }
  }
  button {
    position: absolute;
    border-radius: 50%;
    border: none;
    height: 25px;
    width: 25px;
    font-size: 10px;
    background: var(--shade);
    color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    &.edit {
      top: 10px;
      right: 10px;
    }
  }
`
export default Item
