import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import BreakLine from './break-line'
import { EventTitleWrapper } from './styles/event-title'

const EventTitle = ({ eventTitle, onSave }) => {
  const eventTitleChatacterLimit = 40
  const [value, setValue] = useState(eventTitle || '')
  const intl = useIntl()
  return (
    <>
    <EventTitleWrapper>
      <label htmlFor="eventTitle">
        {intl.formatMessage({
          id: 'events.createevent.general.eventtitle.title',
        })}
        <span className="required-star">*</span>
      </label>
      <input
        type="text"
        name="eventTitle"
        className="title-input"
        placeholder={intl.formatMessage({
          id: 'events.createevent.general.eventtitle.placeholder',
        })}
        maxLength={eventTitleChatacterLimit}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onSave(value)}
      />
    </EventTitleWrapper>
    <BreakLine/>
    </>

  )
}

export default EventTitle
