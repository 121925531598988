import React from 'react'
import styled from '@emotion/styled'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import BlogGrid from '_blog/components/blog-grid'
import DiscoverSection from '../components/discover-section'
import { useColorBucketForced } from "_colorBus/hooks"

const GET_CATEGORIES = gql`
  query {
    getPostCategories {
      id
      title 
    }
  }
`

const GET_DATA = gql`
  query GetPostCategories($paging: PagingInput, $filter: PostsFilterInput) {
    getPostsPublic(paging: $paging, filter: $filter) {
      data {
        id
        title
        coverPhoto
        description
        categories {
          id
          title
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 0px 0px 100px;
  background-color: var(--background);
`

const SectionBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 400px;
`

const DiscoverPeopleConnected = ({ filter }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()

  const { data: categoriesData, error: categoriesError } = useQuery(
    GET_CATEGORIES
  )
  const { data: postsData, error: postsError } = useQuery(GET_DATA, {
    variables: {
      paging: { offset: 0, limit: 4 },
    },
    fetchPolicy: 'no-cache',
  })

  const title = intl.formatMessage(
    { id: 'discover.latestcategory' },
    {
      category: appLabel('blogPlural', 'news.titleplural'),
    }
  )
  const description = appLabel('blogDescription', 'empty')
  const link = '/blog'
  const posts = postsData?.getPostsPublic?.data
 
  const [getElement] = useColorBucketForced("blog")

  return ( 
    <div>
      {posts?.length > 0 ? (
        <Container ref={getElement} className="sectionContainer page-wrapper stretchColorToScreensize">
          <SectionBackground/>
          <DiscoverSection title={title} link={link} description={description}>
            <Loader
              loading={!postsData || !categoriesData}
              error={postsError || categoriesError}
            >
              {() => <BlogGrid url={'/discover/news/'} posts={posts} />}
            </Loader>
          </DiscoverSection>
        </Container>
      ) : null}
    </div>
  )
}

export default DiscoverPeopleConnected
