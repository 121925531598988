import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useAppConfig } from './app-config'

const Props = {
  children: PropTypes.string.isRequired,
  bundleId: PropTypes.string.isRequired,
}

export const useAppLabel = () => {
  const appConfig = useAppConfig()
  const intl = useIntl()

  return useMemo(
    () => (labelKey, bundleId) => {
      return (
        appConfig?.labelOverrides?.[labelKey]?.value ||
        intl.formatMessage({ id: bundleId })
      )
    },
    [appConfig, intl]
  )
}

export const AppLabel = ({ children, bundleId }) => {
  const AppLabel = useAppLabel()
  return AppLabel(children, bundleId)
}
AppLabel.propTypes = Props

export default AppLabel
