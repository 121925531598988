import gql from 'graphql-tag'

export const GET_KEYWORDS = gql`
  query {
    getKeywords {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      keywordList {
        id
        value
      }
      isSearchable
      isMatchable
    }
  }
`

export const ADD_KEYWORD = gql`
  mutation AddKeyword(
    $keywordList: [KeywordListItemInput]
    $description: String
    $name: String
    $isMatchable: Boolean
    $isSearchable: Boolean
  ) {
    addKeyword(
      keywordList: $keywordList
      name: $name
      description: $description
      isMatchable: $isMatchable
      isSearchable: $isSearchable
    ) {
      key
    }
  }
`

export const UPDATE_KEYWORD = gql`
  mutation UpdateKeyword(
    $key: String
    $keywordList: [KeywordListItemInput]
    $usedBy: [String]
    $description: String
    $name: String
    $isMatchable: Boolean
    $isSearchable: Boolean
  ) {
    updateKeyword(
      key: $key
      keywordList: $keywordList
      usedBy: $usedBy
      name: $name
      description: $description
      isMatchable: $isMatchable
      isSearchable: $isSearchable
    ) {
      key
      name
      description
      dateCreated
      dateUpdated
      usedBy
      keywordList {
        id
        value
      }
    }
  }
`

export const DELETE_KEYWORD = gql`
  mutation DeleteKeyword($key: String) {
    deleteKeyword(key: $key) {
      name
    }
  }
`
