import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as service from './messages-service'
import { useEventBus } from '_event-bus'
import {
  setCurrentConversation,
  setConversations,
  setCurrentConversationMessages,
} from './messages-actions'

export const useMessages = (contextKey, resolveContextKey) => {
  const dispatch = useDispatch()
  const eventBus = useEventBus()
  const { messages, user } = useSelector((state) => state)

  useEffect(() => {
    const unsubMethods = []
    unsubMethods.push(
      eventBus.on('NEW_MESSAGE', (msg) => {
        const { id, from, channelId } = msg
        // channel based version
        if (contextKey) {
          if (contextKey === resolveContextKey(msg)) {
            service
            .fetchMessages({ channelId })
            .then((messages) => {
              dispatch(setCurrentConversationMessages(messages, contextKey))
            })
            .catch((e) => {
              console.error('Failed to fetch messages from channel', e)
            })
          }
          // direct message version
        } else if (messages.currentConversation === from || from === user.sub) {
          service
            .fetchMessagesByMessage(id)
            .then((messages) => {
              dispatch(setCurrentConversationMessages(messages))
            })
            .catch((e) => {
              console.error('Failed to fetch messages', e)
            })
        }
        service.getConversations().then((conversations) => {
          return dispatch(setConversations(conversations))
        })
      }),
      eventBus.on('CONVERSATION_UPDATED', () => {
        service.getConversations().then((conversations) => {
          return dispatch(setConversations(conversations))
        })
      })
    )
    return () => unsubMethods.forEach((unsub) => unsub())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useMessageActions = (contextKey) => {
  const dispatch = useDispatch()

  const sendMessage = (toUserSub, messageContent) => {
    service.sendMessage(toUserSub, messageContent).catch((e) => {
      console.error('Failed to send message', e)
    })
  }
  const deleteConversation = (conversationUser) => {
    service.deleteConversation(conversationUser).then((conversations) => {
      dispatch(setCurrentConversation(null))
      dispatch(setConversations(conversations))
    })
  }

  const deleteMessage = (messageId) => {
    service.deleteMessage(messageId).then((dbMessages) => {
      dispatch(setCurrentConversationMessages(dbMessages))
    })
  }

  const setMessages = (messages) =>
    dispatch(setCurrentConversationMessages(messages, contextKey))

  const loadMessages = (chatRoom) =>
    service.fetchMessages(chatRoom).then(setMessages)

  const openConversation = (toUserId) => {
    dispatch(setCurrentConversation(toUserId))
  }
  return {
    sendMessage,
    deleteConversation,
    deleteMessage,
    openConversation,
    loadMessages,
    setMessages,
  }
}

export const useMessagesConversations = () => {
  const dispatch = useDispatch()
  const getConversations = useCallback(() => {
    service
    .getConversations()
    .then((conversations) => {
      return dispatch(setConversations(conversations))
    })
    .catch((e) => {
      console.error('Failed to fetch messages', e)
    })
  }, [dispatch])
  const openConversation = useCallback(
    (toUserId) => {
      if (!toUserId) dispatch(setCurrentConversation(null))
      service
        .fetchMessages(toUserId)
        .then((messages) => {
          dispatch(setCurrentConversation(toUserId))
          dispatch(setCurrentConversationMessages(messages))
          service.getConversations().then((conversations) => {
            return dispatch(setConversations(conversations))
          })
        })
        .catch((e) => {
          console.error('Failed to fetch messages', e)
        })
    },
    [dispatch]
  )
  return { getConversations, openConversation }
}
