import React from 'react'
import { useIntl } from 'react-intl'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../shared/styles/sections'
import BackgroundShared from './background-shared'

const Dashboard = () => {
  const intl = useIntl()
  return (
    <MainSection>
      <span className="title">
        {intl.formatMessage({ id: 'admin.settings.general.backgrounds' })}
      </span>
      <Row>
        <Col sm={12} md={6}>
          <BackgroundShared type="discoveryPage" title="Discovery page" />
        </Col>
        <Col sm={12} md={6}>
          <BackgroundShared type="login" title="Login and registration page" />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
