import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'

import { CreatorStyled, Name } from './styles/creator'

const Creator = ({ props, onChange }) => {
  const [input, setInput] = useState('')
  const intl = useIntl()
  const keywordLimit = 50

  const handleOnChange = (e) => {
    if (
      (e.key === 'Enter' || e.keyCode === 13) &&
      (!props.keywordList || !props?.keywordList?.some((k) => k === input))
    ) {
      onChange({
        ...props,
        keywordList: [
          ...(props.keywordList || []),
          { id: uuid(), value: input },
        ],
      })
      setInput('')
    }
  }

  const handleRemove = (k) => {
    onChange({
      ...props,
      keywordList: props.keywordList.filter((kw) => kw?.id !== k?.id),
    })
  }

  return (
    <CreatorStyled>
      <input
        maxLength={keywordLimit}
        placeholder={intl.formatMessage({
          id: 'admin.managedata.keywords.data.placeholder',
        })}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => handleOnChange(e)}
        value={input}
      />

      <span className="input-label">
        {intl.formatMessage(
          {
            id: 'admin.manage.keywords.addedit.modal.input.label',
          },
          {
            limit: keywordLimit - input.length,
          }
        )}
      </span>
      <div className="keyword-list">
        {props.keywordList?.map((k) => {
          return (
            <div className="keyword">
              <Name className="value">{k?.value}</Name>
              <i onClick={() => handleRemove(k)} className="fas fa-times" />
            </div>
          )
        })}
      </div>
    </CreatorStyled>
  )
}

export default Creator
