import React from 'react'
import { PdfsWrapper } from '../styles/ca-pdfs'

const CustomAttributesPdfs = ({ attributes, currentAttribute, onDelete }) => {
  if(typeof attributes != Array) {
    attributes = [{name: attributes, extension: 'pdf'}]
  }
  return (
      <PdfsWrapper>
        {attributes.map((file, index) => {
          return (
            <div className="pdf-wrapper">
              <i class="fas fa-file-pdf"></i>
              <p className="pdf-title">
                {file.name.length > 12
                  ? `${file.name.substring(0, 12)}...${file.extension}`
                  : `${file.name}.${file.extension}`}
              </p>
              <button onClick={() => onDelete(currentAttribute.key, index)}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          )
        })}
      </PdfsWrapper>
  )
}

export default CustomAttributesPdfs
