import React from 'react'
import { AvatarsStyled, Multiple, Single, MultiImage } from './styles/avatars'
import { useTheme } from 'emotion-theming'
import scaleSanityImage from 'helpers/scaleSanityImage'
import { getDisplayName } from '_people/people-models'

const Avatars = ({ creator, connections }) => {
  const theme = useTheme()
  const isMultiple = connections?.length > 1
  const limit = 4
  const additionalCount = connections?.length - limit
  const singleAvatar = connections?.length === 1
  ? connections[0].coverPhoto
  : creator?.avatar
  const creatorName = connections?.length === 1
  ? connections[0].name
  : getDisplayName(creator, true)

  return (
    <AvatarsStyled>
      {isMultiple && (
        <Multiple>
          {connections.slice(0, limit).map((c, i) => {
            const img = c?.coverPhoto
              ? scaleSanityImage({ link: c?.coverPhoto, height: 70 })
              : theme.images.defaultCover
            return (
              <MultiImage
                key={i}
                className="multiple"
                index={i}
                src={img}
                alt={img}
              />
            )
          })}
          {connections?.length > limit && (
            <span className="count">{`+${additionalCount}`}</span>
          )}
        </Multiple>
      )}
      {!isMultiple && (
        <Single>
          <img
            src={ singleAvatar || "https://storage.googleapis.com/buildtoriot-cdn/not-logged-avatar.jpg" }
            alt={"User Avatar"}
          />
          <span>
            {creatorName}
          </span>
        </Single>
      )}
    </AvatarsStyled>
  )
}

export default Avatars
