import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import Map from '_map-with-result-grid'
import { useHistoryPreservingSearch } from '_utils/router'
import { toMarkers } from '../opportunities-models'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'

const GET_OPPORTUNITIES = gql`
  query GetAllOpportunitiesMap(
    $filter: OpportunityFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllOpportunitiesPublic(filter: $filter, ordering: $ordering) {
      data {
        id
        name
        coverPhoto
        description
        start
        end
        expire
        location
        _createdAt
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const OpportunitiesMapConnected = ({ filter }) => {
  const history = useHistoryPreservingSearch()

  let ordering = undefined
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error } = useQuery(GET_OPPORTUNITIES, {
    variables: { filter, ordering },
    notifyOnNetworkStatusChange: true,
  })

  const opportunities = data?.getAllOpportunitiesPublic?.data

  const markers = useMemo(() => toMarkers(opportunities || []), [opportunities])
  const showOpportunity = useCallback(
    (opportunity) => history.push(`/opportunities/map/${opportunity.id}`),
    [history]
  )

  if (!opportunities && loading) return <Loading />
  if (error) return <Error />

  return (
    <Map markers={markers} onSelect={showOpportunity} type="opportunities" />
  )
}
OpportunitiesMapConnected.propTypes = {
  filter: PropTypes.object,
}

export default OpportunitiesMapConnected
