import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { usePluginsPublic } from '_application/plugins'
import InitialLoading from '_components/initial-loading'
import { useUser } from '_security/session-hooks'

export const Intercom = ({ children }) => {
  const { plugin, loading, error, plugins } = usePluginsPublic('intercom')
  const isEnabled = plugin?.enabled && plugin?.data?.appId
  const email = useUser()?.profile?.email

  if (!loading && plugins) {
    if (isEnabled) {
      return (
        <IntercomProvider appId={plugin?.data?.appId} autoBoot={true} autoBootProps={ email && {email: email}}>
          {children}
        </IntercomProvider>
      )
    } else {
      return children
    }
  }
  if (error) {
    return children
  }

  return <InitialLoading />
}

export default Intercom
