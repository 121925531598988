import styled from '@emotion/styled'
import { mobileUp } from '_utils/responsive-queries'

export const FallbackUIStyled = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--foreground);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    margin: 0 auto;
    height: 100%;
    max-width: 34rem;
  }

  .title {
    display: block;
    font-size: 2rem;
  }
  .disclamer {
    margin-top: 1.25rem;
    display: block;
  }

  .controls {
    margin-top: 1.25rem;
    width: 100%;
    ${mobileUp} {
      width: auto;
    }

    button {
      font-weight: 700;
      border-radius: 30px;
      border: 2px solid var(--flavor);
      cursor: pointer;
      padding: 6px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--flavor);
      color: var(--flavor-contrast);
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      width: 100%;
    }
  }
`
