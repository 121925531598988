import styled from '@emotion/styled'

export const SubSectionTitle = styled.h2`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  width: 100%;
`

export default SubSectionTitle
