import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../../../shared/styles/sections'
import { useIntl } from 'react-intl'
import Toggle from 'components/indy/shared/ui-kit/buttons/toggle-button-with-name-alt'
import { useAppConfig, GET_CONFIG } from '_application'
import {
  UPDATE_OPPORTUNITY_SUBMISSION_AUTH,
  UPDATE_APPLICATION_SUBMISSION_AUTH,
} from './graph-queries'
import { useMutation } from '@apollo/client'

const Dashboard = () => {
  const intl = useIntl()
  const config = useAppConfig()

  const [updateApplicationSubmissionAuth] = useMutation(
    UPDATE_APPLICATION_SUBMISSION_AUTH
  )

  const [updateOpportunitySubmissionAuth] = useMutation(
    UPDATE_OPPORTUNITY_SUBMISSION_AUTH
  )

  const renderMessageText = (value) =>
    intl.formatMessage({
      id: `admin.sitestructure.opportunities.subscription.${value}`,
    })

  const handleToggleOpportunitySubmissionAuth = (checked) => {
    updateOpportunitySubmissionAuth({
      variables: { isOpportunitySubmissionAuthEnabled: checked },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const handleToggleApplicationSubmissionAuth = (checked) => {
    updateApplicationSubmissionAuth({
      variables: { isApplicationSubmissionAuthEnabled: checked },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }

  const {
    isOpportunitySubmissionAuthEnabled,
    isApplicationSubmissionAuthEnabled,
  } = config

  return (
    <MainSection>
      <Row>
        <Col sm={12} md={12}>
          <Toggle
            title={renderMessageText('opportunitysubmissionauth.title')}
            description={renderMessageText(
              'opportunitysubmissionauth.description'
            )}
            statusText={renderMessageText('opportunitysubmissionauth.status')}
            checked={isOpportunitySubmissionAuthEnabled}
            onChange={(checked) =>
              handleToggleOpportunitySubmissionAuth(checked)
            }
          />
        </Col>
        <Col sm={12} md={12}>
          <Toggle
            title={renderMessageText('applicationsubmissionauth.title')}
            description={renderMessageText(
              'applicationsubmissionauth.description'
            )}
            statusText={renderMessageText('applicationsubmissionauth.status')}
            checked={isApplicationSubmissionAuthEnabled}
            onChange={(checked) =>
              handleToggleApplicationSubmissionAuth(checked)
            }
          />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
