import styled from '@emotion/styled'

export const PageTitle = styled.h1`
  font-size: 22px;
  margin-bottom: 25px;
`

export const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

export const LoadMoreButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--flavor);
  font-weight: 600;
  border: 2px solid var(--flavor);
  background-color: transparent;
  border-radius: 25px;
  transition: 0.5s;
`

export const Layout = styled.div``

export const Tabs = styled.div`
  margin: 20px 0;
  .tab {
    display: inline-block;
    padding: 4px 10px;
    border: 1px solid var(--flavor);
    border-radius: 25px;
    margin-right: 14px;
    cursor: pointer;
    &.active {
      background: var(--flavor);
      color: var(--background);
    }
  }
`

export const Container = styled.div``
