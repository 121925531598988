import styled from '@emotion/styled'

export const ThreeDSecureStyled = styled.div`
  iframe {
    border: none;
    width: 100%;
    margin: 0 auto;
    display: table;
    min-height: 60vh;
  }
`
