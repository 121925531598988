import styled from '@emotion/styled'

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    label {
      font-weight: 700;
      font-size: 15px;
      color: var(--foreground);
    }

    .excerpt-content-desc {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: var(--foreground);
      margin: 12px 0;
    }
  }

  .excerpt-content-wrapper {
    width: 410px;
    display: flex;
    flex-direction: column;

    .excerpt-content {
      width: 410px;
      height: 136px;
      border: 1px solid var(--foreground);
      resize: none;
      outline: none;
      background: var(--background);
      padding: 5px;
      margin: 3px 0;
      border-radius: 5px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
      padding: 12px 16px;
    }

    .char-counter {
      align-self: flex-end;
      color: var(--foreground);
      opacity: 0.7;
    }
  }
`

export const MainContentWrapper = styled.div`
  width: 100%;
`
export const NumberOfSelections = styled.span`
  display: block;
  text-align: center;
`
export const CategoriesWrapper = styled.div``
export const SingleCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
`
export const CategoryName = styled.h1`
  font-size: 14px;
  font-weight: 600;
  cursor: ${({ isExpandable }) => isExpandable && 'pointer'};
  .name {
    margin-right: 15px;
  }
  i {
    opacity: ${({ isExpandable }) => (isExpandable ? '1' : '0')};
    transition: 0.3s;
    transform: ${({ isExpand }) => isExpand && 'rotate(-180deg)'};
    margin-right: 15px;
  }
  input[type='checkbox'] {
    accent-color: var(--flavor);
  }
`
export const CategoryOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 15px;
  padding: 25px 0 0 30px;
`
export const SingleOption = styled.span`
  display: flex;
  align-items: center;
  input {
    margin-right: 10px;
  }
  input[type='checkbox'] {
    accent-color: var(--flavor);
  }
`
