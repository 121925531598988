import styled from '@emotion/styled'

export const ExcerptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-weight: 700;
      font-size: 15px;
      color: var(--foreground);
    }

    .excerpt-content-desc {
      font-weight: 400;
      font-size: 14px;
      color: var(--foreground);
    }
  }

  .excerpt-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .excerpt-content {
      width: 100%;
      height: 136px;
      border: 1px solid var(--foreground);
      resize: none;
      outline: none;
      background: var(--background);
      padding: 5px;
      margin: 3px 0;
      border-radius: 5px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
      padding: 12px 16px;
    }

    .char-counter {
      align-self: flex-end;
      color: var(--foreground);
      opacity: 0.7;
      margin: 0;
    }
  }
`
