import styled from '@emotion/styled'
import { GridItem } from '@patternfly/react-core'
import { mobile, pablet } from '_utils/responsive-queries'

export const PageWrapper = styled.div``

export const SectionHeader = styled.div`
  width: 100%;
  margin: 30px 0;
`

export const CategoryAvatar = styled.div`
  border-radius: 50%;
  width: 75px;
  height: 75px;
  flex-shrink: 0;
`

export const TopicAvatar = styled.div`
  background-color: #d2d2d2;
  background-size: cover;
  border-radius: 50%;
  float: left;
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

export const PageSection = styled.div`
  float: left;
`

export const Counter = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`
export const Description = styled.div`
  padding: 20px 20px 0;
  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
  }
  .author {
    font-size: 12px;
  }
`
export const PopularWrapper = styled(GridItem)`
  display: flex;
  margin-bottom: 20px;
`
export const CountersWrapper = styled(GridItem)`
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: flex-start;
  height: 75px;
  width: auto;
  padding: 0 5px;
  gap: 24px;

  ${pablet} {
    flex-grow: 1;
    width: auto;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    padding: 10px 0;
    margin-left: 90px;
  }
  ${mobile} {
    margin-left: 60px;
  }
`
export const LatestWrapper = styled(GridItem)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .author-name {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 5px;
  }
  span {
    font-size: 12px;
  }
`
export const Indicator = styled.div`
  border-left: 7px solid #c4c4c4;
  padding-left: 20px;
`
