import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import AvatarTile from '_uikit/avatar-tile'
import Loading from '_components/loading'
import { TileConnections } from '_uikit/tile-sections'
import { getDisplayLocation } from '_utils/location'
import { useUserStatus } from '_meet'
import { getDisplayName } from '../people-models'

import { PersonConnectButton } from './person-connect-button'

const Props = {
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sub: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    location: PropTypes.object,
    role: PropTypes.string,
    bioShort: PropTypes.string,
    coverPhoto: PropTypes.string,
    avatar: PropTypes.string,
    connectedUsers: PropTypes.array,
    createdActivities: PropTypes.array,
    createdCompanies: PropTypes.array,
    connectedCompanies: PropTypes.array,
    connectedActivitiesCount: PropTypes.number,
    connectedUsersCount: PropTypes.number,
    connectedCompaniesCount: PropTypes.number,
    lastJobPosition: PropTypes.shape({
      companyName: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
}

const PersonTile = ({
  person,
  className,
  children,
  connectButton,
  url,
  connections,
  showConnections = true,
  showCover = true,
  showLocation,
  connectButtonSize,
  tileHeight,
}) => {
  const intl = useIntl()
  const onlineStatus = useUserStatus(person?.id)
  if (!person) return null

  return (
    <AvatarTile
      cover={person.coverPhoto}
      avatar={person.avatar}
      title={getDisplayName(person)}
      subtitle={person.description || person.bioShort}
      location={getDisplayLocation(person.location, intl)}
      className={className}
      onlineStatus={onlineStatus}
      sub={person.sub}
      url={url}
      withoutConnections={connections?.connectedUsers === undefined}
      showConnections={showConnections}
      showCover={showCover}
      showLocation={showLocation}
      tileHeight={tileHeight}
    >
      {children}

      {!showConnections ? (
        <PersonConnectButton
          person={person}
          connectButton={connectButton}
          url={url}
          connections={connections}
          size={connectButtonSize || 'large'}
        />
      ) : !connections ? (
        <Loading />
      ) : (
        <>
          <PersonConnectButton
            person={person}
            connectButton={connectButton}
            url={url}
            connections={connections}
            size={connectButtonSize || 'large'}
          />
          {connections.connectedUsers && showConnections ? (
            <TileConnections
              connectedUsers={connections.connectedUsers}
              connectedCompanies={
                connections.connectedCompanies && connections.createdCompanies
                  ? connections.createdCompanies.concat(
                    connections.connectedCompanies
                  )
                  : connections.createdCompanies &&
                    !connections.connectedCompanies
                    ? connections.createdCompanies
                    : connections.connectedCompanies
              }
              connectedActivitiesCount={connections.connectedActivitiesCount}
              connectedUsersCount={connections.connectedUsersCount}
              connectedCompaniesCount={
                connections.connectedCompaniesCount +
                connections?.createdCompanies?.length || 0
              }
            />
          ) : null}
        </>
      )}
    </AvatarTile>
  )
}
PersonTile.propTypes = Props

export default PersonTile
