import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import IndyButton from '_components/indyButton'

export const AddNewCompanyButton = () => {
  const intl = useIntl()
  return (
    <Link id={'new-company'} to={'/companies/list/new'}>
      <IndyButton>
        <i className={`fas fa-plus`} alt="add new company" />
        {intl.formatMessage({ id: 'companies.createnew' })}
      </IndyButton>
    </Link>
  )
}

export default AddNewCompanyButton
