import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import gql from 'graphql-tag'
import Col from 'react-bootstrap/Col'
import { PlanFeature } from '_application'
import { ReactComponent as CallIcon } from 'resources/call-icon.svg'
import avatar from 'resources/not-logged-avatar.jpg'
import { useMediaQuery } from 'react-responsive'
import { mobilePX, pabletPX } from '_utils/responsive-queries'
import { useUserStatus, useMeetingCall } from '_meet'
import { useIntl } from 'react-intl'
import LoginRequiredLink from '_security/login-required-link'
import { useMessagesConversations } from '_messages'

export const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      avatar
    }
  }
`

const ConversationDetailsContainer = styled.div`
  position: relative;
  border-bottom: 1px solid #c2c8ca;
`

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 10px;
  padding-bottom: ${({ isMobileView }) => isMobileView && '10px'};
`

const ThumbnailCol = styled.div`
  max-width: 85px;
  position: relative;
  margin-left: ${({ isMobileView }) => (isMobileView ? '15px' : '40px')};
`

const UserThumbnail = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
`

const DetailsCol = styled(Col)`
  padding: 0 15px;
  overflow: hidden;
`

const UserName = styled.h3`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.colors.googleFont};
  font-weight: 600;
  font-size: 20px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`

const OnlineLabel = styled.span`
  color: var(--foreground);
  font-family: ${({ theme }) => theme.colors.googleFont};
  font-size: 16px;
  margin-top: 2.5px;
  opacity: 0.75;
  display: block;
`

const ButtonCol = styled.div`
  max-width: 25%;
`

const CallButton = styled.button`
  position: relative;
  border: none;
  color: var(--foreground-shade);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  background-color: var(--shade);
  padding: 15px 20px;
  font-weight: 500;
  border-radius: 4px;
  margin: 15px 40px 15px 0;
  svg {
    margin-right: 5px;
    fill: var(--foreground-shade);
  }
  &.active {
    background-color: var(--flavor);
    color: white;
    svg {
      fill: white !important;
    }
  }

  ${({ isMobileView }) =>
    isMobileView &&
    css`
      margin: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgb(0 0 0 / 20%) 0px 8px 17px 1px;
      border-radius: 100%;
      width: 45px;
      height: 45px;
      span {
        display: none;
      }
      svg {
        position: absolute;
        margin: 0;
      }
    `}
`

const OnlineIndicator = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ isOnline }) => (isOnline ? '#4dcea6' : 'grey')};
  border-radius: 10px;
  border: 2px solid var(--background);
  position: absolute;
  right: 0;
  bottom: 0;
`

const CloseConversationButton = styled.button`
  border: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 17px 1px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  background-color: var(--shade);
  &:hover {
    i {
      transform: scale(1.3);
    }
  }
  i {
    color: var(--foreground-shade);
    transition: 0.3s;
  }
`

const Index = ({ currentChatRoom }) => {
  const { startDirectCall } = useMeetingCall()
  const intl = useIntl()

  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: {
      id: currentChatRoom,
    },
  })
  const conversationsHook = useMessagesConversations()
  const onlineStatus = useUserStatus(currentChatRoom)
  const url = `/people/list/${data?.getUser?.id}`

  const renderMessageText = (value) => {
    return intl.formatMessage({ id: `meet.${value.toLowerCase()}` })
  }

  const clickCall = () => {
    if (onlineStatus === 'ONLINE') {
      startDirectCall(data.getUser?.id)
    }
  }
  const isTabletView = useMediaQuery({ query: `(max-width: ${pabletPX}px)` })
  const isMobileView = useMediaQuery({ query: `(max-width: ${mobilePX}px)` })

  const Link = url ? LoginRequiredLink : ({ children }) => <>{children}</>

  if (!data || loading) return <></>
  return (
    <div>
      <ConversationDetailsContainer className="conversation-details-container">
        <DetailsRow isMobileView={isMobileView || isTabletView}>
          <ThumbnailCol
            className="my-auto"
            isMobileView={isMobileView || isTabletView}
          >
            <Link to={url}>
              <UserThumbnail
                className="conversation-thumbnail"
                alt=""
                src={data?.getUser?.avatar ? data?.getUser?.avatar : avatar}
              />
            </Link>
            <OnlineIndicator isOnline={onlineStatus === 'ONLINE'} />
          </ThumbnailCol>
          <DetailsCol className="my-auto">
            <Link to={url}>
              <UserName className="conversation-name-details">
                {data?.getUser?.firstName} {data?.getUser?.lastName}
              </UserName>
            </Link>
            <OnlineLabel>
              {onlineStatus === 'ONLINE'
                ? `${renderMessageText('online')}`
                : `${renderMessageText('offline')}`}
            </OnlineLabel>
          </DetailsCol>
          {(isMobileView || isTabletView) && (
            <CloseConversationButton
              onClick={() => conversationsHook.openConversation(null)}
            >
              <i className="fas fa-times" />
            </CloseConversationButton>
          )}

          <ButtonCol className="text-right my-auto">
            <PlanFeature feature="meet">
              <CallButton
                className={onlineStatus === 'ONLINE' ? 'active' : ''}
                disabled={onlineStatus === 'ONLINE' ? false : true}
                onClick={() => clickCall()}
                type="button"
                isMobileView={isMobileView || isTabletView}
              >
                <CallIcon />
                <span> {renderMessageText('videocall')}</span>
              </CallButton>{' '}
            </PlanFeature>
          </ButtonCol>
        </DetailsRow>
      </ConversationDetailsContainer>
    </div>
  )
}

export default Index
