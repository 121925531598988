import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import IndyButton from '_components/indyButton'

export const AddNewActivityButton = () => {
  const intl = useIntl()
  return (
    <Link id={'new-activity'} to={'/activities/list/new'}>
      <IndyButton>
        <i className={`fas fa-plus`} alt="add new activity" />
        {intl.formatMessage({ id: 'companies.createnew' })}
      </IndyButton>
    </Link>
  )
}

export default AddNewActivityButton
