import React from 'react'
import { useSelector } from 'react-redux'
// import { useFilters } from '_utils/filters'
import { Route, Switch } from 'react-router-dom'
import MainLayout from '_layouts/main'
import PageTitle from '_components/page-title'
import ScrollToTop from '_components/scroll-to-top'
import AddNew from './components/events-add-new-button'
import EventsConnected from './connected/events-grid-connected'
import { usePageTitle, useAppLabel } from '_application'
import { Container } from '../_styled-components/shared/section-container'
import EventCreator from './components/event-creator'
import RequiresLogin from './security/requires-login'
import { useGetEntityAccess } from '_security/entity-access'

import styled from '@emotion/styled'
import EventsMapConnected from './connected/events-map-connected'
import EventsHeader from './components/events-grid/events-header'
import EventDetailView from './connected/events-details-connected'
import { useFilters } from '_utils/filters'
import { mobilePX } from '_utils/responsive-queries'
import { useMediaQuery } from 'react-responsive'

const EventsContainer = styled(Container)`
  background-color: var(--background);
`

const Events = () => {
  const appLabel = useAppLabel()
  const { filter, setFilter } = useFilters()
  
  const user = useSelector(({ user }) => user)
  const title = appLabel('eventsPlural', 'events.titleplural')

  const entityAccess = useGetEntityAccess('EVENTS', 'detailsRequiresAuth')

  const eventDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })

  const description = appLabel('eventsDescription', 'empty')
  usePageTitle(title)

  return (
    <div>
      <Switch>
        <Route path="/events/manage" component={EventCreator} />
        <Route
          path="/events/list/:id"
          render={({ match, history, location }) => (
            <RequiresLogin isAuthRequired={eventDetailsRequiresAuth}>
              <ScrollToTop />
              <MainLayout>
                <EventDetailView
                  id={match.params.id}
                  onClose={() => history.push(`/event${location.search}`)}
                />
              </MainLayout>
            </RequiresLogin>
          )}
        />
        <Route
          path="*"
          render={() => (
            <MainLayout>
              <ScrollToTop />
              <EventsContainer className={"page-wrapper"}>
                <PageTitle
                  description={description}
                  actions={
                    !isMobileView &&
                    (user.profile.role === 'admin' ||
                      user.profile.role === 'contentEditor') ? (
                      <AddNew />
                    ) : (
                      <></>
                    )
                  }
                >
                  {title}
                </PageTitle>
                {/* TODO: mapview and filters here */}
                <EventsHeader filter={filter} onFilterChange={setFilter} user={user} />
                <Switch>
                  <Route
                    exact
                    path="/events/list"
                    render={() => <EventsConnected filter={filter} />}
                  />
                  <Route
                    path="/events/map"
                    render={() => <EventsMapConnected filter={filter} />}
                  />
                </Switch>
              </EventsContainer>
            </MainLayout>
          )}
        />

        {/* TODO: single event route*/}
        {/* <Route
            path="/events/:id"
            render={({ match, history, location }) => (
              <EventDetails
                id={match.params.id}
                onClose={() => history.push(`/events/${location.search}`)}
              />
            )}
          /> */}
      </Switch>
    </div>
  )
}

export default Events
