import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, gql } from '@apollo/client'
import styled from '@emotion/styled'
import { ReactComponent as Icon } from 'resources/search-icon-tool.svg'
import { useIntl } from 'react-intl'

const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      connectedUsers {
        id
        sub
        firstName
        middleName
        lastName
        avatar
      }
    }
  }
`

const SearchContainer = styled.div`
  position: relative;
  padding: 0 30px 0 20px;
  margin-top: 20px;
  .inner-search-conversations-container {
    position: relative;
  }
`

const SearchInput = styled.input`
  padding: 10px 15px 10px 50px;
  border-radius: 25px;
  border: none;
  width: 100%;
  background-color: var(--shade-faint);
  color: var(--foreground);
  &::placeholder {
    color: var(--shade) !important;
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  fill: var(--flavor);
  circle {
    fill: none;
  }
`

const SuggestionsWrapper = styled.div`
  position: absolute;
  background-color: var(--background);
  color: var(--foreground);
  width: 90%;
  border-radius: 4px;
  transition: 0.5s;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  clip-path: inset(0px 0px 0px 0px);
  border: 1px solid var(--shade);

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }
`

const InnerSuggestionsWrapper = styled.div``

const SingleSuggestion = styled.div`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-size: 14px;
  margin: 10px 0;
  padding: 0 15px;
  transition: 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    font-weight: 500;
  }
`

const Index = ({
  handleUserClick,
  selection,
  setSelection,
  resetSearch,
  setResetSearch,
}) => {
  const intl = useIntl()
  const { user } = useSelector((state) => state)
  const [query, setQuery] = useState('')
  const [people, setPeople] = useState([])
  const searchInputRef = useRef(null)

  const { data: connectionsData, loading: connectionsLoading } = useQuery(
    GET_USERS_CONNECTIONS,
    {
      variables: { ids: [user.sub], limit: 1000 },
      notifyOnNetworkStatusChange: true,
      skip: !user.sub,
    }
  )

  useEffect(() => {
    const usersConnections =
      connectionsData?.getUserConnectionsPublic &&
      connectionsData?.getUserConnectionsPublic.length > 0
        ? connectionsData?.getUserConnectionsPublic[0]
        : []
    if (usersConnections?.connectedUsers) {
      setPeople(
        usersConnections?.connectedUsers?.filter(
          (u) =>
            query.length > 0 &&
            `${u.firstName} ${u.lastName}`
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1
        )
      )
    }
  }, [connectionsData, query, setPeople])

  const displayInputValue = () => {
    if (selection.sub) return `${selection.firstName} ${selection.lastName}`
    if (query !== '' && resetSearch) {
      setQuery('')
      setResetSearch(false)
      return query
    }
    return query
  }

  return (
    <SearchContainer>
      <div className="search-conversations-container">
        <div className="inner-search-conversations-container">
          <SearchIcon />
          <SearchInput
            placeholder={`${intl.formatMessage({
              id: 'user.messages.searchconversation.placeholder',
            })}`}
            type="text"
            data-testid="messages-searchInput"
            disable={connectionsLoading}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) =>
              e.keyCode === 8 &&
              selection.sub &&
              setSelection({ sub: null, firstName: null, lastName: null })
            }
            value={displayInputValue()}
            ref={searchInputRef}
          />
          <>
            {query && query.length > 0 && people && people.length > 0 ? (
              <SuggestionsWrapper>
                <InnerSuggestionsWrapper>
                  {people.map((p) => {
                    return (
                      <SingleSuggestion
                        key={p.sub}
                        onClick={() => {
                          setQuery('')
                          handleUserClick(p)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {p.firstName} {p.lastName}
                      </SingleSuggestion>
                    )
                  })}
                </InnerSuggestionsWrapper>
              </SuggestionsWrapper>
            ) : null}
          </>
        </div>
      </div>
    </SearchContainer>
  )
}

export default Index
