import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { Row, Col } from 'react-bootstrap'
import { Checkout } from '../payment'
import CancelModal from './cancel-subscription-modal'

import GridLoader from 'react-spinners/GridLoader'
import { useTheme } from 'emotion-theming'
import {
  ProductStyled,
  ProductList,
  ProductItem,
  Description,
  Prices,
  CancelButton,
  SelectButton,
  ImageWrapper,
  ActiveIconWrapper,
  CircleBackground,
  Name,
  TopContentWrapper,
} from './styles'
import { useIntl } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const ProductsList = ({ user, dispatch }) => {
  const [products, setProducts] = useState()
  const [currentProduct, setCurrentProduct] = useState()
  const [paymentMessage, setPaymentMessage] = useState()
  const [loading, setLoading] = useState()
  const [cancelSubscriptionId, setCancelSubscriptionId] = useState()
  const [threeDSecure, setThreeDSecure] = useState()
  const intl = useIntl()
  const theme = useTheme()

  const handleSubscribe = async (product) => {
    setCurrentProduct(product)
  }

  const handleCancelSubscription = async () => {
    const request = {
      path: `${process.env.REACT_APP_API_ENDPOINT}/payment/cancel-subscription`,
      payload: {
        subscriptionId: cancelSubscriptionId,
      },
    }
    await axios.post(request.path, request.payload, {
      headers: { authorization: localStorage.getItem('RIOT/token') },
    })
    dispatch({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    setCancelSubscriptionId(null)
    setCurrentProduct(null)
  }

  useEffect(() => {
    ;(async () => {
      const {
        data,
      } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/payment/get-products`,
        { headers: { authorization: localStorage.getItem('RIOT/token') } }
      )
      if (data) setProducts(data)
    })()
  }, [])

  const handleCreateSubscription = async (billingInfo) => {
    const request = {
      path: `${process.env.REACT_APP_API_ENDPOINT}/payment/create-subscription`,
      payload: {
        billingInfo,
        paymentMethod: billingInfo?.paymentMethod,
        priceId: currentProduct?.prices[0]?.id,
        productId: currentProduct?.id,
        isSavePaymentMethod: billingInfo?.isSavePaymentMethod,
        coupon: billingInfo?.coupon,
        host: window.location.host,
      },
    }
    setLoading(true)

    const { data } = await axios.post(request.path, request.payload, {
      headers: { authorization: localStorage.getItem('RIOT/token') },
    })

    setLoading(false)

    const threeDUrl = data?.next_action?.redirect_to_url?.url

    if (threeDUrl) {
      setThreeDSecure(data)
    } else if (data?.id) {
      setPaymentMessage({
        type: 'success',
        title: 'Your payment has been confirmed',
        description: `${currentProduct?.name} has been activated`,
      })
    } else {
      setPaymentMessage({
        type: 'error',
        title: 'Error occured during payment',
        description: data?.error?.message,
      })
    }
  }

  const handleClose = () => {
    setCurrentProduct(null)
    setPaymentMessage(null)
    dispatch({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }

  const handleOpenCancelModal = ({ subscriptionId, product }) => {
    setCancelSubscriptionId(subscriptionId)
    setCurrentProduct(product)
  }

  const handleThreeDSecureVerification = async () => {
    setLoading(true)
    const request = {
      path: `${process.env.REACT_APP_API_ENDPOINT}/payment/verify-3ds`,
      payload: {
        id: threeDSecure?.id,
      },
    }

    setThreeDSecure(null)

    const { data } = await axios.post(request.path, request.payload, {
      headers: { authorization: localStorage.getItem('RIOT/token') },
    })

    setLoading(false)

    if (data?.error) {
      setPaymentMessage({
        type: 'error',
        title: 'Error occured during payment',
        description: data?.error?.message,
      })
    } else {
      setPaymentMessage({
        type: 'success',
        title: 'Your payment has been confirmed',
        description: `${currentProduct?.name} has been activated`,
      })
    }
  }

  if (!products) {
    return <GridLoader size={15} color={theme.colors.buttonColor} loading />
  }

  return (
    <ProductStyled>
      {currentProduct && !cancelSubscriptionId && (
        <Checkout
          onClose={() => handleClose()}
          product={currentProduct}
          onSuccessCallback={handleCreateSubscription}
          paymentMessage={paymentMessage}
          isPaymentInProgress={loading}
          threeDSecure={threeDSecure}
          onThreeDSecureComplete={() => handleThreeDSecureVerification()}
        />
      )}
      {cancelSubscriptionId && (
        <CancelModal
          onSuccess={handleCancelSubscription}
          onClose={() => {
            setCancelSubscriptionId(null)
            setCurrentProduct(null)
          }}
          product={currentProduct}
        />
      )}
      <ProductList>
        <Row className="equal">
          {products?.map((p) => {
            const isProductBought = p.prices.some((price) =>
              user?.profile?.activeSubscriptions?.some(
                (activeSub) =>
                  activeSub.priceId === price.id && activeSub.active
              )
            )
            const subscriptionId = user?.profile?.activeSubscriptions?.filter(
              (sub) => sub.productId === p.id
            )[0]?.id
            const image = p?.images?.[0]

            return (
              <Col
                className="subscription-item"
                sm={6}
                md={4}
                data-testid={`subs-item-${p.name}`}
              >
                <ProductItem
                  isProductBought={isProductBought}
                  className={isProductBought ? 'bought' : ''}
                  data-testid={`subs-item${isProductBought ? '-active' : ''}`}
                >
                  <TopContentWrapper>
                    <ActiveIconWrapper>
                      {isProductBought && (
                        <>
                          <CircleBackground>
                            <i className="bought-icon fas fa-check" />
                          </CircleBackground>
                          <span>
                            {intl.formatMessage({
                              id: 'user.profile.subscriptions.active',
                            })}
                          </span>
                        </>
                      )}
                    </ActiveIconWrapper>
                    <ImageWrapper>
                      {image ? (
                        <img src={image} alt={p.name} />
                      ) : (
                        <i class="fas fa-dollar-sign" />
                      )}
                    </ImageWrapper>
                    {p.name && <Name>{p.name}</Name>}
                    {p.description && (
                      <Description>{p.description}</Description>
                    )}
                  </TopContentWrapper>
                  {!isProductBought && (
                    <Prices>
                      {p.prices.map((price) => {
                        return (
                          <span className="price">
                            {(price.unit_amount / 100).toFixed(2)}{' '}
                            {price.currency.toUpperCase()}
                            {price.recurring?.interval && (
                              <label className="interval">
                                /{price.recurring?.interval}
                              </label>
                            )}
                          </span>
                        )
                      })}
                    </Prices>
                  )}
                  {!isProductBought && (
                    <div>
                      <SelectButton
                        onClick={() => handleSubscribe(p)}
                        data-testid={`subs-btn-purchase-${p.name}`}
                      >
                        {intl.formatMessage({
                          id: 'user.profile.subscriptions.select',
                        })}
                      </SelectButton>
                    </div>
                  )}
                  {isProductBought && <Prices />}
                  {isProductBought && (
                    <div>
                      <CancelButton
                        onClick={() =>
                          handleOpenCancelModal({
                            subscriptionId,
                            product: p,
                          })
                        }
                        data-testid={`subs-btn-cancel-${p.name}`}
                      >
                        {intl.formatMessage({
                          id: 'user.profile.subscriptions.cancel',
                        })}
                      </CancelButton>
                    </div>
                  )}
                </ProductItem>
              </Col>
            )
          })}
        </Row>
      </ProductList>
    </ProductStyled>
  )
}

export default connect(mapStateToProps)(ProductsList)
