import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Themes from './themes'

const defaultManageDataRoute = '/branding'
const defaultRoute = `/manage${defaultManageDataRoute}`

const Routes = () => {
  return (
    <Switch>
      <Route path={`${defaultRoute}/themes`} render={() => <Themes />} />
      <Redirect from={defaultRoute} to={`${defaultRoute}/branding`} />
    </Switch>
  )
}

export default Routes
