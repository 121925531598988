import styled from '@emotion/styled'

export const Container = styled.div`
  position: relative;
  background-color: var(--background);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 306px;
  padding-bottom: 30px;
  height: ${({ withoutConnections, showConnections }) =>
    withoutConnections || !showConnections ? '420px' : '553px'};
  height: ${({ tileHeight }) => tileHeight};
  z-index: 1;
  a,
  .not-logged {
    text-align: center;
    cursor: pointer;
  }

  .popup-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100% !important;
    z-index: 1 !important;
  }

  button {
    position: relative;
    z-index: 3 !important;
  }
  .avatar-popup {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const AvatarContainer = styled.div`
  position: relative;
  display: block;
  height: 160px;
  width: 160px;
  margin: 0 auto;
`

export const Avatar = styled.img`
  border-radius: 100%;
  display: block;
  height: 160px;
  width: 160px;
  object-fit: cover;
  border: 4px solid white;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 16%);
`

export const OnlineIndicator = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  border: 3px solid white;
  border-radius: 100%;
  bottom: 5px;
  right: 20px;
  background-color: ${({ onlineStatus }) =>
    onlineStatus === 'ONLINE' ? '#4dcea6' : '#4a4a4a'};
  z-index: 1;
`

export const Title = styled.div`
  width: 259px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
  height: 52px;
  line-height: 26px;
  text-align: center;
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
`

export const SubTitle = styled.div`
  width: 250px;
  height: 40px;
  line-height: 20px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  i {
    padding: 3px;
    font-size: 10px;
  }
`

export const LocationLabel = styled.div`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 400;

  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
`

export const CoverContainer = styled.div`
  width: 100%;
  height: 160px;
  overflow: hidden;
  object-fit: cover;
`
