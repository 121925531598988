import React, { useState } from "react"
import { useIntl } from 'react-intl'
import styled from "@emotion/styled"
import ImageUpload from "components/indy/shared/image-upload"
import { useEffect } from "react"

const LogoEditorStyled = styled.div`
  .image-upload-container {
    margin-top: 12px;
  }
`

export const LogoEditor = ({value = null, onChange = () => {}}) => {
  const intl = useIntl()
  
  const type = "footerLogo"
  const [logo, setLogo] = useState(value)

  useEffect(() => {
    onChange(logo)
  }, [logo])

  useEffect(() => {
    setLogo(value)
  }, [value])

  return (
    <LogoEditorStyled className="LogoEditor">
      <p><b>{intl.formatMessage({ id: 'admin.sitestructure.footer.logo.title'})}</b></p>
      <p>{intl.formatMessage({ id: 'admin.sitestructure.footer.logo.description' })}</p>

      <ImageUpload
        value={logo}
        type={type}
        cdnType={type}
        onUpload={(imgUrl) => setLogo(imgUrl)}
        onRevert={() => setLogo('')}
      />
    </LogoEditorStyled>
  )
}