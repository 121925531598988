import React from 'react'
import DOMPurify from 'dompurify'

import { AboutMeWrapper, BioLongWrapper } from './styles/about-me'

const AboutMe = ({ person }) => {
  const renderBioLong = () => {
    const { bioLong } = person || {}
    if (/<\/?[a-z][\s\S]*>/i.test(bioLong)) {
      return (
        <BioLongWrapper
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bioLong) }}
        ></BioLongWrapper>
      )
    }
    return bioLong
  }

  return <AboutMeWrapper>{renderBioLong()}</AboutMeWrapper>
}

export default AboutMe
