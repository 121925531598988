import React, { useEffect, useState } from 'react'
import { useAppConfig } from '_application'

const LocationApi = ({ children, history }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState()
  const config = useAppConfig()

  useEffect(() => {
    if (!isScriptLoaded && config) {
      const googleScript = document.createElement('script')
      const language = config?.defaultLocale || 'en_gb'
      const key = `AIzaSyB4mnDLmjo_YgGu1Mv6vqr28CrFZnKBxSs`
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${key}&language=${language}&libraries=places`
      document.head.appendChild(googleScript)
      setIsScriptLoaded(true)
    }
  }, [config, isScriptLoaded])

  return <></>
}

export default LocationApi
