//External dependencies 
import tinycolor from "tinycolor2"

//Internal dependencies
import { converse, getReadable, makeReadable } from './primitives/color'
import { defaultScheme } from './config'

const background = (scheme) => {
  //Makes sure there exists a backgrund color. Defaults to white
  return scheme.background ? tinycolor(scheme.background) : tinycolor('white')
}

const foreground = (scheme) => {
  //Adjusts the foreground and makes sure its readable against the background
  let readability = scheme.readability || defaultScheme.readability || 5

  const bg = scheme.hasOwnProperty('background')
    ? background(scheme)
    : background(defaultScheme)

  const computedForeground = scheme.foreground
    ? getReadable(tinycolor(scheme.foreground), bg, readability)
    : converse(bg.clone())

  return tinycolor(computedForeground)
}

export const flavor = (fl, scheme, fallback = tinycolor.random().toHexString()) => {
  //Adjusts the flavor and makes sure its in contrast to BG and FG.
  //If no flavor, generate a random one
  const fg = scheme.hasOwnProperty('foreground')
    ? foreground(scheme)
    : foreground(defaultScheme)

  const bg = scheme.hasOwnProperty('background')
    ? background(scheme)
    : background(defaultScheme)

  return fl
    ? tinycolor(makeReadable(fl, fg, bg))
    : tinycolor(makeReadable(fallback, fg, bg))
}

export const adjustColors = (scheme = defaultScheme) => {
  if(!scheme.flavors && !scheme.settings.length) return
  return scheme.settings?.map((s) => flavor(s, scheme))
}

export const adjust = (scheme = defaultScheme) => {
  //Gets the config and adjusts the colors according to
  //their relationship to each other so theres enough color contrasts
  let obj = { origin: scheme }

  obj.background = background(scheme)
  obj.foreground = foreground(scheme)
  obj.flavor = scheme.flavor?.map((fl) => flavor(fl, scheme))
  
  return obj
}