import gql from 'graphql-tag'

export const GET_ATTRIBUTES = gql`
  query GetAttributes($usedBy: [String]) {
    getAttributes(usedBy: $usedBy) {
      key
      name
      description
      dateCreated
      dateUpdated
      type
      badges {
        key
        name
        icon
      }
    }
  }
`
