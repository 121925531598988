import React from 'react'
import { MessageStyled } from '../styles/message'

const typeToIcon = (type) => {
  switch (type) {
    case 'success':
      return 'check'
    case 'error':
      return 'times'
    default:
      return ''
  }
}

const Message = ({ message, onClose }) => {
  const { type, title, description } = message
  const icon = typeToIcon(type)

  return (
    <MessageStyled>
      <i className={`fas fa-${icon}`} data-testid={`subs-modal-${type}`} />
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <button onClick={onClose}>Close</button>
    </MessageStyled>
  )
}

export default Message
