import styled from '@emotion/styled'
import { css } from '@emotion/core'

import border from '_utils/css-border'

export const MiniCardStyled = styled.div`
  ${({ theme, hasMultipleConnections }) =>
    !hasMultipleConnections &&
    css`
      ${border()}
      max-width: 306px;
      background-color: var(--background);
      margin: 0 48px;
    `}
  ${({ hasMultipleConnections }) =>
    hasMultipleConnections &&
    css`
      margin-top: 30px;
    `}
`

export const ConnectButtonContainer = styled.div`
  margin-bottom: 30px;
  button {
    display: block;
  }
`

export const Location = styled.div`
  padding: 10px 25px 37px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  svg {
    margin-right: 6px;
    align-self: flex-start;
    path {
      stroke: var(--foreground);
    }
  }
`
