import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SubSectionTitle from '_components/subsection-title'

const Link = ({ label, href, tooltip }) => (
  <Row>
    <Col>
      <label>{label}</label>
    </Col>
    <Col className="text-right">
      <a href={href} target="_blank" rel="noopener noreferrer">
        <i data-tip={tooltip} className="fas fa-link link-icon" />
      </a>
    </Col>
  </Row>
)

const Info = ({ label, value, tooltip }) => (
  <Row>
    <Col>
      <label>{label}</label>
    </Col>
    <Col className="text-right">
      <label>{value}</label>
    </Col>
  </Row>
)

const Container = styled.div`
  margin-top: 17px;
  margin-bottom: 10px;
`

const Body = styled.div`
  margin-top: 20px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
`

const Props = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}
const DrilldownContactSection = ({ title, children }) => (
  <Container>
    <SubSectionTitle>{title}</SubSectionTitle>
    <Body>{children}</Body>
  </Container>
)
DrilldownContactSection.propTypes = Props
DrilldownContactSection.Link = Link
DrilldownContactSection.Info = Info

export default DrilldownContactSection
