import React from 'react'
import { useTheme } from 'emotion-theming'
import {
  Container,
  ContentWrapper,
  TagsWrapper,
  Tag,
  CoverWrapper,
  Cover,
  Title,
  Description,
} from '_themes/default/components/styled/blog-tile'

const checkValue = (value) => {
  return value && value !== 'undefined' ? value : null
}

const BlogTile = ({ post, size }) => {
  const theme = useTheme()
  const tagsLimit = 2
  const displayedTags = post?.categories?.slice(0, tagsLimit)

  const renderTitleSize = (s) => {
    switch (s) {
      case 'high':
        return 60
      case 'wide':
        return 70
      case 'default':
        return 30
      default:
        return 30
    }
  }

  const renderDescriptionSize = (s) => {
    switch (s) {
      case 'high':
        return 280
      case 'wide':
        return 130
      case 'default':
        return 70
      default:
        return 70
    }
  }

  const renderDescription = (post) => {
    const description = post?.description || post.excerpt
    return (
      <Description>
        {checkValue(description) &&
          description.length > renderDescriptionSize(size)
          ? `${description.substring(0, renderDescriptionSize(size))}...`
          : description}
      </Description>
    )
  }

  return (
    <Container>
      <CoverWrapper>
        {post.categories || post.categories === 'undefined' ? (
          <TagsWrapper>
            {displayedTags?.map((t) => (
              <Tag key={t.id}>{t.title}</Tag>
            ))}
            {post.categories.length > tagsLimit && (
              <Tag>{`+${post.categories.length - tagsLimit}`}</Tag>
            )}
          </TagsWrapper>
        ) : null}
        <Cover alt="" src={post.coverPhoto || theme.images.defaultCover} />
      </CoverWrapper>
      <ContentWrapper>
        <Title>
          {checkValue(post.title) && post.title.length > renderTitleSize(size)
            ? `${post.title.substring(0, renderTitleSize(size))}...`
            : post.title}
        </Title>
        {renderDescription(post)}
      </ContentWrapper>
    </Container>
  )
}
export default BlogTile
