import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'query-string'
import LoginRequiredModal from './login-required-modal'
import { useUserAccess } from './helpers'

const Props = {
  children: PropTypes.func.isRequired,
  href: PropTypes.string,
  className: PropTypes.string,
  preserveQuery: PropTypes.bool,
}

const mapStateToProps = ({ user }) => ({ user })

export const LoginRequiredLink = connect(mapStateToProps)(
  ({ user, to, preserveQuery, ...rest }) => {
    const { search } = useLocation()
    const hasUserAccess = useUserAccess()

    if (hasUserAccess) {
      const href = preserveQuery ? `${to}${search}` : to
      return <Link {...rest} to={href} />
    } else {
      return <NoAccess {...rest} />
    }
  }
)
LoginRequiredLink.propTypes = Props

export const LoginRequiredQueryLink = connect(mapStateToProps)(
  ({ user, field, value, ...rest }) => {
    const { search: current } = useLocation()
    const query = useMemo(() => qs.parse(current), [current])
    const search = useMemo(() => qs.stringify({ ...query, [field]: value }), [
      query,
      field,
      value,
    ])
    const hasUserAccess = useUserAccess()
    if (hasUserAccess) {
      return <Link {...rest} to={{ search }} />
    } else {
      return <NoAccess {...rest} />
    }
  }
)
LoginRequiredLink.propTypes = Props

const NoAccess = ({ children, ...rest }) => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <>
      <span
        className="not-logged"
        {...rest}
        onClick={(e) => {
          e.preventDefault()
          setModalVisible(true)
          return false
        }}
      >
        {children}
      </span>
      {modalVisible && (
        <LoginRequiredModal
          onClose={() => {
            setModalVisible(false)
          }}
        />
      )}
    </>
  )
}

export default LoginRequiredLink
