import { gql } from '@apollo/client'

export const INVITE_ENTITY_MANAGER = gql`
  mutation InviteEntityManager(
    $entityId: String!
    $entityType: String!
    $userId: String!
  ) {
    inviteEntityManager(
      entityId: $entityId
      entityType: $entityType
      userId: $userId
    ) {
      _id
    }
  }
`

export const REMOVE_ENTITY_MANAGER = gql`
  mutation RemoveManager(
    $entityId: String!
    $entityType: String!
    $userId: String!
  ) {
    removeManager(
      entityId: $entityId
      entityType: $entityType
      userId: $userId
    ) {
      userId
    }
  }
`

// state: accepted or declined
export const RESPOND_ENTITY_MANAGER_INVITATION = gql`
  mutation RespondToEntityManagerInvitation(
    $notificationId: String!
    $state: String!
  ) {
    respondToEntityManagerInvitation(
      notificationId: $notificationId
      state: $state
    ) {
      _id
    }
  }
`
