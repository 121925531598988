import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
// import LoginRequiredLink from '_security/login-required-link'
import { Default as OpportunityTile } from '_opportunities/components/tiles'
import { Grid, BaseTile } from '_tiles'
import { getPageSizeForScreenSize } from '_tiles/helpers'
import DiscoverSection from '../components/discover-section'
import { Loader } from '_components/loading'
import styled from '@emotion/styled'
import { useGetEntityAccess } from '_security/entity-access'
import { useUserAccess } from '_security/helpers'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 1,
  xl: 3,
  lg: 6,
  md: 12,
  sm: 12,
}

const GET_DATA = gql`
  query GetDiscoverOpportunities($paging: PagingInput) {
    getAllOpportunitiesPublic(paging: $paging)
    @connection(key: "GetDiscoverOpportunities") {
      data {
        id
        name
        coverPhoto
        location
        description
        applicationCount
        start
        end
        expire
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 0px 0px 100px;
  background-color: var(--background);
`

const DiscoverOpportunitiesConnected = ({ filter }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()

  const entityAccess = useGetEntityAccess(
    'OPPORTUNITIES',
    'detailsRequiresAuth'
  )

  const opportunityDetailsRequiresAuth =
    entityAccess !== undefined ? entityAccess : true

  const isAdditionalAuth = useUserAccess()

  const { data, error } = useQuery(GET_DATA, {
    variables: {
      paging: {
        offset: 0,
        limit: getPageSizeForScreenSize({
          type: 'opportunity',
          pageType: 'discoverPage',
        }),
      },
    },
  })

  const title = intl.formatMessage(
    { id: 'discover.latestcategory' },
    {
      category: appLabel('opportunitiesPlural', 'opportunities.titleplural'),
    }
  )

  const DESCRIPTION = appLabel('opportunitiesDescription', 'empty')

  return (
    <Container className="sectionContainer page-wrapper stretchColorToScreensize">
      <DiscoverSection
        title={title}
        link="/opportunities"
        description={DESCRIPTION}
      >
        <Loader loading={!data} error={error}>
          {() => (
            <>
              <Grid config={gridConfig} testid={'opportunities'}>
                {data?.getAllOpportunitiesPublic?.data?.map((opportunity) => {
                  return (
                    <BaseTile
                      key={opportunity.id}
                      url={`/discover/opportunities/${opportunity.id}`}
                      config={defaultConfig}
                      isAdditionalAuthEnabled={opportunityDetailsRequiresAuth}
                      isAdditionalAuth={isAdditionalAuth}
                      testid="discover-opportunity"
                    >
                      <OpportunityTile
                        applyByDate={opportunity?.expire}
                        opportunity={opportunity}
                        connectButton={''}
                        isOwner={''}
                      />
                    </BaseTile>
                  )
                })}
              </Grid>
            </>
          )}
        </Loader>
      </DiscoverSection>
    </Container>
    // <div>
    //   {data?.getAllOpportunitiesPublic?.data?.length > 0 ? (
    //     <NamedTileSlider
    //       loading={!data}
    //       error={error}
    //       title={title}
    //       description={DESCRIPTION}
    //       link="/opportunities/list"
    //       className="testClass"
    //       testid="opportunities-slider"
    //       url={`/discover/opportunities/`}
    //     >
    //       <Grid config={gridConfig}>
    //         {data?.getAllOpportunitiesPublic?.data?.map((opportunity) => (
    //           <BaseTile
    //             url={`/discover/opportunities/${opportunity.id}`}
    //             config={defaultConfig}
    //           >
    //             <LoginRequiredLink
    //               key={opportunity.id}
    //               to={`/discover/opportunities/${opportunity.id}`}
    //             >
    //               <OpportunityTile
    //                 opportunity={opportunity}
    //                 connectButton={''}
    //                 isOwner={''}
    //               />
    //             </LoginRequiredLink>
    //           </BaseTile>
    //         ))}
    //       </Grid>
    //     </NamedTileSlider>
    //   ) : null}
    // </div>
  )
}

export default DiscoverOpportunitiesConnected









