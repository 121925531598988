import styled from '@emotion/styled'

export const MainStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80vw;
    max-width: 800px;
    gap: 6px;
    min-width: 400px;
    .title{
      font-size: 14px;
      font-weight: 700;
      color: var(--foreground);
    }
` 

export const Content = styled.div`
    .section{
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
      .info{
        display: block;
        font-size: 14px;  
        font-weight: 400; 
        color: var(--foreground);
        margin-bottom: 4px;
      }
      select{
        border: solid 1px var(--foreground);
        border-radius: 6px;
        color: var(--foreground);
        padding: 6px;
      }
      input{
        border: solid 1px var(--foreground);
        border-radius: 6px;
        color: var(--foreground);
        padding: 6px;
        min-width: 350px;
      } 
      .error{
        display:block;
        margin-top:4px;
        color:red;
      }
    }
`


