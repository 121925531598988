/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useTheme } from 'emotion-theming'

import NavNotifications from './nav-notifications/nav-notifications'
import { tabletPX, useWindowResize } from '_utils/responsive-queries'

import UserDropdown from '_uikit/user-dropdown'
import {
  AvatarWrapper,
  NavigationLinks,
  NavLinksWrapper,
  SignInContainer,
  SignInButton,
  SignUpButton,
  UserWrapper
} from '_themes/default/components/styled/user-dropdown'
import { useAppConfig } from '_application'
import { useMediaQuery } from 'react-responsive'

const GET_NOTIFICATIONS = gql`
  query {
    getCurrentUser {
      sub
      notifications {
        notification {
          _id
          read
          subtype
          payload
        }
        user {
          sub
          firstName
          lastName
        }
      }
    }
  }
`

const mapStateToProps = ({ user }) => ({ user })

const Index = ({ dispatch, user, isScrollTop, themeSettings, location }) => {
  const appConfig = useAppConfig()
  const { messages } = useSelector((state) => state)
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [messagesCount, setmessagesCount] = useState(0)

  const { data, loading, error, stopPolling, startPolling, refetch: refetchNotifications } = useQuery(
    GET_NOTIFICATIONS,
    {
      pollInterval: 5000,
      skip: !user?.authorized,
    }
  )

  const isTabletView = useMediaQuery({
    query: `(max-width: ${tabletPX}px)`,
  })

  const history = useHistory()

  const [openItemId, setOpenItemId] = useState(null)
  const theme = useTheme()
  const intl = useIntl()

  const userDropdownMyProfile = intl.formatMessage({
    id: 'user.dropdown.myprofile',
  })
  const userDropdownMessages = intl.formatMessage({
    id: 'user.dropdown.messages',
  })
  const userDropdownSignOut = intl.formatMessage({
    id: 'user.dropdown.signout',
  })
  const userDropdownSignUp = intl.formatMessage({
    id: 'user.dropdown.signup',
  })
  const userDropdownSignIn = intl.formatMessage({
    id: 'user.dropdown.signin',
  })
  const userDropdownAdmin = intl.formatMessage({
    id: 'user.dropdown.admin',
  })

  /*   const isForumEnabled = appConfig?.features?.includes('forum')

    const connectedUsersSubs = user?.profile?.connectedUsers?.map((u) => u.sub) */

  const messagesList =
    messages?.conversations?.filter(
      (conv) =>
        !conv.lastMessage.read &&
        conv?.lastMessage?.state === 'NEW' &&
        user.sub !== conv.lastMessage.creator
    ) || []

  const notificationsList =
    data?.getCurrentUser?.notifications?.filter(
      (n) => n.user?.sub !== user?.sub && n.notification?.read === false
    ) || []

  useEffect(() => {
    if (user?.authorized) {
      startPolling()
    } else {
      stopPolling()
    }
  }, [user, stopPolling, startPolling])

  useEffect(() => {
    if (data && !error && !loading) {
      setNotificationsCount(
        data.getCurrentUser?.notifications?.filter(
          (n) => n.user?.sub !== user?.sub && n.notification?.read === false
        ).length +
          messages?.conversations?.filter(
            (conv) =>
              !conv.lastMessage.read && user.sub !== conv.lastMessage.creator
          ).length
      )
    }
  }, [data, error, loading, setNotificationsCount, user, messages])

  const handleLogout = () => {
    dispatch({
      type: 'user/LOGOUT',
      payload: {},
    })
  }

  const toggleDropdown = (e, id, stateId) => {
    if (stateId === e.currentTarget.id) return setOpenItemId(null)
    if (id === openItemId) return setOpenItemId(null)
    return setOpenItemId(id)
  }

  const [cursorOnMenu, setCursorOn] = useState(false)
  const menuOff = () => {
    if (!cursorOnMenu) setOpenItemId(false)
  }

  const linkClicked = () => {
    setOpenItemId(false)
  }

  const checkActiveLink = (url) => {
    return window.location.pathname.includes(String(url))
  }

  const [windowQuery] = useWindowResize()

  return (
    <UserWrapper
      className={`${user.authorized ? "loggedIn" : "loggedOut"} ${isScrollTop ? '' : 'scrolled'}`}
    >
      <UserDropdown
        className={`user-action-container ${openItemId ? 'open' : 'closed'}`}
        notificationsCount={notificationsCount}
        location={location}
        isScrollTop={isScrollTop}
        themeSettings={themeSettings}
      >
        {user.authorized ? (
          <div
            className="userControls"
            onMouseEnter={() => setCursorOn(true)}
            onMouseLeave={() => setCursorOn(false)}
          >
            <div className={`userCard`}>
              <AvatarWrapper
                id="user-dropdown-menu"
                className={isScrollTop ? '' : 'scrolled'}
                location={location}
                frontpage={location === '/' || location === '/discover'}
                isScrollTop={isScrollTop}
                onClick={(e) =>
                  isTabletView
                    ? {}
                    : // ? history.push('/user/profile/about')
                      toggleDropdown(e, 'user-dropdown-menu', openItemId)
                }
              >
                <img
                  src={user.profile.avatar}
                  alt="Avatar"
                  data-testid="navlink-userAvatar"
                />
                {!isTabletView && (
                  <div
                    className={`icon-dropdown ${
                      openItemId === 'user-dropdown-menu' ? ' open' : ''
                    }`}
                  >
                    <i className="fas fa-chevron-up" />
                  </div>
                )}
              </AvatarWrapper>
              <NavNotifications
                isScrollTop={isScrollTop}
                setOpenItemId={toggleDropdown}
                openItemId={openItemId}
                messagesList={messagesList}
                notificationsList={notificationsList}
                refetchNotifications={refetchNotifications}
              />
              {isTabletView && (
                <div
                  onClick={(e) => {
                    toggleDropdown(e, 'user-dropdown-menu', openItemId)
                  }}
                  className={`icon-dropdown ${
                    openItemId === 'user-dropdown-menu' ? ' open' : ''
                  }`}
                >
                  <i className="fas fa-chevron-up" />
                </div>
              )}
            </div>
            <NavLinksWrapper
              className={`${
                openItemId === 'user-dropdown-menu' ? 'open' : 'closed'
              }`}
            >
              <NavigationLinks
                className={`dropdown-links ${
                  openItemId === 'user-dropdown-menu' ? 'open' : 'closed'
                }`}
                onClickOutside={menuOff}
              >
                  <Link
                    onClick={() => linkClicked()}
                    className={`dropdown-link ${
                      checkActiveLink('/user/profile') ? ' active' : ''
                    }`}
                    to="/user/profile"
                    data-testid={`dropdown-${
                      openItemId ? 'open' : 'closed'
                    }-navlink-myProfile`}
                  >
                    {userDropdownMyProfile}
                  </Link>

                {user?.profile?.role === 'admin' ? (
                  <>
                    <Link
                      onClick={() => linkClicked()}
                      className={`dropdown-link admin ${
                        checkActiveLink('/manage/dashboard') ? ' active' : ''
                      }`}
                      to="/manage/dashboard"
                      data-testid={`dropdown-${
                        openItemId ? 'open' : 'closed'
                      }-navlink-admin`}
                    >
                      {userDropdownAdmin}
                    </Link>
                  </>
                ) : null}

                <Link
                  to="#"
                  className="dropdown-link"
                  onClick={() => handleLogout()}
                  data-testid={`dropdown-${
                    openItemId ? 'open' : 'closed'
                  }-navlink-signOut`}
                >
                  {userDropdownSignOut}
                </Link>
              </NavigationLinks>
            </NavLinksWrapper>
          </div>
        ) : (
          <SignInContainer>
            <Link
              className="register-link"
              to="/registration"
              data-testid="navlink-signUp"
            >
              <SignUpButton location={location} isScrollTop={isScrollTop}>
                {' '}
                {userDropdownSignUp}
              </SignUpButton>
            </Link>
            <Link to="/login">
              <SignInButton
                className="sign-up-button"
                type="button"
                data-testid="navlink-signIn"
                location={location}
                isScrollTop={isScrollTop}
              >
                {userDropdownSignIn}
              </SignInButton>
            </Link>
          </SignInContainer>
        )}
      </UserDropdown>
    </UserWrapper>
  )
}

export default connect(mapStateToProps)(Index)
