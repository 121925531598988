import React from 'react'
import styled from '@emotion/styled'
import DashboardMessagesConversationTimeline from '../dashboard-messages-conversation-timeline'

const ConversationFieldContainer = styled.div`
  height: var(--conversationFieldHeight, calc(100vh - 390px));
  position: relative;
  overflow-y: auto;
  flex-grow: 1;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }
`

const InnerConversationFieldContainer = styled.div`
  padding: 30px 0 0;
  overflow-x: hidden;
  div.messages-conversation-timeline {
    padding: 0 40px;

    .single-message:nth-last-child(2) {
      padding-bottom: 40px;
    }
  }
`

export const DashboardMessagesConversationField = ({ channel, chatRoom }) => {
  return (
    <ConversationFieldContainer>
      <InnerConversationFieldContainer>
        <DashboardMessagesConversationTimeline
          channel={channel}
          chatRoom={chatRoom}
        />
      </InnerConversationFieldContainer>
    </ConversationFieldContainer>
  )
}

export default DashboardMessagesConversationField
