import React from 'react'
import NotificationsCenter from './notifications-center'
import NavWrapper from 'layouts/Main/logged-content-wrapper.js'

const Index = () => {
  return (
    <NavWrapper>
      <NotificationsCenter />
    </NavWrapper>
  )
}

export default Index
