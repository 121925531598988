import React from 'react'
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api'

const MapInline = ({ location, key, className, onBoundsChange }) => {
  const defaultStyle = [
    {
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f6f5f7',
        },
      ],
    },
  ]

  const position = {
    lat: parseFloat(location ? location.lat : 0),
    lng: parseFloat(location ? location.long : 0),
    type: 'success',
  }

  return (
    <GoogleMap
      mapContainerClassName={className}
      center={position}
      zoom={8}
      options={{
        styles: defaultStyle,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
      defaultMapTypeId="custom_style"
      onBoundsChanged={function () {
        if (onBoundsChange) {
          onBoundsChange(this?.getBounds()?.toUrlValue())
        }
      }}
    >
      <MarkerClusterer>
        {(clusterer) => (
          <Marker key={key} position={position} clusterer={clusterer} />
        )}
      </MarkerClusterer>
    </GoogleMap>
  )
}

export default MapInline
