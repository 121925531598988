import React from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const cross = () => css`
  --move: calc(35% / 2);

  .line {
    transform: translateX(calc(var(--move) * -1));
  }

  .line2 {
    transform: translateX(var(--move));
    stroke-dasharray: 12;
    stroke-dashoffset: 0;
  }
`

const ModularIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &, * {
    font-family: 'Inter', sans-serif;
    outline: none;
    overflow: visible !important;
  }

  svg {
    overflow: visible;
    width: 80%;
    height: 80%;
  }

  .circleStroke {
    stroke: var(--stroke-color);
    stroke-width: 0px;
  }

  .circleBg {
    display: none;
  }

  .circleBg,
  .circleStroke,
  .line,
  .line2,
  .lines,
  svg {
    transition: .2s ease-in-out;
  }

  .circleBg,
  .circleStroke,
  .line,
  .line2,
  .lines,
  svg {
    fill: none;
  }

  .line,
  .line2 {
    stroke: var(--stroke-color);
    stroke-linecap: round;
    stroke-width: 2px;
    stroke-dasharray: 12;
    stroke-dashoffset: 0;
  }

  .checkmark {
    .circleBg {
      fill: green;
      display: block;
    }

    .lines {
      transform: translateX(-1.5px);
    }

    .line,
    .line2 {
      stroke: var(--stroke-color);
      stroke-dasharray: 12;
      stroke-dashoffset: 0;
    }

    .line2 {
      stroke-dasharray: 12;
      stroke-dashoffset: 6;
    }

    &.empty {

      .line,
      .line2 {
        stroke-dasharray: 12;
        stroke-dashoffset: 12;
      }

      .circleBg {
        opacity: 0;
        stroke: red;
        stroke-width: 2px;
      }

      .circleStroke {
        stroke-width: 2px;
      }
    }
  }

  .cross {
    ${cross}
  }

  .rotate45 {
    transform: rotate(45deg);
  }

  .rotate90 {
    transform: rotate(-90deg);
  }

  .plus {
    ${cross}
    transform: rotate(45deg);
  }

  .pen {
    --move: calc(35% / 2);
    transform: scale(.7);
    .line {
      transform: translate(-30%, 10%);
    }
    .line2 {
      transform: translate(10%, 70%) rotate(-90deg);
      stroke-dasharray: 12;
      stroke-dashoffset: 8.5;
    }
  }

  .oneLine {
    --move: calc(35% / 2);
    .line {
      transform: translate(-30%, 10%);
    }
    .line2 {
      transform: translate(10%, 70%) rotate(-90deg);
    }
  }
`

function ModularIcon(props) {
  const { style, onClick, classAdd, exClass } = props
  return (
    <ModularIconDiv onClick={onClick} style={style} className={"svg-container " + classAdd}>
      <svg id="Layer_1" data-name="Layer 1" className={exClass} viewBox="0 0 24 24">
        <path id="circleBg" className={"circleBg"} d="M20,24H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4H20c2.2,0,4,1.8,4,4V20C24,22.2,22.2,24,20,24z" />
        <path id="circleStroke" className={"circleStroke"} d="M20,24H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4H20c2.2,0,4,1.8,4,4V20C24,22.2,22.2,24,20,24z" />
        <g id="lines" className={"lines"}>
          <line id="line" className={"line"} x1="11.99" y1="16.74" x2="20.47" y2="8.26" />
          <line id="line2" className={"line2"} x1="11.99" y1="16.74" x2="3.5" y2="8.26" />
        </g>
      </svg>
    </ModularIconDiv>
  );
}

export default ModularIcon;