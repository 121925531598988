import React from 'react'
import { useAppConfig, useAppLabel } from '_application'
import { useIntl } from 'react-intl'
import moment from 'moment'
import {
  Title,
  ItemName,
  Item,
  InfoWrapper,
  NotificationCounter,
  Date,
} from './styles/navigation-content'

const convertDateString = (string, intl) => {
  if (string.includes('a few seconds'))
    return string.replace(
      'a few seconds',
      `${intl.formatMessage({ id: 'date.timefromnow.now' })}`
    )
  if (string.includes('a minute')) return string.replace('a minute', '1 min')
  if (string.includes('minutes')) return string.replace('minutes', 'min')
  if (string.includes('hours')) return string.replace('hours', 'h')
  if (string.includes('a hour')) return string.replace('a hour', '1 h')
  if (string.includes('days')) return string.replace('days', 'd')
  if (string.includes('a day')) return string.replace('a day', '1 d')
  if (string.includes('months')) return string.replace('months', 'm')
  if (string.includes('a month')) return string.replace('a month', '1 m')
  if (string.includes('years')) return string.replace('years', 'y')
  if (string.includes('a year')) return string.replace('a year', '1 y')
  return string
}

const notificationsTypes = [
  {
    name: 'All',
    key: 'all',
    data: '',
  },
  {
    name: 'People',
    key: 'people',
    data: '',
  },
  {
    name: 'Opportunities',
    key: 'opportunities',
    data: '',
  },
  {
    name: 'Companies',
    key: 'companies',
    data: '',
  },
  {
    name: 'Activities',
    key: 'activities',
    data: '',
  },
  {
    name: 'Events',
    key: 'events',
    data: '',
  },
  {
    name: 'Recommendations',
    key: 'recommendations',
    data: '',
  },
  {
    name: 'Forum',
    key: 'forum',
    data: '',
  },
  {
    name: 'Ownership',
    key: 'ownership-change',
    data: '',
  },
]

const LeftNavigation = ({ handleSorting, sorting, data, user }) => {
  const appLabel = useAppLabel()
  const intl = useIntl()
  const appConfig = useAppConfig()
  const features = appConfig?.features || []
  const isEventActive = features?.includes('events')

  const totalCount = Object.values(data).flat().length

  return (
    <>
      <Title>
        {intl.formatMessage({ id: 'notificationscenter.navigation.title' })}
      </Title>
      {notificationsTypes.map((type) => {
        const filteredNotifications = data[type.key]?.filter(
          (item) => item.notification.sub !== user.profile.id
        )

        if (type.key === 'events' && !isEventActive) return <></>
        return (
          <Item
            id={type.key}
            onClick={(e) => {
              handleSorting(e, type.key)
            }}
            className={`${type.key === sorting ? 'selected' : ''} ${
              type.key === 'all' && !sorting && 'selected'
            }`}
          >
            <ItemName>
              {appLabel(`${type.key}Plural`, `${type.key}.titleplural`)}
            </ItemName>
            <InfoWrapper>
              <NotificationCounter>
                {type.key === 'all'
                  ? totalCount
                  : filteredNotifications?.length || 0}
              </NotificationCounter>
              <Date>
                {filteredNotifications?.length > 0 &&
                  convertDateString(
                    moment(
                      data[type.key]?.[data[type.key].length - 1]?.notification
                        ?.sentDate
                    ).fromNow(true),
                    intl
                  )}
              </Date>
            </InfoWrapper>
          </Item>
        )
      })}
    </>
  )
}

export default LeftNavigation
