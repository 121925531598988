import { gql } from '@apollo/client'

export const GET_ACTIVITY = gql`
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      coverPhoto
      description
      start
      end
      location
      connectionStatus
      _createdAt
      connectedCompanies {
        id
        coverPhoto
        name
        location
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_OPPORTUNITY = gql`
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      name
      coverPhoto
      description
      start
      end
      location
      _createdAt
      connectedCompanies {
        id
        coverPhoto
        name
        location
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_BLOG = gql`
  query GetBlog($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      coverPhoto
      description
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      coverPhoto
      bannerImage
      location
      description
      connectionStatus
      connectedUsers {
        sub
        id
        avatar
      }
      connectedCompanies {
        id
        coverPhoto
      }
      connectedActivities {
        id
        coverPhoto
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_PERSON = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      fullName
      email
      birthDate
      gender
      avatar
      coverPhoto
      bioShort
      bioLong
      instagram
      linkedIn
      twitter
      languagesSpoken
      languagesWritten
      phone
      role
      location
      lastJobPosition {
        companyName
        title
      }
      competences {
        value
        type
      }
      experience {
        companyName
        title
        startDate
        endDate
        location
        description
        websiteUrl
      }
      education {
        schoolName
        degree
        domain
        yearGraduated
        websiteUrl
      }
      customAttributes {
        key
        value
        type
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomies {
        key
        values
      }
    }
  }
`

export const resolveSubtype = ({ subtype }) => {
  switch (subtype) {
    case 'p2a-recommend':
      return {
        key: 'activities',
        gqlQuery: GET_ACTIVITY,
        gqlQueryName: 'getActivity',
      }
    case 'p2c-recommend':
      return {
        key: 'companies',
        gqlQuery: GET_COMPANY,
        gqlQueryName: 'getCompany',
      }
    case 'p2p-recommend':
      return {
        key: 'people',
        gqlQuery: GET_PERSON,
        gqlQueryName: 'getUser',
      }
    case 'p2o-recommend':
      return {
        key: 'opportunities',
        gqlQuery: GET_OPPORTUNITY,
        gqlQueryName: 'getOpportunity',
      }
    case 'p2b-recommend':
      return {
        key: 'blog',
        gqlQuery: GET_BLOG,
        gqlQueryName: 'getBlogPost',
      }
    default:
      return ''
  }
}
