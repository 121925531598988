import styled from '@emotion/styled'
import border from '_utils/css-border'
import { mobile, tablet, pablet } from '_utils/responsive-queries'

import circleButton, { ctaButtonHollow } from '_utils/css-buttons'
import { themeIsDark } from '_colorBus/primitives/scheme'

export const GoBackButton = styled.button`
  margin: 65px 0;
  i {
    margin-right: 10px;
  }
  &:first-letter {
    font-size: 700 !important;
  }
`

export const TabsWrapper = styled.div`
  max-width: 825px;
  margin: 0 auto;
  ${tablet} {
    margin: 0 80px;
  }
  ${pablet} {
    margin: 0 30px;
  }
  ${mobile} {
    margin: 0 40px;
  }
`
export const ConnectionsWrapper = styled.div`
  margin: 0 130px;
  ${tablet} {
    margin: 0 80px;
  }
  ${pablet} {
    margin: 0 30px;
  }
  ${mobile} {
    margin: 0 10px;
  }
`

export const UserWrapper = styled.div`
  ${border()}
  background-color: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};
  max-width: 825px;
  padding: 40px 40px 50px;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 20px;
  margin: 0 auto 20px;
  ${tablet} {
    padding: 30px 50px;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
  }
  ${mobile} {
    padding: 30px 15px;
  }
`

export const BottomBackButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
  button {
    ${ctaButtonHollow};
  }
`

export const ContentWrapper = styled.div`
  margin: 0 auto;
  ${tablet} {
    max-width: 100%;
  }
`

export const TopBackButtonWrapper = styled.div`
  position: absolute;
  margin: 25px 0 0 25px;
  button {
    ${circleButton}
    background-color: rgba(0,0,0,.1);
    i {
      color: white;
    }
  }
`
