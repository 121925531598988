import styled from '@emotion/styled'

export const PdfsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;

  .pdf-wrapper {
    display: flex;
    margin-top: 25px;
    i {
      font-size: 25px;
    }

    .pdf-title {
      font-size: 14px;
      font-weight: 400;
      color: var(--foreground);
      margin: 0 10px;
      width: 170px;
    }

    button {
      width: 23px;
      border-radius: 50%;
      height: 23px;
      background: var(--foreground);
      border: none;

      i {
        font-size: 12px;
        color: var(--background);
      }
    }
  }
`
