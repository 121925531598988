import axios from 'axios'
import store from 'store'

const uploadFileToCdn = (file, type) => {
  return new Promise((resolve) => {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('type', type)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/image/upload`, formData, {
        headers: { authorization: store.get('RIOT/token') },
      })
      .then((r) => {
        const result = {
          file: {
            name: file.name,
            url: r?.data.urlNoCache,
            type: file.type,
          },
          extension: file?.name?.split('.')?.pop() || 'unknown',
          dateCreated: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
        }
        resolve(result)
      })
  })
}

export const MbToB = (mb) => mb * 1000000

export const bToMb = (b) => b / 1000000

export default uploadFileToCdn
