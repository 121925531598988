import axios from 'axios'
import { notification } from 'antd'

export const firstTimeSetup = async (plugin, intl) => {
  let request = {
    payload: {
      hostname:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3000'
          : `https://${window.location.hostname}`,
    },
  }
  if (plugin.key === 'stripe') {
    request = {
      ...request,
      path: `${process.env.REACT_APP_API_ENDPOINT}/payment/onboard-account`,
    }
  }

  const { data } = await axios.post(request.path, request.payload, {
    headers: { authorization: localStorage.getItem('RIOT/token') },
  })
  if (plugin.key === 'stripe') {
    if (data?.payload?.url) window.location = data.payload.url
    else
      notification.warning({
        message: intl.formatMessage({ id: 'toast.stripe.error.message' }),
      })
  }
}
