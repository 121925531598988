import gql from 'graphql-tag'

export const GET_FORUM_CATEGORIES = gql`
  {
    getCategories {
      categoriesList {
        name
        description
        cid
        parentcid
        slug
        icon
        color
        bgcolor
        childrenList {
          name
          description
          cid
          parentcid
          slug
          icon
          color
          bgcolor
        }
      }
    }
  }
`

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: String!
    $description: String
    $parentcid: ID
    $icon: String
    $bgcolor: String
    $color: String
  ) {
    createCategory(
      name: $name
      description: $description
      parentcid: $parentcid
      icon: $icon
      bgcolor: $bgcolor
      color: $color
    ) {
      created
      category {
        cid
        name
        description
        slug
      }
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($cid: ID!) {
    deleteCategory(cid: $cid) {
      status {
        status
        message
      }
      response
    }
  }
`

export const EDIT_CATEGORY = gql`
  mutation editCategory(
    $cid: ID!
    $name: String!
    $description: String
    $parentcid: ID
    $icon: String
    $bgcolor: String
    $color: String
  ) {
    editCategory(
      cid: $cid
      name: $name
      description: $description
      parentcid: $parentcid
      icon: $icon
      bgcolor: $bgcolor
      color: $color
    ) {
      status {
        status
        message
      }
      category {
        cid
        name
        description
        slug
      }
    }
  }
`

export const UPDATE_FORUM_MODERATED_CATEGORIES = gql`
  mutation updateForumModeratedCategories(
    $forum: [ForumModeratedCategoryInput]
  ) {
    updateForumModeratedCategories(forum: $forum) {
      forum {
        id
        name
      }
    }
  }
`
