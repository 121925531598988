import React from 'react'
import { MainSection } from '../../settings/shared/styles/sections'
import { SectionDescription, SectionTitle } from './styles/labels-section'

const LabelsSection = ({ children, title, description }) => {
  return (
    <>
      <MainSection>
        <SectionTitle>{title}</SectionTitle>
        <SectionDescription>{description}</SectionDescription>
        {children}
      </MainSection>
    </>
  )
}

export default LabelsSection
