import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useHistory } from 'react-router-dom'
import DatePicker from 'react-date-picker'
import { TimePicker } from '@patternfly/react-core'
import { MainWrapper } from '../styles'
import {
  StaticInfo,
  Header,
  Description,
  Actions,
  InputsWrapper,
  ButtonsWrapper,
  RegularButton,
  PublishButton,
  LeftWrapper,
  RightWrapper,
  CheckboxWrapper,
  PickersWrapper,
} from './styles/publish-confirm'
import { useAppConfig } from '_application'
import moment from 'moment'
import { roundTime } from '../../helpers/roundTime'

const ConfirmPublish = ({ event, updateEvent, setEvent }) => {
  const [hasScheduleDate, setHasScheduleDate] = useState(false)
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const eventId = location.pathname.slice(location.pathname.length - 24)
  const { defaultLocale } = useAppConfig()

  const actionButtonsConfig = {
    onPublish: () => {
      // setEvent((prevState) => ({ ...prevState, status: 'PUBLISHED' }))
      updateEvent()
      history.push(`/events/list`)
      // setStatus('PUBLISHED')
    },
    // onDraft: () => {
    // setEvent((prevState) => ({ ...prevState, status: 'DRAFT' }))
    // return updateEvent()
    // setStatus('DRAFT')
    // },
  }

  return (
    <MainWrapper>
      <StaticInfo>
        <Header>
          {intl.formatMessage({ id: 'event.preview.publishsection.title' })}
          {/* Publish date */}
        </Header>
        <Description>
          {intl.formatMessage({
            id: 'event.preview.publishsection.description',
          })}
        </Description>
      </StaticInfo>
      <Actions>
        <InputsWrapper>
          <CheckboxWrapper>
            <input
              type="checkbox"
              id="laterCheckbox"
              checked={hasScheduleDate}
              onChange={() => setHasScheduleDate(!hasScheduleDate)}
            />
            <label for="laterCheckbox">
              {intl.formatMessage({
                id: 'event.preview.publishsection.checkbox.label',
              })}
            </label>
            {hasScheduleDate && (
              <p className="additonal-info">
                Plan 30 minutes in advance and be aware that you won't be able
                to see or edit your event until it goes live.
              </p>
            )}
          </CheckboxWrapper>
          {hasScheduleDate && (
            <PickersWrapper>
              <div className="date-picker">
                <DatePicker
                  value={new Date(event?.publishAt)}
                  showLeadingZeros
                  clearIcon={false}
                  locale={defaultLocale.replace('_', '-')}
                  disabled={!hasScheduleDate}
                  minDate={new Date()}
                  onChange={(value) => {
                    const formattedValue = moment(value).format('YYYY-MM-DD')
                    setEvent({
                      ...event,
                      publishAt: new Date(
                        `${formattedValue} ${roundTime(event?.publishAt)}`
                      ).toISOString(),
                    })
                  }}
                />
              </div>
              <TimePicker
                is24Hour
                time={roundTime(event?.publishAt)}
                onChange={(value) =>
                  setEvent({
                    ...event,
                    publishAt: new Date(
                      `${moment(event?.publishAt).format(
                        'YYYY-MM-DD'
                      )} ${value}`
                    ).toISOString(),
                  })
                }
                style={{ width: '140px' }}
                isDisabled={!hasScheduleDate}
              />
            </PickersWrapper>
          )}
        </InputsWrapper>
        <ButtonsWrapper>
          <LeftWrapper>
            <RegularButton
              onClick={() =>
                history.push(`/events/manage/additonal-information/${eventId}`)
              }
            >
              {intl.formatMessage({
                id: 'events.create.button.back',
              })}
            </RegularButton>
          </LeftWrapper>
          <RightWrapper>
            {/* <RegularButton onClick={actionButtonsConfig.onDraft}>
              {intl.formatMessage({
                id: 'event.preview.publishsection.button.draft',
              })}
            </RegularButton> */}
            <PublishButton onClick={actionButtonsConfig.onPublish}>
              {intl.formatMessage({
                id: 'event.preview.publishsection.button.publish',
              })}
              {/* Publish */}
            </PublishButton>
          </RightWrapper>
        </ButtonsWrapper>
      </Actions>
    </MainWrapper>
  )
}

export default ConfirmPublish
