import React from 'react'
import {
  FullBackgroundTileStyled,
  Location,
  InfoWrapper,
  ConnectionsWrapper,
  Name,
  TopContainer
} from './styled/full-background-tile'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon.svg'

import Connections from '_tiles/shared/full-background-tile-connections'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useUser } from '_security/session-hooks'
import { useUserAccess } from '_security/helpers'

const renderLocation = (location) => {
  if (!location) return null
  const { city, country } = location

  const hasCity = city && city !== 'null' && city !== 'undefined' && city !== ''
  const hasCountry =
    country && country !== 'null' && country !== 'undefined' && country !== ''

  return (
    <Location>
      {(hasCity || hasCountry) && <LocationIcon />}
      {`${hasCity ? city + ', ' : ''}${hasCountry ? country : ''}`}
    </Location>
  )
}
 
const FullBackgroundTile = ({
  activity,
  isLargeTile,
  minHeight,
  connectButton,
}) => {
    const user = useUser()
    const hasUserAccess = useUserAccess()
   const isLogged = user?.profile?.role
  const { coverPhoto, location, name } = activity || {}

  return (
    <FullBackgroundTileStyled
      className={`${isLargeTile ? 'large' : ''}`}
      coverPhoto={coverPhoto}
      minHeight={minHeight}
    >
      <TopContainer>
        <InfoWrapper>
          {renderLocation(location)}
          <Name>{name}</Name>
        </InfoWrapper>
        <ConnectionsWrapper>
          <Connections data={activity} />
        </ConnectionsWrapper>
      </TopContainer>

      {connectButton && (
        <ConnectButton
          status={isLogged && hasUserAccess ? activity.connectionStatus : null}
          onConnect={
            isLogged && hasUserAccess ? connectButton?.onConnect : () => {}
          }
          disabled={connectButton?.disabled}
          type="activities"
        />
      )}
    </FullBackgroundTileStyled>
  )
}

export default FullBackgroundTile
