import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { GET_COMPANY } from '../shared/helpers'
import { useQuery } from '@apollo/client'

const Activity = ({ item, acceptAction, declineAction }) => {
  const intl = useIntl()

  const { data, loading } = useQuery(GET_COMPANY, {
    variables: {
      id:
        item?.notification?.payload?.find((p) => p?.key === 'company')?.value ||
        '',
    },
  })

  const companyLink = data?.getCompany?.id

  const ContentComponent = () => (
    <span>
      {`${intl.formatMessage({
        id: 'notificationscenter.notification.message.connect.activity',
      })}`}{' '}
      <Link to={`/companies/list/${companyLink}`}>
        {data?.getCompany?.name}
      </Link>
      {'.'}
    </span>
  )

  if (!data || loading) return null

  return (
    <BaseNotification
      item={item}
      acceptAction={acceptAction}
      declineAction={declineAction}
      ContentComponent={ContentComponent}
    />
  )
}

export default Activity
