import React from 'react'
import { Button } from '_themes/default/components/styled/category-tag'

const CategoryTag = ({ children, className }) => (
  <Button type="button" className={className}>
    {children}
  </Button>
)

export default CategoryTag
