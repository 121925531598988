import React from 'react'
import moment from 'moment'
import {
  EventTileWrapper,
  Location,
  InfoWrapper,
  Name,
  Date,
  Description,
  DetailsButton,
} from './styles'
import { roundTime } from '../helpers/roundTime'

const DefaultTile = ({ event, className }) => {
  const { bannerImage, start, end, excerpt, title, location } = event

  return (
    <EventTileWrapper className={className} imageUrl={bannerImage}>
      <Location>
        {location?.type !== 'ONLINE' ? (
          <>
            {location?.address && (
              <>
                <i className="fas fa-map-marker-alt" />
                {location?.address}
              </>
            )}
          </>
        ) : (
          'Online'
        )}
      </Location>
      <InfoWrapper>
        <Name>{title}</Name>
        <Date>
          {moment(start).format('DD MMM YYYY')} -{' '}
          {moment(end).format('DD MMM YYYY')}
        </Date>
        <Date>
          {roundTime(start)}
          {' - '}
          {roundTime(end)}
        </Date>
        <Description>{excerpt}</Description>
        <DetailsButton>Details</DetailsButton>
      </InfoWrapper>
    </EventTileWrapper>
  )
}

export default DefaultTile
