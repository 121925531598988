import styled from '@emotion/styled'

const BackgroundImageStyled = styled.div`
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  position: relative;
  height: 300px;
  img {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
  }
`

export default BackgroundImageStyled
