import React, { useState } from 'react'
//import CubeIcon from '@patternfly/react-icons/dist/esm/icons/cube-icon'
import {
  SelectOption,
  SelectVariant,
  SelectDirection,
} from '@patternfly/react-core'
import { useIntl } from 'react-intl'
import { StyledSelect } from '../styles'
import { css } from '@emotion/react'

const intlKey = 'application.management.'

const ApplicationListSelect = ({ listState, lists }) => {
  const [list, setList] = listState
  const intl = useIntl()

  const options = lists.map((option) => (
    <SelectOption key={option.value} value={option.value}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          column-gap: 1em;
        `}
      >
        <span>
          {intl.formatMessage({
            id: `${intlKey}category.${option.value?.split(' ')?.join('')}`,
          })}
        </span>
        <span>{option.count}</span>
      </div>
    </SelectOption>
  ))

  const [isOpen, setOpen] = useState(false)

  const onSelect = (e, selection) => {
    setList(selection)
    setOpen(false)
  }

  const titleId = 'title-id-1'
  return (
    <StyledSelect
      //toggleIcon={<CubeIcon />}
      variant={SelectVariant.single}
      aria-label="Select Input"
      listSelect
      onToggle={setOpen}
      onSelect={onSelect}
      selections={list}
      placeholderText="filters"
      isOpen={isOpen}
      aria-labelledby={titleId}
      direction={SelectDirection.down}
      position={'right'}
    >
      {options}
    </StyledSelect>
  )
}

export default ApplicationListSelect
