import React from 'react'
import { useIntl } from 'react-intl'
import {
  ProductPreviewStyled,
  ProductWrapper,
  ImageWrapper,
  InfoWrapper,
  Section,
  MainWrapper,
  BottomPart,
  ProductPricing,
  ProductName,
  ProductDescription,
} from '../styles/product-preview'
import {
  couponToAmountText,
  couponToDurationText,
  calculateTotal,
} from '../../shared/payment-helpers'
import { ModalTitle } from '../styles/checkout'

const ProductPreview = ({ product, promoCode, renderMessageText }) => {
  const description = product?.description || ''
  const price = product?.prices?.[0] || {}
  const image = product.images?.[0]
  const { coupon } = promoCode || {}

  const intl = useIntl()

  return (
    <ProductPreviewStyled>
      <ModalTitle className="title">
        {renderMessageText('summary', 'Order Summary')}
      </ModalTitle>
      <ProductWrapper className="product">
        <MainWrapper>
          <ImageWrapper>
            {image ? <img src={image} alt={product.name} /> : <span>$</span>}
          </ImageWrapper>
          <InfoWrapper>
            <ProductName>{product.name}</ProductName>
            <ProductDescription>{description}</ProductDescription>
          </InfoWrapper>
        </MainWrapper>
        <ProductPricing>
          <span className="price">
            {`${price.currency.toUpperCase()} ${(
              price.unit_amount / 100
            ).toFixed(2)}`}
          </span>

          {price.recurring && (
            <>
              <span className="divider">/</span>
              <span className="interval">
                {renderMessageText(
                  `summary.subscription.interval.${price.recurring?.interval}`
                )}
              </span>
            </>
          )}
        </ProductPricing>
      </ProductWrapper>
      <BottomPart className="bottom">
        {coupon && (
          <Section className="section">
            <span className="title">
              {renderMessageText('discount', 'Discount')}
            </span>

            <span className="align-right">
              {`-${couponToAmountText({ coupon })}
            (${couponToDurationText({
              coupon,
              intl,
            })})`}
            </span>
          </Section>
        )}

        <Section className="section bold">
          <span className="title">
            {renderMessageText('summary.total', 'Cancel')}
          </span>
          <span className="align-right">
            {calculateTotal({ price, coupon, renderMessageText })}
          </span>
        </Section>
      </BottomPart>
    </ProductPreviewStyled>
  )
}

export default ProductPreview
