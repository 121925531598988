import React from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { SubNavigationStyled, NavItem } from '../styles/sub-navigation'

const SubNavigation = ({ navItems }) => {
  const intl = useIntl()
  const { pathname } = useLocation()

  return (
    <SubNavigationStyled>
      {navItems.map((item) => {
        const isCurrentRoute = item?.url === pathname
        return (
          <NavItem className={isCurrentRoute ? 'active' : ''}>
            <Link to={item?.url}>
              {intl.formatMessage({
                id: item?.titleKey,
                defaultMessage: item?.defaultMessage,
              })}
            </Link>
          </NavItem>
        )
      })}
    </SubNavigationStyled>
  )
}

export default SubNavigation
