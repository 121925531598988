const translations = {
  title: 'contentstudio.form.receive-applications-by.title',
  selector: {
    title: 'contentstudio.form.receive-applications-by.selector.title',
    options: {
      default: 'contentstudio.form.receive-applications-by.selector.options.default',
      externalWebsite: 'contentstudio.form.receive-applications-by.selector.options.external-website',
      thisPlatform: 'contentstudio.form.receive-applications-by.selector.options.thisPlatform', 
    }
  },
  input: {
    ats: {
      info: "contentstudio.form.receive-applications-by.input.ats.info",
      placeholder: "contentstudio.form.receive-applications-by.input.ats.placeholder",
      error: "contentstudio.form.receive-applications-by.input.ats.error"
    },
    app: { 
      info: "contentstudio.form.receive-applications-by.input.app.info",
      placeholder: "contentstudio.form.receive-applications-by.input.app.placeholder",
      error: "contentstudio.form.receive-applications-by.input.app.error"
    } 
  }  
} 
 
export default translations