import styled from '@emotion/styled'

export const ModalStyled = styled.div`
  color: var(--foreground);
  select {
    background: var(--background);
  }

  .result {
    .title {
      display: table;
      margin: 0 auto;
      font-size: 24px;
      margin-bottom: 6px;
    }
    a {
      margin-left: 5px;
      color: var(--flavor);
      text-decoration: underline;
      font-weight: bold;
    }
  }
`

export const Section = styled.div`
  margin: 20px 10px;
  .title {
    font-weight: bold;
    margin-bottom: 6px;
    display: block;
  }
  .checkbox {
    margin: 4px 0;
    display: block;
    input {
      margin-right: 10px;
    }
    span {
      user-select: none;
    }
  }
`
