import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import Loading from '_components/loading'
import Error from '_components/error'
import Map from '_map-with-result-grid'
import { useHistoryPreservingSearch } from '_utils/router'
import { toMarkers } from '../companies-models'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'

const GET_COMPANIES = gql`
  query GetAllCompaniesMap(
    $filter: CompanyFilterInput
    $ordering: [OrderingInput]
  ) {
    getAllCompaniesPublic(filter: $filter, ordering: $ordering) {
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
      }
    }
  }
`

const CompaniesMapConnected = ({ filter }) => {
  const history = useHistoryPreservingSearch()

  let ordering = undefined
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error } = useQuery(GET_COMPANIES, {
    variables: { filter, ordering },
    notifyOnNetworkStatusChange: true,
  })

  const companies = data?.getAllCompaniesPublic?.data

  const markers = useMemo(() => toMarkers(companies || []), [companies])
  const showCompany = useCallback(
    (company) => history.push(`/companies/map/${company.id}`),
    [history]
  )

  if (!companies && loading) return <Loading />
  if (error) return <Error />

  return <Map markers={markers} onSelect={showCompany} type="companies" />
}
CompaniesMapConnected.propTypes = {
  filter: PropTypes.object,
}

export default CompaniesMapConnected
