import React from 'react'
import ActivitiesGrid from '_activities/components/activities-grid'

const ActivitiesGridConnected = ({ items: activities }) => {
  return (
    <div>
      {activities && (
        <ActivitiesGrid
          activities={activities.map((a) => {
            return {
              activity: a?.data,
            }
          })}
        />
      )}
    </div>
  )
}

export default ActivitiesGridConnected
