import { gql } from '@apollo/client'

export const CONNECT_ACTIVITY_TO_COMPANY = gql`
  mutation ConnectActivityToCompany($companyId: String!, $activityId: String!, $companyOwner: String!){
    connectActivityToCompany(companyId: $companyId, activityId: $activityId, companyOwner: $companyOwner) {
      status
    }
  } 
`

export const DISCONNECT_ACTIVITY_TO_COMPANY = gql`
  mutation DisconnectActivityToCompany($companyId: String!, $activityId: String!){
    disconnectActivityToCompany(companyId: $companyId, activityId: $activityId) {
      status 
    }
  } 
`

export const CONNECT_ACTIVITY_TO_OPPORTUNITY = gql`
  mutation ConnectActivityToOpportunity(
    $opportunityId: String!
    $activityId: String!
    $opportunityOwner: String!
  ) {
    connectActivityToOpportunity(
      opportunityId: $opportunityId
      activityId: $activityId
      opportunityOwner: $opportunityOwner
    ) {
      status
    }
  }
`

export const DISCONNECT_ACTIVITY_TO_OPPORTUNITY = gql`
  mutation DisconnectActivityToOpportunity(
    $opportunityId: String!
    $activityId: String!
  ) {
    disconnectActivityToOpportunity(
      opportunityId: $opportunityId
      activityId: $activityId
    ) {
      status
    }
  }
`