import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '_components/loading'
import { H2 } from '_uikit/headers'
import { Modal, TextSection } from '_components/drilldown'
import SanityBlock from '_components/sanity-block'

const GET_PAGE = gql`
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      subtitle
      body
    }
  }
`

const Props = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
const PageConnected = ({ id, onClose }) => {
  const { data, error, loading } = useQuery(GET_PAGE, { variables: { id } })

  const page = data?.getPage

  return (
    <Modal onClose={onClose}>
      <Loader loading={loading} error={error}>
        {() => (
          <div>
            <H2>{page?.title}</H2>
            <TextSection title={page?.subtitle}>
              <SanityBlock>{page?.body}</SanityBlock>
            </TextSection>
          </div>
        )}
      </Loader>
    </Modal>
  )
}
PageConnected.propTypes = Props

export default PageConnected
