import React from 'react'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'
import moment from 'moment'

import AddEditModal from '../shared/modals/add-edit'

const fields = [
  { key: 'companyName', name: 'Company name', type: 'text', required: true },
  { key: 'title', name: 'Title', type: 'text', required: true },
  {
    key: 'startDate',
    name: 'Start date',
    type: 'date',
    required: true,
    minDate: moment().subtract(70, 'years'),
    maxDate: moment().add(2, 'years'),
  },
  {
    key: 'endDate',
    name: 'End date',
    type: 'date',
    optional: {
      dateButton: 'iscurrentposition',
      noDateButton: 'selectdate',
      noDateTitle: 'currentposition',
    },
    minDate: moment().subtract(70, 'years'),
    maxDate: moment().add(2, 'years'),
  },
  { key: 'location', name: 'Location', type: 'location' },
  { key: 'description', name: 'Description', type: 'text' },
  {
    key: 'websiteUrl',
    name: 'Website url',
    type: 'url',
    placeholder: 'https://...',
  },
]

const mapStateToProps = ({ user }) => ({ user })

const Modal = ({ user, currentExperience, onClose, dispatch }) => {
  const handleSave = (props) => {
    let list
    if (currentExperience) {
      list = user.profile.experience.map((e) =>
        e.key === props.key
          ? { ...props, location: props.location?.label || props.location }
          : e
      )
    } else {
      list = [
        ...user.profile.experience,
        {
          ...props,
          location: props.location?.label || props.location,
          key: uuid(),
        },
      ]
    }

    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: {
        ...user,
        profile: { ...user.profile, experience: list },
      },
    })

    onClose()
  }

  return (
    <AddEditModal
      title={'Experience'}
      defaultProps={currentExperience}
      user={user}
      onSave={(props) => handleSave(props)}
      onClose={() => onClose()}
      fields={fields}
    />
  )
}

export default connect(mapStateToProps)(Modal)
