import React from 'react'
import SocialMedia from './social-media'
import GeneralInfo from './general-info'
import CustomAttributes from './custom-attributes'
import Education from './education'
import Experience from './experience'

const About = () => {
  return (
    <>
      <GeneralInfo />
      <SocialMedia />
      <Education />
      <Experience />
      <CustomAttributes />
    </>
  )
}

export default About
