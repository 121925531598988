import React from 'react'
import { MyActivitiesStyled } from './styles/my-activities'
import { GET_ACTIVITIES } from '_activities/connected/activities-grid-connected'
import { useQuery } from '@apollo/client'
import { FullBackground as FullBackgroundTile } from '_activities/components/tiles'
import { Grid, BaseTile } from '_tiles'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'

const gridConfig = {
  hasGutter: true,
}

const defaultConfig = {
  '2xl': 6,
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
}

const largeConfig = {
  '2xl': 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
}

const MyActivities = ({ person }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const { data, loading } = useQuery(GET_ACTIVITIES, {
    variables: {
      filter: { createdById: person?.sub },
      ordering: [{ field: '_updatedAt', order: 'DESC' }],
      paging: {
        limit: 3,
        offset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  if (!data || loading || data?.getAllActivitiesPublic.count <= 0) return null

  return (
    <MyActivitiesStyled>
      <h2>
        {intl.formatMessage(
          {
            id: 'person.details.myactivities',
            defaultMessage: 'Education',
          },
          {
            activities: appLabel(
              'activitiesPlural',
              'person.details.myactivities.default'
            ),
          }
        )}
      </h2>
      <Grid config={gridConfig} testid="activities">
        {data?.getAllActivitiesPublic?.data?.map((activity, i) => {
          const count = data?.getAllActivitiesPublic?.data?.length
          const isLarge = i === 0 && (count > 2 || count === 1)

          return (
            <BaseTile
              url={`/people/list/${person?.id}/activities/${activity?.id}`}
              config={isLarge ? largeConfig : defaultConfig}
            >
              <FullBackgroundTile
                minHeight={'250'}
                isLargeTile={isLarge}
                activity={activity}
              />
            </BaseTile>
          )
        })}
      </Grid>
    </MyActivitiesStyled>
  )
}

export default MyActivities
