import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '@apollo/client'

import { Row, Col } from 'react-bootstrap'

import { renderField } from '../shared/field-factory'
import { GET_ATTRIBUTES } from '../graph-queries/attributes'

import { TitleStyle } from './styles/custom-attributes'
import { Section, Field } from './styles/index'
import { useIntl } from 'react-intl'

const mapStateToProps = ({ user }) => ({ user })

const CustomAttributes = ({ user, dispatch }) => {
  const [errors, setErrors] = useState({})
  const intl = useIntl()

  const { data } = useQuery(GET_ATTRIBUTES, {
    variables: { usedBy: ['people'] },
    fetchPolicy: 'cache-and-network',
  })

  const isAttributeObject = (attribute) => {
    return (
      attribute.type === 'location' ||
      attribute.type === 'badge' ||
      attribute.type === 'file'
    )
  }

  const saveValue = (attribute, value) => {
    const c = user.profile.customAttributes.filter(
      (ca) => ca.key !== attribute.key
    )
    const isObject = isAttributeObject(attribute)
    let stringObject = ''
    if (isObject) stringObject = JSON.stringify(value)
    else if (attribute.type === 'boolean') stringObject = value.toString()
    else stringObject = value
    c.push({ key: attribute.key, value: stringObject, type: attribute.type })
    const p = {
      ...user.profile,
      customAttributes: c,
    }
    saveProfile(p)
  }

  const saveProfile = (p) => {
    dispatch({
      type: 'user/SET_USER_PROFILE_VALUES',
      payload: { ...user, profile: p },
    })
  }

  const handleError = (attr, message) => {
    setErrors({ ...errors, [attr.key]: message })
  }

  const customAtributesText = intl.formatMessage({
    id: 'user.profile.customatributes',
  })

  if (!data || !data.getAttributes || !data.getAttributes.length) return null

  return (
    <div>
      <Section>
        <Row>
          <Col sm={12}>
            <TitleStyle>
              <span className="section-title field-title">
                {customAtributesText}
              </span>
            </TitleStyle>
            <div className="section-content">
              <Row>
                {data.getAttributes.map((attr) => {
                  const isObject = isAttributeObject(attr)
                  if (isObject && attr.value)
                    attr.value = JSON.parse(attr.value)
                  return (
                    <Col key={attr.name} sm={12} md={attr.md || 6}>
                      <Field>
                        <span className="field-title">{attr.name}</span>
                        {renderField({
                          attribute: attr,
                          user,
                          isCustomAttr: true,
                          onSave: (attr, v) => saveValue(attr, v),
                          onError: (attr, message) =>
                            handleError(attr, message),
                          error: errors[attr.key],
                        })}
                      </Field>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default connect(mapStateToProps)(CustomAttributes)
