import React from 'react'
import { usePluginsPublic } from '_application/plugins'

export const Hubspot = () => {
  const { plugin } = usePluginsPublic('hubspot')
  const isEnabled = plugin?.enabled

  if (isEnabled && plugin?.data?.accountId) {
    const script = `<script src="//js.hs-scripts.com/${plugin?.data?.accountId}.js" type="text/javascript" id="hs-script-loader" async defer ></script>`

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: script }} />
      </div>
    )
  } else {
    return <></>
  }
}

export default Hubspot
