import React, { useState } from 'react'
import {
  // desktopXLPX,
  pabletPX,
  tabletPX,
  mobilePX,
} from '_utils/responsive-queries'
// import { useGetEntityAccess } from '_security/entity-access'
// import { useUserAccess } from '_security/helpers'

import styled from '@emotion/styled'

export const StyledGrid = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 50px;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
`

const defaultTilesSetup = {
  mobile: [],
  pablet: [],
  tablet: [],
  desktop: [],
  // desktopXl: [],
}

const resolveScreenSize = (screenSize) => {

switch (true) {
  case screenSize <= mobilePX:
    return 'mobile'
  case screenSize >= pabletPX && screenSize < tabletPX:
    return 'tablet'
  case screenSize > tabletPX:
    return 'desktop'
  default:
    return 'mobile'
}
}

const resolveTileSize = ({defaultConfig, customConfig, tileIndex, screenSize}) => {

const tileGridConfig = customConfig ? { ...defaultConfig, ...customConfig } : defaultConfig
const tileSetupAccordingScreenSize = tileGridConfig[resolveScreenSize(screenSize)]

return !tileSetupAccordingScreenSize ||
  tileIndex > tileSetupAccordingScreenSize.length
  ? 1
  : tileGridConfig[resolveScreenSize(screenSize)][tileIndex]
}

const SpanBasedGrid = ({
  gridItems,
  SingleTile,
  url,
  isAdditionalAuthEnabled,
  isAdditionalAuth,
  queryParams,
  testid,
  gridConfig,
  // refetchData,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const setViewport = () => setWindowWidth(window.innerWidth)

  window.addEventListener('resize', setViewport)

  // const entityAccess = useGetEntityAccess('EVENTS', 'detailsRequiresAuth')

  // const eventDetailsRequiresAuth =
  //   entityAccess !== undefined ? entityAccess : true

  // const isAdditionalAuth = useUserAccess()

  return (
    <StyledGrid
      data-testid={`gridStyled-${testid ?? 'default'}`}
    >
      {gridItems.map((item, i) => {
        return (
          <>
            <SingleTile
              key={i}
              url={url}
              item={item}
              isAdditionalAuthEnabled={isAdditionalAuthEnabled}
              isAdditionalAuth={isAdditionalAuth}
              queryParams={queryParams}
              config={{
                style: {
                  gridColumn: `span ${resolveTileSize({
                    defaultConfig: defaultTilesSetup,
                    customConfig: gridConfig,
                    tileIndex: i,
                    screenSize: windowWidth,
                  })}`,
                },
              }}
              className={`grid-span-column-${resolveTileSize({
                defaultConfig: defaultTilesSetup,
                customConfig: gridConfig,
                tileIndex: i,
                screenSize: windowWidth,
              })}`}
            />
          </>
        )
      })}
    </StyledGrid>
  )
}

export default SpanBasedGrid