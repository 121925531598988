import { isFunction } from 'lodash'
import { useAppConfig } from '_application'

export const PlanFeature = ({ feature, children }) => {
  const { features } = useAppConfig()

  if (features?.includes(feature)) {
    return isFunction(children) ? children() : children
  } else {
    return null
  }
}
