import styled from '@emotion/styled'

const LocationSelectorStyled = styled.div`
  input {
    &::placeholder {
      color: inherit !important;
    }
  }
  &.location-selector {
    position: relative;
    .location-search-input {
      .geosuggest__input {
        opacity: 0.8;
        border: solid 1px var(--foreground);
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 20px;
        border-radius: 6px;
        font-size: 14px;
        background-color: transparent;
        font-family: ${(props) => props.theme.fonts.googleFont} !important;
        color: var(--foreground) !important;
        &::placeholder {
          font-weight: 300;
        }
      }
      .geosuggest__suggests-wrapper {
        position: absolute !important;
        width: 100%;
        z-index: 10000 !important;
        background-color: var(--background);
        box-shadow: 0px 0px 10px #e5e5e5;
        padding: 15px;
        border-radius: 6px;
        ul {
          padding-left: 0px;
          list-style-type: none;
          li {
            list-style-type: none;
            font-size: 14px;
            color: var(--foreground);
            font-family: ${(props) => props.theme.fonts.googleFont} !important;
            &:hover {
              cursor: pointer;
              color: var(--foreground);
            }
          }
        }
      }
      &.hidden {
        .geosuggest__suggests-wrapper {
          display: none;
        }
      }
    }
  }
`

export default LocationSelectorStyled
