import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PLUGINS, UPDATE_PLUGIN } from './graph-queries'

import { Row, Col } from 'react-bootstrap'
import { PluginsStyled } from './styles/plugins'
import { useIntl } from 'react-intl'
import Toggle from 'react-toggle'
import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import 'react-toggle/style.css'

import pluginList from './plugin-list'

const ManagePlugins = ({ setCurrentPlugin }) => {
  const intl = useIntl()

  const { data: pluginsData } = useQuery(GET_PLUGINS, {
    fetchPolicy: 'cache-and-network',
  })

  const [updatePlugin] = useMutation(UPDATE_PLUGIN)

  const handleTogglePlugin = ({ toggled, pluginData }) => {
    const p = { ...pluginData }
    p.enabled = toggled
    updatePlugin({ variables: p, refetchQueries: [{ query: GET_PLUGINS }] })
  }

  const defaultPluginData = {
    key: 'default',
    data: {},
    enabled: false,
  }
  const getStatus = (plugin) => {
    const status = plugin?.data && Object.keys(plugin?.data)?.length > 0

    if (status)
      return intl.formatMessage({ id: 'admin.manageplugins.integrated' })
    return intl.formatMessage({ id: 'admin.manageplugins.notintegrated' })
  }

  if (!pluginsData?.getPlugins) return null

  return (
    <PluginsStyled>
      <Row>
        {pluginList.map((p) => {
          const pluginData = pluginsData?.getPlugins?.filter(
            (pd) => pd.key === p.key
          )[0] || { ...defaultPluginData, key: p.key }
          return (
            <Col md={4} sm={12}>
              <div className="plugin">
                <div className="plugin-info">
                  {p.icon && <i className={p.icon} />}
                  <span>{p.name}</span>
                  <span className="status">
                    {intl.formatMessage({ id: 'admin.manageplugins.config' })}:{' '}
                    {getStatus(pluginData)}
                  </span>
                </div>
                <div className="plugin-toggle">
                  <Toggle
                    checked={pluginData?.enabled}
                    onChange={(e) =>
                      handleTogglePlugin({
                        pluginData,
                        toggled: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="plugin-configuration">
                  <Button
                    onClick={() =>
                      setCurrentPlugin(
                        pluginData || { ...defaultPluginData, key: p.key }
                      )
                    }
                    type="admin"
                  >
                    {intl.formatMessage({ id: 'admin.manageplugins.config' })}
                  </Button>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </PluginsStyled>
  )
}

export default ManagePlugins
