import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ApplicantsListSelector from './list-selector'
import NavBarDropdown from './nav-bar-dropdown'
import { Checkbox, Button } from '@patternfly/react-core'
import StyledDiv from '../styles/nav-bar'
import SendMessageModal from '../shared/sendMessageModal'
import ApplicationFilters from './filter-select'
import ApplicationSortSelect from './sort-select'
import ApplicationListSelect from './list-select'
import { useIntl } from 'react-intl'
import { values, find } from 'lodash'
import applicationLists from '../shared/applicationLists'

const intlKey = 'application.management.'

function NavBar({
  selectedItems,
  toggleAll,
  selectedListState,
  stateFilterState,
  sortState,
  lists,
}) {
  const isMobileView = useMediaQuery({
    query: `(max-width: 992px)`,
  })

  const listItems = values(applicationLists).map((list) => ({
    ...list,
    count: find(lists, { name: list.value })?.applicantCount || 0,
  }))

  listItems.unshift({
    value: 'all',
    text: 'all',
    count: lists && lists.reduce((acc, curr) => acc + curr.applicantCount, 0),
  })

  return (
    <StyledDiv>
      {/* {selectedItems.length < 1 ? ( */}
      {/*   <div id="listSelectors"> */}
      {/*     <ApplicantsListSelector */}
      {/*       selectedListState={selectedListState} */}
      {/*       items={allItems} */}
      {/*       lists={listItems} */}
      {/*     /> */}
      {/*   </div> */}
      {/* ) : ( */}
      {/*   MultiSelectActions(selectedItems, allItems, toggleAll) */}
      {/* )} */}

      {!isMobileView ? (
        <div id="listSelectors">
          <ApplicantsListSelector
            selectedListState={selectedListState}
            lists={listItems}
          />
        </div>
      ) : (
        <ApplicationListSelect
          listState={selectedListState}
          lists={listItems}
        />
      )}
      <div className="grow"></div>
      <ApplicationSortSelect sortState={sortState} />
      <ApplicationFilters stateFilterState={stateFilterState} />
    </StyledDiv>
  )
}

// eslint-disable-next-line no-unused-vars
function MultiSelectActions(selectedApplicants, allItems, toggleAll) {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  return (
    <>
      <div id="selectedItems">
        {/*
			  isChecked: null indicates 'partial' check.
		  */}
        <Checkbox
          onClick={toggleAll}
          id="rootCheckbox"
          isChecked={
            selectedApplicants.length === 0
              ? false
              : selectedApplicants.length === allItems.length
              ? true
              : null
          }
        />
        <span>{selectedApplicants.length}</span>
      </div>
      <Button onClick={() => setShow(true)}>
        {intl.formatMessage({ id: `${intlKey}reply` })}
      </Button>
      <NavBarDropdown />
      <SendMessageModal
        applicants={selectedApplicants}
        show={show}
        setShow={setShow}
      />
    </>
  )
}

export default NavBar
