import React from 'react'
import { ButtonStyled } from './styles/export-button'
import { useIntl } from 'react-intl'

const ExportButton = ({ onClick }) => {
  const intl = useIntl()

  return <ButtonStyled onClick={onClick}>
    {intl.formatMessage({
      id: `usersexport.button`, 
    })}
  </ButtonStyled>
}

export default ExportButton
