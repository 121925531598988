import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Loading from '_components/loading'
import Error from '_components/error'
import Map from '_map-with-result-grid'
import { useHistoryPreservingSearch } from '_utils/router'
import { toMarkers } from '../people-models'
import { parseSort, ORDERINGS } from '_utils/sort'

const GET_USERS = gql`
  query GetAllUsersMap($filter: UserFilterInput, $ordering: [OrderingInput]) {
    getAllUsersPublic(filter: $filter, ordering: $ordering) {
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

const PeopleMapConnected = ({ filter }) => {
  const history = useHistoryPreservingSearch()

  let ordering = undefined
  if (filter) {
    const sort = ORDERINGS.find((sort) => sort === Object.keys(filter)[0])
    if (sort) {
      ordering = parseSort(sort)
      filter = undefined
    }
  }

  const { data, loading, error } = useQuery(GET_USERS, {
    variables: { filter, ordering },
    notifyOnNetworkStatusChange: true,
  })
  const people = data?.getAllUsersPublic?.data

  const markers = useMemo(() => toMarkers(people || []), [people])

  if (!data && loading) return <Loading />
  if (error) return <Error />

  return (
    <Map
      markers={markers}
      onSelect={(person) => history.push(`/people/map/${person.sub}`)}
      type="people"
    />
  )
}
PeopleMapConnected.propTypes = {
  filter: PropTypes.object,
}

export default PeopleMapConnected
