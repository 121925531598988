/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

const ThreeDSecureConfirm = () => {
  useEffect(() => {
    window.top.postMessage('3DS-authentication-complete')
  }, [])

  return <></>
}

export default ThreeDSecureConfirm
