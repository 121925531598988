const actions = {
  GET_CHANNELS: 'message/GET_CHANNELS',
  SET_CHANNELS: 'message/SET_CHANNELS',
  GET_CURRENT_CHANNEL_MESSAGES: 'message/GET_CURRENT_CHANNEL_MESSAGES',
  SET_CURRENT_CHANNEL_MESSAGES: 'message/SET_CURRENT_CHANNEL_MESSAGES',
  GET_CONNECTIONS: 'message/GET_CONNECTIONS',
  SET_CONNECTIONS: 'message/SET_CONNECTIONS',
  GET_CURRENT_CHANNEL: 'message/GET_CURRENT_CHANNEL',
  SET_CURRENT_CHANNEL: 'message/SET_CURRENT_CHANNEL',
  GET_APPEND_MESSAGE: 'message/GET_APPEND_MESSAGE',
  SET_APPEND_MESSAGE: 'message/SET_APPEND_MESSAGE',
  GET_APPEND_ARTICLE_CONNECTIONS: 'message/GET_APPEND_ARTICLE_CONNECTIONS',
  SET_APPEND_ARTICLE_CONNECTIONS: 'message/SET_APPEND_ARTICLE_CONNECTIONS',
}

export default actions
