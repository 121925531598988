import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GridItem } from '@patternfly/react-core'
import BaseTileThemable from '_uikit/base-tile'
import { useUserAccess } from '_security/helpers'
import LoginRequiredModal from '_security/login-required-modal'
import styled from '@emotion/styled'
const defaultConfig = {
  '2xl': 1,
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
}

const StyledGridItem = styled(GridItem)`
  .link-unavailable {
    height: 100%;
  }
`

const LinkComponent = ({
  children,
  url,
  onClick,
  isAdditionalAuthEnabled,
  isAdditionalAuth,
  refetchQueries,
  queryParams,
  state,
  testid,
}) => {
  let hasUserAccess = useUserAccess()

  if (isAdditionalAuthEnabled === true && isAdditionalAuth === false) {
    return <NoAccess>{children}</NoAccess>
  }

  if (isAdditionalAuthEnabled === undefined && !hasUserAccess) {
    return <NoAccess>{children}</NoAccess>
  }

  if (url) {
    return (
      <Link
        to={() => {
          if (!queryParams && !refetchQueries) {
            if (!state) return url
            const result = { pathname: url }
            if (state) {
              result.state = state
            }
            return result
          }
          return {
            pathname: url,
            search: queryParams,
            state: refetchQueries,
          }
        }}
        data-testid={`tile-${testid ?? 'default'}`}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <div className="link-unavailable" onClick={onClick}>
        {children}
      </div>
    )
  }
}

const NoAccess = ({ children, ...rest }) => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <>
      <span
        style={{ cursor: 'pointer' }}
        className="not-logged"
        {...rest}
        onClick={(e) => {
          e.preventDefault()
          setModalVisible(true)
          return false
        }}
      >
        {children}
      </span>
      {modalVisible && (
        <LoginRequiredModal
          onClose={() => {
            setModalVisible(false)
          }}
        />
      )}
    </>
  )
}

const BaseTile = ({
  children,
  config,
  url,
  queryParams,
  refetchQueries,
  onClick,
  isAdditionalAuthEnabled,
  isAdditionalAuth,
  state,
  testid,
}) => {
  const tileConfig = { ...defaultConfig, ...config } || defaultConfig

  return (
    <StyledGridItem {...tileConfig}>
      <LinkComponent
        isAdditionalAuthEnabled={isAdditionalAuthEnabled}
        isAdditionalAuth={isAdditionalAuth}
        onClick={onClick}
        url={url}
        queryParams={queryParams}
        state={state}
        refetchQueries={refetchQueries}
        testid={testid}
      >
        <BaseTileThemable>{children}</BaseTileThemable>
      </LinkComponent>
    </StyledGridItem>
  )
}

export default BaseTile
