import styled from '@emotion/styled'

const ManageTaxonomyStyled = styled.div`
  padding: 10px 15px;
  border: none !important;
  background-color: var(--background);
  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--foreground);
  }
  .modal-description {
    color: var(--foreground);
    font-size: 16px;
  }
  .modal-content {
    background-color: transparent;
    margin-top: 20px;
    border: none;
    .rst__row {
      border-radius: 6px;
    }
  }
  .modal-footer {
    border-top: none !important;
  }
`

export default ManageTaxonomyStyled
