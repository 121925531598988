import colors from './colors'
import images from './images'
import fonts from './fonts'
import components from './components'
import media from './media'
import icons from './icons'
import styles from './styles'
import settings from './settings'

export default {
  fonts,
  colors,
  images,
  components,
  media,
  icons,
  styles,
  settings,
}
