import React, { useState } from 'react'
import moment from 'moment'

import { useIntl } from 'react-intl'

import { Modal } from 'react-bootstrap'
import { SingleStyled } from '../styles/single'
import File from './file'
import { TagListWithIcon } from '_components/drilldown'
import { TextSection, ImageStyled, CloseButton, Image } from '../styles/single'
import { css } from '@emotion/core'

const styles = {
  modal: css`
    .modal-85w {
      max-width: auto;
      top: 100px;
    }
    .modal-content {
      overflow: hidden;
      border-radius: 20px;
      border: 1px solid black;
    }
  `,
  body: css`
    padding: 0;
  `,
  headerTag: css`
    position: absolute;
    left: 15px;
    top: 15px;
  `,
}

const ImageWrapper = ({ attr, intl }) => {
  const [open, setOpen] = useState()

  return (
    <ImageStyled>
      <span onClick={() => setOpen(true)} className="img-link">
        {intl.formatMessage({ id: 'global.button.viewimage' })}
      </span>
      {open && (
        <Modal
          dialogClassName="modal-85w"
          size="lg"
          css={styles.modal}
          show={true}
          onHide={() => setOpen(false)}
        >
          <Modal.Body>
            <Image alt={attr.name} src={attr.value} />
          </Modal.Body>
          <Modal.Footer>
            <CloseButton
              className="type-neutral"
              onClick={() => setOpen(false)}
            >
              {intl.formatMessage({ id: 'global.button.close' })}
            </CloseButton>
          </Modal.Footer>
        </Modal>
      )}
    </ImageStyled>
  )
}

const renderCustomAttribute = (attr, intl) => {
  let Component = null
  const { type, value, badges, name } = attr
  if (
    type === 'text' ||
    type === 'email' ||
    type === 'location' ||
    type === 'phone'
  ) {
    Component = () => (
      <TextSection>
        {type === 'location'
          ? value?.label
            ? value?.label
            : attr?.url
          : value}
      </TextSection>
    )
  }
  if (type === 'boolean') {
    Component = () => <TextSection></TextSection>
  }
  if (type === 'url') {
    Component = () => (
      <TextSection>
        <a href={value} rel="noopener noreferrer" target="_blank">
          {name}
        </a>
      </TextSection>
    )
  }
  if (type === 'image') {
    Component = () => <ImageWrapper attr={attr} intl={intl} />
  }
  if (type === 'file') {
    Component = () => <File attr={attr} />
  }
  if (type === 'date') {
    Component = () => (
      <TextSection>{moment(value).format('DD MMM YYYY')}</TextSection>
    )
  }
  if (type === 'badge' && value) {
    Component = () => (
      <TagListWithIcon
        title={''}
        values={value.map((key) => {
          const badge = badges?.filter((b) => b.key === key)[0]
          return { icon: badge?.icon, name: badge?.name }
        })}
      />
    )
  }
  if (!Component) return null
  return <Component />
}

const Single = ({ attribute }) => {
  const intl = useIntl()
  if (attribute.type === 'boolean' && attribute.value !== 'true') {
    return null
  }

  return (
    <SingleStyled
      data-tip={
        attribute.type === 'url' ? attribute.value : attribute.description
      }
      data-for="info-tooltip"
      isBoolean={attribute.type === 'boolean'}
      type={attribute.type}
    >
      {attribute.name && attribute.type !== 'url' && (
        <>
          {attribute.type === 'boolean' && (
            // <img
            //   className="thumb-up"
            //   data-type={attribute.type}
            //   src={thumbUp}
            //   alt={thumbUp}
            // />
            <i class="far fa-thumbs-up" />
          )}
          <span className="name">{attribute.name}</span>
        </>
      )}
      {renderCustomAttribute(attribute, intl)}
    </SingleStyled>
  )
}

export default Single

