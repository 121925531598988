import React, { useState } from 'react'
import { useStripeCustomer } from '_payment'
import { Row, Col } from 'react-bootstrap'
import {
  TransactionListStyled,
  PaymentContent,
  PaymentHeader,
} from './styles/transaction-list'
import moment from 'moment'

import { useIntl } from 'react-intl'
import GridLoader from 'react-spinners/GridLoader'
import { useTheme } from 'emotion-theming'

import {
  couponToAmountText,
  couponToDurationText,
} from '../shared/payment-helpers'

const Item = ({ payment }) => {
  const [isOpen, setIsOpen] = useState()
  const intl = useIntl()

  const name = payment?.lines?.data?.[0]?.description
  const period = {
    start: moment(payment?.lines?.data?.[0]?.period?.start * 1000).format(
      'DD.MM.YY'
    ),
    end: moment(payment?.lines?.data?.[0]?.period?.end * 1000).format(
      'DD.MM.YY'
    ),
  }
  const date = moment(payment?.created * 1000)
  const { status, total, currency, subtotal } = payment
  const coupon = payment?.discount?.coupon

  return (
    <div className="payment">
      <PaymentHeader onClick={() => setIsOpen(!isOpen)}>
        <div className="info">
          <Row>
            <Col md={6}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.name',
                  })}
                </span>
                <span className="value">{name}</span>
              </div>
            </Col>
            <Col md={2}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.cost',
                  })}
                </span>
                <span className="value upper-case">{`${(total / 100).toFixed(
                  2
                )} ${currency}`}</span>
              </div>
            </Col>
            <Col md={1}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.status',
                  })}
                </span>
                <span className="value capital-case">{status}</span>
              </div>
            </Col>
            <Col md={3}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.date',
                  })}
                </span>
                <span className="value date">{`${date.format(
                  'DD.MM.YYYY HH:mm'
                )}`}</span>
              </div>
            </Col>
          </Row>
          <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`} />
        </div>
      </PaymentHeader>
      {isOpen && (
        <PaymentContent>
          <Row>
            <Col md={6}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.period',
                  })}
                </span>
                <span className="value">{`${period.start} - ${period.end}`}</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="section">
                <span className="key">
                  {intl.formatMessage({
                    id: 'user.profile.paymenthistory.price',
                  })}
                </span>
                <span className="value upper-case">{`${(subtotal / 100).toFixed(
                  2
                )} ${currency}`}</span>
              </div>
            </Col>
          </Row>
          {coupon && (
            <Row>
              <Col md={6}>
                <div className="section">
                  <span className="key">
                    {intl.formatMessage({
                      id: 'user.profile.paymenthistory.couponname',
                    })}
                  </span>
                  <span className="value">{coupon?.name}</span>
                </div>
              </Col>
              <Col md={6}>
                <div className="section">
                  <span className="key">
                    {intl.formatMessage({
                      id: 'user.profile.paymenthistory.couponamount',
                    })}
                  </span>
                  <span className="value upper-case">
                    {couponToAmountText({ coupon })}
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <div className="section">
                  <span className="key">
                    {intl.formatMessage({
                      id: 'user.profile.paymenthistory.couponduration',
                    })}
                  </span>
                  <span className="value capital-case">
                    {couponToDurationText({ coupon, intl })}
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </PaymentContent>
      )}
    </div>
  )
}

const TransactionList = () => {
  const { customer: { paymentHistory } = {} } = useStripeCustomer()
  // const { paymentHistory } = customer || {}
  const theme = useTheme()

  if (!paymentHistory) {
    return <GridLoader size={15} color={theme.colors.buttonColor} loading />
  }

  return (
    <TransactionListStyled>
      {paymentHistory?.map((payment) => {
        return <Item payment={payment} />
      })}
    </TransactionListStyled>
  )
}

export default TransactionList
