import React from 'react'
import { connect } from 'react-redux'
import CreditCards from './credit-cards'
import { useIntl } from 'react-intl'

import { Section, MainWrapper } from '../styles'
const mapStateToProps = ({ user }) => ({ user })

const PaymentDetails = ({ user, dispatch }) => {
  return (
    <MainWrapper>
      <Section>
        <span className="section-title">
          {useIntl().formatMessage({ id: 'user.profile.paymentdetails.cards' })}
        </span>
        <div className="content">
          <CreditCards />
        </div>
      </Section>
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(PaymentDetails)
