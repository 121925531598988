import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

// import Dashboard from './dashboard'
import Categories from './categories'
import AdminsMods from './admins-mods'
import Tags from './tags'
import Labels from './labels'

const defaultManageDataRoute = '/site-structure'
const defaultRoute = `/manage${defaultManageDataRoute}/forum`

const Routes = () => {
  return (
    <Switch>
      {/* <Route path={`${defaultRoute}/dashboard`} render={() => <Dashboard />} /> */}
      <Route
        path={`${defaultRoute}/categories`}
        render={() => <Categories />}
      />
      <Route
        path={`${defaultRoute}/Admins-mods`}
        render={() => <AdminsMods />}
      />
      <Route path={`${defaultRoute}/tags`} render={() => <Tags />} />
      <Route path={`${defaultRoute}/labels`} render={() => <Labels />} />

      <Redirect from={defaultRoute} to={`${defaultRoute}/categories`} />
      {/* redirect to categories until dashboard is ready */}
      {/* <Redirect from={defaultRoute} to={`${defaultRoute}/dashboard`} /> */}
    </Switch>
  )
}

export default Routes
