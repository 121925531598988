import React from 'react'
import { LoaderStyled } from '../styles/loader'
import GridLoader from 'react-spinners/GridLoader'

const Loader = () => {
  return (
    <LoaderStyled>
      <div className="loader">
        <GridLoader size={10} />
      </div>
    </LoaderStyled>
  )
}

export default Loader
