import React from 'react'

import Navigation from './navigation'
import Routes from './routes'
import { useColorBucket } from '_colorBus/hooks'

import MainLayout from '_layouts/main'
import { Content } from './styles/index'

const Profile = () => {
  const [colorBucket] = useColorBucket('admin')
  return (
    <MainLayout>
      <Content className="profile-container" ref={colorBucket}>
        <div class="route-navigation">
          <Navigation />
        </div>
        <div class="route-content">
          <Routes />
        </div>
      </Content>
    </MainLayout>
  )
}

export default Profile
