import React, { useState } from 'react'
import ButtonWithDropdown from './button-with-dropdown'
import moment from 'moment'
import LoginRequiredModal from '_security/login-required-modal'
import { renderLocation } from './render-location'
import {
  EventInfoWrapper,
  TopInfoWrapper,
  BottomInfoWrapper,
  EventInfo,
  Title,
  ActionsWrapper,
  TopButtons,
  AdditionalInfo,
  Date,
  Time,
  // Price,
  EventCreator,
  EventStats,
  StatusOfInterestButton,
  AnchorTag,
} from './styles/event-info'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import { roundTime } from '../helpers/roundTime'

const EventInformationPublic = ({ event }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const [modalVisible, setModalVisible] = useState(false)

  const {
    venueLocation,
    start,
    end,
    title,
    going,
    interested,
    doorsOpen,
    isGoing,
    isInterested,
  } = event || {}

  return (
    <>
      {modalVisible && (
        <LoginRequiredModal
          onClose={() => {
            setModalVisible(false)
          }}
        />
      )}
      <EventInfoWrapper>
        <TopInfoWrapper className="top">
          <EventInfo className="left">
            <Title>{title}</Title>
            <AdditionalInfo className="additional-info">
              <Date>
                <i className="far fa-calendar-alt" />
                {moment(start).format('DD.MM.YYYY')} -{' '}
                {moment(end).format('DD.MM.YYYY')}
              </Date>
              <Time>
                <i className="far fa-clock" />
                {roundTime(doorsOpen)}
              </Time>
              <>{renderLocation(venueLocation, intl, appLabel)}</>
            </AdditionalInfo>
          </EventInfo>
          <ActionsWrapper className="right">
            <TopButtons className="top-buttons">
              <ButtonWithDropdown
                isAnimated={false}
                icon={<i className="fas fa-share-square" />}
                itemsToDropdown={[
                  <AnchorTag
                    href={`mailto:?subject=${encodeURIComponent(title)}&body=${intl.formatMessage({
                      id: 'profile.details.sharewithemail.emailbody',
                    })} ${title}`}
                  >
                    <i className="fas fa-envelope" />
                    {intl.formatMessage({
                      id: 'events.detailview.3dotsdropdown.button.sharewithemail',
                    })}
                  </AnchorTag>,
                ]}
              />
            </TopButtons>
          </ActionsWrapper>
        </TopInfoWrapper>

        <BottomInfoWrapper className="bottom">
          {/* TODO: Add a public option for creator info */}
          <EventCreator className="creator-info">
            {/* <Avatar src={null} alt="event creator image" />
            <CreatorInfo>
              <CreatorName className="creator-name">
                Creator Name Here
              </CreatorName>
            </CreatorInfo> */}
          </EventCreator>

          <EventStats className="event-stats">
            <StatusOfInterestButton
              className={`going-number ${isGoing ? 'active' : ''}`}
              onClick={() => {
                setModalVisible(true)
              }}
            >
              <i className="fas fa-check-circle" />
              {intl.formatMessage({
                id: 'events.detailview.label.going',
              })}{' '}
              {going}
            </StatusOfInterestButton>

            <StatusOfInterestButton
              className={`interested-number ${isInterested ? 'active' : ''}`}
              onClick={() => {
                setModalVisible(true)
              }}
            >
              <i className="fas fa-star" />
              {intl.formatMessage({
                id: 'events.detailview.label.interested',
              })}{' '}
              {interested}
            </StatusOfInterestButton>
          </EventStats>
        </BottomInfoWrapper>
      </EventInfoWrapper>
    </>
  )
}

export default EventInformationPublic
