import Route from './companies-routes'
import Tile from './components/company-tile'
import Details from './connected/company-details-connected'

export { Route, Tile, Details }
export default {
  Route,
  Tile,
  Details,
}
