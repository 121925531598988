import React, { useState } from 'react'
import { useClickOutside } from '../helpers/click-outside'
import MultiSelectStyled from './multi-select-with-icon-styled'
import { useIntl } from 'react-intl'

const MultiSelectWithIcon = ({
  onSelect,
  items,
  selectedValues,
  noDeselect,
}) => {
  const intl = useIntl()
  const [dropdown, setDropdown] = useState()

  const ref = useClickOutside(() => setDropdown(false))

  const handleOnSelect = (item) => {
    if (selectedValues?.some((v) => v === item.key) && !noDeselect)
      onSelect(selectedValues?.filter((v) => v !== item.key))
    else onSelect([...(selectedValues || []), item.key])
  }

  return (
    <MultiSelectStyled className={`ui-kit ui-kit-badge-select`}>
      <div
        className={`${
          selectedValues?.length > 0 ? 'selected-items' : 'selected-items empty'
        }`}
        data-testid="keywords-items-list"
      >
        {selectedValues?.map((v) => {
          const item = items.filter((item) => item.key === v)[0]
          if (item)
            return (
              <li data-testid={`keywords-item-${item.name}-selected`}>
                {item.icon && <img alt={item.name} src={item.icon} />}
                <span>{item.name}</span>
              </li>
            )
          return <></>
        })}
      </div>
      <div className="full-selector" ref={ref}>
        <button
          onClick={() => setDropdown(!dropdown)}
          data-testid="btn-dropdown"
        >
          {`${intl.formatMessage({ id: 'input.multiselect.label' })}`}{' '}
          <i className={`fas fa-chevron-${dropdown ? 'up' : 'down'}`} />
        </button>
        <ul className={`select-dropdown ${dropdown ? 'active' : ''}`}>
          {items?.map((item) => {
            return (
              <li
                className={
                  selectedValues?.some((v) => v === item.key) ? 'active' : ''
                }
                onClick={() => handleOnSelect(item)}
                key={item.key || item.name}
                data-testid={`keywords-dropdown-item-${item.name}${
                  selectedValues?.some((v) => v === item.key) ? '-active' : ''
                }`}
              >
                {item.icon && <img alt={item.name} src={item.icon} />}
                <span>{item.name}</span>
                <i
                  class={`far fa${
                    selectedValues?.some((v) => v === item.key) ? '-check' : ''
                  }-square`}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </MultiSelectStyled>
  )
}

export default MultiSelectWithIcon
