import Route from './activities-routes'
import Tile from './components/activity-tile'
import Details from './connected/activity-details-connected'

export { Route, Details, Tile }
export default {
  Route,
  Details,
  Tile,
}
