import styled from '@emotion/styled'
import { pablet } from '_utils/responsive-queries'

export const HomeViewWrapper = styled.div`
  display: flex;
  max-width: 1570px;
  margin: 0 auto;
  ${pablet} {
    flex-direction: column;
  }
`
export const ContentWrapper = styled.div`
  width: 60%;
  ${pablet} {
    width: 100%;
  }
`

export const SidebarWrapper = styled.div`
  padding-left: 50px;
  width: 40%;
  ${pablet} {
    width: 100%;
    padding-left: 0;
  }
`

export const CategoriesSection = styled.div``

export const ContributorsSection = styled.div``
