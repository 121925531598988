import styled from '@emotion/styled'
import border from '_utils/css-border'

export const Wrapper = styled.div`
  position: relative;
`

export const ExpandButton = styled.button`
  border: none;
  background-color: transparent;

  i {
    font-size: 20px;
  }
`

export const ExpandDropdown = styled.div`
  ${border(1, 1, 0)}
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  border-radius: 5px;
  top: 25px;
  min-width: 200px;
  right: 5px;
  padding: 10px 5px;
  border-top-right-radius: 0;

  .change-card {
    display: flex;
    align-content: center;
    border: none;
    background-color: transparent;
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  .circle {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #d0fdd7;
    width: 14px;
    height: 14px;
    margin: auto 2px;
    i {
      font-size: 10px;
    }
  }
`

export const AddNewCard = styled.button`
  border: 2px dashed var(--shade);
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
  min-height: 140px;
  i {
    font-size: 10px;
    margin-right: 3px;
  }
  span {
    font-weight: 700;
    font-size: 16px;
  }
`

export const InfoWrapper = styled.div`
  margin-top: 10px;
  span {
    display: block;
    font-size: 16px;
    text-transform: capitalize;
  }
  .last4 {
    font-weight: 700;
  }
`

export const DefaultCard = styled.span`
  align-self: flex-end;
  font-size: 12px;
  font-weight: 700;
  border-radius: 10px;
  padding: 4px 10px;
  height: 26px;
  background-color: ${({ isDefault }) => isDefault && 'var(--flavor)'};
  color: ${({ isDefault }) => isDefault && 'var(--flavor-contrast)'};
`

export const SingleCard = styled.div`
  display: flex;
  flex-direction: column;
  .top-wrapper {
    display: flex;
    justify-content: space-between;
  }
`

export const CreditCardsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  grid-template-rows: repeat(auto, 1fr);
  row-gap: 25px;
  column-gap: 25px;
  margin-top: 25px;
  i {
    font-size: 30px;
  }

  .payment-method {
    ${border()}
    border-radius: 10px;
    padding: 13px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
