import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import withExpand from './hoc/withExpand'

const Container = styled.div`
  min-width: 40%;
  height: ${({ expand }) => !expand && '37px'};
  width: ${({ expand }) => (expand ? 'auto' : '40%')};
  overflow: hidden;
  position: relative;
  display: inline-flex;
  text-align: center;
  border-radius: 22px;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 12px;
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--background);
  color: var(--foreground);
  font-family: ${({ theme }) => theme.fonts.googleFont};
  margin: 20px 20px 0 0;

  .icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
`

const Title = styled.span`
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ expand }) => !expand && 'nowrap'};
  line-height: 17px;
`

const Tag = ({ children, className, expand, toggleExpand }) => (
  <Container className={className} expand={expand} onClick={toggleExpand}>
    <Title expand={expand}>
      {/* {children?.length > 22 ? `${children.substring(0, 22)}...` : children} */}
      {children}
    </Title>
  </Container>
)
Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default withExpand(Tag)
