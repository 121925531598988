import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(240, 240, 240);
  box-shadow: 0px 0px 10px rgb(243, 243, 243);
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: 0.5s;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
  }
`

const Cover = styled.img`
  display: block;
  height: 100%;
  max-height: 50px;
  min-height: 50px;
  object-fit: cover;
  width: 100%;
`

const Avatar = styled.img`
  border-radius: 50%;
  border: 4px solid white;
  display: block;
  height: 50px;
  margin-top: -20px;
  margin: -20px auto 0 auto;
  object-fit: cover;
  position: relative;
  width: 50px;
  z-index: 100;
`

const Content = styled.div`
  padding: 0 15px 20px 15px;
`

const Title = styled.h5`
  color: ${({ theme }) => theme.colors.color1_1};
  display: block;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.inactive};
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0.5em;
  text-align: center;
`

const Props = {
  title: PropTypes.string.isRequired,
  cover: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
}

const TileSmall = ({ cover, avatar, title, subtitle }) => {
  const theme = useTheme()
  return (
    <Container>
      <Cover src={cover || theme.images.defaultCover} />
      {avatar && <Avatar src={avatar} />}
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Content>
    </Container>
  )
}
TileSmall.propTypes = Props

export default TileSmall
