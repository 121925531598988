/* eslint-disable import/first */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AdminDashboard from './dashboard'
import AdminManageData from './manage-data'
import AdminManageUsers from './manage-users'
import AdminManageReports from './manage-reports'
import AdminManagePlugins from './manage-plugins'
import AdminSettings from './settings'
import AdminBranding from './branding'
import AdminSiteStructure from './site-structure'

export const defaultRoute = '/manage'

const Routes = () => {
  return (
    <Switch>
      <Route
        path={`${defaultRoute}/dashboard`}
        render={() => <AdminDashboard />}
      />
      <Route path={`${defaultRoute}/data`} render={() => <AdminManageData />} />
      <Route
        path={`${defaultRoute}/users`}
        render={() => <AdminManageUsers />}
      />
      <Route
        path={`${defaultRoute}/reports`}
        render={() => <AdminManageReports />}
      />
      <Route
        path={`${defaultRoute}/plugins`}
        render={() => <AdminManagePlugins />}
      />
      <Route
        path={`${defaultRoute}/settings`}
        render={() => <AdminSettings />}
      />
      <Route
        path={`${defaultRoute}/branding`}
        render={() => <AdminBranding />}
      />
      <Route
        path={`${defaultRoute}/site-structure`}
        render={() => <AdminSiteStructure />}
      />
      <Redirect from={defaultRoute} to={`${defaultRoute}/dashboard`} />
    </Switch>
  )
}

export default Routes
