import React, { useState } from 'react'
import { connect } from 'react-redux'
import GridLoader from 'react-spinners/GridLoader'
import axios from 'axios'
import { useStripeCustomer } from '_payment'
import AddCreditCardModal from './add-credit-card-modal'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'

import { useIntl } from 'react-intl'
import {
  CreditCardsStyled,
  AddNewCard,
  DefaultCard,
  SingleCard,
  InfoWrapper,
  ExpandDropdown,
  ExpandButton,
  Wrapper,
} from './styles/credit-cards'
import { useTheme } from 'emotion-theming'

const mapStateToProps = ({ user }) => ({ user })

const PaymentDetails = ({ user, dispatch }) => {
  const intl = useIntl()
  const [isAddCardModal, setAddCardModal] = useState()
  const [isLoading, setIsLoading] = useState()
  const [openedItemId, setOpenedItemId] = useState(null)
  const theme = useTheme()
  const { customer, loading, refetch } = useStripeCustomer()
  const { paymentMethods } = customer || []

  const handleUpsertPaymentMethod = async (pm, isDefault) => {
    const request = {
      path: `${process.env.REACT_APP_API_ENDPOINT}/payment/upsert-payment-method`,
      payload: {
        paymentMethod: pm,
        isDefault,
      },
    }
    setIsLoading(true)

    await axios.post(request.path, request.payload, {
      headers: { authorization: localStorage.getItem('RIOT/token') },
    })
      .then(() => refetch({ awaitRefetchQueries: true }))
      .catch((err) => console.log('Failed to handleUpsertPaymentMethod', err))
      .finally(() => setIsLoading(false));

  }

  const handleAddCreditCard = (paymentMethod) => {
    handleUpsertPaymentMethod(paymentMethod, paymentMethods?.length <= 0)
    setAddCardModal(null)
  }

  const handleClose = () => openedItemId && setOpenedItemId(null)

  const ref = useClickOutside(handleClose)

  if (loading && !customer) {
    return <GridLoader size={15} color={theme.colors.buttonColor} loading />
  }

  return (
    <CreditCardsStyled data-testid="payments-cards">
      {isAddCardModal && (
        <AddCreditCardModal
          onSuccess={(paymentMethod) => handleAddCreditCard(paymentMethod)}
          onClose={() => setAddCardModal(false)}
        />
      )}
      <div>
        <AddNewCard
          onClick={() => setAddCardModal(true)}
          className="add-new"
          data-testid="payments-btn-addNew"
        >
          <span className="text-wrapper">
            <i className="fas fa-plus" />
            {intl.formatMessage({
              id: 'user.profile.paymentdetails.addcreditcard',
            })}
          </span>
        </AddNewCard>
      </div>
      {paymentMethods?.map((pm) => {
        const card = pm?.card
        const isDefault = pm?.id === customer?.paymentMethod?.id

        return (
          <SingleCard
            className={`payment-method ${isDefault ? 'default' : ''}`}
            data-testid={`payments-card-${card?.brand}${card?.last4}`}
          >
            <Wrapper className="top-wrapper">
              <i className={`fab fa-cc-${card?.brand}`} />
              <ExpandButton
                onClick={() => {
                  return setOpenedItemId(pm?.id)
                }}
                data-testid={`payments-btn-more-${card?.brand}${card?.last4}`}
              >
                <i class="fas fa-ellipsis-h" />
              </ExpandButton>
              {openedItemId === pm?.id && (
                <ExpandDropdown ref={ref}>
                  <button
                    className="change-card"
                    onClick={() => handleUpsertPaymentMethod(pm, true)}
                    data-testid={`payments-btn-makePrimary-${card?.brand}${card?.last4}`}
                  >
                    <span className="circle">
                      <i class="fas fa-check" />
                    </span>
                    <p>
                      {intl.formatMessage({
                        id: 'user.profile.paymentdetails.button.primarycard',
                      })}
                    </p>
                  </button>
                </ExpandDropdown>
              )}
            </Wrapper>
            <InfoWrapper className="info">
              <span className="last4">{`Ending in ${card?.last4}`}</span>
              <span className="type">{card?.brand}</span>
            </InfoWrapper>
            <DefaultCard
              isDefault={isDefault}
              data-testid={`payments-${isDefault ? 'primary-' : ''}${card?.brand
                }${card?.last4}`}
            >
              {isDefault && (
                <>
                  {intl.formatMessage({
                    id: 'user.profile.paymentdetails.defaultcard',
                  })}
                </>
              )}
            </DefaultCard>
          </SingleCard>
        )
      })}

      {(isLoading || loading) && (
        <div className="overlay">
          <div className="loader">
            <GridLoader size={15} color={theme.colors.buttonColor} loading />
          </div>
        </div>
      )}
    </CreditCardsStyled>
  )
}

export default connect(mapStateToProps)(PaymentDetails)
