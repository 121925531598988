import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Tabs } from '../styles/content-tabs'
import { useAppLabel } from '_application'
import { useTheme } from 'emotion-theming'
import { mobilePX } from '_utils/responsive-queries'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

const typeToLabel = (type) => {
  switch (type) {
    case 'people':
      return 'peoplePlural'
    case 'company':
      return 'companiesPlural'
    case 'activity':
      return 'activitiesPlural'
    case 'opportunity':
      return 'opportunitiesPlural'
    case 'speakers':
      return 'speakersPlural'
    default:
      return type
  }
}

const ContentTabs = ({ tabs, onTabSelect, useFollowersLabel }) => {
  const theme = useTheme()
  const appLabel = useAppLabel()
  const { tab } = useParams()
  const intl = useIntl()

  const [currentTab, setCurrentTab] = useState(
    (tab === 'mycreations' ? tab : Object.keys(tabs)[0]) ||
      Object.keys(tabs)[0] ||
      ''
  )

  const typeToIcon = (type) => {
    switch (type) {
      case 'people':
        return <theme.icons.peopleIcon />
      case 'company':
        return <theme.icons.companiesIcon />
      case 'activity':
        return <theme.icons.activitiesIcon />
      case 'opportunity':
        return <theme.icons.opportunitiesIcon />
      case 'about':
        return <i className="fas fa-address-card" />
      case 'mycreations':
        return <i className="fas fa-folder" />
      case 'going':
        return <i className="fas fa-user-check" />
      case 'interested':
        return (
          <>
            <i className="fas fa-user" />
            <i class="far fa-star"></i>
          </>
        )
      default:
        return <i className="far fa-file" />
    }
  }

  useEffect(() => onTabSelect(currentTab), [currentTab, onTabSelect])

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })

  const hasConnections =
    Object.keys(tabs).filter((key) => tabs[key].count && tabs[key].count > 0)
      .length > 0

  return (
    <>
      {hasConnections && (
        <Tabs isMobileView={isMobileView}>
          {Object.keys(tabs).map((k, i) => {
            const tab = tabs[k]
            if (!isNaN(tab?.count) && tab?.count <= 0) return null
            const tabTitle =
              useFollowersLabel && k === 'people'
                ? intl.formatMessage({
                    id: 'connections.followers',
                    defaultMessage: 'Followers',
                  })
                : appLabel(typeToLabel(k), tab?.translationKey)
            return (
              <div
                key={i}
                className={`${k === currentTab ? 'active' : ''} tab`}
                onClick={() => {
                  setCurrentTab(k)
                }}
              >
                {/* With tab counts */}
                {/* {isMobileView
                  ? typeToIcon(k)
                  : `${appLabel(typeToLabel(k), tab?.translationKey)}
                  ${!isNaN(tab?.count) ? ` ${tab?.count}`
                    : ''}
                    `} */}
                {/* TODO: Restore tab counts */}
                {/* Without tab counts */}
                {isMobileView ? typeToIcon(k) : tabTitle}

                {isMobileView && !isNaN(tab?.count) && (
                  <span className={`${k === currentTab ? 'active' : ''} count`}>
                    {tab?.count}
                  </span>
                )}
              </div>
            )
          })}
        </Tabs>
      )}
    </>
  )
}

export default ContentTabs
