/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import MarkerClusterer from '@googlemaps/markerclustererplus'

import blueIcon from './icons/marker-blue.png'
import './styles.scss'

const clustererImagePath =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
const mapId = uuid()

const Index = ({
  onMarkerClick,
  markers,
  centerPos,
  zoom,
  onBoundsChange,
  getVisibleMarkers,
}) => {
  let map = null

  useEffect(() => {
    return initMap()
  }, [markers])

  const [googleMarkers, _setGoogleMarkers] = useState()

  const markersRef = useRef(googleMarkers)

  const setGoogleMarkers = (markers) => {
    markersRef.current = markers
    _setGoogleMarkers(markers)
  }

  const initMap = () => {
    const centerPosition = new window.google.maps.LatLng(
      centerPos ? centerPos.lat : 59.9139,
      centerPos ? centerPos.lng : 10.7522
    )
    const options = {
      center: centerPosition,
      mapTypeId: window.google.maps.MapTypeId.terrain,
      zoom: zoom || 6,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    }
    map = new window.google.maps.Map(
      document.getElementById(`google-maps-${mapId}`),
      options
    )
    if (onBoundsChange) {
      let initialBoundsDone = false
      renderMarkers()
      map.addListener('bounds_changed', function () {
        if (initialBoundsDone && onBoundsChange) {
          onBoundsChange(this?.getBounds()?.toUrlValue())
        }
        const { current: googleMarkers } = markersRef || {}
        if (getVisibleMarkers && googleMarkers) {
          const bounds = this?.getBounds()
          const visibleMarkers = []

          ;(() =>
            googleMarkers?.forEach((m) => {
              if (bounds.contains(m.getPosition())) {
                visibleMarkers.push(m?.metadata?.dataId)
              }
            }))()
          getVisibleMarkers({ ids: visibleMarkers })
        }
      })
      initialBoundsDone = true
    }
    return () => {
      if (map) {
        window.google.maps.event.clearInstanceListeners(map)
      }
    }
  }

  const renderMarkers = () => {
    const gm = markers.map((m) => renderMarker(m)).filter((m) => m)
    ;(() =>
      new MarkerClusterer(map, gm, {
        imagePath: clustererImagePath,
      }))()
    const bounds = new window.google.maps.LatLngBounds()
    gm.map((m) => {
      if (m) {
        m.setMap(map)
        bounds.extend(m.getPosition())
      }
      return m
    })
    setGoogleMarkers(gm)
    map.fitBounds(bounds)
  }

  const renderMarker = (m) => {
    if (m.lat && m.lng) {
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(m.lat, m.lng),
        title: m.title,
        icon: {
          url: blueIcon,
          size: new window.google.maps.Size(32, 32),
          scaledSize: new window.google.maps.Size(32, 32),
          labelOrigin: new window.google.maps.Point(20, -8),
        },
        label: {
          color: '#4A96D9',
          fontSize: '13px',
          fontWeight: '500',
          text: m.title,
        },
        metadata: { id: m.title, dataId: m?.dataId },
      })
      if (onMarkerClick) marker.addListener('click', () => onMarkerClick(m))
      return marker
    }
    return null
  }

  return (
    <div className="google-maps-container">
      <div className="google-maps-content" id={`google-maps-${mapId}`} />
    </div>
  )
}

export default Index
