import actions from './actions'

const initialState = {
  channels: [],
  currentChannel: null,
  currentChannelMessages: {
    messages: [],
  },
  connections: {
    entities: [],
    people: [],
  },
  articleConnections: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHANNELS:
      return { ...state, channels: action.payload }
    case actions.SET_CURRENT_CHANNEL:
      return { ...state, currentChannel: action.payload }
    case actions.SET_CONNECTIONS:
      return { ...state, connections: action.payload }
    case actions.SET_CURRENT_CHANNEL_MESSAGES:
      return { ...state, currentChannelMessages: action.payload }
    case actions.SET_APPEND_MESSAGE:
      return {
        ...state,
        currentChannelMessages: {
          ...state.currentChannelMessages,
          messages: [...state.currentChannelMessages.messages, action.payload],
        },
      }
    case actions.SET_APPEND_ARTICLE_CONNECTIONS:
      return {
        ...state,
        articleConnections: {
          ...state.articleConnections,
          [action.articleId]: {
            people: action.payload.connections,
            activities: action.payload.activities,
            companies: action.payload.companies,
          },
        },
      }
    default:
      return state
  }
}
