import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useIntl } from 'react-intl'
import {
  CardStyled,
  Avatar,
  Info,
  ExpandButtonWrapper,
  Content,
  Degree,
  EducationInfoWrapper,
} from './styles/education'
import moment from 'moment'
import ContentSectionExpandable from './content-section-expandable'
import { pabletPX } from '_utils/responsive-queries'

const ContidionalWrapper = ({ isMobile, children }) => {
  return isMobile ? (
    <EducationInfoWrapper>{children}</EducationInfoWrapper>
  ) : (
    <>{children}</>
  )
}

const isValidDate = (date) =>
  date && date !== '' && date !== 'null' && date !== 'undefined'

const EducationItem = ({ item }) => {
  const intl = useIntl()
  const [isExpand, setIsExpand] = useState(false)
  const isMobileView = useMediaQuery({
    query: `(max-width: ${pabletPX}px)`,
  })

  const hasDescription = item.websiteUrl && item.websiteUrl !== ''

  const formatDate = (date) => moment(date).format('YYYY')

  const renderDate = (type) => {
    if (type === 'endDate')
      return isValidDate(item.yearGraduated)
        ? formatDate(item.yearGraduated)
        : intl.formatMessage({
            id: 'user.profile.education.date.current',
            defaultMessage: 'Current',
          })
    if (type === 'startDate')
      return isValidDate(item.yearStarted)
        ? `${formatDate(item.yearStarted)} - `
        : ''
    return ''
  }

  return (
    <CardStyled>
      <Avatar>
        <i className="fas fa-graduation-cap fa-2x" />
      </Avatar>
      <Content>
        <ContidionalWrapper isMobile={isMobileView}>
          <Info>
            <span className="domain">{item.domain}</span>
            <span className="school-name">{item.schoolName}</span>
            {item.location && <span className="location">{item.location}</span>}
            {isExpand && !isMobileView && (
              <span className="description">{item.websiteUrl}</span>
            )}
          </Info>
          <Degree className="degree-wrapper">
            <span className="degree">{item.degree}</span>
            <span className="data">
              {renderDate('startDate')}
              {renderDate('endDate')}
            </span>
            {isExpand && isMobileView && (
              <span className="description">{item.websiteUrl}</span>
            )}
          </Degree>
        </ContidionalWrapper>

        {hasDescription ? (
          <ExpandButtonWrapper
            isMobileView={isMobileView}
            onClick={() => setIsExpand(!isExpand)}
          >
            <button className="expand-button">
              {isMobileView ? (
                <i className={`fas fa-chevron-${isExpand ? 'up' : 'down'}`} />
              ) : (
                intl.formatMessage({
                  id: `person.details.education.${
                    isExpand ? 'hidedescription' : 'viewdescription'
                  }`,
                })
              )}
              {!isMobileView && <span>{isExpand ? ' -' : ' +'}</span>}
            </button>
          </ExpandButtonWrapper>
        ) : (
          <div></div>
        )}
      </Content>
    </CardStyled>
  )
}

const Education = ({ person }) => {
  const intl = useIntl()
  return (
    <ContentSectionExpandable
      header={intl.formatMessage({
        id: 'person.details.education',
        defaultMessage: 'Education',
      })}
      items={person.education}
      ContentComponent={EducationItem}
    />
  )
}

export default Education
