import React, { useState } from 'react'
import { useTaxonomy } from '../hooks'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'

import ShowMore from './show-more'
import UserTaxonomy from 'components/indy/shared/taxonomy'
import styled from '@emotion/styled'

const Content = styled.div`
  padding: 0 45px;
  h5 {
    margin-top: 30px;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    font-weight: 600;
    font-size: 24px;
    color: var(--foreground);
  }
  .hide {
    display: none;
  }
  .taxonomy-title {
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
  }
`

const defaultShown = 2

const TaxonomyList = ({ keywords, show, taxonomies }) => {
  let vals = taxonomies ? taxonomies : keywords ? keywords : []
  return (
    <>
      {vals.map((k, i) => {
        if (k && k?.values?.length > 0)
          return (
            <div
              className={!show && i > defaultShown - 1 ? 'hide' : ''}
              key={i}
            >
              <div className="taxonomy-title">{k.name}</div>
              <div data-tip={k.description} data-for="taxonomy-tooltip">
                <UserTaxonomy
                  taxonomy={k.taxonomy}
                  selectedValues={
                    k?.values[0]?.value
                      ? k.values.map((v) => v.value.toLowerCase())
                      : k.values
                  }
                  isReadOnly={true}
                />
                <ReactTooltip id="taxonomy-tooltip" effect="solid" />
              </div>
            </div>
          )
        return null
      })}
    </>
  )
}

const Taxonomy = ({
  type,
  values,
  noMainTitle,
  excludeKeys,
  renderOverride,
}) => {
  const [show, setShow] = useState()
  const { taxonomies } = useTaxonomy(type)
  const intl = useIntl()

  const topicsText = intl.formatMessage({
    id: 'tile.details.topics',
  })
  const seeMoreText = intl.formatMessage({
    id: 'default.seemore.tag',
  })

  let taxonomyList = []
  if (taxonomies && values) {
    taxonomyList = values
      .map((k, i) => {
        if (excludeKeys && excludeKeys.some((ek) => ek === k.key)) return null
        const keyword = taxonomies.filter((kw) => kw.key === k.key)[0]
        if (k?.values && keyword && k?.values?.length > 0)
          return {
            name: keyword.name,
            description: keyword.description,
            values: k.values,
            taxonomy: keyword,
          }
        return null
      })
      .filter((k) => k)
  }

  if (taxonomyList.length <= 0) return null

  if (renderOverride) {
    return renderOverride({ taxonomyList })
  }

  return (
    <Content>
      {!noMainTitle && <h5>{topicsText}</h5>}
      <TaxonomyList type={type} keywords={taxonomyList} show={show} />
      {taxonomyList?.length > defaultShown && (
        <ShowMore
          text={`${seeMoreText} +${taxonomyList.length - defaultShown}`}
          show={show}
          setShow={setShow}
        />
      )}
    </Content>
  )
}

export default Taxonomy
