import styled from '@emotion/styled'
import { pablet, tablet, mobile } from '_utils/responsive-queries'

const ProfileStyled = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 3px 3px 10px var(--shade);
  background-color: var(--background);
  padding-bottom: 20px;
  min-height: 600px;
  ${mobile} {
    border-radius: 0;
    box-shadow: none;
    min-height: 300px;
  }

  .background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
  }
  .profile-info-container {
    .info-card {
      position: absolute;
      top: 100px;
      right: 50%;
      transform: translateX(50%);
      width: 50%;
      min-height: 230px;
      border-radius: 10px;
      background-color: var(--background);
      box-shadow: 0px 1px 10px var(--shade);
      padding: 0 16px;

      ${tablet} {
        width: 65%;
      }
      ${pablet} {
      }
      ${mobile} {
        top: 55px;
      }
      .profile-image {
        margin: 15px auto 5px;
        width: 70px;
        height: 70px;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        background-color: var(--background);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        ${mobile} {
          margin-top: 20px;
          width: 100px;
          height: 100px;
        }
      }

      .user-name {
        display: block;
        text-align: center;
        padding: 0 5px;
        font-size: 16px;
        font-weight: 600;
        ${mobile} {
          margin-top: 15px;
          font-size: 20px;
        }
      }

      .user-location {
        display: block;
        padding: 5px;
        font-size: 12px;
        text-align: center;
        i {
          font-size: 12px;
          margin: 0 5px;
        }
        ${mobile} {
          font-size: 12px;
          i {
            font-size: 12px;
          }
        }
      }
    }
    .details-container {
      .social-media {
        padding: 20px 0 20px 20px;
        i {
          padding: 5px 8px;
          font-size: 16px;
        }
      }
    }
  }

  .user-name {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 20px;
  }
  .about-me {
    display: block;
    margin: 10px 20px;
    word-break: break-word;
  }
  .details-content-container {
    ${pablet} {
      margin-top: 145px;
      padding: 0 20px;
    }
    @media (min-device-width: 850px) and (max-device-width: 991px) {
      margin-top: 145px;
    }
    ${mobile} {
      display: none;
    }
  }
`

export default ProfileStyled
