import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const StyledDiv = styled.div`
  .backButton {
    margin: 10px 0;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;

    i {
      margin-right: 6px;
    }
  }
`

const BackButton = ({ children, path }) => {
  return (
    <StyledDiv>
      <Link to={path}>
        <button id="backButton" className="backButton">
          <i class="fas fa-arrow-left"></i>
          {children}
        </button>
      </Link>
    </StyledDiv>
  )
}

export default BackButton
