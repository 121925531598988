import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'

import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'
import border from '_utils/css-border'


const ErrorModalStyled = styled(Modal)`
  .modal-dialog {
    margin-top: 240px;
    width: 357px;
  }
  .modal-content {
    position: relative;
    display: flex;
    gap: 16px;

    border-radius: 10px;
    padding: 36px 16px 16px;
    text-align: center;

    background-color: var(--background);
    color: var(--foreground);

    .title {
      font-size: 18px;
      font-weight: 700;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }

    .close-button {
      position: absolute;
      right: 12px;
      top: 12px;
      border: none;
      background-color: transparent;
      transition: 0.3s;
      &:hover {
        opacity: 0.6;
      }
      i {
        font-size: 20px;
      }
    }
  }

  .footer {
    button {
      ${ctaButtonHollow}
      margin-top: 30px;
      display: inline-block;
    }
  }
`

const ErrorModal = ({ show, title, description, button, handleClose }) => {
  const intl = useIntl()
  return (
    <ErrorModalStyled closeButton show={show} onHide={handleClose}>

        <button className="close-button" type="button" onClick={handleClose}>
          <i className="fas fa-times"/>
        </button>
        <h1 className='title'>{title}</h1>
        <p className='description'>{description}</p>
        <div className="footer">{button}</div>

    </ErrorModalStyled>
  )
}

export default ErrorModal