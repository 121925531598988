import React from 'react'
import { filter } from 'lodash'
import { Taxonomy as ExistingTaxonomy } from '_keywords-and-taxonomies'
import styled from '@emotion/styled'
import Card from './card'
import { mobile } from '_utils/responsive-queries'

const CardStyled = styled(Card)`
  flex-direction: column;
  min-width: calc(50% - 10px);
  .taxonomy-wrapper {
    display: flex;
    min-width: 45%;
    flex-wrap: wrap;
    padding: 25px;
  }
  ${mobile} {
    width: 100%;
  }
`

const Header = styled.div`
  padding: 20px 25px;
  border-bottom: 1px solid rgb(0 0 0 / 16%);
  font-weight: 600;
  ${mobile} {
    padding: 15px;
  }
`

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  ${mobile} {
    font-size: 16px;
  }
`

const SingleItemWrapper = styled.div`
  display: flex;
  gap: 35px;
  flex-grow: 1;
  min-width: 45%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: 1px solid var(--shade);
  word-break: break-word;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  span {
    width: 50%;
  }
  .topic-name {
    opacity: 0.6;
    font-weight: 600;
  }
  .items {
  }

  ${mobile} {
    flex-direction: column;
    gap: 10px;
    span {
      width: 100%;
    }
  }
`

const RenderOverride = ({ taxonomyList }) => {
  return (
    <>
      {taxonomyList.map((taxonomy, i) => {
        if (taxonomy && taxonomy?.values?.length > 0) {
          const treeData = taxonomy.taxonomy.treeData
          const rootItems = filter(treeData, { parent: 0 })
          return (
            <CardStyled
              data-for="taxonomys-tooltip"
              key={i}
              data-tip={taxonomy.description}
            >
              <Header>
                <Title>{taxonomy.name}</Title>
              </Header>
              <div className="taxonomy-wrapper">
                {rootItems.map((rootItem) => {
                  const selectedValues = filter(treeData, (item) => {
                    return (
                      item.parent === rootItem.key &&
                      taxonomy.values.includes(item.refId)
                    )
                  })
                  if (selectedValues.length === 0) {
                    return null
                  }

                  return (
                    <SingleItemWrapper>
                      <span className="topic-name">{rootItem.name}:</span>
                      <span className="items">
                        {selectedValues.map((item) => item.name).join(', ')}
                      </span>
                    </SingleItemWrapper>
                  )
                })}
              </div>
            </CardStyled>
          )
        }
        return null
      })}
    </>
  )
}

const Taxonomy = ({ person }) => {
  const defaultTaxonomiesKeys = ['industry-areas']
  return (
    <>
      {person?.taxonomies && (
        <ExistingTaxonomy
          noMainTitle
          renderOverride={RenderOverride}
          excludeKeys={defaultTaxonomiesKeys}
          values={person?.taxonomies}
          type={'people'}
        />
      )}
    </>
  )
}

export default Taxonomy
