import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useTheme } from 'emotion-theming'

import { customBlocks } from '_pagebuilder/blocks/custom-blocks'
import { usePageBuilder, mapBlocksToEntity } from '_pagebuilder/hooks'
import { colorBus } from '_colorBus'

import { useAppConfig } from '_application'
import { pagebuilderStyles } from "../style"
import { notification } from 'antd'

import { useIntl } from 'react-intl'

const FroalaPages = window.FroalaPages

let init = false

const PageBuilderEditor = forwardRef((props, ref) => {
  const { contextId, draft } = props
  const { save, data, loading } = usePageBuilder(contextId, draft)

  const [editor, setEditor] = useState(null)
  const { editorState, draftEditorState } = data?.pageBuilder || {}

  const editorDataJson = draft ? draftEditorState || editorState : editorState
  const config = useAppConfig()

  const { showSaveMessage } = useShowSaveMessage()

  useImperativeHandle(ref, () => ({
    save: onSaveclick,
  }))

  useEffect(() => {
    if (!init && config) {
      init = true
      registerCustomBlocks(FroalaPages, config?.features)
    }
  }, [config])

  const onSaveclick = async contextIdParam => {
    let currentData = null

    try {
      currentData = await editor?.getJSON()

      if (currentData) {
        const contentBlocks = mapBlocksToEntity(currentData)
        const editorState = JSON.stringify(currentData)

        if (contentBlocks && editorState) {
          await save({
            variables: {
              draft: Boolean(draft),
              context: {
                contextId: contextIdParam || contextId,
                contentBlocks,
                editorState,
              },
            },
            update: cache =>
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPageBuilderContext' }),
          })
          showSaveMessage(true, contextIdParam)
          return true
        }
      }
      showSaveMessage(false, contextIdParam)
    } catch (e) {
      showSaveMessage(false, contextIdParam)
    }
    return false
  } 

  const theme = useTheme()

  useEffect(() => {
    const attachColorBus = () => {
      //empty setTimeout makes sure the lines only fire 
      //after the initial javascript event loop as been completled
      setTimeout(() => {
        const iframeWindow =
          document.getElementById('froala-pages').contentWindow
        const iframeHTML = iframeWindow.document.documentElement
        colorBus(theme?.colors || null, iframeHTML)
      })
    }

    const addCustomCSS = (editorDataJson) => {
      let unstring = JSON.parse(editorDataJson)
      let unstrung = JSON.parse(unstring)

      let inlineArray = unstrung.inlineStyle
      let styleArray = unstrung.style

      let typeCSS = `<style data-fp=""> ${theme?.typeCSS.styles} </style>`
      let pageBuilderCSS = `<style data-fp=""> ${pagebuilderStyles().styles} </style>`

      inlineArray.push(typeCSS, pageBuilderCSS)

      let newScheme = {
        style: styleArray,
        scripts: unstrung.scripts,
        blocks: unstrung.blocks,
        inlineStyle: inlineArray,
      }

      return JSON.stringify(newScheme)
    }

    if (!loading) {
      const editor = new FroalaPages('page-builder-editor-div', {
        key: process.env.REACT_APP_FROALA_LICENSE_KEY,
        editorOptions: {
          pastePlain: false,
        },
        appStyle: [theme?.typeCSS.styles, pagebuilderStyles().styles],
      })

      if (editorDataJson) {
        const newScheme = addCustomCSS(editorDataJson)
        editor.setJSON(newScheme)
      }

      attachColorBus()
      setEditor(editor)
    }
  }, [editorDataJson, loading, theme])

  return (
    <>
      <div>
        <div id="page-builder-editor-div" class="page-wrapper" />
      </div>
    </>
  )
})

const registerCustomBlocks = (FroalaPages, featuresArray) => {
  customBlocks.forEach(({ id, thumbnail, feature }) => {
    feature
      ? featuresArray.includes(feature) && feature === 'events'
        ? FroalaPages.RegisterDesignBlock(
          'Widgets',
          `<div><div class="container" id="customblock-${id}" style="display:flex;flex-direction:column;align-items:center;">
          <div>
          <span style="color:white;border-radius:50%;background:#1275E0;padding: 0px 8px;">i</span>
          <label>You will be able to select one category to display in this Event section after saving.</label>
          </div>
    <img src="/pagebuilder/${thumbnail.split('/')[1]}"/>
    </div></div>`,
          thumbnail
        )
        : FroalaPages.RegisterDesignBlock(
          'Widgets',
          `<div><div class="container" id="customblock-${id}" style="display:flex;flex-direction:column;align-items:center;">
    <img src="/pagebuilder/${thumbnail.split('/')[1]}"/>
    </div></div>`,
          thumbnail
        )
      : FroalaPages.RegisterDesignBlock(
        'Widgets',
        `<div><div class="container" id="customblock-${id}" style="display:flex;flex-direction:column;align-items:center;">
    <img src="/pagebuilder/${thumbnail.split('/')[1]}"/>
    </div></div>`,
        thumbnail
      )
  })
} 

const useShowSaveMessage = () => {
  const intl = useIntl()

  const showSaveMessage = (isSuccess, refId) => {
    const isCustomPages = refId?.includes('custom') 
  
    const notify = {
      message: intl.formatMessage({ id: `${!isCustomPages ? 'pagebuilder' : "custompages"}.save.title` }),
      description: intl.formatMessage({ id: `pagebuilder.save.${isSuccess ? 'success' : 'error'}.desc` }),
      type: isSuccess ? 'success' : 'warning',
    }

    notification[notify.type](notify)
  }
  return { showSaveMessage }
}

export { PageBuilderEditor, useShowSaveMessage }
