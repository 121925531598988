import css from '@emotion/css'
import styled from '@emotion/styled'
import { Tabs } from '@patternfly/react-core'
import { mobile, pablet } from '_utils/responsive-queries'

export const StyledTabs = styled(Tabs)`
  &.pf-c-tabs {
    margin-bottom: 6px;
  }

  .pf-c-tabs__item-text,
  .tag-link {
    font-size: 16px;
    font-weight: 500;
    color: var(--foreground);
  }

  .pf-c-tabs__link::before {
    border-style: none;
  }

  .pf-m-current {
    .pf-c-tabs__link::after {
      border-bottom: 3px solid var(--flavor);
    }
  }

  .pf-c-tabs__scroll-button {
    border-radius: 100%;
    border: 2px solid var(--flavor);
    background-color: transparent;
    color: var(--flavor);
    width: 30px;
    height: 30px;
    margin-left: 30px;
    align-self: center;
  }

  .pf-c-tabs__scroll-button::before {
    display: none;
  }

  ${({ subcategories }) =>
    subcategories &&
    css`
      .pf-c-tabs__scroll-button {
        border: none;
      }

      .pf-c-tabs__item-text {
        font-size: 14px;
        font-weight: 400;
        color: var(--foreground);
      }
    `}

  ${mobile} {
    .pf-c-tabs__scroll-button {
      margin-left: 0;
    }
  }
  ${pablet} {
    margin-top: 25px;
  }
`
