import React, { useState } from 'react'
import styled from '@emotion/styled'

import ForumHeader from './components/header'
import ForumFooter from './components/footer'
import ForumMenu from './components/menu'
import Home from './pages/home'
import Users from './pages/users'
import Tags from './pages/tags'
import Tag from './pages/tag'
import Popular from './pages/popular'
import Categories from './pages/categories'
import Category from './pages/category'
import Topic from './pages/topic'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container } from '_styled-components/shared/section-container'
import { useAppLabel, usePageTitle } from '_application'
import ScrollToTopOnLocationChange from '_components/scroll-to-top-by-location'
// import { usePluginsPublic } from '_application/plugins'

const MainContent = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--background);
  color: var(--foreground);
  [aria-hidden^='true'].graph-tab-container {
    display: none;
  }
`

// const Content = styled.div`
//   padding: 0 100px;
//   flex-grow: 1;
//   max-width: 1570px;
// `

export default function App() {
  const appLabel = useAppLabel()
  const title = appLabel('forum', 'header.navigation.forum')
  usePageTitle(title)

  const [loadingObject, setLoadingObject] = useState({
    isLoading: false,
    hasBackground: false,
  })
  // const { plugin } = usePluginsPublic('forum')
  // const isEnabled = plugin?.enabled
  const isEnabled = true
  if (isEnabled) {
    return (
      <MainContent>
        <Container className={"page-wrapper"}>
          <ForumHeader />
          <Router>
            <ForumMenu />
            <ScrollToTopOnLocationChange />
            <Switch>
              <Route
                path="/forum/topic/:id"
                exact
                render={() => (
                  <Topic
                    loadingObject={loadingObject}
                    setLoadingObject={setLoadingObject}
                  />
                )}
              />
              <Route path="/forum/tag/:tagName" exact component={Tag} />
              <Route
                path="/forum/category/:id"
                exact
                render={(props) => (
                  <Category
                    {...props}
                    loadingObject={loadingObject}
                    setLoadingObject={setLoadingObject}
                  />
                )}
              />
              <Route path="/forum/categories/" exact component={Categories} />
              <Route path="/forum/popular/" exact component={Popular} />
              <Route path="/forum/tags/" exact component={Tags} />
              <Route path="/forum/users/" exact component={Users} />
              <Route path="/forum/" exact component={Home} />
            </Switch>
          </Router>
        </Container>
        <ForumFooter />
      </MainContent>
    )
  } else {
    return ''
  }
}
