import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useMutation } from '@apollo/client'

import { UPDATE_PLUGIN, GET_PLUGINS } from './graph-queries'
import pluginList from './plugin-list'

import Input from 'components/indy/shared/ui-kit/inputs/text'
import { ModalStyled, Section } from './styles/manage-modal'
import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import Toggle from 'react-toggle'
import { firstTimeSetup } from './helpers'
import { useIntl } from 'react-intl'

import { useColorBucket } from '_colorBus/hooks'

const Index = ({ currentPlugin, onClose }) => {
  const intl = useIntl()
  const [plugin, setPlugin] = useState(currentPlugin)

  const [updatePlugin] = useMutation(UPDATE_PLUGIN)

  const rednerMessageText = (value) =>
    intl.formatMessage({ id: `admin.plugins.modal.edit.${value}` })

  const renderField = (field) => {
    if (field.type === 'text' || field.type === 'textarea') {
      return (
        <Input
          onBlurCallback={(v) =>
            handleUpdatePlugin({ key: field.key, value: v })
          }
          input={{
            name: field.name,
            value: plugin?.data[field.key],
            placeholder: field.name,
            required: field.required,
            isMultiline: field.type === 'textarea',
          }}
          noTitle
        />
      )
    }
    if (field.type === 'boolean') {
      return (
        <Toggle
          checked={plugin?.data[field.key]}
          onChange={(e) =>
            handleUpdatePlugin({ key: field.key, value: e.target.checked })
          }
        />
      )
    }
  }

  const handleUpdatePlugin = ({ key, value }) => {
    const p = { ...plugin }
    p.data = { ...p.data, [key]: value }
    setPlugin(p)
  }

  const handleSavePlugin = () => {
    updatePlugin({
      variables: plugin,
      refetchQueries: [{ query: GET_PLUGINS }],
    })
    onClose()
  }

  const handleFirstTimeSetup = (plugin) => {
    firstTimeSetup(plugin, intl)
  }

  const [colorBucket] = useColorBucket('admin')

  const pluginInfo = pluginList.filter((p) => p.key === plugin.key)[0] || {}

  return (
    <Modal dialogClassName="manage-plugin" show onHide={onClose} closeButton>
      <ModalStyled ref={colorBucket}>
        <Modal.Body className="manage-plugin" bsClass="custom-modal">
          <h3>{`${pluginInfo.name} ${rednerMessageText('configuration')}`}</h3>
          {pluginInfo.firstTimeSetup && (
            <Section>
              <span>{rednerMessageText('shortdescription')}</span>
              {pluginInfo.firstTimeSetup && (
                <Button
                  onClick={() => handleFirstTimeSetup(pluginInfo, intl)}
                  type="admin"
                >
                  {rednerMessageText('button.firstsetup')}
                </Button>
              )}
            </Section>
          )}
          {pluginInfo?.data?.map((d) => {
            return (
              <Section>
                <span>{d.name}</span>
                {renderField(d)}
              </Section>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" onClick={onClose}>
            {rednerMessageText('button.cancel')}
          </Button>
          <Button className="save" onClick={handleSavePlugin}>
            {rednerMessageText('button.save')}
          </Button>
        </Modal.Footer>
      </ModalStyled>
    </Modal>
  )
}

export default Index
