import styled from '@emotion/styled'

export const SearchStyled = styled.div`
  width: 80%;
  padding-left: 25px;
  margin-right:20px;
  input {
    padding: 10px 20px;
    border: 1px solid var(--foreground);
    border-radius: 15px;
    width: 100%;
    background-color: var(--background);
    color: var(--foreground) !important;
    &::placeholder {
      color: var(--foreground) !important;
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
`
