import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { notification } from 'antd'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import { useIntl } from 'react-intl'

import './style.scss'

const ModalStyled = styled(Modal)`
  .modal-content {
    background-color: var(--background);
    color: var(--foreground) !important;
    .report-modal-body {
      .report-title {
        color: var(--foreground);
      }
      .report-description {
        color: var(--foreground);
      }
      .single-configuration-container {
        label.input-label {
          color: var(--foreground);
        }
        textarea.input-area {
          color: var(--foreground);
          &::placeholder {
            color: var(--foreground) !important;
          }
        }
      }
    }
    textarea.input-area {
      background-color: transparent !important;

      border: 1px solid var(--foreground) !important;
    }
  }
  .modal-footer {
  }
`

const mapStateToProps = ({ user }) => ({ user })

const Index = ({ canShowModal, setModalVisible }) => {
  const intl = useIntl()
  const [message, setMessage] = useState('')

  const handleClose = () => {
    setModalVisible(!canShowModal)
  }

  const ADD_REPORT = gql`
    mutation AddReport($message: String) {
      addReport(message: $message) {
        message
        status
      }
    }
  `

  const [addReport] = useMutation(ADD_REPORT, {
    variables: { message },
  })

  const handleTextarea = (value) => {
    setMessage(value)
  }

  const handleAddReport = () => {
    addReport()
    notification.success({
      message: intl.formatMessage({ id: 'toast.addreport.title' }),
      description: intl.formatMessage({ id: 'toast.addreport.text' }),
    })
    setModalVisible(false)
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `footer.report.modal.${value.toLowerCase()}`,
    })
  }

  return (
    <ModalStyled
      dialogClassName="report-modal-container"
      show={canShowModal}
      onHide={handleClose}
    >
      <Modal.Body className="report-modal-body">
        <div className="report-info-container">
          <h2 className="report-title">{renderMessageText('title')}</h2>
          <p className="report-description">
            {renderMessageText('description')}
          </p>
        </div>
        <div className="configuration-settings-container">
          <div className="single-configuration-container">
            <label className="input-label" htmlFor="apiKeyInput">
              {renderMessageText('subTitle')}
            </label>
            <textarea
              onChange={(e) => handleTextarea(e.target.value)}
              value={message}
              rows={4}
              className="input-area"
              placeholder={renderMessageText('placeholder')}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={handleClose}
          className="close-button"
          testid="report-close"
        >
          {renderMessageText('close')}
        </Button>
        <Button
          type="button"
          onClick={() => handleAddReport()}
          className="report-button"
          testid="report-submit"
        >
          {renderMessageText('report')}
        </Button>
      </Modal.Footer>
    </ModalStyled>
  )
}

export default connect(mapStateToProps)(Index)
