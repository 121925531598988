import React, { useEffect, useState } from 'react'
import { useSearch } from '_search/search-hooks'
import Search from './search'

import {
  MainSectionWrapper,
  SectionHeader,
  Description,
  ItemsWrapper,
  RemoveButton,
  SelectedItem,
  Info,
  SelectedAvatar,
  SelectedName,
  Actions,
} from '_events/components/event-creator/components/styles/section-with-search'

import { useIntl } from 'react-intl'
import { SearchWrapper } from './styles'
import BreakLine from '../break-line'
import { useUser } from '_security/session-hooks'

const OwnerSelector = ({
  entity,
  searchType = 'user',
  entityType,
  entityName,
  onSave,
}) => {
  const [query, setQuery] = useState('')
  const [owner, setOwner] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  const [getSearchResults, { loading, data }] = useSearch(query, {
    limit: 5,
    type: searchType,
  })

  const user = useUser()
  const isAdmin = user?.profile?.role === 'admin'

  const intl = useIntl()

  const results = data?.search?.data

  const handleSelect = (creator) => {
    setOwner(creator)
    onSave(creator?._id)
    setQuery('')
    setIsEdit(false)
  }

  useEffect(() => setOwner(entity?.creator || {}), [])

  if (!entity || !isAdmin) {
    return <></>
  }

  return (
    <>
      <MainSectionWrapper>
        <SectionHeader>
          {intl.formatMessage({
            id: 'contentstudio.form.owner-selector.title',
          })}
        </SectionHeader>
        <Description>
          {intl.formatMessage({
            id: 'contentstudio.form.owner-selector.description',
          })}
        </Description>
        {isEdit && (
          <SearchWrapper>
            <Search
              searchState={{ query, setQuery }}
              loading={loading}
              placeholder={`${intl.formatMessage({
                id: 'contentstudio.additional.entityconnections.placeholder',
                defaultMessage: 'Search for',
              })} ${entityName || searchType}`}
              handleSelect={(item) => handleSelect(item)}
              results={results}
            />

            <RemoveButton onClick={() => setIsEdit(false)}>
              <i className="fas fa-times" />
            </RemoveButton>
          </SearchWrapper>
        )}

        {!isEdit && (
          <ItemsWrapper>
            <SelectedItem>
              <Info>
                <SelectedAvatar src={owner?.avatar} />
                <SelectedName>
                  {owner?.firstName} {owner?.lastName}
                </SelectedName>
              </Info>
              <Actions>
                <RemoveButton onClick={() => setIsEdit(true)}>
                  <i className="fas fa-pen" />
                </RemoveButton>
              </Actions>
            </SelectedItem>
          </ItemsWrapper>
        )}
      </MainSectionWrapper>
      <BreakLine />
    </>
  )
}

export default OwnerSelector

