import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Graph from 'vis-react'
import { isEqual, sortBy } from 'lodash'
import Loading from '_components/loading'
import { MainWrapper } from '../styles'

const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      connectedUsers {
        sub
        avatar
        firstName
        lastName
        connectionStatus
      }
    }
  }
`

const options = {
  layout: {
    improvedLayout: true,
    hierarchical: {
      enabled: false,
      nodeSpacing: 200,
    },
  },
  edges: {
    color: {
      color: '#ddd',
      highlight: '#254C61',
    },
    width: 0.5,
    smooth: {
      enabled: true,
      type: 'cubicBezier',
    },
  },
  nodes: {
    shape: 'dot',
    size: 24,
  },
  interaction: { hoverEdges: true },
  physics: {
    enabled: true,
    hierarchicalRepulsion: {
      nodeDistance: 500,
      damping: 0,
    },
    solver: 'repulsion',
    stabilization: { iterations: 100 },
  },
}
const processGraph = (data, size = 50, user, nodes, edges) => {
  if (nodes.length === 0) {
    nodes.push({
      id: data.forSub,
      label: `${user.profile?.firstName} ${user.profile?.lastName}`,
      shape: 'circularImage',
      image: user?.profile?.avatar,
      size: size + 20,
    })
  }
  if (data && data.connectedUsers) {
    data.connectedUsers.map((entity) => {
      if (nodes.filter((n) => n.id === entity?.sub).length === 0) {
        if (entity?.sub && (entity?.firstName || entity?.lastName)) {
          nodes.push({
            id: entity.sub,
            label: `${entity?.firstName} ${entity?.lastName}`,
            shape: 'circularImage',
            image:
              entity?.avatar ||
              'https://storage.googleapis.com/buildtoriot-cdn/not-logged-avatar.jpg',
            group: size,
            size,
          })
        }
      }
      if (
        edges.filter((e) =>
          isEqual(sortBy([e.from, e.to]), sortBy([user.sub, entity.sub]))
        ).length === 0 &&
        user.sub !== entity.sub
      ) {
        edges.push({ from: user.sub, to: entity.sub })
      }
      return processGraph(entity.connections, 20, entity, nodes, edges)
    })
  }
  return { nodes, edges }
}

const Visualisation = () => {
  const history = useHistory()
  const { user } = useSelector((state) => state)
  const [stateNodes, setStateNodes] = useState([])
  const [stateEdges, setStateEdges] = useState([])
  const [processed, setProcessed] = useState(false)
  const { data: connectionsData, loading } = useQuery(GET_USERS_CONNECTIONS, {
    variables: { ids: [user.sub], limit: 100 },
    notifyOnNetworkStatusChange: true,
    skip: !user || !user.sub,
  })
  useEffect(() => {
    if (
      connectionsData &&
      connectionsData?.getUserConnectionsPublic?.length > 0 &&
      !loading &&
      !processed
    ) {
      const { nodes, edges } = processGraph(
        connectionsData.getUserConnectionsPublic[0],
        50,
        user,
        [],
        []
      )
      setStateNodes(nodes)
      setStateEdges(edges)
      setProcessed(true)
    }
  }, [connectionsData, user, loading, processed])

  if (loading) {
    return <Loading />
  }
  return (
    <MainWrapper>
      <Graph
        graph={{ nodes: stateNodes, edges: stateEdges }}
        options={options}
        events={{
          select: function (event) {
            const { nodes } = event
            if (nodes && nodes.length > 0) {
              history.push(`/people/list/${nodes[0]}`)
            }
          },
        }}
        style={{ width: '100%', height: '600px' }}
      />
    </MainWrapper>
  )
}

export default Visualisation
