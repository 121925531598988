import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useUser } from '_security/session-hooks'
import IndyButton from '_components/indyButton'
import { useCanAddBlogPost } from '_blog/hooks'

export const AddNewBlog = () => {
  const intl = useIntl()
  const user = useUser()
  const canAddBlogPost = useCanAddBlogPost() && user?.profile?.role
 
  return ( 
    <>
      {canAddBlogPost && <Link
        id={"new-blog"}
        to={"/blog/new"}
      >
        <IndyButton>
          <i className={`fas fa-plus`} alt="Add new blog" />
          {intl.formatMessage({ id: 'companies.createnew' })}
        </IndyButton>
      </Link>}
    </>
  )
}

export const ViewDrafts = () => {
  const intl = useIntl()
  const user = useUser()
  const isAdmin = user?.profile?.role === 'admin'

  return (
    <>
      {isAdmin && (
        <Link
          id={"blog-drafts"}
          to={"/blog/view/drafts"}
        >
          <IndyButton>
            <i className={`fas fa-edit`} alt="See blog drafts" />
            {intl.formatMessage({ id: 'blog.drafts', defaultMessage: "See Drafts" })}
          </IndyButton>
        </Link>
      )}
    </>
  )
}

export const ViewPublished = () => {
  const intl = useIntl()
  const user = useUser()
  const isAdmin = user?.profile?.role === 'admin'

  return isAdmin && (
    <Link
      id={"blog"}
      to={"/blog/"}
    >
      <IndyButton>
        <i className={`fas fa-book`} alt="See published posts drafts" />
        {intl.formatMessage({ id: 'blog.drafts', defaultMessage: "Published Posts" })}
      </IndyButton>
    </Link>
  )

}

