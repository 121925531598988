import React from 'react'
import { nextPage } from '_utils/graph-pagination'
import { Col, Row } from 'react-bootstrap'
import { ctaButtonHollow } from '_utils/css-buttons'
import { useIntl } from 'react-intl'
import { getPageSizeForScreenSize } from '_tiles/helpers'

import styled from '@emotion/styled'

const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

export const FetchMore = ({ hasMore, loader, filter, offset, updateQuery }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(
                  offset,
                  getPageSizeForScreenSize({ pageType: 'grid' })
                ),
                filter,
                updateQuery,
              })
            }
          >
            <p>
              {intl.formatMessage({ id: 'global.loadmore.button' })}
              <i class="fas fa-chevron-down"></i>
            </p>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}
