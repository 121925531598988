import React, { useState } from 'react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { useAppLabel, useAppConfig } from '_application'
import { Map } from '_components/drilldown'
import CustomAttributes from '_custom-attributes/components/list'
import OpportunityMiniCard from './tiles/opportunity-mini-card'
import PublicDetailsCard from '_public-details-card'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import ApplicationSubmission from './application-submission'
import { Keywords, Taxonomy } from '_keywords-and-taxonomies'
import { Section, SingleAttribute } from '_custom-attributes'
import count from 'helpers/counter'
import styled from '@emotion/styled'
import Connections from './connections'
import { useQuery, gql, useMutation } from '@apollo/client'
import { notification } from 'antd'
import { ctaButtonHollow } from '_utils/css-buttons'
import { useUser } from '_security/session-hooks'
import { css } from '@emotion/core'
import SanitiseHTML from 'helpers/sanitize-html'
import Modal from '../../_events/components/event-creator/components/modal'
import EditInfo from 'components/indy/shared/edit-info'
// import { divide } from 'lodash'

const GoBackButton = styled.button`
  ${ctaButtonHollow}
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
  span.text:first-letter {
    text-transform: uppercase;
  }
`

const buttonStyles = `
  font-size: 16px;
  display: inline-block;
  background-color: transparent;
  border: 2px solid var(--flavor);
  color: var(--foreground) !important;
  border-radius: 25px;
  padding: 10px 40px;
  font-weight: 600;
  transition: 0.3s;
  i {
    color: var(--foreground);
    padding-right: 10px;
    transition: 0.3s;
  }
  &:hover {
    border: 2px solid var(--flavor);
    background-color: var(--flavor);
    color: var(--flavor-contrast) !important;
    i {
		color: var(--flavor-contrast);
    }
  }
`

const ApplyButton = styled.a(buttonStyles)
const ViewApplicationBtn = ApplyButton.withComponent('button')

const Expired = styled.div`
  border-radius: 30px;
  border: 2px solid var(--shade);
  padding: 6px 25px;
  height: 50px;
  max-height: 42px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoWrapper = styled.div`
  width: 100%;
  transform: translateY(-100px);
  background-color: transparent;
  .separator {
    height: 30px;
  }
`

const TileWrapper = styled(Link)`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  max-width: 304px;
  margin: 0 auto;
  ${({ hasMultipleConnections, isLoggedIn }) =>
    (hasMultipleConnections || !isLoggedIn) && { pointerEvents: 'none' }}
`

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const GET_REMAINING_OPPORTUNITIES_COUNT = gql`
  query GetRemainingApplicationCount {
    getRemainingApplicationCount
  }
`
const DELETE_OPPORTUNITY = gql`
  mutation deleteOpportunity($id: String) {
    deleteOpportunity(id: $id) {
      id
    }
  }
`

const OpportunityDetails = ({
  opportunity,
  children,
  attributes,
  onClose,
  connectButton,
  isOwner,
  a2cButton,
  recommendButton,
}) => {
  const { creator, connectedCompanies, expire } = opportunity
  const [isApplicationSubmissionModal, setIsApplicationSubmissionModal] =
    useState()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const opportunityExpire = moment(expire)
  const currentDate = moment(new Date())

  const isOpportunityExpired = currentDate.isAfter(opportunityExpire)

  const config = useAppConfig()
  const appLabel = useAppLabel()
  const intl = useIntl()
  const user = useUser()
  const history = useHistory()
  const isLoggedIn = user?.profile?.role

  const { isApplicationSubmissionAuthEnabled } = config

  const {
    data: remainingOpportunities,
    loading: remainingOpportunitiesCountLoading,
  } = useQuery(GET_REMAINING_OPPORTUNITIES_COUNT)

  const [deleteOpportunity] = useMutation(DELETE_OPPORTUNITY, {
    variables: {
      id: opportunity?.id,
    },
  })

  const remainingOpportunitiesCount = !remainingOpportunitiesCountLoading
    ? remainingOpportunities?.getRemainingApplicationCount || 0
    : null

  const isButtonDisabled =
    remainingOpportunitiesCount <= 0 &&
    isApplicationSubmissionAuthEnabled &&
    user?.profile?.role !== 'admin'

  const applyNowButton = intl.formatMessage({
    id: `default.applynow.button`,
  })

  const getLabel = (label) => {
    return intl.formatMessage({
      id: `applicationsubmission.${label}`,
    })
  }

  const applyEmail = config?.labelOverrides?.opportunitiesEmail?.value

  const isAts = opportunity?.receiveApplicationBy?.type === 'ats'
  const atsLink = opportunity?.receiveApplicationBy?.value

  const handleApplyButtonClick = () => {
    if (!isLoggedIn) {
      const notify = {
        message: getLabel('notify.title'),
        description: intl.formatMessage({
          id: 'applicationsubmission.notloggedin.description',
          defaultMessage:
            'You must login or register to apply for this opportunity',
        }),
        type: 'warning',
      }
      notification.warning(notify)
      return false
    }
    if (isButtonDisabled) {
      const notify = {
        message: getLabel('notify.title'),
        description: intl.formatMessage({
          id: 'applicationsubmission.notenoughcredits.description',
        }),
        type: 'warning',
      }
      notification.warning(notify)
      return false
    } else {
      if (!isAts) setIsApplicationSubmissionModal(true)
    }
  }

  const applyButton = () => {
    if (!applyEmail || isOwner) return <></>

    if (isOpportunityExpired) {
      return (
        <Expired>
          {intl.formatMessage({ id: 'default.applynow.button.expired' })}
        </Expired>
      )
    }

    return (
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0.6em;
        `}
      >
        <ApplyButton
          target={isAts && `_blank`}
          href={isAts && atsLink}
          expired={!isOpportunityExpired}
          onClick={
            isOpportunityExpired ? () => {} : () => handleApplyButtonClick()
          }
          data-testid="btn-opportunity-detail-apply"
        >
          <i className="fas fa-plus" />
          {applyNowButton}
        </ApplyButton>
        {opportunity.myApplication && (
          <Link
            to={`/opportunities/applications/${opportunity.myApplication._id}`}
          >
            <ViewApplicationBtn>
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              <FormattedMessage
                id={'opportunities.application.view'}
                defaultMessage={'View Application'}
              />
            </ViewApplicationBtn>
          </Link>
        )}
      </div>
    )
  }

  const defaultTaxonomiesKeys = ['industry-areas']
  const defaultTaxonomies = opportunity?.taxonomy?.filter((v) =>
    defaultTaxonomiesKeys.some((d) => d === v.key)
  )
  const defaultKeywordsKeys = ['competences', 'audience']
  const defaultKeywords = opportunity?.keywords?.filter((v) =>
    defaultKeywordsKeys.some((d) => d === v.key)
  )

  let defaultTaxonomiesLength = count(defaultTaxonomies)
  let defaultKeywordsLength = count(defaultKeywords)

  const tabs = {
    about: { type: 'about', translationKey: 'user.profile.about' },
    activity: {
      type: 'connection',
      translationKey: 'activities.titleplural',
      count: opportunity?.connectedActivity?.length,
    },
    company: {
      type: 'connection',
      translationKey: 'companies.titleplural',
      count: opportunity?.connectedCompanies?.length,
    },
  }

  const hasConnections = opportunity?.connectedCompanies?.length > 0
  const hasMultipleConnections = opportunity?.connectedCompanies?.length > 1

  const tileUrl = () => {
    if (!hasConnections) return `/people/list/${creator?.sub}`
    if (hasConnections && !hasMultipleConnections)
      return `/companies/list/${connectedCompanies[0]?.id}`
    return null
  }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])

  const isAboutView = tabs[currentTab]?.type === 'about'

  if (!opportunity) {
    return <></>
  }
  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${opportunity?.title} opportunity?`}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.continue',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.cancel',
        })}
        onProceed={() => {
          deleteOpportunity()
            .then(() => {
              history.push(`/opportunities/list`)
              history.go(0) // TODO this is temp solution to refetch list
            })
            .catch(console.error)
        }}
      />
      <PublicDetailsCard>
        <PublicDetailsCard.Header
          onClose={onClose}
          returnText={'Back to all opportunities'}
          coverUrl={opportunity.coverPhoto}
          type={appLabel(
            'opportunities',
            'detailcard.opportunity'
          )?.toLowerCase()}
          entityType="opportunity"
          entityId={opportunity.id}
          title={opportunity.name}
          entity={opportunity}
          creator={opportunity?.creator?.id}
          recommendButton={isLoggedIn ? recommendButton : false}
          onDelete={() => setIsDeleteModalOpen(true)}
        />
        <PublicDetailsCard.Container>
          <PublicDetailsCard.Content
            position={isAboutView ? 'left' : 'center'}
            showApply={true}
          >
            {isLoggedIn && (
              <TabsWrapper>
                <PublicDetailsCard.ContentTabs
                  tabs={tabs}
                  onTabSelect={setCurrentTab}
                />
              </TabsWrapper>
            )}
            {opportunity?.editor && (
              <EditInfo
                editDetails={{
                  authorName: opportunity?.editor?.firstName,
                  authorSurname: opportunity?.editor?.lastName,
                  date: opportunity?._updatedAt,
                }}
              />
            )}
            {isAboutView && (
              <>
                <PublicDetailsCard.ContentHeader
                  title={opportunity.name}
                  connectButton={applyButton()}
                  applyByDate={opportunity?.expire}
                  isPrivate={opportunity?.private}
                  type={opportunity.__typename.toLowerCase()}
                />
                <SanitiseHTML html={opportunity?.body} />
                {children}
                <Map location={opportunity.location} />
              </>
            )}
            {!isAboutView && (
              <Connections currentTab={currentTab} entityId={opportunity?.id} />
            )}
          </PublicDetailsCard.Content>
          {isAboutView && (
            <PublicDetailsCard.Info position="right">
              <InfoWrapper position="right">
                <TileWrapper
                  to={tileUrl()}
                  hasMultipleConnections={hasMultipleConnections}
                  isLoggedIn={isLoggedIn}
                >
                  <OpportunityMiniCard
                    opportunity={opportunity}
                    connectButton={connectButton}
                    a2cButton={a2cButton}
                    isOwner={isOwner}
                  />
                </TileWrapper>
                {!hasMultipleConnections && <div className="separator" />}
                {opportunity?.start && (
                  <Section type="date">
                    <SingleAttribute
                      attribute={{
                        type: 'date',
                        description: 'Start date',
                        name: `${intl.formatMessage({
                          id: 'singleattribute.startdate.label',
                        })}`,
                        value: opportunity?.start,
                      }}
                    />
                  </Section>
                )}
                {opportunity?.end && (
                  <Section type="date">
                    <SingleAttribute
                      attribute={{
                        type: 'date',
                        description: 'End date',
                        name: `${intl.formatMessage({
                          id: 'singleattribute.enddate.label',
                        })}`,
                        value: opportunity?.end,
                      }}
                    />
                  </Section>
                )}
                {opportunity.location && opportunity.location?.label && (
                  <>
                    <Section type="location">
                      <SingleAttribute
                        attribute={{
                          type: 'location',
                          description: 'Location',
                          name: `${intl.formatMessage({
                            id: 'singleattribute.officelocation.label',
                          })}`,
                          value: opportunity.location,
                        }}
                      />
                    </Section>
                  </>
                )}

                {defaultKeywords && defaultKeywordsLength > 0 && (
                  <>
                    <Keywords
                      noMainTitle
                      values={defaultKeywords}
                      type={'opportunities'}
                    />
                  </>
                )}
                {defaultTaxonomies && defaultTaxonomiesLength > 0 && (
                  <>
                    <Taxonomy
                      noMainTitle
                      values={defaultTaxonomies}
                      type={'opportunities'}
                    />
                  </>
                )}

                {opportunity?.keywords && (
                  <Keywords
                    noMainTitle
                    excludeKeys={defaultKeywordsKeys}
                    values={opportunity?.keywords}
                    type={'opportunities'}
                  />
                )}
                {opportunity?.taxonomy && (
                  <Taxonomy
                    noMainTitle
                    excludeKeys={defaultTaxonomiesKeys}
                    values={opportunity?.taxonomy}
                    type={'opportunities'}
                  />
                )}
                <CustomAttributes
                  values={opportunity?.customAttributes}
                  attributes={attributes}
                  side="right"
                />
              </InfoWrapper>
            </PublicDetailsCard.Info>
          )}
          <PublicDetailsCard.GoBackContent>
            <Link to="/opportunities/list/">
              <GoBackButton data-testid="detailCard-btn-back">
                <p>
                  <i className="fas fa-chevron-left" />
                  <span className="text">
                    {intl
                      .formatMessage(
                        { id: 'detailcard.button.back' },
                        {
                          category: appLabel(
                            'opportunitiesPlural',
                            'detailcard.button.opportunities'
                          ),
                        }
                      )
                      .toLowerCase()}
                  </span>
                </p>
              </GoBackButton>
            </Link>
          </PublicDetailsCard.GoBackContent>
        </PublicDetailsCard.Container>
        <ReactTooltip id="info-tooltip" effect="solid" />
        {isApplicationSubmissionModal && (
          <>
            <div className="fade-background-overlay" />
            <ApplicationSubmission
              onClose={() => setIsApplicationSubmissionModal(false)}
              opportunity={opportunity}
            />
          </>
        )}
      </PublicDetailsCard>
    </>
  )
}

export default OpportunityDetails

