import { gql } from '@apollo/client'

export const CONNECTED_COMPANIES = gql`
  query GetPersonConnectedCompaniesPaged($id: ID, $paging: PagingInput) {
    getPersonConnectedCompaniesPaged(id: $id, paging: $paging) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECTED_USERS = gql`
  query GetPersonConnectedUsersPaged($id: ID, $paging: PagingInput) {
    getPersonConnectedUsersPaged(id: $id, paging: $paging) {
      offset
      hasMore
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

export const CONNECTED_ACTIVITIES = gql`
  query GetPersonConnectedActivitiesPaged($id: ID, $paging: PagingInput) {
    getPersonConnectedActivitiesPaged(id: $id, paging: $paging) {
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        connectedCompanies {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
