import React from 'react'
import { css } from '@emotion/core'
import { Tile as PersonTile } from '_people'
import { useMutation } from '@apollo/client'
import { CONNECT_MUTATION } from '_people/connected/person-details-connected'

const ConnectedPersonTile = ({
  person,
  p2pButton,
  connections,
  showConnections,
  url,
}) => {
  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION, {
    variables: { sub: person.sub },
    refetchQueries: p2pButton?.refetchQueries,
    awaitRefetchQueries: true,
  })

  return (
    <PersonTile
      css={css`
        width: 306px;
      `}
      url={url}
      person={person}
      connectButton={{
        status: person.connectionStatus,
        onConnect: connect,
        disabled: connecting,
      }}
      connections={connections}
      showConnections={showConnections}
    />
  )
}

export default ConnectedPersonTile
