import React from 'react'
import { Avatar, ListBlock, ContentWrapper } from './styles/latest-posts'
import moment from 'moment'
import { useTheme } from 'emotion-theming'
import { Loader } from '_components/loading'
import { replaceUsernameNumbers } from '_forum/shared/replaceUsernameNumbers'

export default function LatestPosts(props) {
  const theme = useTheme()

  const setCharacterLimit = (string) => {
    const limit = 35
    return string.length > limit ? string.substring(0, limit) + '...' : string
  }

  const unescape = (html) => {
    const returnStr = html?.replace(/<[^>]*>?/gm, '')
    let e = document.createElement('div')
    e.innerHTML = returnStr
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }
  if (!props.posts) {
    return <Loader loading={!props.posts} testid="latestPosts" />
  }
  return (
    <>
      {props?.posts?.recentTopics?.topicsList.map(function (post, i) {
        return (
          <>
            {post.teaser ? (
              <>
                <ListBlock key={i}>
                  <Avatar>
                    <img
                      src={
                        post.teaser.user.picture || theme?.images?.defaultAvatar
                      }
                      alt=""
                    />
                  </Avatar>
                  <ContentWrapper to={`/forum/topic/${post.tid}`}>
                    <h6 className="header">
                      <span className="name">
                        {setCharacterLimit(
                          replaceUsernameNumbers(post.teaser.user.username)
                        )}
                      </span>
                      <span className="bullet-wrapper">
                        <i className="fas fa-circle bullet" />
                      </span>

                      <span className="date">
                        {moment(post.teaser.timestampiso).fromNow(true)}
                      </span>

                      <span className="bullet-wrapper">
                        <i className="fas fa-circle bullet" />
                      </span>
                      <span
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: unescape(post.title),
                        }}
                        data-testid={`latestPost-${post?.title}`}
                      ></span>
                    </h6>
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: setCharacterLimit(
                          unescape(post.teaser.content)
                        ),
                      }}
                    ></p>
                  </ContentWrapper>
                </ListBlock>
              </>
            ) : (
              <></>
            )}
          </>
        )
      })}
    </>
  )
}
