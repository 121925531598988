import React from 'react'
import { Link } from 'react-router-dom'
import DashboardMessagesConversationWindow from '../dashboard-messages-conversation-window'
import { useIntl } from 'react-intl'
import ConnectButton from '_components/buttons-connections/connect-button'
import styled from '@emotion/styled'
import { useColorBucket } from '_colorBus/hooks'
import border from '_utils/css-border'

const MiniCard = styled(Link)`
  ${border(0.3, 8, 2)}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  margin: 25px 0;
  background-color: var(--background);
  overflow: hidden;
  min-width: 306px;
`
const AvatarWrapper = styled.div`
  ${border(0.3, 8, 2)}
  width: 164px;
  height: 164px;
  border-radius: 100%;
  margin: 25px auto 15px;
  img {
    width: 164px;
    height: 164px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }
`
const Name = styled.div`
  font-weight: 500;
  font-size: 22px;
  max-width: 80%;
  text-align: center;
  margin: 0 auto 25px;
  color: var(--foreground);
  word-break: break-word;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20px 34px;
`

const Container = styled.div`
  flex-wrap: wrap;
  display: flex;
  padding: 50px;
  justify-content: space-around;
  flex-wrap: wrap;
  height: calc(100% - 174px);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--flavor);
  }
`
const Heading = styled.h3`
  background-color: var(--cardColor);
  padding: 56px 28px 25px 58px;
`

const TopWrapper = styled.div``

const Index = ({
  activeIndex,
  handleSendMessage,
  message,
  setMessage,
  currentChatRoom,
  setActiveChannel,
  isMobileView,
  user,
}) => {
  const intl = useIntl()
  const [colorBucket] = useColorBucket('tile')
  const slicedUserConnections = user?.profile?.connectedUsers?.slice(0, 6)
  return (
    <>
      {currentChatRoom ? (
        <DashboardMessagesConversationWindow
          currentChatRoom={currentChatRoom}
        />
      ) : !currentChatRoom && !isMobileView ? (
        <>
          <Heading>
            {intl.formatMessage({ id: 'user.messages.heading' })}
          </Heading>
          <Container ref={colorBucket}>
            {slicedUserConnections?.map((user) => {
              return (
                <MiniCard to={`/people/list/${user.sub}`}>
                  <TopWrapper>
                    <AvatarWrapper>
                      <img src={`${user.avatar}`} alt="user avatar" />
                    </AvatarWrapper>
                    <Name>{`${user.firstName} ${user.lastName}`}</Name>
                  </TopWrapper>
                  <ButtonsWrapper>
                    <ConnectButton
                      sub={user.sub}
                      status={'accepted'}
                      type={'people'}
                    />
                  </ButtonsWrapper>
                </MiniCard>
              )
            })}
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Index
