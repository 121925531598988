import styled from '@emotion/styled'

export const SectionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.muted};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 36px;
  margin-bottom: 15px;
  font-weight: 700;
`

export default SectionTitle
