export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Core',
    },
    {
      title: 'Dashboard',
      key: 'dashboards',
      icon: 'fe fe-home',
      // roles: ['admin'], // set user roles with access to this route
      url: '/',
    },
    {
      title: 'Configuration',
      key: 'configuration',
      icon: 'fe fe-settings',
      // roles: ['admin'], // set user roles with access to this route
      url: '/',
    },
    {
      title: 'Manage Policies',
      key: 'manage-policies',
      icon: 'fe fe-git-branch',
      // roles: ['admin'], // set user roles with access to this route
      url: '/',
    },
    {
      title: 'Activity Monitor',
      key: 'activity-monitor',
      icon: 'fe fe-activity',
      // roles: ['admin'], // set user roles with access to this route
      url: '/',
    },
    {
      title: 'Console',
      key: 'console',
      icon: 'fe fe-terminal',
      // roles: ['admin'], // set user roles with access to this route
      url: '/',
    },
    {
      category: true,
      title: 'Support',
    },
    {
      title: 'Contact Us',
      key: 'contact-us',
      icon: 'fe fe-phone-call',
      url: '/',
    },
  ]
}
