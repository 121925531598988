import styled from '@emotion/styled'

const Content = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  padding: 0;
  margin: 0;
  li {
    max-width: 100%;
    background-color: transparent;
    border: 1px solid var(--shade);
    border-radius: 15px;
    list-style: none;
    border: 1px solid green;
    margin-bottom: 30px;

    &:last-of-type {
      border: 2px dashed var(--shade);
      margin-bottom: 0;
    }
  }
`
export default Content
