import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useMutation, gql } from '@apollo/client'
import BasicInput from './styles/basic-input-styles'
import { useClickOutside } from 'components/indy/shared/ui-kit/helpers/click-outside'
import { sendNotification } from './message-helper'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import border from '_utils/css-border'
import { pablet } from '_utils/responsive-queries'

const resolveRecommendType = (queryKey) => {
  switch (queryKey) {
    case 'eventToRecommend':
      return 'p2e-recommend'
    case 'activityToRecommend':
      return 'p2a-recommend'
    case 'opportunityToRecommend':
      return 'p2o-recommend'
    case 'blogToRecommend':
      return 'p2b-recommend'
    case 'companyToRecommend':
      return 'p2c-recommend'
    case 'userToRecommend':
      return 'p2p-recommend'
    default:
      return 'p2d-recommend'
  }
}

const RECOMMEND_DOCUMENT_MUTATION = gql`
  mutation RecommendDocumentToUser(
    $documentToRecommend: String!
    $recipient: String!
    $recommendType: String!
    $isPrivate: Boolean
  ) {
    recommendDocumentToUser(
      documentToRecommend: $documentToRecommend
      recipient: $recipient
      recommendType: $recommendType
      isPrivate: $isPrivate
    ) {
      sub
    }
  }
`

const GET_USERS_CONNECTIONS = gql`
  query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
    getUserConnectionsPublic(ids: $ids, limit: $limit) {
      forSub
      acceptedUserConnections {
        status
        owner
        connectedUser {
          name
          sub
          avatar
        }
      }
    }
  }
`

const SingleResult = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }
`
const ResultWrapper = styled.div`
  padding: 10px 15px 15px;
`
const InputStyled = styled(BasicInput)`
  ${border()}
  height: 42px;
  padding: 0 20px;
`
const ItemWrapper = styled.div`
  &.inner-suggestions-container {
    background-color: var(--background);
    width: 20vw;
    ${pablet} {
      width: 100%;
    }
  }
`
const RecommendBtn = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }

  display: ${({ isInputShown }) => !isInputShown && 'none'};
`

const RecommendButton = ({
  isInputShown,
  setIsInputShown,
  contentToRecomment,
  queryKey = 'userToRecommend',
  isPrivate,
}) => {
  const intl = useIntl()

  // const [showInput, setShowInput] = useState(false)
  const [query, setQuery] = useState('')
  const { user } = useSelector((state) => state)
  const [people, setPeople] = useState([])

  const { data } = useQuery(GET_USERS_CONNECTIONS, {
    variables: {
      ids: [user.sub],
    },
  })

  const { acceptedUserConnections: connectedUsers } = data
    ?.getUserConnectionsPublic[0]
    ? data?.getUserConnectionsPublic[0]
    : ''

  const [recommend, { loading }] = useMutation(RECOMMEND_DOCUMENT_MUTATION)

  const addButton = intl.formatMessage({
    id: 'default.add.button',
  })
  const searchPlaceholder = intl.formatMessage({
    id: 'search.recommend.placeholder',
  })

  useEffect(() => {
    setPeople(
      connectedUsers?.filter(
        (u) =>
          query?.length > 0 &&
          `${u?.connectedUser[0]?.name}`
            ?.toLowerCase()
            ?.indexOf(query?.toLowerCase()) !== -1
      )
    )
  }, [connectedUsers, query, setPeople])

  const onClose = () => {
    if (isInputShown) setIsInputShown(false)
  }

  const onRecommend = (person) => {
    const { sub, name } = person?.connectedUser[0]
    const recommendType = resolveRecommendType(queryKey)

    recommend({
      variables: {
        documentToRecommend: contentToRecomment,
        recipient: sub,
        recommendType: recommendType,
        isPrivate,
      },
    })
    setIsInputShown(false)
    sendNotification({
      category: 'activity',
      type: 'recommendation',
      intl,
      name,
    })
  }

  const ref = useClickOutside(onClose)

  if (isInputShown) {
    return (
      <ItemWrapper className="inner-suggestions-container">
        <div className="input-container" ref={ref}>
          <InputStyled
            className="input-field"
            placeholder={searchPlaceholder}
            type="text"
            onKeyUp={(e) => setQuery(e.target.value)}
            onBlur={() => people?.length === 0 && setIsInputShown(false)}
            autoFocus
          />
          {people?.length > 0 && (
            <ResultWrapper>
              {people?.map((p) => {
                return (
                  <SingleResult>
                    <span className="name">{p?.connectedUser[0]?.name}</span>
                    <button
                      className="add-button"
                      type="button"
                      disabled={loading}
                      onClick={() => onRecommend(p)}
                    >
                      {addButton}
                    </button>
                  </SingleResult>
                )
              })}
            </ResultWrapper>
          )}
        </div>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper className="recommend-button-wrapper">
      <RecommendBtn
        isInputShown={isInputShown}
        onClick={() => setIsInputShown(true)}
      >
        <i class="far fa-star" />
        Recommend
      </RecommendBtn>
    </ItemWrapper>
  )
}

export default RecommendButton
