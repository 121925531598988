import React from 'react'
import {
  MiniCardStyled,
  Avatar,
  Name,
  Location,
  ConnectButtonContainer,
} from './styled/company-mini-card'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon-black.svg'
import { useTheme } from 'emotion-theming'
import { useColorBucket } from '_colorBus/hooks'

const CompanyMiniCard = ({ children, company, connectButton, isOwner }) => {
  const { location, name, coverPhoto } = company || {}
  const theme = useTheme()

  const checkValue = (value) => {
    return value && value !== 'null' && value !== 'undefined' ? value : ''
  }

  const city = location ? checkValue(location.city) : null
  const country = location ? checkValue(location.country) : null

  const [colorBucket] = useColorBucket('tile')

  const renderLocation = () =>
    city ? `${city}${country && country !== '' ? ', ' : ''}${country}` : country

  return (
    <MiniCardStyled ref={colorBucket} data-testid="detailCard-miniCard">
      <Avatar>
        <img src={coverPhoto || theme?.images?.defaultCover} alt="" />
      </Avatar>
      <Name>{name}</Name>
      {city || country ? (
        <Location>
          <LocationIcon />
          {renderLocation()}
        </Location>
      ) : null}
      <ConnectButtonContainer>{connectButton}</ConnectButtonContainer>
    </MiniCardStyled>
  )
}

export default CompanyMiniCard
