import React from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'

const Header = styled.span`
  padding: 15px 10px 25px;
  font-weight: 600;
  font-size: 18px;
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ItemsWrapper = styled.div`
  display: flex;
  gap: 35px;
`

const Item = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.4s;
  &:focus,
  &:active {
    color: var(--foreground);
  }
  &:hover {
    opacity: 0.6;
  }

  i {
    font-size: 22px;
  }

  span {
    text-decoration: underline;
  }
`

const socialMedia = [
  { name: 'LinkedIn', key: 'linkedIn' },
  { name: 'Twitter', key: 'twitter' },
  { name: 'Facebook', key: 'facebook' },
  { name: 'Instagram', key: 'instagram' },
]

const filterSocialMedia = (user) => {
  const userSocialMedia = socialMedia.filter(
    (item) => user[item.key] && user[item.key] !== ''
  )
  return userSocialMedia
}

const SocialMedia = ({ person }) => {
  const intl = useIntl()
  const userSocialMedia = filterSocialMedia(person)

  if (!person) return <></>
  return (
    <>
      {userSocialMedia.length > 0 && (
        <SocialWrapper>
          <Header>
            {intl.formatMessage({ id: 'user.detailview.socialmedia.header' })}
          </Header>
          <ItemsWrapper>
            {userSocialMedia.map((item) => {
              return (
                <Item href={person[item.key]} target="_blank">
                  <i className={`fab fa-${item.key.toLowerCase()}`} />
                  <span className="name">{item.name}</span>
                </Item>
              )
            })}
          </ItemsWrapper>
        </SocialWrapper>
      )}
    </>
  )
}

export default SocialMedia
