import React from 'react'
import PublicDetailsCard from '_public-details-card'

import {
  CONNECTED_COMPANIES,
  CONNECTED_USERS,
  CONNECTED_ACTIVITIES,
} from './graph-queries'

const Index = ({ entityId, currentTab, page }) => {
  const connections = {
    company: {
      gqlQuery: CONNECTED_COMPANIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getPersonConnectedCompaniesPaged',
      translationKey: 'companies.titleplural',
    },
    people: {
      gqlQuery: CONNECTED_USERS,
      gqlParams: { id: entityId },
      gqlQueryName: 'getPersonConnectedUsersPaged',
      translationKey: 'people.titleplural',
    },
    activity: {
      gqlQuery: CONNECTED_ACTIVITIES,
      gqlParams: { id: entityId },
      gqlQueryName: 'getPersonConnectedActivitiesPaged',
      translationKey: 'activities.titleplural',
    },
  }

  return (
    <PublicDetailsCard.ConnectionsPaged
      currentTab={currentTab}
      connections={connections}
      page={page}
    />
  )
}

export default Index
