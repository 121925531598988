import React from 'react'
import {
  DefaultTileStyled,
  Content,
  Title,
  DiscoverTitle,
  ConnectButtonContainer,
  AvatarWrapper,
  Location,
  Avatar,
  TitleWrapper,
  Icon,
  Description,
} from './styled/default'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useUserAccess } from '_security/helpers'
import { useUser } from '_security/session-hooks'
import { useTheme } from 'emotion-theming'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon-black.svg'
import useDimensions from 'react-cool-dimensions'
import { useIsDiscoverPage } from '_application/is-discover-page'

const DefaultTile = ({ children, company, connectButton, isOwner }) => {
  const { location, coverPhoto, description, excerpt } = company || {}
  const user = useUser()
  const hasUserAccess = useUserAccess()
  const theme = useTheme()
  const isDiscoverPage = useIsDiscoverPage()

  const checkValue = (value) => {
    return value && value !== 'null' && value !== 'undefined' ? value : ''
  }

  const city = location ? checkValue(location.city) : null
  const country = location ? checkValue(location.country) : null

  const renderLocation = () =>
    city ? `${city}${country && country !== '' ? ', ' : ''}${country}` : country

  const isLogged = user?.profile?.role

  const { observe, currentBreakpoint } = useDimensions({
    breakpoints: { XS: 0, SM: 330 },
  })

  const _renderTitle = () => (
    <>
      {isDiscoverPage ? (
        <DiscoverTitle>{company.name ||company.title}</DiscoverTitle>
      ) : (
        <Title>{company.name || company.title}</Title>
      )}
    </>
  )

  return (
    <DefaultTileStyled containerQuery={currentBreakpoint} ref={observe}>
      <AvatarWrapper>
        <Avatar>
          <img src={`${coverPhoto || theme?.images?.defaultCover}`} alt="" />
        </Avatar>
      </AvatarWrapper>
      <Content>
        <TitleWrapper>
          {_renderTitle()}
          {city || country ? (
            <Location>
              <Icon>
                <LocationIcon />
              </Icon>
              <span className='location-name'>{renderLocation()}</span>
            </Location>
          ) : null}
          <Description>{description || excerpt}</Description>
        </TitleWrapper>
        <ConnectButtonContainer>
          {connectButton && (
            <ConnectButton
              status={
                isLogged && hasUserAccess ? company.connectionStatus : null
              }
              onConnect={
                isLogged && hasUserAccess ? connectButton?.onConnect : () => {}
              }
              disabled={connectButton?.disabled}
              type="companies"
            />
          )}
        </ConnectButtonContainer>
      </Content>
    </DefaultTileStyled>
  )
}

export default DefaultTile
