import styled from '@emotion/styled'
import border from '_utils/css-border'

export const ProductStyled = styled.div``

export const ProductList = styled.div`
  .subscription-item {
    margin: 10px 0;
  }
  .equal {
    margin-top: 25px;
  }
`

export const TopContentWrapper = styled.div``

export const ProductItem = styled.div`
  ${border()}
  background-color: ${({ isProductBought }) =>
    isProductBought && 'rgba(235, 255, 238, 0.97)'};
  border-radius: 10px;
  padding: 0 20px 25px 20px;
  text-align: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

export const Description = styled.div`
  font-size: 14px;
  padding: 10px 20px 15px 20px;
`
export const Prices = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  height: 31.5px;
`

export const Name = styled.span`
  font-size: 16px;
  font-weight: 600;
`
export const CancelButton = styled.button`
  padding: 5px 20px;
  border-radius: 50px;
  border: 2px solid var(--foreground);
  background-color: transparent;
  font-weight: 700;
`
export const SelectButton = styled.button`
  padding: 5px 20px;
  border-radius: 50px;
  border: 2px solid var(--foreground);
  background-color: transparent;
  font-weight: 700;
`
export const ImageWrapper = styled.div`
  ${border()}
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto 15px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--background);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  i {
    font-size: 35px;
    align-self: center;
  }
`
export const ActiveIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  margin-top: 8px;
`
export const CircleBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #15ac20;
  font-size: 12px;
  i {
    font-size: 8px;
    color: var(--background);
  }
`
