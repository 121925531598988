import React, { useState } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'
import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client'
import styled from '@emotion/styled'
import Loading from '_components/loading'
import Error from '_components/error'
import { nextPage, mergePage } from '_utils/graph-pagination'
import CompaniesGrid from '../components/companies-grid'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'
import { parseSanitySort, SANITY_ORDERINGS } from '_utils/sort'
import { getPageSizeForScreenSize } from '_tiles/helpers'
import { CONNECT_MUTATION } from './company-details-connected'

import { ctaButtonHollow } from '_utils/css-buttons'
import { sendNotification } from '_components/buttons-connections/message-helper'

const GET_COMPANIES = gql`
  query GetAllCompaniesList(
    $filter: CompanyFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllCompaniesPublic(filter: $filter, paging: $paging, ordering: $ordering)
    @connection(key: "GetAllCompaniesList", filter: ["filter", "ordering"]) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        isDraft
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
        creatorId
      }
    }
  }
`

const LoadMoreContainer = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  margin-top: 100px;

  .row {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 0px;
  }

  .button-col {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
  .separator-col {
    hr {
      border: 1px solid var(--shade);
    }
  }
`

const LoadMoreButton = styled.button`
  i {
    margin-left: 10px;
  }
  font-weight: 600;
  ${ctaButtonHollow}
`

const updateQuery = mergePage('getAllCompaniesPublic')

const CompaniesGridConnected = ({ filter, history }) => {
  const intl = useIntl()
  let ordering = undefined
  const detailedFilter = filter
  if (filter) {
    const sort = SANITY_ORDERINGS.find(
      (sort) => sort === Object.keys(filter)[0]
    )
    if (sort) {
      ordering = parseSanitySort(sort)
      filter = undefined
    }
  }

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(GET_COMPANIES, {
    variables: {
      filter,
      ordering,
      paging: {
        limit: getPageSizeForScreenSize({ pageType: 'grid', type: 'company' }),
        offset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [connectId, setConnectId] = useState()

  const [connect, { loading: connecting }] = useMutation(CONNECT_MUTATION)
  const user = useUser()

  if (history.location?.state?.refresh) {
    history.push({ state: undefined })
    setTimeout(refetch, 0)
  }
  const isRefetch = networkStatus === NetworkStatus.refetch
  if (!data || loading || isRefetch) return <Loading />
  if (error) return <Error />

  const { data: companies, hasMore } = data?.getAllCompaniesPublic

  return (
    <div>
      {data && (
        <CompaniesGrid
          filter={detailedFilter}
          companies={companies.map((c) => {
            const creatorId = c?.creator?.sub
            return {
              company: c,
              isOwner: creatorId === user?.sub,
              connectButton: {
                disabled: connecting && connectId === c.id,
                onConnect: () => {
                  setConnectId(c.id)
                  connect({
                    variables: { id: c.id, creatorId: c?.creatorId || c?.creator?.sub  },
                    refetchQueries: [
                      {
                        query: GET_COMPANIES,
                        variables: {
                          ...nextPage(0, companies.length),
                          filter,
                        },
                      },
                    ],
                    awaitRefetchQueries: true,
                  }).then((res) => {
                        const { status } = res?.data?.connectToCompany
                        sendNotification({
                          type: 'connection',
                          intl,
                          defaultHeader: 'Connection',
                          defaultContent: `You have ${
                            status === 'cancelled'
                              ? 'cancelled your connection'
                              : 'sent a connection request'
                          }`,
                        })
                      })
                      .catch((err) => console.log('error', err))
                  }
              },
            }
          })}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <FetchMore
          hasMore={hasMore}
          loader={fetchMore}
          filter={filter}
          offset={companies.length}
        />
      )}
    </div>
  )
}
CompaniesGridConnected.propTypes = {
  filter: PropTypes.object,
}

const FetchMore = ({ hasMore, loader, filter, offset }) => {
  const intl = useIntl()
  return hasMore ? (
    <LoadMoreContainer>
      <Row>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
        <Col className="button-col">
          <LoadMoreButton
            onClick={() =>
              loader({
                variables: nextPage(
                  offset,
                  getPageSizeForScreenSize({
                    pageType: 'grid',
                    type: 'company',
                  })
                ),
                filter,
                updateQuery,
              })
            }
          >
            {intl.formatMessage({ id: 'global.loadmore.button' })}
            <i className="fas fa-chevron-down"></i>
          </LoadMoreButton>
        </Col>
        <Col className="separator-col my-auto">
          <hr />
        </Col>
      </Row>
    </LoadMoreContainer>
  ) : null
}
export default CompaniesGridConnected
