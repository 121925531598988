import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

export const GET_PLUGINS_PUBLIC = gql`
  query GetPluginsPublic {
    getPluginsPublic {
      key
      enabled
      data
    }
  }
`

export const usePluginsPublic = (key) => {
  const { data, loading, error } = useQuery(GET_PLUGINS_PUBLIC)
  const plugins = useMemo(() => data?.getPluginsPublic, [data])
  const plugin = key ? plugins?.filter((p) => p?.key === key)?.[0] : null

  return { loading, error, plugins, plugin }
}
