import React from 'react'
import { useIntl } from 'react-intl'
import { StyledAddNewBtn } from './styles/events-add-new-button'
import { useHistory } from 'react-router-dom'

// moved to components
const AddNewEventButton = () => {
  const intl = useIntl()

  const history = useHistory()

  return (
    <>
      <StyledAddNewBtn
        onClick={() => history.push(`/events/manage`)}
        data-testid="btn-addNew"
      >
        <p>
          <i className="fas fa-plus"></i>
          {intl.formatMessage({
            id: 'events.eventsgrid.header.button.addnew',
          })}
        </p>
      </StyledAddNewBtn>
    </>
  )
}

export default AddNewEventButton
