import styled from '@emotion/styled'
import { ctaButtonHollow, ctaButton } from '_utils/css-buttons'

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    label {
      font-weight: 700;
      font-size: 15px;
      color: var(--foreground);
    }

    .excerpt-content-desc {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: var(--foreground);
      margin: 12px 0;
    }
  }

  .location-content-wrapper {
    width: 100%;

    .excerpt-content {
      width: 410px;
      height: 136px;
      border: 1px solid var(--foreground);
      resize: none;
      outline: none;
      background: var(--background);
      padding: 5px;
      margin: 3px 0;
      border-radius: 5px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
      padding: 12px 16px;
    }

    .char-counter {
      align-self: flex-end;
      color: var(--foreground);
      opacity: 0.7;
    }
  }
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`

export const OptionButton = styled.button`
  ${({ isButtonActive }) => (isButtonActive ? ctaButton() : ctaButtonHollow())}
  border-color: var(--flavor);
  border-radius: 25px;
  width: auto;
`

export const LocationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .location-selector,
  .ui-kit-input {
    width: 100%;
  }
`

export const EventMapWrapper = styled.div`
  .google-maps-container {
    height: 275px;
  }
`
