import React from 'react'
import { useIntl } from 'react-intl'
import { useAppConfig } from '_application'
import {
  EventCategoriesWrapper,
  Title,
  TagsWrapper,
  SingleCategory,
} from './styles/categories'
import { Link } from 'react-router-dom'

const hasChildrenKeys = (allCategories, category, isPreview) => {
  const resolvedId = isPreview ? 'key' : 'id'

  const toCheckCategory = allCategories.find(
    (cat) => cat.id === category[resolvedId]
  )

  return toCheckCategory.subcategories
}

const EventCategories = ({ event, isPreview }) => {
  const intl = useIntl()
  const { categories: eventCategories } = event || []
  const appConfig = useAppConfig()

  const availableCategories = appConfig.eventsCategories

  const previewInlineStyles = isPreview && { pointerEvents: 'none' }

  // const selectedCategories = isPreview
  //   ? availableCategories.filter((item) =>
  //       eventCategories.some((cat) => cat.key === item.id)
  //     )
  //   : eventCategories

  // const getCategoryValue = (attr) => find(availableCategories, { id: attr.id })

  return (
    <>
      {eventCategories.length > 0 ? (
        <EventCategoriesWrapper>
          <Title>
            {intl.formatMessage({
              id: 'events.createevent.preview.categories.title',
            })}
            :
          </Title>
          <TagsWrapper>
            {eventCategories.map((category) => {
              // change getEvent query to getEventDetails query in the future will let remove isPreview check

              const allValueCategory = isPreview
                ? availableCategories.find((cat) => cat.id === category.key)
                : category

              const filteredCategory = isPreview
                ? allValueCategory.subcategories
                  ? {
                      ...allValueCategory,
                      subcategories: allValueCategory.subcategories.filter(
                        (subcat) =>
                          category.childrenKeys.some((child) => {
                            return child === subcat.id
                          })
                      ),
                    }
                  : allValueCategory
                : category

              const { subcategories, name } = filteredCategory || []

              // const selectedSubcategories = subcategories?.filter((c) =>
              //   category[isPreview ? 'childrenKeys' : 'subcategories'].some(
              //     (sc) => sc.id === c.id
              //   )
              // )

              if (subcategories && subcategories.length > 0)
                return subcategories.map((subcategory) => {
                  return (
                    <Link
                      style={previewInlineStyles}
                      to={`/events/list?category=${subcategory?.id || ''}`}
                    >
                      <SingleCategory>
                        {name} / {subcategory.name}
                      </SingleCategory>
                    </Link>
                  )
                })
              return (
                <>
                  {!hasChildrenKeys(availableCategories, category, isPreview) ||
                  subcategories.length === 0 ? (
                    <Link
                      style={previewInlineStyles}
                      to={`/events/list?category=${category?.id || ''}`}
                    >
                      <SingleCategory>{name}</SingleCategory>
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              )
            })}
          </TagsWrapper>
        </EventCategoriesWrapper>
      ) : (
        <></>
      )}
    </>
  )
}

export default EventCategories
