import { gql } from '@apollo/client'
import {
  GET_BLOG,
  GET_OPPORTUNITY,
} from '_notification-center/notifications/helpers'

export { GET_OPPORTUNITY }
export const GET_EVENT = gql`
  query GetEventDetails($id: ID!) {
    getEventDetails(id: $id) {
      id
      title
      bannerImage
      venueLocation {
        address
      }
      description
      companiesCount
      activitiesCount
      creator {
        id
        sub
        avatar
        name
      }
    }
  }
`

export const GET_ACTIVITY = gql`
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      coverPhoto
      description
      start
      end
      location
      connectionStatus
      _createdAt
      connectedCompanies {
        id
        coverPhoto
        name
        location
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      coverPhoto
      bannerImage
      location
      description
      connectionStatus
      connectedUsers {
        sub
        id
        avatar
      }
      connectedCompanies {
        id
        coverPhoto
      }
      connectedActivities {
        id
        coverPhoto
      }
      creator {
        id
        sub
        avatar
        firstName
        middleName
        lastName
      }
    }
  }
`

export const GET_PERSON = gql`
  query GetUserInfo($id: ID!) {
    getUser(id: $id) {
      id
      sub
      firstName
      lastName
      middleName
      fullName
      email
      birthDate
      gender
      avatar
      coverPhoto
      bioShort
      bioLong
      instagram
      linkedIn
      twitter
      languagesSpoken
      languagesWritten
      phone
      role
      location
      lastJobPosition {
        companyName
        title
      }
      competences {
        value
        type
      }
      experience {
        companyName
        title
        startDate
        endDate
        location
        description
        websiteUrl
      }
      education {
        schoolName
        degree
        domain
        yearGraduated
        websiteUrl
      }
      customAttributes {
        key
        value
        type
      }
      keywords {
        key
        values {
          type
          value
        }
      }
      taxonomies {
        key
        values
      }
    }
  }
`

export const resolveSubtype = ({ subtype }) => {
  switch (subtype) {
    case 'p2a-recommend':
      return {
        key: 'activities',
        gqlQuery: GET_ACTIVITY,
        gqlQueryName: 'getActivity',
      }
    case 'p2c-recommend':
      return {
        key: 'companies',
        gqlQuery: GET_COMPANY,
        gqlQueryName: 'getCompany',
      }
    case 'p2p-recommend':
      return {
        key: 'people',
        gqlQuery: GET_PERSON,
        gqlQueryName: 'getUser',
      }

    case 'p2o-recommend':
      return {
        key: 'opportunities',
        gqlQuery: GET_OPPORTUNITY,
        gqlQueryName: 'getOpportunity',
      }

    case 'p2b-recommend':
      return {
        key: 'blog',
        gqlQuery: GET_BLOG,
        gqlQueryName: 'getBlogPost',
      }
    case 'p2e-recommend':
      return {
        key: 'events',
        gqlQuery: GET_EVENT,
        gqlQueryName: 'getEventDetails',
      }
    default:
      return ''
  }
}

export const GET_ACTIVITIES = gql`
  query GetAllActivitiesList(
    $filter: ActivityFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllActivitiesPublic(
      filter: $filter
      paging: $paging
      ordering: $ordering
    ) @connection(key: "GetAllActivitiesList", filter: ["filter", "ordering"]) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        location
        connectionStatus
        _createdAt
        connectedCompanies {
          id
          coverPhoto
          name
          location
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query GetAllCompaniesList(
    $filter: CompanyFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllCompaniesPublic(filter: $filter, paging: $paging, ordering: $ordering)
    @connection(key: "GetAllCompaniesList", filter: ["filter", "ordering"]) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        bannerImage
        location
        description
        connectionStatus
        connectedUsers {
          sub
          id
          avatar
        }
        connectedCompanies {
          id
          coverPhoto
        }
        connectedActivities {
          id
          coverPhoto
        }
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const GET_PEOPLE = gql`
  query GetDiscoverUsers($paging: PagingInput) {
    getAllUsersPublic(paging: $paging) @connection(key: "GetDiscoverUsers") {
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`

export const GET_OPPORTUNITIES = gql`
  query GetAllOpportunitiesList(
    $filter: OpportunityFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllOpportunitiesPublic(
      filter: $filter
      paging: $paging
      ordering: $ordering
    )
    @connection(
      key: "GetAllOpportunitiesList"
      filter: ["filter", "ordering"]
    ) {
      count
      offset
      hasMore
      data {
        id
        name
        coverPhoto
        description
        start
        end
        expire
        location
        _createdAt
        creator {
          id
          sub
          avatar
          firstName
          middleName
          lastName
        }
      }
    }
  }
`

export const CONNECT_COMPANY_MUTATION = gql`
  mutation ConnectCompany($id: String!, $creatorId: String!) {
    connectToCompany(id: $id, creatorId: $creatorId) {
      status
    }
  }
`

export const CONNECT_ACTIVITY_MUTATION = gql`
  mutation ConnectActivity($id: String!, $creatorId: String!) {
    connectToActivity(id: $id, creatorId: $creatorId) {
      status
    }
  }
`

export const CONNECT_USER_MUTATION = gql`
  mutation ConnectUser($sub: String!) {
    connectToUser(sub: $sub) {
      status
    }
  }
`

// export const GET_USERS_CONNECTIONS = gql`
//   query GetUserConnectionsPublic($ids: [ID]!, $limit: Int) {
//     getUserConnectionsPublic(ids: $ids, limit: $limit) {
//       forSub
//       connectionStatus
//       connectedCompaniesCount
//       createdCompaniesCount
//       createdActivitiesCount
//       connectedUsersCount
//       connectedActivitiesCount
//     }
//   }
// `

export const GET_USERS = gql`
  query GetAllUsersList(
    $filter: UserFilterInput
    $paging: PagingInput
    $ordering: [OrderingInput]
  ) {
    getAllUsersPublic(filter: $filter, paging: $paging, ordering: $ordering)
    @connection(key: "GetAllUsersList", filter: ["filter", "ordering"]) {
      offset
      hasMore
      data {
        id
        sub
        firstName
        middleName
        lastName
        bioShort
        coverPhoto
        avatar
        location
        connectionStatus
        lastJobPosition {
          companyName
          title
        }
      }
    }
  }
`
