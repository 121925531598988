import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex } from '@patternfly/react-core'
import border from '_utils/css-border'

export const Item = styled.div`
  position: relative;
  color: var(--foreground);
  transition: 0.3s ease-out;

  padding: 5px 5px;
  transition: display 0;
  &::after {
    content: '';
    position: absolute;
    display: ${({ isMobileView }) => (isMobileView ? 'none' : 'block')};
    left: ${({ isMobileView }) => (isMobileView ? '0' : '50%')};
    bottom: 0;
    background: var(--flavor);
    height: 2px;
    width: 0%;
    transition: 0.4s ease-in-out;
  }
  &::before {
    content: '';
    display: ${({ isMobileView }) => (isMobileView ? 'none' : 'block')};
    position: absolute;
    right: ${({ isMobileView }) => (isMobileView ? '0' : '50%')};
    bottom: 0;
    background: var(--flavor);
    height: 2px;
    width: 0%;
    transition: 0.4s ease-in-out;
  }
  &:hover::after {
    width: 50%;
  }
  &:hover::before {
    width: 50%;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:after,
      &:before {
        width: 50%;
      }
    `}
`

export const ActiveOption = styled.div`
  transform: translateZ(1px);
  cursor: pointer;
  display: flex;
  background-color: var(--background);
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  min-height: 54px;
  padding: 10px 15px;
  ${border()}
  border-radius: 10px;
  i {
    transition: 0.4s;
    transform: ${({ isDropdownOpen }) => isDropdownOpen && 'rotate(180deg)'};
  }
`

export const OptionsWrapper = styled.div`
  background-color: var(--background);
  transition: max-height 300ms, padding 300ms;
  max-height: ${({ isDropdownOpen }) => (isDropdownOpen ? '200vh' : '0px')};
  padding: ${({ isDropdownOpen }) => (isDropdownOpen ? '25px 0 0' : '0')};

  .menu-item-link {
    &:last-child {
      ${Item} {
        padding-bottom: 50px;
      }
    }
  }
`

export const MobileMenuWrapper = styled.div`
  position: absolute;
  left: 25px;
  right: 25px;
  z-index: 1;
  visibility: ${({ isMobileView }) => (isMobileView ? 'visible' : 'hidden')};
  transform: ${({ isMobileView }) =>
    !isMobileView ? 'translateX(-140%)' : 'translateX(0)'};
  background-color: var(--backgroud);
  overflow: hidden;
  ${border()}
  border-radius: 10px;
  transition: 0.7s ease-in-out;

  ${Item} {
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 500;
    height: 44px;
    background-color: var(--background);
    &::after,
    &::before {
      display: none;
    }
  }
`

export const DesktopMenuWrapper = styled(Flex)`
  z-index: 0;
  max-width: 1570px;
  min-height: 54px;
  margin: 0 auto;
  font-size: ${({ isMobileView }) => (!isMobileView ? '20px' : '0px')};
  font-weight: 500;
  transition: 0.6s;
  transition-delay: 200ms;

  .menu-item-link:first-child {
    .menu-item {
      font-size: ${({ isMobileView }) => (!isMobileView ? '36px' : '0px')};
      font-weight: 700;
      padding: 0 5px;
    }
  }
`
