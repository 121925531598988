import { UPDATE_ENTITIES_ACCESS } from './graph-queries'
import { useMutation } from '@apollo/client'
import { GET_CONFIG } from '_application'
import { useGetEntityAccess } from '_security/entity-access'

export const useEntityAccess = () => {
  return [useSetEntityAccess, useGetEntityAccess]
}

const useSetEntityAccess = () => {
  const [updateEntitiesAccess] = useMutation(UPDATE_ENTITIES_ACCESS)

  const doUpdateEntityAccess = ({ name, detailsRequiresAuth }) => {
    updateEntitiesAccess({
      variables: { entitiesAccess: { name, detailsRequiresAuth } },
      refetchQueries: [{ query: GET_CONFIG }],
      awaitRefetchQueries: true,
    })
  }
  return { doUpdateEntityAccess }
}
