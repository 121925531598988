import { useMemo } from 'react'
import { useAppConfig } from '_application'
import { useUser } from '_security/session-hooks'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

export const useUserAccess = () => {
  const user = useUser()

  const isLogged = user?.profile?.role
  if (isLogged) {
    return true
  }
  return false
}

export const useUserBlogAccess = (isPremium) => {
  const user = useUser()
  const config = useAppConfig()
  const { data } = useQuery(HAS_USER_SUBSCRIPTION)
  const hasUserPremiumSubscription = data?.hasUserPremiumBlogSubscription
  const isAdmin = user?.profile?.role === 'admin'
  const isLoggedIn = user?.profile?.role
  const {
    isBlogLoggedInAccessEnabled,
    isBlogSubscriptionAccessEnabled,
    entitiesAccess,
  } = config

  const blogEntityAccess = entitiesAccess?.find(
    (entity) => entity.name === 'BLOG'
  )

  let blogRequiresLogin = false

  if (blogEntityAccess) {
    blogRequiresLogin = blogEntityAccess?.detailsRequiresAuth
  } else {
    // isBlogLoggedInAccessEnabled was depreciated May 2022, but should be respected if detailsRequiresAuth is not set
    blogRequiresLogin = isBlogLoggedInAccessEnabled
  }

  if (!isAdmin && blogRequiresLogin && !isLoggedIn) {
    return false
  }

  if (
    !isAdmin &&
    isPremium &&
    !hasUserPremiumSubscription &&
    isBlogSubscriptionAccessEnabled
  ) {
    return false
  }

  return true
}

export const useGetSearchUrl = ({ field, value }) => {
  const { search: current } = useLocation()
  const query = useMemo(() => qs.parse(current), [current])
  const search = useMemo(
    () => qs.stringify({ ...query, [field]: value }),
    [query, field, value]
  )
  return `/search?${search}`
}

export const HAS_USER_SUBSCRIPTION = gql`
  query hasUserPremiumBlogSubscription {
    hasUserPremiumBlogSubscription
  }
`
