import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { InfoCard } from '_components/InfoCard'

import ItemList, { AddNewItem, NoContent } from '../shared/items-list'
import { MainWrapper, Section } from '../styles'
import AddEditModal from './shared/add-edit-modal'
import RemoveButton from './shared/remove-button'

import styled from '@emotion/styled'

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isOpen }) => isOpen && '35px'};
`

const ButtonsWrapper = styled.div`
  display: flex;
`

const ShowHideButton = styled.button`
  background: var(--shade);
  border: none;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  i {
    color: var(--foreground);
    font-size: 25px;
  }
`

const Title = styled.h4`
  font-size: 22px;
  display: inline-block;
  font-weight: 400;
`

const fields = [
  { key: 'companyName', name: 'Company name', type: 'text', required: true },
  { key: 'title', name: 'Title', type: 'text', required: true },
  {
    key: 'startDate',
    name: 'Start date',
    type: 'date',
    required: true,
    minDate: moment().subtract(70, 'years'),
    maxDate: moment().add(2, 'years'),
  },
  {
    key: 'endDate',
    name: 'End date',
    type: 'date',
    optional: {
      dateButton: 'iscurrentposition',
      noDateButton: 'selectdate',
      noDateTitle: 'currentposition',
    },
    minDate: moment().subtract(70, 'years'),
    maxDate: moment().add(2, 'years'),
  },
  { key: 'location', name: 'Location', type: 'location' },
  { key: 'description', name: 'Description', type: 'text' },
  {
    key: 'websiteUrl',
    name: 'Website url',
    type: 'url',
    placeholder: 'https://...',
  },
  {
    key: 'showOnMinature',
    name: 'Show on minature card',
    type: 'boolean',
  },
]

const mapStateToProps = ({ user }) => ({ user })

const Experience = ({ user }) => {
  const [currentExperience, setCurrentExperience] = useState()
  const [addNewExperience, setAddNewExperience] = useState()
  const [deleteExperience, setDeleteExperience] = useState()
  const [isOpen, setIsOpen] = useState(true)
  const intl = useIntl()

  const { experience } = user?.profile

  const onClose = () => {
    setAddNewExperience(false)
    setCurrentExperience(false)
    setDeleteExperience(false)
  }

  const handleAddNewExperience = () => {
    setAddNewExperience(true)
  }

  return (
    <MainWrapper>
      <Section>
        <TopWrapper isOpen={isOpen}>
          <Title>{intl.formatMessage({ id: 'user.profile.experience' })}</Title>
          <ButtonsWrapper>
            {isOpen && (
              <AddNewItem
                onClick={() => handleAddNewExperience(true)}
                testid="experience"
              />
            )}
            <ShowHideButton onClick={() => setIsOpen(!isOpen)}>
              <i class={`fas fa-angle-${isOpen ? 'up' : 'down'}`} />
            </ShowHideButton>
          </ButtonsWrapper>
        </TopWrapper>
        {isOpen && (
          <ItemList>
            {experience && experience.length > 0 ? (
              experience.map((exp) => {
                return (
                  <InfoCard
                    start={exp.startDate}
                    end={exp.endDate}
                    url={exp.websiteUrl}
                    testid={exp.companyName}
                    icon="fas fa-briefcase"
                  >
                    <div className="dropdown">
                      <p
                        onClick={() => setCurrentExperience(exp)}
                        data-testid={`btn-experience-edit-${exp.companyName}`}
                      >
                        edit
                      </p>
                      <p
                        onClick={() => setDeleteExperience(exp)}
                        data-testid={`btn-experience-delete-${exp.companyName}`}
                      >
                        delete
                      </p>
                    </div>

                    <div className="text">
                      <h4
                        data-testid={`experience-companyName-${exp.companyName}`}
                      >
                        {exp.companyName}
                      </h4>
                      {exp.title && (
                        <p data-testid={`experience-title-${exp.companyName}`}>
                          {exp.title}
                        </p>
                      )}
                      <p data-testid={`experience-location-${exp.companyName}`}>
                        {exp?.location}
                      </p>
                    </div>

                    <div className="description">
                      <p>{exp.description}</p>
                    </div>
                  </InfoCard>
                )
              })
            ) : (
              <NoContent>
                <i className="empty-icon fas fa-briefcase"></i>
                <span className="empty-label">
                  {intl.formatMessage({
                    id: 'user.profile.education.noexperience.message',
                  })}
                </span>
              </NoContent>
            )}
            {deleteExperience && (
              <RemoveButton
                onClose={onClose}
                deleteItem={deleteExperience}
                type="experience"
              />
            )}
            {(currentExperience || addNewExperience) && (
              <AddEditModal
                onClose={onClose}
                currentItem={currentExperience}
                fields={fields}
                type="experience"
              />
            )}
          </ItemList>
        )}
      </Section>
    </MainWrapper>
  )
}

export default connect(mapStateToProps)(Experience)
