import styled from '@emotion/styled'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { H5 } from '_themes/default/components/styled/headers'

export const Container = styled.div`
  padding-top: 0px;
  padding-bottom: 45px;
`

export const Text = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
`

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
`

export const Tag = styled.div`
  color: var(--foreground);
  border-style: solid;
  border-width: 1px;
  margin-right: 7px;
  margin-bottom: 4px;
  border-color: var(--foreground);
`

export const Title = styled(H5)`
  margin-top: 10px;
  color: var(--foreground);
`

export const SeparatorRow = styled(Row)`
  margin-bottom: 15px;
`

export const SideSeparatorCol = styled(Col)`
  padding: 0px;
  &.left {
    padding-left: 10px;
  }
  &.right {
    padding-right: 10px;
  }
  .separator-line {
    position: relative;
    background-color: var(--foreground);
    width: 100%;
    height: 1px;
    margin-top: 10px;
  }
`

export const MiddleSeparatorCol = styled(Col)`
  padding: 2.5px;
  max-width: 140px;
  .connections-title {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.googleFont};
    color: var(--foreground);
    font-weight: 500;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 11px;
  }
`

export const EmptyContainer = styled.div`
  position: relative;
  margin: 0 auto;
`

export const EmptyIcon = styled.img`
  height: 15px;
  width: 15px;
  display: block;
  margin: 0 auto;
  position: relative;
`

export const EmptyTitle = styled(H5)`
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
`

export const ConnectionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  /* position: absolute;
  bottom: 0;
  left: 0; */
`

export const Connections = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 5px;
  width: 120px;
  min-height: 33px;
  font-family: ${({ theme }) => theme.fonts.googleFont};
  .avatars-wrapper {
    flex-basis: 45%;
  }
`

export const ConnectedWrapper = styled.div`
  font-weight: 500;
  flex-basis: 55%;
  margin: 0 auto;
  text-align: ${({ center }) => !center && 'center'};
`
export const ConnectedAvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`
export const ConnectedAvatar = styled.div`
  width: 20px;
  height: 33px;
  position: relative;

  &:last-child {
    width: 33px;
    /* margin-right: 10px; */
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 30px;
    width: 30px;
    height: 30px;
    max-width: 30px;
    border-radius: 50%;
    border: 2px solid white;
  }
`

export const ConnectionsCount = styled.span`
  font-family: ${({ theme }) => theme.fonts.googleFont};
  color: var(--foreground);
  font-weight: 700;
  font-size: 12px;
  float: right;
  padding-right: 5px;
`
