import styled from '@emotion/styled'

const MultiSelectStyled = styled.div`
  display: inline-block;
  width: 100%;

  .selected-items {
    padding: 0;
    margin-bottom: 15px;
    li {
      padding: 5px 10px;
      margin: 0 5px 5px 0;
      display: inline-block;
      color: var(--flavor-contrast);
      background-color: var(--flavor);
      border: none !important;
      border-radius: 6px;
      transition: 0.5s;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
        margin-left: 4px;
      }
      &:hover {
        cursor: pointer;
        background-color: #d6d6d6;
      }
    }
    &.empty {
      display: none;
    }
  }
  .full-selector {
    position: relative;
    width: 100%;
    border: solid 1px var(--foreground);
    border-radius: 6px;
    color: var(--foreground);
    button {
      background: transparent;
      padding: 10px;
      border: none;
      width: 100%;
      text-align: left !important;
      font-size: 14px;
      font-family: ${(props) => props.theme.fonts.googleFont} !important;
      color: var(--foreground) !important;
      i {
        margin-left: 8px;
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    ul {
      position: absolute;
      width: 100%;
      top: 45px;
      left: 0;
      opacity: 0;
      transition: opacity 0.24s;
      z-index: 1;
      background-color: var(--background);
      box-shadow: 0px 0px 10px #e5e5e5;
      padding: 15px;
      border-radius: 6px;
      max-height: 200px;
      overflow-y: auto;
      background: var(--background);
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        list-style: none;
        padding: 10px 16px;
        font-family: ${(props) => props.theme.fonts.googleFont} !important;
        color: var(--foreground);
        cursor: pointer;
        &:last-of-type {
          border: none;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &.active {
          color: var(--foreground);
          background: rgba(0, 0, 0, 0.05);
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
  }
  i.far {
    float: right;
  }
`

export default MultiSelectStyled
