import React from 'react'
import styled from '@emotion/styled'
import Header from 'components/indy/shared/logged/header'
import Footer from '_components/footer'

const MainContent = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--background);
  [aria-hidden^='true'].graph-tab-container {
    display: none;
  }
`

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const MainLayout = ({ children }) => {
  return (
    <MainContent>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </MainContent>
  )
}

export default MainLayout
