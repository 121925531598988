import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import {
  CategoryWrapper,
  MainContentWrapper,
  NumberOfSelections,
  CategoriesWrapper,
  CategoryName,
  CategoryOptions,
  SingleOption,
  SingleCategoryWrapper,
} from './styles/category'

const SingleCategory = ({
  parent,
  eventSelectedCategories,
  onSave,
  isFirstItem,
}) => {
  const { name, subcategories, id: parentId } = parent

  const hasSubcategories = subcategories

  const isDropdownOpen = eventSelectedCategories.some(
    (selectedCat) => selectedCat.key === parentId
  )
  const [isExpand, setIsExpand] = useState(isFirstItem || isDropdownOpen)

  const selectedCategory = eventSelectedCategories.find(
    (selectedCat) => selectedCat.key === parent.id
  )

  const handleClickCheckbox = (id) => {
    if (!hasSubcategories || subcategories.length === 0) {
      return onSave(
        selectedCategory
          ? eventSelectedCategories.filter((el) => el.key !== parentId)
          : [...eventSelectedCategories, { key: parentId, childrenKeys: [] }]
      )
    }

    if (selectedCategory) {
      const filteredCategories = eventSelectedCategories.filter(
        (cat) => cat.key !== selectedCategory.key
      )

      const filteredSubcategories = selectedCategory.childrenKeys.includes(id)
        ? selectedCategory.childrenKeys.filter((subCat) => subCat !== id)
        : [...selectedCategory?.childrenKeys, id]

      return onSave(
        subcategories.length > 0 && filteredSubcategories.length === 0
          ? filteredCategories
          : [
              ...filteredCategories,
              {
                ...selectedCategory,
                childrenKeys: filteredSubcategories,
              },
            ]
      )
    }

    return onSave([
      ...eventSelectedCategories,
      {
        key: parent.id,
        childrenKeys: eventSelectedCategories.subcategories
          ? [...eventSelectedCategories.subcategories, id]
          : [id],
      },
    ])
  }

  return (
    <SingleCategoryWrapper>
      <CategoryName
        isExpandable={hasSubcategories && subcategories.length > 0}
        isExpand={isExpand}
        onClick={() => setIsExpand(!isExpand)}
      >
        <i className="fa fa-angle-down" />
        <span className="name"> {name}</span>

        {(!hasSubcategories || subcategories?.length === 0) && (
          <input
            checked={selectedCategory || false}
            type="checkbox"
            id={`${parentId}`}
            name={`${name}`}
            onClick={() => handleClickCheckbox(parentId, false)}
          />
        )}
      </CategoryName>

      {hasSubcategories && isExpand && (
        <CategoryOptions>
          {subcategories.map((subcat, i) => {
            return (
              <SingleOption key={i}>
                <input
                  checked={
                    selectedCategory?.childrenKeys?.includes(subcat.id) || false
                  }
                  type="checkbox"
                  id={`${subcat.id}`}
                  name={`${subcat.name}`}
                  onClick={() => handleClickCheckbox(subcat.id)}
                />
                <label for={`${subcat.id}`}>{subcat.name}</label>
              </SingleOption>
            )
          })}
        </CategoryOptions>
      )}
    </SingleCategoryWrapper>
  )
}

const Category = ({ allEventsCategories, eventSelectedCategories, onSave }) => {
  const intl = useIntl()

  const firstCategoryWithSubcategories = allEventsCategories.find(
    (cat) => cat.subcategories && cat.subcategories.length > 0
  )

  const countSelected = () => {
    let numberOfSelected = 0

    eventSelectedCategories.forEach((element) => {
      const { childrenKeys } = element

      const hasSubcategoriesToSelect =
        allEventsCategories.find((cat) => cat.id === element.key)?.subcategories
          ?.length > 0

      numberOfSelected = hasSubcategoriesToSelect
        ? numberOfSelected + childrenKeys.length
        : numberOfSelected + 1
    })
    return numberOfSelected
  }

  return (
    <CategoryWrapper>
      <div className="label-wrapper">
        <label htmlFor="category-content">
          {intl.formatMessage({
            id: 'events.createevent.general.categories.title',
          })}
        </label>
        <p className="category-content-desc">
          {intl.formatMessage({
            id: 'events.createevent.general.categories.description',
          })}
        </p>
      </div>
      <MainContentWrapper>
        <CategoriesWrapper>
          {allEventsCategories.map((category, i) => {
            return (
              <SingleCategory
                key={category.id}
                parent={category}
                eventSelectedCategories={eventSelectedCategories}
                onSave={onSave}
                numberOfSelected={countSelected()}
                isFirstItem={firstCategoryWithSubcategories?.id === category.id}
              />
            )
          })}
        </CategoriesWrapper>
        <NumberOfSelections>
          {intl.formatMessage(
            { id: 'event.create.categories.number' },
            {
              number: countSelected(),
            }
          )}
        </NumberOfSelections>
      </MainContentWrapper>
    </CategoryWrapper>
  )
}

export default Category
