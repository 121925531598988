/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useState } from 'react'
import uploadImageToCdn from '../../../../services/images'
import { ImageUploadStyled, Revert } from './image-upload-styled'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import { MbToB, bToMb } from 'services/file'

const defaultMaxFileSize = MbToB(50)

const Index = ({
  type,
  onUpload,
  value,
  className,
  cdnType,
  testid,
  maxFileSize,
  imgSize,
  noRevertToDefault,
  onRevert,
}) => {
  console.log('123der', cdnType, type)
  const intl = useIntl()
  const [uploader, setUploader] = useState({ imgUrl: value })
  let imageUploadRef = null

  const fileName =
    cdnType != null
      ? cdnType.charAt(0).toUpperCase() +
        cdnType.slice(1) +
        ` ${intl
          .formatMessage({ id: 'video.upload.filename' })
          .toLocaleLowerCase()}`
      : intl.formatMessage({ id: 'video.upload.filename' })

  const handleUploadImage = () => {
    if (imageUploadRef.files[0]) {
      if (imageUploadRef.files[0].size > (maxFileSize || defaultMaxFileSize)) {
        notification.warning({
          message: intl.formatMessage({ id: 'toast.videoupload.title' }),
          description: intl.formatMessage(
            {
              id: 'toast.videoupload.upload.fail',
            },
            { name: fileName, size: maxFileSize || defaultMaxFileSize }
          ),
        })
      } else {
        uploadImageToCdn(imageUploadRef.files[0], cdnType, 'video/mp4').then(
          (d) => {
            setUploader({ ...uploader, imgUrl: d.urlNoCache })
            onUpload(d.urlNoCache)
            notification.success({
              message: intl.formatMessage({ id: 'toast.videoupload.title' }),
              description: `${intl.formatMessage(
                {
                  id: 'toast.videoupload.upload.success',
                },
                { name: fileName }
              )}`,
            })
          }
        )
      }
    }
  }

  const handleOnRevert = () => {
    setUploader({ imgUrl: null })
    onRevert()
  }

  const convertFileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      cb(reader.result)
    }
  }
  if (type === 'no-image') {
    return (
      <input
        className={type}
        accept="image/*"
        id={`upload-image-${type}`}
        ref={(ref) => {
          imageUploadRef = ref
        }}
        type="file"
        onChange={() => handleUploadImage()}
        data-testid={`videoInput-${testid ?? 'default'}`}
      />
    )
  }
  return (
    <>
      <ImageUploadStyled
        isImgUploaded={uploader.imgUrl}
        className="image-upload-container"
      >
        <div
          className={`wrapper ${type} size-${imgSize} image-placeholder ${
            uploader.imgUrl ? 'uploaded-image' : ''
          }`}
        >
          {!uploader.imgValue ? <i className="fas fa-video" /> : null}
          <label htmlFor={`upload-video-${type}`} />
          <input
            accept="video/mp4, video/x-flv, video/quicktime"
            id={`upload-video-${type}`}
            ref={(ref) => {
              imageUploadRef = ref
            }}
            type="file"
            onChange={() => handleUploadImage()}
            data-testid={`videoInput-${testid ?? 'default'}`}
          />
          {uploader.imgUrl ? (
            <video
              muted
              playsinline
              loop
              autoPlay
              width="100%"
              height="100%"
              alt=""
              className={className}
              src={uploader.imgUrl}
            />
          ) : null}
          {!noRevertToDefault && onRevert && uploader.imgUrl && (
            <Revert onClick={handleOnRevert}>
              <i class="fas fa-times" />
            </Revert>
          )}
        </div>
      </ImageUploadStyled>
    </>
  )
}

export default Index
