import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'

import { StylesBody, StylesFooter } from './styles/delete'

const Index = ({ canShowModals, setCanShowModals, removeNodeCallback }) => {
  const intl = useIntl()
  const handleClose = () => {
    setCanShowModals({ ...canShowModals, canShowDeleteModal: false })
  }

  return (
    <Modal
      dialogClassName="taxonomy-delete-modal-container"
      show={canShowModals.canShowDeleteModal}
      onHide={handleClose}
    >
      <StylesBody>
        <Modal.Body className="taxonomy-delete-modal-body">
          <div className="taxonomy-delete-info-container">
            <h2 className="taxonomy-title">
              {intl.formatMessage({
                id: 'admin.managedata.creator.modal.delete.title',
              })}
            </h2>
            <p className="taxonomy-description">
              {intl.formatMessage({
                id: 'admin.managedata.creator.modal.delete.description',
              })}
            </p>
          </div>
        </Modal.Body>
      </StylesBody>
      <StylesFooter>
        <Modal.Footer>
          <div className="delete-taxonomy-modal-buttons-container">
            <button
              onClick={() =>
                setCanShowModals({
                  ...canShowModals,
                  canShowDeleteModal: false,
                })
              }
              className="cancel-button"
              type="button"
            >
              {intl.formatMessage({
                id: 'admin.managedata.creator.modal.delete.cancelbutton',
              })}
            </button>
            <button
              onClick={() => {
                removeNodeCallback()
                setCanShowModals({
                  ...canShowModals,
                  canShowDeleteModal: false,
                })
              }}
              className="delete-button"
              type="button"
            >
              {intl.formatMessage({
                id: 'admin.managedata.creator.modal.delete.deletebutton',
              })}
            </button>
          </div>
        </Modal.Footer>
      </StylesFooter>
    </Modal>
  )
}

export default Index
