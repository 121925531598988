import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { FormattedMessage } from 'react-intl'
import { useQuery, gql } from '@apollo/client'
import Appear from '_components/appear'
import { Row, RowCenterAll } from '_components/containers'
import Button from '_components/button'
import { getDisplayName } from '_people/people-models'
import { useMeeting } from '_meet'

const Toast = styled.div`
  background-color: darkgrey;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.color1_1};
`

export const GET_USER_INFO = gql`
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      sub
      avatar
      firstName
      lastName
      middleName
      name
    }
  }
`

const Notification = ({ children }) => (
  <Appear>
    <Toast>{children}</Toast>
  </Appear>
)

const Avatar = styled.img`
  border-radius: 100%;
  border: none;
  display: block;
  height: 60px;
  width: 60px;
  margin-right: 15px;
  object-fit: cover;
`

const styles = {
  action: css`
    margin-right: 10px;
    margin-top: 20px;
  `,
}

export const InstantCall = ({ event, payload, clear }) => {
  const theme = useTheme()
  const meeting = useMeeting(payload.id)
  const { data } = useQuery(GET_USER_INFO, { variables: { id: payload.from } })
  const user = data?.getUserInfo

  return (
    <Notification>
      <Row>
        <audio src={theme.media.videoCallRingtone} autoPlay loop />
        <Avatar src={user?.avatar || theme.images.defaultAvatar} />
        <div>
          <FormattedMessage
            id="meet.incommingVideoCall"
            values={{ user: getDisplayName(user) }}
          />
          <RowCenterAll>
            <Button.Success
              css={styles.action}
              onClick={() => {
                meeting.accept()
                clear()
              }}
            >
              <FormattedMessage id="meet.accept" />
            </Button.Success>
            <Button.Danger
              css={styles.action}
              onClick={() => {
                meeting.reject()
                clear()
              }}
            >
              <FormattedMessage id="meet.reject" />
            </Button.Danger>
          </RowCenterAll>
        </div>
      </Row>
    </Notification>
  )
}
InstantCall.propTypes = {
  event: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
  }).isRequired,
}
