import styled from '@emotion/styled'

export const PluginsStyled = styled.div`
  .plugin {
    background: var(--background);
    border: 1px solid var(--foreground);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    .plugin-info {
      .status {
        font-weight: normal;
        font-size: 14px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        margin-top: 2px;
        display: block;
      }
      i {
        display: block;
        font-size: 32px;
      }
    }
    .plugin-toggle {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .plugin-configuration {
      margin-top: auto;
      button {
        margin: 0 auto;
        display: block;
        margin-top: 15px;
      }
    }
  }
`
