import React from 'react'
import NavStyled from './styles/navigation'
import styled from '@emotion/styled'

const ProgressBar = styled.div`
  background: var(--flavor);
  width: ${({ progressInPercentage }) => `${progressInPercentage}%`};
`

const ProgressBackground = styled.div`
  background: var(--shade);
`

const Navigation = ({ step }) => {
  return (
    <NavStyled>
      <div className="progress-bar-container">
        <ProgressBackground className="progress-bar">
          <ProgressBar
            progressInPercentage={step.progressInPercentage}
            className="bar"
          />
        </ProgressBackground>
      </div>
    </NavStyled>
  )
}

export default Navigation
