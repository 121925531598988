import React from 'react'
import {
  AssetsViewerStyled,
  Assets,
  Pdf,
  Image,
  ImageGalleryStyled,
} from './styled/assets-viewer'
import { useIntl } from 'react-intl'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'

const AssetViewer = ({ assets, removeCallback, renderGallery }) => {
  const intl = useIntl()

  const removeButton = (asset) => {
    return (
      removeCallback && (
        <i
          className="remove-button fas fa-times"
          onClick={() => removeCallback(asset)}
        />
      )
    )
  }

  const renderAsset = (asset) => {
    const type = asset?.url?.split('.').pop()
    if (type === 'pdf') {
      return (
        <Pdf target="_blank" href={asset?.url}>
          <i className={`fas fa-file-pdf`} />
          <span>{asset?.name}</span>
          {removeButton(asset)}
        </Pdf>
      )
    } else if (!renderGallery) {
      return (
        <Image>
          <img alt={asset?.name} src={asset?.url} />
          {removeButton(asset)}
        </Image>
      )
    }
  }

  const getGalleryItems = () => {
    return assets
      ?.map((a) =>
        a?.url?.split('.').pop() !== 'pdf'
          ? { original: a?.url, thumbnail: a?.url }
          : null
      )
      ?.filter((a) => a)
  }

  const items = getGalleryItems()

  const hasAnyAssets = assets?.length > 0

  return (
    <AssetsViewerStyled>
      {hasAnyAssets && (
        <>
          <h4 className="files-heading">
            {intl.formatMessage({ id: 'application.management.files' })}
          </h4>
          {<Assets>{assets.map((a) => renderAsset(a))}</Assets>}
        </>
      )}
      {renderGallery && items?.length > 0 && (
        <ImageGalleryStyled>
          <ImageGallery
            showPlayButton={false}
            items={items}
            thumbnailPosition="top"
          />
        </ImageGalleryStyled>
      )}
    </AssetsViewerStyled>
  )
}

export default AssetViewer
