import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import React, { useState, useEffect } from 'react'

export const InputStyled = styled.input`
  background: var(--background);
  color: var(--foreground);
  width: 100%;
  max-width: 610px;

  border-radius: var(--radius);
  padding: 5px 12px 5px 12px;
  outline: var(--border) !important;

  border: none;
  transition: .1s;

  &:invalid {
    //todo: add proper URL validation pattern
    //outline: solid 1px var(--error, red) !important;
  }

  &::placeholder {
    color: var(--shade);
  }

  &:focus {
    outline: solid 2px var(--foreground) !important;
  }
`

const TextareaContainer = styled.div`
  position: relative;
  max-width: 610px;
  height: 80px;
  p {
    position: absolute;
    width: 100%;
    text-align: right;
    color: var(--shade);
  }
`

export const TextareaStyled = styled.textarea`
  color: var(--foreground);
  width: 100%;
  max-width: 610px;
  height: 80px;
  resize: none;
  border-radius: var(--radius);
  padding: 5px 12px 5px 12px;
  outline: var(--border) !important;
  border: none;
  transition: .1s;

  &::placeholder {
    color: var(--shade);
  }

  &:focus {
    outline: solid 2px var(--foreground) !important;
  }
`

export const Textarea = ({ 
  value, 
  onChange = () => {}, 
  placeholder =  "Short description",  
  maxLength = 250
}) => {
  const intl = useIntl()

  const [inputValue, setInputValue] = useState(value || '')
  const [charLeftCounter, setCharLeftCounter] = useState(
    maxLength - value?.length || maxLength
  )

  useEffect(() => {
    setInputValue(value)
    setCharLeftCounter(maxLength - value?.length)
  }, [value])

  const inputChange = (e) => {
    onChange(e.target.value)
    setInputValue(e.target.value)
  }

  return (
      <TextareaContainer>
        <TextareaStyled
          placeholder={placeholder}
          value={inputValue}
          maxLength={maxLength}
          onChange={(e) => inputChange(e)}
        />
        <p className="char-counter">
          {intl.formatMessage({
            id: 'events.createevent.general.shortdescription.limitlabel',
          })}
          : {charLeftCounter}
        </p>
      </TextareaContainer>
  )
}