import styled from '@emotion/styled'
import { Card, GridItem } from '@patternfly/react-core'
import { themeIsDark } from '_colorBus/primitives/scheme'
import border from '_utils/css-border'
import { mobile, pablet, tablet } from '_utils/responsive-queries'

export const CategoryTile = styled(Card)`
  background-color: var(--background);
  background: ${({ theme }) =>
    themeIsDark(theme.colors) ? 'var(--shade)' : 'var(--background)'};
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  ${border()}

  ${pablet} {
    padding: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const CountersWrapper = styled(GridItem)`
  display: flex;
  flex-basis: ${({isTopElement}) => isTopElement ? '25%' : 'calc(25% + 10px)'};
  min-width: 25%;
  justify-content: space-around;
  align-items: flex-start;
  height: ${({isTopElement}) => isTopElement && '75px'};
  width: auto;
  padding: 0 5px;
  gap: 24px;

  ${pablet} {
    flex-grow: 1;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
  }
  ${mobile} {
    padding: 0;
    margin-left: ${({isTopElement}) => isTopElement ? '60px' : '10px'};
    gap: 24px;
    justify-content: flex-start;
  }
`

export const Counter = styled.div`
  font-size: ${({isTopElement}) => isTopElement ? '22px' : '16px'};
  font-weight: 500;
  text-align: center;
`

export const PageSection = styled.div`
  /* align-self: center;
  .item-name {
    font-size: 16px;
    font-weight: 400;
  }

  ${tablet} {
    display: flex;
    ${Counter}, .item-name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
    }
  }

  ${pablet} {
    display: flex;
     flex-direction: column; 
    ${Counter}, .item-name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
    }
    span {
      align-self: center;
    }
  }  */
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  padding-top: 7px;
  .title {
    font-size: ${({ isTopElement }) => (isTopElement ? '18px' : '16px')};
    font-weight: 600;
    margin-bottom: 5px;
    flex-grow: 1;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .author {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  ${tablet} {
    padding-right: 0;
    .description {
      font-size: 16px;
    }
  }

  ${pablet} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    .title {
      font-weight: 600;
      font-size: ${({ isTopElement }) => (isTopElement ? '18px' : '16px')};
      width: calc(100% - 100px);
      word-break: break-word;
      display: flex;
      align-items: center;
    }
    .description {
      font-weight: 400;
      width: 100%;
      font-size: 16px;
      word-break: break-word;
    }
  }
  ${mobile} {
    gap: 8px;
    .title {
      width: 100%;
    }
    .author {
      flex-direction: column;
      align-items: flex-start;
      .bullet {
        display: none;
      }
    }
    .description {
      padding-bottom: 0;
    }
  }
`
