import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_KEYWORDS } from '../gql'
import Selector from 'components/indy/shared/ui-kit/selectors/multi-select-with-icon'
import {
  KeywordTitle,
  KeywordDescription,
  KeywordContainer,
  SelectorWrapper,
} from './styles/keywords'
import omitDeep from 'omit-deep-lodash'
import BreakLine from '_custom-attributes/editor/components/breakline'

const EventKeywords = ({ event, setEvent }) => {
  const { data: keywordData } = useQuery(GET_KEYWORDS, {
    fetchPolicy: 'cache-and-network',
    variables: { usedBy: ['events'] },
  })

  if (!keywordData) return <></>
  const eventKeywords = event.keywords
  const { getKeywords } = keywordData || []

  // Mutation is ok, problem is in query - we have to have id
  return (
    <>
      {getKeywords.map((keyword) => {
        const selectedItem = eventKeywords.find((ek) => ek.key === keyword.key)
        return (
          <>
          <KeywordContainer>
            <KeywordTitle>{keyword.name}</KeywordTitle>
            <KeywordDescription>{keyword.description}</KeywordDescription>
            <SelectorWrapper>
              <Selector
                items={
                  keyword.keywordList.map((listItem) => {
                    return { name: listItem.value, key: listItem.id }
                  }) || []
                }
                selectedValues={selectedItem?.childrenKeys || []}
                onSelect={(values) => {
                  setEvent({
                    ...event,
                    keywords: selectedItem
                      ? eventKeywords.map((ek) => {
                          const omitedKeyword = omitDeep(ek, '__typename')
                          return omitedKeyword.key !== selectedItem.key
                            ? omitedKeyword
                            : { ...omitedKeyword, childrenKeys: values }
                        })
                      : [
                          ...eventKeywords,
                          {
                            key: keyword.key,
                            childrenKeys: values,
                          },
                        ],
                  })
                }}
              />
            </SelectorWrapper>
          </KeywordContainer>
          <BreakLine/>
          </>
        )
      })}
      
    </>
  )
}

export default EventKeywords
