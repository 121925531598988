/* eslint-disable no-unused-vars */
import validUrl from 'valid-url'
import validEmail from 'email-validator'

const validateText = (f, required) => {
  if ((required !== undefined && !required && f.value === '') || !f.required)
    return null

  return !f.value ||
    f.value === '' ||
    f.value === 'undefined' ||
    f.value === 'null'
    ? `${f.name} is required`
    : null
}

const validateUrl = (f, required) => {
  if (required !== undefined && !required && f.value === '') {
    return null
  } else {
    if (
      !f.value ||
      f.value === '' ||
      f.value === 'undefined' ||
      f.value === 'null'
    )
      return `${f.name} is required`
    if (!validUrl.isWebUri(f.value) || validUrl.isWebUri(f.value) === undefined)
      return `${f.name} is not correct`
  }
}

const validateEmail = (f, required) => {
  if (!required && required !== undefined && f.value === '') return null
  if (
    !f.value ||
    f.value === '' ||
    f.value === 'undefined' ||
    f.value === 'null'
  )
    return `${f.name} is required`
  if (!validEmail.validate(f.value)) return `${f.name} is not correct`
}

const validateWysiwyg = (f, required) => {
  //Needs some more ruling here
  return null
}

function validate(f, required) {
  if (f.type === 'text') return validateText(f, required)
  // if (f.type === "url") return validateUrl(f, required)
  if (f.type === 'email') return validateEmail(f, required)
  if (f.type === 'wysiwyg') return validateWysiwyg(f, required)

  return null
}

export default validate
