import { useState } from 'react'
import { MbToB } from 'services/file'

export const useMediaUploader = (uploadConfig, callback) => {
  const [media, setMedia] = useState({})

  const {
    folderName,
    tagsConfig,
    maxImageFileSize,
    maxImageWidth,
    mediaContainerType,
    allowedFormats,
  } = uploadConfig

  const createTagsArray = () => {
    return (
      tagsConfig && [
        `userId:${tagsConfig.userId}`,
        `${folderName}Id:${tagsConfig.mediaId}`,
        `tenantId:${tagsConfig.tenantId}`,
        `context:${tagsConfig.context}`,
      ]
    )
  }

  const getSourcesForMediaContainerType = () => {
    switch (mediaContainerType) {
      case 'profileCoverImg':
        return ['unsplash', 'local', 'camera', 'google_drive', 'dropbox']
      case 'profileUserAvatar':
        return [
          'local',
          'camera',
          'facebook',
          'instagram',
          'google_drive',
          'dropbox',
        ]

      default:
        return ['unsplash', 'local', 'camera', 'google_drive', 'dropbox']
    }
  }

  const showWidget = () => {
    const uploadWidget = window.cloudinary?.createUploadWidget(
      {
        cloudName: 'indyriot',
        uploadPreset: 'defaultPreset',
        sources: getSourcesForMediaContainerType(),
        cropping: true,
        // multiple: true,
        folder: folderName && folderName, //upload files to the specified folder !!!!
        tags: tagsConfig && createTagsArray(tagsConfig), //add the given tags to the uploaded files
        clientAllowedFormats: allowedFormats || [
          'jpg',
          'png',
          'svg',
          'webp',
          'pdf',
        ], //restrict uploading to image files only
        maxImageFileSize: maxImageFileSize || MbToB(2), //restrict file size to less than 2MB
        maxImageWidth: maxImageWidth || 1000, //Scales the image down to a width of 2000 pixels before uploading
        // theme: "purple", //change to a purple theme
        styles: {
          palette: {
            window: 'white',
            windowBorder: '#90A0B3',
            tabIcon: '#0078FF',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
          fonts: {
            default: null,
            'sans-serif': {
              url: null,
              active: true,
            },
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setMedia(result.info)
          callback &&
            callback({ ...result.info, url: result?.info?.secure_url })
        }
      }
    )

    uploadWidget.open()
  }

  return { media, showWidget }
}

