import React from 'react'
import { useIntl } from 'react-intl'
import { MainSection } from '../../../shared/styles/sections'
import { GET_CONFIG, useAppConfig } from '_application'
import { omit } from 'lodash'
import { UPDATE_BLOG_CATEGORIES } from './graph-queries'
import { useMutation } from '@apollo/client'

import Creator from '../../../shared/components/categories-creator'

const Index = () => {
  const intl = useIntl()
  const { blogCategories: items } = useAppConfig()

  const renderMessageDescription = (value) => {
    return intl.formatMessage({
      id: `admin.settings.sitestructure.blog.categories${value.toLowerCase()}`,
    })
  }

  const [updateBlogCategories] = useMutation(UPDATE_BLOG_CATEGORIES)

  return (
    <>
      <MainSection>
        <span className="title">{renderMessageDescription('title')}</span>
        <Creator
          items={items}
          onSaveCallback={({ items }) =>
            updateBlogCategories({
              variables: {
                blogCategories: items.map((i) =>
                  omit(
                    {
                      ...i,
                      subcategories: i?.subcategories?.map((sub) =>
                        omit(sub, '__typename')
                      ),
                    },
                    '__typename'
                  )
                ),
              },
              refetchQueries: [{ query: GET_CONFIG }],
              awaitRefetchQueries: true,
            })
          }
        />
      </MainSection>
    </>
  )
}

export default Index
