import React from 'react'
import BaseNotification from './default'
import { useIntl } from 'react-intl'
import { resolveSubtype } from '../shared/helpers'
import { useQuery } from '@apollo/client'
import { Default as ActivityTile } from '_activities/components/tiles'
import { Default as CompanyTile } from '_companies/components/tiles'
import { Default as EventTile } from '_events/components/tiles'
import { Default as PeopleTile } from '_people/components/tiles'
import { Default as OpportunityTile } from '_opportunities/components/tiles'
import { Default as BlogTile } from '_blog/components/tiles'
import { Link } from 'react-router-dom'

const Recommendation = ({ item, deleteAction }) => {
  const intl = useIntl()
  const details = resolveSubtype({ subtype: item?.notification?.subtype })

  const id =
    item?.notification?.payload?.find((p) => p?.key === 'id')?.value || ''

  const { data, loading } = useQuery(details?.gqlQuery, {
    variables: {
      id,
    },
  })

  const ContentComponent = ({ setIsValid }) => {
    const isDeleted =
      details?.key !== 'people' &&
      !data?.[details?.gqlQueryName]?.name &&
      !data?.[details?.gqlQueryName]?.title
    if (isDeleted) {
      setIsValid(false)
    }

    return (
      <span>
        {`${intl.formatMessage({
          id: 'notificationscenter.notification.message.recommend',
        })} `}
        <Link
          to={
            !isDeleted
              ? `/${details?.key}/${
                  item?.notification?.subtype === 'p2b-recommend' ? '' : 'list/'
                }${
                  item?.notification?.payload?.find((p) => p?.key === 'id')
                    ?.value
                }`
              : '#'
          }
        >
          {
            !isDeleted
              ? `${
                  details?.key === 'people'
                    ? `${data?.[details?.gqlQueryName]?.firstName} ${
                        data?.[details?.gqlQueryName]?.lastName
                      }`
                    : data?.[details?.gqlQueryName]?.name ||
                      data?.[details?.gqlQueryName]?.title
                }`
              : '[Deleted]' /* @TODO Lukas please add some translation here */
          }
        </Link>
      </span>
    )
  }

  const ExpandComponent = () => {
    const { key: type } = details

    if (loading) return <span>Loading...</span>
    const component = () => {
      if (type === 'activities') {
        return (
          <Link to={`/activities/list/${id}`}>
            <ActivityTile activity={data?.getActivity} />
          </Link>
        )
      } else if (type === 'events') {
        return (
          <Link to={`/events/list/${id}`}>
            <EventTile event={data?.getEventDetails} />
          </Link>
        )
      } else if (type === 'companies') {
        return (
          <Link to={`/companies/list/${id}`}>
            <CompanyTile company={data?.getCompany} />
          </Link>
        )
      } else if (type === 'people') {
        return (
          <Link to={`/people/list/${id}`}>
            <PeopleTile person={data?.getUser} />
          </Link>
        )
      } else if (type === 'opportunities') {
        return (
          <Link to={`/opportunities/list/${id}`}>
            <OpportunityTile opportunity={data?.getOpportunity} />
          </Link>
        )
      } else if (type === 'blog') {
        return (
          <Link to={`/blog/${id}`}>
            <BlogTile post={data?.getBlogPost || {}} />
          </Link>
        )
      }
    }

    return component()
  }

  if (!data || loading) return null

  return (
    <BaseNotification
      item={item}
      deleteAction={deleteAction}
      ExpandComponent={ExpandComponent}
      ContentComponent={ContentComponent}
    />
  )
}

export default Recommendation
