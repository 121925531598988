import React from 'react'
import { map, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import border from '_utils/css-border'
import Item from './search-dropdown-item'

const Container = styled.div`
  ${border(0.3, 8, 2)}
  background-color: var(--background);
  border-radius: 22px;
  overflow: hidden;
  color: var(--foreground);
`

const ViewAll = styled.div`
  text-align: center;
  color: var(--foreground);
  font-weight: 600;
  padding: 8px;
  border-top: ${({ theme }) =>
    `1px solid ${theme.colors.interactiveSecondary}`};
  transition: 0.3s;
  &:hover {
    color: var(--flavor-shade);
  }
`

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--foreground);
  font-weight: 600;
  padding: 40px 20px;
`

const Props = {
  count: PropTypes.number,
  results: PropTypes.array,
  query: PropTypes.string,
  afterSelect: PropTypes.func,
}

const Empty = () => (
  <NoResults>
    <FormattedMessage id="search.noresults" />
  </NoResults>
)

const Loading = () => (
  <NoResults>
    Loading
  </NoResults>
)

const SearchDropdown = ({ count, query, results, afterSelect, loading }) => {
  if(loading){
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if (isEmpty(results)) {
    return (
      <Container>
        <Empty />
      </Container>
    )
  } else {
    return (
      <Container>
        <div onClick={afterSelect}>
          {map(results, (result) => (
            <Item key={result._id} data={result} />
          ))}
        </div>
        {query && (
          <Link to={`/search?q=${query}`}>
            <ViewAll data-testid="link-allResults">
              <FormattedMessage
                id="search.global.viewall"
                values={{ count: count || 0 }}
              />
            </ViewAll>
          </Link>
        )}
      </Container>
    )
  }
}

SearchDropdown.propTypes = Props

export default SearchDropdown
