import React from 'react'
import { Tile as PersonTile } from '_people'
import { Tile as CompanyTile } from '_companies'
import { Tile as OpportunityTile } from '_opportunities'
import { Tile as ActivityTile } from '_activities'
import { Tile as PostTile } from '_blog'
import { Tile as PageTile } from '_pages'
import { Link } from 'react-router-dom'

const TYPE_TO_APP_LABEL = {
  USER: 'people',
  COMPANY: 'companies',
  OPPORTUNITY: 'opportunities',
  ACTIVITY: 'activities',
  POST: 'blog',
  PAGE: 'pages',
}

const Body = ({ result, type }) => {
  const resultType = result.type || type
  switch (resultType) {
    case 'USER':
      return (
        <PersonTile
          person={result}
          showConnections={false}
          tileHeight="438px"
          showLocation={true}
        />
      )
    case 'COMPANY':
      return <CompanyTile company={result} className="h-100" />
    case 'OPPORTUNITY':
      return <OpportunityTile opportunity={result} tileHeight="593px" />
    case 'ACTIVITY':
      return <ActivityTile activity={result} tileHeight="511px" />
    case 'POST':
      return <PostTile post={result} className="h-100" />
    case 'PAGE':
      return <PageTile page={result} className="h-100" />
    default:
      return null
  }
}

const SearchTile = ({ result, type }) => {
  if(result?.type === 'POST' || type === 'POST') {
    return (
      <Link to={`/blog/${result._id}`}>
        <Body result={result} type={type} />
      </Link>
    )
  }
  return (
    <Link to={`/${TYPE_TO_APP_LABEL[result?.type]}/list/${result._id}`}>
      <Body result={result} type={type} />
    </Link>
  )
}

export default SearchTile
