import React from 'react'

import { Row, Col } from 'react-bootstrap'
import { MainSection } from '../shared/styles/sections'
import Generic from './generic'

import { useIntl } from 'react-intl'

const Dashboard = () => {
  return (
    <MainSection>
      <span className="title">
        {useIntl().formatMessage({ id: 'admin.settings.accesspage.title' })}
      </span>
      <Row>
        <Col sm={12} md={6}>
          <Generic />
        </Col>
      </Row>
    </MainSection>
  )
}

export default Dashboard
