import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { Row, Container } from 'react-bootstrap'
import { setActivePage } from 'redux/menu/actions'
import MainLayout from '_layouts/main'
import StaticPage from '../../components/indy/static-system-page'
import styled from '@emotion/styled'
import { desktop } from '_utils/responsive-queries'

const Content = styled.div`
  padding: 180px 50px 100px;
  width: 100%;

  ${desktop} {
    width: 850px;
    margin: auto;
  }

  .container {
  }
  .tab-title {
    font-size: 34px;
    color: var(--foreground);
    font-weight: 600;
  }
  .content {
    margin-top: 35px;
  }
`

const Index = ({ history }) => {
  const match = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setActivePage('about'))
  }, [history, dispatch])

  return (
    <MainLayout>
      <Content>
        <Container>
          <Row>
            <StaticPage id={match.params.id} />
          </Row>
        </Container>
      </Content>
    </MainLayout>
  )
}

export default withRouter(Index)
