import React from 'react'
import DivStyled from './styles/sidebar'
import { Button } from '@patternfly/react-core'
//import Rating from '../shared/rating'
import ApplicationListSelect from '../shared/application-list-select'
import ApplicationStateSelect from '../shared/application-state-select'
import SidebarDropdown from './sidebar-dropdown'
import { css } from '@emotion/react'
//import { useIntl } from 'react-intl'

//const intlKey = "application.management."

function Sidebar({ application }) {
  return (
    <DivStyled>
      <ApplicationListSelect
        css={css`
          width: 100%;
        `}
        application={application}
        isDetailView
      />
      <ApplicationStateSelect
        css={css`
          width: 100%;
        `}
        application={application}
      />
      <div id="dropdownWrapper">
        {/* TO BE ADDED LATER */}
        {false && <Button>Send Reply</Button>}
        <div className="spacer" />
        <SidebarDropdown application={application} />
      </div>
      {/*
      <div className="sidebarBox">
        My rating
        <Rating rating={0} />
        Team ratings
        <Rating rating={3} />
        <Rating rating={2} />
        <Rating rating={3} />
      </div>
		*/}
    </DivStyled>
  )
}

export default Sidebar
