import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SharedComponents from './components'

const ContentStudio = () => {
  return (
    <Switch>
      {/* Testing shared components, delete later */}
      <Route
        path="/content-studio/components"
        render={() => <SharedComponents />}
      />
    </Switch>
  )
}

export default ContentStudio

