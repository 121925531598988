import React from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import { mobile, mobilePX, pablet } from '_utils/responsive-queries'
import ReactTooltip from 'react-tooltip'

export const CardBase = styled.div`
  background-color: var(--background);
  box-shadow: 0px 3px 8px rgb(0 0 0 / 16%);
  border-radius: 10px;
  min-height: 85px;
  display: flex;
  min-width: calc(50% - 10px);
`

const DivStyled = styled(CardBase)`
  flex-grow: 1;
  overflow: hidden;

  .tooltipIcon {
    margin: 10px;
    align-self: flex-start;
    color: var(--shade);
  }
  .tooltip-message {
    font-family: ${({ theme }) => theme.fonts.googleFont} !important;
  }
  .name {
    font-size: 18px;
    font-weight: 700;
  }

  .content-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 30px 20px;
    gap: 20px;

    &.file {
      .title-file {
        word-break: break-word;
        padding: 0 20px;
      }
    }
  }
  .bagges-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 60px;
  }
  .badges {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .pf-c-badge {
      ${mobile} {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  ${pablet} {
    .name {
      font-size: 16px;
      padding: 0 15px 0px;
      font-size: 700;
    }
    .content-wrapper {
      flex-direction: column;
      gap: 0;
    }
    .badges {
      padding: 25px 15px;
    }
  }

  ${mobile} {
    .content-wrapper {
      justify-content: center;
      &.booleans {
        flex-direction: row-reverse;
        .booleans {
          flex-grow: 0;
        }
      }
    }
  }
`
export const BoolWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  column-gap: 20px;
  row-gap: 20px;
`

const Card = ({ children, className, tooltipText }) => {
  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })
  return (
    <DivStyled className={className}>
      {children}
      {tooltipText && (
        <i
          data-for="tooltip"
          data-tip={tooltipText}
          className="tooltipIcon fas fa-info-circle"
        >
          <ReactTooltip
            className="tooltip-message"
            effect="solid"
            id="tooltip"
            place={isMobileView && 'left'}
          />
        </i>
      )}
    </DivStyled>
  )
}

export const BoolCard = styled(Card)`
  min-width: 30%;
  min-height: 85px;
  font-size: 16px;
  align-items: center;
  flex-grow: unset;
  padding: 10px 5px 10px 0;

  i:nth-of-type(1) {
    color: var(--shade);
    margin: 0 5px 0 20px;
  }

  span {
    width: 100%;
    text-align: center;
  }

  .boolname {
    padding: 0 10px;
    font-weight: 600;
    ${mobile} {
      padding: 0 15px;
    }
  }
  .boolwrapper {
    display: flex;
    justify-content: space-between;
    i.fa-flag {
      color: var(--flavor);
    }
  }
  ${mobile} {
    position: relative;
    width: 100%;
    justify-content: center;

    span.boolwrapper {
      width: auto;
      padding: 0 30px;
    }

    i.tooltipIcon {
      position: absolute;
      margin: 10px;
      top: 0;
      right: 0;
    }

    i {
      margin: 0 5px 0 0;
    }
  }
`

export default Card
