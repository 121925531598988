import React from 'react'
import { MyActivitiesStyled } from './styles/my-activities'
import SearchResults from '_search/connected/my-creations-connected'

const MyCreations = ({ creatorId, pageHeight }) => {
  return (
    <MyActivitiesStyled>
      <SearchResults
        creatorId={creatorId}
        isMyCreations={true}
        pageHeight={pageHeight}
      />
    </MyActivitiesStyled>
  )
}

export default MyCreations
