import React from 'react'
import { Link } from 'react-router-dom'
import { FlexItem } from '@patternfly/react-core'
import { DiscoverAll, Header, Wrapper } from './styles/section-header'

const renderButton = (text, link, align, icon, action) => (
  <>
    {text && link ? (
      <FlexItem align={{ default: align }}>
        <Link to={link}>
          <DiscoverAll
            onClick={action}
            data-testid={`btn-discoverAll-${link ?? 'default'}`}
          >
            {icon}
            {text}
          </DiscoverAll>
        </Link>
      </FlexItem>
    ) : (
      <></>
    )}
  </>
)

const SectionHeader = ({
  buttonLink = '/forum',
  buttonAlign,
  buttonText,
  title,
  children,
  icon,
  button,
}) => {
  return (
    <div>
      <Header>
        <Wrapper>
          {title && <h3>{title}</h3>}
          {button
            ? button
            : renderButton(buttonText, buttonLink, buttonAlign, icon)}
        </Wrapper>
        {children}
      </Header>
    </div>
  )
}

export default SectionHeader
