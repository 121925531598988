import styled from '@emotion/styled'

export const StyledDiv = styled.div`
  box-shadow: 0 3px 8px var(--shade);
  border-radius: 0 10px 10px 0;
  padding: 32px;

  .menuItem {
    &:first-of-type .arrows button:first-of-type {
      display: none;
    }

    &:last-of-type .arrows button:last-of-type {
      display: none;
    }

    .fa.fa-link {
      font-size: 1.33em;
    }

    height: 86px;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    grid-template-columns: 0.2fr 0.4fr 1fr repeat(3, 15%);
    border: solid 1px var(--shade);
    border-radius: 5px;
    margin-bottom: -1px;
    align-items: center;
    svg * {
      fill: var(--foreground);
    }

    input,
    button > i {
      width: 16px;
      height: 16px;
    }

    .description {
      display: grid;
      gap: 0.5em;
      justify-self: start;
      text-transform: capitalize;

      .label {
        font-weight: 600;
      }
    }

    button {
      background: transparent;
      border: none;
      font-size: 16px;
    }

    .arrows {
      display: grid;
      gap: 0.5em;
    }

    span {
      width: 100%;
    }

    .icon {
      height: 22px;
      width: 22px;
    }
  }

  .addLinkButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 1.3em;
    text-transform: capitalize;
  }

  .labels {
    text-transform: capitalize;
    margin-bottom: 0.9em;
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    grid-template-columns: repeat(3, 15%);
    justify-items: center;
  }
`

export const ModalDiv = styled.div`
  & *:not(input):first-letter {
    text-transform: capitalize;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h4 {
    text-align: center;
    text-transform: capitalize;
  }

  .inputFields {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;
  }

  .inputFieldWrapper {
    display: grid;
    align-content: center;
    gap: 6px;
    height: 80px;
    input {
      height: 39px;
      border-radius: 5px;
      border-width: 1px;
      padding: 10px 16px;
    }
  }

  .buttons {
    display: flex;
    gap: 0.5em;
    font-size: 16px;
    div {
      flex-grow: 1;
    }
  }
`
