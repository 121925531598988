import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'

export const CancelSubscriptionModalStyled = styled.div`
  background-color: var(--background);
  .modal-footer {
    display: flex;
    gap: 10px;
  }
  span {
    text-align: center;
    display: block;
    font-size: 16px;
  }
  button {
    &.return-button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      &:hover {
        opacity: 0.6;
      }
    }
    &.cancel-button {
      ${ctaButtonHollow()};
    }
  }
`
