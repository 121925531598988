import styled from '@emotion/styled'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;
  position: relative;

  .title {
    font-size: 14px;
    font-weight: 700;
    color: var(--foreground);
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--foreground);
    margin-bottom: 10px;
  }

  input {
    border-radius: 5px;
    height: 40px;
    width: 410px;
    padding: 10px 16px;
    border: 1px solid var(--foreground);
    background: transparent;
    color: var(--foreground);
    font-size: 16px;
    border-color: ${({ isValueIncorrect }) => isValueIncorrect && 'red'};
    background-color: ${({ isValueIncorrect }) =>
      isValueIncorrect && 'rgba(255, 0, 0, 0.08)'};
  }
`

export const WarningWrapper = styled.div`
  width: 100%;
  min-height: 22px;

  .warning-message {
    margin: 0;
    color: red;
  }
`

