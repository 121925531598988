import React, { useState } from 'react'
// import { gql, useQuery } from '@apollo/client'
import ButtonWithDropdown from './button-with-dropdown'
import RecommendButton from '_components/buttons-connections/recommend-button-new'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useUser } from '_security/session-hooks'
import { useHistory } from 'react-router-dom'
import {
  EventInfoWrapper,
  TopInfoWrapper,
  BottomInfoWrapper,
  EventInfo,
  Title,
  ActionsWrapper,
  TopButtons,
  BottomButtons,
  AdditionalInfo,
  Date,
  Time,
  // Price,
  EventCreator,
  Avatar,
  CreatorInfo,
  CreatorName,
  CreatorLocation,
  EventStats,
  StatusOfInterestButton,
  RecommendButtonWrapper,
  AnchorTag,
  RecommendEvent,
  EditEventButton,
  HeaderWrapper,
  InfoWrapper,
  AddToCalendarButton,
} from './styles/event-info'
import Modal from '../event-creator/components/modal'
import { useIntl } from 'react-intl'
import { useAppLabel } from '_application'
import { useSelector } from 'react-redux'
import { roundTime } from '../helpers/roundTime'
import { ICalendar } from 'datebook'
import { gql, useMutation } from '@apollo/client'
import { mobilePX } from '_utils/responsive-queries'
import { useMediaQuery } from 'react-responsive'
import { renderLocation } from './render-location'

const CREATE_GOING = gql`
  mutation Mutation($createGoingToEvent: GoingToEvent) {
    createGoingToEvent(createGoingToEvent: $createGoingToEvent) {
      interactiontype
      user
      entity
    }
  }
`

const CREATE_INTERESTED = gql`
  mutation CreateInterestedInEvent($interestedInEventInput: InterestedInEvent) {
    createInterestedInEvent(interestedInEventInput: $interestedInEventInput) {
      interactiontype
      user
      entity
    }
  }
`

const DELETE_GOING = gql`
  mutation DeleteInterestedInEvent($deleteGoingToEvent: GoingToEvent) {
    deleteGoingToEvent(deleteGoingToEvent: $deleteGoingToEvent) {
      interactiontype
      user
      entity
    }
  }
`

const DELETE_INTERESTED = gql`
  mutation DeleteInterestedInEvent($interestedInEventInput: InterestedInEvent) {
    deleteInterestedInEvent(interestedInEventInput: $interestedInEventInput) {
      interactiontype
      user
      entity
    }
  }
`

export const DELETE_EVENT = gql`
  mutation deleteEvent($deleteEventId: ID!) {
    deleteEvent(id: $deleteEventId)
  }
`

const calendarConfig = (data) => {
  const { title, location, description, start, end } = data
  return {
    title,
    location,
    description,
    start: new window.Date(start),
    end: new window.Date(end),
  }
}

const resolveCalendar = (config) => new ICalendar(config)

const EventInformations = ({ event, isPreview, refetchEventData }) => {
  const intl = useIntl()
  const appLabel = useAppLabel()
  const history = useHistory()

  const { profile: userProfile } = useSelector((state) => state).user

  const [isInputShown, setIsInputShown] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const previewInlineStyles = isPreview && { pointerEvents: 'none' }

  const [deleteEvent] = useMutation(DELETE_EVENT, {
    variables: {
      deleteEventId: event?.id,
    },
  })

  const [createGoing] = useMutation(CREATE_GOING, {
    variables: { createGoingToEvent: { entity: event.id } },
  })

  const [createInterested] = useMutation(CREATE_INTERESTED, {
    variables: { interestedInEventInput: { entity: event.id } },
  })

  const [deleteInterested] = useMutation(DELETE_INTERESTED, {
    variables: { interestedInEventInput: { entity: event.id } },
  })

  const [deleteGoing] = useMutation(DELETE_GOING, {
    variables: { deleteGoingToEvent: { entity: event.id } },
  })

  const {
    venueLocation,
    start,
    end,
    title,
    creator,
    going,
    interested,
    id,
    isGoing,
    isInterested,
  } = event || {}

  const resolvedGoing = isGoing ? deleteGoing : createGoing
  const resolvedInterested = isInterested ? deleteInterested : createInterested
  const user = useUser()
  const isLoggedIn = user?.profile?.role
  const isCreator =
    isPreview || (isLoggedIn && creator?.sub === userProfile?.id)

  const isMobileView = useMediaQuery({
    query: `(max-width: ${mobilePX}px)`,
  })
  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${event?.title} event?`}
        setCanShowInfoModal={(value) => setIsDeleteModalOpen(value)}
        canShowInfoModal={isDeleteModalOpen}
        continueBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.continue',
        })}
        cancelBtnContent={intl.formatMessage({
          id: 'events.modal.delete.button.cancel',
        })}
        onProceed={() => {
          deleteEvent()
          history.push(`/events/list`)
        }}
      />
      <EventInfoWrapper>
        <TopInfoWrapper className="top">
          <EventInfo className="left">
            <HeaderWrapper>
              <Title>{title}</Title>
              <ActionsWrapper className="right">
                <TopButtons className="top-buttons">
                  <ButtonWithDropdown
                    isAnimated={false}
                    icon={<i className="fas fa-share-square" />}
                    itemsToDropdown={[
                      <RecommendEvent
                        isPreview={isPreview}
                        disabled={isPreview}
                        onClick={() => {
                          setIsInputShown(!isInputShown)
                        }}
                      >
                        <i className="fas fa-star" />
                        {intl.formatMessage({
                          id: 'events.detailview.3dotsdropdown.button.recommend',
                        })}
                      </RecommendEvent>,
                      <AnchorTag
                        href={`mailto:?subject=${encodeURIComponent(title)}&body=${intl.formatMessage(
                          {
                            id: 'profile.details.sharewithemail.emailbody',
                          }
                        )} ${title}`}
                        isPreview={isPreview}
                      >
                        <i className="fas fa-envelope" />
                        {intl.formatMessage({
                          id: 'events.detailview.3dotsdropdown.button.sharewithemail',
                        })}
                      </AnchorTag>,
                    ]}
                  />
                  {(isCreator || userProfile?.role === 'admin') &&
                  !isMobileView ? (
                    <ButtonWithDropdown
                      icon={<i className="fas fa-ellipsis-h" />}
                      itemsToDropdown={[
                        // TODO: not for 1.0 version
                        // <span disabled={isPreview}>
                        //   <i className="fas fa-briefcase" />
                        //   {intl.formatMessage({
                        //     id: 'events.detailview.3dotsdropdown.button.manage',
                        //   })}
                        // </span>,
                        isMobileView ? (
                          <></>
                        ) : (
                          <Link
                            style={previewInlineStyles}
                            to={
                              !isPreview &&
                              `/events/manage/general-information/${event?.id}`
                            }
                          >
                            <EditEventButton
                              disabled={isPreview}
                              isPreview={isPreview}
                            >
                              <i className="fas fa-pen-square" />
                              {intl.formatMessage({
                                id: 'events.detailview.3dotsdropdown.button.edit',
                              })}
                            </EditEventButton>
                          </Link>
                        ),
                        <EditEventButton
                          disabled={isPreview}
                          isPreview={isPreview}
                          onClick={() => {
                            setIsDeleteModalOpen(true)
                          }}
                        >
                          <i class="fas fa-trash"></i>
                          {intl.formatMessage({
                            id: 'events.detailview.3dotsdropdown.button.delete',
                            defaultMessage: 'Delete',
                          })}
                        </EditEventButton>,
                      ]}
                      isPreview={isPreview}
                    />
                  ) : (
                    <></>
                  )}
                </TopButtons>
                <RecommendButtonWrapper>
                  <RecommendButton
                    isInputShown={isInputShown}
                    setIsInputShown={setIsInputShown}
                    contentToRecomment={id}
                    onClick={() => setIsInputShown(!isInputShown)}
                    queryKey={'eventToRecommend'}
                  />
                </RecommendButtonWrapper>
                <BottomButtons className="bottom-buttons">
                  {/* TODO: not for 1.0 version */}
                  {/* <button>
              {intl.formatMessage({ id: 'events.detailview.button.buyticket' })}
            </button> */}
                </BottomButtons>
              </ActionsWrapper>
            </HeaderWrapper>
            <InfoWrapper>
              <AdditionalInfo className="additional-info">
                <Date>
                  <i className="far fa-calendar-alt" />
                  {moment(start).format('DD.MM.YYYY')} -{' '}
                  {moment(end).format('DD.MM.YYYY')}
                </Date>
                <Time>
                  <i className="far fa-clock" />
                  {roundTime(start)}
                  {' - '}
                  {roundTime(end)}
                </Time>
                <>{renderLocation(venueLocation, intl, appLabel, isPreview)}</>
                {/* TODO: we should add doorsClose */}
                {/* TODO: not for 1.0 version */}
                {/* <Price>
              <i className="fas fa-ticket-alt" />
              {'hardcoded 1000$'}
            </Price> */}
              </AdditionalInfo>
              {(isGoing || isInterested) && (
                <AddToCalendarButton
                  onClick={() =>
                    resolveCalendar(calendarConfig(event))?.download()
                  }
                >
                  <i className="far fa-calendar-alt" />
                  {intl.formatMessage({
                    id: 'events.detailview.label.addtocalendar',
                    defaultMessage: 'Add to my calendar',
                  })}
                </AddToCalendarButton>
              )}
            </InfoWrapper>
          </EventInfo>
        </TopInfoWrapper>
        {isLoggedIn && (
          <BottomInfoWrapper className="bottom">
            <EventCreator className="creator-info">
              <Avatar
                src={
                  isPreview && !creator ? userProfile.avatar : creator?.avatar
                }
                alt="event creator image"
              />
              <CreatorInfo>
                <CreatorName className="creator-name">
                  {isPreview && !creator ? userProfile.fullName : creator?.name}
                </CreatorName>
                {(creator?.location?.label || userProfile?.location?.label) && (
                  <CreatorLocation className="creator-location">
                    <i className="fas fa-map-marker-alt" />
                    {isPreview && !creator
                      ? userProfile.location.label
                      : creator?.location?.label}
                  </CreatorLocation>
                )}
              </CreatorInfo>
            </EventCreator>

            <EventStats className="event-stats">
              <StatusOfInterestButton
                style={previewInlineStyles}
                className={`going-number ${isGoing ? 'active' : ''}`}
                onClick={() => {
                  resolvedGoing()
                    .then((res) =>
                      isInterested
                        ? deleteInterested()
                            .then((res) => refetchEventData())
                            .catch((err) => err)
                        : refetchEventData()
                    )
                    .catch((err) => console.log('error', err))
                }}
              >
                <i className="fas fa-check-circle" />
                {intl.formatMessage({
                  id: 'events.detailview.label.going',
                })}{' '}
                {going}
              </StatusOfInterestButton>

              <StatusOfInterestButton
                style={previewInlineStyles}
                className={`interested-number ${isInterested ? 'active' : ''}`}
                onClick={() => {
                  resolvedInterested()
                    .then((res) =>
                      isGoing
                        ? deleteGoing()
                            .then((res) => refetchEventData())
                            .catch((err) => err)
                        : refetchEventData()
                    )
                    .catch((err) => console.log('error', err))
                }}
              >
                <i className="fas fa-star" />
                {intl.formatMessage({
                  id: 'events.detailview.label.interested',
                })}{' '}
                {interested}
              </StatusOfInterestButton>
            </EventStats>
          </BottomInfoWrapper>
        )}
      </EventInfoWrapper>
    </>
  )
}

export default EventInformations
