import React from 'react'
import { Bullseye } from '@patternfly/react-core'
import Header from 'components/indy/shared/logged/header'
const ForumHeader = () => {
  return (
    <Bullseye>
      <Header></Header>
    </Bullseye>
  )
}

export default ForumHeader
