import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import ClickAway from '_components/click-away'
//import Dropdown from '_components/dropdown'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'

import { css } from '@emotion/core'

const Props = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.object,
}

const Toggle = styled.div`
  cursor: pointer;
  font-weight: bold;
`

const listClosed = css`
  max-height: 0px;
  padding: 0px;
  border: solid 0px var(--foreground);
`

const List = styled.div`
  position: absolute;
  background: var(--background);
  padding: 12px 0px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 5px;
  max-height: unset;
  z-index: 19;
  width: max-content;
  border: solid 1px var(--foreground);

  transition: 0.2s ease-in-out;

  ${({ open }) => !open && listClosed}
`

const Item = styled.div`
  padding: 6px 25px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background: var(--shade);
  }
  &:active,
  &.active {
    background: var(--flavor-shade);
    color: var(--flavor-contrast);
  }
`

const Dropdown = styled(ClickAway)`
  padding: 0;
  position: relative;
`

const SVG = styled.svg`
  --size: 8px;
  width: var(--size);
  height: var(--size);
  transform: rotate(90deg);
  transition: 0.2s ease-in-out;

  &.open {
    transform: rotate(-90deg);
  }

  path {
    fill: var(--foreground);
  }
`

const SvgArrow = ({ open }) => (
  <SVG
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 494.148 494.148"
    className={open && 'open'}
  >
    <path
      d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
        c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,27.44-45.884
        C432.632,229.572,422.964,213.288,405.284,201.188z"
    />
  </SVG>
)

const FilterDropdown = ({ filter, options, onSelect }) => {
  const [option, useOption] = useState(null)
  const [menu, useMenu] = useState(false)

  const ChangeFilter = (option) => {
    useMenu(false)
    useOption(option.label)
    onSelect({ [option.key]: true })
  }

  const MenuToggle = () => {
    useMenu(!menu)
  }

  const MenuOff = () => {
    useMenu(false)
  }
  const MenuNoFilter = () => {
    useMenu(false)
    useOption(null)
    onSelect(undefined)
  }

  return (
    <Dropdown onClickOutside={() => MenuOff()}>
      <Toggle onClick={() => MenuToggle()} data-testid="filter-btn-dropdown">
        <FormattedMessage id="filter.label" />
        {option && ' : '} {option} <SvgArrow open={menu} />
      </Toggle>
      <List open={menu}>
        <Item
          onClick={() => MenuNoFilter()}
          className={option === null && 'active'}
        >
          <FormattedMessage id="filter.none" />
        </Item>
        {map(options, (option) => (
          <Item
            key={option.key}
            onClick={() => ChangeFilter(option)}
            className={filter && filter[option.key] && 'active'}
          >
            {option.label}
          </Item>
        ))}
      </List>
    </Dropdown>
  )
}
FilterDropdown.propTypes = Props

export default FilterDropdown
