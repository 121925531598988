import React, { useState } from 'react'
import { COUNTRIES } from '../data/countries'
import {
  Button,
  Label,
  FieldsWrapper,
  CityAndPostcodeWrapper,
  IndividualOrCompanyWrapper,
} from '../styles/checkout'

const ToggleCompanyFields = ({
  billingInfo,
  setBillingInfo,
  renderMessageText,
}) => {
  const [isCompany, setIsCompany] = useState(false)

  const handleSetCompany = (e) => {
    e.preventDefault()
    setIsCompany(!isCompany)
  }

  const handleBillingInfo = (e) => {
    const { value } = e.target

    setBillingInfo({
      ...billingInfo,
      company: {
        ...billingInfo.company,
        [e.target.name]: value,
      },
    })
  }

  return (
    <>
      <IndividualOrCompanyWrapper onClick={handleSetCompany}>
        <Button className={`${!isCompany ? 'active' : ''}`}>
          {renderMessageText('individual', 'Individual')}
        </Button>
        <Button className={`${isCompany ? 'active' : ''}`}>
          {' '}
          {renderMessageText('company', 'Company')}
        </Button>
      </IndividualOrCompanyWrapper>

      {isCompany && (
        <FieldsWrapper>
          <Label>
            <input
              type="text"
              name="companyName"
              autoComplete="organization"
              placeholder={renderMessageText(
                'companyNameInput',
                'Company Name'
              )}
              aria-label={renderMessageText('companyNameInput', 'Company Name')}
              onChange={handleBillingInfo}
            />
          </Label>

          <Label>
            <input
              type="text"
              name="line1"
              autoComplete="address-line1"
              placeholder={renderMessageText(
                'addressLine1Input',
                'Address Line 1'
              )}
              aria-label={renderMessageText(
                'addressLine1Input',
                'Address Line 1'
              )}
              onChange={handleBillingInfo}
            />
          </Label>

          <Label>
            <input
              type="text"
              name="line2"
              autoComplete="address-line2"
              placeholder={renderMessageText(
                'addressLine2Input',
                'Address Line 2'
              )}
              aria-label={renderMessageText(
                'addressLine2Input',
                'Address Line 2'
              )}
              onChange={handleBillingInfo}
            />
          </Label>

          <CityAndPostcodeWrapper>
            <Label>
              <input
                type="text"
                name="city"
                autoComplete="city"
                placeholder={renderMessageText('cityInput', 'City')}
                aria-label={renderMessageText('cityInput', 'City')}
                onChange={handleBillingInfo}
              />
            </Label>

            <Label>
              <input
                type="text"
                name="postal_code"
                autoComplete="postal-code"
                placeholder={renderMessageText('postCodeInput', 'Post Code')}
                aria-label={renderMessageText('postCodeInput', 'Post Code')}
                onChange={handleBillingInfo}
              />
            </Label>
          </CityAndPostcodeWrapper>

          <Label>
            <select
              required
              name="country"
              onChange={handleBillingInfo}
              autoComplete="country"
              defaultValue="DEFAULT"
            >
              <option key="DEFAULT" value="DEFAULT" disabled>
                {renderMessageText('countrySelector', 'Select a Country')}
              </option>
              {COUNTRIES.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
              ;
            </select>
          </Label>

          <Label>
            <input
              type="text"
              name="taxIdentifier"
              placeholder="NO123456789"
              aria-label={renderMessageText(
                'taxIdentiferInput',
                'Tax Identifier'
              )}
              onChange={handleBillingInfo}
            />
            <span className="help">
              {renderMessageText(
                'taxIdentiferHelpText',
                'Enter your VAT number with the country prefix'
              )}
            </span>
          </Label>
        </FieldsWrapper>
      )}
    </>
  )
}

export default ToggleCompanyFields
