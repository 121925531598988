import React, { useEffect, useState } from 'react'
import {
  Section,
  StripeElements,
  CheckoutStyled,
  Column,
  ModalTitle,
  ModalSubtitle,
  Content,
  Card,
  Info,
  ButtonsContainer,
  Button,
  CardInput,
  CardDetails,
  SaveInfoText,
  SaveCardOption,
} from '../styles/checkout'
import GridLoader from 'react-spinners/GridLoader'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'

import ProductPreview from './product-preview'
import { usePluginsPublic } from '_application'
import { useStripeCustomer } from '_payment'
import Coupon from './coupon'

import { themeIsDark } from '_colorBus/primitives/scheme'
import { useTheme } from 'emotion-theming'
import ToggleCompanyFields from './company-form'

let card
let stripe

const Checkout = ({ onSuccessCallback, onClose, product }) => {
  const theme = useTheme()
  const intl = useIntl()
  const user = useUser()
  const [promoCode, setPromoCode] = useState()
  const [billingInfo, setBillingInfo] = useState({
    email: user?.profile?.email,
  })
  const { plugin } = usePluginsPublic('stripe')
  const { customer, loading: customerLoading } = useStripeCustomer()
  const key = plugin?.data?.isLiveMode
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST

  useEffect(() => {
    if (plugin?.data) {
      stripe = window.Stripe(String(key), {
        stripeAccount: plugin?.data?.account?.id,
      })
      if (!customer?.paymentMethod && !customerLoading) {
        const styles = getComputedStyle(document.body)
        card = stripe.elements().create('card', {
          style: {
            base: {
              color: themeIsDark(theme?.colors) ? 'white' : 'black',
              '::placeholder': {
                color: styles.getPropertyValue('--foreground-shade'),
              },
            },
          },
        })
        card.mount('#card-element')
      }
    }
    return () => {
      card && card.unmount()
    }
  }, [plugin, customer, customerLoading, key, theme])

  const handleSubscribe = (e) => {
    e.preventDefault()

    if (customer?.paymentMethod) {
      onSuccessCallback({
        ...billingInfo,
        paymentMethod: customer?.paymentMethod,
      })
    } else {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: billingInfo?.email,
          },
        })
        .then((result) => {
          if (result.error) {
            console.log('err', result.error)
          } else {
            onSuccessCallback({
              ...billingInfo,
              paymentMethod: result.paymentMethod,
            })
          }
        })
    }
  }

  const renderMessageText = (value, defaultMessage) => {
    return intl.formatMessage({
      id: `user.profile.payments.form.${value.toLowerCase()}`,
      defaultMessage,
    })
  }

  const creditCard = customer?.paymentMethod?.card

  return (
    <CheckoutStyled id="payment-form">
      <Column className="leftColumn">
        <ModalTitle>{renderMessageText('checkout')}</ModalTitle>
        <div>
          <ModalSubtitle className="title">
            {renderMessageText('whoIsPaying', 'Who is paying?')}
          </ModalSubtitle>
          <ToggleCompanyFields
            billingInfo={billingInfo}
            setBillingInfo={setBillingInfo}
            renderMessageText={renderMessageText}
          />
        </div>

        <Section>
          <ModalSubtitle className="title">
            {renderMessageText('creditcard')}
          </ModalSubtitle>
          <Content className="content">
            {customerLoading && (
              <GridLoader color={theme.colors.buttonColor} size={5} />
            )}
            {!customerLoading && (
              <CardDetails>
                {creditCard ? (
                  <Card className="existing-card">
                    <i className={`fab fa-cc-${creditCard?.brand}`} />
                    <Info className="info">
                      <span className="last4">{`Ending in ${creditCard?.last4}`}</span>
                    </Info>
                  </Card>
                ) : (
                  <StripeElements>
                    <CardInput>
                      <div id="card-element" />
                      <div id="card-element-errors" role="alert" />
                    </CardInput>
                    <SaveCardOption className="save-card-option">
                      <input
                        type="checkbox"
                        onChange={() =>
                          setBillingInfo({
                            ...billingInfo,
                            isSavePaymentMethod: !billingInfo.isSavePaymentMethod,
                          })
                        }
                        value={billingInfo.isSavePaymentMethod}
                      />
                      <SaveInfoText>
                        {renderMessageText('savecard')}
                      </SaveInfoText>
                    </SaveCardOption>
                  </StripeElements>
                )}
              </CardDetails>
            )}
          </Content>
        </Section>

        <Section>
          <Coupon
            onCallback={(promo) => {
              setBillingInfo({
                ...billingInfo,
                promotion_code: promo?.id,
              })
              setPromoCode(promo)
            }}
            renderMessageText={renderMessageText}
          />
        </Section>
      </Column>
      <Column>
        <ProductPreview
          promoCode={promoCode}
          product={product}
          renderMessageText={renderMessageText}
        />
        <ButtonsContainer className="controls">
          <Button
            className="cancel"
            onClick={onClose}
            data-testid="subs-modal-btn-cancel"
          >
            {renderMessageText('cancel', 'Cancel')}
          </Button>
          <Button
            className="subscribe"
            onClick={(e) => handleSubscribe(e)}
            type="submit"
            data-testid="subs-modal-btn-confirm"
          >
            {renderMessageText('subscribe', 'Subscribe')}
          </Button>
        </ButtonsContainer>
      </Column>
    </CheckoutStyled>
  )
}

export default Checkout
