import React from 'react'
import {
  DefaultTileStyled,
  Header,
  EntityInfo,
  Content,
  Title,
  DiscoverTitle,
  Description,
  Date,
  ConnectButtonContainer,
  Controls,
} from './styled/default'
import { Avatars } from '_tiles'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { useUser } from '_security/session-hooks'
import ConnectButton from '_components/buttons-connections/connect-button'
import { useTheme } from 'emotion-theming'
import { ReactComponent as LocationIcon } from '_tiles/shared/resources/location-icon.svg'
import { useIsDiscoverPage } from '_application/is-discover-page'
import EntityEditButton from '_tiles/shared/entity-edit-button'
import { useUserAccess } from '_security/helpers'

const DefaultTile = ({ children, activity, connectButton, isOwner }) => {
  const { creator, coverPhoto, name, description, location, title, excerpt } = activity || {}
  const intl = useIntl()
  const theme = useTheme()
  const isDiscoverPage = useIsDiscoverPage()
  const user = useUser()
  const hasUserAccess = useUserAccess()
  const isAdmin = user?.profile?.role === 'admin'
  const isLogged = user?.profile?.role

  const renderDates = () => {
    let dates = ''
    if (activity.start && activity.end) {
      dates =
        moment(activity.start).format('DD.MM.YYYY') +
        ' - ' +
        moment(activity.end).format('DD.MM.YYYY')
    } else if (activity.start) {
      dates =
        intl.formatMessage({ id: 'sanity.event.start.title' }) +
        ' ' +
        moment(activity.start).format('DD.MM.YYYY')
    } else if (activity.end) {
      dates =
        intl.formatMessage({ id: 'sanity.event.end.title' }) +
        ' ' +
        moment(activity.end).format('DD.MM.YYYY')
    }
    if (dates) {
      return <Date>{dates}</Date>
    } else {
      return <></>
    }
  }

  const _renderTitle = () => (
    <>
      {isDiscoverPage ? (
        <DiscoverTitle>
          {name || title || intl.formatMessage({ id: 'description.notavailable' })}
        </DiscoverTitle>
      ) : (
        <Title>
          {name || title || intl.formatMessage({ id: 'description.notavailable' })}
        </Title>
      )}
    </>
  )

  const activityId = activity?.id || activity?._id

  return (
    <DefaultTileStyled>
      <Header
        style={{
          backgroundImage: `url(${
            coverPhoto?.replace('upload/', 'upload/c_fill,w_400,h_400/') ||
            theme?.images?.defaultCover
          })`,
        }}
      >
        {location?.city && location?.country && (
          <span className="location">
            <LocationIcon />
            {`${location?.city + ', ' + location?.country}`}
          </span>
        )}
        {(isOwner || isAdmin) && (
          <Controls>
            <EntityEditButton url={`/activities/list/${activityId}/edit`} />
          </Controls>
        )}
        <div className="overlay" />
      </Header>
      <Content>
        <EntityInfo>
          <Avatars
            creator={creator}
            connections={activity?.connectedCompanies}
          />
        </EntityInfo>
        {_renderTitle()}
        {renderDates()}
        <Description>
          {description ||
            excerpt ||
            intl.formatMessage({ id: 'description.notavailable' })}
        </Description>
        {/* TODO: Below is broken, no disconnect or refetch */}
        <ConnectButtonContainer>
          {connectButton && (
            <ConnectButton
              status={
                hasUserAccess && isLogged ? activity.connectionStatus : null
              }
              onConnect={
                hasUserAccess && isLogged ? connectButton?.onConnect : () => {}
              }
              disabled={connectButton?.disabled}
              type="activities"
            />
          )}
        </ConnectButtonContainer>
      </Content>
    </DefaultTileStyled>
  )
}

export default DefaultTile
