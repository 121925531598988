import React from 'react'

import { BaseTileStyled } from '_tiles/styles/base-tile'
import { useColorBucket } from '_colorBus/hooks'

const BaseTile = ({ children }) => {
  const [colorBucket] = useColorBucket('tile')
  return <BaseTileStyled ref={colorBucket}>{children}</BaseTileStyled>
}
export default BaseTile
