import React, { useState } from 'react'

import Button from 'components/indy/shared/ui-kit/buttons/single-button'
import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import Style from './styles/remove'

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: var(--background);
  }
`

const ModalIndex = ({
  onClose,
  onDelete,
  user,
  defaultProps,
  title,
  description,
}) => {
  const [modalProps] = useState(defaultProps || {})
  const intl = useIntl()

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `user.profile.education.experience.modal.subtitle.${value.toLowerCase()}`,
    })
  }

  const handleDelete = () => {
    onDelete(modalProps)
  }
  return (
    <StyledModal show onHide={onClose}>
      {defaultProps.schoolName ? (
        <Style>
          <Modal.Body>
            <h3 className="modal-title">
              Delete {title} at {defaultProps.schoolName}
            </h3>
            <p>
              <span>{renderMessageText('school')}:</span>
              {defaultProps.schoolName}
            </p>
            <p>
              <span>{renderMessageText('degree')}:</span> {defaultProps.degree}
            </p>
            <p>
              <span>{renderMessageText('domain')}:</span> {defaultProps.domain}
            </p>
          </Modal.Body>
        </Style>
      ) : (
        <Style>
          <Modal.Body>
            <h3 className="modal-title">
              Delete {title} at {defaultProps.companyName}
            </h3>
            <p>
              <span>{renderMessageText('companyName')}:</span>{' '}
              {defaultProps.companyName}
            </p>
            <p>
              <span>{renderMessageText('title')}:</span> {defaultProps.title}
            </p>
            <p>
              <span>{renderMessageText('description')}:</span>{' '}
              {defaultProps.description}
            </p>
          </Modal.Body>
        </Style>
      )}
      <Style>
        <Modal.Footer>
          <Button type="admin" onClick={onClose} testid="modal-cancel">
            {renderMessageText('cancel')}
          </Button>
          <Button
            type="admin"
            onClick={() => handleDelete()}
            testid="modal-delete"
          >
            {renderMessageText('delete')}
          </Button>
        </Modal.Footer>
      </Style>
    </StyledModal>
  )
}

export default ModalIndex
