import styled from '@emotion/styled'
import { ctaButtonHollow } from '_utils/css-buttons'

export const Section = styled.div`
  padding: 50px 50px 75px;
  background: var(--background);
  font-size: 16px;
  span.field-title {
    font-weight: 700;
  }
  .summary-text {
    display: inline-block;
    padding: 25px 0;
  }
`

export const Field = styled.div`
  .field-title {
    display: block;
    font-size: 14px;
    color: var(--foreground);
    font-weight: 500;
    margin: 20px 0 10px;
  }
  .file-details {
    color: var(--foreground);
  }

  input[type='email']:disabled {
    background: #e7e5e5;
  }
`

export const SectionHeader = styled.h4`
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;
`

export const DeleteButton = styled.button`
  ${ctaButtonHollow()}
  margin-left: auto !important;
  margin-top: 20px;
  &:disabled {
    background-color: var(--shade) !important;
    border-color: var(--shade) !important;
    color: var(--background) !important;
    pointer-events: none;
  }
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .items-header {
    font-size: 18px;
    font-weight: 600;
  }
`
export const SingleItem = styled.div`
  font-size: 16px;
  font-weight: 600;
`
export const StaticContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  font-size: 16px;
  .list {
    list-style: disc;
    list-style-position: inside;

    li {
      padding: 5px 0 0;
    }
  }
  .info-summary {
    padding: 25px 0;
  }
`
