import styled from '@emotion/styled'

const ManageKeywordsStyled = styled.div`
  background-color: var(--background);
  padding: 10px 15px;
  li {
    max-width: 100%;
    margin-top: 5px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--foreground);
  }
  .modal-description {
    color: var(--foreground);
    font-size: 16px;
  }
  .modal-content {
    background-color: transparent;
    margin-top: 20px;
    display: block;
    text-overflow: ellipsis;
    border: none;
  }
  .modal-footer {
    border: none;
  }
  .keywords-list {
    padding: 0;
    li {
      position: relative;
      display: inline-block;
      text-align: left;
      background: var(--foreground);
      color: var(--background);
      padding-left: 24px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 35px;
      border-radius: 6px;
      font-size: 12px;
      margin-right: 4px;
      i {
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
`

export default ManageKeywordsStyled
