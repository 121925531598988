import React from 'react'
import BaseNotification from './default'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { GET_OPPORTUNITY } from './helpers'
import { useQuery } from '@apollo/client'
import { useAppLabel } from '_application'

const Opportunity = ({
  item,
  acceptAction,
  declineAction,
  isOwnerToPersonConnection,
}) => {
  const appLabel = useAppLabel()
  const intl = useIntl()

  const { data, loading } = useQuery(GET_OPPORTUNITY, {
    variables: {
      id:
        item?.notification?.payload?.find((p) => p?.key === 'opportunity')
          ?.value || '',
    },
  })
  const activityLink = data?.getActivity?.id

  const ContentComponent = () => (
    <span>
      {`${intl.formatMessage(
        {
          id: `notificationscenter.notification.message.connect.opportunity${
            isOwnerToPersonConnection ? '.ownertoperson' : ''
          }`,
        },
        {
          entity: `${
            isOwnerToPersonConnection
              ? appLabel('opportunities', 'opportunities.title').toLowerCase()
              : ''
          }`,
        }
      )} `}
      <Link to={`/opportunities/list/${activityLink}`}>
        {data?.getOpportunity?.name}
      </Link>
    </span>
  )

  if (!data || loading) return null

  return (
    <BaseNotification
      item={item}
      acceptAction={acceptAction}
      declineAction={declineAction}
      ContentComponent={ContentComponent}
    />
  )
}

export default Opportunity
