import styled from '@emotion/styled'

const Section = styled.div`
  margin-top: 16px;
  span {
    margin-bottom: 6px;
    display: block;
  }
  textarea {
    resize: none;
    width: 100%;
    padding: 10px;
  }
`

const ModalStyled = styled.div`
  background: var(--background);

  .inspect-report-title {
    font-weight: 600;
    font-size: 24px;
    margin: 25px;
  }
  .report-user-info-row {
    text-align: center;
  }
  .thumbnail-col {
    padding-right: 0;
    margin-right: 0;
    .user-thumbnail {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }
  }
  .user-name {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .message-content-container {
    margin: 15px;
    .report-title {
      font-weight: 600;
    }
  }
  .response-title {
    font-size: 16px;
    margin: 0 15px 15px;
  }
  .message-compose-container {
    text-align: center;
    textarea {
      padding: 10px 20px;
      width: 90%;
      border-radius: 15px;
      border-bottom-right-radius: 0;
      min-height: 100px;
      &:focus {
        outline: none;
      }
    }
  }
  .modal-footer {
    padding: 30px;
    span.info {
      display: block;
      margin-bottom: 10px;
    }
    div.dropdown {
      display: block;
      float: left;
    }
    button {
      float: right;
      border: none;
      padding: 8px 22px;
      border-radius: 6px;
      background: ${(props) => props.theme.colors.neutral};
      color: var(--background);
      font-size: 12px;
      transition: 0.5s;
      &:hover {
        color: var(--background);
        background-color: ${(props) => props.theme.colors.primaryHover};
      }
      &.save {
        background: ${({ theme }) => theme.colors.primaryHover};
      }
    }
  }
`

export { ModalStyled, Section }
