import React from 'react'
import { Grid as GridContainer } from '@patternfly/react-core'
import { GridStyled } from './styles/grid'

const defaultConfig = {
  lg: 6,
}

const Grid = ({ children, config, testid }) => {
  const gridConfig = { ...defaultConfig, ...config } || defaultConfig

  return (
    <GridStyled data-testid={`gridStyled-${testid ?? 'default'}`}>
      <GridContainer {...gridConfig}>{children}</GridContainer>
    </GridStyled>
  )
}

export default Grid
