import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import Button from '_components/button'
import Blink from '_components/blink'
import { H5 } from '_uikit/headers'
import Modal from 'react-bootstrap/Modal'

import MeetVideo from './meet-video'

const IconContainer = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Icon = styled.i`
  font-size: 48px;
  color: ${({ theme }) => theme.colors.primaryColor};
`
const MeetingState = styled(H5)`
  margin: 30px 0;
`

const Calling = () => <Icon className="fas fa-phone-square" />
const Ended = () => <Icon className="fas fa-calendar-check" />
const Rejected = () => <Icon className="fas fa-ban" />

const Body = ({ callState, callData, onDisconnect }) => {
  if (!callState || callState === 'CLOSED') {
    return (
      <IconContainer>
        <Ended />
        <MeetingState>
          <FormattedMessage id="meet.ended" />
        </MeetingState>
        <Button onClick={onDisconnect}>
          <FormattedMessage id="close" />
        </Button>
      </IconContainer>
    )
  } else if (callState === 'REJECTED') {
    return (
      <IconContainer>
        <Rejected />
        <MeetingState>
          <FormattedMessage id="meet.rejected" />
        </MeetingState>
        <Button onClick={onDisconnect}>
          <FormattedMessage id="close" />
        </Button>
      </IconContainer>
    )
  } else if (callState === 'STARTED') {
    return <MeetVideo conferenceId={callData.key} onDisconnect={onDisconnect} />
  } else {
    return (
      <IconContainer>
        <Blink>
          <Calling />
        </Blink>
        <MeetingState>
          <FormattedMessage id="meet.calling" />
        </MeetingState>
        <Button onClick={onDisconnect}>
          <FormattedMessage id="cancel" />
        </Button>
      </IconContainer>
    )
  }
}

const Props = {
  callState: PropTypes.string.isRequired,
  callData: PropTypes.object.isRequired,
}
export const MeetCallModal = ({ callState, callData, onDisconnect }) => {
  return (
    <Modal size="lg" onHide={noop} show>
      <Modal.Body
        css={css`
          min-width: 200px;
          min-height: 200px;
        `}
        bsClass="custom-modal"
      >
        <Body
          callState={callState}
          callData={callData}
          onDisconnect={onDisconnect}
        />
      </Modal.Body>
    </Modal>
  )
}
MeetCallModal.propTypes = Props

export default MeetCallModal
