import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { H5 } from '_uikit/headers'

import { useAppLabel } from '_application'

import SearchGrid from '../components/search-grid'
import SearchTile from '../components/search-tile'

import { Grid, BaseTile } from '_tiles'
import { Default as ActivityTile } from '_activities/components/tiles'
import { Default as PersonTile } from '_people/components/tiles'
import { Default as CompanyTile } from '_companies/components/tiles'
import { Default as OpportunityTile } from '_opportunities/components/tiles'

const TYPE_TO_APP_LABEL = {
  USER: 'people',
  COMPANY: 'companies',
  OPPORTUNITY: 'opportunities',
  ACTIVITY: 'activities',
  POST: 'posts',
  PAGE: 'pages',
}

const SectionTitle = styled(H5)`
  text-transform: lowercase;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  .mapText {
    margin: 0px 0.5em;
  }
`

const SearchGridStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`

const Container = styled.div`
  max-width: 1470px;
  margin: 0 auto;
`

const ResultsSection = ({
  results,
  count,
  type,
}) => {
  const appLabel = useAppLabel()

  const SearchTileNew = ({ result, type }) => {
    const resolveType = type => {
      switch (type) {
        case 'ACTIVITY':
          return (
            <BaseTile
              url={`/activities/list/${result._id}`}
              config={{ xl: 3, lg: 4, md: 6, sm: 6 }}
              key={result._id}
            >
              <ActivityTile activity={result} />
            </BaseTile>
          )
        case 'USER':
          return (
            <BaseTile
              url={`/people/list/${result._id}`}
              config={{ xl: 3, lg: 4, md: 6, sm: 6 }}
              key={result._id}
            >
              <PersonTile person={result} />
            </BaseTile>
          )
        case 'COMPANY':
          return (
            <BaseTile
              url={`/companies/list/${result._id}`}
              config={{ xl: 4, lg: 6, md: 6, sm: 6 }}
              key={result._id}
            >
              <CompanyTile company={result} />
            </BaseTile>
          )
        case 'OPPORTUNITY':
          return (
            <BaseTile
              url={`/opportunities/list/${result._id}`}
              config={{ xl: 3, lg: 4, md: 6, sm: 6 }}
              key={result._id}
            >
              <OpportunityTile opportunity={result} />
            </BaseTile>
          )
        default:
          return <></>
      }
    }

    return resolveType(type)
  }

  const resolveSearchGrid = () => {
    const newGridTypes = ['ACTIVITY', 'USER', 'COMPANY', 'OPPORTUNITY']
    if (newGridTypes.some(t => type === t)) {
      return (
        <SearchGridStyled>
          <Grid>
            {results.map(result => (
              <SearchTileNew result={result} type={type} />
            ))}
          </Grid>
        </SearchGridStyled>
      )
    } else {
      return (
        <SearchGrid>
          {results.map(result => (
            <SearchTile key={result.id} result={result} type={type}  />
          ))}
        </SearchGrid>
      )
    }
  }
  if(count > 0 ){
    return (
      <Container>
        <TitleContainer>
          <SectionTitle>
            <FormattedMessage
              id="search.resultscountpertype"
              values={{
                count,
                type: appLabel(
                  `${TYPE_TO_APP_LABEL[type]}Plural`,
                  `search.type.${type.toLowerCase()}`
                ),
              }}
            />
          </SectionTitle>
        </TitleContainer>
        {resolveSearchGrid()}
      </Container>
    )
  }

  return <></>

}

export const SearchResults = ({ groups }) => {


  return groups.map(group => {
    return (
      <ResultsSection
        results={group.data || []}
        count={group.total}
        type={group._id}
      />
    )
  })
}

export default SearchResults
