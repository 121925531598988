import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useMutation, useQuery } from '@apollo/client'

import { Row } from 'react-bootstrap'
import Toggle from 'react-toggle'

import {
  LOCK_USER,
  CHANGE_ROLE,
  DELETE_USER,
  GET_PROFILE_DATA,
} from './graph-queries'
import ModalStyled from './styles/manage-modal'
import { useIntl } from 'react-intl'
import Button from '_components/button'
import { useAppLabel } from '_application'

const entities = ['activities', 'opportunities', 'companies', 'subscriptions']

const Index = ({ user, onClose, setUsersList, usersList }) => {
  const appLabel = useAppLabel()

  const [changeRole] = useMutation(CHANGE_ROLE)
  const [lockUser] = useMutation(LOCK_USER)
  const [deleteUser] = useMutation(DELETE_USER)
  const { data } = useQuery(GET_PROFILE_DATA, { variables: { sub: user.sub } })
  const intl = useIntl()

  const handleClose = () => {
    clearTimeout(saveTimeout)
    onClose()
  }
  const renderEntities = (type) => {
    return (
      <div>
        {appLabel(`${type}Plural`, `${type}.titleplural`)}:
        {` (${data?.getProfileData[type]})`}
      </div>
    )
  }
  const safeForDelete = ({
    activities,
    opportunities,
    companies,
    subscriptions,
  }) => {
    return activities + opportunities + companies + subscriptions === 0
  }
  const roles = [
    { key: 'user', value: 'User' },
    { key: 'contentEditor', value: 'Content editor' },
    { key: 'admin', value: 'Admin' },
  ]

  let saveTimeout = null

  const [role, setRole] = useState(user.role)
  const [lock, setLock] = useState(user.isLocked)
  const [save, setSave] = useState()

  const renderKey = (item) =>
    intl.formatMessage({ id: `user.profile.account.deleteprofile.${item}` })

  const handleRole = (val) => {
    clearTimeout(saveTimeout)
    setRole(val)
    setSave(true)
    saveTimeout = setTimeout(() => setSave(false), 3000)
    changeRole({
      variables: { sub: user.sub, role: val },
    })
  }

  const handleLock = (val) => {
    clearTimeout(saveTimeout)
    setLock(val)
    setSave(true)
    saveTimeout = setTimeout(() => setSave(false), 3000)
    lockUser({
      variables: { sub: user.sub, locked: val },
    })
  }
  const handleDelete = async () => {
    const { data } = await deleteUser({
      variables: {
        id: user._id,
      },
    })
    if (data.deleteUser === false) {
      alert('This user cannot be deleted from admin panel.')
    }
    if (data.deleteUser === true) {
      onClose(true)
    }
  }

  const renderMessageText = (value) => {
    return intl.formatMessage({
      id: `global.modal.label.${value.toLowerCase()}`,
    })
  }
  const renderDeleteUser = () => {
    if (!user) return <></>
    if (data && data.getProfileData && safeForDelete(data?.getProfileData)) {
      return (
        <Row className="admin-option-row">
          <div className="section">
            <div className="content">
              <label>
                <Button className="button" onClick={(e) => handleDelete()}>
                  Delete this user
                </Button>
              </label>
            </div>
          </div>
        </Row>
      )
    }
    return <></>
  }
  return (
    <Modal
      dialogClassName="manage-users-admin-modal-container"
      show={true}
      onHide={handleClose}
    >
      <ModalStyled>
        <Modal.Body className="manage-users-admin-modal-body">
          <div className="admin-user-upper-container">
            <h2 className="admin-user-title">
              {renderMessageText('manageUser')} (
              {`${user.firstName} ${user.lastName}`})
            </h2>
          </div>
          <div className="admin-user-settings-container">
            <Row className="admin-option-row">
              <div className="section">
                <span className="title">{renderMessageText('role')}</span>
                <div className="content">
                  <select
                    value={role}
                    onChange={(e) => handleRole(e.target.value)}
                  >
                    {roles.map((r) => {
                      return (
                        <option value={r.key}>{`${intl.formatMessage({
                          id: `admin.manageusers.edituser.role.${r.key}`,
                        })}`}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </Row>
            <Row className="admin-option-row">
              <div className="section">
                <span className="title">{renderMessageText('lockUser')}</span>
                <div className="content">
                  <label>
                    <Toggle
                      className="button-toggle"
                      onChange={(e) => handleLock(e.target.checked)}
                      checked={lock}
                    />
                  </label>
                </div>
              </div>
            </Row>
            {data?.getProfileData && (
              <Row>
                <span className="title">{'Delete this user'}</span>
                <span className="items-header">{renderKey('itemsheader')}</span>
                {data && entities.map((ent) => renderEntities(ent))}
              </Row>
            )}
            {renderDeleteUser()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="keywords-modal-buttons-container">
            {save ? (
              <span className="saved-sign">{renderMessageText('saved')}</span>
            ) : null}
            <button
              onClick={() => handleClose()}
              className="cancel-button"
              type="button"
            >
              {renderMessageText('close')}
            </button>
          </div>
        </Modal.Footer>
      </ModalStyled>
    </Modal>
  )
}

export default Index
